import { makeStyles } from "@material-ui/core/styles";
import index from "./index.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable/index";
import Header from "./component/header";
import { ExpandMore } from "@mui/icons-material";
import { filterAllowedColumn } from "../../../_helper/helper";
import { loadingPanel } from "../../../CommonComponents/CommonFunction";
import visileColumns from "./visibleColumns";
import { orderBy } from "@progress/kendo-data-query";
import { useSelector } from "react-redux";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import PayCodeManagement from "./component/payCodeManagement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import { useAuth } from "oidc-react";
import axios from "axios";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const DepartMentMaster = (props) => {
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const classes = useStyles();
  const [sortedColumns, setSortedColumns] = useState([]);
  const { userData } = useAuth();
  const {
    columns,
    loadingSearch,
    searchResults,
    searchResults: { results, rowCount },
  } = useSelector((state) => {
    return state.advanceSearchSlice;
  });
  console.log("columns", columns);
  const [expandedSearchResult, setexpandedSearchResult] = useState(true);

  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([{ field: "Dp1", dir: "desc" }]);
  const [pageSize, setPageSize] = useState(10);
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });
  const [tableList, setTableList] = useState([]);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}depdev`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);
  useEffect(() => {
    if (columns && visileColumns.length > 0 && columns.length > 0) {
      const clms = visileColumns.map((d) => d.field.toLowerCase());
      const data = columns
        .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
        .map((p) => {
          return {
            field:
              p.column_name.indexOf("_") > -1
                ? p.column_name
                    .slice(0, p.column_name.indexOf("_") - 1)
                    .toLowerCase() +
                  p.column_name.slice(p.column_name.indexOf("_") - 1)
                : p.column_name.charAt(0).toLowerCase() +
                  p.column_name.slice(1),
            show: false,
            locked: false,
            width: 200,
            title: p.column_title,
            filter: "text",
          };
        });
      setSortedColumns(
        filterAllowedColumn(userData, visileColumns.concat(data)),
        "id"
      );
    }
  }, [columns]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    setPageSize(10);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    setPageSize(10);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  return (
    <>
      <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <Header
            {...props}
            detail={detail}
            getData={getData}
            setDetail={setDetail}
          />
        </div>
        {loadingSearch && loadingPanel}

        <div
          classes={{
            root: "w-full h-full",
            header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
            contentWrapper: "p-0",
            contentCard: "rounded-none",
          }}
        >
          <div className={`${classes.root} w-full page-content`}>
            <Card className="mt-3 mb-3">
              <Accordion
                className={classes.root}
                expanded={expand.panel1}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="bg-gray-200 sub-header"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="sub-header"
                    style={{
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography className={classes.heading}>
                    Department Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 page-body-wrapper">
                  <CardBody>
                    <PayCodeManagement detail={detail} setDetail={setDetail} />
                  </CardBody>
                </AccordionDetails>
              </Accordion>
            </Card>

            <Card className="mt-3 mb-3">
              <Accordion
                className={classes.root}
                expanded={expand.panel2}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className="bg-gray-200 sub-header"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="sub-header"
                    style={{
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography className={classes.heading}>
                    Departments List
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdvanceSearch
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"LK_DPDV "}
                    searchParams={[]}
                    additionalWhere={additionalWhere}
                    expanded={true}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                  />
                  <div>
                    {sortedColumns.length > 0 && (
                      <TelerikDataTable
                        pageSize={pageSize}
                        totalCount={rowCount}
                        skip={(page - 1) * pageSize}
                        take={pageSize}
                        data={results || []}
                        sortData={sortData}
                        onFilterChange={handleFilterChange}
                        onSortChange={handleSortChange}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        selectedColumns={sortedColumns}
                        setSortedColumns={setSortedColumns}
                        loading={loadingSearch}
                        // actions={actions}
                        isDashboard={true}
                        setChooseFilterMethod={setChooseFilterMethod}
                        chooseFilterMethod={chooseFilterMethod}
                      ></TelerikDataTable>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DepartMentMaster;
