export const SetGridFilter = (payload) => {
  return {
    type: "GRID_FILTER",
    payload: payload,
  };
};

export const SetGridDataState = (payload) => {
  return {
    type: "GRID_DATASTATE",
    payload: payload,
  };
};

export const SetGridSort = (payload) => {
  return {
    type: "GRID_SORT",
    payload: payload,
  };
};

export const setGridPageSize = (payload) => {
  return {
    type: " GRID_PAGE_SIZE",
    payload: payload,
  };
};
export const setAdditionalWhere = (payload) => {
  return {
    type: "ADVANCE_ADDITIONALWHERE",
    payload: payload,
  };
};
export const SaveQuery = (parameters) => {
  return {
    type: "SAVE_QUERY",
    payload: parameters,
  };
};
export const SaveLocation = (parameters) => {
  return {
    type: "SAVE_LOCATION",
    payload: parameters,
  };
};
export const setGridSkip = (payload) => {
  return {
    type: "GRID_SKIP",
    payload: payload,
  };
};

const initialState = {
  filter: {},
  pageSize: {},
  additionalWhere: {},
  skip: {},
  sort: [],
  dataState: {},
  savedQuery: null,
  savedLocation: null,
};

const CommonSlice = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case "GRID_FILTER": {
      const fltr = Object.assign(state.filter, action.payload);
      state = { ...state, filter: fltr };
      break;
    }
    case "GRID_DATASTATE": {
      const dataState = Object.assign(state.dataState, action.payload);
      state = { ...state, dataState: dataState };
      break;
    }
    case "ADVANCE_ADDITIONALWHERE": {
      const additionalWhere = Object.assign(
        state.additionalWhere,
        action.payload
      );
      state = { ...state, dataState: additionalWhere };
      break;
    }
    case "GRID_SKIP": {
      const skip = Object.assign(state.skip, action.payload);
      state = { ...state, skip: skip };
      break;
    }
    case "GRID_SORT": {
      const sort = Object.assign(state.sort, action.payload);
      state = { ...state, sort: sort };
      break;
    }
    case "GRID_PAGE_SIZE": {
      const pageSize = Object.assign(state.pageSize, action.payload);
      state = { ...state, pageSize: pageSize };
      break;
    }
    case "SAVE_QUERY":
      state = { ...state, savedQuery: [...action.payload] };
      break;
    case "SAVE_LOCATION":
      state = { ...state, savedLocation: action.payload };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CommonSlice;
