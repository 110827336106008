import { makeStyles } from "@material-ui/core/styles";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import FilterList from "./filterList";
import TableComponent from "./tableComponent";
import { useDispatch } from "react-redux";
import moment from "moment";
import { set } from "react-hook-form";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    // height: "40px",
    // // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, 0.04)",
    // },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const RequestTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    nPositionNo: "",
    title: "",
    jobColler: "",
    rangeCurrent: "",
    rangeNext: "",
    termId: "",
    passId: "",
    UPDDate: null,
    NUPDDate: null,
    NRVar: "",
    CLASS: "",
    catagory: "",
    exemptNon: "",
    OCCSeries: "",
    TDP: "",
    classified: "",
    jobOrder: "",
  });
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const changeHeaderFilter = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };
  const { requestDetail, filterBy, role } = props;
  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;

  const filterByRef = useRef(filter);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      // (filter && filterByRef.current && filterBy.current !== filter) ||
      requestDetail
    ) {
      filterByRef.current = filter;
      let dataList = requestDetail;

      if (filter?.nPositionNo) {
        dataList = dataList.filter((data) =>
          data.NPOSITION_NO.toLowerCase().includes(
            filter.nPositionNo?.toLowerCase()
          )
        );
      }

      if (filter?.title) {
        dataList = dataList.filter((data) =>
          data.TITLE?.toLowerCase().includes(filter.title?.toLowerCase())
        );
      }
      if (filter?.jobColler) {
        dataList = dataList.filter((data) =>
          data?.JOB_COLLAR.toLowerCase().includes(
            filter.jobColler?.toLowerCase()
          )
        );
      }
      if (filter?.rangeCurrent) {
        dataList = dataList.filter((data) =>
          data.RANGE_CURRENT?.toString().includes(
            parseFloat(filter.rangeCurrent)
          )
        );
      }
      if (filter?.rangeNext) {
        dataList = dataList.filter((data) =>
          data.RANGE_NEXT?.toLowerCase().includes(
            filter.rangeNext.toLowerCase()
          )
        );
      }
      if (filter?.termId) {
        dataList = dataList.filter((data) =>
          data.TERM_ID?.toLowerCase().includes(filter.termId.toLowerCase())
        );
      }
      if (filter?.passId) {
        dataList = dataList.filter((data) =>
          data.PASS_ID?.toLowerCase().includes(filter.passId.toLowerCase())
        );
      }
      if (filter?.NRVar) {
        dataList = dataList.filter((data) =>
          data.NR_VAR?.toLowerCase().includes(filter.NRVar.toLowerCase())
        );
      }
      if (filter?.CLASS) {
        dataList = dataList.filter((data) =>
          data.CLASS?.toLowerCase().includes(filter.CLASS.toLowerCase())
        );
      }
      if (filter?.catagory) {
        dataList = dataList.filter((data) =>
          data.CATAGORY?.toLowerCase().includes(filter.catagory.toLowerCase())
        );
      }
      if (filter?.exemptNon) {
        dataList = dataList.filter((data) =>
          data.EXEMPT_NON?.toLowerCase().includes(
            filter.exemptNon.toLowerCase()
          )
        );
      }
      if (filter?.OCCSeries) {
        dataList = dataList.filter((data) =>
          data.OCC_SERIES?.toLowerCase().includes(
            filter.OCCSeries.toLowerCase()
          )
        );
      }
      if (filter?.TDP) {
        dataList = dataList.filter((data) =>
          data.TDP?.toLowerCase().includes(filter.TDP.toLowerCase())
        );
      }
      if (filter?.classified) {
        dataList = dataList.filter((data) =>
          data.CLASSIFIED.toLowerCase().includes(
            filter.classified?.toLowerCase()
          )
        );
      }
      if (filter?.jobOrder) {
        dataList = dataList.filter((data) =>
          data.JOB_ORDER?.toLowerCase().includes(filter.jobOrder.toLowerCase())
        );
      }

      if (filter?.Multi) {
        dataList = dataList.filter((data) =>
          data.Multi?.toString().includes(parseFloat(filter.Multi))
        );
      }
      //   if (filter?.type && filter?.type !== "All") {
      //     dataList = dataList.filter((data) => data.type.includes(filter.type));
      //   }
      //   if (filter?.status && filter.status !== "All") {
      //     dataList = dataList.filter((data) => data.status === filter.status);
      //   }
      if (filter?.UPDDate) {
        let fdt = moment(filter.UPDDate).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.UPD_DATE).format("MM-DD-YYYY").includes(fdt);
        });
      }
      if (filter?.NUPDDate) {
        let fdt = moment(filter.NUPDDate).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.NUPD_DATE).format("MM-DD-YYYY").includes(fdt);
        });
      }
      //   if (filter?.name) {
      //     dataList = dataList.filter((data) =>
      //       `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`.includes(
      //         filter.name?.toLowerCase()
      //       )
      //     );
      //   }
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filter, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));

    let info = {
      id: data.NPOSITION_NO,
      nPositionNo: data.NPOSITION_NO,
      title: data.TITLE,
      jobColler: data.JOB_COLLAR,
      rangeCurrent: data.RANGE_CURRENT,
      rangeNext: data.RANGE_NEXT,
      termId: data.TERM_ID,
      passId: data.PASS_ID,
      UPDDate: data.UPD_DATE,
      NUPDDate: data.NUPD_DATE,
      NRVar: data.NR_VAR,
      CLASS: data.CLASS,
      catagory: data.CATAGORY,
      exemptNon: data.EXEMPT_NON,
      OCCSeries: data.OCC_SERIES,
      TDP: data.TDP,
      classified: data.CLASSIFIED,
      jobOrder: data.JOB_ORDER,
    };

    props.setValues(info);
    props.setId(data.NPOSITION_NO);
  };

  const columns = [
    { id: "NPOSITION_NO", name: "NPOSITION_NO", type: "" },
    { id: "TITLE", name: "TITLE", type: "" },
    {
      id: "JOB_ORDER",
      name: "JOB_ORDER",
    },
    {
      id: "TERM_ID",
      name: "TERM_ID",
    },
    {
      id: "NR_VAR",
      name: "NR_VAR ",
    },
    {
      id: "JOB_COLLAR",
      name: "JOB_COLLAR",
    },

    {
      id: "RANGE_CURRENT",
      name: "RANGE_CURRENT",
    },

    {
      id: "RANGE_NEXT",
      name: "RANGE_NEXT",
    },
    { id: "PASS_ID", name: "PASS_ID", type: "" },
    { id: "UPD_DATE", name: "UPD_DATE", type: "" },
    { id: "NUPD_DATE", name: "NUPD_DATE", type: "" },

    { id: "CLASS", name: "CLASS", type: "" },
    { id: "CATAGORY", name: "CATAGORY", type: "" },
    { id: "EXEMPT_NON", name: "EXEMPT_NON", type: "" },
    { id: "OCC_SERIES", name: "OCC_SERIES", type: "" },
    { id: "TDP", name: "TDP", type: "" },
    { id: "CLASSIFIED", name: "CLASSIFIED", type: "" },
  ];

  return (
    <TableComponent
      columns={columns}
      filteredList={requestDetail}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      // filterComponent={
      //   filteredList && (
      //     <FilterList
      //       changeHeaderFilter={changeHeaderFilter}
      //       role={role}
      //       requestDetail={requestDetail}
      //       filterBy={filter}
      //     />
      //   )
      // }
    />
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeHeaderFilter, setSelectedId }, dispatch);
// };

// function mapStateToProps(state) {
//   console.log("STATE ::: ", state);
//   return {
//     filterBy: state.paycode && state.paycode.paycode.filterBy,
//   };
// }

export default RequestTable;
