import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Switch, Typography } from "@material-ui/core";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { H5, P } from "../../../AbstractElements";
import moment from "moment";
import axios from "axios";
import { Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import ModalCustom from "../../employee/Modal";
import EmpCertificationManage from "../../ConfigurationMaster/EmployeeCertificationMaster/component/EmpCertificationManage";
import {
  addOrUpdateEmployeeCertificateRecord,
  getAllEmployeeCertificatesRecords,
  getAllEmployeeCertificatesRecordsByEmployeeId,
  getAllLkCertificationRecords,
} from "../../../app/utility/axiosService";
import MUIDataTable from "mui-datatables";
import { useAuth } from "oidc-react";
import { toast } from "react-toastify";

function EmployeeCertificationDetails(props) {
  const { userDetails } = props;

  const { userData } = useAuth();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [LkCertificationList, setLkCertificationList] = useState([]);
  const [empCertifications, setEmpCertifications] = useState([]);
  const [takeCount, setTakeCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [detail, setDetail] = useState({
    oldId: null,
    employeeID: userDetails?.ID ?? 0,
    certificationCode: 0,
    certificationSeq: "",
    validFrom: null,
    validTo: null,
    isActive: true,
    remarks: "",
  });

  const updateData = () => {
    

    let error = false
    let datas = empCertifications.filter(
      (i) =>
       { return i.certificationCode == detail.certificationCode 
        &&
        moment(i.validFrom).format("YYYY-MM-DD") <= moment(detail.validTo).format("YYYY-MM-DD") && moment(i.validTo).format("YYYY-MM-DD") >= moment(detail.validFrom).format("YYYY-MM-DD")
      
       } 
    ); 

    if(datas.length){
      return true
    }else{
      return false
    }
  
  };
  console.log("asdasklsada", empCertifications, detail);

  const clearField = () => {
    setDetail({
      employeeID: userDetails?.ID ?? 0,
      certificationCode: 0,
      certificationSeq: "",
      validFrom: null,
      validTo: null,
      remarks: "",
      isActive: true,
    });
  };

  console.log("asfadsfkdmsfdsklfs", detail);
  const onActiveStatusChange = (event, data) => {
    if (data) {
      axios
        .post(
          `${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/CreateOrUpdate`,
          {
            isActive: event.target.checked,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        )
        .then(() => {})
        .catch(() => {});
    }
  };

  useEffect(() => {
    fetchAllEmployeeCertificationRecords();
    getAllLkCertificationData();
  }, []);

  useEffect(() => {
    fetchAllEmployeeCertificationRecords();
  }, [takeCount, skipCount, JSON.stringify(userDetails)]);

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  // const { requestDetail } = props;
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const [state, setState] = useState({
    filteredList: empCertifications
      ? arrangeList(empCertifications)
      : undefined,
  });

  // const { filteredList } = state;
  // const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (empCertifications) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(empCertifications),
      }));
    }
  }, [empCertifications]);

  const options = {
    selectableRows: false,
    display: false,
    download: false,
    print: false,
    viewColumns: false,
    count: totalCount,
    onChangeRowsPerPage: (number) => {
      setTakeCount(number);
    },
    onChangePage: (page) => {
      setSkipCount(takeCount * page);
    },
  };

  const employeeIssuesColumn = [
    {
      id: "certificationCode",
      name: "certificationCode",
      label: "Certification Code",
    },
    {
      id: "certificationSeq",
      name: "certificationSeq",
      label: "Certification Seq.",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      id: "validFrom",
      name: "validFrom",
      label: "Valid From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("MM-DD-YYYY") : "";
        },
      },
    },
    {
      id: "validTo",
      name: "validTo",
      label: "Valid To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("MM-DD-YYYY") : "";
        },
      },
    },
    {
      id: "remarks",
      name: "remarks",
      label: "Remarks",
    },
    {
      id: "isActive",
      name: "isActive",
      label: "Is Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value ? true : false}
              disableRipple
              onChange={(event) => {
                const value = event.target.checked;
              }}
              color="primary"
              className="align-top"
            />
          );
        },
      },
    },
  ];

  const fetchAllEmployeeCertificationRecords = async () => {
    if (userDetails?.id) {
      const res = await getAllEmployeeCertificatesRecordsByEmployeeId(
        Number(userDetails?.id),
        takeCount,
        skipCount
      );
      if (res.status === 200) {
        setEmpCertifications(res.data);
        setTotalCount(res.totalCount);
      }
    }
  };

  const getAllLkCertificationData = async () => {
    const res = await getAllLkCertificationRecords();
    if (res.status === 200) {
      setLkCertificationList(res.data);
    }
  };

  const handleAddEmployeeCertification = () => {
    setOpenAddModal(true);
  };

  const handleAddEmpCertificate = async () => {
   let error =  updateData();
   if(error){
    toast.error("Check your Date. It is overwrite with other Certificates")
   }
    // if (userDetails?.ID && detail?.certificationCode && detail?.certificationCode !== 0 && detail?.certificationSeq && detail?.validFrom && detail?.validTo && detail?.validTo &&
    //   detail?.remarks && (detail?.isActive === true || detail?.isActive === false)) {
     
    let payload = {
      id: detail?.oldId ?? 0,
      employeeID: "10040530",
      certificationCode: detail?.certificationCode,
      certificationSeq: Number(detail?.certificationSeq),
      validFrom: detail?.validFrom,
      validTo: detail?.validTo,
      remarks: detail?.remarks,
      isActive: detail?.isActive,
    };

    const res = await addOrUpdateEmployeeCertificateRecord(payload);
    if (res.status === 200) {
      toast.success("Record Added.");
      fetchAllEmployeeCertificationRecords();
      clearField();
    } else {
      toast.error("Something went wrong");
    }
    // } else {
    //   toast.error("Please validate values!");
    // }
  };

  const checkValidDate = (index, rowsData) => {
    FindOverWrite(detail, empCertifications);
  };

  const FindOverWrite = (currentRow, AllData) => {
    const uniqueArr = Array.from(
      new Set(AllData.map((obj) => obj.certificationCode))
    ).map((certificationCode) => {
      return AllData.find((obj) => obj.certificationCode === certificationCode);
    });

    if (uniqueArr.length) {
      uniqueArr.map((icode) => {
        let currentInfo = AllData.filter((i) => {
          return i.certificationCode == icode.certificationCode;
        });

        if (currentInfo.length) {
          currentInfo.map((i, index) => {
            console.log("dskdjmaknadkls", index);

            i.error = false;
          });
        }

        if (currentInfo.length >= 2) {
          currentInfo.map((i, index) => {
            console.log("uniqueArr", currentInfo);

            const st = i.validFrom
              ? moment(i.validFrom).format("YYYY-MM-DD")
              : null;
            const et = i.validTo
              ? moment(i.validTo).format("YYYY-MM-DD")
              : null;

            currentInfo.map((j, index2) => {
              const stin = j.validFrom
                ? moment(j.validFrom).format("YYYY-MM-DD")
                : null;
              console.log("ddasdadada", stin);

              const etin = j.validTo
                ? moment(j.validTo).format("YYYY-MM-DD")
                : null;
              // j.error = true;

              console.log("OVerWrite Checking", i.PayCode, j.PayCode);

              if (st && stin && etin) {
                if (st > stin && st < etin && index !== index2) {
                  console.log("innnnn", st, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (st && stin) {
                console.log("bbbb", st, "#", stin, "###", index, "@", index2);
                if (st <= stin && st >= stin && index !== index2) {
                  console.log("innnnn", st, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (et && stin && etin) {
                console.log(
                  "bbbb22222",
                  et,
                  "#",
                  stin,
                  "###",
                  index,
                  "@",
                  index2
                );
                if (et > stin && et < etin && index !== index2) {
                  console.log("innnnn", et, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (et && st) {
                console.log("bbbb", et, "#", etin, "###", index, "@", index2);
                if (et == st) {
                  console.log("innnnn", et, "#", etin);
                  i.error = true;
                }
              }

              if ((stin && etin == null) || (etin && stin == null)) {
                j.error = true;
              }
            });
          });
        } else {
          currentInfo.map((i, index) => {
            const st = i.validFrom
              ? moment(i.validFrom).format("YYYY-MM-DD")
              : null;
            const et = i.validTo
              ? moment(i.validTo).format("YYYY-MM-DD")
              : null;

            console.log("fdesfsfssd", moment(i.st).isValid());
            if ((st && et == null) || (et && st == null)) {
              i.error = true;
            }
          });

          console.log("OVerWrite NO allowed");
        }
      });
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="pb-0">
          <Row align="middle" justify="space-between">
            <H5>Certifications Details</H5>
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#234d10",
                backgroundColor: "#ffffff",
              }}
              onClick={handleAddEmployeeCertification}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Typography className="text-12"> {"Add Certificate"} </Typography>
            </Button>
          </Row>
        </CardHeader>

        <CardBody>
          <div style={{ width: "100%" }}>
            <MUIDataTable
              className="empCertificationDataTable"
              // title={<Typography variant="h6">Employee Certification Data</Typography>}
              data={empCertifications}
              columns={employeeIssuesColumn}
              options={options}
              selectableRowsHideCheckboxes={true}
            />
          </div>
        </CardBody>
      </Card>
      <ModalCustom
        open={openAddModal}
        handleClose={() => {
          setOpenAddModal(false);
          clearField();
        }}
        title="Add Employee Certification"
        closeButton={true}
      >
        <EmpCertificationManage
          detail={detail}
          checkValidDate={checkValidDate}
          setDetail={setDetail}
          currentEmpDetails={userDetails}
          userData={userData}
          LkCertificationList={LkCertificationList}
          isEdit={false}
          saveData={handleAddEmpCertificate}
          closeModal={() => {
            setOpenAddModal(false);
            clearField();
          }}
        />
      </ModalCustom>
    </>
  );
}

export default EmployeeCertificationDetails;
