import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { Container, FormControl, TextField } from "@mui/material";

import Header from "./component/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import axios from "axios";
import { Card, CardBody } from "reactstrap";
import FederalTaxManage from "./component/FederalTaxManage";
import moment from "moment";
import { Button } from "@material-ui/core";
import { getAllFederalTax } from "../../../app/utility/axiosService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        ".&.MuiAccordionSummary-content": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "none",
        },
        margin: "0 !important",
        borderTopWidth: 1,
        borderTopColor: "#ccc",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        // color: theme.palette.text.secondary,
    },
}));

const FederalTax = (props) => {
    const classes = useStyles();
    const [detail, setDetail] = useState({
        oldId: null,
        minRange: '',
        maxRange: '',
        rate: '',
        isSingle: false,
    });
    const [tableList, setTableList] = useState([]);
    const [takeCount, setTakeCount] = useState(10);
    const [skipCount, setSkipCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [expand, setExpand] = React.useState({
        panel1: true,
        panel2: true,
        panel3: true,
    });
    const handleChange = (panel) => (event, isExpanded) => {
        setExpand((prevState) => ({
            ...prevState,
            [panel]: isExpanded,
        }));
    };
    const getData = async () => {
        const res = await getAllFederalTax();
        if (res?.status === 200) {
          setTableList(res.data);
          setTotalCount(res.totalCount);
        }
    };

    useEffect(() => {
        getData();
    }, [skipCount]);

    const clearFields = () => {
        setDetail({
            minRange: '',
            maxRange: '',
            rate: '',
            isSingle: false,
        });
    };

    return (
       
        <>
         <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <Header
                {...props}
                clearFields={clearFields}
                detail={detail}
                tableList={tableList}
                getData={getData}
                setDetail={setDetail}
            />
            </div>
            
            <div
                classes={{
                    root: "w-full h-full",
                    header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                    contentWrapper: "p-0",
                    contentCard: "rounded-none",
                }}
            >
                <div className={`${classes.root} w-full page-content`}>
                    <Card className="mt-3 mb-3">
                        <Accordion
                            className={classes.root}
                            expanded={expand.panel1}
                            onChange={handleChange("panel1")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="bg-gray-200 sub-header"
                            >
                                <FontAwesomeIcon
                                    icon={faRectangleList}
                                    className="sub-header"
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "3px",
                                    }}
                                />
                                <Typography className={classes.heading}>
                                    Federal Tax Details
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="pt-0 page-body-wrapper">
                                <CardBody>
                                    <FederalTaxManage detail={detail} setDetail={setDetail} />
                                </CardBody>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    <Card className="mt-3 mb-3">
                        <Accordion
                            className={classes.root}
                            expanded={expand.panel2}
                            onChange={handleChange("panel2")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className="bg-gray-200 sub-header"
                            >
                                <FontAwesomeIcon
                                    icon={faRectangleList}
                                    className="sub-header"
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "3px",
                                    }}
                                />
                                <Typography className={classes.heading}>
                                    Federal Tax List
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 page-body-wrapper">
                                <RequestTable
                                    {...props}
                                    role={"admin"}
                                    clearFields={clearFields}
                                    requestDetail={tableList}
                                    setSkipCount={setSkipCount}
                                    totalCount={totalCount}
                                    detail={detail}
                                    setDetail={setDetail}
                                    filterBy={detail}
                                    getData={getData}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </div>
            </div>
            </Container>
        </>
    );
};

export default FederalTax;
