import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../../../_helper/api_helper";

const sliceName = "vendor";

export const getVendors = createAsyncThunk(
    `${sliceName}/vendors`,
    async () => {
        try {
            let vendorList = [];
            try {
                const vendorData = await get(`${process.env.REACT_APP_WEB_API_URL}vendor/GetAll`);
                vendorList = vendorData?.data || [];
            } catch (error) {
                return { vendorList: [] };
            }

            return { vendorList };
        } catch (e) {
            return {
                error: e.message,
                vendorList: {},
            };
        }
    }
);

const vendorReducer = createSlice({
    name: sliceName,
    initialState: {
        vendorList: [],
        loading: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVendors.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVendors.fulfilled, (state, action) => {
                state.vendorList = action.payload.vendorList;
                state.loading = false;
            })
            .addCase(getVendors.rejected, (state) => {
                state.loading = false;
            })
    },
});

export default vendorReducer.reducer;
