import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMessageExclamation,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";

import { showMessage } from "../../../../app/store/actions";
import { toast } from "react-toastify";

import moment from "moment";
import { CardHeader } from "reactstrap";

import * as yup from "yup";

let schema = yup.object({
  ACTN_TYPE: yup.string().required(),
  DESCRIPTION: yup.string().required(),
});
function HomeHeader(props) {
  let dispatch = useDispatch();
  const checkvalid = () => {
    if (props.detail.ACTN_TYPE == "" || props.detail.DESCRIPTION == "") {
      // dispatch(
      //   showMessage({
      //     message: "Please validate values!",
      //     variant: "error",
      //   })
      // );
      verifyCode();
      console.log("Show Message... Please validate values!");
      return false;
    } else {
      return true;
    }
  };
  const verifyCode = () => {
    try {
      schema
        .validate({
          ACTN_TYPE: props.detail.ACTN_TYPE,
          DESCRIPTION: props.detail.DESCRIPTION,
        })
        .then((valid) => {
          console.log(
            props.detail.ACTN_TYPE,
            "Yup",
            valid,
            "#",
            props.detail.DESCRIPTION
          ); // true
        })
        .catch((e) => {
          console.log("e1", e);
          toast.error(`${e}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.log("e", error);
    }
  };

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Act Type Management</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={props.saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.oldId ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.oldId ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={props.clearField}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;
