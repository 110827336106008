import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",

  overflow: "scroll",
  height: "50%",
  p: 4,
};

export default function SankeyDPValueModal({
  open,
  setOpen,
  handleOpen,
  label,
  selectedOptions,
  setSelectedOptions,
  deletedOptions,
  setDeletedOptions,
}) {
  const handleClose = () => setOpen(false);

  const handleRemove = (value, id) => {
    let newArray = [...deletedOptions, id];
    setDeletedOptions(newArray);
    const filteredList = selectedOptions.filter((i) => {
      return i.value !== value;
    });
    console.log("filteredList", filteredList);
    setSelectedOptions(filteredList);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{label}</div>
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2, m: 2 }}>
            {selectedOptions.map((v, index, a, getTagProps) => (
              <Chip
                label={v.label}
                style={{ margin: "10px" }}
                // onClick={handleClick}
                // onDelete={handleDelete}
                // className={classes.chip}

                icon={
                  <CloseIcon
                    onClick={() => handleRemove(v.value, v.id)}
                    style={{
                      borderColor: "black",
                      backgroundColor: "gray",
                      fontSize: "20px",
                      color: "white",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                  />
                }
              />
            ))}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
