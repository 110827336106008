import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterList from "./filterList";

import TableComponent from "../DeductionPlanMaster/tableComponent";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TimeSheetTemplateDetails from "./TimeSheetTemplateDetails";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit } from "@fortawesome/pro-duotone-svg-icons";
import { Tooltip } from "@mui/material";
import axios from "axios";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    TemplateID: "",
    TemplateTitle: "",
  });

  const [viewModal, setViewModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [templateData, setTemplateData] = useState();

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    setFilter({ ...filterBy, [name]: value });
  };

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.VendorNo) {
        dataList = dataList.filter(
          (data) =>
            data &&
            data.VendorNo &&
            data.VendorNo.toLowerCase().includes(
              filterBy.VendorNo?.toLowerCase()
            )
        );
      }

      if (filterBy?.VendorName) {
        dataList = dataList.filter(
          (data) =>
            data &&
            data.VendorName &&
            data.VendorName.toLowerCase().includes(
              filterBy.VendorName.toLowerCase()
            )
        );
      }
      if (filterBy?.VendorEIN) {
        dataList = dataList.filter(
          (data) =>
            data &&
            data.VendorEIN &&
            data.VendorEIN.toLowerCase().includes(
              filterBy.VendorEIN.toLowerCase()
            )
        );
      }
      if (filterBy?.State) {
        dataList = dataList.filter(
          (data) =>
            data &&
            data.State &&
            data.State.toLowerCase().includes(filterBy.State.toLowerCase())
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const handleRowClick = (data) => {
    console.log("ON CLICK dd :::: ", data);
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));
    // <TimeSheetTemplateDetails data={data} />;
    history(`/master/timesheet-template-details`, { state: data });

    // let info = {
    //   selectedDay: "",
    //   time_from: "",
    //   time_to: "",
    // };

    // props.setDetail(info);
  };

  const columns = [
    {
      id: "ID",
      name: "Actions",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {/* {console.log(
                "Values 121212 ::: -> ",
                value,
                tableMeta,
                updateValue
              )} */}
              <Tooltip title="View TimeSheet" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // props.setTemplateId(value, tableMeta.TimeSheetName);
                    props.setTemplateId(
                      tableMeta.TimeSheetID
                        ? tableMeta.TimeSheetID
                        : tableMeta.ID,
                      tableMeta.TimeSheetName
                    );
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                  }}
                />
              </Tooltip>
              <Tooltip title="Edit TimeSheet" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    console.log("LOGGGG 123123::: ", tableMeta);
                    // handleRowClick();
                    history(`/master/timesheet-template-details`, {
                      state: tableMeta,
                      // id: tableMeta.ID,
                    });
                  }}
                  // className="sub-header"
                  icon={faEdit}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginLeft: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "Template ID",
      // id: "TimeSheetID",
      type: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.TimeSheetID) {
            return tableMeta.TimeSheetID;
          } else {
            return tableMeta.ID;
          }
        },
      },
    },
    {
      name: "Template Title",
      id: "TimeSheetName",
    },
    {
      name: "Creator Name",
      id: "EmployeeID",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.Employee) {
            return tableMeta?.Employee?.EmployeeName_FML;
          } else {
            return "Admin";
          }
        },
      },
    },
  ];

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      // onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      // filterComponent={
      //   filteredList && (
      //     <FilterList
      //       changeHeaderFilter={changeHeaderFilter1}
      //       role={role}
      //       requestDetail={requestDetail}
      //       filterBy={filterBy}
      //     />
      //   )
      // }
    />
  );
};

export default DataTable;