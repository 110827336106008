import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row } from "react-bootstrap";
import { fontSize } from "@mui/system";
import CustomizerContext from "../../../_helper/Customizer";
import { Label } from "reactstrap";

function ProfessionalDetail(props) {
  const { mix_background_layout } = useContext(CustomizerContext);

  const [detail, setDetail] = useState({
    ssn: props.userDetail.ssn,
    fullName: props.userDetail.fullName,
    empTye: props.userDetail.empTye,
    dept: props.userDetail.dept,
    payGrade: props.userDetail.payGrade,
    empNo: props.userDetail.empNo,
    positionTitle: props.userDetail.positionTitle,
    employmentDate: props.userDetail.employmentDate,
    reEmploymentDate: props.userDetail.reEmploymentDate,
    totalSvc: props.userDetail.totalSvc,
    currentSvc: props.userDetail.currentSvc,
    totalPriorSvc: props.userDetail.totalPriorSvc,
    milPriorSvc: props.userDetail.milPriorSvc,
    grade: props.userDetail.grade,
    step: props.userDetail.step,
    rate: props.userDetail.rate,
    salary: props.userDetail.salary,
    actual: props.userDetail.actual,
    actual2: props.userDetail.actual2,
  });
  return (
    <>
      <Row style={{ width: "100%" }} className="g-3">
        <Col md={4}>
          <Label className="form-label">SSN</Label>
          <input
            className="form-control"
            type="text"
            placeholder="SSN"
            name="eName"
            value={detail.ssn}

          />


        </Col>
        <Col md={4}>
          <Label className="form-label">Full Name</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Full Name"
            name="eName"
            value={detail.fullName}

          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Emp Type</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Emp Type"
            name="eName"
            value={detail.empTye}

          />


        </Col>
        <Col md={4}>
          <Label className="form-label">DEPT/DIV/SEC</Label>
          <input
            className="form-control"
            type="text"
            placeholder="DEPT/DIV/SEC"
            name="eName"
            value={detail.dept}

          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Pay Grade(Position)</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Pay Grade(Position)"
            name="eName"
            value={detail.payGrade}

          />

        </Col>

        <Col md={4}>

          <Label className="form-label">Employee Number</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Employee Number"
            name="eName"
            value={detail.empNo}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Position Title</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Position Title"
            name="eName"
            value={detail.positionTitle}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Employment Date</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Employment Date"
            name="eName"
            value={detail.employmentDate}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Employment Date</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Re Employment Date"
            name="eName"
            value={detail.reEmploymentDate}

          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Total SVC</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Re Employment Date"
            name="eName"
            value={detail.totalSvc}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Current SVC</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Re Employment Date"
            name="eName"
            value={detail.currentSvc}

          />


        </Col>
        <Col md={4}>

          <Label className="form-label">Total Prior SVC</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Total Prior SVC"
            name="eName"
            value={detail.totalPriorSvc}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Total Prior SVC</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Total Prior SVC"
            name="eName"
            value={detail.totalPriorSvc}

          />


        </Col>
        <Col md={2}>

          <Label className="form-label">Rate</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Rate"
            name="eName"
            value={detail.rate}

          />

        </Col>
        <Col md={2}>
          <Label className="form-label">Salary</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Salary"
            name="eName"
            value={detail.salary}

          />

        </Col>
        <Col md={2}>

          <Label className="form-label">Actual</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Actual"
            name="eName"
            value={detail.actual}

          />

        </Col>
        <Col md={2}>

          <Label className="form-label">Actual 2</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Actual2"
            name="eName"
            value={detail.actual2}

          />

        </Col>
        <Col md={2}>

          <Label className="form-label">Actual 2</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Grade"
            name="eName"
            value={detail.grade}

          />

        </Col>
        <Col md={2}>

          <Label className="form-label">Step</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Step"
            name="eName"
            value={detail.step}

          />

        </Col>

      </Row>

    </>
  );
}

export default ProfessionalDetail;
