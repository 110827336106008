import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";

import { Col, Row } from "react-bootstrap";
import CustomizerContext from "../../../_helper/Customizer";
import { Label } from "reactstrap";

function OtherDetail(props) {
  const { mix_background_layout } = useContext(CustomizerContext);

  const [detail, setDetail] = useState({
    home: props.userDetail.home,
    mail: props.userDetail.mail,
    homePhone: props.userDetail.homePhone,
    workPhone: props.userDetail.workPhone,
    workSchedule: props.userDetail.workSchedule,
    lastActionDate: props.userDetail.lastActionDate,
    policeClearanceDate: props.userDetail.policeClearanceDate,
    courtClearanceDate: props.userDetail.courtClearanceDate,
    empContractNo: props.userDetail.empContractNo,
    requestId: props.userDetail.requestId,
    physicalExam: props.userDetail.physicalExam,
    drivingLicenseNo: props.userDetail.drivingLicenseNo,
  });
  return (
    <>
      <Row style={{ width: "100%" }} className="g-3">
        <Col md={4}>

          <Label className="form-label">Home</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Home"
            name="eName"
            value={detail.home}

          />

        </Col>
        <Col md={4}>


          <Label className="form-label">Mail</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Mail"
            name="eName"
            value={detail.mail}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Home Phone</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Home Phone"
            name="eName"
            value={detail.homePhone}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Work Phone</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Work Phone"
            name="eName"
            value={detail.workPhone}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Work Schedule</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Work Schedule"
            name="eName"
            value={detail.workSchedule}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Last Action Date</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Last Action Date"
            name="eName"
            value={detail.lastActionDate}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Police Clearance Date</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Police Clearance Date"
            name="eName"
            value={detail.policeClearanceDate}

          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Court Clearance Date</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Court Clearance Date"
            name="eName"
            value={detail.courtClearanceDate}
          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Emp Contract No</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Emp Contract No"
            name="eName"
            value={detail.empContractNo}
          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Request ID</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Request ID"
            name="eName"
            value={detail.requestId}
          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Physical Exam</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Physical Exam"
            name="eName"
            value={detail.physicalExam}
          />

        </Col>
        <Col md={4}>

          <Label className="form-label">Driving License No</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Driving License No"
            name="eName"
            value={detail.drivingLicenseNo}
          />

        </Col>
      </Row>

    </>
  );
}

export default OtherDetail;
