import Typography from "@material-ui/core/Typography";
import React from "react";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import { addOrUpdateStateTaxRecord } from "../../../../app/utility/axiosService";

function HomeHeader(props) {

  const checkvalid = () => {
    if (props.detail?.code == '' || props.detail?.name == '') {
      toast.error('Please validate values!');
      return false;
    } else {
      return true;
    }
  };

  const checkExistingCode = () => {
    let isValid = false;
    if (props.tableList?.length > 0) {
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        if (ele === props.detail?.code) {
          isValid = false;
        } else isValid = true;
      }
    } else isValid = true;
    return isValid;
  };

  const saveData = async () => {
    if (!checkvalid()) {
      return false;
    }

    if (!checkExistingCode()) {
      toast.error('Code is already exist');
      return false;
    }

    let payload = {
      id: props.detail?.oldId??0,
      code: props.detail?.code,
      name: props.detail?.name,
    };

    const res = await addOrUpdateStateTaxRecord(payload);
    if (res.status === 200) {
      toast.success('Record Added.');
      props.getData();
      props.clearFields();
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">State Master</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.oldId ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.oldId ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={props.clearFields}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;