import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as url from "../../../../_helper/url_helper";
import { get, post } from "../../../../_helper/api_helper";

const sliceName = "advanceSearchSlice";
const URL_AdvanceSearch = "/AdvanceSearch/[LOCATION]";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;
const newBaseURL = "https://ehrp-pr-webapi.azurewebsites.net";
console.log("newBaseURL", newBaseURL);
export const advanceSearch = createAsyncThunk(
  `${sliceName}/advanceSearch`,
  async (payload) => {
    try {
      let advanceSearch = [];
      try {
        // const advanceSearchData = await axios.post(
        //   payload.location
        //     ? baseURL +
        //         URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
        //         `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
        //     : `https://ehrp-pr-webapi.azurewebsites.net/AdvanceSearch/GetRecords`,
        //   payload,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${localStorage.getItem(
        //         "jwt_access_token"
        //       )}`,
        //     },
        //   }
        // );
        const advanceSearchData = await post(`${newBaseURL}/AdvanceSearch/GetRecords`, payload);

        if (!!advanceSearchData?.results) {
          advanceSearch = advanceSearchData;
        }
      } catch (error) {
        return { advanceSearch: {} };
      }
      return {
        advanceSearch: advanceSearch,
      };
    } catch (e) {
      return {
        error: e.message,
        advanceSearch: {},
      };
    }
  }
);

export const getAllAdvanceSearch = createAsyncThunk(
  `${sliceName}/getAllAdvanceSearch`,
  async (payload) => {
    try {
      //get DPDV
      let advanceSearchAll = [];
      try {
        const advanceSearchAllData = await axios.post(
          baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        console.log("advanceSearchAllData", advanceSearchAllData);

        if (advanceSearchAllData.data.length) {
          advanceSearchAllData.data.map((item) => {
            return advanceSearchAll.push(item);
          });
        }
      } catch (error) {
        return { advanceSearchAll: [] };
      }
      return {
        advanceSearchAll: advanceSearchAll,
      };
    } catch (e) {
      return {
        error: e.message,
        advanceSearch: [],
      };
    }
  }
);
export const saveAdvanceSearch = createAsyncThunk(
  `${sliceName}/saveAdvanceSearch`,
  async (payload) => {
    try {
      //get DPDV
      let saveAdvanceSearch = [];
      try {
        const saveAdvanceSearchData = await axios.post(
          baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        console.log("saveAdvanceSearchData", saveAdvanceSearchData);
        if (saveAdvanceSearchData.data.message) {
          saveAdvanceSearch(saveAdvanceSearchData.data);
        }
      } catch (error) {
        return { saveAdvanceSearch: {} };
      }
      return {
        saveAdvanceSearch: saveAdvanceSearch,
      };
    } catch (e) {
      return {
        error: e.message,
        saveAdvanceSearch: [],
      };
    }
  }
);
export const findAsyouType = createAsyncThunk(
  `${sliceName}/findAsyouType`,
  async ({ table, keyField, valueField, search }) => {
    try {
      //get DPDV
      let findAsyou = [];
      try {
        const findAsyouTypeData = await axios.get(
          url.ADVANCE_SEARCH_LOAD_FINDASTYPE +
            "/" +
            `${table}/${keyField}/${valueField}/${search}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (findAsyouTypeData.data.length) {
          findAsyouTypeData.data.map((item) => {
            return findAsyou.push(item);
          });
        }
      } catch (error) {
        return { findAsyou: [] };
      }
      return {
        findAsyou: findAsyou,
      };
    } catch (e) {
      return {
        error: e.message,
        findAsyou: [],
      };
    }
  }
);
export const getAdvanceSearchById = createAsyncThunk(
  `${sliceName}/getAdvanceSearchById`,
  async (payload) => {
    try {
      //get DPDV
      let advanceSearch = {};
      try {
        const advanceSearchData = await axios.get(
          `${
            baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location)
          }?qID=${payload?.params?.qID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        console.log("advanceSearchData", advanceSearchData);
        if (advanceSearchData && advanceSearchData?.data) {
          advanceSearch = advanceSearchData?.data;
        }
        console.log("advanceSearch====>", advanceSearch);
      } catch (error) {
        return { advanceSearch: {} };
      }
      return {
        advanceSearch: advanceSearch,
      };
    } catch (e) {
      return {
        error: e.message,
        advanceSearch: {},
      };
    }
  }
);

export const loadColumns = createAsyncThunk(
  `${sliceName}/loadColumns`,
  async (tableName) => {
    try {
      //get DPDV
      let loadColumn = [];
      try {
        const loadColumnsData = await get(`${newBaseURL + url.ADVANCE_SEARCH_LOAD_COLUMNS + "=" + tableName}`);
        if (loadColumnsData.data.length) {
          loadColumnsData.data.map((item) => {
            return loadColumn.push(item);
          });
        }
      } catch (error) {
        return { loadColumn: [] };
      }
      return {
        loadColumn: loadColumn,
      };
    } catch (e) {
      return {
        error: e.message,
        loadColumn: [],
      };
    }
  }
);

const advanceSearchSlice = createSlice({
  name: sliceName,
  initialState: {
    saveResults: {},
    searchResults: {},
    columns: [],
    loadingSearch: false,
    getAdavanceSearch: {},
    getAdavanceSearchType: "",
    findAsyouTypeResults: [],
    loadingFindAsYouType: false,
    lodingSaveType: "",
    userSavedQueries: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(advanceSearch.pending, (state) => {
        state.loadingSearch = true;
      })
      .addCase(advanceSearch.fulfilled, (state, action) => {
        state.searchResults = action.payload.advanceSearch;
        state.loadingSearch = false;
      })
      .addCase(advanceSearch.rejected, (state) => {
        state.loadingSearch = false;
      })
      .addCase(getAdvanceSearchById.pending, (state, action) => {
        state.getAdavanceSearch = {};
        state.getAdavanceSearchType = "GET_ADVANCE_SEARCH_BYID";
      })
      .addCase(getAdvanceSearchById.fulfilled, (state, action) => {
        state.getAdavanceSearch = action.payload.advanceSearch;
        state.getAdavanceSearchType = "GET_ADVANCE_SEARCH_BYID_SUCCESS";
      })
      .addCase(getAdvanceSearchById.rejected, (state, action) => {
        state.getAdavanceSearch = {};
        state.getAdavanceSearchType = "GET_ADVANCE_SEARCH_BYID_FAIL";
      })
      .addCase(loadColumns.fulfilled, (state, action) => {
        state.columns = action.payload.loadColumn;
      })
      .addCase(getAllAdvanceSearch.fulfilled, (state, action) => {
        state.getAdavanceSearch = [];
        state.getAdavanceSearchType = "";
        state.userSavedQueries = action.payload?.advanceSearchAll;
      })
      .addCase(saveAdvanceSearch.pending, (state, action) => {})
      .addCase(saveAdvanceSearch.fulfilled, (state, action) => {
        state.saveResults = action.payload.saveAdvanceSearch;
        state.lodingSaveType = "SAVE_ADVANCE_SEARCH_SUCCESS";
      })
      .addCase(saveAdvanceSearch.rejected, (state, action) => {
        state.lodingSaveType = "SAVE_ADVANCE_SEARCH_FAIL";
      })
      .addCase(findAsyouType.fulfilled, (state, action) => {
        state.findAsyouTypeResults = action.payload?.findAsyou;
        state.loadingFindAsYouType = true;
      });
  },
});

export default advanceSearchSlice.reducer;
