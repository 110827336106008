import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import dayjs from "dayjs";

import "./Modal.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  WeekColor,
  adjustRoundedHR,
  adjustRoundedTime,
  getHoursMinutes,
  getUserRole,
  getValidPaycodeOfDay,
  handleTimeToMinutes,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigDB from "../../../Config/ThemeConfig";
import { faCircleCheck, faClock } from "@fortawesome/pro-duotone-svg-icons";
import { color } from "highcharts";
import moment from "moment";

function TableRowsEmpV2({
  rowsData,
  deleteTableRows,
  handleChangeV1,
  handleDateFieldChange,
  setTimeTo,
  getDateColor,
  payPeriod,
  maxSLLeave,
  maxALLeave,
  viewFields,
  viewField,
  verify,
  setRowsData,
  setDeleteRowIndex,
  role,
  setOpen,
  radioData,
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  let mask = "12:34";
  let formatChars = {
    1: "[0-2]",
    2: "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  };
  let beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;

    // Conditional mask for the 2nd digit base on the first digit
    if (value.startsWith("2"))
      formatChars["2"] = "[0-3]"; // To block 24, 25, etc.
    else formatChars["2"] = "[0-9]"; // To allow 05, 12, etc.
    return { value, selection: newState.selection };
  };
  const calcualteTime = (mins) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");

    return `${hr} hr  ${min} mins`;
  };
  const handleErrorMessage = (
    ole1,
    ole2,
    dateExceed1,
    dateExceed2,
    leaveHoursAL,
    leaveHoursSL
  ) => {
    console.log("dsvklfjksfhjsksghldksghsl", ole1, ole2, dateExceed1);
    if (ole1 || ole2) {
      return "Check your time slot. It is overwrite with other row";
    } else if (dateExceed1 || dateExceed2) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (ole1 || (ole2 && (dateExceed1 || dateExceed2))) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (leaveHoursAL) {
      return ` AL Leave Hours Exceeded ${calcualteTime(maxALLeave)}`;
    } else if (leaveHoursSL) {
      return `SL Leave Hours Exceeded  ${calcualteTime(maxSLLeave)}`;
    } else {
      return "";
    }
  };

  const calculateEndTime = (startTime, duration) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const durationArr = duration.split(":");
    // Convert duration to milliseconds
    const durationMs =
      parseInt(durationArr[0]) * 60 * 60 * 1000 +
      parseInt(durationArr[1]) * 60 * 1000;
    const end = new Date(start.getTime() + durationMs);
    if (isNaN(end.getTime())) {
    } else {
      return moment(end).format("HH:mm");
    }
  };

  const filterTimeRange = (radioData, payPeriod) => {
    if (radioData === "week1") {
      return {
        StartDate:
          payPeriod && payPeriod.StartDate ? payPeriod.StartDate : null,
        EndDate:
          payPeriod && payPeriod.EndDate
            ? moment(payPeriod.StartDate, "YYYY-MM-DD")
                .add(6, "days")
                .format("YYYY-MM-DD")
            : null,
      };
    } else if (radioData === "week2") {
      return {
        StartDate:
          payPeriod && payPeriod.StartDate
            ? moment(payPeriod.StartDate, "YYYY-MM-DD")
                .add(7, "days")
                .format("YYYY-MM-DD")
            : null,
        EndDate: payPeriod && payPeriod.EndDate ? payPeriod.EndDate : null,
      };
    } else {
      return {
        StartDate:
          payPeriod && payPeriod.StartDate ? payPeriod.StartDate : null,
        EndDate: payPeriod && payPeriod.EndDate ? payPeriod.EndDate : null,
      };
    }
  };

  return rowsData.length
    ? rowsData.map((data, index) => {
        console.log("dsadadddadasdad", data);
        const {
          jobtype,
          date,
          time_from,
          time_to,
          time_from1,
          time_to1,
          hrs,
          jobOrder,
          parentIndex,
          hjobtype,
          ole1,
          ole2,
          rowstatus,
          dateExceed1,
          dateExceed2,
          authError2,
          authError1,
          leaveHoursAL,
          leaveHoursSL,
          verify,
          Status,
          approvedStatus,
          ID,
        } = data;
        // let parentIndex = data.parentIndex ? data.parentIndex : "";
        // let parentEnable = parentIdx!= "" ? true : false;
        console.log("Payrol table data v3", data);
        // const inputProps = {
        //   step: 300,
        //   fontSize: 14,
        // };

        const isDisableRow = (role, Status) => {
          if (["user"].includes(role)) {
            return (
              Status === "timekeeper" ||
              Status === "supervisor" ||
              Status === "admin" ||
              verify ||
              approvedStatus === "Approve"
            );
          } else {
            return false;
          }
        };

        const inputProps = {
          style: { fontSize: 14, step: 300 },
          step: 300,
        };

        let parentEnable =
          parentIndex !== "0" && parentIndex != null ? true : false;
        // const isDisableRow = (parentIndex, jobtype, resource) => {
        //   try {
        //     if (jobtype === "NDIF") {
        //       return true;
        //     } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
        //       if (
        //         ["time_from", "time_to", "time_from1", "time_to1"].includes(
        //           resource
        //         )
        //       ) {
        //         return false;
        //       } else {
        //         return true;
        //       }
        //     } else {
        //       return false;
        //     }
        //   } catch (error) {
        //     console.log("error", error.message);
        //   }
        // };

        // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
        const getSelectedType = (jobtype) => {
          let filterObj = jobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP ST", filterObj);

          return filterObj !== undefined
            ? filterObj
            : {
                value: "REG",
                label: "REG",
                title: "REGULAR",
                code: "1",
                multi: 1,
                EffectDate: "1990-01-01T00:00:00.000Z",
                ExpireDate: "2099-12-31T00:00:00.000Z",
                name: "jobtype",
              };
        };
        const getSelectedHType = (jobtype) => {
          let filterObj = hjobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP STD", filterObj);

          return filterObj !== undefined ? filterObj : null;
        };

        const weekColors = WeekColor(
          payPeriod?.StartDate,
          payPeriod?.EndDate,
          date
        );
        return (
          <>
            <tr
              key={index}
              bordercolor="red"
              // className={index % 2 === 0 ? "trRow1" : "trRow2"}

              style={{
                backgroundColor: getDateColor(date, rowsData),
                // alignItems: "center",

                // display: "flex",
              }}
              // style={{ backgroundColor: (ole1 || ole2 ? "#e7e7e7" : null),borderRadius:'20px' }}
            >
              {/* <td className="paroll-contents-td">
                {!verify ? (
                  <FontAwesomeIcon
                    icon={faClock}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                      color: "green",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                )}
              </td> */}

              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  margin="dense"
                  name="jobt"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={jobtype && jobtype?.trim() != "" ? jobtype : "NONE"}
                  sx={{ fontSize: 14 }}
                  //disabled={isTableRowDisable}
                />
              </td>

              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    value={date ? dayjs(date) : null}
                    format="dd MM/DD/YYYY"
                    onChange={(e) => {
                      const formatDate = e?.$d;
                      handleDateFieldChange("date", e?.$d, index);
                    }}
                    disabled={isDisableRow(role, Status)}
                    name="date"
                    hiddenLabel="true"
                    minDate={
                      payPeriod.StartDate
                        ? filterTimeRange(radioData, payPeriod).StartDate
                        : null
                    }
                    maxDate={
                      payPeriod.EndDate
                        ? filterTimeRange(radioData, payPeriod).EndDate
                        : null
                    }
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from ? time_from : ""}
                  onChange={(e) => {
                    handleChangeV1(index, e, "", data);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_from"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={ parentEnable }
                  disabled={isDisableRow(role, Status)}
                  hiddenLabel="true"
                />
              </td>
              <td
                className="paroll-contents-td mt-1"
                style={{ backgroundColor: `${weekColors}` }}
              >
                {/* <TextField
                  size={"small"}
                  margin="dense"
                  name="hours"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={hrs}
                  onChange={(e) => handleChangeV1(index, e)}
                  sx={{ fontSize: 14 }}
                  disabled={isDisableRow(parentIndex, jobtype, "hrs")}
                /> */}
                <InputMask
                  mask={mask}
                  placeholder="HH:MM"
                  // disabled={rows.isDisable}
                  formatChars={formatChars}
                  disabled={isDisableRow(role, Status)}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                  value={hrs ? hrs : ""}
                  // value={''} // Set initial value for the input
                  onChange={(e) => {
                    let copy = [...rowsData];
                    let mn = handleTimeToMinutes(e.target.value);
                    console.log("Hrs row data", mn);

                    copy[index]["hrs"] = adjustRoundedHR(e.target.value);
                    const calculateEndTimeData = calculateEndTime(
                      copy[index].time_from,
                      copy[index].hrs
                    );

                    // let info = adjustRoundedTime(calculateEndTimeData);

                    copy[index]["time_to"] = calculateEndTimeData;
                    copy[index]["mn"] = mn;
                    handleChangeV1(index, e, "", data);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                    setRowsData(copy);
                    // updateTime(index, copy);
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      // disabled={rows.isDisable}
                      size={"small"}
                      margin="dense"
                      style={{ marginTop: "8px" }}
                      name="hours"
                      fullWidth={true}
                      variant="outlined"
                      mask="99:99"
                      maskChar="0"
                      placeholder="HH:MM"
                      // disabled={verify}

                      // value={timeData[ index ]?.workingHr}
                      disabled={isDisableRow(role, Status)}
                      inputProps={{
                        fontSize: 12,
                        style: { fontSize: 14 },
                      }}
                    />
                  )}
                </InputMask>
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"

                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to ? time_to : ""}
                  onChange={(e) => {
                    console.log("dasdsa", e);
                    handleChangeV1(index, e, "", data);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isDisableRow(role, Status)}
                />
              </td>

              {viewField ? (
                <>
                  {viewFields?.map((i) => {
                    return (
                      <td
                        className="paroll-contents-td"
                        style={{ backgroundColor: `${weekColors}` }}
                      >
                        <TextField
                          size={"small"}
                          margin="dense"
                          inputProps={{
                            readOnly: true,
                            fontSize: 12,
                            style: { fontSize: 14 },
                          }}
                          fullWidth={true}
                          variant="outlined"
                          value={data[i.value] ? data[i.value] : null}
                          sx={{ fontSize: 14 }}
                        />
                      </td>
                    );
                  })}
                </>
              ) : null}
              <td
                className="paroll-contents-td tcolor"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <button
                  className={`${
                    parentEnable ? "greyBtn" : ""
                  } btn btn-outline-danger paroll-contents-calcle `}
                  disabled={isDisableRow(role, Status)}
                  onClick={() => {
                    if (ID) {
                      setDeleteRowIndex(index);
                      setOpen(true);
                    } else {
                      deleteTableRows(index);
                    }
                  }}
                >
                  X
                </button>
              </td>
            </tr>
            <tr
              style={{
                display:
                  ole1 ||
                  ole2 ||
                  dateExceed1 ||
                  dateExceed2 ||
                  leaveHoursAL ||
                  leaveHoursSL
                    ? ""
                    : "none",
              }}
            >
              <td
                colspan="10"
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  alignItems: "center",
                  transition: "ease-in",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {handleErrorMessage(
                    ole1,
                    ole2,
                    dateExceed1,
                    dateExceed2,
                    leaveHoursAL,
                    leaveHoursSL
                  )}
                </p>
              </td>
            </tr>
          </>
        );
      })
    : null;
}

export default TableRowsEmpV2;
