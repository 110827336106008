import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
const sliceName = "empDashboardSlice";

export const filterChart = createAsyncThunk(
  `${sliceName}/filterChart`,
  async (input) => {
    try {
      return {
        chartFilter: {
          id: input.id,
          StartDate: input.startDate,
          EndDate: input.endDate,
        },
      };
    } catch (e) {
      return {
        error: e.message,
        chartFilter: {
          totalEarning: 0,
          annualDeduction: 0,
          annualLeave: 0,
          sickLeave: 0,
        },
      };
    }
  }
);

export const fetchEmployeeBalance = createAsyncThunk(
  `${sliceName}/fetchEmployeeBalance`,
  async (input) => {
    try {
      
   
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };
    let TotalDeduction = 0;
    
      const data1 = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/dashboard/overalldeduction`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      TotalDeduction = data1.data.TotalDeduction;
      
    
      

    // console.log("Mr data 1", data1);
    let Earnings = 0;
     
      
   
     
      const data2 = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/dashboard/overallearning`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      Earnings = data2.data[ 0 ]?.Earnings?data2.data[ 0 ]?.Earnings:0;
    
    let annualLeave = 0;
    let sickLeave = 0;
      
   
      // const data3 = await axios.post(
      //   `${process.env.REACT_APP_API_URL}employee/dashboard/leavebalance`,
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-access-token": localStorage.getItem("jwt_access_token"),
      //     },
      //   }
      // );
      // annualLeave = data3.data && data3.data ? data3.data[ 0 ][ "Balance" ] : 0;
      // sickLeave = data3.data && data3.data ? data3.data[ 1 ][ "Balance" ] : 0;
      console.log(annualLeave,'-MasterMMM-',sickLeave);
      
    

      return {
        empBalanceCount: {
          totalEarning: Earnings ,
          annualDeduction:  0,
          annualLeave: annualLeave,
          sickLeave: sickLeave,
        },
      };
    } catch (error) {
      return {
        empBalanceCount: {
          totalEarning: 0 ,
          annualDeduction:  0,
          annualLeave: 0,
          sickLeave: 0,
        },
      };
    }
  }
);

export const fetchDeltaEmployeeEarning = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeeEarning`,
  async () => {
    try {
      const data  = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-earning-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeEarning: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeEarning: [],
      };
    }
  }
);

export const fetchDeltaEmployeeEmpDeduction = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeeEmpDeduction`,
  async () => {
    try {
      const  data  = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-emp-deduction-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeEmpDeduction: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeEmpDeduction: [],
      };
    }
  }
);
 
export const fetchDeducation = createAsyncThunk(
  `${sliceName}/fetchDeducation`,
  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };

    try {
      const  data  = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/dashboard/deduction-byvendor`,
        payload,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deducation: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deducation: [],
      };
    }
  }
);
export const fetchDeductionToPlan = createAsyncThunk(
  `${sliceName}/fetchDeductionToPlan`,
  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };

    try {
      const  data = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/dashboard/deduction-byplan`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deducationtoPlan: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deducationtoPlan: [],
      };
    }
  }
);

export const fetchEarningByPayType = createAsyncThunk(
  `${sliceName}/fetchEarningByPayType`,
  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };

    try {
      const  data  = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/dashboard/earning-bypaytype`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        earningByPayType: data.data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        earningByPayType: [],
      };
    }
  }
);
export const fetchAnnualLeaveEarning = createAsyncThunk(
  `${sliceName}/fetchAnnualLeaveEarning`,
  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };
    try {
      const  data  = await axios.post(
        // `${process.env.REACT_APP_API_URL}report/summery/delta-employee-gov-deduction-payperiod`,
        `${process.env.REACT_APP_API_URL}employee/dashboard/delta-annualleave`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeALEarning: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeALEarning: [],
      };
    }
  }
);

export const fetchSickLeaveEarning = createAsyncThunk(
  `${sliceName}/fetchSickLeaveEarning`,

  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
    };
    try {
      const  data  = await axios.post(
        // `${process.env.REACT_APP_API_URL}report/summery/delta-employee-count-payperiod`,
        `${process.env.REACT_APP_API_URL}employee/dashboard/delta-sickleave`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeSLEarning: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeSLEarning: [],
      };
    }
  }
);

export const fetchWorkingHoursByDate = createAsyncThunk(
  `${sliceName}/fetchWorkingHoursByDate`,

  async (input) => {
    // let payload = {
    //   ppe: input.ppe ? input.ppe : 0,
    //   employeeID: input.employeeID ? input.employeeID : 0,
    //   "startDate":  moment().subtract(12, 'months').startOf('month').format("YYYY-MM-DD"),
    //   "endDate": moment().format("YYYY-MM-DD"),
    // };
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
      "startDate":  input.StartDate ? input.StartDate : moment().subtract(12, 'months').startOf('month').format("YYYY-MM-DD"),
      "endDate":  input.EndDate ? input.EndDate : moment().format("YYYY-MM-DD"),
    };
    try {
      const  data  = await axios.post(
        // `${process.env.REACT_APP_API_URL}report/summery/delta-employee-count-payperiod`,
        `${process.env.REACT_APP_API_URL}employee/dashboard/workinghrs-bydate`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );


      return {
        deltaWorkingHrsByDate: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaWorkingHrsByDate: [],
      };
    }
  }
);

export const fetchEarningByPayPeriod = createAsyncThunk(
  `${sliceName}/fetchEarningByPayPeriod`,

  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
      max: input.max ? input.max : 0,
    };
    try {
      const  data  = await axios.post(
        // `${process.env.REACT_APP_API_URL}report/summery/delta-employee-count-payperiod`,
        `${process.env.REACT_APP_API_URL}employee/dashboard/earning-histogram`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );


      return {
        deltaEarningByPayPeriod: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEarningByPayPeriod: [],
      };
    }
  }
);

export const fetchDeductionByPayPeriod = createAsyncThunk(
  `${sliceName}/fetchDeductionByPayPeriod`,

  async (input) => {
    let payload = {
      ppe: input.ppe ? input.ppe : 0,
      employeeID: input.employeeID ? input.employeeID : 0,
      max: input.max ? input.max : 0,
    };
    try {
      const  data  = await axios.post(
        // `${process.env.REACT_APP_API_URL}report/summery/delta-employee-count-payperiod`,
        `${process.env.REACT_APP_API_URL}employee/dashboard/deduction-histogram`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );


      return {
        deltaDeductionByPayPeriod: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaDeductionByPayPeriod: [],
      };
    }
  }
);
// export const filterChart = createAsyncThunk(
//   `${sliceName}/ChartFilter`,

//   async (input) => {
//     try {
//       console.log("fdsfvd", input);
//       return {
//         chartFilter: input,
//       };
//     } catch (e) {
//       console.log("Master", e.message);
//       return {
//         error: e.message,
//         chartFilter: [],
//       };
//     }
//   }
// );
const empDashboardSlice = createSlice({
  name: sliceName,
  initialState: {
    empBalanceCount: {
      totalEarning: 0,
      annualDeduction: 0,
      annualLeave: 0,
      sickLeave: 0,
    },
    chartFilter: {
      id: 0,
      startDate: "",
      endDate: "",
    },
    deducation: [],
    deducationtoPlan: [],
    earningByPayType: [],
    deltaEmployee: [],
    deltaEmployeeGovDeduction: [],
    deltaEmployeeALEarning: [],
    deltaEmployeeSLEarning: [],
    deltaEmployeeEarning: [],
    deltaEmployeeEmpDeduction: [],
    deltaWorkingHrsByDate: [],
    deltaEarningByPayPeriod: [],
    deltaDeductionByPayPeriod: [],
  },

  extraReducers: {
    [filterChart.fulfilled]: (state, action) => {
      state.chartFilter = action.payload.chartFilter;
    },
    [fetchDeducation.fulfilled]: (state, action) => {
      state.deducation = action.payload.deducation;
    },
    [fetchDeductionToPlan.fulfilled]: (state, action) => {
      state.deducationtoPlan = action.payload.deducationtoPlan;
    },
    [fetchEarningByPayType.fulfilled]: (state, action) => {
      state.earningByPayType = action.payload.earningByPayType;
    },
    [fetchEmployeeBalance.fulfilled]: (state, action) => {
      state.empBalanceCount = action.payload.empBalanceCount;
    },
  
    [fetchDeltaEmployeeEarning.fulfilled]: (state, action) => {
      state.deltaEmployeeEarning = action.payload.deltaEmployeeEarning;
    },
    [fetchDeltaEmployeeEmpDeduction.fulfilled]: (state, action) => {
      state.deltaEmployeeEmpDeduction =
        action.payload.deltaEmployeeEmpDeduction;
    },

    [fetchAnnualLeaveEarning.fulfilled]: (state, action) => {
      state.deltaEmployeeALEarning = action.payload.deltaEmployeeALEarning;
    },
    [fetchSickLeaveEarning.fulfilled]: (state, action) => {
      state.deltaEmployeeSLEarning = action.payload.deltaEmployeeSLEarning;
    },
    [fetchWorkingHoursByDate.fulfilled]: (state, action) => {
      state.deltaWorkingHrsByDate = action.payload.deltaWorkingHrsByDate;
    },
    [fetchEarningByPayPeriod.fulfilled]: (state, action) => {
      state.deltaEarningByPayPeriod = action.payload.deltaEarningByPayPeriod;
    },
    [fetchDeductionByPayPeriod.fulfilled]: (state, action) => {
      state.deltaDeductionByPayPeriod =
        action.payload.deltaDeductionByPayPeriod;
    },
  },
});
export default empDashboardSlice.reducer;
