import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@mui/material/TextField";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import React from "react";
import { Label } from "reactstrap";

const FilterList = (props) => {
  const { changeHeaderFilter1, role, requestDetail, filterBy, filter } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Act Type"
          name="ACTN_TYPE"
          value={filter.ACTN_TYPE}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Description"
          name="DESCRIPTION"
          value={filterBy.DESCRIPTION}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>
    </TableRow>
  );
};

export default FilterList;
