import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { CardHeader } from "reactstrap";
import { toast } from "react-toastify";

function HomeHeader(props) {

  const checkvalid = () => {
    if (props.detail?.minRange == '' || props.detail?.maxRange == '' || props.detail?.rate == '' || 
      (props.detail?.isSingle !== false && props.detail?.isSingle !== true)) {
      toast.error('Please validate values!');
      return false;
    } else {
      return true;
    }
  };

  const isBetween = (x, min, max) => {
    return x >= min && x <= max;
  };

  const checkValidRange1 = (data) => {
    let isValid = false;
    if (props.tableList?.length > 0 && props.detail?.oldId === 0) {
      const oldRecord = props.tableList?.find(o => o.id === props.detail?.oldId);
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        if (ele.isSingle === props.detail?.isSingle && isBetween(data.minRange, ele.minRange, ele.maxRange)) {
          isValid = false;
          return false;
        } 
        if (ele.isSingle === props.detail?.isSingle && isBetween(data.maxRange, ele.minRange, ele.maxRange)) {
          isValid = false;
          return false;
        } 
        else {
          isValid = true;
        }
      }
    }
    if (props.tableList?.length > 0 && props.detail?.oldId > 0) {
      const oldRecord = props.tableList?.find(o => o.id === props.detail?.oldId);
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        // if (ele.id === props.detail?.oldId) {
          if (oldRecord?.minRange <= data.minRange && oldRecord?.maxRange >= data.maxRange && oldRecord?.isSingle === ele.isSingle) {
            isValid = true;
          }
          if (oldRecord?.isSingle !== ele.isSingle) {
            if (isBetween(data.minRange, ele.minRange, ele.maxRange)) {
              isValid = false;
              return false;
            } 
            if (isBetween(data.maxRange, ele.minRange, ele.maxRange)) {
              isValid = false;
              return false;
            } 
          }
        // }
      }
    }
    if (props.tableList?.length === 0) isValid = true;
    return isValid;
  };

  const checkValidRange = (data) => {
    let isValid = false;
    const checkLoop = (minValue, maxValue, id = null) => {
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        if (ele.isSingle === props.detail?.isSingle && isBetween(minValue, ele.minRange, ele.maxRange) && (!id || (id && id !== ele.id))) {
          isValid = false;
          if (!id) return false;
        } 
        if (ele.isSingle === props.detail?.isSingle && isBetween(maxValue, ele.minRange, ele.maxRange) && (!id || (id && id !== ele.id))) {
          isValid = false;
          if (!id) return false;
        } 
        else {
          isValid = true;
        }
      }
    };

    if (props.tableList?.length > 0 && props.detail?.oldId === 0) {
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        checkLoop(data.minRange, data.maxRange);
        // if (ele.isSingle === props.detail?.isSingle && isBetween(data.minRange, ele.minRange, ele.maxRange)) {
        //   isValid = false;
        //   return false;
        // } 
        // if (ele.isSingle === props.detail?.isSingle && isBetween(data.maxRange, ele.minRange, ele.maxRange)) {
        //   isValid = false;
        //   return false;
        // } 
        // else {
        //   isValid = true;
        // }
      }
    }
    if (props.tableList?.length > 0 && props.detail?.oldId > 0) {
      const oldRecord = props.tableList?.find(o => o.id === props.detail?.oldId);
      for (let i = 0; i < props.tableList?.length; i++) {
        const ele = props.tableList[i];
        if (ele.id === props.detail?.oldId) {
          if (oldRecord?.minRange <= data.minRange && oldRecord?.maxRange >= data.maxRange && oldRecord?.isSingle === ele.isSingle) {
            console.log('true called');
            isValid = true;
            return true;
          } else {
            console.log('false called');
            isValid = false;
            checkLoop(data.minRange, data.maxRange, props.detail?.oldId);
            // return false;
          }
        }
        if (ele.id !== props.detail?.oldId) {
          console.log('loop called');
          checkLoop(data.minRange, data.maxRange, props.detail?.oldId);
        }
          // if (oldRecord?.isSingle === ele.isSingle) {
          //   checkLoop(data.minRange, data.maxRange);
          // }
          // if (oldRecord?.isSingle !== ele.isSingle) {
          //   checkLoop(data.minRange, data.maxRange);
          //   // if (isBetween(data.minRange, ele.minRange, ele.maxRange)) {
          //   //   isValid = false;
          //   //   return false;
          //   // } 
          //   // if (isBetween(data.maxRange, ele.minRange, ele.maxRange)) {
          //   //   isValid = false;
          //   //   return false;
          //   // } 
          // }
        // }
      }
    }
    if (props.tableList?.length === 0) isValid = true;
    return isValid;
  };

  const saveData = async () => {
    if (!checkvalid()) {
      return false;
    }

    if (!checkValidRange(props.detail)) {
      toast.error('NOT Validdd');
      return false;
    }

    let payload = {
      id: props.detail?.oldId??0,
      rate: Number(props.detail?.rate),
      minRange: Number(props.detail?.minRange),
      maxRange: Number(props.detail?.maxRange),
      isSingle: props.detail?.isSingle,
    };

    toast.success('Validdd');
    // const res = await addOrUpdateEmployeeCertificateRecord(payload);
    // if (res.status === 200) {
    //   toast.success('Record Added.');
    //   fetchAllEmployeeCertificationRecords();
    //   clearField();
    // } else {
    //   toast.error('Something went wrong');
    // }
  };

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Federal Tax</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.oldId ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.oldId ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={props.clearFields}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;