import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { faEye, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import DataTable from './DataTable';
import visileColumns from './visibleColumns';
import ErrorBoundary from '../../../ErrorBoundary';

const EmployeDeductionPlanMasterGird = (
    {
        isLoading, deleteResult,
        deleteLTD, employeeID,
        ...props
    }
) => {
    const history = useNavigate();
    const [deleteData, setDeleteData] = useState(null);

    useEffect(() => {
        if (deleteResult != undefined && !!Object.keys(deleteResult).length && !isLoading) {
            setDeleteData({ dt: new Date() });
        }
    }, [deleteResult, isLoading]);

    const actions = useMemo(() => {
        return [
            {
                title: "Show Details",
                action: (selectedData) => {
                    return history(`/master/employee-deduction-plan/${selectedData.dataItem.Id}/${employeeID}`);
                },
                icon: faEye,
                className: "font-primary",
                style: { cursor: "pointer" },
            }
        ];
    }, [employeeID]);

    return (
        <ErrorBoundary>
            <DataTable
                location=""
                tableName={'vw_EmployeeDeductionPlanMasters'}
                visileColumns={visileColumns}
                title={`Deductions`}
                newButtonText={`Add Deduction`}
                newFormURL={`/master/employee-deduction-plan/${employeeID}/new`}
                actions={actions}
                sort={[{ field: "createdOn", dir: "desc" }]}
                deleteData={deleteData}
                isLoading={isLoading}
                additionalWheres={`EmployeeID = ${employeeID}`}
            />
        </ErrorBoundary>
    )
}

export default (EmployeDeductionPlanMasterGird);
