import React, { useEffect } from "react";
import CommonConfigrations from "../Supervisor/CommonConfigrations/CommonConfigrations";
import { useDispatch, useSelector } from "react-redux";
import { GetAllEmployee, GetTagCode } from "../Supervisor/CommonConfigrations/TableSlice";

const EmployeeTag = () => {
  const { allEmployee, tagCodeList } = useSelector((state) => state.TableSlice);
  console.log("dadadasdadasd", tagCodeList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllEmployee(0))
    dispatch(GetTagCode())
  }, []);

  const configrationJSON = {
    headerTitle: "Employee Tag Management",
    detailTitle: "Employee Tag Management Detail",
    detailTableTitle: "Employee Tag Management List",
    tableColumns: [
      {
        id: "employeeName",
        name: "Employee Name",
        type: "select",
        isPagebal: true,
        label: "Employee Name",
        optionsList: allEmployee ?? [],
      },
      {
        id: "tagCode",
        name: "Tag Code",
        type: "select",
        label: "Tag Code",
        optionsList: tagCodeList?.map((i) => {
          return {label : i.label1 , value : i.value }
        }) ?? [],
      },
      {
        id: "validFrom",
        name: "Valid From",
        type: "date",
        label: "Valid From",
        dateFormat: "MM/DD/YYYY"
      },
      {
        id: "validTo",
        name: "Valid To",
        type: "date",
        label: "Valid To",
        dateFormat: "MM/DD/YYYY"
      },
      {
        id: "remarks",
        name: "Remarks",
        type: "text",
        label: "Remarks",
      },
      {
        id: "isActive",
        name: "Is Active",
        type: "status",
        label: "Is Active",
      }
    ],
    getAPIKey: "employeeTags",
    API_ENDPOINT: {
      GET_DATA_API_END_POINT: "Employee/GetAllEmployeeTag",
      CREATEORUPDATE_DATA_API_END_POINT: "Employee/CreateOrUpdateEmployeeTag",
    },
    INPUT_FIELDS: {
      employeeName: "",
      employeeID: 0,
      tagCode: "",
      validFrom: "",
      validTo: "",
      remarks: "",
      isActive: false,
    },
  };
  return (
    <>
      <CommonConfigrations configrationJSON={configrationJSON} />
    </>
  );
};

export default EmployeeTag;
