import TimeClockHead from "./TimeClockHead";

import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import TimeClockRows from "./TimeClockRows";
import axios from "axios";
import {
  HourMinFormat,
  ShowSucess,
  getUserId,
  getUserRole,
  getValidPaycodeOfDay,
  hoursDiffrence,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faSave, faStop } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../app/utility/ConfirmModal";
import { Hidden, Typography } from "@mui/material";
import { Button } from "@material-ui/core";

const HeaderTimeClock = (props) => {
  const {
    // loadPreData,
    filteredEvent = [],
    rowAuthData = [],
  } = props;
  const [isRunning, setIsRunning] = useState(false);
  const [pause, setPause] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [paytimeCode, setPaytimeCode] = React.useState([]);
  const [paytimeCodeByID, setPaytimeCodeByID] = React.useState([]);

  const [startStopCheck, setStartStopCheck] = useState(false);
  const [timeClockData, setTImeClockData] = useState([]);
  const [paycodes, setPaycodes] = useState({ value: "1", label: "REG" });
  const { userData } = useAuth();
  const userRole = getUserRole(userData);
  const userId = getUserId(userData);
  const [selectedPayPeriod, setSelectedPayPeriod] = React.useState({});
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getPayPeriod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payperiod`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // let createObj = [];
        if (response.data.length) {
          let resPaycode = response.data;
          let tody = moment().format("YYYY-MM-DD");
          let allpayperiod = resPaycode.filter((i) => {
            return i.StartDate <= tody;
          });
          //setAllPayPeriod(allpayperiod);
          if (userId) {
            let currentPayPeriod = resPaycode.find((i) => {
              return i.StartDate <= tody && i.EndDate >= tody;
            });
            setSelectedPayPeriod(currentPayPeriod);
          }
          // else {
          //   let currentPayPeriodByID = resPaycode.find((i) => {
          //     return i.ID == userId;
          //   });
          //   setSelectedPayPeriod(currentPayPeriodByID);
          // }
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPaycodeBYID = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        setPaytimeCodeByID(response.data.result);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getMasterData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        let createObj = [];
        if (response.data.length) {
          response.data.map((item, index) => {
            createObj.push({
              value: item.Abberv,
              label: item.Abberv,
              title: item.Description,
              code: item.Code,
              multi: item.Multi,
              EffectDate: item.EffectDate,
              ExpireDate: item.ExpireDate,
              color: item.color,
              name: "jobtype",
              OrderNo: item.OrderNo,
            });
            return true;
          });
        }
        setPaytimeCode(createObj);
        // setALLPaytimeCode(createObj);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if (userId) {
      getMasterData();
      getPaycodeBYID(userId);
      getData(userId);
      getPayPeriod();
    }
  }, [userId]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const seconds = prevTime.seconds + 1;
          const minutes = prevTime.minutes + Math.floor(seconds / 60);
          const hours = prevTime.hours + Math.floor(minutes / 60);

          return {
            hours: hours,
            minutes: minutes % 60,
            seconds: seconds % 60,
          };
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  function getCurrentTime() {
    const now = new Date();
    return {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
      date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      isRunning: true,
      isPause: false,
    };
  }

  const checkAuth = (sd, ed, cDate) => {
    let isauth = "pending";
    // let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
    let shr = sd;
    let ehr = ed;

    let tData = rowAuthData.filter((i) => {
      return i.date == cDate;
    });

    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.time_from, "HH:mm:ss").format("HH:mm");
      let toTime = moment(i.time_to, "HH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };

  const CalculateWorkingHR = (st, et, date) => {
    const startTime = moment(`${date} ${st}`, "YYYY-MM-DD HH:mm");
    const endTime = moment(
      `${moment().format("YYYY-MM-DD")} ${et}`,
      "YYYY-MM-DD HH:mm"
    );
    let duration = moment.duration(endTime.diff(startTime));

    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    let hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);

    return hrCalc;
  };

  const calculateTimeDifference = (existingTime, endDateTime) => {
    const finalExistingDate = existingTime.date;
    const start = new Date(finalExistingDate);
    const end = new Date(endDateTime);

    const timeDiff = end.getTime() - start.getTime();
    const seconds = Math.floor(timeDiff / 1000) % 60;
    const minutes = Math.floor(timeDiff / (1000 * 60)) % 60;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));

    return {
      hours,
      minutes,
      seconds,
      date: existingTime?.date,
      isRunning: existingTime.isRunning,
      isPause: existingTime?.isPause,
      isResume: existingTime?.isResume,
    };
  };

  const existingTimeSaved = localStorage.getItem("timeClockStartHours");

  useEffect(() => {
    if (existingTimeSaved) {
      const newTime = calculateTimeDifference(
        JSON.parse(existingTimeSaved),
        getCurrentTime()?.date
      );

      let isPauseData = JSON.parse(existingTimeSaved);

      if (isPauseData?.isPause || isPauseData?.isResume) {
        setTime(isPauseData);
        setIsRunning(isPauseData.isRunning);
        setPause(isPauseData?.isPause);
        setStartStopCheck(true);
      } else {
        setTime(newTime);
        setIsRunning(newTime?.isRunning);
        setPause(newTime?.isPause);
        setStartStopCheck(true);
      }
    }
  }, [existingTimeSaved]);

  const handleStart = () => {
    setIsRunning(true);

    localStorage.setItem(
      "timeClockStartHours",
      JSON.stringify(getCurrentTime())
    );
    setStartStopCheck(true);
  };

  const adjustTime = (startTime, endTime) => {
    const start = new Date(startTime);
    const minutes = start.getMinutes();
    const startofEnd = new Date(endTime);
    const minutesofEnd = startofEnd.getMinutes();
    let adjustedStartTime;
    let adjustedEndTime;

    if (minutes >= 0 && minutes <= 15) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        0,
        0
      );
    } else if (minutes >= 16 && minutes <= 30) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        30,
        0
      );
    } else if (minutes >= 31 && minutes <= 45) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        45,
        0
      );
    } else {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours() + 1,
        0,
        0
      );
    }

    if (minutesofEnd >= 0 && minutesofEnd <= 15) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        0,
        0
      );
    } else if (minutesofEnd >= 16 && minutesofEnd <= 30) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        30,
        0
      );
    } else if (minutesofEnd >= 31 && minutesofEnd <= 45) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        45,
        0
      );
    } else {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours() + 1,
        0,
        0
      );
    }

    let eMin = Math.round(minutesofEnd / 5) * 5;
    let useRounded = false;
    let finalEndTime = useRounded
      ? moment(startofEnd.setMinutes(eMin)).format("YYYY-MM-DDTHH:mm:ss")
      : moment(adjustedEndTime).format("YYYY-MM-DDTHH:mm:ss");
    return {
      startTime: moment(adjustedStartTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: finalEndTime,

      // endTime: ,
    };
  };

  const handleStop = () => {
    const savedLocal = localStorage.getItem("timeClockStartHours");
    const savedLocalData = JSON.parse(savedLocal);
    const totalMins = calculateTimeDifference(savedLocalData, getCurrentTime());

    const adjustedTime = adjustTime(
      savedLocalData.date,
      moment().format("YYYY-MM-DDTHH:mm:ss")
    );
    const timeForm = moment(savedLocalData.date).format("HH:mm");
    const date = moment(savedLocalData?.date).format("YYYY-MM-DD");

    const time_from = moment(adjustedTime?.startTime).format("HH:mm");
    const time_to = moment(adjustedTime?.endTime).format("HH:mm");
    const exipsting = [
      {
        shr: time_from,
        ehr: time_to,
        fromTime: "06:00",
        toTime: "18:00",
      },
    ];
    const result = splitTimeRange(time_from, time_to, exipsting);
    let newData = [];
    let AuthCheck = checkAuth(timeForm, time_to, date);

    let newOBJ = {
      date: date,
      time_from: timeForm,
      startTime: moment(adjustedTime?.startTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
      time_to: time_to,
      code: paycodes.value,
      paycode: paycodes.label,
      Auth: AuthCheck.status === "confirm" ? "A" : "U",

      totalMins: CalculateWorkingHR(
        timeForm,
        moment(adjustedTime?.endTime).format("HH:mm"),
        date
      ),
    };
    const payperiodEndDate = selectedPayPeriod?.EndDate;
    const currentDate = moment().format("YYYY-MM-DD");
    if (
      payperiodEndDate === moment(adjustedTime?.endTime).format("YYYY-MM-DD") &&
      adjustedTime?.endTime >=
        moment(`${currentDate}T24:00:00`, "YYYY-MM-DDTHH:mm:ss")
    ) {
      const ed = moment(adjustedTime?.startTime).format("YYYY-MM-DD");
      newOBJ.endTime = moment(`${ed}T${"24:00"}`, "YYYY-MM-DDTHH:mm").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      let nextDay = {
        date: currentDate,
        time_from: "12:00",
        endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        startTime: moment(
          `${currentDate}T24:00:00`,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        time_to: moment(adjustedTime?.endTime).format("HH:mm"),
        code: paycodes.value,
        paycode: paycodes.label,
        Auth: AuthCheck.status === "confirm" ? "A" : "U",

        totalMins: CalculateWorkingHR(
          "12:00",
          moment(adjustedTime?.endTime).format("HH:mm"),
          currentDate
        ),
      };
      timeClockData.push(newOBJ);
      timeClockData.push(nextDay);
    } else {
      timeClockData.push(newOBJ);
    }

    if (result && result.length) {
      result.map((item, index) => {
        let AuthCheck = checkAuth(
          item.start,
          item.end,
          moment().format("YYYY-MM-DD")
        );

        let resultData = {
          date: moment(adjustedTime?.endTime).format("YYYY-MM-DD"),
          time_from: item.start,
          time_to: item.end,
          code: "125",
          startTime: moment(adjustedTime.startTime).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endTime: moment(adjustedTime.endTime).format("YYYY-MM-DDTHH:mm:ss"),
          paycode: "NDIF",
          Auth: AuthCheck.status === "confirm" ? "A" : "U",
          totalMins: CalculateWorkingHR(
            item.start,
            item.end,
            moment().format("YYYY-MM-DD")
          ),
        };
        timeClockData.push(resultData);
      });
    }

    if (paycodes?.label === "H-5") {
      let newOBJ = {
        date: date,
        time_from: timeForm,
        startTime: moment(adjustedTime?.startTime).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        time_to: time_to,
        code: 1,
        paycode: "REG",
        Auth: AuthCheck.status === "confirm" ? "A" : "U",

        totalMins: CalculateWorkingHR(
          timeForm,
          moment(adjustedTime?.endTime).format("HH:mm"),
          date
        ),
      };
      timeClockData.push(newOBJ);
    }

    setIsRunning(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setStartStopCheck(false);
    setPause(false);
    const finalTimeClockData = timeClockData.filter((i) => !i.ID);
    localStorage.setItem("timeClockData", JSON.stringify(finalTimeClockData));

    localStorage.removeItem("timeClockStartHours");
  };

  useEffect(() => {
    let UpdatedCodes = paytimeCode?.map((i) => {
      if (["8", "110", "111", "112", "113", "114", "115"].includes(i.code)) {
        i.disabled = true;
      } else {
        i.disabled = false;
      }
      return i;
    });

    let paytime = paytimeCode?.filter((item) => {
      return ["10", "11", "110", "111", "112", "113", "114", "115"].includes(
        item.code
      );
    });

    const fiteterdArray = paytime?.filter((i) => {
      if (jobTypeOptions.find((d) => d.code == i.code)) {
        return i;
      }
    });

    setHJobTypeOptions(fiteterdArray);
  }, [paytimeCode, jobTypeOptions]);

  useEffect(() => {
    if (paytimeCode?.length && paytimeCodeByID?.length) {
      const filterPaycodeList = payCodeFilter(
        paytimeCode,
        paytimeCodeByID,
        userRole
      );

      setJobTypeOptions(filterPaycodeList);
    }
  }, [paytimeCode, paytimeCodeByID]);

  const getSelectedType = (jobtype) => {
    let filterObj = jobTypeOptions.find((i) => {
      return i.value + "" === jobtype + "";
    });

    return filterObj !== undefined
      ? filterObj
      : {
          value: "REG",
          label: "REG",
          title: "REGULAR",
          code: "1",
          multi: 1,
          EffectDate: "1990-01-01T00:00:00.000Z",
          ExpireDate: "2099-12-31T00:00:00.000Z",
          name: "jobtype",
        };
  };

  const splitTimeRange = (startTime, endTime, existingTime) => {
    const splitSlots = [{ start: startTime, end: endTime }];

    for (let i = 0; i < existingTime.length; i++) {
      const currentSlot = existingTime[i];
      const updatedSlots = [];

      for (let j = 0; j < splitSlots.length; j++) {
        const slot = splitSlots[j];

        if (
          currentSlot.fromTime >= slot.start &&
          currentSlot.toTime <= slot.end
        ) {
          updatedSlots.push({
            start: slot.start,
            end: currentSlot.fromTime,
            type: "NDIF",
          });
          updatedSlots.push({
            start: currentSlot.fromTime,
            end: currentSlot.toTime,
            type: "REG",
          });
          updatedSlots.push({
            start: currentSlot.toTime,
            end: slot.end,
            type: "NDIF",
          });
        } else if (
          currentSlot.fromTime < slot.end &&
          currentSlot.toTime > slot.start
        ) {
          if (slot.start < currentSlot.fromTime) {
            updatedSlots.push({
              start: slot.start,
              end: currentSlot.fromTime,
              type: "NDIF",
            });
          }
          if (slot.end > currentSlot.toTime) {
            updatedSlots.push({
              start: currentSlot.toTime,
              end: slot.end,
              type: "NDIF",
            });
          }
        } else {
          updatedSlots.push(slot);
        }
      }

      splitSlots.splice(0, splitSlots.length, ...updatedSlots);
    }

    return splitSlots;
  };
  const time_from = "18:20";
  const time_to = "19:00";
  const exipsting = [
    {
      shr: time_from,
      ehr: time_to,
      fromTime: "06:00",
      toTime: "18:00",
    },
  ];

  const confimClick = () => {
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIsRunning(false);
    setStartStopCheck(false);
    setOpen(false);
    localStorage.removeItem("timeClockStartHours");
  };
  const getData = (userId) => {
    const date = moment().format("YYYY-MM-DD");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee/timelog/${userId}/from/${date}/to/${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        let filteredEvent = response.data;

        let ApiData = filteredEvent.filter(
          (i) =>
            moment(i.WorkDate).format("YYYY-MM-DD") ==
            moment().format("YYYY-MM-DD")
        );

        ApiData = ApiData.map((i) => {
          let wd = moment(i.WorkDate).utc().format("YYYY-MM-DD");
          let time_from = i.WorkFrom
            ? moment(i.WorkFrom).format("HH:mm:ss")
            : null;
          let time_to = i.WorkTo ? moment(i.WorkTo).format("HH:mm:ss") : null;
          let t1 = hoursDiffrence(time_from, time_to);
          let hrCalc = HourMinFormat(
            `${Math.floor(t1.totalMin / 60)}:${t1.totalMin % 60}`
          );

          return {
            date: wd,
            time_from: time_from,
            time_to: time_to,
            paycode: i?.PayCode_PayTimeCode?.Abberv,
            code: i?.PayCode_PayTimeCode?.ID,
            // startTime:moment(`${wd}${time_from}`,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            // endTime:moment(`${wd}${time_to}`,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            startTime: moment(`${wd}${time_from}`, "YYYY-MM-DDTHH:mm:ss")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss"),
            endTime: moment(`${wd}${time_to}`, "YYYY-MM-DDTHH:mm:ss")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss"),
            ID: i.ID,
            totalMins: hrCalc,
            Auth: i.Auth,
          };
        });
        const existingDataSaved = localStorage.getItem("timeClockData");

        if (existingDataSaved) {
          let existingData = JSON.parse(existingDataSaved);
          let datas = existingData.filter((i) => !i.ID);

          ApiData = [...ApiData].concat([...(datas ? datas : [])]);
        }
        setTImeClockData(ApiData);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const saveData = () => {
    let infoArray = [];

    timeClockData.map((item) => {
      if (item.time_from && item.time_to) {
        let infoPick = {
          WorkDate: item.date,
          PayCode: item.code,
          WorkFrom: item.time_from,
          WorkTo: item.time_to,
          JobOrder: "1000000",
          Typh: item.paycode,
          // RecordNo: 0,
          RecordNo: "0",
          Status: "Confirm",
          Auth: item.Auth,
          ID: item.ID ? item.ID : null,
        };
        infoArray.push(infoPick);
      }
    });

    if (!infoArray.length) {
      return false;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
        { workingHours: infoArray, deletedList: [] },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        ShowSucess("Status Change Successfully!");
        getData(userId);
        localStorage.removeItem("timeClockData");
        //setDeletedList([])
      })
      .catch((error) => {
        // ShowError(error)
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <div>
      <TimeClockHead
        setPaycodes={setPaycodes}
        time={time}
        startStopCheck={startStopCheck}
        handleStart={handleStart}
        handleClickOpen={handleClickOpen}
        handleStop={handleStop}
        getSelectedType={getSelectedType}
        paycodes={paycodes}
        getValidPaycodeOfDay={getValidPaycodeOfDay}
        jobTypeOptions={jobTypeOptions}
      />
      <Card>
        {/* {JSON.stringify(timeClockData)} */}
        {/* <CardHeader className="pb-0">
        <div
          style={{
            height: "55px",
            border: " 1px solid #dee2e6",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="p-3">
            <h5>Time Clock</h5>
          </div>
         
        </div>
      </CardHeader> */}
        <CardBody>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center">Pay Type</TableCell> */}
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">From</TableCell>
                  <TableCell align="center">To</TableCell>
                  <TableCell align="center">Hours</TableCell>
                  {/* <TableCell align="center">Auth</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeClockData
                  ? timeClockData?.map((item) => {
                      return <TimeClockRows data={item} />;
                    })
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </CardBody>
        {open ? (
          <ConfirmModal
            open={open}
            setOpen={setOpen}
            confimClick={confimClick}
            message={
              "Your work time is less than 15 minutes so this time will not be save. Are You sure to stop timer ?"
            }
            modalHeader="Alert Message"
          />
        ) : (
          ""
        )}
        <div>
          <Button
            onClick={saveData}
            // onClick={ () => { childRef.current.childFunction() }}
            variant="contained"
            // disabled={check || hasError}
            className="m-4 bg-primary"
            style={{
              color: "#d0efe9",

              // backgroundColor: "#61bdaa",
            }}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{
                // color: "#24695c",
                marginRight: "10px",
              }}
              className="bg-primary"
            />
            <Hidden smDown>
              <Typography className="text-12">Save</Typography>
            </Hidden>
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default HeaderTimeClock;
