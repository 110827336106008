import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { useSelector } from "react-redux";

import { message, Button, Space } from "antd";
import { Log } from "oidc-client";
function convertingText(str) {
  var splitStr = str.toLowerCase().replace(/,/g, "").split("_");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1) + " ";
  }

  return splitStr;
}
const sliceName = "querySlice";
const settingColumns = (columns) => {
  let temp = [];

  columns.forEach((e, index) => {
    let col = {};
    // console.log('all data is ', e);
    switch (e) {
      case "docno":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Doc No",
          placeholder: "Enter Doc No Value",
        };
        break;

      case "prop_desc":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Parcel",
          placeholder: "Enter Parcel Value",
        };
        break;
      case "parcel_source_search":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Parcel",
          placeholder: "Enter Parcel Value",
        };
        break;

      case "estate_no":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Estate",
          placeholder: "Enter Estate",
        };
        break;
      case "grantor":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Grantor",
          placeholder: "Enter Grantor",
        };
        break;
      case "grantee":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Grantee",
          placeholder: "Enter Grantee",
        };
        break;
      case "ref_document":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Ref Docs",
          placeholder: "Enter Ref Docs",
        };
        break;
      case "survey_ref_doc":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Ref Survey",
          placeholder: "Enter Ref Survey",
        };
        break;

      case "recorded_date":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Rec Date",
          placeholder: "Enter Rec Date",
        };
        break;

      case "transaction_Value":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Document Type",
          placeholder: "Enter Doc Type",
        };
        break;

      case "mun":
        col = {
          name: "Municipality",
          value: e,
          short: "Municipality",
          placeholder: "Enter Municipality",
        };
        break;
      case "transaction_value":
        col = {
          name: "Documnet Type",
          value: e,
          short: "Documnet Type",
          placeholder: "Enter Doc Type",
        };
        break;
      case "certificate":
        col = {
          name: "Certificate of Title",
          value: e,
          short: "Certificate of Title",
          placeholder: "Enter Certificate of Title",
        };
        break;
      case "std_prop_desc":
        col = {
          name: "Prop Desc Universal",
          value: e,
          short: "Prop Desc Universal",
          placeholder: "Enter Prop Desc Universal",
        };
        break;
      case "dated":
        col = {
          name: "Signed Date",
          value: e,
          short: "Signed Date",
          placeholder: "Signed Date",
        };
        break;
      case "tax_no":
        col = {
          name: "Release No",
          value: e,
          short: "Release No",
          placeholder: "Release No",
        };
        break;
      case "lender_name":
        col = {
          name: "Bank/Lander Name",
          value: e,
          short: "Bank/Lander Name",
          placeholder: "Enter Bank/Lander Name",
        };
        break;
      case "sourcetype":
        col = {
          name: "Source type",
          value: e,
          short: "Source type",
          placeholder: "Enter source type",
        };
        break;
      case "execution_date":
        col = {
          name: "Document Date",
          value: e,
          short: "Document Date",
          placeholder: "Document Date",
        };
        break;

      default:
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: e,
          placeholder: `Enter ${convertingText(columns[index])} value`,
        };
        break;
    }
    if (
      ![
        "doc_record_id",
        "trash",
        "data_qa",
        "map_file",
        "property_id",
        "mortgage_lender",
      ].includes(col.value)
    ) {
      temp.push(col);
    }
  });
  return temp;
};
const settingColumns2 = (columns) => {
  let temp = [];

  columns.forEach((e, index) => {
    let col = {};
    // console.log('all data is ', e);
    switch (e) {
      case "docno":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Doc No",
        };
        break;
      case "dated":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Doc Signed Date",
          placeholder: "Doc Signed Date",
        };
        break;
      case "tax_no":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Release No",
          placeholder: "Release No",
        };
        break;
      case "parcel_source_search":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Parcel",
        };
        break;
      case "estate_no":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Estate",
        };
        break;
      case "grantor":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Grantor",
        };
        break;
      case "grantee":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Grantee",
        };
        break;
      case "ref_document":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Ref Docs",
        };
        break;
      case "survey_ref_doc":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Ref Survey",
        };
        break;
      case "certificate":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "CT’s",
        };
        break;
      case "lender_name":
        col = {
          name: "Bank/Lander Name",
          value: e,
          short: "Bank/Lander Name",
          placeholder: "Enter Bank/Lander Name",
        };
        break;
      case "recorded_date":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Rec Date",
        };
        break;

      case "transaction_Value":
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: "Doc Type",
        };
        break;

      default:
        col = {
          name: convertingText(columns[index]),
          value: e,
          short: e,
        };
        break;
    }
    temp.push(col);
  });
  return temp;
};

export const fetchCols = createAsyncThunk(
  `${sliceName}/fetchCols`,
  async (_, {}) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}search/employee/columns`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      let { columns } = response;
      console.log("response column", response.data);
      if (columns.length) {
        columns = columns.filter((i) => { return i.column_title != "" }); 
      }
      let temp = settingColumns(columns);
      temp.sort((a, b) =>
        a.short.toLowerCase().localeCompare(b.short.toLowerCase())
      );
      return {
        cols: temp,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const alpha_fetchCols = createAsyncThunk(
  `${sliceName}/alpha_fetchCols`,
  async (_, {}) => {
    try {
      const response = await axios.get(`/alpha_columns`);
      const { columns } = response.data;
      let temp = settingColumns(columns);
      temp.sort((a, b) =>
        a.short.toLowerCase().localeCompare(b.short.toLowerCase())
      );
      return {
        cols: temp,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);
export const alpha_fetchPlaceholderCols = createAsyncThunk(
  `${sliceName}/alpha_fetchPlaceholderCols`,
  async (_, {}) => {
    try {
      const response = await axios.get(`alpha_columnsPlaceholder`);
      let { columns } = response.data;
      return {
        colsPlaceholder: columns,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const fetchPlaceholderCols = createAsyncThunk(
  `${sliceName}/fetchPlaceholderCols`,
  async ( data) => {
    try {
      console.log('ftcColumn',data,'#####',data?.role);
      
      let url = `${process.env.REACT_APP_API_URL}advancesearch/v2/columns/EmployeeMaster`;
      if (data?.role=='supervisor') {
        url = `${process.env.REACT_APP_API_URL}advancesearch/v2/columns/vw_employee_supervisor`;
      }
      const response = await axios.get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      let columns = response.data;

      if (columns.length) {
        columns = columns.filter((i) => { return i.column_title != "" }); 
      }
      console.log("columns", columns);
      return {
        colsPlaceholder: columns,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);
export const saveQueries = createAsyncThunk(
  `${sliceName}/saveQueries`,
  async (uuid) => {
    try {
      const response = await axios.get(`/query/save`, {
        headers: {
          Authorization: `Bearer ${uuid}`,
        },
      });

      return {
        queries: response.data.result,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const changeQuery = createAsyncThunk(
  `${sliceName}/changeQuery`,
  async (resQuery) => {
    // console.log(resQuery);
    try {
      return {
        newQuery: resQuery,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const reRunFalseAction = createAsyncThunk(
  `${sliceName}/reRunFalseAction`,
  async () => {
    try {
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const selectDocsStore = createAsyncThunk(
  `${sliceName}/selectDocsStore`,
  async (newRows) => {
    // console.log('api callll ', newRows);
    try {
      return {
        docs: newRows,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const clearDocSelection = createAsyncThunk(
  `${sliceName}/clearDocSelection`,
  async (newRows) => {
    // console.log('api callll ', newRows);

    try {
      return {
        rows: newRows,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const callSearchRecord = createAsyncThunk(
  `${sliceName}/callSearchRecord`,
  async (newRows) => {
    // console.log('api callll ', newRows);

    try {
      return {
        rows: newRows,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const updateRecords = createAsyncThunk(
  `${sliceName}/updateRecords`,
  async (apiData) => {
    // console.log('api callll ', apiData);
    const { record, grantees, grantors, notes, uuid, list } = apiData;
    try {
      const response = await axios.post(
        `/update`,
        {
          "doc_record_id": record.doc_record_id,
          record: record,
          grantors: grantors,
          grantees: grantees,
          notes,
        },
        {
          headers: {
            Authorization: `Bearer ${uuid}`,
          },
        }
      );
      // console.log(response);
      const id = response.data.doc_id;

      const newResponse = await axios.post(`/record/by/id`, {
        id,
      });

      // console.log('new array is ', newResponse);
      var newList = list;
      var updated = [newResponse.data.result];

      updated[0].grantor = updated[0].grantor.toString();
      updated[0].grantee = updated[0].grantee.toString();

      // console.log('list is ', newList);
      // console.log('updated is ', updated);
      var res = newList.map(
        (obj) =>
          updated.find((o) => o.doc_record_id === obj.doc_record_id) || obj
      );
      alert("Record updated successfully");
      // message.success('Record updated successfully');
      return {
        data: res,
      };
    } catch (e) {
      message.error("Record not updated");
      return {
        error: e.message,
      };
    }
  }
);

export const updateRecordsImage = createAsyncThunk(
  `${sliceName}/updateRecordsImage`,
  async (apiData) => {
    // try {
    // 	let imageError = ""
    // 	const response = await axios
    // 		.post(`/record/image`, apiData.updateData);
    // 	if(response.data.result) {
    // 		var newList = apiData.list;
    // 		var updated = [response.data.result];
    // 		var res = newList.map(obj => updated.find(o => o.doc_record_id === obj.doc_record_id) || obj);
    // 	} else {
    // 		var res = apiData.list;
    // 		imageError = response.data?.message;
    // 		console.log(imageError); debugger;
    // 	}
    // 	return {
    // 		data: res,
    // 		error: imageError ?? ""
    // 	};
    // } catch (e) {
    // 	return {
    // 		error: e.message
    // 	};
    // }
    var newList = apiData.list;
    var updated = [apiData.resData];
    var res = newList.map(
      (obj) => updated.find((o) => o.doc_record_id === obj.doc_record_id) || obj
    );
    return {
      data: res,
    };
  }
);

export const refreshQuery = createAsyncThunk(
  `${sliceName}/refreshQuery`,
  async () => {
    const data = [
      {
        value: "",
        operator: "=",
        column: "",
        condition: "AND",
      },
    ];
    try {
      return {
        newQuery: data,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const callEmptySearch = createAsyncThunk(
  `${sliceName}/callEmptySearch`,
  async () => {
    try {
      return {
        rows: [],
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const deleteRecordStore = createAsyncThunk(
  `${sliceName}/deleteRecordStore`,
  async (deleteData) => {
    try {
      var list = deleteData.newRows;
      var id = deleteData.docRecordId;
      var newList = list.filter((data) => data.doc_record_id !== id);
      return {
        rows: newList,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const userGroupStore = createAsyncThunk(
  `${sliceName}/userGroupStore`,
  async (uuid) => {
    console.log("CALL groups store");
    try {
      const response = await axios.get(
        // `https://my.pinpointguam.com/api/user/groups`,
        `${process.env.REACT_APP_API_URL}search/group`,

        // {
        //   headers: {
        //     Authorization: "Bearer " + uuid,
        //   },
        // }
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      const groups = response.data;
      console.log("groups ", response.data);
      return {
        groups: groups,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const savedQueries = createAsyncThunk(
  `${sliceName}/savedQueries`,
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2/searchquery`,
        {
          tableName: payload.tableName,
        },
        // `${process.env.REACT_APP_API_URL}query/userquery`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      // const userSavedQueries = response.data.result;
      // const groupSavedQueries = response.data.group;
      console.log("response save", response.data);
      const userSavedQueries = response.data;
      return {
        userSavedQueries: userSavedQueries.length ? userSavedQueries : [],
        groupSavedQueries: [],
      };
    } catch (e) {
    
      console.log(e.message);
    }
  }
);

export const deleteSavedQueriesRecordStore = createAsyncThunk(
  `${sliceName}/deleteSavedQueriesRecordStore`,
  async (deleteData) => {
    try {
      // console.log("Query data to update", deleteData);

      var list = deleteData.data;
      var id = deleteData.id;
      var newList = list.filter((data) => data.query_id !== id);
      return {
        userSavedQueries: newList,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const fetchSearchColumns = createAsyncThunk(
  `${sliceName}/fetchSearchColumns`,
  async (obj) => {
    try {
      const response = await axios.get(
        // `https://my.pinpointguam.com/api/user/groups`,sp_findIssueForDepartment
        `${process.env.REACT_APP_API_URL}advancesearch/v2/columns/${obj.tableName}`,

        // {
        //   headers: {
        //     Authorization: "Bearer " + uuid,
        //   },
        // }
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      const searchColumns = response.data;
      console.log("searchColumns ", response.data);
      return {
        searchColumns: searchColumns,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const resetImageUploadError = createAsyncThunk(
  `${sliceName}/resetImageUploadError`,
  () => {
    return {
      imageError: "",
    };
  }
);
const querySlice = createSlice({
  name: sliceName,
  initialState: {
    cols: [],
    alpha_cols: [],
    // queries: [],
    selectdocs: [],
    error: "",
    queryofRun: "",
    recordList: "",
    rows: [],
    loading: false,
    total: 0,
    searchClicked: false,
    selectColumns: [],
    searchColumns: [],
    queries: [
      {
        value: "",
        operator: "=",
        column: "",
        condition: "AND",
      },
    ],
    initialValues: {
      query_name: "",
      user_group_id: null,
      queries: [
        {
          value: "",
          operator: "=",
          column: "",
          condition: "AND",
        },
      ],
    },
    groups: [],
    reRun: false,
    userSavedQueries: [],
    groupSavedQueries: [],
    imageError: "",
  },
  extraReducers: {
    [fetchCols.fulfilled]: (state, action) => {
      state.cols = action?.payload?.cols || [];
    },
    [fetchPlaceholderCols.fulfilled]: (state, action) => {
      console.log("action", action);
      state.colsPlaceholder = action?.payload?.colsPlaceholder || [];
    },

    [alpha_fetchCols.fulfilled]: (state, action) => {
      state.cols = action?.payload?.cols || [];
    },
    [fetchSearchColumns.fulfilled]: (state, action) => {
      state.searchColumns = action?.payload?.searchColumns || [];
    },
    [alpha_fetchPlaceholderCols.fulfilled]: (state, action) => {
      state.colsPlaceholder = action?.payload?.colsPlaceholder || [];
    },

    [saveQueries.fulfilled]: (state, action) => {
      state.queries = action?.payload?.queries || [];
    },
    [saveQueries.rejected]: (state, action) => {
      state.error = action.payload.error;
    },

    [changeQuery.fulfilled]: (state, action) => {
      state.initialValues.queries = action.payload.newQuery;
      state.queries = action.payload.newQuery;
      state.reRun = true;
    },
    [refreshQuery.fulfilled]: (state, action) => {
      state.initialValues.queries = action.payload.newQuery;
    },
    [reRunFalseAction.fulfilled]: (state, action) => {
      state.reRun = false;
    },
    [callSearchRecord.fulfilled]: (state, action) => {
      state.rows = action.payload.rows;
    },

    [selectDocsStore.fulfilled]: (state, action) => {
      let packages = state.selectdocs;
      let payload = action.payload.docs;
      let recordId = payload.recordId;
      let docId = payload.docId;
      let propId = payload.propId;

      let isActive = packages.find((pack) => pack.recordId == recordId)
        ? true
        : false;
      if (!isActive) {
        packages.push({ recordId: recordId, docId: docId, propId: propId });
      } else {
        packages = packages.filter((i) => i.recordId != recordId);

        console.log("Remove", packages);
      }

      state.selectdocs = packages;
    },
    [clearDocSelection.fulfilled]: (state, action) => {
      state.selectdocs = [];
    },
    [callSearchRecord.rejected]: (state, action) => {
      state.error = action.payload.error;
    },
    [updateRecords.fulfilled]: (state, action) => {
      state.rows = action.payload.data;
    },
    [updateRecords.rejected]: (state, action) => {
      state.error = action.payload.error;
    },
    [callEmptySearch.fulfilled]: (state, action) => {
      state.rows = action.payload.rows;
    },
    [callEmptySearch.rejected]: (state, action) => {
      state.error = action.payload.error;
    },
    [deleteRecordStore.fulfilled]: (state, action) => {
      state.rows = action.payload.rows;
    },
    [userGroupStore.fulfilled]: (state, action) => {
      state.groups = action.payload?.groups;
    },
    [savedQueries.fulfilled]: (state, action) => {
      state.userSavedQueries = action.payload.userSavedQueries;
      state.groupSavedQueries = action.payload.groupSavedQueries;
    },
    [deleteSavedQueriesRecordStore.fulfilled]: (state, action) => {
      state.userSavedQueries = action.payload.userSavedQueries;
    },
    [updateRecordsImage.fulfilled]: (state, action) => {
      state.rows = action.payload.data;
    },
    [resetImageUploadError.fulfilled]: (state, action) => {
      state.imageError = action.payload.error;
    },
  },
});

export default querySlice.reducer;
