import { makeStyles } from "@material-ui/core/styles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import Header from "./header";
import AddForm from "./addForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { getAllArrearsBaseList, getAllDeductionPlanMaster, getAllDeductionMethodList, getAllGarnishmentType, getAllPaymentType, getDeductionType, getVendor, getAllDeductionPlanStatusList } from "../../../app/utility/axiosService";
import DataTable from "./data-table";
import { Card } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const DeductionPlanMaster = (props) => {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    id: null,
    deductionType: '',
    deductionCode: '',
    deductionName: '',
    startDate: null,
    endDate: null,
    paymentMonthlySch: '',
    taxable: false,
    deductionMethod: '',
    deductionValue: '',
    totalAmountPlanDue: '',
    totalAmountPaid: '',
    accountNoForVendor: '',
    deductionPlanStatus: '',
    isActive: false,
    applysToOT: '',
    arrearsBase: '',
  });

  const [deductionTypes, setDeductionTypes] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [garnishmentTypes, setGarnishmentTypes] = useState([]);
  const [garnishmentMethods, setGarnishmentMethods] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [deductionPlanStatusList, setDeductionPlanStatusList] = useState([]);
  const [arrearsBaseList, setArrearsBaseList] = useState([]);

  const [expand, setExpand] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const getData = async () => {
    const res = await getAllDeductionPlanMaster();
    if (res?.status === 200) setTableList(res?.data);
  };

  useEffect(() => {
    getData();
    fetchAllDeductionTypes();
    fetchAllGarnishmentTypes();
    fetchAllGarnishmentMethod();
    fetchAllPaymentOption();
    fetchAllArrearsBaseList();
    fetchAllDeductionPlanStatusList();
  }, []);

  const fetchAllDeductionTypes = async () => {
    const res = await getDeductionType();
    if (res?.length > 0) {
      let temp = [];
      res?.forEach(o => {
        if (o?.Code === 'GARNISHMENT' || o?.Code === 'BENEFITS' || o?.Code === 'RETIREMENT-PLANS' || o?.Code === 'MISCELLANEOUS') {
          temp.push(o);
        }
      });
      setDeductionTypes(temp);
    }
  };

  const fetchAllGarnishmentTypes = async () => {
    const res = await getAllGarnishmentType();
    if (res?.status === 200) setGarnishmentTypes(res.data);
  };

  const fetchAllGarnishmentMethod = async () => {
    const res = await getAllDeductionMethodList();
    if (res?.length > 0) setGarnishmentMethods(res);
  };

  const fetchAllDeductionPlanStatusList = async () => {
    const res = await getAllDeductionPlanStatusList();
    if (res?.length > 0) setDeductionPlanStatusList(res);
  };

  const fetchAllPaymentOption = async () => {
    const res = await getAllPaymentType();
    if (res?.status === 200) setPaymentOptions(res.data);
  };

  const fetchAllArrearsBaseList = async () => {
    const res = await getAllArrearsBaseList();
    if (res?.length > 0) setArrearsBaseList(res);
  };

  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <Header
        {...props}
        detail={detail}
        getData={getData}
        setDetail={setDetail}
      />
      </div>
      {/* <div
        classes={{
          root: "w-100 h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      > */}
        <div className={`${classes.root} w-100 page-content`}>
          <Card className="mt-3 mb-3">
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200 sub-header"
                style={{ backgroundColor: "rgba(36, 105, 92, 0.1)" }}
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Deduction Plan Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <AddForm
                  detail={detail}
                  setDetail={setDetail}
                  deductionTypes={deductionTypes}
                  deductionPlanStatusList={deductionPlanStatusList}
                  garnishmentTypes={garnishmentTypes}
                  garnishmentMethods={garnishmentMethods}
                  paymentOptions={paymentOptions}
                  arrearsBaseList={arrearsBaseList}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card className="mt-3 mb-3">
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="btn-primary-light"
                  style={{
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Deduction Plan List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 page-body-wrapper">
                <DataTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  filterBy={detail}
                  deductionTypes={deductionTypes}
                  deductionPlanStatusList={deductionPlanStatusList}
                  garnishmentTypes={garnishmentTypes}
                  garnishmentMethods={garnishmentMethods}
                  paymentOptions={paymentOptions}
                  arrearsBaseList={arrearsBaseList}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        {/* </div> */}
      </div>
      </Container>
    </>
  );
};

export default DeductionPlanMaster;
