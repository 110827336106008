import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "masterSlice";

export const fetchQueryIssuesSearch = createAsyncThunk(
  `${sliceName}/fetchQueryIssuesSearch`,
  async (payload) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        queryIssuesSearch: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        queryIssuesSearch: [],
      };
    }
  }
);

export const fetchQueryLabourCost = createAsyncThunk(
  `${sliceName}/fetchQueryLabourCost`,

  async (payload) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        queryLaborCostData: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        queryLaborCostData: [],
      };
    }
  }
);

export const queryDeductionByVendor = createAsyncThunk(
  `${sliceName}/queryDeductionByVendor`,
  async (payload) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        queryDeductionByVendor: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        queryDeductionByVendor: [],
      };
    }
  }
);

export const fetchDeltaEmployeeEarning = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeeEarning`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-earning-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeEarning: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeEarning: [],
      };
    }
  }
);

export const fetchDeltaEmployeeEmpDeduction = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeeEmpDeduction`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-emp-deduction-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeEmpDeduction: data.data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        deltaEmployeeEmpDeduction: [],
      };
    }
  }
);

export const fetchDeltaEmployeeGovDeduction = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeeGovDeduction`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-gov-deduction-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployeeGovDeduction: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployeeGovDeduction: [],
      };
    }
  }
);

export const fetchDeltaEmployeePE = createAsyncThunk(
  `${sliceName}/fetchDeltaEmployeePE`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/delta-employee-count-payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deltaEmployee: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        deltaEmployee: [],
      };
    }
  }
);

const chartSlice = createSlice({
  name: sliceName,
  initialState: {
    deltaEmployee: [],
    deltaEmployeeEarning: [],
    deltaEmployeeGovDeduction: [],
    deltaEmployeeEmpDeduction: [],
    queryIssuesSearch: [],
    queryLaborCostData: [],
    queryDeductionByVendor: [],
  },
  extraReducers: {
    [fetchQueryIssuesSearch.fulfilled]: (state, action) => {
      state.queryIssuesSearch = action.payload.queryIssuesSearch;
    },
    [fetchQueryLabourCost.fulfilled]: (state, action) => {
      state.queryLaborCostData = action.payload.queryLaborCostData;
    },
    [queryDeductionByVendor.fulfilled]: (state, action) => {
      state.queryDeductionByVendor = action.payload.queryDeductionByVendor;
    },
    [fetchDeltaEmployeePE.fulfilled]: (state, action) => {
      state.deltaEmployee = action.payload.deltaEmployee;
    },
    [fetchDeltaEmployeeEarning.fulfilled]: (state, action) => {
      state.deltaEmployeeEarning = action.payload.deltaEmployeeEarning;
    },
    [fetchDeltaEmployeeGovDeduction.fulfilled]: (state, action) => {
      state.deltaEmployeeGovDeduction =
        action.payload.deltaEmployeeGovDeduction;
    },
    [fetchDeltaEmployeeEmpDeduction.fulfilled]: (state, action) => {
      state.deltaEmployeeEmpDeduction =
        action.payload.deltaEmployeeEmpDeduction;
    },
  },
});

export default chartSlice.reducer;
