import React, { useEffect, useState } from "react";

// import TextField from "@material-ui/core/TextField";
// import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Grid, Typography } from "@material-ui/core";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import MaskedInput from "react-text-mask";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";

import HighchartsReact from "highcharts-react-official";
// import { async } from "regenerator-runtime";
import { ShimmerThumbnail } from "react-shimmer-effects";

HighchartsSankey(Highcharts);

function SankeyEmployee(props) {
  let [loading, setLoading] = useState(true);
  let [sankeyData, setSankeyData] = useState();
  let sankeydataLocal = {};
  const barChart = async () => {
    let chartData = [
        [
          "08-14-2022-08-27-2022",
          "HAZARD 10%",
          14
        ],
        [
          "HAZARD 10%",
          "08-14-2022-08-27-2022",
          14
        ],
        [
          "08-14-2022-08-27-2022",
          "Night Differincial",
          17
        ],
        [
          "Night Differincial",
          "08-14-2022-08-27-2022",
          17
        ],
        [
          "08-14-2022-08-27-2022",
          "REGULAR",
          1430
        ],
        [
          "REGULAR",
          "08-14-2022-08-27-2022",
          1430
        ]
    ];
    

    chartData = [
      ["Office of Technology", "Regular", 3],
      ["Bereau of Women's Affairs", "Regular", 3],
      ["Department of Administration", "Regular", 12],
      ["Department of Agriculture", "Regular", 34],
      ["Department of Chamorro Affairs", "Regular", 3],
      ["Department of Corrections", "Regular", 5],
      ["Department of Parks & Rec", "Regular", 6],
      ["Department of Land Management", "Regular", 44],
      ["Department of Labor", "Regular", 3],
      ["Office of Technology", "Overtime", 6],
      ["Bereau of Women's Affairs", "Overtime", 6],
      ["Department of Administration", "Overtime", 6],
      ["Department of Agriculture", "Overtime", 2],
      ["Department of Chamorro Affairs", "Overtime", 26],
      ["Department of Corrections", "Overtime", 36],
      ["Department of Parks & Rec", "Overtime", 5],
      ["Department of Land Management", "Overtime", 6],
      ["Department of Labor", "Overtime", 6],
      ["Office of Technology", "Night Differential", 19],
      ["Bereau of Women's Affairs", "Night Differential", 1],
      ["Department of Administration", "Night Differential", 6],
      ["Department of Agriculture", "Night Differential", 7],
      ["Department of Chamorro Affairs", "Night Differential", 34],
      ["Department of Corrections", "Night Differential", 4],
      ["Department of Parks & Rec", "Night Differential", 5],
      ["Department of Land Management", "Night Differential", 3],
      ["Department of Labor", "Night Differential", 4],
    ]

      // chartData=[
      //       ['Brazil', 'Portugal', 5],
      //       ['Brazil', 'France', 1],
      //       ['Brazil', 'Spain', 1],
      //       ['Brazil', 'England', 1],
      //       ['Canada', 'Portugal', 1],
      //       ['Canada', 'France', 5],
      //       ['Canada', 'England', 1],
      //       ['Mexico', 'Portugal', 1],
      //       ['Mexico', 'France', 1],
      //       ['Mexico', 'Spain', 5],
      //       ['Mexico', 'England', 1],
      //       ['USA', 'Portugal', 1],
      //       ['USA', 'France', 1],
      //       ['USA', 'Spain', 1],
      //       ['USA', 'England', 5],
      //       ['Portugal', 'Angola', 2],
      //       ['Portugal', 'Senegal', 1],
      //       ['Portugal', 'Morocco', 1],
      //       ['Portugal', 'South Africa', 3],
      //       ['France', 'Angola', 1],
      //       ['France', 'Senegal', 3],
      //       ['France', 'Mali', 3],
      //       ['France', 'Morocco', 3],
      //       ['France', 'South Africa', 1],
      //       ['Spain', 'Senegal', 1],
      //       ['Spain', 'Morocco', 3],
      //       ['Spain', 'South Africa', 1],
      //       ['England', 'Angola', 1],
      //       ['England', 'Senegal', 1],
      //       ['England', 'Morocco', 2],
      //       ['England', 'South Africa', 7],
      //       ['South Africa', 'China', 5],
      //       ['South Africa', 'India', 1],
      //       ['South Africa', 'Japan', 3],
      //       ['Angola', 'China', 5],
      //       ['Angola', 'India', 1],
      //       ['Angola', 'Japan', 3],
      //       ['Senegal', 'China', 5],
      //       ['Senegal', 'India', 1],
      //       ['Senegal', 'Japan', 3],
      //       ['Mali', 'China', 5],
      //       ['Mali', 'India', 1],
      //       ['Mali', 'Japan', 3],
      //       ['Morocco', 'China', 5],
      //       ['Morocco', 'India', 1],
      //       ['Morocco', 'Japan', 3]]

    // chartData = await getSankeyEmployee();
    let colors = [
      "#003f5c",
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600",
    ];

      sankeydataLocal = {
        
          sankey: {
            nodes: { 
                template: {
                    stroke : '#ff0000'
                }
              },
              links: {
                  fill:"#ff0000"
               } 
        }
        ,          
      title: {
        text: "Sankey Goverment Contribution by Department and Employee Type 2",
        },
      accessibility: {
          point: {
          valueDescriptionFormat:
            "{index}. {point.from} to {point.to}, {point.weight}.",
        },
      },
      series: [
        {
          keys: ["from", "to", "weight"],
          data: chartData,
          type: "sankey",
          name: "Sankey Daigram",
        },
      ],
    };

    setSankeyData(sankeydataLocal);
    setLoading(false);
  };
  useEffect(() => {
    barChart();
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: "20px" }}>
          <ShimmerThumbnail height={250} />{" "}
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={sankeyData} />
      ) }
     </div>
    
  );
}

export default SankeyEmployee;
