import {
  Autocomplete,
  Button,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import TimeClockRows from "./TimeClockRows";
import axios from "axios";
import {
  HourMinFormat,
  ShowSucess,
  checkEnterTimeLate,
  getHMFromTime,
  getUserRole,
  getValidPaycodeOfDay,
  hoursDiffrence,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../app/utility/ConfirmModal";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../app/store/reducers/slices/notificationSlice";
import CustomizerContext from "../../../_helper/Customizer";

const array = [
  {
    ID: "77",
    From: "1970-01-01T08:00:00.000Z",
    To: "1970-01-01T12:00:00.000Z",
    TimeSheetID: "1",
    Day: "Tuesday",
  },
  {
    ID: "78",
    From: "1970-01-01T07:00:00.000Z",
    To: "1970-01-01T17:00:00.000Z",
    TimeSheetID: "1",
    Day: "Monday",
  },
  {
    ID: "79",
    From: "1970-01-01T13:00:00.000Z",
    To: "1970-01-01T17:00:00.000Z",
    TimeSheetID: "1",
    Day: "Tuesday",
  },
  {
    ID: "80",
    From: "1970-01-01T08:00:00.000Z",
    To: "1970-01-01T12:00:00.000Z",
    TimeSheetID: "1",
    Day: "Wednesday",
  },
  {
    ID: "81",
    From: "1970-01-01T08:00:00.000Z",
    To: "1970-01-01T12:00:00.000Z",
    TimeSheetID: "1",
    Day: "Thursday",
  },
  {
    ID: "82",
    From: "1970-01-01T08:00:00.000Z",
    To: "1970-01-01T12:00:00.000Z",
    TimeSheetID: "1",
    Day: "Friday",
  },
  {
    ID: "83",
    From: "1970-01-01T13:00:00.000Z",
    To: "1970-01-01T17:00:00.000Z",
    TimeSheetID: "1",
    Day: "Wednesday",
  },
];

const TimeClock = forwardRef((props, ref) => {
  const {
    userId,
    paytimeCode,
    paytimeCodeByID,
    loadPreData,
    filteredEvent,
    rowAuthData,
    payPeriod,
    templateData,
    userInfo,
  } = props;
  const { timeClock, setTimeClock } = useContext(CustomizerContext);

  const [isRunning, setIsRunning] = useState(false);
  const [pause, setPause] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const dispatch = useDispatch();
  const [startStopCheck, setStartStopCheck] = useState(false);
  const [timeClockData, setTImeClockData] = useState([]);
  const [paycodes, setPaycodes] = useState({ value: "1", label: "REG" });
  const { userData } = useAuth();
  const userRole = getUserRole(userData);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const split12TimeRange = (startTime, endTime, date) => {
    if (!startTime && !endTime && !date) {
      return false;
    }
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);
    const newDate = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");

    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const timeRanges = [];

    if (endDate < startDate && endTime !== "00:00") {
      const midnight = new Date(startDate);
      midnight.setHours(24, 0);

      timeRanges.push({
        date: date,
        startTime: startTime,
        endTime: midnight.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      });

      timeRanges.push({
        startTime: "00:00",
        endTime: endTime,
        date: newDate,
      });
    }
    // else {
    //   timeRanges.push({
    //     // date: date,
    //     startTime: startTime,
    //     endTime: endTime
    //   });
    // }

    return timeRanges;
  };
  useEffect(() => {
    if (filteredEvent.length) {
      console.log("DSadasdasf adsf", filteredEvent);

      let ApiData = filteredEvent.filter(
        (i) =>
          moment(i.WorkDate).utc().format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
      );
      ApiData = ApiData.map((i) => {
        let wd = moment(i.WorkDate).utc().format("YYYY-MM-DD");
        let time_from = i.WorkFrom
          ? moment(i.WorkFrom).format("HH:mm:ss")
          : null;
        let time_to = i.WorkTo ? moment(i.WorkTo).format("HH:mm:ss") : null;
        let t1 = hoursDiffrence(time_from, time_to);
        let hrCalc = HourMinFormat(
          `${Math.floor(t1.totalMin / 60)}:${t1.totalMin % 60}`
        );
        return {
          date: wd,
          time_from: time_from,
          time_to: time_to,

          paycode: i?.PayCode_PayTimeCode?.Abberv,
          code: i?.PayCode_PayTimeCode?.ID,
          // startTime:moment(`${wd}${time_from}`,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
          // endTime:moment(`${wd}${time_to}`,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
          startTime: moment(`${wd}${time_from}`, "YYYY-MM-DDTHH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          endTime: moment(`${wd}${time_to}`, "YYYY-MM-DDTHH:mm:ss")
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss"),
          ID: i.ID,
          totalMins: hrCalc,
          Auth: i.Auth,
          isLate: i.isLate,
        };
      });
      const existingDataSaved = localStorage.getItem("timeClockData");
      if (existingDataSaved) {
        let existingData = JSON.parse(existingDataSaved);
        let datas = existingData.filter((i) => !i.ID);

        ApiData = [...ApiData].concat([...(datas ? datas : [])]);
      }
      console.log("DSadasdasf", ApiData);
      setTImeClockData(ApiData);
    }
  }, [filteredEvent.length]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const seconds = prevTime.seconds + 1;
          const minutes = prevTime.minutes + Math.floor(seconds / 60);
          const hours = prevTime.hours + Math.floor(minutes / 60);

          return {
            hours: hours,
            minutes: minutes % 60,
            seconds: seconds % 60,
          };
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  function getCurrentTime() {
    const now = new Date();
    return {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
      date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      isRunning: true,
      isPause: false,
    };
  }

  function getCurrentTimeRemove() {
    return {
      isRunning: true,
    };
  }

  const checkAuth = (sd, ed, cDate) => {
    let isauth = "pending";
    // let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
    let shr = sd;
    let ehr = ed;

    let tData = rowAuthData.filter((i) => {
      return i.date == cDate;
    });

    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.time_from, "HH:mm:ss").format("HH:mm");
      let toTime = moment(i.time_to, "HH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };

  const CalculateWorkingHR = (st, et, date) => {
    const startTime = moment(`${date} ${st}`, "YYYY-MM-DD HH:mm");
    const endTime = moment(
      `${moment().format("YYYY-MM-DD")} ${et}`,
      "YYYY-MM-DD HH:mm"
    );
    let duration = moment.duration(endTime.diff(startTime));

    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    let hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);

    return hrCalc;
  };

  const calculateTimeDifference = (existingTime, endDateTime) => {
    const finalExistingDate = existingTime.date;
    const start = new Date(finalExistingDate);
    const end = new Date(endDateTime);

    const timeDiff = end.getTime() - start.getTime();
    const seconds = Math.floor(timeDiff / 1000) % 60;
    const minutes = Math.floor(timeDiff / (1000 * 60)) % 60;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));

    return {
      hours,
      minutes,
      seconds,
      date: existingTime?.date,
      isRunning: existingTime.isRunning,
      isPause: existingTime?.isPause,
      isResume: existingTime?.isResume,
    };
  };

  const existingTimeSaved = localStorage.getItem("timeClockStartHours");

  useEffect(() => {
    if (existingTimeSaved) {
      const newTime = calculateTimeDifference(
        JSON.parse(existingTimeSaved),
        getCurrentTime()?.date
      );

      let isPauseData = JSON.parse(existingTimeSaved);

      if (isPauseData?.isPause || isPauseData?.isResume) {
        setTime(isPauseData);
        setIsRunning(isPauseData.isRunning);
        setPause(isPauseData?.isPause);
        setStartStopCheck(true);
      } else {
        setTime(newTime);
        setIsRunning(newTime?.isRunning);
        setPause(newTime?.isPause);
        setStartStopCheck(true);
      }
    }
  }, [existingTimeSaved]);

  // const existingDataSaved = localStorage.getItem("timeClockData");
  //   useEffect(() => {
  //     if(existingDataSaved?.length){
  //       setTImeClockData([...timeClockData,JSON.parse(existingDataSaved)])
  //     }
  //   },[existingDataSaved?.length])

  const handleStart = () => {
    setTimeClock(!timeClock);
    setIsRunning(true);

    localStorage.setItem(
      "timeClockStartHours",
      JSON.stringify(getCurrentTime())
    );
    setStartStopCheck(true);
  };

  const handlePause = () => {
    let newOBj = {
      hours: time.hours,
      minutes: time.minutes,
      seconds: time.seconds,
      date: JSON.parse(existingTimeSaved)?.date,
      isRunning: false,
      isPause: true,
    };

    localStorage.setItem("timeClockStartHours", JSON.stringify(newOBj));
    setPause(true);
    setIsRunning(false);
  };
  const handleResume = () => {
    let newOBj = {
      hours: time.hours,
      minutes: time.minutes,
      seconds: time.seconds,
      date: JSON.parse(existingTimeSaved)?.date,
      isRunning: true,
      isPause: false,
      isResume: true,
    };

    localStorage.setItem("timeClockStartHours", JSON.stringify(newOBj));
    setPause(false);
    setIsRunning(true);
  };

  const adjustTime = (startTime, endTime) => {
    const start = new Date(startTime);
    const minutes = start.getMinutes();
    const startofEnd = new Date(endTime);
    const minutesofEnd = startofEnd.getMinutes();
    let adjustedStartTime;
    let adjustedEndTime;

    if (minutes >= 0 && minutes <= 15) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        0,
        0
      );
    } else if (minutes >= 16 && minutes <= 30) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        30,
        0
      );
    } else if (minutes >= 31 && minutes <= 45) {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours(),
        45,
        0
      );
    } else {
      adjustedStartTime = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        start.getHours() + 1,
        0,
        0
      );
    }

    if (minutesofEnd >= 0 && minutesofEnd <= 15) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        0,
        0
      );
    } else if (minutesofEnd >= 16 && minutesofEnd <= 30) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        30,
        0
      );
    } else if (minutesofEnd >= 31 && minutesofEnd <= 45) {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours(),
        45,
        0
      );
    } else {
      adjustedEndTime = new Date(
        startofEnd.getFullYear(),
        startofEnd.getMonth(),
        startofEnd.getDate(),
        startofEnd.getHours() + 1,
        0,
        0
      );
    }

    let eMin = Math.round(minutesofEnd / 5) * 5;
    let useRounded = false;
    let finalEndTime = useRounded
      ? moment(startofEnd.setMinutes(eMin)).format("YYYY-MM-DDTHH:mm:ss")
      : moment(adjustedEndTime).format("YYYY-MM-DDTHH:mm:ss");
    return {
      startTime: moment(adjustedStartTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: finalEndTime,

      // endTime: ,
    };
  };

  const splitTime = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    if (end.getHours() < 12) {
      return { startDateTime, endDateTime };
    } else {
      const splitEnd = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate(),
        23,
        59,
        59
      );
      const nextDayStart = new Date(splitEnd.getTime() + 1000); // Add 1 second to avoid the same endDateTime
      const firstInterval = {
        startDateTime: nextDayStart,
        endDateTime: splitEnd.toISOString(),
      };
      const secondInterval = {
        startDateTime: nextDayStart.toISOString(),
        endDateTime,
      };
      return { nextDayStart, splitEnd };
    }
  };
  console.log("dsajhfsajklfhasflasf", templateData);

  const handleStop = () => {
    const savedLocal = localStorage.getItem("timeClockStartHours");
    const savedLocalData = JSON.parse(savedLocal);
    const totalMins = calculateTimeDifference(savedLocalData, getCurrentTime());

    const adjustedTime = adjustTime(
      savedLocalData.date,
      moment().format("YYYY-MM-DDTHH:mm:ss")
    );
    const timeForm = moment(savedLocalData.date).format("HH:mm");
    const date = moment(savedLocalData?.date).format("YYYY-MM-DD");
    const time_from = moment(adjustedTime?.startTime).format("HH:mm");
    const time_to = moment(adjustedTime?.endTime).format("HH:mm");
    const time_toFormat = moment(adjustedTime?.endTime).format("HH:mm");

    const splitafter12Timerange = split12TimeRange(time_from, time_to, date);

    console.log("asdadadadadad", splitafter12Timerange);

    const exipsting = [
      {
        shr: time_from,
        ehr: time_to,
        fromTime: "06:00",
        toTime: "18:00",
      },
    ];
    const result = splitTimeRange(time_from, time_to, exipsting);
    console.log("ckasajklajfas", result);
    let newData = [];
    let AuthCheck = checkAuth(timeForm, time_to, date);
    const lateFlag = checkEnterTimeLate(
      templateData,
      time_from,
      moment(date).format("dddd")
    );
    let newOBJ = {
      date: date,
      time_from: timeForm,
      startTime: moment(adjustedTime?.startTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
      time_to: time_to,
      code: paycodes.value,
      paycode: paycodes.label,
      Auth: AuthCheck.status === "confirm" ? "A" : "U",
      isLate: lateFlag,
      totalMins: CalculateWorkingHR(
        timeForm,
        moment(adjustedTime?.endTime).format("HH:mm"),
        date
      ),
      ActualFromTime: savedLocalData.date,
      ActualToTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    if (splitafter12Timerange?.length) {
      splitafter12Timerange?.map((item) => {
        let AuthCheck = checkAuth(item.startTime, item.endTime, item.date);
        const lateFlag = checkEnterTimeLate(
          templateData,
          item.time_from,
          moment(item.date).format("dddd")
        );
        let datas = {
          date: item.date,
          time_from: item.startTime,
          time_to: item.endTime,
          code: null,
          startTime: moment(item.startTime, "HH:mm").format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endTime: moment(item.endTime, "HH:mm").format("YYYY-MM-DDTHH:mm:ss"),
          paycode: null,
          Auth: AuthCheck.status === "confirm" ? "A" : "U",
          isLate: lateFlag,
          totalMins: CalculateWorkingHR(
            item.startTime,
            item.endTime,
            moment().format("YYYY-MM-DD")
          ),
        };
        timeClockData.push(datas);
      });
    }

    const payperiodEndDate = payPeriod?.EndDate;
    const currentDate = moment().format("YYYY-MM-DD");
    if (
      payperiodEndDate === moment(adjustedTime?.endTime).format("YYYY-MM-DD") &&
      adjustedTime?.endTime >=
        moment(`${currentDate}T24:00:00`, "YYYY-MM-DDTHH:mm:ss")
    ) {
      const ed = moment(adjustedTime?.startTime).format("YYYY-MM-DD");
      newOBJ.endTime = moment(`${ed}T${"24:00"}`, "YYYY-MM-DDTHH:mm").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      let nextDay = {
        date: currentDate,
        time_from: "24:00",
        endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        startTime: moment(
          `${currentDate}T24:00:00`,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        time_to: moment(adjustedTime?.endTime).format("HH:mm"),
        code: paycodes.value,
        paycode: paycodes.label,
        Auth: AuthCheck.status === "confirm" ? "A" : "U",
        isLate: lateFlag,
        totalMins: CalculateWorkingHR(
          "24:00",
          moment(adjustedTime?.endTime).format("HH:mm"),
          currentDate
        ),
      };
      timeClockData.push(newOBJ);
      timeClockData.push(nextDay);
    } else {
      if (!splitafter12Timerange.length) {
        timeClockData.push(newOBJ);
      }
    }

    if (result && result.length) {
      result.map((item, index) => {
        let AuthCheck = checkAuth(
          item.start,
          item.end,
          moment().format("YYYY-MM-DD")
        );
        let endTimes = moment(adjustedTime?.endTime).format("YYYY-MM-DD");
        let resultData = {
          date: moment(adjustedTime?.endTime).format("YYYY-MM-DD"),
          time_from: item.start,
          time_to: item.end,
          code: "5",
          startTime: moment(
            `${endTimes}T${item.start}`,
            "YYYY-MM-DDTHH:mm"
          ).format("YYYY-MM-DDTHH:mm:ss"),
          endTime: moment(`${endTimes}T${item.end}`, "YYYY-MM-DDTHH:mm").format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          paycode: "N/D",
          Auth: AuthCheck.status === "confirm" ? "A" : "U",
          isLate: lateFlag,
          totalMins: CalculateWorkingHR(
            item.start,
            item.end,
            moment().format("YYYY-MM-DD")
          ),
        };

        newOBJ.startTime = moment(
          `${endTimes}T${item.end}`,
          "YYYY-MM-DDTHH:mm"
        ).format("YYYY-MM-DDTHH:mm:ss");

        timeClockData.push(resultData);
      });
    }

    // if(paycodes?.label === "H-5"){
    //   let newOBJ = {
    //     date: date,
    //     time_from: timeForm,
    //     startTime: moment(adjustedTime?.startTime).format("YYYY-MM-DDTHH:mm:ss"),
    //     endTime: moment(adjustedTime?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
    //     time_to: time_to,
    //     code: 1,
    //     paycode: "REG",
    //     Auth: AuthCheck.status === "confirm" ? "A" : "U",
    //     isLate:lateFlag,
    //     totalMins: CalculateWorkingHR(
    //       timeForm,
    //       moment(adjustedTime?.endTime).format("HH:mm"),
    //       date
    //     ),
    //   };
    //   timeClockData.push(newOBJ)
    // }

    setIsRunning(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setStartStopCheck(false);
    setPause(false);
    const finalTimeClockData = timeClockData.filter((i) => !i.ID);
    localStorage.setItem("timeClockData", JSON.stringify(finalTimeClockData));

    localStorage.removeItem("timeClockStartHours");
  };

  useImperativeHandle(ref, () => ({
    childFunction() {
      let infoArray = [];
      let notification = [];

      timeClockData.map((item) => {
        if (item.time_from && item.time_to && !item.ID) {
          let infoPick = {
            WorkDate: item.date,
            PayCode: item.code,
            WorkFrom: item.time_from,
            WorkTo: item.time_to,
            JobOrder: null,
            Typh: item.paycode,
            // RecordNo: 0,
            TotalMinutes: getHMFromTime(item.time_from, item.time_to)?.min,
            RecordNo: "0",
            Status: "Confirm",
            Auth: item.Auth,
            ID: item.ID ? parseInt(item.ID) : null,
            isLate: item.isLate,
            ActualFromTime: moment(item.ActualFromTime).format("HH:mm:00"),
            ActualToTime: moment(item.ActualToTime).format("HH:mm:00"),
          };
          infoArray.push(infoPick);
        }
      });

      console.log("xdasfasf", notification);

      if (!infoArray.length) {
        return false;
      }

      const findisLate = timeClockData?.find((i) => i.isLate == "Y" && !i.id);
      if (findisLate?.isLate == "Y" && userRole === "user") {
        let notificationObj = {
          receiverID: null,
          forID: payPeriod?.ID,
          message: `#${userInfo.ID ? userInfo.ID : ""} added Late hours`,
          type: "WHL", // work hour late
        };
        notification.push(notificationObj);
        dispatch(createNotification({ notificationData: notification }));
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
          { workingHours: infoArray, deletedList: [] },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(async (response) => {
          localStorage.removeItem("timeClockData");
          ShowSucess("Status Change Successfully!");
          loadPreData();

          //setDeletedList([])
        })
        .catch((error) => {
          // ShowError(error)
          console.warn("Cannot retrieve user data", error);
        });
      // handleButtonClick();
    },
  }));

  useEffect(() => {
    let UpdatedCodes = paytimeCode?.map((i) => {
      if (["8", "110", "111", "112", "113", "114", "115"].includes(i.code)) {
        i.disabled = true;
      } else {
        i.disabled = false;
      }
      return i;
    });

    let paytime = paytimeCode?.filter((item) => {
      return ["10", "11", "110", "111", "112", "113", "114", "115"].includes(
        item.code
      );
    });

    const fiteterdArray = paytime.filter((i) => {
      if (jobTypeOptions.find((d) => d.code == i.code)) {
        return i;
      }
    });

    setHJobTypeOptions(fiteterdArray);
  }, [paytimeCode, jobTypeOptions]);

  useEffect(() => {
    const filterPaycodeList = payCodeFilter(
      paytimeCode,
      paytimeCodeByID,
      userRole
    );

    setJobTypeOptions(filterPaycodeList);
  }, [paytimeCode, paytimeCodeByID]);

  const getSelectedType = (jobtype) => {
    let filterObj = jobTypeOptions.find((i) => {
      return i.value + "" === jobtype + "";
    });

    return filterObj !== undefined
      ? filterObj
      : {
          value: "REG",
          label: "REG",
          title: "REGULAR",
          code: "1",
          multi: 1,
          EffectDate: "1990-01-01T00:00:00.000Z",
          ExpireDate: "2099-12-31T00:00:00.000Z",
          name: "jobtype",
        };
  };

  const splitTimeRange = (startTime, endTime, existingTime) => {
    const splitSlots = [{ start: startTime, end: endTime }];

    for (let i = 0; i < existingTime.length; i++) {
      const currentSlot = existingTime[i];
      const updatedSlots = [];

      for (let j = 0; j < splitSlots.length; j++) {
        const slot = splitSlots[j];

        if (
          currentSlot.fromTime >= slot.start &&
          currentSlot.toTime <= slot.end
        ) {
          updatedSlots.push({
            start: slot.start,
            end: currentSlot.fromTime,
            type: "N/D",
          });
          updatedSlots.push({
            start: currentSlot.fromTime,
            end: currentSlot.toTime,
            type: "REG",
          });
          updatedSlots.push({
            start: currentSlot.toTime,
            end: slot.end,
            type: "N/D",
          });
        } else if (
          currentSlot.fromTime < slot.end &&
          currentSlot.toTime > slot.start
        ) {
          if (slot.start < currentSlot.fromTime) {
            updatedSlots.push({
              start: slot.start,
              end: currentSlot.fromTime,
              type: "N/D",
            });
          }
          if (slot.end > currentSlot.toTime) {
            updatedSlots.push({
              start: currentSlot.toTime,
              end: slot.end,
              type: "N/D",
            });
          }
        } else {
          updatedSlots.push(slot);
        }
      }

      splitSlots.splice(0, splitSlots.length, ...updatedSlots);
    }

    return splitSlots;
  };
  const time_from = "18:20";
  const time_to = "19:00";
  const exipsting = [
    {
      shr: time_from,
      ehr: time_to,
      fromTime: "06:00",
      toTime: "18:00",
    },
  ];

  const confimClick = () => {
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIsRunning(false);
    setStartStopCheck(false);
    setOpen(false);

    localStorage.removeItem("timeClockStartHours");
  };

  return (
    <Card>
      {/* {JSON.stringify(timeClockData)} */}
      <CardHeader className="pb-0">
        <div
          style={{
            height: "55px",
            border: " 1px solid #dee2e6",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="p-3">
            <h5>Time Clock</h5>
          </div>
          <div style={{ display: "flex" }}>
            {/* <div style={{ marginTop: "3px" }}>
              <Autocomplete
                size={"small"}
                id="combo-box-demo"
                name="combo-box-demo" 
                autoHighlight={true}
                autoSelect={true}
                className="flex w-full mx-10 paroll-autocomplete "
                onChange={(e, value) => {
                  setPaycodes({ value: value.code, label: value.label });
                }}
                value={getSelectedType(paycodes.label)} 
                options={getValidPaycodeOfDay(
                  moment().format("YYYY-MM-DD"),
                  jobTypeOptions
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="MuiAutocomplete-input"
                    size="small"
                    
                    variant="outlined"
                    hiddenLabel="true"
                    input
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                    }}
                   
                  />
                )}
              />
            </div> */}
            <div className="timerbg" title="Working Hours">
              <span>{time.hours.toString().padStart(2, "0")}</span>:
              <span>{time.minutes.toString().padStart(2, "0")}</span>:
              <span>{time.seconds.toString().padStart(2, "0")}</span>
            </div>
            {!startStopCheck ? (
              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  variant="contained"
                  onClick={handleStart}
                  className="p-1  bg-primary"
                  size="medium"
                  style={{
                    color: "#d0efe9",
                    width: "100px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{ marginRight: "5px" }}
                    className="bg-primary"
                  />
                  <Hidden smDown>
                    <FontAwesomeIcon icon="fa-solid fa-play" />
                    <Typography className="text-12">Start</Typography>
                  </Hidden>
                </Button>
              </div>
            ) : (
              <>
                {/* {!pause ?
                <div  style={{ padding: "10px"  }}>
                  {" "}
                  <Button
                    variant="contained"
                    onClick={handlePause}
                    // disabled={check || hasError}
                    className="p-1  bg-primary"
                    size="medium"
                    style={{
                      color: "#d0efe9",
                      // backgroundColor: "#61bdaa",
                    }}
                  >
                    <Hidden smDown>
                      <Typography className="text-12">Pause</Typography>
                    </Hidden>
                  </Button>
                </div> : 
                <div  style={{ padding: "10px"  }}>
                {" "}
                <Button
                  variant="contained"
                  onClick={handleResume}
                  // disabled={check || hasError}
                  className="p-1  bg-primary"
                  size="medium"
                  style={{
                    color: "#d0efe9",
                    // backgroundColor: "#61bdaa",
                  }}
                >
                  <Hidden smDown>
                    <Typography className="text-12">Resume</Typography>
                  </Hidden>
                </Button>
              </div>
                } */}
                <div style={{ padding: "10px" }}>
                  {" "}
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (time?.hours <= 0 && time.minutes <= 15) {
                        handleClickOpen();
                      } else {
                        handleStop();
                      }
                    }}
                    className="p-1  bg-primary"
                    size="medium"
                    style={{
                      color: "#d0efe9",
                      width: "100px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faStop}
                      style={{ marginRight: "5px" }}
                      className="bg-primary"
                    />
                    <Hidden smDown>
                      <Typography className="text-12">Stop</Typography>
                    </Hidden>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center">Pay Type</TableCell> */}
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">From</TableCell>
                <TableCell align="center">To</TableCell>
                <TableCell align="center">Hours</TableCell>
                {/* <TableCell align="center">Auth</TableCell>
                <TableCell align="center">Is Late</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {timeClockData
                ? timeClockData?.map((item) => {
                    return <TimeClockRows data={item} />;
                  })
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </CardBody>
      {open ? (
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          confimClick={confimClick}
          message={
            "Your work time is less than 15 minutes so this time will not be save. Are You sure to stop timer ?"
          }
          modalHeader="Alert Message"
        />
      ) : (
        ""
      )}
    </Card>
  );
});

export default TimeClock;
