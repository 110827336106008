import React, { useEffect, useState } from "react";
import { Card, FormControl, Grid, TextField } from "@mui/material";
import moment from "moment";
import axios from "axios";

function CombinedDeductionReportLayout (props) {

  const [allPayPeriod, setAllPayPeriod] = useState([]);
  const [payPeriod, setPayPeriod] = useState(null);

  useEffect(() => {
    getPayPeriod();
  }, []);

  const getPayPeriod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payperiod`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        if (response.data.length) {
          let resPaycode = response.data;
          let tody = moment().format("YYYY-MM-DD");
          let allpayperiod = resPaycode.filter((i) => {
            return i.StartDate <= tody;
          });
          setAllPayPeriod(allpayperiod);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };


  return (
    <div className="w-full h-full reportTbl CardBack">
      <div className="mt-5  ">
        <div style={{ marginTop: "20px" }}>
          <Card
            style={{
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            elevation={3}
            className="body-Color2"
          >
            <Grid container spacing={2}>
              <Grid item xs={18} sm={8} lg={8} style={{ textAlign: "center" }}>
                <table className="padTbl">
                  <tbody>
                  <tr>
                  </tr>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          Date Range: Sun Jun. 18 2023 - Sat Jul. 01 2023
                        </b>
                      </td>
                      <div style={{ display: "flex", flex: 1 }} className="mx-4">
                        <FormControl className="payperiodRange" style={{ border: '1px solid lightgrey', borderRadius: '7px' }}>
                            <TextField
                                size="small"
                                select
                                name="payperiod_range"
                                id="payperiod_range"
                                className="min-w-128 text-16"
                                style={{ color: "red" }}
                                variant="outlined"
                                value={payPeriod ? payPeriod["ID"] : 0}
                                margin="dense"
                                onChange={(event) => {
                                console.log("setData", JSON.stringify(event.target.value));
                                let info = allPayPeriod.find((i) => {
                                    return i.ID.toString() === event.target.value + "";
                                });
                                setPayPeriod(info);
                                }}
                                SelectProps={{
                                native: true,
                                }}
                                inputProps={{
                                style: { color: "grey" },
                                }}
                            >
                                {allPayPeriod.length &&
                                allPayPeriod.map((option, index) => (
                                    <option
                                      key={index}
                                      value={option.ID}
                                      style={{ backgroundColor: "white", color: "black" }}
                                    >
                                    {moment(option.StartDate).format("MM-DD-YYYY")} TO {moment(option.EndDate).format("MM-DD-YYYY")}
                                    </option>
                                ))}
                            </TextField>
                        </FormControl>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card>
        </div>
        
      </div>
    </div>
  );
}

export default CombinedDeductionReportLayout;
