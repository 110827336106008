import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  faDollarCircle,
  faEnvelope,
  faUser,
  faDown,
  faUp,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";

import { Box, Collapse, Grid } from "@material-ui/core";
import {
  HourMinFormat,
  isOnLeave,
  removeDuplicatesByKey,
} from "../../../app/utility/helper";
import { Card } from "reactstrap";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ConfigDB from "../../../Config/ThemeConfig";
import LoadingPanel from "../../Common/telerik/loadingPanel";
import moment from "moment";

const EmployeeDetail = ({
  data,
  hourData,
  paytimeCode,
  userInfo,
  EmployeeStatuses,
  isloading,
  price,
  setPrice,
  rowsData,
  OTHours,
  OTMinutes,
  OTType,
}) => {
  let rowno = 1;
  let rowflag = true;
  // const userInfo = useSelector(({ ehrpweb }) => ehrpweb.userData);
  // const payCodeInfo = useSelector(({ ehrpweb }) => ehrpweb.payCodes);
  // const EmployeeStatuses = useSelector(
  //   ({ ehrpweb }) => ehrpweb.EmployeeStatuses
  // );

  const [collabOn, setCollabOn] = useState(false);
  const [payCodes, setPayCodes] = useState([]);
  const [stickySwitch, setStickySwitch] = useState(false);
  const [payCodeAmount, setPayCodeAmount] = useState({
    primeAmount: [],
    secondryAmount: [],
  });

  useEffect(() => {
    // getPrice();
    if (EmployeeStatuses && EmployeeStatuses.length > 0) {
      setPrice(EmployeeStatuses[0]["HourlyRate"]);
    } else {
      if (userInfo && userInfo.Rate) {
        setPrice(userInfo.Rate.toFixed(2));
      } else {
        setPrice(0);
      }
    }
  }, [EmployeeStatuses, userInfo]);

  useEffect(() => {
    if (paytimeCode && paytimeCode.length) {
      let cpPayCodes = [ ...paytimeCode ];
      console.log('cpPayCodeszz',cpPayCodes);
      
      cpPayCodes.map((i, index) => {
        if (hourData[i.value] !== undefined && hourData[i.value] != "0") {
          // let payInfo = GetJobHoursPrice(i.value, hourData[i.value]);
          let payInfo = GetAmountTimeCalc(i.value, hourData[i.value], rowsData);

          let fcp = { ...cpPayCodes[index] };
          fcp["time"] = payInfo.time;
          fcp["amount"] = payInfo.amount;
          fcp["show"] = true;
          cpPayCodes[index] = fcp;
        } else {
          let fcp = { ...cpPayCodes[index] };
          fcp["show"] = false;
          fcp["amount"] = 0;
        }
      });
      let infoObjREG = cpPayCodes.findIndex((i) => {
        return i.value == "REG";
      });

      //checkOT allowed
      let OTCode = cpPayCodes.filter((i) => {
        return ["OT.5-0.50", "OT1.5", "OT.5"].includes(i.value);
      });
      if (OTCode.length) {
        // let payOverInfo = GetJobHoursPrice("OT1.5", hourData["REG"]);
        /*
        let payOverInfo = GetJobHoursPrice(OTCode[0]?.label, hourData["REG"]);

        console.log("infoObjxxxx", payOverInfo);

        let infoObj = cpPayCodes.findIndex((i) => {
          return ["OT.5-0.50", "OT1.5", "OT.5"].includes(i.value);
        });
        console.log(
          "infoObj 121212",
          infoObj,
          cpPayCodes[infoObj],
          payOverInfo
        );
        if (infoObj !== -1) {
          cpPayCodes[infoObj]["time"] = payOverInfo["time"];
          cpPayCodes[infoObj]["amount"] = payOverInfo["amount"];
          cpPayCodes[infoObj]["show"] = parseInt(payOverInfo["amount"])
            ? true
            : false;
        }

        */
      }

      cpPayCodes.sort((a, b) => {
        return parseFloat(a.amount) > parseFloat(b.amount) ? -1 : 1;
      });
      console.log("dfdsfdsfsdfsfdsfs", cpPayCodes);
      setPayCodes(removeDuplicatesByKey(cpPayCodes, "label"));
      // setPayCodes(cpPayCodes);
    }
  }, [hourData]);

  const getCalculatedAmt = (rate, hrs, type) => {
    let codeItem = payCodes.find((item) => {
      return item.value == type;
    });

    let multi = codeItem ? (codeItem.multi ? codeItem.multi : 1) : 1;
    return (rate * hrs * multi).toFixed(2);
  };

  const getCalculatedAmtMin = (totalMins, type) => {
    let rate = price;

    if (["OT.5-0.50", "OT1.5", "OT.5"].includes(type)) {
      const maxRate = rowsData.reduce((max, item) => {
        const rate = parseFloat(item.Rate);
        return rate > max ? rate : max;
      }, 0);

      rate = maxRate;
    }
    // ----- for amount calculation
    if (totalMins > 4800) {
      totalMins = 4800;
    }
    //-----
    let codeItem =
      paytimeCode &&
      paytimeCode.find((item) => {
        return item.value === type;
      });

    let multi = codeItem ? (codeItem.multi ? codeItem.multi : 1) : 1;

    let hr = `${Math.floor(totalMins / 60)}`;
    let min = `${totalMins % 60}`;

    let hRate = (rate * hr * multi).toFixed(2);
    let mRate = (rate * (min / 60) * multi).toFixed(2);

    return (parseFloat(hRate) + parseFloat(mRate)).toFixed(2);
  };

  const getHoursMinutes = (minutes, type) => {
    if (type.toLowerCase() === "h") {
      let hours = Math.floor(minutes / 60);
      return hours;
    } else if (type.toLowerCase() === "m") {
      let min = parseInt(minutes) % 60;
      return min;
    } else if (type.toLowerCase() === "tm") {
      let min = minutes;
      return min;
    } else if (type.toLowerCase() === "hm") {
      let hrCalc = HourMinFormat(`${Math.floor(minutes / 60)}:${minutes % 60}`);
      return hrCalc;
    } else {
      return 0;
    }
  };

  const getAmountCalculatedByType = (totalMins, type, rowsData) => {
    let rate = price;
    // ----- for amount calculation
    let amount = rowsData
      .filter((i) => {
        return i.jobtype == type;
      })
      .reduce(function (acc, obj) {
        return (
          acc +
          parseFloat(
            (obj.payAmount ? parseFloat(obj.payAmount) : 0) +
              (obj.payAmount1 ? parseFloat(obj.payAmount1) : 0)
          )
        );
      }, 0);

    let finalMins = rowsData
      .filter((i) => {
        return i.jobtype == type;
      })
      .reduce(function (acc, obj) {
        return (
          acc + parseFloat((obj.mn ? obj.mn : 0) + (obj.mn1 ? obj.mn1 : 0))
        );
      }, 0);

    let finalOTMins = finalMins - totalMins;
    if (finalOTMins) {
      let maxValue = Math.max.apply(
        null,
        rowsData.map(function (o) {
          return o.Rate;
        })
      );
      const hr = getHoursMinutes(finalOTMins, "h");
      const min = getHoursMinutes(finalOTMins, "m");
      let hRate = (maxValue * hr).toFixed(2);
      let mRate = (maxValue * (min / 60)).toFixed(2);
      let minOTAmt = parseFloat(hRate) + parseFloat(mRate);

      return parseFloat(amount - minOTAmt).toFixed(2);
    } else {
      return parseFloat(amount).toFixed(2);
    }

    // if (totalMins < 60) {
    //   totalMins = 6360;
    // }

    //-----
    let codeItem =
      paytimeCode &&
      paytimeCode.find((item) => {
        return item.value === type;
      });

    let multi = codeItem ? (codeItem.multi ? codeItem.multi : 1) : 1;

    let hr2 = `${Math.floor(totalMins / 60)}`;
    let min2 = `${totalMins % 60}`;

    let hRate = (rate * hr2 * multi).toFixed(2);
    let mRate = (rate * (min2 / 60) * multi).toFixed(2);

    return (parseFloat(hRate) + parseFloat(mRate)).toFixed(2);
  };

  const getOTHours = (type) => {
    //PPEPW40=40=2400 hr for week / PPEPW43 =43=2580 hr for week / PPE2W106=Full=106 PPE / PD= Per day
    if ((type = "PPEPW40")) {
      return { hours: 80, minutes: 4800 };
    } else if (type == "PPEPW43") {
      return { hours: 80, minutes: 5160 };
    } else if (type == "PPE2W106") {
      return { hours: 106, minutes: 6360 };
    } else if (type == "PD") {
      return { hours: 80, minutes: 4800 };
    } else {
      return { hours: 80, minutes: 4800 };
    }
  };

  const GetAmountTimeCalc = (type, mins, rowsData) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");
    if (type === "REG" || ["OT.5-0.50", "OT1.5", "OT.5"].includes(type)) {
      if (userInfo?.DPDV == "4202") {
        let maxHourWork = 106;
        if (hr > maxHourWork) {
          return {
            time: `${maxHourWork} hours`,
            amount: getAmountCalculatedByType(106 * 60, type, rowsData),
          };
        } else {
          if (min > 0) {
            return {
              time: `${hr} hrs ${min} mins`,
              amount: getAmountCalculatedByType(mins, type, rowsData),
            };
          } else {
            return {
              time: `${hr} hours`,
              amount: getAmountCalculatedByType(mins, type, rowsData),
            };
          }
        }
      } else if (hr >= getOTHours(OTType)?.hours) {
        return {
          time: `${getOTHours(OTType)?.hours} hours`,
          amount: getAmountCalculatedByType(
            getOTHours(OTType)?.minutes,
            type,
            rowsData
          ),
        };
      } else {
        if (min > 0) {
          return {
            time: `${hr} hrs ${min} mins`,
            amount: getAmountCalculatedByType(mins, type, rowsData),
          };
        } else {
          return {
            time: `${hr} hours`,
            amount: getAmountCalculatedByType(mins, type, rowsData),
          };
        }
      }
    } else if (["OT.5-0.50x", "OT1.5x", "OT.5x"].includes(type)) {
      if (hr >= 80) {
        if (min > 0) {
          return {
            time: `${hr - 80} hrs ${min} mins`,
            // amount: getCalculatedAmtMin(mins, "OT1.5"),
            amount: getAmountCalculatedByType(mins, type, rowsData),
          };
        } else {
          return {
            time: `${hr - 80} hours`,
            // amount: getCalculatedAmtMin(mins - 4800, 'REG') + getCalculatedAmtMin(mins - 4800, 'OT1.5'),
            amount: getAmountCalculatedByType(mins - 4800, type, rowsData),
          };
        }
      } else {
        return { time: "-", amount: 0 };
      }
    } else {
      if (hr == "NaN" && min == "NaN") {
        return {
          time: `0`,
          amount: `0`,
        };
      }
      if (min > 0) {
        return {
          time: `${hr} hrs ${min} mins`,
          amount: getAmountCalculatedByType(mins, type, rowsData),
        };
      } else {
        return {
          time: `${hr} hours`,
          amount: getAmountCalculatedByType(mins, type, rowsData),
        };
      }
    }
  };

  const GetJobHoursPrice = (type, mins) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");
    if (type === "REG") {
      if (hr >= 80) {
        if (userInfo?.DPDV == "4202") {
          return {
            time: `106 hours`,
            amount: getCalculatedAmtMin(106 * 60, type),
          };
        } else {
          return {
            time: `80 hours`,
            amount: getCalculatedAmtMin(4800, type),
          };
        }
      } else {
        return { time: "-", amount: 0 };
      }
    } else if (["OT.5-0.50", "OT1.5", "OT.5"].includes(type)) {
      if (userInfo?.DPDV == "4202") {
        // GFD department

        if (isOnLeave(rowsData)) {
          return {
            time: ``,
            amount: 0,
          };
        }
        if (hr >= 106) {
          if (hr < 120 && min > 0) {
            return {
              time: `${hr - 106} hrs ${min} mins`,
              amount: getCalculatedAmtMin(mins, type),
            };
          } else {
            let maxmin = mins - 106 * 60;
            if (maxmin >= 840) {
              maxmin = 840;
            }

            let maxhr = hr - 106;
            if (maxhr >= 14) {
              maxhr = 14;
            }

            return {
              time: `${maxhr} hours`,
              // amount: getCalculatedAmtMin(mins - 4800, 'REG') + getCalculatedAmtMin(mins - 4800, 'OT1.5'),
              amount: getCalculatedAmtMin(maxmin, type),
            };
          }
        } else {
          return { time: "-", amount: 0 };
        }
      } else if (hr >= 80) {
        if (min > 0) {
          return {
            time: `${hr - 80} hrs ${min} mins`,
            // amount: getCalculatedAmtMin(mins, "OT1.5"),
            amount: getCalculatedAmtMin(mins, type),
          };
        } else {
          return {
            time: `${hr - 80} hours`,
            // amount: getCalculatedAmtMin(mins - 4800, 'REG') + getCalculatedAmtMin(mins - 4800, 'OT1.5'),
            amount: getCalculatedAmtMin(mins - 4800, type),
          };
        }
      } else {
        return { time: "-", amount: 0 };
      }
    }
    // else if (type === "NDIF" || type === "H-5") {
    //   if (min > 0) {
    //     return {
    //       time: `${hr} hrs ${min} mins`,
    //       amount: getCalculatedAmtMin(mins, type),
    //     };
    //   } else {
    //     return {
    //       time: `${hr} hours`,
    //       amount: getCalculatedAmtMin(mins, type),
    //     };
    //   }
    // }
    else {
      // return { time: "0 Hours", amount: "0" };
      if (hr == "NaN" && min == "NaN") {
        return {
          time: `0`,
          amount: `0`,
        };
      }
      if (min > 0) {
        return {
          time: `${hr} hrs ${min} mins`,
          amount: getCalculatedAmtMin(mins, type),
        };
      } else {
        return {
          time: `${hr} hours`,
          amount: getCalculatedAmtMin(mins, type),
        };
      }
    }
  };
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const stickyElement = document.querySelector(".myStickyDiv");
      const container = document.querySelector(".MainDivForSticky");

      window.addEventListener("scroll", function () {
        const containerRect = ''; //container.getBoundingClientRect();
        if (containerRect.top <= 0) {
          stickyElement.classList.add("sticky");
          setStickySwitch(true);
        } else {
          stickyElement.classList.remove("sticky");
          setStickySwitch(false);
        }
      });
    });
  }, [window]);
  console.log("sticky", stickySwitch);

  return (
    <>

        <div className="gap-16  myStickyDiv">
          <Card elevation={4} className=" p-10   ">
            {isloading && <LoadingPanel></LoadingPanel>}
            <h5 className="font-bold my-3 ml-1 "> General Employee Details </h5>
            <Grid container spacing={2}>
              <Grid item className="mx-1">
                <div className="">
                  <Typography className="text-14 font-semibold pl-2 pt-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        color: ConfigDB.data.color.primary_color, //"#24695c",
                        marginRight: "10px",
                      }}
                    />
                    <b>Name </b>
                    <span className="">
                      {" "}
                      : {userInfo ? userInfo.EmployeeName : ""}
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item className="mx-1">
                {" "}
                <div className="xs-6">
                  <Typography className="text-14  font-semibold pl-2 pt-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{
                        color: ConfigDB.data.color.primary_color, //"#24695c",
                        marginRight: "10px",
                      }}
                    />
                    <b className="">Department</b>
                    <span className="">
                      {" "}
                      :{" "}
                      {userInfo && userInfo.DPDV_DepartmentDivisionMaster
                        ? userInfo.DPDV_DepartmentDivisionMaster.description
                        : ""}
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item className="mx-1">
                {" "}
                <div className="">
                  <Typography className="text-14  font-semibold pl-2 pt-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{
                        color: ConfigDB.data.color.primary_color, //"#24695c",
                        marginRight: "10px",
                      }}
                    />
                    <b className="">Employee Id</b>
                    <span className=""> : {userInfo ? userInfo.ID : "-"}</span>
                  </Typography>
                </div>
              </Grid>
              <Grid item className="mx-1">
                {" "}
                <div className="">
                  <Typography className="text-14  font-semibold pl-2 pt-2 ">
                    <FontAwesomeIcon
                      icon={faDollarCircle}
                      style={{
                        color: ConfigDB.data.color.primary_color, //"#24695c",
                        marginRight: "10px",
                      }}
                    />
                    <b className="">Rate</b>
                    <span className=""> : $ {price}</span>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          {/* code  */}
          {/* end code */}
        </div>
        <div className="p-16">
          <Card elevation={4} className="mb-3">
            <Box
              className="width-full bg-primary"
              style={{ borderRadius: "5px" }}>
              <Typography
                className="my-2 ml-1"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px",
                }}>
                <h5 className="font-bold">
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="bg-primary"
                    style={{
                      margin: "0px 10px",
                    }}
                  />
                  Payroll Details
                </h5>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "144px",
                    alignItems: "center",
                  }}>
                  {collabOn ? (
                    <FontAwesomeIcon
                      icon={faUp}
                      // style={{ color: "#24695c", marginRight: "10px" }}

                      style={{ marginRight: "10px" }}
                      className="bg-primary"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faDown}
                      style={{
                        marginRight: "10px",
                      }}
                      className="bg-primary"
                    />
                  )}
                  <h6
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    onClick={() => {
                      setCollabOn(!collabOn);
                    }}>
                    {collabOn ? "Show Less" : "Show All "}
                  </h6>
                </span>
              </Typography>
            </Box>
            <Box style={{ width: "100%", padding: "10px" }}>
              <Grid container spacing={2}>
                <>
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    lg={2}
                    className="empPayTitle bg-light">
                    <Typography variant="subtitle2" className="text-14  ">
                      <b>Pay Type</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    lg={2}
                    className="empPayTitle bg-light">
                    <Typography variant="subtitle2" className="text-14  ">
                      <b>Time</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    lg={2}
                    className="empPayTitle bg-light">
                    <Typography variant="subtitle2" className="text-14  ">
                      <b>Amount$</b>
                    </Typography>
                  </Grid>

                  <></>
                  <Grid
                    item
                    lg={2}
                    sm={2}
                    component={Box}
                    display={{ xs: "none", sm: "block", lg: "block" }}
                    className="empPayTitle bg-light">
                    <Typography
                      variant="subtitle2"
                      className="text-14 bg-light ">
                      <b>Pay Type</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    component={Box}
                    display={{ xs: "none", sm: "block", lg: "block" }}
                    className="empPayTitle bg-light">
                    <Typography variant="subtitle2" className="text-14  ">
                      <b>Time</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    component={Box}
                    display={{ xs: "none", sm: "block", lg: "block" }}
                    className="empPayTitle bg-light">
                    <Typography variant="subtitle2" className="text-14  ">
                      <b>Amount</b>
                    </Typography>
                  </Grid>
                </>
                {/* 
                    {payCodeAmount.secondryAmount && payCodeAmount.secondryAmount.length>0 
                        
                        && <>
                        <Grid style={{display:`${collabOn?'block':'none'}`}} item xs={12} sm={12} md={4}  >
                          <Typography variant="subtitle2" className="text-14 text-black ">
                             <b>Pay type code</b>
                          </Typography>
                        </Grid>
                   
                        <Grid style={{display:`${collabOn?'block':'none'}`}}  item xs={12} sm={12} md={4}  >
                        <b>Time</b>

                        </Grid>
                    
                        <Grid style={{display:`${collabOn?'block':'none'}`}} item xs={12} sm={12} md={4}  >
                        <b>Amount</b>
                        </Grid>
                       
                    </>
                        }  */}

                {payCodes.length
                  ? payCodes
                      .filter((i) => {
                        return i.show;
                      })
                      .map((i) => {
                        if (rowno <= 2) {
                          rowflag = true;
                          rowno = rowno + 1;
                        } else {
                          if (rowno <= 4 && rowno >= 3) {
                            rowflag = false;
                            rowno = rowno + 1;
                          } else {
                            rowno = 1;
                            rowflag = true;
                            rowno = rowno + 1;
                          }
                        }
                        // if (rowno <= 4 && rowno >= 3) {
                        //   rowflag = false;

                        //   if (rowno == 4) {
                        //     rowno = 0;
                        //   } else
                        //   rowno = rowno + 1;
                        // }

                        return (
                          <>
                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black">
                                {i.value}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black  ">
                                {" "}
                                {i.time ? `${i.time}` : "-"}{" "}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black  ">
                                {" "}
                                {i.amount ? `$${i.amount}` : "-"}{" "}
                              </Typography>
                            </Grid>
                            {/* { rowno % 2 == 0 ? { rowflag=true; rowno=0}:rowflag=false} */}
                            {/* { rowno % 2 ==0? :rowno=rowno} */}
                          </>
                        );
                      })
                  : null}

                {payCodes.length
                  ? payCodes
                      .filter((i) => {
                        return !i.show;
                      })
                      .map((i, inx) => {
                        if (rowno <= 2) {
                          rowflag = true;
                          rowno = rowno + 1;
                        } else {
                          if (rowno <= 4 && rowno >= 3) {
                            rowflag = false;
                            rowno = rowno + 1;
                          } else {
                            rowno = 1;
                            rowflag = true;
                            rowno = rowno + 1;
                          }
                        }

                        return (
                          <React.Fragment key={`b${inx}`}>
                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black">
                                {i.value}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black  ">
                                {" "}
                                {i.time ? `${i.time}` : "-"}{" "}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              sm={2}
                              lg={2}
                              style={{
                                display: i.show
                                  ? "block"
                                  : collabOn
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                rowflag ? "empPayTitleR1" : "empPayTitleR2"
                              }>
                              <Typography
                                variant="subtitle2"
                                className="text-14 text-black  ">
                                {" "}
                                {i.amount ? `$${i.amount}` : "-"}{" "}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })
                  : null}
              </Grid>
            </Box>
          </Card>
        </div>{" "}

    </>
  );
};

export default EmployeeDetail;
