import { makeStyles } from "@material-ui/core/styles";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import FilterList from "./filterList";
import TableComponent from "./tableComponent";
import { useDispatch } from "react-redux";
import moment from "moment";
import { set } from "react-hook-form";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    // height: "40px",
    // // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, 0.04)",
    // },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const RequestTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    Code: "",
    Abberv: "",
    Description: "",
    EffectDate: null,
    ExpireDate: null,
    Multi: "",
  });
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const changeHeaderFilter = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };
  const { requestDetail, filterBy, role } = props;
  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;

  const filterByRef = useRef(filter);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      // (filter && filterByRef.current && filterBy.current !== filter) ||
      requestDetail
    ) {
      filterByRef.current = filter;
      let dataList = requestDetail;
      if (filter?.Code) {
        dataList = dataList.filter((data) =>
          data.Code.toLowerCase().includes(filter.Code?.toLowerCase())
        );
      }

      if (filter?.Abberv) {
        dataList = dataList.filter((data) =>
          data.Abberv.includes(filter.Abberv)
        );
      }
      if (filter?.Description) {
        dataList = dataList.filter((data) =>
          data.Description.includes(filter.Description)
        );
      }
      if (filter?.Multi) {
        dataList = dataList.filter((data) =>
          data.Multi.toString().includes(parseFloat(filter.Multi))
        );
      }
      //   if (filter?.type && filter?.type !== "All") {
      //     dataList = dataList.filter((data) => data.type.includes(filter.type));
      //   }
      //   if (filter?.status && filter.status !== "All") {
      //     dataList = dataList.filter((data) => data.status === filter.status);
      //   }
      if (filter?.EffectDate) {
        let fdt = moment(filter.EffectDate).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.EffectDate).format("MM-DD-YYYY").includes(fdt);
        });
      }
      if (filter?.ExpireDate) {
        let fdt = moment(filter.ExpireDate).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.ExpireDate).format("MM-DD-YYYY").includes(fdt);
        });
      }
      //   if (filter?.name) {
      //     dataList = dataList.filter((data) =>
      //       `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`.includes(
      //         filter.name?.toLowerCase()
      //       )
      //     );
      //   }
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filter, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));

    let info = {
      id: data.ID,
      code: data.Code,
      effectDate: moment(data.EffectDate).format("YYYY-MM-DD"),
      expireDate: moment(data.ExpireDate).format("YYYY-MM-DD"),
      abbreviation: data.Abberv,
      description: data.Description,
      multi: data.Multi,
    };

    props.setDetail(info);
  };

  const columns = [
    { id: "Code", name: "Code", type: "" },
    { id: "Abberv", name: "Abbreviation", type: "" },
    {
      id: "Description",
      name: "Description",
    },
    {
      id: "EffectDate",
      name: "EffectDate",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.EffectDate).format("MM-DD-YYYY");
        },
      },
    },

    {
      id: "ExpireDate",
      name: "ExpireDate",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.ExpireDate).format("MM-DD-YYYY");
        },
      },
    },
    { id: "Multi", name: "Multi", type: "" },
  ];

  console.log("filter Lsit ", filteredList);

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            changeHeaderFilter={changeHeaderFilter}
            role={role}
            requestDetail={requestDetail}
            filterBy={filter}
          />
        )
      }
    />
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeHeaderFilter, setSelectedId }, dispatch);
// };

// function mapStateToProps(state) {
//   console.log("STATE ::: ", state);
//   return {
//     filterBy: state.paycode && state.paycode.paycode.filterBy,
//   };
// }

export default RequestTable;
