import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableComponent from "./tableComponent";
import FilterList from "./filterList";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../../app/utility/ConfirmModal";
import axios from "axios";
import moment from "moment";
import { Row } from "antd";
import ModalCustom from "../../../employee/Modal";
import EmpCertificationManage from "./EmpCertificationManage";
import { addOrUpdateEmployeeCertificateRecord } from "../../../../app/utility/axiosService";
import { useAuth } from "oidc-react";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const { userData } = useAuth();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    employeeID: '',
    certificationCode: '',
    validFrom: '',
    validTo: '',
    remarks: '',
    isActive: false,
  });

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  const [defaultRemoveData, setDefaultRemoveData] = useState(null);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.employeeID) {
        dataList = dataList.filter((data) =>
          data.employeeID.toString().includes(
            filterBy.employeeID?.toString()
          )
        );
      }

      if (filterBy?.certificationCode) {
        dataList = dataList.filter((data) =>
          data?.certificationCode?.toLowerCase()?.includes(
            filterBy?.certificationCode?.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.remarks) {
        dataList = dataList.filter((data) =>
          data?.remarks?.toLowerCase()?.includes(
            filterBy?.remarks?.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.validFrom) {
        dataList = dataList.filter((data) =>
            new Date(data.validFrom) < new Date(filterBy.validFrom)
        );
      }

      if (filterBy?.validTo) {
        dataList = dataList.filter((data) =>
            new Date(data.validTo) < new Date(filterBy.validTo)
        );
      }

      if (filterBy?.isActive) {
        dataList = dataList.filter((data) =>
            data.isActive === filterBy.isActive
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const changeHeaderFilter1 = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
  };

  const filterListByIsActive = (e) => {
    setFilter({ ...filterBy, 'isActive': e.target?.checked });
  };

  const handleEditEmpCertificationModal = (data) => {
    let info = {
      oldId: data.id,
      employeeID: data.employeeID,
      certificationCode: data.certificationCode,
      validFrom: data.validFrom,
      validTo: data.validTo,
      remarks: data.remarks,
      isActive: data.isActive
    };

    props.setDetail(info);
  };

  const checkvalid = () => {
    if (props.detail.employeeID === "" || props.detail.certificationCode === "" || props.detail?.certificationSeq === '' || props.detail.certificationCode === 0 || props.detail.validFrom === "" || props.detail.validTo === "" ||
    props.detail.remarks === "" || (props.detail.isActive !== false && props.detail.isActive !== true)) {
      toast.error('Please validate values!');
      return false;
    } else {
      return true;
    }
  };

  const confimRemoveClick = async () => {
    axios.delete(`${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/Delete?id=${defaultRemoveData.id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenRemoveAlert(false);
          toast.success('Record Deleted');
          props.getData();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      oldId: data.id,
      employeeID: data.employeeID,
      certificationCode: data.certificationCode,
      validFrom: data.validFrom,
      validTo: data.validTo,
      remarks: data.remarks,
      isActive: data.isActive
    };

    props.setDetail(info);
  };

  const columns = [
    {
        id: "employeeID",
        name: "Employee ID",
    },
    {
      id: "certificationCode",
      name: "Certification Code",
    },
    {
      id: "certificationSeq",
      name: "Certification Sequence",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta?.certificationSeq ? tableMeta?.certificationSeq : '-';
        },
      },
    },
    {
      id: "validFrom",
      name: "Valid From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.validFrom).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "validTo",
      name: "Valid To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.validTo).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "remarks",
      name: "Remarks",
    },
    {
      id: "isActive",
      name: "isActive",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
          checked={tableMeta.isActive}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
        },
      },
    },
    {
      id: "Action",
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row>
            <FontAwesomeIcon
              icon={faPen}
              className="sub-header trashIcon"
              onClick={() => {
                handleEditEmpCertificationModal(tableMeta);
                setOpenEditModal(true);
              }}
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="sub-header trashIcon"
              onClick={() => {
                setDefaultRemoveData(tableMeta);
                setOpenRemoveAlert(true);
              }}
            />
          </Row> 
        },
      },
    },
  ];

  const handleUpdateEmpCertification = async () => {
    if (!checkvalid()) {
      return false;
    }
    const payload = {
      id: props?.detail?.oldId,
      employeeID: Number(props?.detail?.employeeID),
      certificationCode: props?.detail?.certificationCode,
      certificationSeq: Number(props.detail?.certificationSeq),
      validFrom: props.detail?.validFrom,
      validTo: props.detail?.validTo,
      remarks: props.detail?.remarks,
      isActive: props.detail?.isActive,
    }
    const res = await addOrUpdateEmployeeCertificateRecord(payload);
    if (res.status === 200) {
      toast.success('Record Updated.');
      props.getData();
      props.clearFields();
      setOpenEditModal(false);
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <TableComponent
        columns={columns}
        filteredList={filteredList}
        totalCount={props.totalCount}
        setSkipCount={props.setSkipCount}
        onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <FilterList
              changeHeaderFilter={changeHeaderFilter1}
              filterListByIsActive={filterListByIsActive}
              role={role}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      />
      <ConfirmModal
        open={openRemoveAlert}
        setOpen={setOpenRemoveAlert}
        setOpenRemoveAlert={setOpenRemoveAlert}
        confimClick={confimRemoveClick}
        message={
          "Are You Sure you want to delete?"
        }
        modalHeader="Alert Message"
      />
      <ModalCustom 
        open={openEditModal}
        handleClose={() => {
          setOpenEditModal(false);
          props.clearFields();
        }}
        title='Edit Employee Certification'
        closeButton={true}
      >
        <EmpCertificationManage
          detail={props.detail}
          setDetail={props.setDetail}
          userData={userData}
          currentEmpDetails={props.detail}
          employeeList={props.employeeList}
          saveData={handleUpdateEmpCertification}
          closeModal={() => {
            setOpenEditModal(false);
            props.clearFields();
          }}
          LkCertificationList={props.LkCertificationList}
          isEdit={true}
        />
      </ModalCustom>
    </>
  );
};

export default DataTable;