import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import Header from "./component/header";
import AddForm from "./component/addForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import DataTable from "./component/data-table";
import axios from "axios";
import { Card } from "reactstrap";

import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

export const basicSchema = yup.object().shape({
  ACTN_TYPE: yup
    .string()
    .min(1, "Must be 1 character or less")
    .max(1, "Must be 2 characters or less")

    .nullable()
    .default(undefined)
    .required("Enter Actn type"),

  DESCRIPTION: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter Description"),
});

const ActType = (props) => {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    oldID: "",
    ACTN_TYPE: "",
    DESCRIPTION: "",
  });
  const [tableList, setTableList] = useState([]);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}actType`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const clearField = () => {
    setDetail({
      oldId: "",
      ACTN_TYPE: "",
      DESCRIPTION: "",
    });
  };
  const onSubmit = (e) => {
    // console.log("props.detail xxxxx", JSON.stringify(checkvalid()));

    let paypload = {
      ACTN_TYPE: e.ACTN_TYPE,
      DESCRIPTION: e.DESCRIPTION,
    };
    if (detail.oldId) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}actType/update/${detail.oldId}`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          clearField();
          getData();
          console.log("Master responsexxxx", response.data);
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
          toast.error(error.message);
        });
    } else {
      console.log("PAy Load::: ", paypload);
      axios
        .post(`${process.env.REACT_APP_API_URL}actType/create`, paypload, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        })
        .then((response) => {
          clearField();
          getData();
          console.log("Master responsexxxx", response.data);
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
          toast.error(error.message);
        });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: detail,

    validationSchema: basicSchema,
    onSubmit,
  });

  useEffect(() => {
    setFieldValue("ACTN_TYPE", detail.ACTN_TYPE);
    setFieldValue("DESCRIPTION", detail.DESCRIPTION);
  }, [detail]);

  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" , }}>
    <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <Header
        {...props}
        detail={detail}
        getData={getData}
        setDetail={setDetail}
        clearField={clearField}
        saveData={handleSubmit}
      />
      </div>

      <div className={`${classes.root} w-100 page-content`}
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full`}>
          <Card className="mt-3 mb-3">
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange1("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Act Type Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0 page-body-wrapper">
                <AddForm
                  detail={detail}
                  setDetail={setDetail}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card>
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange1("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Act Types List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 page-body-wrapper">
                <DataTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  filterBy={detail}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </div>
      </div>
      </Container>
    </>
  );
};

export default ActType;
