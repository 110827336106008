import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const EmpDashDeducationToPlanPieChart = () => {
  const deducation = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.deducationtoPlan
  );
  let charData = [
    { name: "ABC11", amount: 125 },
    { name: "Xyz", amount: 152 },
    { name: "pqr", amount: 50 },
  ];
  let charData2 = [ { name: "ABC", amount: 25 } ];
  var pieColors = (function () {
      var colors = [],
          base = '#4ebfa9',
          i;
      let j = 1;
      for (i = 1; i < 10; i += 1) {
        colors.push(Highcharts.color(base).brighten((i - 5) / 10).get());
      }
      return colors;
      // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  }());
  const getChartDetails = (chart, type) => {
    if (type === "pie") {
      let series_name = "";
      let series_data = [];
      let empshare = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        chart_title = "Distribution amongst types of pay";
        series_data = charData2.map((i) => {
          return {
            name: i.name,
            y: i.amount,
          };
        });
      }

      if (chart == "deduction") {
        series_data = deducation.map((i) => {
          return {
            name: `${i.DeductionType} [${i.DeductionName}]`,
            y: i.GovermentDeduction + i.EmployeeDeduction,
          };
        });
        chart_title = "Deducation by Plan";
        empshare = deducation.map((i) => {
          return {
            name: i.DeductionType,
            y: i.GovermentDeduction + i.EmployeeDeduction,
          };
        });
      }

      let opPie = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: chart_title,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          
          pie: {
            allowPointSelect: true,
            colors: pieColors,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Deduction",
            colorByPoint: true,
            data: series_data,
          },
        ],
      };
      return opPie;
    }
    if (type == "column") {
      let series_name = "";
      let series_data = [];
      let series_category = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        series_data = charData2.map((i) => {
          return i.amount;
        });
        series_category = charData2.map((i) => {
          return i.name;
        });
        chart_title = "Distribution amongst types of pay";
      }
      if (chart == "deduction") {
        series_name = "Deduction Types";
        series_data = deducation.map((i) => {
          return i.GovermentDeduction + i.EmployeeDeduction;
        });
        series_category = deducation.map((i) => {
          return i.DeductionType;
        });
        chart_title = "Distribution amongst types of deduction";
      }
      let opCol = {
        chart: {
          type: "column",
        },
        title: {
          text: chart_title,
        },
        subtitle: {
          text: "Source:Payroll System",
        },
        xAxis: {
          categories: series_category,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Amount ($)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} $</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: series_name,
            data: series_data,
          },
        ],
      };

      return opCol;
    }
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChartDetails("deduction", "pie")}
    />
  );
};

export default EmpDashDeducationToPlanPieChart;
