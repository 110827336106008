import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Hidden,
  Switch,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import "./Modal.css";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getUserRole,
  getValidPaycodeOfDay,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import moment from "moment/moment";

const statusCheck = [
  {
    label: "Approved",
    value: "Approved",
  },
  { label: "Rejected", value: "Rejected" },
];
function PayrollAuthModalData({
  rowsData,
  deleteTableRows,
  confirmTableRows,
   
  setTimeTo,
  paytimeCode,
  paytimeCodeByID,
  getDateColor,
  setRowsData,
  payPeriod,
  authModal,
  setAuthModal,
  rowDisable,
  templateTitleCurrent
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextType, setSearchTextType] = useState("");
  const [defaultType, setDefaultType] = useState({});
  const { userData } = useAuth();
  const userRole = getUserRole(userData);
 
  

  useEffect(() => {
    const filterPaycodeList =  payCodeFilter(paytimeCode,paytimeCodeByID,userRole)
    setJobTypeOptions(filterPaycodeList)
   }, [paytimeCode,paytimeCodeByID])
 
   useEffect(() => {
     let UpdatedCodes = paytimeCode.map((i) => {
       if (["8", "110", "111", "112","113","114","115"].includes(i.code)) {
         i.disabled = true;
       } else {
         i.disabled = false;
       }
       return i;
     });
 
 
      setDefaultType(
       paytimeCode.find((i) => {
         return i.value + "" === "REG";
       })
     );
 
     let paytime = paytimeCode.filter((item) => {
       return ["10","11", "110", "111", "112","113","114","115"].includes(item.code)
        
        
     });
   const fiteterdArray = paytime.filter((i) => {
     if(jobTypeOptions.find((d) => d.code == i.code)){
       return i
     }
   }) 
 
     setHJobTypeOptions(fiteterdArray);
   }, [paytimeCode,jobTypeOptions]); 
        
        return (
          <>
            
            <Dialog
              open={authModal}
              fullWidth
              maxWidth="lg"
              // onClose={() => {
              //   setAuthModal(false);
              // }}
            >
              <DialogTitle>
                <Typography>
                  <h3>Authorized Hours - ({templateTitleCurrent})</h3>
                </Typography>
              </DialogTitle>
              <DialogContent>
                <table className="table paroll-contents-table w-full">
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Type</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Date</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Day</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>

                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      
                    </tr>
                  </thead>
                  
                  <tbody
                    className="bg-white  paroll-contents-tbody mt-2"
                    style={{ width: "100%" }}
                  >
                    {rowsData?.length && rowsData.map((data,index) => {
                       const {
                        jobtype,
                        date,
                        time_from,
                        time_to,
                        time_from1,
                        time_to1,
                        hrs,
                        jobOrder,
                        parentIndex,
                        hjobtype,
                        ole1,
                        ole2,
                        rowstatus,
                        dateExceed1,
                        dateExceed2
                      } = data;

                      const inputProps = {
                        style: { fontSize: 14, step: 300 },
                        step: 300,
                      };
              
                      let parentEnable =
                        parentIndex !== "0" && parentIndex != null ? true : false;
                      const isDisableRow = (parentIndex, jobtype, resource) => {
                        try {
                          if (jobtype === "NDIF") {
                            return true;
                          } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
                            if (
                              ["time_from", "time_to", "time_from1", "time_to1"].includes(
                                resource
                              )
                            ) {
                              return false;
                            } else {
                              return true;
                            }
                          } else {
                            return false;
                          }
                        } catch (error) {
                          console.log("error", error.message);
                        }
                      };
              
                      // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
                      const getSelectedType = (jobtype) => {
                        let filterObj = jobTypeOptions.find((i) => {
                          return i.value + "" === jobtype + "";
                        });
                        console.log("PPP ST", filterObj);
              
                        return filterObj !== undefined
                          ? filterObj
                          : {
                              value: "REG",
                              label: "REG",
                              title: "REGULAR",
                              code: "1",
                              multi: 1,
                              EffectDate: "1990-01-01T00:00:00.000Z",
                              ExpireDate: "2099-12-31T00:00:00.000Z",
                              name: "jobtype",
                            };
                      };
                      const getSelectedHType = (jobtype) => {
                        let filterObj = hjobTypeOptions.find((i) => {
                          return i.value + "" === jobtype + "";
                        });
                        console.log("PPP STD", filterObj);
              
                        return filterObj !== undefined ? filterObj : null;
                      };
                   return <>
                    <tr
                      key={index}
                      bordercolor="red"
                      className={index % 2 === 0 ? "trRow1" : "trRow2"}
                      style={{
                        marginBottom: 4,
                        marginTop: 5,
                        backgroundColor: getDateColor(date, rowsData),
                        
                      }}
                  
                    >
                      <td className="paroll-contents-td">
                        <Autocomplete
                          size={"small"}
                          id="combo-box-demo"
                          name="combo-box-demo"
                          // disabled={ parentEnable }
                          // disabled={isDisableRow(
                          //   parentIndex,
                          //   jobtype,
                          //   "jobtype"
                          // )}
                          readOnly={rowDisable}
                          autoHighlight={true}
                          autoSelect={true}
                          className="flex w-full mx-10 paroll-autocomplete "
                          
                          value={getSelectedType(jobtype)}
                          filterOptions={(item) => {
                            return item.filter((i) =>
                              i.code
                                ? i.code
                                    .replace(/-/g, "")
                                    .toLowerCase()
                                    .match(
                                      `${searchTextType
                                        .replace(/-/g, "")
                                        .toLowerCase()}`
                                    )
                                : ""
                            );
                          }}
                        
                          options={getValidPaycodeOfDay(date, jobTypeOptions)}
                          getOptionDisabled={(jobTypeOptions) =>
                            !!jobTypeOptions.disabled
                          }
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="MuiAutocomplete-input"
                              size="small"
                              // label="Pay Type"
                              variant="outlined"
                              
                              hiddenLabel="true"
                              input
                              inputProps={{
                                ...params.inputProps,
                                style: { fontSize: 14 },
                              }}
                               
                            />
                          )}
                        />

                     
                      </td>
                      <td className="paroll-contents-td">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className="w-100 payroll-content-date1"
                            // margin="dense"
                            size="small"
                            format="MM/dd/yyyy"
                            disableToolbar
                            inputVariant={"outlined"}
                            id="date-picker-inline"
                            
                            // label="Date"
                            value={date}
                            minDate={
                              payPeriod.StartDate ? payPeriod.StartDate : null
                            }
                            maxDate={
                              payPeriod.EndDate ? payPeriod.EndDate : null
                            }
                            
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            readOnly={rowDisable}
                            name="date"
                            // variant="outlined"
                            hiddenLabel="true"
                          />
                        </MuiPickersUtilsProvider>
                   
                       </td>
                       <td className="paroll-contents-td">
                     

                        <TextField
                          size={"small"}
                          id="time"
                          // label="From"
                          // type="time"
                          inputProps={inputProps}
                          error={ole1 || dateExceed1 ? true : false}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                            },
                            shrink: true,
                          }}
                          value={date ? moment(date,"YYYY-MM-DD").format("dddd") : ""}
                          margin="dense"
                          name="time_from"
                          fullWidth={true}
                          variant="outlined"
                          readOnly={rowDisable}
                          hiddenLabel="true"
                        />

                      
                      </td>
                      <td className="paroll-contents-td">
                     

                        <TextField
                          size={"small"}
                          id="time"
                          // label="From"
                          // type="time"
                          inputProps={inputProps}
                          error={ole1 || dateExceed1 ? true : false}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                            },
                            shrink: true,
                          }}
                          value={time_from ? moment(time_from,"HH:mm:ss").format("hh:mm A") : ""}
                           
                       

                          margin="dense"
                          name="time_from"
                          fullWidth={true}
                          variant="outlined"
                         
                          // disabled={isDisableRow(
                          //   parentIndex,
                          //   jobtype,
                          //   "time_from"
                          // )}
                          readOnly={rowDisable}
                          hiddenLabel="true"
                        />

                      
                      </td>
                      <td className="paroll-contents-td">
                        <TextField
                          size={"small"}
                          id="time"
                          // label="To"

                          // type="time"
                          inputProps={inputProps}
                          error={ole1 || dateExceed1 ? true : false}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                            },
                            shrink: true,
                          }}
                          value={time_to ? moment(time_to,"HH:mm:ss").format("hh:mm A") : ""}
                           
                          
                          margin="dense"
                          name="time_to"
                          fullWidth={true}
                          variant="outlined"
                        
                          // disabled={isDisableRow(
                          //   parentIndex,
                          //   jobtype,
                          //   "time_to"
                          // )}
                          readOnly={rowDisable}
                        />
                      </td>
                      <td className="paroll-contents-td">
                        <TextField
                          size={"small"}
                          id="time"
                          // label="From"
                          // type="time"
                          inputProps={inputProps}
                          error={ole2 || dateExceed2 ? true : false}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                            },
                            shrink: true,
                          }}
                          value={time_from1 ? moment(time_from1,"HH:mm:ss").format("hh:mm A") : ""}
                          margin="dense"
                          name="time_from1"
                          fullWidth={true}
                          variant="outlined"
                        
                          // disabled={isDisableRow(
                          //   parentIndex,
                          //   jobtype,
                          //   "time_from1"
                          // )}
                          readOnly={rowDisable}
                        />
                      </td>
                      <td className="paroll-contents-td">
                        <TextField
                          size={"small"}
                          id="time"
                          // label="To"
                          // type="time"
                          inputProps={inputProps}
                          error={ole2 || dateExceed2 ? true : false}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                            },
                            shrink: true,
                          }}
                           value={ time_to1 ? moment(time_to1,"HH:mm:ss").format("hh:mm A") : "" }
                           
                        
                          
                          margin="dense"
                          name="time_to1"
                          fullWidth={true}
                          variant="outlined"
                         
                          // disabled={isDisableRow(
                          //   parentIndex,
                          //   jobtype,
                          //   "time_to1"
                          // )}
                          readOnly={rowDisable}
                        />
                      </td>
                      <td className="paroll-contents-td">
                        <TextField
                          size={"small"}
                          margin="dense"
                          // label="Hrs"
                          name="hours"
                          inputProps={{
                            readOnly: true,
                            fontSize: 12,
                            style: { fontSize: 14 },
                          }}
                          fullWidth={true}
                          variant="outlined"
                          value={hrs}
                          sx={{ fontSize: 14 }}
                          // disabled={isDisableRow(parentIndex, jobtype, "hrs")}
                          readOnly={rowDisable}
                        />
                      </td> 
                    </tr> 
                    </>
                    })}
                  </tbody>
                </table>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    setAuthModal(false);
                  }}
                  className="bg-primary"
                  style={{ color: "white" }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
    
   
}

export default PayrollAuthModalData;
