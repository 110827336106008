import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

function FilterList(props) {
  const { changeHeaderFilter, filterBy } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Id"
          name="id"
          value={filterBy.id}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Code"
          name="code"
          value={filterBy.code}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="disabilityInsurance"
          placeholder="Disability Insurance"
          value={filterBy.disabilityInsurance}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="stateIncomeTax"
          placeholder="State Income Tax"
          value={filterBy.stateIncomeTax}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="localTax"
          placeholder="Local Tax"
          value={filterBy.localTax}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="familyLeaveInsurance"
          placeholder="Family Leave Insurance"
          value={filterBy.familyLeaveInsurance}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="stateIndustrialInsurance"
          placeholder="State Industrial Insurance"
          value={filterBy.stateIndustrialInsurance}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="numberOfQualifyingChildrenUnderAge17"
          placeholder="No. Of Qualifying Children Under Age 17"
          value={filterBy.numberOfQualifyingChildrenUnderAge17}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="numberOfOtherDependents"
          placeholder="No. Of Other Dependents"
          value={filterBy.numberOfOtherDependents}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="medicareTax"
          placeholder="Medicare Tax"
          value={filterBy.medicareTax}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="socialSecurityTax"
          placeholder="Social Security Tax"
          value={filterBy.socialSecurityTax}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell>

    </TableRow>
  );
}

export default FilterList;