import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";
import { CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMessageExclamation,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { Row } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";

function HomeHeader(props) {
  let dispatch = useDispatch();
  const checkvalid = () => {
    if (
      props.detail.code == "" ||
      props.detail.description == "" ||
      props.detail.abbreviation == "" ||
      props.detail.effectDate == null ||
      props.detail.effectDate == "" ||
      props.detail.expireDate == null ||
      props.detail.expireDate == "" ||
      props.detail.multi == ""
    ) {
      toast.error("Please validate values!");
      // dispatch();
      // showMessage({
      //   message: "Please validate values!",
      //   variant: "error",
      // })
      return false;
    } else {
      return true;
    }
  };
  const clearField = () => {
    props.setDetail({
      id: "",
      code: "",
      description: "",
      abbreviation: "",
      effectDate: null,
      expireDate: null,
      multi: "",
    });
  };
  const saveData = () => {
    if (!checkvalid()) {
      return false;
    }

    let paypload = {
      code: props.detail.code,
      description: props.detail.description,
      abberv: props.detail.abbreviation,
      effectDate: moment(props.detail.effectDate),
      expireDate: moment(props.detail.expireDate),
      multi: props.detail.multi,
    };
    if (props.detail.id) {
      paypload = {
        code: props.detail.code,
        description: props.detail.description,
        abberv: props.detail.abbreviation,
        effectDate: moment(props.detail.effectDate),
        expireDate: moment(props.detail.expireDate),
        multi: props.detail.multi,
      };
      axios
        .put(
          `${process.env.REACT_APP_API_URL}paytimecode/update/${props.detail.id}`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          dispatch();
          // showMessage({
          //   message: "Updated successfully!",
          //   variant: "success",
          // })
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}paytimecode/create`, paypload, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        })
        .then((response) => {
          dispatch();
          // showMessage({
          //   message: "Added successfully!",
          //   variant: "success",
          // })
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    }
  };

  return (
    <>
      <CardHeader>
        <div>
          <div
            className={`flex flex-1 w-full bg-primary`}
            style={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "#1975D2",
              height: "4.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faAddressCard}
              className="mx-10  px-3 fs-4"
            />
            <Typography className="fs-5">Pay Codes Management</Typography>

            <div style={{ display: "flex", flex: 1 }}></div>
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#234d10",
                backgroundColor: "#ffffff",
              }}
              onClick={saveData}
            >
              <FontAwesomeIcon
                icon={faSave}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12">
                  {" "}
                  {props.detail.id ? "Update" : "Save"}{" "}
                </Typography>
              </Hidden>
            </Button>
            {props.detail.id ? (
              <Button
                variant="contained"
                className="m-10 mx-2 xs:mx-10 min-w-0"
                style={{
                  color: "#d0efe9",
                  backgroundColor: "#61bdaa",
                }}
                onClick={clearField}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  style={{ color: "#24695c", marginRight: "10px" }}
                />
                <Hidden smDown>
                  <Typography className="text-12"> Cancel </Typography>
                </Hidden>
              </Button>
            ) : null}
          </div>
        </div>
      </CardHeader>
    </>
  );
}

export default HomeHeader;
