
import React from "react";
import Icon from "@material-ui/core/Icon";
import {
  Autocomplete,
  Button,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faFileExport,
  faAddressCard,
  faClock,
  faPlay,
  faStop,
} from "@fortawesome/pro-duotone-svg-icons";



import { connect } from "react-redux";
import { CardHeader } from "reactstrap";
import moment from "moment";

function TimeClockHead(props) {

  const {
    setPaycodes,
    time,
    startStopCheck,
    handleStart,
    handleStop,
    handleClickOpen,
    getValidPaycodeOfDay,
    getSelectedType,
    paycodes,
    jobTypeOptions
  } = props
 
  return (
    <CardHeader>
      <div
        className={`flex flex-1 w-full bg-primary`}
        style={{
          display: "flex",
          flexDirection: "row",
          // backgroundColor: "#1975D2",
          height: "4.2rem",
          borderRadius: "5px",
          alignItems: "center",
          justifyContent:"space-between"
        }}
      >
        <div className="d-flex">
        <FontAwesomeIcon icon={faClock} className="mx-10  px-3 fs-4" />
        <Typography className="fs-5">Time Clock</Typography>
        </div>
        <div style={{ display: "flex" }}>
            <div style={{ marginTop: "3px" }}>
              <Autocomplete
                size={"small"}
                id="combo-box-demo"
                name="combo-box-demo" 
                style={{color:"white"}}
                autoHighlight={true}
                autoSelect={true}
                className="flex w-full mx-10 paroll-autocomplete "
                onChange={(e, value) => {
                  setPaycodes({ value: value?.code, label: value?.label });
                }}
                value={getSelectedType(paycodes.label)} 
                options={getValidPaycodeOfDay(
                  moment().format("YYYY-MM-DD"),
                  jobTypeOptions
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="MuiAutocomplete-input"
                    size="small"
                    style={{color:"white"}}
                    variant="outlined"
                    hiddenLabel="true"
                    input
                    
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                    }}
                   
                  />
                )}
              />
            </div>
            <div className="timerbg" title="Working Hours">
              <span>{time.hours.toString().padStart(2, "0")}</span>:
              <span>{time.minutes.toString().padStart(2, "0")}</span>:
              <span>{time.seconds.toString().padStart(2, "0")}</span>
            </div>
            {!startStopCheck ? (
              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  variant="contained"
                  onClick={handleStart}
                  
                  className="p-1"
                  size="medium"
                  style={{
                    color: "black",
                    width: "100px",
                     
                     backgroundColor:"white"
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlay}
                    
                    style={{ marginRight: "5px" }}
                    //className="bg-primary"
                  />
                  <Hidden smDown>
                    <FontAwesomeIcon icon="fa-solid fa-play" />
                    <Typography className="text-12">Start</Typography>
                  </Hidden>
                </Button>
              </div>
            ) : (
              <>
                {/* {!pause ?
                <div  style={{ padding: "10px"  }}>
                  {" "}
                  <Button
                    variant="contained"
                    onClick={handlePause}
                    // disabled={check || hasError}
                    className="p-1  bg-primary"
                    size="medium"
                    style={{
                      color: "#d0efe9",
                      // backgroundColor: "#61bdaa",
                    }}
                  >
                    <Hidden smDown>
                      <Typography className="text-12">Pause</Typography>
                    </Hidden>
                  </Button>
                </div> : 
                <div  style={{ padding: "10px"  }}>
                {" "}
                <Button
                  variant="contained"
                  onClick={handleResume}
                  // disabled={check || hasError}
                  className="p-1  bg-primary"
                  size="medium"
                  style={{
                    color: "#d0efe9",
                    // backgroundColor: "#61bdaa",
                  }}
                >
                  <Hidden smDown>
                    <Typography className="text-12">Resume</Typography>
                  </Hidden>
                </Button>
              </div>
                } */}
                <div style={{ padding: "10px" }}>
                  {" "}
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (time?.hours <= 0 && time.minutes <= 15) {
                        handleClickOpen();
                      } else {
                        handleStop();
                      }
                    }}
                    
                    className="p-1 "
                    size="medium"
                    style={{
                      color: "black",
                      width: "100px",
                      backgroundColor:"white"

                     
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faStop}
                     
                      style={{ marginRight: "5px" }}
                      // className="bg-p"
                    />
                    <Hidden smDown>
                      <Typography   className="text-12">Stop</Typography>
                    </Hidden>
                  </Button>
                </div>
              </>
            )}
          </div>
      </div>
   
    </CardHeader>
  );
}

export default TimeClockHead;
