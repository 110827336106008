import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card } from "reactstrap";
import TableComponent from "../../ConfigurationMaster/DeductionPlanMaster/tableComponent";
import EmployeeFilterList from "./EmployeeFilterList";
import Typography from "@material-ui/core/Typography";
import { useNavigate, useParams } from "react-router-dom";
import Header1 from "./component/header1";
import { Tooltip } from "@mui/material";
import { faEye, faCalendar } from "@fortawesome/pro-duotone-svg-icons";
import MUIDataTable from "mui-datatables";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

// const useStyles = makeStyles(() => ({
//   tableIcon: {
//     color: "#0000008a",
//     borderRadius: "50%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     width: "40px",
//     height: "40px",
//     "&:hover": {
//       backgroundColor: "rgba(0, 0, 0, 0.04)",
//     },
//   },
//   paperStyle: {
//     cursor: "pointer",
//     boxShadow:
//       "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
//     "&:hover": {
//       backgroundColor: "rgba(0, 0, 0, 0.08)",
//     },
//   },
//   textLabels: {
//     "& > span": {
//       marginTop: "0.2rem",
//     },
//   },
//   scrollHeight: {
//     height: "calc(100vh - 100px - 235px)",
//     overflow: "auto",
//   },
//   tableCard: {
//     minWidth: "800px",
//     overflowX: "auto",
//   },
// }));
const EmployeeListView = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useNavigate();

  const [requestDetail, setReqestDetail] = useState([]);

  const [detail, setDetail] = useState({
    ID: "",
    EmployeeName: "",
    DPDV_NAME: "",
    Emp_Status: "",
    PPE: "",
    WorkingHours: "",
  });

  useEffect(() => {
    handleClick();
  }, []);

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    DPDV_NAME: "",
    Emp_Status: "",
    PPE: "",
    WorkingHours: "",
  });

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    setFilter({ ...filterBy, [name]: value });
  };

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLocaleLowerCase()
          )
        );
      }
      if (filterBy?.DPDV_NAME) {
        dataList = dataList.filter((data) =>
          data.DPDV_NAME.toLowerCase().includes(
            filterBy.DPDV_NAME.toLocaleLowerCase()
          )
        );
      }
      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLocaleLowerCase()
          )
        );
      }
      if (filterBy?.Emp_Status) {
        dataList = dataList.filter((data) =>
          data.Emp_Status.toLowerCase().includes(
            filterBy.Emp_Status.toLocaleLowerCase()
          )
        );
      }
      if (filterBy?.PPE) {
        dataList = dataList.filter((data) =>
          data.PPE.toString()
            .toLowerCase()
            .includes(filterBy.PPE.toString().toLocaleLowerCase())
        );
      }
      if (filterBy?.WorkingHours) {
        dataList = dataList.filter((data) =>
          data.WorkingHours.toString()
            .toLowerCase()
            .includes(filterBy.WorkingHours.toString().toLocaleLowerCase())
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const handleRowClick = (data) => {
    console.log("data row", data);
    data = data.data;
    let info = {
      oldId: data.ETHNIC_CODE,
      ethnicCode: data.ETHNIC_CODE,
      ethnicTitle: data.ETHNIC_TITLE,
    };

    setReqestDetail(info);
  };

  const columns = [
    {
      id: "ACTION",
      name: "PPE",
      label: "ACTIONS",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue, PPE) => {
          return (
            <div style={{ display: "flex" }}>
              {/* <Tooltip title="Employees List" placement="bottom">
                <FontAwesomeIcon
                  className="sub-header"
                  onClick={() => {
                    // return history(
                    //   `/admin/working-hrs/employee/${tableMeta.DPDV}/${params.id}`
                    // );
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip> */}
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    // console.log(value);

                    return history(`/admin/employee/update/${tableMeta.rowData[1]}`);
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
              <Tooltip title="View Time Sheet" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(
                      `/payroll-work/${tableMeta.rowData[1]}/${value}`
                    );
                  }}
                  // className="sub-header"
                  icon={faCalendar}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    { id: "ID", name: "ID", label: "ID", type: "" },
    {
      id: "EmployeeName",
      name: "EmployeeName",
      label: "Employee Name",
    },
    {
      id: "DPDV_NAME",
      name: "DPDV_NAME",
      label: "Department Name",
    },
    {
      id: "PPE",
      name: "PPE_Date",
      label: "PPE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("MM-DD-yyyy") : "-";
        },
      },
    },
    {
      id: "WorkingHours",
      name: "WorkingHours",
      label: "Working Hours",
    },
    // {
    //   id: "Emp_Status",
    //   name: "Emp_Status",
    // },
  ];

  const handleClick = (data) => {
    console.log("MMMYYY");
    // https://api.ehrpweb.com/api/advancesearch/v2

    let adv_issue_query = JSON.parse(localStorage.getItem("adv_issue_query"));
    let oldParams = adv_issue_query.params;
    const dpdvid = params.id;
    const stateVal = params.stat;
    const ppeId = params.ppe;
    console.log("Pramsx", params);

    console.log("oldParams", oldParams);
    if (dpdvid != 0) {
      oldParams.push({
        value: dpdvid,
        operator: "=",
        column: "DPDV",
        dataType: "VARCHAR(MAX)",
        columnName: "DPDV",
      });
    }
    // if (ppeId) {
    //   oldParams.push({
    //     value: ppeId,
    //     operator: "=",
    //     column: "PPE",
    //     dataType: "VARCHAR(MAX)",
    //     columnName: "PPE",
    //   });
    // }

    let findStat = oldParams.find((i) => {
      return i.column == "Stat";
    });
    if (!findStat) {
      oldParams.push({
        value: stateVal,
        operator: "=",
        column: "Stat",
        dataType: "VARCHAR(MAX)",
        columnName: "Stat",
      });
    }
    let findPPE = oldParams.find((i) => {
      return i.column == "PPE";
    });
    if (!findPPE && (ppeId != 0 || !ppeId)) {
      oldParams.push({
        value: ppeId,
        operator: "=",
        column: "PPE",
        dataType: "",
        columnName: "PPE",
      });
    }

    console.log("oldParams", oldParams);

    /**
     * 
     * [ { "value": "16", "operator": "=", "column": "PPE", "dataType": "", "columnName": "PPE" }
    ,
    {"value":"Employees with 80 Hours","operator":"=","column":"Stat","dataType":"VARCHAR(MAX)","columnName":"Stat"},
    {"value":"1620","operator":"=","column":"DPDV","dataType":"VARCHAR(MAX)","columnName":"DPDV"}
]
     */

    if (oldParams.length) {
      oldParams = oldParams.filter((i) => {
        return i.column != "EMP_CNT";
      });
      oldParams = oldParams.filter((i) => {
        return i.column != "DEPT_CNT";
      });
    }

    let payload = {
      tableName: "sp_findEmployeeByIssueAndDepartment",
      params: oldParams,
      columns: "*",
      page: 1,
      limit: 2000,
      orderBy: "PPE",
      sortBy: "DESC",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}advancesearch/v2`, payload, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("second api", response.data);
        setReqestDetail(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <>
      {/* <Header1 {...props} title="Employee List" /> */}
      <Header1
        title={`Payroll Processing Exception Reports `}
      />

      {/* <TableComponent
        columns={columns}
        filteredList={filteredList}
        // onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <EmployeeFilterList
              changeHeaderFilter1={changeHeaderFilter1}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      /> */}
      <div style={{ width: "100%" }}>
        <MUIDataTable
          className={classes.customData}
          title={<Typography variant="h6">Employee List</Typography>}
          data={requestDetail}
          columns={columns}
          options={{
            selectableRows: false, // <===== will turn off checkboxes in rows
            jumpToPage: true,
          }}

          // selectableRowsHideCheckboxes={false}
        />
      </div>
    </>
  );
};

export default EmployeeListView;
