import React, { useEffect, useState } from "react";
import { Card, FormControl, Grid, TextField } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form } from "reactstrap";
import dayjs from "dayjs";

function W2ReportLayout(props) {
  const { earning, getRecordDate, setGetRecordDate } = props;

  return (
    <div className="w-full h-full reportTbl CardBack">
      <div className="mt-5  ">
        <div style={{ marginTop: "20px" }}>
          <Card
            style={{
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            elevation={3}
            className="body-Color2"
          >
            <Grid container spacing={2}>
              <Grid item xs={18} sm={8} lg={8} style={{ textAlign: "center" }}>
                <table className="padTbl">
                  <tbody>
                  <tr>
                  </tr>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          Select Year:
                        </b>
                      </td>
                      <div style={{ display: "flex", flex: 1 }} className="mx-4">
                        <Form>
                        {/* <FormControl className="payperiodRange" style={{ border: '1px solid lightgrey', borderRadius: '7px' }}> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              views={['year']}
                              label="Year"
                              value={dayjs(getRecordDate?.startDate)}
                              onChange={(e) => {
                                const formatDate = e.$d;
                                const value = moment(formatDate).format("YYYY-MM-DD");
                                setGetRecordDate({ ...getRecordDate, startDate: `01-01-${e?.$y}`, endDate: `12-31-${e?.$y}` });
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                          </LocalizationProvider>

                            {/* <TextField
                                size="small"
                                select
                                name="payperiod_range"
                                id="payperiod_range"
                                className="min-w-128 text-16"
                                style={{ color: "red" }}
                                variant="outlined"
                                value={payPeriod ? payPeriod["ID"] : 0}
                                margin="dense"
                                onChange={(event) => {
                                let info = allPayPeriod.find((i) => {
                                    return i.ID.toString() === event.target.value + "";
                                });
                                setPayPeriod(info);
                                }}
                                SelectProps={{
                                native: true,
                                }}
                                inputProps={{
                                style: { color: "grey" },
                                }}
                            >
                                {allPayPeriod.length &&
                                allPayPeriod.map((option, index) => (
                                    <option
                                    key={index}
                                    value={option.ID}
                                    style={{ backgroundColor: "white", color: "black" }}
                                    >
                                    {moment(option.StartDate).format("MM-DD-YYYY")} TO {moment(option.EndDate).format("MM-DD-YYYY")}
                                    </option>
                                ))}
                            </TextField> */}
                        {/* </FormControl> */}
                        </Form>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default W2ReportLayout;
