import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const sliceName = "querySlice";

export const fetchPlaceholderCols = createAsyncThunk(
  `${sliceName}/fetchPlaceholderCols`,
  async (_, { object }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search/employee/columns`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      let columns = response.data;
      console.log("columns", columns);
      return {
        colsPlaceholder: columns,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);
export const fetchSearchColumns = createAsyncThunk(
  `${sliceName}/fetchSearchColumns`,
  async (obj) => {
    try {
      const response = await axios.get(
        // `https://my.pinpointguam.com/api/user/groups`,sp_findIssueForDepartment
        `${process.env.REACT_APP_API_URL}/advancesearch/v2/columns/${obj.tableName}`,

        // {
        //   headers: {
        //     Authorization: "Bearer " + uuid,
        //   },
        // }
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      const searchColumns = response.data;
      console.log("searchColumns ", response.data);
      return {
        colsPlaceholder: searchColumns,
      };
    } catch (e) {
      console.log(e.message);
      return {
        colsPlaceholder: [],
      };
    }
  }
);
export const saveQueries = createAsyncThunk(
  `${sliceName}/saveQueries`,
  async (uuid) => {
    try {
      const response = await axios.get(`/query/save`, {
        headers: {
          Authorization: `Bearer ${uuid}`,
        },
      });

      return {
        queries: response.data.result,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const changeQuery = createAsyncThunk(
  `${sliceName}/changeQuery`,
  async (resQuery) => {
    // console.log(resQuery);
    try {
      return {
        newQuery: resQuery,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const savedQueries = createAsyncThunk(
  `${sliceName}/savedQueries`,
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/advancesearch/v2/searchquery`,
        {
          tableName: payload.tableName,
        },
        // `${process.env.REACT_APP_API_URL}query/userquery`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      // const userSavedQueries = response.data.result;
      // const groupSavedQueries = response.data.group;
      console.log("response save", response.data);
      const userSavedQueries = response.data;
      return {
        userSavedQueries: userSavedQueries,
        groupSavedQueries: [],
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const userGroupStore = createAsyncThunk(
  `${sliceName}/userGroupStore`,
  async (uuid) => {
    console.log("CALL groups store");
    try {
      const response = await axios.get(
        // `https://my.pinpointguam.com/api/user/groups`,
        `${process.env.REACT_APP_API_URL}/search/group`,

        // {
        //   headers: {
        //     Authorization: "Bearer " + uuid,
        //   },
        // }
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      const groups = response.data;
      console.log("groups ", response.data);
      return {
        groups: groups,
      };
    } catch (e) {
      console.log(e.message);
    }
  }
);

const querySlice = createSlice({
  name: sliceName,
  initialState: {
    loading: false,

    queries: [
      {
        value: "",
        operator: "=",
        column: "",
        condition: "AND",
      },
    ],
    initialValues: {
      query_name: "",
      user_group_id: null,
      queries: [
        {
          value: "",
          operator: "=",
          column: "",
          condition: "AND",
        },
      ],
    },
    groups: [],
    reRun: false,
    userSavedQueries: [],
    groupSavedQueries: [],
  },
  extraReducers: {
    [fetchPlaceholderCols.fulfilled]: (state, action) => {
      console.log("action", action);
      state.colsPlaceholder = action?.payload?.colsPlaceholder || [];
    },
    [fetchSearchColumns.fulfilled]: (state, action) => {
      console.log("action", action);
      state.colsPlaceholder = action?.payload?.colsPlaceholder || [];
    },

    [saveQueries.fulfilled]: (state, action) => {
      state.queries = action?.payload?.queries || [];
    },
    [userGroupStore.fulfilled]: (state, action) => {
      state.groups = action.payload.groups;
    },
    [saveQueries.rejected]: (state, action) => {
      state.error = action.payload.error;
    },

    [changeQuery.fulfilled]: (state, action) => {
      state.initialValues.queries = action.payload.newQuery;
      state.queries = action.payload.newQuery;
      state.reRun = true;
    },

    [savedQueries.fulfilled]: (state, action) => {
      state.userSavedQueries = action.payload.userSavedQueries;
      state.groupSavedQueries = action.payload.groupSavedQueries;
    },
  },
});

export default querySlice.reducer;
