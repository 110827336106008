import React, { useState } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Tooltip, Typography } from "@mui/material";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { faEye, faCalendar, faQrcode } from "@fortawesome/pro-duotone-svg-icons";
import { TelerikCustomMenu } from "../../../employee/component/telerikCustomMenu";
import { useNavigate } from "react-router-dom";
import ColumnFilter from "../../../employee/component/ColumnFilter";

const initialDataState = {
  sort: [
    {
      field: "code",
      dir: "asc",
    },
  ],
  take: 10,
  skip: 0,
};
const TelerikDataTable = (props) => {
  let gridPDFExport;
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const history = useNavigate();
  const [dataState, setDataState] = React.useState(initialDataState);
  const [printDataModal, setPrintDataModal] = useState(false);
 
  let { setSortedColumns, selectedColumns, pathExit, defaultDeductionType, setIsDeductionDetailsShow } = props;

  const updateLockColumn = async (col, status) => {
    let copyArray = [...selectedColumns];
    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field == col;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["locked"] = status;
      copyArray[objectAtIndex] = copyObject;
      setSortedColumns(copyArray);
    }
  };

  let GridComponent = (
    <Grid
      style={{
        height: "700px",
      }}
      data={process(props.data, dataState)}
      {...dataState}
      reorderable={true}
      pageable={true}
      sortable={true}
      groupable={true}
      // filterable={ true }
      resizable={true}
      onDataStateChange={(e) => {
        setDataState(e.dataState);
      }}
    >
      <GridToolbar>
        {/* <Tooltip title="Export Excel" placement="bottom">
          <IconButton
            // className="bg-primary"
            // style={{ color: "white" }}
            aria-label="more"
            id="long-button"
            // aria-controls={open ? "long-menu" : undefined}
            // aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={excelExport}
          >
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export PDF" placement="bottom">
          <IconButton
            aria-label="more"
            id="long-button"
            // aria-controls={open ? "long-menu" : undefined}
            // aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            // onClick={exportPDF}
            onClick={() => setPrintDataModal(true)}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip> */}

        <ColumnFilter
          columns={selectedColumns}
          setSortedColumns={setSortedColumns}
        />
      </GridToolbar>
      <GridColumn
        field="ID"
        title="Actions"
        width="140px"
        cell={(props) => (
          <td {...props}>
            <div style={{ display: "flex", columnGap: "10px" }}>
                <Tooltip
                    title={defaultDeductionType === "GARNISHMENT" ? "Add Garnishment" :
                        defaultDeductionType === "BENEFITS" ? "Add Benefits" :
                        defaultDeductionType === "MISCELLANEOUS" ? "Add Miscellaneous" :
                        defaultDeductionType === "RETIREMENT-PLANS" ? "Add Retirement" : 'Add'
                    }
                    placement="bottom"
                >
                <span
                  onClick={() => {
                    // setIsDeductionDetailsShow(true);
                    let getPath = defaultDeductionType === "GARNISHMENT" ? "garnishment" :
                    defaultDeductionType === "BENEFITS" ? "benefit" :
                    defaultDeductionType === "MISCELLANEOUS" ? "miscellaneous" :
                    defaultDeductionType === "RETIREMENT-PLANS" ? "retirement-plan" : '';
                    return history(`/master/${getPath}/update/${props?.dataItem?.ID}`, { state: { employeeId: props?.dataItem?.ID, employeeName: props?.dataItem?.EmployeeName } });
                  }}
                  className="k-icon k-i-eye k-icon-64 k-cursor"
                  style={{ cursor: "pointer" }}
                ></span>
              </Tooltip>
              {/* <Tooltip title="View Time Sheet" placement="bottom">
                <span
                  className="k-icon k-i-calendar k-icon-48 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    return history(`/payroll-work/${props.dataItem.ID}`);
                  }}
                ></span>
              </Tooltip> */}
            </div>
          </td>
        )}
      />
      {/* <GridColumn field="ID2" title="ID2" locked={true} width="140px" cell={()=>{<div>India</div>}} minResizableWidth={30}/> */}
      {/* <GridColumn field="EmployeeName_FML" filter="date" locked={ true } title="EmployeeName FML" width="250px"
          columnMenu={(props) => (
              <TelerikCustomMenu
                {...props}
                onColumnLockToggle={(col)=>{console.log('colname',col);
                }}
                // locked={item.locked || false}
              />
            )}
          /> */}

      {props.selectedColumns && props.selectedColumns.length
        ? props.selectedColumns
            .filter((i) => i.show)
            .map((i) => {
              return (
                <GridColumn
                  field={i.field}
                  filter={i.filter}
                  locked={i.locked}
                  title={i.title}
                  width={i.width}
                  columnMenu={(props) => (
                    <TelerikCustomMenu
                      locked={i.locked}
                      {...props}
                      onColumnLockToggle={
                        (col, status) => {
                          updateLockColumn(col, status);
                        }
                        // props.onColumnLockToggle(col, !i.field);
                      }
                      // locked={item.locked || false}
                    />
                  )}
                />
              );
            })
        : null}
      {/* <GridColumn field="Nposition_No" locked={true} title="Nposition No" width="250px"/>
    <GridColumn field="Ethnic_Group" title="Ethnic Group" />
    <GridColumn field="Hm_Phone"  title="phone" width="250px" />
    <GridColumn field="Handi_Cond"  title="Handi cond" width="250px" />
    <GridColumn field="Hm_Add_Ln1"  title="Addreess Line 1" width="250px" />
    <GridColumn field="Hm_Add_City"  title="City" width="250px" />
    <GridColumn field="Sex" title="Sex" /> */}
    </Grid>
  );
  return (
    <>
      <ExcelExport data={props.data} ref={_export}>
        {GridComponent}
      </ExcelExport>
    </>
  );
};

export default TelerikDataTable;