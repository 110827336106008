import React, { useState } from "react";
import { FormControl, FormHelperText, InputAdornment, TextField, } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomTextField = ({ name, type = "text", className, InputLabelProps, errorMessage, prefixIcon, postfixIcon, onChange, label, value, ...props }) => {
    const [isSelected, setIsSelected] = useState(false);

    const iconAdornment = ((!!value || (value == 0 && value != "") || isSelected))
        ? {
            startAdornment: <InputAdornment position="start" className="mr-4">{prefixIcon}</InputAdornment>,
            endAdornment: <InputAdornment position="end" className="mr-4">{postfixIcon}</InputAdornment>
        }
        : {};

    return <FormControl
        fullWidth
        size="small"
        margin="dense"
        error={!!errorMessage}
    >
        <TextField
            variant="outlined"
            size="small"
            type={type}
            fullWidth
            label={label}
            onChange={onChange}
            value={value}
            error={!!errorMessage}
            onFocus={e => setIsSelected(true)}
            onBlur={e => setIsSelected(false)}
            InputProps={iconAdornment}
            InputLabelProps={{ ...InputLabelProps }}
            className={`${className}`}
            {...props}
        />
        <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
};

export const CustomTextFieldWithController = ({ name, control, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
                return <CustomTextField onChange={onChange} onBlur={onBlur} value={value} {...props} />
            }}
        />
    );
};

export default CustomTextField;