import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import MaskedInput from "react-text-mask";
let departArray = [
  { name: "Office of Technology", amount: 1028286.9 },
  { name: "Bereau of Women's Affairs", amount: 822629.52 },
  { name: "Department of Administration", amount: 616972.14 },
  { name: "Department of Agriculture", amount: 1233944.28 },
  { name: "Department of Chamorro Affairs", amount: 154243.04 },
  { name: "Department of Corrections", amount: 1028286.9 },
  { name: "Department of Parks & Rec", amount: 1542430.35 },
  { name: "Department of Land Management", amount: 1542430.35 },
  { name: "Department of Labor", amount: 2313645.53 },
];
function ReportChartLayout(props) {
  var pieColors = (function () {
    var colors = [],
      base = "#4ebfa9",
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 4) / 9)
          .get()
      );

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let [chartType, setChartType] = useState("column");
  let [charData, setCharData] = useState(departArray);
  let [charData2, setCharData2] = useState([]);
  let { deduction, earning } = props;

  const getChartDetails = (chart, type) => {
    if (type == "pie") {
      let series_name = "";
      let series_data = [];
      let chart_title = "Column chart";

      if (chart == "deduction") {
        series_data = charData.map((i) => {
          return {
            name: i.name,
            y: i.amount,
          };
        });
        chart_title = "Distribution amongst departments";
      }

      let opPie = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: chart_title,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors: pieColors,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: series_data,
          },
        ],
      };
      return opPie;
    }
    if (type == "column") {
      let series_name = "";
      let series_data = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        series_data = charData2.map((i) => {
          return i.amount;
        });
        chart_title = "Distribution amongst types of pay";
      }
      if (chart == "deduction") {
        series_name = "Departments Types";
        series_data = charData.map((i) => {
          return i.amount;
        });
        chart_title = "Distribution amongst departments";
      }
      let opCol = {
        chart: {
          type: "column",
        },
        title: {
          text: chart_title,
        },
        subtitle: {
          text: "Source:Payroll System1",
        },
        xAxis: {
          categories: charData.map((i) => {
            return i.name;
          }),
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Amount ($)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} $</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        colors: pieColors,
        series: [
          {
            name: series_name,
            data: series_data,
          },
        ],
      };

      return opCol;
    }
  };
  useEffect(() => {
    let tArray = [];
    console.log("deduction", deduction);
    if (deduction.length) {
      deduction.map((i) => {
        tArray.push({
          name: i.key,
          amount:
            parseFloat(i.EmployeeShare ? i.EmployeeShare : 0) +
            parseFloat(i.GovtShare ? i.GovtShare : 0),
        });
      });
      console.log("tArray", tArray);
      // setCharData(tArray);

      /** new code */
      let empsum = 0;
      let govtsum = 0;
      deduction.map((i) => {
        i.deductionPlans.map((j) => {
          empsum = empsum + parseFloat(j.EmployeeShare ? j.EmployeeShare : 0);
          govtsum = govtsum + parseFloat(j.GovtShare ? j.GovtShare : 0);
        });

        tArray.push({
          name: i.key,
          amount: parseFloat(empsum) + parseFloat(govtsum),
        });
        return (i.total = { empDeduction: empsum, govtDeduction: govtsum });
      });

      // setCharData(departArray);
      console.log("deduction", deduction);
      // setDeductions(deduction);
      // setTotaldeDuction({empDeduction:empsum,govtDeduction:govtsum})
      /** end of new code */
    }
  }, [deduction]);

  // useEffect(() => {
  //   let typeArray = [];
  //   if (earning.length) {
  //     earning.map((i) => {
  //       typeArray.push({
  //         name: i.Description,
  //         amount: parseFloat(i.Earnings),
  //       });
  //     });
  //     console.log("typeArray", typeArray);
  //     setCharData2(typeArray);
  //   }
  // }, [earning]);

  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-10">
        <div
        // style={{ marginBottom: "10px", borderBottom: "1px solid darkgrey" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Card style={{ minHeight: 300 }}>
                <CardContent>
                  {deduction.length ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartDetails("deduction", "column")}
                    />
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Card>
                <CardContent>
                  {deduction.length ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartDetails("deduction", "pie")}
                    />
                  ) : null}
                </CardContent>
              </Card>
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={6}>
              {charData2.length ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getChartDetails("paytype", "column")}
                />
              ) : null}
            </Grid> */}
            {/* <Grid item xs={12} sm={6} lg={6}>
              {charData2.length ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getChartDetails("paytype", "pie")}
                />
              ) : null}
            </Grid> */}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ReportChartLayout;
