import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardHeader } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import DataTable from "./data-table";
import { useNavigate } from "react-router-dom";
import TimeSheetHeader from "./header";
import ViewPayCodeTemplates from "./ViewPayCodeTemplates";
import ModalCustom from "../../employee/Modal";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Container,
} from "@mui/material";
import { Close } from "@material-ui/icons";
import { useAuth } from "oidc-react";
import { getUserRole } from "../../../app/utility/helper";

const inputProps = {
  style: { fontSize: 14, step: 300 },
  step: 300,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "90%",
    flexShrink: 0,
  },
  // secondaryHeading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   flexBasis: "33.33%",
  //   flexShrink: 0,
  //   // color: theme.palette.text.secondary,
  // },
}));
function PayCodeTemplate(props) {
  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  const classes = useStyles();
  const history = useNavigate();
  const [detail, setDetail] = useState({
    TemplateID: "",
    TemplateTitle: "",
    CreatorName: "",
  });
  const [tableList, setTableList] = useState([]);
  const [expand, setExpand] = React.useState({
    panel1: true,
    // panel2: true,
    // panel3: true,
  });

  const [viewModal, setViewModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [modalHeader, setModalHeader] = useState();

  const setTemplateId = (id, title) => {
    console.log("Set Template 121212 ::: ", id, title);
    setSelectedId(id);
    setModalHeader(title);
    setViewModal(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}typecode/get`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        if (response.data.result.length) {
          if (userRole == "user") {
            response.data.result.map((i) => {
              i.TimeSheetName = i.TimeSheetAssignLists[0]["TImeSheetName"];
              i.EmployeeID = i.TimeSheetAssignLists[0]["EmployeeID"];

              if (i.Employee) {
                i.Employee["EmployeeName_FML"] = "Self";
              }

              return i;
            });
          }
        }
        setTableList(response.data.result);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const addTimeSheet = () => {
    history(`/master/paycode-template-details`);
  };

  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TimeSheetHeader createTimeSheet={addTimeSheet} />
      </div>
      <div className={`${classes.root} w-full page-content`} style={{ padding: "16px 2px" }}>
        <Card>
          <Accordion
            className={classes.root}
            expanded={expand.panel1}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="bg-gray-200 sub-header"
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography className={classes.heading}>Template List</Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
              <DataTable
                {...props}
                role={"admin"}
                requestDetail={tableList}
                detail={detail}
                setDetail={setDetail}
                filterBy={detail}
                setTemplateId={setTemplateId}
              />
            </AccordionDetails>
          </Accordion>

          <Dialog
            open={viewModal}
            fullWidth
            onClose={() => {
              setViewModal(false);
            }}
          >
            <DialogTitle>
              {/* <Box display="flex" justifyContent="space-between"> */}
              <Typography>
                <h3>{modalHeader}</h3>
              </Typography>

              {/* <IconButton
                  onClick={() => {
                    setViewModal(false);
                  }}
                >
                  <Close />
                </IconButton> */}
              {/* </Box> */}
            </DialogTitle>
            <DialogContent>
              <ViewPayCodeTemplates templateId={selectedId} />
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  setViewModal(false);
                }}
                className="bg-primary"
                style={{ color: "white" }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </div>
    </Container>
  );
}
export default PayCodeTemplate;
