import React, { useEffect, useState } from "react";
import EarningHeader from "./EarningHeader";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Label, Row } from "reactstrap";
import clsx from "clsx";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import { fetchPayPeriodMaster } from "../../../app/store/reducers/slices/masterSlice";
import EarningDataTable from "./EarningDataTable";
import axios from "axios";

const EarningsReports = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { payperiods } = useSelector((state) => state.masterSlice);

  const configrationJSON = {
    headerTitle: "Earnings Reports",
    detailTitle: "Employee Detail",
  };
  const [loading, setLoading] = useState(false);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);
  const [employeeEarningReports, setEmployeeEarningReports] = useState([]);
  const [totalCount, setTotalCount] = useState(16);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });
  const [pageSize, setPageSize] = useState(10);
  const [expand, setExpand] = useState({
    panel1: true,
    panel2: true,
  });
  useEffect(() => {
    dispatch(fetchPayPeriodMaster());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPayPeriod) {
      getEmployeeEarningReport();
    }
  }, [selectedPayPeriod, page, pageSize]);

  const getEmployeeEarningReport = () => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_WEB_API_URL
        }Employee/GetAllEmployeeEarnings?MaxCount=${page.take}&SkipCount=${
          page.skip / page.take
        }&Id=${selectedPayPeriod}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      )
      .then((res) => {
        setEmployeeEarningReports(res?.data?.data);
        setTotalCount(res?.data?.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  return (
    <>
      <EarningHeader configrationJSON={configrationJSON} />
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full`}>
          <Card style={{ width: "100%", padding: "10px" }}>
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <div
                className={clsx("form-parent-div1")}
                style={{ justifyContent: "left" }}
              >
                <div className="grid mt-10 grid-cols-1">
                  <div
                    className="flex justify-start mt-2 mb-3 gap-12"
                    style={{ gap: "10px" }}
                  >
                    <Row style={{ width: "100%" }}>
                      <Col lg={2} md={2} sm={12} xs={12}>
                        <div>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "250px",
                            }}
                          >
                            <InputLabel>Select Pay Period</InputLabel>
                            <Select
                              label="Select Pay Period"
                              onChange={handleChange}
                            >
                              {payperiods?.map((item, index) => (
                                <MenuItem
                                  value={item?.ID}
                                  onClick={() => setSelectedPayPeriod(item?.ID)}
                                >
                                  {`${item?.StartDate} TO ${item?.EndDate}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              color: "white",
                            }}
                            className="bg-primary"
                          >
                            <FontAwesomeIcon
                              icon={faRectangleList}
                              style={{
                                color: "white",
                                marginRight: "5px",
                              }}
                            />
                            Generate Report
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
          </Card>
        </div>
      </div>
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
        style={{ marginBottom: "55px" }}
      >
        <EarningDataTable
          data={employeeEarningReports}
          selectedColumns={sortedColumns}
          setSortedColumns={setSortedColumns}
          loading={loading}
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
        ></EarningDataTable>
        <div className={`${classes.root} w-full`}></div>
      </div>
    </>
  );
};

export default EarningsReports;
