import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DayPilot } from "daypilot-pro-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "oidc-react";
import { getUserRole } from "../../../app/utility/helper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  heights: "500px",

  p: 4,
};

const getDescription = (title, saveButton) => {
  const savebtn = (
    <Button
      variant="contained"
      className="m-10 bg-primary"
      style={{
        color: "#d0efe9",
      }}
    >
      <FontAwesomeIcon
        icon={faSave}
        style={{
          marginRight: "10px",
        }}
        className="bg-primary"
      />

      <Typography className="text-12">Save Time Sheet</Typography>
    </Button>
  );
  if (title === "table") {
    return (
      <div>
        <p>- Here is help for standard table view timesheet </p>
        <p>- Select paycode on which you work </p>
        <p>- Select date and working hours </p>
        <p>- Here you can select two shift hours in single date </p>
        {saveButton ? (
          <>
            {" "}
            <p>
              - If your work is hazard and you have permission for hazard then
              select hazard type in H pay code{" "}
            </p>
            <p>
              - For submit / save data click on <b>{savebtn}</b> and confirm it{" "}
            </p>
          </>
        ) : null}
      </div>
    );
  } else if (title === "sheet") {
    return (
      <div>
        <p>- Here is help for timesheet </p>
        <p>- You can select hours agains your work date </p>
        <p>
          - For select hours click on starting time of work and drag till you
          worked{" "}
        </p>
        <p>
          - After select hours you have option to select pay time and also you
          can modify hours{" "}
        </p>
        {saveButton ? (
          <>
            {" "}
            <p>
              - For submit / save data click on <b>{savebtn}</b>{" "}
            </p>
          </>
        ) : null}
      </div>
    );
  } else if (title === "simplev1") {
    return (
      <div>
        <p>- Here is help for timesheet using start and end time </p>
        <p>- This is simple way to add timeseet </p>
        <p>- Select date from top datepicker having next prev values </p>
        <p>- Enter Start and end time with related paycode</p>
        <p>- Work hours automatic calculate from start and end time</p>
        <p>
          - You can see only those paycode which assigned to you by supervisor
        </p>
        {saveButton ? (
          <>
            <p>
              - For submit / save data click on <b>{savebtn}</b>{" "}
            </p>
          </>
        ) : null}
      </div>
    );
  } else if (title === "simplev2") {
    return (
      <div>
        <p>
          - Here is help for Timesheet Using Start Time And Hours Worked (This
          Will Calculate End Time){" "}
        </p>
        <p>- This is simple way to add timeseet </p>
        <p>- Select date from top datepicker having next prev values </p>
        <p>
          - Enter Start and hours you worked. End time automatic calculated{" "}
        </p>
        <p>- Work hours automatic calculate from start and end time</p>
        <p>
          - You can see only those paycode which assigned to you by supervisor
        </p>
        {saveButton ? (
          <>
            <p>
              - For submit / save data click on <b>{savebtn}</b>{" "}
            </p>
          </>
        ) : null}
      </div>
    );
  } else if (title === "timeClock") {
    return (
      <div>
        <p>
          - Click on start Button when you start your work
        </p>
        <p>- Automatic time calculate from system </p>
        <p>- Stop the timer once you complete your work </p>
          
        {saveButton ? (
          <>
            <p>
              - For submit / save data click on <b>{savebtn}</b>{" "}
            </p>
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <div>
        <p>- Here is help for standard table view timesheet </p>
        <p>- Select paycode on which you work </p>
        <p>- Select date and working hours </p>
        {saveButton ? (
          <>
            <p>- Here you can select two shift hours in single date </p>
          </>
        ) : null}
      </div>
    );
  }
};

const TimeSheetModal = (props) => {
  const [titleHead, setTitleHead] = useState("");
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.title) {
      handleTitle(props.title);
    }
  }, [props.title]);

  const handleTitle = (title) => {
    let titleHead = "";
    if (title === "table") {
      titleHead = "Standard Sheet ";
    } else if (title === "sheet") {
      titleHead = "TimeSheet";
    } else if (title === "simplev1") {
      titleHead = "Timesheet Using Start And End Time";
    } else if (title === "simplev2") {
      titleHead =
        "Timesheet Using Start Time And Hours Worked (This Will Calculate End Time)";
    } else if (title === "timeClock") {
      titleHead =
        "Time Clock";
    } else {
      titleHead = "";
    }
    setTitleHead(titleHead);
  };
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleHead}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getDescription(props.title, props.saveButton)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Event"
                  value={props.eventName}
                  onChange={handleChange}
                >
                  {props?.paytimeCodeList?.length
                    ? props?.paytimeCodeList?.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })
                    : ""}
                </Select>
              </FormControl>
          </Grid>
          <Grid lg={6}></Grid>
          
        </Box>
      </Modal> */}
    </div>
  );
};

export default TimeSheetModal;
