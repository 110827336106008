import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { message } from 'antd';

const ConfirmModal = (props) => {
    const  {open , setOpen, message,confimClick,modalHeader} = props;


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         {modalHeader ? modalHeader : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         {message ? message : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color='error'>Cancel</Button>
          <Button onClick={() => confimClick()} variant='contained' color='success' autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmModal