import React, { useEffect, useState } from 'react';
import { H5 } from '../../../../AbstractElements';
import { ShimmerTable } from 'react-shimmer-effects';
import { Card, Grid } from "@mui/material";
import { Table } from "reactstrap";
import { formatMoney } from '../../../../app/utility/helper';

const DeductionDetailModal = (props) => {

    const { defaultTransactionDetails, selectedTransactionDetails } = props;
    const [totalDeductedAmount, setTotalDeductedAmount] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (defaultTransactionDetails?.length > 0) {
            setVisible(false);
            let tempTotalDeduction = 0;
            defaultTransactionDetails.forEach(o => {
                tempTotalDeduction = parseFloat(tempTotalDeduction) + parseFloat(o.deductedAmount);
            });
            setTotalDeductedAmount(tempTotalDeduction);
        }
        const intervalId = setInterval(() => {
            setVisible(true);
          }, 3000);
          return () => {
            clearInterval(intervalId);
          };
    }, [JSON.stringify(defaultTransactionDetails)]);

    return (
        <div className="w-full h-full empDeductionDetailsModalDiv">
        {/* <H5>Employee Deduction Transactions</H5> */}
        {/* <div> */}
        <Grid item xs={12} sm={8} lg={8}>
            <Card
            style={{
                marginBottom: "10px",
                overflow: "hidden",
                padding: 0,
                borderRadius: "10px",
            }}
            elevation={0}
            className="body-Color2 reportTbl"
            >
            <Table>
                <table className="table100">
                <thead>
                    {/* <tr>
                        <th colSpan={5}>
                            <h5>
                                <span>Employee {
                                    props?.defaultDeductionType === 'GARNISHMENT' ? 'Garnishment' : 
                                    props?.defaultDeductionType === 'BENEFITS' ? 'Benefits' :
                                    props?.defaultDeductionType === 'MISCELLANEOUS' ? 'Miscellaneous' : ''
                                } Transactions</span>
                            </h5>
                        </th>
                    </tr> */}
                    <tr className="tc mainHead1">
                        <th className="tl1">Plan</th>
                        <th className="tr1" colSpan={4} style={{ padding: "0px" }}>
                            <table
                                style={{ width: "100%" }}
                                className="w251 tl1"
                            >
                            <thead>
                                <tr className="tc mainHead1">
                                    <th className="tl1 w60">Transaction Date</th>
                                    <th className="tl1">Deducted Amount</th>
                                    {/* <th className="tl1">Total Amount</th> */}
                                </tr>
                            </thead>
                            </table>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {(defaultTransactionDetails?.length > 0) ? (
                    defaultTransactionDetails.map((i) => {
                        return ( */}
                        <tr
                            // key={i}
                            style={{
                                borderBottomColor: "#EAEBE4",
                                borderBottomWidth: "1px",
                            }}
                        >
                            <td className="tl1 labelColor ">
                                <b>{selectedTransactionDetails?.deductionCode}</b>
                            </td>
                            <td colSpan={4}>
                            <Table>
                                <table style={{ width: "100%" }} className="w25">
                                <tbody>
                                    {(defaultTransactionDetails?.length > 0)
                                    ? defaultTransactionDetails.map((info, index) => {
                                        return (
                                            <>
                                            <tr key={index}>
                                                <td className="labelColor w60">
                                                {new Date(info.createdOn).toLocaleDateString()}
                                                </td>
                                                <td className="labelColor">
                                                {/* {info.deductedAmount} */}
                                                {formatMoney(
                                                    info.deductedAmount
                                                    ? info.deductedAmount
                                                    : 0
                                                )}
                                                </td>
                                                {/* <td className="labelColor">
                                                {formatMoney(
                                                    info.totalAmount
                                                    ? info.totalAmount
                                                    : 0
                                                )}
                                                </td> */}
                                            </tr>
                                            {/* {index ===
                                            i.deductionPlans.length - 1 ? (
                                                <>
                                                <tr
                                                    style={{
                                                    fontWeight: "bold",
                                                    paddingTop: "15px",
                                                    paddingBottom: "20px",
                                                    // backgroundColor: "#F5F5F5",

                                                    // marginTop:
                                                    }}
                                                >
                                                    <td colSpan={2}>
                                                    Total {i.key}
                                                    </td>
                                                    <td>
                                                    {formatMoney(
                                                        i.total.empDeduction
                                                    )}
                                                    </td>
                                                    <td>
                                                    {formatMoney(
                                                        i.total.govtDeduction
                                                    )}
                                                    </td>
                                                </tr>
                                                </>
                                            ) : null} */}
                                            </>
                                        );
                                        })
                                    : (
                                        <tr>
                                            {" "}
                                            <td colSpan={3}>
                                            {" "}
                                            {visible ? "No Record Found" : <ShimmerTable row={2} col={5} />}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                </table>
                            </Table>
                            </td>
                            {/* <td className="tr1">{i.amount}</td> */}
                        </tr>
                        {/* );
                    })
                    ) : (
                    <tr>
                        {" "}
                        <td colSpan={3}>
                        {" "}
                        <ShimmerTable row={2} col={5} />
                        </td>
                    </tr>
                    )} */}
                    {/* Deduction Gross Total */}
                    <tr className="bg-primary">
                    <td
                        className="tl1"
                        colSpan={4}
                        style={{
                        padding: "8px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "white",
                        }}
                    >
                        Goverment Arrear Amount: {formatMoney(selectedTransactionDetails?.totalAmountPlanDue)}
                    </td>
                    <td colSpan={4}>
                        <table style={{ width: "100%" }} className="w25">
                        <tbody>
                            <td></td>
                            <td></td>
                            <td
                            // className="tl1"
                            style={{
                                // padding: "8px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "white",
                            }}
                            >
                            {formatMoney(totalDeductedAmount)}
                            </td>
                            
                        </tbody>
                        </table>
                    </td>
                    </tr>
                    {/* Deduction Gross Total Ends*/}
                </tbody>
                </table>
            </Table>
            </Card>
        </Grid>
        {/* </div> */}
        </div>
    )
}

export default DeductionDetailModal;