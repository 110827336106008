import { makeStyles } from "@material-ui/core/styles";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
} from "reactstrap";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Fragment, useEffect, useRef, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded";
// import Header from "./component/header";
// import AddForm from "./component/addForm";
import { connect, useDispatch, useSelector } from "react-redux";
// import withReducer from "app/store/withReducer";
// import reducer from "./store/reducer/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
// import DataTable from "./component/data-table";
import axios from "axios";
import {
  getDeductionType,
  getOverAllDeduction,
  getOverAllEarning,
  getVendor,
} from "../../../app/utility/axiosService";
import LaborCostList from "./component/LaborCostList";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { faChartBar, faReceipt } from "@fortawesome/pro-duotone-svg-icons";
// import DepartmentDeduction from "./DepartmentDeduction";
// import { Card, CardContent } from "@material-ui/core";
import { Card, CardBody } from "reactstrap";
import { fetchDeductionTypeMaster } from "../../../app/store/reducers/slices/masterSlice";
import ReportHeader from "../ReportHeader";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

function LaborCostReport(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({
    Code: "",
    VendorCode: "",
    Description: "",
    EmployeerFee: "",
    EmployeeFee: "",
    IsPercent: "",
    StartDate: null,
    EndDate: null,
    DeductionType: [],
    SelectedEmployee: [],
    DepartmentDivision: [],
    PayTimeCode: [],
  });

  const [vendors, setVendors] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [value, setValue] = useState(0);

  const pageLayout = useRef(null);

  const labourCostData = useSelector(
    ({ chartSlice }) => chartSlice?.queryLaborCostData
  );

  const labourCostData1 = useSelector((state) => state);

  console.log("labourCostData", labourCostData1);

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    setTableList(labourCostData);
  }, [labourCostData]);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}deductionPlan`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  // useEffect(() => {
  //   if (detail && detail.SelectedEmployee) {
  //     alert("selected emp " + detail.SelectedEmployee.EmployeeName);
  //   }
  // }, [detail, detail.SelectedEmployee]);

  // useEffect(() => {
  //   if (detail && detail.PayTimeCode && detail.PayTimeCode.length > 0) {
  //     alert(
  //       "selected Paytime " +
  //         detail.PayTimeCode[detail.PayTimeCode.length - 1].Description
  //     );
  //   }
  // }, [detail, detail.PayTimeCode]);

  // useEffect(() => {
  //   getData();
  //   (async () => {
  //     setDeductionTypes(await getDeductionType());
  //     setVendors(await getVendor());
  //     setDeduction(await getOverAllDeduction());
  //     setEarning(await getOverAllEarning());
  //   })();
  //   dispatch(fetchDeductionTypeMaster());
  // }, []);

  const { userDetail } = props;
  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
      
      <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
        <ReportHeader {...props} header="Labor Cost Deduction Summary Report" />
        </div> 

        <div className="page-content">
        <div className="w-100 h-full mt-3 " ref={pageLayout} innerScroll>
          <div className={`${classes.root} w-100`}>
            <LaborCostList
              {...props}
              role={"admin"}
              deduction={deduction}
              earning={earning}
              requestDetails={tableList}
            />
          </div>
        </div>
        {/* <Card
          classes={{
            root: "w-full h-full",
            header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
            contentWrapper: "p-0",
            contentCard: "rounded-none",
          }}
          style={{ backgroundColor: "red" }}
        >
          <CardBody>
            <div className={`${classes.root} w-full`}>
              <Accordion className={classes.root} open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1" className="bg-gray-200">
                    <FontAwesomeIcon
                      icon={faRectangleList}
                      style={{
                        color: "#1569C7",
                        marginRight: "10px",
                        marginTop: "3px",
                      }}
                    />
                    <Typography className={classes.heading}>
                      Labor Cost Deduction Summary Report
                    </Typography>
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <div className="w-full">
                      {value === 0 ? (
                        <div className="w-full">
                          <Card>
                            <CardBody>
                              <LaborCostList
                                {...props}
                                role={"admin"}
                                deduction={deduction}
                                earning={earning}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      ) : null}
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </CardBody>
        </Card> */}
      </div>
      
      </Container>
    
  );
}
export default LaborCostReport;
