import React, { useEffect, useState } from "react";

// import TextField from "@material-ui/core/TextField";
// import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Grid, Typography } from "@material-ui/core";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import MaskedInput from "react-text-mask";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";

import HighchartsReact from "highcharts-react-official";
import { getSankeyChart } from "../../../app/utility/axiosService";
// import { async } from "regenerator-runtime";
import { ShimmerThumbnail } from "react-shimmer-effects";

HighchartsSankey(Highcharts);

function SankeyChart(props) {
  let [loading, setLoading] = useState(true);
  let [sankeyData, setSankeyData] = useState();
  let sankeydataLocal = {};
  const barChart = async () => {
    let chartData = props.chartData;


    
    console.log("CCC",chartData);
    let colors = [
      "#003f5c",
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600",
    ];

    sankeydataLocal = {
      title: {
        text: "Sankey For Employee Information",
      },
      accessibility: {
        point: {
          valueDescriptionFormat:
            "{index}. {point.from} to {point.to}, {point.weight}.",
        },
      },
      series: [
        {
          keys: ["from", "to", "weight"],
          data: chartData,
          type: "sankey",
          name: "Sankey Daigram",
        },
      ],
    };

    setSankeyData(sankeydataLocal);
    setLoading(false);
  };
  useEffect(() => {
    barChart();
  }, [props.chartData]);

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: "20px" }}>
          <ShimmerThumbnail height={250} />{" "}
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={sankeyData} />
      )}
    </div>
  );
}

export default SankeyChart;
