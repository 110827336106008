import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row } from "reactstrap";
import { Autocomplete } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GetAllEmployee } from "../TableSlice";
import dayjs from "dayjs";

const InputForm = (props) => {
  const { configrationJSON, setDetail, detail } = props;
  const { employeeTotalCount, allEmployee } = useSelector((state) => state.TableSlice);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const loadMoreResults = () => {
    const nextPage = page + 1;
    if (allEmployee.length < employeeTotalCount) {
      setPage(nextPage);
      dispatch(GetAllEmployee(nextPage))
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const InputFiledFuc = (item) => {
    switch (item?.type) {
      case "text":
        return (
          <TextField
            margin="dense"
            className="flex w-full"
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            label={item?.label}
            value={detail[item?.id]}
            onChange={(e) => {
              setDetail({
                ...detail,
                [item?.id]: e.target.value,
              });
            }}
            name={item?.id}
          />
        );
      case "select":
        return (
          <>
            <Autocomplete
              disablePortal
              margin="dense"
              className="flex w-full"
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  fontSize: "14px",
                  color: "black",
                  paddingTop: "12px",
                },
              }}
              options={item?.optionsList}
              ListboxProps={{
                onScroll: item.isPagebal && handleScroll
              }}
              // getOptionLabel={((option) => option.label)}
              value={detail[item?.id]}
              onChange={(e, newValue) => {
                const detailData = {
                  ...detail,
                  [item?.id]: newValue?.value || "",
                }
                if (item?.id === "employeeName" && configrationJSON?.headerTitle === "Employee Tag Management") {
                  detailData.employeeID = newValue?.id
                }

                setDetail(detailData);
              }}
              renderInput={(params) => (
                <TextField {...params} label={item?.label} />
              )}
            />
          </>
        );
      case "multiSelect":
        return (
          <>
            <Autocomplete
              disablePortal
              margin="dense"
              className="flex w-full"
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
              multiple
              inputProps={{
                style: {
                  fontSize: "14px",
                  color: "black",
                  paddingTop: "12px",
                },
              }}
              options={item?.optionsList}
              onChange={(e, newValue) => {
                const data = newValue.map((i) => { return i.value })
                const detailData = {
                  ...detail,
                  [item?.id]: data || [],
                }

                setDetail(detailData);
              }}
              renderInput={(params) => (
                <TextField {...params} label={item?.label} />
              )}
            />
          </>
        )
      case "date":
        return (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                label={item?.label}
                margin="dense"
                className="flex w-full"
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                value={dayjs(detail[item?.id]) || null}
                onChange={(e) => {
                  const formatDate = e.$d;
                  const value = moment(formatDate).format("YYYY-MM-DD");
                  setDetail({
                    ...detail,
                    [item?.id]: value,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </>
        );
      case "number":
        return (
          <TextField
            margin="dense"
            className="flex w-full"
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            type="number"
            label={item?.label}
            value={detail[item?.id]}
            onChange={(e) => {
              setDetail({
                ...detail,
                [item?.id]: e.target.value,
              });
            }}
            name={item?.name}
          />
        );
      case "status":
        const option = [{ label: "true", value: "true" }, { label: "false", value: "false" }]
        return (
          <>
            <Autocomplete
              disablePortal
              margin="dense"
              className="flex w-full"
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  fontSize: "14px",
                  color: "black",
                  paddingTop: "12px",
                },
              }}
              options={option}
              value={detail[item?.id]}
              onChange={(e, newValue) => {
                setDetail({
                  ...detail,
                  [item?.id]: Boolean(newValue?.value) || false,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={item?.label} />
              )}
            />
          </>
        );

      default:
        return;
    }
  };
  return (
    <Row style={{ width: "100%" }} className="g-3">
      {configrationJSON?.tableColumns?.map((item) => {
        return <Col md={6} className="flex align-items-center">{InputFiledFuc(item)}</Col>;
      })}
    </Row>
  );
}

export default InputForm;
