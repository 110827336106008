import { useState } from "react";
import QRCode from "qrcode.react";

const QRCodeModal = (props) => {
  const { employeeInfo } = props;
  const [qrValue, setQrValue] = useState(employeeInfo?.ID);

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <QRCode
          id="qr-gen"
          value={qrValue}
          size={290}
          level={"H"}
          includeMargin={true}
        />
      </div>
      <div
        className="mb-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p
          onClick={downloadQRCode}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Download
        </p>
      </div>
    </div>
  );
};

export default QRCodeModal;
