import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";

const Splash = () => {
  const history = useNavigate();
  const { userData } = useAuth();

  // history(`${process.env.REACT_APP_PREFIX_URL}login`);

  useEffect(() => {
    if (userData && userData.profile) {
      history(`${process.env.REACT_APP_PREFIX_URL}dashboard/default`);
    }
  }, [history, userData]);

  const [show, setShow] = useState(true);

  return (
    <Fragment>
      <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
        <div className="theme-loader">
          <div className="loader-p"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default Splash;
