import * as Yup from 'yup';
import { generateYupValidationSchema } from '../../../_helper/helper';

//ARCHIVAL DT LOG
export const DEDUCTION_PLAN_INITIAL_DATA = {
    'deductionType': "",
    // 'deductionCode': "",
    'deductionName': "",
    'startDate': "",
    'endDate': "",
    'paymentMonthlySch': null,
    'deductionMethod': "",
    'deductionValue': "",
    'deductionPlanStatus': "",
    'totalAmountPlanDue': "",
    // 'totalAmountPaid': "",
    'vendorNo': null,
    'accountNoForVendor': null,
    // 'employeeDeductionAmount': "",
    'govermentDeductionAmount': "",
    // 'totalDeductionAmount': "",
    // 'totalGovermentDeductionAmount': "",
    'applys_To_OT':"",
    'deductionTypeId':"",
    "isActive":false,
    "Taxable":false,
    "IsEmployeeModifiable":false
}

export const VALIDATION_SCHEMA_DEDUCTION_PLAN_FORM = generateYupValidationSchema({
    deductionTypeId: [
        {
            type: 'required',
            rules: ['Please select deduction type'],
            dataType: 'string'
        }
    ],
    // deductionCode: [
    //     {
    //         type: 'required',
    //         rules: ['Deduction code is required'],
    //         dataType: 'string'
    //     }
    // ],
    deductionName: [
        {
            type: 'required',
            rules: ['Deduction name is required'],
            dataType: 'string'
        }
    ],
    startDate: [
        {
            type: 'required',
            rules: ['Please select start date'],
            dataType: 'string'
        }
    ],
    endDate: [
        {
            type: 'required',
            rules: ['Please select end date'],
            dataType: 'string'
        }
    ],
    paymentMonthlySch: [
        {
            type: 'required',
            rules: ['Payment monthly schedule is required'],
            dataType: 'string'
        }
    ],
    deductionMethod: [
        {
            type: 'required',
            rules: ['Please select deduction method'],
            dataType: 'string'
        }
    ],
    deductionValue: [
        {
            type: 'required',
            rules: ['Deduction value is required'],
            dataType: 'string'
        }
    ],
    deductionPlanStatus: [
        {
            type: 'required',
            rules: ['Please select deduction plan status'],
            dataType: 'string'
        }
    ],
    totalAmountPlanDue: [
        {
            type: 'required',
            rules: ['Total amount plan due is required'],
            dataType: 'string'
        }
    ],
    // totalAmountPaid: [
    //     {
    //         type: 'required',
    //         rules: ['total amount paid is required'],
    //         dataType: 'string'
    //     }
    // ],
    vendorNo: [
        {
            type: 'required',
            rules: ['Please select vendor'],
            dataType: 'string'
        }
    ],
    // accountNoForVendor: [
    //     {
    //         type: 'required',
    //         rules: ['Account no for vendor is required'],
    //         dataType: 'string'
    //     }
    // ],
    // employeeDeductionAmount: [
    //     {
    //         type: 'required',
    //         rules: ['Employee deduction amount is required'],
    //         dataType: 'string'
    //     }
    // ],
    // govermentDeductionAmount: [
    //     {
    //         type: 'required',
    //         rules: ['Goverment deduction amount is required'],
    //         dataType: 'string'
    //     }
    // ],
    // totalDeductionAmount: [
    //     {
    //         type: 'required',
    //         rules: ['Total deduction amount is required'],
    //         dataType: 'string'
    //     }
    // ],
    // totalGovermentDeductionAmount: [
    //     {
    //         type: 'required',
    //         rules: ['Total goverment deduction amount is required'],
    //         dataType: 'string'
    //     }
    // ],
});