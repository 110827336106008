import React from "react";
import { Col, Row } from "reactstrap";
import { TextField } from "@mui/material";

function GarnishmentTypeManage(props) {
    return (
        <Row style={{ width: "100%" }} className="g-3">
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                    }}
                    label="Name"
                    value={props.detail.name}
                    onChange={(e) => {
                        props.setDetail({ ...props.detail, name: e.target.value });
                    }}
                    name="name"
                />
            </Col>
        </Row>
    );
}

export default GarnishmentTypeManage;