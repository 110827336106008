import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
// import {
//   KeyboardDatePicker,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import MaskedInput from "react-text-mask";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";

import HighchartsReact from "highcharts-react-official";
import {
  getReportdeductionGovVsEmployee,
  getReportDepartmentwiseDeduction,
  getReportEarningbyPayperiod,
} from "../../../app/utility/axiosService";
import { async } from "regenerator-runtime";
import moment from "moment";
HighchartsSankey(Highcharts);

function PayPeriodEarning(props) {
  var pieColors = (function () {
    var colors = [],
      base = "#4ebfa9",
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 7)
          .get()
      );

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let [sankeyData, setSankeyData] = useState([{ options: [], series: [] }]);

  const barChart = async () => {
    let rData = await getReportEarningbyPayperiod();

    let series_name = "Pay Types";
    let chart_title = "Distribution Share Employee Vs Goverment";
    let series_data = [2, 44, 55];
    let dept = rData.map((i) => {
      return `${moment(i.EndDate).format("MM-DD-YYYY")}`;
      // return `${i.StartDate}-${i.EndDate}`;
    });

    let amountshare = rData.map((i) => {
      return i.Amount;
    });

    let myOptions = {
      chart: {
        type: "spline",
      },
      title: {
        text: "Earning by pay period",
      },
      subtitle: {
        text: " ",
      },
      xAxis: {
        categories: dept,
        accessibility: {
          description: "Months of the year",
        },
      },
      yAxis: {
        title: {
          text: "Amount ($)",
        },
        labels: {
          formatter: function () {
            return "$" + this.value + "°";
          },
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: "#4ebfa9",
            lineWidth: 1,
          },
        },
      },
      series: [
        {
          lineColor: "#4ebfa9",
          name: "Pay Period Earning",
          data: amountshare,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: pieColors,
          },
        },
      ],
    };
    setSankeyData(myOptions);
  };
  useEffect(() => {
    barChart();
  }, []);

  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-10 mb-20">
        <div>
          <Card>
            <CardContent>
              {/* <Typography variant="h6" component="b" color="text.secondary" gutterBottom>
                Employee Vs Goverment Share Comparison
                </Typography> */}
              <Typography component="div">
                <HighchartsReact highcharts={Highcharts} options={sankeyData} />
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PayPeriodEarning;
