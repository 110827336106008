import Typography from "@material-ui/core/Typography";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../../../AbstractElements";

function Header1(props) {
  const params = useParams();
  console.log("params", params);
  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faAddressCard}
              className="mx-10  px-3 fs-4"
            />
            <Link
              to="/admin/working-hrs"
              className="fs-5"
              style={{
                color: "white",
              }}
            >
              <Typography className="fs-5">
                {props.title ? props.title : "No title"}
              </Typography>
            </Link>
          </div>
          {(params && params?.ppeId) || params?.ppe ? (
            <div className="mt-4" style={{ width: "50%" }}>
              <Breadcrumbs
             
                parent={
                  <Link to="/admin/working-hrs" className="font-light">
                    {props.title ? props.title : "No title"}
                  </Link>
                }
             
                title={
                  params && params.ppe ? "Employee List" : "Department List"
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>
        
      </div>
    </CardHeader>
  );
}

export default Header1;
