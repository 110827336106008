import moment from "moment";
import { toast } from "react-toastify";
import {
  Activity,
  Bell,
  CheckCircle,
  FileText,
  UserCheck,
} from "react-feather";
import { filter } from "lodash";
import { LOGIN } from "../../Constant";

export const intNumberRegex = /^\d+$/;
export const floatNumberRegex = /^[0-9]*\.?[0-9]*$/;
// export const floatNumberRegex = /^[0-9]*\.?[0-9]*(?:\.[0-9]{0,4})?$/;
// export const floatNumberRegex = /^\d{1,10}(\.\d{1,4})?$/;
// export const floatNumberRegex = /^\d+(?:\.\d{1,2})?$/;

export const percentageRegex = /^((?:|0|[1-9]\d?|100)(?:\.\d{1,2})?)$/;

export const ActivityItem = (type, data) => {
  let obj = {
    icon: <Bell />,
    link: `/payroll-work/${data.senderID}?forId=${data.forID}`,
  };
  if (type == "WHL") {
    return {
      icon: <Activity />,
      link: `/payroll-work/${data.senderID}?forId=${data.forID}`,
    };
  } else {
  }
  return obj;
};
export const GetLeaveAccureByExperiance = (empmentDate, leaveHours) => {
  if (!empmentDate && !leaveHours) {
    return { sick: 0, annual: 0 };
  }
  const formateEmpmentDate = moment(empmentDate).format("YYYY-MM-DD");
  const employmentDate = new Date(formateEmpmentDate);
  const currentDate = new Date();
  const employmentYear = employmentDate.getFullYear();
  const employmentMonth = employmentDate.getMonth();
  const employmentDay = employmentDate.getDate();

  let differenceInYears = currentDate.getFullYear() - employmentYear;

  if (
    currentDate.getMonth() < employmentMonth ||
    (currentDate.getMonth() === employmentMonth &&
      currentDate.getDate() < employmentDay)
  ) {
    differenceInYears--;
  }

  // return differenceInYears;

  const filteredData = leaveHours?.filter((item) => {
    const minYear = parseInt(item.minYear);
    const maxYear = parseInt(item.maxYear);

    return differenceInYears >= minYear && differenceInYears < maxYear;
  });

  const sickData = filteredData.find((i) => i.type === "sick");
  const annualData = filteredData.find((i) => i.type === "annual");
  if (filteredData.length) {
    return {
      sick: sickData?.type === "sick" ? parseInt(sickData?.Hours) : 0,
      annual: annualData?.type === "annual" ? parseInt(annualData?.Hours) : 0,
    };
  } else {
    return { sick: 0, annual: 0 };
  }
};

export const d2Time = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
};
export function removeDuplicatesByKey(arr, key) {
  const uniqueValues = {};
  return arr.filter((obj) => {
    const value = obj[key];
    if (!uniqueValues[value]) {
      uniqueValues[value] = true;
      return true;
    }
    return false;
  });
}
function sortByKey(arr, key) {
  return arr.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];

    if (keyA < keyB) {
      return -1; // a should be sorted before b
    } else if (keyA > keyB) {
      return 1; // a should be sorted after b
    } else {
      return 0; // a and b have equal sort order
    }
  });
}
export const zeroNumPad = (num, places) => {
  String(num).padStart(places, "0")
};

export const hoursDiffrence = (time_from, time_to) => {
  const startTime = moment(time_from, "HH:mm");
  const endTime = moment(time_to, "HH:mm");
  let duration = moment.duration(endTime.diff(startTime));
  if (duration.asHours() < 0) {
    const endTime2 = moment(time_to, "HH:mm").add(1, "day");
    duration = moment.duration(endTime2.diff(startTime));
  }
  const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
  const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
  const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;
  return {
    hr: hours,
    minutes: minutes,
    totalMin: totalMin,
  };
};

export const payCodeFilter = (
  payCodeList,
  payCodeListByID,
  role,
  type = "timesheet",
  other = {}
) => {
  if (!payCodeList.length) {
    return [];
  }
  let matchedArray = payCodeList.filter((item1) =>
    payCodeListByID?.some((item2) => item1.code === item2.PayCode)
  );
  if (matchedArray.length) {
    matchedArray = matchedArray.map((i) => {
      let info = payCodeListByID.find((j) => j.PayCode == i.code);
      return {
        ...i,
        EffectDate: info.EffectiveStartDate,
        ExpireDate: info.EffectiveEndDate,
        AccountID: info?.AccountID,
      };
    });
  }

  let commonArray = payCodeList.filter((item1) => {
    return ["REG", "S/L", "A/L"].includes(item1.value);
  });
  // const commonArray = payCodeList.filter((item1) =>  { return ["REG"].includes(item1.value)});
  if (other && other.DPDV == "1210") {
    // GPD department

    commonArray = payCodeList.filter((item1) => {
      return ["REG", "S/L", "A/L", "H-10"].includes(item1.value);
    });
    //  let DPDVCodes =  payCodeList.filter((item1) =>  { return ["H-10"].includes(item1.value)})
    //   commonArray.concat(DPDVCodes)
  }

  let makeArray = matchedArray.map((i) => {
    return {
      ...i,
      label: i.label,
      code: i.code,
    };
  });

  const commonData = commonArray.map((i) => {
    return { ...i, label: i.label, code: i.code };
  });
  // makeArray = sortByKey(removeDuplicatesByKey(makeArray.concat(commonData), "code"), 'value');

  const defaultArray = payCodeList?.map((i) => {
    return { ...i, label: i.label, code: i.code };
  });

  //  const filterdata = props.paytimeCodeList.filter((i) => i.code === )
  if (type == "timesheet") {
    if (["user", "supervisor", "admin", "timekeeper"].includes(role)) {
      const sortByOrderNo = makeArray.sort((a, b) =>
        parseInt(a.OrderNo) > parseInt(b.OrderNo) ? 1 : -1
      );
      return sortByOrderNo;
    } else {
      return defaultArray;
    }
  } else {
    if (["user", "supervisor", "timekeeper"].includes(role)) {
      const sortByOrderNo = makeArray.sort((a, b) =>
        parseInt(a.OrderNo) > parseInt(b.OrderNo) ? 1 : -1
      );
      return sortByOrderNo;
    } else {
      return defaultArray;
    }
  }
};

export const SELECTED_FORM_TIME = "09:00";

export const getValidPaycodeOfDay = (date, payCode, paytimeCodeSuperVisor) => {
  const filterPaycodesByDate = payCode.filter((i) => {
    let Effdate = i.EffectDate
      ? moment(i.EffectDate).format("YYYY-MM-DD")
      : null;
    let ExpireDate = i.ExpireDate
      ? moment(i.ExpireDate).format("YYYY-MM-DD")
      : null;

    return (
      (date >= Effdate && date <= ExpireDate) ||
      (i.EffectDate == null && i.ExpireDate == null)
      // i.value === "REG"
    );
  });
  let final = filterPaycodesByDate;
  let EmpCodes = filterPaycodesByDate.map((i) => {
    return i.value;
  });
  if (paytimeCodeSuperVisor?.length) {
    let data = paytimeCodeSuperVisor
      ?.filter((i) => {
        return !EmpCodes.includes(i.PayCode_PayTimeCode?.Abberv);
      })
      .map((i) => {
        return {
          value: i.PayCode_PayTimeCode?.Abberv,
          label: i.PayCode_PayTimeCode?.Abberv,
          title: i.PayCode_PayTimeCode?.Abberv,
          code: i.PayCode,

          EffectDate: i.EffectiveStartDate,
          ExpireDate: i.EffectiveEndDate,
          name: "a",
        };
      });
    data = removeDuplicatesByKey(data, "value");
    final = final.concat(data);
  }

  return final;
};

export const getValidPaycodeOfDayv2 = (
  date,
  payCode,
  paytimeCodeSuperVisor,
  userCode
) => {
  let userFilterCodes = userCode?.filter((i) => {
    let Effdate = i.EffectiveStartDate
      ? moment(i.EffectiveStartDate).format("YYYY-MM-DD")
      : null;
    let ExpireDate = i.EffectiveEndDate
      ? moment(i.EffectiveEndDate).format("YYYY-MM-DD")
      : null;
    return (
      (date >= Effdate && date <= ExpireDate) ||
      (i.EffectiveStartDate == null && i.EffectiveEndDate == null)
      // i.value === "REG"
    );
  });

  // ------------------------------------------------
  userFilterCodes = userFilterCodes?.map((i) => {
    return {
      value: i.PayCode_PayTimeCode?.Abberv,
      label: i.PayCode_PayTimeCode?.Abberv,
      title: i.PayCode_PayTimeCode?.Description,
      code: i.PayCode,

      EffectDate: i.EffectiveStartDate,
      ExpireDate: i.EffectiveEndDate,
      name: "jobtype",
      OrderNo: i.AccountID,
      AccountID: i.AccountID,
    };
  });
  let final = userFilterCodes;
  let EmpCodes = userFilterCodes.map((i) => {
    return i.value;
  });
  if (paytimeCodeSuperVisor?.length) {
    let data = paytimeCodeSuperVisor
      ?.filter((i) => {
        return !EmpCodes.includes(i.PayCode_PayTimeCode?.Abberv);
      })
      .map((i) => {
        return {
          value: i.PayCode_PayTimeCode?.Abberv,
          label: i.PayCode_PayTimeCode?.Abberv,
          title: i.PayCode_PayTimeCode?.Description,
          code: i.PayCode,
          AccountID: i.AccountID,
          EffectDate: i.EffectiveStartDate,
          ExpireDate: i.EffectiveEndDate,
          name: "a",
          OrderNo: i.AccountID,
        };
      });

    data = removeDuplicatesByKey(data, "value");
    final = final.concat(data);
  }

  return final;
};

export const ShowSucess = (message) => {
  toast.success(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const ShowError = (message) => {
  toast.error(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const HourFormat = (hr) => {
  let size = 2;
  let s = "000000000" + hr;
  return s.substr(s.length - size) + ":00";
};

export const HourMinFormat = (hrMin) => {
  let sec = hrMin.split(":");
  let size = 2;
  let s = "000000000" + sec[0];
  let s2 = "000000000" + sec[1];
  let hr = s.substr(s.length - size);
  let min = s2.substr(s2.length - size);
  return hr + ":" + min;
};

export const HourMinLabelFormat = (hrMin, lable = false) => {
  const hr = hrMin && parseInt(hrMin / 60);
  const min = hrMin && hrMin % 60;
  if (lable) {
    return (
      hr + (hr > 0 ? " hrs" : " hr") + " " + min + (min > 0 ? " mins" : " min")
    );
  } else {
    return hr + ":" + min;
  }
};

export const KeyGen = (length = 7) => {
  var result = "";
  var characters = `ABCDEFGHIJKLMNOPQRSTUV${moment(
    "YYMMDDHHmmss"
  )}WXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const RemoveSpaceKeyGen = (text) => {
  text = text.replace(" ", "");
  text = text.replace("/", "");
  text = text.replace(/[-]/g, "");
  text = text.replace(/[^\w-]+/g, "");
  return text;
};

export const timeSlotCheckInBeetween = (
  selectedDateFrom,
  elementFrom,
  elementTo
) => {
  const formate = "YYYY-MM-DD HH:mm";
  try {
    if (
      selectedDateFrom.length >= 16 &&
      elementFrom.length >= 16 &&
      elementTo.length >= 16
    ) {
      //from & to is proper date
      let startDate = moment(elementFrom, formate);

      // 12-07-2022 21:00
      let endDate = moment(elementTo, formate);
      let testDate = moment(selectedDateFrom, formate);

      let resultFrom = testDate.isBetween(startDate, endDate, "minute", false); // will return true
      if (resultFrom) {
        // _firstTimeSlotError = true;

        return true;
      }
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const checkAddedTimeErrorTimekeeper = (
  listData,
  currentData1,
  callbackError
) => {
  let hasError = false;
  listData = listData.filter((i) => {
    return !i.isTemplate;
  });
  if (listData && listData.length > 0) {
    listData.forEach((currentData) => {
      let innerError = checkAddedTimeInnerTimekeeper(listData, currentData);
      if (innerError) {
        hasError = true;
      }
    });
    callbackError(hasError);

    // const errorList = listData.filter((data) => {
    //   return data["ole1"] || data["ole2"];
    // });

    // console.log("errorList length" + errorList ? errorList.length : "0");

    return listData;
  }
};
export const checkAddedTimeError1 = (listData, currentData1, callbackError) => {
  let hasError = false;

  listData = listData.filter((i) => {
    return !i.isTemplate;
  });
  if (listData && listData.length > 0) {
    listData.forEach((currentData) => {
      let innerError = checkAddedTimeInner(listData, currentData);
      if (innerError) {
        hasError = true;
      }
    });
    callbackError(hasError);

    // const errorList = listData.filter((data) => {
    //   return data["ole1"] || data["ole2"];
    // });

    // console.log("errorList length" + errorList ? errorList.length : "0");

    return listData;
  }
};

export const checkAddedTimeError2 = (listData, currentData1, callbackError) => {
  let hasError = false;

  listData = listData.filter((i) => {
    return !i.isTemplate;
  });
  if (listData && listData.length > 0) {
    listData.forEach((currentData) => {
      let innerError = checkAddedTimeInnerv2(listData, currentData);
      if (innerError) {
        hasError = true;
      }
    });
    callbackError(hasError);

    // const errorList = listData.filter((data) => {
    //   return data["ole1"] || data["ole2"];
    // });

    // console.log("errorList length" + errorList ? errorList.length : "0");

    return listData;
  }
};

export const checkAddedTimeInner = (listData, currentData) => {
  // console.log('listData helper 111111', listData);

  if (listData && listData.length > 0 && currentData) {
    let sf = currentData.date + " " + currentData.time_from;
    let st = currentData.date + " " + currentData.time_to;

    let sf1 = currentData.date + " " + currentData.time_from1;
    let st1 = currentData.date + " " + currentData.time_to1;
    // console.log(sf1,'#master',st1);
    let currentError1 = false;
    let currentError2 = false;

    listData.forEach((element, index) => {
      //Check selected time slot's Form time is between any other row's range.

      if (
        // element.jobtype === "REG" &&
        // currentData.jobtype === "REG" &&
        element.date === currentData.date &&
        ((element.time_from && element.time_to) ||
          (element.time_from1 && element.time_to1))
      ) {
        //First time slot propery entered.
        let ef = element.date + " " + element.time_from;
        let et = `${element.date} ${element.time_to}`;

        let ef1 = element.date + " " + element.time_from1;
        let et1 = element.date + " " + element.time_to1;

        let elementError1 = false;
        let elementError2 = false;
        if (currentData.id !== element.id) {
          if (
            (sf.length > 11 && ef.length > 11 && sf === ef) ||
            (st.length > 11 && et.length > 11 && st === et) ||
            timeSlotCheckInBeetween(sf, ef, et) ||
            timeSlotCheckInBeetween(st, ef, et) ||
            timeSlotCheckInBeetween(ef, sf, st) ||
            timeSlotCheckInBeetween(et, sf, st)
          ) {
            elementError1 = true;
            currentError1 = true;
          }

          if (
            (sf.length > 11 && ef1.length > 11 && sf === ef1) ||
            (st.length > 11 && et1.length > 11 && st === et1) ||
            timeSlotCheckInBeetween(sf, ef1, et1) ||
            timeSlotCheckInBeetween(st, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf, st) ||
            timeSlotCheckInBeetween(et1, sf, st)
          ) {
            elementError2 = true;
            currentError1 = true;
          }

          if (
            (sf1.length > 11 && ef.length > 11 && sf1 === ef) ||
            (st1.length > 11 && et.length > 11 && st1 === et) ||
            timeSlotCheckInBeetween(sf1, ef, et) ||
            timeSlotCheckInBeetween(st1, ef, et) ||
            timeSlotCheckInBeetween(ef, sf1, st1) ||
            timeSlotCheckInBeetween(et, sf1, st1)
          ) {
            elementError1 = true;
            currentError2 = true;
          }
          if (
            (sf1.length > 11 && ef1.length > 11 && sf1 === ef1) ||
            (st1.length > 11 && st1.length > 11 && st1 === et1) ||
            timeSlotCheckInBeetween(sf1, ef1, et1) ||
            timeSlotCheckInBeetween(st1, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf1, st1) ||
            timeSlotCheckInBeetween(et1, sf1, st1)
          ) {
            elementError2 = true;
            currentError2 = true;
          }
          if (
            element.time_from &&
            element.time_to &&
            !element.time_from1 &&
            !element.time_to1
          ) {
            elementError2 = false;
            currentError2 = false;
          }

          element["ole1"] = elementError1;
          element["ole2"] = elementError2;
          currentData["ole2"] = currentError2;
        } else {
          console.log("CHECK IN OWN TIME SLOT ");
        }
      }
    });

    // if (currentData.jobtype === "REG") {
    if (
      (sf.length > 11 && sf1.length > 11 && sf === sf1) ||
      (st.length > 11 && st1.length > 11 && st === st1) ||
      timeSlotCheckInBeetween(sf, sf1, st1) ||
      timeSlotCheckInBeetween(st, sf1, st1) ||
      timeSlotCheckInBeetween(sf1, sf, st) ||
      timeSlotCheckInBeetween(st1, sf, st)
    ) {
      currentError1 = true;
      currentError2 = true;
    }
    // }

    currentData["ole1"] = currentError1;
    currentData["ole2"] = currentError2;

    if (currentError1 || currentError2) {
      return true;
    }
    return false;

    // return listData;
  }
};

export const checkAddedTimeInnerTimekeeper = (listData, currentData) => {
  // console.log('listData helper 111111', listData);

  if (listData && listData.length > 0 && currentData) {
    let sf = currentData.date + " " + currentData.time_from;
    let st = currentData.date + " " + currentData.time_to;

    let sf1 = currentData.date + " " + currentData.time_from1;
    let st1 = currentData.date + " " + currentData.time_to1;
    // console.log(sf1,'#master',st1);
    let currentError1 = false;
    let currentError2 = false;

    listData.forEach((element, index) => {
      //Check selected time slot's Form time is between any other row's range.

      if (
        element.jobtype == currentData.jobtype &&
        element?.EmployeeID == currentData?.EmployeeID &&
        // element.jobtype === "REG" &&
        // currentData.jobtype === "REG" &&
        element.date === currentData.date &&
        ((element.time_from && element.time_to) ||
          (element.time_from1 && element.time_to1))
      ) {
        //First time slot propery entered.
        let ef = element.date + " " + element.time_from;
        let et = `${element.date} ${element.time_to}`;

        let ef1 = element.date + " " + element.time_from1;
        let et1 = element.date + " " + element.time_to1;

        let elementError1 = false;
        let elementError2 = false;
        // console.log(ef1,'#master inn',et1,currentData.id ,'!==', element.id ,'#index',index);
        if (currentData.id !== element.id) {
          if (
            (sf.length > 11 && ef.length > 11 && sf === ef) ||
            (st.length > 11 && et.length > 11 && st === et) ||
            timeSlotCheckInBeetween(sf, ef, et) ||
            timeSlotCheckInBeetween(st, ef, et) ||
            timeSlotCheckInBeetween(ef, sf, st) ||
            timeSlotCheckInBeetween(et, sf, st)
          ) {
            elementError1 = true;
            currentError1 = true;
          }

          if (
            (sf.length > 11 && ef1.length > 11 && sf === ef1) ||
            (st.length > 11 && et1.length > 11 && st === et1) ||
            timeSlotCheckInBeetween(sf, ef1, et1) ||
            timeSlotCheckInBeetween(st, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf, st) ||
            timeSlotCheckInBeetween(et1, sf, st)
          ) {
            elementError2 = true;
            currentError1 = true;
          }

          if (
            (sf1.length > 11 && ef.length > 11 && sf1 === ef) ||
            (st1.length > 11 && et.length > 11 && st1 === et) ||
            timeSlotCheckInBeetween(sf1, ef, et) ||
            timeSlotCheckInBeetween(st1, ef, et) ||
            timeSlotCheckInBeetween(ef, sf1, st1) ||
            timeSlotCheckInBeetween(et, sf1, st1)
          ) {
            elementError1 = true;
            currentError2 = true;
          }
          if (
            (sf1.length > 11 && ef1.length > 11 && sf1 === ef1) ||
            (st1.length > 11 && st1.length > 11 && st1 === et1) ||
            timeSlotCheckInBeetween(sf1, ef1, et1) ||
            timeSlotCheckInBeetween(st1, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf1, st1) ||
            timeSlotCheckInBeetween(et1, sf1, st1)
          ) {
            elementError2 = true;
            currentError2 = true;
          }
          if (
            element.time_from &&
            element.time_to &&
            !element.time_from1 &&
            !element.time_to1
          ) {
            elementError2 = false;
            currentError2 = false;
          }

          element["ole1"] = elementError1;
          element["ole2"] = elementError2;
          currentData["ole2"] = currentError2;
        } else {
          console.log("CHECK IN OWN TIME SLOT ");
        }
      }
    });

    // if (currentData.jobtype === "REG") {
    if (
      (sf.length > 11 && sf1.length > 11 && sf === sf1) ||
      (st.length > 11 && st1.length > 11 && st === st1) ||
      timeSlotCheckInBeetween(sf, sf1, st1) ||
      timeSlotCheckInBeetween(st, sf1, st1) ||
      timeSlotCheckInBeetween(sf1, sf, st) ||
      timeSlotCheckInBeetween(st1, sf, st)
    ) {
      currentError1 = true;
      currentError2 = true;
    }
    // }

    currentData["ole1"] = currentError1;
    currentData["ole2"] = currentError2;

    if (currentError1 || currentError2) {
      return true;
    }
    return false;

    // return listData;
  }
};

export const checkAddedTimeInnerv2 = (listData, currentData) => {
  if (listData && listData.length > 0 && currentData) {
    let sf = currentData.date + " " + currentData.time_from;
    let st = currentData.date + " " + currentData.time_to;

    // let sf1 = currentData.date + " " + currentData.time_from1;
    // let st1 = currentData.date + " " + currentData.time_to1;

    let currentError1 = false;
    let currentError2 = false;

    listData.forEach((element) => {
      //Check selected time slot's Form time is between any other row's range.

      if (
        // element.jobtype === "REG" &&
        // currentData.jobtype === "REG" &&
        element.date === currentData.date &&
        element.time_from &&
        element.time_to
      ) {
        //First time slot propery entered.
        let ef = element.date + " " + element.time_from;
        let et = `${element.date} ${element.time_to}`;

        // let ef1 = element.date + " " + element.time_from1;
        // let et1 = element.date + " " + element.time_to1;

        let elementError1 = false;
        let elementError2 = false;

        if (currentData.id !== element.id) {
          if (
            (sf.length > 11 && ef.length > 11 && sf === ef) ||
            (st.length > 11 && et.length > 11 && st === et) ||
            timeSlotCheckInBeetween(sf, ef, et) ||
            timeSlotCheckInBeetween(st, ef, et) ||
            timeSlotCheckInBeetween(ef, sf, st) ||
            timeSlotCheckInBeetween(et, sf, st)
          ) {
            elementError1 = true;
            currentError1 = true;
          }

          // if (
          //   (sf.length > 11 && ef1.length > 11 && sf === ef1) ||
          //   (st.length > 11 && et1.length > 11 && st === et1) ||
          //   timeSlotCheckInBeetween(sf, ef1, et1) ||
          //   timeSlotCheckInBeetween(st, ef1, et1) ||
          //   timeSlotCheckInBeetween(ef1, sf, st) ||
          //   timeSlotCheckInBeetween(et1, sf, st)
          // ) {
          //   elementError2 = true;
          //   currentError1 = true;
          // }

          // if (
          //   (sf1.length > 11 && ef.length > 11 && sf1 === ef) ||
          //   (st1.length > 11 && et.length > 11 && st1 === et) ||
          //   timeSlotCheckInBeetween(sf1, ef, et) ||
          //   timeSlotCheckInBeetween(st1, ef, et) ||
          //   timeSlotCheckInBeetween(ef, sf1, st1) ||
          //   timeSlotCheckInBeetween(et, sf1, st1)
          // ) {
          //   elementError1 = true;
          //   currentError2 = true;
          // }
          // if (
          //   (sf1.length > 11 && ef1.length > 11 && sf1 === ef1) ||
          //   (st1.length > 11 && st1.length > 11 && st1 === et1) ||
          //   timeSlotCheckInBeetween(sf1, ef1, et1) ||
          //   timeSlotCheckInBeetween(st1, ef1, et1) ||
          //   timeSlotCheckInBeetween(ef1, sf1, st1) ||
          //   timeSlotCheckInBeetween(et1, sf1, st1)
          // ) {
          //   elementError2 = true;
          //   currentError2 = true;
          // }
          if (element.time_from && element.time_to) {
            elementError2 = false;
            currentError2 = false;
          }

          element["ole1"] = elementError1;
          // element["ole2"] = elementError2;
          currentData["ole2"] = currentError2;
        } else {
          console.log("CHECK IN OWN TIME SLOT ");
        }
      }
    });

    // if (currentData.jobtype === "REG") {
    //   if (
    //     (sf.length > 11 && sf1.length > 11 && sf === sf1) ||
    //     (st.length > 11 && st1.length > 11 && st === st1) ||
    //     timeSlotCheckInBeetween(sf, sf1, st1) ||
    //     timeSlotCheckInBeetween(st, sf1, st1) ||
    //     timeSlotCheckInBeetween(sf1, sf, st) ||
    //     timeSlotCheckInBeetween(st1, sf, st)
    //   ) {
    //     currentError1 = true;
    //     currentError2 = true;
    //   }
    // }

    currentData["ole1"] = currentError1;
    // currentData["ole2"] = currentError2;

    if (currentError1 || currentError2) {
      return true;
    }
    return false;

    // return listData;
  }
};
export const formatMoney = (number) => {
  number = parseFloat(number);
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const format2Decimal = (number) => {
  number = parseFloat(number);
  var rounded = Math.round((number + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const getUserRole = (userData) => {
  if (!userData) {
  }
  if (userData && userData.profile && userData.profile.role) {
    return userData.profile.role;
  } else {
    return "";
  }
};

export const getUserId = (userData) => {
  if (!userData) {
  }
  if (userData && userData.profile && userData.profile.EmployeeID) {
    return userData.profile.EmployeeID;
  } else {
    return 0; // for test admin dashboard
  }
};

export const checkEnterTimeLate = (array, startTime, date) => {
  let today = moment().format("dddd");

  if (date) {
    today = date;
  }

  for (let i = 0; i < array.length; i++) {
    if (array[i].Day === today) {
      const fromTime = moment(array[i].From).utc().format("HH:mm");
      const toTime = moment(array[i].To).utc().format("HH:mm");
      if (startTime >= fromTime && startTime <= toTime) {
        const diffMinutes = moment(startTime, "HH:mm")
          .utc()
          .diff(moment(fromTime, "HH:mm").utc(), "minutes");
        if (diffMinutes >= 15) {
          return "Y";
        } else {
          return "N";
        }
      }
    }
  }

  return "N";
};

export const getHoursMinutes = (minutes, type) => {
  if (type.toLowerCase() === "h") {
    let hours = Math.floor(minutes / 60);
    return hours;
  } else if (type.toLowerCase() === "m") {
    let min = parseInt(minutes) % 60;
    return min;
  } else if (type.toLowerCase() === "tm") {
    let min = minutes;
    return min;
  } else if (type.toLowerCase() === "hm") {
    let hrCalc = HourMinFormat(`${Math.floor(minutes / 60)}:${minutes % 60}`);
    return hrCalc;
  } else {
    return 0;
  }
};

export const isOnLeave = (data) => {
  let isLeave = data?.findIndex((i) => {
    return ["A/L", "S/L"].includes(i.jobtype);
  });

  return isLeave === -1 ? false : true;
};

export const handleTimeToMinutes = (time) => {
  let arr = time.split(":");
  return parseInt(arr[0] * 60) + parseInt(arr[1]);
};

export const WeekColor = (startDate, EndDate, currentDate, paycode = "") => {
  let result = {};
  const week1StartDate = startDate;
  const week1EndDate = moment(startDate, "YYYY-MM-DD")
    .add(6, "d")
    .format("YYYY-MM-DD");
  const week2StartDate = moment(startDate, "YYYY-MM-DD")
    .add(7, "d")
    .format("YYYY-MM-DD");
  const week2EndDate = EndDate;
  if (["O/T1.5"].includes(paycode)) {
    return "#b1d4ff6b";
  }

  if (currentDate >= week1StartDate && currentDate <= week1EndDate) {
    return "#f6e5e5";
  } else if (currentDate >= week2StartDate && currentDate <= week2EndDate) {
    return "#d9ecd9";
  } else {
    return "#f6e5e5";
  }
};

export const adjustRoundedHR = (hrs) => {
  let arr = hrs.split(":");
  const hr = parseInt(arr[0]);
  const minutes = parseInt(arr[1]);
  let adjustedStartTime;
  if (minutes >= 38 && minutes <= 52) {
    adjustedStartTime = `${zeroNumPad(hr, 2)}:45`;
  } else if (minutes >= 8 && minutes <= 21) {
    adjustedStartTime = `${zeroNumPad(hr, 2)}:15`;
  } else if (minutes >= 22 && minutes <= 37) {
    adjustedStartTime = `${zeroNumPad(hr, 2)}:30`;
  } else {
    if (minutes >= 0 && minutes <= 7) {
      adjustedStartTime = `${zeroNumPad(hr, 2)}:00`;
    } else {
      adjustedStartTime = `${zeroNumPad(hr + 1, 2)}:00`;
    }
  }
  return adjustedStartTime;
};

export const adjustRoundedTime = (startTime) => {
  const minutes = moment(startTime, "HH:mm").format("m");

  let adjustedStartTime;

  if (minutes >= 38 && minutes <= 52) {
    adjustedStartTime = moment(startTime, "HH:mm").format("HH:45");
  } else if (minutes >= 8 && minutes <= 21) {
    adjustedStartTime = moment(startTime, "HH:mm").format("HH:15");
  } else if (minutes >= 22 && minutes <= 37) {
    adjustedStartTime = moment(startTime, "HH:mm").format("HH:30");
  } else {
    if (minutes >= 0 && minutes <= 7) {
      adjustedStartTime = moment(startTime, "HH:mm").format("HH:00");
    } else {
      adjustedStartTime = moment(startTime, "HH:mm")
        .add(1, "hours")
        .format("HH:00");
    }
  }
  return adjustedStartTime;
};

export const getHMFromTime = (time_from1, time_to1) => {
  const startTime1 = moment(time_from1, "HH:mm");
  const endTime1 = moment(time_to1, "HH:mm");
  let duration1 = moment.duration(endTime1.diff(startTime1));
  if (duration1.asHours() < 0) {
    const endTime3 = moment(time_to1, "HH:mm").add(1, "day");
    duration1 = moment.duration(endTime3.diff(startTime1));
  }

  const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
  const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

  let hrCalc = HourMinFormat(`${Math.floor(totalMin1 / 60)}:${totalMin1 % 60}`);

  return {
    min: totalMin1,
    hr: hours1,
    hhmm: hrCalc,
  };
};

export const checkIsHoliday = (data, date) => {
  let datas = data?.find((i) => moment(i.date).format("YYYY-MM-DD") == date);
  if (datas) {
    return {
      status: true,
      name: moment(datas.date).format("MM-DD-YYYY") + " is " + datas.name,
    };
  } else {
    return { status: false, name: "" };
  }
};

export const checkEmpTag = (data, code) => {
  let datas = data?.find((i) => i.tagCode == code);
  if (datas) {
    return { status: true, multi: datas.multi };
  } else {
    return { status: false, multi: 0 };
  }
};

export const checkEmpDepartment = (userInfo, deptID, deptDIV = false) => {
  if (userInfo?.DPDV?.trim() == deptID) {
    return true;
  } else if (deptDIV && userInfo?.DPDV.startsWith(deptID)) {
    return true;
  } else {
    return false;
  }
};

export const addPad = (time) => {
  console.log("dasasddad", time);
  if (!time) {
    return null;
  }
  time = time.toUpperCase();
  if (time.includes('AM') || time.includes('PM')) {
     time = moment(time, 'hh:mm A').format("HH:mm");
  }

  let arr = time?.split(":");

  let arr1 = arr[0].padStart(2, "0");
  let arr2 = arr[1].padStart(2, "0");

  let final = `${arr1}:${arr2}`;
  console.log("cdscfdsfdsf", final);


  
  return final;
  // return parseInt(arr[0] * 60) + parseInt(arr[1]);
};
export const uniqueValue = (array, uniquekey) => {
  const uniqBy = (arr, selector = (item) => item) => {
    const map = new Map();
    arr.forEach((item) => {
      const prop = selector(item);
      if (!map.has(prop)) map.set(prop, item[uniquekey]);
    });
    return [...map.values()];
  };

  const data = uniqBy(array, (item) => item[uniquekey]);
  return data;
};
