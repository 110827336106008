import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "reactstrap";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./RequestTable";
import { useSelector } from "react-redux";

const DetailsTable = (props) => {
  const { detail, setDetail, configrationJSON, classes, expand, handleChange, setIsUpdate } =
    props;
  const { data } = useSelector((state) => state.TableSlice);

  return (
    <>
      <Card className="mt-3 mb-3">
        <Accordion
          className={classes.root}
          expanded={expand.panel2}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            className="bg-gray-200 sub-header"
          >
            <FontAwesomeIcon
              icon={faRectangleList}
              className="sub-header"
              style={{
                marginRight: "10px",
                marginTop: "3px",
              }}
            />
            <Typography className={classes.heading}>
              {configrationJSON.detailTableTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="p-0 page-body-wrapper">
            <RequestTable
              {...props}
              requestDetail={data[configrationJSON.getAPIKey]}
              detail={detail}
              setDetail={setDetail}
              setIsUpdate={setIsUpdate}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
};

export default DetailsTable;
