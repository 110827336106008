import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router";

import TableComponent from "../../ConfigurationMaster/DeductionPlanMaster/tableComponent";
import DepartMentFilterList from "./DepartMentFilterList";
import axios from "axios";

import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPeople, faUser } from "@fortawesome/pro-duotone-svg-icons";
import Header1 from "./component/header1";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DepartMentList = (props) => {
  const classes = useStyles();
  const params = useParams();
  const history = useNavigate();

  const dispatch = useDispatch();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const [requestDetail, setReqestDetail] = useState([]);

  const [filterBy, setFilter] = useState({
    DEPT_NAME: "",
    EMP_CNT: "",
  });
  console.log("PROPS::: ", props);
  const getData = () => {
    console.log("params..", params);
    let adv_issue_query = JSON.parse(localStorage.getItem("adv_issue_query"));
    let oldParams = adv_issue_query.params;
    const stateVal = params.id;
    const ppeId = params.ppeId;

    console.log("oldParams", oldParams);
    // oldParams.push({
    //   value: stateVal,
    //   operator: "=",
    //   column: "Stat",
    //   dataType: "VARCHAR(MAX)",
    //   columnName: "Stat",
    // });

    let findStat = oldParams.find((i) => {
      return i.column == "Stat";
    });
    if (!findStat) {
      oldParams.push({
        value: stateVal,
        operator: "=",
        column: "Stat",
        dataType: "VARCHAR(MAX)",
        columnName: "Stat",
      });
    }

    let findPPE = oldParams.find((i) => {
      return i.column == "PPE";
    });
    if (!findPPE && ppeId) {
      oldParams.push({
        value: ppeId,
        operator: "=",
        column: "PPE",
        dataType: "",
        columnName: "PPE",
      });
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        {
          tableName: "sp_findIssueForDepartment",
          params: oldParams,
          columns: "*",
          page: 1,
          limit: 2000,
          orderBy: "PPE",
          sortBy: "DESC",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );

        setReqestDetail(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    setFilter({ ...filterBy, [name]: value });
  };

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.DEPT_NAME) {
        dataList = dataList.filter((data) =>
          data.DEPT_NAME.toLowerCase().includes(
            filterBy.DEPT_NAME?.toLowerCase()
          )
        );
      }

      if (filterBy?.EMP_CNT) {
        dataList = dataList.filter((data) =>
          data.EMP_CNT.toLowerCase().includes(
            filterBy.EMP_CNT.toLocaleLowerCase()
          )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    console.log("MaData", data);
    return history(`/admin/working-hrs/employee/${data.DPDV}/${params.id}`);
    let info = {
      id: data.ID,
      DEPT_NAME: data.DEPT_NAME,
      description: data.EMP_CNT,
    };

    props.setDetail(info);
  };

  const columns = [
    // {
    //   id: "ACTION",
    //   name: "ACTION",
    //   type: "",
    // },
    {
      id: "ACTION",
      name: "DPDV",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip title="Employees List" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    if (params.ppeId) {
                      return history(
                        `/admin/working-hrs/employee/${value}/${params.id}/${params.ppeId}`
                      );
                    } else {
                      return history(
                        `/admin/working-hrs/employee/${value}/${params.id}`
                      );
                    }
                  }}
                  icon={faUser}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    { id: "DEPT_NAME", label: "DEPARTMENT NAME", name: "DEPT_NAME", type: "" },
    {
      id: "PPE_Date",
      name: "PPE_Date",
      label: "PPE",

      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("MM-DD-yyyy") : "-";
        },
      },
    },
    {
      id: "EMP_CNT",
      name: "EMP_CNT",
      label: "EMPLOYEE COUNT",
    },
  ];

  return (
    <>
      <Header1
        {...props}
        title={`Payroll Processing Exception Reports `}
      />

      {/* <TableComponent
        columns={columns}
        filteredList={filteredList}
        // onRowClick={handleRowClick}
        // tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <DepartMentFilterList
              changeHeaderFilter={changeHeaderFilter1}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      /> */}
      <div style={{ width: "100%" }}>
        <MUIDataTable
          className={classes.customData}
          title={<Typography variant="h6">Department List</Typography>}
          data={requestDetail}
          columns={columns}
          options={{
            selectableRows: false, // <===== will turn off checkboxes in rows
          }}
          selectableRowsHideCheckboxes={false}
        />
      </div>
    </>
  );
};

export default DepartMentList;
