import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import Icon from "@material-ui/core/Icon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faFileExport,
  faAddressCard,
  faCalendar,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { connect } from "react-redux";
import { CardHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../AbstractElements";

function PayrollMasterHeader(props) {
  const history = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);

    }
}, []);

const isMobile = width <= 768; 
  const headers = [
    { header: "Employee Name", key: "employee_name" },
    { header: "Employee Number", key: "employee_number" },
    { header: "Department", key: "department" },
    { header: "Division", key: "division" },
    { header: "Date Of Birth", key: "dob" },
    { header: "Rate", key: "rate" },
  ];
  return (
    // <CardHeader style={{marginTop:isMobile ? "40px" : "0px"}}>
    //   <div
    //     className={`flex flex-1 w-full bg-primary`}
    //     style={{
    //       display: "flex",
    //       flexDirection: "row",
    //       // backgroundColor: "#1975D2",
    //       height: "4.2rem",
    //       borderRadius: "5px",
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //     }}
    //     >
    //       <div style={{display:"flex"}}>
    //     <FontAwesomeIcon icon={faAddressCard} className="mx-10 mt-1 px-3 fs-4" />

    //     <Typography className="fs-5">Payroll Master Detail</Typography>
    //     </div>
     
    //     <div
    //       style={{ display: "flex", justifyContent: "end" }}
    //       className="mx-4"
    //     >
        
    //          {!isMobile && props?.breadcrumbs?.path ? 
    //       <div className="mt-4" style={{ width: "100%", right: "0" }}>
            // <Breadcrumbs
            //   parent={
            //     <Link to={props.breadcrumbs?.path} className="font-light">
            //       {props.breadcrumbs?.breadName}
            //     </Link>
            //   }
            //   title={"Employee Details"}
            // />
    //       </div> : null}
          // <div
          //   style={{ marginTop:props.breadcrumbs ? "28px" : "0" ,cursor: "pointer" }}
            
          //   onClick={() => {
          //     return history(`/payroll-work/${props.ID}`);
          //   }}
          // >
    //         <FontAwesomeIcon icon={faCalendar} />
    //       </div>
    //     </div>
      
    //   </div>
    // </CardHeader>
    <CardHeader >
    {/* Outer div */}
    <div
      className="flex flex-1 w-full bg-primary"
      style={{
        display: "flex",
        flexDirection: "row",
        height: "4.2rem",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* Left side content */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={faAddressCard} className="mx-10 mt-1 px-3 fs-4" />
        <Typography className="fs-5">Payroll Master Detail</Typography>
      </div>
  
      {/* Right side content */}
      
          <div style={{ display: "flex", alignItems: "center", }}>
      {/* Home link */}
      <div style={{ marginRight: "10px", marginLeft: "20px" }}>
        <Link to="/dashboard/default" className="font-light">
          Home
        </Link>
        <span className="font-light"> / </span>
      </div>

      {/* Employee Management link */}
      <div style={{ marginRight: "10px",  }}>
        <Link to="/admin/employee" className="font-light">
          Employee Management
        </Link>
        <span className="font-light"> / </span>
      </div>

      {/* Employee Details link */}
      <div style={{ marginRight: "10px" }}>

          Employee Details
        
      </div>
        
        {/* <div
            style={{ marginTop:props.breadcrumbs ? "28px" : "0" ,cursor: "pointer" }}
            
            onClick={() => {
              return history(`/payroll-work/${props.ID}`);
            }}
          >
          <FontAwesomeIcon icon={faCalendar} />
        </div> */}
      </div>
    </div>
  </CardHeader>
  

  );
}

export default PayrollMasterHeader;
