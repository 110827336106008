import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
import { Col, Label, Row } from "reactstrap";

// import MaskedInput from "react-text-mask";
function PayCodeManagement(props) {
  return (
    <Row style={{ width: "100%" }} className="g-3">
      <Col md={6}>
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Department Code"
          value={props.detail.code}
          onChange={(e) => {
            props.setDetail({ ...props.detail, code: e.target.value });
          }}
          name="eName"
        />

        {/* <Label className="labelColor">Department Code</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Department Code"
          name="eName"
          value={props.detail.code}
          onChange={(e) => {
            props.setDetail({ ...props.detail, code: e.target.value });
          }}
        /> */}
      </Col>
      <Col md={6}>
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Description"
          value={props.detail.description}
          onChange={(e) => {
            props.setDetail({
              ...props.detail,
              description: e.target.value,
            });
          }}
          name="eName"
        />
        {/* <Label className="labelColor">Description</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Description"
          name="eName"
          value={props.detail.description}
          onChange={(e) => {
            props.setDetail({
              ...props.detail,
              description: e.target.value,
            });
          }}
        /> */}
      </Col>
    </Row>
  );
}

export default PayCodeManagement;
