import React from "react";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "oidc-react";
import { isAllowedURL } from "../../../_helper/helper";
import { Breadcrumbs } from "../../../AbstractElements";

const Header = ({ title, icon, url, ...props }) => {
    const { userData } = useAuth();
    const params = useParams();

    return (
        <Breadcrumbs
            mainTitle={
                <Typography variant="h5">
                    {!icon && <FontAwesomeIcon
                        icon={icon}
                        className="m-r-10"
                    />}
                    {title}
                </Typography>
            }
            parent={
                isAllowedURL(url, userData)
                    ? (
                        <Link
                            to={`${process.env.PUBLIC_URL}/${url}`}
                            className="font-light"
                        >
                            {title}
                        </Link>
                    )
                    : undefined
            }
            title={params?.id ? "Details" : "New"}
        />
    );
}

export default Header;
