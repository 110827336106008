import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { IconButton, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ShowError, ShowSucess } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { getUserId, getUserRole } from "../../../app/utility/helper";
import TimeSheetHeader from "./header";

const inputProps = {
  style: { fontSize: 14, step: 300 },
  step: 300,
};
function PayCodeTemplateDetails() {
  const location = useLocation();
  const history = useNavigate();

  const { userData } = useAuth();
  const userId = getUserId(userData);
  const userRole = getUserRole(userData);

  const [payCodeList, setPayCodeList] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      payCodeId: "",
      payCodeAbberv: "",
    },
  ]);

  const [templateTitle, setTemplateTitle] = useState();
  const [detail, setDetail] = useState({
    code: "",
    effectDate: null,
    expireDate: null,
    abbreviation: "",
    description: "",
    multi: 0,
  });

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        // setTableList(response.data);
        let newArray =
          response.data.length &&
          response.data.map((item, index) => {
            return {
              value: index,
              payCodeId: item.Code,
              payCodeAbberv: item.Abberv,
            };
          });
        setPayCodeList(newArray);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      { value: "", payCodeId: "", payCodeAbberv: "" },
    ]);
  };

  useEffect(() => {
    console.log("data ::: ", location.state);
    if (!location.state) {
      return false;
    }
    // if (location.state) {
    //   let data = location.state;
    //   let newSheetData = data?.TimeSheetProfiles;
    //   let newArray = [];
    //   newSheetData.length > 0 &&
    //     newSheetData.map((obj) => {
    //       newArray.push({
    //         selectedDay: obj.Day,
    //         time_from: obj.From && moment(obj.From).utc().format("HH:mm"),
    //         time_to: obj.To && moment(obj.To).utc().format("HH:mm"),
    //       });
    //     });
    //   setTemplateTitle(data?.TimeSheetName);
    //   setInputFields(
    //     newArray.length > 0
    //       ? newArray
    //       : [{ selectedDay: "", time_from: "", time_to: "" }]
    //   );
    // }
  }, [location]);

  const removeInputFields = (data, index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, data, key) => {
    console.log("index, data", index, data);

    let inputData = [...inputFields];
    inputData[index][`${key}`] = data;
    setInputFields(inputData);

    // setInputFields([{ ...inputFields, selectedDay: data }]);
  };

  const addTimeSheet = () => {
    if (!templateTitle || templateTitle === "") {
      ShowError("Please Enter Title Value!");
      return false;
    }

    let newData = [];

    inputFields.map((obj) => {
      newData.push({
        PayCode: obj.payCodeId.payCodeId,
      });
    });

    console.log("Data sheet add : ", newData, templateTitle);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}typecode/create`,
        {
          TypeCodeName: templateTitle,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            selectedDay: "",
            time_from: "",
            time_to: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/paycode-template`);
        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const updateTimeSheet = () => {
    let newData = [];

    inputFields.map((obj, index) => {
      newData.push({
        Day: obj.selectedDay,
        From: moment(obj.time_from, "HH:mm").format("HH:mm:00"),
        To: moment(obj.time_to, "HH:mm").format("HH:mm:00"),
        SnNo: index,
      });
    });

    console.log("Data sheet : ", location);

    // return false;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/update`,
        {
          TimeSheetName: templateTitle,
          TimeSheetID: location?.state?.ID,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            payCodeId: "",
            payCodeAbberv: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/paycode-template`);

        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <div>
      <TimeSheetHeader screen={"details"} data={location.state} />
      <div style={{ padding: "16px 2px" }}>
        <Card style={{ gap: "10px" }} elevation={4} className=" p-10  ">
          {/* <h3>TimeSheet Template</h3> */}
          <TextField
            size={"small"}
            id="time"
            label="Template Title"
            // placeholder="Template Title"
            type="text"
            sx={{ width: "250px" }}
            inputProps={inputProps}
            value={templateTitle}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
              shrink: true,
            }}
            onChange={(e) => {
              // handleChange(index, e.target.value, "time_from");
              console.log("index eeee", e);
              setTemplateTitle(e.target.value);
            }}
            margin="dense"
            name="template_title"
            fullWidth={true}
            variant="outlined"
            hiddenLabel="true"
          />
          {JSON.stringify(inputFields)}
          <table style={{ width: "fit-content" }}>
            <tr>
              <td>Pay Code</td>
              {/* <td>From Time</td>
              <td>To Time</td> */}
            </tr>
            {inputFields.map((data, index) => {
              const { fullName, emailAddress, salary } = data;
              return (
                <tr>
                  <td>
                    <Autocomplete
                      // value={inputFields[index].payCodeAbberv}
                      value={inputFields[index]["payCodeId"]}
                      size="small"
                      onChange={(event, newValue) => {
                        handleChange(index, newValue, "payCodeId");
                        // console.log("Clicked... ", event.target.value, newValue);
                        // setInputFields({ ...inputFields, selectedDay: newValue });
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      // id="free-solo-with-text-demo"
                      id="controllable-states-demo"
                      options={payCodeList}
                      getOptionLabel={(option) => {
                        console.log("12331233 ::: ", option);
                        return option.payCodeAbberv || "";
                      }}
                      // renderOption={(props, option) => (
                      //   console.log("props option", props, option),
                      //   (<li {...props}>{option}</li>)
                      // )}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label="Choose PayCode" />
                      )}
                    />
                  </td>
                  {/* <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      // error={ole1 ? true : false}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_from}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_from");
                        console.log("index eeee", index, e);
                      }}
                      margin="dense"
                      name="time_from"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td>
                  <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_to}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_to");
                      }}
                      margin="dense"
                      name="time_to"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td> */}
                  <td>
                    {inputFields.length !== index + 1 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => removeInputFields(data, index)}
                      >
                        x
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-success"
                        onClick={addInputField}
                      >
                        +
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>

          <div className="row">
            <div className="col-sm-12">
              {location.state && location.state.ID ? (
                <>
                  {/* {userRole === "admin" ? (
                  <button
                    className="btn btn-outline-success"
                    onClick={updateTimeSheet}
                  >
                    Update
                  </button>
                ) : (
                  <> */}
                  {["admin", "supervisor", "timekeeper"].includes(userRole) &&
                  userId === location?.state?.EmployeeID ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={updateTimeSheet}
                      style={{
                        textTransform: "none",
                        color: "white",
                      }}
                      className="bg-primary"
                    >
                      Update Template
                    </Button>
                  ) : (
                    <></>
                  )}
                  {/* </>
                )} */}
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={addTimeSheet}
                  style={{
                    textTransform: "none",
                    color: "white",
                  }}
                  className="bg-primary"
                >
                  Add Template
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default PayCodeTemplateDetails;
