import React, {
  Component,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import "./timesheet.css";
import moment from "moment";
import EventModal from "./EventModal";
import { Day } from "@material-ui/pickers";
import axios from "axios";
import {
  HourMinFormat,
  KeyGen,
  RemoveSpaceKeyGen,
  ShowError,
  d2Time,
  getUserRole,
  getValidPaycodeOfDay,
  payCodeFilter,
} from "../../app/utility/helper";
import ConfigDB from "../../Config/ThemeConfig";
import { LegendToggleOutlined } from "@mui/icons-material";
import { disableBodyScroll } from "body-scroll-lock";
import { Log, useAuth } from "oidc-react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ALERT } from "../../Constant";

function getSplitRangeData(startTime, endTime, existingSlots) {
  const timeInterval = 30; // minutes
  const range = [];

  let currentTime = new Date(`01/01/2000 ${startTime}`);

  while (currentTime < new Date(`01/01/2000 ${endTime}`)) {
    const timeString = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let isAvailable = true;
    let existingTime = null;

    for (let i = 0; i < existingSlots.length; i++) {
      const slotStart = new Date(`01/01/2000 ${existingSlots[i].start}`);
      const slotEnd = new Date(`01/01/2000 ${existingSlots[i].end}`);

      if (currentTime >= slotStart && currentTime < slotEnd) {
        isAvailable = false;
        existingTime = {
          start: existingSlots[i].start,
          end: existingSlots[i].end,
        };
        break;
      }
    }

    if (isAvailable) {
      range.push({
        time: timeString,
        isAvailable: true,
        existingTime: null,
      });
    } else {
      range.push({
        time: timeString,
        isAvailable: false,
        existingTime: existingTime,
      });
    }

    currentTime.setMinutes(currentTime.getMinutes() + timeInterval);
  }

  return range;
}

// const  splitTimeRange = (startTime, endTime, existingSlots) => {
//   // Create a copy of the existing slots array to avoid modifying the original
//   let slots = existingSlots.slice();

//   // Add the start and end times to the slots array if they are not already in it
//   if (startTime !== slots[0].fromTime) {
//     slots.unshift({ start: startTime, end: slots[0].toTime });
//   }
//   if (endTime !== slots[slots.length - 1].toTime) {
//     slots.push({ start: slots[slots.length - 1].fromTime  , end: endTime });
//   }

//   // Create a new array to hold the split slots
//   let splitSlots = [];

//   // Iterate over the existing slots and split them into smaller slots as necessary
//   for (let i = 0; i < slots.length - 1; i++) {
//     let currentSlot = slots[i];
//     let nextSlot = slots[i + 1];

//     // Check if there is a gap between the current and next slots
//     if (currentSlot.toTime < nextSlot.fromTime) {
//       // Create a new slot for the gap
//       splitSlots.push({ start: currentSlot.toTime, end: nextSlot.fromTime });
//     }
//   }

//   // Combine the existing and split slots and return the result
//   return slots.concat(splitSlots);
// }

// const  correctionTime = (existingTime, selectedTime) =>function correctTime(existingTime, selectedTime) {
//   let correctedTime = [];
//   let existingIndex = 0;
//   let selectedIndex = 0;
//   while (existingIndex < existingTime.length && selectedIndex < selectedTime.length) {
//     let existingStart = existingTime[existingIndex].start;
//     let existingEnd = existingTime[existingIndex].end;
//     let selectedStart = selectedTime[selectedIndex].start;
//     let selectedEnd = selectedTime[selectedIndex].end;
//     if (selectedStart >= existingStart && selectedEnd <= existingEnd) {
//       // Selected time is completely inside existing time
//       let timeRange1 = { start: existingStart, end: selectedStart };
//       let timeRange2 = { start: selectedEnd, end: existingEnd };
//       if (timeRange1.start !== timeRange1.end) {
//         correctedTime.push(timeRange1);
//       }
//       if (timeRange2.start !== timeRange2.end) {
//         correctedTime.push(timeRange2);
//       }
//       existingIndex++;
//     } else if (selectedEnd <= existingStart || selectedStart >= existingEnd) {
//       // Selected time is completely outside existing time
//       if (selectedEnd > existingStart && selectedStart < existingEnd) {
//         // Selected time overlaps with existing time
//         if (selectedStart < existingStart) {
//           correctedTime.push({ start: selectedStart, end: existingStart });
//         }
//         if (selectedEnd > existingEnd) {
//           correctedTime.push({ start: existingEnd, end: selectedEnd });
//         }
//       }
//       existingIndex++;
//     } else if (selectedStart < existingStart && selectedEnd > existingEnd) {
//       // Existing time is completely inside selected time
//       correctedTime.push(selectedTime[selectedIndex]);
//       selectedIndex++;
//     } else if (selectedStart < existingStart && selectedEnd <= existingEnd) {
//       // Selected time overlaps with the start of existing time
//       correctedTime.push({ start: selectedStart, end: existingStart });
//       existingTime[existingIndex].start = selectedEnd;
//       selectedIndex++;
//     } else if (selectedStart >= existingStart && selectedEnd > existingEnd) {
//       // Selected time overlaps with the end of existing time
//       correctedTime.push({ start: existingStart, end: selectedStart });
//       existingTime[existingIndex].end = selectedStart;
//       existingIndex++;
//     }
//   }
//   while (existingIndex < existingTime.length) {
//     // Add any remaining existing times to corrected time
//     correctedTime.push(existingTime[existingIndex]);
//     existingIndex++;
//   }
//   while (selectedIndex < selectedTime.length) {
//     // Add any remaining selected times to corrected time
//     correctedTime.push(selectedTime[selectedIndex]);
//     selectedIndex++;
//   }
//   return correctedTime;
// }

const splitTimeRange = (startTime, endTime, existingTime) => {
  // Create a copy of the existing time slots array to avoid modifying the original
  let slots = [{ start: startTime, end: endTime }];
  for (let i = 0; i < existingTime.length; i++) {
    const currentSlot = existingTime[i];
    const splitSlots = [];

    for (let j = 0; j < slots.length; j++) {
      const slot = slots[j];

      if (
        currentSlot.fromTime >= slot.start &&
        currentSlot.toTime <= slot.end
      ) {
        console.log("case ====:::: 1");
        // The existing slot is fully contained within the current slot, so split the current slot
        splitSlots.push({ start: slot.start, end: currentSlot.fromTime });
        splitSlots.push({
          start: currentSlot.fromTime,
          end: currentSlot.toTime,
        });
        splitSlots.push({ start: currentSlot.toTime, end: slot.end });
      } else if (
        currentSlot.fromTime < slot.end &&
        currentSlot.toTime > slot.start
      ) {
        // The existing slot partially overlaps the current slot, so split the current slot into two parts
        if (slot.start < currentSlot.fromTime) {
          console.log("case ====:::: 3");

          splitSlots.push({ start: slot.start, end: currentSlot.fromTime });
          splitSlots.push({ start: currentSlot.fromTime, end: slot.end });
        }
        if (slot.end > currentSlot.toTime) {
          console.log("case ====:::: 4");
          splitSlots.push({ start: slot.start, end: currentSlot.toTime });

          splitSlots.push({ start: currentSlot.toTime, end: slot.end });
        }
      } else {
        console.log("case ====:::: 5");

        // The existing slot does not overlap the current slot, so leave the current slot as is
        splitSlots.push(slot);
      }
    }

    // Update the slots array with the new split slots
    slots = splitSlots;
  }

  return slots;
};

const timeRange = (st, et) => {
  const startHour = moment(st).format("HH.mm A");
  const endHour = moment(et).format("HH.mm A");

  return `${startHour} - ${endHour} \n`;
};

export const diifrenceInMinutes = (st, et) => {
  const startTime = new Date(st); // replace with your start time
  const endTime = new Date(et);

  const time1 = moment(st);
  const time2 = moment(et);

  const diffInMs = endTime?.getTime() - startTime.getTime(); // get the difference in milliseconds
  const diffInMinutes = Math.floor(diffInMs / 1000 / 60); // convert to minutes
  const diffInMinutes1 = (diffInMs / 1000 / 60) % 60; // convert to minutes

  const data = diffInMinutes + diffInMinutes1;
  return diffInMinutes;
};

const ReactTimeSchedular = forwardRef((props, ref) => {
  let lno = 2;
  const {
    templateData,
    paytimeCodeList,
    EmployeeID,
    payPeriod,
    filteredEvent,
    templateID,
    paytimeCodeByID,
    statusSubmit,
    loadPreData,
  } = props;
  let checkboxBusiness = useRef();
  let checkboxSummary = useRef();
  let checkboxREG = useRef();
  let scheduler = useRef();
  console.log("filteredEvent 12.", filteredEvent);

  const handleSetHzToHtype = async (arrayInput) => {
    // console.log('taget arrayInput',arrayInput);

    let paytime = paytimeCodeList.filter((item) => {
      return item.code >= 10 && item.code <= 12;
    });
    let arrayHtype = paytime.map((i) => {
      return i.value;
    });
    let targetItem = arrayInput.filter((i) => {
      return arrayHtype.includes(i.jobtype);
    });

    console.log("taget index", targetItem);

    // if (targetItem.length) {
    //   targetItem.map((j) => {
    //     let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
    //     console.log('taget findAtIndex',index);
    //     arrayInput[ index ][ 'hjobtype' ] = j.jobtype;
    //   })
    // }
    if (targetItem.length) {
      targetItem.map((j) => {
        // let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
        let index = arrayInput.findIndex((row) => row.id == j.id);
        console.log("taget findAtIndex", index);
        // arrayInput[index]["hjobtype"] = j.jobtype;
        arrayInput[index]["jobtype"] = j.jobtype;
      });
    }

    console.log("targetItem", arrayInput);
    return arrayInput;
  };

  const [daysCnt, setDaysCnt] = useState(14);
  const [deletedList, setDeletedList] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState("2023-03-30T10:00:00");
  const [eventId, setEventId] = useState(null);
  const [approveStatus, setApproveStatus] = useState(null);
  const [openEventID, setOpenEventID] = useState(null);
  const [onTimeArgs, setOnTimeArgs] = useState([]);
  const [updatedTime, setUpdatedTime] = useState({
    startTime: null,
    endTime: null,
  });

  const [eventPayCode, setEventPayCode] = useState(1);
  const [creteButtonClick, setCreteButtonClick] = useState(false);
  const [labelCode, setLableCode] = useState();
  console.log("eventPayCode", eventPayCode);

 

  const [selectedEvent, setSelectedEvent] = useState(null);
  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  const [columns, setColumns] = useState([
    { field: "start", text: "Start Time", width: 100 },
    { field: "end", text: "End Time", width: 100 },
    { field: "text", text: "Event", width: 200 },
    { field: "resource", text: "Resource", width: 100 },
  ]);

  useEffect(() => {
    if (payPeriod?.StartDate) {
      let sd = moment(payPeriod?.StartDate);
      let ed = moment(payPeriod?.EndDate);
      setStartDate(payPeriod?.StartDate);
      setDaysCnt(ed.diff(sd, "days") + 1);
    }
  }, [payPeriod?.StartDate]);

  const [events, setEvents] = useState([]);
  console.log("events 456", events);
  const checkAuth = (sd, ed) => {
    let isauth = "pending";
    let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("dddd");
    let shr = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    let ehr = moment(ed, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    let tData = templateData.filter((i) => {
      return i.Day == dy;
    });
    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.From, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
      let toTime = moment(i.To, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };

  const getTimeFromMin = (TotalMin) => {
    let getHours = TotalMin > 0 ? Math.floor(TotalMin / 60) : "00";
    let getMinutes = TotalMin && TotalMin % 60 > 0 ? TotalMin % 60 : "00";
    return `${getHours}:${getMinutes}`;
  };

  const handelonBeforeRowHeaderRender = (args) => {
    args.row.columns[1].text = args.row.start.toString("ddd");
    let onlyT = [...columnsLookup].filter((i) => i.show == true);

    let contailnsData = args?.row?.events.all().length;
    if (!contailnsData) {
      return false;
    }
    console.log(" args?.row?.events.all()", args?.row?.events.all());
    let dataHour = [];
    let hoursData = {};
    let eachRows = [];

    contailnsData = args?.row?.events.all().map((i) => {
      // if (i.data.paytype == "Auth") {
      let labelval = getPPECodeLabel(i.data.paycode);
      //   const paytypename = i.data.paytype;
      //  dataHour.push({ paytypename: paytypename,time :diifrenceInMinutes(i.data.start, i.data.end)})
      hoursData[i.data.paytype] =
        (hoursData[i.data.paytype] ? hoursData[i.data.paytype] : 0) +
        diifrenceInMinutes(i.data.start, i.data.end);
      console.log(i.data, "stack all loop", eachRows);

      let isAuth = eachRows.find((k) => {
        return k.type == "Auth";
      });
      if (isAuth && i.data.paytype == "Auth") {
        // isAuth.timeMin
        let findAt = eachRows.findIndex((i) => {
          return i.id == isAuth.id;
        });
        console.log(isAuth, "***", eachRows, "findAt", findAt);
        if (findAt !== -1) {
          let getTotmin =
            diifrenceInMinutes(i.data.start, i.data.end) + isAuth.timeMin;

          eachRows[findAt]["timeMin"] = getTotmin;
          eachRows[findAt]["hrTime"] = getTimeFromMin(getTotmin);
          const startHour = moment(i.data.start).format("hh:mm A");
          const endHour = moment(i.data.end).format("hh:mm A");
          let time = isAuth.time + `\n ${startHour}-${endHour}`;
          eachRows[findAt]["time"] = time;
        }
      } else {
        let getTotmin = diifrenceInMinutes(i.data.start, i.data.end);
        const startHour = moment(i.data.start).format("hh:mm A");
        const endHour = moment(i.data.end).format("hh:mm A");
        let autho = i.data.authStatus === "pending" ? "U" : "A";
        eachRows.push({
          autho: autho,
          type: i.data.paytype,
          id: i.data.id,
          time: `${startHour}-${endHour}`,
          timeMin: getTotmin,
          hrTime: getTimeFromMin(getTotmin),
        });
      }
    });

    // outputs 210

    if (onlyT.length) {
      onlyT.map((i) => {
        let ind = onlyT.findIndex((j) => {
          return j.name == i.name;
        });
        // args.row.columns[1].text = args.row.start.toString("ddd");

        if (i.name == "Type" && args.row.columns[ind]) {
          // let getHours =
          //   hoursData["Type"] > 0 ? Math.floor(hoursData["Type"] / 60) : "00";
          // let getMinutes =
          //   hoursData["Type"] && hoursData["REG"] % 60 > 0
          //     ? hoursData["Type"] % 60
          //     : "00";

          // args.row.columns[ind].text = `${getHours}:${getMinutes}`;
          // let keys = Object.keys(hoursData);
          // console.log("i Type 123", dataHour.map((i)=>{
          //   return i.paytypename
          // }));
          // args.row.columns[ind].text = keys?.join("\n");

          let hrlist = [];
          let a1 = eachRows
            .filter((i) => {
              return i.type == "Auth";
            })
            .map((k) => {
              return k.type;
            });
          let a2 = eachRows
            .filter((i) => {
              return i.type != "Auth";
            })
            .map((k) => {
              return k.type;
            });

          hrlist = hrlist.concat([a1]);
          hrlist = hrlist.concat(a2);
          args.row.columns[ind].html = hrlist
            .map((i) => {
              return `<div style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px ">${i}</div>`;
            })
            .join("");
        } else if (i.name == "Hours" && args.row.columns[ind]) {
          // let keys = eachRows; //Object.keys(hoursData);
          // let keys = Object.keys(dataHour);

          let hrlist = [];
          let a1 = eachRows
            .filter((i) => {
              return i.type == "Auth";
            })
            .map((k) => {
              return k.hrTime;
            });
          let a2 = eachRows
            .filter((i) => {
              return i.type != "Auth";
            })
            .map((k) => {
              return k.hrTime;
            });

          hrlist = hrlist.concat([a1]);
          hrlist = hrlist.concat(a2);
          // hrlist.push eachRows.map((i)=>{return i.hrTime && i.type=='Auth'});
          // if (keys.length) {
          //   keys.map((itemkey) => {
          //     let getHours =
          //       hoursData[itemkey.type] > 0
          //         ? Math.floor(hoursData[itemkey.type] / 60)
          //         : "00";
          //     let getMinutes =
          //       hoursData[itemkey.type] && hoursData[itemkey.type] % 60 > 0
          //         ? hoursData[itemkey.type] % 60
          //         : "00";

          //     hrlist.push(`${getHours}:${getMinutes}`);
          //     console.log("hrList 123", hrlist, hoursData);
          //   });
          // }

          // let getHoursREG =
          //   hoursData["REG"] > 0 ? Math.floor(hoursData["REG"] / 60) : "00";
          // let getMinutesREG =
          //   hoursData["REG"] && hoursData["REG"] % 60 > 0
          //     ? hoursData["REG"] % 60
          //     : "00";
          // let addBlank = `<div style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px "></div>`;
          args.row.columns[ind].html = hrlist
            .map((i) => {
              return `<div style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px ">${i}</div>`;
            })
            .join("");

          // args.row.columns[ ind ].text = hrlist.join("\n");
          //`${getHoursREG}:${getMinutesREG}\n${getHours}:${getMinutes}`;
          // args.row.columns[ind].text =getHours.join("\n")
        } else if (i.name == "TOTAL" && args.row.columns[ind]) {
          let hoursData = {};
          contailnsData = args?.row?.events.all().map((i) => {
            if (i.data.paytype !== "Auth") {
              let labelval = getPPECodeLabel(i.data.paycode);
              hoursData[labelval] =
                (hoursData[labelval] ? hoursData[labelval] : 0) +
                diifrenceInMinutes(i.data.start, i.data.end);
            }
          });
          console.log("hoursData 123456", contailnsData);
          let keys = Object.keys(hoursData);
          let Thours = 0;
          keys.map((item) => {
            Thours = Thours + hoursData[item];
          });
          let getHours1 = Thours > 0 ? Math.floor(Thours / 60) : "00";
          let getMinutes1 = Thours && Thours % 60 > 0 ? Thours % 60 : "00";
          args.row.columns[ind].text = args.row.columns[
            ind
          ].text = `${getHours1}:${getMinutes1}`;

          // args.row.columns[ ind ].line = contailnsData.length;
          // args.row.events.totalDuration().toString("h:mm");
        } else if (i.name == "timeRange" && args.row.columns[ind]) {
          let hrlist = [];
          let a1 = eachRows
            .filter((i) => {
              return i.type == "Auth";
            })
            .map((k) => {
              return k.time;
            });
          let a2 = eachRows
            .filter((i) => {
              return i.type != "Auth";
            })
            .map((k) => {
              return k.time;
            });

          hrlist = hrlist.concat([a1]);
          hrlist = hrlist.concat(a2);

          //  args.row.columns[ind].text = args.row.events.all().map((i)=>{
          //     return timeRange(i.data.start, i.data.end)
          //   })
          args.row.columns[ind].html = hrlist
            .map((i) => {
              return `<div style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px ">${i}</div>`;
            })
            .join("");
        } else if (i.name == "Auth" && args.row.columns[ind]) {
          let hrlist = [];
          let a1 = eachRows
            .filter((i) => {
              return i.type == "Auth";
            })
            .map((k) => {
              return k.autho;
            });
          let a2 = eachRows
            .filter((i) => {
              return i.type != "Auth";
            })
            .map((k) => {
              return k.autho;
            });

          hrlist = hrlist.concat([a1]);
          hrlist = hrlist.concat(a2);
          args.row.columns[ind].html = hrlist
            .map((i) => {
              return `<div style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px ">${i}</div>`;
            })
            .join("");
        } else if (i.name == "Approve" && args.row.columns[ind]) {
          console.log("sdskldsjfds", eachRows);
          let hrlist = eachRows.filter((i) => i.type !== "Auth");

          args.row.columns[ind].html = hrlist
            .map((i) => {
              return `<div
              style="margin-bottom: 20px;border:1px sold grey;line-height:35px;height:35px "
            >
              <input type="checkbox"  onChange="${myFunction()}" />
            </div>`
              // return <HandleApproveCheck eachRows={eachRows}/>
            })
            .join("");
        }
      });
    }
  };
 

   function myFunction  ( ) {
    alert("Hi")  
  }
 

  const getPPECodeLabel = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    if (acode === undefined) {
      return acode;
    }
    let getcode = paytimeCodeList.find((i) => {
      return i.code == acode;
    });
    console.log(getcode, "###", paytimeCodeList);
    return getcode ? getcode?.label : "H-5";
  };
  const getPPEColorCode = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    if (acode.toLowerCase() == "auth") {
      return "#e07c40";
    }
    let getcode = paytimeCodeList.find((i) => {
      return i.label == acode;
    });

    return getcode && getcode.color ? getcode.color : "#000";
  };

  const getStatusColorCode = (ConfirmStatus) => {
    if (ConfirmStatus === undefined) {
      return {color:"#000",Auth:'U'};
    }
    ConfirmStatus = ConfirmStatus.toLowerCase();
    if (ConfirmStatus == "confirm") {
      return {color:"#4A8738",Auth:'A'};
    } else if (ConfirmStatus == "auth") {
      return {color:"#32CD32",Auth:'A'};
    } else if (ConfirmStatus == "pending") {
      return {color:"#ED2938",Auth:'U'};
    } else {
      return {color:"#000",Auth:'U'};
    }
  };

  const checkPaycodeAccess = (type, dates) => {
    const date = moment(dates).format("YYYY-MM-DD");
    let paycodeList = payCodeFilter(paytimeCodeList, paytimeCodeByID, userRole);
    // getValidPaycodeOfDay()

    let validateNDIF = getValidPaycodeOfDay(date, paycodeList);

    console.log("paycodeList 123", date);
    if (type == "NDIF") {
      let filter = validateNDIF?.filter((i) => i.label === type);
      console.log("filter paylist", filter);
      return filter.length ? true : false;
    } else {
      return false;
    }
  };

  const getTimesheetRecord = () => {
    console.log("filteredEvent", filteredEvent);
    const data = filteredEvent?.map((i) => {
      let wd = moment(i.WorkDate).format("YYYY-MM-DD");
      let stdate = moment(
        `${moment(i.WorkDate).format("YYYY-MM-DD")} ${moment(i.WorkFrom).format(
          "HH:mm:ss"
        )}`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      let eddate = moment(
        `${moment(i.WorkDate).format("YYYY-MM-DD")} ${moment(i.WorkTo).format(
          "HH:mm:ss"
        )}`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      let authNColor =   getStatusColorCode(i.ConfirmStatus);    
      return {
        Approve: i.Approve,
        id: i.ID,
        start: moment(stdate, "YYYY-MM-DDTHH:mm:ss")
          .utc()
          .format(`${wd}THH:mm:ss`), //moment(i.WorkFrom).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(eddate, "YYYY-MM-DDTHH:mm:ss")
          .utc()
          .format(`${wd}THH:mm:ss`), // moment(i.WorkTo).format("YYYY-MM-DDTHH:mm:ss"),
        text: `${i.PayCode_PayTimeCode?.Abberv}-${moment(stdate)
          .utc()
          .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
        paycode: i.PayCode,

        paytype:
          templateID && i.prefillData ? "Auth" : i.PayCode_PayTimeCode?.Abberv,
        line: i.prefillData ? 0 : "dedicated",
        indexNo: templateID ? -1 : parseFloat(moment().format("YYMMDDHHmmss")),
        barColor: templateID ? "#000" :authNColor.color,
        Auth: i.Auth,
        authStatus: i.ConfirmStatus?.toLowerCase(),
        backColor: templateID
          ? "#e07c40"
          : getPPEColorCode(i.PayCode_PayTimeCode?.Abberv),

        JobOrder: i.JobOrder,
       
        bubbleHtml: `${i.PayCode_PayTimeCode?.Abberv}-${moment(stdate)
          .utc()
          .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
        TotalHours: i.TotalHours,
        WorkDate: i.WorkDate,
        WorkFrom: i.WorkFrom,
        WorkTo: i.WorkTo,
        PayCode_PayTimeCode: i.PayCode_PayTimeCode,
        // RecordNo: i.RecordNo,
        parentIndex: i.RecordNo,
        ids:RemoveSpaceKeyGen(`${wd}${i.PayCode}${moment(i.WorkFrom).utc().format("HH:mm")}`),
        refid:i.RecordNo,
        API : "API"
      };
    });

    console.log("filteredFinal data", data);
    setEvents(data);

    return false;
  };

  useEffect(() => {
    payPeriod?.StartDate && getTimesheetRecord();
  }, [payPeriod?.StartDate, filteredEvent]);
  const [columnsLookup, setColumnsLookup] = useState([
    { name: "Date", show: true, id: 1 },
    { name: "Day", width: 50, show: true, id: 2 },
  ]);
  const [state, setState] = useState({
    locale: "en-us",
    rowHeaderColumns: [...columnsLookup],
    // columns,
    resources: [
      {
        id: "1",
        name: "Resource 1",
        column1: "Value 1",
        column2: "Value 2",
        column3: "Value 3",
      },
      {
        id: "2",
        name: "Resource 2",
        column1: "Value 4",
        column2: "Value 5",
        column3: "Value 6",
      },
      // add more resources here
    ],

    onBeforeRowHeaderRender: function (args) {
      args.cell.properties.disabled = true;
      // args.row.columns[0].horizontalAlignment = "center";

      // args.row.columns[0].backGroundColor = "red";

      // iterate through the events and do something with them
      // args.row.columns[1].text = args.row.start.toString("ddd");

      let onlyT = [...columnsLookup].filter((i) => i.show == true);

      if (onlyT.length) {
        onlyT.map((i, ind) => {
          //   let copy =  [...state.rowHeaderColumns] ;
          // let ind = copy.findIndex((j) => { return j.name == i.name });

          if (i.name == "REG") {
            args.row.columns[ind].text = "REG"; //+args.row.start.toString("ddd");
          } else if (i.name == "NDIF") {
            args.row.columns[ind].text = "NNN"; //+args.row.start.toString("ddd");
          } else if (i.name == "TOTAL") {
            args.row.columns[ind].text = args.row.events
              .totalDuration()
              .toString("h:mm");
          }
        });
      }

      // if (args.row.columns[2]) {
      //   args.row.columns[2].text = args.row.events
      //     .totalDuration()
      //     .toString("h:mm");
      // }
      // if (args.row.columns[3]) {
      //   args.row.columns[3].text = "5";
      // }
    },

    onBeforeCellRender: (args) => {
      // args.cell.disabled = true;
      // args.cell.properties.disabled = true;
      // args.cell.backColor = "#ff0000";
    },

    // onTimeRangeHover: (args) => {

    //   const element = args.e.cell;
    //   // Get the position of the element relative to the viewport
    //   const rect = element.getBoundingClientRect();
    //   // Show the tooltip

    //   // ReactTooltip.show(element, {
    //   //   place: "bottom",
    //   //   effect: "solid",
    //   //   getContent: () => {
    //   //     // Return the tooltip content based on the hovered time range
    //   //     return `Start: ${args.start.toLocaleString()}<br>End: ${args.end.toLocaleString()}`;
    //   //   },
    //   // });
    // },
    // onEventHover: function (args) {
    //
    //   var tooltip = new DayPilot.ToolTip({
    //     target: args.e.div,
    //     content: "Event: " + args.e.text(),
    //     autoHide: false,
    //   });
    //   tooltip.show();
    // },

    onBeforeResHeaderRender: (args) => {
      args.resource.backColor = ConfigDB.data.color.primary_color;
      args.resource.fontColor = ConfigDB.data.color.font_white;
    },
    onBeforeTimeHeaderRender: (args) => {
      args.header.backColor = ConfigDB.data.color.primary_color;
      args.header.fontColor = ConfigDB.data.color.font_white;
    },

    onBeforeCornerDomAdd: (args) => {
      const style = {
        fontSize: "30px",
        fontWeight: "bold",
        height: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        // backgroundColor: "red",
      };
      args.element = <div style={style}></div>;
    },

    businessBeginsHour: 6, // business day starts at 9am
    businessEndsHour: 18, // business day ends at 5pm
    // startDate: "2023-05-01",
    // days: 7,
    // Use the CallBack option

    onEventResize: (args) => {
      // if ( (args.multiresize[0]["event"]["data"]["paytype"]) === "Auth") {
      //   ShowError("You can't change");
      //   // args.left.enabled = false;

      //   args.allowed = false;

      // }

      const dp = args.control;

      console.log("dpEvent", dp.events);

      let oldStart = args.e.part.start.value;
      let oldEnd = args.e.part.end.value;

      let newstart = args.newStart.value;
      let newend = args.newEnd.value;

      // let oldStartHour = moment(oldStart).format("HH");
      // let newstartHour = moment(newstart).format("HH");

      let startTime = moment(args.newStart.value).format("HH");
      let EndTime = moment(args.newEnd.value).format("HH");

      let eid = args.e.data.id;
      let refEvent = dp.events.list.filter((i) => {
        return i.refid == eid;
      });

      if (refEvent.length) {
        refEvent.map((i) => {
          let updatedStartTime = i.start;
          let copyobj = i;

          //update ndif time
          if (startTime < 6) {
            if (EndTime < 6) {
              copyobj["start"] = args.newStart.value;
              copyobj["end"] = args.newEnd.value;
              copyobj["authStatus"] = checkAuth(
                args.newStart.value,
                args.newEnd.value,
                "REG"
              ).status;

              dp.events.update(copyobj, copyobj);
              // dp.events.add({
              //   start: start,
              //   end: end,
              //   id: DayPilot.guid(),
              //   resource: args.resource,
              //   barColor: "#ff0000",
              //   paycode: 125,
              //   refid:eid,
              //   paytype: "NDIF",
              //   line:newLine,
              //   indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
              //   text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
              //     "hh:mm A"
              //   )}`,
              //   bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
              //     end
              //   ).format("hh:mm A")}`,
              // });
            } else {
              console.log("CallCase2");
              let etime = moment(
                moment(`${args.newEnd.value}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("YYYY-MM-DDTHH:mm:ss");
              copyobj["start"] = args.newStart.value;
              copyobj["end"] = etime;
              copyobj["authStatus"] = checkAuth(
                args.newStart.value,
                etime,
                "REG"
              ).status;

              // dp.events.add({
              //   start: start,
              //   end: moment(moment(`${end}`).format("YYYY-MM-DD"))
              //     .set("hour", 6)
              //     .set("minute", 0)
              //     .format("YYYY-MM-DDTHH:mm:ss"),
              //   id: DayPilot.guid(),
              //   resource: args.resource,
              //   barColor: "#ff0000",
              //   paycode: 125,
              //   paytype: "NDIF",
              //   line:newLine,
              //   indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
              //   text: `${moment(start).format("hh:mm A")} - ${moment(
              //     moment(`${end}`).format("YYYY-MM-DD")
              //   )
              //     .set("hour", 6)
              //     .set("minute", 0)
              //     .format("hh:mm A")}`,
              //   bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
              //     moment(`${end}`).format("YYYY-MM-DD")
              //   )
              //     .set("hour", 6)
              //     .set("minute", 0)
              //     .format("hh:mm A")}`,
              // });

              startTime = 6;
              updatedStartTime = moment(
                moment(`${updatedStartTime}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("YYYY-MM-DDTHH:mm:ss");

              dp.events.update(copyobj, copyobj);
            }
          }

          if (startTime >= 6 && startTime < 18) {
            console.log("CallCase3");
            if (EndTime > 18) {
              console.log("case 3");
              let st = moment(
                moment(`${args.newStart.value}`).format("YYYY-MM-DD")
              )
                .set("hour", 18)
                .set("minute", 0)
                .format("YYYY-MM-DDTHH:mm:ss");
              copyobj["start"] = st;
              copyobj["end"] = args.newEnd.value;
              copyobj["authStatus"] = checkAuth(
                st,
                args.newEnd.value,
                "REG"
              ).status;

              dp.events.update(copyobj, copyobj);
            } else {
              if (
                moment(args.newStart.value).format("HH") >= 6 &&
                moment(args.newEnd.value).format("HH") <= 18
              ) {
                dp.events.removeByData({ id: i.id });
                console.log("CallCase4");
              } else {
                console.log("CallCase5");
              }
              // dp.events.removeByData({id:i.id})
            }
          }

          if (startTime >= 18) {
            console.log("CallCase6");
            copyobj["start"] = args.newStart.value;
            copyobj["end"] = args.newEnd.value;
            copyobj["authStatus"] = checkAuth(
              args.newStart.value,
              args.newEnd.value,
              "REG"
            ).status;
            dp.events.update(copyobj, copyobj);
          }
        });
      } else {
        // if not exist then create new

        if (startTime < 6) {
          let ed = moment(moment(`${newend}`).format("YYYY-MM-DD"))
            .set("hour", 6)
            .set("minute", 0)
            .format("YYYY-MM-DDTHH:mm:ss");

          if (checkPaycodeAccess("NDIF", args.newStart.value)) {
            dp.events.add({
              start: newstart,
              end: ed,
              id: DayPilot.guid(),
              resource: args.resource,
              refid: eid,
              barColor: "#ff0000",
              paycode: 125,
              paytype: "NDIF",
              authStatus: checkAuth(newstart, ed, "REG").status,
              line: "dedicated",
              indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
              text: `${moment(newstart).format("hh:mm A")} - ${moment(
                moment(`${newend}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("hh:mm A")}`,
              bubbleHtml: `${moment(newstart).format("hh:mm A")} - ${moment(
                moment(`${newend}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("hh:mm A")}`,
            });
          }

          startTime = 6;
        }

        if (startTime >= 6 && startTime < 18) {
          if (EndTime > 18) {
            startTime = 18;
            args.e.data.authStatus = checkAuth(
              args.newStart.value,
              args.newEnd.value,
              "REG"
            ).status;
            dp.events.update(args.e.data, args.e.data);
          } else {
            args.e.data.authStatus = checkAuth(
              args.newStart.value,
              args.newEnd.value,
              "REG"
            ).status;
            dp.events.update(args.e.data, args.e.data);
          }
        }

        if (startTime >= 18) {
          let st = moment(moment(`${newstart}`).format("YYYY-MM-DD"))
            .set("hour", 18)
            .set("minute", 0)
            .format("YYYY-MM-DDTHH:mm:ss");
          if (checkPaycodeAccess("NDIF", args.newStart.value)) {
            dp.events.add({
              start: st,
              end: newend,
              id: DayPilot.guid(),
              resource: args.resource,
              refid: eid,
              barColor: "#ff0000",
              paycode: 125,
              paytype: "NDIF",
              authStatus: checkAuth(st, newend, "REG").status,
              line: "dedicated",
              indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
              text: `${moment(newstart).format("hh:mm A")} - ${moment(
                moment(`${newend}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("hh:mm A")}`,
              bubbleHtml: `${moment(newstart).format("hh:mm A")} - ${moment(
                moment(`${newend}`).format("YYYY-MM-DD")
              )
                .set("hour", 6)
                .set("minute", 0)
                .format("hh:mm A")}`,
            });
          }
        }
      }
    },

     
    onEventMoving: (args) => {
      console.log("args.data", args.multimove[0]["event"]["data"]);
      if (
        ["Auth", "NDIF"].includes(args.multimove[0]["event"]["data"]["paytype"])
      ) {
        args.left.enabled = false;
        args.right.html = "You can't move an event from resource A to B";
        args.allowed = false;
      }
    },
    // durationBarVisible: true,
    // durationBarMode: "PercentComplete",
    // groupConcurrentEvents: true,
    onEventClick: (args) => {
      setSelectedEvent(args);
      let stime =
        typeof args.e.data.start == "object"
          ? moment(args.e.data.start, "YYYY-MM-DDTHH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : args.e.data.start;
      let etime =
        typeof args.e.data.end == "object"
          ? moment(args.e.data.end, "YYYY-MM-DDTHH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : args.e.data.end;
      let momentStartTime = moment(stime).format("HH:mm");
      let momentEndTime = moment(etime).format("HH:mm");
      let momentDate = moment(stime).format("MM-DD-YYYY - dddd");

      setUpdatedTime({
        ...updatedTime,
        startTime: momentStartTime,
        endTime: momentEndTime,
        date: momentDate,
      });
      setEventPayCode(args.e.data.paycode);
      setOpenEventID(args.e.data.id);
      setApproveStatus(args.e.data.Approve);
      setModalOpen(true);

      // return;

      // let ee = dp.events.find({ id: eventID });

      // dp.events.update(ee).notify();

      // // const dps = DayPilotScheduler("dp");
      // // var e = dps.events.find(eventID);
      // return;
      // // Update the ID value of the event
      // // ev.text("Hurry");

      // // // Save the changes to the server
      // // dps.events.update(ev);
    },
    // rowHeaderColumns:{[{ title: "Resource", width: 100 }]},
    columns: columns,

    onBeforeEventRender: (args) => {
      // args.data.cssClass = classes.event;
      //Render each time this is common

      const allDataEvents = scheduler.current.control.events.list;
      console.log("args 789",  allDataEvents);
      const eventsOnDate =
        allDataEvents.length &&
        allDataEvents.filter(
          (e) =>
            new Date(e.start).toLocaleDateString() ===
            new Date(args.data.start).toLocaleDateString()
        );

      console.log("args 789 10", args);

      // if (allDataEvents.length) {
      //   if (args.e === eventsOnDate[0]) {
      //     args.data.top = 0;
      //   }
      //   console.log("eventsOnDate", eventsOnDate);
      // scheduler.current.control.events.list.push eventsOnDate;
      // }
      const duration = new DayPilot.Duration(args.data?.start, args.data?.end);

      let lb = getPPECodeLabel(args.data.paycode);
      let mycolor = getPPEColorCode(lb);
      if (args.data.paytype == "Auth") {
        mycolor = getPPEColorCode(args.data.paytype);
      }

      console.log(mycolor, "mycolor", lb, "##", args.data);

      let authNColor = getStatusColorCode(args.data.authStatus);
      args.data.areas = [
        {
          top: 10,
          right: 5,
          text: duration?.toString("h:mm"),
          fontColor: "#fff",
          fontWeight: "bold",
          fontSize: "14px",
          line: 0,
          bubbleHtml: `${moment(args.data.start.value).format(
            "hh:mm A"
          )} - ${moment(args.data.end.value).format("hh:mm A")}`,
        },
      ];
      args.data.eventMargin = 80;
      args.e.eventMargin = 80;
      console.log("argsc 23656", args);
      args.data.enabled = false;
      console.log("vvvvv", args.data);

      args.data.text = `${args.data.paytype ? args.data.paytype : lb} ${moment(
        args.data.start.value
      ).format("hh:mm A")} - ${moment(args.data.end.value).format("hh:mm A")}`;
      args.data.bubbleHtml = `${moment(args.data.start.value).format(
        "hh:mm A"
      )} - ${moment(args.data.end.value).format("hh:mm A")}`;
      args.data.backColor = mycolor;
      // args.data.line = 22;
      args.data.barColor = authNColor.color ;
      args.data.Auth = authNColor.Auth ;
      //disbale delete
      args.data.deleteDisabled = ["Auth", "NDIF"].includes(args.data.paytype)
        ? true
        : false;
      //disbale resize
      args.data.resizeDisabled = ["Auth", "NDIF"].includes(args.data.paytype)
        ? true
        : false;
    },

    duration: "Week",
    cellWidthSpec: "Auto",
    cellWidthMin: 25,
    timeHeaders: [{ groupBy: "Hour" }, { groupBy: "Cell", format: "mm" }],
    scale: "CellDuration",
    cellDuration: 30,
    days: daysCnt,
    businessWeekends: "false",
    viewType: "Days",
    startDate: DayPilot.Date.today().firstDayOfMonth(),

    splitter: {
      enabled: true,
      timeResolution: 15,
      eventHeight: 20,
    },
    eventStackingLineHeight: 110,
    showNonBusiness: true,
    //   allowEventOverlap: true,
    overlap: true,
    //   durationBarVisible: false,
    eventBackgroundColor: "#ff0000",
    timeRangeSelectedHandling: "Enabled",
    durationBarColor: "#ff0000",

    onTimeRangeSelected: async (args) => {
      setOnTimeArgs(args);
      setModalOpen(true);
      console.log("args TimeData", args);
      let momentStartTime = moment(args.start.value).format("HH:mm");
      let momentEndTime = moment(args.end.value).format("HH:mm");
      setUpdatedTime({
        ...updatedTime,
        startTime: momentStartTime,
        endTime: momentEndTime,
      });
      //call on create event

      // newEventCreateHandler(args)
    },
    eventMoveHandling: "Disabled",
  });

  // useEffect(() => {
  //   setState({
  //     events: [
  //       {
  //         id: 1,
  //         text: "Task 1",
  //         start: "2021-07-02T10:00:00",
  //         end: "2021-07-02T11:00:00",
  //       },
  //       {
  //         id: 2,
  //         text: "Task 2",
  //         start: "2021-07-05T09:30:00",
  //         end: "2021-07-05T11:30:00",
  //         barColor: "#38761d",
  //         barBackColor: "#93c47d",
  //       },
  //       {
  //         id: 3,
  //         text: "Task 3",
  //         start: "2021-07-07T10:30:00",
  //         end: "2021-07-07T13:30:00",
  //         barColor: "#f1c232",
  //         barBackColor: "#ff0000",
  //       },
  //     ],
  //   });

  //   scheduler.scrollTo(DayPilot.Date.today().firstDayOfMonth().addHours(9));
  // }, []);

  const newEventCreateHandler = (paycodeVal, payTypeLabel) => {
     
    const args = onTimeArgs;
    const dp = args.control;
    let info = scheduler.current.control?.events?.list;

    console.log("argscksdklfnsf", args);

    let newLine = info.filter((i) => {
      return (
        moment(i.start).format("YYYY-MM-DD") ==
          moment(args.start.value).format("YYYY-MM-DD") && i.paytype !== "Auth"
      );
    }).length;
    newLine = "dedicated"; //newLine>1 ?newLine+1: newLine + 2; //'default';
    console.log(
      newLine,
      moment(args.start.value).format("YYYY-MM-DD"),
      "manoj",
      info
    );

    dp.clearSelection();
    dp.floatingEvents = false;

    let checkStartTimeSpit = moment(args.start.value).format("HH:mm");
    let checkEndTimeSpit = moment(args.end.value).format("HH:mm");
    let startTime = moment(args.start.value).format("HH");
    let EndTime = moment(args.end.value).format("HH");

    // let start = args.start.value;
    // let end = args.end.value;
    // let updatedStartTime = args.start.value;
    const preDate = moment(args.start.value).format("YYYY-MM-DD");
    const finalStartDate = moment(
      `${preDate} ${updatedTime.startTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss");
    const finalEndDate = moment(
      `${preDate} ${updatedTime.endTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss");

    let start = finalStartDate;
    let end = finalEndDate;
    let updatedStartTime = finalStartDate;

    let eid = DayPilot.guid();
    console.log("CASE EEI init",eid);
    let authChk = checkAuth(start, end, "REG");
    let authStatus = authChk.status;
    let authIsPartial = authChk.isPartial;

    if (payTypeLabel === "H-5") {
      dp.events.add({
        start: start,
        end: end,
        id: DayPilot.guid(),
        resource: args.resource,
        barColor: "#00FF00",
        paycode: paycodeVal,
        paytype: payTypeLabel,
        authStatus: authStatus,
        Auth:authStatus=='confirm'?'A':'U',
        line: newLine,
        indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
        text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
          "hh:mm A"
        )}`,
        bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(end).format(
          "hh:mm A"
        )}`,
        TotalHours: diifrenceInMinutes(start, end),
        WorkDate: start,
        WorkFrom: start,
        WorkTo: end,
        PayCode_PayTimeCode: {
          ID: paycodeVal,
          Abberv: payTypeLabel,
        },
      });
      payTypeLabel = "REG";
      paycodeVal = 1;
    }

    if (startTime < 6) {
      if (EndTime < 6) {
        console.log("case=====1");
        // array.push({ startTime, EndTime });
        dp.events.add({
          start: start,
          end: end,
          id: eid,
          resource: args.resource,
          barColor: "#00FF00",
          paycode: paycodeVal,
          paytype: payTypeLabel,
          authStatus: authStatus,
          Auth:authStatus=='confirm'?'A':'U',
          line: newLine,
          TotalHours: diifrenceInMinutes(start, end),

          indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
          text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
            "hh:mm A"
          )}`,
          bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
            end
          ).format("hh:mm A")}`,
          WorkDate: start,
          WorkFrom: start,
          WorkTo: end,
          PayCode_PayTimeCode: {
            ID: paycodeVal,
            Abberv: payTypeLabel,
          },
        });
        if (checkPaycodeAccess("NDIF", args.start.value)) {
          dp.events.add({
            start: start,
            end: end,
            id: DayPilot.guid(),
            resource: args.resource,
            barColor: "#ff0000",
            paycode: 125,
            refid: eid,
            paytype: "NDIF",
            authStatus: authStatus,
            Auth:authStatus=='confirm'?'A':'U',
            line: newLine,
            indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
            text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
              "hh:mm A"
            )}`,

            bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
              end
            ).format("hh:mm A")}`,
            TotalHours: diifrenceInMinutes(start, end),

            WorkDate: start,
            WorkFrom: start,
            WorkTo: end,
            PayCode_PayTimeCode: {
              ID: 125,
              Abberv: "NDIF",
            },
          });
        }
      } else {
        console.log("case====2");
        console.log("CASE EEID 2",eid);
        // array.push({ startTime, EndTime: 6 });
        let edate = moment(moment(`${end}`).format("YYYY-MM-DD"))
          .set("hour", 6)
          .set("minute", 0)
          .format("YYYY-MM-DDTHH:mm:ss");
        if (checkPaycodeAccess("NDIF", args.start.value)) {
          dp.events.add({
            start: start,
            end: edate,
             id: DayPilot.guid(),
            resource: args.resource,
            barColor: "#ff0000", 
            paycode: 125,
            refid: eid,
           
            paytype: "NDIF",
            authStatus: checkAuth(start, edate, "REG").status,
            Auth:authStatus=='confirm'?'A':'U',
            line: newLine,
            indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
            text: `${moment(start).format("hh:mm A")} - ${moment(
              moment(`${end}`).format("YYYY-MM-DD")
            )
              .set("hour", 6)
              .set("minute", 0)
              .format("hh:mm A")}`,
            bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
              moment(`${end}`).format("YYYY-MM-DD")
            )
              .set("hour", 6)
              .set("minute", 0)
              .format("hh:mm A")}`,
            TotalHours: diifrenceInMinutes(start, edate),

            WorkDate: start,
            WorkFrom: start,
            WorkTo: edate,
            PayCode_PayTimeCode: {
              ID: 125,
              Abberv: "NDIF",
            },
          });
        }
        startTime = 6;
        updatedStartTime = moment(
          moment(`${updatedStartTime}`).format("YYYY-MM-DD")
        )
          .set("hour", 6)
          .set("minute", 0)
          .format("YYYY-MM-DDTHH:mm:ss");
      }
    }

    if (startTime >= 6 && startTime <= 18) {
      if (EndTime > 18) {
        console.log("case===3"); 

        updatedStartTime = moment(
          moment(`${updatedStartTime}`).format("YYYY-MM-DD")
        )
          .set("hour", 18)
          .set("minute", 0)
          .format("YYYY-MM-DDTHH:mm:ss");

        startTime = 18;
      } else {
        console.log("case====4");
        console.log("CASE EEID 4", eid);
        
        let eventObject = {
          start: start,
          end: end,
          enabled: false,
          id: eid,
          // refid:eid,
          resource: args.resource,
          barColor: "#00FF00",
          paycode: paycodeVal,
          paytype: payTypeLabel,
          authStatus: checkAuth(start, end, "REG").status,
          Auth:authStatus=='confirm'?'A':'U',
          line: newLine,
          indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
          text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
            "hh:mm A"
          )}`,
          bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
            end
          ).format("hh:mm A")}`,
          TotalHours: diifrenceInMinutes(start, end),

          WorkDate: start,
          WorkFrom: start,
          WorkTo: end,
          PayCode_PayTimeCode: {
            ID: paycodeVal,
            Abberv: payTypeLabel,
          },
        };
        console.log("CASE EEID obj", eventObject);
        // if (authIsPartial) {
        //   let authinfo = authChk.info;
        //   let fEnd = authinfo[0].shr > authinfo[0].fromTime  ? authinfo[0].toTime :authinfo[0].fromTime;
        //   console.log("caseEnd",authinfo[0].shr > authinfo[0].fromTime)
        //   console.log("caseEnd",authinfo[0].shr,authinfo[0].fromTime)
        //   let dt = moment(end, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
        //   let tend = moment(`${dt}T${fEnd}`, "YYYY-MM-DDTHH:mm").format(
        //     "YYYY-MM-DDTHH:mm:00"
        //   );
        //   console.log(end, "Tend====4", tend);
        //   eventObject['end'] = up;
        //   eventObject['authStatus'] = checkAuth(start, tend, "REG").status;
        // }

        // if (authIsPartial) {

        //   let authinfo = authChk.info;
        //   let fEnd = authinfo[0].shr  > authinfo[0].fromTime ? authinfo[0].toTime  : authinfo[0].fromTime  ;
        //   let dt = moment(eventObject["start"], "YYYY-MM-DDTHH:mm:ss").format(
        //     "YYYY-MM-DD"
        //   );
        //   let startNew = moment(`${dt}T${fEnd}`, "YYYY-MM-DDTHH:mm").format(
        //     "YYYY-MM-DDTHH:mm:00"
        //   );
        //    dp.events.add({
        //     start: startNew,
        //     end: end,
        //     enabled: false,
        //     id: DayPilot.guid(),
        //     resource: args.resource,
        //     barColor: "#00FF00",
        //     paycode: paycodeVal,
        //     paytype: payTypeLabel,

        //   authStatus: checkAuth(startNew, end, "REG").status,
        //     line: newLine,
        //     indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
        //     text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
        //       "hh:mm A"
        //     )}`,
        //     bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
        //       end
        //     ).format("hh:mm A")}`,
        //     });

        // }

        let authinfo = authChk.info;

        console.log("dskfjdskld", args);
        if (authinfo.length) {
          let startTime = moment(args.start.value).format("HH:mm");
          let EndTime = moment(args.end.value).format("HH:mm");

          const splitData = splitTimeRange(startTime, EndTime, authinfo);

          const rangeData = getSplitRangeData(startTime, EndTime, authinfo);

          console.log("splitData", splitData);

          if (splitData.length) {
            splitData.map((i) => {
              if (i.start) {
                let dt = moment(end, "YYYY-MM-DDTHH:mm:ss").format(
                  "YYYY-MM-DD"
                );
                
                let setID = DayPilot.guid();
                // console.log(eid,"SIDD ",setID,"##",checkStartTimeSpit===i.start, "YYYY-MM-DDTHH:mm:ss").format("HH:mm"),checkStartTimeSpit,"==",moment(i.start, "YYYY-MM-DDTHH:mm:ss").format("HH:mm"));
                // console.log("SIDD ",JSON.stringify(checkStartTimeSpit)=== JSON.stringify(moment(i.start, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")));
                if(checkStartTimeSpit==i.start){
                  
                  setID = eid;
                }
            
                let tStart = moment(
                  `${dt}T${i.start}`,
                  "YYYY-MM-DDTHH:mm"
                ).format("YYYY-MM-DDTHH:mm:00");
                let tEnd = moment(`${dt}T${i.end}`, "YYYY-MM-DDTHH:mm").format(
                  "YYYY-MM-DDTHH:mm:00"
                );
 
                const AuthSplit = checkAuth(tStart, tEnd, "REG").status;
                
               
 
                
                
               
 
                dp.events.add({
                  start: tStart,
                  end: tEnd,
                  enabled: false,
                  id:    setID  ,
                  // refid: payTypeLabel === "NDIF" ? setID : eid,
                  // parentIndex:"0",//DayPilot.guid(),
                  
                  resource: args.resource,
                  barColor: "#00FF00",
                  paycode: paycodeVal,
                  paytype: payTypeLabel,
                  authStatus: AuthSplit,
                  Auth:AuthSplit=='confirm'?'A':'U',
                  line: newLine,
                  indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
                  text: `${moment(start).format("hh:mm A")} - ${moment(
                    end
                  ).format("hh:mm A")}`,
                  bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
                    end
                  ).format("hh:mm A")}`,
                  TotalHours: diifrenceInMinutes(tStart, tEnd),

                  WorkDate: tStart,
                  WorkFrom: tStart,
                  WorkTo: tEnd,
                  PayCode_PayTimeCode: {
                    ID: paycodeVal,
                    Abberv: payTypeLabel,
                  },
                });
              }
            });
          }
        } else {
          dp.events.add(eventObject);
        }
      }
    }

    if (startTime >= 18) {
      console.log("case====5");
      let rid = DayPilot.guid();

      let authinfo = authChk.info;

      console.log("dskfjdskld", args);
      if (authinfo.length) {
        let startTime = moment(args.start.value).format("HH:mm");
        let EndTime = moment(args.end.value).format("HH:mm");

        const splitData = splitTimeRange(startTime, EndTime, authinfo);

        const rangeData = getSplitRangeData(startTime, EndTime, authinfo);

        console.log("splitData", splitData);

        if (splitData.length) {
          splitData.map((i) => {
            if (i.start) {
              let dt = moment(end, "YYYY-MM-DDTHH:mm:ss").format(
                "YYYY-MM-DD"
              );
              
              let setID = DayPilot.guid();
              
              if(checkEndTimeSpit==i.end){
                
                setID = eid;
              }
          
              let tStart = moment(
                `${dt}T${i.start}`,
                "YYYY-MM-DDTHH:mm"
              ).format("YYYY-MM-DDTHH:mm:00");
              let tEnd = moment(`${dt}T${i.end}`, "YYYY-MM-DDTHH:mm").format(
                "YYYY-MM-DDTHH:mm:00"
              );
              const AuthSplit = checkAuth(tStart, tEnd, "REG").status;
              
             
              dp.events.add({
                start: tStart,
                end: tEnd,
                enabled: false,
                id:    setID  ,
                // refid: payTypeLabel === "NDIF" ? setID : eid,
                // parentIndex:"0",//DayPilot.guid(),
                
                resource: args.resource,
                barColor: "#00FF00",
                paycode: paycodeVal,
                paytype: payTypeLabel,
                authStatus: AuthSplit,
                Auth:AuthSplit=='confirm'?'A':'U',
                line: newLine,
                indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
                text: `${moment(start).format("hh:mm A")} - ${moment(
                  end
                ).format("hh:mm A")}`,
                bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(
                  end
                ).format("hh:mm A")}`,
                TotalHours: diifrenceInMinutes(tStart, tEnd),

                WorkDate: tStart,
                WorkFrom: tStart,
                WorkTo: tEnd,
                PayCode_PayTimeCode: {
                  ID: paycodeVal,
                  Abberv: payTypeLabel,
                },
              });
            }
          });
        }
      } else{
  dp.events.add({
        start: start,
        end: end,
        id: eid,
        resource: args.resource,
        barColor: "#00FF00",
        paycode: paycodeVal,
        paytype: payTypeLabel,
        authStatus: checkAuth(start, end, "REG").status,
        Auth:authStatus=='confirm'?'A':'U',
        line: newLine,
        indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
        text: `${moment(start).format("hh:mm A")} - ${moment(end).format(
          "hh:mm A"
        )}`,
        bubbleHtml: `${moment(start).format("hh:mm A")} - ${moment(end).format(
          "hh:mm A"
        )}`,
        TotalHours: diifrenceInMinutes(start, end),

        WorkDate: start,
        WorkFrom: start,
        WorkTo: end,
        PayCode_PayTimeCode: {
          ID: paycodeVal,
          Abberv: payTypeLabel,
        },
      });
      }

    
      if (checkPaycodeAccess("NDIF", args.start.value)) {
        dp.events.add({
          start: updatedStartTime,
          end: end,
          id: DayPilot.guid(),
          resource: args.resource,
          barColor: "#ff0000",
          paycode: 125,
          paytype: "NDIF",
          refid: eid,
          authStatus: checkAuth(updatedStartTime, end, "REG").status,
          Auth:authStatus=='confirm'?'A':'U',
          line: newLine,
          indexNo: parseFloat(moment().format("YYMMDDHHmmss")),
          text: `${moment(updatedStartTime).format("hh:mm A")} - ${moment(
            end
          ).format("hh:mm A")}`,
          bubbleHtml: `${moment(updatedStartTime).format("hh:mm A")} - ${moment(
            end
          ).format("hh:mm A")}`,
          TotalHours: diifrenceInMinutes(updatedStartTime, end),

          WorkDate: updatedStartTime,
          WorkFrom: updatedStartTime,
          WorkTo: end,
          PayCode_PayTimeCode: {
            ID: 125,
            Abberv: "NDIF",
          },
        });
      }
    }

    // const allEvents=  scheduler.current.control.events.list;

    // console.log("allEvents data ",allEvents);

    // if (allEvents.length) {
    //   let newArray = [];

    //   let skip = false;
    //   allEvents.map(async (i, index) => {
    //     let totalMins = i.TotalHours;
    //     let orgObj = i;

    //     let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
    //     // newobj[ 'time_from1' ]  = info.start;
    //     // newobj[ 'time_to1' ]  = info.end;
    //     // newobj[ 'hrs' ] = HourMinFormat(hrCalc);

    //     let lableid = `${moment(i.WorkDate).format("YYYY-MM-DD")}${
    //       i.PayCode
    //     }${d2Time(i.WorkFrom)}`;
    //     lableid = RemoveSpaceKeyGen(lableid);
    //     // orgObj["WorkDate"] = replacePPEDate(
    //     //   preFillDatesArray,
    //     //   moment(i.WorkDate).format("YYYY-MM-DD")
    //     // );
    //     let assign = {
    //       // jobtype: i.Typh,
    //       jobtype: i.PayCode_PayTimeCode
    //         ? i.PayCode_PayTimeCode.Abberv
    //         : "",
    //       ID: i.id,
    //       id: lableid,
    //       // date: replacePPEDate(
    //       //   preFillDatesArray,
    //       //   moment(i.WorkDate).format("YYYY-MM-DD")
    //       // ), // moment(i.WorkDate).format("YYYY-MM-DD"),
    //       date: moment(i.WorkDate).format("YYYY-MM-DD"),
    //       time_from: d2Time(i.WorkFrom),
    //       time_to: d2Time(i.WorkTo),
    //       time_from1: null,
    //       time_to1: null,
    //       mn: i.TotalHours,
    //       mn1: 0,
    //       hrs: HourMinFormat(hrCalc),
    //       jobOrder: parseInt(i.JobOrder),
    //       code: parseInt(i.PayCode),
    //       parentIndex: i.RecordNo,
    //       ignoreadd: true,
    //       time_to_set: true,
    //       rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
    //     };
    //     console.log(index, "@##", allEvents.length);

    //     if (
    //       index + 1 < allEvents.length &&
    //       i.PayCode == allEvents[index + 1]["PayCode"] &&
    //       moment(i.WorkDate).format("YYYY-MM-DD") ==
    //         moment(allEvents[index + 1]["WorkDate"]).format(
    //           "YYYY-MM-DD"
    //         ) &&
    //       moment(i.CreatedDate).format("YYYY-MM-DD HH:mm:ss") ==
    //         moment(
    //           allEvents[index + 1]["CreatedDate"]
    //         ).format("YYYY-MM-DD HH:mm:ss") &&
    //       i.code == allEvents[index + 1]["code"]
    //     ) {
    //       skip = true;

    //       // assign.ID = EmployeeProcessPayTimes[index + 1]["ID"];

    //       assign.time_from1 = d2Time(
    //         allEvents[index + 1]["WorkFrom"]
    //       );
    //       assign.time_to1 = d2Time(
    //         allEvents[index + 1]["WorkTo"]
    //       );

    //       assign.mn1 = parseInt(
    //         allEvents[index + 1]["TotalHours"]
    //       );
    //       assign.time_to_set = true;
    //       assign.parentIndex =
    //       allEvents[index + 1]["RecordNo"];
    //       assign.hrs = HourMinFormat(
    //         `${Math.floor(
    //           (i.TotalHours +
    //             parseInt(
    //               allEvents[index + 1]["TotalHours"]
    //             )) /
    //             60
    //         )}:${
    //           (i.TotalHours +
    //             parseInt(
    //               allEvents[index + 1]["TotalHours"]
    //             )) %
    //           60
    //         }`
    //       );
    //       assign.rowstatus = i.ConfirmStatus
    //         ? i.ConfirmStatus
    //         : "Confirm";
    //       // assign.hrs = HourFormat(parseInt(i.TotalHours)+parseInt(EmployeeProcessPayTimes[index+1]['TotalHours']));
    //       newArray.push(assign);

    //       console.log(index, "@## same date createdmmmm", assign);
    //     } else {
    //       if (!skip) {
    //         newArray.push(assign);
    //       }
    //       skip = false;
    //     }

    //     console.log("@## skip", JSON.stringify(skip));
    //   });
    //   // setIsLoading(false);
    //   let refindArray =   handleSetHzToHtype(newArray);
    //   console.log("taget newArrayMB prevdate", newArray);

    //   props.setRowsData(newArray);
    // }

    setModalOpen(false);

    setOnTimeArgs([]);
  };

  const correctionClick = () => {
    const args = onTimeArgs;
    if (args.start) {
      const allEvents = scheduler.current.control.events.list;
      const selectedDate = moment(args.start.value).format("YYYY-MM-DD");

      let filteredByStartEnd = [];
      const filterEventsByDate = allEvents.filter((i) => {
        return (
          !["Auth", "NDIF"].includes(i.paytype) &&
          moment(i.start).format("YYYY-MM-DD") === selectedDate
        );
      });

      console.log("filterEventsByDate Correction", filterEventsByDate);
      console.log(
        "filterEventsByDate Correction LEN",
        filterEventsByDate.length
      );

      if (filterEventsByDate.length) {
        filterEventsByDate.map((i) => {
          console.log("csdcsvsvssfgsdff   2");
          const startTime = moment(i.start).format("HH:mm");
          const endTime = moment(i.end).format("HH:mm");
          // const selectedStart = moment(args.start.value).format("HH:mm")
          // const selectedEnd = moment(args.end.value).format("HH:mm")
          const selectedStart = updatedTime.startTime;
          const selectedEnd = updatedTime.endTime;
          console.log(
            "startTime ends",
            startTime,
            endTime,
            selectedStart,
            selectedEnd
          );

          console.log("ssdsafsafaasfsafs");

          if (selectedStart < endTime && selectedEnd > startTime) {
            console.log("find", i);
            let tempData = null;
            if (selectedStart > startTime) {
              console.log("find start", endTime, "@@@", i);
              tempData = startTime;
              setUpdatedTime({ ...updatedTime, startTime: endTime });
            } else {
              tempData = endTime;
              console.log(
                args.start,
                args.end,
                args.newStart,
                args.newEnd,
                "find end",
                startTime,
                "####",
                i
              );
              setUpdatedTime({ ...updatedTime, endTime: startTime });
            }
          }
        });
      }
    }
  };
  const changeBusiness = () => {
    const businessOnly = checkboxBusiness.checked;

    setState({
      showNonBusiness: !businessOnly,
    });
  };

  // const changeSummary = () => {
  //   const showSummary = checkboxSummary.checked;

  //   if (showSummary) {
  //     setState({
  //       rowHeaderColumns: [
  //         { name: "Date" },
  //         { name: "Day", width: 40 },
  //         { name: "Total", width: 60, title: "saasds" },
  //       ],
  //     });
  //   } else {
  //     setState({
  //       rowHeaderColumns: [{ name: "Date" }, { name: "Day", width: 40 }],
  //     });
  //   }
  // };

  // const changeREG = () => {
  //   const showREG = checkboxREG.checked;
  //   let copyState = [state];
  //   let copyobj = copyState[0]["rowHeaderColumns"];
  //   if (showREG) {
  //
  //     copyobj.push({ name: "REG" });
  //     // setState({
  //     //   rowHeaderColumns: copyobj,
  //     // });
  //     setState({
  //       rowHeaderColumns: [
  //         { name: "Date" },
  //         { name: "Day", width: 40 },
  //         { name: "Total", width: 60, title: "saasds" },
  //         { name: "REG" },
  //       ],
  //     });
  //   } else {

  //     copyobj = copyobj.filter((i) => {
  //       return i.name == "REG";
  //     });
  //     // setState({
  //     //   rowHeaderColumns: copyobj,
  //     // });
  //     setState({
  //       rowHeaderColumns: [
  //         { name: "Date" },
  //         { name: "Day", width: 40 },
  //         { name: "Total", width: 60, title: "saasds" },
  //       ],
  //     });
  //   }
  // };

  var { ...config } = state;

  const today = new DayPilot.Date.today();
  const monday = today.addDays(1 - today.getDayOfWeek());

  const sortArray = (arr) => {
  return arr.sort((a, b) => {
    const dateA = new Date(a.WorkDate);
    const dateB = new Date(b.WorkDate);

    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      if (a.PayCode === 1 && b.PayCode !== 1) {
        return -1;
      } else if (a.PayCode !== 1 && b.PayCode === 1) {
        return 1;
      } else {
        return 0;
      }
    }
  });
};

  const createRecord = (recno) => {
    console.log("13D reconnno", recno);
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }

    let allEvents = scheduler.current.control?.events?.list;
    let recordsCopy = [...allEvents];
    console.log("13D info REC", recordsCopy, "###", recno);

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });
    console.log("13D infoAg", findID);

    if (findID.length) {
      let lableCreate = `${moment(findID[0]?.WorkDate).format("YYYY-MM-DD")}${findID[0]?.paycode}${moment(findID[0]?.WorkFrom).format("HH:mm")}`;
      console.log("13D lable", lableCreate);
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };

  function handleButtonClick() {
    console.log("scheduler dsfsfadsf", scheduler.current.control.events?.list);
    let allEvents = scheduler.current.control?.events?.list;

    const payloadData = [];
    allEvents = allEvents.filter((i) => i.paytype !== "Auth");
    allEvents.map((i) => {

      let recno = i.refid ? i.refid : "0";

      recno = createRecord(recno);
      //   return false;
      return payloadData.push({
        ID: i.id.includes("-") ? null : i.id,
        WorkDate: i.start.value
          ? moment(i.start.value).format("YYYY-MM-DD")
          : moment(i.start).format("YYYY-MM-DD"),
        PayCode: i.paycode ? i.paycode : 1,
        FMLA: "",
        WorkFrom: i.start.valueß
          ? moment(i.start.value).format("HH:mm")
          : moment(i.start).format("HH:mm"),
        WorkTo: i.end.value
          ? moment(i.end.value).format("HH:mm")
          : moment(i.end).format("HH:mm"),
        JobOrder: "991022201",
        Typh: "N",
 
 
        RecordNo: recno, 
        Status: i.authStatus,
        Auth :i.Auth
      });
    });

    if (!payloadData.length) {
      ShowError("Please Select Time");
      return;
    }
 
 
 
    const sortedArray = sortArray(payloadData);
 
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feed/${EmployeeID}`,
        {
          workingHours: sortedArray,
          deletedList: deletedList
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Get payperiod  response", response.data);
        loadPreData();
        setDeletedList([])
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  }

  useImperativeHandle(ref, () => ({
    childFunction() {
      handleButtonClick();
    },
  }));

  const handleFilterColumns = (event, name) => {
    if (event) {
      let cp = [...columnsLookup];
      let findRec = cp.find((i) => {
        return i.name == name;
      });
      if (!findRec) {
        let copy = cp;
        copy.push({ name: name, width: 50, show: true });
        setColumnsLookup(copy);
      } else {
        let copy = [...columnsLookup];
        let ind = copy.findIndex((i) => {
          return i.name == name;
        });
        let copyobj = copy[ind];
        copyobj["show"] = true;
        copy[ind] = copyobj;
        setColumnsLookup(copy);
      }
    } else {
      let copy = [...columnsLookup];
      let ind = copy.findIndex((i) => {
        return i.name == name;
      });
      let copyobj = copy[ind];
      copyobj["show"] = false;
      copy[ind] = copyobj;

      setColumnsLookup(copy);

      // setState({
      //   ...state,
      //   rowHeaderColumns: .filter((i) => i.name !== name),
      // });
    }

    setTimeout(() => {}, 3000);
  };
  useEffect(() => {
    console.log("csdcsvsvssfgsdff");
    const args = onTimeArgs;
    if (args.start) {
      const allEvents = scheduler.current.control.events.list;
      const selectedDate = moment(args.start.value).format("YYYY-MM-DD");

      const filterEventsByDate = allEvents.filter((i) => {
        return (
          !["Auth", "H-5"].includes(i.paytype) &&
          moment(i.start).format("YYYY-MM-DD") === selectedDate
        );
      });

      console.log("filterEventsByDate 123", filterEventsByDate);
      // setCreteButtonClick(false)
      let isFind = false;
      if (filterEventsByDate.length) {
        filterEventsByDate.map((i) => {
          console.log("csdcsvsvssfgsdff   2");
          const startTime = moment(i.start).format("HH:mm");
          const endTime = moment(i.end).format("HH:mm");
          // const selectedStart = moment(args.start.value).format("HH:mm")
          // const selectedEnd = moment(args.end.value).format("HH:mm")
          const selectedStart = updatedTime.startTime;
          const selectedEnd = updatedTime.endTime;
          console.log(
            "startTime ends",
            startTime,
            endTime,
            selectedStart,
            selectedEnd
          );

          if (selectedStart < endTime && selectedEnd > startTime) {
            console.log("ssdsafsafaasfsafs");
            isFind = true;
          }
        });
        if (isFind) {
          setCreteButtonClick(isFind);
        } else {
          setCreteButtonClick(false);
        }

        if (["Auth", "H-5"].includes(labelCode)) {
          setCreteButtonClick(false);
        }
      } else {
        setCreteButtonClick(false);
      }
    }

    if (updatedTime.startTime === updatedTime.endTime) {
      setCreteButtonClick(true);
    } else if (updatedTime.startTime > updatedTime.endTime) {
      console.log("asdsfdjfodsfsdf grator");

      setCreteButtonClick(true);
    }
  }, [eventPayCode, updatedTime, creteButtonClick]);

  useEffect(() => {
    let onlyT = columnsLookup.filter((i) => i.show == true);

    setState({
      ...state,
      rowHeaderColumns: onlyT,
    });
  }, [columnsLookup]);

  const handleNonBusiness = (event, name) => {
    if (event) {
      setState({
        ...state,
        showNonBusiness: false,
      });
    } else {
      setState({
        ...state,
        showNonBusiness: true,
      });
    }
  };
  const handleEventDelete = (args) => {
    // args.data contains the deleted event
    console.log("Event deleted:", args);
  };

  console.log("data1234567", filteredEvent);

  return (
    <div>
      <div className={"space mb-3 d-flex "} style={{ gap: "10px" }}>
        <div>
          <label>
            <input
              type={"checkbox"}
              onChange={(ev) =>
                handleNonBusiness(ev.target.checked, "business")
              }
              ref={(el) => (checkboxBusiness = el)}
            />{" "}
            Show REG Hours
          </label>
        </div>
        <div>
          <label>
            <input
              type={"checkbox"}
              onChange={(ev) => handleFilterColumns(ev.target.checked, "TOTAL")}
              ref={(el) => (checkboxSummary = el)}
            />{" "}
            Show Total Hours
          </label>
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type={"checkbox"}
              onChange={(ev) => handleFilterColumns(ev.target.checked, "Type")}
              ref={(el) => (checkboxREG = el)}
            />{" "}
            Type
          </label>
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type={"checkbox"}
              onChange={(ev) => handleFilterColumns(ev.target.checked, "Hours")}
              ref={(el) => (checkboxREG = el)}
            />{" "}
            Hours
          </label>
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type={"checkbox"}
              onChange={(ev) =>
                handleFilterColumns(ev.target.checked, "timeRange")
              }
              ref={(el) => (checkboxREG = el)}
            />{" "}
            Time Range
          </label>
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type={"checkbox"}
              onChange={(ev) => handleFilterColumns(ev.target.checked, "Auth")}
              ref={(el) => (checkboxREG = el)}
            />{" "}
            Auth
          </label>
        </div>
        {/* <div>
          <label style={{ marginRight: "10px" }}>
            <input
              type={"checkbox"}
              onChange={(ev) =>
                handleFilterColumns(ev.target.checked, "Approve")
              }
              ref={(el) => (checkboxREG = el)}
            />{" "}
            Approve
          </label>
        </div> */}
      </div>
      <DayPilotScheduler
        {...config}
        onBeforeRowHeaderRender={handelonBeforeRowHeaderRender}
        headerTemplate={
          <div>
            <div>My Calendar</div>
          </div>
        }
        // rowMinHeight={60}
        
        cellWidthMin={50}
        eventMarginBottom={15}
       
        eventDeleteHandling="Update"
        onAfterEventRender={(args) => {
          // args.div = "manadd";
        }}
        onEventDelete={(arg) => {
          const allEvents = scheduler.current.control.events.list;
          let targetid = arg.e.data?.id;
          let targetids = arg.e.data?.ids;

          let wd = moment(arg.e.data?.WorkDate).format("YYYY-MM-DD")
          console.log("delete arg 1234",  allEvents.filter((i) => moment(i.WorkDate).format("YYYY-MM-DD") === wd));

          const dp = arg.control;
          if (targetid) {
            // const dp = arg.control;
            let refEvent = dp.events.list.filter((i) => {
              return i.refid == targetid 
            });

            refEvent &&
              refEvent.length &&
              refEvent.map((i) => {
                dp.events.removeById(i.id);
              });
          }
          if (targetid) { // remove child from that date
            let list = deletedList;
            list.push(targetid);

            let olds = allEvents.filter(
              (i) => i.parentIndex == targetids && i.API === "API" &&  moment(i.WorkDate).format("YYYY-MM-DD") == wd
            );

            console.log("delete arg olds",olds);
            olds.map((i)=>{
              dp.events.removeById(i.id);
              list.push(i.id)

            });  

            setDeletedList(list);
          }
          if (arg.e.data?.paytype?.toLowerCase() == "auth") {
            console.log("AAAAAA", arg.e.data?.paytype);
            arg.preventDefault();
          }
        }}
        // rowMinHeight={60}
        // eventMarginBottom={20}
        // eventStyle={eventStyle}
        startDate={startDate}
        eventHoverHandling="Bubble"
        rowHeaderHideIconEnabled="false"
        // endDate={endDate}
        // timeFormat="Clock12Hours"
        events={events}
        // allowEventOverlap={false}

        // duration={"Week"}

        // days={7}
        // TimeHeaders="6:00,6:00-18:00,18:00"
        ref={scheduler}
        eventBorderRadius={30}
      />

      {/* <button onClick={handleButtonClick}>Get All Events</button> */}

      <EventModal
        open={modalOpen}
        setOpen={setModalOpen}
        id={eventId}
        paytimeCodeList={paytimeCodeList?.length && paytimeCodeList}
        selectedEvent={selectedEvent}
        scheduler={scheduler}
        getPPEColorCode={getPPEColorCode}
        eventPayCode={eventPayCode}
        setEventPayCode={setEventPayCode}
        updatedTime={updatedTime}
        setUpdatedTime={setUpdatedTime}
        DayPilotScheduler={DayPilotScheduler}
        newEventCreateHandler={newEventCreateHandler}
        onTimeArgs={onTimeArgs}
        setOnTimeArgs={setOnTimeArgs}
        creteButtonClick={creteButtonClick}
        // setLableCode={setLableCode}
        correctionClick={correctionClick}
        paytimeCodeByID={paytimeCodeByID}
        openEventID={openEventID}
        statusSubmit={statusSubmit}
        approveStatus={approveStatus}
        loadPreData={loadPreData}
      />
      {/* {updatedTime} */}
    </div>
  );
});

export default ReactTimeSchedular;
