import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import useKeyboardShortcut from "use-keyboard-shortcut";
import MenuItem from "@mui/material/MenuItem";
import TableRows from "./payrollTableRow";
import * as XLSX from "xlsx";
// import { Grid, Typography } from "@material-ui/core";
// import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

// import "../../../../styles/muiTables.css";
import {
  Button,
  Hidden,
  Switch,
  Typography,
  Grid,
  FormControl,
  TextField,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Alert,
} from "@mui/material";

import { Card, CardBody, CardHeader } from "reactstrap";

// import Button from "@material-ui/core/Button";
// import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faFileExport,
  faTable,
  faCalendar,
  faCalendarCheck,
  faList,
  faEye,
  faCircleInfo,
  faClock,
  faUpload,
  faTableRows,
  faTableCells,
  faTableColumns,
  faDownload,
  faFileDownload,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";

import {
  addPad,
  adjustRoundedHR,
  adjustRoundedTime,
  checkAddedTimeError1,
  checkAddedTimeInnerv2,
  checkEnterTimeLate,
  d2Time,
  getHMFromTime,
  GetLeaveAccureByExperiance,
  getUserId,
  getUserRole,
  getValidPaycodeOfDay,
  HourMinFormat,
  isOnLeave,
  KeyGen,
  RemoveSpaceKeyGen,
  ShowError,
  ShowSucess,
  uniqueValue,
  zeroNumPad,
} from "../../../app/utility/helper";

import { floor } from "lodash";
import { Row, Col } from "react-bootstrap";
import LoadingPanel from "../../Common/telerik/loadingPanel";
import ConfigDB from "../../../Config/ThemeConfig";

import ReactTimeSchedular, {
  diifrenceInMinutes,
} from "../../ReactTimeSheet/ReactTimeSchedular";
import ViewTimeSheetTemplates from "../../ConfigurationMaster/TimeSheetTemplate/ViewTimeSheetTemplates";
import { useAuth } from "oidc-react";
import PayrollSimpleView from "./payrollSimpleView";
import PayrollSimpleViewV2 from "./payrollSimpleViewV2";
import { TableView } from "@mui/icons-material";
import TimeSheetModal from "./TimeSheetModal";

import { payCodeFilter } from "../../../app/utility/helper";
import PayrollAuthModalData from "./PayrollAuthModalData";
import { fetchLeaveHours } from "../../../app/store/reducers/slices/configurationSlice";
import { useDispatch, useSelector } from "react-redux";
import TimeClock from "./TimeClock";
import { createNotification } from "../../../app/store/reducers/slices/notificationSlice";
import {
  checkRuleNoneStandardRateCQA,
  checkRuleNoneStandardRateGBHWC,
  checkRuleNoneStandardRateGFD,
} from "../../../app/utility/rulesHelper";
import { toast } from "react-toastify";
import TableRowsV1 from "./TableRowsV1";
import TableRowsEmpV1 from "./TableRowsEmpV1";
import TableRowsEmpV2 from "./TableRowsEmpV2";
import ConfirmModal from "../../../app/utility/ConfirmModal";
import OTStandardSheet from "./OTStandardSheet";

const getStatusColorCode = (ConfirmStatus) => {
  if (ConfirmStatus === undefined) {
    return "#000";
  }
  ConfirmStatus = ConfirmStatus.toLowerCase();
  if (ConfirmStatus == "confirm") {
    return "#4A8738";
  } else if (ConfirmStatus == "auth") {
    return "#32CD32";
  } else if (ConfirmStatus == "pending") {
    return "#ED2938";
  } else {
    return "#000";
  }
};

const viewFields = [
  {
    label: "Pay Type",
    value: `jobtype`,
  },
  {
    label: "Job Order",
    value: "jobOrder",
  },
  {
    label: "Approve From",
    value: "ApproveFromTime",
  },
  {
    label: "Approve To",
    value: "ApproveToTime",
  },
];
let templateOption = [
  {
    label: "Use Previous Pay Period",
    value: "PREVPPE",
  },
  {
    label: "Use Previous Pay Week",
    value: "PREVWEAKPPE",
  },
  {
    label: "Use Template Profile",
    value: "PROFILETEMPLATE",
  },
  {
    label: "Custom Template for User",
    value: "CUSTOMTEMPLATE",
  },
];

let templateOptionData1 = [
  {
    label: "Use Previous Pay Period",
    value: "PREVPPE",
  },
  {
    label: "Use Previous Pay Week",
    value: "PREVWEAKPPE",
  },

  // {
  //   label: "Custom Template for User",
  //   value: "CUSTOMTEMPLATE",
  // },
];

const amountArray = [
  {
    startDate: "2020-06-01",
    endDate: "2020-06-05",
    rate: 25.7,
  },
  {
    startDate: "2020-06-06",
    endDate: "2020-06-08",
    rate: 35,
  },
  {
    startDate: "2020-06-10",
    endDate: "2020-06-15",
    rate: 50,
  },
];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const headers = [
  { label: "Pay Type", key: "jobtype" },
  { label: "Date", key: "date" },
  { label: "From", key: "time_from" },
  { label: "To", key: "time_to" },
];

const PayrollContent = ({
  setWeekData,
  weekData,
  setHourData,
  hourData,
  paytimeCode,
  payPeriod,
  setIsLoading,
  isloading,
  EmployeeProcessPayTimes,
  userId,
  allPayPeriod,
  paytimeCodeByID,
  userInfo,
  price1,
  rowsData,
  setRowsData,
  sampleData,
  sampleDatav1,
  rowsOTData,
  setRowsOTData,
  isLoadTimesheetData,
  setIsLoadTimesheetData,
}) => {
  const childRef = useRef();
  const handleClick = () => {};

  const [price, setPrice] = useState(0);
  const [authTemplate, setAuthTemplate] = useState({});
  const [fileErrors, setFileErrors] = useState([]);

  console.log("asdadadada", authTemplate);
  const keyData = [
    { labal: "Pay Type", type: "jobtype" },
    { labal: "Date", type: "date" },
    { labal: "From", type: "time_from" },
    { labal: "To", type: "time_to" },
  ];

  const handleDownloadExcel = (rowData) => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = rowData.map((i) => ({
      jobtype: i.jobtype,
      date: i.date,
      from: i.time_from,
      to: i.time_to,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "timesheet");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [["Pay Type", "Date", "From", "To"]]);

    XLSX.writeFile(
      workbook,
      `TimeSheet-E-${payPeriod.StartDate}-${userId}-${moment().format(
        "MMDDYYYYHHmmss"
      )}.xlsx`,
      { compression: true }
    );
  };

  function getMatchPaycode(a, b, err) {
    var matches = [];
    console.log("Dsaddadadadas", a, b);
    for (var i = 0; i < a.length; i++) {
      if (!b.includes(a[i])) {
        matches.push(a[i]);
      }
    }
    return { matches, err };
  }

  function getMatchDate(a, b, err) {
    var matches = [];
    for (var i = 0; i < a.length; i++) {
      if (!(a[i] >= b?.StartDate && a[i] <= b?.EndDate)) {
        matches.push(a[i]);
      }
    }
    return { matches, err };
  }

  const checkIsValidFile = (fileData, authPaycode) => {
    console.log("fileData", fileData);

    const uniqueDataDate = uniqueValue(fileData, "date");

    const filterPaycode = paytimeCodeByID.map((i) => {
      return { payCode: i.PayCode_PayTimeCode?.Abberv, code: i.PayCode };
    });

    const uniquePaycodeExcel = uniqueValue(fileData, "jobtype");
    const uniquePaycodeAssign = uniqueValue(filterPaycode, "payCode");
    console.log("fileData uniq", filterPaycode);

    const getErrorDate = getMatchDate(uniqueDataDate, payPeriod, "DATEERROR");

    const getErrorPaycode = getMatchPaycode(
      uniquePaycodeExcel,
      uniquePaycodeAssign,
      "CODEERR"
    );

    console.log("fileData Complete", paytimeCodeByID);

    let errors = [];

    if (getErrorDate?.matches.length) {
      console.log("dscdfsffas 2");

      let errorMEss = {
        message: `Date ${getErrorDate?.matches
          ?.map((i) => i)
          ?.join(",")} is not in PPE`,
      };
      errors.push(errorMEss);
    }

    if (getErrorPaycode?.matches.length) {
      console.log("dscdfsffas 2");

      let errorMEss = {
        message: `PayCode ${getErrorPaycode?.matches
          ?.map((i) => i)
          ?.join(",")} is not assign to you`,
      };
      errors.push(errorMEss);
    }

    setFileErrors(errors);
    return errors;
    // fileErrors = []
    // fileError.push("10000 is not assign to you");
    // fileError.push("Paycode is not assign to you");
    // fileError.push("Date is not in PPE");
  };
  const handleGetFileData = (e) => {
    console.log("CSdcfdssdfdsfds", e);

    const fileType = e.target.files[0]?.type;
    if (
      fileType !== "application/vnd.ms-excel" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload valid file");
      return false;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);

      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        // header: 1,
        raw: false,
        cellDates: true,
        dateNF: "yyyy-mm-dd",
        defval: "",
      });
      console.log("askdjlsjas", jsonData, data);
      function mergeDataObjects(data) {
        const finalData = [];
        let i = 0;
        console.log("asdasdfdadfa", data);
        while (i < data.length) {
          const currentObject = { ...data[i] };

          console.log("askdjahsdad", data[i]);

          if (
            i + 1 < data.length &&
            currentObject.date === data[i + 1].date &&
            currentObject.jobtype === data[i + 1].jobtype
          ) {
            currentObject.time_from1 = data[i + 1].From;
            currentObject.time_to1 = data[i + 1].To;

            i++; // Increment i to skip the next object since it has been merged
          } else {
            currentObject.time_from = null; //data[i].From;
            currentObject.time_to = null; //data[i].To;
          }

          finalData.push(currentObject);
          i++;
        }
        console.log("finalData", finalData);

        return finalData;
      }

      // const modifiedData = jsonData.map((item, i) => {
      const modifiedData = jsonData.map((item, i) => {
        keyData.map((k) => {
          if (k.type == "time_from") {
            item[k.type] = addPad(item[k.labal]);
            delete item[k.labal];
          } else if (k.type == "time_to") {
            item[k.type] = addPad(item[k.labal]);
            delete item[k.labal];
          } else {
            item[k.type] = item[k.labal];
            delete item[k.labal];
          }
          console.log("Csfdsfdsfsf", k, item);
        });

        item["id"] = KeyGen(7);
        item["jobOrder"] = null;
        item["rowstatus"] = "Confirm";
        item["ole2"] = false;
        item["ole1"] = false;
        item["isNew"] = true;
        const rate = getRateForDate(amountArray, item["date"], price);
        item["Rate"] = rate;
        let t1 = hoursDiffrence(item["time_from"], item["time_to"]);
        let t2 = hoursDiffrence(item["time_from1"], item["time_to1"]);
        let hrCalc = HourMinFormat(
          `${Math.floor((t1.totalMin + t2.totalMin) / 60)}:${
            (t1.totalMin + t2.totalMin) % 60
          }`
        );
        item["hrs"] = hrCalc;
        item["mn"] = t1.totalMin;
        item["mn1"] = t2.totalMin;
        const payInfo = getCalculatedAmtMin(
          t1.totalMin + t2.totalMin,
          item["jobtype"],
          rate
        );
        item["payAmount"] = payInfo;
        return item;
      });
      console.log("dasdjkadaklsa", modifiedData);
      checkIsValidFile(modifiedData);

      setRowsData(modifiedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const getRateForDate = (amountArray, date, price) => {
    const formattedDate = new Date(date);

    for (let i = 0; i < amountArray.length; i++) {
      const startDate = new Date(amountArray[i].startDate);
      const endDate = new Date(amountArray[i].endDate);

      if (formattedDate >= startDate && formattedDate <= endDate) {
        return amountArray[i].rate;
      }
    }
    console.log("Prccc", price);
    return price;
  };

  useEffect(() => {
    if (price1 > 0) {
      console.log("price1", price1);
      setPrice(price1);
    }
  }, [price1 > 0]);

  const getPPERateByDate = async (amountArray, date, price) => {
    const formattedDate = new Date(date);

    for (let i = 0; i < amountArray.length; i++) {
      const startDate = new Date(amountArray[i].startDate);
      const endDate = new Date(amountArray[i].endDate);

      if (formattedDate >= startDate && formattedDate <= endDate) {
        return amountArray[i].rate;
      }
    }
    return price;
  };

  console.log("asfsdf", price);

  const selectedCss = {
    padding: "5px",
    border: "1px solid grey",
    borderRadius: "6px",
    marginRight: "10px",
  };
  const notSelectedCss = { marginRight: "10px" };

  const getPPEColorCode = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    if (acode.toLowerCase() == "auth") {
      return "#e07c40";
    }
    let getcode = paytimeCode.find((i) => {
      return i.label == acode;
    });

    return getcode && getcode.color ? getcode.color : "#000";
  };

  const [originalAuthData, setOriginalAuthData] = useState([]); // set as rowdata values for authdata
  const [rowAuthData, setRowAuthData] = useState([]); // set as rowdata values for authdata
  const [templateData, setTemplateData] = useState([]); // over all show time by dayname
  const [authModal, setAuthModal] = useState(false);
  const [rowAuthAllData, setRowAuthAllData] = useState([]);
  console.log("Dasdsada", rowAuthData);

  // const [rowsData, setRowsData] = useState([sampleData]);
  const [saveData, setSaveData] = useState([]);
  const [templateGenData, setTemplateGenData] = useState([]);

  const [originalData, setOriginalData] = useState([]);
  console.log("originalData", originalData);
  // const [preDayHours, setPreDayHours] = useState([]);

  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [timeTo, setTimeTo] = useState();
  const [index, setIndex] = useState(0);
  const [check, setChecked] = useState(false);
  const [pay, setPay] = useState(rowsData);
  const [deleted, setDeleted] = useState(false);
  const [viewField, setViewField] = useState(false);
  const [prevPPE, setPrevPPE] = useState([]);
  const [profileType, setProfileType] = useState({ label: "", value: "" });
  const [maxSLLeave, setMaxSLLeave] = useState(0);
  const [maxALLeave, setMaxALLeave] = useState(0);
  const [adminTemplate, setAdminTemplate] = useState("");
  const [userTemplate, setUserTemplate] = useState("");
  const [templateID, setTemplateID] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateTitleCurrent, setTemplateTitleCurrent] = useState("");
  const [deletedItemIds, setDeletedItemIds] = useState([]);
  const [unqiueDate, setUnqiueDate] = useState([]);
  const { userData } = useAuth();
  const loginUserID = getUserId(userData);
  const [open, setOpen] = useState(false);
  const [radioData, setRadioData] = useState("all");
  const [deleteRowIndex, setDeleteRowIndex] = useState(0);
  const [isResetTimesheetModal, setIsResetTimesheetModal] = useState(false);
  const [templateOption1, setTemplateOption1] = useState(templateOptionData1);

  const role = getUserRole(userData);
  const handleClickOpen = () => {
    setOpen(true);
  };

  console.log("Sadadadad", userTemplate);

  const [isTableView, setIsTableView] = useState("simplev1"); //table , simplev1
  const [view, setView] = useState([]);
  const dispatch = useDispatch();
  const leaveHours = useSelector(
    (state) => state.configurationSlice.leaveHours
  );

  const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleConfirmResetTimesheet = async () => {
    let data = {
      employeeID: userId,
      ppe: payPeriod.ID,
    };
    axios
      .delete(
        `${process.env.REACT_APP_WEB_API_URL}Employee/DeleteEmployeeProcessPayTime`,
        { params: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      )
      .then(async (response) => {
        console.log("response", response);
        ShowSucess("Timesheet Reset Successfully");
        loadPreData();
        setIsResetTimesheetModal(false);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const handleDateColor = (data) => {
    const uniqueArr = Array.from(new Set(data.map((obj) => obj.date))).map(
      (id) => {
        return data.find((obj) => obj.date === id);
      }
    );
    console.log("new date", uniqueArr);

    let uniqueDates = uniqueArr.map((k, ind) => {
      if (ind % 2 == 0) {
        return { ...k, bgcolor: "#7173a429" };
      } else {
        return { ...k, bgcolor: "#e3e3e36b" };
      }
    });

    setUnqiueDate(uniqueDates);
  };

  const getView = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}timesheet/accessType/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        const result = response.data.result;
        console.log("askjskdsfhds", result);
        let resultArray = [];
        let order = ["table", "sheet", "simplev1", "simplev2", "timeClock"];
        // result.map((i,) => {
        //   return resultArray.push(i.code);
        // });

        order.map((s) => {
          if (result.find((i) => i.code == s))
            resultArray.push(result.find((i) => i.code == s).code);
        });

        //  const finalSortArray =  resultArray.sort((a,b) => order.indexOf(a) < order.indexOf(b))

        console.log("finalSortArray", resultArray);

        setView(resultArray);
        if (resultArray.length) {
          // setIsTableView(resultArray[0]);
        }
        console.log("dsklfjdsksddfhds", resultArray);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getDateColor = (date, rowsData) => {
    return unqiueDate.find((i) => {
      return i.date == date;
    })?.bgcolor;
  };
  const getTimeSheetTemplate = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/gettimesheetForEmployee`,
        {
          EmployeeID: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("templateData", response.data);

        if (response.data.result.length) {
          let templateData = response.data.result;
          let adminTemplateRes = templateData.filter((i) => {
            return i.EmployeeID == null;
          });

          setAdminTemplate(adminTemplateRes);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };

  const filterCustomTemplate = (userTemps) => {
    let filteredArray = [];
    console.log("finsfkdsmdsf 1", userTemps);
    userTemps.filter((i) => {
      let Esd = i.EffectiveStartDate
        ? moment(i.EffectiveStartDate).format("YYYY-MM-DD")
        : null;
      let Eed = i.EffectiveEndDate
        ? moment(i.EffectiveEndDate).format("YYYY-MM-DD")
        : null;
      if (
        (Esd == null && Eed == null) ||
        (payPeriod.StartDate >= Esd && payPeriod.StartDate <= Eed) ||
        (payPeriod.EndDate >= Esd && payPeriod.EndDate <= Eed)
      ) {
        filteredArray.push(i);
        console.log("finsfkdsmdsf 3", i);

        if (i.Auth) {
          console.log("finsfkdsmdsf 2");

          setAuthTemplate(i);
        }
      }
    });

    setUserTemplate(filteredArray);
    let finalDataOption = filteredArray.map((i) => {
      return {
        label:
          i.Auth == true
            ? `${i.EmployeeTemplateName} (Authorized) `
            : i.EmployeeTemplateName,
        value: i.TemplateID,
      };
    });
    finalDataOption = templateOption1.concat(finalDataOption);
    setTemplateOption1(finalDataOption);
  };
  const getTimeSheetTemplateByID = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/worktemplate/get`,
        {
          EmployeeID: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("templateData 121212 ", response.data, payPeriod);

        if (response.data.result.length) {
          let templateData = response.data.result;

          let userTemplateRes = templateData.filter((i) => {
            return i.IsActive === true && i.EmployeeID == userId;
          });
          if (payPeriod) {
            filterCustomTemplate(userTemplateRes);
          }
          // setUserTemplate(userTemplateRes);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };

  const statusSubmit = (id, status) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/approve/${id}`,
        {
          Approve: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        ShowSucess("Status Change Successfully");
      })
      .catch((error) => {
        ShowError(error);
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if (userId) {
      getTimeSheetTemplate();
      // getTimeSheetTemplateByID();
      getView(userId);
      dispatch(fetchLeaveHours());
    }
  }, [userId]);
  useEffect(() => {
    if (userId && payPeriod?.StartDate) {
      getTimeSheetTemplateByID();
    }
  }, [userId, payPeriod]);
  useEffect(() => {
    // if (payPeriod) {
    //   setTimeout(() => {
    //   getTimeSheetTemplateByID();
    //   // if (templateID) {
    //   //   // renderProfileData(); // update for change te
    //   //   // handlePrefillTimesSlot(templateData);
    //   // }
    //   if (templateID && templateTitle) {
    //     renderProfileData();
    //   }
    // }, 1000);
    // }
  }, [payPeriod]);

  const getDataforUserTemplate = async (templateId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}timesheet/get/${
            templateId ? templateId : 0
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => reject([]));
    });
  };

  useEffect(() => {
    getAuthTemlateData1(authTemplate?.TemplateID);
  }, [authTemplate?.TemplateID]);

  const getAuthTemlateData = (templateID) => {
    setIsLoading(true);
    let TimeSlot = {};
    getDataforUserTemplate(templateID)
      .then((res) => {
        setIsLoading(false);
        console.log("return inf res 11", res);
        TimeSlot = res[0];
        rowsData?.map((i) => {
          return deletedItemIds.push(i.ID);
        });

        if (Object.keys(TimeSlot).length > 0) {
          handlePrefillTimesSlot(TimeSlot.TimeSheetProfiles);
          setTemplateData(TimeSlot.TimeSheetProfiles); //days data save in state
          loadPreData();
        }
      })
      .catch((err) => {
        console.log("return inf err", err);
        setIsLoading(false);
      });
  };

  const getAuthTemlateData1 = (templateID) => {
    setIsLoading(true);
    let TimeSlot = {};
    getDataforUserTemplate(templateID)
      .then((res) => {
        setIsLoading(false);
        console.log("return inf res 11", res);
        TimeSlot = res[0];
        rowsData?.map((i) => {
          return deletedItemIds.push(i.ID);
        });
        if (Object.keys(TimeSlot).length > 0) {
          handlePrefillTimesSlot1(TimeSlot.TimeSheetProfiles);

          //days data save in state
        }
      })
      .catch((err) => {
        console.log("return inf err", err);
        setIsLoading(false);
      });
  };
  const renderProfileData = async () => {
    console.log("profileType 111 :->", profileType);

    setIsLoading(true);
    if (["PROFILETEMPLATE", "CUSTOMTEMPLATE"].includes(profileType?.value)) {
      if (!profileType?.value) {
        ShowError("Select Profile Type");
        return false;
      }
      if (!templateID) {
        ShowError("Select Template Type");
        return false;
      }

      let TimeSlot = {};
      if (profileType?.value == "CUSTOMTEMPLATE") {
        await getDataforUserTemplate(templateID)
          .then((res) => {
            console.log("return inf res", res[0]);
            TimeSlot = res[0];
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("return inf err", err);
            setIsLoading(false);
          });
      } else {
        TimeSlot = adminTemplate.find((k) => {
          return k.ID == templateID;
        });
      }
      console.log("return inf err", TimeSlot);

      if (Object.keys(TimeSlot).length > 0) {
        console.log("Innn Timeslot return inf err", TimeSlot);
        handlePrefillTimesSlot(TimeSlot.TimeSheetProfiles);

        setTemplateData(TimeSlot.TimeSheetProfiles); //days data save in state
        setTemplateTitleCurrent(templateTitle); // template title for display only
      }
    } else if (profileType?.value == "asdsa") {
      // let TimeSlot = userTemplate.find((k) => {
      //   return k.ID == templateID;
      // });
      // if (TimeSlot) {
      //   handlePrefillTimesSlot(TimeSlot.TimeSheetProfiles);
      // }
      setIsLoading(false);
    } else if (profileType?.value == "PREVPPE") {
      console.log("sjkhdsjkfdshfdjksfs", profileType?.value);
      handlePreviousPPE(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useKeyboardShortcut(
    ["Shift", "A"],
    (shortcutKeys) => {
      addTableRows();
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );
  useKeyboardShortcut(
    ["Shift", "S"],
    (shortcutKeys) => {
      handleSaveRows();
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  const getCalculatedAmtMin = (totalMins, type, price) => {
    let rate = price;
    console.log("#P rate", rate, "#", totalMins, "Type", type);
    // ----- for amount calculation
    if (totalMins > 4800) {
      totalMins = 4800;
    }
    //-----
    let codeItem =
      paytimeCode &&
      paytimeCode.find((item) => {
        return item.value === type;
      });

    let multi = codeItem ? (codeItem.multi ? codeItem.multi : 1) : 1;

    let hr = `${Math.floor(totalMins / 60)}`;
    let min = `${totalMins % 60}`;

    let hRate = (rate * hr * multi).toFixed(2);
    let mRate = (rate * (min / 60) * multi).toFixed(2);

    console.log("#pppp", totalMins, "=", hRate, "#", mRate);
    return (parseFloat(hRate) + parseFloat(mRate)).toFixed(2);
  };

  const addTableRows = () => {
    let contactArray = [{ ...sampleData }].concat([...rowsData]);

    console.log("contactArray", contactArray);
    setRowsData(contactArray);

    // setRowsData([...rowsData],{...sampleData});
  };

  const addTableRowsV1Sheet = () => {
    const week1End = moment(payPeriod.StartDate, "YYYY-MM-DD")
      .add(6, "days")
      .format("YYYY-MM-DD");
    const week2Start = moment(payPeriod.StartDate, "YYYY-MM-DD")
      .add(7, "days")
      .format("YYYY-MM-DD");

    if (radioData === "week1") {
      sampleDatav1.date =
        sampleDatav1.date <= week1End &&
        sampleDatav1.date >= payPeriod.StartDate
          ? sampleDatav1.date
          : week1End;
    } else if (radioData === "week2") {
      sampleDatav1.date =
        sampleDatav1.date <= payPeriod.EndDate &&
        sampleDatav1.date >= week2Start
          ? sampleDatav1.date
          : payPeriod.EndDate;
    }

    let contactArray = [{ ...sampleDatav1 }].concat([...rowsData]);

    setRowsData(contactArray);

    // setRowsData([...rowsData],{...sampleData});
  };

  const addTableRowForNextDay = (index) => {
    let dataRow = rowsData[index];
    let newData = { ...dataRow };
    newData.date = moment(newData.date).add(1, "day");
    newData.time_from = "00:00";

    let contactArray = [...rowsData].concat([{ ...newData }]);
    console.log("contactArray", contactArray);
    setRowsData(contactArray);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];

    let rowId = rows[index]["id"];
    let rowIds = rows[index]["ID"];
    let rowIds2 = rows[index]["ID2"];

    // return;
    let matchingRecord = rows.filter((row) => {
      return row.parentIndex === rowId;
    });
    let list = [...deletedItemIds];
    matchingRecord.map((i) => {
      if (i.ID) {
        list.push(parseInt(i.ID));
      }
      if (i.ID2) {
        list.push(parseInt(i.ID2));
      }
      let childIndex = rows.findIndex((row) => row.parentIndex === rowId);
      rows.splice(childIndex, 1);
    });

    rows.splice(index, 1);
    setRowsData(rows);
    // list.push(141002);
    list.push(parseInt(rowIds));
    list.push(parseInt(rowIds2));
    setDeletedItemIds(list);
  };

  const confirmTableRows = (index, evnt) => {
    console.log(index, "#@ rowstatus", evnt, "@#@");

    let code = "";
    let rowsInput = [...rowsData];
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      rowsInput[index][name] = value;
      rowsInput[index]["rowstatus"] = value;

      setRowsData(rowsInput);
    }
  };
  const updateHzRowOLD = (rowsInput, prevData) => {
    console.log(index, "qwe", prevData, "$$$$", rowsInput);
    if (prevData === undefined) {
      return rowsInput;
    }

    //update hazard row value
    if (
      rowsInput[index].hjobtype !== undefined &&
      rowsInput[index].hjobtype !== ""
    ) {
      let rowId = rowsInput[index]["id"] ? rowsInput[index]["id"] : "0";
      if (rowId == "0") {
        return false;
      }
      // let rowsInput = [ ...rowsInput ];

      console.log("#qwe rowsid", rowId);

      let paytime = paytimeCode.filter((item) => {
        return item.code >= 10 && item.code <= 12;
      });
      let arrayHtype = paytime.map((i) => {
        return i.value;
      });
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      let OtherforMasterIndex = rowsInput.filter((i) => {
        return i.parentIndex == rowId;
      });
      OtherforMasterIndex =
        parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
      if (existAtIndex !== -1) {
        //old index find
        console.log("#qwe LK UPDATE");

        // console.log('#LK UPDATE prevData', prevData);
        let newData = { ...rowsInput[index] };
        let newobj = { ...rowsInput[existAtIndex] };

        if (
          prevData["date"] == newobj["date"] &&
          prevData["time_from"] == newobj["time_from"] &&
          prevData["time_to"] == newobj["time_to"]
        ) {
          newobj = newData;
          newobj["id"] = newobj["id"] ? newobj["id"] : KeyGen(7);
          if (!newData["hjobtype"]) {
            newobj["code"] = getpayCode(newobj["jobtype"]);
            newobj["jobtype"] = newobj["jobtype"];
          } else {
            newobj["code"] = getpayCode(newobj["hjobtype"]);
            newobj["jobtype"] = newobj["hjobtype"];
          }
          newobj["time_from"] = newobj["time_from"];
          newobj["time_to"] = newobj["time_to"];
          newobj["ole1"] = false;
          newobj["ole2"] = false;
          newobj["hjobtype"] = "";
          newobj["parentIndex"] = rowId;

          console.log("#qwe LK UPDATE newobj", newobj);
          console.log(existAtIndex, "#LK newobj", newobj);
          rowsInput[existAtIndex] = newobj;

          console.log("#LK newobj newval", rowsInput);
          // return rowsInput;
          setRowsData([...rowsInput], rowsInput);
        }
      }
    }
    return rowsInput;
    //end hazard
  };

  const updateHzRow = async (rowsInput, prevData, newData) => {
    console.log(index, "qwe", prevData, "$$$$", rowsInput);
    if (prevData === undefined) {
      return rowsInput;
    }

    //update hazard row value
    if (
      rowsInput[index]?.hjobtype !== undefined &&
      rowsInput[index].hjobtype !== ""
    ) {
      let rowId = rowsInput[index]["id"] ? rowsInput[index]["id"] : "0";
      if (rowId == "0") {
        return rowsInput;
      }
      // let rowsInput = [ ...rowsInput ];

      console.log("#qwe rowsid", rowId);

      let paytime = paytimeCode.filter((item) => {
        return item.code >= 10 && item.code <= 12;
      });
      let arrayHtype = paytime.map((i) => {
        return i.value;
      });
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      let OtherforMasterIndex = rowsInput.filter((i) => {
        return i.parentIndex == rowId;
      });
      OtherforMasterIndex =
        parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
      if (existAtIndex !== -1) {
        //old index find
        console.log("#qwe LK UPDATE");

        // let newData = { ...rowsInput[index] };
        let newobj = { ...rowsInput[existAtIndex] };

        console.log("#LK UPDATE prevData", prevData);
        if (
          prevData["date"] == newobj["date"] &&
          prevData["time_from"] == newobj["time_from"] &&
          prevData["time_to"] == newobj["time_to"]
        ) {
          newobj["time_from"] = newData["time_from"];
          newobj["time_to"] = newData["time_to"];

          let t1 = hoursDiffrence(newData["time_from"], newData["time_to"]);
          let t2 = hoursDiffrence(newData["time_from1"], newData["time_to1"]);

          let hrCalc = HourMinFormat(
            `${Math.floor((t1.totalMin + t2.totalMin) / 60)}:${
              (t1.totalMin + t2.totalMin) % 60
            }`
          );
          console.log("asfaf", price);
          const rate = await getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = newobj.mn;
          let payInfo = getCalculatedAmtMin(totalmins, "H-5", rate);
          let totalmins1 = newobj.mn1;
          let payInfo1 = getCalculatedAmtMin(totalmins1, "H-5", rate);

          console.log("HTYPE", newobj, payInfo, rate);
          newobj["time_from1"] = newData["time_from1"];
          newobj["time_to1"] = newData["time_to1"];
          newobj["hrs"] = hrCalc;
          newobj["ole1"] = false;
          newobj["ole2"] = false;
          newobj["hjobtype"] = "";
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;

          console.log("#qwe LK UPDATE newobj", newobj);
          console.log(existAtIndex, "#LK newobj", newobj);
          rowsInput[existAtIndex] = newobj;

          console.log("#LK newobj newval", rowsInput);
          return rowsInput;
          // setRowsData([...rowsInput], rowsInput);
        }
      }
    }
    return rowsInput;
    //end hazard
  };
  const getTimeOfDays = (dayName, preDayHours, view) => {
    let data = preDayHours;

    data = preDayHours.filter((i) => {
      return i.dayName == dayName;
    });

    console.log("xxipen", data);

    if (data) {
      console.log("xxipenin", data);

      // let myresult = data.map((j) => { return { startTime: moment(j.startTime).format('HH:mm:ss'), endTime: moment( j.endTime).format('HH:mm:ss') } });
      let myresult = data.map((j) => {
        return {
          startTime: moment(j.startTime).utc().format("HH:mm:ss"),
          endTime: moment(j.endTime).utc().format("HH:mm:ss"),
          orgstartTime: j.startTime,
          orgendTime: j.endTime,
        };
      });
      console.log("Pixel", data, "###", myresult);

      return myresult;
    } else {
      return [{ startTime: null, endTime: null }];
    }
  };

  const replacePPEDate = (dateList, date) => {
    console.log(date, "dateList", dateList);
    let replaceby = dateList.find((i) => {
      return i.StartDate == date;
    });
    return replaceby ? replaceby.replacedate : date;
    // dateList.find(());
  };
  const isVerify = !rowsData.some((item) => item.verify);
  const handlePreviousPPE = async (PPECall = false) => {
    setIsLoading(true);
    console.log("currentSelectedPPEIndex 22222==");
    let csdt = payPeriod && payPeriod.StartDate;
    let cedt = payPeriod && payPeriod.EndDate;

    let currentSelectedPPEIndex = allPayPeriod.find((i) => {
      return i.StartDate == csdt && i.EndDate == cedt;
    }).ID;
    if (!currentSelectedPPEIndex) {
      ShowError("Previous PPE record not found");
      // return false;
    }
    console.log("xascdasadadd", currentSelectedPPEIndex);
    let preVal1 = allPayPeriod[currentSelectedPPEIndex - 1];
    let preVal = allPayPeriod.find((i) => i.ID == currentSelectedPPEIndex - 1);

    let sdt = preVal && preVal.StartDate;
    let edt = preVal && preVal.EndDate;
    if (sdt === undefined || edt === undefined) {
      return false;
    }

    // payPeriod.map((i) => {
    //   if(moment(i.startTime).format('dddd')===moment(preVal[i]['startTime']).format('dddd')) {
    //     preVal[ i ][ 'currentSDate' ] = i.startTime;
    //   }
    // });

    let a = moment(sdt);
    let b = moment(edt);
    let csm = moment(csdt);
    let cem = moment(cedt);

    let preFilledArray = [];
    let preFillDatesArray = [];
    let tempindex = 0;
    let c = moment(csm);
    for (let m = moment(a); m.isBefore(b); m.add(1, "days")) {
      let cdateName = c.format("YYYY-MM-DD");
      let cdayName = c.format("dddd");

      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");

      console.log(
        "preVal",
        cdayName,
        "#---",
        dayName,
        "@@",
        cdateName,
        "##",
        dateName
      );

      if (dayName == cdayName) {
        let oneObj = {};
        oneObj["replacedate"] = cdateName;
        oneObj["replaceday"] = cdayName;
        oneObj["StartDate"] = dateName;
        oneObj["EndDate"] = dateName;
        preFillDatesArray.push(oneObj);
      } else {
      }

      c.add(1, "days");
    }

    console.log("preVal--->", preFillDatesArray);
    setPrevPPE(preFillDatesArray);
    console.log("currentSelectedPPEIndex 11111");

    // get on PPE Change
    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee/timelog/${userId}/from/${sdt}/to/${edt}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        setIsLoading(false);
        console.log("Master response aas", response.data);
        if (!response.data.length && PPECall) {
          ShowError("Previous PPE record not found");
          return false;
        }
        let createObj = [];
        if (response.data.length) {
          let prevdata = response.data;
          setOriginalData(prevdata);

          console.log("prevdata--", prevdata);

          // dispatch({
          //   type: "EmployeeProcessPayTimes",
          //   payload: { EmployeeProcessPayTimes: prevdata },
          // });

          let EmployeeProcessPayTimes = prevdata;

          if (EmployeeProcessPayTimes.length) {
            let newArray = [];
            let assignData = [];
            let skip = false;
            EmployeeProcessPayTimes.map(async (i, index) => {
              const rate = getPPERateByDate(
                amountArray,
                replacePPEDate(
                  preFillDatesArray,
                  moment(i.WorkDate).format("YYYY-MM-DD")
                ),
                price
              );
              let totalMins = i.TotalMinutes;
              let orgObj = i;

              let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
              // newobj[ 'time_from1' ]  = info.start;
              // newobj[ 'time_to1' ]  = info.end;
              // newobj[ 'hrs' ] = HourMinFormat(hrCalc);

              let lableid = `${moment(i.WorkDate).format("YYYY-MM-DD")}${
                i.PayCode
              }${d2Time(i.WorkFrom)}`;
              lableid = RemoveSpaceKeyGen(lableid);
              orgObj["WorkDate"] = replacePPEDate(
                preFillDatesArray,
                moment(i.WorkDate).format("YYYY-MM-DD")
              );
              let payInfo = getCalculatedAmtMin(
                i.TotalMinutes,
                i.PayCode_PayTimeCode ? i.PayCode_PayTimeCode.Abberv : "",
                rate
              );
              let assign1 = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : null,
                ID: 0,
                id: lableid,
                date: replacePPEDate(
                  preFillDatesArray,
                  moment(i.WorkDate).utc().format("YYYY-MM-DD")
                ), // moment(i.WorkDate).format("YYYY-MM-DD"),
                dateName: moment(i.WorkDate).utc().format("dddd"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: payInfo,
                payAmount1: 0,
                isNew: true,
                mn: i.TotalMinutes,
                mn1: 0,
                hrs: HourMinFormat(hrCalc),
                jobOrder: i.JobOrder,
                code: i.PayCode ? parseInt(i.PayCode) : null,
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                ApproveFromTime: moment(i.ApproveFromTime).format("HH:mm"),
                ApproveToTime: moment(i.ApproveToTime).format("HH:mm"),
                Status: i?.Status,
                approvedStatus: i?.ApproveStatus,
              };

              let assign = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : null,
                ID: 0,
                id: lableid,
                date: replacePPEDate(
                  preFillDatesArray,
                  moment(i.WorkDate).utc().format("YYYY-MM-DD")
                ), // moment(i.WorkDate).format("YYYY-MM-DD"),
                dateName: moment(i.WorkDate).utc().format("dddd"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: payInfo,
                payAmount1: 0,
                isNew: true,
                mn: i.TotalMinutes,
                mn1: 0,
                hrs: HourMinFormat(hrCalc),
                jobOrder: i.JobOrder,
                code: i.PayCode ? parseInt(i.PayCode) : null,
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                ApproveFromTime: moment(i.ApproveFromTime).format("HH:mm"),
                ApproveToTime: moment(i.ApproveToTime).format("HH:mm"),
                Status: i?.Status,
                approvedStatus: i?.ApproveStatus,
              };

              console.log("cdsjfklsfjsklds", assign, assign1);
              assignData.push(assign1);
              console.log(index, "sadsdf@##", EmployeeProcessPayTimes.length);

              if (
                index + 1 < EmployeeProcessPayTimes.length &&
                i.PayCode == EmployeeProcessPayTimes[index + 1]["PayCode"] &&
                moment(i.WorkDate).utc().format("YYYY-MM-DD") ==
                  moment(EmployeeProcessPayTimes[index + 1]["WorkDate"])
                    .utc()
                    .format("YYYY-MM-DD") &&
                moment(i.CreatedDate).utc().format("YYYY-MM-DD HH:mm:ss") ==
                  moment(EmployeeProcessPayTimes[index + 1]["CreatedDate"])
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss") &&
                i.code == EmployeeProcessPayTimes[index + 1]["code"]
              ) {
                skip = true;

                // assign.ID = EmployeeProcessPayTimes[index + 1]["ID"];
                assign.ID2 = EmployeeProcessPayTimes[index + 1]["ID"];
                assign.time_from1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkFrom"]
                );
                assign.time_to1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkTo"]
                );
                assign.isNew = false;

                assign.mn1 = parseInt(
                  EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                );
                assign.time_to_set = true;
                assign.parentIndex =
                  EmployeeProcessPayTimes[index + 1]["RecordNo"];
                assign.hrs = HourMinFormat(
                  `${Math.floor(
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) /
                      60
                  )}:${
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) %
                    60
                  }`
                );
                assign.rowstatus = i.ConfirmStatus
                  ? i.ConfirmStatus
                  : "Confirm";
                // assign.hrs = HourFormat(parseInt(i.TotalHours)+parseInt(EmployeeProcessPayTimes[index+1]['TotalHours']));
                newArray.push(assign);

                console.log(index, "@## same date createdmmmm", assign);
              } else {
                if (!skip) {
                  newArray.push(assign);
                }
                skip = false;
              }
            });
            let mergedArray = [];

            if (isTableView == "table") {
              mergedArray = [...refindArray, ...rowAuthData];
            } else {
              mergedArray = assignData;
            }
            console.log("asjasklfjfkl", mergedArray);

            setRowsData(mergedArray);
            setIsLoading(false);
            let refindArray = await handleSetHzToHtype(newArray);
            console.log("taget newArrayMB prevdate 11111", refindArray);

            setSaveData(refindArray);
            // setRowsData(refindArray);
          } else {
            setIsLoading(false);
            setRowsData([]);
          }
        } else {
          setIsLoading(false);
          setRowsData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };
  const handlePrefill = async (evnt, preHrsArray) => {
    console.log("Load prefill", rowsData, preHrsArray);

    // setChecked(evnt);
    // if (evnt === true && !rowsData.length) {
    let sdt = payPeriod && payPeriod.StartDate;
    let edt = payPeriod && payPeriod.EndDate;

    var a = moment(sdt);
    var b = moment(edt);

    let preFilledArray = [];
    let preOriginal = [];
    let allData = [];
    let tempindex = 0;
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, "days")) {
      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");
      console.log("ascafafasf", preHrsArray);
      let timePre = getTimeOfDays(dayName, preHrsArray, isTableView);
      console.log("111222 @@@@@::: ", timePre);
      let createObject = {
        jobtype: "REG",
        id: KeyGen(7),
        date: dateName,
        time_from: timePre[0]?.["startTime"],
        time_to: timePre[0]?.["endTime"],
        time_from1: timePre[1]?.["startTime"],
        time_to1: timePre[1]?.["endTime"],
        hrs: "00:00",
        // hrs: HourMinFormat(hrCalc),
        mn: 0,
        mn1: 0,
        code: null,
        jobOrder: null,
        hjobtype: "",
        rowstatus: "Confirm",
        time_to_set: true,
        ole1: false,
        ole2: false,
        isTemplate: true,
      };

      //create logic
      if (timePre[0]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[0]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: null,
          FMLA: "",
          WorkFrom: timePre[0]?.["orgstartTime"],
          WorkTo: timePre[0]?.["orgendTime"],

          // "WorkFrom": moment(` ${timePre[0]?.["orgstartTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),
          // "WorkTo":moment(` ${timePre[0]?.["orgendTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),// timePre[0]?.["endTime"],
          // "WorkFrom": moment(`${timePre[0]?.["startTime"]}`,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.000Z'),
          // "WorkTo":mome,
          Rate: 26,
          JobOrder: null,
          TotalHours: 420,
          GrossAmount: 10920,
          Typh: "REG",
          prefillData: true,
          PPE: 19,
          RecordNo: "0",
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          Auth: "Auth",
          isTemplate: true,
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }
      if (timePre[1]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[1]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: null,
          FMLA: "",
          WorkFrom: timePre[1]?.["orgstartTime"],
          WorkTo: timePre[1]?.["orgendTime"],
          Rate: 26,
          JobOrder: null,
          TotalHours: 420,
          GrossAmount: 10920,
          prefillData: true,
          Typh: "REG",
          PPE: 19,
          RecordNo: "0",
          Auth: "Auth",
          isTemplate: true,
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }
      if (timePre[0]?.["startTime"]) {
        let hrCalc1 = `${Math.floor(timePre[0]?.["mn"] / 60)}:${
          timePre[0]?.["mn"] % 60
        }`;

        allData.push({
          jobtype: "REG",
          id: KeyGen(7),
          date: dateName,
          time_from: timePre[0]?.["startTime"],
          time_to: timePre[0]?.["endTime"],

          // hrs: "05:00",
          hrs: getHMFromTime(timePre[0]?.["startTime"], timePre[0]?.["endTime"])
            .hhmm,
          mn: getHMFromTime(timePre[0]?.["startTime"], timePre[0]?.["endTime"])
            .min,
          mn1: 0,
          code: null,
          jobOrder: null,
          hjobtype: "",
          rowstatus: "Confirm",
          time_to_set: true,
          ole1: false,
          ole2: false,
          isTemplate: true,
        });
      }

      if (timePre[1]?.["startTime"]) {
        let hrCalc2 = `${Math.floor(timePre[1]?.["mn1"] / 60)}:${
          timePre[1]?.["mn1"] % 60
        }`;

        allData.push({
          jobtype: "REG",
          id: KeyGen(7),
          date: dateName,
          time_from: timePre[1]?.["startTime"],
          time_to: timePre[1]?.["endTime"],
          // hrs: "05:00",
          hrs: getHMFromTime(timePre[1]?.["startTime"], timePre[1]?.["endTime"])
            .hhmm,
          mn: getHMFromTime(timePre[1]?.["startTime"], timePre[1]?.["endTime"])
            .min,
          mn1: 0,
          code: null,
          jobOrder: null,
          hjobtype: "",
          rowstatus: "Confirm",
          time_to_set: true,
          ole1: false,
          ole2: false,
          isTemplate: true,
        });
      }

      /**
       * 
}
       */
      if (timePre[0]?.["startTime"]) {
        preFilledArray.push(createObject);
        console.log("111 @@@@@ :: ", createObject, preFilledArray);
      }
    } //end of loop

    let merged = [...saveData, ...preFilledArray];

    // setRowsData(merged);
    console.log("--preOriginal merged 22", merged);
    console.log(
      merged,
      "--preOriginal",
      saveData,
      "PPE",
      preFilledArray,
      "@@@@@",
      preOriginal,
      "#####",
      originalData
    );

    console.log("PreOrifginal Data asd", allData);
    let mixData = originalData.concat(preOriginal);
    const combinedArray = [originalData, preOriginal];
    setOriginalAuthData(preOriginal);
    setRowAuthData(preFilledArray); // not in use
    setRowAuthAllData(allData);
    handleDateColor(preFilledArray);
    console.log("asdadadadadadsa", allData);
    await Promise.all(
      preFilledArray.map(async (i, index) => {
        // setIndex(index);
        // await handleTimeDiffCalculation();
        // handleTimeDiffCalculationPrefill(index, merged);
        handleTimeDiffCalculationPrefill(index, preFilledArray);
      })
    );
    // await  loadPreData()
    setIsLoading(false);
    setTimeout(() => {
      setRowsData(allData);
    }, 1000);

    // }
  };
  const getAuthHoursData = async (evnt, preHrsArray) => {
    console.log("Load prefill", rowsData, preHrsArray);

    // setChecked(evnt);
    // if (evnt === true && !rowsData.length) {
    let sdt = payPeriod && payPeriod.StartDate;
    let edt = payPeriod && payPeriod.EndDate;

    var a = moment(sdt);
    var b = moment(edt);

    let preFilledArray = [];
    let preOriginal = [];
    let allData = [];
    let tempindex = 0;
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, "days")) {
      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");
      console.log("ascafafasf", preHrsArray);
      let timePre = getTimeOfDays(dayName, preHrsArray, isTableView);
      console.log("111222 @@@@@::: ", timePre);
      let createObject = {
        jobtype: "REG",
        id: KeyGen(7),
        date: dateName,
        time_from: timePre[0]?.["startTime"],
        time_to: timePre[0]?.["endTime"],
        time_from1: timePre[1]?.["startTime"],
        time_to1: timePre[1]?.["endTime"],
        hrs: "00:00",
        // hrs: HourMinFormat(hrCalc),
        mn: 0,
        mn1: 0,
        code: null,
        jobOrder: null,
        hjobtype: "",
        rowstatus: "Confirm",
        time_to_set: true,
        ole1: false,
        ole2: false,
        isTemplate: true,
      };

      //create logic
      if (timePre[0]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[0]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: null,
          FMLA: "",
          WorkFrom: timePre[0]?.["orgstartTime"],
          WorkTo: timePre[0]?.["orgendTime"],

          // "WorkFrom": moment(` ${timePre[0]?.["orgstartTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),
          // "WorkTo":moment(` ${timePre[0]?.["orgendTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),// timePre[0]?.["endTime"],
          // "WorkFrom": moment(`${timePre[0]?.["startTime"]}`,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.000Z'),
          // "WorkTo":mome,
          Rate: 26,
          JobOrder: null,
          TotalHours: 420,
          GrossAmount: 10920,
          Typh: "REG",
          prefillData: true,
          PPE: 19,
          RecordNo: "0",
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          Auth: "Auth",
          isTemplate: true,
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }
      if (timePre[1]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[1]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: null,
          FMLA: "",
          WorkFrom: timePre[1]?.["orgstartTime"],
          WorkTo: timePre[1]?.["orgendTime"],
          Rate: 26,
          JobOrder: null,
          TotalHours: 420,
          GrossAmount: 10920,
          prefillData: true,
          Typh: "REG",
          PPE: 19,
          RecordNo: "0",
          Auth: "Auth",
          isTemplate: true,
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }
      if (timePre[0]?.["startTime"]) {
        let hrCalc1 = `${Math.floor(timePre[0]?.["mn"] / 60)}:${
          timePre[0]?.["mn"] % 60
        }`;

        allData.push({
          jobtype: "REG",
          id: KeyGen(7),
          date: dateName,
          time_from: timePre[0]?.["startTime"],
          time_to: timePre[0]?.["endTime"],

          // hrs: "05:00",
          hrs: getHMFromTime(timePre[0]?.["startTime"], timePre[0]?.["endTime"])
            .hhmm,
          mn: getHMFromTime(timePre[0]?.["startTime"], timePre[0]?.["endTime"])
            .min,
          mn1: 0,
          code: null,
          jobOrder: null,
          hjobtype: "",
          rowstatus: "Confirm",
          time_to_set: true,
          ole1: false,
          ole2: false,
          isTemplate: true,
        });
      }

      if (timePre[1]?.["startTime"]) {
        let hrCalc2 = `${Math.floor(timePre[1]?.["mn1"] / 60)}:${
          timePre[1]?.["mn1"] % 60
        }`;

        allData.push({
          jobtype: "REG",
          id: KeyGen(7),
          date: dateName,
          time_from: timePre[1]?.["startTime"],
          time_to: timePre[1]?.["endTime"],
          // hrs: "05:00",
          hrs: getHMFromTime(timePre[1]?.["startTime"], timePre[1]?.["endTime"])
            .hhmm,
          mn: getHMFromTime(timePre[1]?.["startTime"], timePre[1]?.["endTime"])
            .min,
          mn1: 0,
          code: null,
          jobOrder: null,
          hjobtype: "",
          rowstatus: "Confirm",
          time_to_set: true,
          ole1: false,
          ole2: false,
          isTemplate: true,
        });
      }

      /**
       * 
}
       */
      if (timePre[0]?.["startTime"]) {
        preFilledArray.push(createObject);
        console.log("111 @@@@@ :: ", createObject, preFilledArray);
      }
    } //end of loop

    let merged = [...saveData, ...preFilledArray];

    // setRowsData(merged);
    console.log("--preOriginal merged 22", merged);
    console.log(
      merged,
      "--preOriginal",
      saveData,
      "PPE",
      preFilledArray,
      "@@@@@",
      preOriginal,
      "#####",
      originalData
    );

    console.log("PreOrifginal Data asd", allData);
    let mixData = originalData.concat(preOriginal);
    const combinedArray = [originalData, preOriginal];

    console.log("asdanskdjahdajkdsa", allData);
    // setOriginalAuthData(preOriginal);
    setRowAuthData(allData); // not in use
    // setRowAuthAllData(allData);
    // handleDateColor(preFilledArray);
    //   console.log("asdadadadadadsa", allData);
    // await Promise.all(
    //   preFilledArray.map(async (i, index) => {
    //     // setIndex(index);
    //     // await handleTimeDiffCalculation();
    //     // handleTimeDiffCalculationPrefill(index, merged);
    //     handleTimeDiffCalculationPrefill(index, preFilledArray);
    //   })
    // );
    // // await  loadPreData()
    // setIsLoading(false);
    // setTimeout(() => {

    //   setRowsData(allData);

    // }, 1000);

    // }
  };
  const handleChange = (index, evnt, val = "") => {
    console.log(index, "#@", evnt, "@#@", val);

    setIndex(index);
    let code = "";
    let rowsInput = [...rowsData];

    let prev = { ...rowsInput[index] };
    // let prev = { ...rowsInput[index] };

    let attempt = false;
    // debugger;
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      const jobtypeCode = evnt.target.code ? evnt.target.code : null;
      console.log("JOBTYEO DATA BAR 1", val, value, jobtypeCode);

      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        attempt = true;
      }

      rowsInput[index][name] = value;
      if (name === "time_from") {
        if (!rowsInput[index]["time_to_set"]) {
          let newTime = moment(value, "HH:mm").add(1, "hours");
          rowsInput[index]["time_to"] = newTime.format("HH:mm");
        }
      }
      //JD EXTRA CODE END
      if (name === "time_to") {
        rowsInput[index]["time_to_set"] = value ? true : false;
      }

      if (
        rowsInput[index].jobtype === "REG" &&
        rowsInput[index].hjobtype !== ""
      ) {
        rowsInput = updateHzRow(rowsInput, prev, rowsInput[index]);
      }

      //JD EXTRA CODE
      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        if (attempt) {
          // rowsInput.splice(index + 1, 1);
        }
      }

      if (name === "normal_profile" && evnt.target.checked === true) {
        setChecked(evnt.target.checked);
        // setRowsData(data);
        // loadPreData('BBB');
      } else if (name === "normal_profile" && evnt.target.checked === false) {
        console.log("evnt.target.checked", sampleData);

        // setRowsData([sampleData]);
        setChecked(evnt.target.checked);
      } else if (name && name === "jobtype") {
        if (value === "NDIF") {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        } else {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        }
        setRowsData(rowsInput);
      } else {
        if (isTableView == "table") {
          console.log("XXASDDE UPDATE AAA 1");

          checkAddedTimeError1(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        } else {
          console.log("XXASDDE UPDATE AAA 2");

          checkAddedTimeInnerv2(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        }
        // let updateRowsInput = updateHzRow(rowsInput,prev);
        setRowsData(rowsInput);
      }
    } else {
      if (val !== null) {
        console.log("XXASDDE UPDATE AAA");

        rowsInput[index][val.name] = val.value;
        rowsInput[index]["code"] = val.code;
        setRowsData(rowsInput);
      } else {
        rowsInput[index]["jobtype"] = null;
        rowsInput[index]["code"] = null;
        setRowsData(rowsInput);
      }
    }

    // setIndex(index);
  };
  const handleChangeV1 = (ind, evnt, val = "", data) => {
    let index = ind;
    console.log(index, "#@", evnt, "@#@");
    let code = "";
    let rowsInput = [...rowsData];
    // console.log("fdsffdsfdsfs",index,rowsInput.findIndex((i) => i.date == data.date && (i.ID == data.ID || i.id == data.id) ))// rowsInput.findIndex((i) => i.date === rowsInput[index].date) , evnt.target.name, evnt.target.value, rowsInput[index].date);
    if (
      rowsInput.findIndex(
        (i) => i.date == data?.date && (i.ID == data.ID || i.id == data.id)
      )
    ) {
      index = rowsInput.findIndex(
        (i) => i.date == data.date && (i.ID == data.ID || i.id == data.id)
      );
    }
    setIndex(index);

    let prev = { ...rowsInput[index] };
    // let prev = { ...rowsInput[index] };

    let attempt = false;
    // debugger;
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      const jobtypeCode = evnt.target.code ? evnt.target.code : null;
      console.log("JOBTYEO DATA BAR 2", value, jobtypeCode);

      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        attempt = true;
      }

      rowsInput[index][name] = value;
      if (name === "time_from") {
        console.log("dsfdsmfkldsfnklds", value, index);
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_from"] = info;
        if (!rowsInput[index]["time_to_set"]) {
          let newTime = moment(info, "HH:mm").add(1, "hours");
          rowsInput[index]["time_to"] = newTime.format("HH:mm");
        }
      }

      //JD EXTRA CODE END
      if (name === "time_to") {
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_to"] = info;
        rowsInput[index]["time_to_set"] = value ? true : false;
        setRowsData(rowsInput);
      }

      if (name === "time_from1") {
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_from1"] = info;
        // if (!rowsInput[index]["time_to_set"]) {
        //   let newTime = moment(info, "HH:mm").add(1, "hours");
        //   rowsInput[index]["time_to"] = newTime.format("HH:mm");
        // }
      }

      //JD EXTRA CODE END
      if (name === "time_to1") {
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_to1"] = info;
        // rowsInput[index]["time_to_set"] = value ? true : false;
      }
      if (name === "hours") {
        if (!value.includes("_")) {
          let info = adjustRoundedHR(value);
          console.log(value, "Infoa hrs time ###", info);
          rowsInput[index]["hrs"] = info;
        }

        // rowsInput[index]["time_to_set"] = value ? true : false;
      }

      // if (
      //   rowsInput[index].jobtype === "REG" &&
      //   rowsInput[index].hjobtype !== ""
      // ) {
      //   rowsInput = updateHzRow(rowsInput, prev, rowsInput[index]);
      // }

      //JD EXTRA CODE
      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        if (attempt) {
          // rowsInput.splice(index + 1, 1);
        }
      }

      if (name === "normal_profile" && evnt.target.checked === true) {
        setChecked(evnt.target.checked);
        // setRowsData(data);
        // loadPreData('BBB');
      } else if (name === "normal_profile" && evnt.target.checked === false) {
        console.log("evnt.target.checked", sampleData);

        // setRowsData([sampleData]);
        setChecked(evnt.target.checked);
      } else if (name && name === "jobtype") {
        if (value === "NDIF") {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        } else {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        }
        setRowsData(rowsInput);
      } else {
        console.log("XXASDDE UPDATE AAA");

        if (isTableView == "table") {
          checkAddedTimeError1(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        } else {
          checkAddedTimeInnerv2(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        }
        // let updateRowsInput = updateHzRow(rowsInput,prev);
        setRowsData(rowsInput);
      }
    } else {
      if (val !== null) {
        console.log("XXASDDE UPDATE AAA");

        rowsInput[index][val.name] = val.value;
        rowsInput[index]["code"] = val.code;
        setRowsData(rowsInput);
      } else {
        rowsInput[index]["jobtype"] = null;
        rowsInput[index]["code"] = null;
        setRowsData(rowsInput);
      }
    }

    // setIndex(index);
  };

  const handleChangeOTV1 = (index, evnt, val = "") => {
    console.log(index, "#@", evnt, "@#@", val);

    setIndex(index);
    let code = "";
    let rowsInput = [...rowsOTData];

    let prev = { ...rowsInput[index] };
    // let prev = { ...rowsInput[index] };

    let attempt = false;
    // debugger;
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      const jobtypeCode = evnt.target.code ? evnt.target.code : null;
      console.log("JOBTYEO DATA BAR 3", val, value, jobtypeCode);

      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        attempt = true;
      }

      rowsInput[index][name] = value;
      if (name === "time_from") {
        if (!rowsInput[index]["time_to_set"]) {
          let newTime = moment(value, "HH:mm").add(1, "hours");
          rowsInput[index]["time_to"] = newTime.format("HH:mm");
        }
      }
      //JD EXTRA CODE END
      if (name === "time_to") {
        rowsInput[index]["time_to_set"] = value ? true : false;
      }

      // if (
      //   rowsInput[index].jobtype === "REG" &&
      //   rowsInput[index].hjobtype !== ""
      // ) {
      //   rowsInput = updateHzRow(rowsInput, prev, rowsInput[index]);
      // }

      //JD EXTRA CODE
      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        if (attempt) {
          // rowsInput.splice(index + 1, 1);
        }
      }

      if (name === "normal_profile" && evnt.target.checked === true) {
        setChecked(evnt.target.checked);
        // setRowsData(data);
        // loadPreData('BBB');
      } else if (name === "normal_profile" && evnt.target.checked === false) {
        console.log("evnt.target.checked", sampleData);

        // setRowsData([sampleData]);
        setChecked(evnt.target.checked);
      } else if (name && name === "jobtype") {
        if (value === "NDIF") {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        } else {
          rowsInput[index]["jobOrder"] = null;
          rowsInput[index]["code"] = jobtypeCode;
        }
        setRowsOTData(rowsInput);
      } else {
        console.log("XXASDDE UPDATE AAA");

        if (isTableView == "table") {
          checkAddedTimeError1(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        } else {
          checkAddedTimeInnerv2(rowsInput, rowsInput[index], (isError) => {
            setHasError(isError);
          });
        }
        // let updateRowsInput = updateHzRow(rowsInput,prev);
        setRowsOTData(rowsInput);
      }
    } else {
      if (val !== null) {
        console.log("XXASDDE UPDATE AAA");

        rowsInput[index][val.name] = val.value;
        rowsInput[index]["code"] = val.code;
        setRowsOTData(rowsInput);
      } else {
        rowsInput[index]["jobtype"] = null;
        rowsInput[index]["code"] = null;
        setRowsOTData(rowsInput);
      }
    }

    // setIndex(index);
  };
  const addAfter = (array, index, newItem) => {
    return [...array.slice(0, index), newItem, ...array.slice(index)];
  };
  const handleChangeHizard = async (index, val = "") => {
    console.log("#LK", index, "#", val);

    let paytime = paytimeCode.filter((item) => {
      return item.code >= 10 && item.code <= 12;
    });
    let arrayHtype = paytime.map((i) => {
      return i.value;
    });

    if (!val) {
      const rowsInput = rowsData;
      let rowId = rowsInput[index]["id"];

      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      if (existAtIndex !== -1) {
        console.log("Next clear", existAtIndex);
        let newobj = { ...rowsInput[existAtIndex] };
        rowsInput.splice(existAtIndex, 1);
        console.log("next row check", newobj);
        setRowsData([...rowsInput], rowsInput);
        return false;
      }
    }

    const rowsInput = [...rowsData];
    let rowId = rowsInput[index]["id"];
    console.log("#mj rowsid", rowId);

    let existAtIndex = rowsData.findIndex((i) => {
      return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
    });
    let OtherforMasterIndex = rowsInput.filter((i) => {
      return i.parentIndex == rowId;
    });
    console.log(
      "#LK index",
      index,
      "#val",
      val.value,
      "@-@",
      existAtIndex,
      "XX",
      OtherforMasterIndex,
      " << >> ",
      rowsInput
    );
    OtherforMasterIndex =
      parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
    if (existAtIndex !== -1) {
      //old index find
      let prevData = { ...rowsInput[index] };
      // let newobj = { ...rowsInput[ existAtIndex ] };
      // newobj = prevData;
      let newobj = prevData;

      const rate = getPPERateByDate(amountArray, newobj.date, price);
      let totalmins = newobj.totalminutes;
      let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

      let totalmins1 = newobj.totalminutes;
      let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);

      newobj["jobtype"] = val.value;
      newobj["code"] = val.code;
      newobj["hjobtype"] = "";
      newobj["parentIndex"] = rowId;
      newobj["rowstatus"] = "Confirm";
      newobj["ole2"] = false;
      newobj["ole1"] = false;
      newobj["id"] = KeyGen(7);
      newobj["Rate"] = rate;
      newobj["payAmount"] = payInfo;
      newobj["payAmount1"] = payInfo1;

      console.log("#LK UPDATE newobj", newobj);

      console.log(existAtIndex, "#LK newobj", newobj);
      rowsInput[existAtIndex] = newobj;

      console.log("#LK newobj newval", rowsInput);

      // setRowsData([...rowsInput], rowsInput);
    } else {
      let newobj = { ...rowsInput[index] };
      let insertAt = OtherforMasterIndex;
      const rate = getPPERateByDate(amountArray, newobj.date, price);
      let totalmins = newobj.mn;
      let payInfo = getCalculatedAmtMin(totalmins, val.value, rate);
      let totalmins1 = newobj.mn1;
      let payInfo1 = getCalculatedAmtMin(totalmins1, val.value, rate);
      console.log("dsfhjs", newobj, rate, payInfo);
      // OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log(
        "#LK ADD",
        "OtherforMasterIndex",
        OtherforMasterIndex,
        "#",
        insertAt,
        "!#!",
        index
      );
      newobj["jobtype"] = val.value;
      newobj["code"] = val.code;
      newobj["parentIndex"] = rowId;
      newobj["rowstatus"] = "Confirm";
      newobj["ole2"] = false;
      newobj["ole1"] = false;
      newobj["id"] = KeyGen(7);
      newobj["ID"] = null;
      newobj["ID2"] = null;
      newobj["Rate"] = rate;
      newobj["payAmount"] = payInfo;
      newobj["payAmount1"] = payInfo1;
      let newobjSame = { ...rowsInput[index] };
      newobjSame["hjobtype"] = val.value;
      rowsInput[index] = newobjSame;
      let ne = addAfter(rowsInput, insertAt, newobj);
      console.log("ASacfasfas", ne);
      setRowsData(ne);
    }

    return false;
  };
  const setHoursWorked = async () => {
    await setHourData({
      ...hourData,
      REG: 0,
      "S/L": 0,
      NDIF: 0,
      HAZARDOUS: 0,
    });
    let myCnt = {
      "S/L": 0,
      REG: 0,
      NDIF: 0,
      HAZARDOUS: 0,
    };

    let rowsData1 = rowsData.filter((i) => {
      return !i.isTemplate;
    });
    console.log("matter rowsData", rowsData1);

    if (rowsData1.length) {
      rowsData1.map(async (item, index) => {
        let selectedHours = parseInt(item.mn) + parseInt(item.mn1);
        if (item.jobtype === "REG") {
          // myCnt.REG = myCnt.REG + parseInt(selectedHours);
          myCnt.REG = myCnt.REG + parseInt(selectedHours);
        } else if (item.jobtype === "NDIF") {
          myCnt.NDIF = myCnt.NDIF + parseInt(selectedHours);
        } else if (item.jobtype === "HAZARDOUS") {
          myCnt.HAZARDOUS = myCnt.HAZARDOUS + parseInt(selectedHours);
        } else {
          console.log("item.jobtype", item.jobtype);
          myCnt[item.jobtype] = myCnt[item.jobtype]
            ? myCnt[item.jobtype] + parseInt(selectedHours)
            : 0 + parseInt(selectedHours);
        }
      });

      console.log("Nhr", myCnt);
      setHourData(myCnt);
    }
  };

  const setHoursWorkedOld = async () => {
    await setHourData({
      ...hourData,
      REG: 0,
      "S/L": 0,
      NDIF: 0,
      HAZARDOUS: 0,
    });
    let myCnt = {
      "S/L": 0,
      REG: 0,
      NDIF: 0,
      HAZARDOUS: 0,
    };
    if (rowsData.length) {
      rowsData.map(async (item, index) => {
        let selectedHours = item.hrs
          ? item.hrs.includes(":")
            ? item.hrs.split(":")[0]
            : item.hrs
          : 0;

        console.log("##### Hours ::: ", selectedHours, item.hrs);
        if (item.jobtype === "REG") {
          myCnt.REG = myCnt.REG + parseInt(selectedHours);
          // setHourData({
          //   ...hourData,
          //   REG: parseInt(selectedHours),
          // });
        } else if (item.jobtype === "NDIF") {
          // setHourData({
          //   ...hourData,
          //   NDIF: parseInt(selectedHours),
          // });
          myCnt.NDIF = myCnt.NDIF + parseInt(selectedHours);
        } else if (item.jobtype === "HAZARDOUS") {
          // setHourData({
          //   ...hourData,
          //   HAZARDOUS: parseInt(selectedHours),
          // });
          myCnt.HAZARDOUS = myCnt.HAZARDOUS + parseInt(selectedHours);
        } else {
          console.log("item.jobtype", item.jobtype);

          myCnt[item.jobtype] = myCnt[item.jobtype]
            ? myCnt[item.jobtype] + parseInt(selectedHours)
            : 0 + parseInt(selectedHours);
        }
      });

      console.log("Nhr", myCnt);
      setHourData(myCnt);
    }
  };
  const handleDateFieldChange = (name, value, index) => {
    setIndex(index);
    let rowsInput = [...rowsData];
    console.log("#LL 012331", "rowsInput", rowsInput[index]["id"]);
    let rowId = rowsInput[index]["id"];

    let filZ = rowsData.filter((i) => {
      return i.parentIndex === rowId;
    });

    filZ.map((j) => {
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.id === j.id;
      });
      if (existAtIndex !== -1) {
        rowsInput[existAtIndex]["date"] = value;
      }
    });
    const date = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
    const rate = getPPERateByDate(
      amountArray,
      moment(value, "MM/DD/YYYY").format("YYYY-MM-DD"),
      price
    );
    rowsInput[index][name] = date;
    rowsInput[index]["Rate"] = rate;
    console.log("dsds", rowsInput[index]);
    let totalmins = rowsInput[index].mn;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index].jobtype,
      rate
    );

    let totalmins1 = rowsInput[index].mn1;
    let payInfo1 = getCalculatedAmtMin(
      totalmins1,
      rowsInput[index].jobtype,
      rate
    );

    rowsInput[index].payAmount = payInfo;
    rowsInput[index].payAmount1 = payInfo1;
    if (isTableView == "table") {
      checkAddedTimeError1(rowsInput, rowsInput[index], (isError) => {
        setHasError(isError);
      });
    } else {
      checkAddedTimeInnerv2(rowsInput, rowsInput[index], (isError) => {
        setHasError(isError);
      });
    }

    // const sorted = [...rowsInput].sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    let rowDate = rowsInput[index]["date"];
    console.log("rowDate aaaa", rowDate);
    if (!checkPaycodeAccess("NDIF", rowDate)) {
      console.log("check ndiffffff", rowsInput[index]);
      let id4Match = rowsInput[index]["id"];
      let jobtype4Match = rowsInput[index]["jobtype"];
      // parentIndex
      let findDataIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == id4Match && i.jobtype == "NDIF";
      });
      if (findDataIndex !== -1) {
        let items = [...rowsInput];
        let remainAray = [
          ...items.slice(0, findDataIndex),
          ...items.slice(findDataIndex + 1),
        ];
        console.log(rowsInput, "Lsit Deleeted", deletedItemIds);
        if (rowsInput[findDataIndex].ID) {
          let list = deletedItemIds;
          list.push(rowsInput[findDataIndex].ID);
          console.log("Lsit Deleeted aaa", list);
          setDeletedItemIds(list);
        }
        rowsInput = remainAray;
      }
      //   remainAray.filter(
      //   (i) => i.parentIndex !== rowsInput[index].id
      // );

      // rowsInput = [...rowsInput];
      // rowsInput = [
      //   ...rowsInput.slice(0, findDataIndex),
      //   ...rowsInput.slice(findDataIndex + 1),
      // ];

      console.log("Bricks", findDataIndex);
    }
    setRowsData(rowsInput);
    handleDateColor(rowsInput);
    handleTimeDiffCalculationV1(rowsInput);
  };
  const createRecord = (recno) => {
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }
    let recordsCopy = [...rowsData];

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });

    if (findID.length) {
      let lableCreate = `${findID[0]?.date}${findID[0]?.code}${findID[0]?.time_from}`;
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };

  const createRecord2 = (recno) => {
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }
    let recordsCopy = [...rowsData];

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });

    if (findID.length) {
      let lableCreate = `${findID[0]?.date}${findID[0]?.code}${findID[0]?.time_from1}`;
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };
  //Manoj
  const handleSaveRows = () => {
    console.log("SAve", rowsData);
    let hasError = rowsData.filter((i) => {
      return i.ole1 || i.ole2 || i.dateError;
    });

    if (hasError.length) {
      ShowError(
        "Please Verify Timesheet. It's has issue with overlapping time"
      );
      return false;
    }
    let rowdataNew = rowsData; //.filter((i) => !i.isTemplate);
    let myArray = [];
    console.log("asdfasf", rowdataNew);
    rowdataNew.map((item, index) => {
      // let recno = item.id ? item.id : "0";

      let recno = item.parentIndex ? item.parentIndex : "0";
      recno = createRecord(recno);

      let info = {
        WorkDate: item.date,
        PayCode: item.code,
        //parseInt(item.code),
        FMLA: "",
        WorkFrom: item.time_from,
        WorkTo: item.time_to,
        TotalMinutes: item.mn,
        GrossAmount: item.payAmount,
        Rate: item.Rate,
        JobOrder: item.jobOrder,
        Typh: item.jobtype,
        RecordNo: recno ? recno : "0",
        Status: item.rowstatus,
        Auth: item.authError1 ? "U" : "A",
      };
      if (item?.CreatedDate) {
        info = { ...info, CreatedDate: item["CreatedDate"] };
      }
      if (["H-5", "H-8", "H-10"].includes(item.jobtype)) {
        info["isLate"] = checkEnterTimeLate(
          templateData,
          item.time_from,
          moment(item.date).format("dddd")
        );
      }

      if (item.ID && item.ID !== undefined) {
        info["ID"] = item.ID;
        // info["RecordNo"] = item.parentIndex;
      } else {
      }

      if (
        (item.time_from && item.time_to) ||
        (item.time_from1 && item.time_to1)
      ) {
        if (!item.time_from && !item.time_to && item.ID) {
          console.log("del id", item.ID);
        }
        if (!item.time_from1 && !item.time_to1 && item.ID2) {
          console.log("del id 2", item.ID2);
        }
        if (item.time_from && item.time_to) {
          myArray.push(info);
        }

        if (item.time_from1 && item.time_to1) {
          if (
            typeof item.time_from1 == "string" &&
            item.time_from1.includes(":")
          ) {
            if (!item.ID2) {
              // let recno2 = item.parentIndex ? item.parentIndex : "0";
              // recno = createRecord(recno2); //implement as createRecord2
            }
            if (!item.ID2 && item.ID) {
              let recno2 = item.parentIndex ? item.parentIndex : "0";
              recno = createRecord2(recno2); //implement as createRecord2
            }
            if (!item.ID && item.ID2) {
              let recno2 = item.parentIndex ? item.parentIndex : "0";
              recno = createRecord(recno2); //implement as createRecord2
            }

            console.log(
              item.ID2,
              "#RNO",
              recno,
              "push3 Index",
              index,
              "@@@",
              item.parentIndex2
            );
            let info2 = {
              WorkDate: item.date,
              ID: item.ID2,
              PayCode: item.code,
              FMLA: "",
              WorkFrom: item.time_from1,
              WorkTo: item.time_to1,
              TotalMinutes: item.mn1,
              GrossAmount: item.payAmount1,
              Rate: item.Rate,
              JobOrder: item.jobOrder,
              Typh: item.jobtype,
              RecordNo: recno ? recno : "0",
              Status: item.rowstatus,
              Auth: item.authError2 ? "U" : "A",
            };
            if (item?.CreatedDate) {
              info2 = { ...info2, CreatedDate: item["CreatedDate"] };
            }

            if (["H-5", "H-8"].includes(item.jobtype)) {
              info["isLate"] = checkEnterTimeLate(
                templateData,
                item.time_from1,
                moment(item.date).format("dddd")
              );
            }
            if (!item.isNew && item.ID && item.ID !== undefined) {
              info["ID"] = item.ID;
            } else {
            }
            if (!item.isNew && item.ID2 && item.ID2 !== undefined) {
              info2["ID"] = item.ID2;
              // info2["RecordNo"] = item.parentIndex2;
            }
            myArray.push(info2);
            console.log("LogA", info2);
          } else {
            console.log("LogB");
          }
        }
      }
    });

    console.log(deletedItemIds, "asasffdf", myArray);

    if (!window.confirm("Are you sure to submit timesheet?")) {
      return false;
    }
    let notification = [];
    const findisLate = myArray.findIndex(
      (i) => ["H-5", "H-8"].includes(i.Typh) && !i.ID
    );
    if (findisLate !== -1 && getUserRole(userData) === "user") {
      let notificationObj = {
        receiverID: null,
        forID: payPeriod?.ID,
        message: `#${userInfo.ID ? userInfo.ID : ""} added HZ hours`,
        type: "HZW", // work hour late
      };
      notification.push(notificationObj);
      dispatch(createNotification({ notificationData: notification }));
    }

    //  return
    //working
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
        { workingHours: myArray, deletedList: deletedItemIds },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        loadPreData();
        // setRowsData([sampleData]);
        // alert("Timesheet added successfully")

        ShowSucess("Time Added Successfully");
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        console.log("Master responsexxxx", response.data);
        setDeletedItemIds([]);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
        setDeletedItemIds([]);
      });
    console.log("myArray", myArray);
  };

  const handleSaveRowsv2 = () => {
    console.log("SAve", rowsData);
    let hasError = rowsData.filter((i) => {
      return i.ole1 || i.ole2;
    });

    if (hasError.length) {
      ShowError(
        "Please Verify Timesheet. It's has issue with overlapping time"
      );
      return false;
    }
    let hasErrorDate = rowsData.filter((i) => {
      return i.dateError;
    });

    if (hasErrorDate.length) {
      ShowError("Please Verify Timesheet. Date not in PPE");
      return false;
    }
    let rowdataNew = rowsData; //.filter((i) => !i.isTemplate);
    let myArray = [];
    console.log("asdfasf", rowdataNew);
    rowdataNew.map((item, index) => {
      // let recno = item.id ? item.id : "0";

      let recno = item.parentIndex ? item.parentIndex : "0";
      recno = createRecord(recno);

      let info = {
        WorkDate: item.date,
        PayCode: parseInt(item.code),
        FMLA: "",
        WorkFrom: item.time_from,
        WorkTo: item.time_to,
        TotalMinutes: item.mn,
        GrossAmount: null, //item.payAmount,
        Rate: parseInt(price), //item.Rate ? item.Rate : null,
        JobOrder: item.jobOrder,
        Typh: item.jobtype,
        RecordNo: recno ? recno : "0",
        Status: item.rowstatus,
        Auth: item.authError1 ? "U" : "A",
      };
      if (item?.CreatedDate) {
        info = { ...info, CreatedDate: item["CreatedDate"] };
      }
      if (["H-5", "H-8", "H-10"].includes(item.jobtype)) {
        info["isLate"] = checkEnterTimeLate(
          templateData,
          item.time_from,
          moment(item.date).format("dddd")
        );
      }

      if (item.ID && item.ID !== undefined) {
        info["ID"] = item.ID;
        // info["RecordNo"] = item.parentIndex;
      } else {
      }

      if (item.time_from && item.time_to) {
        if (!item.time_from && !item.time_to && item.ID) {
          console.log("del id", item.ID);
        }

        if (item.time_from && item.time_to) {
          myArray.push(info);
        }
      }
    });

    console.log(deletedItemIds, "asasffdf", myArray);

    if (!window.confirm("Are you sure to submit timesheet?")) {
      return false;
    }
    let notification = [];
    const findisLate = myArray.findIndex(
      (i) => ["H-5", "H-8"].includes(i.Typh) && !i.ID
    );
    if (findisLate !== -1 && getUserRole(userData) === "user") {
      let notificationObj = {
        receiverID: null,
        forID: payPeriod?.ID,
        message: `#${userInfo.ID ? userInfo.ID : ""} added HZ hours`,
        type: "HZW", // work hour late
      };
      notification.push(notificationObj);
      dispatch(createNotification({ notificationData: notification }));
    }

    //  return
    //working
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
        { workingHours: myArray, deletedList: deletedItemIds },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        loadPreData();
        // setRowsData([sampleData]);
        // alert("Timesheet added successfully")

        ShowSucess("Time Added Successfully");
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        console.log("Master responsexxxx", response.data);
        setDeletedItemIds([]);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
        setDeletedItemIds([]);
      });
    console.log("myArray", myArray);
  };

  const hoursDiffrence = (time_from, time_to) => {
    const startTime = moment(time_from, "HH:mm");
    const endTime = moment(time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;
    return {
      hr: hours,
      minutes: minutes,
      totalMin: totalMin,
    };
  };

  const handleTimeDiffCalculationPrefillRemove = async (
    indexID,
    rowsDataArray
  ) => {
    let indexNo = indexID ? indexID : index;
    let rows_Data = rowsDataArray ? rowsDataArray : rowsData;
    console.log("indexCall night diff calc", index, "#", rows_Data[indexNo]);

    const startTime = moment(rows_Data[indexNo]?.time_from, "HH:mm");
    const endTime = moment(rows_Data[indexNo]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(rows_Data[indexNo]?.time_to, "HH:mm").add(
        1,
        "day"
      );
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    console.log(hours, "##", minutes);

    const startTime1 = moment(rows_Data[indexNo]?.time_from1, "HH:mm");
    const endTime1 = moment(rows_Data[indexNo]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rows_Data[indexNo]?.time_to1, "HH:mm").add(
        1,
        "day"
      );
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

    let hrCalc = await HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );

    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rows_Data[indexNo] };
    const rowsInput = [...rows_Data];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    console.log(rowsInput[index], "#33123# ", hrCalc, "=", hours1, totalMin1);
    rowsInput[index]["hrs"] = hrCalc; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;
    rowsInput[index]["mn"] = totalMin;
    rowsInput[index]["mn1"] = totalMin1;
    await setRowsData(rowsInput);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rows_Data[indexNo]?.time_from === "string" &&
      typeof rows_Data[indexNo]?.time_to === "string" &&
      rows_Data[indexNo]?.jobtype === "REG"
    )
      if (
        typeof rows_Data[indexNo]?.time_to === "string" &&
        rows_Data[indexNo]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", ninfo);

        if (rows_Data[indexNo]?.hjobtype !== "") {
          console.log("#LK in info", rows_Data[indexNo]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rows_Data[indexNo]?.time_from === "string" &&
      typeof rows_Data[indexNo]?.time_to === "string" &&
      typeof rows_Data[indexNo]?.time_from1 === "string" &&
      rows_Data[indexNo]?.jobtype === "REG"
    )
      if (
        typeof rows_Data[indexNo]?.time_to1 === "string" &&
        rows_Data[indexNo]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        console.log("#LK ninfo 2", ninfo);
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    if (rows_Data[indexNo]?.jobtype == "REG") {
      console.log("ntntntntntnt", rows_Data[indexNo]);
      await appendNDIFRow(nightDiffObj, rows_Data[indexNo]?.date);
    }
  };
  const handleTimeDiffCalculationPrefill = async (index, rowsData) => {
    console.log("indexCall night diff calc", index, "#", rowsData[index]);
    const startTime = moment(rowsData[index]?.time_from, "HH:mm");
    const endTime = moment(rowsData[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    const startTime1 = moment(rowsData[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsData[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;
    let hrCalc = HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rowsData[index] };
    const rowsInput = [...rowsData];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }
    rowsInput[index]["hrs"] = hrCalc; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;
    rowsInput[index]["mn"] = totalMin;
    rowsInput[index]["mn1"] = totalMin1;

    setRowsData([...rowsInput, ...saveData]);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to === "string" &&
        rowsData[index]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", rowsData[index]);

        if (rowsData[index]?.hjobtype !== "") {
          console.log("#LK in info", rowsData[index]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      typeof rowsData[index]?.time_from1 === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to1 === "string" &&
        rowsData[index]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    if (rowsData[index]?.jobtype == "REG") {
      console.log("ntntntntntnt", rowsData[index]);
      await appendNDIFRow(nightDiffObj, rowsData[index]?.date);
    }
  };

  const checkAuth = (sd, ed, cDate) => {
    console.log("ascfsaf", sd, ed);
    let isauth = "pending";
    // let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
    let shr = sd;
    let ehr = ed;
    let tData = rowAuthData.filter((i) => {
      return i.date == cDate;
    });
    console.log("adsadasdasdasdaads", rowAuthData, cDate, tData);

    console.log("Dasflkdjdmds", tData);

    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.time_from, "HH:mm:ss").format("HH:mm");
      let toTime = moment(i.time_to, "HH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };
  const handleTimeDiffCalculation = async () => {
    console.log("indexCall night diff calc", index, "#", rowsData[index]);
    const startTime = moment(rowsData[index]?.time_from, "HH:mm");
    const endTime = moment(rowsData[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    console.log(hours, "##", minutes);

    const startTime1 = moment(rowsData[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsData[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;
    let hrCalc = HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rowsData[index] };

    const rowsInput = [...rowsData];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    if (rowsInput[index]) {
      rowsInput[index]["mn"] = totalMin;
      rowsInput[index]["hrs"] = hrCalc ? hrCalc : null; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;

      rowsInput[index]["mn1"] = totalMin1;
    }

    //Check date exceed
    if (payPeriod?.EndDate == rowsInput[index]?.date) {
      if (
        (rowsInput[index]["time_to"] || rowsInput[index]["time_from"]) &&
        rowsInput[index]["time_to"] <= rowsInput[index]["time_from"]
      ) {
        rowsInput[index]["dateExceed1"] = true;
      } else {
        rowsInput[index]["dateExceed1"] = false;
      }
      if (
        (rowsInput[index]["time_to1"] || rowsInput[index]["time_from1"]) &&
        rowsInput[index]["time_to1"] <= rowsInput[index]["time_from1"]
      ) {
        rowsInput[index]["dateExceed2"] = true;
      } else {
        rowsInput[index]["dateExceed2"] = false;
      }
    } else {
      rowsInput[index]["dateExceed1"] = false;
      rowsInput[index]["dateExceed2"] = false;
      let rowId = rowsInput[index]["id"];
      // rowsInput.filter((i) => { i.parentIndex == rowsInput[ index ][ "Id" ] });
      let matchingRecord = rowsInput.filter((row) => {
        return row.parentIndex === rowId;
      });
      let list = [...deletedItemIds];
      matchingRecord.map((i) => {
        if (i.ID) {
          // list.push(parseInt(i.ID));
        }
        let childIndex = rowsInput.findIndex(
          (row) => row.parentIndex === rowId && i.jobtype == "NDIF"
        );
        if (childIndex !== -1) {
          rowsInput.splice(childIndex, 1);
        }
      });
    }
    let rate = getPPERateByDate(amountArray, rowsInput[index]?.date, price);
    console.log("userInfodswd", userInfo);
    if (
      userInfo?.DPDV == "2330A" &&
      rowsInput[index].jobtype == "REG"
      // && userInfo?.Nposition_No == "9998033105"
    ) {
      // GBHWC department
      const checkGBHWC = checkRuleNoneStandardRateGBHWC(
        rowsInput[index],
        rowsInput,
        rate
      );

      rate = checkGBHWC;
    }

    if (
      userInfo?.DPDV == "4202" &&
      rowsInput[index].jobtype == "REG"
      // && userInfo?.Nposition_No == "9998033105"
    ) {
      // GFD department

      const checkGFD = checkRuleNoneStandardRateGFD(rate, rowsInput);
      rate = checkGFD;
      console.log("Rate GFDDDD", checkGFD);
    }

    if (
      userInfo?.DPDV == "3200" &&
      rowsInput[index].jobtype == "REG"
      // && userInfo?.Nposition_No == "9998033105"
    ) {
      // GFD department

      const sameDateRecords = rowsInput.filter(
        (i) => i.date == rowsInput[index].date
      );
      let smaeDateRate = rate;
      sameDateRecords.map((item) => {
        let sameDataIndex = rowsInput.findIndex((i) => i.id == item.id);

        const checkCQA = checkRuleNoneStandardRateCQA(
          rowsInput[sameDataIndex],
          rowsInput,
          smaeDateRate
        );
        rate = checkCQA;
        console.log(
          smaeDateRate,
          "sameDataIndex",
          sameDataIndex,
          "Rate=>",
          rate
        );
        rowsInput[sameDataIndex].Rate = rate;
        let totalmins = rowsInput[sameDataIndex]?.mn;
        let payInfo = getCalculatedAmtMin(
          totalmins,
          rowsInput[sameDataIndex]?.jobtype,
          rate
        );
        rowsInput[sameDataIndex].payAmount = payInfo;

        let totalmins1 = rowsInput[sameDataIndex]?.mn1;
        let payInfo1 = getCalculatedAmtMin(
          totalmins1,
          rowsInput[sameDataIndex]?.jobtype,
          rate
        );
        rowsInput[sameDataIndex].payAmount1 = payInfo1;
        //   rowsInput[sameDataIndex].payAmount = payInfo;
      });

      // const checkCQA = checkRuleNoneStandardRateCQA(rowsInput[index], rowsInput, rate);
      //   rate = checkCQA;
      // console.log("Rate sameDateRecords", checkCQA);
    }

    rowsInput[index].Rate = rate;
    let totalmins = rowsInput[index]?.mn;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount = payInfo;

    let totalmins1 = rowsInput[index]?.mn1;
    let payInfo1 = getCalculatedAmtMin(
      totalmins1,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount1 = payInfo1;
    if (
      rowsInput[index].jobtype == "S/L" ||
      rowsInput[index].jobtype == "A/L"
    ) {
      //This check emp yr experiace and get leave
      const diffInYears = GetLeaveAccureByExperiance(
        userInfo?.EmployeementDate,
        leaveHours
      );
      const TotalMinSick = diffInYears.sick * 60;
      const TotalMinAnnual = diffInYears.annual * 60;
      console.log(
        "TotalLeaves",
        "Sick = ",
        TotalMinSick,
        "Annual => ",
        TotalMinAnnual
      );

      let sumAL = 0;
      let sumSL = 0;
      rowsInput.forEach((data) => {
        if (data.jobtype == "A/L") {
          sumAL += data.mn;
          sumAL += data.mn1;
        } else if (data.jobtype == "S/L") {
          sumSL += data.mn;
          sumSL += data.mn1;
        } else {
          sumSL += 0;
          sumAL += 0;
        }
      });

      setMaxSLLeave(TotalMinSick);
      setMaxALLeave(TotalMinAnnual);

      if (rowsInput[index].jobtype == "S/L" && sumSL > TotalMinSick) {
        rowsInput[index]["leaveHoursSL"] = true;
      } else if (rowsInput[index].jobtype == "A/L" && sumAL > TotalMinAnnual) {
        rowsInput[index]["leaveHoursAL"] = true;
      } else {
        rowsInput[index]["leaveHoursAL"] = false;
        rowsInput[index]["leaveHoursSL"] = false;
      }
    } else {
      rowsInput[index]["leaveHoursAL"] = false;
      rowsInput[index]["leaveHoursSL"] = false;
    }
    // end of check date exceed

    if (rowAuthData.length && rowsInput[index].jobtype == "REG") {
      const checkData1 = checkAuth(
        rowsInput[index].time_from,
        rowsInput[index].time_to,
        rowsInput[index].date
      );
      const checkData2 = checkAuth(
        rowsInput[index].time_from1,
        rowsInput[index].time_to1,
        rowsInput[index].date
      );

      if (checkData1 && rowsInput[index].time_to) {
        if (checkData1.status === "confirm") {
          rowsInput[index]["authError1"] = false;
        } else {
          rowsInput[index]["authError1"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
      if (checkData2 && rowsInput[index].time_to1) {
        if (checkData2.status === "confirm") {
          rowsInput[index]["authError2"] = false;
        } else {
          rowsInput[index]["authError2"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
    }

    setRowsData(rowsInput);

    if (
      userInfo?.DPDV == "1210"
      // && userInfo?.Nposition_No == "9998033105"
    ) {
      // GPD = Guam Police department Rule = 57

      if (rowsInput[index].jobtype == "REG") {
        handleChangeHizard(index, {
          value: "H-10",
          code: "12",
        });
      } else if (isOnLeave(rowsInput)) {
        let data = rowsInput.filter((i) => !["H-10"].includes(i.jobtype));
        console.log("isOnLeave Data", data);
        setRowsData(data);
      }
    }
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to === "string" &&
        rowsData[index]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", rowsData[index]);

        if (rowsData[index]?.hjobtype !== "") {
          console.log("#LK in info", rowsData[index]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      typeof rowsData[index]?.time_from1 === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to1 === "string" &&
        rowsData[index]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    if (rowsData[index]?.jobtype == "REG") {
      console.log("ntntntntntnt 22222", rowsData[index]);
      await appendNDIFRow(nightDiffObj, rowsData[index]?.date);
    }
    // add To Next Payperiod dipenBhai

    // if (rowsData[index].time_from && rowsData[index].time_to) {
    //   let fr = rowsData[index].time_from.split(":")[0];
    //   let to = rowsData[index].time_to.split(":")[0];
    //   let fr1 = rowsData[index].time_from1.split(":")[0];
    //   let to1 = rowsData[index].time_to1.split(":")[0];
    //   console.log(
    //     "121212 before condition ::: ",
    //     payPeriod,
    //     rowsData[index].date,
    //     to
    //   );
    //   if (rowsData[index].date === payPeriod.EndDate) {
    //     if (
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from) ||
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from)
    //     ) {
    //       console.log(
    //         "121212 in condition  ::: ",
    //         rowsData[index].time_from,
    //         rowsData[index].time_to
    //       );
    //       addTableRowForNextDay(index);
    //     }
    //   }
    // }
  };

  const splitTimeRange = (startTime, endTime, date) => {
    if (!startTime && !endTime && !date) {
      return false;
    }
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);
    const newDate = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");

    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);

    const timeRanges = [];

    if (endDate < startDate && endTime !== "00:00") {
      const midnight = new Date(startDate);
      midnight.setHours(24, 0);

      // timeRanges.push({
      //   date:newDate,
      //   startTime: startTime,
      //   endTime: midnight.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      // });

      timeRanges.push({
        startTime: "00:00",
        endTime: endTime,
        date: newDate,
      });
    }
    // else {
    //   timeRanges.push({
    //     // date: date,
    //     startTime: startTime,
    //     endTime: endTime
    //   });
    // }

    return timeRanges;
  };

  const handleTimeDiffCalculationV1 = async (rowsData) => {
    console.log("indexCall night diff calc", index, "#", rowsData[index]);
    const startTime = moment(rowsData[index]?.time_from, "HH:mm");
    const endTime = moment(rowsData[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    console.log(hours, "##", minutes);

    const startTime1 = moment(rowsData[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsData[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;
    let hrCalc = HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rowsData[index] };

    const rowsInput = [...rowsData];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    if (rowsInput[index]) {
      rowsInput[index]["mn"] = totalMin;
      rowsInput[index]["hrs"] = hrCalc ? hrCalc : null; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;
      rowsInput[index]["mn1"] = totalMin1;
    }

    console.log("amountArray =>", amountArray, rowsInput[index]?.date, price);
    console.log("amountArray2 =>", amountArray, rowsInput[index], price);

    let rate = await getPPERateByDate(
      amountArray,
      rowsInput[index]?.date,
      price
    );
    rowsInput[index].Rate = rate;
    let totalmins = rowsInput[index]?.mn;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount = payInfo;
    let totalmins1 = rowsInput[index]?.mn1;
    let payInfo1 = getCalculatedAmtMin(
      totalmins1,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount1 = payInfo1;

    if (rowAuthData.length) {
      const checkData1 = checkAuth(
        rowsInput[index].time_from,
        rowsInput[index].time_to,
        rowsInput[index].date
      );
      const checkData2 = checkAuth(
        rowsInput[index].time_from1,
        rowsInput[index].time_to1,
        rowsInput[index].date
      );

      if (checkData1 && rowsInput[index].time_to) {
        if (checkData1.status === "confirm") {
          rowsInput[index]["authError1"] = false;
        } else {
          rowsInput[index]["authError1"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
      if (checkData2 && rowsInput[index].time_to1) {
        if (checkData2.status === "confirm") {
          rowsInput[index]["authError2"] = false;
        } else {
          rowsInput[index]["authError2"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
    }

    const spiltAfter12Range1 = splitTimeRange(
      rowsInput[index]?.time_from,
      rowsInput[index]?.time_to,
      rowsInput[index]?.date
    );
    let spiltAfter12Range2 = [];
    if (rowsInput[index]?.time_from1 && rowsInput[index]?.time_to1) {
      spiltAfter12Range2 = splitTimeRange(
        rowsInput[index]?.time_from1,
        rowsInput[index]?.time_to1,
        rowsInput[index]?.date
      );
    }

    if (spiltAfter12Range1.length) {
      if (
        rowsInput.filter((item) => item.parentID1 === rowsInput[index].id)
          .length
      ) {
        setRowsData(
          rowsInput.map((item) => {
            if (item.parentID1 === rowsInput[index].id) {
              return {
                ...item,
                date: spiltAfter12Range1[0].date,
                time_from: spiltAfter12Range1[0].startTime,
                time_to: spiltAfter12Range1[0].endTime,
              };
            } else {
              return item;
            }
          })
        );
        // rowsInput[index].time_to = "00:00"
      } else {
        spiltAfter12Range1.map((i) => {
          if (i.date && rowsInput[index].id) {
            let mins = hoursDiffrence(i.startTime, i.endTime)?.totalMin;

            let hrCalc = HourMinFormat(`${Math.floor(mins / 60)}:${mins % 60}`);

            let mn = hoursDiffrence(
              rowsInput[index].time_from,
              "00:00"
            )?.totalMin;
            let mn1 = hoursDiffrence(
              rowsInput[index].time_from1,
              rowsInput[index].time_to1
            )?.totalMin;
            let hrCalc1 = HourMinFormat(
              `${Math.floor((mn + mn1) / 60)}:${(mn + mn1) % 60}`
            );
            let createObject = sampleData;
            createObject["date"] = i.date;
            createObject["parentID1"] = rowsInput[index].id;
            createObject["time_from"] = i.startTime;
            createObject["time_to"] = i.endTime;
            createObject["hrs"] = hrCalc;
            createObject["mn"] = mins;

            // rowsInput.push(createObject);

            rowsInput.splice(index + 1, 0, createObject);

            rowsInput[index]["time_to"] = "00:00";
            rowsInput[index]["hrs"] = hrCalc1;
            rowsInput[index]["mn"] = mn;
          }
        });
        setRowsData(rowsInput);
      }
    } else {
      setRowsData(
        rowsInput.filter((item) => item.parentID1 !== rowsInput[index].id)
      );
    }

    if (spiltAfter12Range2.length) {
      if (
        rowsInput.filter((item) => item.parentID2 === rowsInput[index].id)
          .length
      ) {
        setRowsData(
          rowsInput.map((item) => {
            if (item.parentID2 === rowsInput[index].id) {
              return {
                ...item,
                date: spiltAfter12Range2[0].date,
                time_from: spiltAfter12Range2[0].startTime,
                time_to: spiltAfter12Range2[0].endTime,
              };
            } else {
              return item;
            }
          })
        );
        // rowsInput[index].time_to = "00:00"
      } else {
        spiltAfter12Range2.map((i) => {
          if (i.date && rowsInput[index].id) {
            let mins = hoursDiffrence(i.startTime, i.endTime)?.totalMin;
            let hrCalc = HourMinFormat(`${Math.floor(mins / 60)}:${mins % 60}`);
            let mn1 = hoursDiffrence(
              rowsInput[index].time_from1,
              "00:00"
            )?.totalMin;
            let mn = hoursDiffrence(
              rowsInput[index].time_from,
              rowsInput[index].time_to
            )?.totalMin;
            let hrCalc1 = HourMinFormat(
              `${Math.floor((mn + mn1) / 60)}:${(mn + mn1) % 60}`
            );
            let createObject = sampleData;
            createObject["date"] = i.date;
            createObject["parentID2"] = rowsInput[index].id;
            createObject["time_from"] = i.startTime;
            createObject["time_to"] = i.endTime;
            createObject["mn"] = mins;

            createObject["hrs"] = hrCalc;

            rowsInput.push(createObject);
            rowsInput[index]["time_to1"] = "00:00";
            rowsInput[index]["hrs"] = hrCalc1;
            rowsInput[index]["mn1"] = mn1;
          }
        });
        setRowsData(rowsInput);
      }
    } else {
      setRowsData(
        rowsInput.filter((item) => item.parentID2 !== rowsInput[index].id)
      );
    }

    let findIDS = rowsInput.find((i) => i.parentID1 !== rowsInput[index].id);
    console.log("findISDSds ", findIDS, rowsInput);
    if (findIDS) {
      if (moment(findIDS.time_to, "HH:mm") >= moment("00:00", "HH:mm")) {
        console.log("fadsfdsfffasf 12 up");
      } else {
        console.log("fadsfdsfffasf 12 down");
      }
    }
  };

  const getpayCode = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    let getcode = paytimeCode.find((i) => {
      return i.label == acode;
    });

    return getcode.code ? parseInt(getcode.code) : "";
  };

  const checkPaycodeAccess = (type, date) => {
    let paycodeList = payCodeFilter(
      paytimeCode,
      paytimeCodeByID,
      getUserRole(userData)
    );

    let validateNDIF = getValidPaycodeOfDay(date, paycodeList);
    console.log("paycodeList 123", paycodeList, type, validateNDIF);
    if (type == "NDIF") {
      let filter = validateNDIF?.filter((i) => i.label === type);
      console.log("filter paylist", filter);
      return filter.length ? true : false;
    } else {
      return false;
    }
  };
  const appendNDIFRow2 = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }
          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }
          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (!rowsInput[existAtIndex]?.ID && !rowsInput[existAtIndex]?.ID2) {
            console.log("#NDIF 4 111 delete", rowsInput);

            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          JSON.stringify(nightObj.firstShift),
          "#",
          JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          console.log("#NDIF BOTHEXIST ", infos);
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          let totalmins1 = info.totalminutes;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);

          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);
          let info = nightObj.firstInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }

          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }

          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = 0;
          newobj["payAmount1"] = payInfo;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);

        setRowsData([...myNew]);

        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          let totalmins1 = info.totalminutes;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;
          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (nightObj.firstShift) {
          let info = nightObj.firstInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("#mj log B ff");

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: null,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  const appendNDIFRow = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }
          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }
          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (
            !rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.jobtype == "NDIF"
          ) {
            console.log("#NDIF 4 111 delete", rowsInput);

            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          JSON.stringify(nightObj.firstShift),
          "#",
          JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          console.log("#NDIF BOTHEXIST ", infos);

          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          let totalmins1 = info.totalminutes;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);

          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }
          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          let info = nightObj.firstInfo;

          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          console.log("#NDIF Second ", newobj);
          console.log("#NDIF OLD", oldDataCopy);
          console.log("#NDIF Second info", info);
          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);

          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }
          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount1"] = payInfo;
          newobj["payAmount"] = 0;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);

        setRowsData([...myNew]);

        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let info = nightObj.secondInfo;
          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;

          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          let totalmins1 = info.totalminutes;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (nightObj.firstShift) {
          console.log("#mj log B ff");
          let info = nightObj.firstInfo;

          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: null,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  const appendNDIFRow3 = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }

          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }

          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (
            !rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.ID2 &&
            rowsInput[existAtIndex]?.jobtype === "NDIF"
          ) {
            console.log("#NDIF 4 111 delete 123", rowsInput);
            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          JSON.stringify(nightObj.firstShift),
          "#",
          JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          let totalmins1 = info.totalminutes;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);
          console.log("adsfjadksf 0", rate, payInfo, newobj);

          console.log("#NDIF BOTHEXIST ", infos);
          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;

          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }
          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          let info = nightObj.firstInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 1", rate, payInfo, newobj);
          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          console.log("#NDIF Second ", newobj);
          console.log("#NDIF OLD", oldDataCopy);
          console.log("#NDIF Second info", info);
          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 2", rate, payInfo, newobj);

          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount1"] = payInfo;
          newobj["payAmount"] = 0;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);
        setRowsData([...myNew]);
        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let info = nightObj.secondInfo;
          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = newobj.mn;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          let totalmins1 = newobj.mn1;
          let payInfo1 = getCalculatedAmtMin(totalmins1, newobj.jobtype, rate);
          console.log("adsfjadksf 3", rate, payInfo, newobj);

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = payInfo1;
        } else if (nightObj.firstShift) {
          console.log("#mj log B ff");
          let info = nightObj.firstInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 4", rate, payInfo, newobj);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getPPERateByDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 5", rate, payInfo, newobj);

          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
          newobj["payAmount1"] = 0;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: null,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  const nightShiftTimeCheck = (shift) => {
    let startTime = moment(rowsData[index]?.time_from, "HH:mm");
    let endTime = moment(rowsData[index]?.time_to, "HH:mm");
    if (shift !== "first") {
      startTime = moment(rowsData[index]?.time_from1, "HH:mm");
      endTime = moment(rowsData[index]?.time_to1, "HH:mm");
    }

    let nightStart = moment("18:00", "HH:mm");
    let nightEnd = moment("06:00", "HH:mm");
    let isNight = true;
    let totalminutes = 0;
    let totalHr = 0;
    let nightTime = {};
    if (
      (moment(startTime, "HH:mm") > nightStart &&
        moment(startTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(startTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(startTime, "HH:mm") < moment("06:00", "HH:mm")) ||
      (moment(endTime, "HH:mm") > moment("18:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(endTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("06:00", "HH:mm")) ||
      (moment(startTime, "HH:mm") > moment(endTime, "HH:mm") &&
        moment(endTime, "HH:mm") >= moment("06:00", "HH:mm"))
    ) {
      if (startTime >= endTime) {
        if (startTime >= endTime && startTime <= nightEnd) {
          let durationBefore = moment.duration(nightEnd.diff(startTime));
          console.log("#mj HHBefore", durationBefore.asHours());
        }
        console.log("#mj Contains ");
        nightStart = nightStart >= startTime ? nightStart : startTime;
        let duration = moment.duration(
          moment("24:00", "HH:mm").diff(nightStart)
        );
        totalminutes = totalminutes + parseInt(duration.asMinutes());
        totalHr = totalHr + parseInt(duration.asHours());

        if (endTime >= nightEnd) {
          endTime = nightEnd;
        }
        let durationAfter = moment.duration(
          endTime.diff(moment("00:00", "HH:mm"))
        );
        totalminutes = totalminutes + parseInt(durationAfter.asMinutes());
        totalHr = totalHr + parseInt(durationAfter.asHours());

        console.log("#mj totalminutes", totalminutes);
        nightTime["start"] = nightStart;
        nightTime["end"] = endTime;
      } else {
        if (startTime <= endTime) {
          //  let sectionA = endTime >= nightStart ? endTime: nightStart ;
          // console.log(endTime,sectionA,'#mj sAAA',startTime);
          if (startTime <= nightEnd && endTime <= nightEnd) {
            let duration = moment.duration(endTime.diff(startTime));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

            console.log("#mj HHAA#", duration.asHours());
            nightTime["start"] = startTime;
            nightTime["end"] = endTime;
          }
          if (startTime <= nightEnd && endTime > nightEnd) {
            let duration = moment.duration(nightEnd.diff(startTime));
            console.log("#mj HHAA", duration.asHours());
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());
            nightTime["start"] = startTime;
            nightTime["end"] = nightEnd;
          }

          if (startTime <= nightEnd && endTime > nightStart) {
            /// equal removed
            let duration = moment.duration(endTime.diff(nightStart));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

            console.log("#mj HHAA11", duration.asHours());
            nightTime["start"] = nightStart;
            nightTime["end"] = endTime;
          } else {
            if (endTime > nightStart && startTime < endTime) {
              if (startTime >= nightStart) {
                nightStart = startTime;
              }
              let duration = moment.duration(endTime.diff(nightStart));

              totalminutes = totalminutes + parseInt(duration.asMinutes());
              totalHr = totalHr + parseInt(duration.asHours());
              console.log(
                endTime,
                "@@",
                startTime,
                "#mj Plain",
                duration.asHours()
              );
              nightTime["start"] = nightStart;
              nightTime["end"] = endTime;
            }
          }
        } else {
          nightStart = nightStart >= startTime ? nightStart : startTime;
          let duration = moment.duration(endTime.diff(nightStart));
          console.log("#mj HHBB", duration.asHours());
          totalminutes = totalminutes + parseInt(duration.asMinutes());
          totalHr = totalHr + parseInt(duration.asHours());
        }

        /*
        if (startTime <= endTime) {
          nightStart = startTime;
        } else {
          nightStart = nightStart >= startTime?nightStart:startTime;
        }

        endTime = endTime >= nightEnd ? nightEnd:endTime;
       
        let duration = moment.duration(endTime.diff(nightStart));
        if (endTime >= nightStart) {
          let durationNext = moment.duration(endTime.diff(nightStart));
          console.log('#mj HH$',durationNext.asHours());
        } */
      }

      // if (duration.asHours() < 0) {
      //   const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      //   duration1 = moment.duration(endTime3.diff(startTime1));
      // }
      console.log(
        "#mj night diff",
        moment(nightTime.start).format("HH:mm"),
        ":",
        moment(nightTime.end).format("HH:mm")
      );
      console.log("#mj totalminutes", totalminutes);
      console.log("#mj totalhours", floor(totalminutes / 60));
      console.log("#mj totalhours mm", totalminutes % 60);
      let info = {
        start: moment(nightTime.start).format("HH:mm"),
        end: moment(nightTime.end).format("HH:mm"),
        hours: floor(totalminutes / 60),
        minutes: totalminutes % 60,
        totalminutes: totalminutes,
      };
      // appendNDIFRow(shift,info)
      return { status: true, shift: shift, info: info };
    } else {
      console.log("#mj No TdFFF", shift);
      // appendNDIFRow(`clear_${shift}`,{})
      isNight = false;
      return { status: false, shift: shift, info: {} };
    }
  };

  const handleSetHzToHtype = async (arrayInput) => {
    // console.log('taget arrayInput',arrayInput);
    console.log("cdsfsdfdsfds");
    let paytime = paytimeCode.filter((item) => {
      return item.code >= 10 && item.code <= 12;
    });
    let arrayHtype = paytime.map((i) => {
      return i.value;
    });
    let targetItem = arrayInput.filter((i) => {
      return arrayHtype.includes(i.jobtype);
    });

    console.log("taget index", targetItem);

    // if (targetItem.length) {
    //   targetItem.map((j) => {
    //     let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
    //     console.log('taget findAtIndex',index);
    //     arrayInput[ index ][ 'hjobtype' ] = j.jobtype;
    //   })
    // }
    if (targetItem.length) {
      targetItem.map((j) => {
        // let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
        let index = arrayInput.findIndex((row) => row.id == j.id);
        console.log("taget findAtIndex", index);
        // arrayInput[index]["hjobtype"] = j.jobtype;
        arrayInput[index]["jobtype"] = j.jobtype;
      });
    }

    console.log("targetItem", arrayInput);
    return arrayInput;
  };

  useEffect(() => {
    if (isLoadTimesheetData) {
      loadPreData();
    }
  }, [isLoadTimesheetData]);

  //Dipen
  const loadPreData = async (type) => {
    let sdt = payPeriod && payPeriod?.StartDate;
    let edt = payPeriod && payPeriod?.EndDate;
    setIsLoading(true);
    console.log("isLoading===", isloading);
    setIsLoadTimesheetData(false);

    if (sdt === undefined || edt === undefined) {
      ///|| check === false uncommented
      return false;
    }
    console.log("currentSelectedPPEIndex 22222", userId);
    // get on page load
    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee/timelog/${userId}/from/${sdt}/to/${edt}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("Master response aas 121212 ", response.data);

        let createObj = [];
        if (response.data.length) {
          let prevdata = response.data;

          const sorted = prevdata.sort(
            (a, b) =>
              Date.parse(a.WorkDate) - Date.parse(b.WorkDate) ||
              Date.parse(a.WorkFrom) - Date.parse(b.WorkFrom)
          );
          setOriginalData(sorted);

          // console.log("prevdata--", prevdata);

          // dispatch({
          //   type: "EmployeeProcessPayTimes",
          //   payload: { EmployeeProcessPayTimes: prevdata },
          // });

          let EmployeeProcessPayTimes = sorted;

          if (EmployeeProcessPayTimes.length) {
            let newArray = [];
            let assignData = [];
            let skip = false;
            EmployeeProcessPayTimes.map(async (i, index) => {
              // const rate = getPPERateByDate(
              //   amountArray,
              //   moment(i.WorkDate).format("YYYY-MM-DD"),
              //   price
              // );
              const rate = i.Rate;
              let totalMins = i.TotalMinutes;

              let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
              // newobj[ 'time_from1' ]  = info.start;
              // newobj[ 'time_to1' ]  = info.end;
              // newobj[ 'hrs' ] = HourMinFormat(hrCalc);

              let lableid = `${moment(i.WorkDate).format("YYYY-MM-DD")}${
                i.PayCode
              }${d2Time(i.WorkFrom)}`;
              lableid = RemoveSpaceKeyGen(lableid);
              console.log("acasasav 2", price);

              console.log(
                "Dadtdadvadhasdvd",
                i.WorkDate,
                "Moment Date",
                moment(i.WorkDate).utc().format("YYYY-MM-DD")
              );

              let payInfo = getCalculatedAmtMin(
                i.TotalMinutes,
                i.PayCode_PayTimeCode ? i.PayCode_PayTimeCode.Abberv : "",
                rate
              );
              let assign1 = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : null,
                ID: i.ID,
                id: lableid,
                date: moment(i.WorkDate).utc().format("YYYY-MM-DD"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: parseFloat(i.GrossAmount),
                payAmount1: 0,
                isNew: true,
                mn: i.TotalMinutes,
                mn1: 0,
                hrs: HourMinFormat(hrCalc),
                jobOrder: i.JobOrder,
                code: i.PayCode ? i.PayCode : null,
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                CreatedDate: i.CreatedDate,
                ApproveFromTime: i.ApproveFromTime
                  ? moment(i.ApproveFromTime).utc().format("HH:mm")
                  : null,
                ApproveToTime: i.ApproveToTime
                  ? moment(i.ApproveToTime).utc().format("HH:mm")
                  : null,
                verify: i.verify,
                Status: i?.Status,
                approvedStatus: i?.ApproveStatus,
              };

              let assign = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : null,
                ID: i.ID,
                id: lableid,
                date: moment(i.WorkDate).utc().format("YYYY-MM-DD"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: parseFloat(i.GrossAmount),
                payAmount1: 0,
                isNew: true,
                mn: i.TotalMinutes,
                mn1: 0,
                hrs: HourMinFormat(hrCalc),
                jobOrder: i.JobOrder,
                code: i.PayCode ? i.PayCode : null,
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                CreatedDate: i.CreatedDate,
                ApproveFromTime: i.ApproveFromTime
                  ? moment(i.ApproveFromTime).utc().format("HH:mm")
                  : null,
                ApproveToTime: i.ApproveToTime
                  ? moment(i.ApproveToTime).utc().format("HH:mm")
                  : null,
                verify: i.verify,
                Status: i?.Status,
                approvedStatus: i?.ApproveStatus,
              };

              assignData.push(assign1);
              console.log(index, "@##", EmployeeProcessPayTimes.length);
              if (
                index + 1 < EmployeeProcessPayTimes.length &&
                i.PayCode == EmployeeProcessPayTimes[index + 1]["PayCode"] &&
                moment(i.WorkDate).utc().format("YYYY-MM-DD") ==
                  moment(EmployeeProcessPayTimes[index + 1]["WorkDate"])
                    .utc()
                    .format("YYYY-MM-DD") &&
                moment(i.CreatedDate).utc().format("YYYY-MM-DD HH:mm:ss") ==
                  moment(EmployeeProcessPayTimes[index + 1]["CreatedDate"])
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss") &&
                i.code == EmployeeProcessPayTimes[index + 1]["code"]
              ) {
                skip = isTableView == "table" ? true : false;

                assign.ID2 = EmployeeProcessPayTimes[index + 1]["ID"];

                assign.time_from1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkFrom"]
                );
                assign.time_to1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkTo"]
                );
                assign.isNew = false;

                assign.mn1 = parseInt(
                  EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                );
                assign.time_to_set = true;
                assign.parentIndex2 =
                  EmployeeProcessPayTimes[index + 1]["RecordNo"];
                assign.hrs = HourMinFormat(
                  `${Math.floor(
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) /
                      60
                  )}:${
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) %
                    60
                  }`
                );
                assign.rowstatus = i.ConfirmStatus;
                assign.payAmount1 = parseFloat(
                  EmployeeProcessPayTimes[index + 1]["GrossAmount"]
                );
                // assign.hrs = HourFormat(parseInt(i.TotalHours)+parseInt(EmployeeProcessPayTimes[index+1]['TotalHours']));
                newArray.push(assign);
                console.log(
                  index,
                  "@## same date created",
                  i.TotalHours,
                  "@##---",
                  EmployeeProcessPayTimes[index + 1]["WorkTo"],
                  "###",
                  EmployeeProcessPayTimes.length
                );
              } else {
                if (!skip) {
                  newArray.push(assign);
                }
                skip = false;
              }
            });
            setIsLoading(false);

            let refindArray = await handleSetHzToHtype(newArray);
            console.log("refindArray", refindArray);
            const sorted = [...refindArray].sort(
              (a, b) => Date.parse(a.date) - Date.parse(b.date)
            );
            console.log("sorteddasd", sorted);
            handleDateColor(sorted);
            setSaveData(sorted);

            let mergedArray = [];
            if (isTableView == "table") {
              mergedArray = [...refindArray, ...rowAuthData];
            } else {
              mergedArray = assignData;
            }
            console.log("saaddasffasfdsfdsf", assignData);
            setRowsData(mergedArray);
          } else {
            setIsLoading(false);
            setRowsData([]);
          }
        } else {
          setIsLoading(false);
          setRowsData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };

  // useEffect(() => {
  //   console.log("EmployeeProcessPayTimes", EmployeeProcessPayTimes);
  //   // loadPreData()
  // }, [EmployeeProcessPayTimes]);
  /** 
  useEffect(() => {
    // if (!isloading) {
    //   return false
    // }
     

    console.log('PreviTimesheet ppe',prevPPE);
    

      let sdt = prevPPE && prevPPE.StartDate;
      let edt = prevPPE && prevPPE.EndDate;

      let a = moment(sdt);
      let b = moment(edt);

      let preFilledArray = [];
      let tempindex = 0;
    for (let m = moment(a); m.isBefore(b); m.add(1, "days")) {
      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");
       
      console.log("PreviTimesheet", dateName,dayName);

      let createObject = {
        jobtype: "REG",
        id: KeyGen(7),
        date: dateName,
        // time_from: timePre[ 0 ]?.[ "startTime" ],
        // time_to: timePre[ 0 ]?.[ "endTime" ],
        // time_from1: timePre[ 1 ]?.[ "startTime" ],
        // time_to1: timePre[ 1 ]?.[ "endTime" ],
        hrs: "00:00",
        mn: 0,
        mn1: 0,
        code: null,
        jobOrder: null,
        hjobtype: "",
        rowstatus: "Confirm",
        time_to_set: true,
        ole1: false,
        ole2: false,
      };
     
    
      } //end of loop
      console.log("Instaz", preFilledArray);


  },[isloading]);
*/

  useEffect(() => {
    console.log("Ms good");
    if (templateID && templateTitle) {
      renderProfileData();
    }

    if (payPeriod && userId && price > 0) {
      setRowsData([]);
      setTimeout(() => {
        loadPreData("AAA");
      }, 3000);
    }
    // getTimeSheetTemplateByID();
    // if (templateID) {
    //   // renderProfileData(); // update for change te
    //   // handlePrefillTimesSlot(templateData);
    // }

    // if (check) {

    // }
  }, [payPeriod, userId, price > 0, isTableView]);

  useEffect(() => {
    if (authTemplate && authTemplate?.TemplateID) {
      renderProfileData();
    }
  }, [authTemplate?.TemplateID]);

  const getPrefillTimesSlot = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}advancesearch/prefillhours`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // setPreDayHours(response.data.result);
        console.log("Master responsexxxx", response.data.result);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  // useEffect(() => {
  //    setDeletedItemIds([]);
  // }, []);

  const confimDeleteClick = () => {
    deleteTableRows(deleteRowIndex);
    setOpen(false);
  };
  const handlePrefillTimesSlot = (arrSlot) => {
    let tempArray = [];
    arrSlot.map((i) => {
      tempArray.push({
        id: i.ID,
        dayName: i.Day,
        startTime: i.From,
        endTime: i.To,
      });
    });
    console.log("tempArray", tempArray);

    // setPreDayHours(tempArray);
    setRowsData([]);
    setTimeout(() => {
      handlePrefill(true, tempArray);
    }, 1000);
  };

  const handlePrefillTimesSlot1 = (arrSlot) => {
    let tempArray = [];
    arrSlot.map((i) => {
      tempArray.push({
        id: i.ID,
        dayName: i.Day,
        startTime: i.From,
        endTime: i.To,
      });
    });
    console.log("tempArray", tempArray);

    // setPreDayHours(tempArray);
    // setRowsData([]);
    setTimeout(() => {
      getAuthHoursData(true, tempArray);
    }, 1000);
  };
  useEffect(() => {
    console.log("Go 4 Update timeTo", view);
    // handleTimeDiffAll();
    // handleTimeDiff();
    // if (isTableView == "timeSheetv1") {
    handleTimeDiffCalculationV1(rowsData);
    // } else {
    // handleTimeDiffCalculation();
    // }

    let contactArray = rowsData;
    // let contactArray = [{ ...sampleData }].concat([...rowsData]);

    // console.log("contactArray 123",contactArray);

    // const data = contactArray?.map((i) => {
    //   let wd = moment(i.date).format("YYYY-MM-DD");
    //   let stdate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_from,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';
    //   let eddate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_to,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';

    //   console.log("stdate eddate",stdate,eddate );

    //   return {
    //     ID: i.id,
    //     start:  stdate,   //moment(i.WorkFrom).format("YYYY-MM-DDTHH:mm:ss"),
    //     end:  eddate,  // moment(i.WorkTo).format("YYYY-MM-DDTHH:mm:ss"),
    //     text: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //     paycode: i.code,

    //     paytype:  i.jobtype,

    //     indexNo:   parseFloat(moment().format("YYMMDDHHmmss")),
    //     barColor: getStatusColorCode(i.rowstatus),
    //     backColor:  getPPEColorCode(i.jobtype),

    //     // JobOrder: i.JobOrder,
    //     authStatus:"Confirm",
    //     bubbleHtml: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //       TotalHours:diifrenceInMinutes(stdate, eddate),
    //       WorkDate:i.date,
    //       WorkFrom:  stdate ,
    //       WorkTo:   eddate,
    //       PayCode_PayTimeCode:{
    //         ID:i.code,
    //         Abberv:i.jobtype
    //       }

    //   };

    // });
    // console.log("data fia 123456", data);

    // setOriginalData(data)
  }, [timeTo]);

  useEffect(() => {
    setHoursWorked();
  }, [rowsData]);

  const [viewModal, setViewModal] = useState(false);

  const filterRowData = (fData, radioData, payPeriod) => {
    // const sorted = fData.sort(
    //   (a, b) => Date.parse(a.date) - Date.parse(b.date)
    //   || Date.parse(`1970-01-01T${a.time_from}`) - Date.parse(`1970-01-01T${b.time_from}`)
    // );
    // console.log("fDdasdadada", sorted);

    return fData.filter((i) => {
      if (["simplev2", "simplev1"].includes(isTableView)) {
        if (radioData === "week1") {
          return (
            i.date >= payPeriod.StartDate &&
            i.date <=
              moment(payPeriod.StartDate, "YYYY-MM-DD")
                .add(6, "days")
                .format("YYYY-MM-DD")
          );
        } else if (radioData === "week2") {
          return (
            i.date <= payPeriod.EndDate &&
            i.date >=
              moment(payPeriod.StartDate, "YYYY-MM-DD")
                .add(7, "days")
                .format("YYYY-MM-DD")
          );
        } else {
          return i;
        }
      }
    });
  };

  useEffect(() => {
    if (rowAuthAllData.length) {
      setRowsData([]);

      let mergedArray = [];
      if (isTableView == "table") {
        mergedArray = [...rowsData, ...rowAuthData];
      } else {
        mergedArray = [...rowsData, ...rowAuthAllData];
      }
      console.log("assadada", mergedArray);
      setRowsData(mergedArray);
    }
  }, [rowAuthAllData.length, isTableView]);

  const disableTable =
    rowsData.length > 0
      ? rowsData?.every((item) =>
          ["user"].includes(role)
            ? item?.Status === "timekeeper" ||
              item?.Status === "supervisor" ||
              item?.Status === "admin"
            : false
        )
      : false;

  return (
    <div className="container w-full ">
      {isloading && <LoadingPanel></LoadingPanel>}
      <div className="row mt-3 mb-3" card>
        <div className="col-md-2">
          {isVerify && (
            <FormControl fullWidth>
              <InputLabel id="demo-data-select-label">
                Select Profile Type
              </InputLabel>

              <Select
                labelId="demo-data-select-label"
                label="Select Profile Type"
                id="demo-data-select"
                value={profileType.label}
                name={profileType.value}
                size={"medium"}
                onChange={(e) => {
                  console.log("podsfdsfsfs", e.target);
                  setTemplateID(e.target.value);
                  setProfileType({
                    label: e.target.value,
                    value: ["PREVPPE", "PREVWEAKPPE"].includes(e.target.value)
                      ? e.target.value
                      : "CUSTOMTEMPLATE",
                  });
                  if (
                    userTemplate.length > 0 &&
                    !["PREVPPE", "PREVWEAKPPE"].includes(e.target.value)
                  ) {
                    let optionData = userTemplate.find(
                      (option) => option.TemplateID == e.target.value
                    );
                    if (optionData?.EmployeeTemplateName) {
                      setTemplateTitle(optionData?.EmployeeTemplateName);
                    }
                  }
                }}
              >
                {["admin", "supervisor"].includes(getUserRole(userData))
                  ? templateOption.length &&
                    templateOption.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : templateOption1.length &&
                    templateOption1.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          )}
        </div>
        {profileType?.value == "PROFILETEMPLATE" && (
          <div className="col-md-6">
            <FormControl fullWidth>
              <InputLabel id="demo-data2-select-label">
                Select Profile Template
              </InputLabel>

              <Select
                labelId="demo-data2-select-label"
                label="Select Profile Template"
                id="demo-data2-select"
                value={templateID ? templateID : ""}
                size={"medium"}
                onChange={(e) => {
                  console.log("Max 1", e.target.value, e.target);
                  setTemplateID(e.target.value);
                  if (adminTemplate.length > 0) {
                    let optionData = adminTemplate.find(
                      (option) => option.ID === e.target.value
                    );
                    if (optionData.TimeSheetName) {
                      setTemplateTitle(optionData.TimeSheetName);
                    }
                  }
                }}
              >
                {adminTemplate.length &&
                  adminTemplate.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.ID}
                      name={option.TimeSheetName}
                    >
                      {option.TimeSheetName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}

        {/* {profileType?.value == "CUSTOMTEMPLATE" && (
          <div className="col-md-3">
            <FormControl fullWidth>
              <InputLabel id="demo-data3-select-label">
                Select Custom Template
              </InputLabel>

              <Select
                labelId="demo-data3-select-label"
                label="Select Custom Template"
                id="demo-data3-select"
                value={templateID ? templateID : ""}
                size={"medium"}
                onChange={(e) => {
                  setTemplateID(e.target?.value);
                  if (userTemplate.length > 0) {
                    let optionData = userTemplate.find(
                      (option) => option.TemplateID === e.target.value
                    );
                    if (optionData.EmployeeTemplateName) {
                      setTemplateTitle(optionData.EmployeeTemplateName);
                    }
                  }
                }}
              >
                {userTemplate.length ? (
                  userTemplate.map((option, index) => (
                    <MenuItem key={index} value={option.TemplateID}>
                      {console.log("121212 :::: ", option)}
                      {option.EmployeeTemplateName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={0}>
                    No Template Found
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        )} */}
        {["PROFILETEMPLATE", "CUSTOMTEMPLATE"].includes(profileType?.value) ? (
          <div className="col-md-2 flex">
            <Button
              // onClick={renderProfileData}
              onClick={() => {
                console.log("TEST 123123 ::: ", templateID);
                if (templateID) {
                  setViewModal(true);
                } else {
                  ShowError("Please select Template");
                }
              }}
              variant="contained"
              // disabled={check || hasError}
              className="m-10 bg-primary"
              size="medium"
              style={{
                color: "#d0efe9",
                // backgroundColor: "#61bdaa",
              }}
            >
              <FontAwesomeIcon
                icon={faEye}
                // style={{ color: "#24695c", marginRight: "10px" }}
                style={{ marginRight: "10px" }}
                className="bg-primary"
              />
              <Hidden smDown>
                <Typography className="text-12">Preview</Typography>
              </Hidden>
            </Button>
            {/* {templateData.length ? (
              <Button
                onClick={() => {
                  setAuthModal(true);
                }}
                variant="contained"
                className="m-10 bg-primary"
                size="medium"
                style={{
                  color: "#d0efe9",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{ marginRight: "10px" }}
                  className="bg-primary"
                />
                <Hidden smDown>
                  <Typography className="text-12">Auth</Typography>
                </Hidden>
              </Button>
            ) : null} */}
          </div>
        ) : (
          <div className="col-md-2">
            {isVerify && (
              <Button
                onClick={renderProfileData}
                variant="contained"
                // disabled={check || hasError}
                className="m-10 bg-primary"
                size="medium"
                style={{
                  color: "#d0efe9",
                  // backgroundColor: "#61bdaa",
                }}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  // style={{ color: "#24695c", marginRight: "10px" }}
                  style={{ marginRight: "10px" }}
                  className="bg-primary"
                />
                <Hidden smDown>
                  <Typography className="text-12">Submit Now</Typography>
                </Hidden>
              </Button>
            )}
          </div>
        )}
        <div className="col-md-2">
          {authTemplate?.TemplateID && isVerify ? (
            <Button
              onClick={() => {
                getAuthTemlateData(authTemplate?.TemplateID);
              }}
              variant="contained"
              className="m-10 bg-primary"
              size="medium"
              style={{
                color: "#d0efe9",
              }}
            >
              <FontAwesomeIcon
                icon={faCalendarCheck}
                style={{ marginRight: "10px" }}
                className="bg-primary"
              />
              <Hidden smDown>
                <Typography className="text-12">Auth Hours</Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
        {viewModal && (
          <Dialog
            open={viewModal}
            fullWidth
            maxWidth="lg"
            onClose={() => {
              setViewModal(false);
            }}
          >
            <DialogTitle>
              {/* <Box display="flex" justifyContent="space-between"> */}
              <Typography>
                <h3>{templateTitle}</h3>
              </Typography>

              {/* <IconButton
          onClick={() => {
            setViewModal(false);
          }}
        >
          <Close />
        </IconButton> */}
              {/* </Box> */}
            </DialogTitle>
            <DialogContent>
              <ViewTimeSheetTemplates
                templateId={templateID}
                setTemplateID={setTemplateID}
                templateList={
                  profileType?.value == "CUSTOMTEMPLATE"
                    ? userTemplate
                    : adminTemplate
                }
                sideBarFrom="Payroll"
                setTemplateTitle={setTemplateTitle}
              />
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  setViewModal(false);
                  if (templateID && templateTitle) {
                    renderProfileData();
                  }
                }}
                className="bg-primary"
                style={{ color: "white" }}
              >
                Apply
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  setViewModal(false);
                }}
                className="bg-primary"
                style={{ color: "white" }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* <div className="col-md-12">
          <Button
            onClick={() => handlePreviousPPE()}
            variant="contained"
            // disabled={check || hasError}
            className="m-10"
            style={{
              color: "#d0efe9",
              backgroundColor: "#61bdaa",
            }}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">Load Previous PPE</Typography>
            </Hidden>
          </Button>
          <Button
            onClick={() => handlePrefill(true)}
            variant="contained"
            // disabled={check || hasError}
            className="m-10"
            style={{
              color: "#d0efe9",
              backgroundColor: "#61bdaa",
            }}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                Load Pre Define TimeSheet
              </Typography>
            </Hidden>
          </Button>
        </div> */}

        <div
          className={`col-md-${
            !["PROFILETEMPLATE", "CUSTOMTEMPLATE"].includes(profileType?.value)
              ? 6
              : 3
          }`}
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            float: "right",
          }}
        >
          <Tooltip title={"Help"}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              size="2x"
              style={{
                color: ConfigDB.data.color.primary_color,
                marginRight: "10px",
                float: "right",
              }}
              onClick={() => {
                setModalOpen(true);
              }}
              cursor="pointer"
            />
          </Tooltip>
          {view.map((v) => {
            return (
              <>
                {/* {v === "table" ? (
                  <span
                    style={
                      isTableView == "table" ? selectedCss : notSelectedCss
                    }
                  >
                    <Tooltip title={"Standard Sheet"}>
                      <FontAwesomeIcon
                        icon={faList}
                        size="2x"
                        style={{
                          color: ConfigDB.data.color.primary_color,

                          float: "right",
                        }}
                        onClick={() => {
                          setIsTableView("table");
                        }}
                        cursor="pointer"
                      />
                    </Tooltip>
                  </span>
                ) : (
                  ""
                )} */}
                {/* {v === "sheet" ? (
                  <span
                    style={
                      isTableView == "sheet" ? selectedCss : notSelectedCss
                    }
                  >
                    <Tooltip title={"TimeSheet"}>
                      <FontAwesomeIcon
                        icon={faTable}
                        size="2x"
                        style={{
                          color: ConfigDB.data.color.primary_color,

                          float: "right",
                        }}
                        onClick={() => {
                          setIsTableView("sheet");
                        }}
                        cursor="pointer"
                      />
                    </Tooltip>
                  </span>
                ) : (
                  ""
                )} */}
                {/* {v === "simplev1" ? (
                  <span
                    style={
                      isTableView == "simplev1" ? selectedCss : notSelectedCss
                    }
                  >
                    <Tooltip title={"Enter Timesheet using Start and End Time"}>
                      <Button
                        variant="contained"
                        className="bg-primary"
                        onClick={() => {
                          setIsTableView("simplev1");
                        }}
                        style={{
                          width: "40px",
                          minWidth: 0,

                          padding: 0,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{
                            marginRight: "3px",
                          }}
                        />{" "}
                        v1
                      </Button>
                    </Tooltip>
                  </span>
                ) : (
                  ""
                )} */}
                {/* {v === "simplev2" ? (
                  <span
                    style={
                      isTableView == "simplev2" ? selectedCss : notSelectedCss
                    }
                  >
                    <Tooltip
                      title={
                        "Enter Timesheet using Start Time and Hours Worked (This will Calculate End Time)"
                      }
                    >
                      <Button
                        variant="contained"
                        className="bg-primary"
                        onClick={() => {
                          setIsTableView("simplev2");
                        }}
                        style={{
                          width: "40px",
                          minWidth: 0,

                          padding: 0,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{
                            marginRight: "3px",
                          }}
                        />{" "}
                        v2
                      </Button>
                    </Tooltip>
                  </span>
                ) : (
                  ""
                )} */}
                {v === "timeClock" && loginUserID == userId ? (
                  <span
                    style={
                      isTableView == "timeClock" ? selectedCss : notSelectedCss
                    }
                  >
                    <Tooltip title={"Time Clock"}>
                      <FontAwesomeIcon
                        icon={faClock}
                        size="2x"
                        style={{
                          color: ConfigDB.data.color.primary_color,
                          float: "right",
                        }}
                        onClick={() => {
                          setIsTableView("timeClock");
                        }}
                        cursor="pointer"
                      />
                    </Tooltip>
                  </span>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {/* <span style={isTableView == "table" ? selectedCss : notSelectedCss}>
              <Tooltip title={"Standard Sheet"}>
                <Button
                  variant="contained"
                  className="bg-primary"
                  onClick={() => {
                    setIsTableView("table");
                  }}
                  style={{
                    width: "40px",
                    minWidth: 0,

                    padding: 0,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{
                      marginRight: "3px",
                    }}
                  />{" "}
                  v1
                </Button>
              </Tooltip>
            </span> */}
          <span
            style={isTableView == "simplev1" ? selectedCss : notSelectedCss}
          >
            <Tooltip title={"Standard Employee v1"}>
              <Button
                variant="contained"
                className="bg-primary"
                onClick={() => {
                  setIsTableView("simplev1");
                }}
                style={{
                  width: "40px",
                  minWidth: 0,

                  padding: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{
                    marginRight: "3px",
                  }}
                />{" "}
                v2
              </Button>
            </Tooltip>
          </span>
          <span
            style={isTableView == "simplev2" ? selectedCss : notSelectedCss}
          >
            <Tooltip title={"Standard Employee v2"}>
              <Button
                variant="contained"
                className="bg-primary"
                onClick={() => {
                  setIsTableView("simplev2");
                }}
                style={{
                  width: "40px",
                  minWidth: 0,

                  padding: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{
                    marginRight: "3px",
                  }}
                />{" "}
                v3
              </Button>
            </Tooltip>
          </span>
          {getUserRole(userData) == "user" && (
            <>
              <span
                style={
                  isTableView == "uploadTimesheet"
                    ? selectedCss
                    : notSelectedCss
                }
              >
                <Tooltip title="Upload Timesheet (CSV)">
                  <Button
                    variant="contained"
                    className="bg-primary"
                    onClick={() => {
                      setIsTableView("uploadTimesheet");
                    }}
                    style={{
                      width: "85px",
                      minWidth: 0,
                      padding: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{
                        marginRight: "3px",
                      }}
                    />{" "}
                    Upload
                  </Button>
                </Tooltip>
              </span>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12" style={{ padding: "0" }}>
          {/* <pre> */}
          {/* {JSON.stringify(originalData)} */}
          {/* {JSON.stringify(rowsData)} */}

          {/* {JSON.stringify(hourData)} */}
          {/* </pre> */}
          {/* {isTableView == "table" ? (
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <h5>Standard Sheet</h5>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Type</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Date</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>

                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      <th
                        className="paroll-contents-th"
                        style={{ marginBottom: 20 }}
                      >
                        <p className="tabletitle labelColor">Job Order</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor"> H Pay Type</p>
                      </th>

                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Status</p>
                      </th>
                      <th className="paroll-contents-th">
                        <button
                          className="btn btn-outline-success paroll-contents-btn"
                          onClick={addTableRows}
                          style={{ marginBottom: 20 }}
                        >
                          +
                        </button>
                      </th>
                      {getUserRole(userData) === "supervisor" && (
                        <th className="paroll-contents-th">
                          <p className="tabletitle labelColor">Status</p>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white  paroll-contents-tbody  "
                    style={{ width: "100%" }}
                  >
                    <TableRows
                      setTimeTo={setTimeTo}
                      rowsData={rowsData.filter((i) => {
                        return !i.isTemplate;
                      })}
                      userInfo={userInfo}
                      setRowsData={setRowsData}
                      paytimeCode={paytimeCode}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleChange={handleChange}
                      handleChangeHizard={handleChangeHizard}
                      handleDateFieldChange={handleDateFieldChange}
                      maxALLeave={maxALLeave}
                      maxSLLeave={maxSLLeave}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                    />
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null} */}

          {isTableView == "table" ? (
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Version 1 : Standard TimeSheet Layout</h5>
                  </div>
                </div>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Code</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p
                          className="tabletitle labelColor"
                          style={{ width: "185px" }}
                        >
                          Date
                        </p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      {viewField ? (
                        <>
                          {viewFields.map((i) => {
                            return (
                              <th className="paroll-contents-th">
                                <p className="tabletitle labelColor">
                                  {i.label}
                                </p>
                              </th>
                            );
                          })}{" "}
                        </>
                      ) : null}
                      <th className="paroll-contents-th">
                        <button
                          className="btn btn-outline-success paroll-contents-btn"
                          onClick={addTableRowsV1Sheet}
                          style={{ marginBottom: 20 }}
                        >
                          +
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white  paroll-contents-tbody  "
                    style={{ width: "100%" }}
                  >
                    {console.log("rowData 123", rowsData.Date, payPeriod)}
                    <TableRowsV1
                      setTimeTo={setTimeTo}
                      role={role}
                      setOpen={setOpen}
                      viewField={viewField}
                      viewFields={viewFields}
                      rowsData={rowsData.filter((i) => {
                        return !i.isTemplate;
                      })}
                      userInfo={userInfo}
                      setRowsData={setRowsData}
                      paytimeCode={paytimeCode}
                      setDeleteRowIndex={setDeleteRowIndex}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleChangeV1={handleChangeV1}
                      handleChangeHizard={handleChangeHizard}
                      handleDateFieldChange={handleDateFieldChange}
                      maxALLeave={maxALLeave}
                      maxSLLeave={maxSLLeave}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                    />
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null}

          {isTableView == "simplev1" ? (
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>
                      Version 2 : Enter Timesheet Using Start And End Time
                    </h5>
                  </div>
                  {/* <div>
                       <Button
                  onClick={() =>setIsResetTimesheetModal(true)}
                  variant="contained"
                  className="m-10 bg-primary"
                  style={{
                    color: "#d0efe9",
                  }}
                >Reset PPE Data
</Button>
                  </div> */}

                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="all"
                        value={radioData}
                        onChange={(e) => {
                          setRadioData(e.target.value);
                        }}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          style={{ color: "#d54747" }}
                          value="week1"
                          control={
                            <Radio
                              sx={{
                                "&,&.Mui-checked": {
                                  // color: "#f6e5e5",
                                  color: "#d54747",
                                },
                              }}
                            />
                          }
                          label="Week1"
                        />
                        <FormControlLabel
                          style={{ color: "#1f9b1f" }}
                          value="week2"
                          control={
                            <Radio
                              sx={{
                                " &,&.Mui-checked": {
                                  // color: "#d9ecd9",
                                  color: "#1f9b1f",
                                },
                              }}
                            />
                          }
                          label="Week 2"
                        />
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="All"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {/* <div>
                    <Switch
                      size="small"
                      defaultChecked={viewField}
                      onChange={(e) => {
                        setViewField(e.target.checked);
                      }}
                    />
                  </div> */}
                </div>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                {/* {JSON.stringify(rowsData)} */}

                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%", maxWidth: "700px" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">#</p>
                      </th>{" "}
                      <th
                        className="paroll-contents-th"
                        style={{ width: "185px" }}
                      >
                        <p
                          className="tabletitle labelColor"
                          style={{ width: "185px" }}
                        >
                          Date
                        </p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      {viewField ? (
                        <>
                          {viewFields.map((i) => {
                            return (
                              <th className="paroll-contents-th">
                                <p className="tabletitle labelColor">
                                  {i.label}
                                </p>
                              </th>
                            );
                          })}{" "}
                        </>
                      ) : null}
                      {disableTable === false && (
                        <th className="paroll-contents-th">
                          <button
                            className="btn btn-outline-success paroll-contents-btn"
                            onClick={addTableRowsV1Sheet}
                            style={{ marginBottom: 20 }}
                          >
                            +
                          </button>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white  paroll-contents-tbody  "
                    style={{ width: "100%" }}
                  >
                    <TableRowsEmpV1
                      role={role}
                      setTimeTo={setTimeTo}
                      setOpen={setOpen}
                      viewField={viewField}
                      viewFields={viewFields}
                      rowsData={filterRowData(rowsData, radioData, payPeriod)}
                      userInfo={userInfo}
                      setRowsData={setRowsData}
                      paytimeCode={paytimeCode}
                      paytimeCodeByID={paytimeCodeByID}
                      setDeleteRowIndex={setDeleteRowIndex}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleChangeV1={handleChangeV1}
                      handleChangeHizard={handleChangeHizard}
                      handleDateFieldChange={handleDateFieldChange}
                      maxALLeave={maxALLeave}
                      maxSLLeave={maxSLLeave}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                      radioData={radioData}
                    />
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null}
          {isTableView == "simplev2" ? (
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>
                      Version 3: Enter Timesheet Using Start Time And Hours
                      Worked (This Will Calculate End Time)
                    </h5>
                  </div>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="all"
                        value={radioData}
                        onChange={(e) => {
                          setRadioData(e.target.value);
                        }}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          style={{ color: "#d54747" }}
                          value="week1"
                          control={
                            <Radio
                              sx={{
                                "&,&.Mui-checked": {
                                  // color: "#f6e5e5",
                                  color: "#d54747",
                                },
                              }}
                            />
                          }
                          label="Week1"
                        />
                        <FormControlLabel
                          style={{ color: "#1f9b1f" }}
                          value="week2"
                          control={
                            <Radio
                              sx={{
                                " &,&.Mui-checked": {
                                  // color: "#d9ecd9",
                                  color: "#1f9b1f",
                                },
                              }}
                            />
                          }
                          label="Week 2"
                        />
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="All"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* <div>
                    <Switch
                      size="small"
                      defaultChecked={viewField}
                      onChange={(e) => {
                        setViewField(e.target.checked);
                      }}
                    />
                  </div> */}
                </div>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%", maxWidth: "800px" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      {/* <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">#</p>
                      </th>{" "} */}
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Code</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p
                          className="tabletitle labelColor"
                          style={{ width: "185px" }}
                        >
                          Date
                        </p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      {viewField ? (
                        <>
                          {viewFields.map((i) => {
                            return (
                              <th className="paroll-contents-th">
                                <p className="tabletitle labelColor">
                                  {i.label}
                                </p>
                              </th>
                            );
                          })}{" "}
                        </>
                      ) : null}
                      <th className="paroll-contents-th">
                        {disableTable === false && (
                          <button
                            className="btn btn-outline-success paroll-contents-btn"
                            onClick={addTableRowsV1Sheet}
                            style={{ marginBottom: 20 }}
                          >
                            +
                          </button>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white  paroll-contents-tbody  "
                    style={{ width: "100%" }}
                  >
                    <TableRowsEmpV2
                      role={role}
                      setOpen={setOpen}
                      setTimeTo={setTimeTo}
                      viewField={viewField}
                      viewFields={viewFields}
                      setRowsData={setRowsData}
                      rowsData={
                        filterRowData(rowsData, radioData, payPeriod)
                        //   .filter((i) => {
                        //   return !i.isTemplate;
                        // })
                      }
                      userInfo={userInfo}
                      paytimeCode={paytimeCode}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleChangeV1={handleChangeV1}
                      handleChangeHizard={handleChangeHizard}
                      setDeleteRowIndex={setDeleteRowIndex}
                      handleDateFieldChange={handleDateFieldChange}
                      maxALLeave={maxALLeave}
                      maxSLLeave={maxSLLeave}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                      radioData={radioData}
                    />
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null}
          {/* {isTableView == "sheet" ? (
            <Card className="p-3">
              <CardHeader>
                <h5>Schedular Sheet</h5>
              </CardHeader>
              <ReactTimeSchedular
                EmployeeID={userId}
                paytimeCodeList={paytimeCode}
                filteredEvent={originalData.concat(originalAuthData)}
                templateID={templateID}
                payPeriod={payPeriod}
                ref={childRef}
                templateData={templateData}
                paytimeCodeByID={paytimeCodeByID}
                setRowsData={setRowsData}
                statusSubmit={statusSubmit}
                loadPreData={loadPreData}
              />
            </Card>
          ) : null} */}
          {/* {isTableView == "simplev1" ? (
            // <Card className="p-3">
            <PayrollSimpleView
              EmployeeID={userId}
              paytimeCodeList={paytimeCode}
              filteredEvent={originalData.filter((i) => i.Auth !== "Auth")}
              templateID={templateID}
              payPeriod={payPeriod}
              ref={childRef}
              templateData={templateData} // check for vaild auth hours
              paytimeCodeByID={paytimeCodeByID}
              userId={userId}
              setRowsData={setRowsData}
              statusSubmit={statusSubmit}
              loadPreData={loadPreData}
            />
          ) : // </Card>
          null} */}
          {/* {isTableView == "simplev2" ? (
            // <Card className="p-3">
            <PayrollSimpleViewV2
              EmployeeID={userId}
              paytimeCodeList={paytimeCode}
              filteredEvent={originalData.filter((i) => i.Auth !== "Auth")}
              templateID={templateID}
              payPeriod={payPeriod}
              ref={childRef}
              templateData={templateData}
              paytimeCodeByID={paytimeCodeByID}
              userId={userId}
              setRowsData={setRowsData}
              statusSubmit={statusSubmit}
              loadPreData={loadPreData}
            />
          ) : // </Card>
          null} */}

          {isTableView == "timeClock" && loginUserID == userId ? (
            <TimeClock
              userId={userId}
              userInfo={userInfo}
              ref={childRef}
              paytimeCodeByID={paytimeCodeByID}
              filteredEvent={originalData}
              paytimeCode={paytimeCode}
              templateData={templateData}
              payPeriod={payPeriod}
              loadPreData={loadPreData}
              rowAuthData={rowAuthData}
            />
          ) : null}

          {isTableView == "uploadTimesheet" && (
            <>
              <>
                <Card style={{ width: "100%" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        <h5>Standard Sheet</h5>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <label htmlFor="upload-csv">
                            <input
                              style={{ display: "none" }}
                              id="upload-csv"
                              name="upload-csv"
                              type="file"
                              accept=".xlsx,.xls"
                              onChange={handleGetFileData}
                              onClick={(e) => {
                                e.currentTarget.value = "";
                              }}
                            />
                            <Button
                              variant="contained"
                              className="m-10 bg-primary"
                              size="medium"
                              style={{
                                color: "#d0efe9",
                              }}
                              component="span"
                            >
                              <FontAwesomeIcon
                                icon={faFileExport}
                                style={{ marginRight: "10px" }}
                                className="bg-primary"
                              />
                              <Hidden smDown>
                                <Typography className="text-12">
                                  Upload Timesheet
                                </Typography>
                              </Hidden>
                            </Button>
                          </label>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className="m-10 bg-primary"
                            size="medium"
                            style={{
                              color: "#d0efe9",
                            }}
                            onClick={() => handleDownloadExcel(rowsData)}
                            component="span"
                          >
                            <FontAwesomeIcon
                              icon={faFileDownload}
                              style={{ marginRight: "10px" }}
                              className="bg-primary"
                            />
                            <Hidden smDown>
                              <Typography className="text-12">
                                DownLoad Excel
                              </Typography>
                            </Hidden>
                          </Button>
                        </div>
                      </div>
                    </div>
                    {fileErrors?.length ? (
                      <Alert
                        icon={false}
                        severity="success"
                        className="mt-2"
                        style={{ backgroundColor: "#FDEDED", color: "#5F2120" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              size="40"
                              style={{
                                marginRight: "10px",
                                color: "#5F2120",
                                backgroundColor: "#FDEDED",
                                fontSize: "14px",
                              }}
                              // className="bg-primary"
                            />
                          </div>
                          <div>
                            {fileErrors
                              ? fileErrors?.map((i, index) => {
                                  return (
                                    <div>
                                      <b>{`(${index + 1})`}</b> {`${i.message}`}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </Alert>
                    ) : null}
                  </CardHeader>
                  <CardBody
                    style={{
                      width: "100%",
                      padding: "10px",
                      overflowX: "auto",
                    }}
                  >
                    <table
                      className="table paroll-contents-table  "
                      style={{ width: "100%" }}
                    >
                      <thead className="paroll-contents-thead mb-3">
                        <tr>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Pay Type</p>
                          </th>
                          <th className="paroll-contents-th">
                            <p
                              className="tabletitle labelColor"
                              style={{ width: "185px" }}
                            >
                              Date
                            </p>
                          </th>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">From</p>
                          </th>

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">To</p>
                          </th>

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Hours</p>
                          </th>

                          <th className="paroll-contents-th">
                            <button
                              className="btn btn-outline-success paroll-contents-btn"
                              onClick={addTableRows}
                              style={{ marginBottom: 20 }}
                            >
                              +
                            </button>
                          </th>
                          {getUserRole(userData) === "supervisor" && (
                            <th className="paroll-contents-th">
                              <p className="tabletitle labelColor">Status</p>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody
                        className="bg-white  paroll-contents-tbody  "
                        style={{ width: "100%" }}
                      >
                        <TableRowsEmpV1
                          role={role}
                          setTimeTo={setTimeTo}
                          setOpen={setOpen}
                          viewField={viewField}
                          viewFields={viewFields}
                          rowsData={rowsData.filter((i) => {
                            return !i.isTemplate;
                          })}
                          userInfo={userInfo}
                          setRowsData={setRowsData}
                          paytimeCode={paytimeCode}
                          paytimeCodeByID={paytimeCodeByID}
                          setDeleteRowIndex={setDeleteRowIndex}
                          getDateColor={getDateColor}
                          deleteTableRows={deleteTableRows}
                          confirmTableRows={confirmTableRows}
                          handleChangeV1={handleChangeV1}
                          handleChangeHizard={handleChangeHizard}
                          handleDateFieldChange={handleDateFieldChange}
                          maxALLeave={maxALLeave}
                          maxSLLeave={maxSLLeave}
                          payPeriod={{
                            StartDate:
                              payPeriod && payPeriod.StartDate
                                ? payPeriod.StartDate
                                : null,
                            EndDate:
                              payPeriod && payPeriod.EndDate
                                ? payPeriod.EndDate
                                : null,
                          }}
                          radioData={radioData}
                        />
                        {/* <TableRows
                          setTimeTo={setTimeTo}
                          rowsData={rowsData.filter((i) => {
                            return !i.isTemplate;
                          })}
                          setRowsData={setRowsData}
                          paytimeCode={paytimeCode}
                          paytimeCodeByID={paytimeCodeByID}
                          getDateColor={getDateColor}
                          deleteTableRows={deleteTableRows}
                          confirmTableRows={confirmTableRows}
                          handleChange={handleChange}
                          handleChangeHizard={handleChangeHizard}
                          handleDateFieldChange={handleDateFieldChange}
                          payPeriod={{
                            StartDate:
                              payPeriod && payPeriod.StartDate
                                ? payPeriod.StartDate
                                : null,
                            EndDate:
                              payPeriod && payPeriod.EndDate
                                ? payPeriod.EndDate
                                : null,
                          }}
                        /> */}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </>
            </>
          )}
          {/* <Row style={{ marginTop: 30 }}>
            <Col
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <b className="labelColor">Load Normal Profile :</b>

              <div style={{ flex: 1, display: "flex", marginLeft: 20 }}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item component="label">
                    No
                    <AntSwitch
                      checked={check}
                      onChange={(e) => {
                        handlePrefill(e.target.checked);
                      }}
                      name="normal_profile"
                    />
                    Yes
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col xs="12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "50px",
                  textAlign: "center",
                }}
              >
                {/* <b className="labelColor" style={{ marginRight: 40 }}>
                  Save Time Sheet
                </b> */}
                {disableTable === false && (
                  <Button
                    // onClick={() => handleSaveRows()}
                    onClick={() =>
                      // ["simplev1", "simplev2", "sheet", "timeClock"].includes(  // hide for the
                      //   isTableView
                      // )
                      //   ? childRef.current.childFunction()
                      //   :
                      isTableView == "table"
                        ? handleSaveRows()
                        : isTableView == "timeClock"
                        ? childRef.current.childFunction()
                        : handleSaveRowsv2()
                    }
                    // onClick={ () => { childRef.current.childFunction() }}
                    variant="contained"
                    // disabled={check || hasError}
                    className="m-10 bg-primary"
                    style={{
                      color: "#d0efe9",
                      // backgroundColor: "#61bdaa",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{
                        // color: "#24695c",
                        marginRight: "10px",
                      }}
                      className="bg-primary"
                    />
                    {/* <Hidden smDown> */}
                    <Typography className="text-12">Save Time Sheet</Typography>
                    {/* </Hidden> */}
                  </Button>
                )}

                <div style={{ display: "flex" }}>
                  <span style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "#f6e5e5 ",
                      }}
                    ></div>{" "}
                    <div style={{ marginLeft: "5px" }}>Week 1 Records</div>
                  </span>
                  <span style={{ display: "flex", marginLeft: "10px" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "#d9ecd9",
                      }}
                    ></div>{" "}
                    <div style={{ marginLeft: "5px" }}>Week 2 Records</div>
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
        
            <Card style={{ width: "100%" }}>
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>
                    OT Sheet
                    </h5>
                  </div>
                 
                </div>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%", maxWidth: "700px" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Date</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      {viewField ? (
                        <>
                          {viewFields.map((i) => {
                            return (
                              <th className="paroll-contents-th">
                                <p className="tabletitle labelColor">
                                  {i.label}
                                </p>
                              </th>
                            );
                          })}{" "}
                        </>
                      ) : null}
                      <th className="paroll-contents-th">
                        <button
                          className="btn btn-outline-success paroll-contents-btn"
                          onClick={addTableRowsV1Sheet}
                          style={{ marginBottom: 20 }}
                        >
                          +
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="bg-white  paroll-contents-tbody  "
                    style={{ width: "100%" }}
                  >
                    <OTStandardSheet
                      role={role}
                      setTimeTo={setTimeTo}
                      setOpen={setOpen}
                      viewField={viewField}
                      viewFields={viewFields}
                      rowsData={rowsOTData}
                      userInfo={userInfo}
                      setRowsData={setRowsOTData}
                      paytimeCode={paytimeCode}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleChangeV1={handleChangeOTV1}
                      handleChangeHizard={handleChangeHizard}
                      handleDateFieldChange={handleDateFieldChange}
                      maxALLeave={maxALLeave}
                      maxSLLeave={maxSLLeave}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                    />
                  </tbody>
                </table>
              </CardBody>
            </Card>
          
          </Row> */}
        </div>
      </div>

      <TimeSheetModal
        open={modalOpen}
        setOpen={setModalOpen}
        title={isTableView}
        saveButton={true}
      />
      {open ? (
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          confimClick={confimDeleteClick}
          message={"Are you sure you want to remove this record?"}
          modalHeader="Alert Message"
        />
      ) : (
        ""
      )}
      {isResetTimesheetModal ? (
        <ConfirmModal
          open={isResetTimesheetModal}
          setOpen={setIsResetTimesheetModal}
          confimClick={handleConfirmResetTimesheet}
          message={
            "Are you sure to delete the timesheet data for this pay period?"
          }
          modalHeader="Alert Message"
        />
      ) : (
        ""
      )}
      {rowAuthData.length && authModal ? (
        <PayrollAuthModalData
          authModal={true}
          setAuthModal={setAuthModal}
          rowDisable={true}
          setTimeTo={setTimeTo}
          rowsData={rowAuthData}
          setRowsData={setRowsData}
          paytimeCode={paytimeCode}
          templateTitleCurrent={templateTitleCurrent}
          paytimeCodeByID={paytimeCodeByID}
          getDateColor={getDateColor}
          deleteTableRows={deleteTableRows}
          confirmTableRows={confirmTableRows}
          handleChange={handleChange}
          handleChangeHizard={handleChangeHizard}
          handleDateFieldChange={handleDateFieldChange}
          payPeriod={{
            StartDate:
              payPeriod && payPeriod.StartDate ? payPeriod.StartDate : null,
            EndDate: payPeriod && payPeriod.EndDate ? payPeriod.EndDate : null,
          }}
        />
      ) : null}
    </div>
  );
};
// export default PayrollContent;

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ showMessage }, dispatch);
// };

// export default connect(null, mapDispatchToProps)(PayrollContent);
export default PayrollContent;
