import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../_helper/api_helper";
import { toast } from "react-toastify";

const ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD = "DeductionPlanMaster/CreateOrUpdate";
const sliceName = "deductionPlan";

export const createOrUpdateDeductionPlanMaster = createAsyncThunk(
    ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD,
    async (payload) => {
        try {
            // let response = await post(`${process.env.REACT_APP_AZURE_URL}/${ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD}`, payload);
            let response = await post(`${process.env.REACT_APP_WEB_API_URL}${ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD}`, payload);
            toast.success(`${payload?.id ? 'Updated' : 'Created'} successfully`);
            return { createResult: response?.data };
        } catch (error) {
            toast.error(`Invalid data!`);
            return { error }
        }
    }
);

export const getDeductionPlanById = createAsyncThunk(
    'DeductionPlanMaster/GetById',
    async (id) => {
        try {
            let response = await get(`${process.env.REACT_APP_WEB_API_URL}DeductionPlanMaster/GetById/${id}`);
            return { data: response.data };
        } catch (error) {
            return { error }
        }
    }
);

export const resetDeductionPlan = createAsyncThunk('resetDeductionPlan', async () => {
    return {}
});

const deductionSlice = createSlice({
    name: sliceName,
    initialState: {
        createResult: {},
        isCreating: false,
        createError: {},
        isFetching: false,
        deductionPlan: {},
        error: {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrUpdateDeductionPlanMaster.pending, (state) => { state.isCreating = true; })
            .addCase(createOrUpdateDeductionPlanMaster.fulfilled, (state, action) => {
                state.createResult = action.payload?.createResult;
                state.isCreating = false;
            })
            .addCase(createOrUpdateDeductionPlanMaster.rejected, (state, action) => {
                state.createResult = {};
                state.createError = action.payload?.error;
                state.isCreating = false;
            })
            .addCase(getDeductionPlanById.pending, (state) => { state.isFetching = true; })
            .addCase(getDeductionPlanById.fulfilled, (state, action) => {
                state.deductionPlan = action.payload?.data;
                state.isFetching = false;
            })
            .addCase(getDeductionPlanById.rejected, (state, action) => {
                state.deductionPlan = {};
                state.error = action.payload?.error;
                state.isFetching = false;
            })
            .addCase(resetDeductionPlan.fulfilled, (state, action) => {
                state.deductionPlan = {};
                state.isFetching = false;
            });
    },
});
export default deductionSlice.reducer;
