import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import { GroupAdd, Lock, PlayArrow } from "@material-ui/icons";
import { message } from "antd";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import ModalCustom from "./Modal";
import { useFormikContext } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";

import AdvanceSearchForm from "./AdvanceSearchFrom";
import moment from "moment/moment";

import { useDispatch, useSelector } from "react-redux";
import { changeQuery } from "../../app/store/reducers/slices/querySlice";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";

import { deleteSavedQueriesRecordStore } from "../../app/store/reducers/slices/querySlice";

const AddRecordFormStyles = makeStyles((theme) => ({
  MainHeading: {
    color: theme.palette.primary.main,
    marginBottom: "25px",
    marginLeft: "2px",
    fontWeight: "bold",
  },
  Subheading: {
    color: theme.palette.secondary.main,
    marginLeft: "2px",
    marginBottom: "8px",
  },
  ErrorForm: {
    color: "red",
    marginLeft: "2px",
    marginBottom: "8px",
  },
  Divider: {
    margin: "16px 0",
  },
  Button: {
    minWidth: "145px",
    fontSize: "1.4rem",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "90px",
    },
  },
  Icons: {
    fontSize: "2.5rem",
    marginLeft: "10px",
  },
  table: {
    minWidth: 650,
  },
  MainHeadingForTable: {
    color: theme.palette.primary.main,
    marginLeft: "20px",
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  DividerForTable: {
    marginTop: 0,
  },
}));
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: "#F4F5F7",
  },
}));

const ViewSavedQueries = (props) => {
  const { data, getData, edit, shared } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentQuery, setCurrentQuery] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [currentName, setCurrentName] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext() ?? {};
  // const status = useSelector((state) => state.auth.user.role[0]);

  // const { uuid } = useSelector((state) => state.auth.user);
  const [item, setItem] = useState([]);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(!open);
  };

  const handleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const editHandler = (rowData) => {
    const key = "fetchQuery";
    message.loading({ content: "Fetching Query...", key });
    console.log("rowData", rowData);
    axios
      .get("/query/byid?id=" + rowData.ID)

      .then((response) => {
        setCurrentId(rowData.id);
        setCurrentName(rowData.name_query);
        let resQuery = [];
        response.data.result.forEach((item) => {
          resQuery.push({
            column: item.collumn,
            operator: item.operator,
            value: item.value,
            condition: item.condition,
          });
        });
        setCurrentQuery({
          query_name: rowData.name_query,
          queries: resQuery,
        });
        handleClose();
        message.success({ content: "Fetched", key });
      })
      .catch((error) => {
        message.error({ content: error.message, key });
      });
  };

  const setCurrentRow = (rowData) => {
    const key = "fetchQuery";
    message.loading({ content: "Fetching Query...", key });
    axios
      // .get(`${process.env.REACT_APP_API_URL}query/byid/` + rowData.ID,
      .get(
        `${process.env.REACT_APP_API_URL}advancesearch/v2/fetch/` + rowData.ID,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        let resQuery = [];
        response.data.result.forEach((item, index) => {
          if (index !== 0) {
            console.log("item query", item);
            resQuery.push({
              column: item.columnName,
              operator: item.operator,
              value: item.paramValue,
              condition: item.logicOperator,
            });
            // resQuery.push({
            //   column: item.collumn,
            //   operator: item.operator,
            //   value: item.value,
            //   condition: item.condition,
            // });
          }
        });
        let newArray = [];
        values.queries.forEach((query) => {
          if (!(query.value === "" || query.column === "")) {
            newArray.push(query);
          }
        });
        setFieldValue("queries", [...resQuery]);
        // setFieldValue("queries", [...newArray, ...resQuery]);
        getData(resQuery);
        message.success({ content: "Fetched", key });
      })
      .catch((error) => {
        message.error({ content: error.message, key });
      });
  };
  const runHandler = (item) => {
    const key = "fetchQuery";
    message.loading({ content: "Fetching Query...", key });
    axios
      .get("/query/byid?id=" + item.id)
      .then((response) => {
        let resQuery = [];
        response.data.result.forEach((item) => {
          resQuery.push({
            column: item.collumn,
            operator: item.operator,
            value: item.value,
            condition: item.condition,
          });
        });
        // console.log('my queryyyy', resQuery);
        dispatch(changeQuery(resQuery));

        message.success({ content: "Fetched", key });
      })
      .catch((error) => {
        message.error({ content: error.message, key });
      });

    props.history.push("/apps/records-query/advance");
  };
  const deleteCurrentRow = (item) => {
    setItem(item);
    handleDeleteModal();
    if (deleteModalOpen) {
      deleteQuery(item);
    }
  };
  const deleteQuery = () => {
    const queryId = item.id;
    const deleteData = {
      id: queryId,
      data,
    };
    axios
      .delete(`/query/save`, {
        params: { id: queryId },
      })
      .then((response) => {
        dispatch(deleteSavedQueriesRecordStore(deleteData));
        message.success(response.status === 200 && "Record Deleted.");
      })
      .catch((error) => {
        // console.log(error);
        message.error("Server Not Responding");
      });
  };
  const classes = AddRecordFormStyles();
  return (
    <div style={{ height: "400px", overflow: "auto" }}>
      <ModalCustom handleClose={handleClose} fullWidth={true} open={open}>
        <AdvanceSearchForm
          edit={true}
          editValues={currentQuery}
          currentId={currentId}
          handleClose={handleClose}
        />
      </ModalCustom>
      <ModalCustom open={deleteModalOpen} handleClose={handleDeleteModal}>
        <div
          className="flex w-full justify-center mt-20"
          style={{ width: "700px" }}
        >
          <Card className="rounded-4 shadow-2xl w-full bg-white px-16 py-8 mb-8">
            <CardContent>
              <Typography
                variant="h6"
                component="h6"
                className={classes.MainHeading}
              >
                Are you sure you want to delete?
              </Typography>
              <div className="flex justify-end pt-20">
                <Button
                  color={"secondary"}
                  variant="contained"
                  className={clsx(classes.Button, "mr-16 w-30")}
                  onClick={handleDeleteModal}
                >
                  Cancel
                </Button>
                <Button
                  type={"button"}
                  color={"primary"}
                  variant="contained"
                  className={clsx(classes.Button, "w-30")}
                  onClick={deleteCurrentRow}
                >
                  Delete
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </ModalCustom>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>Query Id</TableCell>
            <TableCell>Query Name</TableCell>
            {/* {["admin", "super admin"].includes(status) && ( */}
            <TableCell>Group Name</TableCell>
            {/* )} */}
            {shared === true && <TableCell>Shared By</TableCell>}
            {shared === false && <TableCell>Status</TableCell>}
            <TableCell width="20%">Created At</TableCell>
            <TableCell width={200}>Actions</TableCell>
            {shared === false && (
              <TableCell align="center" width={1 / 4}>
                Delete
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log("DATA", data)}
          {data.map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell>{item.ID}</TableCell>
                <TableCell>{item.QueryName}</TableCell>
                {/* {["admin", "super admin"].includes(status) && ( */}
                <TableCell>{item.PermissionType ?? "N/A"}</TableCell>
                {/* )} */}
                {shared === true && (
                  <TableCell>{item.firstname + " " + item.lastname}</TableCell>
                )}
                {shared === false && (
                  <TableCell>
                    {item.user_group_id == null ? (
                      <IconButton>
                        <Lock />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <GroupAdd />
                      </IconButton>
                    )}
                  </TableCell>
                )}
                <TableCell width="auto">
                  {moment(item.created_at).format("MM-DD-YYYY HH:mm:ss a")}
                </TableCell>
                <TableCell>
                  {!edit && (
                    <div className="grid grid-cols-2">
                      <div className="text-right">
                        <Button
                          variant="contained"
                          color="primary"
                          className={useStyles.button}
                          size="small"
                          onClick={() => runHandler(item)}
                        >
                          Run
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          className={useStyles.button}
                          size="small"
                          style={{ marginLeft: "10px" }}
                          onClick={() => editHandler(item)}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  )}
                  {edit && (
                    <div className="flex justify-center">
                      <div className="">
                        <IconButton onClick={() => setCurrentRow(item)}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </TableCell>
                {shared === false && (
                  <TableCell>
                    <div className="flex justify-center">
                      <div className="">
                        <IconButton onClick={() => deleteCurrentRow(item)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ViewSavedQueries;
