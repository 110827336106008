import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
function ModalComponent(props) {
  const { open, setOpen } = props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
    >
      <DialogTitle>{props.title ? props.title : "Title"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalComponent;
