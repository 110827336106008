import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

import "./Modal.css";
// web.cjs is required for IE11 support
import { useSpring, animated } from "@react-spring/web";
import { useAuth } from "oidc-react";
import { Row } from "react-bootstrap";

// import { animated, useSpring } from '@react-spring/web'

const useStyles = makeStyles((theme) => ({
  messageRow: {
    "&.contact": {
      "& .bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        overflow: "hidden",
        "& .time": {
          marginLeft: 12,
        },
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopLeftRadius: 20,
        },
      },
      "&.last-of-group": {
        "& .bubble": {
          borderBottomLeftRadius: 20,
        },
      },
    },
    "&.me": {
      paddingLeft: 40,

      "& .avatar": {
        order: 2,
        margin: "0 0 0 16px",
      },
      "& .bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& .time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12,
        },
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopRightRadius: 20,
        },
      },

      "&.last-of-group": {
        "& .bubble": {
          borderBottomRightRadius: 20,
        },
      },
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20,
    },
    "&.first-of-group": {
      "& .bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13,
      },
    },
    "&.last-of-group": {
      "& .bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& .time": {
          display: "flex",
        },
      },
    },
  },
}));
const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ChatModal = (props) => {
  const [open, setOpen] = useState(props.open ? props.open : false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const classes = useStyles();
  // const user = useSelector(({ auth }) => auth.user);
  const { userData } = useAuth();

  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getChatData();
  }, [props.appId]);

  const getChatData = () => {
    // console.log("App Id : ", props.appId, userData.profile.name);
    axios
      .post(
        "https://gluc.eblpguam.com/apiv1/chat/getChat",
        { applicationId: props.appId },
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwOCwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjU4MjMxOTEwLCJleHAiOjE2NjYwMDc5MTB9.UuZl7qi0qx4WIiVuIhZeyloNQyAQ_VpZmU9BXLUOjF0",
          },
        }
      )
      .then((res) => {
        console.log("responded data", res.data.data);

        setData(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
        setData([]);
      });
  };

  const sendMessage = () => {
    if (!message) {
      return false;
    }
    axios
      .post(
        "https://gluc.eblpguam.com/apiv1/chat/createChat",
        {
          isPublish: false,
          applicationStatus: null,
          text: message,
          applicationFormType: props.appType,
          formId: props.appId,
        },
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwOCwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjU4MjMxOTEwLCJleHAiOjE2NjYwMDc5MTB9.UuZl7qi0qx4WIiVuIhZeyloNQyAQ_VpZmU9BXLUOjF0",
          },
        }
      )
      .then((res) => {
        console.log("responded data", res.data.data);
        let newData = [...data, res.data.data];

        setData(newData);
        setMessage("");
      })
      .catch((error) => {
        console.log("error", error);
        // setData([]);
      });
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
          props.closeModal();
        }}
      >
        <Fade in={open}>
          <Box
            sx={style}
            style={{
              overflow: "hidden",
              borderRadius: 20,
              // height: "400px",
            }}
          >
            <div
              className="bg-primary"
              style={{
                padding: "10px",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            >
              <span>
                <b style={{ fontSize: "large" }}>View Comments</b>
              </span>
              <span style={{ float: "right" }}>
                <button
                  onClick={() => {
                    setOpen(false);
                    props.closeModal();
                  }}
                  className="closebtn"
                >
                  <b style={{ fontSize: "large" }}>X</b>
                </button>
              </span>
              <span />
            </div>

            <div>
              <div className="contact">
                <div className="dataContainer" style={{ padding: 10 }}>
                  {data && data.length
                    ? data.map((chat) => {
                        return (
                          <>
                            {chat.email === userData.profile.email ? (
                              <div className="rightmsg">
                                <div className="txt">
                                  <div className="txtinfo">{chat.text}</div>
                                  <div className="senderinfo">
                                    <span>
                                      {moment(chat.createdAt).format(
                                        "MM-DD-YYYY hh:mm A"
                                      )}
                                    </span>
                                    {/* <span> {chat.username}</span> */}
                                    <span> user b</span>
                                  </div>
                                </div>
                                <Avatar
                                  className="rightavtar"
                                  sx={{ bgcolor: "#0D8ABC" }}
                                >
                                  {chat.username.charAt(0).toUpperCase()}
                                </Avatar>
                              </div>
                            ) : (
                              <div className="leftmsg">
                                <Avatar
                                  className="leftavtar"
                                  sx={{ bgcolor: "#0D8ABC" }}
                                >
                                  {chat.username.charAt(0).toUpperCase()}
                                </Avatar>
                                <div className="txt">
                                  <div className="txtinfo">{chat.text}</div>
                                  <div className="senderinfo">
                                    <span> user a</span>
                                    {/* <span> {chat.username}</span> */}

                                    <span>
                                      {" "}
                                      {moment(chat.createdAt).format(
                                        "MM-DD-YYYY hh:mm A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })
                    : "No Data Found..."}
                </div>
              </div>
              {/* {data.length &&
                data.map((chat) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection:
                          chat.username === user.displayName
                            ? "row-reverse"
                            : "row",
                      }}
                    >
                      <Typography style={{ padding: "5px" }}>
                        {chat.text}
                      </Typography>
                    </div>
                  );
                })} */}
              <form
                // className={clsx("pb-16 px-8 absolute bottom-0 left-0 right-0")}
                // onSubmit={sendMessage}
                style={{}}
              >
                <Paper
                  // className={clsx("flex items-center relative shadow")}
                  style={{
                    borderRadius: 0,
                    overflow: "hidden",
                  }}
                >
                  <Row
                    xs={12}
                    style={{
                      position: "relative",
                      paddingRight: 20,
                    }}
                  >
                    <TextField
                      multiline
                      maxRows={3}
                      style={{
                        border: "none",
                        background: "#d2d2d2",
                        display: "flex",
                        flex: 1,
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      autoFocus={false}
                      id="message-input"
                      className="flex-1"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: "flex flex-grow flex-shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8",
                          input: "",
                        },
                        placeholder: "Type your message",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        className: classes.bootstrapFormLabel,
                      }}
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                      value={message}
                    />

                    <IconButton
                      className="btn btn-primary-light"
                      // className="absolute ltr:right-0 rtl:left-0 top-0"
                      type="button"
                      style={{
                        position: "relative",
                        right: 0,
                        top: 0,
                        width: 50,
                        marginLeft: "15px",
                        height: 50,
                      }}
                      onClick={sendMessage}
                    >
                      <SendIcon color="action " />
                    </IconButton>
                  </Row>
                </Paper>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChatModal;
