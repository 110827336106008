import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as url from "../../../../_helper/url_helper";

const sliceName = "PRhistorySlice";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;
export const getHistorysDetails = createAsyncThunk(
  `${sliceName}/getHistoryDetails`,
  async (id) => {
    try {
      //get DPDV
      let getHistoryDetails = {};
      try {
        const getHistoryDetailsData = await axios.get(
          `${baseURL + url.HISTORY_BASE}/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        console.log("getHistoryDetails", getHistoryDetailsData);
        if (getHistoryDetailsData.data) {
          getHistoryDetails = getHistoryDetailsData?.data;
        }
      } catch (error) {
        return { getHistoryDetails: {} };
      }
      return {
        getHistoryDetails: getHistoryDetails,
      };
    } catch (e) {
      return {
        error: e.message,
        getHistoryDetails: {},
      };
    }
  }
);
export const updateHistory = createAsyncThunk(
  `${sliceName}/updateHistory`,
  async (ID, payload) => {
    try {
      //get DPDV
      let updateHistory = {};
      try {
        const updateHistoryData = await axios.put(
          `${baseURL}${url.HISTORY_BASE}/${ID}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (updateHistoryData.data) {
          updateHistory = updateHistoryData?.data;
        }
      } catch (error) {
        return { updateHistory: {} };
      }
      return {
        updateHistory: updateHistory,
      };
    } catch (e) {
      return {
        error: e.message,
        updateHistory: {},
      };
    }
  }
);
export const createHistory = createAsyncThunk(
  `${sliceName}/createHistory`,
  async (payload) => {
    try {
      //get DPDV
      let createHistory = {};
      try {
        const createHistoryData = await axios.post(
          baseURL + url.HISTORY_BASE,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (createHistoryData.data) {
          createHistory = createHistoryData?.data;
        }
      } catch (error) {
        return { createHistory: {} };
      }
      return {
        createHistory: createHistory,
      };
    } catch (e) {
      return {
        error: e.message,
        createHistory: {},
      };
    }
  }
);

const advanceSearchSlice = createSlice({
  name: sliceName,
  initialState: {
    history: {},
    postingResult: {},
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistorysDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHistorysDetails.fulfilled, (state, action) => {
        state.history = action.payload.getHistoryDetails;
        state.loading = false;
      })
      .addCase(getHistorysDetails.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createHistory.fulfilled, (state, action) => {
        state.postingResult = action.payload.createHistory;
      })
      .addCase(updateHistory.fulfilled, (state, action) => {
        state.postingResult = action.payload.updateHistory;
      });
  },
});

export default advanceSearchSlice.reducer;
