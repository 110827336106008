import * as React from "react";

import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormLabel from "@mui/material/FormLabel";
import moment from "moment";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import { Card } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";

import axios from "axios";
import { ShowError, ShowSucess } from "../../../../app/utility/helper";

const CopyTemplateModal = (props) => {
  const [inputFields, setInputFields] = useState([
    {
      selectedDay: "",
      time_from: "",
      time_to: "",
    },
  ]);

  useEffect(() => {
    if (props.open) {
      console.log("dasfsafdsfdsfsf", props.copyTemplate());
      setInputFields(props.copyTemplate());
    }
  }, [props.open]);
  const [weekDayData, setWeekDayData] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [templateTitle, setTemplateTitle] = useState();
  const [templateDetails, setTemplateDetails] = useState();
  const [valueRadio, setValueRadio] = React.useState("G");

  const handleChangeRadio = (e) => {
    setValueRadio(e.target.value);
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const inputProps = {
    style: { fontSize: 14, step: 300 },
    step: 300,
  };

  const handleChange = (index, data, key) => {
    console.log("index, data", index, data);

    let inputData = [...inputFields];
    inputData[index][`${key}`] = data;
    setInputFields(inputData);

    // setInputFields([{ ...inputFields, selectedDay: data }]);
  };
  const removeInputFields = (data, index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    console.log("index 456", index, data, rows);
    setInputFields(rows);
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        selectedDay: "",
        time_from: "",
        time_to: "",
      },
    ]);
  };

  // Sort the data array based on the custom order

  const addTimeSheet = () => {
    if (!templateTitle || templateTitle === "") {
      ShowError("Please Enter Title Value!");
      return false;
    }

    let newData = [];

    inputFields.map((obj, index) => {
      newData.push({
        Day: obj.selectedDay,
        From: moment(obj.time_from, "HH:mm").format("HH:mm:00"),
        To: moment(obj.time_to, "HH:mm").format("HH:mm:00"),
        SnNo: index,
      });
    });

    console.log("Data sheet : ", newData, templateTitle, valueRadio);

     
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/create`,
        {
          TimeSheetName: templateTitle,
          data: newData,
          global: valueRadio,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        setInputFields([
          {
            selectedDay: "",
            time_from: "",
            time_to: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Template</DialogTitle>
        <DialogContent>
          <div>
            {/* <TimeSheetHeader screen={"details"} /> */}
            <div style={{ padding: "16px 2px" }}>
              <Card style={{ gap: "10px" }} elevation={4} className=" p-10  ">
                {/* <h3>TimeSheet Template</h3> */}
                <TextField
                  size={"small"}
                  id="time"
                  label="Template Title"
                  // placeholder="Template Title"
                  type="text"
                  sx={{ width: "250px" }}
                  inputProps={inputProps}
                  value={templateTitle}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                    },
                    shrink: true,
                  }}
                  onChange={(e) => {
                    // handleChange(index, e.target.value, "time_from");
                    console.log("index eeee", e);
                    setTemplateTitle(e.target.value);
                  }}
                  margin="dense"
                  name="template_title"
                  fullWidth={true}
                  variant="outlined"
                  hiddenLabel="true"
                />
                <table style={{ width: "fit-content" }}>
                  <tr>
                    <td>Day</td>
                    <td>From Time</td>
                    <td>To Time</td>
                  </tr>
                  {inputFields.map((data, index) => {
                    const { fullName, emailAddress, salary } = data;
                    return (
                      <tr>
                        <td>
                          <Autocomplete
                            value={inputFields[index].selectedDay}
                            size="small"
                            onChange={(event, newValue) => {
                              handleChange(index, newValue, "selectedDay");
                              // console.log("Clicked... ", event.target.value, newValue);
                              // setInputFields({ ...inputFields, selectedDay: newValue });
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            // id="free-solo-with-text-demo"
                            id="controllable-states-demo"
                            options={weekDayData}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            // renderOption={(props, option) => (
                            //   console.log("props option", props, option),
                            //   (<li {...props}>{option}</li>)
                            // )}
                            sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField {...params} label="Choose Day" />
                            )}
                          />
                        </td>
                        <td>
                          <TextField
                            size={"small"}
                            id="time"
                            // label="From"
                            type="time"
                            sx={{ width: "200px" }}
                            inputProps={inputProps}
                            // error={ole1 ? true : false}
                            InputLabelProps={{
                              style: {
                                fontSize: 14,
                              },
                              shrink: true,
                            }}
                            value={inputFields[index].time_from}
                            onChange={(e) => {
                              handleChange(index, e.target.value, "time_from");
                              console.log("index eeee", index, e);
                            }}
                            margin="dense"
                            name="time_from"
                            fullWidth={true}
                            variant="outlined"
                            hiddenLabel="true"
                          />
                        </td>
                        <td>
                          <TextField
                            size={"small"}
                            id="time"
                            // label="From"
                            type="time"
                            sx={{ width: "200px" }}
                            inputProps={inputProps}
                            InputLabelProps={{
                              style: {
                                fontSize: 14,
                              },
                              shrink: true,
                            }}
                            value={inputFields[index].time_to}
                            onChange={(e) => {
                              handleChange(index, e.target.value, "time_to");
                            }}
                            margin="dense"
                            name="time_to"
                            fullWidth={true}
                            variant="outlined"
                            hiddenLabel="true"
                          />
                        </td>
                        <td>
                          {inputFields.length !== index + 1 ? (
                            <button
                              className="btn btn-outline-danger"
                              onClick={() => removeInputFields(data, index)}
                            >
                              x
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-success"
                              onClick={addInputField}
                            >
                              +
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </table>

                <div>
                  <Radio
                    checked={valueRadio === "G"}
                    onChange={handleChangeRadio}
                    value="G"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <b>Global</b>
                  <Radio
                    checked={valueRadio == props.EmpID}
                    onChange={handleChangeRadio}
                    value={props.EmpID}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />{" "}
                  <b>Assign To Employee {props.EmpID}</b>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <Button
                      variant="contained"
                      onClick={addTimeSheet}
                      style={{
                        textTransform: "none",
                        color: "white",
                      }}
                      className="bg-primary"
                    >
                      Add Template
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CopyTemplateModal;
