import React, { useEffect, useState } from "react";

// import {
//   KeyboardDatePicker,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers";
import { Card, CardContent, Grid, Typography, TextField } from "@mui/material";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import MaskedInput from "react-text-mask";
import { getReportdeductionGovVsEmployee } from "../../../../app/utility/axiosService";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";

import HighchartsReact from "highcharts-react-official";
HighchartsSankey(Highcharts);

function EmpVsGovt(props) {
  var pieColors = (function () {
    var colors = [],
      base = "#4ebfa9",
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 7)
          .get()
      );

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let [sankeyData, setSankeyData] = useState([{ options: [], series: [] }]);
  const barChart = async () => {
    let rData = await getReportdeductionGovVsEmployee();
    // let rData = [];
    console.log("color", pieColors);
    let series_name = "Pay Types";
    let chart_title = "Distribution Share Employee Vs Goverment";
    let series_data = [
      {
        name: "Employee Share",
        y: rData[0]["EmployeeShare"],
        color: "rgb(78,191,169)",
      },
      {
        name: "Goverment Share",
        y: rData[0]["GovtShare"],
        color: "rgb(6,119,97)",
      },
    ];
    let opPie = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: chart_title,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          color: pieColors,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: series_data,
        },
      ],
    };

    setSankeyData(opPie);
  };
  useEffect(() => {
    barChart();
  }, []);
  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-10 mb-20">
        <div>
          <Card>
            <CardContent>
              {/* <Typography variant="h6" component="b" color="text.secondary" gutterBottom>
                Employee Vs Goverment Share Comparison
                </Typography> */}
              <Typography component="div">
                <HighchartsReact highcharts={Highcharts} options={sankeyData} />
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default EmpVsGovt;
