import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import MaskedInput from "react-text-mask";
import Highcharts, { numberFormat } from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";

import HighchartsReact from "highcharts-react-official";

import {
  getReportdeductionGovVsEmployee,
  getReportDepartmentwiseDeduction,
} from "../../../app/utility/axiosService";
import { async } from "regenerator-runtime";
HighchartsSankey(Highcharts);

function DepartmentDeduction(props) {
  var pieColors = (function () {
    var colors = [],
      base = "#4ebfa9",
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 4)
          .get()
      );

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let [sankeyData, setSankeyData] = useState([{ options: [], series: [] }]);

  const barChart = async () => {
    let rData = await getReportDepartmentwiseDeduction();
    console.log("rdata", rData);
    let series_name = "Pay Types";
    let chart_title = "Distribution Share Employee Vs Goverment";
    let series_data = [2, 44, 55];
    let dept = rData.map((i) => {
      return i.Department;
    });

    let empshare = rData.map((i) => {
      return i.EmployeeShare;
    });
    let govtshare = rData.map((i) => {
      return i.GovtShare;
    });

    let myOptions = {
      chart: {
        type: "column",
      },
      title: {
        text: "Employee Vs Govt Share By Department",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: dept,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Amount ($)",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      colors: pieColors,
      series: [
        {
          name: "Employee Share",
          data: empshare,
        },
        {
          name: "Goverment Share",
          data: govtshare,
        },
      ],
    };
    setSankeyData(myOptions);
  };
  useEffect(() => {
    barChart();
  }, []);

  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-10 mb-20">
        <div>
          <Card>
            <CardContent>
              {/* <Typography variant="h6" component="b" color="text.secondary" gutterBottom>
                Employee Vs Goverment Share Comparison
                </Typography> */}
              <Typography component="div">
                <HighchartsReact highcharts={Highcharts} options={sankeyData} />
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DepartmentDeduction;
