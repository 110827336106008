import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCalendar,
  faQrcode,
} from "@fortawesome/pro-duotone-svg-icons";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";

import FilterList from "./filterList";

import TableComponent from "../../ConfigurationMaster/DeductionPlanMaster/tableComponent";

import { useDispatch } from "react-redux";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../app/utility/helper";
import QRCodeModal from "./QRCodeModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const visibleColumnArray = [
  "SSN",
  "ID",
  "EmployeeName",
  "Nposition_No",
  "DPDV",
  "Military_Status",
  "Hm_Phone",
  "Ml_Wk_Phone",
  "Email",
  "Inactive_Status_Title",
  "Emp_Status_Title",
];

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));
const customStyles = {
  exportButton: {
    display: "none",
  },
  header: {
    rows: [
      {
        columns: [
          {
            formatAs: (column, index) =>
              console.log("column 123", column, index),
          },
        ],
      },
    ],
  },
};

const DataTable = (props) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState({
    ID: null,
    NAME: "",
  });

  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };
  const dispatch = useDispatch();
  const history = useNavigate();
  const [CSVPrint, setCSVPrint] = useState(visibleColumnArray);
  console.log("CSVPrint", CSVPrint);
  const columnsName = useSelector(
    (state) => state?.querySlice?.colsPlaceholder
  );
  console.log("columns name", columnsName);
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    // if(name.toString().toLowerCase()==="status"){

    //   if(value.toString().toLowerCase().startwith==='in'){
    //     setFilter({ ...filterBy, [name]: false });

    //   }else {
    //     setFilter({ ...filterBy, [name]: true });

    //   }

    // }else{
    setFilter({ ...filterBy, [name]: value });

    // }
  };

  const [visibleColumn, setVisibleColumn] = useState([]);
  const [newArray, setNewArray] = useState(requestDetail);
  console.log("visibleColumn", visibleColumn);

  useEffect(() => {
    let updatedColumns = columnsName?.map((item) => {
      const columnIndex = visibleColumnArray?.indexOf(item.column_name);

      return {
        id: item.column_name,
        name: item.column_name,
        label: item.column_title,
        order: columnIndex === -1 ? 1000 : columnIndex,
        sortable: true,

        options: {
          display: CSVPrint?.some((i) => i == item.column_name),
          download: CSVPrint?.some((i) => i === item.column_name),
        },
      };
    });

    setTimeout(() => {
      updatedColumns.splice(0, 0, {
        id: "Actions",
        name: "ID",
        label: "Actions",
        // width: "70px",
        sort: "false",
        minWidth: "90px",
        order: 0,
        options: {
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Tooltip title="Employee Details" placement="bottom">
                  <FontAwesomeIcon
                    // className="sub-header"
                    onClick={() => {
                      // return history(`update/${tableMeta.ID}`);
                      return history(`/admin/employee/update/${value}`);
                    }}
                    icon={faEye}
                    style={{
                      cursor: "pointer",
                      color: "#1569C7",
                    }}
                  />
                </Tooltip>

                <Tooltip title="View Time Sheet" placement="bottom">
                  <FontAwesomeIcon
                    onClick={() => {
                      return history(`/payroll-work/${value}`);
                    }}
                    // className="sub-header"
                    icon={faCalendar}
                    style={{
                      cursor: "pointer",
                      color: "#1569C7",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
                <Tooltip title="QR Code" placement="bottom">
                  <FontAwesomeIcon
                    onClick={() => handleModal(value, tableMeta?.rowData[3])}
                    className="sub-header"
                    icon={faQrcode}
                    style={{
                      cursor: "pointer",
                      color: "#1569C7",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        },
      });

      updatedColumns.sort(function (a, b) {
        var keyA = new Date(a.order),
          keyB = new Date(b.order);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      setVisibleColumn(updatedColumns);
    }, 0);
  }, [columnsName, CSVPrint]);

  useEffect(() => {
    const updatedArray = requestDetail.map((i) => {
      return { ...i, Annual_Salary: formatMoney(i.Annual_Salary) };
    });

    setNewArray(updatedArray);
  }, [requestDetail]);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data && data.EmployeeID
            ? data.EmployeeID.toLowerCase().includes(
                filterBy.EmployeeID?.toLowerCase()
              )
            : data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLowerCase()
          )
        );
      }
      if (filterBy?.SSN) {
        dataList = dataList.filter((data) =>
          data.SSN.toLowerCase().includes(filterBy.SSN.toLowerCase())
        );
      }
      if (filterBy?.Status) {
        dataList = dataList.filter((data) =>
          data.Status.toLowerCase().includes(filterBy.Status.toLowerCase())
        );
      }
      if (filterBy?.Department) {
        dataList = dataList.filter((data) =>
          data.Description
            ? data.Description
            : data.DPDV_DepartmentDivisionMaster.Description.toLowerCase().includes(
                filterBy.Department.toLowerCase()
              )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const DownLoadCsv = (data) => {
    alert("Sasa");
  };

  const handleRowClick = (data) => {
    console.log("ON CLICK dd :::: ", data);
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));
    let info = {
      id: data.ID,
      code: data.Code,
      description: data.Description,
    };
    props.setDetail(info);
  };

  const columns = [
    {
      id: "Actions",
      name: "ID",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    return history(`update/${value}`);
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>

              <Tooltip title="View Time Sheet" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(`/payroll-work/${tableMeta.ID}`);
                  }}
                  className="sub-header"
                  icon={faCalendar}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      id: "ID",
      name: "ID",
      label: "ID",

      // type: "",
      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return (
      //       <Typography variant="subtitle2">
      //         {tableMeta.EmployeeID ? tableMeta.EmployeeID : tableMeta.ID}
      //       </Typography>
      //     );
      //   },
      // },
    },
    {
      id: "EmployeeName",
      name: "EmployeeName",
    },
    {
      id: "SSN",
      name: "SSN",
    },
    {
      id: "DPDV",
      name: "DPDV",
      label: "Department",

      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return (
      //       <Typography variant="subtitle2">
      //         {value?.Description}
      //         {/* {value?.Description
      //           ? JSON.stringify(value?.Description)
      //           : tableMeta?.Description
      //           ? JSON.stringify(tableMeta?.Description)
      //           : ""} */}
      //       </Typography>
      //     );
      //   },
      // },
    },
    {
      id: "Emp_Status",
      name: "Emp_Status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="subtitle2">
              {value === "I" ? "InActive" : "Active"}
              {/* {value?.Description
                ? JSON.stringify(value?.Description)
                : tableMeta?.Description
                ? JSON.stringify(tableMeta?.Description)
                : ""} */}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    display: false,

    onViewColumnsChange: (changedColumn, action, option) => {
      console.log("option", option);

      if (action === "add") {
        setCSVPrint([...CSVPrint, changedColumn]);
      } else {
        setCSVPrint(CSVPrint.filter((i) => i !== changedColumn));
      }
    },
  };

  return (
    // <TableComponent
    //   columns={columns}
    //   filteredList={filteredList}
    //   onRowClick={() => {}}
    //   tableClass={classes.scrollHeight}
    //   filterComponent={
    //     filteredList && (
    //       <FilterList
    //         changeHeaderFilter={changeHeaderFilter1}
    //         role={role}
    //         requestDetail={requestDetail}
    //         filterBy={filterBy}
    //       />
    //     )
    //   }
    // />
    <>
      <div style={{ width: "100%" }}>
        <MUIDataTable
          className={classes.customData}
          title={<Typography variant="h6">Employee List</Typography>}
          data={newArray}
          columns={visibleColumn}
          options={options}
          selectableRowsHideCheckboxes={true}
        />
      </div>

      {openModal && (
        <div>
          <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0",
                }}
              >
                <h6 className="mt-2">
                  {employeeInfo?.NAME ? employeeInfo.NAME + " 'S QR Code" : ""}
                </h6>
                <div>
                  <IconButton onClick={handleModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <QRCodeModal employeeInfo={employeeInfo} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default DataTable;
