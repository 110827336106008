import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "configurationSlice";

export const fetchAdvanceSearchData = createAsyncThunk(
  `${sliceName}/fetchAdvanceSearchData`,
  async () => {
    try {
      //get DPDV
      let DPDVList = [];
      try {
        const dataDPDV = await axios.get("https://api.ehrpweb.com/api/depdev", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        });

        if (dataDPDV.data.length) {
          console.log("DPDV", dataDPDV.data);
          dataDPDV.data.map((item) => {
            DPDVList.push({
              value: item.Code,
              label: item.Description,
            });
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });
        }
      } catch (error) {
        return DPDVList;
      }
      //get Employee Types
      let EmpTypeList = [];
      try {
        const dataEmpType = await axios.get(
          `${process.env.REACT_APP_API_URL}employeetypes`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        );

        if (dataEmpType.data.length) {
          console.log("DPDV", dataEmpType.data);
          dataEmpType.data.map((item) => {
            EmpTypeList.push({
              value: item.TypeCode,
              label: item.Description,
            });
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });
        }
      } catch (error) {
        return EmpTypeList;
      }

      let Education = [];
      let EmpStatus = [];
      let EmpType = [];
      let Inactive_Status = [];
      let Ethnic_Group = [];
      let Nposition_No = [];
      let Pay_Grade = [];
      let Pay_Step = [];
      let Military_Status = [];
      
      try {
        const allOptions = await axios.get(
          `${process.env.REACT_APP_API_URL}optionsmaster`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        );

        if (allOptions?.data) {
          console.log("allOptions", allOptions.data);
          allOptions?.data?.Education?.map((item) => {
            Education.push({
              value: item.Education,
              label: item.Description,
            });
          });
          allOptions?.data?.Emp_Status?.map((item) => {
            EmpStatus.push({
              value: item.EMP_STATUS,
              label: item.DESCRIPTION,
            });
          });
          allOptions?.data?.Emp_Type?.map((item) => {
            EmpType.push({
              value: item.EMPL_TYPE,
              label: item.DESCRIPTION,
            });
          });
          allOptions?.data?.Inactive_Status?.map((item) => {
            Inactive_Status.push({
              value: item.DESCRIPTION,
              label: item.DESCRIPTION,
            });
          });
          allOptions?.data?.Ethnic_Group?.map((item) => {
            Ethnic_Group.push({
              value: item.ETHNIC_CODE,
              label: item.ETHNIC_TITLE,
            });
          });
          allOptions?.data?.Nposition_No?.map((item) => {
            Nposition_No.push({
              value: item.TITLE,
              label: item.TITLE,
              code : item.NPOSITION_NO,
            });
          });
          allOptions?.data?.Pay_Grade?.map((item) => {
            Pay_Grade.push({
              value: item.PAY_GRADE,
              label: item.PAY_GRADE,
            });
          });
          allOptions?.data?.Pay_Step?.map((item) => {
            Pay_Step.push({
              value: item.PAY_STEP,
              label: item.PAY_STEP,
              payGrade: item.PAY_GRADE,
            });
          });

          allOptions?.data?.Military_Status?.map((item) => {
            Military_Status.push({
              value: item.Military_Code,
              label: item.Military_Title,
            });
          });
        }
      } catch (error) {}

      let resultArray = {
        DPDV: DPDVList,
        EmpTypeList: EmpTypeList,
        Education: Education,
        EmpStatus: EmpStatus,
        Nposition_No: Nposition_No,
        EmpType: EmpType,
        Inactive_Status: Inactive_Status,
        Ethnic_Group: Ethnic_Group,
        Pay_Grade: Pay_Grade,
        Pay_Step: Pay_Step,
        Military_Status: Military_Status,

        doc_type: [
          { value: "COURT_RECORD", label: "COURT RECORD" },
          { value: "DLM_RECORD", label: "DLM RECORD" },
        ],
      };

      return {
        advanceSearchData: resultArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const fetchLeaveHours = createAsyncThunk(
  `${sliceName}/leaveHours`,
  async () => {
    try {
      //get DPDV
      let dataLeaveHours = [];
      try {
        const dataLeaveHour = await axios.get("https://api.ehrpweb.com/api/leavehours", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        });

        if (dataLeaveHour.data.length) {
         
          dataLeaveHour.data.map((item) => {
            dataLeaveHours.push(item);
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });
        }
      } catch (error) {

        return dataLeaveHours;
        
      }
      //get Employee Types
       

      return {
        leaveHour: dataLeaveHours,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const HolidaysData = createAsyncThunk(
  `${sliceName}/holiday`,
  async (payload) => {
    try {
      //get DPDV
      let Holiday = [];
      try {
        const dataHoliday = await axios.get(`${process.env.REACT_APP_WEB_API_URL}Employee/GetAllHolidays?MaxCount=365&SkipCount=0&Year=${payload}`,         {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        });
        
        if (dataHoliday.data.data.length) {
         
         
          dataHoliday.data.data.map((item) => {
             
            Holiday.push(item);
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });
        }
      } catch (error) {
        return Holiday;
      }
      //get Employee Types
       

      return {
        holidays: Holiday,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);



const configurationSlice = createSlice({
  name: sliceName,
  initialState: {
    advanceSearchData: [],
    leaveHours: [],
    holidayData: []
  },
  extraReducers: {
    [fetchAdvanceSearchData.fulfilled]: (state, action) => {
      state.advanceSearchData = action.payload.advanceSearchData;
    },
    [fetchLeaveHours.fulfilled]: (state, action) => {
      state.leaveHours = action.payload.leaveHour;
    },
    [HolidaysData.fulfilled]: (state, action) => {
      state.holidayData = action.payload.holidays;
    },
  },
});

export default configurationSlice.reducer;
