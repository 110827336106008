import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { Grid } from '@mui/material';
import { DEDUCTION_PLAN_INITIAL_DATA, VALIDATION_SCHEMA_DEDUCTION_PLAN_FORM } from './Constants';

import CustomTextField from '../../../CommonComponents/FormComponents/TextField';
import DatePicker from '../../../CommonComponents/FormComponents/DatePicker';
import CustomAutocomplete from '../../../CommonComponents/FormComponents/Autocomplete';
import CustomButton from '../../../CommonComponents/FormComponents/Button';
import CustomCheckBox from '../../../CommonComponents/FormComponents/CheckBox';
import { LoadingPanel } from '../../../CommonComponents/CommonFunction';
import { useParams } from 'react-router-dom';
import { getAllDeductionMethodList, getAllPaymentType, getDeductionType } from '../../../app/utility/axiosService';
import { useDispatch, useSelector } from 'react-redux';
import { getVendors } from '../../../app/store/reducers/vendor/slice';
import { createOrUpdateDeductionPlanMaster, getDeductionPlanById, resetDeductionPlan } from '../../../app/store/reducers/DeductionPlan/slice';
import _ from 'lodash';

const CreateUpdateForm = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [deductionTypes, setDeductionTypes] = useState({ data: [], isLoading: false });
    const [paymentTypes, setPaymentTypes] = useState({ data: [], isLoading: false });
    const [deductionMethods, setDeductionMethods] = useState({ data: [], isLoading: false });

    const { vendorList, loading: isVendorListLoading } = useSelector(({ vendor }) => vendor);
    const { isCreating: isCreaingOrUpdating, deductionPlan, isFetching } = useSelector(({ deductionPlan }) => deductionPlan);

    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: {
            ...DEDUCTION_PLAN_INITIAL_DATA
        }
    });

    const hanldeVendorChange = (evt) => {
        const selectedVendor = vendorList?.find(e => e?.vendorNo == evt);
        setValue('vendorSeq', (selectedVendor?.seq || selectedVendor?.vendorSeq) || null);
        setValue('accountNoForVendor', selectedVendor?.vendorAccountNo || null);
    }

    const hanldeDeductionTypeChange = (evt) => {
        const selectedDeductionType = deductionTypes?.data?.find(e => (e?.id == evt || e?.code == evt || e?.description == evt || _.toString(e?.code) == evt));
        setValue('deductionType', selectedDeductionType?.code);
        // setValue('deductionCode', selectedDeductionType?.code);
        setValue('deductionTypeId', selectedDeductionType?.code);
    }

    const fields = [
        {
            name: 'deductionTypeId',
            label: 'Deduction Type',
            placeholder: 'Deduction Type',
            errorMessage: error['deductionTypeId'],
            component: CustomAutocomplete,
            onChange: hanldeDeductionTypeChange,
            options: deductionTypes?.data || [],
            isLoading: deductionTypes?.isLoading,
            keyName: "code",
            labelName: "description",
            gridSizes: { xs: 12, sm: 4 },
        },
        // {
        //     name: 'deductionCode',
        //     label: 'Deduction Code',
        //     placeholder: 'Deduction Code',
        //     errorMessage: error['deductionCode'],
        //     component: CustomTextField,
        //     gridSizes: { xs: 12, sm: 4 },
        //     disabled: true
        // },
        {
            name: 'deductionName',
            label: 'Deduction Name',
            placeholder: 'Deduction Name',
            errorMessage: error['deductionName'],
            component: CustomTextField,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'startDate',
            label: 'Start Date',
            placeholder: 'MM/DD/YYYY',
            dateFormat: 'MM/DD/YYYY',
            errorMessage: error['startDate'],
            component: DatePicker,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'endDate',
            label: 'End Date',
            placeholder: 'MM/DD/YYYY',
            dateFormat: 'MM/DD/YYYY',
            errorMessage: error['endDate'],
            component: DatePicker,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'paymentMonthlySch',
            label: 'Payment Monthly Schedule',
            placeholder: 'Payment Monthly Schedule',
            errorMessage: error['paymentMonthlySch'],
            component: CustomAutocomplete,
            options: paymentTypes?.data,
            isLoading: paymentTypes?.isLoading,
            keyName: "name",
            labelName: "name",
            gridSizes: { xs: 12, sm: 4 },
            gridClassName: 'd-flex align-items-center',
        },
        {
            name: 'deductionMethod',
            label: 'Deduction Method',
            placeholder: 'Deduction Method',
            errorMessage: error['deductionMethod'],
            component: CustomAutocomplete,
            options: [
                { id: "Fix", label: "Fix" },
                { id: "PCTGross", label: "PCTGross" }
            ],
            keyName: "id",
            labelName: "label",
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'deductionValue',
            label: 'Deduction Value',
            placeholder: 'Deduction Value',
            errorMessage: error['deductionValue'],
            component: CustomTextField,
            type: "number",
            min: 0,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'deductionPlanStatus',
            label: 'Deduction Plan Status',
            placeholder: 'Deduction Plan Status',
            errorMessage: error['deductionPlanStatus'],
            component: CustomAutocomplete,
            options: [
                { id: 1, label: "Submitted" },
                { id: 2, label: "Active" },
                { id: 3, label: "In Active" }
            ],
            keyName: "id",
            labelName: "label",
            gridSizes: { xs: 12, sm: 4 },
            labelPlacement: 'start',
        },
        {
            name: 'applys_To_OT',
            label: 'Applys To OT',
            placeholder: 'Applys To OT',
            errorMessage: error['applys_To_OT'],
            component: CustomAutocomplete,
            options: [
                { id: 1, label: "Yes" },
                { id: 0, label: "No" },
            ],
            keyName: "id",
            labelName: "label",
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'arrearsBase',
            label: 'Arreas Base',
            placeholder: 'Arreas Base',
            errorMessage: error['arrearsBase'],
            component: CustomAutocomplete,
            options: [
                { id: "Yes", label: "Yes" },
                { id: "No", label: "No" },
            ],
            keyName: "id",
            labelName: "label",
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'totalAmountPlanDue',
            label: 'Total Amount Plan Due',
            placeholder: 'Total Amount Plan Due',
            errorMessage: error['totalAmountPlanDue'],
            component: CustomTextField,
            type: "number",
            min: 0,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'vendorNo',
            label: 'Vendor',
            placeholder: 'Vendor',
            errorMessage: error['vendorNo'],
            component: CustomAutocomplete,
            onChange: hanldeVendorChange,
            options: vendorList || [],
            isLoading: isVendorListLoading,
            keyName: "vendorNo",
            labelName: "vendorName",
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'accountNoForVendor',
            label: 'Account No For Vendor',
            placeholder: 'Account No For Vendor',
            errorMessage: error['accountNoForVendor'],
            component: CustomTextField,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'govermentDeductionAmount',
            label: 'Goverment Deduction Amount',
            placeholder: 'Goverment Deduction Amount',
            errorMessage: error['govermentDeductionAmount'],
            component: CustomTextField,
            type: "number",
            min: 0,
            gridSizes: { xs: 12, sm: 4 },
        },
        {
            name: 'taxable',
            label: 'Taxable',
            placeholder: 'Taxable',
            errorMessage: error['taxable'],
            component: CustomCheckBox,
            gridSizes: { xs: 12, sm: 1 },
            labelPlacement: 'start',
        },
        {
            name: 'isActive',
            label: 'Is Active',
            placeholder: 'Is Active',
            errorMessage: error['isActive'],
            component: CustomCheckBox,
            gridSizes: { xs: 12, sm: 1 },
            labelPlacement: 'start',
        },
        {
            name: 'isEmployeeModifiable',
            label: 'Is Employee Modifiable',
            placeholder: 'Is Employee Modifiable',
            errorMessage: error['isEmployeeModifiable'],
            component: CustomCheckBox,
            gridSizes: { xs: 12, sm: 2 },
            labelPlacement: 'start',
        },
    ];

    useEffect(() => {
        fetchAllDeductionTypes();
        fetchAllPaymentTypes();
        fetchAllDeductionMethods();
        dispatch(getVendors());
        !!params?.id && dispatch(getDeductionPlanById(params?.id));

        return () => {
            dispatch(resetDeductionPlan());
        }
    }, []);

    useEffect(() => {
        if (deductionTypes?.data.length && vendorList.length && !!Object.keys(deductionPlan).length) {
            reset({ ...deductionPlan });
            hanldeDeductionTypeChange(deductionPlan?.deductionType);
        }
    }, [deductionTypes, vendorList, deductionPlan])

    const fetchAllDeductionTypes = async () => {
        setDeductionTypes({ data: [], isLoading: true });
        const res = await getDeductionType();
        setDeductionTypes({ data: res, isLoading: false });
    };

    const fetchAllPaymentTypes = async () => {
        setPaymentTypes({ data: [], isLoading: true });
        const res = await getAllPaymentType();
        setPaymentTypes({ data: res, isLoading: false });
    }

    const fetchAllDeductionMethods = async () => {
        setDeductionMethods({ data: [], isLoading: true });
        const res = await getAllDeductionMethodList();
        setDeductionMethods({ data: res, isLoading: false });
    }

    const onSubmit = async (data) => {
        try {
            await VALIDATION_SCHEMA_DEDUCTION_PLAN_FORM.validate(data, { abortEarly: false });

            setError({});
            let transformData = {
                ...data,
                startDate: dayjs(data?.startDate).toISOString(),
                endDate: dayjs(data?.endDate).toISOString(),
            };
            dispatch(createOrUpdateDeductionPlanMaster(transformData));
        } catch (error) {
            const validationErrors = {};
            error.inner.forEach((fieldError) => {
                validationErrors[fieldError.path] = fieldError.message;
            });
            setError(validationErrors);
        }
    };

    const handleReset = () => {
        setError({});
        reset({ ...DEDUCTION_PLAN_INITIAL_DATA, ...deductionPlan });
    }

    return (
        <Fragment>
            {(isCreaingOrUpdating || isFetching) && <LoadingPanel />}
            {
                (isVendorListLoading || deductionTypes?.isLoading || paymentTypes?.isLoading)
                    ? <LoadingPanel />
                    : <form onSubmit={handleSubmit}>
                        <Grid container xs={12} sm={12} spacing={1}>

                            {fields.map(({ component: Cmp, gridSizes, gridClassName, onChange: customOnChange = () => { }, errorMessage, ...field }) => (
                                <Grid key={field.name} item {...gridSizes} className={`${gridClassName}`}>
                                    <Controller
                                        name={field.name}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Cmp
                                                onChange={(evt) => {
                                                    customOnChange(evt);
                                                    onChange(evt);
                                                }}
                                                value={value}
                                                {...field}
                                                errorMessage={!value && errorMessage}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item xs={12} sm={12} display={'flex'} justifyContent={'center'} marginTop={5} gap={2}>
                            <CustomButton
                                onClick={handleSubmit(onSubmit)}
                                text={'Submit'}
                            />
                            <CustomButton
                                text={"Reset"}
                                onClick={handleReset}
                            />
                        </Grid>
                    </form>
            }
        </Fragment>
    )
};

export default CreateUpdateForm;
