import React, { useState } from "react";
// import TextField from "@mui/material/TextField";
import { Col, Label, Row } from "reactstrap";
import { Checkbox, TextField } from "@mui/material";
import { floatNumberRegex } from "../../../../app/utility/helper";

function FederalTaxManage(props) {
    const [maxRangeError, setMaxRangeError] = useState(false);
    return (
        <Row style={{ width: "100%" }} className="g-3">
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                    }}
                    label="Min Range"
                    value={props.detail.minRange}
                    onChange={(e) => {
                        if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                            props.setDetail({ ...props.detail, minRange: e.target.value });
                            if (props.detail?.maxRange && Number(e.target.value) > Number(props.detail?.maxRange)) setMaxRangeError(true);
                            else setMaxRangeError(false);
                            // if (e.target.value === "") {
                            //     setMaxRangeError(false);
                            // }
                        }
                    }}
                    name="minRange"
                />
            </Col>
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                    }}
                    helperText={maxRangeError ? "Max range should be greater than min range" : null}
                    error={maxRangeError}
                    label="Max Range"
                    value={props.detail.maxRange}
                    onChange={(e) => {
                        if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                            props.setDetail({ ...props.detail, maxRange: e.target.value });
                            if (props.detail?.minRange && Number(props.detail?.minRange) > Number(e.target.value)) setMaxRangeError(true);
                            else setMaxRangeError(false);
                        } else setMaxRangeError(true);
                    }}
                    name="maxRange"
                />
            </Col>
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                    }}
                    label="Rate"
                    value={props.detail.rate}
                    onChange={(e) => {
                        if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                            props.setDetail({ ...props.detail, rate: e.target.value });
                        }
                    }}
                    name="eName"
                />
            </Col>
            <Col md="6" className="isActiveAlignCenter">
                <Label className="labelColor isActiveLabel">Is Single</Label>
                <Checkbox
                    checked={props.detail.isSingle}
                    onChange={(e) => {
                        props.setDetail({
                            ...props.detail,
                            isSingle: !props.detail.isSingle,
                        })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Col>
        </Row>
    );
}

export default FederalTaxManage;
