import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faMessageExclamation, faSave, faClose } from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";

import { toast } from 'react-toastify';


import moment from "moment";
import { CardHeader } from "reactstrap";


function HomeHeader(props) {
  let dispatch = useDispatch();
  const checkvalid = () => {
    
    if (props.detail.code == "" || props.detail.description == "") {
      toast.error('Please validate values!', {
        position: toast.POSITION.TOP_RIGHT
      })
      return false;
    } else {
      return true;
    }

  }
  const clearField = () => {

    props.setDetail({
      "id": "",
      "code": '',
      "description": ''
    });
  }
  const saveData = () => {
    if (!checkvalid()) {
      console.log('props.deta xxxx1')
      return false;
    }


    let paypload = {
      "code": props.detail.code,
      "description": props.detail.description
    }
    if (props.detail.id) {
      paypload = {
        "code": props.detail.code,
        "description": props.detail.description
      }
      axios
        .put(
          `${process.env.REACT_APP_API_URL}depdev/update/${props.detail.id}`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          toast.success('Updated successfully!', {
            position: toast.POSITION.TOP_RIGHT
          })
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}depdev/create`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          toast.success('Added successfully!', {
            position: toast.POSITION.TOP_RIGHT
          })
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    }

  };



  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faAddressCard}
            className="mx-10  px-3 fs-4"
          />
          <Typography className="fs-5">Department Management</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.id ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.id ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={clearField}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;
