import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMessageExclamation,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { showMessage } from "../../../app/store/actions";
import { CardHeader } from "reactstrap";
import { addOrUpdateDeductionPlanMasterRecord } from "../../../app/utility/axiosService";
import { toast } from "react-toastify";

function HomeHeader(props) {
  const dispatch = useDispatch();
  const checkvalid = () => {
    const {
      deductionType,
      deductionCode,
      deductionName,
      startDate,
      endDate,
      paymentMonthlySch,
      taxable,
      deductionMethod,
      deductionValue,
      totalAmountPlanDue,
      totalAmountPaid,
      accountNoForVendor,
      deductionPlanStatus,
      isActive,
      applysToOT,
      arrearsBase,
    } = props?.detail;
    if (
      !deductionType ||
      !deductionCode ||
      !deductionName ||
      !startDate ||
      !endDate ||
      !paymentMonthlySch ||
      (taxable !== false && taxable !== true) ||
      !deductionMethod ||
      !deductionValue ||
      !totalAmountPaid ||
      !deductionPlanStatus ||
      (isActive !== false && isActive !== true) ||
      !arrearsBase
    ) {
      toast.error("Please validate field");
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    console.log("props", props);
    if (props.data !== null && Object.keys(props.data).length > 0) {
      const {
        DeductionCode,
        DeductionName,
        DeductionType,
        StartDate,
        EndDate,
        IsActive,
        TotalAmountPaid,
        TotalAmountPlanDue,
        DeductionValue,
        DeductionMethod,
        DeductionPlanStatus,
        ArrearsBase,
        ApplysToOT,
        AccountNoForVendor,
        Taxable,
        PaymentMonthlySch,
        ...rest
      } = props.data;
      props.setDetail({
        id: props.data.Id,
        deductionType: DeductionType,
        deductionCode: DeductionCode,
        deductionName: DeductionName,
        startDate: StartDate,
        endDate: EndDate,
        paymentMonthlySch: PaymentMonthlySch,
        taxable: Taxable,
        deductionMethod: DeductionMethod,
        deductionValue: DeductionValue,
        totalAmountPlanDue: TotalAmountPlanDue,
        totalAmountPaid: TotalAmountPaid,
        accountNoForVendor: AccountNoForVendor,
        deductionPlanStatus: DeductionPlanStatus,
        isActive: IsActive,
        applysToOT: ApplysToOT,
        arrearsBase: ArrearsBase,
      });
    }
  }, []);
  const clearField = () => {
    props.setDetail({
      id: null,
      deductionType: "",
      deductionCode: "",
      deductionName: "",
      startDate: null,
      endDate: null,
      paymentMonthlySch: "",
      taxable: false,
      deductionMethod: "",
      deductionValue: "",
      totalAmountPlanDue: "",
      totalAmountPaid: "",
      accountNoForVendor: "",
      deductionPlanStatus: "",
      isActive: false,
      applysToOT: "",
      arrearsBase: "",
    });
  };

  const saveData = async () => {
    if (!checkvalid()) {
      return false;
    }

    let payload = {
      id: props.detail?.id ?? 0,
      deductionType: props?.detail?.deductionType,
      deductionCode: props?.detail?.deductionCode,
      deductionName: props?.detail?.deductionName,
      startDate: props?.detail?.startDate,
      endDate: props?.detail?.endDate,
      paymentMonthlySch: props?.detail?.paymentMonthlySch,
      taxable: props?.detail?.taxable,
      deductionMethod: props?.detail?.deductionMethod,
      deductionValue: props?.detail?.deductionValue,
      totalAmountPlanDue: props?.detail?.totalAmountPlanDue
        ? props?.detail.totalAmountPlanDue
        : null,
      totalAmountPaid: props?.detail?.totalAmountPaid,
      accountNoForVendor: props?.detail?.accountNoForVendor
        ? props?.detail?.accountNoForVendor
        : null,
      deductionPlanStatus: props?.detail?.deductionPlanStatus,
      isActive: props?.detail?.isActive,
      dataType: props?.detail?.dataType,
      arrearsBase: props?.detail?.arrearsBase,
      applysToOT: props?.detail?.applysToOT ? props?.detail?.applysToOT : null,
    };

    const res = await dispatch(addOrUpdateDeductionPlanMasterRecord(payload));
    if (res.payload.status === 200) {
      toast.success("Record Updated.");
      // props.getData();
      clearField();
      props.setVisible(false);
    } else if (res?.payload.status === 208) {
      toast.warn("Already Code Existed");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <CardHeader>
      <div>
        {/* <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        > */}
        {/* <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Deduction Plan Management</Typography> */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className="bg-primary"
            // className="m-10 mx-2 xs:mx-10 min-w-0"
            // style={{
            //   color: "#234d10",
            //   backgroundColor: "#ffffff",
            // }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography style={{ color: "white" }}>
                {" "}
                {props?.data?.Id ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
        </div>
        {/* {props.id ? (
          <Button
            variant="contained"
            // className="m-10 mx-2 xs:mx-10 min-w-0"
            // style={{
            //   color: "#d0efe9",
            //   backgroundColor: "#61bdaa",
            // }}
            className="bg-primary"
            onClick={clearField}
          >
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12"> Cancel </Typography>
            </Hidden>
          </Button>
        ) : null} */}
        {/* </div> */}
      </div>
    </CardHeader>
  );
}

export default HomeHeader;
