import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Modal.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getHoursMinutes,
  getUserRole,
  getValidPaycodeOfDay,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigDB from "../../../Config/ThemeConfig";
import { faCircleCheck, faClock } from "@fortawesome/pro-duotone-svg-icons";
import { color } from "highcharts";

function OTStandardSheet({
  rowsData,
  deleteTableRows,
  handleChangeV1,
  handleDateFieldChange,
  setTimeTo,
  getDateColor,
  payPeriod,
  maxSLLeave,
  maxALLeave,
  viewFields,
  viewField,
  setOpen,
  verify,
  role
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  const calcualteTime = (mins) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");

    return `${hr} hr  ${min} mins`;
  };
  const handleErrorMessage = (
    ole1,
    ole2,
    dateExceed1,
    dateExceed2,
    leaveHoursAL,
    leaveHoursSL
  ) => {
    console.log("dsvklfjksfhjsksghldksghsl", ole1, ole2, dateExceed1);
    if (ole1 || ole2) {
      return "Check your time slot. It is overwrite with other row";
    } else if (dateExceed1 || dateExceed2) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (ole1 || (ole2 && (dateExceed1 || dateExceed2))) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (leaveHoursAL) {
      return ` AL Leave Hours Exceeded ${calcualteTime(maxALLeave)}`;
    } else if (leaveHoursSL) {
      return `SL Leave Hours Exceeded  ${calcualteTime(maxSLLeave)}`;
    } else {
      return "";
    }
  };

  return rowsData.length
    ? rowsData.map((data, index) => {
        console.log("dsadadddadasdad", data);
        const {
          jobtype,
          date,
          time_from,
          time_to,
          time_from1,
          time_to1,
          hrs,
          jobOrder,
          parentIndex,
          hjobtype,
          ole1,
          ole2,
          rowstatus,
          dateExceed1,
          dateExceed2,
          authError2,
          authError1,
          leaveHoursAL,
          leaveHoursSL,
          verify,
          ID
        } = data;
        // let parentIndex = data.parentIndex ? data.parentIndex : "";
        // let parentEnable = parentIdx!= "" ? true : false;
        console.log("Payrol table data", data);
        // const inputProps = {
        //   step: 300,
        //   fontSize: 14,
        // };
        const inputProps = {
          style: { fontSize: 14, step: 300 },
          step: 300,
        };

        let parentEnable =
          parentIndex !== "0" && parentIndex != null ? true : false;
        const isDisableRow = (parentIndex, jobtype, resource) => {
          try {
            if (jobtype === "NDIF") {
              return true;
            } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
              if (
                ["time_from", "time_to", "time_from1", "time_to1"].includes(
                  resource
                )
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } catch (error) {
            console.log("error", error.message);
          }
        };

        // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
        const getSelectedType = (jobtype) => {
          let filterObj = jobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP ST", filterObj);

          return filterObj !== undefined
            ? filterObj
            : {
                value: "REG",
                label: "REG",
                title: "REGULAR",
                code: "1",
                multi: 1,
                EffectDate: "1990-01-01T00:00:00.000Z",
                ExpireDate: "2099-12-31T00:00:00.000Z",
                name: "jobtype",
              };
        };
        const getSelectedHType = (jobtype) => {
          let filterObj = hjobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP STD", filterObj);

          return filterObj !== undefined ? filterObj : null;
        };
        return (
          <>
            <tr
              key={index}
              bordercolor="red"
              // className={index % 2 === 0 ? "trRow1" : "trRow2"}
              style={{
                
                backgroundColor: getDateColor(date, rowsData),
                // alignItems: "center",
                // display: "flex",
              }}
              // style={{ backgroundColor: (ole1 || ole2 ? "#e7e7e7" : null),borderRadius:'20px' }}
            >
              {/* <td className="paroll-contents-td">
                {!verify ? (
                  <FontAwesomeIcon
                    icon={faClock}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                      color: "green",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                )}
              </td> */}

              <td className="paroll-contents-td">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="w-100 payroll-content-date1"
                    // margin="dense"
                    size="small"
                    format="MM/dd/yyyy"
                    disableToolbar
                    inputVariant={"outlined"}
                    id="date-picker-inline"
                    // label="Date"
                    value={date}
                    minDate={payPeriod.StartDate ? payPeriod.StartDate : null}
                    maxDate={payPeriod.EndDate ? payPeriod.EndDate : null}
                    onChange={(_, value) => {
                      handleDateFieldChange("date", value, index);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={["user"].includes(role) ? verify:false }
                    name="date"
                    // variant="outlined"
                    hiddenLabel="true"
                  />
                </MuiPickersUtilsProvider>
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from ? time_from : ""}
                  onChange={(e) => {
                    handleChangeV1(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_from"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={ parentEnable }
                  disabled={["user"].includes(role) ? verify:false }
                  hiddenLabel="true"
                />
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"

                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to ? time_to : ""}
                  onChange={(e) => {
                    handleChangeV1(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={["user"].includes(role) ? verify:false }
                />
              </td>
           
         
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  margin="dense"
                  name="hours"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={hrs}
                  onChange={(e) => handleChangeV1(index, e)}
                  sx={{ fontSize: 14 }}
                  disabled={["user"].includes(role) ? verify:false }
                />
              </td>
              {viewField ? (
                <>
                  {viewFields?.map((i) => {
                    return (
                      <td className="paroll-contents-td"  >
                        <TextField
                          size={"small"}
                          margin="dense"
                          inputProps={{
                            readOnly: true,
                            fontSize: 12,
                            style: { fontSize: 14 },
                          }}
                          fullWidth={true}
                          variant="outlined"
                          value={data[i.value] ? data[i.value] : null}
                          sx={{ fontSize: 14 }}
                        />
                      </td>
                    );
                  })}
                </>
              ) : null}
              <td className="paroll-contents-td tcolor">
                <button
                  className={`${
                    parentEnable ? "greyBtn" : ""
                  } btn btn-outline-danger paroll-contents-calcle `}
                  disabled={["user"].includes(role) ? verify:false }
                  onClick={() => {
                    if(ID){
                      setOpen(true)
                      
                    }else{
                      deleteTableRows(index)

                    }
                  }
                  }
                >
                  X
                </button>
              </td>
            </tr>
            <tr
              style={{
                display:
                  ole1 ||
                  ole2 ||
                  dateExceed1 ||
                  dateExceed2 ||
                  leaveHoursAL ||
                  leaveHoursSL
                    ? ""
                    : "none",
              }}
            >
              <td
                colspan="10"
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  alignItems: "center",
                  transition: "ease-in",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {handleErrorMessage(
                    ole1,
                    ole2,
                    dateExceed1,
                    dateExceed2,
                    leaveHoursAL,
                    leaveHoursSL
                  )}
                </p>
              </td>
            </tr>
          </>
        );
      })
    : null;
}

export default OTStandardSheet;
