import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Media,
} from "reactstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function PayCodeManagement(props) {
  const { handleChange, values, errors, touched, setFieldValue } = props;
  const [selectedDate, handleDateChange] = useState(new Date());
  const [topLevelDark, setTopLevelDark] = React.useState(false);

  console.log("top-------", props.detail);
  return (
    <Row>
      <Col md="4">
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          name="PAY_GRADE"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Pay Grade"
          value={values?.PAY_GRADE}
          onChange={handleChange}
          // onChange={(e) => {
          //   props.setDetail({
          //     ...props.detail,
          //     PAY_GRADE: e.target.value,
          //   });
          // }}
        />
        {errors.PAY_GRADE && touched.PAY_GRADE && (
          <p className="error">{errors.PAY_GRADE}</p>
        )}
        {/* <Label className="labelColor">Pay Code</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Employee Number"
                value={props.detail.code}
                label="Pay Code"
                onChange={(e) => {
                  props.setDetail({ ...props.detail, code: e.target.value });
                }}
              /> */}
      </Col>
      <Col md="4">
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          name="LOW_END"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Low End"
          value={values?.LOW_END}
          // defaultValue={""}
          // onChange={(e) => {
          //   props.setDetail({
          //     ...props.detail,
          //     LOW_END: e.target.value,
          //   });
          // }}
          onChange={handleChange}
        />
        {errors.LOW_END && touched.LOW_END && (
          <p className="error">{errors.LOW_END}</p>
        )}
        {/* <Label className="labelColor">Abbreviation</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Abbreviation"
                value={props.detail.abbreviation}
                // defaultValue={""}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    abbreviation: e.target.value,
                  });
                }}
              /> */}
      </Col>
      <Col md="4">
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          name="LOW_END_HOURLY"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "11px",
            },
          }}
          label="LE(Hourly)"
          value={values?.LOW_END_HOURLY}
          // onChange={(e) => {
          //   props.setDetail({
          //     ...props.detail,
          //     LOW_END_HOURLY: e.target.value,
          //   });
          // }}
          onChange={handleChange}
        />
        {errors.LOW_END_HOURLY && touched.LOW_END_HOURLY && (
          <p className="error">{errors.LOW_END_HOURLY}</p>
        )}
        {/* <Label className="labelColor">Description</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Description"
                value={props.detail.description}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    description: e.target.value,
                  });
                }}
              /> */}
      </Col>
      <Col md="3">
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          name="HIGH_END"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "11px",
            },
          }}
          label="High End"
          value={values?.HIGH_END}
          // onChange={(e) => {
          //   props.setDetail({
          //     ...props.detail,
          //     HIGH_END: e.target.value,
          //   });
          // }}
          onChange={handleChange}
        />
        {errors.HIGH_END && touched.HIGH_END && (
          <p className="error">{errors.HIGH_END}</p>
        )}
      </Col>
      <Col md="3">
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          name="HIGH_END_HOURLY"
          id="HIGH_END_HOURLY"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "11px",
            },
          }}
          label="HE(Hourly)"
          value={values?.HIGH_END_HOURLY}
          // onChange={(e) => {
          //   props.setDetail({
          //     ...props.detail,
          //     HIGH_END_HOURLY: e.target.value,
          //   });
          // }}
          onChange={handleChange}
        />
        {errors.HIGH_END_HOURLY && touched.HIGH_END_HOURLY && (
          <p className="error">{errors.HIGH_END_HOURLY}</p>
        )}
      </Col>
      <Col md="3">
        {/* {console.log("value date api", props.detail.effectDate)} */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            KeyboardButtonProps={{ "aria-label": "change date" }}
            label="Date1"
            value={values?.DATE1}
            // onChange={(e) => {
            //   const formatDate = e.$d;
            //   const value = moment(formatDate).format("YYYY-MM-DD");
            //   console.log("value date", value);
            //   props.setDetail({
            //     ...props.detail,
            //     DATE1: value,
            //   });
            // }}
            onChange={(e, _value) => {
              const formatDate = e?.$d && e?.$d;
              const value = moment(formatDate).format("YYYY-MM-DD");

              setFieldValue("DATE1", value);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                fullWidth
                margin="dense"
                inputProps={{
                  style: {
                    fontSize: "12px",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        {errors.DATE1 && touched.DATE1 && (
          <p className="error">{errors.DATE1}</p>
        )}

        {/* <Label className="labelColor">Effect Date</Label>

              <input
                className="form-control"
                type="date"
                format="MM/dd/yyyy"
                value={props.detail.effectDate}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    effectDate: e.target.value,
                  });
                }}
                placeholder="Effect Date"
                name="date"
              /> */}
      </Col>
      <Col md="3">
        {/* {console.log("value date api", props.detail.effectDate)} */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            KeyboardButtonProps={{ "aria-label": "change date" }}
            label="Date2"
            value={values?.DATE2}
            onChange={(e) => {
              const formatDate = e?.$d && e?.$d;
              const value = moment(formatDate).format("YYYY-MM-DD");
              console.log("value date 123", value);
              setFieldValue("DATE2", value);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                fullWidth
                margin="dense"
                inputProps={{
                  style: {
                    fontSize: "12px",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        {errors.DATE2 && touched.DATE2 && (
          <p className="error">{errors.DATE2}</p>
        )}

        {/* <Label className="labelColor">Effect Date</Label>

              <input
                className="form-control"
                type="date"
                format="MM/dd/yyyy"
                value={props.detail.effectDate}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    effectDate: e.target.value,
                  });
                }}
                placeholder="Effect Date"
                name="date"
              /> */}
      </Col>
    </Row>
  );
}

export default PayCodeManagement;
