import { makeStyles } from "@material-ui/core/styles";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import FilterList from "./filterList";
import TableComponent from "./tableComponent";
import { useDispatch } from "react-redux";
import moment from "moment";
import { set } from "react-hook-form";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    // height: "40px",
    // // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, 0.04)",
    // },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const RequestTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    payGrade: "",
    payStep: "",
    hourleyRate: "",
    salary: "",
    DESCR: "",
    date1: null,
    date2: null,
  });
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const changeHeaderFilter = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };
  const { requestDetail, filterBy, role } = props;
  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;

  const filterByRef = useRef(filter);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      // (filter && filterByRef.current && filterBy.current !== filter) ||
      requestDetail
    ) {
      filterByRef.current = filter;
      let dataList = requestDetail;
      if (filter?.payGrade) {
        dataList = dataList.filter((data) =>
          data.PAY_GRADE.toLowerCase().includes(filter?.payGrade?.toLowerCase())
        );
      }

      if (filter?.payStep) {
        dataList = dataList.filter((data) =>
          data.PAY_STEP.toString().includes(parseFloat(filter.payStep))
        );
      }
      if (filter?.hourleyRate) {
        dataList = dataList.filter((data) =>
          data.HOURLY_RATE.toString().includes(parseFloat(filter.hourleyRate))
        );
      }
      if (filter?.salary) {
        dataList = dataList.filter((data) =>
          data.SALARY.toString().includes(parseFloat(filter.salary))
        );
      }
      if (filter?.DESCR) {
        dataList = dataList.filter((data) =>
          data.DESCR.includes(parseFloat(filter.DESCR))
        );
      }
      //   if (filter?.type && filter?.type !== "All") {
      //     dataList = dataList.filter((data) => data.type.includes(filter.type));
      //   }
      //   if (filter?.status && filter.status !== "All") {
      //     dataList = dataList.filter((data) => data.status === filter.status);
      //   }
      if (filter?.date1) {
        let fdt = moment(filter.date1).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.DATE1).format("MM-DD-YYYY").includes(fdt);
        });
      }
      if (filter?.date2) {
        let fdt = moment(filter.date2).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.DATE2).format("MM-DD-YYYY").includes(fdt);
        });
      }
      //   if (filter?.name) {
      //     dataList = dataList.filter((data) =>
      //       `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`.includes(
      //         filter.name?.toLowerCase()
      //       )
      //     );
      //   }
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filter, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));

    let info = {
      payGrade: data.PAY_GRADE,
      payStep: data.PAY_STEP,
      hourleyRate: data.HOURLY_RATE,
      salary: data.SALARY,
      DESCR: data.DESCR,
      date1: data.DATE1,
      date2: data.DATE2,
    };

    props.setDetail(info);
  };

  const columns = [
    { id: "PAY_STEP", name: "PAY_STEP", type: "" },

    {
      id: "PAY_GRADE",
      name: "PAY_GRADE",
    },
    {
      id: "HOURLY_RATE",
      name: "HOURLY_RATE",
    },

    { id: "SALARY", name: "SALARY", type: "" },
    { id: "DESCR", name: "DESCR", type: "" },
    { id: "DATE1", name: "DATE1", type: "" },
    { id: "DATE2", name: "DATE2", type: "" },
  ];

  console.log("filter Lsit ", filteredList);

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            changeHeaderFilter={changeHeaderFilter}
            role={role}
            requestDetail={requestDetail}
            filterBy={filter}
          />
        )
      }
    />
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeHeaderFilter, setSelectedId }, dispatch);
// };

// function mapStateToProps(state) {
//   console.log("STATE ::: ", state);
//   return {
//     filterBy: state.paycode && state.paycode.paycode.filterBy,
//   };
// }

export default RequestTable;
