import Loader from "./Loader";
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import React, { Fragment, useEffect, useState } from "react";
import ThemeCustomize from "../Layout/ThemeCustomizer";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import CustomizerContext from "../_helper/Customizer";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimationThemeContext from "../_helper/AnimationTheme";
import ConfigDB from "../Config/ThemeConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-duotone-svg-icons";

const AppLayout = ({ children, classNames, ...rest }) => {
  const location = useLocation();
  const { sidebar_types } = useContext(CustomizerContext);
  const queryData = location?.search?.split("=")[1]?.toString();
  const settings1 =
    localStorage.getItem("sidebar_Settings") ||
    ConfigDB.data.settings.sidebar_setting ||
    queryData;
  const sidebar_types1 =
    localStorage.getItem("sidebar_types") ||
    ConfigDB.data.settings.sidebar.type ||
    sidebar_types;
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;

  const [tapTopStyle, setTapTopStyle] = useState("none");

  const executeScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 60) {
      setTapTopStyle("block");
    } else {
      setTapTopStyle("none");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <button
        style={{
          display: tapTopStyle,
          zIndex: "6000000",
          border: "0px",
          backgroundColor: "var(--theme-default)",
          position: "fixed",
          right: "0",
          bottom: "20px",
          borderRadius: "50px",
          height: "40px",
          width: "40px",
          marginRight: "15px",
        }}
        onClick={() => executeScroll()}
      >
        <FontAwesomeIcon
          icon={faArrowUp}
          size="lg"
          style={{ fontSize: "25px", color: "white" }}
        />
      </button> */}

      <Taptop />
      <Fragment>
        <Loader />

        <div
          className={`page-wrapper ${sidebar_types1} ${settings1}`}
          id="pageWrapper"
        >
          <Header />
          <div className="page-body-wrapper horizontal-menu">
            <Sidebar />
            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                unmountOnExit
              >
                <div className="page-body" style={{ overflow: "hidden" }}>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
            <Footer />
          </div>
        </div>
        <ThemeCustomize />
        <ToastContainer />
      </Fragment>
    </>
  );
};
export default AppLayout;
