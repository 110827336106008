import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./component/header";
import DetailForm from "./component/DetailForm";
import DetailsTable from "./component/DetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { getTableData, CreateOrUpdateData } from "./TableSlice";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
}));

const CommonConfigrations = (props) => {
  const { configrationJSON } = props;
  let dispatch = useDispatch();
  const { rowsPerPage, page } = useSelector((state) => state.TableSlice);
  const classes = useStyles();
  const [detail, setDetail] = useState(configrationJSON.INPUT_FIELDS);
  const [isUpdate, setIsUpdate] = useState(false);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  useEffect(() => {
    handleGetDetail();
  }, [rowsPerPage, page]);

  const handleGetDetail = () => {
    dispatch(
      getTableData({
        API_ENDPOINT: configrationJSON.API_ENDPOINT.GET_DATA_API_END_POINT,
        rowsPerPage, page
      })
    );
  };
  const checkvalid = () => {
    let inputKeys = Object.keys(configrationJSON.INPUT_FIELDS);
    for (let i = 0; i < inputKeys.length; i++) {
      const arrayValueCheck = Array.isArray(detail[inputKeys[i]]) && detail[inputKeys[i]].length === 0;
      if (arrayValueCheck || (!detail[inputKeys[i]] && inputKeys[i] !== "isActive")) {
        toast.error("Please validate values!");
        return false;
      }
    }
    return true;
  };
  const clearField = () => {
    setIsUpdate(false);
    setDetail(configrationJSON.INPUT_FIELDS);
  };

  const saveData = () => {
    if (!checkvalid()) {
      return false;
    }

    let payload = {};
    let inputKeys = Object.keys(configrationJSON.INPUT_FIELDS);
    if (isUpdate) {
      payload["id"] = detail?.id;
    }
    for (let i = 0; i < inputKeys.length; i++) {
      payload[inputKeys[i]] = detail[inputKeys[i]];
    }
    dispatch(
      CreateOrUpdateData({
        API_ENDPOINT: `${configrationJSON.API_ENDPOINT.CREATEORUPDATE_DATA_API_END_POINT}`,
        payload: payload,
      })
    )
      .unwrap()
      .then(() => {
        handleGetDetail();
        clearField();
        const msg = !isUpdate ? "Record successfully created." : "Record successfully updated.";
        toast.success(msg);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  return (
    <>
      <Header
        isUpdate={isUpdate}
        handleSubmit={saveData}
        clearField={clearField}
        configrationJSON={configrationJSON}
      />
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full`}>
          <DetailForm
            handleChange={handleChange}
            expand={expand}
            detail={detail}
            classes={classes}
            setDetail={setDetail}
            configrationJSON={configrationJSON}
          />
          <DetailsTable
            handleChange={handleChange}
            expand={expand}
            detail={detail}
            classes={classes}
            setDetail={setDetail}
            configrationJSON={configrationJSON}
            setIsUpdate={setIsUpdate}
          />
        </div>
      </div>
    </>
  );
};

export default CommonConfigrations;
