import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const FilterList = (props) => {
  const { changeHeaderFilter, filterBy, configrationJSON } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder={configrationJSON?.tableColumns[0]?.label}
          name={configrationJSON?.tableColumns[0]?.id}
          value={filterBy[configrationJSON?.tableColumns[0]?.id]}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
    </TableRow>
  );
}

export default FilterList;
