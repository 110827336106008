import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TableComponent from "./tableComponent";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import FilterList from "./filterList";
import { formatMoney } from "../../../app/utility/helper";
const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, filterBy, setDetail, role } = props;
  const [filter, setFilter] = useState({
    deductionType: '',
    deductionCode: '',
    deductionName: '',
    startDate: null,
    endDate: null,
    paymentMonthlySch: '',
    taxable: '',
    deductionMethod: '',
    deductionValue: '',
    totalAmountPlanDue: '',
    totalAmountPaid: '',
    accountNoForVendor: '',
    deductionPlanStatus: '',
    isActive: '',
    applysToOT: '',
    arrearsBase: '',
  });

  const changeHeaderFilter = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      // (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;

      if (filter?.deductionCode) {
        dataList = dataList.filter(
          (data) =>
            data && data.deductionCode && data.deductionCode.toLowerCase().includes(filter.deductionCode?.toLowerCase())
        );
      }

      if (filter?.deductionName) {
        dataList = dataList.filter(
          (data) =>
            data && data.deductionName && data.deductionName.toLowerCase().includes(filter.deductionName?.toLowerCase())
        );
      }
      if (filterBy?.deductionType) {
        dataList = dataList.filter((data) =>
          data.deductionType.toLowerCase().includes(
            filterBy.deductionType.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.garnishmentType) {
        dataList = dataList.filter((data) =>
          data.garnishmentTypeId.toLowerCase().includes(
            filterBy.garnishmentType.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.deductionMethod) {
        dataList = dataList.filter((data) =>
          data.garnishmentMethod.toLowerCase().includes(
            filterBy.garnishmentMethod.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.startDate) {
        dataList = dataList.filter((data) =>
          new Date(data.startDate) < new Date(filterBy.startDate)
        );
      }

      if (filterBy?.endDate) {
        dataList = dataList.filter((data) =>
          new Date(data.endDate) < new Date(filterBy.endDate)
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filter, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));

    let info = {
      id: data.id,
      DeductionType: data.DeductionType,
      deductionType: data.deductionType,
      deductionCode: data.deductionCode,
      deductionName: data.deductionName,
      startDate: data.startDate,
      endDate: data.endDate,
      paymentMonthlySch: data.paymentMonthlySch,
      taxable: data.taxable,
      deductionMethod: data.deductionMethod,
      deductionValue: data.deductionValue,
      totalAmountPlanDue: data.totalAmountPlanDue,
      totalAmountPaid: data.totalAmountPaid,
      accountNoForVendor: data.accountNoForVendor,
      deductionPlanStatus: data.deductionPlanStatus,
      isActive: data.isActive,
      applysToOT: data.applysToOT,
      arrearsBase: data.arrearsBase,
    };

    props.setDetail(info);
  };

  const columns = [
    {
      id: "id",
      name: "id",
    },
    {
      id: "deductionCode",
      name: "Deduction Code",
    },
    {
      id: "deductionName",
      name: "Deduction Name",
    },
    {
      id: "deductionType",
      name: "Deduction Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.deductionTypes?.length > 0) {
            temp = props.deductionTypes.find(o => o.Code == value);
          }
          return temp ? temp?.Code : '';
        },
      },
    },
    {
      id: "paymentMonthlySch",
      name: "Payment Monthly Schedule",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.paymentOptions?.length > 0) {
            temp = props?.paymentOptions.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "deductionMethod",
      name: "Deduction Method",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.garnishmentMethods?.length > 0) {
            temp = props.garnishmentMethods.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "startDate",
      name: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.startDate).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "endDate",
      name: "End Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.endDate).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "deductionAmount",
      name: "Deduction Amount",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta?.deductionMethod == 1) {
            let temp = tableMeta?.deductionAmount ? tableMeta?.deductionAmount : 0;
            return `${temp}%`;
          }
          if (tableMeta?.deductionMethod == 2) {
            let temp = tableMeta?.deductionAmount ? tableMeta?.deductionAmount : 0;
            return `${formatMoney(temp)}`;
          }
          else return '';
        },
      },
    },
    {
      id: "applyToOT",
      name: "Apply to OT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.applyToOT ? tableMeta?.applyToOT : 0;
          return `${temp}%`;
        },
      },
    },
    {
      id: "totalAmountPaid",
      name: "Total Original",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.totalAmountPaid??0;
          return `${formatMoney(temp)}`;
        },
      },
    },
    {
      id: "totalAmountPlanDue",
      name: "Balance Due",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.totalAmountPlanDue??0;
          return `${formatMoney(temp)}`;
        },
      },
    },
    {
      id: "accountNoForVendor",
      name: "Vendor AccountNo."
    },
    {
      id: "deductionPlanStatus",
      name: "Deduction Plan Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.deductionPlanStatusList?.length > 0) {
            temp = props.deductionPlanStatusList.find(o => o.id == value);
          }
          return temp ? temp?.displayName : '';
        },
      },
    },
    {
      id: "taxable",
      name: "Taxable",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
          checked={tableMeta.taxable}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
        },
      },
    },
    {
      id: "isActive",
      name: "isActive",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
          checked={tableMeta.isActive}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
        },
      },
    },
  ];

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            changeHeaderFilter={changeHeaderFilter}
            role={role}
            requestDetail={requestDetail}
            filterBy={filter}
          />
        )
      }
    />
  );
};

export default DataTable;