import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";

const MiscellaneousInfo = (props) => {
  const { userInfo, optionsMaster } = props;
  const { userDetail, changeDetails, state } = props;
  return (
    <Card>
      <CardHeader className="pb-0">
        <H5>Miscellaneous Info </H5>
      </CardHeader>
      <CardBody>
        <Form>
          <Row className="g-3 mt-1">
            <Col md="4">
              {/* <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Military Reserve"
                value={userDetail.militaryReserve}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "militaryReserve", value: e.target.value },
                  })
                }
              /> */}

              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Military Status"
                defaultValue={
                  state?.military_Status || null
                }
                value={
                  state?.military_Status || null
                }
              >
                <option value={null}>Select Military Status</option>
                {optionsMaster?.Military_Status &&
                  optionsMaster?.Military_Status?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </TextField>
              {/* <Label className="form-label">Military Reserve</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Military Reserve"
                value={userDetail.militaryReserve}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "militaryReserve", value: e.target.value },
                  })
                }
              /> */}
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Reimbursable Per Diem"
                value={userDetail.reimbursablePerDiem}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "reimbursablePerDiem",
                      value: e.target.value,
                    },
                  })
                }
              />
              {/* <Label className="form-label">Reimbursable Per Diem</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Reimbursable Per Diem"
                value={userDetail.reimbursablePerDiem}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "reimbursablePerDiem",
                      value: e.target.value,
                    },
                  })
                }
              /> */}
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Deceased Processing </Label>

              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "deceasedProcessing", value: value },
                  })
                }
                defaultValue={userDetail.deceasedProcessing}
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
          </Row>
          <Row className="g-3 mt-1">
            <Col md="4">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="flex w-full mx-10"
                  inputVariant="outlined"
                  renderInput={(props) => <TextField {...props} />}
                  margin="dense"
                  size="small"
                  fullWidth
                  format="MM/dd/yyyy"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      color: "black",
                      paddingTop: "12px",
                    },
                  }}
                  label="Date Deceased"
                  value={userDetail.dateDeceased ? dayjs(userDetail.dateDeceased) : null}
                  onChange={(e) => {
                    const formatDate = e?.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    changeDetails({
                      target: { name: "actingDetailBeginDate", value: value },
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* <Label className="form-label">Date Deceased</Label>
              <input
                className="form-control"
                type="date"
                placeholder="Last Name"
                value={moment(userDetail.dateDeceased).format("yyyy-MM-DD")}
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "actingDetailBeginDate", value: value },
                  })
                }
              /> */}
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label ">Process for Sepration </Label>

              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "processForSeparation", value: value },
                  })
                }
                defaultValue={userDetail.processForSeparation}
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Separation Rate"
                value={userDetail.separationRate}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "separationRate", value: e.target.value },
                  })
                }
              />
              {/* <Label className="form-label">Separation Rate</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Separation Rate"
                value={userDetail.separationRate}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "separationRate", value: e.target.value },
                  })
                }
              /> */}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default MiscellaneousInfo;
