import React, { useEffect, useState } from "react";
import { ShimmerTable } from "react-shimmer-effects";

import { Card, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ConfigDB from "../../../Config/ThemeConfig";
import moment from "moment";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
// import Header1 from "../../HoursReport/component/header1";

import { getUserRole } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";

function ViewTimeSheetTemplates(props) {
  // const { templateId } = props;
  const [templateId, setTemplateId] = useState();
  const [templateDetails, setTemplateDetails] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [timeProfiles, setTimeProfiles] = [];

  const [maxLength, setMaxLength] = useState([]);

  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  const getTemplateDetails = () => {
    setIsLoading(true);
    console.log("123123 ::: ", templateId, props.templateId);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}timesheet/get/${
          templateId ? templateId : props.templateId ? props.templateId : 0
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // setTableList(response.data.result);
        console.log("timecode111 :::: ", templateId, response.data);

        setIsLoading(false);
        // setTemplateDetails(response.data.result[0]);
        let newArray = response.data.result[0]?.TimeSheetProfiles.sort(
          (a, b) => {
            const dayA = daysOfWeek.indexOf(a.Day);
            const dayB = daysOfWeek.indexOf(b.Day);
            return dayA - dayB;
          }
        );

        let mergedArray = newArray.map((data, index) => {
          console.log("121212 data::: ", data);
          return { ...data, times: [{ From: data.From, To: data.To }] };
        });

        let mergedArray1 = [];
        mergedArray.map((data, index) => {
          if (index > 0 && data.Day === mergedArray[index - 1].Day) {
            // if (
            //   mergedArray1.length > 0 &&
            //   mergedArray1.find((d1) => d1.Day === data.Day)
            // ) {
            // } else {
            mergedArray1.push({
              ...mergedArray[index - 1],
              times: [
                ...mergedArray[index - 1].times,
                { From: data.From, To: data.To },
              ],
            });
            // }
          }
        });

        let na12 = mergedArray.map((data, index) => {
          let nd = mergedArray1.find((data1) => {
            return data.ID === data1.ID;
          });
          if (nd) {
            return nd;
          } else {
            return data;
          }
        });

        let na1 = [];
        na12.map((data) => {
          if (na1.length === 0) {
            na1.push(data);
          } else {
            if (!na1.find((d1) => d1.Day === data.Day)) {
              na1.push(data);
            }
          }
        });

        console.log("NEW DATA timecode111::: ", na12, na1);

        console.log(
          "Merged Array timecode111 :::: ",
          mergedArray,
          mergedArray1
        );
        setTemplateDetails(na1);
        // setTimeProfiles(newArray);
        let tAr = [];
        var lengths = na1.map((tmp) => {
          return tmp.times.length;
        });

        var mlength = Math.max(...lengths);

        for (let index = 0; index < mlength; index++) {
          tAr.push(index);
        }
        setMaxLength(tAr);
        console.log("Master timecode111 ::: ", newArray, maxLength);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    console.log("12121212 Innn UseEffect ::: ", templateId, props.templateId);
    getTemplateDetails();
  }, [props.templateId, templateId]);

  const renderTimeSheet = () => {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        {/* <Card
          style={{
            marginBottom: "10px",
            overflow: "hidden",
            padding: "10px",
            borderRadius: "10px",
          }}
          elevation={3}
        > */}
        {
          // isLoading ? (
          //   <ShimmerTable row={2} col={6} />
          // ) :
          <div style={{ display: "flex" }}>
            {props.sideBarFrom === "Payroll" && (
              <nav aria-label="secondary mailbox folders">
                <List
                  sx={{
                    width: 200,
                    maxWidth: 200,
                    maxHeight: "300px",
                    bgcolor: "background.paper",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    marginRight: "10px",
                    // border: "1px solid black",
                    backgroundColor: ConfigDB.data.color.primary_color,
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {props.templateList?.length &&
                    props.templateList?.map((temp) => {
                      return (
                        <React.Fragment>

                          <ListItem
                            onClick={() => {
                              console.log("Template ID 123123::: ", temp.ID);

                              setTemplateId(
                                temp.TemplateID ? temp.TemplateID : temp.ID
                              );
                              props.setTemplateID(
                                temp.TemplateID ? temp.TemplateID : temp.ID
                              );
                              props.setTemplateTitle(
                                temp.EmployeeTemplateName
                                  ? temp.EmployeeTemplateName
                                  : temp.TimeSheetName
                              );
                            }}
                            // sx={{ backgroundColor: "yellow" }}
                          >
                            <ListItemText>
                              {temp.EmployeeTemplateName
                                ? temp.EmployeeTemplateName
                                : temp.TimeSheetName}
                            </ListItemText>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                </List>
              </nav>
            )}
            {isLoading ? (
              <ShimmerTable row={2} col={12} />
            ) : (
              <table className="table100">
                <thead>
                  <tr
                    style={{
                      backgroundColor: ConfigDB.data.color.primary_color,
                      color: "white",
                    }}
                  >
                    <th className="tl1">Day</th>
                    <>
                      {maxLength.length > 1 ? (
                        maxLength.map(() => {
                          return (
                            <>
                              <th className="tl1">From</th>
                              <th className="tl1">To</th>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <th className="tl1">From</th>
                          <th className="tl1">To</th>
                        </>
                      )}
                    </>
                    {/* <th className="tl1">From</th>
                    <th className="tl1">To</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {timeProfiles &&
              timeProfiles.length?.map((sheet) => { */}
                  {templateDetails?.map((sheet) => {
                    return (
                      <tr
                        style={{
                          borderBottomColor: "#EAEBE4",
                          borderBottomWidth: "1px",
                        }}
                      >
                        <td className="tl1">{sheet.Day}</td>
                        {sheet.times.length > 1 ? (
                          sheet.times.map((item,index) => {
                            return (
                              <React.Fragment key={index}>
                                <td className="tl1">
                                  {moment(item.From).utc().format("hh:mm A")}
                                </td>
                                <td className="tl1">
                                  {moment(item.To).utc().format("hh:mm A")}
                                </td>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <>
                            <td className="tl1">
                              {moment(sheet.times[0].From)
                                .utc()
                                .format("hh:mm A")}
                            </td>
                            <td className="tl1">
                              {moment(sheet.times[0].To)
                                .utc()
                                .format("hh:mm A")}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        }
        {/* </Card> */}
      </Grid>
    );
  };
  return (
    <div className="w-full h-full reportTbl">
      {/* <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <h4>{templateDetails?.TimeSheetName}</h4>
      </Typography> */}

      <Grid container spacing={2}>
        {renderTimeSheet()}
      </Grid>
    </div>
  );
}

export default ViewTimeSheetTemplates;