import { makeStyles } from "@material-ui/core/styles";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import FilterList from "./filterList";
import TableComponent from "./tableComponent";
import { useDispatch } from "react-redux";
import moment from "moment";
import { set } from "react-hook-form";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    // height: "40px",
    // // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, 0.04)",
    // },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const RequestTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    PAY_GRADE: "",
    LOW_END: "",
    LOW_END_HOURLY: "",
    HIGH_END: "",
    HIGH_END_HOURLY: "",
    DATE1: null,
    DATE2: null,
  });
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const changeHeaderFilter = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };
  const { requestDetail, filterBy, role } = props;
  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;

  const filterByRef = useRef(filter);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      // (filter && filterByRef.current && filterBy.current !== filter) ||
      requestDetail
    ) {
      filterByRef.current = filter;
      let dataList = requestDetail;
      if (filter?.PAY_GRADE) {
        dataList = dataList.filter((data) =>
          data.PAY_GRADE.toLowerCase().includes(filter.PAY_GRADE?.toLowerCase())
        );
      }

      if (filter?.LOW_END) {
        dataList = dataList.filter((data) =>
          data.LOW_END.includes(filter.LOW_END)
        );
      }
      if (filter?.LOW_END_HOURLY) {
        dataList = dataList.filter((data) =>
          data.LOW_END_HOURLY.includes(filter.LOW_END_HOURLY)
        );
      }
      if (filter?.HIGH_END) {
        dataList = dataList.filter((data) =>
          data.HIGH_END.includes(filter.HIGH_END)
        );
      }
      if (filter?.HIGH_END_HOURLY) {
        dataList = dataList.filter((data) =>
          data.HIGH_END_HOURLY.includes(filter.HIGH_END_HOURLY)
        );
      }
      //   if (filter?.type && filter?.type !== "All") {
      //     dataList = dataList.filter((data) => data.type.includes(filter.type));
      //   }
      //   if (filter?.status && filter.status !== "All") {
      //     dataList = dataList.filter((data) => data.status === filter.status);
      //   }
      if (filter?.DATE1) {
        let fdt = moment(filter.DATE1).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.DATE1).format("MM-DD-YYYY").includes(fdt);
        });
      }
      if (filter?.DATE2) {
        let fdt = moment(filter.DATE2).format("MM-DD-YYYY");
        dataList = dataList.filter((data) => {
          return moment(data.DATE2).format("MM-DD-YYYY").includes(fdt);
        });
      }
      //   if (filter?.name) {
      //     dataList = dataList.filter((data) =>
      //       `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`.includes(
      //         filter.name?.toLowerCase()
      //       )
      //     );
      //   }
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filter, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));
    console.log("data ::: ", data);
    let info = {
      id: data.PAY_GRADE,

      PAY_GRADE: data.PAY_GRADE ? data.PAY_GRADE : "",
      LOW_END: data.LOW_END ? data.LOW_END : "",
      LOW_END_HOURLY: data.LOW_END_HOURLY ? data.LOW_END_HOURLY : "",
      HIGH_END: data.HIGH_END ? data.HIGH_END : "",
      HIGH_END_HOURLY: data.HIGH_END_HOURLY ? data.HIGH_END_HOURLY : "",
      DATE1: data.DATE1 ? moment(data.DATE1).format("YYYY-MM-DD") : null,
      DATE2: data.DATE2 ? moment(data.DATE2).format("YYYY-MM-DD") : null,
    };

    props.setValues(info);
  };

  const columns = [
    { id: "PAY_GRADE", name: "Pay Grade", type: "" },
    { id: "LOW_END", name: "Low End", type: "" },
    {
      id: "LOW_END_HOURLY",
      name: "Low End(Hourly)",
    },
    {
      id: "HIGH_END",
      name: "High End",
    },
    {
      id: "HIGH_END_HOURLY",
      name: "High End(Hourly)",
    },
    {
      id: "DATE1",
      name: "Date1",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.DATE1
            ? moment(tableMeta.DATE1).format("MM-DD-YYYY")
            : "-";
        },
      },
    },

    {
      id: "DATE2",
      name: "Date2",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.DATE2
            ? moment(tableMeta.DATE2).format("MM-DD-YYYY")
            : "-";
        },
      },
    },
  ];

  console.log("filter Lsit ", filteredList);

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            changeHeaderFilter={changeHeaderFilter}
            role={role}
            requestDetail={requestDetail}
            filterBy={filter}
          />
        )
      }
    />
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeHeaderFilter, setSelectedId }, dispatch);
// };

// function mapStateToProps(state) {
//   console.log("STATE ::: ", state);
//   return {
//     filterBy: state.paycode && state.paycode.paycode.filterBy,
//   };
// }

export default RequestTable;
