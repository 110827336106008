import React, { useState } from "react";
import { TextField, Checkbox } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@mui/material/Grid";
import InputMask from "react-input-mask";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Media,
} from "reactstrap";
import { floatNumberRegex, percentageRegex } from "../../../app/utility/helper";
import dayjs from "dayjs";
// import MaskedInput from "react-text-mask";

const citizenshipOptions = [
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "us",
    label: "United States",
  },
];

function AddForm(props) {
  const menuItemListHeight = 300;
  const [pctToDeductLabel, setPctToDeductLabel] = useState("Percent To Deduct");
  const [deductValueRegex, setDeductValueRegex] = useState(/^[0-9]*\.?[0-9]*$/);
  const [isOTError, setIsOTError] = useState(false);
  return (
    <div className="w-full h-full">
      <div className="flex flex-col sm:flex-row w-full py-4 mt-10">
        <Form>
          <Row className="g-3">
            {/* <Grid item xs={ 12 } >
          { JSON.stringify(props.detail) }
            </Grid> */}
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 12,
                }}
                InputLabelProps={{
                  shrink: props.detail.deductionCode ? true : false,
                }}
                label="Deduction Code"
                value={props.detail.deductionCode}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionCode: e.target.value,
                  });
                }}
                name="deductionCode"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                InputLabelProps={{
                  shrink: props.detail.deductionName ? true : false,
                }}
                label="Deduction Name"
                value={props.detail.deductionName}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionName: e.target.value,
                  });
                }}
                name="deductionName"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  },
                }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: props.detail.deductionType ? true : false,
                }}
                value={props.detail.deductionType}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionType: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Type"
              >
                {props?.deductionTypes.map((option) => (
                  <MenuItem
                    style={{ zIndex: "10004" }}
                    key={option.ID}
                    value={option.Code}
                  >
                    {option.Description} - {option.Code}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  },
                }}
                InputLabelProps={{
                  shrink: props.detail.arrearsBase ? true : false,
                }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.arrearsBase}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    arrearsBase: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Arrears Base"
              >
                {props.arrearsBaseList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Start Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.startDate)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      startDate: value,
                      endDate: null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="End Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.endDate)}
                  minDate={dayjs(props.detail.startDate)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      endDate: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 20,
                }}
                label="Account No. For Vendor"
                InputLabelProps={{
                  shrink: props.detail.accountNoForVendor ? true : false,
                }}
                value={props.detail.accountNoForVendor}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    accountNoForVendor: e.target.value,
                  });
                }}
                name="accountNoForVendor"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  },
                }}
                InputLabelProps={{
                  shrink: props.detail.paymentMonthlySch ? true : false,
                }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.paymentMonthlySch}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    paymentMonthlySch: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Payment Options"
              >
                {props.paymentOptions.map((option) => (
                  <MenuItem
                    style={{ zIndex: "1000009" }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  },
                }}
                InputLabelProps={{
                  shrink: props.detail.deductionMethod ? true : false,
                }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.deductionMethod}
                onChange={(e) => {
                  if (e.target.value) {
                    props.setDetail({
                      ...props.detail,
                      deductionMethod: e.target.value,
                      pctToDeduct: "",
                    });
                    let temp = props?.garnishmentMethods?.find(
                      (o) => o.id === e.target.value
                    );
                    if (temp?.name === "Pct" || temp?.id === 1)
                      setPctToDeductLabel("Percent To Deduct");
                    if (temp?.name === "Fixed" || temp?.id === 2)
                      setPctToDeductLabel("Amount To Deduct");
                  }
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Method"
              >
                {props.garnishmentMethods.map((option) => (
                  <MenuItem
                    style={{ zIndex: "10004" }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name} - {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label={pctToDeductLabel}
                InputLabelProps={{
                  shrink: props.detail.deductionValue ? true : false,
                }}
                // value={(pctToDeductLabel === 'Percent To Deduct') ? `${props.detail.pctToDeduct}%` : `$${props.detail.pctToDeduct}`}
                value={props.detail.deductionValue}
                onChange={(e) => {
                  const regex = deductValueRegex;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    props.setDetail({
                      ...props.detail,
                      deductionValue: e.target.value,
                    });
                  }
                }}
                name="deductionValue"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 10,
                }}
                label="Apply To OT"
                error={isOTError}
                helperText={isOTError ? "Valid percentage!" : ""}
                InputLabelProps={{
                  shrink: props.detail.applyToOT ? true : false,
                }}
                value={props.detail.applyToOT}
                onChange={(e) => {
                  let str = e.target.value;
                  // let str = (Math.round(e.target.value * 100) / 100).toFixed(2);
                  // if (str?.length <= 12 && (str?.includes('.') && str?.split('.')[1]?.length <= 2)) {
                  if (str?.length <= 12) {
                    props.setDetail({ ...props.detail, applyToOT: str });
                  }
                  if (!percentageRegex.test(str)) setIsOTError(true);
                  else setIsOTError(false);
                }}
                name="applyToOT"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 12,
                }}
                label="Total Amount Paid"
                InputLabelProps={{
                  shrink: props.detail.totalAmountPaid ? true : false,
                }}
                value={props.detail.totalAmountPaid}
                onChange={(e) => {
                  if (
                    (e.target.value === "" ||
                      floatNumberRegex.test(e.target.value)) &&
                    e.target.value?.length <= 12
                  ) {
                    props.setDetail({
                      ...props.detail,
                      totalAmountPaid: e.target.value,
                    });
                  }
                }}
                name="totalAmountPaid"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                // disabled
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 12,
                }}
                label="Total Amount Plan Due"
                InputLabelProps={{
                  shrink: props.detail.totalAmountPlanDue ? true : false,
                }}
                value={props.detail.totalAmountPlanDue}
                onChange={(e) => {
                  if (
                    (e.target.value === "" ||
                      floatNumberRegex.test(e.target.value)) &&
                    e.target.value?.length <= 12
                  ) {
                    props.setDetail({
                      ...props.detail,
                      totalAmountPlanDue: e.target.value,
                    });
                  }
                }}
                name="totalAmountPlanDue"
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  },
                }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: props.detail?.deductionPlanStatus ? true : false,
                }}
                value={props.detail.deductionPlanStatus}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionPlanStatus: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Plan Status"
              >
                {props.deductionPlanStatusList.map((option) => (
                  <MenuItem
                    style={{ zIndex: "10004" }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col
              md="2"
              className="isActiveAlignCenter d-flex align-items-center"
            >
              <Label className="labelColor isActiveLabel mb-0">Taxable</Label>
              <Checkbox
                checked={props.detail.taxable}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    taxable: !props.detail.taxable,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
            <Col
              md="2"
              className="isActiveAlignCenter d-flex align-items-center"
            >
              <Label className="labelColor isActiveLabel mb-0">Is Active</Label>
              <Checkbox
                checked={props.detail.isActive}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    isActive: !props.detail.isActive,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
            {/* <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full  "
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Type"
              >
                {props.deductionTypes &&
                  props.deductionTypes.map((option) => (
                    <option key={option.value} value={option.Code}>
                      {option.Description}
                    </option>
                  ))}
              </TextField>
            </Col> */}
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddForm;
