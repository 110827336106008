import React, { Fragment, useEffect, useRef } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { AddCircleTwoTone, RemoveCircleTwoTone } from "@material-ui/icons";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";

import { makeStyles } from "@material-ui/core";

const AddRecordFormStyles = makeStyles((theme) => ({
  MainHeading: {
    color: theme.palette.primary.main,
    marginBottom: "25px",
    marginLeft: "2px",
    fontWeight: "bold",
  },
  Subheading: {
    color: theme.palette.secondary.main,
    marginLeft: "2px",
    marginBottom: "8px",
  },
  ErrorForm: {
    color: "red",
    marginLeft: "2px",
    marginBottom: "8px",
  },
  Divider: {
    margin: "16px 0",
  },
  Button: {
    minWidth: "145px",
    fontSize: "1.4rem",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "90px",
    },
  },
  Icons: {
    fontSize: "2.5rem",
    marginLeft: "10px",
  },
  table: {
    minWidth: 650,
  },
  MainHeadingForTable: {
    color: theme.palette.primary.main,
    marginLeft: "20px",
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  DividerForTable: {
    marginTop: 0,
  },
}));

const initialValues = {
  query_name: "",
  queries: [
    {
      value: "",
      operator: "=",
      column: "",
      condition: "AND",
    },
  ],
};

const schema = Yup.object().shape({
  friends: Yup.array()
    .of(
      Yup.object().shape({
        column: Yup.string().required("Field is required"),
        condition: Yup.string().required("Field is required "),
        operator: Yup.string().required("Field is required"),
        value: Yup.string().required("Field is required"),
      })
    )
    .required("Field is required"),
});

const AdvanceSearchForm = (props) => {
  const classes = AddRecordFormStyles();

  const {
    handleClose,
    editValues,
    edit = false,
    currentId,
    currentName,
  } = props;

  const { cols } = useSelector((state) => state.query);

  const formRef = useRef();

  const submitHandler = (values, options) => {
    const key = "saveQuery";
    const { queries } = values;

    let temp = [];
    queries.map((element, index) => {
      if (typeof element.value != "undefined") {
        element.value = element.value.trim();
        if (typeof element.operator == "undefined") {
          element.operator = "=";
        }
        if (typeof element.condition == "undefined") {
          element.condition = "AND";
        }
        if (index == 0) {
          temp.push({
            ...element,
          });
        } else if (index > 0) {
          temp.push({
            ...element,
            // condition: queries[index - 1].condition,
          });
        }
      }
    });
    let list = [];
    queries.forEach((item) => {
      list.push({
        collumn: item.column,
        condition: item.condition,
        value: item.value,
        operator: item.operator,
      });
    });
    message.loading({ content: "Loading ...", key });
    axios
      .put("/query/save?id=" + currentId, {
        name: values.query_name,
        list,
      })
      .then((response) => {
        message.success({ content: "Query Saved", key });
        handleClose();
      })
      .catch((error) => {
        message.error({ content: error.message, key });
      });
  };

  return (
    <>
      <div className="flex w-full justify-center mt-20 ">
        <Card className="rounded-4 shadow-2xl w-11/12 bg-white px-16 py-8 mb-8">
          <CardContent>
            <Typography
              variant="h3"
              component="h3"
              className={classes.MainHeading}
            >
              Save Query
            </Typography>
            <Formik
              innerRef={formRef}
              initialValues={edit ? editValues : initialValues}
              enableReinitialize={true}
              onSubmit={(values, options) => submitHandler(values, options)}
            >
              {({ values, errors, handleSubmit }) => (
                <form autoComplete="Off" onSubmit={handleSubmit}>
                  <Field
                    name={"query_name"}
                    as={TextField}
                    label={"Query Name"}
                    className="w-full"
                    required
                  />
                  <FieldArray
                    name={"queries"}
                    render={(arrayHelpers) => {
                      return (
                        <Fragment>
                          {values["queries"] && values["queries"].length > 0 ? (
                            values["queries"].map((friend, index) => (
                              <Fragment>
                                <div
                                  className="grid mt-10 grid-cols-6"
                                  key={index}
                                >
                                  <div></div>
                                  <div
                                    className={
                                      edit ? "col-span-6" : "col-span-4"
                                    }
                                  >
                                    <div className="flex justify-start  gap-4">
                                      {index > 0 && (
                                        <div className="">
                                          <FormControl
                                            variant="outlined"
                                            className="w-full"
                                          >
                                            <InputLabel htmlFor="forType">
                                              Select Field
                                            </InputLabel>
                                            <Field
                                              as={Select}
                                              native
                                              value={
                                                values.queries[index].condition
                                              }
                                              label="Select Field"
                                              inputProps={{
                                                name: `queries.${index}.condition`,
                                                id: "forType",
                                              }}
                                              defaultValue="AND"
                                              required
                                            >
                                              <option value="AND">AND</option>
                                              <option value="OR">OR</option>
                                            </Field>
                                            {/*<FormHelperText>*/}
                                            {/*	{arrayHelpers.form[*/}
                                            {/*		`queries.${index}.condition`*/}
                                            {/*	].touched &&*/}
                                            {/*		arrayHelpers.form[*/}
                                            {/*			`queries.${index}.condition`*/}
                                            {/*		].error}*/}
                                            {/*</FormHelperText>*/}
                                          </FormControl>
                                        </div>
                                      )}
                                      <div className="">
                                        <FormControl
                                          variant="outlined"
                                          className="w-full"
                                        >
                                          <InputLabel htmlFor="forType">
                                            Select Field
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            native
                                            value={values.queries[index].column}
                                            label="Select Field"
                                            inputProps={{
                                              name: `queries.${index}.column`,
                                              id: "forType",
                                            }}
                                            required
                                          >
                                            <option value="null"></option>
                                            {cols.map((item, index) => (
                                              <option
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </Field>
                                          {/*<FormHelperText>*/}
                                          {/*	{arrayHelpers.form[*/}
                                          {/*		`queries.${index}.column`*/}
                                          {/*	].touched &&*/}
                                          {/*		arrayHelpers.form[*/}
                                          {/*			`queries.${index}.column`*/}
                                          {/*		].error}*/}
                                          {/*</FormHelperText>*/}
                                        </FormControl>
                                      </div>

                                      <div className="">
                                        <FormControl
                                          variant="outlined"
                                          className="w-full"
                                        >
                                          <InputLabel htmlFor="forType">
                                            Select Field
                                          </InputLabel>
                                          <Field
                                            as={Select}
                                            native
                                            value={
                                              values.queries[index].operator
                                            }
                                            label="Select Field"
                                            inputProps={{
                                              name: `queries.${index}.operator`,
                                              id: "forType",
                                            }}
                                            defaultValue="="
                                            required
                                          >
                                            <option value="=">
                                              {"Equal To"}
                                            </option>
                                            <option value="!=">
                                              {"Not Equal To"}
                                            </option>
                                            <option value="~*">
                                              {"Contains"}
                                            </option>
                                            <option value="start">
                                              {"Starts With"}
                                            </option>
                                            <option value="end">
                                              {"Ends With"}
                                            </option>
                                            <option value=">">
                                              {"Greater Than"}
                                            </option>
                                            <option value="<">
                                              {"Less Than"}
                                            </option>
                                            <option value="<=">
                                              {"Greater Than And Equal to"}
                                            </option>
                                            <option value=">=">
                                              {"Less Than And Equal to"}
                                            </option>
                                          </Field>
                                          {/*<FormHelperText>*/}
                                          {/*	{arrayHelpers.form[*/}
                                          {/*		`queries.${index}.operator`*/}
                                          {/*	].touched &&*/}
                                          {/*		arrayHelpers.form[*/}
                                          {/*			`queries.${index}.operator`*/}
                                          {/*		].error}*/}
                                          {/*</FormHelperText>*/}
                                        </FormControl>
                                      </div>
                                      <div className="">
                                        <Field
                                          name={`queries.${index}.value`}
                                          as={TextField}
                                          className="w-full"
                                          variant="outlined"
                                          label={"Enter Value"}
                                          required
                                          // error={
                                          // 	arrayHelpers.form[
                                          // 		`queries.${index}.operator`
                                          // 	].touched &&
                                          // 	!!arrayHelpers.form[
                                          // 		`queries.${index}.operator`
                                          // 	].error
                                          // }
                                          // helperText={
                                          // 	arrayHelpers.form[
                                          // 		`queries.${index}.operator`
                                          // 	].touched &&
                                          // 	arrayHelpers.form[
                                          // 		`queries.${index}.operator`
                                          // 	].error
                                          // }
                                        />
                                      </div>
                                      <div>
                                        <IconButton
                                          disableRipple
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <RemoveCircleTwoTone />
                                        </IconButton>
                                      </div>
                                    </div>
                                    <div
                                      className={`flex justify-start gap-4 mt-20`}
                                    >
                                      <div>
                                        {values["queries"].length === 1 ? (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                              arrayHelpers.insert(index + 1, {
                                                value: "",
                                                operator: "",
                                                column: "",
                                                condition: "AND",
                                              })
                                            }
                                            style={{
                                              textTransform: "none",
                                            }}
                                          >
                                            Add Field
                                          </Button>
                                        ) : values["queries"].length - 1 !==
                                          index ? (
                                          <span></span>
                                        ) : (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                              arrayHelpers.insert(index + 1, {
                                                value: "",
                                                operator: "",
                                                column: "",
                                                condition: "AND",
                                              })
                                            }
                                            style={{
                                              textTransform: "none",
                                            }}
                                          >
                                            Add Field
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                    {values.queries.length - 1 === index && (
                                      <div
                                        className={`flex justify-end gap-4 mt-20`}
                                      >
                                        <Button
                                          color="secondary"
                                          onClick={handleClose}
                                          variant={"contained"}
                                          style={{ textTransform: "none" }}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          style={{ textTransform: "none" }}
                                          variant={"contained"}
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  <div></div>
                                </div>
                              </Fragment>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </Fragment>
                      );
                    }}
                  />
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default AdvanceSearchForm;
