import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import {
  faHourglass,
  faXmark,
  faCheck,
  faCircleArrowDown,
  faCircleArrowUp,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TableComponent(props) {
  const use = makeStyles({
    selectLabel: {
      padding: 8,
      fontSize: "15px",
    },
    toolbar: {
      "& > p": {
        fontSize: "15px",
      },
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  console.log("pagepagepagepagepagepage",page,rowsPerPage);
  const {
    filteredList,
    onRowClick,
    columns,
    tableClass,
    filterComponent,
    buttonFunction,
  } = props;

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,

      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        className="w-full labelColor "
        style={{ width: "100%", position: "sticky", top: "0", zIndex: "5" }}
      >
        <TableRow className="labelColor">
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              className="py-10 bg-gray-300 page-body-wrapper labelColor"
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.minWidth ? headCell.minWidth : "200px",
                fontWeight: "bold",
              }}
            >
              {headCell.name}
              {headCell.sort ? null : orderBy === headCell.id ? (
                <Box component="span">
                  {order === "desc" ? (
                    <FontAwesomeIcon
                      icon={faCircleArrowDown}
                      className="sub-header"
                      onClick={createSortHandler(headCell.id)}
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="sub-header"
                      icon={faCircleArrowUp}
                      onClick={createSortHandler(headCell.id)}
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Box>
              ) : (
                <>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleArrowDown}
                    className="sub-header"
                    onClick={createSortHandler(headCell.id)}
                    style={{ color: "#1569C7", marginRight: "5px" }}
                  />
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    className="sub-header"
                    onClick={createSortHandler(headCell.id)}
                    style={{ color: "#1569C7", marginRight: "5px" }}
                  />
                </>
              )}
            </TableCell>
          ))}
        </TableRow>
        {/* {filterComponent ? filterComponent : null} */}
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const renderRowData = (column, value, row, index) => {
    return column.type === "status" ? (
      <TableCell key={index} size="small" className="labelColor">
        {renderStatus(value)}
      </TableCell>
    ) : column.type === "message" ? (
      <TableCell key={column.id} className="labelColor">
        <Typography
          fontSize="small"
          className={`text-14 labelColor bg-${
            value === "urgent" ? "red" : value === "alert" ? "orange" : "green"
          }-100 rounded capitalize flex w-128 ml-10 px-2 py-4`}
        >
          <Icon
            fontSize="small"
            className={`text-16 text-${
              value === "urgent"
                ? "red"
                : value === "alert"
                ? "orange"
                : "green"
            } pt-4 mr-10 ml-6`}
          >
            {value === "urgent"
              ? "notifications_active"
              : value === "alert"
              ? "priority_high"
              : "info"}
          </Icon>
          {value === "info" ? "information" : value}
        </Typography>
      </TableCell>
    ) : column.type === "button" ? (
      <TableCell
        key={column.id}
        className="labelColor"
        style={{ backgroundColor: "red" }}
      >
        <Tooltip title={`Reply to ${value}`}>
          <Fab color="secondary" className={classes.fab}>
            <Icon className="text-20" onClick={() => buttonFunction(row)}>
              reply
            </Icon>
          </Fab>
        </Tooltip>
      </TableCell>
    ) : column.type === "employementStatus" ? (
      <TableCell key={index} size="small" className="labelColor">
        {renderEmployementStatus(value)}
      </TableCell>
    ) : (
      <TableCell key={column.id} align={column.align} className="labelColor">
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className="labelColor"
        >
          {value}
        </Typography>
      </TableCell>
    );
  };

  const renderStatus = (status) => {
    // const statusCls =
    //   status === "Pending"
    //     ? "bg-orange-300 text-black"
    //     : status === "Disapproved"
    //     ? "bg-red-700 text-white"
    //     : "bg-green-700 text-white";
    const statusCls =
      status === "Pending"
        ? " text-orange"
        : status === "Disapproved"
        ? " text-red"
        : " text-green";
    return (
      <div
        className={`inline text-12 p-4 labelColor rounded truncate ${statusCls} font-semibold w-95 justify-left flex items-center`}
      >
        <span style={{ marginTop: "2px", marginRight: "5px" }}>
          {status === "Pending" ? (
            <FontAwesomeIcon icon={faHourglass} />
          ) : status === "Disapproved" ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </span>
        {status}
      </div>
    );
  };

  const renderEmployementStatus = (status) => {
    const statusCls =
      status === "Retired"
        ? "bg-red-700 text-white"
        : "bg-green-700 text-white";
    return (
      <div
        className={`inline text-12 p-4 labelColor rounded truncate ${statusCls} font-semibold w-84 justify-center flex items-center`}
      >
        {status}
      </div>
    );
  };

  function descendingComparator(a, b, orderBy) {
    if (moment(a[orderBy], "MM/DD/YYYY", true).isValid()) {
      var date1, date2;
      if (typeof a[orderBy] == "string") {
        const parts1 = a[orderBy].split("/");
        const parts2 = b[orderBy].split("/");
        date1 = new Date(parts1[2], parts1[1] - 1, [parts1[0]]);
        date2 = new Date(parts2[2], parts2[1] - 1, [parts2[0]]);
      } else {
        date1 = new Date(
          a[orderBy].getFullYear(),
          a[orderBy].getMonth(),
          a[orderBy].getDate()
        );
        date2 = new Date(
          b[orderBy].getFullYear(),
          b[orderBy].getMonth(),
          b[orderBy].getDate()
        );
      }

      if (date2 < date1) {
        return -1;
      }
      if (date2 > date1) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const classes = use();
  return (
    <div className="mt-10" style={{ minWidth: "100%" }}>
      <TableContainer
        component={Paper}
        id="scrollableDiv"
        className={tableClass}
      >
        <Table stickyHeader aria-label="sticky table">
          {/* <TableHead className="w-full">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  size="small"
                  className="py-10 bg-gray-300"
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
            
          </TableHead> */}
          {filteredList && (
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredList.length}
            />
          )}
          {filterComponent ? filterComponent : null}
          {filteredList && filteredList.length > 0 ? (
            <TableBody className="page-body-wrapper labelColor ">
              {stableSort(filteredList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((obj, index) => (
                  <TableRow
                    key={index}
                    className="capitalize cursor-pointer labelColor"
                    // style={
                    //   index % 2
                    //     ? { background: "#F5F5F5" }
                    //     : { background: "white" }
                    // }
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick !== null && onRowClick({ data: obj, index });
                      }
                    }}
                  >
                    {/*{columns.map((column) => {*/}
                    {/*  const value = obj[column.id];*/}
                    {/*  return renderRowData(column, value, obj);*/}
                    {/*})}*/}
                    {columns.map((column) => {
                      return column.options ? (
                        <TableCell className="labelColor" key={column.name}>
                          <div className="labelColor">
                            {column?.options?.customBodyRender(
                              obj[`${column.id}`],
                              { ...obj }
                            )}
                          </div>
                        </TableCell>
                      ) : (
                        renderRowData(column, obj[column.id], obj, index)
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody className="page-body-wrapper">
              <TableRow>
                <TableCell
                  align="center"
                  className="labelColor"
                  colSpan={columns.length}
                >
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {filteredList && (
        <TablePagination
          sx={{ fontSize: "15px" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          className="labelColor"
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ page }) => {
            return `Page: ${page + 1} of ${Math.ceil(
              filteredList.length / rowsPerPage
            )}`;
          }}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
          }}
        />
      )}
    </div>
  );
}

export default TableComponent;
