import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import moment from "moment";
import useKeyboardShortcut from "use-keyboard-shortcut";

import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowForward from "@mui/icons-material/KeyboardDoubleArrowRight";
import DateFnsUtils from "@date-io/date-fns";

import axios from "axios";
import {
  Checkbox,
  Chip,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Switch,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import {
  HourMinFormat,
  KeyGen,
  RemoveSpaceKeyGen,
  ShowError,
  d2Time,
  getUserRole,
  payCodeFilter,
  ShowSucess,
  zeroNumPad,
  getValidPaycodeOfDay,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { isEqual, isNull } from "lodash";
const inputProps = {
  style: { fontSize: 14, step: 300, borderColor: "blue" },
  step: 300,
};

const PayrollSimpleView = forwardRef((props, ref) => {
  const {
    paytimeCodeList,
    EmployeeID,
    templateData,
    payPeriod,
    filteredEvent,
    templateID,
    paytimeCodeByID,
    statusSubmit,
    loadPreData,
    userId,
  } = props;

  const [payCodes, setPayCodes] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [deletedList, setDeletedList] = useState([]);



  let sampleData = {
    id: null,
    paycode: null,
    code: null,
    date: null,
    time_from: null,
    time_to: null,
    status: false,
    workingHr: "",
    totalMin: 0,
  };

  const [startDate, setStartDate] = useState(null);
  const [daysCnt, setDaysCnt] = useState(14);
  const [onTimeArgs, setOnTimeArgs] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

   

  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  useKeyboardShortcut(
    ["Control", "R"],
    (shortcutKeys) => {
 
      let findex = timeData.findIndex((i) => {
        return i.code == "REG" && i.date == currentDate;
      });
    
      if (findex !== -1) {
        let copy = [...timeData];
        const newObj = { ...copy[findex] };
        newObj["time_from"] = null;
        newObj["time_to"] = null;
        newObj["totalMin"] = 0;
        newObj["error"] = false;
        newObj["workingHr"] = null;
        newObj["id"] = KeyGen(7);
        copy.splice(findex + 1, 0, newObj);
        setTimeData([...copy]);
        // let copy = [ ...timeData ];
        // const newObj = {...copy[ findex ]};
        // newObj[ "date" ] = currentDate;
        // newObj[ "time_from" ] = null;
        // newObj[ "time_to" ] = null;
        // newObj[ "totalMin" ] = 0;
        // newObj[ "workingHr" ] = null;
        // newObj[ "id" ] = KeyGen(7);
        // copy[ findex ] = newObj;
        // copy.splice(findex+1, 0, newObj);
        // setTimeData(copy);
      }
    },
    {
      overrideSystem: true,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );
  useKeyboardShortcut(
    ["Control", "-"],
    (shortcutKeys) => {
      let copy = [...timeData];
      let findex = copy.findIndex((i) => {
        return i.code == "REG" && i.date == currentDate;
      });
      let findlng = copy.filter((i) => {
        return i.code == "REG" && i.date == currentDate;
      });

      if (findex !== -1 && findlng.length > 1) {
        let items = [...timeData];
        let remainAray = [
          ...items.slice(0, findex),
          ...items.slice(findex + 1),
        ];

        let listCode = remainAray.filter((i) => {
          return i.code == "REG" && i.error == true;
        });

        if (listCode.length) {
          listCode.map((iq) => {
            let existCode = remainAray.findIndex((i) => {
              return i.id == iq.id;
            });
            updateTime(existCode, remainAray);
          });
        }
        setTimeData(remainAray);
      }
    },
    {
      overrideSystem: true,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  const createRecord = (recno) => {
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }
    let recordsCopy = [...timeData];

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });

    if (findID.length) {
      let lableCreate = `${findID[0]?.date}${findID[0]?.paycode}${findID[0]?.time_from}`;
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };
  useImperativeHandle(ref, () => ({
    childFunction() {
      let infoArray = [];
      let hasError = timeData.filter((i) => {
        return i.error ;
      });

      if (hasError.length) {
        ShowError(
          "Please Verify Timesheet. It's has issue with overlapping time"
        );
        return false;
      }
      timeData.map((item) => {
        if (item.time_from && item.time_to) {
          let recno = item.parentIndex ? item.parentIndex : "0";
          recno = createRecord(recno);
          let infoPick = {
            WorkDate: moment(item.date).format("YYYY-MM-DD"),
            PayCode: parseInt(item.paycode),
            WorkFrom: item.time_from,
            WorkTo: item.time_to,
            JobOrder: "1000000",
            Typh: item.code,
            // RecordNo: 0,
            RecordNo: recno ? recno : "0",
            Status: "Confirm",
            ID: item.ID?item.ID:null,
          };
          infoArray.push(infoPick);
        }
      });

      if (!infoArray.length) {
        return false;
      }
     
        
      axios
        .post(
          `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
          { workingHours: infoArray, deletedList: deletedList },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(async (response) => {
          ShowSucess("Status Change Successfully!");
          loadPreData();
        })
        .catch((error) => {
          // ShowError(error)
          console.warn("Cannot retrieve user data", error);
        });
      // handleButtonClick();
    },
  }));

  const CalculateWorkingHR = (st, et, date) => {
    const startTime = moment(`${date} ${st}`, "YYYY-MM-DD HH:mm");
    const endTime = moment(`${date} ${et}`, "YYYY-MM-DD HH:mm");
    let duration = moment.duration(endTime.diff(startTime));

    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    let hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);

    return hrCalc;
  };
   
  
  const getNDIFRange = (startT, endT) => {
    let dt = moment().format("YYYY-MM-DD");
    let startTime = moment(startT, "HH:mm");
    let endTime = moment(endT, "HH:mm");
    // let startTime = moment(rowsData[index]?.time_from, "HH:mm");
    // let endTime = moment(rowsData[index]?.time_to, "HH:mm");

    let nightStart = moment("18:00", "HH:mm");
    let nightEnd = moment("06:00", "HH:mm");
    let isNight = true;
    let totalminutes = 0;
    let totalHr = 0;
    let nightTime = {};
    if (
      (moment(startTime, "HH:mm") > nightStart &&
        moment(startTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(startTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(startTime, "HH:mm") < moment("06:00", "HH:mm")) ||
      (moment(endTime, "HH:mm") > moment("18:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(endTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("06:00", "HH:mm")) ||
      (moment(startTime, "HH:mm") > moment(endTime, "HH:mm") &&
        moment(endTime, "HH:mm") >= moment("06:00", "HH:mm"))
    ) {
      if (startTime >= endTime) {
        if (startTime >= endTime && startTime <= nightEnd) {
          let durationBefore = moment.duration(nightEnd.diff(startTime));
          
        }
     
        nightStart = nightStart >= startTime ? nightStart : startTime;
        let duration = moment.duration(
          moment("24:00", "HH:mm").diff(nightStart)
        );
        totalminutes = totalminutes + parseInt(duration.asMinutes());
        totalHr = totalHr + parseInt(duration.asHours());

        if (endTime >= nightEnd) {
          endTime = nightEnd;
        }
        let durationAfter = moment.duration(
          endTime.diff(moment("00:00", "HH:mm"))
        );
        totalminutes = totalminutes + parseInt(durationAfter.asMinutes());
        totalHr = totalHr + parseInt(durationAfter.asHours());

        
        nightTime["start"] = nightStart;
        nightTime["end"] = endTime;
      } else {
        if (startTime <= endTime) {
          //  let sectionA = endTime >= nightStart ? endTime: nightStart ;
          
          if (startTime <= nightEnd && endTime <= nightEnd) {
            let duration = moment.duration(endTime.diff(startTime));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

           
            nightTime["start"] = startTime;
            nightTime["end"] = endTime;
            nightTime[""] = endTime;
          }
          if (startTime <= nightEnd && endTime > nightEnd) {
            let duration = moment.duration(nightEnd.diff(startTime));
             
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());
            nightTime["start"] = startTime;
            nightTime["end"] = nightEnd;
          }

          if (startTime <= nightEnd && endTime > nightStart) {
            /// equal removed
            let duration = moment.duration(endTime.diff(nightStart));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

             
            nightTime["start"] = nightStart;
            nightTime["end"] = endTime;
            nightTime["isDisable"] = true;
          } else {
            if (endTime > nightStart && startTime < endTime) {
              if (startTime >= nightStart) {
                nightStart = startTime;
              }
              let duration = moment.duration(endTime.diff(nightStart));

              totalminutes = totalminutes + parseInt(duration.asMinutes());
              totalHr = totalHr + parseInt(duration.asHours());
              
              nightTime["start"] = nightStart;
              nightTime["end"] = endTime;
              nightTime["isDisable"] = true;
            }
          }
        } else {
          nightStart = nightStart >= startTime ? nightStart : startTime;
          let duration = moment.duration(endTime.diff(nightStart));
         
          totalminutes = totalminutes + parseInt(duration.asMinutes());
          totalHr = totalHr + parseInt(duration.asHours());
        }

        /*
        if (startTime <= endTime) {
          nightStart = startTime;
        } else {
          nightStart = nightStart >= startTime?nightStart:startTime;
        }

        endTime = endTime >= nightEnd ? nightEnd:endTime;
       
        let duration = moment.duration(endTime.diff(nightStart));
        if (endTime >= nightStart) {
          let durationNext = moment.duration(endTime.diff(nightStart));
        
        } */
      }

      // if (duration.asHours() < 0) {
      //   const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      //   duration1 = moment.duration(endTime3.diff(startTime1));
      // }
      const minitues1 = zeroNumPad(Math.floor(totalminutes / 60), 2);
      const minitues2 = zeroNumPad(totalminutes % 60, 2);
      let hrCalc = `${minitues1}:${minitues2}`;
      let info = {
        start: moment(nightTime.start).format("HH:mm"),
        end: moment(nightTime.end).format("HH:mm"),
        hours: Math.floor(totalminutes / 60),
        minutes: totalminutes % 60,
        totalminutes: totalminutes,
        hrCalc: hrCalc,
      };
      // appendNDIFRow(shift,info)

      return { status: true, shift: "one", info: info };
    } else {
      // appendNDIFRow(`clear_${shift}`,{})
      isNight = false;
      return { status: false, shift: "one", info: {} };
    }
  };

  const checkAuth = (sd, ed) => {
    let isauth = "pending";
    let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("dddd");
    let shr = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    let ehr = moment(ed, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
    let tData = templateData.filter((i) => {
      return i.Day == dy;
    });
    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.From, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
      let toTime = moment(i.To, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };

  const addHzEntry = (timeData, data, index) => {
    
    if (data.code !== "REG") {
      return false;
    }
    if (!index) {
      return false;
    }

    let date = data.date;
    const isNdif = payCodes.some((i) => {
      const Effdate = i.EffectDate
        ? moment(i.EffectDate).format("YYYY-MM-DD")
        : null;
      const ExpireDate = i.ExpireDate
        ? moment(i.ExpireDate).format("YYYY-MM-DD")
        : null;

      return (
        i.value === "NDIF" &&
        ((date >= Effdate && date <= ExpireDate) ||
          (Effdate == null && ExpireDate == null))
      );
    });

    if (!isNdif) {
      return false;
    }

    const st = data.time_from;
    const et = data.time_to;

    let statusData = false;
    // const existing = timeData.some((i) => i.isExist == index);

    let findIndex = timeData.findIndex(
      (i) =>
        i.date === currentDate && i.code === "NDIF" && i.parentIndex == data.id
    );
    if (findIndex === -1) {
     
      findIndex = timeData.findIndex(
        (i) =>
          i.date === currentDate &&
          i.code === "NDIF" &&
          !i.time_from &&
          !i.time_to
      );
    }

    

    let ndifInfo = getNDIFRange(st, et);
    if (!ndifInfo.status) {
      const findIndex = timeData.findIndex(
        (i) => i.date === currentDate && i.parentIndex == data.id
      );
      if (findIndex !== -1) {
        const otherIndex = timeData.findIndex(
          (i) =>
            i.date === currentDate &&
            i.parentIndex !== data.id &&
            i.code === "NDIF" &&
            i.remove === true
        );
        if (otherIndex !== -1) {
         
          // let items = [...timeData];
          timeData = [
            ...timeData.slice(0, findIndex),
            ...timeData.slice(findIndex + 1),
          ];

        
          setTimeData(timeData);
        } else {
          if (timeData.some((i) => i.remove === true)) {
            const fasfsf = timeData.filter((i) => i.remove !== true);
           

            setTimeData([...timeData, fasfsf]);
          } else {
             

            let oldObj = { ...timeData[findIndex] };
            timeData[findIndex] = {
              ...oldObj,
              ID:null,
              time_from: null,
              time_to: null,
              workingHr: "",
              totalMin: 0,
            };
            let list = deletedList;
            list.push(oldObj.ID);
            setDeletedList(list)
          }
        }
      }
      return false;
    } else {
      // const findIndex =  timeData.findIndex((i) => i.date === currentDate && i.parentIndex === data.id );
      // if(findIndex){
      //   let items = [...timeData];
      //   let remainAray = [
      //     ...items.slice(0, findIndex),
      //     ...items.slice(findIndex + 1),
      //   ];
      //   // timeData = remainAray;
      // }
     
    }
    ndifInfo = ndifInfo.info;
   
    let stime = ndifInfo.start;
    let etime = ndifInfo.end;
    let minutes = ndifInfo.minutes;
    let totalminutes = ndifInfo.hrCalc;
    let hours = ndifInfo.hours;

    if (findIndex !== -1) {
      
      let newobj = {
        // id: data.id,
        paycode: "125",
        // parentIndex: data.id,
        // id: KeyGen(7),
        code: "NDIF",
        date: data.date,
        ID: timeData[findIndex]['ID']?timeData[findIndex]['ID']:null,
        time_from: stime,
        time_to: etime,
        status: data.status,
        workingHr: totalminutes,
        totalMin: totalminutes,
        isExist: index,
        isDisable: true,
        error: false,
        parentIndex: data.id, // RemoveSpaceKeyGen(`${data.date}${data?.code}${data?.time_from}`),
        id: KeyGen(7),

        // id:RemoveSpaceKeyGen(`${currentDate}${125}${stime}`),
      };
     

      timeData[findIndex] = newobj;
    } else {
      const findIndex = timeData.findIndex((i) => {
        return i.parentIndex == data.id;
      });

      
      if (findIndex !== -1) {
        
        let oldObj = { ...timeData[findIndex] };

      
        timeData[findIndex] = {
          ...oldObj,
          time_from: stime,
          time_to: etime,
          status: data.status,
          workingHr: totalminutes,
          totalMin: totalminutes,
          error: data.error,
          // parentIndex: RemoveSpaceKeyGen(`${data.date}${data?.code}${data?.time_from}`),
          // // id: KeyGen(7),
          // id:RemoveSpaceKeyGen(`${currentDate}${125}${stime}`),
        };
      } else {
        
        // const findIndex =  timeData.findIndex((i) => i.date === currentDate && i.code === "NDIF" && i.parentIndex == data.id  )
        // if(findIndex!==-1){

        // }else
        {
          let oldObj = { ...timeData[index] };
          let newObj = {
            ...oldObj,
            time_from: stime,
            time_to: etime,
            paycode: "125",
            // parentIndex: data.id ,
            parentIndex: data.id,
            // id: KeyGen(7),
            // id:RemoveSpaceKeyGen(`${currentDate}${125}${stime}`),
            code: "NDIF",
            status: data.status,
            workingHr: totalminutes,
            totalMin: totalminutes,
            isDisable: true,
            error: data.error,
            remove: true,
          };

          timeData.splice(index + 1, 0, newObj);
          setTimeData([...timeData]);
        }
        
      }
    }
    setTimeData(timeData);
  };

  const updateTime = async (index, data) => {
    // let data = [ ...timeData ];

    let copy = data[index];
    FindOverWrite(index, copy, data);

    if (!copy?.time_from || !copy?.time_to) {
      return false;
    }

    let cDate = currentDate;
    const startTime = moment(`${cDate} ${copy?.time_from}`, "YYYY-MM-DD HH:mm");
    let endTime = moment(`${cDate} ${copy?.time_to}`, "YYYY-MM-DD HH:mm");

   
    if (startTime > endTime) {
      let nextDay = moment(cDate).add("days", 1).format("YYYY-MM-DD");
     
      endTime = moment(`${nextDay} ${copy?.time_to}`, "YYYY-MM-DD HH:mm");
    }
    let duration = moment.duration(endTime.diff(startTime));

    // if (duration.asHours() < 0) {
    
    //   const endTime2 = moment(timeData?.time_to, "HH:mm").add(1, "day");
    //   duration = moment.duration(endTime2.diff(startTime));
    // }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    let hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);
     
    copy["date"] = cDate;
    copy["workingHr"] = hrCalc;
    copy["totalMin"] = totalMin;

    if(payPeriod?.EndDate === currentDate){
     

      if(copy["time_to"] <= copy["time_from"]){
    

        copy["tempError"] = true;
      }else{
    
        copy["tempError"] = false;
      }
    }
    // copy["id"] = RemoveSpaceKeyGen(`${cDate}${copy?.paycode}${copy?.time_from}`);

    if (hrCalc?.replace(":", "") <= 0) {
      copy["error"] = true;
    } else {
      copy["error"] = false;
    }

    if (templateData.length && copy.code === "REG") {
      const checkData = checkAuth(startTime, endTime);
      
      if (checkData.status === "confirm") {
        copy["tempError"] = false;
      } else {
        copy["tempError"] = true;
        ShowError("Unauthorized Time added!");
      }
    }

    data[index] = copy;

    
    setTimeData(data);

    
    addHzEntry(data, copy, index);
    FindOverWrite(index, copy, data);
  };

  useEffect(() => {
    if (props.paytimeCodeList && props.paytimeCodeByID) {
      let filterdata = payCodeFilter(
        props.paytimeCodeList,
        props.paytimeCodeByID,
        userRole
      );

     

      setPayCodes(filterdata);
    }
  }, [props.paytimeCodeList, props.paytimeCodeByID]);

  useEffect(() => {
    let paycode111 = [
      {
        value: "REG",
        label: "REG",
        title: "REGULAR",
        code: "1",
        multi: 1,
        EffectDate: "2023-05-02T08:00:00.000Z",
        ExpireDate: "2023-05-03T08:00:00.000Z",
        color: "#00FF00",
        name: "jobtype",
        OrderNo: "1",
      },
    ];

    
  }, [currentDate, payCodes.length]);

 
  useEffect(() => {
    if (!payCodes.length && !currentDate) {
      return [];
    }
    let firstArray = [];

    payCodes.map((i) => {
      firstArray.push({
        id: null,
        paycode: i.code,
        code: i.label,
        date: currentDate,
        time_from: null,
        time_to: null,
        status: false,
      });
    });
    
    // setTimeData(firstArray);
  }, [payCodes, currentDate]);
  useEffect(() => {
    if (!filteredEvent.length && !payCodes.length) {
      return;
    }
    
    const dynamicData = filteredEvent?.map((i) => {
      let wd = moment(i.WorkDate).utc().format("YYYY-MM-DD");
      let startDate = moment(i.WorkFrom).utc().format("HH:mm");

      let eddate = moment(i.WorkTo).utc().format("HH:mm");
      let hrCalc = HourMinFormat(
        `${Math.floor(i.TotalHours / 60)}:${i.TotalHours % 60}`
      );

      return {
        ...i,
        code: i.PayCode_PayTimeCode?.Abberv,
        date: wd,
        ID: i.ID,
        // id: RemoveSpaceKeyGen(`${wd}${i.PayCode_PayTimeCode.ID}${startDate}`) ,
        paycode: i.PayCode,
        status: true,
        time_from: startDate,
        time_to: eddate,
        workingHr: hrCalc,
        totalMin: i.TotalHours,
        RecordNo: i.RecordNo,
        parentIndex: i.RecordNo,
        Auth: i.Auth,
      };
    });
    
    //rendor all
    let minDate = payPeriod?.StartDate ? payPeriod?.StartDate : null;
    let maxDate = payPeriod?.EndDate ? payPeriod?.EndDate : null;
    let rendorArray = [];
    

    while (minDate <= maxDate) {
      const saveData = dynamicData.filter((i) => i.date == minDate);
    
      if (saveData) {
        saveData.map((i) => {
        
          rendorArray.push({
            code: i.code,
            date: minDate, //moment(i.WorkDate).format("YYYY-MM-DD"),a
            id: RemoveSpaceKeyGen(`${minDate}${i.paycode}${i.time_from}`),
            ID: i.ID,
            error: false,
            paycode: i.paycode,
            status: false,
            time_from: i.time_from,
            time_to: i.time_to,
            workingHr: i.workingHr,
            totalMin: i.totalMin,
            isDisable: i.code === "NDIF" ? true : false,
            RecordNo: i.parentIndex,
            parentIndex: i.parentIndex,
            Auth: i.Auth,
          });
        });
      }

      

      const validCodes = getValidPaycodeOfDay(minDate, payCodes);
      validCodes.map((i) => {
        rendorArray.push({
          id: null,
          id: KeyGen(7),
          error: false,
          paycode: i.code,
          code: i.label,
          date: minDate,
          time_from: null,
          time_to: null,
          status: false,
          isDisable: i.value === "NDIF" ? true : false,
          RecordNo: "0",
        });
      });
      minDate = moment(minDate, "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    // end of rendor all

    let currentInfo = rendorArray.filter((i) => {
      return i.date == currentDate;
    });

    
    setTimeData(rendorArray);
    setCurrentData(currentInfo);
  }, [filteredEvent, payCodes]);

  useEffect(() => {
    if (payPeriod?.StartDate) {
      let sd = moment(payPeriod?.StartDate).format("YYYY-MM-DD");
      let ed = moment(payPeriod?.EndDate).format("YYYY-MM-DD");
      setStartDate(payPeriod?.StartDate);
      let todayDate = moment().format("YYYY-MM-DD");
       
      if (sd <= todayDate && ed >= todayDate) {
       
        setCurrentDate(todayDate);
      } else {
        
        setCurrentDate(moment(payPeriod?.StartDate).format("YYYY-MM-DD"));
      }
    }
  }, [payPeriod]);

  const [events, setEvents] = useState([]);

  const nextprevData = async (type) => {
    let newdate = "";
    let minDate = payPeriod?.StartDate ? payPeriod?.StartDate : null;
    let maxDate = payPeriod?.EndDate ? payPeriod?.EndDate : null;

    if (type == "next") {
      let nextdate = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
      if (!timeData.some((i) => i.date === nextdate)) {
        let filterdata = payCodeFilter(
          props.paytimeCodeList,
          props.paytimeCodeByID,
          userRole
        );
        let firstArray = [];
       
        filterdata.map(async (i) => {
          // firstArray.push({
          //   id: null,
          //   paycode: i.code,
          //   code: i.label,
          //   date: nextdate,
          //   time_from: null,
          //   time_to: null,
          //   status: false,
          // });
          
          // await  timeData.push(  {
          //       id: null,
          //       paycode: i.code,
          //       code: i.label,
          //       date: nextdate,
          //       time_from: null,
          //       time_to: null,
          //       status: false,
          //     });
        });
      }

      if (maxDate >= nextdate) {
        setCurrentDate(nextdate);
      } else {
        ShowError("Date should be in range of PPE");
      }
    } else {
      let predate = moment(currentDate).subtract(1, "day").format("YYYY-MM-DD");
      

      if (minDate <= predate) {
        setCurrentDate(predate);
      } else {
        ShowError("Date should be in range of PPE");
      }
    }
  };

  function checkOverlap(time_from, time_to, paycode, apiData) {
    const newStartTime = new Date(`2023-04-09 ${time_from}`);
    const newEndTime = new Date(`2023-04-09 ${time_to}`);

    for (let i = 0; i < apiData.length; i++) {
      const data = apiData[i];
      if (data.paycode === paycode) {
        const existingStartTime = new Date(`2023-04-09 ${data.time_from}`);
        const existingEndTime = new Date(`2023-04-09 ${data.time_to}`);
        if (
          (newStartTime >= existingStartTime &&
            newStartTime <= existingEndTime) ||
          (newEndTime >= existingStartTime && newEndTime <= existingEndTime)
        ) {
          console.error("Error: Time range overlaps with existing record.");
          break;
        }
      }
    }
  }

  const FindOverWrite = (index, currentRow, AllData) => {
    
    let code = currentRow["code"];
    let paycode = currentRow["paycode"];
    let date = currentRow["date"];
    let time_from = currentRow["time_from"];
    let time_to = currentRow["time_to"];

    let currentInfo = AllData.filter((i) => {
      return i.date == currentDate && i.paycode == paycode;
    });
    if (currentInfo.length == 1) {
      const st = currentInfo[0].time_from
        ? moment(
            `${currentInfo[0].date} ${currentInfo[0].time_from}`,
            "YYYY-MM-DD HH:mm"
          )
        : null;
      const et = currentInfo[0].time_to
        ? moment(
            `${currentInfo[0].date} ${currentInfo[0].time_to}`,
            "YYYY-MM-DD HH:mm"
          )
        : null;

      if (st && et) {
        if (
          moment(st).format("YYYY-MM-DD HH:mm") ===
          moment(et).format("YYYY-MM-DD HH:mm")
        ) {
          currentInfo[0].error = true;
        }
      }
    }
    if (currentInfo.length >= 2) {
      currentInfo.map((i, index) => {
        i.error = false;
      });
      currentInfo.map((i, index) => {
        const st = i.time_from
          ? moment(`${i.date} ${i.time_from}`, "YYYY-MM-DD HH:mm")
          : null;
        const et = i.time_to
          ? moment(`${i.date} ${i.time_to}`, "YYYY-MM-DD HH:mm")
          : null;
        currentInfo.map((j, index2) => {
          const stin = j.time_from
            ? moment(`${j.date} ${j.time_from}`, "YYYY-MM-DD HH:mm")
            : null;
          const etin = j.time_to
            ? moment(`${j.date} ${j.time_to}`, "YYYY-MM-DD HH:mm")
            : null;
          // j.error = true;
          if (st && stin && etin) {
            if (st > stin && st < etin && index !== index2) {
              j.error = true;
              i.error = true;
            }
          }
          if (st && stin) {
            if (st <= stin && st >= stin && index !== index2) {
              j.error = true;
              i.error = true;
            }
          }
          if (et && stin && etin) {
            if (et > stin && et < etin && index !== index2) {
              j.error = true;
              i.error = true;
            }
          }
          if (et && etin) {
            if (et <= etin && et >= etin && index !== index2) {
              j.error = true;
              i.error = true;
            }
          }
          // if (stin && etin) {
          
          //   if (stin == etin) {
          //     j.error = true;
          //   }
          // }
          if (st && et) {
            if (
              moment(st).format("YYYY-MM-DD HH:mm") ===
              moment(et).format("YYYY-MM-DD HH:mm")
            ) {
              i.error = true;
            }
          }
        });
      });
    } else {
    
    }
  };

  const getTimesheetRecord = () => {
    
    const data = filteredEvent?.map((i) => {
      let wd = moment(i.WorkDate).format("YYYY-MM-DD");
      let startDate = moment(i.WorkFrom).format("HH:mm");

      let eddate = moment(i.WorkTo).format("HH:mm");

      return {
        code: i.PayCode_PayTimeCode?.Abberv,
        date: wd,
        id: i.ID,
        paycode: i.PayCode,
        status: false,
        time_from: startDate,
        time_to: eddate,

        // Approve: i.Approve,
        // id: i.ID,
        // start: moment(stdate, "YYYY-MM-DDTHH:mm:ss")
        //   .utc()
        //   .format(`${wd}THH:mm:ss`), //moment(i.WorkFrom).format("YYYY-MM-DDTHH:mm:ss"),
        // end: moment(eddate, "YYYY-MM-DDTHH:mm:ss")
        //   .utc()
        //   .format(`${wd}THH:mm:ss`), // moment(i.WorkTo).format("YYYY-MM-DDTHH:mm:ss"),
        // text: `${i.PayCode_PayTimeCode.Abberv}-${moment(stdate)
        //   .utc()
        //   .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
        // paycode: i.PayCode,

        // paytype:
        //   templateID && i.prefillData ? "Auth" : i.PayCode_PayTimeCode.Abberv,
        // line: i.prefillData ? 0 : "dedicated",
        // indexNo: templateID ? -1 : parseFloat(moment().format("YYMMDDHHmmss")),
        // barColor: templateID ? "#000" : "#000",
        // backColor: templateID ? "#e07c40" : "getPPEColorCode",

        // JobOrder: i.JobOrder,
        // authStatus: "Confirm",
        // bubbleHtml: `${i.PayCode_PayTimeCode.Abberv}-${moment(stdate)
        //   .utc()
        //   .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
        // TotalHours: i.TotalHours,
        // WorkDate: i.WorkDate,
        // WorkFrom: i.WorkFrom,
        // WorkTo: i.WorkTo,
        // PayCode_PayTimeCode: i.PayCode_PayTimeCode,
      };
    });

    // const mergedArray = data.concat(timeData)
    

    // setTimeData(mergedArray)
  };

  useEffect(() => {
    getTimesheetRecord();
  }, [filteredEvent]);

  const templateError = {
    marginTop: "0px",
    border: "2px solid purple",
    borderRadius: "5px",
  };
  const simpleCss = { marginTop: "0px" };

  const addTemplateRow = (index, rows) => {
    return (
      <Row className="g-2">
        {/* )} */}
        <Col md="3">
          <TextField
            // margin="dense"
            className="flex w-full"
            variant="outlined"
            select
            SelectProps={{ native: true }}
            style={{ width: "100%" }}
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            placeholder="Paycode Type"
            disabled={true}
            // label="PaycodeType"
            // name="PaycodeType"
            value={rows.paycode}
            onChange={(e) => {
              let copy = [...timeData];
              copy[index]["paycode"] = e.target.value;
              setTimeData(copy);
            }}
          >
            <option value={""}>Select Type</option>
            {payCodes.length
              ? payCodes.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.label}
                  </option>
                ))
              : null}
          </TextField>
        </Col>
        <Col md="2">
          <TextField
            size={"small"}
            type="time"
            style={simpleCss}
            inputProps={inputProps}
            InputLabelProps={{
              style: {
                fontSize: 14,
                marginTop: "0px",
              },
              shrink: true,
            }}
            value={rows["time_from"] ? rows["time_from"] : ""}
            onChange={(e) => {
              let copy = [...timeData];
              let cpyObj = { ...copy[index] };
              cpyObj["time_from"] = `${e.target.value}`;
              copy[index] = cpyObj;
              setTimeData(copy);
              updateTime(index, copy);
            }}
            margin="dense"
            name={`time_from${index}`}
            id={`time_from${index}`}
            fullWidth={true}
            variant="outlined"
            error={rows.error || rows.tempError ? true : false}
            // hiddenLabel="true"
            disabled={rows.isDisable}
          />
        </Col>
        <Col md="2">
          <TextField
            size={"small"}
            id="time"
            // label="From"
            type="time"
            inputProps={inputProps}
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
              shrink: true,
            }}
            style={simpleCss}
            value={rows["time_to"] ? rows["time_to"] : " "}
            onChange={(e) => {
              let copy = [...timeData];
              let cpyObj = { ...copy[index] };
              cpyObj["time_to"] = `${e.target.value}`;
              copy[index] = cpyObj;
              setTimeData(copy);
              setIsIndex(index);
              updateTime(index, copy);
            }}
            margin="dense"
            name="time_to"
            fullWidth={true}
            variant="outlined"
            error={rows.error || rows.tempError ? true : false}
            // hiddenLabel="true"
            disabled={rows.isDisable}
          />
        </Col>
        <Col md="1">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "left",
            }}
          >
            <TextField
              size={"small"}
              margin="dense"
              style={{ marginTop: "0px" }}
              name="hours"
              inputProps={{
                readOnly: true,
                fontSize: 12,
                style: { fontSize: 14 },
              }}
              fullWidth={true}
              variant="outlined"
              value={rows.workingHr ? rows.workingHr : ""}
              sx={{ fontSize: 14 }}
              disabled={rows.isDisable}
            />
          </div>
        </Col>
        <Col md="2">
          <button
            className="btn btn-outline-success paroll-contents-btn"
            disabled={rows.isDisable}
            onClick={() => {
              let items = [...timeData];
              

              if (timeData[index].ID) {
                let list = deletedList;
                list.push(timeData[index].ID);

                let olds = timeData.filter(
                  (i) => i.parentIndex == items[index].id && i.date==currentDate
                );

               
                olds.map((i)=>{list.push(i.ID)});  

                setDeletedList(list);
              }
              let remainAray = [
                ...items.slice(0, index),
                ...items.slice(index + 1),
              ];

              remainAray = remainAray.filter(
                (i) => i.parentIndex !== items[index].id
              );

              let listCode = remainAray.filter((i) => {
                return i.paycode == rows.paycode && i.error == true;
              });

              if (listCode.length) {
                listCode.map((iq) => {
                  let existCode = remainAray.findIndex((i) => {
                    return i.id == iq.id;
                  });
                  updateTime(existCode, remainAray);
                });
              }

              setTimeData(remainAray);
            }}
            style={{ marginBottom: 20, marginRight: "10px" }}
          >
            -
          </button>
          <button
            disabled={rows.isDisable}
            className="btn btn-outline-success paroll-contents-btn"
            onClick={() => {
              let copy = [...timeData];
              const newObj = { ...timeData[index] };
              newObj["id"] = null;
              newObj["time_from"] = null;
              newObj["time_to"] = null;
              newObj["totalMin"] = 0;
              newObj["workingHr"] = null;
              newObj["error"] = false;
              newObj["id"] = KeyGen(7);
              copy.splice(index + 1, 0, newObj);
              setTimeData(copy);
            }}
            style={{ marginBottom: 20 }}
          >
            +
          </button>
        </Col>
        {/* <Col md="1">
          { index === timeData.length - 1 ? (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={ () => {
                let copy = [ ...timeData ];
                copy.push(sampleData);
                setTimeData(copy);
              } }
              style={ { marginBottom: 20 } }
            >
              +
            </button>
          ) : (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={ () => {
                let copy = [ ...timeData ];
                if (timeData[ index ].id) {
                  let list = deletedList;
                  list.push(timeData[ index ].id);
                  setDeletedList(list);
                }
                copy.splice(index, 1);
                setTimeData(copy);
              } }
              style={ { marginBottom: 20 } }
            >
              -
            </button>
          ) }
        </Col> */}
      </Row>
    );
  };

  return (
    <div>
      <Card>
        <CardHeader className="pb-0">
          <h5>Version 1 : Enter Timesheet using Start and End Time</h5>
        </CardHeader>
        <CardBody>
          {/* <Form> */}
          {/* <Row>
            <Col lg="12">
              {JSON.stringify(
                timeData.filter((i) => {
                  return i.date == currentDate;
                })
              )}
            </Col>
          </Row> */}
          <Row className="g-3 mt-2">
            <Col lg="6">
              <div className="flex " justify="center">
                {/* <Button variant="outlined" onClick={ () => nextprevData('prev') }>{ '<<' }</Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="bg-primary"
                  onClick={() => nextprevData("prev")}
                  style={{
                    // borderRadius: '50%',
                    width: "48px",
                    height: "48px",
                    minWidth: 0,
                    padding: 0,
                  }}
                >
                  {/* <IconButton onClick={ () => nextprevData('prev') } aria-label="fingerprint" color="primary"  variant="contained"> */}
                  <ArrowBack />
                  {/* </IconButton> */}
                </Button>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // className=" payroll-content-date1"
                    style={{
                      width: "240px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                    // margin="dense"
                    size="medium"
                    format="MMM-dd-yyyy EEEE"
                    // format="MM/dd/yyyy"
                    disableToolbar
                    inputVariant={"outlined"}
                    id="date-picker-inline"
                    // label="Date"
                    value={currentDate}
                    minDate={payPeriod?.StartDate ?  payPeriod?.StartDate :null}
                    maxDate={payPeriod?.EndDate? payPeriod?.EndDate :null}
                    onChange={(_, value) => {
                      setCurrentDate(moment(value).format("YYYY-MM-DD"));
                      // handleDateFieldChange("date", value, index)
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    name="date"
                    // variant="outlined"
                    // hiddenLabel="true"
                  />
                </MuiPickersUtilsProvider>
                <Button
                  variant="contained"
                  color="primary"
                  className="bg-primary"
                  onClick={() => nextprevData("next")}
                  style={{
                    // borderRadius: '50%',
                    width: "48px",
                    height: "48px",
                    minWidth: 0,
                    padding: 0,
                  }}
                >
                  {/* <IconButton onClick={ () => nextprevData('next') } aria-label="fingerprint"  variant="contained" color="primary"> */}
                  <ArrowForward />
                </Button>
                {/* </IconButton> */}
                {/* <Button variant="outlined" onClick={()=>nextprevData('next')}>{ '>>' }</Button> */}
              </div>
            </Col>
          </Row>
          <Row className="g-3 mt-2">
            <Col md="3">
              <Typography style={{ display: "flex", justifyContent: "left" }}>
                <h6>Pay Type</h6>
              </Typography>
            </Col>
            <Col md="2">
              <Typography style={{ display: "flex", justifyContent: "left" }}>
                <h6>Start Time</h6>
              </Typography>
            </Col>
            <Col md="2">
              <Typography style={{ display: "flex", justifyContent: "left" }}>
                <h6>End Time</h6>
              </Typography>
            </Col>
            <Col md="1">
              <Typography style={{ display: "flex", justifyContent: "left" }}>
                <h6>Hours</h6>
              </Typography>
            </Col>
            <Col md="2"></Col>
          </Row>
          {timeData.map((row, index) => {
            if (row.date == currentDate) {
              return addTemplateRow(index, row);
            }
          })}
        </CardBody>
      </Card>
    </div>
  );
});

export default PayrollSimpleView;
