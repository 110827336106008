import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Modal.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  WeekColor,
  getHoursMinutes,
  getUserRole,
  getValidPaycodeOfDay,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigDB from "../../../Config/ThemeConfig";
import { faCircleCheck, faClock } from "@fortawesome/pro-duotone-svg-icons";
import { color } from "highcharts";
import moment from "moment";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import dayjs from "dayjs";
import "dayjs/locale/en";

function TableRowsEmpV1({
  rowsData,
  deleteTableRows,
  handleChangeV1,
  handleDateFieldChange,
  setTimeTo,
  getDateColor,
  payPeriod,
  maxSLLeave,
  maxALLeave,
  viewFields,
  viewField,
  setOpen,
  setDeleteRowIndex,
  verify,
  role,
  radioData,
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  const calcualteTime = (mins) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");

    return `${hr} hr  ${min} mins`;
  };
  const handleErrorMessage = (
    ole1,
    ole2,
    dateExceed1,
    dateExceed2,
    leaveHoursAL,
    leaveHoursSL,
    dateError
  ) => {
    console.log("dsvklfjksfhjsksghldksghsl", ole1, ole2, dateExceed1);
    if (ole1 || ole2) {
      return "Check your time slot. It is overwrite with other row";
    } else if (dateExceed1 || dateExceed2) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (ole1 || (ole2 && (dateExceed1 || dateExceed2))) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (leaveHoursAL) {
      return ` AL Leave Hours Exceeded ${calcualteTime(maxALLeave)}`;
    } else if (leaveHoursSL) {
      return `SL Leave Hours Exceeded  ${calcualteTime(maxSLLeave)}`;
    } else if (dateError) {
      return `Date Not in PPE`;
    } else {
      return "";
    }
  };

  return rowsData.length
    ? rowsData.map((data, index) => {
        const {
          jobtype,
          date,
          time_from,
          time_to,
          time_from1,
          time_to1,
          hrs,
          jobOrder,
          parentIndex,
          hjobtype,
          ole1,
          ole2,
          rowstatus,
          dateExceed1,
          dateExceed2,
          authError2,
          authError1,
          leaveHoursAL,
          leaveHoursSL,
          approvedStatus,
          verify,
          Status,
          ID,
          dateError,
          PayCode_PayTimeCode,
        } = data;
        // let parentIndex = data.parentIndex ? data.parentIndex : "";
        // let parentEnable = parentIdx!= "" ? true : false;
        console.log("Payrol table data", data);
        // const inputProps = {
        //   step: 300,
        //   fontSize: 14,
        // };
        const inputProps = {
          style: { fontSize: 14, step: 300 },
          step: 300,
        };

        let parentEnable =
          parentIndex !== "0" && parentIndex != null ? true : false;
        const isDisableRow = (role, Status, verify) => {
          if (["user"].includes(role)) {
            return (
              Status === "timekeeper" ||
              Status === "supervisor" ||
              Status === "admin" ||
              verify ||
              approvedStatus === "Approve"
            );
          } else {
            return false;
          }
        };
        // const isDisableRow = (parentIndex, jobtype, resource) => {
        //   try {
        //     if (jobtype === "NDIF") {
        //       return true;
        //     } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
        //       if (
        //         ["time_from", "time_to", "time_from1", "time_to1"].includes(
        //           resource
        //         )
        //       ) {
        //         return false;
        //       } else {
        //         return true;
        //       }
        //     } else {
        //       return false;
        //     }
        //   } catch (error) {
        //     console.log("error", error.message);
        //   }
        // };

        // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
        const getSelectedType = (jobtype) => {
          let filterObj = jobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP ST", filterObj);

          return filterObj !== undefined
            ? filterObj
            : {
                value: "REG",
                label: "REG",
                title: "REGULAR",
                code: "1",
                multi: 1,
                EffectDate: "1990-01-01T00:00:00.000Z",
                ExpireDate: "2099-12-31T00:00:00.000Z",
                name: "jobtype",
              };
        };
        const getSelectedHType = (jobtype) => {
          let filterObj = hjobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP STD", filterObj);

          return filterObj !== undefined ? filterObj : null;
        };

        const weekColors = WeekColor(
          payPeriod?.StartDate,
          payPeriod?.EndDate,
          date
        );

        const filterTimeRange = (radioData, payPeriod) => {
          if (radioData === "week1") {
            return {
              StartDate:
                payPeriod && payPeriod.StartDate ? payPeriod.StartDate : null,
              EndDate:
                payPeriod && payPeriod.EndDate
                  ? moment(payPeriod.StartDate, "YYYY-MM-DD")
                      .add(6, "days")
                      .format("YYYY-MM-DD")
                  : null,
            };
          } else if (radioData === "week2") {
            return {
              StartDate:
                payPeriod && payPeriod.StartDate
                  ? moment(payPeriod.StartDate, "YYYY-MM-DD")
                      .add(7, "days")
                      .format("YYYY-MM-DD")
                  : null,
              EndDate:
                payPeriod && payPeriod.EndDate ? payPeriod.EndDate : null,
            };
          } else {
            return {
              StartDate:
                payPeriod && payPeriod.StartDate
                  ? moment(payPeriod.StartDate).format("MM/DD/YYYY")
                  : null,
              EndDate:
                payPeriod && payPeriod.EndDate
                  ? moment(payPeriod.EndDate).format("MM/DD/YYYY")
                  : null,
            };
          }
        };
        const isTableRowDisable = isDisableRow(role, Status, verify);
        return (
          <>
            <tr
              key={index}
              bordercolor="red"
              // className={index % 2 === 0 ? "trRow1" : "trRow2"}
              style={{
                backgroundColor: getDateColor(date, rowsData),
                // alignItems: "center",
                // display: "flex",
              }}
              // style={{ backgroundColor: (ole1 || ole2 ? "#e7e7e7" : null),borderRadius:'20px' }}
            >
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  margin="dense"
                  name="jobt"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={jobtype && jobtype?.trim() != "" ? jobtype : "NONE"}
                  sx={{ fontSize: 14 }}
                  disabled={isTableRowDisable}
                />
              </td>
              {}
              {/* <td className="paroll-contents-td">
                {!verify ? (
                  <FontAwesomeIcon
                    icon={faClock}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="2x"
                    style={{
                      color: ConfigDB.data.color.primary_color,
                      // float: "right",
                      fontSize: "20px",
                      color: "green",
                    }}
                    cursor="pointer"
                    className="mt-1"
                  />
                )}
              </td> */}

              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="w-100 payroll-content-date1"
                    // margin="dense"
                    size="small"
                    format="MM/dd/yyyy"
                    disableToolbar
                    time
                    inputVariant={"outlined"}
                    id="date-picker-inline"
                    // label="Date"
                    value={moment(date).format("MM/DD/YYYY")}
                    minDate={
                      payPeriod.StartDate
                        ? filterTimeRange(radioData, payPeriod).StartDate
                        : null
                    }
                    maxDate={
                      payPeriod.EndDate
                        ? filterTimeRange(radioData, payPeriod).EndDate
                        : null
                    }
                    onChange={ (_, value) => {
                      let timer = null;
                      timer = setTimeout(() => {
                        console.log('MAx',value);
                        if (moment(value, "MM/DD/YYYY", true).isValid()) {
                          console.log('MAx isvalid', value);
                          handleDateFieldChange("date", value, index);
                        } else {
                          console.log('MAx isvalid',value);
                        }
                        
                    
                      }, 500);
                      return () => {
                        if (timer) {
                            clearTimeout(timer);
                        }
                    };
        
                       
                      
                    } }
                    mask="__/__/____"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isTableRowDisable}
                    name="date"
                    hiddenLabel="true"
                  />
                </MuiPickersUtilsProvider>  */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    // format="MM/DD/YYYY"
                    format="dd MM/DD/YYYY"
                    label=""
                    value={date ? dayjs(date) : null}
                    onChange={(e) => {
                      handleDateFieldChange("date", e?.$d, index);
                    }}
                    disabled={isTableRowDisable}
                    name="date"
                    hiddenLabel={true}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "39px",
                      },
                    }}
                    onError={(e) => {
                      console.log("dasdadada", e);

                      handleChangeV1(
                        index,
                        {
                          target: {
                            value: e,
                            name: "dateError",
                          },
                        },
                        e,
                        data
                      );
                    }}
                    minDate={
                      payPeriod.StartDate
                        ? dayjs(filterTimeRange(radioData, payPeriod).StartDate)
                        : null
                    }
                    maxDate={
                      payPeriod.EndDate
                        ? dayjs(filterTimeRange(radioData, payPeriod).EndDate)
                        : null
                    }
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
                {}
                {/* <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Start Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={date}
                  onChange={(_, value) => {
                    console.log("date===>", _,value);
                    handleDateFieldChange("date", value, index);
                  }}
                  disabled={isTableRowDisable}
                  name="date"
                  hiddenLabel="true"
                  minDate={
                    payPeriod.StartDate
                      ? filterTimeRange(radioData, payPeriod).StartDate
                      : null
                  }
                  maxDate={
                    payPeriod.EndDate
                      ? filterTimeRange(radioData, payPeriod).EndDate
                      : null
                  }

                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                /> */}
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from ? time_from : ""}
                  onChange={(e) => {
                    console.log("dasdadad", e.target.value);
                    handleChangeV1(index, e, "", data);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_from"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={ parentEnable }
                  disabled={isTableRowDisable}
                  hiddenLabel="true"
                />
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"

                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 ||
                    dateExceed1 ||
                    authError1 ||
                    leaveHoursAL ||
                    leaveHoursSL
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to ? time_to : ""}
                  onChange={(e) => {
                    handleChangeV1(index, e, "", data);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isTableRowDisable}
                />
              </td>

              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  margin="dense"
                  name="hours"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={hrs}
                  onChange={(e) => handleChangeV1(index, e, "", data)}
                  sx={{ fontSize: 14 }}
                  disabled={isTableRowDisable}
                />
              </td>
              {viewField ? (
                <>
                  {viewFields?.map((i) => {
                    return (
                      <td
                        className="paroll-contents-td"
                        style={{ backgroundColor: `${weekColors}` }}
                      >
                        <TextField
                          size={"small"}
                          margin="dense"
                          inputProps={{
                            readOnly: true,
                            fontSize: 12,
                            style: { fontSize: 14 },
                          }}
                          fullWidth={true}
                          variant="outlined"
                          value={data[i.value] ? data[i.value] : null}
                          sx={{ fontSize: 14 }}
                        />
                      </td>
                    );
                  })}
                </>
              ) : null}
              <td
                className="paroll-contents-td tcolor"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <button
                  className={`${
                    parentEnable ? "greyBtn" : ""
                  } btn btn-outline-danger paroll-contents-calcle `}
                  disabled={isTableRowDisable}
                  onClick={(e) => {
                    // if (ID) {
                    setDeleteRowIndex(index);
                    setOpen(true);
                    // } else {
                    //   deleteTableRows(index);
                    // }
                    handleChangeV1(index, e, "", data);
                  }}
                >
                  X
                </button>
              </td>
            </tr>
            <tr
              style={{
                display:
                  ole1 ||
                  ole2 ||
                  dateExceed1 ||
                  dateExceed2 ||
                  leaveHoursAL ||
                  leaveHoursSL ||
                  dateError
                    ? ""
                    : "none",
              }}
            >
              <td
                colspan="10"
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  alignItems: "center",
                  transition: "ease-in",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {handleErrorMessage(
                    ole1,
                    ole2,
                    dateExceed1,
                    dateExceed2,
                    leaveHoursAL,
                    leaveHoursSL,
                    dateError
                  )}
                </p>
              </td>
            </tr>
          </>
        );
      })
    : null;
}

export default TableRowsEmpV1;
