import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H5 } from "../../../../AbstractElements";
import {
    Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const selectColumnData = [
  { label: "Employee Id", value: "ID" },
  { label: "EmployeeName", value: "Employeename" },
];

const NotificationSearch = () => {
  const [selectColumn, setSelectColumn] = useState("");
  const [textValue, setTextValue] = useState("");
  return (
    <Card className="latest-update-sec">
      <CardHeader>
        <div className="header-top d-sm-flex align-items-center">
          <H5>Notification Search</H5>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={2} className=" text-left mt-2">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Select Column
              </InputLabel>
              <Select
                label="Select PayCode Template"
                id="demo-simple-select"
                value={selectColumn ? selectColumn : ""}
                onChange={(e, value) => {
                  setSelectColumn(e.target.value);
                }}
              >
                {selectColumnData
                  ? selectColumnData.map((i) => {
                      return <MenuItem value={i.value}>{i.label}</MenuItem>;
                    })
                  : ""}
              </Select>
            </FormControl>
          </Col>
          <Col md={4} className=" text-left mt-2" >
            <TextField
            style={{width:"100%"}}
              size="small"
              label="Enter Value"
              value={textValue}
              onChange={(e) => {
                setTextValue(e.target.value);
              }}
            />
          </Col>
        </Row> 
            <Button variant="contained" className="mt-4 bg-primary">
                Search
            </Button> 
      </CardBody>
    </Card>
  );
};

export default NotificationSearch;
