import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilterList from "./FilterList";
import TableComponent from "./TableComponents";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const { requestDetail, configrationJSON, setDetail, setIsUpdate } = props;
  const classes = useStyles();
  const [filterBy, setFilter] = useState(configrationJSON.INPUT_FIELDS);
  const [filteredList, setFilteredList] = useState(requestDetail);

  const changeHeaderFilter = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
    let dataList = requestDetail;
    const filterByNew = { ...filterBy, [name]: value };
    if (filterByNew) {
      let filterKeys = Object.keys(filterByNew);
      dataList = dataList.filter((data) => {
        return filterKeys.every((key) => {
          if (!filterByNew[key]) return true;
          return Array.isArray(filterByNew[key]) ? Array.isArray(filterByNew[key]) : data[key]
            ?.toLowerCase()
            ?.includes(filterByNew[key]?.toLowerCase());
        });
      });
    }
    setFilteredList(dataList);
  };

  useEffect(() => {
    setFilteredList(requestDetail)
  }, [requestDetail]);

  const handleRowClick = (data) => {
    setIsUpdate(true)
    setDetail(data.data);
  };
  return (
    <TableComponent
      columns={configrationJSON?.tableColumns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            configrationJSON={configrationJSON}
            changeHeaderFilter={changeHeaderFilter}
            filterBy={filterBy}
          />
        )
      }
    />
  );
};

export default DataTable;
