import React from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { H3, H4 } from "../../AbstractElements";
const ModalCustom = (props) => {
  const {
    open,
    handleClose,
    title = '',
    fullWidth = false,
    maxWidth = "md",
    closeButton = false,
  } = props;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {closeButton && (
        <DialogTitle id="id" style={{ padding: "0 !important" }}>
          <Box display="flex" alignItems='flex-end' justifyContent={title ? "space-between" : "flex-end"}>
            {title && <H4>{title}</H4>}
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
      )}
      {props.children}
    </Dialog>
  );
};

export default ModalCustom;
