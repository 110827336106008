 
import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'; 

import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../../../app/store/reducers/slices/notificationSlice';
import { LatestActivity } from '../../../../Components/Common/Data/Dashboard';
import { ArrowSvgIcon } from '../../../../Components/Common/Data/SvgIcons';
import moment from 'moment';
import NotiFicationTable from './NotiFicationTable';
import NotificationSearch from './NotificationSearch';
 

const NotificationView = () => {
    const dispatch = useDispatch();
    const notificationData = useSelector(
      ({ notificationSlice }) => notificationSlice.notifications
    );
    useEffect(() => {
      dispatch(getNotification());
    }, []); 
  return (
     <>
     <div>
        <NotificationSearch />
        <NotiFicationTable notificationData={notificationData}/>
     </div>
   
</>
  )
}

export default NotificationView