import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { Label } from "reactstrap";
import { Checkbox } from "@mui/material";

function FilterList(props) {
  const { changeHeaderFilter, filterBy, filterListByIsActive } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee ID"
          name="employeeID"
          value={filterBy.employeeID}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="certificationCode"
          placeholder="Certification Code"
          value={filterBy.certificationCode}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="certificationSeq"
          placeholder="Certification Seq"
          value={filterBy.certificationSeq}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          format="MM/dd/yyyy"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "validFrom", value } });
          }}
          value={filterBy && filterBy.validFrom}
          placeholder="Valid From"
          name="ValidFrom"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "validTo", value } });
          }}
          value={filterBy && filterBy.validTo}
          placeholder="Valid To"
          name="ValidTo"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="remarks"
          placeholder="Remarks"
          value={filterBy && filterBy.remarks}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.isActive}
          onClick={(event) => filterListByIsActive(event)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>

    </TableRow>
  );
}

export default FilterList;