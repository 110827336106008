import React, { useState } from "react";
import { Autocomplete, Button, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessages,
  faFileSpreadsheet,
  faUser,
  faCalendarCheck,
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-duotone-svg-icons";
import ChatModal from "./ChatModal.js";
import { FormControl, TextField } from "@mui/material";
import { Row } from "react-bootstrap";
import { CardHeader, Container } from "reactstrap";
import { useAuth } from "oidc-react";
import {
  ShowSucess,
  getUserId,
  getUserRole,
} from "../../../app/utility/helper.js";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ConfirmModal from "../../../app/utility/ConfirmModal.js";
import axios from "axios";

const PayrollHeader = (props) => {
  const { allPayPeriod, setPayPeriod, payPeriod, setIsLoadTimesheetData } =
    props;
  const { userData } = useAuth();
  let role = getUserRole(userData);
  const userId = getUserId(userData);
  const history = useNavigate();
  const [isResetTimesheetModal, setIsResetTimesheetModal] = useState(false);

  const sortedPayPeriod =
    allPayPeriod?.length > 0 &&
    allPayPeriod?.sort((a, b) => {
      return b.ID - a.ID;
    });

  const currentPayPeriodIndex =
    sortedPayPeriod?.length > 0 &&
    sortedPayPeriod?.findIndex((i) => i?.ID === payPeriod?.ID);
  const handleChangePayPeriod = (skipType) => {
    if (skipType === "next") {
      if (currentPayPeriodIndex > 0) {
        setPayPeriod(allPayPeriod[currentPayPeriodIndex - 1]);
      }
    } else {
      if (currentPayPeriodIndex < allPayPeriod.length - 1) {
        setPayPeriod(allPayPeriod[currentPayPeriodIndex + 1]);
      }
    }
  };

  const handleConfirmResetTimesheet = async () => {
    let data = {
      employeeID: userId,
      ppe: payPeriod.ID,
    };
    axios
      .delete(
        `${process.env.REACT_APP_WEB_API_URL}Employee/DeleteEmployeeProcessPayTime`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
          params: data,
        }
      )
      .then(async (response) => {
        console.log("response", response);
        ShowSucess("Timesheet Reset Successfully");
        setIsLoadTimesheetData(true);
        // loadPreData();
        setIsResetTimesheetModal(false);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <>
      {" "}
   
        <CardHeader>
          <div>
            <div
              className={`flex flex-1 w-full bg-primary page-content`}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "4.2rem",
                borderRadius: "5px",
                alignItems: "center",
              }}>
              <FontAwesomeIcon
                icon={faFileSpreadsheet}
                className="mx-10  px-3 fs-4"
              />
              <Typography className="fs-5">
                {role === "user"
                  ? "Payroll Work Time"
                  : "Employee Payroll List"}
                <b className="pl-2"> : Pay Period </b>
              </Typography>

              <div style={{ display: "flex", flex: 1 }} className="mx-4">
                <FormControl className="payperiodRange">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ cursor: "pointer", padding: "15px" }}>
                      {currentPayPeriodIndex < sortedPayPeriod.length - 1 && (
                        <FontAwesomeIcon
                          icon={faChevronsLeft}
                          onClick={() => handleChangePayPeriod("prev")}
                        />
                      )}
                    </div>
                    {sortedPayPeriod?.length > 0 && (
                      <Autocomplete
                        size="small"
                        name="payperiod_range"
                        id="payperiod_range"
                        className="min-w-128 text-16"
                        style={{ color: "red" }}
                        value={{
                          ID: payPeriod?.ID,
                          StartDate: moment(payPeriod?.StartDate).format(
                            "MM-DD-YYYY"
                          ),
                          EndDate: moment(payPeriod?.EndDate).format(
                            "MM-DD-YYYY"
                          ),
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, value) => {
                          console.log("setData", value);
                          let info = allPayPeriod.find((i) => {
                            return (
                              i.ID.toString() === value?.ID.toString() + ""
                            );
                          });
                          console.log("info", info);
                          if (info) setPayPeriod(info);
                        }}
                        options={sortedPayPeriod}
                        getOptionLabel={(option) =>
                          `${moment(option.StartDate).format(
                            "MM-DD-YYYY"
                          )} TO ${moment(option.EndDate).format("MM-DD-YYYY")}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            style={{ minWidth: "300px" }}
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                            }}
                          />
                        )}
                      />
                    )}
                    <div style={{ cursor: "pointer", padding: "15px" }}>
                      {currentPayPeriodIndex > 0 && (
                        <FontAwesomeIcon
                          icon={faChevronsRight}
                          onClick={() => handleChangePayPeriod("next")}
                        />
                      )}
                    </div>
                  </div>
                </FormControl>
              </div>
              {["user"].includes(role) && (
                <div>
                  <Button
                    onClick={() => setIsResetTimesheetModal(true)}
                    variant="contained"
                    className="m-10 bg-primary"
                    style={{
                      color: "#FFF",
                      border: "1px solid #FFF",
                    }}>
                    Reset PPE Data
                  </Button>
                </div>
              )}
              {/* {!["user"].includes(role) ? (
              <Tooltip title={"Verify TimeSheet"}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history(`/payroll-work-verify/${props.userId}`, { state: { isVerifyTimeSheet: true }});
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </div>
              </Tooltip>
            ) : null} */}
              <Tooltip title={"Send Message"}>
                <div
                  style={{ padding: "20px", cursor: "pointer" }}
                  onClick={() => {
                    props.handleChatClose();
                  }}>
                  <FontAwesomeIcon icon={faMessages} />
                </div>
              </Tooltip>
              {props.userId ? (
                <Tooltip title={"View User Profile"}>
                  <div
                    style={{ padding: "0px 10px 0px 0px", cursor: "pointer" }}
                    onClick={() => {
                      history(
                        `/admin/employee/update/${props.userId}`,

                        {
                          state: {
                            path: `/payroll-work/${props.userId}`,
                            breadName: "Payroll Work Time",
                          },
                        }
                      );
                    }}>
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                </Tooltip>
              ) : null}

              {props.chatOpen && (
                <ChatModal
                  open={props.chatOpen}
                  closeModal={props.handleChatClose}
                  appId="123"
                  appType="123"
                />
              )}
            </div>
          </div>
          {isResetTimesheetModal ? (
            <ConfirmModal
              open={isResetTimesheetModal}
              setOpen={setIsResetTimesheetModal}
              confimClick={handleConfirmResetTimesheet}
              message={
                "Are you sure to delete the timesheet data for this pay period?"
              }
              modalHeader="Alert Message"
            />
          ) : (
            ""
          )}
        </CardHeader>{" "}
     
    </>
  );
};

export default PayrollHeader;
