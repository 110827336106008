import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "notificationSlice";
 

export const getNotification = createAsyncThunk(
  `${sliceName}/notification`,
  async () => {
    try {
      //get DPDV
      let notifications = [];
      try {
        const notificationsData = await axios.get("https://api.ehrpweb.com/api/notification", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        });

        if (notificationsData.data.length) {
          notificationsData.data.map((item) => {
            notifications.push(item);
          });
        }
      } catch (error) {
        return {notifications : []};
      }
      return {
        notifications: notifications,
      };
    } catch (e) {
      return {
        error: e.message,
        notifications:[]
      };
    }
  }
);

export const createNotification = createAsyncThunk(
  `${sliceName}/createNotification`,
  async (input) => {
    
    try {
      const  data  = await axios.post(
        `${process.env.REACT_APP_API_URL}notification/create`,
        input,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        createNotification: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        createNotification: [],
      };
    }
  }
);



const notificationSlice = createSlice({
  name: sliceName,
  initialState: {
   createNotification:[],
    notifications: []
  },
  extraReducers: {
    [getNotification.fulfilled]: (state, action) => {
      state.notifications = action.payload.notifications;
    },

    [createNotification.fulfilled]: (state, action) => {
      state.createNotification = action.payload.createNotification;
    },
   
  },
});

export default notificationSlice.reducer;
