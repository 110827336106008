import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReportHeader from '../ReportHeader';
import RequestTable from "./component/request-table";
import { makeStyles } from "@material-ui/core/styles";
import W2ReportLayout from './component/W2ReportLayout';
import { getAllW2ReportRecords } from '../../../app/utility/axiosService';
import { getUserId, getUserRole } from "../../../app/utility/helper";
import AdvanceSearch from './component/AdvanceSearch';
import { useAuth } from 'oidc-react';
import "./style.css";
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      ".&.MuiAccordionSummary-content": {
        padding: 0,
      },
      "& .MuiPaper-root": {
        background: "none",
      },
      margin: "0 !important",
      borderTopWidth: 1,
      borderTopColor: "#ccc",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      fontWeight: 500,
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
}));

const W2Report = (props) => {

  const classes = useStyles();
  const { userData } = useAuth();
  const ID = getUserId(userData);
  const role = getUserRole(userData);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [paramsQueries, setParamsQueries] = useState([]);
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });
  const [getRecordDate, setGetRecordDate] = useState({
    startDate: `01-01-${new Date().getFullYear()}`,
    endDate: `12-31-${new Date().getFullYear()}`
  });

  const pageLayout = useRef(null);

  useEffect(() => {
    fetchW2ReportRecords();
  }, []);

  useEffect(() => {
    fetchW2ReportRecords();
  }, [paramsQueries, JSON.stringify(getRecordDate)]);

  const fetchW2ReportRecords = async () => {
    if (getRecordDate.startDate && getRecordDate.endDate) {
      // setLoading(true);
      setDataLoading(true);
      let temp = [];
      if (paramsQueries && paramsQueries?.length > 0) {
        paramsQueries.forEach((o, i) => {
          temp.push({
              "columnName": o.columnName,
              "operator": o.operator,
              "paramValue": o.paramValue?.trim(),
              "paramType": "",
              "logicOperator": (i===0) ? "" : o.logicOperator
          })
        });
      }

      let payload = {
        startDate: new Date(getRecordDate?.startDate).toISOString(),
        endDate: new Date(getRecordDate?.endDate).toISOString(),
        params: temp
      }
      const res = await getAllW2ReportRecords(payload);
      if (res?.status===200) {
        setTableList(res.data);
        // setTotalCount(res.totalCount);
      }
      setLoading(false);
      setDataLoading(false);
    }
  };

    return (
      <>
      <Container fluid={true} style={{ overflow: "auto" }}>
            <div style={{ position: "sticky", top: 0, zIndex: 1 }}
            ref={pageLayout}>
                <ReportHeader {...props} header="W2 Summary Reports" />
            </div>
            <div className='page-content'>
            <AdvanceSearch loading={loading} role={role} userId={ID} paramsQueries={paramsQueries} setParamsQueries={setParamsQueries} setLoading={setLoading} />
            
            <W2ReportLayout
                {...props}
                role={"admin"}
                getRecordDate={getRecordDate}
                setGetRecordDate={setGetRecordDate}
            />

            <div
                classes={{
                root: "w-full h-full",
                header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                contentWrapper: "p-0",
                contentCard: "rounded-none",
                }}
            >
                <div className={`${classes.root} w-full`}>
                    <RequestTable
                        {...props}
                        role={"admin"}
                        requestDetail={tableList}
                        detail={detail}
                        setDetail={setDetail}
                        loading={dataLoading}
                        setLoading={setDataLoading}
                        userDetail={null}
                        apiCall={apiCall}
                        setApiCall={setApiCall}
                    />
                </div>
            </div>
            </div>
            
        </Container>
        </>
    )
}

export default W2Report;