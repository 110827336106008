import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableComponent from "./tableComponent";
import FilterList from "./filterList";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../../app/utility/ConfirmModal";
import axios from "axios";
import moment from "moment";
import { Row } from "antd";
import { useAuth } from "oidc-react";
import { toast } from "react-toastify";
import { formatMoney } from "../../../../app/utility/helper";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const { userData } = useAuth();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    minRange: '',
    maxRange: '',
    rate: '',
    isSingle: false,
  });

  const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  const [defaultRemoveData, setDefaultRemoveData] = useState(null);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.minRange) {
        dataList = dataList.filter((data) =>
          data.minRange?.toString().includes(
            filterBy.minRange?.toString()
          )
        );
      }

      if (filterBy?.maxRange) {
        dataList = dataList.filter((data) =>
          data.maxRange?.toString().includes(
            filterBy.maxRange?.toString()
          )
        );
      }

      if (filterBy?.rate) {
        dataList = dataList.filter((data) =>
          data.rate?.toString().includes(
            filterBy.rate?.toString()
          )
        );
      }

      if (filterBy?.isSingle) {
        dataList = dataList.filter((data) =>
            data.isSingle === filterBy.isSingle
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const changeHeaderFilter1 = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
  };

  const filterListByIsSingle = (e) => {
    setFilter({ ...filterBy, 'isSingle': e.target?.checked });
  };

  const confimRemoveClick = async () => {
    axios.delete(`${process.env.REACT_APP_WEB_API_URL}FederalTax/Delete?id=${defaultRemoveData.id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenRemoveAlert(false);
          toast.success('Record Deleted');
          props.getData();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      oldId: data.id,
      minRange: data.minRange,
      maxRange: data.maxRange,
      rate: data.rate,
      isSingle: data.isSingle
    };

    props.setDetail(info);
  };

  const columns = [
    {
      id: "minRange",
      name: "Min Range",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${formatMoney(Number(tableMeta.minRange) ? Number(tableMeta.minRange) : 0)}`;
        },
      },
    },
    {
      id: "maxRange",
      name: "Max Range",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${formatMoney(Number(tableMeta.maxRange) ? Number(tableMeta.maxRange) : 0)}`;
        },
      },
    },
    {
      id: "rate",
      name: "Rate",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${tableMeta.rate}%`;
        },
      },
    },
    {
      id: "isSingle",
      name: "Is Single",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
            checked={tableMeta.isSingle}
            inputProps={{ 'aria-label': 'controlled' }}
          />;
        },
      },
    },
    {
      id: "Action",
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row>
            <FontAwesomeIcon
              icon={faTrash}
              className="sub-header trashIcon"
              onClick={() => {
                setDefaultRemoveData(tableMeta);
                setOpenRemoveAlert(true);
              }}
            />
          </Row>
        },
      },
    },
  ];

  return (
    <>
      <TableComponent
        columns={columns}
        filteredList={filteredList}
        totalCount={props.totalCount}
        setSkipCount={props.setSkipCount}
        onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <FilterList
              changeHeaderFilter={changeHeaderFilter1}
              filterListByIsSingle={filterListByIsSingle}
              role={role}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      />
      <ConfirmModal
        open={openRemoveAlert}
        setOpen={setOpenRemoveAlert}
        setOpenRemoveAlert={setOpenRemoveAlert}
        confimClick={confimRemoveClick}
        message={
          "Are You Sure you want to delete?"
        }
        modalHeader="Alert Message"
      />
    </>
  );
};

export default DataTable;