import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import Header from "./component/header";
import AddForm from "./component/addForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import DataTable from "./component/data-table";
import axios from "axios";
import { Card } from "reactstrap";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const DeductionType = (props) => {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });
  const [tableList, setTableList] = useState([]);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}deductionType`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <Header
        {...props}
        detail={detail}
        getData={getData}
        setDetail={setDetail}
      />
      </div>

      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full page-content` }>
          <Card className="mt-3 mb-3">
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Deduction Type Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0 page-body-wrapper">
                <AddForm detail={detail} setDetail={setDetail} />
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card>
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Deduction Types List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 page-body-wrapper">
                <DataTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  filterBy={detail}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </div>
      </div>
      </Container>
    </>
  );
};

export default DeductionType;
