import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableComponent from "./tableComponent";
import FilterList from "./filterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../../app/utility/ConfirmModal";
import axios from "axios";
import { Row } from "antd";
import { toast } from "react-toastify";
import { formatMoney } from "../../../../app/utility/helper";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    stateId: null,
    disabilityInsurance: '',
    stateIncomeTax: '',
    localTax: '',
    familyLeaveInsurance: '',
    stateIndustrialInsurance: '',
    numberOfQualifyingChildrenUnderAge17: '',
    numberOfOtherDependents: '',
    medicareTax: '',
    socialSecurityTax: ''
  });

  const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  const [defaultRemoveData, setDefaultRemoveData] = useState(null);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.code) {
        dataList = dataList.filter((data) =>
          data.code?.toLowerCase().includes(
            filterBy.code.toLocaleLowerCase()
          )
        );
      }

      if (filterBy?.name) {
        dataList = dataList.filter((data) =>
          data.name.toLowerCase().includes(
            filterBy.name.toLocaleLowerCase()
          )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const changeHeaderFilter1 = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
  };

  const confimRemoveClick = async () => {
    axios.delete(`${process.env.REACT_APP_WEB_API_URL}StateTax/Delete?id=${defaultRemoveData.id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenRemoveAlert(false);
          toast.success('Record Deleted');
          props.getData();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      oldId: data.id,
      stateId: data?.code,
      disabilityInsurance: data?.disabilityInsurance,
      stateIncomeTax: data?.stateIncomeTax,
      localTax: data?.localTax,
      familyLeaveInsurance: data?.familyLeaveInsurance,
      stateIndustrialInsurance: data?.stateIndustrialInsurance,
      numberOfQualifyingChildrenUnderAge17: data?.numberOfQualifyingChildrenUnderAge17,
      numberOfOtherDependents: data?.numberOfOtherDependents,
      medicareTax: data?.medicareTax,
      socialSecurityTax: data?.socialSecurityTax
    };

    props.setDetail(info);
  };

  const columns = [
    {
      id: "id",
      name: "id",
    },
    {
      id: "code",
      name: "Code",
    },
    {
      id: "disabilityInsurance",
      name: "Disability Insurance",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.disabilityInsurance ? tableMeta.disabilityInsurance : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "stateIncomeTax",
      name: "State Income Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.stateIncomeTax ? tableMeta.stateIncomeTax : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "localTax",
      name: "Local Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.localTax ? tableMeta.localTax : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "familyLeaveInsurance",
      name: "Family Leave Insurance",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.familyLeaveInsurance ? tableMeta.familyLeaveInsurance : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "stateIndustrialInsurance",
      name: "State Industrial Insurance",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.stateIndustrialInsurance ? tableMeta.stateIndustrialInsurance : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "numberOfQualifyingChildrenUnderAge17",
      name: "No. Of Qualifying Children Under Age 17",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.numberOfQualifyingChildrenUnderAge17 ? tableMeta.numberOfQualifyingChildrenUnderAge17 : 0;
          return `${formatMoney(temp)}`;
        },
      },
    },
    {
      id: "numberOfOtherDependents",
      name: "No. Of Other Dependents",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.numberOfOtherDependents ? tableMeta.numberOfOtherDependents : 0;
          return `${formatMoney(temp)}`;
        },
      },
    },
    {
      id: "medicareTax",
      name: "Medicare Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.medicareTax ? tableMeta.medicareTax : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "socialSecurityTax",
      name: "Social Security Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.socialSecurityTax ? tableMeta.socialSecurityTax : null;
          return `${temp ? `${parseFloat(Number(temp)).toFixed(2)}%` : '-'}`;
        },
      },
    },
    {
      id: "Action",
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row>
            <FontAwesomeIcon
              icon={faTrash}
              className="sub-header trashIcon"
              onClick={() => {
                setDefaultRemoveData(tableMeta);
                setOpenRemoveAlert(true);
              }}
            />
          </Row>
        },
      },
    },
  ];

  return (
    <>
      <TableComponent
        columns={columns}
        filteredList={filteredList}
        totalCount={props.totalCount}
        setSkipCount={props.setSkipCount}
        onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <FilterList
              changeHeaderFilter={changeHeaderFilter1}
              role={role}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      />
      <ConfirmModal
        open={openRemoveAlert}
        setOpen={setOpenRemoveAlert}
        setOpenRemoveAlert={setOpenRemoveAlert}
        confimClick={confimRemoveClick}
        message={
          "Are You Sure you want to delete?"
        }
        modalHeader="Alert Message"
      />
    </>
  );
};

export default DataTable;