import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import _ from 'lodash';

export const getOptionsAsync = (query, flatProps, value) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            if (!!query) {
                let data = flatProps.filter((option) => (option?.label || '').toLowerCase().includes(query.toLowerCase()));
                resolve(value ? flatProps : data);
            } else {
                resolve(flatProps.slice(0, 500));
            }
        }, 1000);
    });
};

const CustomAutocomplete = ({ value, name, options, errorMessage, placeholder, isLoading, onChange, isCreatable, label, ...props }) => {
    const filter = createFilterOptions();
    const [searchQuery, setSearchQuery] = useState("");
    const [optionsArray, setOptionsArray] = useState([]);
    const [flatProps, setFlatProps] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const getOptionsDelayed = useCallback(
        _.debounce(
            (query, callback) => {
                setOptionsArray([]);
                getOptionsAsync(query, flatProps, value).then(callback);
            },
            200),
        [flatProps, value]
    );

    useEffect(() => {
        !flatProps.length && setFlatProps((options || []).filter(option => (option[props.keyName] || option[props.keyName] == 0) && option[props.labelName]).map((option) => {
            return {
                'label': _.toString(option[props.labelName]),
                'value': option[props.keyName]
            }
        }));
    }, [options]);

    useEffect(() => {
        !optionsArray.length && setOptionsArray(flatProps.slice(0, 500));
    }, [flatProps])

    useEffect(() => {
        if (value != "" || _.isBoolean(value) || _.isNumber(value))
            setInputValue(flatProps.find(elm => ((elm.value == value) || elm.value == (_.trim(_.toString(value)))))?.label);
        else
            setInputValue('');
        setOptionsArray(flatProps.slice(0, 500));
    }, [value, flatProps]);


    useEffect(() => {
        if (!!searchQuery) {
            setIsSearchLoading(true);
            getOptionsDelayed(searchQuery, (options) => {
                setOptionsArray(options)
                setIsSearchLoading(false);
            });
        } else {
            setOptionsArray(flatProps.slice(0, 500));
            setIsSearchLoading(false);
        }
    }, [searchQuery, getOptionsDelayed]);

    const onInputChange = (event, value) => {
        setIsSearchLoading(true);
        setInputValue(value);
        setSearchQuery(value);
    };

    const handleChange = (evt, selectedOption) => {
        selectedOption?.isNew
            ? props.handleCreate(selectedOption)
            : onChange(selectedOption?.value);

        setInputValue(flatProps.find(elm => elm.value == _.trim(_.toString(selectedOption?.value)))?.label);
    }

    const getOptionLabel = (option) => option?.label;

    return <FormControl
        fullWidth
        size="small"
        margin="dense"
        error={!!errorMessage}
    >
        <Autocomplete
            key={name}
            loading={isLoading || isSearchLoading}
            loadingText="Loading..."
            id={name}
            // value={flatProps.find(elm => elm.value == _.trim(_.toString(value)))?.label}
            onInputChange={onInputChange}
            getOptionLabel={getOptionLabel}
            options={optionsArray}
            onChange={handleChange}
            renderInput={(params) => <TextField
                label={label}
                {...params}
                value={value}
                placeholder={placeholder}
                error={!!errorMessage}
                inputProps={{
                    ...params.inputProps,
                    value: (value || inputValue) ? inputValue : ''
                }}
            />}
            size="small"
            error={!!errorMessage}
            {...props}
        />
        <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
};

export const CustomAutocompleteWithController = ({ name, control, options, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
                return <CustomAutocomplete onChange={onChange} onBlur={onBlur} value={value} options={options} {...props} />
            }}
        />
    );
};

export default CustomAutocomplete;