import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterAllowedColumn } from "../../_helper/helper";

import {
  Add,
  DeleteForeverRounded,
  ExpandMore,
  RemoveCircleOutline,
  Save,
  SearchOffTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import { Typography } from "@mui/material";
import { Card, CardBody, Button } from "reactstrap";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  saveAdvanceSearch,
  advanceSearch,
  getAdvanceSearchById,
  loadColumns,
  findAsyouType,
  getAllAdvanceSearch,
} from "../../app/store/reducers/slices/advanceSearchSlice";
import {
  SaveQuery,
  SaveLocation,
} from "../../app/store/reducers/slices/CommonSlice";

import ModalCustom from "../../Components/employee/Modal";
import SaveNewQuery from "./SaveNewQuery";
import { userGroupStore } from "../../Components/AdvanceSearch/slices/querySlice";
import { toast } from "react-toastify";
import { useAuth } from "oidc-react";
import HistoryDataTable from "../HistoryDataTable";
import { sortingFunc } from "../CommonFunction";
import { faEye, faTrash, faSearch } from "@fortawesome/pro-duotone-svg-icons";
import HistoryColumns from "./HistoryColumns";
import { Btn } from "../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTimeline } from "@fortawesome/pro-duotone-svg-icons";
import { faSave } from "@fortawesome/pro-duotone-svg-icons";

function AdvanceSearch({
  gridId = "",
  expanded,
  columnList,
  searchParams = [],
  tableName,
  columnRefernces = [],
  pageNumber,
  pageSize,
  additionalWhere,
  sortData,
  location,
  isDashboard,
  deleteData,
  chooseFilterMethod = "normalSearch",
  processMessage,
}) {
  const dispatch = useDispatch();
  const {
    filter: storeFilter,
    dataState: storeDataState,
    sort: storeSort,
    skip: storeSkip,
    pageSize: storePageSize,
    additionalWhere: storeAdditionalWhere,
    savedQuery,
    savedLocation,
  } = useSelector((state) => {
    return state.CommonSlice;
  });

  const {
    columns,
    lodingSaveType,
    findAsyouTypeResults,
    loadingFindAsYouType,
    searchResults,
    saveResults,
    error,

    userSavedQueries,
    getAdavanceSearchType,
    getAdavanceSearch,
  } = useSelector((state) => state.advanceSearchSlice);
  const URL = useLocation();
  const [expandedSearchTools, setExpandedSearchTools] = useState(expanded);
  const [isSearched, setIsSearched] = useState(false);
  const [columnsData, setColumnsData] = React.useState([
    { id: "original", columns },
  ]);
  const { userData } = useAuth();
  const [SortData, setSortData] = React.useState([
    { field: " queryName", dir: "desc" },
  ]);
  const [historyData, setHistoryData] = React.useState([]);
  const [currentFindAsType, setCurrentFindAsType] = useState("");
  const [paramterChange, setParameterChange] = useState(null);
  const [sortedColumns, setSortedColumns] = useState([]);

  const [open, setOpen] = useState({
    saveOpen: false,
    selectOpen: false,
  });
  console.log("findAsyouTypeResults", findAsyouTypeResults);
  const actions = useMemo(() => {
    return [
      {
        title: "Show Details",
        action: async (props) => {
          console.log("props", props);
          return await dispatch(
            getAdvanceSearchById({
              location: "getAdvanceSearchDetails",
              params: {
                qID: props?.dataItem?.id,
              },
            })
          );
        },
        icon: faEye,
        className: "font-primary",
        style: { cursor: "pointer" },
      },
      {
        title: "delete",
        icon: faTrash,
        className: "font-primary",
        style: { cursor: "pointer" },
        action: async (props) => {
          await deleteQuery(props?.dataItem);
        },
      },
    ];
  });
  useEffect(() => {
    if (getAdavanceSearchType === "GET_ADVANCE_SEARCH_BYID_SUCCESS") {
      let para = [];
      let localData = [...columnsData];
      getAdavanceSearch?.advanceSearchParamters?.forEach((item, index) => {
        const elmID =
          new Date().getTime() * (Math.random() * (9999 - 1000) + 1000);
        para.push({
          columnName: item.columnName,
          paramValue: item.paramValue,
          operator: item.operatorName,
          id: elmID,
          paramType: item.paramType,
        });
        const clm = localData.find((p) => p.id === elmID);

        if (clm) {
          localData.push({ id: elmID, columns: [...columns] });
        } else {
          localData.push({ id: elmID, columns: [...columns] });
        }
      });
      setColumnsData(localData);
      setSearchParameter({
        ...searchParameter,
        params: para,
        qID: getAdavanceSearch?.advanceSearchQuery.id,
      });
      setOpen({ ...open, selectOpen: !open });
      toast.success("Fetched");
    }
    if (getAdavanceSearchType === "GET_ADVANCE_SEARCH_BYID_FAIL") {
      setOpen({ ...open, selectOpen: !open });
      toast.error(error.message);
      // setState(false)
    }
  }, [getAdavanceSearchType]);
  const deleteQuery = async (item) => {
    const queryId = item.id;
    await axios
      .post(`/query/save`, {
        params: { id: queryId },
      })
      .then((response) => {
        toast.success(response.status === 200 && "Record Deleted.");
      })
      .catch((error) => {
        toast.error("Server Not Responding", error.message);
      });
  };
  const handleSortChange = (sort) => {
    console.log("sort", sort);
    setSortData(sort);
  };
  useEffect(() => {
    dispatch(loadColumns(tableName));
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (chooseFilterMethod === "advanceSearch") {
        await dispatch(
          getAllAdvanceSearch({
            tableName: "",
            location: "findSavedSearch",
            permissionType: "",
            permissionValue: "",
            queryName: "",
          })
        );
      }
    };

    fetchData();
  }, [chooseFilterMethod]);

  useEffect(() => {
    if (userSavedQueries?.length !== 0) {
      setHistoryData(userSavedQueries);
    }
  }, [userSavedQueries]);

  useEffect(() => {
    if (historyData?.length > 0) {
      sortingFunc(SortData, historyData, setHistoryData);
    }
  }, [SortData]);

  useEffect(() => {
    setColumnsData([{ id: "original", columns: columns?.slice() }]);
  }, [columns]);
  useEffect(() => {
    setSearchParameter({
      ...searchParameter,
      page: pageNumber,
      pageSize: pageSize,
      additionalWhere: additionalWhere,
      orderBy: sortData[0].field,
      sortBy: sortData[0].dir,
      orderByMultiple: "",
      limit: pageSize,
      // sortData: sortData.reduce(
      //   (accumulator, item) =>
      //     (accumulator === "" ? " " : accumulator + ", ") +
      //     item.field +
      //     " " +
      //     item.dir,
      //   ""
      // ),
    });
    setParameterChange(new Date());
  }, [pageSize, pageNumber, additionalWhere, sortData]);

  useEffect(() => {
    const sortedData = filterAllowedColumn(userData, HistoryColumns, "id");
    setSortedColumns(sortedData);
  }, [SortData]);

  useEffect(() => {
    if (gridId) {
      let pageSize = storePageSize[gridId];
      let skip = storeSkip[gridId];
      let additionalWhere = storeAdditionalWhere[gridId];
      let sort = storeSort[gridId];
      const storeParams = {};
      if (pageSize > 0) {
        storeParams["pageNumber"] = skip / pageSize + 1;
        storeParams["pageSize"] = pageSize;
      }
      if (additionalWhere) {
        storeParams["additionalWhere"] = additionalWhere;
      }
      if (sort) {
        storeParams["sortData"] = sort.reduce(
          (accumulator, item) =>
            (accumulator === "" ? " " : accumulator + ", ") +
            item.field +
            " " +
            item.dir,
          ""
        );
      }
      if (Object.keys(storeParams).length > 0) {
        setSearchParameter({
          ...searchParameter,
          ...storeParams,
        });
        setParameterChange(new Date());
      }
    }
  }, [gridId, storePageSize, storeSkip, storeAdditionalWhere, storeSort]);

  useEffect(() => {
    if (paramterChange != null) {
      handleSearch();
    }
  }, [paramterChange]);
  useEffect(() => {
    if (deleteData != null || processMessage !== undefined) {
      handleSearch();
    }
  }, [deleteData, processMessage]);
  const filterData = (filter) => {
    const data = columns?.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    const elmID = event.target.element.id;
    const localData = [...columnsData];

    const clm = columnsData.find((p) => p.id === elmID);
    if (clm) {
      localData[localData.indexOf(clm)].columns = filterData(event.filter);
    } else {
      localData.push({ id: elmID, columns: filterData(event.filter) });
    }
    setColumnsData(localData);
  };

  const [newParamater, setNewParamer] = useState({
    id: 0,
    columnName: "",
    operator: "",
    paramValue: "",
    paramType: "",
    logicOperator: null,
  });

  const [searchParameter, setSearchParameter] = useState({
    tableName: tableName,
    params: searchParams,
    columns: columnList,
    page: pageNumber,
    pageSize: pageSize,
    orderBy: sortData[0].field,
    sortBy: sortData[0].dir,
    orderByMultiple: "",
    limit: pageSize,
    sortData: sortData.reduce(
      (accumulator, item) =>
        (accumulator === "" ? " " : accumulator + ", ") +
        item.field +
        " " +
        item.dir,
      ""
    ),
    additionalWhere: additionalWhere,
    // qID: 0,
  });

  const [saveParameter, setSaveParameter] = useState({
    role: "",
    queryName: "",
  });

  useEffect(() => {
    if (lodingSaveType === "SAVE_ADVANCE_SEARCH_SUCCESS") {
      setOpen({ ...open, saveOpen: !open.saveOpen });
      toast.success(saveResults?.message);
    } else if (lodingSaveType === "SAVE_ADVANCE_SEARCH_FAIL") {
      setOpen({ ...open, saveOpen: !open.saveOpen });
      toast.success(error?.message);
    }
  }, [lodingSaveType]);

  const userGroup = useSelector((state) => state.querySlice.groups);

  const clearNewParamter = () => {
    setNewParamer({
      id: 0,
      columnName: "",
      operator: "",
      paramValue: "",
      paramType: "",
      logicOperator: null,
    });
  };
  const findDataType = (columnName) => {
    const c = columns.find((p) => p.column_name === columnName);
    if (c) {
      return c.data_type === "char" ||
        c.data_type === "varchar" ||
        c.data_type === "nvarchar"
        ? "VARCHAR(MAX)"
        : c.data_type;
    } else {
      return "";
    }
  };
  const getOperatorList = (dataType) => {
    if (dataType === "VARCHAR(MAX)" || dataType === "datetime") {
      return ["=", "LIKE"];
    } else {
      return ["=", ">", "<", ">=", "<=", "LIKE"];
    }
  };
  const handleNewParameter = (event) => {
    let p = { ...searchParameter };

    const elmID = new Date().getTime();
    p.params.push({
      ...newParamater,
      paramType: findDataType(newParamater.columnName),
      id: elmID,
    });
    const localData = [...columnsData];
    const clm = localData.find((p) => p.id === elmID);
    if (clm) {
      localData[localData.indexOf(clm)].columns = [...columns];
    } else {
      localData.push({ id: elmID, columns: [...columns] });
    }

    clearNewParamter();
    setColumnsData(localData);
    setTimeout(() => {
      setSearchParameter(p);
    }, 500);
  };
  useEffect(() => {
    if (processMessage !== undefined || processMessage !== null) {
      toast.success(processMessage, { style: { color: "#FFF" } });
    }
  }, [processMessage]);
  const handleExistingParameter = (param, key, value) => {
    let p = [...searchParameter.params];
    p[p.indexOf(param)][key] = value;
    p[p.indexOf(param)].paramType = findDataType(
      p[p.indexOf(param)].columnName
    );
    setSearchParameter({ ...searchParameter, params: p });
  };
  function checkObjectProperties(obj) {
    return (
      obj.hasOwnProperty("columnName") &&
      obj.hasOwnProperty("paramValue") &&
      obj.hasOwnProperty("operator") &&
      obj.columnName !== "" &&
      obj.paramValue !== "" &&
      obj.operator !== ""
    );
  }

  const handleSearch = (isTrue) => {
    if (isTrue && checkObjectProperties(newParamater)) {
      setIsSearched(isTrue);
      handleNewParameter();
    }
    dispatch(
      advanceSearch({
        ...searchParameter,
        params: searchParameter.params,
      })
    );
  };

  useEffect(() => {
    if (searchParameter.params.length > 0 && isSearched === true) {
      dispatch(SaveQuery(searchParameter.params));
      dispatch(SaveLocation(URL.pathname));
    }
  }, [searchParameter.params.length > 0]);

  useEffect(() => {
    if (savedLocation === URL.pathname && savedQuery !== null) {
      setSearchParameter({ ...searchParameter, params: savedQuery });
      setExpandedSearchTools(true);
    }
  }, [savedLocation, savedQuery]);

  const renderAutoComplete = (param, identifier, changeCallback) => {
    console.log("param", param);
    return findDataType(param.columnName) === "VARCHAR(MAX)" &&
      param.operator !== "LIKE" &&
      param.operator !== "" ? (
      <AutoComplete
        loading={loadingFindAsYouType && currentFindAsType === identifier}
        data={findAsyouTypeResults}
        textField="keyField"
        ref={param.autocomplete}
        value={param.paramValue}
        label="Value"
        size={"medium"}
        fillMode={"outline"}
        className="bg-white"
        style={{
          width: "400px",
          height: "35px",
        }}
        onChange={(e) => {
          setCurrentFindAsType(identifier);
          if (columnRefernces && columnRefernces.length > 0) {
            const item = columnRefernces.find(
              (d) => d.columnName === param.columnName
            );
            if (item)
              dispatch(
                findAsyouType({
                  table: item.table,
                  keyField: item.keyField,
                  valueField: item.valueField,
                  search: e.target.value,
                })
              );
            else
              dispatch(
                findAsyouType({
                  table: tableName,
                  keyField: param.columnName,
                  valueField: param.columnName,
                  search: e.target.value,
                })
              );
          } else {
            dispatch(
              findAsyouType({
                table: tableName,
                keyField: param.columnName,
                valueField: param.columnName,
                search: e.target.value,
              })
            );
          }
          changeCallback(
            { ...newParamater, paramValue: e.target.value.trim() },
            e.target.value
          );
        }}
      />
    ) : (
      <FloatingLabel
        className="mx-2"
        editorId={"textbox-id"}
        label={"value"}
        editorValue={param.paramValue}
      >
        <TextBox
          placeholder="Enter your value"
          style={{
            width: 400,
            marginTop: "0px",
            height: "35px",
          }}
          fillMode={"outline"}
          className="bg-white"
          value={param.paramValue}
          onChange={(e) => {
            changeCallback(
              { ...newParamater, paramValue: e.target.value.trim() },
              e.target.value
            );
          }}
        />
      </FloatingLabel>
    );
  };

  useEffect(() => {
    dispatch(userGroupStore());
  }, []);

  const onSubmit = (e) => {
    // const saveQueryApi = (data) => {
    // const payload = {
    //   queryName: saveParameter.queryName,
    //   permissionType: saveParameter.role,
    //   permissionValue: saveParameter.role,
    //   // state.userGroupID.length === 0 ? null : state.userGroupID,
    //   userId: 10000000,
    //   tableName: "EmployeeMaster",
    //   params: searchParameter.searchParams,
    //   columns: "*",
    //   limit: 10,
    //   page: 1,
    //   orderBy: "EmployeeName_FML",
    //   sortBy: "DESC",
    // };
    const { params, ...searchParamsWithoutParams } = searchParameter;
    dispatch(
      saveAdvanceSearch({
        ...searchParamsWithoutParams,
        searchParams: JSON.stringify(searchParameter.params),
        queryName: saveParameter.queryName,
        permissionType: saveParameter.role,
        permissionValue: saveParameter.role,
        qID: 0,
        location: "saveSearch",
        sortBy: "DESC",
        orderBy: "ID",
      })
    );

    // axios
    //   .post(
    //     // `query/save`,
    //     `${process.env.REACT_APP_API_URL}/advancesearch/v2/save`,
    //     data,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "x-access-token": localStorage.getItem("jwt_access_token"),
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     toast.success("Query Saved");
    //     setOpen({ ...open, saveOpen: !open.saveOpen });
    //   })
    //   .catch((error) => {
    //     toast.error("something went wrong");
    //   });
    // axios
    //   }
  };
  console.log({chooseFilterMethod})
  return (
    <Fragment>
      <Accordion
        style={{ marginBottom: "9px" }}
        expanded={expandedSearchTools}
        className={
          chooseFilterMethod === "normalSearch" // Removed isDashboard === true || to show advance filter in Dashboard
            ? // isDashboard === true || chooseFilterMethod === "normalSearch"
            "hideFilter"
            : ""
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="bg-light"
          onClick={() => setExpandedSearchTools(!expandedSearchTools)}
        >
          <Typography variant="h6" style={{ color: "white" }}>
            Search Tools
            {expandedSearchTools && (
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: (e) => {
                    e.stopPropagation();
                    setOpen({ ...open, selectOpen: !open.selectOpen });
                  },
                  style: { marginLeft: "20px" },
                }}
              >
                <FontAwesomeIcon icon={faListTimeline} /> Load Saved Query
              </Btn>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              <Stack
                direction={"column"}
                alignSelf="center"
                justifyContent={"center"}
              >
                {searchParameter.params.map((param) => {
                  return (
                    <Stack
                      direction={"row"}
                      style={{ width: "100%", justifyContent: "left" }}
                      marginX={2}
                      spacing={2}
                    >
                      <DropDownList
                        id={param.id}
                        fillMode={"outline"}
                        className="bg-white m-x-2"
                        value={
                          columnsData.length > 0 &&
                          columnsData
                            .find((d) => d.id === "original")
                            ?.columns?.find(
                              (d) =>
                                d.column_name
                                  .replace(/_/g, "")
                                  .toLowerCase() ===
                                param.columnName.replace(/_/g, "").toLowerCase()
                            )
                        }
                        label="Field"
                        style={{ width: 300, height: "35px" }}
                        dataItemKey="column_name"
                        filterable={true}
                        textField="column_title"
                        data={
                          columnsData.find((d) => d.id === param.id)?.columns
                        }
                        onFilterChange={filterChange}
                        onChange={(e) => {
                          handleExistingParameter(
                            param,
                            "columnName",
                            e.target.value.column_name
                          );
                        }}
                      ></DropDownList>

                      <DropDownList
                        className="bg-white m-x-2"
                        value={param.operator}
                        label="Operator"
                        style={{ width: 120, height: "35px" }}
                        fillMode={"outline"}
                        data={getOperatorList(findDataType(param.columnName))}
                        onChange={(e) => {
                          handleExistingParameter(
                            param,
                            "operator",
                            e.target.value
                          );
                        }}
                      ></DropDownList>
                      {renderAutoComplete(
                        param,
                        param.columnName,
                        (p, value) => {
                          handleExistingParameter(param, "paramValue", value);
                        }
                      )}

                      <Btn
                        attrBtn={{
                          color: "primary",
                          size: "sm",
                          style: {
                            marginTop: "20px",
                            marginLeft: "3px",
                            height: "30px",
                            width: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          onClick: () => {
                            const searchParams = searchParameter.params;
                            /// const idx = params.indexOf(param);
                            setSearchParameter({
                              ...searchParameter,
                              params: searchParams.filter((d) => d !== param),
                            });
                          },
                        }}
                      >
                        <DeleteForeverRounded style={{ fontSize: "16px" }} />
                      </Btn>
                    </Stack>
                  );
                })}
              </Stack>

              {console.log(
                "Data1010",
                columnsData.length > 0
                  ? columnsData
                    .find((d) => d.id === `original`)
                    ?.columns?.find(
                      (d) => d.column_name === newParamater.columnName
                    )
                  : null
              )}

              <Stack
                direction={"row"}
                style={{ width: "100%", justifyContent: "left" }}
                marginX={2}
                spacing={2}
              >
                <DropDownList
                  id={`original`}
                  fillMode={"outline"}
                  className="bg-white m-x-2"
                  value={
                    columnsData.length > 0 && newParamater.columnName !== ""
                      ? columnsData
                        .find((d) => d.id === `original`)
                        ?.columns?.find(
                          (d) => d.column_name === newParamater.columnName
                        )
                      : null
                  }
                  label="Field"
                  style={{ width: 300, height: "35px" }}
                  dataItemKey="column_name"
                  textField="column_title"
                  data={
                    columnsData.length > 0
                      ? columnsData.find((d) => d.id === `original`)?.columns
                      : []
                  }
                  filterable={true}
                  onFilterChange={filterChange}
                  onChange={(e) => {
                    setNewParamer({
                      ...newParamater,
                      columnName: e.target.value.column_name,
                    });
                  }}
                ></DropDownList>

                <DropDownList
                  className="bg-white m-x-2"
                  fillMode={"outline"}
                  value={newParamater.operator}
                  label="Operator"
                  style={{ width: 120, height: "35px" }}
                  data={getOperatorList(findDataType(newParamater.columnName))}
                  onChange={(e) => {
                    setNewParamer({
                      ...newParamater,
                      operator: e.target.value,
                    });
                  }}
                ></DropDownList>
                {renderAutoComplete(newParamater, "new", (p) => {
                  setNewParamer(p);
                })}
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      handleNewParameter();
                    },
                    size: "sm",
                    style: {
                      marginTop: "20px",
                      marginLeft: "3px",
                      height: "30px",
                      width: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Add style={{ fontSize: "16px" }} />
                </Btn>
              </Stack>
            </CardBody>
            <CardActionArea style={{ marginLeft: "30px" }}>
              <Stack
                className="my-2"
                direction={"row"}
                alignItems={"flex-end"}
                // justifyContent="center"
                justifyContent="left"
                spacing={2}
                marginX={2}
              >
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      setSearchParameter({
                        ...searchParameter,
                        params: [],
                      });
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> Clear All
                </Btn>
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      handleSearch(true);
                      //TODO - SEARCH CALL
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} /> Search Now
                </Btn>

                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      setOpen({ ...open, saveOpen: !open.saveOpen });
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faSave} /> Save Search
                </Btn>
              </Stack>
            </CardActionArea>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/* save query			 */}

      {open.saveOpen && (
        <ModalCustom open={open.saveOpen} fullWidth>
          <SaveNewQuery
            onSelectUserGroup={(e) => {
              setSaveParameter({ ...saveParameter, role: e });
            }}
            userGroup={userGroup}
            //   onSelectUserGroup={onSelectUserGroup}
            //   onRemoveUserGroup={onRemoveUserGroup}
            //   onSubmit={onFinished}
            //   values={values}
            onTextChange={(e) => {
              setSaveParameter({
                ...saveParameter,
                queryName: e.target.value,
              });
            }}
            selected={saveParameter.role}
            handleClose={() => {
              setOpen({ ...open, saveOpen: !open.saveOpen });
            }}
            onSubmit={onSubmit}
          />
        </ModalCustom>
      )}

      <HistoryDataTable
        openHistory={open.selectOpen}
        setOpenHistory={setOpen}
        data={userSavedQueries || []}
        sortData={SortData}
        selectedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
        onSortChange={handleSortChange}
        actions={actions}
        title={"My Queries"}
      />
    </Fragment>
  );
}
export default AdvanceSearch;
