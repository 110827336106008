import React, { Fragment, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";

import { CardBody, CardHeader, Label } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { Card } from "react-bootstrap";
import { Box } from "@mui/material";
import moment from "moment";
import { getUserRole } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
const CardInfo = (title, value) => {
  return (
    <Card className="income-card" style={{ marginBottom: "20px" }}>
      <CardBody className="text-center" style={{ padding: "20px" }}>
        {/* <div className="round-box">{iconClass}</div> */}
        {/* <P>{title}</P> */}
        <h4>{title}</h4>
        <H5>{value}</H5>
        <div>
          {/* {percentage >= 0 ? (
        <span className="upclass">
          {" "}
          +{format2Decimal(difference)} ({format2Decimal(percentage)}%){" "}
          <i className=" upclass fa fa-arrow-up me-2"></i>
          {subtext}{" "}
        </span>
      ) : (
        <span className="downclass">
          {" "}
          {format2Decimal(difference)} ({format2Decimal(percentage)}%){" "}
          <i className=" downclass fa fa-arrow-down me-2"></i>
          {subtext}
        </span>
      )} */}
        </div>
        {/* <div className="parrten">{iconClass}</div> */}
      </CardBody>
    </Card>
  );
};
const EmployeeAccountDetail = ({ accountBalanceList }) => {
  const { userData } = useAuth();
  const tableCellStyle = {
    padding: "5px", // Adjust the cell padding as needed
  };

  const tableRowStyle = {
    marginBottom: "10px", // Adjust the row margin as needed
  };
  let role = getUserRole(userData);
  return (
    <div className=" w-full">
      {/* <Grid container spacing={2}>
  <Grid item xs={6} md={8}>
    <div>xs=6 md=8</div>
  </Grid>
  <Grid div xs={6} md={4}>
    <div>xs=6 md=4</div>
  </Grid>
  <Grid div xs={6} md={4}>
    <div>xs=6 md=4</div>
  </Grid>
  <Grid div xs={6} md={8}>
    <div>xs=6 md=8</div>
  </Grid>
</Grid> */}
      {accountBalanceList?.employeeAuthorizedPayTimeCodesList?.length ? (
        <Card>
          <CardHeader>
            <h5> PayCode Assigned</h5>
          </CardHeader>
          <CardBody>
      <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0 10px" ,whiteSpace: 'nowrap'}}>
        <thead>
          <tr>
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Pay Type</b>
              </Typography>
            </th>
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Description</b>
              </Typography>
            </th>
            {!["user"].includes(role) && (
              <th style={tableCellStyle} className="empPayTitle bg-light">
                <Typography variant="subtitle2" className="text-14">
                  <b>Account No.</b>
                </Typography>
              </th>
            )}
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Effect/Expire Date</b>
              </Typography>
            </th>
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Authorized Hours</b>
              </Typography>
            </th>
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Reserved Hours</b>
              </Typography>
            </th>
            <th style={tableCellStyle} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14">
                <b>Used Hours</b>
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {accountBalanceList?.employeeAuthorizedPayTimeCodesList?.length &&
            accountBalanceList?.employeeAuthorizedPayTimeCodesList?.map(
              (i, index) => (
                <tr key={index} style={tableRowStyle}>
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.abberv}
                    </Typography>
                  </td>
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.description}
                    </Typography>
                  </td>
                  {!["user"].includes(role) && (
                    <td style={tableCellStyle}>
                      <Typography variant="subtitle2" className="text-14 text-black">
                        {i.jobOrderNo}
                      </Typography>
                    </td>
                  )}
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.effectDate
                        ? `${moment(i.effectDate).format("MM/DD/YYYY")} - ${moment(
                            i.expireDate
                          ).format("MM/DD/YYYY")}`
                        : "-"}
                    </Typography>
                  </td>
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.totalAuthorizedHours ?? "-"}
                    </Typography>
                  </td>
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.totalReservedHours ?? "-"}
                    </Typography>
                  </td>
                  <td style={tableCellStyle}>
                    <Typography variant="subtitle2" className="text-14 text-black">
                      {i.totalUsedHours ?? "-"}
                    </Typography>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </CardBody>
        </Card>
      ) : null}

      {/* {accountBalanceList?.employeeTagList?.length ?
<Card>
  <CardHeader>
   <h5> TagList Assigned</h5>
  </CardHeader>
  <CardBody>
      <Box style={{ width: "100%",  }}>
        <Grid container spacing={2}>
          <>
            <Grid item xs={4} sm={2} lg={2} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14  ">
                <b>Tag Code</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3} lg={3} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14  ">
                <b>Description</b>
              </Typography>
            </Grid>
            <Grid item xs={5} sm={3} lg={3} className="empPayTitle bg-light">
              <Typography variant="subtitle2" className="text-14  ">
                <b>Valid From</b>
              </Typography>
            </Grid>

            <></>
            <Grid
              item
              lg={4}
              sm={4}
              component={Box}
              display={{ xs: "none", sm: "block", lg: "block" }}
              className="empPayTitle bg-light"
            >
              <Typography variant="subtitle2" className="text-14 bg-light ">
                <b>Valid To</b>
              </Typography>
            </Grid>
            
           
          </>
          
          {accountBalanceList?.employeeTagList?.length  && accountBalanceList?.employeeTagList?.map((i) => {
            return (
              <>
               <Grid item xs={4} sm={2} lg={2}>
                  <Typography
                    variant="subtitle2"
                    className="text-14 text-black  "
                  >
                    {" "}
                    {i.tagCode}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={3} lg={3}>
                  <Typography
                    variant="subtitle2"
                    className="text-14 text-black  "
                  >
                    {" "}
                    {i.remarks ? i.remarks : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={3} lg={3}>
                  <Typography
                    variant="subtitle2"
                    className="text-14 text-black  "
                  >
                    {" "}
                    {i.validFrom ? moment(i.validFrom).format("YYYY-MM-DD") : "-"}
                  </Typography>
                </Grid>
                

                <Grid item xs={5} sm={4} lg={4}>
                  <Typography
                    variant="subtitle2"
                    className="text-14  text-black"
                  >
                  
                    {i.validTo ? moment(i.validTo).format("YYYY-MM-DD") : "-"}

                  </Typography>
                </Grid>
               
                 
              </>
            );
          })}
        </Grid>
      </Box>
      </CardBody>
      </Card>
      : null } */}
      <Grid container spacing={1}>
        <Grid item lg={4}>
          {CardInfo("Annual leave balance", "40hours")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("Sick leave balance", "20hours")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("Comp leave balance", "6hours")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("PR Account Balance", "$ 520,200")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("PR Account Projection", "20 week")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("Healthcare Benifits Arrears", "$ 0")}
        </Grid>
        <Grid item lg={4}>
          {CardInfo("Gross YTD", "$ 24,500")}
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeAccountDetail;
