import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useFormikContext } from "formik";

import ViewSavedQueries from "./ViewSavedQueries";
import { savedQueries } from "../../app/store/reducers/slices/querySlice";
import { makeStyles } from "@material-ui/core";

const AddRecordFormStyles = makeStyles((theme) => ({
  MainHeading: {
    color: theme.palette.primary.main,
    marginBottom: "25px",
    marginLeft: "2px",
    fontWeight: "bold",
  },
  Subheading: {
    color: theme.palette.secondary.main,
    marginLeft: "2px",
    marginBottom: "8px",
  },
  ErrorForm: {
    color: "red",
    marginLeft: "2px",
    marginBottom: "8px",
  },
  Divider: {
    margin: "16px 0",
  },
  Button: {
    minWidth: "145px",
    fontSize: "1.4rem",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "90px",
    },
  },
  Icons: {
    fontSize: "2.5rem",
    marginLeft: "10px",
  },
  table: {
    minWidth: 650,
  },
  MainHeadingForTable: {
    color: theme.palette.primary.main,
    marginLeft: "20px",
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  DividerForTable: {
    marginTop: 0,
  },
}));
const SavedQueries = (props) => {
  const { getData, edit = false } = props;
  const data = useSelector((state) => state);
  console.log("data selector", data);

  console.log("datas ", data);
  const classes = AddRecordFormStyles();

  const { access_token } = localStorage.getItem("jwt_access_token");

  const [queriesData, setQueriesData] = useState([]);

  const [sharedQueries, setSharedQueries] = useState([]);

  const status = "admin";

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(savedQueries({ tableName: props.tableName }));
  }, []);
  const userSavedQueries = useSelector(
    (state) => state.querySlice.userSavedQueries
  );
  console.log("userSavedQueries", userSavedQueries);
  // const groupSavedQueries = useSelector(
  //   (state) => state.query.groupSavedQueries
  // );
  return (
    <div className="flex w-full justify-center mt-20 ">
      <Card className="rounded-4 shadow-2xl w-11/12 bg-white px-16 py-8 mb-8">
        <CardContent>
          <Typography
            variant="h4"
            component="h4"
            className={classes.MainHeading}
          >
            My Queries
          </Typography>
          <ViewSavedQueries
            data={userSavedQueries}
            shared={false}
            // sharedQueries={groupSavedQueries}
            edit={edit}
            getData={getData}
          />
          {/* <Typography
            variant="h3"
            component="h3"
            className={classes.MainHeading}
          >
            {status === "admin" ? "Group Queries" : "Shared Queries"}
          </Typography> */}
          {/* <ViewSavedQueries
            data={groupSavedQueries}
            shared={true}
            edit={edit}
            getData={getData}
          /> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default SavedQueries;
