import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCalendar,
  faPeople,
  faUser,
} from "@fortawesome/pro-duotone-svg-icons";

import { useDispatch } from "react-redux";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../../app/utility/helper";
import axios from "axios";

const visibleColumnArray = [
  "SSN",
  "ID",
  "EmployeeName",
  "Nposition_No",
  "DPDV",
  "Military_Status",
  "Hm_Phone",
  "Ml_Wk_Phone",
  "Email",
  "Inactive_Status",
  "Emp_Status",
];

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));
const customStyles = {
  exportButton: {
    display: "none",
  },
  header: {
    rows: [
      {
        columns: [
          {
            formatAs: (column, index) =>
              console.log("column 123", column, index),
          },
        ],
      },
    ],
  },
};

const DataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [CSVPrint, setCSVPrint] = useState(visibleColumnArray);
  const [secondApiData, setSecondApiData] = useState([]);

  console.log("CSVPrint", CSVPrint);
  const columnsName = useSelector(
    (state) => state?.querySlice?.colsPlaceholder
  );

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;
  console.log("employee issues", requestDetail);
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    // if(name.toString().toLowerCase()==="status"){

    //   if(value.toString().toLowerCase().startwith==='in'){
    //     setFilter({ ...filterBy, [name]: false });

    //   }else {
    //     setFilter({ ...filterBy, [name]: true });

    //   }

    // }else{
    setFilter({ ...filterBy, [name]: value });

    // }
  };

  const [visibleColumn, setVisibleColumn] = useState([]);
  const [newArray, setNewArray] = useState(requestDetail);
  console.log("visibleColumn", visibleColumn);

  // useEffect(() => {
  //   let updatedColumns = columnsName?.map((item) => {
  //     const columnIndex = visibleColumnArray?.indexOf(item.column_name);

  //     return {
  //       id: item.column_name,
  //       name: item.column_name,
  //       label: item.column_title,
  //       order: columnIndex === -1 ? 1000 : columnIndex,
  //       sortable: true,

  //       options: {
  //         display: CSVPrint?.some((i) => i == item.column_name),
  //         download: CSVPrint?.some((i) => i === item.column_name),
  //       },
  //     };
  //   });

  //   setTimeout(() => {
  //     updatedColumns.splice(0, 0, {
  //       id: "Actions",
  //       name: "ID",
  //       label: "Actions",
  //       // width: "70px",
  //       sort: "false",
  //       minWidth: "90px",
  //       order: 0,
  //       options: {
  //         download: false,
  //         customBodyRender: (value, tableMeta, updateValue) => {
  //           return (
  //             <>
  //               <Tooltip title="Employee Details" placement="bottom">
  //                 <FontAwesomeIcon
  //                   className="sub-header"
  //                   onClick={() => {
  //                     // return history(`update/${tableMeta.ID}`);
  //                     return history(`update/${value}`);
  //                   }}
  //                   icon={faEye}
  //                   style={{
  //                     cursor: "pointer",
  //                     color: "#1569C7",
  //                     marginRight: "10px",
  //                   }}
  //                 />
  //               </Tooltip>

  //               <Tooltip title="View Time Sheet" placement="bottom">
  //                 <FontAwesomeIcon
  //                   onClick={() => {
  //                     return history(`/payroll-work/${tableMeta.ID}`);
  //                   }}
  //                   className="sub-header"
  //                   icon={faCalendar}
  //                   style={{
  //                     cursor: "pointer",
  //                     color: "#1569C7",
  //                     marginRight: "5px",
  //                   }}
  //                 />
  //               </Tooltip>
  //             </>
  //           );
  //         },
  //       },
  //     });

  //     updatedColumns.sort(function (a, b) {
  //       var keyA = new Date(a.order),
  //         keyB = new Date(b.order);
  //       // Compare the 2 dates
  //       if (keyA < keyB) return -1;
  //       if (keyA > keyB) return 1;
  //       return 0;
  //     });

  //     setVisibleColumn(updatedColumns);
  //   }, 0);
  // }, [columnsName, CSVPrint]);

  const column = [
    {
      id: "Actions",
      name: "EmployeeID",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    return (window.location.href = `${process.env.REACT_APP_REDIRECT_URL}admin/employee/update/${value}`);
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>

              <Tooltip title="View Time Sheet" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(`/payroll-work/${tableMeta.ID}`);
                  }}
                  // className="sub-header"
                  icon={faCalendar}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      id: "EmployeeID",
      name: "EmployeeID",
    },
    {
      id: "EmployeeName",
      name: "EmployeeName",
    },
    {
      id: "DPDV",
      name: "DPDV",
    },
    {
      id: "Emp_Status",
      name: "Emp_Status ",
    },
    {
      id: "PPE",
      name: "PPE",
    },
    {
      id: "WorkingHours",
      name: "WorkingHours",
    },
  ];

  const handleClick = (data) => {
    const datas = data?.rowData;

    console.log("Data 456", data.rowData);
    let totalHR = "0";
    if (datas[2] === "Employees with Zero Hours") {
      totalHR = "0";
    } else if (datas[2] === "Employees with 80 Hours") {
      totalHR = "80+";
    } else if (datas[2] === "Employees with less than 80 hours") {
      totalHR = "80-";
    }

    console.log("MMMXXX");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}employee/filterByHours`,
        {
          TotalHr: totalHR,

          DepartmentID: datas[0],
          PPE: datas[1],
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("second api", response.data);
        setSecondApiData(response.data);
        props.setApiCall(true);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const columnFirstApiResponse = [
    {
      id: "Actions",
      name: "DPDV",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => handleClick(tableMeta)}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "PPE",
      id: "PPE",
    },
    {
      name: "Stat",
      id: "Stat",
    },
    {
      name: "DEPT_NAME",
      id: "DEPT_NAME",
    },
    {
      name: "CNT",
      id: "CNT",
    },
  ];

  useEffect(() => {
    const updatedArray = requestDetail.map((i) => {
      return { ...i, Annual_Salary: formatMoney(i.Annual_Salary) };
    });

    setNewArray(updatedArray);
  }, [requestDetail]);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data && data.EmployeeID
            ? data.EmployeeID.toLowerCase().includes(
                filterBy.EmployeeID?.toLowerCase()
              )
            : data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLowerCase()
          )
        );
      }
      if (filterBy?.SSN) {
        dataList = dataList.filter((data) =>
          data.SSN.toLowerCase().includes(filterBy.SSN.toLowerCase())
        );
      }
      if (filterBy?.Status) {
        dataList = dataList.filter((data) =>
          data.Status.toLowerCase().includes(filterBy.Status.toLowerCase())
        );
      }
      if (filterBy?.Department) {
        dataList = dataList.filter((data) =>
          data.Description
            ? data.Description
            : data.DPDV_DepartmentDivisionMaster.Description.toLowerCase().includes(
                filterBy.Department.toLowerCase()
              )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const DownLoadCsv = (data) => {
    alert("Sasa");
  };

  const handleRowClick = (data) => {
    console.log("ON CLICK dd :::: ", data);
    data = data.data;
    //   props.dispatch(setSelectedId(data.ID));
    let info = {
      id: data.ID,
      code: data.Code,
      description: data.Description,
    };
    props.setDetail(info);
  };

  const columns = [
    {
      id: "Actions",
      name: "ID",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    return history(`update/${value}`);
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>

              <Tooltip title="View Time Sheet" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(`/payroll-work/${tableMeta.ID}`);
                  }}
                  // className="sub-header"
                  icon={faCalendar}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      id: "ID",
      name: "ID",
      label: "ID",

      // type: "",
      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return (
      //       <Typography variant="subtitle2">
      //         {tableMeta.EmployeeID ? tableMeta.EmployeeID : tableMeta.ID}
      //       </Typography>
      //     );
      //   },
      // },
    },
    {
      id: "EmployeeName",
      name: "EmployeeName",
    },
    {
      id: "SSN",
      name: "SSN",
    },
    {
      id: "DPDV",
      name: "DPDV",
      label: "Department",

      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return (
      //       <Typography variant="subtitle2">
      //         {value?.Description}
      //         {/* {value?.Description
      //           ? JSON.stringify(value?.Description)
      //           : tableMeta?.Description
      //           ? JSON.stringify(tableMeta?.Description)
      //           : ""} */}
      //       </Typography>
      //     );
      //   },
      // },
    },
    {
      id: "Emp_Status",
      name: "Emp_Status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="subtitle2">
              {value === "I" ? "InActive" : "Active"}
              {/* {value?.Description
                ? JSON.stringify(value?.Description)
                : tableMeta?.Description
                ? JSON.stringify(tableMeta?.Description)
                : ""} */}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    display: false,

    onViewColumnsChange: (changedColumn, action, option) => {
      console.log("option", option);

      if (action === "add") {
        setCSVPrint([...CSVPrint, changedColumn]);
      } else {
        setCSVPrint(CSVPrint.filter((i) => i !== changedColumn));
      }
    },
  };

  const employeeIssuesColumn = [
    {
      id: "Actions",
      name: "PPE",
      label: "Actions",
      // width: "70px",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip title="Department Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    return history(
                      `/admin/working-hrs/department/${tableMeta.rowData[1]}/${value}`
                    );
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // className="sub-header"
                  onClick={() => {
                    // return history(`update/${tableMeta.ID}`);
                    console.log("Meta121 ::: ", tableMeta, value);
                    return history(
                      `/admin/working-hrs/employee/0/${tableMeta.rowData[1]}/${value}`
                    );
                  }}
                  icon={faUser}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      id: "Stat",
      name: "Stat",
      label: "Stat",
    },
    {
      id: "PPE_Date",
      name: "PPE_Date",
      label: "PPE",

      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("MM-DD-yyyy") : "-";
        },
      },
    },
    // {
    //   id: "DEPT_NAME",
    //   name: "DEPT_NAME",
    //   label: "Department",
    // },
    {
      id: "DEPT_CNT",
      name: "DEPT_CNT",
      label: "Department Count",
    },

    {
      id: "EMP_CNT",
      name: "EMP_CNT",
      label: "Employee Count",
    },
  ];

  return (
    // <TableComponent
    //   columns={columns}
    //   filteredList={filteredList}
    //   onRowClick={() => {}}
    //   tableClass={classes.scrollHeight}
    //   filterComponent={
    //     filteredList && (
    //       <FilterList
    //         changeHeaderFilter={changeHeaderFilter1}
    //         role={role}
    //         requestDetail={requestDetail}
    //         filterBy={filterBy}
    //       />
    //     )

    //   }
    // />
    // <div style={{ width: "100%" }}>
    //   <MUIDataTable
    //     className={classes.customData}
    //     title={<Typography variant="h6">Report Data</Typography>}
    //     data={props.apiCall ? secondApiData : newArray}
    //     columns={props.apiCall ? column : columnFirstApiResponse}
    //     options={options}
    //     selectableRowsHideCheckboxes={true}
    //   />
    // </div>
    <div style={{ width: "100%" }}>
      <MUIDataTable
        className={classes.customData}
        title={<Typography variant="h6">Report Data</Typography>}
        data={requestDetail}
        columns={employeeIssuesColumn}
        options={options}
        selectableRowsHideCheckboxes={true}
      />
    </div>
  );
};

export default DataTable;
