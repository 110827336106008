import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import {
  Checkbox,
  Chip,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Switch,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import withStyles from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import AutocompleteAll from "../../../CommonElements/CustomComponent/AutoComplete";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { ShowError, ShowSucess } from "../../../app/utility/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-duotone-svg-icons";
import ViewTimeSheetTemplates from "../../ConfigurationMaster/TimeSheetTemplate/ViewTimeSheetTemplates";
import { jsIcon } from "@progress/kendo-svg-icons";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

// const statusSwitch = withStyles((theme) => ({
//   root: {
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: "flex",
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     "&$checked": {
//       transform: "translateX(12px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     boxShadow: "none",
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);
const TemplateInfo = (props) => {
  const [templateList, setTemplateList] = useState([]);
  console.log("Cascdsfdsfd", templateList);
  const [statusActive, setStatusActive] = useState(false);

  const [deletedList, setDeletedList] = useState([]);

  let sampleData = {
    id: null,
    template: null,
    effStartDate: null,
    effEndDate: null,
    status: false,
    auth: false,
  };

  const [selectedTemplates, setSelectedTemplates] = useState([sampleData]);
  const [isUpdate, setIsUpdate] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [modalHeader, setModalHeader] = useState();

  const setTemplateId = (id, title) => {
    setSelectedId(id);
    setModalHeader(title);
    setViewModal(true);
  };

  const getTemplateList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}timesheet/get`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode dadaas", response.data);
        if (response.data.result.length) {
          //   if (userRole == "user") {

          let data = response.data.result.map((i) => {
            // i.TimeSheetName = i.TimeSheetAssignLists[0]["TImeSheetName"];
            // i.EmployeeID = i.TimeSheetAssignLists[0]["EmployeeID"];

            let obj = {
              label: i.TimeSheetName,
              // value: i.ID,
              value: i.TimeSheetID ? i.TimeSheetID  :i.ID,
            };
            // if (i.Employee) {
            //   i.Employee["EmployeeName_FML"] = "Self";
            // }

            return obj;
          });
          //   }
          setTemplateList(data);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getAssignedTemplate = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/worktemplate/get`,
        {
          EmployeeID: props.empId ? props.empId : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode1212122", response.data);
        if (response.data.result.length) {
          let data = response.data.result.map((i) => {
            // i.TimeSheetName = i.TimeSheetAssignLists[0]["TImeSheetName"];
            // i.EmployeeID = i.TimeSheetAssignLists[0]["EmployeeID"];

            let obj = {
              template: i.TemplateID,
              effStartDate: i.EffectiveStartDate
                ? moment(i.EffectiveStartDate).format("MM/DD/YYYY")
                : null,
              effEndDate: i.EffectiveEndDate
                ? moment(i.EffectiveEndDate).format("MM/DD/YYYY")
                : null,
              status: i.IsActive,
              id: i.ID,
              auth: i.Auth,
            };

            return obj;
          });
          //   }
          setSelectedTemplates(data);
          setIsUpdate(true);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getTemplateList();
    getAssignedTemplate();
  }, []);

  const getTemplateName = (value) => {
    let templateName = templateList.find((temp) => {
      return temp.value == value;
    });
    return templateName?.label;
  };

  const assignTemplate = () => {
    let newArray = selectedTemplates.map((temp) => {
      if (temp.template) {
        return {
          ID: temp.id,
          EmployeeID: props.empId ? props.empId : 10000000,
          TemplateID: temp.template,
          EmployeeTemplateName: getTemplateName(temp.template),
          DPDV: 111,
          isActive: temp.status === true ? 1 : 0,
          startDate: temp.effStartDate
            ? moment(temp.effStartDate, "MM/DD/YYYY").format("YYYY/MM/DD")
            : null,
          endDate: temp.effEndDate
            ? moment(temp.effEndDate, "MM/DD/YYYY").format("YYYY/MM/DD")
            : null,
          Auth: temp.auth,
        };
      }
    });
    newArray = newArray.filter((temp) => temp != null);
    let infoIsExist = newArray.find((i) => {
      return (
        (!i.startDate && i.endDate) ||
        (i.startDate && !i.endDate) ||
        i.startDate > i.endDate
      );
    });
    if (infoIsExist) {
      if (infoIsExist.startDate > infoIsExist.endDate) {
        ShowError("End Date Should be Greater or Equal to Start Date.");
      } else {
        ShowError("Please Enter valid date");
      }
      return;
    }
    // return;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/worktemplate/${
          isUpdate ? "update" : "update" //"create"
        }`,
        {
          assignList: newArray,
          deletedList: deletedList,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master timecode", response.data);
        // setSelectedTemplates([sampleData]);
        ShowSucess(isUpdate ? "Updated Successfully" : "Added Successfully");
        setDeletedList([]);
        getAssignedTemplate();
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const addTemplateRow = (index) => {
    return (
      <Row className="g-3 mt-1">
        {/* {selectedTemplates[index]?.template && ( */}
        <Col md="1">
          <div
            style={{
              display: "flex",
              width: "50%",
              height: "70%",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Tooltip title="View Template Details" placement="bottom">
              <FontAwesomeIcon
                // className="sub-header"
                onClick={() => {
                  console.log(
                    "123 ::: ",
                    selectedTemplates[index],
                    getTemplateName(selectedTemplates[index].template)
                  );
                  if (selectedTemplates[index]?.template) {
                    setTemplateId(
                      selectedTemplates[index]?.template,
                      getTemplateName(selectedTemplates[index].template)
                    );
                  }
                }}
                icon={faEye}
                style={{
                  cursor: "pointer",
                  color:
                    selectedTemplates[index]?.template &&
                    selectedTemplates[index]?.template != ""
                      ? "#1569C7"
                      : "grey",
                  alignItems: "center",
                }}
              />
            </Tooltip>
          </div>
        </Col>
        {/* )} */}
        <Col md="2">
          <TextField
            // margin="dense"
            className="flex w-full"
            variant="outlined"
            select
            SelectProps={{ native: true }}
            style={{ width: "100%" }}
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            placeholder="Select Template"
            // label="Template"
            // name="template"
            // value={rowData[index]?.template}
            value={selectedTemplates[index]?.template}
            onChange={(e) => {
              let copy = [...selectedTemplates];
              copy[index]["template"] = e.target.value;
              setSelectedTemplates(copy);
            }}
          >
            <option value={""}>Select Template</option>
            {templateList.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Col>
        <Col md="3">
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="flex w-full"
              fullWidth
              size="small"
              format="MM/dd/yyyy"
              disableToolbar
              inputVariant={"outlined"}
              id="effective-start-date"
              // value={selectedTemplates[index]["effStartDate"]}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              name="date"
              value={dayjs(selectedTemplates[index]?.effStartDate)}
              onChange={(_, value) => {
                let copy = [...selectedTemplates];
                copy[index]["effStartDate"] = value;
                setSelectedTemplates(copy);
              }}
              hiddenLabel="true"
            />
          </MuiPickersUtilsProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DesktopDatePicker
               className="payroll-content-date13 w-full"
               KeyboardButtonProps={{
                 "aria-label": "change date",
               }}
               format="MM/DD/YYYY"
               sx={{
                 "& .MuiInputBase-root": {
                   fontSize: 16,
                   height: 40,
                   padding: 0,
                 },
                 "& .MuiInputAdornment-root" : {
                   marginRight:"12px"
                 }
               }}
               inputVariant={"outlined"}
               value={dayjs(selectedTemplates[index]?.effStartDate)}
               onChange={( e) => {
                 let copy = [...selectedTemplates];
                 copy[index]["effStartDate"] = moment(e?.$d).format("MM/DD/YYYY");
                 setSelectedTemplates(copy);
               }}
               name="date"
               hiddenLabel={true}
                renderInput={(props) =>
                  <TextField  
                   {...props} 
                  />}
                />
           </LocalizationProvider>
        </Col>
        <Col md="3">
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="flex w-full"
              fullWidth
              size="small"
              format="MM/dd/yyyy"
              disableToolbar
              inputVariant={"outlined"}
              id="effective-end-date"
              // value={selectedTemplates[index]["effEndDate"]}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              value={dayjs(selectedTemplates[index]?.effEndDate)}
              onChange={(_, value) => {
                let copy = [...selectedTemplates];
                copy[index]["effEndDate"] = value;
                setSelectedTemplates(copy);
              }}
              name="date1"
              hiddenLabel="true"
              error={
                selectedTemplates[index]?.effStartDate >
                selectedTemplates[index]?.effEndDate
                  ? true
                  : false
              }
            />
          </MuiPickersUtilsProvider> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DesktopDatePicker
               className="payroll-content-date13 w-full"
               KeyboardButtonProps={{
                 "aria-label": "change date",
               }}
               format="MM/DD/YYYY"
               sx={{
                 "& .MuiInputBase-root": {
                   fontSize: 16,
                   height: 40,
                   padding: 0,
                 },
                 "& .MuiInputAdornment-root" : {
                   marginRight:"12px"
                 }
               }}
               inputVariant={"outlined"}
               value={dayjs(selectedTemplates[index]?.effEndDate)}
               onChange={( e) => {
                 let copy = [...selectedTemplates];
                 copy[index]["effEndDate"] = moment(e?.$d).format("MM/DD/YYYY");
                 setSelectedTemplates(copy);
               }}
               name="date"
               error={
                selectedTemplates[index]?.effStartDate >
              selectedTemplates[index]?.effEndDate
                  ? true
                  : false
              }
               hiddenLabel={true}
                renderInput={(props) =>
                  <TextField  
                   {...props} 
                  />}
                />
           </LocalizationProvider>
        </Col>

        <Col md="1">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "left",
            }}
          >
            <Switch
              // checked={rowData[index]?.status}
              checked={selectedTemplates[index]?.status}
              onChange={(e) => {
                let copy = [...selectedTemplates];
                copy[index]["status"] = e.target.checked;
                setSelectedTemplates(copy);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </Col>

        <Col md="1">
          {/* <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          > */}

          <Checkbox
            checked={selectedTemplates[index]?.auth ? "true" : false}
            onChange={(e) => {
              let copy = [...selectedTemplates];
              copy.map((i, idx) => {
                return (i.auth = idx == index ? true : false);
              });
              // copy[index]["auth"] = !selectedTemplates[index]?.auth;
              console.log("copy data", copy);
              setSelectedTemplates(copy);
            }}
          />

          {/* </RadioGroup> */}
        </Col>
        <Col md="1">
          {index === selectedTemplates.length - 1 ? (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={() => {
                let copy = [...selectedTemplates];
                copy.push(sampleData);
                setSelectedTemplates(copy);
              }}
              style={{ marginBottom: 20 }}
            >
              +
            </button>
          ) : (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={() => {
                let copy = [...selectedTemplates];
                if (selectedTemplates[index].id) {
                  let list = deletedList;
                  list.push(selectedTemplates[index].id);
                  setDeletedList(list);
                }
                copy.splice(index, 1);
                setSelectedTemplates(copy);
              }}
              style={{ marginBottom: 20 }}
            >
              -
            </button>
          )}
        </Col>
      </Row>
    );
  };

  const checkDateValidations = () => {
    if (selectedTemplates.length) {
      let tempArray = [...selectedTemplates];
    }
  };
  useEffect(() => {
    checkDateValidations();
  }, [selectedTemplates]);

  return (
    <Card>
      <CardHeader className="pb-0">
        <H5>Assign TimeSheet Templates </H5>
      </CardHeader>
      <CardBody>
        {/* <Form> */}
        <Row className="g-3 mt-2">
          <Col md="1">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>View</h6>
            </Typography>
          </Col>
          <Col md="2">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>Template</h6>
            </Typography>
          </Col>
          <Col md="3">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>Effective Start Date</h6>
            </Typography>
          </Col>
          <Col md="3">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>Effective End Date</h6>
            </Typography>
          </Col>
          <Col md="1">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>Status</h6>
            </Typography>
          </Col>
          <Col md="1">
            <Typography style={{ display: "flex", justifyContent: "left" }}>
              <h6>Auth</h6>
            </Typography>
          </Col>
          <Col md="1"></Col>
        </Row>

        {selectedTemplates.map((row, index) => {
          return addTemplateRow(index);
        })}

        <Button
          onClick={() => assignTemplate()}
          variant="contained"
          className="m-10 bg-primary"
          style={{
            color: "#d0efe9",
          }}
        >
          {isUpdate ? "Update Templates" : "Assign Templates"}
        </Button>

        <Dialog
          open={viewModal}
          fullWidth
          onClose={() => {
            setViewModal(false);
          }}
        >
          <DialogTitle>
            {/* <Box display="flex" justifyContent="space-between"> */}
            <Typography>
              <h3>{modalHeader}</h3>
            </Typography>

            {/* <IconButton
                  onClick={() => {
                    setViewModal(false);
                  }}
                >
                  <Close />
                </IconButton> */}
            {/* </Box> */}
          </DialogTitle>
          <DialogContent>
            <ViewTimeSheetTemplates templateId={selectedId} />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setViewModal(false);
              }}
              className="bg-primary"
              style={{ color: "white" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* </Form> */}
      </CardBody>
    </Card>
  );
};

export default TemplateInfo;
