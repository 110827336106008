import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Modal.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { WeekColor, getHoursMinutes, getUserRole, getValidPaycodeOfDay, payCodeFilter } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";

const statusCheck = [{
  label : "Approved" , value : "Approved"
},
{label :"Rejected" , value :"Rejected"}]
function TableRows({
  rowsData,
  deleteTableRows,
  confirmTableRows,
  handleChange,
  handleChangeHizard,
  handleDateFieldChange,
  setTimeTo,
  paytimeCode,
  paytimeCodeByID,
  getDateColor,
  setRowsData,
  payPeriod,
  maxSLLeave,
  maxALLeave,
  userInfo
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextType, setSearchTextType] = useState("");
  const [defaultType, setDefaultType] = useState({});
  const { userData } = useAuth();
  const userRole = getUserRole(userData)


  console.log("rowsData=========",rowsData);

  //   <input
  //   className="form-control"
  //   type="text"
  //   placeholder="Employee Number"
  //   name="eName"
  // />

  useEffect(() => {
   const filterPaycodeList =  payCodeFilter(paytimeCode,paytimeCodeByID,userRole,null,{DPDV:userInfo?.DPDV} )

   setJobTypeOptions(filterPaycodeList)

   console.log("filterPaycodeList",filterPaycodeList, userInfo);
  }, [paytimeCode,paytimeCodeByID,userInfo])

  useEffect(() => {
    console.log("paytimeCode dfsfds",paytimeCode);
    let UpdatedCodes = paytimeCode.map((i) => {
      if (["8", "110", "111", "112","113","114","115"].includes(i.code)) {
        i.disabled = true;
      } else {
        i.disabled = false;
      }
      return i;
    });

    console.log(UpdatedCodes, "MS paytimeCode", paytimeCode);

   

    // setJobTypeOptions(paytimeCode);
   
    setDefaultType(
      paytimeCode.find((i) => {
        return i.value + "" === "REG";
      })
    );

    let paytime = paytimeCode.filter((item) => {
      return ["10","11", "12","110", "111", "112","113","114","115"].includes(item.code)
       
       
    });

    console.log("paytimedfds",paytime,jobTypeOptions);

  const fiteterdArray = paytime.filter((i) => {
    if(jobTypeOptions.find((d) => d.code == i.code)){
      return i
    }
  }) 

  console.log("fiteterdArray",fiteterdArray);

    setHJobTypeOptions(fiteterdArray);
  }, [paytimeCode,jobTypeOptions]);

  const calcualteTime = (mins) => {
    const hr = getHoursMinutes(mins, "h");
    const min = getHoursMinutes(mins, "m");

    return `${hr} hr  ${min} mins`
  }
  const handleErrorMessage = (ole1, ole2, dateExceed1,dateExceed2, leaveHoursAL, leaveHoursSL) => {
  
    console.log("dsvklfjksfhjsksghldksghsl", ole1, ole2, dateExceed1);
    if(ole1 || ole2){
      return "Check your time slot. It is overwrite with other row"
    }else if(dateExceed1 || dateExceed2){
      return "This is PPE end date so enter 12AM time in next date"
    }else if(ole1 || ole2 && (dateExceed1 || dateExceed2)){
      return "This is PPE end date so enter 12AM time in next date"
    }else if(leaveHoursAL){
      return ` AL Leave Hours Exceeded ${calcualteTime(maxALLeave)}`
    }else if(leaveHoursSL){
      return `SL Leave Hours Exceeded  ${calcualteTime(maxSLLeave)}`
    }else{
      return ""
    }

  }
  

  return rowsData.length
    ? rowsData.map((data, index) => {
      console.log("dsadadddadasdad", data);
        const {
          jobtype,
          date,
          time_from,
          time_to,
          time_from1,
          time_to1,
          hrs,
          jobOrder,
          parentIndex,
          hjobtype,
          ole1,
          ole2,
          rowstatus,
          dateExceed1,
          dateExceed2,
          authError2,
          authError1,
          leaveHoursAL,
          leaveHoursSL
        } = data;
        // let parentIndex = data.parentIndex ? data.parentIndex : "";
        // let parentEnable = parentIdx!= "" ? true : false;
        console.log("Payrol table data", data);
        // const inputProps = {
        //   step: 300,
        //   fontSize: 14,
        // };
        const inputProps = {
          style: { fontSize: 14, step: 300 },
          step: 300,
        };

        let parentEnable =
          parentIndex !== "0" && parentIndex != null ? true : false;
        const isDisableRow = (parentIndex, jobtype, resource) => {
          try {
            if (jobtype === "NDIF") {
              return true;
            } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
              if (
                ["time_from", "time_to", "time_from1", "time_to1"].includes(
                  resource
                )
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } catch (error) {
            console.log("error", error.message);
          }
        };

        // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
        const getSelectedType = (jobtype) => {
          let filterObj = jobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP ST", filterObj);

          return filterObj !== undefined
            ? filterObj
            : {
                value: "REG",
                label: "REG",
                title: "REGULAR",
                code: "1",
                multi: 1,
                EffectDate: "1990-01-01T00:00:00.000Z",
                ExpireDate: "2099-12-31T00:00:00.000Z",
                name: "jobtype",
              };
        };
        const getSelectedHType = (jobtype) => {
          let filterObj = hjobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });
          console.log("PPP STD", filterObj);

          return filterObj !== undefined ? filterObj : null;
        };
        return (
          <>
 
            <tr
              key={index}
              bordercolor="red"
              className={index % 2 === 0 ? "trRow1" : "trRow2"}
              style={{
                marginBottom: 4,
                marginTop: 5,

                backgroundColor:getDateColor(date, rowsData) //WeekColor(payPeriod?.StartDate, payPeriod?.EndDate, date)
                //getDateColor(date, rowsData)
                // alignItems: "center",
                // display: "flex",
              }}
              // style={{ backgroundColor: (ole1 || ole2 ? "#e7e7e7" : null),borderRadius:'20px' }}
            >
              {console.log("clsdfjkdsl;",getValidPaycodeOfDay(date,jobTypeOptions))}
              <td className="paroll-contents-td">
                <Autocomplete
                  size={"small"}
                  id="combo-box-demo"
                  name="combo-box-demo"
                  // disabled={ parentEnable }
                  disabled={isDisableRow(parentIndex, jobtype, "jobtype")}
                  autoHighlight={true}
                  autoSelect={true}
                  className="flex w-full mx-10 paroll-autocomplete "
                  onChange={(e, value) => {
                    handleChange(index, {
                      target: {
                        value: value ? value.value : "",
                        code: value ? value.code : 1,
                        name: "jobtype",
                      },
                    });
                    console.log("value==>", value);
                  }}
                  value={jobtype ? getSelectedType(jobtype) : null}
                  filterOptions={(item) => {
                    return item.filter((i) =>
                    i.code?i.code
                        .replace(/-/g, "")
                        .toLowerCase()
                        .match(
                          `${searchTextType.replace(/-/g, "").toLowerCase()}`
                        ):""
                    );
                  }}
                  // getOptionDisabled={(option) => !!timeSlotsArr.find(element => element === option)}

                  // options={jobTypeOptions.filter((val) => !["NDIF","H-5",'H-8','H-10'].includes(val.value))}
                  options={getValidPaycodeOfDay(date,jobTypeOptions)}
                  getOptionDisabled={(jobTypeOptions) =>
                    !!jobTypeOptions.disabled
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="MuiAutocomplete-input"
                      size="small"
                      // label="Pay Type"
                      variant="outlined"
                      hiddenLabel="true"
                      input
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 14 },
                      }}
                      onChange={(e) => {
                        setSearchTextType(e.target.value);
                      }}
                    />
                  )}
                />

                {/*
          <Autocomplete
            id="combo-box-demo"
            disabled={ isDisableRow(parentIndex,jobtype,'jobtype') }
            autoHighlight={true}
            autoSelect={true}
            className="flex w-full mx-10 paroll-autocomplete "
            defaultValue ={{
              "value": "OT1.5",
              "label": "OT1.5",
              "title": "OVERTIME 1.5",
              "code": "6",
              "multi": 1.5,
              "EffectDate": "1990-01-01T00:00:00.000Z",
              "ExpireDate": "2099-12-31T00:00:00.000Z",
              "name": "jobtype"
          }}
            onChange={(e, value) => handleChange(index, e, value)}
            options={jobTypeOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Pay Type" variant="outlined" />
            )}
          />
         
          */}
              </td>
              <td className="paroll-contents-td">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="w-100 payroll-content-date1"
                    // margin="dense"
                    size="small"
                    format="MM/dd/yyyy"
                    disableToolbar
                    inputVariant={"outlined"}
                    id="date-picker-inline"
                    // label="Date"
                    value={date}
                    minDate={payPeriod.StartDate?payPeriod.StartDate:null}
                    maxDate={payPeriod.EndDate?payPeriod.EndDate:null}
                    onChange={(_, value) =>{
                      handleDateFieldChange("date", value, index)
                   

                    }
                      
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isDisableRow(parentIndex, jobtype, "date")}
                    name="date"
                    // variant="outlined"
                    hiddenLabel="true"
                  />
                </MuiPickersUtilsProvider>
                {/* {date} */}
                {/* <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                >
                  <KeyboardDatePicker
                    className="w-full payroll-content-date"
                    disableToolbar
                    inputVariant={"outlined"}
                    format="MM/DD/YYYY"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    value={date}
                    minDate={payPeriod.StartDate}
                    maxDate={payPeriod.EndDate}
                    onChange={(_, value) =>
                      handleDateFieldChange("date", value, index)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    name="date"
                    // disabled={ parentEnable }
                    disabled={isDisableRow(parentIndex, jobtype, "date")}
                  />
                </MuiPickersUtilsProvider> */}
              </td>
              <td className="paroll-contents-td">
                {/* <IMaskInput
            
            mask="(#00) 000-0000"
            definitions={ {
              '#': /[1-9]/,
            } }
             
            onAccept={ (value) => { console.log('ddd', value) } }
         
        overwrite
        /> */}
                {/* <TimePicker
            disableClock={ true } 
            className="tpicker"
            autoFocus={false}
            clearIcon={null}
            name="time_from" id="time_from" onChange={ (e) => { console.log('PXP', e); } } value={ time_from } /> */}

                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={ole1 || dateExceed1 || authError1 ||leaveHoursAL || leaveHoursSL  ? true : false}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from?time_from:""}
                  onChange={(e) => {
                    handleChange(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  // onKeyUp={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  // onBlur={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // } }

                  margin="dense"
                  name="time_from"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={ parentEnable }
                  disabled={isDisableRow(parentIndex, jobtype, "time_from")}
                  hiddenLabel="true"
                />

                {/* {JSON.stringify(time_from)}   */}
                {/* <TimeInput 
          className="tpicker"
            id="time"
            label="From"
            type="time"
            inputProps={ inputProps }
            error	={ole1 ? true: false}
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
              shrink: true,
            }}
           value={time_from?moment(time_from,"HH:mm").format("hh:mm A") :null}
 
            onChange={ (e) => {
              console.log('Mask Row',e.target.value);
              handleChange(index, e)
             } }
            onKeyUp={ (e) => {
              console.log('EEE',e.target.value);
              if (e.target.value) {
                setTimeTo((e.target.value).toUpperCase());
              }
            }}
            onBlur={(e) => {
              if (e.target.value) {
                setTimeTo((e.target.value).toUpperCase());
              }
            } }
            
            margin="dense"
            name="time_from"
            // onKeyUp={(e) => {
            //   let updata = (e.target.value).toUpperCase();
            //   setTemp(updata)
            // } }
          ></TimeInput> */}
                {/* <InputMask  mask={`99:99 {A|P}M`} className="tpicker"  maskChar={null} onKeyDown={(e) => console.log(e.target.value.replace(/ /g,''))} /> */}
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"

                  type="time"
                  inputProps={inputProps}
                  error={ole1 || dateExceed1 || authError1 ||leaveHoursAL || leaveHoursSL ? true : false}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to?time_to:""}
                  onChange={(e) => {
                    handleChange(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  // onKeyUp={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  // onBlur={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isDisableRow(parentIndex, jobtype, "time_to")}
                />
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={ole2 || dateExceed2 || authError2 ||leaveHoursAL || leaveHoursSL ? true : false}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from1?time_from1:""}
                  onChange={(e) => {
                    handleChange(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  // onKeyUp={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  // onBlur={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  margin="dense"
                  name="time_from1"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isDisableRow(parentIndex, jobtype, "time_from1")}
                />
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"
                  type="time"
                  inputProps={inputProps}
                  error={ole2 || dateExceed2 || authError2 ||leaveHoursAL || leaveHoursSL ? true : false}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to1?time_to1:""}
                  onChange={(e) => {
                    handleChange(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  //  onKeyUp={(e) => {
                  //   if (e.target.value) {
                  //     // setTimeTo(e.target.value);
                  //   }
                  // }}
                  // onBlur={(e) => {
                  //   if (e.target.value) {
                  //     setTimeTo(e.target.value);
                  //   }
                  // }}
                  margin="dense"
                  name="time_to1"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isDisableRow(parentIndex, jobtype, "time_to1")}
                />
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  margin="dense"
                  // label="Hrs"
                  name="hours"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={hrs}
                  onChange={(e) => handleChange(index, e)}
                  sx={{ fontSize: 14 }}
                  disabled={isDisableRow(parentIndex, jobtype, "hrs")}
                />
              </td>
              <td className="paroll-contents-td">
                <TextField
                  size={"small"}
                  margin="dense"
                  // label="Job Order"

                  name="jobOrder"
                  // fullWidth={true}
                  variant="outlined"
                  value={jobOrder}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      minWidth: 70,
                    },
                    readOnly: true,
                  }}
                  disabled={isDisableRow(parentIndex, jobtype, "jobOrder")}
                />
              </td>
              <td className="paroll-contents-td">
                {/* <Autocomplete
              id="combo-box-demo"
              disabled={parentEnable}
              autoHighlight={true}
              autoSelect={true}
              className="flex w-full mx-10 paroll-autocomplete "
              onChange={(e, value) => {
                console.log("data123", value);
                handleChangeHizard(index, e, value);
              }}
              options={hjobTypeOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Pay Type" variant="outlined" />
              )}
            /> */}
                {/* <Autocomplete
            id="combo-box-demo"
            disabled={ parentEnable }
            autoHighlight={true}
            autoSelect={true}
            className="flex w-full mx-10 paroll-autocomplete "
            onChange={ (e, value) => handleChangeHizard(index, value) }
            options={ hjobTypeOptions }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Pay Type" variant="outlined" />
            )}
          />  */}

                <Autocomplete
                  className="flex w-full mx-10 paroll-autocomplete"
                  size="small"
                  id="combo-box-demo"
                  disabled={isDisableRow(parentIndex, jobtype, "jobOrder")}
                  autoHighlight={true}
                  autoSelect={true}
                  onChange={(e, value) => {
                    console.log("saaaafa", value);
                    handleChangeHizard(index, value);
                  }}
                  filterOptions={(item) => {
                    return item.filter((i) =>
                      i.value
                        .replace(/-/g, "")
                        .toLowerCase()
                        .match(`${searchText.replace(/-/g, "").toLowerCase()}`)
                    );
                  }}
                  options={hjobTypeOptions}
                  getOptionLabel={(option) => option.label}
                  defaultValue={getSelectedHType(hjobtype)}
                  renderInput={(params) => (
                    <TextField
                      size={"small"}
                      {...params}
                      // label="Pay Type"
                      variant="outlined"
                      // inputProps={inputProps}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 14 },
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  )}
                />
                
              </td>
              <td className="paroll-contents-td tcolor">
                <button
                  className={ `
                  ${parentEnable ? "" : ""}
                   btn btn-outline-primary paroll-contents-calcle `}
                  // disabled={parentEnable}
                  // onClick={ () => confirmTableRows(index) }
                  name="rowstatus"
                  onClick={(e, value) => {
                    confirmTableRows(index, {
                      target: {
                        value: 'Confirmed',
                        name: "rowstatus",
                      },
                    });
                  }}
                >
                   {rowstatus?rowstatus:'confirm' }
                </button>
              </td>
              <td className="paroll-contents-td tcolor">
                <button
                  className={`${
                    parentEnable ? "greyBtn" : ""
                  } btn btn-outline-danger paroll-contents-calcle `}
                  disabled={parentEnable}
                  onClick={() => deleteTableRows(index)}
                 
                  // disabled={parentEnable}
                >
                  X
                </button>
              </td>
              
              {getUserRole(userData) === "supervisor" &&
              <td className="paroll-contents-td" >
                <Autocomplete
                  size={"small"}
                  id="combo-box-demo"
                  name="combo-box-demo"
                  style={{width:"100%"}}
                  // disabled={ parentEnable }
                  disabled={isDisableRow(parentIndex, jobtype, "jobtype")}
                  autoHighlight={true}
                  autoSelect={true}
                  className="flex w-full mx-10 paroll-autocomplete "
                  // onChange={(e, value) => {
                  //   handleChange(index, {
                  //     target: {
                  //       value: value ? value.value : "",
                  //       name: "jobtype",
                  //     },
                  //   });
                  //   console.log("value==>", value);
                  // }}
                  // value={getSelectedType(jobtype)}
                  filterOptions={(item) => {
                    return item.filter((i) =>
                    i.value?i.value
                        .replace(/-/g, "")
                        .toLowerCase()
                        .match(
                          `${searchTextType.replace(/-/g, "").toLowerCase()}`
                        ):""
                    );
                  }}
                  // getOptionDisabled={(option) => !!timeSlotsArr.find(element => element === option)}

                  // options={jobTypeOptions.filter((val) => !["NDIF","H-5",'H-8','H-10'].includes(val.value))}
                  options={statusCheck}
                  getOptionDisabled={(jobTypeOptions) =>
                    !!jobTypeOptions.disabled
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="MuiAutocomplete-input"
                      size="small"
                      // label="Pay Type"
                      variant="outlined"
                      hiddenLabel="true"
                      input
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 14 },
                      }}
                      // onChange={(e) => {
                      //   setSearchTextType(e.target.value);
                      // }}
                    />
                  )}
                />

               
              </td> }
            </tr>
            {/*<tr
          
        >
          <td
            colspan="10"
          >My Work</td>
          </tr> */}
           
            <tr style={{ display: ole1 || ole2 || dateExceed1 || dateExceed2 || leaveHoursAL || leaveHoursSL ? "" : "none" }}>
              <td
                colspan="10"
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  alignItems: "center",
                  transition: "ease-in",
                }}
              >
 
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                
                  {/* {ole1   || ole2 
                    ? "Check your time slot. It is overwrite with other row"
                    : ""} */}
                   {handleErrorMessage(ole1, ole2 , dateExceed1,dateExceed2, leaveHoursAL, leaveHoursSL)}



                </p>
              </td>
              
            </tr>
          </>
        );
      })
    : null;
}

export default TableRows;
