import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { fetchAdvanceSearchData } from "../../../app/store/reducers/slices/configurationSlice";
import AutocompleteAll from "../../../CommonElements/CustomComponent/AutoComplete";
import { ShowError, ShowSucess } from "../../../app/utility/helper";
import LoadingPanel from "../../Common/telerik/loadingPanel";
const top100Films = [
  { title: "foo", value: "foo" },
  { title: "bar", value: "bar" },
  { title: "jar", value: "jar" },
  { title: "nar", value: "nar" },
  { title: "mar", value: "mar" },
  { title: "far", value: "far" },
];

const SankeyDetails = (props) => {
  const [ loading, setLoading ] = useState(true);
  const [value, setValue] = useState({
    DPDV: [],
    Education: [],
    EmpStatus: [],
    EmpType: [],
  });
  const dispatch = useDispatch();
  const { DPDV, Education, EmpStatus, EmpType } = useSelector(
    (state) => state.configurationSlice.advanceSearchData
  );
  // console.log("optionMaster 123", DPDV, Education, EmpStatus, EmpType);
  useEffect(() => {
    dispatch(fetchAdvanceSearchData()).then(() => {
     setLoading(false);
    });
  }, []);
  // const getOptionDisabled = option => option.value === "foo";

  const handleChange = (e, title) => {
    console.log("7896", e, title);
    setValue({ ...value, [title]: e });
  };


  const handleSubmit = () => {
    setLoading(true)
    let submitarray = [];
    if (value?.DPDV?.length) {
      submitarray.push({
        DPDV: value?.DPDV?.map((i) => {
          return i.value;
        }),
      });
    }

    if (value?.Education?.length) {
      submitarray.push({
        Education: value?.Education?.map((i) => {
          return i.value;
        }),
      });
    }

    if (value?.EmpStatus?.length) {
      submitarray.push({
        Emp_Status: value?.EmpStatus?.map((i) => {
          return i.value;
        }),
      });
    }

    if (value?.EmpType?.length) {
      submitarray.push({
        Empl_Type: value?.EmpType?.map((i) => {
          return i.value;
        }),
      });
    }
    if (submitarray.length<2) {
      ShowError("Please select atleast 2 Diffrent types value");
    }
    props.sankeyChartRender(submitarray);
    setTimeout(() => {
      setLoading(false)
    }, 3000);
   

  };

  return (
    <>
      { loading && <LoadingPanel></LoadingPanel> }
    
    <Row>
      <Col md={4} lg={4} sm={12}>
        <AutocompleteAll
          items={DPDV && DPDV}
          // getOptionDisabled={getOptionDisabled}
          label=""
          placeholder="Select Department"
          modelLable="Selected Department"
          selectAllLabel="Select all"
          onChange={(e) => handleChange(e, "DPDV")}
        />
      </Col>
      <Col md={4} lg={4} sm={12}>
        <AutocompleteAll
          items={Education && Education}
          // getOptionDisabled={getOptionDisabled}
          label=""
          placeholder="Select Employee Education"
          selectAllLabel="Select all"
          onChange={(e) => handleChange(e, "Education")}
        />
      </Col>
      <Col md={4} lg={4} sm={12}>
        <AutocompleteAll
          items={EmpStatus && EmpStatus}
          // getOptionDisabled={getOptionDisabled}
          label=""
          placeholder="Select Employee Status"
          selectAllLabel="Select all"
          onChange={(e) => handleChange(e, "EmpStatus")}
        />
      </Col>
      <Col md={4} lg={4} sm={12}>
        <AutocompleteAll
          items={EmpType && EmpType}
          // getOptionDisabled={getOptionDisabled}
          label=""
          placeholder="Select Employee Type"
          selectAllLabel="Select all"
          onChange={(e) => handleChange(e, "EmpType")}
        />
      </Col>
      <Row>
        <Col md={4} lg={4} sm={12}>
          <Button
            variant="contained"
            className="bg-primary"
            onClick={handleSubmit}
          >
            Generate Flow
          </Button>
        </Col>
      </Row>
      </Row>
      </>
  );
};

export default SankeyDetails;
