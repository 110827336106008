import React, { useEffect, useState } from "react";
import table from "./table.css";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import CloseIcon from "@mui/icons-material/Close";

import {
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Chip,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

import { ExcelExport } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { CustomMenu } from "./customMenu";
import { useNavigate, useLocation } from "react-router-dom";
import ColumnFilter from "./columnFilter";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import FullScreenWorkflowDialog from "../../Components/Workflow/WorkflowReadOnly";
import QRCodeModal from "../../Components/AdvanceSearch/QRCodeModal";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  SetGridDataState,
  SetGridFilter,
  SetGridSort,
  setAdditionalWhere,
  setGridPageSize,
  setGridSkip,
} from "../../app/store/reducers/slices/CommonSlice";
import { AlternateEmail } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialDataState = {
  sort: [
    {
      field: "code",
      dir: "asc",
    },
  ],
  //   take: 10,
  //   skip: 0,
};
const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
const SQL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const SQL_DATE_FORMAT = "DD/MM/YYYY";
const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
const mappings = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
};
const formatFilter = (mapping, field, value) => {
  return mapping.replace("{0}", field).replace("{1}", value);
};
const formatDateFilter = (mapping, field, value) => {
  return mapping
    .replace("{0}", `CONVERT(DATE,${field})`)
    .replace("'{1}'", `CONVERT(DATE,'${value}')`);
};
const toSQLExpression = (filter) => {
  if (!filter) return;

  let filters = filter.filters,
    field,
    value,
    operator,
    mapping,
    type,
    logic = filter.logic || "AND",
    result = [];

  for (let i = 0; i < filters.length; i++) {
    filter = filters[i];
    field = filter.field;
    value = filter.value;
    operator = filter.operator;

    if (filter.filters) {
      filter = toSQLExpression(filter);
    } else {
      mapping = mappings[operator];
      type = typeof value; ///$.type

      // if (value instanceof Date) {
      //   value =moment(value).format(SQL_DATE_FORMAT) // toString(value.toISOString().slice(0, 19).replace('T', ' '));
      // }
      if (value instanceof Date) {
        filter = formatDateFilter(
          mapping,
          field,
          moment(value).format(SQL_DATE_FORMAT)
        );
      } else {
        filter = formatFilter(mapping, field, value);
      }
    }

    result.push(filter);
  }

  filter = result.join(" " + logic.toUpperCase() + " ");

  if (result.length > 1) {
    filter = "(" + filter + ")";
  }

  return filter;
};
const TelerikDataTable = (props) => {
  const {
    filter: storeFilter,
    dataState: storeDataState,
    sort: storeSort,
    skip: storeSkip,
    pageSize: storePageSize,
  } = useSelector((state) => state.CommonSlice);
  const { savedLocation } = useSelector((state) => state.advanceSearchSlice);
  const dispatch = useDispatch();
  const URL = useLocation();
  const minGridWidth = React.useRef(0);
  const grid = React.useRef(null);
  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  let gridPDFExport;
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const navigate = useNavigate();
  const [filter, setFilter] = React.useState({ logic: "and", filters: [] });
  const [gridParams, setGridParams] = useState({
    sort: [],
    skip: 0,
    pageSize: 10,
  });
  const [workflowInfo, setWorkflowInfo] = useState({
    workflowID: 0,
    CurrentStatus: "",
    employeeName: "",
    hrControlNo: "",
  });
  const [dataState, setDataState] = React.useState(initialDataState);
  let {
    id,
    setSortedColumns,
    selectedColumns,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    onSortChange,
    totalCount,
    skip,
    loading,
    sortData,
    actions,
    handleRadioChange,
    chooseFilterMethod,
    setChooseFilterMethod,
    isDashboard,
    handleClick,
    isEmployeeManagement,
  } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (savedLocation !== "") {
      setChooseFilterMethod(
        savedLocation === URL?.pathname ? "advanceSearch" : chooseFilterMethod
      );
    }
  }, [savedLocation]);

  React.useEffect(() => {
    setGridParams({ pageSize: pageSize, skip: skip, sort: sortData });
  }, [pageSize, skip, sortData]);
  React.useEffect(() => {
    let gridState = storeDataState[id];
    if (gridState) {
      setDataState(gridState);
    }
  }, [id]);

  React.useEffect(() => {
    let gridState = storeDataState[id];
    if (gridState) {
      setDataState(gridState);
    }
  }, [storeDataState, id]);
  useEffect(() => {
    let pageSize = storePageSize[id];
    let skip = storeSkip[id];
    let filter = storeFilter[id];
    let sort = storeSort[id];
    if (filter) {
      setFilter(filter);
    }

    if (pageSize || skip || sort) {
      setGridParams({ pageSize: pageSize, skip: skip, sort: sort });
    }
  }, [storeSort, storePageSize, storeSkip, storeFilter, id]);
  useEffect(() => {
    if (id && storeDataState[id]) {
      setDataState(storeDataState[id]);
    }
  }, [id, storeDataState]);
  useEffect(() => {
    let data = {};
    data[id] = dataState;
    dispatch(SetGridDataState(data));
  }, [dataState]);
  const [employeeInfo, setEmployeeInfo] = useState({
    ID: null,
    NAME: "",
  });
  React.useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    props.selectedColumns.map((item) =>
      item.minWidth !== undefined
        ? (minGridWidth.current += item.minWidth)
        : minGridWidth.current
    );
    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, []);
  const handleResize = () => {
    if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };
  // const setWidth = (minWidth) => {
  //   minWidth = minWidth === undefined ? (minWidth = 0) : minWidth;
  //   let width = applyMinWidth
  //     ? minWidth
  //     : minWidth +
  //       (gridCurrent - minGridWidth.current) / props.selectedColumns.length;
  //   width = width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
  //   return width;
  // };
  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };
  handleRadioChange = (e) => {
    setChooseFilterMethod(e.target.value);
  };

  const exportPDF = () => {
    setTimeout(() => {
      if (gridPDFExport) {
        gridPDFExport.save([{ id: 1, name: "Hello" }]);
      }
    }, 250);
  };
  const updateLockColumn = async (col, status) => {
    let copyArray = [...selectedColumns];
    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field === col;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["locked"] = status;
      copyArray[objectAtIndex] = copyObject;
      setSortedColumns(copyArray);
    }
  };

  let GridComponent = (
    <>
      {/* {workflowInfo?.workflowID > 0 && (
        <FullScreenWorkflowDialog
          id={workflowInfo?.workflowID}
          currentNodeText={workflowInfo?.CurrentStatus}
          employeeName={workflowInfo?.employeeName}
          hrControlNo={workflowInfo?.hrControlNo}
          onClose={() => {
            setWorkflowInfo({ workflowID: 0 });
          }}
          isOpened={workflowInfo?.workflowID > 0 ? true : false}
        />
      )} */}
      {loading && loadingPanel}
      <Grid
        style={{
          maxHeight: "calc(100vh - 420px)",
          minHeight: "450px",
        }}
        data={process(props.data, dataState)}
        {...dataState}
        reorderable={true}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 20, 50, 100],
          pageSizeValue: gridParams.pageSize,
        }}
        sortable={{
          allowUnsort: true,
          mode: "multiple",
        }}
        groupable={true}
        pageSize={gridParams.pageSize}
        total={totalCount}
        skip={gridParams.skip}
        take={gridParams.pageSize}
        filter={filter}
        onFilterChange={(props) => {
          setFilter(props.filter);
          const SQLFilter = toSQLExpression(props.filter);
          onFilterChange(SQLFilter);
          if (id) {
            const d = {};
            d[id] = props.filter;
            dispatch(SetGridFilter(d));
            const where = {};
            where[id] = SQLFilter;
            dispatch(setAdditionalWhere(where));
            const skip = {};
            skip[id] = 0;
            dispatch(setGridSkip(skip));
          }
          return false;
        }}
        onSortChange={(props) => {
          if (id) {
            const d = {};
            d[id] = props.sort;
            dispatch(SetGridSort(d));
          }
          onSortChange(props.sort);
        }}
        sort={gridParams.sort}
        onPageChange={(evt) => {
          if (id) {
            const skip = {};
            skip[id] = evt.page.skip;
            dispatch(setGridSkip(skip));
            const d = {};
            d[id] = evt.page.take;
            dispatch(setGridPageSize(d));
          }
          onPageChange(evt);
        }}
        onPageSizeChange={(evt) => {
          if (id) {
            const d = {};
            d[id] = evt.target.value;
            dispatch(setGridPageSize(d));
          }
          onPageSizeChange(evt);
        }}
        filterable={
          // isDashboard === true || chooseFilterMethod === "normalSearch"
          //   ? true
          //   : false
          true
        }
        resizable={true}
        onDataStateChange={(e) => {
          setDataState(e.dataState);
        }}
      >
        <GridToolbar>
          <div style={{ float: "left", width: "50%" }}>
            <Tooltip title="Export Excel" placement="bottom">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={excelExport}
              >
                <FontAwesomeIcon icon={faFile} className="font-primary" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Export PDF" placement="bottom">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={exportPDF}
              >
                <FontAwesomeIcon icon={faFilePdf} className="font-primary" />
              </IconButton>
            </Tooltip>

            <ColumnFilter
              columns={selectedColumns}
              setSortedColumns={setSortedColumns}
            />
          </div>
          {isDashboard === true ? (
            ""
          ) : (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="radio"
                checked={chooseFilterMethod ?? ""}
                value={chooseFilterMethod ?? ""}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="advanceSearch"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" fontWeight="bold">
                      Advance Search
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="normalSearch"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" fontWeight="bold">
                      Normal Search
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        </GridToolbar>
        {actions && actions.length && (
          <GridColumn
            field="ID"
            title="Actions"
            width={
              actions?.length <= 3
                ? `${actions?.length * 30 + 70}px`
                : `${actions?.length * 30}px`
            }
            filterable={false}
            cell={(props) => (
              <td
                {...props}
                style={{
                  backgroundColor:
                    props?.dataItem?.isDeleted === true ? "red" : null,
                }}
              >
                {isEmployeeManagement ? (
                  <div>
                    <FormControl sx={{ minWidth: 200 }}>
                      <Select
                        sx={{
                          width: "200px",
                          height: "40px",
                          marginTop: "7px",
                        }}
                        displayEmpty
                        value=""
                      >
                        <MenuItem disabled value="">
                          Select Action
                        </MenuItem>
                        {actions && actions.length
                          ? actions
                              .filter(
                                (d) =>
                                  d.onlyVisible === undefined ||
                                  (d.onlyVisible &&
                                    props.dataItem[d.onlyVisible] > 0)
                              )
                              .map((item, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    value={item.title}
                                    onClick={() => {
                                      if (!item.action) {
                                        switch (item.actionType) {
                                          case "workflow":
                                            setWorkflowInfo({
                                              workflowID:
                                                props.dataItem.workflowID,
                                              CurrentStatus:
                                                props.dataItem
                                                  .currentWorkflowStatus,
                                              employeeName:
                                                props.dataItem.employeeName,
                                              hrControlNo:
                                                props.dataItem.hrControl,
                                            });
                                            break;
                                          case "qrcode": {
                                            // debugger;
                                            handleModal(
                                              props.dataItem.id,
                                              props.dataItem.employeeName_FML
                                            );
                                            break;
                                          }
                                          default:
                                            break;
                                        }
                                      } else {
                                        item.action(props);
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ marginRight: "7px" }}
                                      className={item.className}
                                      icon={item?.icon}
                                    />
                                    {item?.title}
                                  </MenuItem>
                                );
                              })
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <div style={{ display: "flex", columnGap: "6px" }}>
                    {actions &&
                      actions
                        .filter(
                          (d) =>
                            d.onlyVisible === undefined ||
                            (d.onlyVisible && props.dataItem[d.onlyVisible] > 0)
                        )
                        .map((item) => (
                          <Tooltip
                            style={{ cursor: "pointer" }}
                            title={item.title}
                            placement="bottom"
                          >
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (!item.action) {
                                  console.log("workflowAction", props);

                                  switch (item.actionType) {
                                    case "workflow":
                                      setWorkflowInfo({
                                        workflowID: props.dataItem.workflowID,
                                        CurrentStatus:
                                          props.dataItem.currentWorkflowStatus,
                                        employeeName:
                                          props.dataItem.employeeName,
                                        hrControlNo: props.dataItem.hrControl,
                                      });
                                      break;
                                    case "qrcode": {
                                      // debugger;
                                      handleModal(
                                        props.dataItem.id,
                                        props.dataItem.employeeName_FML
                                      );
                                      break;
                                    }
                                    default:
                                      break;
                                  }
                                } else {
                                  item.action(props);
                                }
                              }}
                              className={item.className}
                              icon={
                                item?.title === "delete" &&
                                props?.dataItem?.isDeleted === true
                                  ? null
                                  : item.icon
                              }
                            />
                          </Tooltip>
                        ))}
                  </div>
                )}
              </td>
            )}
          />
        )}

        {props.selectedColumns && props.selectedColumns.length
          ? props.selectedColumns
              .filter((i) => i.show)
              .map((i) => {
                return (
                  <GridColumn
                    field={i.field}
                    filter={i.filter}
                    locked={i.locked}
                    title={i.title}
                    width={i.minWidth || i.width}
                    minResizableWidth={100}
                    format={i.format}
                    editor={i.editor}
                    cell={(props) => {
                      const field = props.field || "";
                      const editor = props.editor || "";
                      const format = props.format || "";
                      const value = props.dataItem[field];
                      return (
                        <td
                          key={i.title}
                          onClick={
                            isDashboard === true
                              ? () => handleClick(props)
                              : null
                          }
                          style={{
                            whiteSpace: "nowrap",
                            minWidth: i.minWidth || "100px !important",
                            backgroundColor:
                              props?.dataItem?.isDeleted === true
                                ? "red"
                                : null,
                          }}
                        >
                          {editor === "date" && value !== null ? (
                            moment(value).format(format)
                          ) : field === "IsActive" ? (
                            value === true ? (
                              "Yes"
                            ) : (
                              "No"
                            )
                          ) : field === "requestType" ? (
                            value?.toUpperCase()
                          ) : (field === "status" ||
                              field === "currentWorkflowStatus" ||
                              field === "processStatus") &&
                            value !== null ? (
                            <Button
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                                height: "35px",
                              }}
                              color="primary"
                              variant="contained"
                              className="bg-primary"
                            >
                              {value ? value : "N/A"}
                            </Button>
                          ) : value ? (
                            value
                          ) : (
                            "N/A"
                          )}
                        </td>
                      );
                    }}
                    columnMenu={(props) => (
                      <CustomMenu
                        locked={i.locked}
                        {...props}
                        onColumnLockToggle={(col, status) => {
                          updateLockColumn(col, status);
                        }}
                      />
                    )}
                  />
                );
              })
          : null}
      </Grid>
    </>
  );
  return (
    <>
      <ExcelExport data={props.data} ref={_export}>
        {GridComponent}
      </ExcelExport>

      {openModal && (
        <div>
          <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0",
                }}
              >
                <h6 className="mt-2">
                  {employeeInfo?.NAME ? employeeInfo.NAME + " 'S QR Code" : ""}
                </h6>
                <div>
                  <IconButton onClick={handleModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <QRCodeModal employeeInfo={employeeInfo} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TelerikDataTable;
