import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "./header";
// import Header from "./component/header";
import PayCodeManagement from "./PayStepManage";
// import Professional from "./component/professionaleDetail";
// import Other from "./component/otherDetail";
import RequestTable from "./request-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import PayStepManage from "./PayStepManage";

import { toast } from "react-toastify";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "33.33%",
    fontWeight: 500,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

function NPosition(props) {
  const classes = useStyles();

  const detail = {
    id: null,
    nPositionNo: "",
    title: "",
    jobColler: "",
    rangeCurrent: "",
    rangeNext: "",
    termId: "",
    passId: "",
    UPDDate: null,
    NUPDDate: null,
    NRVar: "",
    CLASS: "",
    catagory: "",
    exemptNon: "",
    OCCSeries: "",
    TDP: "",
    classified: "",
    jobOrder: "",
  };
  const [tableList, setTableList] = useState([]);
  const submitCallValidation = useRef();
  const pageLayout = useRef(null);
  const [id, setId] = useState(null);
  console.log("id123", id);
  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}nPosition`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const clearField = () => {
    // setValues(detail);
  };
  // const onSubmit = () => {
  //   return false;
  //   const e = {};
  //   let paypload = {
  //     NPOSITION_NO: e.nPositionNo,
  //     TITLE: e.title,
  //     JOB_COLLAR: e.jobColler,
  //     RANGE_CURRENT: e.rangeCurrent,
  //     RANGE_NEXT: e.rangeNext,
  //     TERM_ID: e.termId,
  //     PASS_ID: e.passId,
  //     UPD_DATE: e.UPDDate,
  //     NUPD_DATE: e.NUPDDate,
  //     NR_VAR: e.NRVar,
  //     CLASS: e.CLASS,
  //     CATAGORY: e.catagory,
  //     EXEMPT_NON: e.exemptNon,
  //     OCC_SERIES: e.OCCSeries,
  //     TDP: e.TDP,
  //     CLASSIFIED: e.classified,
  //     JOB_ORDER: e.jobOrder,
  //   };
  //   if (detail.id) {
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_API_URL}nPosition/update/${detail.id}`,
  //         paypload,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-token": localStorage.getItem("jwt_access_token"),
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         getData();

  //         clearField();
  //         toast.success(response.data.message);

  //         console.log("Master responsexxxx", response.data);
  //       })
  //       .catch((error) => {
  //         console.warn("Cannot retrieve user data", error);
  //         toast.error(error.message);
  //       });
  //   } else {
  //     axios
  //       .post(`${process.env.REACT_APP_API_URL}nPosition/create`, paypload, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-token": localStorage.getItem("jwt_access_token"),
  //         },
  //       })
  //       .then((response) => {
  //         getData();

  //         clearField();
  //         toast.success(response.data.message);

  //         console.log("Master responsexxxx", response.data);
  //       })
  //       .catch((error) => {
  //         console.warn("Cannot retrieve user data", error);
  //         toast.error(error.message);
  //       });
  //   }
  // };

  return (
    <>
      
      
      <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
        <HomeHeader
          {...props}
          detail={detail}
          clearField={() => submitCallValidation.current?.setValues()}
          saveData={() => submitCallValidation.current?.submitCall()}
          values={() => submitCallValidation.current?.currentValues}
          id={id}
          setId={setId}
        />
        </div>
        <div className="w-100 h-full mt-3 " ref={pageLayout} innerScroll>
          <div className={`${classes.root} w-100 page-content`}>
            <Card className="mt-3 mb-3 ">
              <Accordion
                className={classes.root}
                expanded={expand.panel1}
                onChange={handleChange1("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="bg-gray-200 sub-header"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="sub-header"
                    style={{
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography className={classes.heading}>
                    NPosition Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 card-header">
                  <PayStepManage
                    ref={submitCallValidation}
                    detail={detail}
                    getData={getData}
                    // handleChange={handleChange}
                    // values={values}
                    // errors={errors}
                    // touched={touched}
                    // setFieldValue={setFieldValue}
                    // functionCall={submitCall}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card>
              <Accordion
                className={classes.root}
                expanded={expand.panel2}
                onChange={handleChange1("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className="bg-gray-200 sub-header"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    style={{
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography className={classes.heading}>
                    NPosition List
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0">
                  <RequestTable
                    // {...props}
                    // role={"admin"}
                    setId={setId}
                    requestDetail={tableList.length && tableList}
                    setValues={(data) =>
                      submitCallValidation.current?.setValues(data)
                    }
                    // setDetail={setDetail}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          </div>
        </div>
        {/* <FusePageCarded
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
        header={
          <Header
            {...props}
            detail={detail}
            getData={getData}
            setDetail={setDetail}
          />
        }
        content={
          <div className={`${classes.root} w-full`}>
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Codes Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <PayCodeManagement detail={detail} setDetail={setDetail} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Codes List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <RequestTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  userDetail={{
                    firstName: "Robert",
                    middleName: "M",
                    lastName: "Duenas",
                    fullName: "Robert Duenas",
                    email: "robert@gmail.com",
                    avatar: "assets/images/avatars/Tillman.jpg",
                    maritalStatus: "single",
                    citizen: "United States",
                    education: "BACCALAUREATE",
                    gender: "Male",
                    disability: "NO",
                    DOB: "11/05/1993",
                    veteran: "Y",
                    i9: "Y",
                    ssn: "333-10-1001",
                    empTye: "Permanent",
                    dept: "DOA-Personnel Management",
                    payGrade: "Q",
                    empNo: "1035",
                    positionTitle: "EQUAL EMPLOYMENT OPPORTUNITY",
                    employmentDate: "02/04/2018",
                    reEmploymentDate: "02/04/2019",
                    totalSvc: " ",
                    currentSvc: " ",
                    totalPriorSvc: "18Y 0M 11D",
                    milPriorSvc: "18Y 0M 11D",
                    grade: "Q",
                    step: "9",
                    rate: "38.69",
                    salary: "8047",
                    actual: "38.69",
                    actual2: "80,476.00",
                    home: "174N HENRY KAISER, DEDEDO, GU, 96921",
                    mail: "POBOX 228444 GMF, BARRIGADA, GU, 96921",
                    homePhone: "6124893753",
                    workPhone: "6124893753",
                    workSchedule: "FULL TIME",
                    lastActionDate: "09/02/2020",
                    policeClearanceDate: "09/02/2020",
                    courtClearanceDate: "09/02/2020",
                    empContractNo: " ",
                    requestId: " ",
                    physicalExam: "Y",
                    drivingLicenseNo: " ",
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        }
        ref={pageLayout}
        innerScroll
      /> */}
      </Container>
    </>
  );
}

export default NPosition;
