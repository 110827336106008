import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalCustom from "../../../employee/Modal";
import DeductionDetailModal from "./DeductionDetailModal";
import LoadingPanel from "../../../Common/telerik/loadingPanel";
import { formatMoney } from "../../../../app/utility/helper";

const visibleColumnArray = [
  "SSN",
  "ID",
  "EmployeeName",
  "Email",
  "Inactive_Status",
  "Emp_Status",
];

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [openDeductionDetailModal, setOpenDeductionDetailModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [CSVPrint, setCSVPrint] = useState(visibleColumnArray);

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail } = props;
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    Department: "",
    Status: "",
  });

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data && data.EmployeeID
            ? data.EmployeeID.toLowerCase().includes(
                filterBy.EmployeeID?.toLowerCase()
              )
            : data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLowerCase()
          )
        );
      }
      if (filterBy?.Status) {
        dataList = dataList.filter((data) =>
          data.Status.toLowerCase().includes(filterBy.Status.toLowerCase())
        );
      }
      if (filterBy?.Department) {
        dataList = dataList.filter((data) =>
          data.Description
            ? data.Description
            : data.DPDV_DepartmentDivisionMaster.Description.toLowerCase().includes(
                filterBy.Department.toLowerCase()
              )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const options = {
    selectableRows: false,
    display: false,

    onViewColumnsChange: (changedColumn, action, option) => {
      if (action === "add") {
        setCSVPrint([...CSVPrint, changedColumn]);
      } else {
        setCSVPrint(CSVPrint.filter((i) => i !== changedColumn));
      }
    },
  };

  const employeeW2Column = [
    {
      id: "Actions",
      name: "employeeID",
      label: "Actions",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              {/* <Tooltip title="Deduction Details" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    setOpenDeductionDetailModal(true);
                    setSelectedEmployee(requestDetail?.find(o => o.employeeID === value));
                  }}
                  icon={faEye}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip> */}
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  // onClick={() => {
                  //   return history(
                  //     `/admin/employee/update/${value}`
                  //   );
                  // }}
                  onClick={()=>history(`/admin/employee/update/${tableMeta?.rowData[1]}`)}
                  icon={faUser}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
        id: "employeeId",
        name: "employeeId",
        label: "Employee Id",
    },
    {
        id: "employeeName",
        name: "employeeName",
        label: "Employee Name",
    },
    {
        id: "address",
        name: "address",
        label: "Address",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
          },
      },
    },
    {
        id: "zipcode",
        name: "zipcode",
        label: "Zipcode",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
          },
      },
    },
    {
        id: "description",
        name: "description",
        label: "Department",
    },
    {
        id: "totalWage",
        name: "totalWage",
        label: "Total Wage",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return formatMoney(Number(value) ? Number(value) : 0);
            },
        },
    },
    {
        id: "totalWithHeld",
        name: "totalWithHeld",
        label: "Total Withheld",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return formatMoney(Number(value) ? Number(value) : 0);
            },
        },
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {!props?.loading ?
          <MUIDataTable
            className={classes.customData}
            title={<Typography variant="h6">W2 Report Data</Typography>}
            data={requestDetail}
            columns={employeeW2Column}
            options={options}
            selectableRowsHideCheckboxes={true}
          />
          :
          <div >
            <LoadingPanel></LoadingPanel>
          </div>
        }
      </div>
      {/* <ModalCustom
        open={openDeductionDetailModal}
        handleClose={() => {
          setOpenDeductionDetailModal(false);
        }}
        closeButton={true}
      >
        <DeductionDetailModal
          selectedEmployee={selectedEmployee}
          setOpenDeductionDetailModal={setOpenDeductionDetailModal}
        />
      </ModalCustom> */}
    </>
  );
};

export default DataTable;