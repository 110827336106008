import Typography from '@material-ui/core/Typography';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUp, faCircleDown } from '@fortawesome/pro-duotone-svg-icons';
import { Card } from '@material-ui/core';
import { format2Decimal } from '../../../../app/utility/helper';

function DeltaComponent(props) {

  const { value, percentage, diffrence, title, subtext } = props;
  return (
    <Card className="w-full rounded-20 p-0 CardBack">
      <div className="p-20 pb-0">
        <Typography className="h4 font-medium">{title}</Typography>
        <div className="flex-col flex-wrap mt-12  mb-12">
          <Typography
            variant="h5"
            className=" leading-none tracking-tighter mb-6">
            {/**font-semibold  text-48 */}
            {value}
          </Typography>

          <div className="flex flex-rows ">
            <div className="flex items-center " style={{ marginBottom: '20px' }}>
              {percentage >= 0 ? (
                <Typography
                  className="font-semibold upclass"
                  color="textSecondary">
                  +{format2Decimal(diffrence)} ({format2Decimal(percentage)}%){' '}
                  <FontAwesomeIcon
                    style={{ verticalAlign: 'middle' }}
                    icon={faCircleUp}
                    className=" upclass text-18  "
                  />{' '}
                  {subtext}
                </Typography>
              ) : (
                <Typography
                  className="font-semibold downclass"
                  color="textSecondary">
                  {/* {data.impressions.ofTarget}% */}
                  {format2Decimal(diffrence)} ({format2Decimal(percentage)}%){' '}
                  <FontAwesomeIcon
                    style={{ verticalAlign: 'middle' }}
                    icon={faCircleDown}
                    className=" downclass text-18 "
                  />{' '}
                  {subtext}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-96 w-100-p">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type={data.options.chart.type}
          height={data.options.chart.height}
        />
      </div> */}
    </Card>
  );
}

export default DeltaComponent;
