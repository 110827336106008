/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Box, Hidden, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox, Input } from "@material-ui/core";
import { Label } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@material-ui/icons/Done";
import SankeyDPValueModal from "../../Components/Reports/Sankey/SankeyDPValueModal";

const items = [
  { label: "foo", value: "foo" },
  { label: "bar", value: "bar" },
  { label: "jar", value: "jar" },
  { label: "nar", value: "nar" },
  { label: "mar", value: "mar" },
  { label: "far", value: "far" },
];

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const AutocompleteAll = ({
  items,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
  modelLable,
  selectedValues,
  deletedValues,
  setDeletedValues,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option) => `${option.label}`;
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const [deletedOptions, setDeletedOptions] = useState([]);
  useEffect(() => {
    console.log("Jams", selectedValues.length);
    if (selectedValues) {
      console.log("Jamsinnnnn", selectedValues);
      setSelectedOptions(selectedValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    if(deletedOptions?.length){

    
    console.log("DDDDDDD deletedOptions : ", deletedOptions);
    setDeletedValues(deletedOptions);
    }
  }, [deletedOptions?.length]);

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== "select-all");
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      multiline={false}
      value={selectedOptions.map((i) => i.label)}
    />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Box>
      <Autocomplete
        multiple
        //   limitTags={limitTags}
        //   renderValue={(selected) => selected.join(", ")}
        style={{ width: "100%", overflow: "hidden" }}
        options={items}
        closeIcon={<CloseIcon onClick={() => onChange([])} />}
        // renderTags={(selected) => {
        //   if (selected.length === 0) {
        //     return <em>Placeholder</em>;
        //   }

        //   return selected.join(", ");
        // }}
        renderTags={() => null}
        //   renderTags={(selected) => {
        //     if (selected?.length === 0) {
        //       return <em>Placeholder</em>;
        //     }

        //     return (
        //       <span
        //         style={{
        //           display: "flex",
        //           overflow: "hidden",
        //           maxHeight: "25px",
        //           //   flexWrap: "wrap",
        //           position: "relative",
        //         }}
        //       >
        //         {selected
        //           ?.map((i) => {
        //             return i.label;
        //           })
        //           ?.join(" ,")}
        //       </span>
        //     );
        //   }}
        //   renderTags={(selected) => (
        //     <div style={{ overflow: "inherit" }}>
        //       {selected.map((value) => (
        //         <div
        //           key={value.value}
        //           style={{
        //             margin: "2px",
        //             display: "flex",
        //             maxHeight: "10px",
        //           }}
        //         >
        //           {value.label}
        //         </div>
        //       ))}
        //     </div>
        //   )}
        value={selectedOptions}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        noOptionsText={noOptionsText}
        disableCloseOnSelect
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
        }}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
        deletedOptions={deletedOptions}
      />

      <Box
        mt={1}
        sx={{
          "& > :not(:last-child)": { marginRight: 1 },
          "& > *": { marginBottom: 1 },
        }}
      >
        {/* <Tooltip
          title={selectedOptions
            ?.map((v) => {
              return v.label;
            })
            ?.join(" , ")}
          arrow
        >
          <label style={{ color: "#1976d2" }}>
            {" "}
            {selectedOptions.length
              ? `${selectedOptions.length} Selected`
              : null}
          </label>
        </Tooltip> */}
        <Tooltip title="Click To View" arrow>
          <label
            onClick={handleOpen}
            style={{ color: "#1976d2", cursor: "pointer" }}
          >
            {" "}
            {selectedOptions?.length
              ? `${selectedOptions?.length} Item Selected`
              : null}
          </label>
        </Tooltip>
        <SankeyDPValueModal
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          label={modelLable}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          deletedOptions={deletedOptions}
          setDeletedOptions={setDeletedOptions}
        />
      </Box>
    </Box>
  );
};

AutocompleteAll.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
};

export default AutocompleteAll;
