import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { Container, FormControl, TextField } from "@mui/material";

import Header from "./component/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import axios from "axios";
import { Card, CardBody } from "reactstrap";
import EmpCertificationManage from "./component/EmpCertificationManage";
import './employeeCertification.css';
import moment from "moment";
import { getAllEmployeeCertificatesRecords, getAllEmployeeRecords, getAllLkCertificationRecords } from "../../../app/utility/axiosService";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const EmployeeCertification = (props) => {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    oldId: null,
    employeeID: 0,
    certificationCode: 0,
    certificationSeq: '',
    validFrom: null,
    validTo: null,
    isActive: true,
    remarks: ""
  });
  const [tableList, setTableList] = useState([]);
  const [allPayPeriod, setAllPayPeriod] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [LkCertificationList, setLkCertificationList] = useState([]);
  const [payPeriod, setPayPeriod] = useState(null);
  const [takeCount, setTakeCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [takeEmployeeCount, setTakeEmployeeCount] = useState(10);
  const [skipEmployeeCount, setSkipEmployeeCount] = useState(0);
  const [getRecordDate, setGetRecordDate] = useState({
    startDate: "",
    endDate: ""
  });

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getData = async () => {
    const res = await getAllEmployeeCertificatesRecords(takeCount, skipCount);
    if (res?.status === 200) {
      setTableList(res.data);
      setTotalCount(res.totalCount);
    }
  };
  useEffect(() => {
    if (getRecordDate.startDate && getRecordDate.endDate) {
      getData();
    }
    getAllEmployeeData();
    getAllLkCertificationData();
  }, []);

  useEffect(() => {
    getData();
  }, [getRecordDate]);

  useEffect(() => {
    getData();
  }, [skipCount]);

  const getAllEmployeeData = async () => {
    const res = await getAllEmployeeRecords(null, null, takeEmployeeCount, skipEmployeeCount);
    if (res.status === 200) {
      setEmployeeList(res.data);
    }
  };

  const getAllLkCertificationData = async () => {
    const res = await getAllLkCertificationRecords();
    if (res.status === 200) {
      setLkCertificationList(res.data);
    }
  };

  const clearFields = () => {
    setDetail({
      employeeID: 0,
      certificationCode: 0,
      certificationSeq: '',
      validFrom: null,
      validTo: null,
      remarks: "",
      isActive: true,
    });
  };

  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <Header
        {...props}
        detail={detail}
        getData={getData}
        setDetail={setDetail}
      />
      </div>
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full page-content`}>
          <Card className="mt-3 mb-3">
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Employee Certification List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 page-body-wrapper">
                <RequestTable
                  {...props}
                  role={"admin"}
                  clearFields={clearFields}
                  requestDetail={tableList}
                  setSkipCount={setSkipCount}
                  totalCount={totalCount}
                  detail={detail}
                  setDetail={setDetail}
                  filterBy={detail}
                  getData={getData}
                  employeeList={employeeList}
                  LkCertificationList={LkCertificationList}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </div>
      </div>
      </Container>
    </>
  );
};

export default EmployeeCertification;
