import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import { Select } from "@mui/material";
import { Field } from "formik";
import { Col } from "reactstrap";
import { Button } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import { Row } from "antd";

const AddRecordFormStyles = makeStyles((theme) => ({
  MainHeading: {
    color: theme.palette.primary.main,
    marginBottom: "25px",
    marginLeft: "2px",
    fontWeight: "bold",
  },
  Subheading: {
    color: theme.palette.secondary.main,
    marginLeft: "2px",
    marginBottom: "8px",
  },
  ErrorForm: {
    color: "red",
    marginLeft: "2px",
    marginBottom: "8px",
  },
  Divider: {
    margin: "16px 0",
  },
  Button: {
    minWidth: "145px",
    fontSize: "1.4rem",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "90px",
    },
  },
  Icons: {
    fontSize: "2.5rem",
    marginLeft: "10px",
  },
  table: {
    minWidth: 650,
  },
  MainHeadingForTable: {
    color: theme.palette.primary.main,
    marginLeft: "20px",
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  },
  DividerForTable: {
    marginTop: 0,
  },
}));

const SaveNewQuery = (props) => {
  const classes = AddRecordFormStyles();
  const [role, setRole] = useState({
    name: "",
    id: "",
  });
  console.log("roles 456", role);
  return (
    <Card>
      <CardContent>
        {/* <Typography
            variant="h5"
            component="h5"
            className={classes.MainHeading}
          >
            Save New Queries
          </Typography> */}

        {/* {this.state.openSave && ( */}
        <Row>
          <Typography
            variant="h5"
            component="h5"
            style={{
              marginBottom: "16px",
              marginLeft: "2px",
              fontWeight: "bold",
              color: "#24695c",
            }}
          >
            Enter Query Details
          </Typography>
        </Row>
        <div className="mt-2 mb-5">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Field
                className="mb-3"
                as={TextField}
                label="Query Name"
                name="query_name"
                variant="outlined"
                fullWidth
              />
            </Col>
          </Row>

          <>
            {/* <Multiselect
              placeholder="Role"
              options={props.userGroup}
              // selectedValues={values.user_group_id}
              onSelect={props.onSelectUserGroup}
              onRemove={props.onRemoveUserGroup}
              displayValue="user_group_name"
            /> */}
            {console.log("userGroup", props.userGroup)}
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                value={"select-role"}
                size="small"
                onChange={(e) => props.onSelectUserGroup(e.target.value)}

                // onChange={handleChange}
              >
                <MenuItem value="select-role">Select Role</MenuItem>
                {props.userGroup?.map((i) => {
                  return (
                    <MenuItem value={i.user_group_name}>
                      {i.user_group_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        </div>
        {/* )} */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "4rem",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Button
              variant="contained"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
          <div>
            {console.log("values 1111 ::: ", props.values)}
            <Button
              variant="contained"
              className="bg-primary"
              style={{ color: "white" }}
              // type="submit"
              // onClick={() => {
              //   this.onSubmitClick();
              // }}
              onClick={() => {
                props.onSubmit(props.values);
              }}
            >
              Submit
            </Button>
          </div>
        </div>

        {/* )} */}
      </CardContent>
    </Card>
  );
};

export default SaveNewQuery;
