import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
import { Col, Label, Row } from "reactstrap";

// import MaskedInput from "react-text-mask";
function MilitaryStatusManage(props) {
  const { handleChange, values, errors, touched, setFieldValue } = props;
  return (
    <Row style={{ width: "100%" }} className="g-3">
      <Col md={6}>
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Military Code"
          value={values?.militaryCode?.trim()}
          onChange={(e) => setFieldValue("militaryCode", e.target.value)}
          name="militaryCode"
          id="militaryCode"
        />

        {!values?.militaryCode &&
          errors.militaryCode &&
          touched.militaryCode && (
            <p className="error">{errors.militaryCode}</p>
          )}

        {/* <Label className="labelColor">Department Code</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Department Code"
          name="eName"
          value={props.detail.code}
          onChange={(e) => {
            props.setDetail({ ...props.detail, code: e.target.value });
          }}
        /> */}
      </Col>
      <Col md={6}>
        <TextField
          margin="dense"
          className="flex w-full "
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label="Military Title"
          value={values?.militaryTitle?.trim()}
          onChange={handleChange}
          name="militaryTitle"
          id="militaryTitle"
        />
        {!values?.militaryTitle &&
          errors.militaryTitle &&
          touched.militaryTitle && (
            <p className="error">{errors.militaryTitle}</p>
          )}
        {/* <Label className="labelColor">Description</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Description"
          name="eName"
          value={props.detail.description}
          onChange={(e) => {
            props.setDetail({
              ...props.detail,
              description: e.target.value,
            });
          }}
        /> */}
      </Col>
    </Row>
  );
}

export default MilitaryStatusManage;
