import { TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";

const DetailSettings = (props) => {
  const { userDetail, changeDetails, state } = props;

  return (
    <Card>
      <CardHeader className="pb-0">
        <H5>Detail Settings </H5>
      </CardHeader>
      <CardBody>
        <Form>
          <Row className="g-3 mt-1">
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Acting Detail Code"
                value={userDetail.actingDetailCode}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "actingDetailCode", value: e.target.value },
                  })
                }
              />
              {/* <Label className="form-label">Acting Detail Code</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Acting Detail Code"
                value={userDetail.actingDetailCode}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "actingDetailCode", value: e.target.value },
                  })
                }
              /> */}
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Acting Detail Job Class"
                value={userDetail.actingDetailJobClass}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "actingDetailJobClass",
                      value: e.target.value,
                    },
                  })
                }
              />
              {/* <Label className="form-label">Acting Detail Job Class</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Acting Detail Job Class"
                value={userDetail.actingDetailJobClass}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "actingDetailJobClass",
                      value: e.target.value,
                    },
                  })
                }
              /> */}
            </Col>
            <Col md={4} xs={6} lg={4}>
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Acting Detail Hourly Rate"
                value={userDetail.actingDetailHourlyRate}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "actingDetailHourlyRate",
                      value: e.target.value,
                    },
                  })
                }
              />
              {/* <Label className="form-label">Acting Detail Hourly Rate</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Acting Detail Hourly Rate"
                value={userDetail.actingDetailHourlyRate}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "actingDetailHourlyRate",
                      value: e.target.value,
                    },
                  })
                }
              /> */}
            </Col>
          </Row>
          <Row className="g-3 mt-1">
            <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(userDetail.dateDeceased)}
                  label="Acting Detail Begin Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    changeDetails({
                      target: { name: "dateDeceased", value: value },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="form-label">Acting Detail Begin Date</Label>
              <input
                className="form-control"
                type="date"
                placeholder="Last Name"
                value={moment(userDetail.dateDeceased).format("yyyy-MM-DD")}
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "dateDeceased", value: value },
                  })
                }
              /> */}
            </Col>
            <Col md={4} xs={6} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(moment(state.actingDetailEndCode).format(
                    "yyyy-MM-DD"
                  ))}
                  label="Acting Detail End Date"
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    changeDetails({
                      target: { name: "actingDetailEndCode", value: value },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="form-label">Acting Detail End Code </Label>
              <input
                className="form-control"
                type="date"
                placeholder="Acting Detail End Code"
                value={moment(userDetail.actingDetailEndCode).format(
                  "yyyy-MM-DD"
                )}
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "actingDetailEndCode", value: value },
                  })
                }
              /> */}
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Separation Rate"
                value={state.priorLengthOfService}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "priorLengthOfService",
                      value: e.target.value,
                    },
                  })
                }
              />
              {/* <Label className="form-label">Separation Rate</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Separation Rate"
                value={userDetail.priorLengthOfService}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "priorLengthOfService",
                      value: e.target.value,
                    },
                  })
                }
              /> */}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default DetailSettings;
