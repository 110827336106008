import React, { useEffect, useRef, useState } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { TelerikCustomMenu } from "../../employee/component/telerikCustomMenu";
import { process } from "@progress/kendo-data-query";
import { IconButton, Tooltip } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";


const EarningDataTable = (props) => {
  let { totalCount, page, setPage } = props;
  const _export = useRef(null);
  const [pageSizeValue, setPageSizeValue] = useState();

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuFilter {...props} />
      </div>
    );
  };

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? totalCount : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  return (
    <ExcelExport data={props?.data} ref={_export}>
      <Grid
        style={{
          height: "700px",
        }}
        data={props?.data}
        skip={page.skip}
        take={page.take}
        total={totalCount}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15],
          pageSizeValue: pageSizeValue,
        }}
        onPageChange={pageChange}
        sortable={true}
        groupable={true}
        resizable={true}
        reorderable={true}
        columnMenu={ColumnMenu}
      >
        <GridToolbar>
          <Tooltip title="Export Excel" placement="bottom">
            <IconButton
              // className="bg-primary"
              // style={{ color: "white" }}
              aria-label="more"
              id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={excelExport}
            >
              <InsertDriveFileIcon />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Export PDF" placement="bottom">
            <IconButton
              aria-label="more"
              id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              // onClick={exportPDF}
              onClick={() => setPrintDataModal(true)}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip> */}
        </GridToolbar>
        <GridColumn field="employeeID" title="employeeID" />
        <GridColumn field="employeeName" title="Employee Name" />
        <GridColumn field="abberv" title="Pay Code" />
        <GridColumn
          cell={(props) => {
            return <td>{props.dataItem.totalMinutes / 60}</td>;
          }}
          title="Total Hours"
        />
        <GridColumn
          cell={(props) => {
            return <td>{`$ ${props.dataItem.totalAmount}`}</td>;
          }}
          title="Total Amount"
        />
      </Grid>
    </ExcelExport>
  );
};

export default EarningDataTable;
