import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "masterSlice";

export const fetchPayPeriodMaster = createAsyncThunk(
  `${sliceName}/fetchPayPeriodMaster`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}payperiod`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      let PPE = [];
      if (data.data.length) {
        data.data.map((item) => {
          PPE.push({
            ID: item.ID,
            value: item.ID,
            label: item.EndDate,
            StartDate: item.StartDate,
            EndDate: item.EndDate,
          });
          // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
        });
      }
      return {
        payperiods: PPE,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        payperiods: [],
      };
    }
  }
);

export const fetchPayGradeStep = createAsyncThunk(
  `${sliceName}/fetchPayGradeStep`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}payGradeStep`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        payGradeStep: data.data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        payGradeStep: [],
      };
    }
  }
);

export const fetchDeductionTypeMaster = createAsyncThunk(
  `${sliceName}/fetchDeductionTypeMaster`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}deductionType`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        deductionTypes: data.data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        deductionTypes: [],
      };
    }
  }
);

export const fetchEmployeeTypeMaster = createAsyncThunk(
  `${sliceName}/fetchEmployeeTypeMaster`,
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}employeetypes`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        employeeTypes: data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        employeeTypes: [],
      };
    }
  }
);
const surveySlice = createSlice({
  name: sliceName,
  initialState: {
    deductionTypes: [],
    payperiods: [],
    employeeTypes: [],
    payGradeStep: [],
  },
  extraReducers: {
    [fetchDeductionTypeMaster.fulfilled]: (state, action) => {
      state.deductionTypes = action.payload.deductionTypes;
    },
    [fetchPayPeriodMaster.fulfilled]: (state, action) => {
      state.payperiods = action.payload.payperiods;
    },
    [fetchEmployeeTypeMaster.fulfilled]: (state, action) => {
      state.employeeTypes = action.payload.employeeTypes;
    },
    [fetchPayGradeStep.fulfilled]: (state, action) => {
      state.payGradeStep = action.payload.payGradeStep;
    },
  },
});

export default surveySlice.reducer;
