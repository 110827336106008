import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../../app/utility/helper";
import LoadingPanel from "../../../Common/telerik/loadingPanel";

const visibleColumnArray = [
  "SSN",
  "ID",
  "EmployeeName",
  "Email",
  "Inactive_Status",
  "Emp_Status",
];

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [CSVPrint, setCSVPrint] = useState(visibleColumnArray);
  
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, loading } = props;
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data && data.EmployeeID
            ? data.EmployeeID.toLowerCase().includes(
                filterBy.EmployeeID?.toLowerCase()
              )
            : data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLowerCase()
          )
        );
      }
      if (filterBy?.SSN) {
        dataList = dataList.filter((data) =>
          data.SSN.toLowerCase().includes(filterBy.SSN.toLowerCase())
        );
      }
      if (filterBy?.Status) {
        dataList = dataList.filter((data) =>
          data.Status.toLowerCase().includes(filterBy.Status.toLowerCase())
        );
      }
      if (filterBy?.Department) {
        dataList = dataList.filter((data) =>
          data.Description
            ? data.Description
            : data.DPDV_DepartmentDivisionMaster.Description.toLowerCase().includes(
                filterBy.Department.toLowerCase()
              )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const options = {
    selectableRows: false,
    display: false,

    onViewColumnsChange: (changedColumn, action, option) => {
      if (action === "add") {
        setCSVPrint([...CSVPrint, changedColumn]);
      } else {
        setCSVPrint(CSVPrint.filter((i) => i !== changedColumn));
      }
    },
  };

  const employeeIssuesColumn = [
    {
      id: "Actions",
      name: "employeeID",
      label: "Actions",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(
                      `/admin/employee/update/${value}`
                    );
                  }}
                  icon={faUser}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      id: "employeeId",
      name: "employeeId",
      label: "Employee Id",
    },
    {
      id: "employeeName",
      name: "employeeName",
      label: "Employee Name",
    },
    {
      id: "description",
      name: "description",
      label: "Department",
    },
    {
      id: "employeeEIN",
      name: "employeeEIN",
      label: "EIN",
    },
    {
      id: "tradeName",
      name: "tradeName",
      label: "Trade Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : '-';
        },
      },
    },
    {
      id: "q1Wage",
      name: "q1Wage",
      label: "Q1 Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "q2Wage",
      name: "q2Wage",
      label: "Q2 Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "q3Wage",
      name: "q3Wage",
      label: "Q3 Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "q4Wage",
      name: "q4Wage",
      label: "Q4 Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "totalWage",
      name: "totalWage",
      label: "Total Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "socialSecurityTax",
      name: "socialSecurityTax",
      label: "Social security tax",
      options: {
        setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "150px" }}),
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      }
    },
    {
      id: "sickLeaveWage",
      name: "sickLeaveWage",
      label: "S/L Wage",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "federalTaxAmount",
      name: "federalTaxAmount",
      label: "Federal Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "medicareTax",
      name: "medicareTax",
      label: "Medicare Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
    {
      id: "Total_tax",
      name: "Total_tax",
      label: "Total Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(Number(value) ? Number(value) : 0);
        },
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {loading ?
        <div >
          <LoadingPanel></LoadingPanel>
        </div>
        :
        <MUIDataTable
          className={classes.customData}
          title={<Typography variant="h6">IRS Quarterly Report Data</Typography>}
          data={requestDetail}
          columns={employeeIssuesColumn}
          options={options}
          selectableRowsHideCheckboxes={true}
        />
      }
    </div>
  );
};

export default DataTable;