import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";

import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

export default function ColumnFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlseSelect = (option) => {
    console.log("option 123", option);
  };

  const handleRemoveColumn = async (field, showField) => {
    // This function used  to ordering fields
    let copyArray = [...props.columns];

    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field == field;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["show"] = showField;
      copyArray[objectAtIndex] = copyObject;
      props.setSortedColumns(copyArray);
    }
  };

  return (
    <>
      <div>
        <Tooltip title="Filter Column" placement="bottom">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ViewColumnIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              // width: "200px",
            },
          }}
        >
          {props?.columns?.map((option) => (
            <MenuItem
              style={{ backgroundColor: "white", width: "auto" }}
              key={option.field}
              // onClick={() => handlseSelect(option.title)}
              onClick={() => handleRemoveColumn(option.field, !option.show)}
            >
              <Checkbox
                checked={option.show}
                style={{ padding: "0", margin: "0" }}
              />
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div onClick={() => setAnchorEl(null)}></div>
    </>
  );
}
