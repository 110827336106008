import Typography from "@material-ui/core/Typography";
import React from "react";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import { addOrUpdateEmployeeDeductionPlanRecord } from "../../../../app/utility/axiosService";

function HomeHeader(props) {

  const checkvalid = () => {
    const { deductionType, sequence, deductionPlan, garnishmentMethod, employeeSeq, paymentMonthlySch, pctToDeduct, startDate, endDate, 
      deductionPlanStatus, totalOriginal, balanceDue, taxable, isActive, accountNoForVendor, applyToOT } = props.detail;
    if (deductionType == '' || !sequence || Number(deductionPlanStatus) == 0 || Number(deductionPlanStatus) == NaN ||  Number(deductionPlan) == 0 || Number(deductionPlan) == NaN || !accountNoForVendor || !applyToOT ||
    Number(garnishmentMethod) == 0 || Number(garnishmentMethod) == NaN || (taxable !== false && taxable !== true) || (isActive !== false && isActive !== true) ||
    Number(paymentMonthlySch) == 0 || Number(paymentMonthlySch) == NaN || !employeeSeq || !startDate || !endDate || pctToDeduct == '' || !totalOriginal || !balanceDue) {
      toast.error('Please validate values!');
      return false;
    } else {
      return true;
    }
  };

  const saveData = async () => {
    if (!checkvalid()) {
      return false;
    }

    let payload = {
      id: props.detail?.oldId??0,
      // employeeID: props.detail?.employeeID??0,
      employeeID: Number(props?.paramsData?.state?.employeeId),
      employeeSeq: Number(props.detail?.employeeSeq),
      deductionType: props.detail?.deductionType,
      deductionCode: props.detail?.deductionPlan,
      prioritySeq: Number(props.detail?.sequence),
      // garnishmentTypeId: props.detail?.garnishmentTypeId,
      deductionMethod: props.detail?.garnishmentMethod.toString(),
      paymentMonthlySch: props.detail?.paymentMonthlySch.toString(),
      startDate: new Date(props.detail?.startDate),
      endDate: new Date(props.detail?.endDate),
      deductionAmount: Number(props.detail?.pctToDeduct),
      accountNoForVendor: props.detail?.accountNoForVendor,
      deductionPlanStatus: props.detail?.deductionPlanStatus,
      // pctBasis: props.detail?.pctBasis,
      totalAmountPaid: Number(props.detail?.totalOriginal),
      totalAmountPlanDue: Number(props.detail?.balanceDue),
      applyToOT: Number(props.detail?.applyToOT),
      taxable: props.detail?.taxable,
      isActive: props.detail?.isActive,
      // paidToDate: props.detail?.paidToDate,
    };

    const res = await addOrUpdateEmployeeDeductionPlanRecord(payload);
    if (res.status === 200) {
      toast.success('Record Updated.');
      props.getData();
      props.clearFields();
    } else if (res?.status === 208) {
      toast.warn('Sequence Already Existed');
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <CardHeader className="mb-3">
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">{
            props?.defaultDeductionType === 'GARNISHMENT' ? 'Garnishment' : 
            props?.defaultDeductionType === 'BENEFITS' ? 'Benefits' :
            props?.defaultDeductionType === 'MISCELLANEOUS' ? 'Miscellaneous' :
            props?.defaultDeductionType === 'RETIREMENT-PLANS' ? 'Retirement-Plan' : ''
          }</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          {props?.isDeductionDetailsShow &&
            <>
              <Button
                variant="contained"
                className="m-10 mx-2 xs:mx-10 min-w-0"
                style={{
                  color: "#234d10",
                  backgroundColor: "#ffffff",
                }}
                onClick={saveData}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ color: "#24695c", marginRight: "10px" }}
                />
                <Hidden smDown>
                  <Typography className="text-12">
                    {" "}
                    {props.detail.oldId ? "Update" : "Save"}{" "}
                  </Typography>
                </Hidden>
              </Button>
              {props.detail.oldId ? (
                <Button
                  variant="contained"
                  className="m-10 mx-2 xs:mx-10 min-w-0"
                  style={{
                    color: "#d0efe9",
                    backgroundColor: "#61bdaa",
                  }}
                  onClick={props.clearFields}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    style={{ color: "#24695c", marginRight: "10px" }}
                  />
                  <Hidden smDown>
                    <Typography className="text-12"> Cancel </Typography>
                  </Hidden>
                </Button>
              ) : null}
            </>
          }
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;