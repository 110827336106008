import React, { Fragment, useEffect, useRef, useState } from "react";

import { Card, CardBody } from "reactstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Typography, AppBar, Tabs, Tab, Container } from "@mui/material";
import AddForm from "./Components/AddForm";
// import CardContent from "@mui/material";
import ReportLayout from "./Components/ReportLayout";
import ReportChartLayout from "./Components/ReportChartLayout";
// import { makeStyles } from "@mui/material/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faReceipt,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";

import { useDispatch } from "react-redux";
import {
  getDeductionType,
  getOverAllDeduction,
  getOverAllEarning,
  getVendor,
} from "../../../app/utility/axiosService";
import { fetchDeductionTypeMaster } from "../../../app/store/reducers/slices/masterSlice";
import ReportHeader from "../ReportHeader";
import DeductionReportAdvanceSearch from "./DeductionReportAdvanceSearch";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     ".&.MuiAccordionSummary-content": {
//       padding: 0,
//     },
//     margin: "0 !important",
//     borderTopWidth: 1,
//     borderTopColor: "#ccc",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "33.33%",
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "33.33%",
//     flexShrink: 0,
//     // color: theme.palette.text.secondary,
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "33.33%",
    fontWeight: 500,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));
const DeductionReport = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const classes = useStyles();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({
    Code: "",
    VendorCode: "",
    Description: "",
    EmployeerFee: "",
    EmployeeFee: "",
    IsPercent: "",
    StartDate: null,
    EndDate: null,
    DeductionType: [],
    SelectedEmployee: [],
    DepartmentDivision: [],
    PayTimeCode: [],
  });

  const [vendors, setVendors] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [value, setValue] = useState(0);

  const pageLayout = useRef(null);
  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}deductionPlan`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getData();
    (async () => {
      setDeductionTypes(await getDeductionType());
      setVendors(await getVendor());
      setDeduction(await getOverAllDeduction());
      setEarning(await getOverAllEarning());
    })();
    dispatch(fetchDeductionTypeMaster());
  }, []);

  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
        
      <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
        <ReportHeader {...props} header="Deduction Summary Reports" />
        </div>
        <div className="w-100 h-full mt-3 " ref={pageLayout} innerScroll>
          <div className={`${classes.root} w-100 page-content`}>
            <Card className="mb-3">
              <DeductionReportAdvanceSearch />
              {/* <Accordion
                className={classes.root}
                expanded={expand.panel1}
                onChange={handleChange("panel1")}
                >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="bg-gray-200 sub-header"
                >
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    className="sub-header"
                    style={{
                      color: "#24695c",
                      marginRight: "10px",
                      marginTop: "3px",
                    }}
                  />
                  <Typography className={classes.heading}>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 page-body-wrapper">
                  <div className="card-body">
                    <AddForm
                      detail={detail}
                      vendors={vendors}
                      deductionTypes={deductionTypes}
                      setDetail={setDetail}
                    />
                  </div>
                </AccordionDetails>
              </Accordion> */}
            </Card>

            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    color: "#24695c",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>Report</Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0 CardBack">
                <div className="w-100 mt-2">
                  <AppBar position="static" color="default">
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#26695c",
                        },
                      }}
                      value={value}
                      onChange={(e, val) => {
                        setValue(val);
                      }}
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab
                        className="tab-wrapper flex body-Color2"
                        indicatorColor="border-light"
                        icon={
                          <FontAwesomeIcon
                            icon={faReceipt}
                            className=" body-Color2"
                            style={{
                              color: "#1569C7",
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        }
                        label="Data Report"
                      />
                      <Tab
                        className="tab-wrapper flex   body-Color2"
                        icon={
                          <FontAwesomeIcon
                            className="body-Color2"
                            icon={faChartBar}
                            style={{
                              color: "#1569C7",
                              marginRight: "10px",
                              marginTop: "5px",
                            }}
                          />
                        }
                        label="Analytic Report"
                      />
                    </Tabs>
                  </AppBar>

                  {value === 0 ? (
                    <div className="w-full">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        {/* <FontAwesomeIcon
                          icon={faFilePdf}
                          style={{
                            color: "#1569C7",
                            marginRight: "20px",
                            marginTop: "20px",
                            scale: "2",
                          }}
                          onClick={handlePdfPrint}
                        /> */}
                      </div>

                      <ReportLayout
                        {...props}
                        role={"admin"}
                        deduction={deduction}
                        earning={earning}
                      />
                    </div>
                  ) : (
                    value === 1 && (
                      <div className="">
                        <ReportChartLayout
                          deduction={deduction}
                          earning={earning}
                        />
                      </div>
                    )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      
    </Container>
  );
};

export default DeductionReport;
