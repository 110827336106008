import { TextField } from "@mui/material";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Media,
} from "reactstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

export const basicSchema = yup.object().shape({
  nPositionNo: yup
    .string()
    .min(1, "Must be 1 character or less")

    .nullable()
    .default(undefined)
    .required("Enter nposition No."),

  title: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter  Title"),

  jobColler: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter JobColler"),

  rangeCurrent: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter range current"),
  rangeNext: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter rangenext"),
  termId: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter TermId"),

  passId: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter PassId"),

  UPDDate: yup
    .date()
    // .max(20, "Must be 20 characters or less")

    .nullable()
    .default(undefined)
    .required("Enter UPD date"),

  NUPDDate: yup
    .date()
    // .max(20, "Must be 20 characters or less")

    .nullable()
    .default(undefined)
    .required("Enter NUPD date"),

  NRVar: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter NRVar"),

  CLASS: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter Class"),

  catagory: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter Category"),

  exemptNon: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter exemptNon"),

  OCCSeries: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter OCCSerires"),

  TDP: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter TDP"),

  classified: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter classified"),

  jobOrder: yup
    .string()
    // .max(20, "Must be 20 characters or less")
    .min(1, "Must be 1 character or less")
    .nullable()
    .default(undefined)
    .required("Enter Job Order"),
});

const PayStepManage = forwardRef((props, ref) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const [topLevelDark, setTopLevelDark] = React.useState(false);

  const initialState = {
    id: null,
    nPositionNo: "",
    title: "",
    jobColler: "",
    rangeCurrent: "",
    rangeNext: "",
    termId: "",
    passId: "",
    UPDDate: null,
    NUPDDate: null,
    NRVar: "",
    CLASS: "",
    catagory: "",
    exemptNon: "",
    OCCSeries: "",
    TDP: "",
    classified: "",
    jobOrder: "",
  };

  const submitCall = () => {
    handleSubmit();
  };

  useImperativeHandle(ref, () => ({
    submitCall: () => handleSubmit(),
    setValues: (data) => {
      console.log("data123", data);
      setValues(data ? data : initialState);
    },
  }));

  const onSubmit = (e) => {
    let paypload = {
      NPOSITION_NO: e.nPositionNo,
      TITLE: e.title,
      JOB_COLLAR: e.jobColler,
      RANGE_CURRENT: e.rangeCurrent,
      RANGE_NEXT: e.rangeNext,
      TERM_ID: e.termId,
      PASS_ID: e.passId,
      UPD_DATE: e.UPDDate,
      NUPD_DATE: e.NUPDDate,
      NR_VAR: e.NRVar,
      CLASS: e.CLASS,
      CATAGORY: e.catagory,
      EXEMPT_NON: e.exemptNon,
      OCC_SERIES: e.OCCSeries,
      TDP: e.TDP,
      CLASSIFIED: e.classified,
      JOB_ORDER: e.jobOrder,
    };
    if (e.id) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}nPosition/update/${values.id}`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          props.getData();

          setValues(initialState);
          toast.success(response.data.message);

          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
          toast.error(error.message);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}nPosition/create`, paypload, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        })
        .then((response) => {
          props.getData();

          setValues(initialState);
          toast.success(response.data.message);

          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
          toast.error(error.message);
        });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: props.detail,
    onSubmit: onSubmit,
    validationSchema: basicSchema,
    // onSubmit,
  });

  console.log("top-------", props.detail);
  return (
    <div className="w-full h-full">
      <div className="flex flex-col sm:flex-row w-full py-4 mt-10 ">
        <Form>
          <Row className="g-3  ">
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="nPositionNo"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="NPosition No"
                value={values?.nPositionNo}
                onChange={handleChange}
              />
              {errors.nPositionNo && touched.nPositionNo && (
                <p className="error">{errors.nPositionNo}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="title"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Title"
                value={values?.title}
                onChange={handleChange}
              />
              {errors.title && touched.title && (
                <p className="error">{errors.title}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="jobColler"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="JOb Coller"
                value={values?.jobColler}
                onChange={handleChange}
              />
              {errors.jobColler && touched.jobColler && (
                <p className="error">{errors.jobColler}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="rangeCurrent"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Range Current"
                value={values?.rangeCurrent}
                onChange={handleChange}
              />
              {errors.rangeCurrent && touched.rangeCurrent && (
                <p className="error">{errors.rangeCurrent}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="rangeNext"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Range Next"
                value={values?.rangeNext}
                onChange={handleChange}
              />
              {errors.rangeNext && touched.rangeNext && (
                <p className="error">{errors.rangeNext}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="termId"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Term Id"
                value={values?.termId}
                onChange={handleChange}
              />
              {errors.termId && touched.termId && (
                <p className="error">{errors.termId}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="passId"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Pass Id"
                value={values?.passId}
                onChange={handleChange}
              />
              {errors.passId && touched.passId && (
                <p className="error">{errors.passId}</p>
              )}
            </Col>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  label="UPD Date"
                  value={values?.UPDDate}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    setFieldValue("UPDDate", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
                {errors.UPDDate && touched.UPDDate && (
                  <p className="error">{errors.UPDDate}</p>
                )}
              </LocalizationProvider>
            </Col>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  label="NUPD Date"
                  value={values?.NUPDDate}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    setFieldValue("NUPDDate", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
                {errors.NUPDDate && touched.NUPDDate && (
                  <p className="error">{errors.NUPDDate}</p>
                )}
              </LocalizationProvider>
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="NRVar"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="NR Var"
                value={values?.NRVar}
                onChange={handleChange}
              />
              {errors.NRVar && touched.NRVar && (
                <p className="error">{errors.NRVar}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="CLASS"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="CLASS"
                value={values?.CLASS}
                onChange={handleChange}
              />
              {errors.CLASS && touched.CLASS && (
                <p className="error">{errors.CLASS}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="catagory"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Catagory"
                value={values?.catagory}
                onChange={handleChange}
              />
              {errors.catagory && touched.catagory && (
                <p className="error">{errors.catagory}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="exemptNon"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Exempt Non"
                value={values?.exemptNon}
                onChange={handleChange}
              />
              {errors.exemptNon && touched.exemptNon && (
                <p className="error">{errors.exemptNon}</p>
              )}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="OCCSeries"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="OCC Series"
                value={values?.OCCSeries}
                onChange={handleChange}
              />
              {errors.OCCSeries && touched.OCCSeries && (
                <p className="error">{errors.OCCSeries}</p>
              )}
            </Col>

            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="classified"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "11px",
                  },
                }}
                label="Classified"
                value={values?.classified}
                onChange={handleChange}
              />
              {errors.classified && touched.classified && (
                <p className="error">{errors.classified}</p>
              )}
            </Col>
            <Col md="2">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="TDP"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="TDP"
                value={values?.TDP}
                // defaultValue={""}
                onChange={handleChange}
              />
              {errors.TDP && touched.TDP && (
                <p className="error">{errors.TDP}</p>
              )}
            </Col>
            <Col md="1">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="jobOrder"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Job Order"
                value={values?.jobOrder}
                onChange={handleChange}
              />
              {errors.jobOrder && touched.jobOrder && (
                <p className="error">{errors.jobOrder}</p>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
});

export default PayStepManage;
