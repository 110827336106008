import React, { useEffect } from "react";
import { Switch } from "@material-ui/core";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import ToggleButton from "@mui/material/ToggleButton";
import { H5 } from "../../../AbstractElements";
import moment from "moment";
import { CardContent, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchEmployeeDeductionPlan } from "../../../app/store/reducers/slices/employeeSlice";
import ChipsDeduction from "./ChipsDeduction";

function DeductionSettings(props) {
  const { userDetail, changeDetails, userInfo } = props;
  const [selected, setSelected] = React.useState(false);
  console.log(userDetail.convertANLLVtoRtrmt === "Y");
  console.log("changeDetails", changeDetails);
  const empDuductionPlans = useSelector(
    ({ employeeSlice }) => employeeSlice.empDuductionPlans
  );

  console.log("empDuductionPlans", empDuductionPlans);

  const fnSubscribe = (subscribe, DeductionCode) => {
    console.log("subscribe", subscribe);

    if (subscribe == "add") {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}employeeDeduction/subscribe`,
          {
            DeductionCode: DeductionCode,
            DeductionDate: "2022-01-05",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(() => {})
        .catch(() => {});
    } else if (["active", "inactive"].includes(subscribe)) {
      let url = `${process.env.REACT_APP_API_URL}employeeDeduction/deactivate/${DeductionCode}`;
      if (subscribe == "active") {
        url = `${process.env.REACT_APP_API_URL}employeeDeduction/activate/${DeductionCode}`;
      }
      axios
        .put(
          `${url}`,
          { deductionCode: DeductionCode },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(() => {})
        .catch(() => {});
    } else {
    }
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEmployeeDeductionPlan());
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="pb-0">
          <H5>Deduction Settings </H5>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <Table>
              <table
                className="table100 "
                style={{ margin: "20px 0px", width: "100%" }}
              >
                <thead bg-primary>
                  <tr
                    style={{
                      // backgroundColor: "#dcdcea",
                      fontSize: "16px",
                      width: "100%",
                    }}
                    className="body-Color2"
                  >
                    <th className="tl1">Vendor Code</th>
                    <th className="tl1">Deduction Name</th>
                    <th className="tl1">Deduction Type</th>
                    <th className="tl1">Emp Contribution</th>
                    <th className="tl1">Gov Contribution</th>
                    <th className="tl1">Subscribed</th>
                  </tr>
                </thead>
                <tbody>
                  {empDuductionPlans.length
                    ? empDuductionPlans.map((i, idx) => {
                        return (
                          <tr
                            id={`a${idx}`}
                            style={{
                              borderBottomColor: "#EAEBE4",
                              borderBottomWidth: "1px",
                            }}
                          >
                            <td className="tl1">{i.VendorCode}</td>
                            <td className="tl1">{i.DeductionName}</td>
                            <td className="tl1">{i.DeductionType}</td>
                            <td className="tl1">{i.EmpContribution}</td>
                            <td className="tl1">{i.GovContribution}</td>
                            <td className="tl1">
                              <Switch
                                defaultChecked={i.subscribed ? true : false}
                                onChange={(event) => {
                                  const value = event.target.checked
                                    ? "Y"
                                    : "N";
                                  console.log(
                                    "subscribe DeductionCode--->",
                                    i.DeductionCode
                                  );
                                  if (!i.SubscribedOn) {
                                    fnSubscribe("add", i.DeductionCode);
                                  } else {
                                    if (i.subscribed == 1) {
                                      fnSubscribe("inactive", i.DeductionCode);
                                    } else {
                                      fnSubscribe("active", i.DeductionCode);
                                    }
                                  }

                                  // changeDetails({
                                  //   target: { name: "convertANLLVtoRtrmt", value: value },
                                  // });
                                }}
                                color="primary"
                                className="align-top"
                              />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {/* Deduction Gross Total */}
                  {/* <tr style={{ backgroundColor: "#1975D2" }}>
                <td
                  className="tl1"
                  style={{
                    padding: "8px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  Gross Deduction
                </td>
                <td colSpan={4}>
                   
                </td>
              </tr> */}
                  {/* Deduction Gross Total Ends*/}
                </tbody>
              </table>
            </Table>
          </div>

          <Row className="g-3 mt-1">
            {/* <Col md="3">
              <button
                className={
                  userDetail.convertANLLVtoRtrmt === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.convertANLLVtoRtrmt === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "convertANLLVtoRtrmt", value: value },
                  });
                }}
              >
                Convert ANLLV to Rtrmt
              </button>
            
            </Col> */}
            {/* <Col md="3">
              <button
                className={
                  userDetail.supervisor === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.supervisor === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "supervisor", value: value },
                  });
                }}
              >
                Supervisor
              </button>
              
            </Col> */}
            {/* <Col md={3} xs={6} lg={3}>
              <button
                className={
                  userDetail.mailFinalCheck === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                // style={userDetail.mailFinalCheck === "Y" ? "notSelected" : select}
                value={userDetail.mailFinalCheck === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "mailFinalCheck", value: value },
                  });
                }}
              >
                Mail Final Check
              </button>
            
            </Col> */}
            {/* <Col md="3">
              <button
                className={
                  userDetail.deductMedicare === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.deductMedicare === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "deductMedicare", value: value },
                  });
                }}
              >
                Deduct Medicare
              </button>
           
            </Col> */}
          </Row>
          <Row className="g-3 mt-1">
            {/* <Col md="3">
              <button
                className={
                  userDetail.holdCheckforClearance === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.holdCheckforClearance === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "holdCheckforClearance", value: value },
                  });
                }}
              >
                Hold Check for Clearance
              </button>
               
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.suspended === "Y" ? "notSelected" : "selectDefault"
                }
                value={userDetail.suspended === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "suspended", value: value },
                  });
                }}
              >
                Suspended
              </button>
              
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.authorizedOverageAL === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.authorizedOverageAL === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "authorizedOverageAL", value: value },
                  });
                }}
              > 
                Authorized Overage AL
              </button>
             
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.eligibleForNightDiff === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.eligibleForNightDiff === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "authorizedOverageAL", value: value },
                  });
                }}
              >
                Eligible For Night Diff
              </button>
              
            </Col> */}
          </Row>
          <Row className="g-3 mt-1">
            {/* <Col md="3">
              <button
                className={
                  userDetail.cTAAgreement === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.cTAAgreement === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "cTAAgreement", value: value },
                  });
                }}
              >
                CTA Agreement
              </button>
           
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.cafeteriaPlan === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.cafeteriaPlan === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "cafeteriaPlan", value: value },
                  });
                }}
              >
                Cafeteria Plan
              </button>
             
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.nurseCertificationDiff === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.nurseCertificationDiff === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "nurseCertificationDiff", value: value },
                  });
                }}
              >
                Nurse Certification Diff
              </button>
               
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail._457ACADeduction === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail._457ACADeduction === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "_457ACADeduction", value: value },
                  });
                }}
              >
                457 ACA Deduction
              </button>
              
            </Col> */}
          </Row>

          <Row>
            {/* <Col md="3">
              <button
                className={
                  userDetail.demandRunNetToMisc === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.demandRunNetToMisc === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "demandRunNetToMisc", value: value },
                  });
                }}
              >
                Demand Run Net To Misc
              </button>
          
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.longTermDisabilityDC === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.longTermDisabilityDC === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "demandRunNetToMisc", value: value },
                  });
                }}
              >
                Long Term Disability DC
              </button>
             
            </Col> */}
            {/* <Col md={3}>
              <button
                className={
                  userDetail.dCRetireeReEmployed === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.dCRetireeReEmployed === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "dCRetireeReEmployed", value: value },
                  });
                }}
              >
                DC Retiree Re-Employed
              </button>
            
            </Col> */}
            <Col md={4}>
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Retirement Plan Option"
                value={userDetail.retirementPlanOption}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "retirementPlanOption",
                      value: e.target.value,
                    },
                  })
                }
              />
            </Col>

            {/* <Col md="3" style={{ marginTop: "40px" }}>
              <button
                className={
                  userDetail.deductRetirement === "Y"
                    ? "notSelected"
                    : "selectDefault"
                }
                value={userDetail.deductRetirement === "Y"}
                onClick={(event) => {
                  const value = event.target.checked ? "Y" : "N";
                  changeDetails({
                    target: { name: "deductRetirement", value: value },
                  });
                }}
              >
                Deduct Retirement
              </button>
         
            </Col> */}

            <Col md={4}>
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Tax Code"
                value={userDetail.taxCode}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "taxCode", value: e.target.value },
                  })
                }
              />
              {/* <label style={{ width: "75%" }}>Tax Code</label>

                <Input
                  type="text"
                  value={userDetail.taxCode}
                  onChange={(e) =>
                    changeDetails({
                      target: { name: "taxCode", value: e.target.value },
                    })
                  }
                /> */}
            </Col>
            <Col md={4}>
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Number Of Exemptions"
                value={userDetail.numberOfExemptions}
                onChange={(e) =>
                  changeDetails({
                    target: {
                      name: "numberOfExemptions",
                      value: e.target.value,
                    },
                  })
                }
              />
              {/* <label style={{ width: "75%" }}>Number Of Exemptions</label>
                <Input
                  value={userDetail.numberOfExemptions}
                  onChange={(e) =>
                    changeDetails({
                      target: {
                        name: "numberOfExemptions",
                        value: e.target.value,
                      },
                    })
                  }
                /> */}
            </Col>

            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Additional Tax"
                value={userDetail.additionalTax}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "additionalTax", value: e.target.value },
                  })
                }
              />
              {/* <label style={{ width: "75%" }}>Additional Tax</label>
                <Input
                  type="text"
                  value={userDetail.additionalTax}
                  onChange={(e) =>
                    changeDetails({
                      target: { name: "additionalTax", value: e.target.value },
                    })
                  }
                /> */}
            </Col>
            <Col md={6}>
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Retro Pay"
                value={userDetail.retroPay}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "retroPay", value: e.target.value },
                  })
                }
              />
              {/* <label style={{ width: "75%" }}>Retro Pay</label>
                <Input
                  value={userDetail.retroPay}
                  onChange={(e) =>
                    changeDetails({
                      target: { name: "retroPay", value: e.target.value },
                    })
                  }
                /> */}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={12}>
              <ChipsDeduction userInfo={userInfo} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/*  
         
       
        </div>
        <div className="flex flex-col sm:flex-row w-full py-4">
          <div className="flex w-full">
            <Card className="flex justify-start mb-10 text-center" elevation={0}>
             
            </Card>
          </div>
          <div className="flex w-full">
            <Card className="flex justify-start mb-10 text-center" elevation={0}>
             
            </Card>
          </div>
          <div className="flex w-full">
            
          </div>
          <div className="flex w-full">
            
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full py-4">
          <div className="flex w-full">
           
          </div>
          <div className="flex w-full">
           
          </div>
        </div>
      </div> */}
    </>
  );
}

export default DeductionSettings;
