import React, { useState, useEffect } from "react";
import classes from "./qrScan.module.css";
// import NavHeader from "../../components/header/NavHeader";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header1 from "./header1";
// import Loading from "../../components/loading/Loading";
import { useDispatch, useSelector } from "react-redux";
// import { Offline } from "react-detect-offline";

import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { Container } from "@mui/material";

const QRCode = (props) => {
  // const user = useSelector((state) => state.app.userObject);

  useEffect(() => {
    // if (!user) {
    //   return navigate("/");
    // }
  }, []);

  // const { formId } = route.params;
  // const { needToUpdate } = route.params;
  const [isLoading, setIsLoading] = useState(false);

  // const frontCam = { exact: "user" };
  const frontCam = "user";

  const backCam = "environment";
  // facingMode: 'user',
  // facingMode: 'environment',

  const [selected, setSelected] = useState(backCam);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleError = (err) => {
    console.error(err);
  };

  const handleClick = () => {
    if (selected === backCam) {
      setSelected(frontCam);
    } else {
      setSelected(backCam);
    }
    console.log("sddsvd");
  };

  let previ = {
    height: "200px",
    width: "200px",
    display: "flex",
    "justify-content": "ceneter",
  };

  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
    
      <Header1 />
      </div>
      <div className="page-content">
      {/* <MobileView>
        <h1>This is rendered only on mobile</h1>
      </MobileView> */}

      <div className="row background" style={{ minHeight: "100vh" }}>
        <div className="col-xl-2"></div>
        <div className="col-xl-8">
          <div className={classes.subcontainer}>
            <div className={classes.container}>
              {/* <Online
                onChange={(status) => {
                  console.log("online ", status);

                  if (status) {
                    localStorage.setItem("timestamp", "");
                  } else {
                    // if (surveysSaved) setSurveys(surveysSaved);

                    const current_timestamp = Date.now();
                    localStorage.setItem(
                      "timestamp",
                      String(current_timestamp)
                    );
                  }
                }}
              >
                You're online!
              </Online> */}
              {/* <Offline>You're offline!</Offline> */}
              <br />
              <br />
              {/* {isLoading && (
                <div className={classes.nodata}>
                  <Loading />
                </div>
              )} */}
              {!isLoading && (
                <div className={classes.nodata}>
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      border: "2px solid black",
                      display: "inline-flex",
                    }}
                  >
                    <QrReader
                      // facingMode={selected}
                      constraints={{
                        facingMode: selected,
                      }}
                      // facingMode={selected}
                      delay={500}
                      className={classes.scanbox}
                      scanDelay={1000}
                      onError={handleError}
                      style={previ}
                      videoStyle={{
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        height: "100%",
                        display: "block",
                        overflow: "hidden",
                        position: "absolute",
                        transform: "scaleX(-1)",
                        objectFit: "cover",
                      }}
                      //  containerStyle={ { margin :"10px",border:"2px solid red" } }
                      containerStyle={{
                        border: "1px red solid",
                        margin: "10px",
                      }}
                      onResult={(result, error) => {
                        if (error) {
                          return false;
                        }
                        if (result) {
                          let url = `${process.env.REACT_APP_REDIRECT_URL}admin/employee/update/${result}`;
                          console.log("ssss", url);
                          // navigate(url);
                          window.location.href = url;
                        }
                      }}
                    />
                  </div>
                  {/* <p style={{ marginTop: "20px" }}>
                    <b>Scan data is {scanData}</b>
                  </p> */}
                </div>
              )}
              <div className="mt-4" style={{ textAlign: "center" }}>
                <div>
                  <button
                    style={{ background: "none", border: "none" }}
                    onClick={handleClick}
                  >
                    <CameraswitchIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2"></div>
        </div>
      </div>
    </div>
    </Container>
  );
};

export default QRCode;
