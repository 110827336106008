import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterList from "./filterList";
import TableComponent from "../../DeductionPlanMaster/tableComponent";

import { useDispatch } from "react-redux";
import moment from "moment";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, changeHeaderFilter, role } = props;

  const [filterBy, setFilter] = useState({
    militaryCode: "",
    militaryTitle: "",
  });

  const changeHeaderFilter1 = (event) => {
    let filtered = [];
    const { name, value } = event.target;
    console.log("ON CLICK h1 : ", name, value);
    setFilter({ ...filterBy, [name]: value });
  };

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.militaryCode) {
        dataList = dataList.filter((data) =>
          data.Military_Code.toLowerCase().includes(
            filterBy.militaryCode?.toLowerCase()
          )
        );
      }

      if (filterBy?.militaryTitle) {
        dataList = dataList.filter((data) =>
          data.Military_Title.toLowerCase().includes(
            filterBy.militaryTitle.toLocaleLowerCase()
          )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      id: data.Military_Code,
      militaryCode: data.Military_Code,
      militaryTitle: data.Military_Title,
    };

    props.setDetail(info);
  };

  const columns = [
    {
      id: "Military_Code",
      name: "Military_Code",
    },
    { id: "Military_Title", name: "Military_Title" },
  ];

  return (
    <TableComponent
      columns={columns}
      filteredList={filteredList}
      onRowClick={handleRowClick}
      tableClass={classes.scrollHeight}
      filterComponent={
        filteredList && (
          <FilterList
            changeHeaderFilter={changeHeaderFilter1}
            role={role}
            requestDetail={requestDetail}
            filterBy={filterBy}
          />
        )
      }
    />
  );
};

export default DataTable;
