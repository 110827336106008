import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { Bell, Maximize } from "react-feather";
import { LI, UL } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import Bookmarks from "./Bookmark";
import LanguageClass from "./Language";
import LogoutClass from "./Logout";
import MessageDrop from "./MessageDrop";
import MoonLight from "./MoonLight";
import Notifications from "./Notifiations";
import { Clock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import moment from "moment";

const Rightbar = () => {
  const { sidebarResponsive } = useContext(CustomizerContext);
  const navigate = useNavigate();
  const { userData } = useAuth();
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);

  const existingTimeSaved = localStorage.getItem("timeClockStartHours");

  console.log("Daswdasasdada", existingTimeSaved);

  const calculateTimeDifference = (existingTime, endDateTime) => {
    const finalExistingDate = existingTime.date;
    const start = new Date(finalExistingDate);
    const end = new Date(endDateTime);

    const timeDiff = end.getTime() - start.getTime();
    const seconds = Math.floor(timeDiff / 1000) % 60;
    const minutes = Math.floor(timeDiff / (1000 * 60)) % 60;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));

    return {
      hours,
      minutes,
      seconds,
      date: existingTime?.date,
      isRunning: existingTime.isRunning,
      isPause: existingTime?.isPause,
      isResume: existingTime?.isResume,
    };
  };
  function getCurrentTime() {
    const now = new Date();
    return {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
      date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      isRunning: true,
      isPause: false,
    };
  }
  useEffect(() => {
    console.log("dasdkjdkda TimeClocl");
    if (existingTimeSaved) {
      const newTime = calculateTimeDifference(
        JSON.parse(existingTimeSaved),
        getCurrentTime()?.date
      );

      let isPauseData = JSON.parse(existingTimeSaved);

      if (isPauseData?.isPause || isPauseData?.isResume) {
        setTime(isPauseData);
        setIsRunning(isPauseData.isRunning);
      } else {
        setTime(newTime);
        setIsRunning(newTime?.isRunning);
      }
    } else {
      setIsRunning(false);
      setTime({ hours: 0, minutes: 0, seconds: 0 });
    }
  }, [localStorage.getItem("timeClockStartHours")]);
  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const seconds = prevTime.seconds + 1;
          const minutes = prevTime.minutes + Math.floor(seconds / 60);
          const hours = prevTime.hours + Math.floor(minutes / 60);

          return {
            hours: hours,
            minutes: minutes % 60,
            seconds: seconds % 60,
          };
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);
  const userRole = getUserRole(userData);
  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const handleTimeClick = () => {
    navigate("/time-clock");
  };
  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu p-0">
        <UL
          attrUL={{
            className: `simple-list d-flex flex-row nav-menus ${
              sidebarResponsive ? "open" : ""
            }`,
          }}
        >
          {getUserRole(userData) == "user" && existingTimeSaved ? (
            <LI>
              <div
                className="timerbg"
                title="Working Hours"
                style={{ padding: 0 }}
              >
                <span>{time.hours.toString().padStart(2, "0")}</span>:
                <span>{time.minutes.toString().padStart(2, "0")}</span>:
                <span>{time.seconds.toString().padStart(2, "0")}</span>
              </div>
            </LI>
          ) : null}
          {userRole === "user" ? (
            <LI>
              <a className="text-dark" onClick={handleTimeClick}>
                <Clock />
              </a>
            </LI>
          ) : (
            ""
          )}
          <LI>
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Maximize />
            </a>
          </LI>
          {/* <LanguageClass /> */}
          {/* <Bookmarks /> */}
          {/* <Clock size={18}/> */}

          <Notifications />

          <MoonLight />
          {/* <MessageDrop /> */}
          <LogoutClass />
        </UL>
      </div>
    </Fragment>
  );
};

export default Rightbar;
