import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { IconButton, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ShowError, ShowSucess } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { getUserId, getUserRole } from "../../../app/utility/helper";
import TimeSheetHeader from "./header";

const inputProps = {
  style: { fontSize: 14, step: 300 },
  step: 300,
};
function TimeSheetTemplateDetails() {
  const location = useLocation();
  const history = useNavigate();

  const { userData } = useAuth();
  const userId = getUserId(userData);
  const userRole = getUserRole(userData);

  const [weekDayData, setWeekDayData] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [inputFields, setInputFields] = useState([
    {
      selectedDay: "",
      time_from: "",
      time_to: "",
    },
  ]);

  const [templateTitle, setTemplateTitle] = useState();
  const [templateDetails, setTemplateDetails] = useState();

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        selectedDay: "",
        time_from: "",
        time_to: "",
      },
    ]);
  };

  const getTemplateDetails = (tempID) => {
    // setIsLoading(true);
    // console.log(templateId, props.templateId);
    console.log("123123 details ::: ", tempID);

    axios
      .get(`${process.env.REACT_APP_API_URL}timesheet/get/${tempID}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // setIsLoading(false);
        console.log("Response 121212::: ", response.data);
        // let newArray = response.data.result[0].TimeSheetProfiles.sort(
        //   (a, b) => {
        //     const dayA = daysOfWeek.indexOf(a.Day);
        //     const dayB = daysOfWeek.indexOf(b.Day);
        //     return dayA - dayB;
        //   }
        // );
        setTemplateDetails(response.data.result[0]);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getTemplateDetails(
      location?.state?.TimeSheetID
        ? location?.state?.TimeSheetID
        : location?.state?.ID
    );
    // getTemplateDetails(location?.state?.ID);
  }, [location.state]);

  useEffect(() => {
    console.log("data ::: ", location.state);
    if (!location.state) {
      return false;
    }

    // getTemplateDetails(location.state.TimeSheetID);

    if (location.state) {
      let data = templateDetails ?? templateDetails; //location.state;
      let newSheetData = data?.TimeSheetProfiles;
      let newArray = [];
      newSheetData?.length > 0 &&
        newSheetData.map((obj) => {
          newArray.push({
            selectedDay: obj.Day,
            time_from: obj.From && moment(obj.From).utc().format("HH:mm"),
            time_to: obj.To && moment(obj.To).utc().format("HH:mm"),
          });
        });
      setTemplateTitle(data?.TimeSheetName);
      console.log("In Timesheet details... ", data, newArray);

      setInputFields(
        newArray.length > 0
          ? newArray
          : [{ selectedDay: "", time_from: "", time_to: "" }]
      );
    }
  }, [location, templateDetails]);

  console.log("inputFields", inputFields);
  const removeInputFields = (data, index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    console.log("index 456", index, data, rows);
    setInputFields(rows);
  };
  const handleChange = (index, data, key) => {
    console.log("index, data", index, data);

    let inputData = [...inputFields];
    inputData[index][`${key}`] = data;
    setInputFields(inputData);

    // setInputFields([{ ...inputFields, selectedDay: data }]);
  };

  const addTimeSheet = () => {
    if (!templateTitle || templateTitle === "") {
      ShowError("Please Enter Title Value!");
      return false;
    }

    let newData = [];

    inputFields.map((obj, index) => {
      newData.push({
        Day: obj.selectedDay,
        From: moment(obj.time_from, "HH:mm").format("HH:mm:00"),
        To: moment(obj.time_to, "HH:mm").format("HH:mm:00"),
        SnNo: index,
      });
    });

    console.log("Data sheet : ", inputFields, newData, templateTitle);

    // return false;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/create`,
        {
          TimeSheetName: templateTitle,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            selectedDay: "",
            time_from: "",
            time_to: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/timesheet-template`);
        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const updateTimeSheet = () => {
    let newData = [];

    inputFields.map((obj, index) => {
      newData.push({
        Day: obj.selectedDay,
        From: moment(obj.time_from, "HH:mm").format("HH:mm:00"),
        To: moment(obj.time_to, "HH:mm").format("HH:mm:00"),
        SnNo: index,
      });
    });

    console.log("Data sheet : ", location);

    // return false;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/update`,
        {
          TimeSheetName: templateTitle,
          TimeSheetID: location?.state?.ID,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            selectedDay: "",
            time_from: "",
            time_to: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/timesheet-template`);

        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <div>
      <TimeSheetHeader screen={"details"} data={location.state} />
      <div style={{ padding: "16px 2px" }}>
        <Card style={{ gap: "10px" }} elevation={4} className=" p-10  ">
          {/* <h3>TimeSheet Template</h3> */}
          <TextField
            size={"small"}
            id="time"
            label="Template Title"
            // placeholder="Template Title"
            type="text"
            sx={{ width: "250px" }}
            inputProps={inputProps}
            value={templateTitle}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
              shrink: true,
            }}
            onChange={(e) => {
              // handleChange(index, e.target.value, "time_from");
              console.log("index eeee", e);
              setTemplateTitle(e.target.value);
            }}
            margin="dense"
            name="template_title"
            fullWidth={true}
            variant="outlined"
            hiddenLabel="true"
          />
          <table style={{ width: "fit-content" }}>
            <tr>
              <td>Day</td>
              <td>From Time</td>
              <td>To Time</td>
            </tr>
            {inputFields.map((data, index) => {
              const { fullName, emailAddress, salary } = data;
              return (
                <tr>
                  <td>
                    <Autocomplete
                      value={inputFields[index].selectedDay}
                      size="small"
                      onChange={(event, newValue) => {
                        handleChange(index, newValue, "selectedDay");
                        // console.log("Clicked... ", event.target.value, newValue);
                        // setInputFields({ ...inputFields, selectedDay: newValue });
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      // id="free-solo-with-text-demo"
                      id="controllable-states-demo"
                      options={weekDayData}
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      // renderOption={(props, option) => (
                      //   console.log("props option", props, option),
                      //   (<li {...props}>{option}</li>)
                      // )}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label="Choose Day" />
                      )}
                    />
                  </td>
                  <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      // error={ole1 ? true : false}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_from}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_from");
                        console.log("index eeee", index, e);
                      }}
                      margin="dense"
                      name="time_from"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td>
                  <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_to}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_to");
                      }}
                      margin="dense"
                      name="time_to"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td>
                  <td>
                    {inputFields.length !== index + 1 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => removeInputFields(data, index)}
                      >
                        x
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-success"
                        onClick={addInputField}
                      >
                        +
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>

          <div className="row">
            <div className="col-sm-12">
              {location.state && location.state.ID ? (
                <>
                  {/* {userRole === "admin" ? (
                  <button
                    className="btn btn-outline-success"
                    onClick={updateTimeSheet}
                  >
                    Update
                  </button>
                ) : (
                  <> */}
                  {console.log(
                    "121212 :::-> ",
                    userRole,
                    userId,
                    location.state
                  )}
                  {["admin", "supervisor", "timekeeper"].includes(userRole) &&
                  userId ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={updateTimeSheet}
                      style={{
                        textTransform: "none",
                        color: "white",
                      }}
                      className="bg-primary"
                    >
                      Update Template
                    </Button>
                  ) : (
                    <></>
                  )}
                  {/* </>
                )} */}
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={addTimeSheet}
                  style={{
                    textTransform: "none",
                    color: "white",
                  }}
                  className="bg-primary"
                >
                  Add Template
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default TimeSheetTemplateDetails;
