import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";
import {
    faPlusLarge,
} from "@fortawesome/pro-duotone-svg-icons";
import { Btn } from "../../../AbstractElements";
import ErrorBoundary from "../../../ErrorBoundary";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable";
import { filterAllowedColumn } from "../../../_helper/helper";

const DataTable = (
    {
        tableName, location, isLoading,
        title, newButtonText, newFormURL,
        sort = [], actions = [], visileColumns,
        employeeLists, isLoadingEmployeeLists, deleteData,
        additionalWheres = "",
        ...props
    }
) => {
    const { userData } = useAuth();
    const history = useNavigate();

    const {
        columns,
        loadingSearch,
        searchResults,
        searchResults: { results, rowCount },
    } = useSelector((state) => {
        return state.advanceSearchSlice;
    });

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortedColumns, setSortedColumns] = useState([]);
    const [sortData, setSortData] = useState(sort);
    const [additionalWhere, setAdditionalWhere] = useState("");
    const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");

    useEffect(() => {
        if (columns && visileColumns.length > 0 && columns.length > 0) {
            setSortedColumns(filterAllowedColumn(userData, visileColumns), "id");
        }
    }, [columns]);
    
    useEffect(() => {
        setAdditionalWhere(additionalWheres);
    }, [additionalWheres])

    const handlePageChange = (event) => {
        setPage(event.page.skip / event.page.take + 1);
        setPageSize(event.page.take);
    };

    const handleSortChange = (sort) => {
        setSortData(sort);
        setPage(1);
        setPageSize(10);
    };

    const handleFilterChange = (data) => {
        setAdditionalWhere(data);
        setPage(1);
        setPageSize(10);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handleReset = () => {
        console.log("handleReset.....");
    };

    return (
        <ErrorBoundary>
            <Fragment>
                {/* <Container fluid={true}> */}
                {/* <Row className="bg-primary rounded py-3 mb-3">
                        <Typography variant="h5">
                            <i class="fa-duotone fa-brain m-r-10"></i>
                            {title}
                        </Typography>
                    </Row> */}
                <Row>
                    <Col sm="12" className="page-content">
                        <Card>
                            <CardBody>
                                <AdvanceSearch
                                    location={location}
                                    columnList={"*"}
                                    columnRefernces={[]}
                                    tableName={tableName}
                                    searchParams={[]}
                                    additionalWhere={additionalWhere}
                                    expanded={true}
                                    sortData={sortData}
                                    pageNumber={page}
                                    pageSize={pageSize}
                                    deleteData={deleteData}
                                    chooseFilterMethod={chooseFilterMethod}
                                />
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        className="bg-light"
                                    >
                                        <Btn
                                            attrBtn={{
                                                color: 'primary',
                                                onClick: () => {
                                                    handleReset();
                                                    history(`${newFormURL}`)
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlusLarge} style={{ marginRight: '5px' }} />{newButtonText}
                                        </Btn>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <>
                                            {sortedColumns.length > 0 && (
                                                <TelerikDataTable
                                                    isDashboard={true}
                                                    pageSize={pageSize}
                                                    totalCount={rowCount}
                                                    skip={(page - 1) * pageSize}
                                                    take={pageSize}
                                                    data={results || []}
                                                    sortData={sortData}
                                                    onFilterChange={handleFilterChange}
                                                    onSortChange={handleSortChange}
                                                    onPageChange={handlePageChange}
                                                    onPageSizeChange={handlePageSizeChange}
                                                    selectedColumns={sortedColumns}
                                                    setSortedColumns={setSortedColumns}
                                                    loading={loadingSearch || isLoading}
                                                    actions={actions}
                                                    setChooseFilterMethod={setChooseFilterMethod}
                                                    handleClick={() => { }}
                                                />
                                            )}
                                        </>
                                    </AccordionDetails>
                                </Accordion>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* </Container> */}
            </Fragment>
        </ErrorBoundary>
    );
}

export default (DataTable);

