import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";

import axios from "axios";
import TimeSheetModal from "./TimeSheetModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import ConfigDB from "../../../Config/ThemeConfig";
import { ShowSucess } from "../../../app/utility/helper";

//   root: {
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: "flex",
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     "&$checked": {
//       transform: "translateX(12px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     boxShadow: "none",
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);
const ViewAccess = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState(null);

  const [resultArray, setResultArray] = useState([]);

  const [checkBoxArray, setCheckBoxArray] = useState([
    {
      title: "Standard Sheet",
      code: "table",
    },
    
  ]);

  const AssignViewAccess = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/accessType`,
        {
          employeeID: props.empId ? props.empId : null,
          code: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        ShowSucess(response.data.message);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getDefaultChecked = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}timesheet/accessType/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        const result = response.data.result;
        let allAccess = response.data.allAccess;
        
        let resultArray = [];
        result.map((i) => {
          return resultArray.push(i.code);
        });
        allAccess = allAccess.map((i) => {
          return { code:i.code,title:i.title };
        });
        setCheckBoxArray(allAccess);
        setResultArray(resultArray);
        console.log("dsklfjdsksddfhds allAccess", allAccess);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if (props.empId) {
      getDefaultChecked(props.empId);
    }
  }, [props.empId]);

  return (
    <>
      <Card>
        <CardHeader className="pb-0">
          <H5>Timesheet Layout Access </H5>
        </CardHeader>
        <CardBody>
          {/* <Form> */}
          <Row className="g-3 mb-2">
            {checkBoxArray.map((i, index) => {
              return (
                <Col lg="3" md="3" sm="6" sx="12"  style={{ display: "flex" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            console.log("dsaddfsfsads", e.target, index);
                            const isChecked = e.target.checked;
                            let apiData = [...resultArray];
                            if (isChecked) {
                              apiData.push(e.target.value);
                            } else {
                              // apiData.splice(index,1)
                              apiData = apiData.filter(
                                (item) => item != i.code
                              );
                            }

                            setResultArray(apiData);
                          }}
                          value={i.code}
                          checked={resultArray.includes(i.code)}

                          // checked={resultArray.map((item) => {return item}) === i.title ? true : false}
                        />
                      }
                      label={i.title}
                    />
                  </FormGroup>
                  <Tooltip title={"Help"} className="mt-2">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      size="2x"
                      style={{
                        color: ConfigDB.data.color.primary_color,
                        fontSize: "25px",
                      }}
                      onClick={() => {
                        setTitle(i.title);
                        setTitle(i.code);
                        setModalOpen(true);
                      }}
                      cursor="pointer"
                    />
                  </Tooltip>
                </Col>
              );
            })}
          </Row>

          <Button
            onClick={() => AssignViewAccess(resultArray)}
            variant="contained"
            className="m-10 bg-primary mt-2"
            style={{
              color: "#d0efe9",
            }}
          >
            {" "}
            Update
          </Button>
        </CardBody>
      </Card>

      <TimeSheetModal open={modalOpen} setOpen={setModalOpen} title={title} />
    </>
  );
};

export default ViewAccess;
