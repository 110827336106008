import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import Header from "./component/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import { Card, CardBody } from "reactstrap";
import GarnishmentManage from "./component/GarnishmentManage";
import { getAllDeductionPlans, getAllEmployeeDeductionPlan, getAllDeductionMethodList, getAllGarnishmentType, getAllPaymentType, getDeductionType, getAllDeductionPlanMaster, getAllDeductionPlanStatusList } from "../../../app/utility/axiosService";
import { GetAllEmployee } from "../../Supervisor/CommonConfigrations/TableSlice";
import { useDispatch, useSelector } from "react-redux";
import Header1 from "../../employee/component/header1";
import AdvanceSearch from "../../employee/AdvanceSearch";
import { useAuth } from "oidc-react";
import { getUserId, getUserRole } from "../../../app/utility/helper";
import { useLocation, useNavigate } from "react-router-dom";
// import TelerikDataTable from "../../employee/component/telerikDataTable";
import TelerikDataTable from "./component/TelerikDataTable";
import './garnishment.css';
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        ".&.MuiAccordionSummary-content": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "none",
        },
        margin: "0 !important",
        borderTopWidth: 1,
        borderTopColor: "#ccc",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        // color: theme.palette.text.secondary,
    },
}));

const Garnishment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [detail, setDetail] = useState({
        oldId: null,
        employeeID: null,
        employeeSeq: '',
        deductionType: null,
        deductionPlan: null,
        sequence: '',
        // garnishmentTypeId: null,
        startDate: null,
        endDate: null,
        garnishmentMethod: null,
        paymentMonthlySch: null,
        taxable: false,
        accountNoForVendor: '',
        applyToOT: '',
        pctToDeduct: '',
        deductionPlanStatus: null,
        // pctBasis: '',
        balanceDue: '',
        totalOriginal: '',
        paidToDate: null,
        isActive: true,
    });
    const history = useNavigate();
    const { userData } = useAuth();
    const paramsData = useLocation();
    const ID = getUserId(userData);
    const role = getUserRole(userData);
    const [loading, setLoading] = useState(false);
    const [isDeductionDetailsShow, setIsDeductionDetailsShow] = useState(props?.isDetailShow);
    const [tableList, setTableList] = useState([]);
    const [employeeTableList, setEmployeeTableList] = useState([]);
    const [takeCount, setTakeCount] = useState(10);
    const [skipCount, setSkipCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [garnishmentTypes, setGarnishmentTypes] = useState([]);
    const [deductionTypes, setDeductionTypes] = useState([]);
    const [deductionPlans, setDeductionPlans] = useState([]);
    const [garnishmentMethods, setGarnishmentMethods] = useState([]);
    const [deductionPlanStatusList, setDeductionPlanStatusList] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [sortedColumns, setSortedColumns] = useState([]);
    const [expand, setExpand] = useState({
        panel1: true,
        panel2: true,
        panel3: true,
    });
    const employeeSearched = useSelector(({ employeeSlice }) => employeeSlice.employeeSearched);
    const employeeColumns = useSelector((state) => state.querySlice.colsPlaceholder);

    useEffect(() => {
        if (paramsData?.pathname === "/master/garnishment/update/:id" && !paramsData?.state) {
            let getPath = props?.defaultDeductionType === "GARNISHMENT" ? "garnishment" :
                props?.defaultDeductionType === "BENEFITS" ? "benefit" :
                props?.defaultDeductionType === "MISCELLANEOUS" ? "miscellaneous" :
                props?.defaultDeductionType === "RETIREMENT-PLANS" ? "retirement-plan" : ''
            return history(`/master/${getPath}`);
        }
        getData();
        fetchAllGarnishmentMethod();
        fetchAllGarnishmentTypes();
        fetchAllPaymentOption();
        fetchAllDeductionTypes();
        fetchAllEmployee();
        fetchAllDeductionPlans();
        fetchAllDeductionPlanStatusList();
    }, []);

    useEffect(() => {
        getData();
    }, [skipCount]);

    const getColumnFilterType = (type) => {
        if (["float", "bigint", "smallint", "int"].includes(type)) {
          return "numeric";
        } else if (["varchar", "char"].includes(type)) {
          return "text";
        } else if (["datetime"].includes(type)) {
          return "date";
        } else {
          return "text";
        }
    };
      
    useEffect(() => {
        if (employeeColumns && employeeColumns.length) {
            let colArray = [];
            employeeColumns.map((i) => {
                let iObj = {
                title: i.column_title,
                field: i.column_name,
                locked: false,
                show: false,
                filter: getColumnFilterType(i.data_type),
                width: 200,
                };
                colArray.push(iObj);
            });
        
            const visibleColumnArray = [
                "SSN",
                "ID",
                "EmployeeName",
                "DeptName",
                "Nposition_No",
                "Military_Status",
                "Hm_Phone",
                "Ml_Wk_Phone",
                "Email",
                "Inactive_Status_Title",
                "Emp_Status_Title",
            ];
            let orderColumns = [];
            visibleColumnArray.map((i) => {
                let columnIndex = colArray.findIndex((k) => {
                    return k.field == i;
                });
                if (columnIndex !== -1) {
                    orderColumns.push({ ...colArray[columnIndex], show: true });
                    colArray.splice(columnIndex, 1);
                }
            });
            let updatedColumn = orderColumns.concat(colArray);
            setSortedColumns(updatedColumn);
        }
    }, [employeeColumns]);

    useEffect(() => {
        setEmployeeTableList(employeeSearched);
    }, [employeeSearched]);
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpand((prevState) => ({
            ...prevState,
            [panel]: isExpanded,
        }));
    };

    const fetchAllEmployee = () => {
        dispatch(GetAllEmployee(0));
    };

    const getData = async () => {
        if (paramsData?.state?.employeeId) {
            const res = await getAllEmployeeDeductionPlan(Number(paramsData?.state?.employeeId), props?.defaultDeductionType);
            if (res?.status === 200) {
              setTableList(res.data);
              setTotalCount(res.totalCount);
            }
        }
    };

    const fetchAllDeductionTypes = async () => {
        const res = await getDeductionType();
        if (res?.length > 0) {
            // let temp = [];
            // res?.forEach(ele => {
            //     if (ele?.Code === 'GARNISHMENT' || ele?.Code === 'BENEFITS' || ele?.Code === 'MISCELLENAOUS') {
            //         temp.push(ele);
            //     }
            // });
            setDeductionTypes(res);
            // const findDeductionType = res?.find(o => o.ID === 35 || o.Code === 'GARNISHMENT');
            const findDeductionType = res?.find(o => o.Code === props?.defaultDeductionType);
            if (findDeductionType) setDetail({ ...detail, deductionType: findDeductionType?.Code });
        }
    };

    const fetchAllDeductionPlans = async () => {
        // const res = await getAllDeductionPlans();
        const res = await getAllDeductionPlanMaster();
        if (res?.status === 200) {
            let temp = [];
            res?.data?.forEach(e => {
                if (e.deductionType === props?.defaultDeductionType) {
                    temp.push(e);
                }
            });
            setDeductionPlans(temp);
        }
    };

    const fetchAllGarnishmentTypes = async () => {
        const res = await getAllGarnishmentType();
        if (res?.status === 200) setGarnishmentTypes(res.data);
    };

    const fetchAllGarnishmentMethod = async () => {
        const res = await getAllDeductionMethodList();
        if (res?.length > 0) setGarnishmentMethods(res);
    };

    const fetchAllDeductionPlanStatusList = async () => {
        const res = await getAllDeductionPlanStatusList();
        if (res?.length > 0) setDeductionPlanStatusList(res);
    };
    
    const fetchAllPaymentOption = async () => {
        const res = await getAllPaymentType();
        if (res?.status === 200) setPaymentOptions(res.data);
    };

    const clearFields = () => {
        setDetail({
            oldId: null,
            employeeID: null,
            employeeSeq: '',
            deductionType: null,
            deductionPlan: null,
            sequence: '',
            garnishmentTypeId: null,
            startDate: null,
            endDate: null,
            garnishmentMethod: null,
            paymentMonthlySch: null,
            taxable: false,
            accountNoForVendor: '',
            applyToOT: '',
            pctToDeduct: '',
            deductionPlanStatus: null,
            // pctBasis: '',
            balanceDue: '',
            totalOriginal: '',
            paidToDate: null,
            isActive: true,
        });
    };

    return (
        <>
        <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <Header
                {...props}
                clearFields={clearFields}
                detail={detail}
                tableList={tableList}
                paramsData={paramsData}
                isDeductionDetailsShow={isDeductionDetailsShow}
                getData={getData}
                setDetail={setDetail}
            />
            </div>

            {!isDeductionDetailsShow && 
                <>
                <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
                    <Header1 {...props} />
                    </div>

                    <div
                        classes={{
                            root: "w-full h-full",
                            header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                            contentWrapper: "p-0",
                            contentCard: "rounded-none",
                        }}
                    >
                        <div className={`${classes.root} w-full page-content`}>
                            <AdvanceSearch loading={loading} role={role} userId={ID} setLoading={setLoading} />
                        </div>
                    </div>

                    <div
                        classes={{
                        root: "w-full h-full",
                        header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                        contentWrapper: "p-0",
                        contentCard: "rounded-none",
                        }}
                    >
                        <TelerikDataTable
                            pathExit= {paramsData?.pathname}
                            data={employeeTableList}
                            setIsDeductionDetailsShow={setIsDeductionDetailsShow}
                            defaultDeductionType={props?.defaultDeductionType}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loading}
                        ></TelerikDataTable>
                        <div className={`${classes.root} w-full`}></div>
                    </div>
                </>
            }

            {isDeductionDetailsShow &&
                <>
                    <div
                        classes={{
                            root: "w-full h-full",
                            header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                            contentWrapper: "p-0",
                            contentCard: "rounded-none",
                        }}
                    >
                        <div className={`${classes.root} w-full`}>
                            <Card className="mt-3 mb-3">
                                <Accordion
                                    className={classes.root}
                                    expanded={expand.panel1}
                                    onChange={handleChange("panel1")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className="bg-gray-200 sub-header"
                                    >
                                        <FontAwesomeIcon
                                            icon={faRectangleList}
                                            className="sub-header"
                                            style={{
                                                marginRight: "10px",
                                                marginTop: "3px",
                                            }}
                                        />
                                        <Typography className={classes.heading}>
                                            {props?.defaultDeductionType === 'GARNISHMENT' ? 'Garnishment Details' : 
                                            props?.defaultDeductionType === 'BENEFITS' ? 'Benefits Details' :
                                            props?.defaultDeductionType === 'MISCELLANEOUS' ? 'Miscellaneous Details' :
                                            props?.defaultDeductionType === 'RETIREMENT-PLANS' ? 'Retirement Details' : ''
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="pt-0 page-body-wrapper">
                                        <CardBody>
                                            <GarnishmentManage
                                                detail={detail}
                                                paramsData={paramsData}
                                                setDetail={setDetail}
                                                deductionTypes={deductionTypes}
                                                deductionPlanStatusList={deductionPlanStatusList}
                                                deductionPlans={deductionPlans}
                                                garnishmentTypes={garnishmentTypes}
                                                garnishmentMethods={garnishmentMethods}
                                                paymentOptions={paymentOptions}
                                            />
                                        </CardBody>
                                    </AccordionDetails>
                                </Accordion>
                            </Card>
                            <Card className="mt-3 mb-3">
                                <Accordion
                                    className={classes.root}
                                    expanded={expand.panel2}
                                    onChange={handleChange("panel2")}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                        className="bg-gray-200 sub-header"
                                    >
                                        <FontAwesomeIcon
                                            icon={faRectangleList}
                                            className="sub-header"
                                            style={{
                                                marginRight: "10px",
                                                marginTop: "3px",
                                            }}
                                        />
                                        <Typography className={classes.heading}>
                                        {props?.defaultDeductionType === 'GARNISHMENT' ? 'Garnishment List' : 
                                            props?.defaultDeductionType === 'BENEFITS' ? 'Benefits List' :
                                            props?.defaultDeductionType === 'MISCELLANEOUS' ? 'Miscellaneous List' :
                                            props?.defaultDeductionType === 'RETIREMENT-PLANS' ? 'Retirement List' : ''
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="p-0 page-body-wrapper">
                                        <RequestTable
                                            {...props}
                                            role={"admin"}
                                            clearFields={clearFields}
                                            requestDetail={tableList}
                                            setSkipCount={setSkipCount}
                                            totalCount={totalCount}
                                            detail={detail}
                                            deductionTypes={deductionTypes}
                                            deductionPlans={deductionPlans}
                                            garnishmentTypes={garnishmentTypes}
                                            deductionPlanStatusList={deductionPlanStatusList}
                                            garnishmentMethods={garnishmentMethods}
                                            paymentOptions={paymentOptions}
                                            setDetail={setDetail}
                                            filterBy={detail}
                                            getData={getData}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Card>
                        </div>
                    </div>
                </>
            }
            </Container>
        </>
    );
};

export default Garnishment;