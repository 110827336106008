import React, { useEffect, useState } from "react";
import { MenuItem } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { TextField } from "@mui/material";
import { floatNumberRegex } from "../../../../app/utility/helper";

function StateTaxManage(props) {
    const menuItemListHeight = 300;
    const [codeMinLengthError, setCodeMinLengthError] = useState(false);
    const [isCaliforniaState, setIsCaliforniaState] = useState(false);
    const [isWashingtonState, setIsWashingtonState] = useState(false);
    const [isHawaiiState, setIsHawaiiState] = useState(false);
    const [isGuamState, setIsGuamState] = useState(false);

    useEffect(() => {
        if (props?.detail?.stateId === 'CA') { setIsCaliforniaState(true) } else setIsCaliforniaState(false);
        if (props?.detail?.stateId === 'WA') { setIsWashingtonState(true) } else setIsWashingtonState(false);
        if (props?.detail?.stateId === 'HI') { setIsHawaiiState(true) } else setIsHawaiiState(false);
        if (props?.detail?.stateId === 'GU') { setIsGuamState(true) } else setIsGuamState(false);
    }, [props?.detail?.stateId]);

    return (
        <>
            <Row className="g-3 row">
                <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full textInput"
                    variant="outlined"
                    select
                    SelectProps={{
                    MenuProps: {
                            sx: { maxHeight: menuItemListHeight },
                        }
                    }}
                    size="small"
                    InputLabelProps={{ shrink: props?.detail?.stateId ? true : false }}
                    value={props?.detail?.stateId}
                    // value={getStateValue}
                    onChange={(e) => {
                        let temp = props?.stateList?.find(o => o.code === e.target.value);
                        props.setDetail({
                            ...props.detail,
                            stateId: e.target.value,
                            disabilityInsurance: temp?.disabilityInsurance ? temp?.disabilityInsurance : '',
                            stateIncomeTax: temp?.stateIncomeTax ? temp?.stateIncomeTax : '',
                            localTax: temp?.localTax ? temp?.localTax : '',
                            familyLeaveInsurance: temp?.familyLeaveInsurance ? temp?.familyLeaveInsurance : '',
                            stateIndustrialInsurance: temp?.stateIndustrialInsurance ? temp?.stateIndustrialInsurance : '',
                            numberOfQualifyingChildrenUnderAge17: temp?.numberOfQualifyingChildrenUnderAge17 ? temp?.numberOfQualifyingChildrenUnderAge17 : '',
                            numberOfOtherDependents: temp?.numberOfOtherDependents ? temp?.numberOfOtherDependents : '',
                            medicareTax: temp?.medicareTax ? temp?.medicareTax : '',
                            socialSecurityTax: temp?.socialSecurityTax ? temp?.socialSecurityTax : ''
                        });
                        if (e.target.value === 'CA') { setIsCaliforniaState(true) } else setIsCaliforniaState(false);
                        if (e.target.value === 'WA') { setIsWashingtonState(true) } else setIsWashingtonState(false);
                        if (e.target.value === 'HI') { setIsHawaiiState(true) } else setIsHawaiiState(false);
                        if (e.target.value === 'GU') { setIsGuamState(true) } else setIsGuamState(false);
                    }}
                    inputProps={{
                        className: 'textInputInputProps'
                    }}
                    label="State"
                >
                    {props?.stateList?.map((option) => (
                        <MenuItem key={option.id} value={option.code}>
                            {option.code} - {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                </Col>
                <Col md="6"></Col>
                {isCaliforniaState &&
                    <Col md="6">
                        <TextField
                            margin="dense"
                            className="flex w-full textInput"
                            variant="outlined"
                            size="small"
                            type="number"
                            inputProps={{
                                className: 'textInputInputProps',
                                minLength: 12,
                                maxLength: 12,
                            }}
                            error={codeMinLengthError}
                            helperText={codeMinLengthError ? "minimum length should be 2" : null}
                            label="Disability Insurance"
                            value={props?.detail?.disabilityInsurance}
                            onChange={(e) => {
                                if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                    props.setDetail({ ...props.detail, disabilityInsurance: e.target.value });
                                    if (e.target.value?.length < 12) setCodeMinLengthError(true);
                                    else setCodeMinLengthError (false);
                                }
                            }}
                            name="disabilityInsurance"
                        />
                    </Col>
                }
                {(isCaliforniaState || isHawaiiState) &&
                    <Col md="6">
                        <TextField
                            margin="dense"
                            className="flex w-full textInput"
                            variant="outlined"
                            type="number"
                            size="small"
                            inputProps={{
                                className: 'textInputInputProps',
                                minLength: 12,
                                maxLength: 12,
                            }}
                            label="State Income Tax"
                            value={props?.detail?.stateIncomeTax}
                            onChange={(e) => {
                                if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                    props.setDetail({ ...props.detail, stateIncomeTax: e.target.value });
                                }
                            }}
                            name="stateIncomeTax"
                        />
                    </Col>
                }
                {isWashingtonState &&
                    <>
                        <Col md="6">
                            <TextField
                                margin="dense"
                                className="flex w-full textInput"
                                variant="outlined"
                                type="number"
                                size="small"
                                inputProps={{
                                    className: 'textInputInputProps',
                                    minLength: 12,
                                    maxLength: 12,
                                }}
                                label="Local Tax"
                                value={props?.detail?.localTax}
                                onChange={(e) => {
                                    if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                        props.setDetail({ ...props.detail, localTax: e.target.value });
                                    }
                                }}
                                name="localTax"
                            />
                        </Col>
                        <Col md="6">
                            <TextField
                                margin="dense"
                                className="flex w-full textInput"
                                variant="outlined"
                                type="number"
                                size="small"
                                inputProps={{
                                    className: 'textInputInputProps',
                                    minLength: 12,
                                    maxLength: 12,
                                }}
                                label="Family Leave Insurance"
                                value={props?.detail?.familyLeaveInsurance}
                                onChange={(e) => {
                                    if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                        props.setDetail({ ...props.detail, familyLeaveInsurance: e.target.value });
                                    }
                                }}
                                name="familyLeaveInsurance"
                            />
                        </Col>
                        <Col md="6">
                            <TextField
                                margin="dense"
                                className="flex w-full textInput"
                                variant="outlined"
                                type="number"
                                size="small"
                                inputProps={{
                                    className: 'textInputInputProps',
                                    minLength: 12,
                                    maxLength: 12,
                                }}
                                label="State Industrial Insurance"
                                value={props?.detail?.stateIndustrialInsurance}
                                onChange={(e) => {
                                    if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                        props.setDetail({ ...props.detail, stateIndustrialInsurance: e.target.value });
                                    }
                                }}
                                name="stateIndustrialInsurance"
                            />
                        </Col>
                    </>
                }
                <Col md="6">
                    <TextField
                        margin="dense"
                        className="flex w-full textInput"
                        variant="outlined"
                        type="number"
                        size="small"
                        inputProps={{
                            className: 'textInputInputProps',
                            minLength: 12,
                            maxLength: 12,
                        }}
                        label="Number Of Qualifying Children Under Age 17"
                        value={props?.detail?.numberOfQualifyingChildrenUnderAge17}
                        onChange={(e) => {
                            if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                props.setDetail({ ...props.detail, numberOfQualifyingChildrenUnderAge17: e.target.value });
                            }
                        }}
                        name="numberOfQualifyingChildrenUnderAge17"
                    />
                </Col>
                <Col md="6">
                    <TextField
                        margin="dense"
                        className="flex w-full textInput"
                        variant="outlined"
                        type="number"
                        size="small"
                        inputProps={{
                            className: 'textInputInputProps',
                            minLength: 12,
                            maxLength: 12,
                        }}
                        label="Number Of Other Dependents"
                        value={props?.detail?.numberOfOtherDependents}
                        onChange={(e) => {
                            if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                props.setDetail({ ...props.detail, numberOfOtherDependents: e.target.value });
                            }
                        }}
                        name="numberOfOtherDependents"
                    />
                </Col>
                <Col md="6">
                    <TextField
                        margin="dense"
                        className="flex w-full textInput"
                        variant="outlined"
                        type="number"
                        size="small"
                        inputProps={{
                            className: 'textInputInputProps',
                            minLength: 12,
                            maxLength: 12,
                        }}
                        label="Medicare Tax"
                        value={props?.detail?.medicareTax}
                        onChange={(e) => {
                            if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                props.setDetail({ ...props.detail, medicareTax: e.target.value });
                            }
                        }}
                        name="medicareTax"
                    />
                </Col>
                <Col md="6">
                    <TextField
                        margin="dense"
                        className="flex w-full textInput"
                        variant="outlined"
                        type="number"
                        size="small"
                        inputProps={{
                            className: 'textInputInputProps',
                            minLength: 12,
                            maxLength: 12,
                        }}
                        label="Social Security Tax"
                        value={props?.detail?.socialSecurityTax}
                        onChange={(e) => {
                            if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                                props.setDetail({ ...props.detail, socialSecurityTax: e.target.value });
                            }
                        }}
                        name="socialSecurityTax"
                    />
                </Col>
            </Row>
        </>
    );
}

export default StateTaxManage;
