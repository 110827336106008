import React, { useEffect, useState } from "react";
import {
  Button,
  Switch,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { H5 } from "../../../AbstractElements";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import {
  ShowError,
  ShowSucess,
  getUserId,
  getUserRole,
  payCodeFilter,
} from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import 'dayjs/locale/en';
const PayCodeManagment = (props) => {
  const sampleData = {
    EmployeeID: null,
    startDate: null,
    endDate: null,
    PayCode: "",
    state: false,
    id: null,
    TotalAuthorizedHours: 0,
    jobOrder: null,
  };
  const [payCodeList, setPaycodeList] = useState([]);
  const [paytimeCodeByID, setPaytimeCodeByID] = useState([]);
  const [adminPayCodes, setAdminPaycodes] = useState([]);
  const [filterPaycodes, setFilterPaycodes] = useState([]);
  const [deletedList, setDeletedList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rowsData, setRowsData] = useState([sampleData]);
  const [assigneeAccountNumber, setAssigneeAccountNumber] = useState([]);

  const { userData } = useAuth();
  const empId = getUserId(userData);
  const role = getUserRole(userData);

  console.log("getPaycodeByAdmin", rowsData);

  useEffect(() => {
    console.log("dxasdas");
    // if(payCodeList.length && paytimeCodeByID.length){
    //   const filterPaycode= payCodeFilter(payCodeList,paytimeCodeByID, role,'assign')

    //   setFilterPaycodes(filterPaycode)
    // }else
    {
      const filterPaycode = payCodeFilter(
        payCodeList,
        adminPayCodes.concat(paytimeCodeByID),
        role,
        "assign"
      );
      console.log("filterpayad", filterPaycode, "####", adminPayCodes);
      setFilterPaycodes(filterPaycode);
    }
  }, [payCodeList, paytimeCodeByID, role]);
  console.log("data===>", rowsData);
  useEffect(() => {
    getPaycodeByAdmin(getUserId(userData));
    getPaycodeBYID(props.userId);
    getAssignedPayCode(props.userId);
    handleGetAcountNumber(props.userId);
  }, [props.userId]);

  const handleGetAcountNumber = (data) => {
    axios
      .get(
        `${process.env.REACT_APP_WEB_API_URL}Account/GetEmployeeAccountById?Id=${data}`
      )
      .then((res) => {
        console.log("account number", res.data);
        let data = res?.data?.map((item) => {
          return {
            label: item.jobOrderNo,
            value: item.id,
          };
        });
        setAssigneeAccountNumber(data);
      })
      .catch((err) => {
        console.log("account number", err);
      });
  };

  useEffect(() => {
    getPayCode();
  }, []);
  const getPayCode = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Get api/paytimecode  response", response.data);
        const finalData = response?.data.map((i) => {
          if (i.Abberv) {
            return {
              label: i.Abberv ? i.Abberv : null,
              value: i.Abberv ? i.Abberv : null,
              code: i.Code,
              color: i.color,
              Description:i.Description
            };
          } else {
            return {
              label: null,
              value: null,
              code: null,
              color: null,
              Description:null
            };
          }
        });
        console.log("finalData", finalData);
        setPaycodeList(finalData);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPaycodeBYID = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master response", response.data);

        setPaytimeCodeByID(response.data.result);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPaycodeByAdmin = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master response", response.data);

        setAdminPaycodes(response.data.result);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  const addTableRows = () => {
    setRowsData([...rowsData, sampleData]);
  };
  const deleteTableRows = (index, id) => {
    console.log("xxxx", deletedList);
    if (id != null) {
      setDeletedList([...deletedList, id]);
    }
    const _data = [...rowsData];
    _data.splice(index, 1);
    setRowsData(_data);
  };
  const getAssignedPayCode = (userId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: userId ? userId : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        if (
          response.data &&
          response.data.result &&
          response.data.result.length
        ) {
          console.log("Dsfdfdsf", response?.data?.result);
          let data = response.data.result.map((item) => {
            let subData = {
              PayCode: item.PayCode,
              startDate: item.EffectiveStartDate
                ? moment(item.EffectiveStartDate).format("MM/DD/YYYY")
                : null,
              endDate: item.EffectiveEndDate
                ? moment(item.EffectiveEndDate).format("MM/DD/YYYY")
                : null,
              id: item.ID,
              TotalAuthorizedHours: item.TotalAuthorizedHours,
              jobOrder: item.AccountID,
            };

            return subData;
          });
          //   }
          console.log("updated Data", data);
          setRowsData(data);
          setIsUpdate(true);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  // const handleDateFieldChange = (name, value, index) => {
  //   console.log(
  //     "#LL",
  //     "handleDateFieldChange",
  //     name,
  //     "##",
  //     value,
  //     "!#!",
  //     index
  //   );
  //   const rowsInput = [...rowsData];

  //   console.log("#LL", "rowsInput", rowsInput[index]["id"]);
  //   let rowId = rowsInput[index]["id"];

  //   let filZ = rowsData.filter((i) => {
  //     return i.parentIndex === rowId;
  //   });

  //   filZ.map((j) => {
  //     let existAtIndex = rowsInput.findIndex((i) => {
  //       return i.id === j.id;
  //     });
  //     if (existAtIndex !== -1) {
  //       rowsInput[existAtIndex]["date"] = value;
  //     }
  //   });

  //   rowsInput[index][name] = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
  //   // checkAddedTimeError1(rowsInput, rowsInput[index], (isError) => {
  //   // //   setHasError(isError);
  //   // });
  //   // setRowsData(rowsInput);
  // };
  console.log("rowsData==",rowsData);
  const PayCodeAssignToEmployee = () => {
    let hasError = rowsData.filter((i) => {
      return i.error;
    });
    if (hasError.length) {
      ShowError(
        "Please Verify Paycode. It's has issue with overlapping date"
      );
      return false;
    }
    let parameters = rowsData.map((item) => {
      if (item.PayCode) {
        console.log("Item ::: ", item);
        return {
          EmployeeID: props.userId,
          PayCode: item.PayCode,
          // PayType: item.PayCode_PayTimeCode.Abberv,

          ID: item.id,
          startDate: item.startDate
            ? moment(item.startDate, "MM/DD/YYYY").format("YYYY/MM/DD")
            : null,
          endDate: item.endDate
            ? moment(item.endDate, "MM/DD/YYYY").format("YYYY/MM/DD")
            : null,
          TotalAuthorizedHours: item.TotalAuthorizedHours,

          AccountID: item?.jobOrder ? item?.jobOrder : null,
        };
      }
    });
    parameters = parameters.filter((temp) => temp != null);
    console.log("Params ::: ", parameters);
    console.log(
      "Selected Template ",
      selectedTemplate,
      payCodeTemplates,
      payCodeTemplates.find((i) => {
        if (i.TypeCodeID == selectedTemplate) {
          return i;
        }
      })?.TypeCodeName
    );
    // return;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/${
          isUpdate ? "update" : "update"
        }`,
        {
          assignList: parameters,
          deletedList: deletedList,
          TypeCodeID: selectedTemplate ? selectedTemplate : 0,
          TypeCodeName: selectedTemplate
            ? payCodeTemplates.find((i) => {
                if (i.TypeCodeID == selectedTemplate) {
                  return i;
                }
              })?.TypeCodeName
            : "",
          SupervisorID: selectedTemplate
            ? props.userId
              ? props.userId
              : 0
            : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("responded", response.data);
        ShowSucess(isUpdate ? "Updated Successfull " : "Added Successfull");
        if (props.userId) {
          getAssignedPayCode(props.userId);
        }
      })
      .catch((error) => {
        console.error("Cannot retrieve user data", error);
        ShowError("shomething went Wrong!!");
      });
  };

  const [payCodeTemplates, setPayCodeTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const getPayCodeTemplates = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}typecode/get`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode 111", response.data);
        setPayCodeTemplates(response?.data?.result);
        // if (response.data.result.length) {
        //   if (userRole == "user") {
        //     response.data.result.map((i) => {
        //       i.TimeSheetName = i.TimeSheetAssignLists[0]["TImeSheetName"];
        //       i.EmployeeID = i.TimeSheetAssignLists[0]["EmployeeID"];

        //       if (i.Employee) {
        //         i.Employee["EmployeeName_FML"] = "Self";
        //       }

        //       return i;
        //     });
        //   }
        // }
        // setTableList(response.data.result);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  const getTemplatePayCodeList = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}typecode/get/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode1 111", response.data);

        let newArray = [];
        if (response.data.result.length) {
          newArray = response.data.result.map((item) => {
            return {
              PayCode: item.Code,
              startDate: null,
              endDate: null,
              id: null,
              tempId: id,
              TotalAuthorizedHours: item.TotalAuthorizedHours,
            };
          });
        }

        let newRows = rowsData.filter((i) => !i.tempId);
        console.log("newRows 111 ::: ", newRows);

        let newRowsData = newRows.concat(newArray);
        console.log("newArray 111", newArray, newRowsData);
        setRowsData(newRowsData);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getPayCodeTemplates();
  }, []);

  const checkValidDate = (index) => {
    FindOverWrite(index, rowsData[index], rowsData); 
  };

  const FindOverWrite = (index, currentRow, AllData) => {
 

    const uniqueArr = Array.from(
      new Set(AllData.map((obj) => obj.PayCode))
    ).map((id) => {
      return AllData.find((obj) => obj.PayCode === id);
    });

    if (uniqueArr.length) {
      uniqueArr.map((icode) => {
        let currentInfo = AllData.filter((i) => {
          return i.PayCode == icode.PayCode;
        });

        if (currentInfo.length) {
          currentInfo.map((i, index) => {
            console.log("dskdjmaknadkls", index);

            i.error = false;
          });
        }

        if (currentInfo.length >= 2) {
          currentInfo.map((i, index) => {
            console.log("uniqueArr", currentInfo);

            const st = i.startDate
              ? moment(i.startDate).format("YYYY-MM-DD")
              : null;
            const et = i.endDate
              ? moment(i.endDate).format("YYYY-MM-DD")
              : null;

            currentInfo.map((j, index2) => {
              const stin = j.startDate
                ? moment(j.startDate).format("YYYY-MM-DD")
                : null;
              console.log("ddasdadada", stin);

              const etin = j.endDate
                ? moment(j.endDate).format("YYYY-MM-DD")
                : null;
              // j.error = true;

              console.log("OVerWrite Checking", i.PayCode, j.PayCode);

              if (st && stin && etin) {
              
                if (st > stin && st < etin && index !== index2) {
                  console.log("innnnn", st, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (st && stin) {
                console.log("bbbb", st, "#", stin, "###", index, "@", index2);
                if (st <= stin && st >= stin && index !== index2) {
                  console.log("innnnn", st, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (et && stin && etin) {
                console.log(
                  "bbbb22222",
                  et,
                  "#",
                  stin,
                  "###",
                  index,
                  "@",
                  index2
                );
                if (et > stin && et < etin && index !== index2) {
                  console.log("innnnn", et, "#", stin);
                  j.error = true;
                  i.error = true;
                }
              }
              if (et && st) {
                console.log("bbbb", et, "#", etin, "###", index, "@", index2);
                if (et == st) {
                  console.log("innnnn", et, "#", etin);
                  i.error = true;
                }
              }

              if ((stin && etin == null) || (etin && stin == null)) {
                j.error = true;
              }
            });
          });
        } else {
          currentInfo.map((i, index) => {
            const st = i.startDate
              ? moment(i.startDate).format("YYYY-MM-DD")
              : null;
            const et = i.endDate
              ? moment(i.endDate).format("YYYY-MM-DD")
              : null;

            console.log("fdesfsfssd", moment(i.st).isValid());
            if ((st && et == null) || (et && st == null)) {
              i.error = true;
            }
          });

          console.log("OVerWrite NO allowed");
        }
      });
    }
  };

  console.log("rows daatatdasdtys", rowsData);
  const filterApiData = (paycode, startDate, endDate) => {
    console.log("cscklsdcjsdkljkls", paycode, startDate, endDate);

    let data = [...rowsData];
    data.map((i) => {
      let cpobj = i;
      if (i.PayCode === paycode) {
        i["error"] = true;
      } else {
        i["error"] = false;
      }
      i = cpobj;
    });
    setRowsData(data);

    // let filteredData = [];
    // let error = null;
    // apiData.forEach((data) => {
    //   if (data.PayCode === paycode) {
    //     // Check if the date range overlaps
    //     const dataStartDate = moment(data.startDate).format("YYYY-MM-DD");
    //     const dataEndDate = moment(data.endDate).format("YYYY-MM-DD");
    //     const inputStartDate = moment(startDate).format("YYYY-MM-DD");
    //     const inputEndDate = moment(endDate).format("YYYY-MM-DD");

    //   if (dataStartDate <= inputEndDate && dataEndDate >= inputStartDate) {
    //  error  = true
    //   }else{
    //     error = false

    //   }

    //   }
    // });
    // return error ;
  };

  const getJobOrder = (accID) => {
    console.log("sdfdss", accID);
    if (assigneeAccountNumber?.length) {
      let data = assigneeAccountNumber?.find((i) => i.value == accID);
      console.log("datadada", data);
      if (data) {
        return {
          label: data.label,
          value: data.value,
        };
      } else {
        return {
          label: "",
          value: "",
        };
      }
    } else {
      return {
        label: "",
        value: "",
      };
    }
  };

  // console.log("sfkjndsknjksfs", filterApiData( "6", "01/07/2023", "01/21/2023"));
  return (
    <>
      <Card>
        <CardHeader className="pb-0">
          <H5>Paycode Authorization Assignment</H5>
        </CardHeader>
        <CardBody>
          <Grid>
            <Row>
              <Col xs={12}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6> Pay Code Templates</h6>
                </Typography>
              </Col>
            </Row>
            <Row className="mb-3">
             
              <Col xs={12} className=" text-left mt-2">
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Select PayCode Template
                  </InputLabel>
                  <Select
                    label="Select PayCode Template"
                    id="demo-simple-select1"
                    value={selectedTemplate ? selectedTemplate : ""}
                    onChange={(e) => {
                      // let copy = [...rowsData];
                      // copy[index]["PayCode"] = e.target.value;
                      // setRowsData(copy);
                      // setTemplateID(0);
                      console.log("s111 ::: ", e.target.value);
                      getTemplatePayCodeList(e.target.value);
                      setSelectedTemplate(e.target.value);
                    }}
                  >
                    {payCodeTemplates?.length &&
                      payCodeTemplates?.map((option, index) => (
                        <MenuItem key={index} value={option?.TypeCodeID}>
                          {option?.TypeCodeName}
                          
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            {/* {JSON.stringify(selectedTemplate)} */}
            <Row>
              <Col xs={3}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6>Pay Code Type</h6>
                </Typography>
              </Col>
              <Col xs={2}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6> Effective Start Date</h6>
                </Typography>
              </Col>
              <Col xs={2}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6> Effective End Date </h6>
                </Typography>
              </Col>
              <Col xs={1}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6> Status </h6>
                </Typography>
              </Col>
              <Col xs={1}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6>Total Authorized Hours</h6>
                </Typography>
              </Col>
              <Col xs={2}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6>Account No</h6>
                </Typography>
              </Col>
              <Col xs={1}></Col>
            </Row>
            {rowsData.length &&
              rowsData.map((data, index) => (
                <Row className="mt-2">
                  <Col xs={3} className=" text-left ">
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Pay Code Type
                      </InputLabel>
                      <Select
                        label="Pay Code Type"
                        id="demo-simple-select1"
                        value={data?.PayCode && data.PayCode}
                        onChange={(e) => {
                          let copy = [...rowsData];
                          copy[index]["PayCode"] = e.target.value;
                          setRowsData(copy);
                          checkValidDate(index, copy);

                          // setTemplateID(0);
                        }}
                      >
                        {filterPaycodes?.map((option, index) => (
                          <MenuItem key={index} value={option?.code}>
                            {option?.label} - {" "}
                            {option?.Description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={2} className=" text-left ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                      className="payroll-content-date13 w-full"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      format="MM/DD/YYYY"
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: 16,
                          height: 40,
                          padding: 0,
                        },
                        "& .MuiInputAdornment-root" : {
                          marginRight:"12px"
                        }
                      }}
                      inputVariant={"outlined"}
                        value={data.startDate ? dayjs(data.startDate) : null}
                        onChange={( e) => {
                          console.log("po", e?.$d);
                          let copy = [...rowsData];
                          copy[index]["startDate"] = moment(e?.$d).format("MM/DD/YYYY");
                          setRowsData(copy);
                          checkValidDate(index, copy);
                        }}
                        name="date"
                        hiddenLabel={true}
                        error={data?.error ? true : false}
                       renderInput={(props) =>
                         <TextField  
                          {...props} 
                         />}
                       />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={2} className=" text-left ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                      className="payroll-content-date13 w-full"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      format="MM/DD/YYYY"
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: 16,
                          height: 40,
                          padding: 0,
                        },
                        "& .MuiInputAdornment-root" : {
                          marginRight:"12px"
                        }
                      }}
                      inputVariant={"outlined"}
                        value={data.endDate ? dayjs(data.endDate) : null}
                        onChange={( e) => {
                          console.log("po", e?.$d);
                          let copy = [...rowsData];
                          copy[index]["endDate"] = moment(e?.$d).format("MM/DD/YYYY");
                          setRowsData(copy);
                          checkValidDate(index, copy);
                        }}
                        name="date"
                        hiddenLabel={true}
                        error={data?.error ? true : false}
                       renderInput={(props) =>
                         <TextField  
                          {...props} 
                         />}
                       />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={1} className=" text-left ">
                    <Switch
                      defaultChecked
                      value={data?.state}
                      onChange={(e) => {
                        console.log("eee", e);
                        let copy = [...rowsData];
                        copy[index]["state"] = e.target.checked;
                        setRowsData(copy);
                      }}
                    />
                  </Col>
                  <Col xs={1} className=" text-left ">
                    <TextField
                      type="number"
                      value={data?.TotalAuthorizedHours}
                      size="small"
                      onChange={(e) => {
                        let copy = [...rowsData];
                        copy[index]["TotalAuthorizedHours"] = parseInt(
                          e.target.value
                        );
                        setRowsData(copy);
                      }}
                    />
                  </Col>
                  <Col xs={2} className=" text-left ">
                    <Autocomplete
                      size={"small"}
                      autoHighlight={true}
                      autoSelect={true}
                      className="flex w-full mx-10 paroll-autocomplete "
                      onChange={(e, value) => {
                        console.log("eee job Order", value);
                        let copy = [...rowsData];
                        copy[index]["jobOrder"] = value.value;
                        setRowsData(copy);
                      }}
                      value={getJobOrder(data?.jobOrder)}
                      options={assigneeAccountNumber}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="MuiAutocomplete-input"
                          size="small"
                          variant="outlined"
                          hiddenLabel="true"
                          input
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={1} className=" ">
                    {" "}
                    {index === rowsData.length - 1 ? (
                      <button
                        className={" btn btn-success mt-1 "}
                        onClick={addTableRows}
                      >
                        +
                      </button>
                    ) : (
                      <button
                        className={"btn btn-outline-danger mt-1 "}
                        onClick={() => {
                          deleteTableRows(index, data.id);
                          let copy = [...rowsData];
                          checkValidDate(index, copy);
                        }}
                      >
                        X
                      </button>
                    )}
                  </Col>
                </Row>
              ))}
            <Button
              onClick={() => PayCodeAssignToEmployee()}
              variant="contained"
              className="m-10 bg-primary"
              style={{
                color: "#d0efe9",
              }}
            >
              {isUpdate ? "Update PayCode List" : "Assign PayCode List"}
            </Button>
          </Grid>
        </CardBody>
      </Card>
    </>
  );
};

export default PayCodeManagment;
