import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";
import { Label } from "reactstrap";
import { withStyles } from "@material-ui/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";

// const darkTheme = createTheme({
//   palette: {
//     type: "dark",
//   },
// });

// const lightTheme = createTheme({
//   palette: {
//     type: "light",
//   },
// });
// import MaskedInput from "react-text-mask";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(TextField);
const CssTextField1 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },

      "& .MuiInputBase-root": {
        height: (params) => (params.height ? params.height : "auto"),
        color: (params) => (params.color ? params.color : "inherit"),
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(KeyboardDatePicker);
function RequestFilter(props) {
  const { changeHeaderFilter, role, requestDetail, filterBy } = props;
  const [topLevelDark, setTopLevelDark] = React.useState(false);
  const theme = localStorage.getItem("mix_background_layout");
  useEffect(() => {
    if (theme === "dark-only") {
      setTopLevelDark(true);
    } else {
      setTopLevelDark(false);
    }
  }, [theme]);
  console.log("fiter", filterBy);
  return (
    // <MuiThemeProvider theme={topLevelDark ? lightTheme : darkTheme}>
    <TableRow>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="NPositionNo"
          name="nPositionNo"
          value={filterBy && filterBy.nPositionNo}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Title"
          name="title"
          value={filterBy && filterBy.title}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Job Order"
          name="jobOrder"
          value={filterBy && filterBy.jobOrder}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Term Id"
          name="termId"
          value={filterBy && filterBy.termId}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="NR Var"
          name="NRVar"
          value={filterBy && filterBy.NRVar}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Job Coller"
          name="jobColler"
          value={filterBy && filterBy.jobColler}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Range Current"
          name="rangeCurrent"
          value={filterBy && filterBy.rangeCurrent}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Range Next"
          name="rangeNext"
          value={filterBy && filterBy.rangeNext}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Pass Id"
          name="passId"
          value={filterBy && filterBy.passId}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="date"
          format="MM/dd/yyyy"
          onChange={(event) => {
            let value = event.target.value;
            console.log("effect", value);
            changeHeaderFilter({ target: { name: "UPDDate", value } });
          }}
          value={filterBy && filterBy.UPDDate}
          placeholder="UPD Date "
          // name="date"
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="date"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "NUPDDate", value } });
          }}
          value={filterBy && filterBy.ExpireDate}
          placeholder="NUPDDate"
          name="NUPDDate"
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="CLASS"
          name="CLASS"
          value={filterBy && filterBy.CLASS}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Catagory"
          name="catagory"
          value={filterBy && filterBy.catagory}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="ExemptNon"
          name="exemptNon"
          value={filterBy && filterBy.exemptNon}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="OCC Series"
          name="OCCSeries"
          value={filterBy && filterBy.OCCSeries}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="TDP"
          name="TDP"
          value={filterBy && filterBy.TDP}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Classified"
          name="classified"
          value={filterBy && filterBy.classified}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
    </TableRow>
    // </MuiThemeProvider>
  );
}

export default RequestFilter;
