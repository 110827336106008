import React, { useEffect, useState } from "react";
import { ShimmerTable } from "react-shimmer-effects";

import { Card, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../../../app/utility/helper";
import { fetchEmployeeByVendor } from "../../../../app/store/reducers/slices/vendorSlice";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import Header1 from "../../HoursReport/component/header1";

function EmployeeByVendor(props) {
  // const { vendorCode } = props;
  const params = useParams();
  const vendorCode = params.vendorId ? params.vendorId : props.vendorCode;
  console.log("vendorCode", vendorCode);

  const history = useNavigate();

  const vendorList = useSelector(({ vendorSlice }) => vendorSlice.vendorList);
  const employeeList = useSelector(
    ({ vendorSlice }) => vendorSlice.employeeByVendor
  );

  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({
    Department: "",
    PPE: "",
    VendorName: "",
    VendorNo: "",
  });
  const [totalDeduct, setTotalDeduct] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    if (employeeList.length) {
      let object = employeeList[0]["deduction"];
      console.log("object", object);
      let tempObjArr = [];
      object.map((i) => {
        tempObjArr.push({
          title: i.title,
          emp: 0,
          gov: 0,
          total: 0,
        });
      });
      let grandTotal = 0;
      employeeList.map((k) => {
        k.deduction.map((j) => {
          let indx = tempObjArr.findIndex((m) => m.title == j.title);
          console.log("indexat", indx);
          let gov = j.gov ? j.gov : 0;
          let emp = j.emp ? j.emp : 0;
          tempObjArr[indx]["emp"] = tempObjArr[indx]["emp"] + emp;
          tempObjArr[indx]["gov"] = tempObjArr[indx]["gov"] + gov;
          tempObjArr[indx]["total"] = tempObjArr[indx]["total"] + gov + emp;
          grandTotal = grandTotal + gov + emp;
        });
      });
      setTotalDeduct(tempObjArr);
      setGrandTotal(grandTotal);
    }
  }, [employeeList]);
  useEffect(() => {
    let info = vendorList.find((i) => {
      return i.VendorNo == vendorCode;
    });
    setIsLoading(true);
    dispatch(fetchEmployeeByVendor(vendorCode))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {});
    if (!info) {
      console.log("redirect");
      // return props.history.push("/report/deductionbyvendor");
      return history("/report/deductionbyvendor");
    }
    if (info) {
      let infoA = {
        Department: info.Department,
        PPE: info.PPE,
        VendorName: info.VendorName,
        VendorNo: info.VendorNo,
      };
      setVendorInfo(infoA);
    }

    //     "RunID":"1",
    // "RunDateTime":"2022-12-31",
    // "Department":"Department Of Administration",
    // "VendorNo":"S0097689",
    // "VendorName":"CALVO'S INSURANCE UNDWRITERS",
    // "totalEmployee":1,
    // "EmpFee":36,
    // "GovtShare":72,
    // "PPE":"2022-12-17"
    // setVendorInfo()
  }, [vendorCode]);

  const renderDeductionList = () => {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        {/* <Card
          style={{
            marginBottom: "10px",
            overflow: "hidden",
            padding: 0,
            borderRadius: "10px",
          }}
          elevation={3}
        > */}
        {isLoading ? (
          <ShimmerTable row={2} col={6} />
        ) : (
          <table className="table100">
            <thead>
              <tr>
                {/* <th colSpan={5} className="tc mainHead">
                  Deduction
                </th> */}
                {/* <th
                    colSpan={
                      4 +
                      (employeeList[0] && employeeList[0]["deduction"]
                        ? employeeList[0]["deduction"].length * 2
                        : "0")
                    }
                  >
                    <h2
                      style={{
                        padding: "8px",
                      }}
                    >
                      {`Employee Deduction for ${vendorInfo.VendorName} (PPE: ${vendorInfo.PPE})`}
                    </h2>
                  </th> */}
              </tr>
              <tr
                style={{
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                <th className="tl1" colSpan={3}></th>
                {employeeList.length &&
                  employeeList[0].deduction &&
                  employeeList[0].deduction.map((it) => {
                    return (
                      <>
                        <th
                          className="tl1"
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#1975d2",
                            color: "#fff",
                            borderRadius: "10px 10px 0px 0px",
                          }}
                        >
                          {it.title}
                        </th>
                      </>
                    );
                  })}

                <th className="tl1"></th>
              </tr>
              <tr
                style={{
                  // paddingBottom: "8px",
                  // paddingTop: "8px",
                  backgroundColor: "#dcdcea",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                <th className="tl1">Code</th>
                <th className="tl1">Employee Name</th>
                <th className="tl1">Dept Code</th>
                {employeeList.length &&
                  employeeList[0].deduction &&
                  employeeList[0].deduction.map((it) => {
                    return (
                      <>
                        <td className="tl1">Emp Share $</td>
                        <td className="tl1">Govt Share $</td>
                      </>
                    );
                  })}
                {/* <th className="tl1">Emp Share $</th>
                <th className="tl1">Govt Share $</th> */}
                <th className="tl1">Total</th>
              </tr>
            </thead>
            <tbody>
              {employeeList.length
                ? employeeList.map((i) => {
                    return (
                      <tr
                        style={{
                          borderBottomColor: "#EAEBE4",
                          borderBottomWidth: "1px",
                        }}
                      >
                        <td className="tl1">{i.ID}</td>
                        <td className="tl1">{i.EmployeeName}</td>
                        <td className="tl1">{i.DPDV}</td>
                        {i.deduction &&
                          i.deduction.map((it) => {
                            return (
                              <>
                                <td className="tl1">
                                  {it.emp ? formatMoney(it.emp) : "-"}
                                </td>
                                <td className="tl1">
                                  {it.gov ? formatMoney(it.gov) : "-"}
                                </td>
                              </>
                            );
                          })}
                        <td className="tl1">
                          {i.TotalDeduction
                            ? formatMoney(i.TotalDeduction)
                            : "-"}{" "}
                        </td>
                        {/* <td className="tl1">{formatMoney(i.EmpHealthInsFee)}</td>
                        <td className="tl1">{formatMoney(i.GovHealthInsFee)}</td>
                        <td className="tl1">{formatMoney(i.EmpHealthInsFee+i.GovHealthInsFee)}</td> */}
                      </tr>
                    );
                  })
                : null}
              {employeeList.length ? (
                <tr className="grandTd">
                  <td className="tl1" colSpan={3}>
                    Grand Total
                  </td>
                  {totalDeduct.map((it) => {
                    return (
                      <>
                        <td className="tl1">
                          {it.emp ? formatMoney(it.emp) : "-"}
                        </td>
                        <td className="tl1">
                          {it.gov ? formatMoney(it.gov) : "-"}
                        </td>
                      </>
                    );
                  })}
                  <td className="tl1">{formatMoney(grandTotal)}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        )}
        {/* </Card> */}
      </Grid>
    );
  };
  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-5">
        <div style={{ marginTop: "20px" }}>
          {/* <div class="rounded overflow-hidden shadow-lg"> */}
          {/* <Card
            style={{
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            elevation={3}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "right" }}>
                <table className="padTbl">
                  <tbody className="leftHead">
                    <tr>
                      <td className="cardSmallTitle">Run Date</td>
                      <td>:</td>
                      <td className="cardSmallValue">12/22/2022</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Program</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle" colSpan={3}>
                        DEPT/DIV SPECIFICATION
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ textAlign: "center" }}>
                <table className="table100 centerDiv padTbl">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b>Department of Administrative</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="t18">
                        <b>Standard Deduction By Employee</b>
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td className="cardSmallTitle">CHK: 6/24/2022</td>
                      <td className="cardSmallTitle">PPE: 6/18/2022</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>

              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "left" }}>
                <table className="rightHead fright  padTbl">
                  <tbody>
                    <tr>
                      <td className="cardSmallTitle">Page</td>
                      <td>:</td>
                      <td className="cardSmallValue">1</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Run Time</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>

                    <tr>
                      <td className="cardSmallTitle">Run ID</td>
                      <td>:</td>
                      <td className="cardSmallValue">PAYU004</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card> */}
          {/* </div> */}
        </div>
        <div style={{ marginBottom: "50px", marginTop: "20px" }}>
          <Grid container spacing={2}>
            {/* Deduction list */}
            {renderDeductionList()}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default EmployeeByVendor;
