import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableComponent from "./tableComponent";
import FilterList from "./filterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import ConfirmModal from "../../../../app/utility/ConfirmModal";
import axios from "axios";
import { Row } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import { Checkbox, Tooltip } from "@mui/material";
import DeductionDetailModal from "./DeductionDetailModal";
import { getAllDeductionTransactionDetailsById } from "../../../../app/utility/axiosService";
import ModalCustom from "../../../employee/Modal";
import { formatMoney } from "../../../../app/utility/helper";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, role } = props;

  const [filterBy, setFilter] = useState({
    id: null,
    deductionType: '',
    employeeID: null,
    employeeSeq: '',
    deductionType: null,
    deductionPlan: null,
    sequence: '',
    garnishmentTypeId: null,
    startDate: null,
    endDate: null,
    garnishmentMethod: null,
    paymentMonthlySch: null,
    taxable: false,
    accountNoForVendor: '',
    applyToOT: '',
    pctToDeduct: '',
    deductionPlanStatus: null,
    balanceDue: '',
    totalOriginal: '',
    paidToDate: null,
    isActive: true,
  });

  const [openDeductionDetailModal, setOpenDeductionDetailModal] = useState(false);
  const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  const [defaultRemoveData, setDefaultRemoveData] = useState(null);
  const [selectedTransactionDetails, setSelectedTransactionDetails] = useState(null);
  const [defaultTransactionDetails, setDefaultTransactionDetails] = useState([]);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;

      if (filterBy?.id) {
        dataList = dataList.filter((data) =>
          data?.id?.toString().includes(
            filterBy?.id?.toString()
          )
        );
      }
      if (filterBy?.employeeID) {
        dataList = dataList.filter((data) =>
          data?.employeeID?.toString().includes(
            filterBy?.employeeID?.toString()
          )
        );
      }
      if (filterBy?.employeeSeq) {
        dataList = dataList.filter((data) =>
          data?.employeeSeq?.toString().includes(
            filterBy?.employeeSeq?.toString()
          )
        );
      }
      if (filterBy?.sequence) {
        dataList = dataList.filter((data) =>
          data?.prioritySeq?.toString().includes(
            filterBy?.sequence?.toString()
          )
        );
      }
      if (filterBy?.deductionType) {
        dataList = dataList.filter((data) =>
          data?.deductionType?.toLowerCase()?.includes(
            filterBy?.deductionType?.toLocaleLowerCase()
          )
        );
      }
      if (filterBy?.deductionPlan) {
        let temp = props?.deductionPlans?.filter(o => o?.deductionName?.toLowerCase()?.includes(filterBy?.deductionPlan?.toLocaleLowerCase()));
        dataList = dataList.filter((data) =>
          temp.some(y => y.deductionCode == data.deductionCode)
        );
      }
      if (filterBy?.paymentMonthlySch) {
        let temp = props?.paymentOptions?.filter(o => o?.name?.toLowerCase()?.includes(filterBy?.paymentMonthlySch?.toLocaleLowerCase()));
        dataList = dataList.filter((data) =>
          temp.some(y => y.id == data.paymentMonthlySch)
        );
      }
      if (filterBy?.garnishmentMethod) {
        let temp = props?.garnishmentMethods?.filter(o => o?.name?.toLowerCase()?.includes(filterBy?.garnishmentMethod?.toLocaleLowerCase()));
        dataList = dataList.filter((data) =>
          temp.some(y => y.id == data.deductionMethod)
        );
      }
      if (filterBy?.totalOriginal) {
        dataList = dataList.filter((data) =>
          data?.totalAmountPaid?.toString().includes(
            filterBy?.totalOriginal?.toString()
          )
        );
      }
      if (filterBy?.balanceDue) {
        dataList = dataList.filter((data) =>
          data?.totalAmountPlanDue?.toString().includes(
            filterBy?.balanceDue?.toString()
          )
        );
      }
      if (filterBy?.applyToOT) {
        dataList = dataList.filter((data) =>
          data?.applyToOT?.toString().includes(
            filterBy?.applyToOT?.toString()
          )
        );
      }
      if (filterBy?.accountNoForVendor) {
        dataList = dataList.filter((data) =>
          data?.accountNoForVendor?.toString().includes(
            filterBy?.accountNoForVendor?.toString()
          )
        );
      }
      if (filterBy?.startDate) {
        dataList = dataList.filter((data) =>
          new Date(data.startDate) < new Date(filterBy.startDate)
        );
      }
      if (filterBy?.endDate) {
        dataList = dataList.filter((data) =>
          new Date(data.endDate) < new Date(filterBy.endDate)
        );
      }
      if (filterBy?.deductionPlanStatus) {
        let temp = props?.deductionPlanStatusList?.filter(o => o?.displayName?.toLowerCase()?.includes(filterBy?.deductionPlanStatus?.toLocaleLowerCase()));
        dataList = dataList.filter((data) =>
          temp.some(y => y.id == data.deductionPlanStatus)
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const changeHeaderFilter1 = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
  };

  const filterListByChecked = (e, name) => {
    setFilter({ ...filterBy, [name]: e.target?.checked });
  };

  const confimRemoveClick = async () => {
    axios.delete(`${process.env.REACT_APP_WEB_API_URL}EmployeeDeductionPlan/Delete/${defaultRemoveData.id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenRemoveAlert(false);
          toast.success('Record Deleted');
          props.getData();
          props.clearFields();

        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const fetchDeductionTransactionDetail = async (data) => {
    if (data?.id) {
      const res = await getAllDeductionTransactionDetailsById(data?.id);
      if (res?.status === 200) setDefaultTransactionDetails(res?.data);
    }
  };

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      oldId: data.id,
      employeeSeq: data.employeeSeq,
      deductionType: data.deductionType,
      garnishmentMethod: data.garnishmentMethod,
      garnishmentTypeId: data.garnishmentTypeId,
      paymentMonthlySch: data.paymentMonthlySch,
      // pctBasis: data.pctBasis,
      pctToDeduct: data.pctToDeduct,
      sequence: data.sequence,
      startDate: data.startDate,
      endDate: data.endDate,
      totalOriginal: data.totalOriginal,
      balanceDue: data.balanceDue,
      paidToDate: data.paidToDate,
    };

    // props.setDetail(info);
  };

  const columns = [
    {
      id: "Action",
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row>
            <Tooltip title="Deduction Details" placement="bottom">
              <FontAwesomeIcon
                icon={faEye}
                className="sub-header trashIcon"
                onClick={() => {
                  fetchDeductionTransactionDetail(tableMeta);
                  setOpenDeductionDetailModal(true);
                  setSelectedTransactionDetails(tableMeta);
                  // setDefaultRemoveData(tableMeta);
                  // setOpenRemoveAlert(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <FontAwesomeIcon
                icon={faTrash}
                className="sub-header trashIcon"
                onClick={() => {
                  setDefaultRemoveData(tableMeta);
                  setOpenRemoveAlert(true);
                }}
              />
            </Tooltip>
          </Row>
        },
      },
    },
    {
      id: "id",
      name: "id",
    },
    {
      id: "employeeID",
      name: "Employee Id",
    },
    {
      id: "employeeSeq",
      name: "Employee Sequence",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : '-';
        },
      },
    },
    {
      id: "deductionType",
      name: "Deduction Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.deductionTypes?.length > 0) {
            temp = props.deductionTypes.find(o => o.Code == value);
          }
          return temp ? temp?.Code : '';
        },
      },
    },
    {
      id: "deductionCode",
      name: "Deduction Plan",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.deductionPlans?.length > 0) {
            temp = props.deductionPlans.find(o => o.deductionCode == value);
          }
          return temp ? temp?.deductionName : '';
        },
      },
    },
    {
      id: "prioritySeq",
      name: "Sequence",
    },
    // {
    //   id: "garnishmentTypeId",
    //   name: "Garnishment Type",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let temp = null;
    //       if (props?.garnishmentTypes?.length > 0) {
    //         temp = props.garnishmentTypes.find(o => o.id == value);
    //       }
    //       return temp ? temp?.name : '';
    //     },
    //   },
    // },
    {
      id: "paymentMonthlySch",
      name: "Payment Monthly Schedule",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.paymentOptions?.length > 0) {
            temp = props?.paymentOptions.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "deductionMethod",
      name: "Deduction Method",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.garnishmentMethods?.length > 0) {
            temp = props.garnishmentMethods.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "startDate",
      name: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.startDate).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "endDate",
      name: "End Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(tableMeta.endDate).format("MM-DD-YYYY");
        },
      },
    },
    {
      id: "deductionAmount",
      name: "Deduction Amount",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta?.deductionMethod == 1) {
            return `${tableMeta.deductionAmount}%`;
          }
          if (tableMeta?.deductionMethod == 2) {
            return `${formatMoney(Number(tableMeta.deductionAmount) ? Number(tableMeta.deductionAmount) : 0)}`;
          }
          else return '';
        },
      },
    },
    // {
    //   id: "pctBasis",
    //   name: "Percent Basis",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return `${tableMeta.pctBasis}%`;
    //     },
    //   },
    // },
    {
      id: "applyToOT",
      name: "Apply to OT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${tableMeta.applyToOT}%`;
        },
      },
    },
    {
      id: "totalAmountPaid",
      name: "Total Original",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${formatMoney(Number(tableMeta.totalAmountPaid) ? Number(tableMeta.totalAmountPaid) : 0)}`;
        },
      },
    },
    {
      id: "totalAmountPlanDue",
      name: "Balance Due",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${formatMoney(Number(tableMeta.totalAmountPlanDue) ? Number(tableMeta.totalAmountPlanDue) : 0)}`;
        },
      },
    },
    // {
    //   id: "paidToDate",
    //   name: "Paid To Date",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return moment(tableMeta.paidToDate).format("MM-DD-YYYY");
    //     },
    //   },
    // },
    {
      id: "accountNoForVendor",
      name: "Vendor AccountNo."
    },
    {
      id: "deductionPlanStatus",
      name: "Deduction Plan Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (props?.deductionPlanStatusList?.length > 0) {
            temp = props.deductionPlanStatusList.find(o => o.id == value);
          }
          return temp ? temp?.displayName : '';
        },
      },
    },
    {
      id: "taxable",
      name: "Taxable",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
          checked={tableMeta.taxable}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
        },
      },
    },
    {
      id: "isActive",
      name: "isActive",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox
          checked={tableMeta.isActive}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
        },
      },
    },
  ];

  return (
    <>
      <TableComponent
        columns={columns}
        filteredList={filteredList}
        totalCount={props.totalCount}
        setSkipCount={props.setSkipCount}
        onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <FilterList
              changeHeaderFilter={changeHeaderFilter1}
              filterListByChecked={filterListByChecked}
              role={role}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      />
      <ConfirmModal
        open={openRemoveAlert}
        setOpen={setOpenRemoveAlert}
        setOpenRemoveAlert={setOpenRemoveAlert}
        confimClick={confimRemoveClick}
        message={
          "Are You Sure you want to delete?"
        }
        modalHeader="Alert Message"
      />
      <ModalCustom
        open={openDeductionDetailModal}
        title={<span>Employee {
          props?.defaultDeductionType === 'GARNISHMENT' ? 'Garnishment' : 
          props?.defaultDeductionType === 'BENEFITS' ? 'Benefits' :
          props?.defaultDeductionType === 'MISCELLANEOUS' ? 'Miscellaneous' : ''
      } Transactions</span>}
        handleClose={() => {
          setOpenDeductionDetailModal(false);
        }}
        closeButton={true}
      >
        <DeductionDetailModal
          defaultTransactionDetails={defaultTransactionDetails}
          selectedTransactionDetails={selectedTransactionDetails}
          defaultDeductionType={props?.defaultDeductionType}
        />
      </ModalCustom>
    </>
  );
};

export default DataTable;