import React, { useEffect, useState } from "react";
import {  useAuth } from "oidc-react";

const Authorized = () => {
  
  const {signOut, signOutRedirect} = useAuth();

  
  
  useEffect(() => {
    
    setTimeout(()=>{ 
      signOutRedirect();
    },3000)
    
    
  }, []); 

  
    
    return (
      
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <h1>You are not an Authorized User.</h1>
      </div>
      
    );
  }
 





export default Authorized;