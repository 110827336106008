import React, { Component, useEffect, useState } from "react";

import { Row } from "reactstrap";

import Autocomplete from "@mui/material/Autocomplete";

import { Edit, Delete } from "@mui/icons-material";

import { CircularProgress, Tooltip } from "@mui/material";

import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./style.css";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { IconButton, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import { RemoveCircleTwoTone } from "@mui/icons-material";
import clsx from "clsx";
import ModalCustom from "./Modal";
import SavedQueries from "./SavedQueries";
import {
  fetchPlaceholderCols,
  userGroupStore,
} from "../../app/store/reducers/slices/querySlice";

import { fetchAdvanceSearchData } from "../../app/store/reducers/slices/configurationSlice";
import { Card, Col, Label } from "reactstrap";
import { fetchEmployeeSearch } from "../../app/store/reducers/slices/employeeSlice";
import SaveNewQuery from "./SaveNewQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBadgeCheck,
  faBroom,
  faFloppyDisk,
  faMagnifyingGlass,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";
import LoadingPanel from "../Common/telerik/loadingPanel";

const operator = [
  {
    operation: "=",
    name: "Equal To",
  },
  {
    operation: "!=",
    name: "Not Equal To",
  },
  {
    operation: "isnull",
    name: "Is Empty",
  },
  {
    operation: "end",
    name: "Ends With",
  },
  {
    operation: ">",
    name: "Greater Than",
  },
  {
    operation: "<",
    name: "Less Than",
  },
  {
    operation: ">=",
    name: "Greater Than And Equal to",
  },
  {
    operation: "<=",
    name: "Less Than And Equal to",
  },
  {
    operation: "wildcard",
    name: "Wild Card",
  },
  {
    operation: "parse",
    name: "Parse",
  },
  {
    operation: "array",
    name: "Array",
  },
];

const operatorForString = [
  {
    operation: "=",
    name: "Equal To",
  },
  {
    operation: "!=",
    name: "Not Equal To",
  },

  {
    operation: "like",
    name: "Contains",
  },
  {
    operation: "notlike",
    name: "Not Contains",
  },

  {
    operation: "isnull",
    name: "Is Empty",
  },
  {
    operation: "start",
    name: "Starts With",
  },
  {
    operation: "end",
    name: "Ends With",
  },
  {
    operation: "wildcard",
    name: "Wild Card",
  },
  {
    operation: "parse",
    name: "Parse",
  },
  {
    operation: "array",
    name: "Array",
  },
];

operator.sort((a, b) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
);

const CustomForm = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    postQueries: [],
    loading: false,
    filters: [],
    openSave: false,
    openSaveQuery: false,
    saveQuery: false,
    role: "",
    data: [],
  });

  const colsPlaceholder = useSelector(
    (state) => state.querySlice.colsPlaceholder
  );

  const userGroup = useSelector((state) => state.querySlice.groups);

  const initialState = useSelector((state) => state.querySlice.initialValues);

  const advanceSearchData = useSelector(
    (state) => state.configurationSlice.advanceSearchData
  );
  useEffect(() => {
    apiGet();
  }, []);
  
  useEffect(() => {
    setState({ ...state, loading: props.loading });
  }, [JSON.stringify(props)]);

  const handleSave = () => {
    setState({ ...state, openSave: !state.openSave }, () => {});
  };

  const getData = (queries) => {
    setState({ ...state, openSaveQuery: false, saveQuery: false });
  };

  const handleViewSavedQuery = () => {
    setState({ ...state, openSaveQuery: !state.openSaveQuery }, () => {});
  };

  const filterColumnList = (columnArray) => {
    let searchItem = colsPlaceholder?.filter((i) => {
      return !["Inactive_Status", "Inactive_Status_Title"].includes(
        i.column_name
      );
    });
    searchItem = searchItem?.filter((i) => {
      return i.column_title !== "";
    });

    return searchItem;
  };

  const getDataType = (columnName) => {
    let searchItem = colsPlaceholder?.find((i) => {
      return i.column_name == columnName;
    });
    let result = searchItem.data_type;
    console.log("result", searchItem, "#", columnName, "---", result);
    if (["Stat"].includes(columnName)) {
      return "VARCHAR(MAX)";
    } else {
      if ((result ? result.toUpperCase() : "") == "VARCHAR") {
        return "VARCHAR(MAX)";
      } else {
        return result;
      }
    }
  };

  const onFinished = (values) => {
    const { queries } = values;
    const temp = [];
    let isValidDate = true;
    let myop = "";

    queries.map((element, index) => {
      console.log("elemnt", element);
      if (typeof element.value !== "undefined") {
        element.dataType = "";

        if (typeof element.operator === "undefined") {
          element.operator = "=";
        }

        if (typeof element.condition === "undefined") {
          element.condition = "AND";
        }
        if (element.column) {
          element.columnName = element.column;
          element.dataType = getDataType(element.column);
        }

        const menuQueries = { ...element };
        menuQueries.value = menuQueries.value;
        if (index == 0) {
          temp.push({
            ...menuQueries,
          });
        } else if (index > 0) {
          temp.push({
            ...menuQueries,
          });
        }
      }
    });

    if (state.openSave) {
      const list = [];

      queries.forEach((item) => {
        list.push({
          columnName: item.column,
          // condition: item.condition,
          dataType: "",
          value: item.value.toUpperCase(),
          operator: item.operator,
        });
      });

      saveQueryApi({
        queryName: values.query_name,
        permissionType: state.role,
        permissionValue: state.role,
        // state.userGroupID.length === 0 ? null : state.userGroupID,
        userId: props.userId,
        tableName: "EmployeeMaster",
        params: list,
        columns: "*",
        limit: 10,
        page: 1,
        orderBy: "EmployeeName_FML",
        sortBy: "DESC",
      });
    } else {
      delete temp[0].condition;

      setState(
        {
          ...state,
          loading: true,
          postQueries: temp,
          searchClicked: true,
        },
        apiPost(temp)
      );
      console.log("TEAM-POST");
    }
  };

  const saveQueryApi = (data) => {
    axios
      .post(
        // `query/save`,
        `${process.env.REACT_APP_API_URL}/advancesearch/v2/save`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        toast.success("Query Saved");
        setState({ ...state, openSave: false });
      })
      .catch((error) => {
        toast.error("something went wrong");
      });
    // axios
  };

  const apiPost = (data) => {
    props.setLoading(true);
    let tblname =  "EmployeeMaster";
    if(props.role=='supervisor'){
      tblname = 'vw_employee_supervisor'
    }else if(props.role=='timekeeper'){
      tblname = 'vw_employee_timekeeper'
    }else{
      tblname = 'EmployeeMaster'
    }

    let payload = {
      tableName: tblname,
      params: data ,
      columns: "*",
      limit: 2000,
      orderBy: "ID",
      sortBy: "DESC",
    };
    localStorage.setItem("adv_emp_front_query", JSON.stringify(payload));
    if (["supervisor", "timekeeper"].includes(props.role)) {
      data.push({
        "value": props.userId,
        "operator": "=",
        "column": "SupervisorEmployeeID",
        "label": "",
        "dataType": "bigint",
        "columnName": "SupervisorEmployeeID",
    });
    payload.params = data
    }
    dispatch(fetchEmployeeSearch(payload))
      .then(() => {
       setState({ ...state, loading: false });
        props.setLoading(false);
      })
      .catch(() => {
        setState({ ...state, loading: false });
        props.setLoading(false);
      });
  };

  const apiGet = () => {
    setState({ ...state, loading: false });
    dispatch(fetchAdvanceSearchData());
    dispatch(userGroupStore());
    dispatch(fetchPlaceholderCols({ role: props.role }));
  };
  const getLoadData = (type) => {
    let advData = advanceSearchData;
    console.log("type ====>", type, advData);

    console.log("advDAta", advData);
    if (type == "DPDV") {
      return advData?.DPDV;
    } else if (type == "Empl_Type") {
      return advData?.EmpType;
    } else if (type == "Inactive_Status") {
      return advData?.Inactive_Status;
    } else if (type == "Education") {
      return advData.Education;
    } else if (type == "Emp_Status_Title") {
      return advData?.EmpStatus;
    } else if (type == "Nposition_No") {
      return advData?.Nposition_No;
    } else if (type == "Ethnic_Group") {
      return advData?.Ethnic_Group;
    } else {
      return [];
    }
  };

  const onSelectUserGroup = (selected) => {
    console.log("selected 123", selected);
    // let groupArray = selected.map((i) => {
    //   return i.id;
    // });
    setState({ ...state, role: selected });
  };
  const onRemoveUserGroup = (selected) => {
    console.log("selected remove", selected);
    let groupArray = selected.map((i) => {
      return i.id;
    });
    setState({ ...state, userGroupID: groupArray });
  };
  const handleClear = (resetForm) => {
    resetForm();
    setState({ ...state, postQueries: [] });
    localStorage.removeItem("adv_emp_front_query");
    dispatch(fetchEmployeeSearch([]));

    let sampleData = {
      query_name: "",
      user_group_id: "",
      queries: [
        {
          value: " ",
          operator: "=",
          column: " ",
          // condition: "AND",
        },
      ],
    };
    setState({ ...state, data: sampleData });
  };

  // const data = initialState;
  useEffect(() => {
    let oldInitial = initialState;
    let dataArray = [];
    let adv_issue_query = localStorage.getItem("adv_emp_front_query");
    let adv_issue_queryArray = JSON.parse(adv_issue_query);
    if (
      adv_issue_queryArray &&
      adv_issue_queryArray.params &&
      adv_issue_queryArray.params.length
    ) {
      console.log("xxxx", adv_issue_queryArray["params"]);
      dataArray = {
        query_name: oldInitial["query_name"],
        user_group_id: oldInitial["user_group_id"],
        queries:
          advanceSearchData?.DPDV && advanceSearchData?.DPDV.length
            ? adv_issue_queryArray["params"]
            : [],
      };
    } else {
      dataArray = initialState;
    }
    setState({ ...state, data: dataArray });
  }, [advanceSearchData?.DPDV]);

  return (
    <>
      <Card style={{ width: "100%", padding: "10px" }}>
        <Col lg={12} md={12} sm={12} xs={12} className="">
          {props.loading && (
            <LoadingPanel></LoadingPanel>
            // <CircularProgress
            //   size={24}
            //   style={{ marginLeft: 15, position: "relative", top: 4 }}
            // />
          )}
          <div
            className={clsx("form-parent-div1")}
            style={{ justifyContent: "left" }}
          >
            <Formik
              initialValues={state.data}
              enableReinitialize={true}
              onSubmit={(values, options) => onFinished(values, options)}
            >
              {({
                values,
                errors,
                handleSubmit,
                setValue,
                setFieldValue,
                resetForm,
              }) => (
                <form autoComplete="Off" onSubmit={handleSubmit}>
                  <ModalCustom
                    open={state.openSaveQuery}
                    handleClose={handleViewSavedQuery}
                    closeButton
                    fullWidth
                  >
                    <SavedQueries
                      getData={getData}
                      tableName="EmployeeMaster"
                      edit
                    />
                  </ModalCustom>

                  <FieldArray
                    name="queries"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {console.log("values 456", values)}
                          {values &&
                          values.queries &&
                          values.queries.length > 0 ? (
                            <>
                              {values.queries.map((friend, index) => (
                                <>
                                  <div
                                    className="grid mt-10 grid-cols-1"
                                    key={index}
                                  >
                                    {/* <div /> */}

                                    <div
                                      className="flex justify-start mt-2 mb-3 gap-12"
                                      style={{ gap: "10px" }}
                                    >
                                      <Row style={{ width: "100%" }}>
                                        {index > 0 && (
                                          <Col lg={2} md={2} sm={12} xs={12}>
                                            <div style={{ width: "100%" }}>
                                              <FormControl
                                                variant="outlined"
                                                className="w-full"
                                                style={{ width: "100%" }}
                                              >
                                                <Label htmlFor="forType">
                                                  Select Field
                                                </Label>
                                                <Field
                                                  as={Select}
                                                  native
                                                  size="small"
                                                  value={
                                                    values.queries[index]
                                                      .condition
                                                  }
                                                  // label="Select Field"
                                                  inputProps={{
                                                    name: `queries.${index}.condition`,
                                                    id: "forType",
                                                  }}
                                                  defaultValue="AND"
                                                  required
                                                >
                                                  <option value="AND">
                                                    AND
                                                  </option>
                                                  <option value="OR">OR</option>
                                                </Field>
                                              </FormControl>
                                            </div>
                                          </Col>
                                        )}
                                        <Col lg={2} md={2} sm={12} xs={12}>
                                          {console.log(
                                            "values.queries[index].operator",
                                            values.queries[index].column
                                          )}
                                          <div style={{ width: "100%" }}>
                                            <FormControl
                                              variant="outlined"
                                              className="w-full"
                                              style={{ width: "100%" }}
                                            >
                                              <Label>Select Field</Label>
                                              <Field
                                                st
                                                className="yourMaterialUI-Select"
                                                as={Select}
                                                native
                                                size="small"
                                                value={
                                                  values.queries[index].column
                                                    ? values.queries[index]
                                                        .column
                                                    : null
                                                }
                                                inputProps={{
                                                  name: `queries.${index}.column`,
                                                  id: "forType",
                                                }}
                                                required
                                                onChange={(e) => {
                                                  // values[
                                                  //   `queries.${index}.value`
                                                  // ] = '';
                                                  setFieldValue(
                                                    `queries.${index}.value`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `queries.${index}.label`,
                                                    ""
                                                  );
                                                  setFieldValue(
                                                    `queries.${index}.column`,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                <option value="null">
                                                  Select Field
                                                </option>
                                                {console.log(
                                                  "propsCol",
                                                  colsPlaceholder
                                                )}
                                                {colsPlaceholder?.length <=
                                                  0 && (
                                                  <option value="null">
                                                    {" "}
                                                    Loading......{" "}
                                                  </option>
                                                )}

                                                {filterColumnList()?.map(
                                                  (item, colIndex) =>
                                                    item.value != "docno" && (
                                                      <option
                                                        key={colIndex}
                                                        value={item.column_name}
                                                      >
                                                        {item.column_title}
                                                      </option>
                                                    )
                                                )}
                                              </Field>
                                            </FormControl>
                                          </div>
                                        </Col>
                                        <Col lg={2} md={2} sm={12} xs={12}>
                                          <div>
                                            <FormControl
                                              variant="outlined"
                                              className="w-full"
                                              style={{ width: "100%" }}
                                            >
                                              <Label>Select Operator</Label>

                                              <Field
                                                as={Select}
                                                native
                                                value={
                                                  values.queries[index].operator
                                                }
                                                size="small"
                                                inputProps={{
                                                  name: `queries.${index}.operator`,
                                                  id: "forType",
                                                }}
                                                required
                                              >
                                                {([
                                                  "created_at",
                                                  "ID",
                                                  "Annual_Salary",
                                                  "Rate",
                                                ].includes(
                                                  values.queries[index].column
                                                )
                                                  ? operator
                                                  : operatorForString
                                                ).map((item) => {
                                                  return (
                                                    <option
                                                      value={item.operation}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  );
                                                })}
                                              </Field>
                                            </FormControl>
                                          </div>
                                        </Col>

                                        {values.queries[index].operator !==
                                          "isnull" && (
                                          <>
                                            {![
                                              "DPDV",
                                              "Education",
                                              "Inactive_Status",
                                              "Emp_Status_Title",
                                              "Nposition_No",
                                              "Ethnic_Group",
                                              "record_type",
                                              "Empl_Type",
                                            ].includes(
                                              values.queries[index].column
                                            ) && (
                                              <Col lg={5} md={5}>
                                                <div
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Label>Enter Value</Label>
                                                  <Field
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    name={`queries.${index}.value`}
                                                    as={TextField}
                                                    className="styleField"
                                                    variant="outlined"
                                                    placeholder={
                                                      colsPlaceholder &&
                                                      colsPlaceholder.find(
                                                        (i) =>
                                                          i.column_name ==
                                                          values.queries[index]
                                                            .column
                                                      )
                                                        ? colsPlaceholder.find(
                                                            (i) =>
                                                              i.column_name ==
                                                              values?.queries[
                                                                index
                                                              ].column
                                                          ).column_name
                                                        : "Enter value"
                                                    }
                                                    required
                                                  />
                                                </div>
                                              </Col>
                                            )}

                                            {[
                                              "DPDV",
                                              "Education",
                                              "Inactive_Status",
                                              "Emp_Status_Title",
                                              "Nposition_No",
                                              "Ethnic_Group",
                                              "doc_type",
                                              "record_type",
                                              "Empl_Type",
                                            ].includes(
                                              values.queries[index].column
                                            ) && (
                                              <Col
                                                lg={4}
                                                md={4}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Autocomplete
                                                  size="small"
                                                  options={getLoadData(
                                                    values.queries[index].column
                                                  )}
                                                  autoHighlight
                                                  autoComplete
                                                  autoSelect
                                                  selectOnFocus
                                                  getOptionLabel={(option) =>
                                                    option.label
                                                  }
                                                  renderOption={(
                                                    props,
                                                    option
                                                  ) => {
                                                    return (
                                                      <li
                                                        {...props}
                                                        key={
                                                          option.label +
                                                          Math.random() *
                                                            100000000
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                        }}
                                                      >
                                                        {option.label}
                                                      </li>
                                                    );
                                                  }}
                                                  defaultValue={
                                                    values.queries[index].value
                                                      ? {
                                                          value:
                                                            values.queries[
                                                              index
                                                            ]?.value,
                                                          label:
                                                            values.queries[
                                                              index
                                                            ]?.label,
                                                        }
                                                      : null
                                                  }
                                                  value={
                                                    values.queries[index].value
                                                      ? {
                                                          value:
                                                            values.queries[
                                                              index
                                                            ]?.value,
                                                          label:
                                                            values.queries[
                                                              index
                                                            ]?.label,
                                                        }
                                                      : null
                                                  }
                                                  onChange={(event, value) => {
                                                    if (value) {
                                                      console.log(
                                                        "value 4567",
                                                        value
                                                      );
                                                      values.queries[
                                                        index
                                                      ].value = value.value;
                                                      values.queries[
                                                        index
                                                      ].label = value.label;
                                                      values[
                                                        `queries.${index}.value`
                                                      ] = value.value;

                                                      setFieldValue(
                                                        `queries.${index}.value`,
                                                        value.value
                                                      );
                                                      setFieldValue(
                                                        `queries.${index}.label`,
                                                        value.label
                                                      );
                                                      // setFieldValue(`queries.${index}.column`,e.target.value);

                                                      // setFieldValue(`queries.${index}.value`,value.value)
                                                    }
                                                  }}
                                                  name={`queries.${index}.value`}
                                                  renderInput={(params) => (
                                                    <>
                                                      <Label>Enter Value</Label>
                                                      <TextField
                                                        {...params}
                                                        label="Enter Value"
                                                        variant="outlined"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </Col>
                                            )}
                                          </>
                                        )}

                                        <Col lg={1} md={1}>
                                          <IconButton
                                            disableRipple
                                            onClick={() =>
                                              values.queries.length !== 1 &&
                                              arrayHelpers.remove(index)
                                            }
                                            style={{
                                              marginTop: "20px",
                                            }}
                                          >
                                            <RemoveCircleTwoTone />
                                          </IconButton>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      <div>
                                        {values.queries.length === 1 ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            <div>
                                              <Button
                                                variant="contained"
                                                onClick={() =>
                                                  arrayHelpers.insert(
                                                    index + 1,
                                                    {
                                                      value: "",
                                                      operator: "=",
                                                      column: "",
                                                      condition: "AND",
                                                    }
                                                  )
                                                }
                                                style={{
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="bg-primary"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faRectangleList}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Add Field
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                color="secondary"
                                                variant="contained"
                                                style={{
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="bg-primary"
                                                onClick={() => {
                                                  handleClear(resetForm);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faBroom}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Clear Query
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                type="submit"
                                                style={{
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="   bg-primary"
                                                variant="contained"
                                                // onClick={() => {
                                                //   onSearchClick();
                                                // }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faMagnifyingGlass}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Search Query
                                              </Button>
                                            </div>
                                          </div>
                                        ) : values.queries.length - 1 !==
                                          index ? (
                                          <span />
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            <div>
                                              <Button
                                                variant="contained"
                                                onClick={() =>
                                                  arrayHelpers.insert(
                                                    index + 1,
                                                    {
                                                      value: "",
                                                      operator: "=",
                                                      column: "",
                                                      condition: "AND",
                                                    }
                                                  )
                                                }
                                                style={{
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="bg-primary"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faRectangleList}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Add Field
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                variant="contained"
                                                onClick={() => {
                                                  handleClear(resetForm);
                                                }}
                                                className="   bg-primary"
                                                style={{
                                                  textTransform: "none",

                                                  color: "white",
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faBroom}
                                                  style={{
                                                    color: "white",

                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Clear Query
                                              </Button>
                                            </div>
                                            <div>
                                              <Button
                                                type="submit"
                                                style={{
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="   bg-primary"
                                                variant="contained"
                                                // onClick={() => {
                                                //   onSearchClick();
                                                // }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faMagnifyingGlass}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                                Search Query
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div>
                                        {!state.openSave && (
                                          <>
                                            {values.queries.length - 1 ===
                                              index && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "10px",
                                                }}
                                              >
                                                <div>
                                                  <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                      handleViewSavedQuery();
                                                    }}
                                                    style={{
                                                      textTransform: "none",

                                                      color: "white",
                                                    }}
                                                    className="bg-primary"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faBadgeCheck}
                                                      style={{
                                                        color: "white",
                                                        marginRight: "5px",
                                                      }}
                                                    />
                                                    Select Query
                                                  </Button>
                                                </div>
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    className="bg-primary"
                                                    style={{
                                                      textTransform: "none",
                                                      color: "white",
                                                    }}
                                                    disabled={state.openSave}
                                                    onClick={() => {
                                                      if (
                                                        values.queries[0]
                                                          .column !== "" &&
                                                        values.queries[0]
                                                          .value !== ""
                                                      ) {
                                                        handleSave();
                                                      }
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faFloppyDisk}
                                                      style={{
                                                        color: "white",
                                                        marginRight: "5px",
                                                      }}
                                                    />
                                                    Save Query
                                                  </Button>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}

                              <div className="flex justify-center">
                                {state.openSave && (
                                  <ModalCustom open={state.openSave} fullWidth>
                                    <SaveNewQuery
                                      userGroup={userGroup}
                                      onSelectUserGroup={onSelectUserGroup}
                                      onRemoveUserGroup={onRemoveUserGroup}
                                      onSubmit={onFinished}
                                      values={values}
                                      selected={state.role}
                                      handleClose={handleSave}
                                    />
                                  </ModalCustom>
                                )}
                              </div>
                            </>
                          ) : (
                            <div style={{ minHeight: "100px" }}>
                              <LoadingPanel></LoadingPanel>
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </form>
              )}
            </Formik>
          </div>

          <div className="mt-2" style={{ width: "100%" }}>
            {state.loading && (
              <LoadingPanel></LoadingPanel>
              // <CircularProgress
              //   size={24}
              //   style={{ marginLeft: 15, position: "relative", top: 4 }}
              // />
            )}
          </div>
        </Col>
      </Card>
    </>
  );
};
export default CustomForm;
