import Typography from "@material-ui/core/Typography";
import React from "react";
import { Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import { addOrUpdateStateTaxRecord } from "../../../../app/utility/axiosService";

function HomeHeader(props) {

  const checkvalid = () => {
    const { stateId, disabilityInsurance, stateIncomeTax, localTax, familyLeaveInsurance, stateIndustrialInsurance, numberOfQualifyingChildrenUnderAge17, numberOfOtherDependents, medicareTax, socialSecurityTax } = props?.detail;
    if (!stateId || !numberOfQualifyingChildrenUnderAge17 || !numberOfOtherDependents || !medicareTax || !socialSecurityTax) {
      return false;
    } else if (stateId === 'CA' && (!disabilityInsurance || !stateIncomeTax)) {
      return false;
    } else if (stateId === 'WA' && (!localTax || !familyLeaveInsurance || !stateIndustrialInsurance)) {
      return false;
    } else if (stateId === 'HI' && !stateIncomeTax) {
      return false;
    } else {
      return true;
    }
  };

  const saveData = async () => {
    if (!checkvalid()) {
      toast.error('Please validate values!');
      return false;
    }

    let payload = { 
      id: props.detail?.oldId??0,
      id: props?.tableList?.find(o => o.code === props?.detail?.stateId)?.id,
      code: props?.detail?.stateId,
      name: props?.tableList?.find(o => o.code === props?.detail?.stateId)?.name,
      disabilityInsurance: props?.detail?.disabilityInsurance,
      stateIncomeTax: props?.detail?.stateIncomeTax,
      localTax: props?.detail?.localTax,
      familyLeaveInsurance: props?.detail?.familyLeaveInsurance,
      stateIndustrialInsurance: props?.detail?.stateIndustrialInsurance,
      numberOfQualifyingChildrenUnderAge17: props?.detail?.numberOfQualifyingChildrenUnderAge17,
      numberOfOtherDependents: props?.detail?.numberOfOtherDependents,
      medicareTax: props?.detail?.medicareTax,
      socialSecurityTax: props?.detail?.socialSecurityTax,
    };

    const res = await addOrUpdateStateTaxRecord(payload);
    if (res.status === 200) {
      toast.success('Record Added.');
      props.getData();
      props.clearFields();
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Tax Master</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.oldId ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.oldId ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={props.clearFields}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;