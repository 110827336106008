
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";



import React from "react";
import { Label } from "reactstrap";

function RequestFilter(props) {
  const { changeHeaderFilter, filterBy } = props;

  return (
    <TableRow className="page-body-wrapper">
      <TableCell size="small" className="py-6 px-8"></TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="ID"
          name="ID"

          value={filterBy.ID}
          onChange={(event) => changeHeaderFilter(event)}

        />


      </TableCell>

      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Employee Name"
          name="EmployeeName"

          value={filterBy.EmployeeName}
          onChange={(event) => changeHeaderFilter(event)}

        />

      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="SSN"
          name="SSN"
          value={filterBy.SSN}
          onChange={(event) => changeHeaderFilter(event)}

        />

      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Department"
          name="Department"
          value={filterBy.Department}
          onChange={(event) => changeHeaderFilter(event)}

        />

      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Status"
          name="Status"

          value={filterBy.Status}
          onChange={(event) => changeHeaderFilter(event)}

        />

      </TableCell>
    </TableRow>
  );
}

export default RequestFilter;
