import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardBody, CardHeader, Col, Form, Label, Row } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { getAllStateTax } from "../../../app/utility/axiosService";

const StateTaxW4Info = (props) => {
    const { employeeDefaultW4Data, userInfo, setUserInfo, isUpdate } = props;
    // const [filingStatusList, setFilingStatusList] = useState([]);
    const [isWashingtonState, setIsWashingtonState] = useState(false);
    const [isHawaiiState, setIsHawaiiState] = useState(false);
    const [isLIRate, setIsLIRate] = useState(false);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        fetchAllState();
    }, []);

    useEffect(() => {
        if (employeeDefaultW4Data && !isUpdate) {
          if (employeeDefaultW4Data?.stateCode === 'WA') setIsWashingtonState(true);
          if (employeeDefaultW4Data?.isWashingtonLAndIRate) setIsLIRate(true);
          if (employeeDefaultW4Data?.stateCode === 'HI') setIsHawaiiState(true);
        }
    }, [JSON.stringify(employeeDefaultW4Data), isUpdate]);

    const fetchAllState = async () => {
        const res = await getAllStateTax();
        if (res?.status === 200) {
            let temp = [];
            res?.data?.forEach(o => {
                if (o.code === 'CA' || o.code === 'WA' || o.code === 'HI' || o.code === 'GU') {
                    temp.push(o);
                }
            })
            setStateList(temp);
        }
    };

    return (
        <>
        <CardHeader className="pb-0">
            <H5>State or Territory Taxes</H5>
        </CardHeader>
        <CardBody>
            <Form>
            <Row className="g-3 mt-1">
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full mx-10"
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    style={{ width: "100%" }}
                    size="small"
                    inputProps={{
                    style: {
                        fontSize: "14px",
                        paddingTop: "12px",
                        color: "black",
                    },
                    }}
                    // InputLabelProps={{ shrink: userInfo?.state ? true : false }}
                    label="State or Territory"
                    value={userInfo?.state ? userInfo.state : null}
                    onChange={(e) => {
                        setUserInfo({
                            ...userInfo,
                            state: e.target.value,
                            regAllowances: '',
                            allowancesFromEstDeduction: '',
                            additionalWithholding: '',
                            isExemptFromStateWithholding: false,
                            isExemptFromSDI: false,
                            isWashingtonLIRate: false,
                            washingtonLIRate: '',
                            isSalaryOrhourly: true,
                            isExemptFromFLI: false,
                            isExemptFromMLI: false
                        });
                        if (e.target.value === 'WA') { setIsWashingtonState(true) } else setIsWashingtonState(false);
                        if (e.target.value === 'HI') { setIsHawaiiState(true) } else setIsHawaiiState(false);
                    }}
                >
                    <option value={null} selected disabled>Choose a state</option>
                    {stateList?.length > 0 && stateList?.map((option) => (
                        <option key={option.id} value={option.code}>
                        {option.code} - {option.name}
                        </option>
                    ))}
                </TextField>
            </Col>
            </Row>
            <Row className="g-3 mt-1">
                {userInfo?.state &&
                    <>
                        {!isWashingtonState &&
                            <>
                                {/* <Col md="4">
                                <TextField
                                    margin="dense"
                                    className="flex w-full mx-10"
                                    variant="outlined"
                                    select
                                    SelectProps={{ native: true }}
                                    style={{ width: "100%" }}
                                    size="small"
                                    inputProps={{
                                    style: {
                                        fontSize: "14px",
                                        paddingTop: "12px",
                                        color: "black",
                                    },
                                    }}
                                    label="Filing Status"
                                    defaultValue={userInfo?.filingStatus ? userInfo.filingStatus : null}
                                    value={userInfo?.filingStatus ? userInfo.filingStatus : null}
                                >
                                    <option value={null} disabled>What's your filing status?</option>
                                    {filingStatusList?.length > 0 && filingStatusList?.map((option) => (
                                        <option key={option.id} value={option.id}>
                                        {option.displayName}
                                        </option>
                                    ))}
                                </TextField>
                                </Col> */}
                                <Col md="6">
                                <TextField
                                    margin="dense"
                                    className="flex w-full "
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name="regAllowances"
                                    inputProps={{
                                    style: {
                                        fontSize: "14px",
                                        color: "black",
                                        paddingTop: "12px",
                                    },
                                    }}
                                    label="How many regular allowances do you have?"
                                    value={userInfo?.regAllowances ? userInfo?.regAllowances : null}
                                    onChange={(e) => setUserInfo({ ...userInfo, regAllowances: e.target.value })}
                                />
                                </Col>
                                {!isHawaiiState &&
                                    <Col md="6">
                                        <TextField
                                            margin="dense"
                                            className="flex w-full "
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            name="allowancesFromEstDeduction"
                                            inputProps={{
                                            style: {
                                                fontSize: "14px",
                                                color: "black",
                                                paddingTop: "12px",
                                            },
                                            }}
                                            label="How many allowances from estimated deductions do you have?"
                                            value={userInfo?.allowancesFromEstDeduction ? userInfo?.allowancesFromEstDeduction : null}
                                            onChange={(e) => setUserInfo({ ...userInfo, allowancesFromEstDeduction: e.target.value })}
                                        />
                                    </Col>
                                }
                                <Col md="4">
                                <TextField
                                    margin="dense"
                                    className="flex w-full "
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name="additionalWithholding"
                                    inputProps={{
                                    style: {
                                        fontSize: "14px",
                                        color: "black",
                                        paddingTop: "12px",
                                    },
                                    }}
                                    label="Any additional withholding amount?"
                                    value={userInfo?.additionalWithholding ? userInfo?.additionalWithholding : null}
                                    onChange={(e) => setUserInfo({ ...userInfo, additionalWithholding: e.target.value })}
                                />
                                </Col>
                                {!isHawaiiState &&
                                    <Col md={4} xs={6} lg={4}>
                                        <Label className="form-label">Are you exempt from State Income Tax Withholding?</Label>
                                        <RadioGroup
                                            className="mx-2"
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(_, value) => setUserInfo({ ...userInfo, isExemptFromStateWithholding: JSON.parse(value) })}
                                            defaultValue={userInfo?.isExemptFromStateWithholding ? userInfo.isExemptFromStateWithholding : false}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </Col>
                                }
                                <Col md={4} xs={6} lg={4}>
                                <Label className="form-label">Are you exempt from State Disability Insurance (SDI)?</Label>
                                <RadioGroup
                                    className="mx-2"
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    // defaultValue={userInfo?.isExemptFromSDI ? userInfo.isExemptFromSDI : false}
                                    value={userInfo?.isExemptFromSDI ? userInfo.isExemptFromSDI : false}
                                    onChange={(_, value) => setUserInfo({ ...userInfo, isExemptFromSDI: JSON.parse(value) })}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                                </Col>
                            </>
                        }
                        {isWashingtonState &&
                            <>
                                <Col md={4} xs={6} lg={4}>
                                <Label className="form-label">Do you know your Washington L&I rate?</Label>
                                <RadioGroup
                                    className="mx-2"
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    // defaultValue={userInfo?.isWashingtonLIRate ? userInfo.isWashingtonLIRate : false}
                                    value={userInfo?.isWashingtonLIRate ? userInfo.isWashingtonLIRate : false}
                                    onChange={(_, value) => {
                                        setUserInfo({ ...userInfo, isWashingtonLIRate: JSON.parse(value), washingtonLIRate: '', isSalaryOrhourly: true });
                                        if (JSON.parse(value) === true) setIsLIRate(true);
                                        else setIsLIRate(false);
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                                </Col>
                                {isLIRate &&
                                    <>
                                        <Col md="4">
                                        <TextField
                                            margin="dense"
                                            className="flex w-full "
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            name="washingtonLIRate"
                                            inputProps={{
                                            style: {
                                                fontSize: "14px",
                                                color: "black",
                                                paddingTop: "12px",
                                            },
                                            }}
                                            label="What is your Washington L&I rate?"
                                            value={userInfo?.washingtonLIRate ? userInfo?.washingtonLIRate : null}
                                            onChange={(e) => setUserInfo({ ...userInfo, washingtonLIRate: e.target.value })}
                                        />
                                        </Col>
                                        <Col md={4} xs={6} lg={4}>
                                        <Label className="form-label">Are you salaried or hourly employee?</Label>
                                        <RadioGroup
                                            className="mx-2"
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            // defaultValue={userInfo?.isSalaryOrhourly ? userInfo.isSalaryOrhourly : true}
                                            value={userInfo?.isSalaryOrhourly ? userInfo.isSalaryOrhourly : true}
                                            onChange={(_, value) => setUserInfo({ ...userInfo, isSalaryOrhourly: JSON.parse(value) })}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Salaried" />
                                            <FormControlLabel value={false} control={<Radio />} label="Hourly" />
                                        </RadioGroup>
                                        </Col>
                                    </>
                                }
                                <Col md={4} xs={6} lg={4}>
                                <Label className="form-label">Are you exempt from Family Leave Insurance (FLI)?</Label>
                                <RadioGroup
                                    className="mx-2"
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    // defaultValue={userInfo?.isExemptFromFLI ? userInfo.isExemptFromFLI : false}
                                    value={userInfo?.isExemptFromFLI ? userInfo.isExemptFromFLI : false}
                                    onChange={(_, value) => setUserInfo({ ...userInfo, isExemptFromFLI: JSON.parse(value) })}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                                </Col>
                                <Col md={4} xs={6} lg={4}>
                                <Label className="form-label">Are you exempt from Medical Leave Insurance (MLI)?</Label>
                                <RadioGroup
                                    className="mx-2"
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    // defaultValue={userInfo?.isExemptFromMLI ? userInfo.isExemptFromMLI : false}
                                    value={userInfo?.isExemptFromMLI ? userInfo.isExemptFromMLI : false}
                                    onChange={(_, value) => setUserInfo({ ...userInfo, isExemptFromMLI: JSON.parse(value) })}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                                </Col>
                            </>
                        }
                    </>
                }
            </Row>
            </Form>
        </CardBody>
        </>
    );
};

export default StateTaxW4Info;