import React, { useEffect, useState } from "react";
// import {
//   KeyboardDatePicker,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUp } from "@fortawesome/pro-duotone-svg-icons";

import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import EmpVsGovt from "./EmpVsGovt";
import DepartmentDeduction from "../DepartmentDeduction";
// import HistroChartLayout from "./HistroChartLayout.jsx";
import PayPeriodEarning from "../PayPeriodEarning";
import DeltaComponet from "./DeltaComponet";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchDeltaEmployeeEarning,
  fetchDeltaEmployeeEmpDeduction,
  fetchDeltaEmployeeGovDeduction,
  fetchDeltaEmployeePE,
} from "../../../../app/store/reducers/slices/chartSlice";

// import MaskedInput from "react-text-mask";

function ReportChartLayout(props) {
  var pieColors = (function () {
    var colors = [],
      base = "#4ebfa9",
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 7)
          .get()
      );

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let [chartType, setChartType] = useState("column");
  const chart = useSelector(({ chartSlice }) => chartSlice);

  let [charData, setCharData] = useState([]);
  let [charData2, setCharData2] = useState([]);
  let { deduction, earning } = props;
  const dispatch = useDispatch();

  const formatMoney = (number) => {
    number = parseFloat(number);
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  useEffect(() => {
    dispatch(fetchDeltaEmployeeEarning());
    dispatch(fetchDeltaEmployeeEmpDeduction());
    dispatch(fetchDeltaEmployeeGovDeduction());
    dispatch(fetchDeltaEmployeePE());
  }, []);

  const getChartDetails = (chart, type) => {
    if (type == "pie") {
      let series_name = "";
      let series_data = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        chart_title = "Distribution amongst types of pay";
        series_data = charData2.map((i) => {
          return {
            name: i.name,
            y: i.amount,
          };
        });
      }
      if (chart == "deduction") {
        series_data = charData.map((i) => {
          return {
            name: i.name,
            y: i.amount,
          };
        });
        chart_title = "Distribution amongst types of deduction";
      }

      let opPie = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: chart_title,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors: pieColors,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: series_data,
          },
        ],
      };
      return opPie;
    }
    if (type == "column") {
      let series_name = "";
      let series_data = [];
      let series_category = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        series_data = charData2.map((i) => {
          return i.amount;
        });
        series_category = charData2.map((i) => {
          return i.name;
        });
        chart_title = "Distribution amongst types of pay";
      }
      if (chart == "deduction") {
        series_name = "Deduction Types";
        series_data = charData.map((i) => {
          return i.amount;
        });
        series_category = charData.map((i) => {
          return i.name;
        });
        chart_title = "Distribution amongst types of deduction";
      }
      let opCol = {
        chart: {
          type: "column",
        },
        title: {
          text: chart_title,
        },
        subtitle: {
          text: "Source:Payroll System",
        },
        xAxis: {
          categories: series_category,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Amount ($)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} $</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        colors: pieColors,
        series: [
          {
            name: series_name,
            data: series_data,
          },
        ],
      };

      return opCol;
    }
  };
  useEffect(() => {
    let tArray = [];
    console.log("deduction", deduction);
    // if (deduction.length) {
    //   deduction.map((i) => {
    //     tArray.push({
    //       name: i.key,
    //       amount:
    //         parseFloat(i.total.empDeduction ? i.total.empDeduction : 0) +
    //         parseFloat(i.total.govtDeduction ? i.total.govtDeduction : 0),
    //     });
    //   });
    //   console.log("tArray", tArray);
    //   setCharData(tArray);
    // }
    if (deduction.length) {
      // deduction.map((i) => {
      //   tArray.push({
      //     name: i.key,
      //     amount:
      //       parseFloat( i.EmployeeShare ? i.EmployeeShare : 0) +
      //       parseFloat ( i.GovtShare ? i.GovtShare : 0),
      //   });
      // });
      console.log("tArray", tArray);
      // setCharData(tArray);
      /** new code */
      let empsum = 0;
      let govtsum = 0;
      deduction.map((i) => {
        i.deductionPlans.map((j) => {
          empsum = empsum + parseFloat(j.EmployeeShare ? j.EmployeeShare : 0);
          govtsum = govtsum + parseFloat(j.GovtShare ? j.GovtShare : 0);
        });

        tArray.push({
          name: i.key,
          amount: parseFloat(empsum) + parseFloat(govtsum),
        });
        return (i.total = { empDeduction: empsum, govtDeduction: govtsum });
      });

      setCharData(tArray);
      console.log("deduction", deduction);
      // setDeductions(deduction);
      // setTotaldeDuction({empDeduction:empsum,govtDeduction:govtsum})
      /** end of new code */
    }
  }, [deduction]);

  useEffect(() => {
    let typeArray = [];
    if (earning.length) {
      earning.map((i) => {
        typeArray.push({
          name: i.Description,
          amount: parseFloat(i.Earnings),
        });
      });
      console.log("typeArray", typeArray);
      setCharData2(typeArray);
    }
  }, [earning]);
  return (
    <div className="w-full h-full reportTbl  body-Color2">
      <div className="mt-10">
        <div style={{ marginBottom: "10px" }}>
          <Grid container spacing={2} style={{ margin: "30px 0px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }} className="card-body CardBack">
                <CardContent>
                  <Typography
                    component="div"
                    sx={{ fontSize: 14 }}
                    className="form-label"
                    gutterBottom
                  >
                    Total Gross payments for this pay period
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatMoney(5141434.5)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }} className="CardBack">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    className="form-label"
                    gutterBottom
                  >
                    Total Govt contribution
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatMoney(19929234)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card sx={{ minWidth: 275 }} className="CardBack">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    className="form-label"
                    gutterBottom
                  >
                    Total Compensatory / Unattended Pay
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatMoney(1542430.35)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ margin: "30px 0px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <DeltaComponet
                value={
                  chart && chart.deltaEmployee.data
                    ? chart.deltaEmployee.data[0]["count"]
                    : 0
                }
                percentage={
                  chart && chart.deltaEmployee.percentChange
                    ? chart.deltaEmployee.percentChange
                    : 0
                }
                diffrence={
                  chart && chart.deltaEmployee.data
                    ? chart.deltaEmployee.data[0]["count"] -
                      chart.deltaEmployee.data[1]["count"]
                    : 0
                }
                title="Employee"
                subtext="Employee"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={4}>
              <DeltaComponet
                value={
                  chart && chart.deltaEmployeeEarning.data
                    ? formatMoney(chart.deltaEmployeeEarning.data[0]["Earning"])
                    : 0
                }
                percentage={
                  chart && chart.deltaEmployeeEarning.percentChange
                    ? chart.deltaEmployeeEarning.percentChange
                    : 0
                }
                diffrence={
                  chart.deltaEmployeeEarning.data
                    ? chart.deltaEmployeeEarning.data[0]["Earning"] -
                      chart.deltaEmployeeEarning.data[1]["Earning"]
                    : 0
                }
                title="Employee Earning"
                subtext="Earning"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={4}>
              <DeltaComponet
                value={
                  chart && chart.deltaEmployeeGovDeduction.data
                    ? formatMoney(
                        chart.deltaEmployeeGovDeduction.data[0]["govDeduction"]
                      )
                    : 0
                }
                percentage={
                  chart.deltaEmployeeGovDeduction.percentChange
                    ? chart.deltaEmployeeGovDeduction.percentChange
                    : 0
                }
                diffrence={
                  chart.deltaEmployeeGovDeduction.data
                    ? chart.deltaEmployeeGovDeduction.data[0]["govDeduction"] -
                      chart.deltaEmployeeGovDeduction.data[1]["govDeduction"]
                    : 0
                }
                title="Goverment Deduction"
                subtext="Deduction"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <DeltaComponet
                value={
                  chart && chart.deltaEmployeeEmpDeduction.data
                    ? formatMoney(
                        chart.deltaEmployeeEmpDeduction.data[0]["empDeduction"]
                      )
                    : 0
                }
                percentage={
                  chart.deltaEmployeeEmpDeduction.percentChange
                    ? chart.deltaEmployeeEmpDeduction.percentChange
                    : 0
                }
                diffrence={
                  chart.deltaEmployeeEmpDeduction.data
                    ? chart.deltaEmployeeEmpDeduction.data[0]["empDeduction"] -
                      chart.deltaEmployeeEmpDeduction.data[1]["empDeduction"]
                    : 0
                }
                title="Employee Deduction"
                subtext="Deduction"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <CardContent>
                  <HistroChartLayout deduction={deduction} earning={earning} />
                </CardContent>
              </Card>
            </Grid> */}

            <Grid item xs={12} sm={6} lg={6}>
              {charData.length ? (
                <Card>
                  <CardContent>
                    {/* <Typography variant="h6" component="b" color="text.secondary" gutterBottom>
                    Employee Vs Goverment Share Comparison
                    </Typography> */}
                    <Typography component="div">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={getChartDetails("deduction", "column")}
                      />
                    </Typography>
                  </CardContent>
                </Card>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              {charData.length ? (
                <Card>
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartDetails("deduction", "pie")}
                    />
                  </CardContent>
                </Card>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              {charData2.length ? (
                <Card>
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartDetails("paytype", "column")}
                    />
                  </CardContent>
                </Card>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              {charData2.length ? (
                <Card>
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartDetails("paytype", "pie")}
                    />
                  </CardContent>
                </Card>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <EmpVsGovt />
            </Grid>

            <Grid item xs={12} sm={6} lg={6} style={{ padding: "10px" }}>
              <DepartmentDeduction />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} style={{ padding: "10px" }}>
              <PayPeriodEarning />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ReportChartLayout;
