import React from "react";
import TextField from "@mui/material/TextField";
import { Col, Label, Row, Form } from "reactstrap";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Checkbox } from "@mui/material";
import { H5, P } from "../../../../AbstractElements";
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { floatNumberRegex } from '../../../../app/utility/helper';
import dayjs from "dayjs";

function EmpCertificationManage(props) {
  const { isEdit = false ,checkValidDate} = props;
  const menuItemListHeight = 300;

  return (
    <div className="w-full h-full empCertificationManageDiv">
      {/* <H5>{isEdit ? 'Edit Employee Certification' : 'Add Employee Certification'}</H5> */}
      {props?.userData?.profile?.role === 'admin' && <P>Employee Id: {props?.currentEmpDetails?.employeeID ? props?.currentEmpDetails?.employeeID : ''}</P>}
      <div className="flex flex-col sm:flex-row w-full py-4 mt-10">
        <Form>
          <Row className="g-3">
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                    // PaperProps: {
                    //   // onScroll: loadMoreLkCertificationItems,
                    // },
                  }
                  }
                }
                style={{ width: "100%" }}
                size="small"
                value={props.detail.certificationCode}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    certificationCode: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Certification Code"
              >
                {/* <option key={'selectCertificationCode'} disabled value={0}>Select Certification Code</option>
                {props.LkCertificationList && 
                  props.LkCertificationList.map((option) => (
                    <option key={option.id} value={option.code}>
                      {option.certificateName} - {option.code}
                    </option>
                  ))} */}
                  {props.LkCertificationList.map((option) => (
                    <MenuItem key={option.id} value={option.code}>
                      {option.certificateName} - {option.code}
                    </MenuItem>
                  ))}
              </TextField>
            </Col>
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Certification Sequence"
                value={props?.detail?.certificationSeq}
                onChange={(e) => {
                  if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                    props.setDetail({ ...props.detail, certificationSeq: e.target.value });
                  }
                }}
                name="eName"
              />
            </Col>
            <Col md="6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Valid From"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.validFrom)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      validFrom: value,
                      validTo: null
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Valid To"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.validTo)}
                  minDate={dayjs(props.detail.validFrom)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      validTo: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="6" className="isActiveAlignCenter">
              <Label className="labelColor isActiveLabel">Is Active</Label>
              <Checkbox
                checked={props.detail.isActive}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    isActive: !props.detail.isActive,
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Col>
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                multiline
                rows={2}
                maxRows={4}
                label="Remarks"
                value={props.detail.remarks}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    remarks: e.target.value,
                  });
                }}
                name="eName"
              />
            </Col>
          </Row>
        </Form>
      </div>
      <Row className="mb-3 buttonRow">
        <Button
          variant="contained"
          className="m-10 mx-2 xs:mx-10 min-w-0"
          style={{
            color: "#234d10",
            backgroundColor: "#ffffff",
            width: 'auto'
          }}
          onClick={props.saveData}
        >
          <Typography className="text-12">
            {" "}
            {"Save"}{" "}
          </Typography>
        </Button>
        <Button
          variant="contained"
          className="m-10 mx-2 xs:mx-10 min-w-0"
          style={{
            color: "#234d10",
            backgroundColor: "#ffffff",
            width: 'auto'
          }}
          onClick={props.closeModal}
        >
          <Typography className="text-12">
            {" "}
            {"Cancel"}{" "}
          </Typography>
        </Button>
      </Row>
    </div>
  );
}

export default EmpCertificationManage;