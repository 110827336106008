import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

const CustomButton = ({ text, icon, buttonClasses, iconClasses, onClick, ...props }) => {
    return <Button
        variant="contained"
        style={{
            textTransform: "none",
            color: "white",
        }}
        className={`bg-primary ${buttonClasses}`}
        onClick={onClick}
        type="button"
        {...props}
    >
        {/* {icon && <FontAwesomeIcon
            icon={icon}
            style={{
                color: "white",
                marginRight: "8px",
            }}
            size={40}
            className={iconClasses}
        />} */}
        {text}
    </Button>
};

export default CustomButton;