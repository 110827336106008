import React, { useEffect, useState } from "react";
import { H5, LI, P, UL } from "../../../AbstractElements";
import { LatestActivity } from "../../Common/Data/Dashboard";
import { Card, CardBody, CardHeader, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { fetchPayPeriodMaster } from "../../../app/store/reducers/slices/masterSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { filterChart } from "../../../app/store/reducers/slices/empDashboardSlice";
import moment from "moment/moment";

const FilterSection = () => {
  let dispatch = useDispatch();
  const [selectedPayPeriod, setSelectedPayPeriod] = useState({});
  const payperiod = useSelector(({ masterSlice }) => masterSlice.payperiods);
  useEffect(() => {
      dispatch(fetchPayPeriodMaster()).then((res) => {
        let resPaycode = res.payload.payperiods;
        let tody = moment().format("YYYY-MM-DD");
        let currentPayPeriod = resPaycode.find((i) => {
          return i.StartDate <= tody && i.EndDate >= tody;
        });
        setSelectedPayPeriod(currentPayPeriod);
      });
  }, []);
  useEffect(() => {
    dispatch(
      filterChart({
        id: selectedPayPeriod?.ID,
        startDate: selectedPayPeriod?.StartDate,
        endDate: selectedPayPeriod?.EndDate,
      })
    );
  }, [selectedPayPeriod]);
  return (
    <Card className="latest-update-sec">
      <CardHeader>
        <div className="header-top d-sm-flex align-items-center">
          <H5>Filters</H5>
        </div>
      </CardHeader>
      <CardBody>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Label>Pay Periods home</Label>
            <Input
              className="form-control form-control-primary btn-square"
              name="select"
              type="select"
              value={selectedPayPeriod?.ID?selectedPayPeriod.ID:0}
              onChange={ (event) => {
                console.log(event.target.value,"payperiod",payperiod);
                let info = payperiod.find((i) => {
                  return i.ID.toString() === event.target.value + "";
                });
                setSelectedPayPeriod(info);
              }}
            >
              {payperiod.length &&
                payperiod.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    style={{ color: "black" }}
                  >
                    {option.label}
                  </option>
                ))}
            </Input>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default FilterSection;
