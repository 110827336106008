import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "vendorSlice";

export const fetchEmployeeByVendor = createAsyncThunk(
  `${sliceName}/fetchEmployeeByVendor`,
  async (vid) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/employee-by-vendor/${vid}/5`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );

      let copyData = data;
      let tempArr = [];
      if (copyData.length) {
        copyData.map((i) => {
          JSON.parse(i.deduction).map((i) => {
            if (i.emp && i.gov) {
              tempArr.push(i.title);
            }
          });
        });
      }
      console.log("tempArr", tempArr);
      if (copyData.length) {
        copyData.map((k) => {
          let tarray = [];
          JSON.parse(k.deduction).map((i) => {
            if (tempArr.includes(i.title)) {
              tarray.push(i);
            }
          });
          k.deduction = tarray;
        });
        console.log("tempArr1111", copyData);
        return {
          employeeByVendor: copyData,
        };
      }

      return {
        employeeByVendor: data,
      };
      let infoX = [
        {
          ID: "998891231",
          EmpName: "Grey walter G",
          DEPT: 1010,
          empshare: 1000,
          govtshare: 1003,
          total: 2003,
          grandtotal: 6000,
        },
        {
          ID: "998891234",
          EmpName: "MACIS ANTHONY JM",
          DEPT: 1010,
          empshare: 1000,
          govtshare: 1003,
          total: 2003,
          grandtotal: 6000,
        },
      ];

      return {
        employeeByVendor: infoX,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        employeeByVendor: [],
      };
    }
  }
);

export const fetchDeductionByVendor = createAsyncThunk(
  `${sliceName}/fetchDeductionByVendor`,
  async () => {
    try {
      const  data  = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/deduction-by-vendor/5`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        vendorList: data.data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        vendorList: [],
      };
    }
  }
);

export const fetchLaborCostDiduction = createAsyncThunk(
  `${sliceName}/fetchLaborCostDiduction`,
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}report/summery/labour-cost-distribution/5`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        laborCostList: data,
      };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        laborCostList: [],
      };
    }
  }
);

export const fetchMiscellaneousVendorDiduction = createAsyncThunk(
  `${sliceName}/fetchMiscellaneousVendorDiduction`,
  async () => {
    try {
      return {
        misVendorDeductionList: [
          {
            ID: 652332,
            Name: "ADA MARK",
            CODE: "ASCFEE",
            Share: "0.75",
            DVPV: "4250",
          },
          {
            ID: 58616435,
            Name: "ANDARDE JOHNN",
            CODE: "ASCFEE",
            Share: "0.75",
            DVPV: "4250",
          },
          {
            ID: 586164332,
            Name: "CALVO, LYDYNNA",
            CODE: "ASCFEE",
            Share: "0.75",
            DVPV: "4250",
          },
        ],
      };
      // const { data } =  await axios.get(
      // 	`${process.env.REACT_APP_API_URL}report/summery/labour-cost-distribution`,
      // 	{
      // 		headers: {
      // 			"Content-Type": "application/json",
      // 			"x-access-token": localStorage.getItem("jwt_access_token"),
      // 		},
      // 	}
      // )
      // return {
      //   misVendorDeductionList: data,
      // };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        misVendorDeductionList: [],
      };
    }
  }
);
const chartSlice = createSlice({
  name: sliceName,
  initialState: {
    vendorList: [],
    laborCostList: [],
    employeeByVendor: [],
    misVendorDeductionList: [],
  },
  extraReducers: {
    [fetchDeductionByVendor.fulfilled]: (state, action) => {
      state.vendorList = action.payload.vendorList;
    },
    [fetchLaborCostDiduction.fulfilled]: (state, action) => {
      state.laborCostList = action.payload.laborCostList;
    },
    [fetchEmployeeByVendor.fulfilled]: (state, action) => {
      state.employeeByVendor = action.payload.employeeByVendor;
    },
    [fetchMiscellaneousVendorDiduction.fulfilled]: (state, action) => {
      state.misVendorDeductionList = action.payload.misVendorDeductionList;
    },
  },
});

export default chartSlice.reducer;
