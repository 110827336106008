import React, { useEffect, useState } from "react";

import { Card, Grid } from "@material-ui/core";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";

import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../../../app/utility/helper";
import { fetchLaborCostDiduction } from "../../../../app/store/reducers/slices/vendorSlice";
import { Tooltip, Typography } from "@mui/material";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import clsx from "clsx";

import moment from "moment";
import MUIDataTable from "mui-datatables";
// import MaskedInput from "react-text-mask";
import { makeStyles } from "@material-ui/core/styles";
import AdvanceSearchLabourCostReport from "./AdvanceSearchLabourCostReport";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
  footerCell: {
    // backgroundColor: theme.palette.background.paper,
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
  },
}));

function LaborCostList(props) {
  const classes = useStyles();

  const laborCostList = props?.requestDetails;
  let dispatch = useDispatch();

  const [totalShare, setTotalShare] = useState({
    emp: 0,
    gov: 0,
    grandTotal: 0,
  });
  useEffect(() => {
    dispatch(fetchLaborCostDiduction());
  }, [dispatch]);
  useEffect(() => {
    let grandTotal = 0;
    let grandEmp = 0;
    let grandGov = 0;
    laborCostList?.map((j) => {
      let emp = j.GrossPay ? j.GrossPay : 0;
      let gov = j.GovContribution ? j.GovContribution : 0;

      grandEmp = grandEmp + emp;
      grandGov = grandGov + gov;
      grandTotal = grandTotal + gov + emp;
    });
    setTotalShare({ emp: grandEmp, gov: grandGov, grandTotal: grandTotal });
  }, [laborCostList]);
  const renderDeductionList = () => {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        <Card
          style={{
            marginBottom: "10px",
            overflow: "hidden",
            padding: 0,
            borderRadius: "10px",
          }}
          elevation={3}
          className="CardBack"
        >
          <table className="table100">
            <thead>
              <tr>
                {/* <th colSpan={5} className="tc mainHead">
                  Deduction
                </th> */}
                <th colSpan={7}>
                  <h6
                    style={{
                      padding: "10px",
                      marginTop: 10,
                    }}
                  >
                    Labor Cost Deduction
                  </h6>
                </th>
              </tr>

              <tr
                style={{
                  // paddingBottom: "8px",
                  // paddingTop: "8px",
                  // backgroundColor: "#dcdcea",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                <th className="tl1">Job Order</th>
                <th className="tl1">Dept Code</th>
                <th className="tl1">Employee ID</th>
                <th className="tl1">EmployeeName</th>
                <th className="tl1">Emp Share $</th>
                <th className="tl1">Govt Share $</th>
                <th className="tl1">Total</th>
                {/* <th className="tl1">Grand Total</th> */}
              </tr>
            </thead>
            <tbody>
              {laborCostList.length
                ? laborCostList.map((i, index) => {
                    return (
                      <tr
                        key={`z${index}`}
                        style={{
                          borderBottomColor: "#EAEBE4",
                          borderBottomWidth: "1px",
                        }}
                        className="CardBack"
                      >
                        <td className="tl1">{i.JobOrder}</td>
                        <td className="tl1">{i.DPDV}</td>
                        <td className="tl1">{i.EmployeeID}</td>
                        <td className="tl1">{i.EmployeeName}</td>
                        <td className="tl1">
                          {formatMoney(i.GrossPay ? i.GrossPay : 0)}
                        </td>
                        <td className="tl1">
                          {formatMoney(
                            i.GovContribution ? i.GovContribution : 0
                          )}
                        </td>
                        <td className="tl1">
                          {formatMoney(
                            (i.GrossPay ? i.GrossPay : 0) +
                              (i.GovContribution ? i.GovContribution : 0)
                          )}
                        </td>
                        {/* <td className="tl1">-</td> */}
                      </tr>
                    );
                  })
                : null}

              {laborCostList.length ? (
                <tr className="grandTd CardBack">
                  <td className="tl1" colSpan={4}>
                    Grand Total
                  </td>

                  <td className="tl1">{formatMoney(totalShare.emp)}</td>
                  <td className="tl1">{formatMoney(totalShare.gov)}</td>
                  <td className="tl1">{formatMoney(totalShare.grandTotal)}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Card>
      </Grid>
    );
  };

  const columns = [
    {
      id: "JobOrder",
      name: "JobOrder",
      label: "Job Order",
    },
    {
      id: "DPDV",
      name: "DPDV",
      label: "Dept Code",
    },
    {
      id: "EmployeeID",
      name: "EmployeeID",
      label: "Employee ID",
    },
    {
      id: "EmployeeName",
      name: "EmployeeName",
      label: "Employee Name",
    },
    {
      id: "GrossPay",
      name: "GrossPay",
      label: "Emp Share $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(value);
        },
      },
    },
    {
      id: "GovContribution",
      name: "GovContribution",
      label: "Govt Share $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(value);
        },
      },
    },
    {
      id: "GovContribution",
      name: "GovContribution",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>{formatMoney(tableMeta.rowData[4] + tableMeta.rowData[5])}</>
          );
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    // [classes.stickyFooterCell]: stickyFooter,
  });
  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",

    rowsPerPage: 10,
    download: true,
    customTableBodyFooterRender: function (opts) {
      const finalEmpShare = laborCostList?.reduce((i, a) => i + a.GrossPay, 0);
      const finalGovShare = laborCostList?.reduce(
        (i, a) => i + a.GovContribution,
        0
      );
      const finalTotal = laborCostList?.reduce(
        (i, a) => i + a.GrossPay + a.GovContribution,
        0
      );

      return (
        <>
          <TableFooter className={footerClasses}>
            <TableRow style={{ width: "100%" }}>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}>
                {" "}
                <b style={{ fontSize: "15px" }}>Grand Total:</b>
              </TableCell>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}>
                {" "}
                <b style={{ fontSize: "15px" }}>
                  {" "}
                  {formatMoney(finalEmpShare)}
                </b>
              </TableCell>
              <TableCell className={footerClasses}>
                <b style={{ fontSize: "15px" }}>
                  {" "}
                  {formatMoney(finalGovShare)}
                </b>
              </TableCell>
              <TableCell className={footerClasses}>
                <b style={{ fontSize: "15px" }}> {formatMoney(finalTotal)}</b>
              </TableCell>
            </TableRow>
          </TableFooter>
        </>
      );
    },
  };
  return (
    <div className="w-full h-full reportTbl">
      <div className="mt-0">
        <div style={{ marginTop: "20px" }}>
          {/* <div class="rounded overflow-hidden shadow-lg"> */}
          <AdvanceSearchLabourCostReport />

          <Card
            style={{
              padding: "15px",
              marginTop: "0px",
              marginBottom: "30px",
              borderRadius: "10px",
            }}
            className="CardBack"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "right" }}>
                {/* <table className="padTbl CardBack">
                  <tbody className="leftHead CardBack">
                    <tr>
                      <td className="cardSmallTitle">Run Date</td>
                      <td>:</td>
                      <td className="cardSmallValue">12/22/2022</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Program</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle" colSpan={3}>
                        DEPT/DIV SPECIFICATION
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ textAlign: "center" }}>
                <table className="table100 centerDiv padTbl">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          {laborCostList?.length
                            ? laborCostList[0]["Department"]
                            : "Department of Administrative"}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="t18">
                        <b className="text-light">
                          Labor Cost Distribution Report
                        </b>
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td className="cardSmallTitle">CHK: 6/24/2022</td>
                      <td className="cardSmallTitle">
                        PPE:{" "}
                        {laborCostList?.length
                          ? moment(laborCostList[0]["PPE"]).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>

              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "left" }}>
                {/* <table className="rightHead fright  padTbl">
                  <tbody>
                    <tr>
                      <td className="cardSmallTitle">Page</td>
                      <td>:</td>
                      <td className="cardSmallValue">1</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Run Time</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>

                    <tr>
                      <td className="cardSmallTitle">Run ID</td>
                      <td>:</td>
                      <td className="cardSmallValue">
                        PAYU00{vendorList.length ? vendorList[0]["RunID"] : "2"}
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Grid>
            </Grid>
          </Card>
          {/* </div> */}
        </div>

        <Grid container spacing={2}>
          {/* Deduction list */}
          <div style={{ width: "100%", padding: "10px" }}>
            {/* {renderDeductionList()} */}
            <MUIDataTable
              className={classes.customData}
              title={
                <h6
                  style={{
                    padding: "10px",
                  }}
                >
                  Labor Cost Deduction
                </h6>
              }
              data={laborCostList}
              columns={columns}
              isRowExpanded={true}
              options={options}
            />
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default LaborCostList;
