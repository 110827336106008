import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { useAuth } from "oidc-react";
import { Grid, TextField, Tooltip } from "@mui/material";
import { Dialog } from "@progress/kendo-react-dialogs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ShowError } from "../../_helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { faAdd, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Btn, H5 } from "../../AbstractElements";
import dayjs from "dayjs";
// import { getDocumentAttached } from "../../store/employees/actions";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  listHeight: {
    height: "calc(100vh - 100px - 317px)",
    maxHeight: "calc(100vh - 100px - 317px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 180px)",
      maxHeight: "calc(100vh - 100px - 180px)",
    },
  },
  listHeightError: {
    height: "calc(100vh - 100px - 256px)",
    maxHeight: "calc(100vh - 100px - 256px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 239px)",
      maxHeight: "calc(100vh - 100px - 239px)",
    },
  },
}));

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 10px;
  margin: 25px;
  align-items: center;
`;

const Label = styled.label`
  grid-column: 1;
`;
function SelectEmployee(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    dataList,onClickHandle, currentEmployeeID
  } = props;
  const [filterText, setFilterText] = useState("");
  const [timesheetNotVerify, setTimesheetNotVerify] = useState([]);
  const [timesheetVerify, setTimesheetVerify] = useState([]);
  const [timesheetNoData, setTimesheetNoData] = useState([]);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState("")
  const [openModal, setOpenModal] = useState(false);
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [loadAttachFiles, setLoadAttachFiles] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const { employeeID } = useParams();
  const { userData } = useAuth();
   


  useEffect(() => {
    let verified = [];
	let unvefified = [];
	let noData =[];
    dataList.forEach((item) => {
      if (item?.flag === 0) {
        unvefified.push(item);
      } else if(item?.flag === 1){
		verified.push(item);
	  } else {
		noData.push(item);
	  }
    });
    setTimesheetNoData(noData);
	setTimesheetNotVerify(unvefified);
	setTimesheetVerify(verified);
	setData(dataList);
  }, [dataList]);
  useEffect(()=>{

	if(filterText === ""){
		setData([...dataList])
	  } else {
		setData([...dataList.filter(item=>{
			return item.employeeID.toString().toLowerCase().indexOf(filterText) > -1 
			|| item.employeeName.toLowerCase().indexOf(filterText) > -1
		})])
	  }
  },[filterText])
  const renderItem = (item,idx,className)=>{
	
	if(item?.employeeID === 0)
		return null;
	return <Tooltip
	placement="top"
	title={`${item.employeeName} - ${item.employeeID}`}
  >
	<div className="p-1" key={`e${idx}`}>
	  <label 
		onClick={() => {
			onClickHandle(item.employeeID);
		}}
		className={`${className} ${currentEmployeeID === item.employeeID ? 'selectedUser':''}`}
	  >
		{item.employeeName}
	  </label>
	</div>
  </Tooltip>
  }
  return (
  
		<Grid container spacing={1}>
			<Grid item xs={12} >
			<TextField
        fullWidth
        size="small"
        className="m-b-10"
        label={`Find Employee`}
        onChange={(e) => {
          setFilterText(e.target.value);
		 
		  
        }}
      />
			</Grid>
			<Grid item xs={12}>
			<div className="flex w-full flex-col">
			<div className="d-flex align-content-start flex-wrap" style={{maxHeight:'140px',overflowY:'scroll'}}>
        {data && data.length > 0  && data.filter((item) => {
            return (
              timesheetNoData &&
              timesheetNoData.find(
                (d) => d.employeeID === (item.employeeID)
              ) != null 
            );
          })
          .map((i, index) => {
           return renderItem(i,index,"btn btn-outline-primary timesheetNoData")
          })}
        {data &&
          data.length>0 &&
          data.filter((item) => {
              return (
                timesheetNotVerify.find(
                  (d) => d.employeeID === item?.employeeID
                ) != null  );
            })
            .map((i, index) => {
              return renderItem(i,index,"btn btn-outline-primary timesheetNotVerify")
          
            })}
		{data &&
          data.length >0 &&
          data.filter((item) => {
              return (
                timesheetVerify.find(
                  (d) => d.employeeID === item?.employeeID
                ) != null);
            })
            .map((i, index) => {
              return renderItem(i,index,"btn btn-outline-primary timesheetVerify")
          
            })}
      </div> </div>
			</Grid>
		</Grid>
   
  );
}

export default SelectEmployee;
