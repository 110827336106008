const initialState = {
    userData: null,
    payCodes: [],
    EmployeeProcessPayTimes: [],
    EmployeeStatuses: [],
    token: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'EHR_ON_SET_USER': {
        return {
          ...state,
          userData: action.payload,
        };
      }
      case 'EmployeeProcessPayTimes': {
          return {
            ...state,
            EmployeeProcessPayTimes: action.payload.EmployeeProcessPayTimes,
          };
      }
      case 'EmployeeStatuses': {
        return {
          ...state,
          EmployeeStatuses: action.payload.EmployeeStatuses,
        };
      }
        case 'EHR_ON_SET_PAYCODE': {
            console.log("payCodes",action.payload)
            return {
              ...state,
              payCodes: action.payload,
            };
          }
      case 'ON_SET_TOKEN': {
        return {
          ...state,
          token: action.payload,
        };
      }
  
      default:
        return state;
    }
  };
  export default reducer;