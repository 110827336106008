import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../_helper/api_helper";
const ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD =
  "deductionPlanMaster/addOrUpdateRecord";
const sliceName = "axioSlice";

const HEADER_OPTIONS = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
  },
};

export const getAllEmployeeRecords = async (startDate, endDate, take, skip) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}Employee/GetAllEmployee?MaxCount=${take}&SkipCount=${skip}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllEmployeeCertificatesRecords = async (take, skip) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/GetAll?MaxCount=${take}&SkipCount=${skip}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllEmployeeCertificatesRecordsByEmployeeId = async (
  employeeId,
  take,
  skip
) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/GetAll?EmployeeId=${employeeId}&MaxCount=${take}&SkipCount=${skip}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateEmployeeCertificateRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllLkCertificationRecords = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}LkCertification`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllEarningsReportRecords = async (data) => {
  try {
    let response = await axios.post(
      // `${process.env.REACT_APP_WEB_API_URL}Employee/GetAllEmployeeEarningReport?StartDate=${startDate}&EndDate=${endDate}&MaxCount=${take}&SkipCount=${skip}`,
      `${process.env.REACT_APP_WEB_API_URL}Employee/GetAllEmployeeEarningReport`,
      data,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllW2ReportRecords = async (data) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}Report/GetW2Report`,
      data,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllIRSQuarterlyReportRecords = async (data) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}Report/GetEmployeeQuartlyReport`,
      data,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllFederalTax = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}FederalTax/GetAll`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateFederalTaxRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}FederalTax/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllStateTax = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}State/GetAll`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateStateTaxRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}State/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllGarnishmentType = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_AZURE_URL}GarnishmentType/GetAll`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateGarnishmentTypeRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}GarnishmentType/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllDeductionPlanMaster = async () => {
  try {
    let response = await get(`${process.env.REACT_APP_WEB_API_URL}DeductionPlanMaster/GetAll`);
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};
export const addOrUpdateDeductionPlanMasterRecord = createAsyncThunk(
  ADD_OR_UPDATE_DEDUCTION_PLAN_MASTER_RECORD,
  async (payload) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_AZURE_URL}/DeductionPlanMaster/CreateOrUpdate`,
        payload,
        HEADER_OPTIONS
      );
      return response.data ? response.data : [];
    } catch (error) {
      return [];
    }
  }
);

// export const addOrUpdateDeductionPlanMasterRecord = async (payload) => {
//   try {
//     let response = await axios.post(
//       `${process.env.REACT_APP_AZURE_URL}/DeductionPlanMaster/CreateOrUpdate`,
//       payload,
//       HEADER_OPTIONS
//     );
//     return response.data ? response.data : [];
//   } catch (error) {
//     return [];
//   }
// };

export const getAllPaymentType = async () => {
  try {
    let response = await get(`${process.env.REACT_APP_WEB_API_URL}PaymentType/GetAll`)
    return response?.data || [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdatePaymentTypeRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}PaymentType/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getEmployeePayrollProcessRunRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}Payroll/EmployeePayrollProcessRun`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllEmployeePayrollList = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}Payroll/EmployeePayrollSearch`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const updateEmployeePayrollProcessRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}Payroll/UpdateEmployeePayrollProcess`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getEmployeeW4Record = async (employeeId) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}EmployeeW4/GetById?EmployeeId=${employeeId}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateEmployeeW4Record = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_WEB_API_URL}EmployeeW4/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllEmployeeDeductionPlan = async (
  employeeId,
  deductionType
) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_AZURE_URL}/EmployeeDeductionPlan/GetAll?EmployeeId=${employeeId}&DeductionType=${deductionType}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllDeductionTransactionDetailsById = async (Id) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}DeductionTransactionDetail/GetById?Id=${Id}`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};
export const getAllDeductionMethodList = async () => {
  try {
    let response = await get(`${process.env.REACT_APP_WEB_API_URL}Enum/GetDeductionMethodList`);
    return response || [];
  } catch (error) {
    return [];
  }
};

export const getAllArrearsBaseList = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_AZURE_URL}Enum/GetAllArrearsBaseList`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllPaymentOptions = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_AZURE_URL}/Enum/GetAllPaymentOptions`,
      HEADER_OPTIONS
      // {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "x-access-token": localStorage.getItem("jwt_access_token"),
      //   },
      // }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllFilingStatus = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}Enum/GetAllFilingStatusList`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllDeductionPlanStatusList = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_WEB_API_URL}Enum/GetAllDeductionPlanStatusList`,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getAllDeductionPlans = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/deductionPlan`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const addOrUpdateEmployeeDeductionPlanRecord = async (payload) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/EmployeeDeductionPlan/CreateOrUpdate`,
      payload,
      HEADER_OPTIONS
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getDeductionType = async () => {
  try {
    let response = await get(`${process.env.REACT_APP_WEB_API_URL}deductionType/GetAll`,);
    return response?.data || [];
  } catch (error) {
    return [];
  }
};

export const getVendor = async () => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}vendor`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("jwt_access_token"),
      },
    });
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getOverAllDeduction = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/overalldeduction/5`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};
export const getOverAllEarning = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/overallearning/5`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

//done
export const getEmployeeTypes = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}employeetypes`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getReportDepartmentwiseDeduction = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/departmentwise-deduction/5`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getReportdeductionGovVsEmployee = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/deduction-gov-vs-employee/5`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getReportEarningbyPayperiod = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/earningby-payperiod/10`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};

export const getSankeyChart = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}report/summery/department-deductiontype-employeetype-deduction/5`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      }
    );
    return response.data ? response.data : [];
  } catch (error) {
    return [];
  }
};
const axioSlice = createSlice({
  name: sliceName,
  initialState: {
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateDeductionPlanMasterRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addOrUpdateDeductionPlanMasterRecord.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(addOrUpdateDeductionPlanMasterRecord.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default axioSlice.reducer;
