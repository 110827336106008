import React from 'react';
import { H5, H6, P } from '../../../AbstractElements';
import { formatMoney } from '../../../app/utility/helper';
import { Divider } from 'antd';

const TaxDetailModal = ({ selectedData }) => {
  return (
    <div className='payrollRunTaxDetailModal' style={{ padding: '0 4% 4% 4%', width: 400 }}>
        <H5 style={{ margin: 0 }}>{selectedData?.name} - {selectedData?.employeeId}</H5>
        {/* <H6 style={{ margin: 0 }}>Pay Breakup</H6> */}
        <Divider style={{ margin: '3%' }} />
        
        <div className='insideDiv'>
        <H6>Earnings :</H6>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.totalAmount??0)}</P>
        </div>
        <Divider style={{ margin: '3%' }} />
        <div className='insideDiv'>
        <H6>Taxes </H6>
	    {/* <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.federalTax)}</P> */}
        </div>
        <div className='insideDiv'>
        <P>Federal Tax :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.federalTax??0)}</P>
        </div>
        <div className='insideDiv'>

        <P>Medicare Tax :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.medicareTax??0)}</P>
        </div>
        <div className='insideDiv'>
        <P>Social Security Tax :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.socialSecurityTax??0)}</P>
        </div>
       
        {(selectedData?.stateId == 1 || selectedData?.stateId == 3) &&
          <>
           <div className='insideDiv'>
            <P>Additional Withholding :</P>
            <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.additionalWithholding??0)}</P>
            </div>
            <div className='insideDiv'>
            <P>State Income Tax :</P>
            <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.stateIncomeTax??0)}</P>
            </div>
            {selectedData?.stateId == 1 && <div className='insideDiv'>
              <P>State Disability Insurance :</P>
              <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.stateDisabilityInsurance??0)}</P>
              </div>
            }
          </>
        }
        {selectedData?.stateId == 2 &&
          <>
            <div className='insideDiv'>
            <P>Local Tax :</P>
          <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.localTax??0)}</P>
            </div>
            <div className='insideDiv'>
            <P>Family Leave Insurance :</P>
          <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.familyLeaveInsurance??0)}</P>
            </div>
            <div className='insideDiv'>
            <P>State Industrial Insurance :</P>
          <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.stateIndustrialInsurance??0)}</P>
            </div>
          </>
        }
        <Divider style={{ margin: '3%' }} />
        <div className='insideDiv'>
        <H6>Employee Deductions :</H6>
	    {/* <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.deductionAmount)}</P> */}
        </div>
        <div className='insideDiv'>
        <P>Garnishment :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.garnishmentAmount??0)}</P>
        </div>
        <div className='insideDiv'>
        <P>Benefits :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.benefitAmount??0)}</P>
        </div>
        <div className='insideDiv'>
        <P>Miscellaneous :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.miscellaneousDeduction??0)}</P>
        </div>
        <div className='insideDiv'>
        <P>Retirement :</P>
	    <P style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.retirementAmount??0)}</P>
        </div>
        <Divider style={{ margin: '3%' }} />
        <div className='insideDiv'>
        <H6>Net Pay:</H6>
	    <H6 style={{ marginLeft: '5%', marginTop: '2%' }}>{formatMoney(selectedData?.netPay??0)}</H6>
        </div>
   
       
        
       

      
        
       
</div>
  )
}

export default TaxDetailModal;