import React from "react";
import store from "./app/store/index";

import { Provider } from "react-redux";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
// import { setApiToken } from "./_helper/api_helper";
import { AuthProvider, UserManager } from "oidc-react";
import { WebStorageStateStore } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "./utils/authConst";

const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  completeAuthentication: (user) => {
    return user;
  },
  metadata: {
    ...METADATA_OIDC,
  },
});
userManager
  .signinRedirectCallback()
  .then((user) => {
    if (user) {
      console.log("sgjasjkashjkashjk", user.profile);
      if (
        user.profile != undefined &&
        user.profile.role !== "user" &&
        user.profile.Application !== "PR" &&
        user.profile.Application !== undefined
      ) {
        // window.location.replace("/unauthorized");
      } else {
        localStorage.setItem("role", user.profile.role);
        localStorage.setItem("authStatus", "SignedIn");
        localStorage.setItem("jwt_access_token", user.access_token);

        // setApiToken(user.access_token);
        // window.location.replace("/dashboard/default");
      }
    }
  })
  .catch((err) => {
    console.log(err);

    // history.push({ 'pathname': '/employeeManage' })
    // history.push({ pathname: window.location.pathname });
    // window.location.replace("/login");
  });

const oidcConfig = {
  onSignIn: async (user) => {
    localStorage.setItem("role", user?.profile?.role);
    localStorage.setItem("authStatus", "SignedIn");
    localStorage.setItem("jwt_access_token", user?.access_token);
    console.log("Logged In App");

    //window.location.reload();
    // Redirect?
  },
  loadUserInfo: true,
  filterProtocolClaims: true,
  userManager: userManager,
  automaticSilentRenew: true,
};

const App = () => (
  <div className="App">
    <Provider store={store}>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <AuthProvider {...oidcConfig}>
            <Routers />
          </AuthProvider>
        </AnimationThemeProvider>
      </CustomizerProvider>
    </Provider>
  </div>
);
export default App;
