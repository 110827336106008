import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { H5, LI, P, UL } from "../../../../AbstractElements";
import moment from "moment";
import { ArrowSvgIcon } from "../../../../Components/Common/Data/SvgIcons";
import { ActivityItem } from "../../../../app/utility/helper";

const NotiFicationTable = (props) => {
  const { notificationData } = props;
  const navigate = useNavigate()
  console.log("asafaasf", );
  return (
    <Card className="latest-update-sec">
      <CardHeader>
        <div className="header-top d-sm-flex align-items-center">
          <H5>Latest activity </H5>
          <div className="center-content">
            <UL attrUL={{ className: "week-date flex-row d-flex" }}>
              <LI attrLI={{ className: "font-primary" }}>Today</LI>
              <LI>Month</LI>
            </UL>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="table-responsive">
          <table className="table table-bordernone m-0">
            <tbody>
              {notificationData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="media">
                    <div style={{marginRight:"20px", width:"40px", height:"40px", display:"flex", alignItems:"center", justifyContent:"center", borderRadius:"20px"}} className="bg-primary ">
                    {ActivityItem(item.type, item)?.icon}

                    </div>
                    
                      <img
                        src={"https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"}
                        
                        alt={"img"}
                        style={{ height: "40px", width: "40px" }}
                        loading="lazy"
                      />
                      <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                      <div className="media-body ">
                        <div onClick={() => {
                              navigate({
                                pathname: `/payroll-work-verify/${item.senderID}`,
                                search: `?${  createSearchParams({ppeID:item.forID})  } `,
                              });
                        }} to={ActivityItem(item.type, item)?.link}>
                          <span>{item.senderName}</span>
                        </div>
                        <P>
                          {/* {moment(item.CreatedOn).format("DD-MM-YYYY hh:mm A")} */}
                          {item.message}
                        </P>
                        </div>
                        <div>
                             {moment(item.CreatedOn).format("DD-MM-YYYY hh:mm A")}
                        </div>
                      </div>
                    </div>
                  </td> 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default NotiFicationTable;
