import React from "react";
import { Hidden, Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";

const EarningHeader = (props) => {
  const { isUpdate, handleSubmit, clearField, configrationJSON } = props;
  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">
            {" "}
            {configrationJSON.headerTitle}
          </Typography>
        </div>
      </div>
    </CardHeader>
  );
}

export default EarningHeader;
