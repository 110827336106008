import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableComponent from "./tableComponent";
import FilterList from "./filterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash } from "@fortawesome/pro-duotone-svg-icons";
// import ConfirmModal from "../../../../app/utility/ConfirmModal";
import axios from "axios";
import { Row } from "antd";
import { toast } from "react-toastify";
import TaxDetailModal from "./TaxDetailModal";
import ModalCustom from "../../employee/Modal";
import EmployeePayrollDetail from "./EmployeePayrollDetail";
import { updateEmployeePayrollProcessRecord } from "../../../app/utility/axiosService";
// import ModalCustom from "../../employee/Modal";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, role, isPreviewOption } = props;

  const [filterBy, setFilter] = useState({
    employeeId: '',
    totalAmount: '',
    deductionAmount: '',
    withheldTax: '',
    netPay: '',
  });

  // const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  // const [defaultRemoveData, setDefaultRemoveData] = useState(null);
  const [openTaxDetailModal, setOpenTaxDetailModal] = useState(false);
  const [empDetailLoading, setEmpDetailLoading] = useState(false);
  const [openEmployeePayrollListDetailModal, setOpenEmployeePayrollListDetailModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.employeeId) {
        dataList = dataList.filter((data) =>
          data.employeeId?.toString().includes(
            filterBy.employeeId?.toString()
          )
        );
      }

      if (filterBy?.totalAmount) {
        dataList = dataList.filter((data) =>
          data.totalAmount?.toString().includes(
            filterBy.totalAmount?.toString()
          )
        );
      }

      if (filterBy?.deductionAmount) {
        dataList = dataList.filter((data) =>
          data.deductionAmount?.toString().includes(
            filterBy.deductionAmount?.toString()
          )
        );
      }

      if (filterBy?.withheldTax) {
        dataList = dataList.filter((data) =>
          data.withHeldTax?.toString().includes(
            filterBy.withheldTax?.toString()
          )
        );
      }

      if (filterBy?.netPay) {
        dataList = dataList.filter((data) =>
          data.netPay?.toString().includes(
            filterBy.netPay?.toString()
          )
        );
      }

      if (filterBy?.name) {
        dataList = dataList.filter((data) =>
          data.name.toLowerCase().includes(
            filterBy.name.toLocaleLowerCase()
          )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const changeHeaderFilter1 = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filterBy, [name]: value });
  };

  // const confimRemoveClick = async () => {
  //   axios.delete(`${process.env.REACT_APP_WEB_API_URL}StateTax/Delete?id=${defaultRemoveData.id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setOpenRemoveAlert(false);
  //         toast.success('Record Deleted');
  //         props.getData();
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error('Something went wrong');
  //     });
  // };

  const handleRowClick = (data) => {
    data = data.data;
    let info = {
      oldId: data.id,
      code: data.code,
      name: data.name,
    };

    props.setDetail(info);
  };

  const handleSaveEmployeeDetail = async (checkNumber) => {
    if (!checkNumber) {
      toast.error("Please Enter Check Number");
      return;
    }

    setEmpDetailLoading(true);

    const payload = {
      id: selectedData?.id,
      checkNumber: Number(checkNumber),
    }

    const res = await updateEmployeePayrollProcessRecord(payload);
    if (res?.status === 200) {
      toast.success("Payroll Updated successfully");
      setOpenEmployeePayrollListDetailModal(false);
    } else {
      toast.warn("Something went wrong");
    };
    setEmpDetailLoading(false);
  }

  const columns = [
    {
      id: "id",
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row>
            <FontAwesomeIcon
              icon={faEye}
              className="sub-header trashIcon"
              onClick={() => {
                setSelectedData(tableMeta);
                setOpenTaxDetailModal(true);
                // setDefaultRemoveData(tableMeta);
                // setOpenRemoveAlert(true);
              }}
            />
            {isPreviewOption &&
              <FontAwesomeIcon
                icon={faPen}
                className="sub-header trashIcon"
                onClick={() => {
                  setSelectedData(tableMeta);
                  setOpenEmployeePayrollListDetailModal(true);
                  // setDefaultRemoveData(tableMeta);
                  // setOpenRemoveAlert(true);
                }}
              />
            }
          </Row>
        },
      },
    },
    {
      id: "employeeId",
      name: "Employee Id",
    },
    {
      id: "name",
      name: "Employee Name",
    },
    {
      id: "totalAmount",
      name: "Total Amount",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.totalAmount ? tableMeta.totalAmount : 0;
          return `$${(Number(temp)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}`;
        },
      },
    },
    {
      id: "deductionAmount",
      name: "Deduction Amount",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.deductionAmount ? tableMeta.deductionAmount : 0;
          return `$${(Number(temp)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}`;
        },
      },
    },
    {
      id: "withHeldTax",
      name: "Withheld Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.withHeldTax ? tableMeta.withHeldTax : 0;
          return `$${(Number(temp)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}`;
        },
      },
    },
    {
      id: "netPay",
      name: "Net Pay",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = tableMeta?.netPay ? tableMeta.netPay : 0;
          return `$${(Number(temp)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}`;
        },
      },
    },
    // {
    //   id: "Action",
    //   name: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <Row>
    //         <FontAwesomeIcon
    //           icon={faTrash}
    //           className="sub-header trashIcon"
    //           onClick={() => {
    //             setDefaultRemoveData(tableMeta);
    //             setOpenRemoveAlert(true);
    //           }}
    //         />
    //       </Row>
    //     },
    //   },
    // },
  ];

  return (
    <>
      <TableComponent
        columns={columns}
        filteredList={filteredList}
        totalCount={props.totalCount}
        setSkipCount={props.setSkipCount}
        // onRowClick={handleRowClick}
        tableClass={classes.scrollHeight}
        filterComponent={
          filteredList && (
            <FilterList
              changeHeaderFilter={changeHeaderFilter1}
              role={role}
              requestDetail={requestDetail}
              filterBy={filterBy}
            />
          )
        }
      />
      <ModalCustom
        open={openTaxDetailModal}
        handleClose={() => {
          setOpenTaxDetailModal(false);
        }}
        title='Pay Breakup'
        closeButton={true}
      >
        <TaxDetailModal selectedData={selectedData} />
      </ModalCustom>
      <ModalCustom
        open={openEmployeePayrollListDetailModal}
        handleClose={() => {
          setOpenEmployeePayrollListDetailModal(false);
        }}
        title='Employee Payroll Detail'
        closeButton={true}
      >
        <EmployeePayrollDetail
          selectedData={selectedData}
          saveData={handleSaveEmployeeDetail}
          loading={empDetailLoading}
          closeModal={() => setOpenEmployeePayrollListDetailModal(false)} 
        />
      </ModalCustom>
      {/* <ConfirmModal
        open={openRemoveAlert}
        setOpen={setOpenRemoveAlert}
        setOpenRemoveAlert={setOpenRemoveAlert}
        confimClick={confimRemoveClick}
        message={
          "Are You Sure you want to delete?"
        }
        modalHeader="Alert Message"
      /> */}
    </>
  );
};

export default DataTable;