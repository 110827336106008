import React, { Component, Fragment } from "react";

import { Form, Input, Space, message, Row } from "antd";

import Autocomplete from "@mui/material/Autocomplete";

import { Edit, RotateLeft, Visibility, Delete } from "@material-ui/icons";
// import LRSMuiTable from "./LRSMuiTable";

import { CircularProgress, Tooltip } from "@material-ui/core";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import axios from "axios";
import "../../Reports/HoursReport/component/style.css";
import { isEmpty } from "lodash/lang";
// import { getUserId } from "../../app/utility/helper";
import { useAuth } from "oidc-react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Multiselect } from "multiselect-react-dropdown";

import moment from "moment";
import {
  CardContent,
  IconButton,
  // InputLabel,
  Button,
  // FormControl,
  withStyles,
  Typography,
} from "@material-ui/core";
// import Select, { SelectChangeEvent } from "@mui/material/Select";

import { TextField } from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import { RemoveCircleTwoTone } from "@material-ui/icons";
import clsx from "clsx";
// import SimpleSearch from "./SimpleSearch";

import ModalCustom from "../../employee/Modal";
import { recordFormMainFields } from "../../employee/redundanceyArray";

import SavedQueries from "../../employee/SavedQueries";
import {
  refreshQuery,
  reRunFalseAction,
  fetchCols,
  fetchPlaceholderCols,
  callSearchRecord,
  callEmptySearch,
  userGroupStore,
  fetchSearchColumns,
} from "../../../app/store/reducers/slices/querySlice";

import { fetchPayPeriodMaster } from "../../../app/store/reducers/slices/masterSlice";
import { setToolbarHeader } from "../../../app/store/reducers/slices/toolbarHeaderSlice";

import { fetchAdvanceSearchData } from "../../../app/store/reducers/slices/configurationSlice";
import { Card, Col, Label } from "reactstrap";
import { fetchEmployeeIssues } from "../../../app/store/reducers/slices/employeeSlice";
import { fetchQueryIssuesSearch } from "../../../app/store/reducers/slices/chartSlice";
import SaveNewQuery from "../../employee/SaveNewQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBadge,
  faBadgeCheck,
  faBroom,
  faBroomBall,
  faFloppyDisk,
  faMagnifyingGlass,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const styleField = {
  width: "100%",
  color: "red",
  fontSize: "40px",
};

const advanceFormStyles = {
  containerForm: {
    "& .MuiInputBase-input": {
      height: "5.5rem",
      minWidth: "209px",
    },
  },
};

function convertingText(str) {
  var splitStr = str.toLowerCase().replace(/,/g, "").split("_");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1) + " ";
  }

  return splitStr;
}
let colNewTable = [
  {
    label: "Doc No",
    name: "doc_no",
    options: { filter: true },
  },
  {
    label: "Document Title",
    name: "document_title",
    options: { filter: true },
  },
  {
    label: "Source Type",
    name: "sourcetype",
    options: { filter: true },
  },
  {
    label: "Document Date",
    name: "dated",
    options: { filter: true },
  },
  {
    label: "Record Date",
    name: "recorded_date",
    options: { filter: true },
  },
  {
    label: "Document Type",
    name: "transaction_value",
    options: { filter: true },
  },
  {
    label: "Parcel",
    name: "parcel_source_search",
    options: { filter: true },
  },

  {
    label: "Grantor",
    name: "grantor",
    options: { filter: true },
  },
  {
    label: "Grantee",
    name: "grantee",
    options: { filter: true },
  },
  {
    label: "Ref Docs",
    name: "ref_document",
    options: { filter: true },
  },

  {
    label: "CT’s",
    name: "certificate",
    options: { filter: true },
  },
  {
    label: "Estate",
    name: "estate_no",
    options: { filter: true },
  },
  {
    label: "Actions",
    name: "ignore",

    options: {
      filter: false,
    },
  },
];
const operator = [
  {
    operation: "=",
    name: "Equal To",
  },
  {
    operation: "!=",
    name: "Not Equal To",
  },
  {
    operation: "isnull",
    name: "Is Empty",
  },
  {
    operation: "end",
    name: "Ends With",
  },
  {
    operation: ">",
    name: "Greater Than",
  },
  {
    operation: "<",
    name: "Less Than",
  },
  {
    operation: ">=",
    name: "Greater Than And Equal to",
  },
  {
    operation: "<=",
    name: "Less Than And Equal to",
  },
  {
    operation: "wildcard",
    name: "Wild Card",
  },
  {
    operation: "parse",
    name: "Parse",
  },
  {
    operation: "array",
    name: "Array",
  },
];

const operatorForString = [
  {
    operation: "=",
    name: "Equal To",
  },
  {
    operation: "!=",
    name: "Not Equal To",
  },
  // {
  //   operation: "~*",
  //   name: "Contains",
  // },
  // {
  //   operation: "!~*",
  //   name: "Not Contains",
  // },
  {
    operation: "like",
    name: "Contains",
  },
  {
    operation: "notlike",
    name: "Not Contains",
  },

  {
    operation: "isnull",
    name: "Is Empty",
  },
  {
    operation: "start",
    name: "Starts With",
  },
  {
    operation: "end",
    name: "Ends With",
  },
  {
    operation: "wildcard",
    name: "Wild Card",
  },
  {
    operation: "parse",
    name: "Parse",
  },
  {
    operation: "array",
    name: "Array",
  },
];

operator.sort((a, b) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
);

const initialValues = {
  query_name: "",
  user_group_id: null,
  queries: [
    {
      value: "",
      operator: "=",
      column: "",
      condition: "AND",
    },
  ],
};
const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const rows = [
  { id: 2, module: "PTR", type: "standard", value: 210, index: 0 },
  { id: 3, module: "PTR", type: "rush", value: 340, index: 1 },
];
const options = () => ({
  filter: true,
  filterType: "dropdown",
  tableBodyMaxHeight: "calc(100vh - 280px)",
  fixedHeader: true,
  selectableRows: "none",
  draggableColumns: {
    enabled: true,
    transitionTime: 300,
  },
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 10, 25, 100],
  viewColumns: false,
  download: false,
  print: false,
});
class DeductionReportAdvanceSearch extends Component {
  state = {
    removeColumns: [],
    selectedFields: [],
    fieldChange: false,
    options: [
      { key: 1, value: "Best Jom Doe" },
      { key: 2, value: "Jane Doe" },
      { key: 3, value: "Wandri Doe" },
      { key: 4, value: "Hiren " },
    ],
    value: "",
    csvData: [],
    rows: [],
    columns: [{ value: "", pl: "" }],
    selectColumns: [],
    cols: [],
    sortedCols: [],
    postQueries: [],
    loading: false,
    payPeriodMater: [],
    searchColumns: [],
    searchClicked: false,
    searchMode: "advance",
    searchText: "",
    filters: [],
    openSave: false,
    openSaveQuery: false,
    saveQuery: false,
    queryName: "",
    initialValues: {
      query_name: "",
      user_group_id: null,
      queries: [
        {
          value: "",
          operator: "=",
          column: "",
          condition: "AND",
        },
      ],
    },
    groups: "",
    currentSelectedGroups: [],
    orderBy: "",
    orderField: "",
    cPage: 1,
    newColumn: [],
    rowArray: [],
    userGroupID: [],
    role: "",
  };
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ loading: true });
    const columns = [];
    const obj = { value: "", placeholder: "Search text here" };
    columns[0] = { ...obj };
    this.setState({ columns });
    this.apiGet();
    this.apiPostSimpleSearch(this.state.searchText, 1, 20, []);
    const uuid = this.props.token;
    this.props.status === "admin" && this.props.userGroups(uuid);
    this.props.fetchSearchColumns({ tableName: "fn_overallDeductions" });
  }

  componentWillUnmount() {
    this.props.reRunFalse();
    this.props.changeQuery();
    this.props.emptySearch();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('prev Props is', prevProps.reRun);
    // console.log('now props', this.props.reRun);
    if (prevProps.reRun !== this.props.reRun) {
      // console.log('condition tue ->>>>>>>');
      // console.log(this.props);
      this.onFinishedRrun();
    }
    if (!this.state.newColumn.length && this.props.colsPlaceholder) {
      const cols = this.props.cols;
      console.log("cols==a", cols);
      // this.getNewColumns(cols)
    }
  }

  handleSave = () => {
    this.setState({ openSave: !this.state.openSave }, () => {});
  };

  getData = (queries) => {
    this.setState({
      openSaveQuery: false,
      saveQuery: false,
    });
  };

  handleViewSavedQuery = () => {
    this.setState({ openSaveQuery: !this.state.openSaveQuery }, () => {});
  };

  handleSaveNewQuery = () => {
    this.setState({ openSave: !this.state.openSave }, () => {});
  };

  handleChangeSelect = (value) => {
    const selectedFields = [...this.state.selectedFields, value];
    this.setState({ selectedFields });
  };

  onColumnChange = (value, index) => {
    let pl = "Search text here";
    const columns = [...this.state.columns];

    if (value == "recorded_date" || value == "dated" || value == "recorded") {
      pl = "YYYY-MM-DD";
      const obj = {
        value,
        placeholder: pl,
        type: "date",
        reg: /^\d{4}[-]\d{2}[-]\d{2}$/,
      };
      columns[index] = { ...obj };
    } else {
      const obj = { value, placeholder: pl, type: "text", reg: "" };
      columns[index] = { ...obj };
    }

    this.setState({ columns });
  };
  changeSortOrder = (orderBy1, orderField1) => {
    console.log("State value updated", orderBy1, "#", orderField1);
    this.setState({ orderBy: orderBy1, orderField: orderField1 });
  };
  onChangeSearchMode = (e) => {
    this.setState(
      {
        searchMode: e.target.value,
        rows: [],
        selectColumns: [],
      },
      () => {
        if (e.target.value == "advance") {
          this.buttonRef.current.click();
          const columns = [];
          const obj = { value: "", placeholder: "Search text here" };
          columns[0] = { ...obj };
          this.setState({ columns });
          this.apiGet();
        }
      }
    );
  };

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };

  // onSubmitClick = () => {
  //   alert("Submit Clicked...");
  //   this.saveQueryApi();
  // };

  // onSearchClick = () => {
  //   alert("Search Clicked...");
  //   this.apiPost();
  // };

  onFinished = (values) => {
    console.log("VASCO", values);
    const { queries } = values;
    const temp = [];
    let isValidDate = true;
    let myop = "";

    queries.map((element, index) => {
      if (typeof element.value !== "undefined") {
        // element['value'] = element.value.trim() ;
        element.dataType = "";

        if (typeof element.operator === "undefined") {
          element.operator = "=";
        }
        if (typeof element.condition === "undefined") {
          element.condition = "AND";
        }
        if (element.column) {
          element.columnName = element.column;
          // delete element["column"];
        }

        const menuQueries = { ...element };
        menuQueries.value = menuQueries.value;
        if (index == 0) {
          temp.push({
            ...menuQueries,
          });
        } else if (index > 0) {
          temp.push({
            ...menuQueries,
          });
        }
      }

      if (
        isValidDate &&
        [
          "execution_date",
          "maturity_date",
          "recorded_date",
          "dated",
          "created_at",
          "updated_at",
        ].includes(element.column)
      ) {
        if (["<=", "<", ">", ">=", "=", "!="].includes(element.operator)) {
          let datevalue = element.value;
          myop = element.operator;
          var aDate = moment(datevalue, "YYYY-MM-DD", true);
          isValidDate = aDate.isValid();
        }
      }
    });

    if (!isValidDate) {
      console.log("element.operator");
      //console.log("xxx",!["isnull"].includes(element.operator));
      message.error({ content: "Enter valid format date asd", key: "forSave" });
      return false;
    }

    console.log(
      "this.state.openSave : ",
      this.state.openSave,
      this.props.userId
    );
    if (this.state.openSave) {
      const list = [];

      // queries.forEach((item) => {
      //   list.push({
      //     collumn: item.column,
      //     condition: item.condition,
      //     value: item.value.toUpperCase(),
      //     operator: item.operator,
      //   });
      // });
      queries.forEach((item) => {
        list.push({
          columnName: item.column,
          // condition: item.condition,
          dataType: "",
          value: item.value,
          operator: item.operator,
        });
      });
      console.log("TEAMKEY", this.state.currentSelectedGroups);

      // this.saveQueryApi({
      //   name: values.query_name,
      //   user_group_id:
      //     this.state.userGroupID.length === 0 ? null : this.state.userGroupID,
      //   userId: this.props.userId,
      //   list,
      // });
      this.saveQueryApi({
        queryName: values.query_name,
        permissionType: this.state.role,
        permissionValue: this.state.role,
        // this.state.userGroupID.length === 0 ? null : this.state.userGroupID,
        userId: this.props.userId,
        tableName: "fn_DeductionByVendor",
        params: list,
        columns: "*",
        limit: 10,
        page: 1,
        orderBy: "PPE",
        sortBy: "DESC",
      });
    } else {
      delete temp[0].condition;
      this.setState({
        loading: true,
        rows: [],
        postQueries: temp,
        searchClicked: true,
      });
      console.log("TEAM-POST");
      this.apiPost();
    }
  };

  onFinishedRrun = async (values) => {
    const { queries } = this.props;
    // console.log('check queries', queries);

    const temp = [];
    queries.map((element, index) => {
      if (typeof element.value !== "undefined") {
        if (typeof element.operator === "undefined") {
          element.operator = "=";
        }
        if (typeof element.condition === "undefined") {
          element.condition = "AND";
        }

        if (index == 0) {
          temp.push({
            ...element,
          });
        } else if (index > 0) {
          temp.push({
            ...element,
          });
        }
      }
    });
    // console.log('temp is', temp);
    console.log("this.state.openSave1 : ", this.state.searchClicked);

    if (this.state.openSave && !this.state.searchClicked) {
      const list = [];
      queries.forEach((item) => {
        list.push({
          collumn: item.column,
          condition: item.condition,
          value: item.value,
          operator: item.operator,
        });
      });

      this.saveQueryApi({
        name: values.query_name,
        list,
      });
    } else {
      // delete temp[0].condition;

      await this.setState({
        loading: true,
        rows: [],
        postQueries: temp,
        searchClicked: true,
      });
      // this.state.postQueries = temp;
      this.apiPost();
    }
  };

  saveQueryApi = (data) => {
    message.loading({ content: "Saving ...", key: "forSave" });

    axios
      .post(
        // `query/save`,
        `${process.env.REACT_APP_API_URL}advancesearch/v2/save`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        message.success({ content: "Query Saved", key: "forSave" });
        this.setState({
          openSave: false,
        });
      })
      .catch((error) => {
        // message.error({ content: error.message, key: "forSave" });
      });
    // axios
    // .post(
    //   // `query/save`,
    //   `${process.env.REACT_APP_API_URL}query/save`,
    //   data,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-access-token": localStorage.getItem("jwt_access_token"),
    //     },
    //   }
    // )
    // .then((response) => {
    //   message.success({ content: "Query Saved", key: "forSave" });
    //   this.setState({
    //     openSave: false,
    //   });
    // })
    // .catch((error) => {
    //   // message.error({ content: error.message, key: "forSave" });
    // });
  };

  apiPostSimpleSearch = (text, page, limit, filters) => {
    if (text) this.setState({ searchText: text });

    if (!filters.length) {
      this.setState({ loading: false });
      return false;
    }
    this.setState({ loading: true });

    axios
      .post(`${process.env.REACT_APP_API_URL}search/employee2`, {
        searchText: text == null ? this.state.searchText : text,
        page: page || 1,
        limit: limit || 2000,
        filters: filters || [],
      })
      .then((response) => {
        const { rows, total, columns } = response.data;

        const { searchClicked } = this.state;

        let temp = [];
        if (rows.length == 0) {
          message.error("No record found");
          temp = this.settingColumns(columns);
          this.setState({ loading: false, selectColumns: temp });
        } else {
          if (searchClicked) message.success(`Total ${total} Records found`);
          if (total > 2000) {
            if (searchClicked)
              message.info(
                `Your query has resulted in more that 2,000 records, please enter more specific search criteria`
              );
          }

          temp = this.settingColumns(columns);
        }
        this.setState({
          rows,
          loading: false,
          total,
          searchClicked: false,
          selectColumns: temp,
        });
        // this.getRowData(rows)
      })
      .catch((error) => {
        this.setState({ loading: false });
        // message.error("Server Not Responding");
      });
  };

  apiPost = (page, limit, filters, resetpage = true) => {
    // console.log('check state', this.state.postQueries);
    if (this.state.postQueries.length === 0) return;
    if (filters) this.setState({ filters });
    this.setState({ loading: true });
    if (resetpage) {
      this.setState({ cPage: 1 });
    }
    console.log("111 : ", this.state.postQueries);
    const payload = {
      P_PPE: "16",
      tableName: "fn_LabourCostDistribution",
      params:
        // [
        //   {
        //     value: "TEST UPDATEd",
        //     operator: "like",
        //     column: "DPDV",
        //   },
        // ],
        [...this.state.postQueries],
      columns: "*",
      page: page || 1,
      limit: limit || 2000,
      // filters: filters || this.state.filters,
      // orderBy: this.state.orderBy,
      orderBy: "PPE",
      sortBy: "DESC",
      // orderField: this.state.orderField,
    };
    localStorage.setItem("adv_deduction_query", JSON.stringify(payload));

    // this.props
    //   .queryLaborCost(payload)
    //   .then(() => {
    //     this.setState({ loading: false });
    //   })
    //   .catch((err) => {
    //     this.setState({ loading: false });
    //   });
  };
  onChangeFilter = (e, val) => {
    let addedColumns = this.state.removeColumns;
    if (e === false) {
      this.setState({ removeColumns: [...this.state.removeColumns, val] });
      if (val.toLowerCase() === "transaction_value") {
        this.setState({
          removeColumns: [...this.state.removeColumns, "transaction_Value"],
        });
      }
      // this.setState({ ...removeColumns, removeColumns: removeColumns.push(val) })
    } else {
      var index = addedColumns.indexOf(val);
      if (index > -1) {
        addedColumns.splice(index, 1);
      }
      this.setState({ removeColumns: addedColumns });
      if (val.toLowerCase() === "transaction_value") {
        var index = addedColumns.indexOf("transaction_Value");
        if (index > -1) {
          addedColumns.splice(index, 1);
        }
        this.setState({ removeColumns: addedColumns });
      }
    }
  };

  getRowData = (data_rows) => {
    console.log("this.state.rows 2", data_rows);
    // let rowdata = data_rows.map(row => [row.lender_name, row.doc_no.toUpperCase(), row.document_title,
    // 	<div>
    // 		<Edit onClick={() => { console.log("Cliekc") }} />&nbsp;
    // 	</div>
    // ])
    try {
      let recordCollection = [];
      let rowCollection = [];
      Promise.all(
        data_rows.map((row) => {
          rowCollection = [];
          colNewTable.map((i) => {
            if (i.name != "ignore") {
              rowCollection.push(row[i.name]);
            }
          });
          let iconA = (
            <div>
              <Delete
                onClick={() => {
                  console.log("Cliekc");
                }}
              />
              <Edit
                onClick={() => {
                  console.log("Cliekcxxx");
                }}
              />
            </div>
          );
          rowCollection.push(iconA);
          recordCollection.push(rowCollection);
        })
      );
      console.log("rowdata", recordCollection);
      this.setState({ rowArray: recordCollection });
    } catch (error) {
      console.log("message-->", error.message);
    }
  };

  settingColumnsFilterList = (columns, type = "old") => {
    const temp = [];
    const tempNewCol = [];
    columns.forEach((d, index) => {
      let e = d.value;
      let col = {};
      let colNewTable = {};
      switch (e) {
        case "docno":
          col = {
            name: convertingText(e),
            value: e,
            short: "Doc No",
          };
          colNewTable = {
            label: "Doc No",
            name: e,
            options: { filter: true },
          };
          break;
        case "document_title":
          col = {
            name: convertingText(e),
            value: e,
            short: "Document Title",
          };
          colNewTable = {
            label: "Document Title",
            name: e,
            options: { filter: true },
          };
          break;
        case "sourcetype":
          col = {
            name: convertingText(e),
            value: e,
            short: "Source Type",
          };
          colNewTable = {
            label: "Source Type",
            name: e,
            options: { filter: true },
          };
          break;

        case "dated":
          col = {
            name: convertingText(e),
            value: e,
            short: "Document Date",
          };
          colNewTable = {
            label: "Document Date",
            name: e,
            options: { filter: true },
          };
          break;
        case "parcel_source_search":
          col = {
            name: convertingText(e),
            value: e,
            short: "Parcel",
          };
          colNewTable = {
            label: "Parcel",
            name: e,
            options: { filter: true },
          };
          break;

        case "estate_no":
          col = {
            name: convertingText(e),
            value: e,
            short: "Estate",
          };
          colNewTable = {
            label: "Estate",
            name: e,
            options: { filter: true },
          };
          break;
        case "grantor":
          col = {
            name: convertingText(e),
            value: e,
            width: 250,
            short: "Grantor",
          };
          colNewTable = {
            label: "Grantor",
            name: e,
            options: { filter: true },
          };
          break;
        case "grantee":
          col = {
            name: convertingText(e),
            value: e,
            width: 250,
            short: "Grantee",
          };
          colNewTable = {
            label: "Grantee",
            name: e,
            options: { filter: true },
          };
          break;
        case "ref_document":
          col = {
            name: convertingText(e),
            value: e,
            short: "Ref Docs",
          };
          colNewTable = {
            label: "Ref Docs",
            name: e,
            options: { filter: true },
          };
          break;
        case "survey_ref_doc":
          col = {
            name: convertingText(e),
            value: e,
            short: "Ref Survey",
          };
          colNewTable = {
            label: "Ref Survey",
            name: e,
            options: { filter: true },
          };
          break;
        case "certificate":
          col = {
            name: convertingText(e),
            value: e,
            short: "CT’s",
          };
          colNewTable = {
            label: "CT’s",
            name: e,
            options: { filter: true },
          };
          break;

        case "recorded_date":
          col = {
            name: convertingText(e),
            value: e,
            short: "Record Date",
          };
          colNewTable = {
            label: "Record Date",
            name: e,
            options: { filter: true },
          };
          break;
        case "process_date":
          col = {
            name: convertingText(e),
            value: e,
            short: "Exe Date",
          };
          colNewTable = {
            label: "Exe Date",
            name: e,
            options: { filter: true },
          };
          break;
        case "transaction_value":
          col = {
            name: convertingText(e),
            value: e,
            short: "Document Type",
          };
          colNewTable = {
            label: "Document Type",
            name: e,
            options: { filter: true },
          };
          break;

        default:
          break;
      }
      if (col.value) {
        temp.push(col);
        tempNewCol.push(colNewTable);
      }
    });
    if (type == "old") {
      return temp;
    } else {
      return tempNewCol;
    }
  };

  hideShowCols = (clearFilters) => {
    let mycol = this.state.selectColumns;
    let removeColumnsArray = this.state.removeColumns;

    return mycol.filter((i) => !removeColumnsArray.includes(i.value));
  };
  getNewColumns = (mycol) => {
    console.log("mycol", mycol);

    let add = [];
    mycol.map((item) => {
      let obj = {
        label: item.name,
        name: item.value,
        options: {
          filter: true,
        },
      };
      add.push(obj);
    });
    console.log("Bhaipura", add);
    this.setState({ newColumn: add });
  };
  settingColumns = (columns) => {
    const temp = [];
    columns.forEach((e, index) => {
      let col = {};
      switch (e) {
        case "docno":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Doc No",
          };
          break;
        case "document_title":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Document Title",
          };
          break;
        case "sourcetype":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Source Type",
          };
          break;

        case "dated":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Document Date",
          };
          break;
        case "parcel_source_search":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Parcel",
          };
          break;
        case "estate_no":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Estate",
          };
          break;
        case "grantor":
          col = {
            name: convertingText(columns[index]),
            value: e,
            width: 250,
            short: "Grantor",
          };
          break;
        case "grantee":
          col = {
            name: convertingText(columns[index]),
            value: e,
            width: 250,
            short: "Grantee",
          };
          break;
        case "ref_document":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Ref Docs",
          };
          break;
        case "survey_ref_doc":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Ref Survey",
          };
          break;
        case "certificate":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "CT’s",
          };
          break;

        case "recorded_date":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Record Date",
          };
          break;
        case "process_date":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Exe Date",
          };
          break;
        case "transaction_value":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Document Type",
          };
          break;
        case "transaction_Value":
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: "Document Type",
          };
          break;
        default:
          col = {
            name: convertingText(columns[index]),
            value: e,
            short: e,
          };
          break;
      }
      temp.push(col);
    });
    // columns.forEach((e, index) => {
    // 	let col = {};
    // 	// console.log('e value', e);

    // 	switch (e) {

    // 		case 'grantor':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				width:300,
    // 				short: 'Grantor'
    // 			};
    // 			break;
    // 		case 'grantee':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				width:300,
    // 				short: 'Grantee'
    // 			};
    // 		break;
    // 		case 'docno':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Doc No'
    // 			};
    // 			break;
    // 		case 'dated':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Document Date'
    // 			};
    // 		break;
    // 		case 'doc_type':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Doc Type'
    // 			};
    // 			break;

    // 		case 'parcel_source_search':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Parcel'
    // 			};
    // 			break;
    // 		case 'estate_no':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Estate'
    // 			};
    // 			break;
    // 		case 'grantor':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Grantor'
    // 			};
    // 			break;
    // 		case 'grantee':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Grantee'
    // 			};
    // 			break;
    // 		case 'ref_document':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Ref Docs'
    // 			};
    // 			break;
    // 		case 'survey_ref_doc':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Ref Survey'
    // 			};
    // 			break;
    // 		case 'certificate':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'CT’s'
    // 			};
    // 			break;

    // 		case 'recorded_date':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Record Date'
    // 			};
    // 			break;

    // 		case 'transaction_value':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Document Type'
    // 			};
    // 			break;
    // 		case 'transaction_Value':
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: 'Document Type'
    // 			};
    // 			break;

    // 		default:
    // 			col = {
    // 				name: convertingText(columns[index]),
    // 				value: e,
    // 				short: e
    // 			};
    // 			break;
    // 	}
    // 	temp.push(col);
    // });
    return temp;
  };

  apiGet = () => {
    this.setState({ loading: true });
    this.props.fetchAdvanceSearchData();
    this.props.fetchPayPeriodMaster();
    setToolbarHeader("LRS Advance Search");
    if (isEmpty(this.props.cols)) {
    }
    if (isEmpty(this.props.cols)) {
      this.props.fetchCols();
    }
    if (isEmpty(this.props.colsPlaceholder)) {
      this.props.fetchPlaceholderCols();
    }
  };

  getLoadData = (type) => {
    let advData = this.props.advanceSearchData;

    console.log(
      "type ====>",
      type,
      this.props.payPeriodMater && this.props.payPeriodMater
    );

    console.log("advDAta", advData);
    if (type == "transaction_value") {
      return advData.transaction_type;
    } else if (type == "DPDV") {
      return advData?.DPDV;
    } else if (type == "PPE") {
      return this.props.payPeriodMater;
    } else if (type == "Empl_Type") {
      return advData?.EmpType;
    } else if (type == "Inactive_Status") {
      return advData?.Inactive_Status;
    } else if (type == "Education") {
      return advData.Education;
    } else if (type == "Emp_Status") {
      return advData?.EmpStatus;
    } else if (type == "Nposition_No") {
      return advData?.Nposition_No;
    } else if (type == "Ethnic_Group") {
      return advData?.Ethnic_Group;
    } else if (type == "mortgage") {
      let mortgageData = recordFormMainFields.find((i) => {
        return i.name == "mortgage";
      });
      if (mortgageData) {
        let newMortageArray = [];
        mortgageData.options.map((i) => {
          newMortageArray.push({ value: i.name, label: i.value });
        });
        return newMortageArray;
      } else {
        return [];
      }
    } else if (type == "lender_name") {
      return advData.lender;
    } else if (type == "sourcetype") {
      return [
        { value: "Alpha", label: "Alpha" },
        { value: "Official", label: "Official" },
      ];
    } else if (type == "ownership_type") {
      // return advData.vesting;
      let ownershipType = recordFormMainFields.find((i) => {
        return i.name == "ownership_type";
      });
      if (ownershipType) {
        let newMortageArray = [];
        ownershipType.options.map((i) => {
          newMortageArray.push({ value: i.name, label: i.value });
        });
        return newMortageArray;
      } else {
        return [];
      }
    } else if (type == "doc_type") {
      return advData.doc_type;
    } else if (type == "record_type") {
      return advData.doc_type;
    } else {
      return [];
    }
  };

  adding = (add) => {
    add();
  };

  del = (remove, fieldName) => {
    const columns = [...this.state.columns];
    if (columns[fieldName]) columns[fieldName].placeholder = "Search text Here";
    this.setState({ columns });
    remove(fieldName);
  };

  onReset = () => {
    this.formRef.current.resetFields();
  };

  onActionOrChild = (value) => {
    const newData = value?.map((item) => {
      return item.user_group_id;
    });
    this.setState({
      currentSelectedGroups: newData,
    });
  };

  handleGroupShare = (values) => {
    let groupArray = [];
    groupArray.push(values.user_group_id);
    this.setState({
      currentSelectedGroups: groupArray,
    });
  };

  onSelectUserGroup = (selected) => {
    console.log("selected", selected);
    // let groupArray = selected.map((i) => {
    //   return i.id;
    // });
    this.setState({ role: selected });
  };
  onRemoveUserGroup = (selected) => {
    console.log("selected remove", selected);
    let groupArray = selected.map((i) => {
      return i.id;
    });
    this.setState({ userGroupID: groupArray });
  };
  handleClear = (resetForm) => {
    resetForm();
    this.setState({
      postQueries: [],
    });
    this.props.getData();
  };

  // formatCSV = () => {
  //   this.apiPostSimpleSearch(
  //     this.state.searchText,
  //     1,
  //     this.state.total,
  //     this.state.filters
  //   );
  //   let newArray = [];
  //   let dataDownload = this.state.rows;
  //   this.setState({ csvData: dataDownload });
  // };
  onSearch = (searchText) => {
    this.setState(
      !searchText
        ? []
        : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]
    );
  };
  onSelect = (data) => {
    console.log("onSelect", data);
  };
  onChange = (data) => {
    // setValue(data);
    this.setState({ value: data });
  };
  checkSearchInputType = (data) => {
    // setValue(data);
  };
  render() {
    const { Option } = Select;

    const { columns, advance, searchMode } = this.state;

    const {
      classes,
      cols: propsCols,
      colsPlaceholder: cols_placeholder,
    } = this.props;

    console.log("colsPlaceholder", this.cols_placeholder);

    const data = this.props.initialState;

    const value1223 = [
      {
        queries: [
          {
            column: "",
            condition: "AND",
            operator: "=",
            value: "",
          },
        ],
        query_name: "",
        user_group_id: null,
      },
    ];

    return (
      <>
        <Card style={{ width: "100%", padding: "10px" }}>
          <Col lg={12} md={12} sm={12} xs={12} className="">
            {searchMode == "advance" ? (
              <div
                className={clsx("form-parent-div1")}
                style={{ justifyContent: "left" }}
              >
                <Formik
                  initialValues={data}
                  enableReinitialize={true}
                  onSubmit={(values, options) =>
                    this.onFinished(values, options)
                  }
                >
                  {({ values, errors, handleSubmit, setValue, resetForm }) => (
                    <form autoComplete="Off" onSubmit={handleSubmit}>
                      <ModalCustom
                        open={this.state.openSaveQuery}
                        handleClose={this.handleViewSavedQuery}
                        closeButton
                        fullWidth
                      >
                        <SavedQueries
                          getData={this.getData}
                          tableName="fn_DeductionByVendor"
                          edit
                        />
                      </ModalCustom>

                      <FieldArray
                        name="queries"
                        render={(arrayHelpers) => {
                          return (
                            <>
                              {console.log("values", values)}
                              {values &&
                              values.queries &&
                              values.queries.length > 0 ? (
                                <>
                                  {values.queries.map((friend, index) => (
                                    <>
                                      {console.log("friend", friend)}
                                      <div
                                        className="grid mt-10 grid-cols-1"
                                        key={index}
                                      >
                                        {/* <div /> */}

                                        <div
                                          className="flex justify-start mt-2 mb-3 gap-12"
                                          style={{ gap: "10px" }}
                                        >
                                          <Row
                                            style={{
                                              width: "100%",
                                              gap: "10px",
                                            }}
                                          >
                                            {index > 0 && (
                                              <Col
                                                lg={2}
                                                md={2}
                                                sm={12}
                                                xs={12}
                                              >
                                                <div style={{ width: "100%" }}>
                                                  <FormControl
                                                    variant="outlined"
                                                    className="w-full"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <Label htmlFor="forType">
                                                      Select Field
                                                    </Label>
                                                    <Field
                                                      as={Select}
                                                      native
                                                      size="small"
                                                      value={
                                                        values.queries[index]
                                                          .condition
                                                      }
                                                      // label="Select Field"
                                                      inputProps={{
                                                        name: `queries.${index}.condition`,
                                                        id: "forType",
                                                      }}
                                                      defaultValue="AND"
                                                      required
                                                    >
                                                      <option value="AND">
                                                        AND
                                                      </option>
                                                      <option value="OR">
                                                        OR
                                                      </option>
                                                    </Field>
                                                  </FormControl>
                                                </div>
                                              </Col>
                                            )}
                                            <Col lg={2} md={2} sm={12} xs={12}>
                                              {console.log(
                                                "values.queries[index].operator",
                                                values.queries[index].operator
                                              )}
                                              <div style={{ width: "100%" }}>
                                                <FormControl
                                                  variant="outlined"
                                                  className="w-full"
                                                  style={{ width: "100%" }}
                                                >
                                                  <Label>Select Field</Label>
                                                  <Field
                                                    st
                                                    className="yourMaterialUI-Select"
                                                    as={Select}
                                                    native
                                                    size="small"
                                                    value={
                                                      values.queries[index]
                                                        .column
                                                        ? values.queries[index]
                                                            .column
                                                        : "selecteField"
                                                    }
                                                    inputProps={{
                                                      name: `queries.${index}.column`,
                                                      id: "forType",
                                                    }}
                                                    // onChange ={ (index) => {
                                                    // 	alert(index)
                                                    // } }

                                                    required
                                                  >
                                                    {/* {this.props.colsPlaceholder
                                                      ?.length <= 0 && (
                                                      <option value="null">
                                                        {" "}
                                                        Loading......{" "}
                                                      </option>
                                                    )} */}
                                                    <option
                                                      disabled
                                                      value="selecteField"
                                                    >
                                                      Select Field
                                                    </option>

                                                    {this.props?.searchColumns?.map(
                                                      (item, colIndex) =>
                                                        item.value !=
                                                          "docno" && (
                                                          <option
                                                            key={colIndex}
                                                            value={
                                                              item.column_name
                                                            }
                                                          >
                                                            {item.column_title}
                                                          </option>
                                                        )
                                                    )}
                                                  </Field>
                                                </FormControl>
                                              </div>
                                            </Col>
                                            <Col lg={2} md={2} sm={12} xs={12}>
                                              <div>
                                                <FormControl
                                                  variant="outlined"
                                                  className="w-full"
                                                  style={{ width: "100%" }}
                                                >
                                                  <Label>Select Operator</Label>

                                                  <Field
                                                    as={Select}
                                                    native
                                                    value={
                                                      values.queries[index]
                                                        .operator
                                                    }
                                                    size="small"
                                                    inputProps={{
                                                      name: `queries.${index}.operator`,
                                                      id: "forType",
                                                    }}
                                                    required
                                                  >
                                                    {console.log(
                                                      " values.queries[index].column 123",
                                                      values.queries[index]
                                                        .column
                                                    )}
                                                    {([
                                                      "created_at",
                                                      "EMP_CNT",
                                                      "DEPT_CNT",
                                                      "Stat",
                                                    ].includes(
                                                      values.queries[index]
                                                        .column
                                                    )
                                                      ? operator
                                                      : operatorForString
                                                    ).map((item) => {
                                                      return (
                                                        <option
                                                          value={item.operation}
                                                        >
                                                          {item.name}
                                                        </option>
                                                      );
                                                    })}
                                                  </Field>
                                                </FormControl>
                                              </div>
                                            </Col>

                                            {values.queries[index].operator !==
                                              "isnull" && (
                                              <>
                                                {![
                                                  "transaction_value",
                                                  "mortgage",
                                                  "lender_name",
                                                  "sourcetype",
                                                  "ownership_type",
                                                  "doc_type",
                                                  "DPDV",
                                                  "Education",
                                                  "Inactive_Status",
                                                  "Emp_Status",
                                                  "Emp_Type",
                                                  "Nposition_No",
                                                  "Ethnic_Group",
                                                  "record_type",
                                                  "Empl_Type",
                                                  "DEPT_NAME",
                                                  "PPE",
                                                ].includes(
                                                  values.queries[index].column
                                                ) && (
                                                  <Col lg={5} md={5}>
                                                    {console.log(" ", index)}
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <Label>Enter Value</Label>
                                                      <Field
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        size="small"
                                                        name={`queries.${index}.value`}
                                                        as={TextField}
                                                        className="styleField"
                                                        variant="outlined"
                                                        // label={ JSON.stringify(propsCols[index])}
                                                        placeholder={
                                                          this
                                                            .colsPlaceholder &&
                                                          this.colsPlaceholder.find(
                                                            (i) =>
                                                              i.column_name ==
                                                              values.queries[
                                                                index
                                                              ].column
                                                          )
                                                            ? this.colsPlaceholder
                                                                .find(
                                                                  (i) =>
                                                                    i.column_name ==
                                                                    values
                                                                      .queries[
                                                                      index
                                                                    ].column
                                                                )
                                                                .placeholder.replace(
                                                                  /,/g,
                                                                  " "
                                                                )
                                                            : "Enter value"
                                                        }
                                                        required
                                                      />
                                                    </div>
                                                  </Col>
                                                )}

                                                {[
                                                  "transaction_value",
                                                  "mortgage",
                                                  "lender_name",
                                                  "sourcetype",

                                                  "ownership_type",
                                                  "Education",
                                                  "Inactive_Status",
                                                  "Emp_Status",
                                                  "Emp_Type",
                                                  "Nposition_No",
                                                  "Ethnic_Group",
                                                  "doc_type",
                                                  "record_type",
                                                  "Empl_Type",
                                                  "DPDV",
                                                  "DEPT_NAME",
                                                  "PPE",
                                                ].includes(
                                                  values.queries[index].column
                                                ) && (
                                                  <Col
                                                    lg={4}
                                                    md={4}
                                                    sm={12}
                                                    xs={12}
                                                  >
                                                    {console.log(
                                                      "1111111",
                                                      values.queries[index]
                                                        .value
                                                    )}
                                                    <Autocomplete
                                                      size="small"
                                                      options={this.getLoadData(
                                                        values.queries[index]
                                                          .column
                                                      )}
                                                      selectOnFocus
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.label}
                                                      renderOption={(
                                                        props,
                                                        option
                                                      ) => {
                                                        return (
                                                          <li
                                                            {...props}
                                                            key={
                                                              option.label +
                                                              Math.random() *
                                                                100000000
                                                            }
                                                            style={{
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          >
                                                            {option.label}
                                                          </li>
                                                        );
                                                      }}
                                                      value={
                                                        values.queries[index]
                                                          .value
                                                          ? {
                                                              value:
                                                                values.queries[
                                                                  index
                                                                ].value,
                                                              label:
                                                                values.queries[
                                                                  index
                                                                ].value,
                                                            }
                                                          : values[
                                                              `queries.${index}.value`
                                                            ]
                                                      }
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) => {
                                                        if (value) {
                                                          console.log(
                                                            "value 456",
                                                            value
                                                          );
                                                          values.queries[
                                                            index
                                                          ].value = value.value;
                                                          values[
                                                            `queries.${index}.value`
                                                          ] = value.value;
                                                        }
                                                      }}
                                                      // onKeyPress={(event) => {
                                                      //   if (
                                                      //     event.target.value
                                                      //   ) {
                                                      //     values.queries[
                                                      //       index
                                                      //     ].value =
                                                      //       event.target.value;
                                                      //     values[
                                                      //       `queries.${index}.value`
                                                      //     ] =
                                                      //       event.target.value;
                                                      //   }
                                                      // }}
                                                      name={`queries.${index}.value`}
                                                      renderInput={(params) => (
                                                        <>
                                                          <Label>
                                                            Enter Value
                                                          </Label>
                                                          <TextField
                                                            {...params}
                                                            label="Enter Value"
                                                            variant="outlined"
                                                          />
                                                        </>
                                                      )}
                                                    />
                                                  </Col>
                                                )}
                                              </>
                                            )}

                                            {/* auto search Input */}

                                            {/* <FormControl>
																							<AutoComplete
																								options={this.state.options}
																								style={{ width: 200 }}
																								onSelect={this.onSelect}
																								onSearch={this.onSearch}
																								placeholder="input here"
																							/>
																						</FormControl> */}
                                            {/* <FormControl>
																						<AutoCompleteTransactionSearch
																						data={{
																							name: 'transaction_type',
																							url: process.env.REACT_APP_URL + '/get-transactions',
																							disabled: this.state.fieldChange,
																							label: 'Document Type'
																						}}
																						edit={ false }
																						initialValuesForEdit={{ property_descriptor: '', property_id: '' }}
																					/>
																					</FormControl> */}
                                            {/* end of ause search input */}
                                            <Col style={{ marginTop: "25px" }}>
                                              <IconButton
                                                disableRipple
                                                onClick={() =>
                                                  values.queries.length !== 1 &&
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <RemoveCircleTwoTone />
                                              </IconButton>
                                            </Col>
                                          </Row>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                          {values.queries.length === 1 ? (
                                            <>
                                              <Row
                                                className="mt-1 mb-3"
                                                style={{ display: "flex" }}
                                              >
                                                <Button
                                                  variant="contained"
                                                  onClick={() =>
                                                    arrayHelpers.insert(
                                                      index + 1,
                                                      {
                                                        value: "",
                                                        operator: "=",
                                                        column: "",
                                                        condition: "AND",
                                                      }
                                                    )
                                                  }
                                                  style={{
                                                    textTransform: "none",
                                                    color: "white",
                                                  }}
                                                  className="bg-primary"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faRectangleList}
                                                    style={{
                                                      color: "white",
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                  Add Field
                                                </Button>

                                                <Button
                                                  color="secondary"
                                                  variant="contained"
                                                  style={{
                                                    marginLeft: "10px", //"6px",
                                                    textTransform: "none",
                                                    color: "white",
                                                  }}
                                                  className="   bg-primary"
                                                  onClick={() => {
                                                    this.handleClear(resetForm);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faBroom}
                                                    style={{
                                                      color: "white",
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                  Clear Query
                                                </Button>

                                                <Button
                                                  type="submit"
                                                  style={{
                                                    marginLeft: "10px", //"6px",
                                                    textTransform: "none",
                                                    color: "white",
                                                  }}
                                                  className="   bg-primary"
                                                  variant="contained"
                                                  // onClick={() => {
                                                  //   this.onSearchClick();
                                                  // }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faMagnifyingGlass}
                                                    style={{
                                                      color: "white",
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                  Search Query
                                                </Button>
                                              </Row>
                                            </>
                                          ) : values.queries.length - 1 !==
                                            index ? (
                                            <span />
                                          ) : (
                                            <Row className="mt-1 mb-3">
                                              <Button
                                                variant="contained"
                                                onClick={() =>
                                                  arrayHelpers.insert(
                                                    index + 1,
                                                    {
                                                      value: "",
                                                      operator: "=",
                                                      column: "",
                                                      condition: "AND",
                                                    }
                                                  )
                                                }
                                                style={{
                                                  width: "115px",
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="bg-primary"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faRectangleList}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                                Add Field
                                              </Button>

                                              {/* <Button
																										color="secondary"
																										variant="contained"
																										onClick={() => {
																											this.handleViewSavedQuery();
																										}}
																										style={{
																											marginLeft:
																												'10px',
																											textTransform:
																												'none',
																											width: '120px'
																										}}
																									>
																										Select Query
																									</Button> */}

                                              <Button
                                                variant="contained"
                                                onClick={() => {
                                                  this.handleClear(resetForm);
                                                }}
                                                className="   bg-primary"
                                                style={{
                                                  marginLeft: "10px", //"6px",
                                                  textTransform: "none",

                                                  color: "white",
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faBroom}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                                Clear Query
                                              </Button>

                                              <Button
                                                type="submit"
                                                style={{
                                                  marginLeft: "10px", //"6px",
                                                  textTransform: "none",
                                                  color: "white",
                                                }}
                                                className="   bg-primary"
                                                variant="contained"
                                                // onClick={() => {
                                                //   this.onSearchClick();
                                                // }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faMagnifyingGlass}
                                                  style={{
                                                    color: "white",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                                Search Query
                                              </Button>
                                            </Row>
                                          )}

                                          <div
                                            className="mt-1 mb-3"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            {!this.state.openSave && (
                                              <>
                                                {values.queries.length - 1 ===
                                                  index && (
                                                  // <Row>
                                                  <>
                                                    <Button
                                                      color="secondary"
                                                      variant="contained"
                                                      onClick={() => {
                                                        this.handleViewSavedQuery();
                                                      }}
                                                      style={{
                                                        marginRight: "10px",
                                                        textTransform: "none",

                                                        color: "white",
                                                      }}
                                                      className="bg-primary"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faBadgeCheck}
                                                        style={{
                                                          color: "white",
                                                          marginRight: "4px",
                                                        }}
                                                      />
                                                      Select Query
                                                    </Button>

                                                    <Button
                                                      variant="contained"
                                                      className="   bg-primary"
                                                      style={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        // backgroundColor:
                                                        //   "#26695c",
                                                      }}
                                                      disabled={
                                                        this.state.openSave
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          values.queries[0]
                                                            .column !== "" &&
                                                          values.queries[0]
                                                            .value !== ""
                                                        ) {
                                                          this.handleSave();
                                                        }
                                                        // this.handleSaveNewQuery();
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faFloppyDisk}
                                                        style={{
                                                          color: "white",

                                                          marginRight: "4px",
                                                        }}
                                                      />
                                                      Save Query
                                                    </Button>
                                                  </>
                                                  // </Row>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div /> */}
                                    </>
                                  ))}

                                  <div className="flex justify-center">
                                    {this.state.openSave && (
                                      <ModalCustom
                                        open={this.state.openSave}
                                        // handleClose={this.handleSave}

                                        fullWidth
                                      >
                                        <SaveNewQuery
                                          userGroup={this.props.userGroup}
                                          onSelectUserGroup={
                                            this.onSelectUserGroup
                                          }
                                          onRemoveUserGroup={
                                            this.onRemoveUserGroup
                                          }
                                          onSubmit={this.onFinished}
                                          values={values}
                                          handleClose={this.handleSave}
                                        />
                                      </ModalCustom>
                                      // <div
                                      //   style={{
                                      //     width: "500px",
                                      //     padding: "20px 30px",
                                      //   }}
                                      // >
                                      //   <Typography
                                      //     variant="h5"
                                      //     component="h5"
                                      //     color="secondary"
                                      //     style={{
                                      //       marginBottom: "16px",
                                      //       marginLeft: "2px",
                                      //       fontWeight: "bold",
                                      //     }}
                                      //   >
                                      //     Enter Query Details
                                      //   </Typography>
                                      //   <div className="m-4 gap-x-20 gap-y-14 grid md:grid-cols-1 sm:grid-cols-1">
                                      //     <div className="">
                                      //       <Field
                                      //         as={TextField}
                                      //         label="Query Name"
                                      //         name="query_name"
                                      //         variant="outlined"
                                      //         fullWidth
                                      //       />
                                      //     </div>

                                      //     <>
                                      //       <Multiselect
                                      //         placeholder="Role"
                                      //         options={this.props.userGroup}
                                      //         selectedValues={
                                      //           values.user_group_id
                                      //         }
                                      //         onSelect={this.onSelectUserGroup}
                                      //         onRemove={this.onRemoveUserGroup}
                                      //         displayValue="user_group_name"
                                      //       />
                                      //     </>
                                      //     {/* )} */}

                                      //     <div className="flex justify-end pt-20 gap-4">
                                      //       <Button
                                      //         style={{
                                      //           width: "40%",
                                      //           maxWidth: "170px",
                                      //           marginTop: "10%",
                                      //         }}
                                      //         color="secondary"
                                      //         variant="contained"
                                      //         onClick={() => {
                                      //           this.handleSave();
                                      //         }}
                                      //       >
                                      //         Cancel
                                      //       </Button>
                                      //       <Button
                                      //         style={{
                                      //           width: "40%",
                                      //           maxWidth: "170px",
                                      //           marginTop: "10%",
                                      //         }}
                                      //         // disabled={!isValidDate}
                                      //         variant="contained"
                                      //         color="primary"
                                      //         type="submit"
                                      //         // onClick={() => {
                                      //         //   this.onSubmitClick();
                                      //         // }}
                                      //       >
                                      //         Submit
                                      //       </Button>
                                      //     </div>
                                      //   </div>
                                      // </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div />
                              )}
                            </>
                          );
                        }}
                      />
                    </form>
                  )}
                </Formik>
              </div>
            ) : (
              <div className="simple-search-div">
                {/* <SimpleSearch api={this.apiPostSimpleSearch} /> */}
              </div>
            )}

            <div style={{ width: "100%" }}>
              {/* { this.state.rows.length ?
							<MUIDataTable
								data={this.state.rowArray }
								columns={colNewTable}
								options={options}
								className={classes.table}
							/>
								: null
							}
							  */}
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}

              {/* <LRSMuiTable
                // muidata={this.state.rowArray }
                // muicolumns={colNewTable}
                // muioptions={ options }

                loading={this.state.loading}
                rows={this.state.rows}
                columnsList={this.settingColumnsFilterList(this.props.cols)}
                columns={this.hideShowCols()}
                apiPost={this.apiPost}
                apiPostSimpleSearch={this.apiPostSimpleSearch}
                onChangeFilter={this.onChangeFilter}
                changeSortOrder={(v1, v2) => this.changeSortOrder(v1, v2)}
                cPage={this.state.cPage}
                setcPage={(page) => {
                  this.setState({ cPage: page });
                }}
                total={this.state.total}
                searchMode={this.state.searchMode}
                searchText={this.state.searchText}
                postQueries={this.state.postQueries}
              /> */}
              {/* 							 
							<CustomLRSTable
								loading={this.state.loading}
								rows={ this.state.rows }
								columnsList={this.settingColumnsFilterList(this.props.cols)}
								columns={ this.hideShowCols() }
								apiPost={this.apiPost}
								apiPostSimpleSearch={this.apiPostSimpleSearch}
								onChangeFilter={ this.onChangeFilter }
								changeSortOrder={(v1,v2)=>this.changeSortOrder(v1,v2) }
								cPage={ this.state.cPage }
								setcPage={ (page) => {
									this.setState({ cPage: page });
								}}
								total={this.state.total}
								searchMode={this.state.searchMode}
								searchText={this.state.searchText}
								postQueries={this.state.postQueries}
							/> */}
            </div>
          </Col>
        </Card>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeQuery: (data) => dispatch(refreshQuery()),
    reRunFalse: (data) => dispatch(reRunFalseAction()),
    searchRecord: (newRows) => dispatch(callSearchRecord(newRows)),
    emptySearch: () => dispatch(callEmptySearch()),
    userGroups: (uuid) => dispatch(userGroupStore(uuid)),
    fetchAdvanceSearchData: (uuid) => dispatch(fetchAdvanceSearchData(uuid)),
    setToolbarHeader: (msg) => dispatch(setToolbarHeader(msg)),
    fetchCols: () => dispatch(fetchCols()),
    fetchPlaceholderCols: () => dispatch(fetchPlaceholderCols()),

    fetchEmployeeIssues: (data) => dispatch(fetchEmployeeIssues(data)),

    fetchPayPeriodMaster: (data) => dispatch(fetchPayPeriodMaster(data)),
    fetchSearchColumns: (data) => dispatch(fetchSearchColumns(data)),
  };
}

const mapStateToProps = (state) => (
  console.log("state :::", state),
  {
    token:
      "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2NzU0MDI1MzUsImV4cCI6MTY3NTQ4ODkzNSwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiMmQ1ODlhMGMtZGFiYS00ODE2LWE4ZjQtOGQ4YjYwN2MwNjUzIiwiYXV0aF90aW1lIjoxNjc1NDAyNDA4LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJhZG1pbiIsIm5hbWUiOiJBZG1pbiIsImVtYWlsIjoiZGlnaWJoYWt0YSsyQGdtYWlsLmNvbSIsImp0aSI6IjMyMzA5RkUxNTMwRkI0RkE1NkJCRjZDQTU5MTlGMDBGIiwic2lkIjoiQ0YxNzVFQzJGQzczNUIwOEU3OERGMkJENzRGNjA4NDciLCJpYXQiOjE2NzU0MDI1MzUsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJlbWFpbCIsInJvbGVzIiwiYXBpMSJdLCJhbXIiOlsicHdkIl19.UVSTnBeYZROdrnaB05rvfeRfj_ZuEocfJec8NfDptRrALbqHO5fcszIuVnXGyOBVUb4BWMdhXIAryJe4rR1r7kB3zOOyRa1rq8TKMwC77rqsAoi-IUTosouUayxGtVCC0Gyz9ZJAqKVUpmPa9Pu8dF4GqVsZnteWJV7as5mJ_hEy2XxqzM3V6oQ8078-d-WHkhyuoaoi3OplU1NWoITqLgCLKSdpTVLOV2Il8OeXcbABRXzX0C09WJ9q7F4IsdbCmpZhOqM55qIdE8xWe4lJoA59Abzw56bsultfNzTc0wy5aC-I2M4k6bdJASvfoW5N8r7mc11DirE9AMvM8ukSrA  ",
    status: "admin",
    groupId: 53,
    reRun: false,
    queries: state.querySlice.queries,
    initialState: state.querySlice.initialValues,
    userGroup: state.querySlice.groups,
    cols: state.querySlice.cols,
    colsPlaceholder: state.querySlice.colsPlaceholder,
    advanceSearchData: state.configurationSlice.advanceSearchData,
    selectColumns: state.querySlice.selectColumns,
    payPeriodMater: state.masterSlice?.payperiods,
    searchColumns: state.querySlice.searchColumns,
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(advanceFormStyles)(DeductionReportAdvanceSearch));
