// dashbaord
import React, { lazy } from "react";

import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import DeductionReport from "../Components/Reports/DeductionReport/DeductionReport";
import DeductionByVendorReport from "../Components/Reports/VendorReport/DeductionByVendorReport";
import LaborCostReport from "../Components/Reports/VendorReport/LaborCostReport";
import DepartmentReport from "../Components/Reports/DepartmentReport/DepartmentReport";
import SankeyReport from "../Components/Reports/Sankey/SankeyReport";
import HoursReport from "../Components/Reports/HoursReport/HoursReport";
// import PayrollWorkTime from "../Components/Payroll-time/PayrollWorkTime";
import QRCode from "../Components/QrCode/QRScan";
import SupportTickitContain from "../Components/SupportTicket";
import { SilentRenewComponent } from "../SilentRenewComponent";
import PayrollWorkTime from "../Components/payroll/payrollWorkTime";
import DeductionPlanMaster from "../Components/ConfigurationMaster/DeductionPlanMaster/DeductionPlanMaster";
import CreateUpdateDeductionPlanMaster from "../Components/ConfigurationMaster/DeductionPlanMaster/CreateUpdateDPM";
import Vendor from "../Components/ConfigurationMaster/vendors/vendor";
import DeductionType from "../Components/ConfigurationMaster/deductionType/deductionType";
import DepartMentMaster from "../Components/ConfigurationMaster/departmentDiv/departmentDiv";
import SuperVisorDetails from "../Components/Supervisor/supervisorDetails";
import Paytimecode from "../Components/ConfigurationMaster/PayCodeMaster/Paytimecode";
import EmployeeDetails from "../Components/employee/employeeDetails";
import EmployeeTag from "../Components/EmployeeTag";
import PayrollMasterDetails from "../Components/payroll/payrollMasterDetails";
import MasterDetails from "../Components/Master Details/MasterDetails";
import LogOut1 from "../Layout/Header/RightBar/LogOut1";
import EhnicCode from "../Components/ConfigurationMaster/Ethnic Code/EhnicCode";
import InactiveStatus from "../Components/ConfigurationMaster/Inactive Status/InactiveStatus";
import MilitaryStatus from "../Components/ConfigurationMaster/MIlitary Status/MilitaryStatus";
import PayStep from "../Components/ConfigurationMaster/Pay Step/PayStep";
import NPosition from "../Components/ConfigurationMaster/NPosition/NPosition";
import ActType from "../Components/ConfigurationMaster/ActType/actType";
import Education from "../Components/ConfigurationMaster/Education/education";
import EmployeeType from "../Components/ConfigurationMaster/EmployeeType/employeeType";
import PayGrade from "../Components/ConfigurationMaster/PayGradeMaster/PayGrade";
import DepartMentList from "../Components/Reports/HoursReport/DepartMentList";
import EmployeeListView from "../Components/Reports/HoursReport/EmployeeListView";
import VendorDeductionEmployeeList from "../Components/Reports/VendorReport/component/VendorDeductionEmployeeList";
import AutoComplete from "../CommonElements/CustomComponent/AutoComplete";
// import TimeSheetTemplate from "../Components/payroll/TimeSheetTemplate";
import ReactTimeSheet from "../Components/ReactTimeSheet/ReactTimeSheet";
import TimeSheetTemplate from "../Components/ConfigurationMaster/TimeSheetTemplate/TimeSheetTemplate";
import TimeSheetTemplateDetails from "../Components/ConfigurationMaster/TimeSheetTemplate/TimeSheetTemplateDetails";
import ViewTimeSheetTemplates from "../Components/ConfigurationMaster/TimeSheetTemplate/ViewTimeSheetTemplates";
import PayCodeTemplate from "../Components/ConfigurationMaster/PayCodeTemplate/PayCodeTemplate";
import PayCodeTemplateDetails from "../Components/ConfigurationMaster/PayCodeTemplate/PayCodeTemplateDetails";
import TimeSheetTemplateDetailsUpdate from "../Components/ConfigurationMaster/TimeSheetTemplate/TimeSheetTemplateDetailsUpdate";
import SupervisorInfo from "../Components/payroll/component/SuperVisorInfo";
import LeaveApply from "../Components/Leave Apply/LeaveApply";
import TimeClock from "../Components/payroll/component/TimeClock";
import HeaderTimeClock from "../Components/payroll/component/HeaderTimeClock";
import PayrollWorkTimeVerification from "../Components/payroll/payrollWorkTimeVerification";
import NotificationView from "../Layout/Header/RightBar/Bookmark/NotificationView";
import LeaveConfigration from "../Components/Supervisor/LeaveConfigration";
import EarningsReports from "../Components/Timekeeper/EarningsReports";
import EarningReport from "../Components/Reports/EarningReport/EarningReport";
import W2Report from "../Components/Reports/W2Report/W2Report";
import CombinedDeductionReport from "../Components/Reports/CombinedDeductionReport/CombinedDeductionReport";
import IRSQuarterlyReport from "../Components/Reports/IRSQuarterlyReport/IRSQuarterlyReport";
import EmployeeCertification from "../Components/ConfigurationMaster/EmployeeCertificationMaster/EmployeeCertification";
import FederalTax from "../Components/ConfigurationMaster/FederalTaxMaster/FederalTaxMaster";
import StateMaster from "../Components/ConfigurationMaster/StateMaster/StateMaster";
import PayRollRun from "../Components/PayRollRun";
import GarnishmentType from "../Components/ConfigurationMaster/GarnishmentTypeMaster/GarnishmentTypeMater";
import Garnishment from "../Components/ConfigurationMaster/GarnishmentMaster/GarnishmentMaster";
import UnAuthorized from "../Components/UnAuthorized";
import StateTaxMaster from "../Components/ConfigurationMaster/StateTaxMaster/StateTax";
const PrHistory = lazy(() => import("../Components/PrHistory"));
const PrHistoryAddOrUpdate = lazy(() =>
  import("../Components/PrHistory/components/PrHistoryAddOrUpdate")
);
const DeductionPlan = lazy(() =>
  import("../Components/ConfigurationMaster/DeductionPlanMaster/index")
);
const EmployeeManagement = lazy(() => import("../Components/employee/index"));
const CreateUpdateEmployeeDeductionsMaster = lazy(() =>
  import("../Components/ConfigurationMaster/EmployeeDeductionMaster/CreateUpdateEDM")
);

export const routes = [
  //dashboard
  {
    path: `${process.env.REACT_APP_PREFIX_URL}silentrenew`,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    Component: <SilentRenewComponent />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}dashboard/default`,
    Component: <Default />,
  },

  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/employee`,
    Component: <EmployeeManagement />,
  },
  {
    auth: ["admin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/employee-tag-management`,
    Component: <EmployeeTag />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/supervisor`,
    Component: <EmployeeDetails />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs`,
    Component: <HoursReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs/department/:id`,
    Component: <DepartMentList />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs/department/:id/:ppeId`,
    Component: <DepartMentList />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs/employee/:id/:stat`,
    Component: <EmployeeListView />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/notification-view`,
    Component: <NotificationView />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs/employee/:id/:stat/:ppe`,
    Component: <EmployeeListView />,
  },
  {
    auth: ["admin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/scanqrCode`,
    Component: <QRCode />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/employee/update/:id`,
    Component: <PayrollMasterDetails />,
  },
  {
    auth: ["admin", "DepartmentAdmin", "supervisor"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/employee/update/:id/assign-dept-emp`,
    Component: <SupervisorInfo />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/auto`,
    Component: <AutoComplete />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}admin/employee/update/`,
    Component: <PayrollMasterDetails />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-work/:id`,
    Component: <PayrollWorkTime />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-work/:id/:ppe`,
    Component: <PayrollWorkTime />,
  },
  {
    auth: ["timekeeper"],
    path: `${process.env.REACT_APP_PREFIX_URL}earnings-reports`,
    Component: <EarningsReports />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-work`,
    Component: <PayrollWorkTime />,
  },
  {
    auth: ["admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-work-verify`,
    Component: <PayrollWorkTimeVerification />,
  },
  {
    auth: ["admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-work-verify/:id`,
    Component: <PayrollWorkTimeVerification />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}time-clock`,
    Component: <HeaderTimeClock />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/earning`,
    Component: <EarningReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/w2`,
    Component: <W2Report />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/combined-deduction`,
    Component: <CombinedDeductionReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/irs-quarterly`,
    Component: <IRSQuarterlyReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/deduction`,
    Component: <DeductionReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/deductionbyvendor`,
    Component: <DeductionByVendorReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}report/deductionbyvendor/:vendorId`,
    Component: <VendorDeductionEmployeeList />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}report/laborcost`,
    Component: <LaborCostReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}report/department`,
    Component: <DepartmentReport />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}report/sankey`,
    Component: <SankeyReport />,
  },
  // {
  //   auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  //   path: `${process.env.REACT_APP_PREFIX_URL}master/deduction-plan`,
  //   Component: <DeductionPlanMaster />,
  // },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/deduction-plan`,
    Component: <DeductionPlan />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}logout`,
    Component: <LogOut1 />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master-detail`,
    Component: <MasterDetails />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}master/vendors`,
    Component: <Vendor />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}master/deduction-type`,
    Component: <DeductionType />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/department`,
    Component: <DepartMentMaster />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/ethnic-code`,
    Component: <EhnicCode />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/employee-certification`,
    Component: <EmployeeCertification />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/federal-tax`,
    Component: <FederalTax />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/state-tax`,
    Component: <StateTaxMaster />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/state`,
    Component: <StateMaster />,
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/garnishment-type`,
    Component: <GarnishmentType />,
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/garnishment`,
    Component: (
      <Garnishment defaultDeductionType="GARNISHMENT" isDetailShow={false} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/garnishment/update/:id`,
    Component: (
      <Garnishment defaultDeductionType="GARNISHMENT" isDetailShow={true} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/benefit`,
    Component: (
      <Garnishment defaultDeductionType="BENEFITS" isDetailShow={false} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/benefit/update/:id`,
    Component: (
      <Garnishment defaultDeductionType="BENEFITS" isDetailShow={true} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/miscellaneous`,
    Component: (
      <Garnishment defaultDeductionType="MISCELLANEOUS" isDetailShow={false} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/miscellaneous/update/:id`,
    Component: (
      <Garnishment defaultDeductionType="MISCELLANEOUS" isDetailShow={true} />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/retirement-plan`,
    Component: (
      <Garnishment
        defaultDeductionType="RETIREMENT-PLANS"
        isDetailShow={false}
      />
    ),
  },
  {
    auth: ["admin", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/retirement-plan/update/:id`,
    Component: (
      <Garnishment
        defaultDeductionType="RETIREMENT-PLANS"
        isDetailShow={true}
      />
    ),
  },
  // {
  //   auth: ["user", "admin", "supervisor","timekeeper", "DepartmentAdmin"],
  //   path: `${process.env.REACT_APP_PREFIX_URL}master/payment-option`,
  //   Component: <PaymentOption />,
  // },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/inactive-status`,
    Component: <InactiveStatus />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/military-status`,
    Component: <MilitaryStatus />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/pay-step`,
    Component: <PayStep />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/nposition`,
    Component: <NPosition />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/act-type`,
    Component: <ActType />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}master/education`,
    Component: <Education />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],

    path: `${process.env.REACT_APP_PREFIX_URL}master/employee-type`,
    Component: <EmployeeType />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/paygrade`,
    Component: <PayGrade />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/paytimecode`,
    Component: <Paytimecode />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}dashboard/ecommerce`,
    Component: <Ecommerce />,
  },

  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet-template-details`,
    Component: <TimeSheetTemplateDetails />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/paycode-template-details/update/:id`,
    Component: <TimeSheetTemplateDetailsUpdate />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet-template`,
    Component: <TimeSheetTemplate />,
  },
  // {
  //   auth: ["user", "admin","supervisor","timekeeper", "DepartmentAdmin"],
  //   path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet-template`,
  //   Component: <TimeSheetTemplate />,
  // },

  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/paycode-template-details`,
    Component: <PayCodeTemplateDetails />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/leave-apply`,
    Component: <LeaveApply />,
  },
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/paycode-template`,
    Component: <PayCodeTemplate />,
  },

  //Support Ticket
  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}starter-kits/sample-page`,
    Component: <SupportTickitContain />,
  },

  {
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet`,
    Component: <ReactTimeSheet />,
  },
  {
    auth: ["supervisor"],
    path: `${process.env.REACT_APP_PREFIX_URL}master/leave-configration`,
    Component: <LeaveConfigration />,
  },
  {
    auth: ["admin"],
    path: `${process.env.REACT_APP_PREFIX_URL}payroll-run`,
    Component: <PayRollRun />,
  },
  {
    auth: ["admin"],
    path: `${process.env.REACT_APP_PREFIX_URL}pr`,
    Component: <PayRollRun />,
  },
  {
    path: "/legacy-payroll",
    Component: <PrHistory />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "/legacy-payroll/:id",
    Component: <PrHistoryAddOrUpdate />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "/legacy-payroll/new",
    Component: <PrHistoryAddOrUpdate />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "/unauthorized",
    Component: <UnAuthorized />,
    auth: [
      "user",
      "admin",
      "supervisor",
      "timekeeper",
      "DepartmentAdmin",
      "HRAdmin",
    ],
    // exact: true,

    layout: "none",
  },
  {
    path: "/master/deduction-plan/new",
    Component: <CreateUpdateDeductionPlanMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "/master/deduction-plan/:id",
    Component: <CreateUpdateDeductionPlanMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "master/employee-deduction-plan/new",
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "master/employee-deduction-plan/new",
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "master/employee-deduction-plan/:id",
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "master/employee-deduction-plan/:id/:employeeId",
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  {
    path: "master/employee-deduction-plan/:employeeId/new",
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user", "admin", "supervisor", "timekeeper", "DepartmentAdmin"],
  },
  //     {
  //       path: "/payroll-master/:id",
  //       Component: <EmployeeManage />,

  //       component: React.lazy(() => import("./payrollMasterDetails")),
  //     },
  //   {
  //     path: "/payroll-master",
  //     component: import("../Components/payroll/payrollMasterDetails"),
  //   },
  //     {
  //       path: "/payroll-detail",
  //       component: React.lazy(() => import("./payrollDetailList")),
  //     },

  //     {
  //       path: ["/payroll-timesheets/employee/:id"],
  //       component: React.lazy(() => import("./payrollTimeSheets")),
  //       exact: true,
  //     },
  //     {
  //       path: ["/payroll-work"],
  //       component: React.lazy(() => import("./payrollWorkTime")),
  //       exact: true,
  //     },
  //     {
  //       path: ["/payroll-authorization"],
  //       component: React.lazy(() => import("./payrollAuthorization")),
  //       exact: true,
  //     },
  //     {
  //       path: ["/payroll-employee-auth"],
  //       component: React.lazy(() => import("./payrollAuthEmployee")),
  //       exact: true,
  //     },
];
