const visibleColumns = [
  {
    title: "HR Control",
    field: "HR_CONTROL",
    locked: false,
    filter: "numeric",
    width: "190",
    show: false,
  },
  {
    title: "Employee Id",
    field: "EmployeeID",
    locked: false,
    filter: "numeric",
    width: "190",
    show: false,
  },
  {
    title: "Employee Name",
    field: "EmployeeName_LFM",
    locked: false,
    filter: "text",
    width: "200",
    show: false,
  },
  {
    title: "Employee Sequence",
    field: "EmployeeSeq",
    locked: false,
    filter: "numeric",
    width: "190",
    show: false,
  },
  {
    title: "Deduction Plan Name",
    field: "DeductionName",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Deduction Code",
    field: "DeductionCode",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Deduction Title",
    field: "DeductionTitle",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Deduction Method",
    field: "DeductionMethod",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Deduction Value",
    field: "DeductionValue",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Payment schedule",
    field: "PaymentMonthlySch",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Active",
    field: "IsActive",
    locked: false,
    filter: "boolean",
    width: "150",
    show: true,
  },
  {
    title: "Start At",
    field: "StartDate",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy hh:mm A",
    editor: "date",
    width: "220",
    show: true,
  },
  {
    title: "End At",
    field: "EndDate",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy hh:mm A",
    editor: "date",
    width: "220",
    show: true,
  },
  {
    title: "Deduction Plan Status",
    field: "DeductionPlanStatus",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Applys To OT",
    field: "Applys_To_OT",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Total Amount Due",
    field: "TotalAmountPlanDue",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Total Amount Paid",
    field: "TotalAmountPaid",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Employee Deduction Amount",
    field: "EmployeeDeductionAmount",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Goverment Deduction Amount",
    field: "GovermentDeductionAmount",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Total Deduction Amount",
    field: "TotalDeductionAmount",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Total Goverment Deduction Amount",
    field: "TotalGovermentDeductionAmount",
    locked: false,
    filter: "numeric",
    width: "190",
    show: true,
  },
  {
    title: "Vendor",
    field: "VendotName",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Vendor Account No",
    field: "AccountNoForVendor",
    locked: false,
    filter: "text",
    width: "190",
    show: true,
  },
  {
    title: "Taxable",
    field: "Taxable",
    locked: false,
    filter: "boolean",
    width: "150",
    show: true,
  },
  {
    title: "Is Employee Modifiable",
    field: "IsEmployeeModifiable",
    locked: false,
    filter: "boolean",
    width: "150",
    show: true,
  },
  {
    title: "Updated At",
    field: "UpdatedOn",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy hh:mm A",
    editor: "date",
    width: "190",
    show: true,
  },
  {
    title: "Created At",
    field: "CreateddOn",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy hh:mm A",
    editor: "date",
    width: "190",
    show: true,
  },
];

export default visibleColumns;
