import { useAuth } from "oidc-react";
import React, { Fragment, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { Link } from "react-router-dom";
import md5 from 'md5-hash'
import { H6, Image, LI, UL, P } from "../../AbstractElements";
import { getUserRole } from "../../app/utility/helper";
import man from "../../assets/images/dashboard/1.png";

const Profile = () => {
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const { userData } = useAuth();

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name"));
    if(userData){
      if(userData.profile.email.indexOf('gmail')> -1){
        setProfile(`https://secure.gravatar.com/avatar/${md5(userData.profile.email)}`);
        } else {
        setProfile(`https://api.ehrpweb.com/profiles/${md5(userData.profile.email)}.png`);
        }
    }
    
  }, [setProfile, setName,userData]);


  return (
    <Fragment>
      <div className="sidebar-user text-center">
        {/* <a className="setting-primary" href="#javascript">
          <Settings />
        </a> */}
        {/* <Link to={`${process.env.REACT_APP_PREFIX_URL}app/users/userProfile`}> */}
        <Image
          attrImage={{
            className: "img-90 rounded-circle",
            src: authenticated ? auth0_profile.picture : profile,
            alt: "",
          }}
        />
        {/* <div className="badge-bottom">
          <div className="badge badge-primary">New</div>
        </div> */}
        <H6 attrH6={{ className: "mt-3 f-14 f-w-600" }}>
          {authenticated ? auth0_profile.name : name}
        </H6>
        {/* </Link> */}
        <P attrPara={{ className: "mb-0 font-roboto" }}>
          {userData && userData.profile && userData.profile.name
            ? userData.profile.name
            : userData && userData.profile && userData.profile.role
            ? userData.profile.role
            : ""}
        </P>
        {/* <UL attrUL={{ className: "flex-row simple-list" }}>
          <LI>
            <span>
              <span className="counter">19.8</span>k
            </span>
            <P>Follow</P>
          </LI>
          <LI>
            <span>2 year</span>
            <P>Experince</P>
          </LI>
          <LI>
            <span>
              <span className="counter">95.2</span>k
            </span>
            <P>Follower </P>
          </LI>
        </UL>   */}
      </div>
    </Fragment>
  );
};

export default Profile;
