import Typography from "@material-ui/core/Typography";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";

function HomeHeader(props) {

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Employee Certification</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          {/* <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={props.saveData}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Typography className="text-12">
              {" "}
              {"Add Certificate"}{" "}
            </Typography>
          </Button> */}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;