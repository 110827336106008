import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import {
  getDeductionType,
  getOverAllDeduction,
  getOverAllEarning,
  getVendor,
} from "../../../app/utility/axiosService";

import { faChartBar, faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import ReportChartLayout from "./component/reportChartLayout";
import { Card, CardBody } from "reactstrap";
import { Accordion, AccordionSummary, AccordionDetails, Container } from "@mui/material";
import ReportHeader from "../ReportHeader";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const DepartmentReport = (props) => {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    Code: "",
    VendorCode: "",
    Description: "",
    EmployeerFee: "",
    EmployeeFee: "",
    IsPercent: "",
    StartDate: null,
    EndDate: null,
    DeductionType: "",
  });

  const [vendors, setVendors] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [value, setValue] = useState(0);

  const pageLayout = useRef(null);
  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}deductionPlan`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
    (async () => {
      // setDeductionTypes(await getDeductionType());
      // setVendors(await getVendor());
      // setEarning(await getOverAllEarning());
      setDeduction(await getOverAllDeduction());
    })();
  }, []);

 
  const { userDetail } = props;
  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
        
    <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <ReportHeader {...props} header="Department Summary" />
      </div>
      <div className="page-content" style={{ height: 20 }} />
      <ReportChartLayout deduction={deduction} earning={earning} />
    
    </Container>
  );
};

export default DepartmentReport;
