import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import LoadingPanel from "../../../Common/telerik/loadingPanel";

const visibleColumnArray = [
  "SSN",
  "ID",
  "EmployeeName",
  "Email",
  "Inactive_Status",
  "Emp_Status",
];

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [CSVPrint, setCSVPrint] = useState(visibleColumnArray);

  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  const { requestDetail, loading, setLoading } = props;
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const [state, setState] = useState({
    filteredList: requestDetail ? arrangeList(requestDetail) : undefined,
  });

  const { filteredList } = state;
  const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (!filteredList && requestDetail) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(requestDetail),
      }));
    }

    if (
      (filterBy && filterByRef.current && filterByRef.current !== filterBy) ||
      requestDetail
    ) {
      filterByRef.current = filterBy;
      let dataList = requestDetail;
      if (filterBy?.ID) {
        dataList = dataList.filter((data) =>
          data && data.EmployeeID
            ? data.EmployeeID.toLowerCase().includes(
                filterBy.EmployeeID?.toLowerCase()
              )
            : data.ID.toLowerCase().includes(filterBy.ID?.toLowerCase())
        );
      }

      if (filterBy?.EmployeeName) {
        dataList = dataList.filter((data) =>
          data.EmployeeName.toLowerCase().includes(
            filterBy.EmployeeName.toLowerCase()
          )
        );
      }
      if (filterBy?.SSN) {
        dataList = dataList.filter((data) =>
          data.SSN.toLowerCase().includes(filterBy.SSN.toLowerCase())
        );
      }
      if (filterBy?.Status) {
        dataList = dataList.filter((data) =>
          data.Status.toLowerCase().includes(filterBy.Status.toLowerCase())
        );
      }
      if (filterBy?.Department) {
        dataList = dataList.filter((data) =>
          data.Description
            ? data.Description
            : data.DPDV_DepartmentDivisionMaster.Description.toLowerCase().includes(
                filterBy.Department.toLowerCase()
              )
        );
      }

      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(dataList),
      }));
    }
  }, [filterBy, requestDetail]);

  const options = {
    selectableRows: false,
    display: false,
    count: props.totalCount,
    onChangeRowsPerPage: (number) => {
      props.setTakeCount(number);
    },
    onChangePage: (page) => {
      props.setSkipCount(props.takeCount*(page));
    },

    onViewColumnsChange: (changedColumn, action, option) => {

      if (action === "add") {
        setCSVPrint([...CSVPrint, changedColumn]);
      } else {
        setCSVPrint(CSVPrint.filter((i) => i !== changedColumn));
      }
    },
  };

  const employeeIssuesColumn = [
    {
      id: "Actions",
      name: "employeeID",
      label: "Actions",
      sort: "false",
      minWidth: "90px",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip title="Employee Details" placement="bottom">
                <FontAwesomeIcon
                  onClick={() => {
                    return history(
                      `/admin/employee/update/${value}`
                      // `/admin/working-hrs/employee/0/${tableMeta.rowData[1]}/${value}`
                    );
                  }}
                  icon={faUser}
                  style={{
                    cursor: "pointer",
                    color: "#1569C7",
                    marginRight: "10px",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
        id: "employeeID",
        name: "employeeID",
        label: "Employee Id",
    },
    {
        id: "employeeName",
        name: "employeeName",
        label: "Employee Name",
    },
    {
        id: "department",
        name: "department",
        label: "Department",
    },
    {
        id: "totalHours",
        name: "totalHours",
        label: "Total Hrs.",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return (value !== '0') ? value : "-";
            },
        },
    },
    {
        id: "regHours",
        name: "regHours",
        label: "Reg Hrs.",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return (value !== '0') ? value : "-";
            },
        },
    },
    {
        id: "otHours",
        name: "otHours",
        label: "OT Hrs.",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return (value !== '0') ? value : "-";
            },
        },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {loading ?
      <div >
        <LoadingPanel></LoadingPanel>
      </div>
      :
      <MUIDataTable
        className={classes.customData}
        title={<Typography variant="h6">Earning Report Data</Typography>}
        data={requestDetail}
        columns={employeeIssuesColumn}
        options={options}
        selectableRowsHideCheckboxes={true}
      />
      }
    </div>
  );
};

export default DataTable;
