import { createAsyncThunk, createSlice,current } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "TableSlice";

export const getTableData = createAsyncThunk(
  `${sliceName}/getTableData`,
  async (payload) => {
    try {
      const { API_ENDPOINT, rowsPerPage, page } = payload;
      const data = await axios.get(
        `${process.env.REACT_APP_WEB_API_URL}${API_ENDPOINT}?MaxCount=${rowsPerPage}&SkipCount=${rowsPerPage * page}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      return data.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const CreateOrUpdateData = createAsyncThunk(
  `${sliceName}/CreateOrUpdateData`,
  async ({ API_ENDPOINT, payload }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_WEB_API_URL}${API_ENDPOINT}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const GetPayCode = createAsyncThunk(
  `${sliceName}/GetPayCode`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}paytimecode`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return data?.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const GetTagCode = createAsyncThunk(
  `${sliceName}/GetTagCode`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_WEB_API_URL}Tag/GetTags`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      return data?.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const GetEmpTagCode = createAsyncThunk(
  `${sliceName}/GetEmpTagCode`,
  async (payload) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeeTagDetails?EmployeeId=${payload}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      return data?.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const GetAllEmployee = createAsyncThunk(
  `${sliceName}/GetAllEmployee`,
  async (page) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_WEB_API_URL}Employee/GetAllEmployee?MaxCount=2000000&SkipCount=${20 * page}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      return data.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const TableSlice = createSlice({
  name: sliceName,
  initialState: {
    data: [],
    page: 0,
    rowsPerPage: 100,
    payCodeList: [],
    tagCodeList: [],
    allEmployee: [],
    getEmpTag:[],
    employeeTotalCount:0
  },
  reducers: {
    setRowsPerPage(state, action) {
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    },
    setPage(state, action) {
      return {
        ...state,
        page: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTableData.pending, (state, action) => {
        state.data = [];
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(getTableData.rejected, (state, action) => {
        state.data = [];
      })
      .addCase(GetPayCode.fulfilled, (state, action) => {
        const finalData = action.payload.map((i) => {
          return {
            label: i.Abberv ?? null,
            value: i.Abberv ?? null,
          };
        })
        return ({
          ...state,
          payCodeList: finalData,
        }
        )
      })
      .addCase(GetTagCode.fulfilled, (state, action) => {
        const finalData = action.payload?.data?.map((i) => {
          return {
            label: i.code ?? null,
            value: i.code ?? null,
            id: i?.id,
            jobOrder:i?.jobOrderNo,
            tagName:i?.tagName,
            label1:i?.code + " - " + i.tagName,
          };
        })
        return ({
          ...state,
          tagCodeList: finalData?.length ? finalData : []

        })
      })
      .addCase(GetAllEmployee.fulfilled, (state, action) => {
        const finalData = action.payload.data?.map((i) => {
          return {
            label: i.employeeName +" - "+ i?.id ?? null,
            value: i.employeeName ?? null,
            id: i?.id
          };
        })
        return ({
          ...state,
          allEmployee:[...current(state).allEmployee,...finalData],
          employeeTotalCount: action.payload?.totalCount
        }
        )
      })
      .addCase(GetEmpTagCode.fulfilled, (state, action) => {
        const finalData = action.payload 
       

        return ({
          ...state,
          getEmpTag: finalData,
        }
        )
      })
  },
});

export const { setRowsPerPage, setPage } = TableSlice.actions;
export default TableSlice.reducer;
