import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReportHeader from '../ReportHeader';
import RequestTable from "./component/request-table";
import { makeStyles } from "@material-ui/core/styles";
import { getAllEarningsReportRecords } from '../../../app/utility/axiosService';
import EarningReportLayout from './component/EarningReportLayout';
import { getUserId, getUserRole } from "../../../app/utility/helper";
import AdvanceSearch from './component/AdvanceSearch';
import { useAuth } from 'oidc-react';
import { Container } from '@mui/material';


// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: "100%",
//       ".&.MuiAccordionSummary-content": {
//         padding: 0,
//       },
//       "& .MuiPaper-root": {
//         background: "none",
//       },
//       margin: "0 !important",
//       borderTopWidth: 1,
//       borderTopColor: "#ccc",
//     },
//     heading: {
//       fontSize: theme.typography.pxToRem(16),
//       flexBasis: "33.33%",
//       fontWeight: 500,
//       flexShrink: 0,
//     },
//     secondaryHeading: {
//       fontSize: theme.typography.pxToRem(15),
//       flexBasis: "33.33%",
//       flexShrink: 0,
//     },
// }));
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "33.33%",
    fontWeight: 500,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const EarningReport = (props) => {

  const classes = useStyles();
  const [tableList, setTableList] = useState([]);
  const { userData } = useAuth();
  const ID = getUserId(userData);
  const role = getUserRole(userData);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [takeCount, setTakeCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [paramsQueries, setParamsQueries] = useState([]);
  const [getRecordDate, setGetRecordDate] = useState({
    startDate: `01-01-${new Date().getFullYear()}`,
    endDate: `12-31-${new Date().getFullYear()}`
  });
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });

  const pageLayout = useRef(null);

  useEffect(() => {
    fetchEarningReportRecords();
  }, []);
  
  useEffect(() => {
    fetchEarningReportRecords();
  }, [JSON.stringify(getRecordDate), takeCount, skipCount, paramsQueries]);
  
  const fetchEarningReportRecords = async () => {
    if (getRecordDate.startDate && getRecordDate.endDate) {
      setDataLoading(true);
      let temp = [];
      if (paramsQueries && paramsQueries?.length > 0) {
        paramsQueries.forEach((o, i) => {
          temp.push({
              "columnName": o.columnName,
              "operator": o.operator,
              "paramValue": o.paramValue?.trim(),
              "paramType": "",
              "logicOperator": (i===0) ? "" : o.logicOperator
          })
        });
      }
      let payload = {
        startDate: new Date(getRecordDate?.startDate).toISOString(),
        endDate: new Date(getRecordDate?.endDate).toISOString(),
        // params: (paramsQueries && paramsQueries?.length > 0) ? paramsQueries : []
        params: temp
      }

      const res = await getAllEarningsReportRecords(payload);
      if (res?.status===200){
        setTableList(res.data);
        setTotalCount(res.totalCount);
      }
      setLoading(false);
      setDataLoading(false);
    }
  };

  

    return (
      <>
      <Container fluid={true} style={{ overflow: "auto"  }}>
      
            <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
                <ReportHeader {...props} header="Earning Summary Reports" />
            </div>
            
            <div className='page-content' >

            <AdvanceSearch loading={loading} role={role} userId={ID} paramsQueries={paramsQueries} setParamsQueries={setParamsQueries} setLoading={setLoading} />

            <EarningReportLayout
                {...props}
                role={"admin"}
                getRecordDate={getRecordDate}
                setGetRecordDate={setGetRecordDate}
            />

           
              <div className={`${classes.root} w-full`}   >
                <RequestTable
                    {...props}
                    role={"admin"}
                    requestDetail={tableList}
                    detail={detail}
                    totalCount={totalCount}
                    setTakeCount={setTakeCount}
                    takeCount={takeCount}
                    loading={dataLoading}
                    setLoading={setDataLoading}
                    setSkipCount={setSkipCount}
                    setDetail={setDetail}
                    userDetail={null}
                    apiCall={apiCall}
                    setApiCall={setApiCall}
                />
              </div>
              </div>
            
        </Container>
        </>
    )
}

export default EarningReport;