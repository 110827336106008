import React, { Fragment, useEffect } from "react";
import {
  Activity,
  Bell,
  CheckCircle,
  FileText,
  UserCheck,
} from "react-feather";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { LI, P, UL } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../app/store/reducers/slices/notificationSlice";
import moment from "moment";
import { ActivityItem } from "../../../app/utility/helper";

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const notificationData = useSelector(
    ({ notificationSlice }) => notificationSlice.notifications
  );
  useEffect(() => {
    dispatch(getNotification());
  }, []);

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown" }}>
        <div className="notification-box">
          <Bell />
          <span className="dot-animated"></span>
        </div>
        <UL attrUL={{ className: "notification-dropdown onhover-show-div" }}>
          <LI>
            <Link
              to={`${process.env.REACT_APP_PREFIX_URL}admin/notification-view`}
            >
              <P attrPara={{ className: "f-w-700 m-0" }}>
                You have {notificationData?.length} Notifications
                <span className="pull-right badge badge-primary badge-pill">
                  {notificationData?.length}
                </span>
              </P>
            </Link>
          </LI>
          {notificationData?.length
            ? notificationData?.slice(0,5)?.map((i,indx) => {
                return (
                  <LI attrLI={{ className: "noti-primary" }} key={`n${indx}`}>
                    <div
                      onClick={() => {
                        navigate({
                          pathname: `/payroll-work-verify/${i.senderID}`,
                          search: `?${  createSearchParams({ppeID:i.forID})  } `,
                        });
                      }}
                    >
                      <div className="media">
                        <span className="notification-bg bg-light-primary">
                          {ActivityItem(i.type, i).icon}
                        </span>
                        <div className="media-body">
                          <P>{i.message}</P>
                          <span>
                            {moment(i.CreatedOn).format("DD-MM-YYYY hh:mm A")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </LI>
                );
              })
            : ""}
          {/* <LI attrLI={{ className: "noti-secondary" }}>
            <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
              <div className="media">
                <span className="notification-bg bg-light-secondary">
                  <CheckCircle />
                </span>
                <div className="media-body">
                  <P>Report Complete</P>
                  <span>1 hour ago</span>
                </div>
              </div>
            </Link>
          </LI>
          <LI attrLI={{ className: "noti-success" }}>
            <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
              <div className="media">
                <span className="notification-bg bg-light-success">
                  <FileText />
                </span>
                <div className="media-body">
                  <P>Hours Generated</P>
                  <span>3 hour ago</span>
                </div>
              </div>
            </Link>
          </LI>
          <LI attrLI={{ className: "noti-danger" }}>
            <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
              <div className="media">
                <span className="notification-bg bg-light-danger">
                  <UserCheck />
                </span>
                <div className="media-body">
                  <P>New Employee added</P>
                  <span>6 hour ago</span>
                </div>
              </div>
            </Link>
          </LI> */}
        </UL>
      </LI>
    </Fragment>
  );
};

export default Notifications;
