import React, { useEffect, useState } from "react";

import { Card, Grid } from "@material-ui/core";
import { Tooltip, Typography } from "@mui/material";

// import { fontSize } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../../../app/utility/helper";
import { fetchDeductionByVendor } from "../../../../app/store/reducers/slices/vendorSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-duotone-svg-icons";
import clsx from "clsx";

import moment from "moment";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ModalComponent from "../../../SharedComponents/ModalComponent";
import EmployeeByVendor from "./EmployeeByVendor";
import MUIDataTable, { TableHead, TableHeadCell } from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import AdvanceSearchVendorReport from "./AdvanceSearchVendorReport";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  tableIcon: {
    color: "#0000008a",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  paperStyle: {
    cursor: "pointer",
    boxShadow:
      "0px -1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  textLabels: {
    "& > span": {
      marginTop: "0.2rem",
    },
  },
  scrollHeight: {
    height: "calc(100vh - 100px - 235px)",
    overflow: "auto",
  },
  tableCard: {
    minWidth: "800px",
    overflowX: "auto",
  },
  footerCell: {
    // backgroundColor: theme.palette.background.paper,
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
  },
}));

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   // border: '2px solid #000',
//   boxShadow: 24,
// };
function ReportLayoutByVendor(props) {
  const classes = useStyles();

  const vendorList = props.requestDetails;

  let dispatch = useDispatch();
  const history = useNavigate();

  const [open, setOpen] = useState(false);
  const [vendorCode, setVendorCode] = useState(0);

  const [totalShare, setTotalShare] = useState({
    empCnt: 0,
    emp: 0,
    gov: 0,
    grandTotal: 0,
  });
  useEffect(() => {
    dispatch(fetchDeductionByVendor());
  }, [dispatch]);

  useEffect(() => {
    let grandTotal = 0;
    let grandEmpCnt = 0;
    let grandEmp = 0;
    let grandGov = 0;
    vendorList?.map((j) => {
      let empC = j.totalEmployee ? j.totalEmployee : 0;
      let emp = j.EmpFee ? j.EmpFee : 0;
      let gov = j.GovtShare ? j.GovtShare : 0;
      grandEmpCnt = grandEmpCnt + empC;
      grandEmp = grandEmp + emp;
      grandGov = grandGov + gov;
      grandTotal = grandTotal + gov + emp;
    });
    setTotalShare({
      empCnt: grandEmpCnt,
      emp: grandEmp,
      gov: grandGov,
      grandTotal: grandTotal,
    });
  }, [vendorList]);

  const renderDeductionList = () => {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        <Card
          style={{
            marginBottom: "10px",
            overflow: "hidden",
            padding: 0,
            borderRadius: "10px",
          }}
          elevation={3}
          className="CardBack"
        >
          <table className="table100">
            <thead>
              <tr>
                {/* <th colSpan={5} className="tc mainHead">
                  Deduction
                </th> */}
                <th colSpan={7}>
                  <h6
                    style={{
                      padding: "10px",
                      marginTop: 10,
                    }}
                  >
                    Deduction By Vendor
                  </h6>
                </th>
              </tr>

              <tr
                style={{
                  // paddingBottom: "8px",
                  // paddingTop: "8px",
                  // backgroundColor: "#dcdcea",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                <th className="tl1">Code</th>
                <th className="tl1">Vendor Name</th>
                <th className="tl1">Emp Count</th>
                <th className="tl1">Emp Share $</th>
                <th className="tl1">Govt Share $</th>
                <th className="tl1">Total</th>
                {/* <th className="tl1">Grand Total</th> */}
              </tr>
            </thead>
            <tbody>
              {vendorList.length
                ? vendorList.map((i, index) => {
                    return (
                      <tr
                        key={`z${index}`}
                        style={{
                          borderBottomColor: "#EAEBE4",
                          borderBottomWidth: "1px",
                        }}
                        className="CardBack"
                      >
                        <td className="tl1">{i.VendorNo}</td>
                        <td className="tl1">{i.VendorName}</td>
                        <td className="tl1">
                          <div
                            style={{ color: "#111827", cursor: "pointer" }}
                            onClick={() => {
                              setOpen(true);
                              setVendorCode(i.VendorNo);

                              history(
                                `/report/deductionbyvendor/${i.VendorNo}`
                              );
                            }}
                          >
                            {i.totalEmployee}{" "}
                            <FontAwesomeIcon
                              style={{ color: "#1569C7", height: "12px" }}
                              icon={faUser}
                              className="  "
                            />
                          </div>
                          {/* <Link
                            style={{color:"#111827"}} 
                            to={{
                              pathname: `/report/employee/${i.VendorNo}`,
                              state: i,
                            }}
                          >
                            {i.totalEmployee}{" "}
                            <FontAwesomeIcon
                              style={{ color: "#1569C7", height: "12px" }}
                              icon={faUser}
                              className="  "
                            />
                          </Link> */}
                        </td>
                        <td className="tl1">{formatMoney(i.EmpFee)}</td>
                        <td className="tl1">{formatMoney(i.GovtShare)}</td>
                        <td className="tl1">
                          {formatMoney(i.EmpFee + i.GovtShare)}
                        </td>
                        {/* <td className="tl1">-</td> */}
                      </tr>
                    );
                  })
                : null}

              {vendorList.length ? (
                <tr className="grandTd  CardBack">
                  <td className="tl1" colSpan={2}>
                    Gross Deduction
                  </td>

                  <td className="tl1">{totalShare.empCnt}</td>
                  <td className="tl1">{formatMoney(totalShare.emp)}</td>
                  <td className="tl1">{formatMoney(totalShare.gov)}</td>
                  <td className="tl1">{formatMoney(totalShare.grandTotal)}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Card>
      </Grid>
    );
  };
  const columns = [
    {
      id: "VendorNo",
      name: "VendorNo",
      label: "Code",
    },
    {
      id: "VendorName",
      name: "VendorName",
      label: "Vendor Name",
    },
    {
      id: "totalEmployee",
      name: "totalEmployee",
      label: "Emp Count",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{ color: "#111827", cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
                setVendorCode(tableMeta.rowData[0]);
                // props.history.push(`/report/deductionbyvendor/${vendorCode}`);
                history(`/report/deductionbyvendor/${tableMeta.rowData[0]}`);
              }}
            >
              {value}{" "}
              <FontAwesomeIcon
                style={{ color: "#1569C7", height: "12px" }}
                icon={faUser}
                className="  "
              />
            </div>
          );
        },
      },
    },
    {
      id: "EmpFee",
      name: "EmpFee",
      label: "Emp Share $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(value);
        },
      },
    },
    {
      id: "GovtShare",
      name: "GovtShare",
      label: "Govt Share $",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatMoney(value);
        },
      },
    },
    {
      id: "GovtShare",
      name: "GovtShare" || "EmpFee",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {console.log("valeu total", value)}
              {formatMoney(tableMeta.rowData[3] + tableMeta.rowData[4])}
            </>
          );
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    // [classes.stickyFooterCell]: stickyFooter,
  });
  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",

    rowsPerPage: 10,
    expandableRowsHeader: true,
    fixedSelectColumn: true,
    // customRowRender: (data, dataIndex, rowIndex) => {
    //   return (
    //     <>
    //       {rowIndex === 0 && (
    //         <TableRow>
    //           <TableCell></TableCell>
    //           <TableCell>sa</TableCell>
    //           <TableCell>sa</TableCell>
    //           <TableCell>sasa</TableCell>
    //           <TableCell>asas</TableCell>
    //           <TableCell>sasa</TableCell>
    //           <TableCell>sasa</TableCell>
    //         </TableRow>
    //       )}
    //       <TableRow className={classes.headerRow}>
    //         <TableCell>{props.title1}</TableCell>
    //         <TableCell>{data[0]}</TableCell>
    //         <TableCell>{data[1]}</TableCell>
    //         <TableCell>{data[2]} </TableCell>
    //         <TableCell>{data[3]}</TableCell>
    //         <TableCell>{data[4]}</TableCell>
    //         <TableCell>{data[5]}</TableCell>
    //       </TableRow>
    //     </>
    //   );
    // },
    customTableBodyFooterRender: function (opts) {
      const finalEmpCount = vendorList?.reduce(
        (i, a) => i + a.totalEmployee,
        0
      );
      const finalEmpShare = vendorList?.reduce((i, a) => i + a.EmpFee, 0);
      const finalGovShare = vendorList?.reduce((i, a) => i + a.GovtShare, 0);
      const finalTotal = vendorList?.reduce(
        (i, a) => i + a.GovtShare + a.EmpFee,
        0
      );

      return (
        <>
          <TableFooter className={footerClasses}>
            <TableRow style={{ width: "100%" }}>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}>
                {" "}
                <b style={{ fontSize: "15px" }}>Gross Deduction:</b>
              </TableCell>
              <TableCell className={footerClasses}></TableCell>
              <TableCell className={footerClasses}>
                <b style={{ fontSize: "15px" }}> {finalEmpCount}</b>
              </TableCell>
              <TableCell className={footerClasses}>
                {" "}
                <b style={{ fontSize: "15px" }}>
                  {" "}
                  {formatMoney(finalEmpShare)}
                </b>
              </TableCell>
              <TableCell className={footerClasses}>
                <b style={{ fontSize: "15px" }}>
                  {" "}
                  {formatMoney(finalGovShare)}
                </b>
              </TableCell>
              <TableCell className={footerClasses}>
                <b style={{ fontSize: "15px" }}> {formatMoney(finalTotal)}</b>
              </TableCell>
            </TableRow>
          </TableFooter>
        </>
      );
    },
  };
  return (
    <div className="w-full h-full reportTbl">
      <div>
        <div style={{ marginTop: "0px", borderRadius: "10px" }}>
          <AdvanceSearchVendorReport />
          {/* <div class="rounded overflow-hidden shadow-lg"> */}
          <Card
            style={{
              padding: "15px",
              marginTop: "0px",
              marginBottom: "30px",
              borderRadius: "10px",
            }}
            className="CardBack"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "right" }}>
                {/* <table className="padTbl CardBack">
                  <tbody className="leftHead CardBack">
                    <tr>
                      <td className="cardSmallTitle">Run Date</td>
                      <td>:</td>
                      <td className="cardSmallValue">12/22/2022</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Program</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle" colSpan={3}>
                        DEPT/DIV SPECIFICATION
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ textAlign: "center" }}>
                <table className="table100 centerDiv padTbl">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          {vendorList?.length
                            ? vendorList[0]["Department"]
                            : "Department of Administrative"}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="t18">
                        <b className="text-light">
                          Standard Deduction By Employee
                        </b>
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td className="cardSmallTitle">CHK: 6/24/2022</td>
                      <td className="cardSmallTitle">
                        PPE:{" "}
                        {vendorList?.length
                          ? moment(vendorList[0]["PPE"]).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>

              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "left" }}>
                {/* <table className="rightHead fright  padTbl">
                  <tbody>
                    <tr>
                      <td className="cardSmallTitle">Page</td>
                      <td>:</td>
                      <td className="cardSmallValue">1</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle">Run Time</td>
                      <td>:</td>
                      <td className="cardSmallValue">Paybills</td>
                    </tr>

                    <tr>
                      <td className="cardSmallTitle">Run ID</td>
                      <td>:</td>
                      <td className="cardSmallValue">
                        PAYU00{vendorList.length ? vendorList[0]["RunID"] : "2"}
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Grid>
            </Grid>
          </Card>
          {/* </div> */}
        </div>

        <Grid container spacing={2}>
          {/* {renderDeductionList()} */}
          {/* <ModalComponent
            title="Employee List"
            open={open}
            setOpen={() => {
              setOpen(!open);
            }}
          >
            <div>
              <EmployeeByVendor {...props} vendorCode={vendorCode} />
            </div>
          </ModalComponent> */}
        </Grid>
      </div>
      <MUIDataTable
        className={classes.customData}
        title={
          <h6
            style={{
              padding: "10px",
              marginTop: 10,
            }}
          >
            Deduction By Vendor
          </h6>
        }
        data={vendorList}
        columns={columns}
        isRowExpanded={true}
        options={options}
      />
    </div>
  );
}

export default ReportLayoutByVendor;
