import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faMessageExclamation, faSquareList } from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";

function HomeHeader(props) {
  const openViewTransaction = () => {
    props.history.push("/transaction-log");
  };

  const handleRequestVOEClick = () => {
    props.history.push("/master-detail/employee-verification");
  };

  return (
    <div animation="transition.slideLeftIn" delay={300}>
      <CardHeader>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
            gap: "10px",
            paddingRight: "10px"
          }}
        >

          {/* <Icon className="text-24 mx-10">portrait</Icon> */}

          <FontAwesomeIcon
            icon={faAddressCard}
            className="mx-10  px-3 fs-4"
          />
          <Typography className="fs-5">Employee Master Detail</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <button

            className="btn btn-success btn-sm"

            onClick={handleRequestVOEClick}
          >
            {/* <Icon className="text-16 mr-4">call_received</Icon> */}

            Request VOE

          </button>
          <button

            className="btn btn-success btn-sm"

            onClick={openViewTransaction}
          >
            <div className="d-flex">
              <FontAwesomeIcon
                icon={faSquareList}
                style={{ color: "white", marginRight: "10px", marginTop: "3px" }}
              />
              {/* <SyncAltIcon className="text-16 mr-4" /> */}

              View Transactions

            </div>
          </button>
          <button
            onClick={() => props.history.push("/announcement")}

            className="btn btn-success btn-sm"

          >
            <div className="d-flex">
              <FontAwesomeIcon
                icon={faMessageExclamation}
                style={{ color: "white", marginRight: "10px", marginTop: "3px" }}
              />
              {/* <Icon className="text-16 mr-4">announcement</Icon> */}

              Announcement

            </div>
          </button>
        </div>
      </CardHeader>
    </div>
  );
}

export default HomeHeader;