import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { HourMinFormat, getHMFromTime, getValidPaycodeOfDay } from "../../../app/utility/helper";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CalculateWorkingHR1 = (start, end, date) => {
  const startTime = moment(`${date} ${start}`, "YYYY-MM-DD HH:mm");
  let endTime = moment(`${date} ${end}`, "YYYY-MM-DD HH:mm");

  
  if (startTime > endTime) {
    let nextDay = moment(date).add("days", 1).format("YYYY-MM-DD");
    
    endTime = moment(`${nextDay} ${end}`, "YYYY-MM-DD HH:mm");
  }
  let duration = moment.duration(endTime.diff(startTime));

  // if (duration.asHours() < 0) {
  
  //   const endTime2 = moment(timeData?.time_to, "HH:mm").add(1, "day");
  //   duration = moment.duration(endTime2.diff(startTime));
  // }
  const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
  const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
  const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

  let hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);

return hrCalc

}


const calculateTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const timeDiff = end.getTime() - start.getTime();
  const seconds = Math.floor(timeDiff / 1000) % 60;
  const minutes = Math.floor(timeDiff / (1000 * 60)) % 60;
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));

  return (`${pad(hours)}:${pad(minutes)}`);
};

const pad = (value) => {
  return value.toString().padStart(2, '0');
};



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TimeClockRows = ({data,jobTypeOptions}) => {
  
  return (
    <StyledTableRow
              // key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <StyledTableCell align="center">
               {data.paycode}
              </StyledTableCell> */}
              <StyledTableCell align="center">{data.date}</StyledTableCell>
              <StyledTableCell align="center">{moment(data.startTime).format("hh:mm A")}</StyledTableCell>
              <StyledTableCell align="center">{moment(data.endTime).format("hh:mm A")}</StyledTableCell>
              <StyledTableCell align="center">{getHMFromTime(moment(data.startTime).format("HH:mm"),moment(data.endTime).format("HH:mm"))?.hhmm}</StyledTableCell>
              {/* <StyledTableCell align="center">{data.Auth}</StyledTableCell>
              <StyledTableCell align="center">{data.isLate}</StyledTableCell> */}

            </StyledTableRow>
  );
};

export default TimeClockRows;
