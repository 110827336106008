import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  createFilterOptions,
  Chip,
  Box,
} from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import clsx from "clsx";

import { H5 } from "../../../AbstractElements";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector } from "react-redux";
import AutocompleteAll from "../../../CommonElements/CustomComponent/AutoComplete";
import axios from "axios";
import { ShowSucess } from "../../../app/utility/helper";
import { concat } from "lodash";
import EmployeeDetails from "../../employee/employeeDetails";
import Header1 from "../../employee/component/header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/pro-duotone-svg-icons";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@mui/icons-material/Close";

import { useLocation, useParams } from "react-router-dom";
import TelerikDataTable from "./telerikDataTableAssignEmp";
import AdvanceSearchAssignEmp from "./AdvanceSearchAssignEmp";
import { getUserId, getUserRole } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import DepartmetChips from "./DepartmetChips";

const SupervisorInfo = (props) => {
  const  DPDV  = useSelector(
    (state) => state.configurationSlice?.advanceSearchData?.DPDV
  );
  const [value, setValue] = useState({
    DPDV: [],
  });
  const [empData, setEmpData] = useState([]);
  const [selectedEmps, setSelectedEmps] = useState([]);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const paramsID = useParams()
  const [deletedDepts, setDeletedDepts] = useState([]);
  const [userDatas, setUserDatas] = useState({

  });
  const [deletedEmps, setDeletedEmps] = useState([]);

  const supEmpId = paramsID?.id;
 

  const [selectedEmpsData, setSelectedEmpsData] = useState([]);

  // const allSelected = DPDV.length === selectedDepts.length;
  // const handleToggleOption = (selectedDepts) => setSelectedDepts(selectedDepts);
  // const handleClearOptions = () => setSelectedDepts([]);
  // const getOptionLabel = (option) => `${option.label}`;
  // const handleSelectAll = (isSelected) => {
  //   if (isSelected) {
  //     setSelectedDepts(DPDV);
  //   } else {
  //     handleClearOptions();
  //   }
  // };

  const handleChange = (e, title) => {
    setValue({ ...value, [title]: e });
    setSelectedDepts(e);
  };
  const handleEmpChange = (e) => {
    setSelectedEmps(e);
    // setValue({ ...value, [title]: e });
  };

  const getEmpName = (value) => {
    let empName =
      empData &&
      empData.length &&
      empData.find((temp) => {
        return temp.value === value;
      });
    return empName?.label;
  };
  const getDeptName = (val) => {
    let empName =
      DPDV &&
      DPDV.length &&
      DPDV.find((temp) => {
        return temp.value === val;
      });
    return empName?.label;
  };

  const getEmpData = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        {
          tableName: "EmployeeMaster",
          params: [
            {
              value: 0,
              operator: ">=",
              column: "ID",
              dataType: "bigint",
              columnName: "ID",
            },
          ],
          columns: "ID,EmployeeName",
          page: 1,
          limit: 200,
          orderBy: "ID",
          sortBy: "DESC",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        let newData = response.data?.map((data) => {
          return { value: data.ID, label: data.EmployeeName };
        });
        setEmpData(newData);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };


  const getUserInfo = (ID) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        {
          "tableName": "EmployeeMaster",
          "params": [
              {
                  "value": ID,
                  "operator": "=",
                  "column": "ID",
                  "dataType": "bigint",
                  "columnName": "ID"
              }
          ],
          "columns": "ID,EmployeeName",
          "page": 1,
          "limit": 100000000,
          "orderBy": "ID",
          "sortBy": "DESC"
      },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("AScadfasfsfsafafa", response);
        setUserDatas(response?.data[0])
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if(supEmpId){
      getUserInfo(supEmpId)
    }
  },[supEmpId])
  const getAssignedDeptEmp = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/supervisor/getassignDpdvEmp`,
        {
          EmployeeID: supEmpId  
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("In getAssignedDeptEmp response ::: ", response.data);
        if (response.data.result.length) {
          let newArray = response.data.result.filter(
            (i) => i.EmployeeID !== null
          );
          let empArray = newArray.map((emp) => {
            let obj = {
              value: emp.EmployeeID,
              label: getEmpName(emp.EmployeeID),
              id: emp.ID,
            };

            return obj;
          });
          // setSelectedEmps(empArray);

          let newArrayDept = response.data.result.filter(
            (i) => i.DPDV !== null
          );
          let empArrayDept = newArrayDept.map((emp) => {
            let obj = {
              value: emp.DPDV,
              label: getDeptName(emp.DPDV),
              id: emp.ID,
            };

            return obj;
          });
          setSelectedEmps(empArray);
          setSelectedDepts(empArrayDept);

          setIsUpdate(true);
          console.log("Emp111 : ", empArray);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const assignDeptEmp = () => {
    let empArray = selectedEmps.map((emp) => {
      return {
        EmployeeID: emp.value,
        SupervisorEmployeeID: supEmpId,
        ActiveDate: null,
        EndDate: null,
        DPDV: null,
        ID: emp.id ? emp.id : null,
      };
    });

    let deptArray = selectedDepts.map((dept) => {
      return {
        EmployeeID: null,
        SupervisorEmployeeID: supEmpId,
        ActiveDate: null,
        EndDate: null,
        DPDV: dept.value,
        ID: dept.id ? dept.id : null,
      };
    });

    let assignArray = [
      ...empArray,
      ...deptArray,
      // { ID: null, SupervisorEmployeeID: props.supEmpId },
    ];

    console.log("assign Array : ", assignArray, deletedDepts, deletedEmps);
    // return;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/supervisor/assignDpdvEmp`,
        {
          assignList: assignArray,
          deletedList: concat(deletedDepts, deletedEmps),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master timecode", response.data);
        // ShowSucess(isUpdate ? "Updated Successfully" : "Added Successfully");
        ShowSucess("Added Successfully");
        // getAssignedDeptEmp();
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    let newArray = selectedEmps.map((item) => {
      return item.value;
    });
    setSelectedIds(newArray);
  }, [selectedEmps]);

  useEffect(() => {
    console.log("12341234 ::: ", deletedDepts, deletedEmps);
  }, [deletedDepts, deletedEmps]);

  useEffect(() => {
    getEmpData();
  }, []);
  useEffect(() => {
    console.log("In useEffect DPDV ::: ", DPDV);
    if (DPDV?.length && empData?.length) {
      getAssignedDeptEmp();
    }
  }, [DPDV, empData]);

  //*********
  const { userData } = useAuth();
  const ID = getUserId(userData);
  const role = getUserRole(userData);
  const employeeSearched = useSelector(
    ({ employeeSlice }) => employeeSlice?.employeeSearched
  );

  const employeeColumns = useSelector(
    (state) => state.querySlice?.colsPlaceholder
  );

  const [sortedColumns, setSortedColumns] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getColumnFilterType = (type) => {
    if (["float", "bigint", "smallint", "int"].includes(type)) {
      return "numeric";
    } else if (["varchar", "char"].includes(type)) {
      return "text";
    } else if (["datetime"].includes(type)) {
      return "date";
    } else {
      return "text";
    }
  };

  useEffect(() => {
    if (employeeColumns && employeeColumns.length) {
      let colArray = [];
      employeeColumns.map((i) => {
        let iObj = {
          title: i.column_title,
          field: i.column_name,
          locked: false,
          show: false,
          filter: getColumnFilterType(i.data_type),
          width: 200,
        };
        colArray.push(iObj);
      });

      // Arrange column sequence

      const visibleColumnArray = [
        "SSN",
        "ID",
        "EmployeeName",
        "Department",
        "Nposition_No",
        "DPDV",
        "Military_Status",
        "Hm_Phone",
        "Ml_Wk_Phone",
        "Email",
        "Inactive_Status_Title",
        "Emp_Status_Title",
      ];
      let orderColumns = [];
      visibleColumnArray.map((i) => {
        let columnIndex = colArray.findIndex((k) => {
          return k.field == i;
        });
        if (columnIndex !== -1) {
          orderColumns.push({ ...colArray[columnIndex], show: true });
          colArray.splice(columnIndex, 1);
        }
      });
      let updatedColumn = orderColumns.concat(colArray);
      setSortedColumns(updatedColumn);
    }
  }, [employeeColumns]);
  useEffect(() => {
    setTableList(employeeSearched);
    console.log("Prm", employeeSearched);
  }, [employeeSearched]);

  const handleRemove = (value, id) => {
    let newArray = [...deletedDepts, id];
    setDeletedDepts(newArray);
    const filteredList = selectedDepts.filter((i) => {
      return i.value !== value;
    });
    console.log("filteredList", filteredList);
    setSelectedDepts(filteredList);
  };
  return (
    <>
      <CardHeader className="pb-0">
        <div>
          <div
            className={`flex flex-1 w-full bg-primary`}
            style={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "#1975D2",
              height: "4.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faTasks} className="mx-10  px-3 fs-4" />
            <Typography className="fs-5">
              Supervisor Department/Employee Assignment
            </Typography>
          </div>
        </div>
      </CardHeader>
      {!['supervisor'].includes(role) &&
      <Card className="mt-2">
        <CardBody>
          <Form>
            <Row>
              <Col md={12} lg={12} sm={12}>
                <Typography style={{ display: "flex", justifyContent: "left" }}>
                  <h6>Department Assignment </h6>
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12}>
                
              {/* <AutocompleteAll
                  items={DPDV && DPDV}
                  // getOptionDisabled={getOptionDisabled}
                  selectedValues={selectedDepts}
                  label=""
                  placeholder="Select Department"
                  modelLable="Selected Department"
                  selectAllLabel="Select all"
                  onChange={(e) => {
                    handleChange(e, "DPDV");
                  }}
                  // deletedValues={deletedDepts}
                  setDeletedValues={setDeletedDepts}
                />
                <Box>
                  <Typography
                    id="keep-mounted-modal-description"
                    sx={{ mt: 2, m: 2 }}
                  >
                    {selectedDepts.map((v, index, a, getTagProps) => (
                      <Chip
                        label={v.label}
                        style={{ margin: "10px" }}
                        // onClick={handleClick}
                        // onDelete={handleDelete}
                        // className={classes.chip}

                        icon={
                          <CloseIcon
                            onClick={() => handleRemove(v.value, v.id)}
                            style={{
                              borderColor: "black",
                              backgroundColor: "gray",
                              fontSize: "20px",
                              color: "white",
                              borderRadius: "20px",
                              cursor: "pointer",
                            }}
                          />
                        }
                      />
                    ))}
                  </Typography>
                </Box> */}
                { DPDV && DPDV.length ? (
                  <DepartmetChips
                    DPDVList={DPDV}
                    selectedDepts={selectedDepts}
                    setSelectedDepts={setSelectedDepts}
                    handleRemove={handleRemove}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {/* <Row> */}
            {/* <Col md={4} lg={4} sm={12}>
              <Typography style={{ display: "flex", justifyContent: "left" }}>
                <h6>Employee</h6>
              </Typography>
            </Col> */}
            {/* </Row> */}
            {/* <Row>
            <Col md={4} lg={4} sm={12}>
              <AutocompleteAll
                items={empData && empData}
                selectedValues={selectedEmps}
                // getOptionDisabled={getOptionDisabled}
                label=""
                placeholder="Select Employee"
                modelLable="Selected Employees"
                selectAllLabel="Select all"
                onChange={(e) => {
                  handleEmpChange(e);
                }}
                // deletedValues={deletedEmps}
                setDeletedValues={setDeletedEmps}
              />
            </Col>
          </Row> */}
          </Form>
        </CardBody>
      </Card>}
      {/* <CardHeader className="pb-0">
        <div>
          <div
            className={`flex flex-1 w-full bg-primary`}
            style={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "#1975D2",
              height: "4.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faTasks} className="mx-10  px-3 fs-4" />
            <Typography className="fs-5">Employee Assignment</Typography>
          </div>
        </div>
      </CardHeader> */}
      <Card className="mt-2">
        <CardBody>
          {/* <EmployeeDetails /> */}
          <Typography style={{ display: "flex", justifyContent: "left" }}>
            <h6 style={{marginTop:"3px"}}>Employee Assignment </h6>
            <span style={{marginLeft:"20px"}}>({userDatas?.EmployeeName ? userDatas?.EmployeeName + " / " +  userDatas?.ID : ""})</span>
          </Typography>
          <div>
            <AdvanceSearchAssignEmp
              role={role}
              userId={ID}
              setLoading={setLoading}
              selectedIds={selectedIds}
              setSelectedEmpsData={setSelectedEmpsData}
            />
          </div>
          <div>
            <TelerikDataTable
              data={tableList}
              selectedColumns={sortedColumns}
              setSortedColumns={setSortedColumns}
              loading={loading}
              selectedEmps={selectedEmps}
              setSelectedEmps={setSelectedEmps}
              deletedEmps={deletedEmps}
              setDeletedEmps={setDeletedEmps}
            />
          </div>
          <div>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                color: "white",
                borderRadius: "5px",
              }}
              className="bg-primary p-2 mt-2"
              onClick={() => {
                assignDeptEmp();
              }}
            >
              Assign Department & Employees
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SupervisorInfo;
