import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";

import Header from "../../employee/component/header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import axios from "axios";
import Header1 from "./component/header1";
import AdvanceSearch from "./component/AdvanceSearch";
import { useSelector } from "react-redux";
import { useAuth } from "oidc-react";
import { getUserId } from "../../../app/utility/helper";
import AdvanceSearch1 from "./component/AdvanceSearch1";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const HoursReport = (props) => {
  const classes = useStyles();

  const { userData } = useAuth();
  const ID = getUserId(userData);
  console.log("ID ::", userData, ID);
  const [apiCall, setApiCall] = useState(false);

  const employeeSearched = useSelector(
    ({ chartSlice }) => chartSlice.queryIssuesSearch
  );
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });
  const [tableList, setTableList] = useState([]);
  const [hoursQuery, setHoursQuery] = useState(null);
  const [departmentQuery, setDepartmentQuery] = useState(null);
  const [ppeQuery, setPpeQuery] = useState(null);
  console.log("hoursQuery", hoursQuery);
  useEffect(() => {
    setTableList(employeeSearched);
  }, [employeeSearched]);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getFirstApiData = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employee/findIssueForDepartment`,
        // {
        //   // TotalHr: hoursQuery,
        //   P_DPDV: 1622,
        //   P_PPE: 16,
        // },
        {
          // TotalHr: data,
          P_DPDV: departmentQuery,
          P_PPE: ppeQuery,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
        setApiCall(false);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    // getData();
    console.log(hoursQuery, "#", ppeQuery, "##", departmentQuery);
  }, [hoursQuery, ppeQuery, departmentQuery]);

  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <Header1 {...props} title="Payroll Processing Exception Reports" />
      </div>

      {/* <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
        >
        <div className={`${classes.root} w-full`}>
          <AdvanceSearch
            userId={ID}
            getData={getFirstApiData}
            setDepartmentQuery={setDepartmentQuery}
            setPpeQuery={setPpeQuery}
          />
        </div>
      </div> */}
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full page-content`}>
          <AdvanceSearch1 />
        </div>
      </div>

      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full page-content`}>
          <RequestTable
            {...props}
            role={"admin"}
            requestDetail={tableList}
            detail={detail}
            setHoursQuery={setHoursQuery}
            setDetail={setDetail}
            userDetail={null}
            apiCall={apiCall}
            setApiCall={setApiCall}
          />
        </div>
      </div>
      </Container>
    </>
  );
};
export default HoursReport;
