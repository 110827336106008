import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row, Form } from "reactstrap";
import { P } from "../../../AbstractElements";
import { Button, Typography } from "@material-ui/core";
import { floatNumberRegex } from '../../../app/utility/helper';
import { LoadingButton } from "@mui/lab";

function EmployeePayrollDetail(props) {
  const { selectedData, loading } = props;
  const [checkNumber, setCheckNumber] = useState('');
  const menuItemListHeight = 300;

  useEffect(() => {
    if (selectedData) {
      setCheckNumber(selectedData?.checkNumber ? selectedData?.checkNumber : '');
    }
  }, [JSON.stringify(selectedData)]);

  return (
    <div className="w-full h-full empPayrollDetailDiv">
      {/* {props?.userData?.profile?.role === 'admin' && <P>Employee Id: {props?.currentEmpDetails?.ID}</P>} */}
      <P>Employee Id: {selectedData?.employeeId}</P>
      <P>Employee Name: {selectedData?.name}</P>
      <div className="flex flex-col sm:flex-row w-full py-4 mt-10">
        <Form>
          <Row className="g-3">
            <Col md="12">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Check Number"
                value={checkNumber}
                onChange={(e) => {
                  if (e.target.value === "" || floatNumberRegex.test(e.target.value)) {
                    setCheckNumber(e.target.value);
                  }
                }}
                name="eName"
              />
            </Col>
          </Row>
        </Form>
      </div>
      <Row className="mb-3 buttonRow">
        <LoadingButton
          variant="contained"
          className="m-10 mx-2 xs:mx-10 min-w-0"
          style={{
            color: "#234d10",
            backgroundColor: "#ffffff",
            width: 'auto'
          }}
          loading={loading}
          onClick={() => props.saveData(checkNumber)}
          // loadingPosition="start"
          // startIcon={<SaveIcon />}
          // variant="outlined"
        >
          {/* Save */}
          <Typography className="text-12">
            {" "}
            {loading ? "" : "Save"}{" "}
          </Typography>
        </LoadingButton>
        {/* <Button
          variant="contained"
          className="m-10 mx-2 xs:mx-10 min-w-0"
          style={{
            color: "#234d10",
            backgroundColor: "#ffffff",
            width: 'auto'
          }}
          onClick={() => props.saveData(checkNumber)}
        >
          <Typography className="text-12">
            {" "}
            {"Save"}{" "}
          </Typography>
        </Button> */}
        <Button
          variant="contained"
          className="m-10 mx-2 xs:mx-10 min-w-0"
          style={{
            color: "#234d10",
            backgroundColor: "#ffffff",
            width: 'auto'
          }}
          onClick={props.closeModal}
        >
          <Typography className="text-12">
            {" "}
            {"Cancel"}{" "}
          </Typography>
        </Button>
      </Row>
    </div>
  );
}

export default EmployeePayrollDetail;