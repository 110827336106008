import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@mui/material";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "./header";
// import Header from "./component/header";
import PayCodeManagement from "./payCodeManagement";
// import Professional from "./component/professionaleDetail";
// import Other from "./component/otherDetail";
import RequestTable from "./request-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { Card, CardBody, CardHeader, Col } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "33.33%",
    fontWeight: 500,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

function PayTimeCode(props) {
  const classes = useStyles();
  const [detail, setDetail] = useState({
    code: "",
    effectDate: null,
    expireDate: null,
    abbreviation: "",
    description: "",
    multi: 0,
  });
  const [tableList, setTableList] = useState([]);
  const pageLayout = useRef(null);
  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  console.log("detail--", detail);
  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const { userDetail } = props;
  console.log("userDetail ::: ", userDetail);
  return (
    <>
      {" "}
      <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <HomeHeader
            {...props}
            detail={detail}
            getData={getData}
            setDetail={setDetail}
          />
        </div>
        {/* <div className="w-100 h-full mt-3 " ref={pageLayout} innerScroll> */}
        <div className={`${classes.root} w-100 page-content`}>
          <Card className="mt-3 mb-3 ">
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  className="sub-header"
                  style={{
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Code Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0 card-header">
                <PayCodeManagement detail={detail} setDetail={setDetail} />
              </AccordionDetails>
            </Accordion>
          </Card>
          <Card>
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200 sub-header"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Codes List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <RequestTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  userDetail={{
                    firstName: "Robert",
                    middleName: "M",
                    lastName: "Duenas",
                    fullName: "Robert Duenas",
                    email: "robert@gmail.com",
                    avatar: "assets/images/avatars/Tillman.jpg",
                    maritalStatus: "single",
                    citizen: "United States",
                    education: "BACCALAUREATE",
                    gender: "Male",
                    disability: "NO",
                    DOB: "11/05/1993",
                    veteran: "Y",
                    i9: "Y",
                    ssn: "333-10-1001",
                    empTye: "Permanent",
                    dept: "DOA-Personnel Management",
                    payGrade: "Q",
                    empNo: "1035",
                    positionTitle: "EQUAL EMPLOYMENT OPPORTUNITY",
                    employmentDate: "02/04/2018",
                    reEmploymentDate: "02/04/2019",
                    totalSvc: " ",
                    currentSvc: " ",
                    totalPriorSvc: "18Y 0M 11D",
                    milPriorSvc: "18Y 0M 11D",
                    grade: "Q",
                    step: "9",
                    rate: "38.69",
                    salary: "8047",
                    actual: "38.69",
                    actual2: "80,476.00",
                    home: "174N HENRY KAISER, DEDEDO, GU, 96921",
                    mail: "POBOX 228444 GMF, BARRIGADA, GU, 96921",
                    homePhone: "6124893753",
                    workPhone: "6124893753",
                    workSchedule: "FULL TIME",
                    lastActionDate: "09/02/2020",
                    policeClearanceDate: "09/02/2020",
                    courtClearanceDate: "09/02/2020",
                    empContractNo: " ",
                    requestId: " ",
                    physicalExam: "Y",
                    drivingLicenseNo: " ",
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </div>
        {/* </div> */}
        {/* <FusePageCarded
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
        header={
          <Header
            {...props}
            detail={detail}
            getData={getData}
            setDetail={setDetail}
          />
        }
        content={
          <div className={`${classes.root} w-full`}>
            <Accordion
              className={classes.root}
              expanded={expand.panel1}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gray-200"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Codes Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <PayCodeManagement detail={detail} setDetail={setDetail} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="bg-gray-200"
              >
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{
                    color: "#1569C7",
                    marginRight: "10px",
                    marginTop: "3px",
                  }}
                />
                <Typography className={classes.heading}>
                  Pay Codes List
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <RequestTable
                  {...props}
                  role={"admin"}
                  requestDetail={tableList}
                  detail={detail}
                  setDetail={setDetail}
                  userDetail={{
                    firstName: "Robert",
                    middleName: "M",
                    lastName: "Duenas",
                    fullName: "Robert Duenas",
                    email: "robert@gmail.com",
                    avatar: "assets/images/avatars/Tillman.jpg",
                    maritalStatus: "single",
                    citizen: "United States",
                    education: "BACCALAUREATE",
                    gender: "Male",
                    disability: "NO",
                    DOB: "11/05/1993",
                    veteran: "Y",
                    i9: "Y",
                    ssn: "333-10-1001",
                    empTye: "Permanent",
                    dept: "DOA-Personnel Management",
                    payGrade: "Q",
                    empNo: "1035",
                    positionTitle: "EQUAL EMPLOYMENT OPPORTUNITY",
                    employmentDate: "02/04/2018",
                    reEmploymentDate: "02/04/2019",
                    totalSvc: " ",
                    currentSvc: " ",
                    totalPriorSvc: "18Y 0M 11D",
                    milPriorSvc: "18Y 0M 11D",
                    grade: "Q",
                    step: "9",
                    rate: "38.69",
                    salary: "8047",
                    actual: "38.69",
                    actual2: "80,476.00",
                    home: "174N HENRY KAISER, DEDEDO, GU, 96921",
                    mail: "POBOX 228444 GMF, BARRIGADA, GU, 96921",
                    homePhone: "6124893753",
                    workPhone: "6124893753",
                    workSchedule: "FULL TIME",
                    lastActionDate: "09/02/2020",
                    policeClearanceDate: "09/02/2020",
                    courtClearanceDate: "09/02/2020",
                    empContractNo: " ",
                    requestId: " ",
                    physicalExam: "Y",
                    drivingLicenseNo: " ",
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        }
        ref={pageLayout}
        innerScroll
      /> */}{" "}
      </Container>
    </>
  );
}

export default PayTimeCode;
