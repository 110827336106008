import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getUserRole, getValidPaycodeOfDay, KeyGen, payCodeFilter } from "../../app/utility/helper";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DayPilot } from "daypilot-pro-react";

import moment from "moment";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "oidc-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  heights: "500px",

  p: 4,
};

const EventModal = (props) => {
  console.log("openEventID",props.approveStatus);
  const handleOpen = () => props.setOpen(true);
  const handleClose = () =>{ 
   
    setEventData({})
   
    props.setOpen(false);
    props.setOnTimeArgs([])
  }
 

  const [payTimeCode, setPayTimeCode] = useState(
    props.eventPayCode && props.eventPayCode
  );
  const [selectedCode, setSelectedCode] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState('REG');
  const [ eventData, setEventData ] = useState({});
  const { userData } = useAuth();
  const userRole = getUserRole(userData)


  

  

  
  useEffect(() => {
    // if (props.selectedEvent?.e?.data.paycode) {
    //   setPayTimeCode(props.selectedEvent?.e?.data?.paycode);
    // }
    if (props.selectedEvent) {
      console.log('selectedEvent', props.selectedEvent.e.data);
      setEventData(props.selectedEvent.e.data);
    }
    else{
      setEventData({})
    }
    
    
  }, [props.selectedEvent]);

  const eleDisabled = (type) => {
    console.log(type,'<<<<>>>>',eventData?.paytype);
    
    if (eventData?.paytype=='NDIF') {
     return [ "update", "copy", "endTime", "startTime", "paycode" ].includes(type) ? true : false
    } else if (eventData?.paytype=="Auth") {
      return [ "update", "copy", "endTime", "startTime", "paycode" ].includes(type) ? true : false
    }else {
      return false
    }
  }
  const handleChange = (e) => {
    console.log("e.target.value", e.target.value);

    let elabel = props.paytimeCodeList.find((i) => {
      return i.code == e.target.value;
    })?.value;

    console.log(elabel,'props.paytimeCodeList',props.paytimeCodeList);
    
    // const dp = new DayPilot.Scheduler("dp");
    // var es = new DayPilot.Event(props.selectedEvent.e.data);
    // const pdata = props.selectedEvent.e.data;
    // console.log(" click es", es);

    // props.selectedEvent.e.data.text = `${e.target.value}- ${moment(
    //   pdata.start
    // ).format("hh:mm A")}:${moment(pdata.end).format("hh:mm A")}`;
    // console.log(" click esaas123", es.data.text);
    // dp.events.update(e);
    // console.log("handleChange", e.target.value);

    setPayTimeCode(elabel);
    setSelectedCode(e.target.value)
    setSelectedLabel(elabel)
    props.setEventPayCode(e.target.value);
    // // props.setOpen(false);
  };

  const handleCopyevent = () => {
    const schedulerRef = props.scheduler.current.control.events;
    const preData = props.selectedEvent;
    const preDate = moment(preData.start).format("YYYY-MM-DD");

    const finalStartDate = moment(
      `${preDate} ${props.updatedTime.startTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss");
    const finalEndDate = moment(
      `${preDate} ${props.updatedTime.endTime}`,
      "YYYY-MM-DD HH:mm"
    ).format("YYYY-MM-DDTHH:mm:ss");
    console.log("props.selectedEvent.e.data 123", finalStartDate, finalEndDate);

    let mycolor = props.getPPEColorCode(payTimeCode);
    const copyObject = {
      start: finalStartDate,
      end: finalEndDate,
      id: KeyGen(7),
      resource: preData.resource,
      paycode: props.eventPayCode,
      paytype: payTimeCode,
      barColor: mycolor,
      // text: `Vishlo ${moment(preData.start).format("hh:mm a")} - ${moment(
      //   preData.end
      // ).format("hh:mm a")}`,
    };

    schedulerRef.add(copyObject);
  };


  const handleUpdateevent = () => {
    console.log("props.selectedEvent.e.data", props.selectedEvent.e.data);
    const dp = new DayPilot.Scheduler("dp");
    var es = new DayPilot.Event(props.selectedEvent.e.data);
    const pdata = props.selectedEvent.e.data;
    console.log(typeof pdata.start =='object'?pdata.start.value:pdata.start ," click propsxx", props.updatedTime);
    console.log(typeof pdata.start ," click props", props.updatedTime);

    props.selectedEvent.e.data.text = `${"e.target.value"}- ${moment(
      pdata.start
    ).format("hh:mm A")}:${moment(pdata.end).format("hh:mm A")}`;
   

    let newSdate = moment(`${moment(pdata.start,"YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD")}T${props.updatedTime.startTime}:00`).format("YYYY-MM-DDTHH:mm:ss")
    let newEdate = moment(`${moment(pdata.end,"YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD")}T${props.updatedTime.endTime}:00`).format("YYYY-MM-DDTHH:mm:ss")
    //alert(typeof newSdate+"====="+pdata.start+'##'+newSdate +'%%%'+props.updatedTime.startTime);
    // console.log(selectedLabel?selectedCode:pdata.paytype," click tttt", selectedCode?selectedCode:pdata.paycode);
    props.selectedEvent.e.data.paycode = selectedCode?selectedCode:pdata.paycode;
    props.selectedEvent.e.data.paytype = selectedLabel?selectedCode:pdata.paytype;
    props.selectedEvent.e.data.start = newSdate;
    props.selectedEvent.e.data.end = newEdate;
    
    // dp.events.update(e);
    // console.log("handleChange", e.target.value);
    // props.setEventName("e.target.value1");
    props.setOpen(false);
  };
  

  const inputProps = {
    step: 1800,
  };

  console.log("props.paytimeCodeByID",props.paytimeCodeByID);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Timesheet ({props.onTimeArgs && props.onTimeArgs?.start?moment(props.onTimeArgs?.start,"YYYY-MM-DDTHH:mm:ss").format("MM-DD-YYYY - dddd"):null}   {props.updatedTime && props.updatedTime.date
            ? props.updatedTime.date
            : null})</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ padding: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Pay Time Code 
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"  
                    id="demo-simple-select"
                    label="Pay Time Code"
                    size="small"
                    disabled={ eleDisabled('paycode') }
                    
                    // defaultOp={props.selectedEvent?.e?.data?.paycode}
                    value={props.eventPayCode}
                    onChange={handleChange}
                  >
                    {payCodeFilter
                      ? getValidPaycodeOfDay(props.onTimeArgs && props.onTimeArgs?.start?moment(props.onTimeArgs?.start,"YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"):null,payCodeFilter(props.paytimeCodeList, props.paytimeCodeByID,userRole)).map((i) => {
                          return <MenuItem value={i.code}>{i.label}</MenuItem>;
                        })
                      : ""}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={4}>
                <TextField
                  size={"small"}
                  id="time"
                  label="Start Time"
                  type="time"
                  disabled={eleDisabled('startTime')}
                  fullWidth
                 
                  // error={ole1 ? true : false}
                  inputProps={inputProps}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={props.updatedTime.startTime}
                  // value={time_from}
                  onChange={(e) => {
                    console.log("handleTime start", e.target.value);
                    props.setUpdatedTime({
                      ...props.updatedTime,
                      startTime: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="End Time"
                  size={"small"}
                  id="time"
                  fullWidth
                  // label="From"
                  type="time"
                  disabled={eleDisabled('endTime')}
                  
                  // inputProps={{
                  //   style: { fontSize: 14, step: 1800 },
                  //   step: 1800,

                  // }}
                  inputProps={inputProps}
                  // error={ole1 ? true : false}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={props.updatedTime.endTime}
                  onChange={(e) => {
                    console.log("handleTime End", e.target.value);
                    props.setUpdatedTime({
                      ...props.updatedTime,
                      endTime: e.target.value,
                    });
                  }}
                />
              </Grid>
              {console.log("check 1234", !props.openEventID?.includes("-"))}
              {!props.openEventID?.includes("-") && getUserRole(userData) === "supervisor" &&
              <>
              {!props.onTimeArgs?.start &&
              <Grid item lg={ 3 }>
              <Button disabled={props.approveStatus === "Y" ? true : false} variant="contained" onClick={() => {
                
                
                props.statusSubmit(props.openEventID,"Y")
                props.loadPreData()
                props.setOpen(false)
                }}>Approved</Button>
              </Grid>}
              {  !props.onTimeArgs?.start &&
              <Grid item lg={ 3 }>
              <Button variant="contained" disabled={props.approveStatus === "N" ? true : false} onClick={() => {
                props.statusSubmit(props.openEventID,"N")
                props.loadPreData()
                props.setOpen(false)


            }}>Rejected</Button>
              </Grid>}
              </>}
              <Grid item lg={ 12 }>
                {props.onTimeArgs?.start && props.creteButtonClick === true ? <Alert severity="error">Time confict with previous selected time. Please Correct it</Alert> : ""}
                
              </Grid>

            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              
          
          <Button onClick={handleClose}>Close</Button>
          {!props.onTimeArgs?.start ? 
          <Button onClick={ handleUpdateevent } autoFocus
                  disabled={eleDisabled('update')}
          
          > 
            Update
            </Button> : "" }
            {props?.creteButtonClick === true && props.onTimeArgs?.start ? 
          <Button onClick={() => props.correctionClick()}  autoFocus> 
            Correction
          </Button> : ""}
          {!props.onTimeArgs?.start ? 
          <Button onClick={ handleCopyevent } autoFocus
                  disabled={eleDisabled('copy')}
          >
            Copy
          </Button> : ""}
          {props.onTimeArgs?.start ? 
          <Button
            disabled={props.creteButtonClick === true ?true:false}
              onClick={ () => props.newEventCreateHandler(selectedCode, selectedLabel) } autoFocus>
            Create
          </Button> : ""}
          
        </DialogActions>
      </Dialog>
      {/* <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Event"
                  value={props.eventName}
                  onChange={handleChange}
                >
                  {props?.paytimeCodeList?.length
                    ? props?.paytimeCodeList?.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })
                    : ""}
                </Select>
              </FormControl>
          </Grid>
          <Grid lg={6}></Grid>
          
        </Box>
      </Modal> */}
    </div>
  );
};

export default EventModal;
