import React, { useState } from "react";
import { Col, Row, Label } from "reactstrap";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MenuItem } from "@material-ui/core";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAllEmployee } from "../../../Supervisor/CommonConfigrations/TableSlice";
import { floatNumberRegex, percentageRegex } from "../../../../app/utility/helper";
import dayjs from "dayjs";

function GarnishmentManage(props) {
    const menuItemListHeight = 300;
    const dispatch = useDispatch();
    const [employeePage, setEmployeePage] = useState(0);
    const { employeeTotalCount, allEmployee } = useSelector((state) => state.TableSlice);
    const [pctToDeductLabel, setPctToDeductLabel] = useState('Percent To Deduct');
    const [deductValueRegex, setDeductValueRegex] = useState(/^[0-9]*\.?[0-9]*$/);
    const [isOTError, setIsOTError] = useState(false);
    // const [pctBasisLabel, setPctBasisLabel] = useState('Percent Basis');

    const loadMoreResults = () => {
      const nextPage = employeePage + 1;
      if (allEmployee.length < employeeTotalCount) {
        setEmployeePage(nextPage);
        dispatch(GetAllEmployee(nextPage));
      }
    };

    const handleScroll = (event) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 1) {
        loadMoreResults();
      }
    };

    return (
        <Row style={{ width: "100%" }} className="g-3">
            {/* <Col md="4">
              <Autocomplete
                  disablePortal
                  margin="dense"
                  className="flex w-full"
                  style={{ width: "100%", marginTop: '2%' }}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                    },
                  }}
                  options={allEmployee || []}
                  // getOptionLabel={(opt) => opt ? `${opt?.label} - ${opt?.id}` : ''}
                  // getOptionLabel={(opt) => {
                  //   debugger
                  //   console.log(opt);
                  // }}
                  ListboxProps={{
                    onScroll: handleScroll
                  }}
                  value={props.detail?.employeeID}
                  onChange={(e, newValue) => { props.setDetail({ ...props.detail, employeeID: newValue?.id }) }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Employee" />
                  )}
              />
            </Col> */}
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    disabled
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                      maxLength: 12
                    }}
                    InputLabelProps={{ shrink: props?.paramsData?.state?.employeeId ? true : false }}
                    label="Employee Id"
                    value={`${props?.paramsData?.state?.employeeId} - ${props?.paramsData?.state?.employeeName}`}
                    name="employeeId"
                />
            </Col>
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                      maxLength: 12
                    }}
                    InputLabelProps={{ shrink: props.detail.employeeSeq ? true : false }}
                    label="Employee Sequence"
                    value={props.detail.employeeSeq}
                    onChange={(e) => {
                      if ((e.target.value === "" || floatNumberRegex.test(e.target.value)) && e.target.value?.length <= 12) {
                        props.setDetail({ ...props.detail, employeeSeq: e.target.value });
                      }
                    }}
                    name="employeeSeq"
                />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                disabled
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{ shrink: props.detail.deductionType ? true : false }}
                value={props.detail.deductionType}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionType: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Type"
              >
                {props.deductionTypes.map((option) => (
                    <MenuItem key={option.ID} value={option.Code}>
                      {option.Description} - {option.Code}
                    </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{ shrink: props.detail.deductionPlan ? true : false }}
                value={props.detail.deductionPlan}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionPlan: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Plan"
              >
                {props.deductionPlans.map((option) => (
                    <MenuItem key={option.id} value={option.deductionCode}>
                      {option.deductionName} - {option.deductionCode}
                    </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                      maxLength: 12
                    }}
                    InputLabelProps={{ shrink: props.detail.sequence ? true : false }}
                    label="Priority Sequence"
                    value={props.detail.sequence}
                    onChange={(e) => {
                      if ((e.target.value === "" || floatNumberRegex.test(e.target.value)) && e.target.value?.length <= 12) {
                        props.setDetail({ ...props.detail, sequence: e.target.value });
                      }
                    }}
                    name="sequence"
                />
            </Col>
            {/* <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                InputLabelProps={{ shrink: props.detail.garnishmentTypeId ? true : false }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.garnishmentTypeId}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    garnishmentTypeId: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Garnishment Type"
              >
                {props.garnishmentTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
              </TextField>
            </Col> */}
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                      maxLength: 20
                    }}
                    label='Account No. For Vendor'
                    InputLabelProps={{ shrink: props.detail.accountNoForVendor ? true : false }}
                    value={props.detail.accountNoForVendor}
                    onChange={(e) => {
                      props.setDetail({ ...props.detail, accountNoForVendor: e.target.value });
                    }}
                    name="accountNoForVendor"
                />
            </Col>
            <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Start Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.startDate)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      startDate: value,
                      endDate: null
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="End Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={dayjs(props.detail.endDate)}
                  minDate={dayjs(props.detail.startDate)}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      endDate: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                InputLabelProps={{ shrink: props.detail.paymentMonthlySch ? true : false }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.paymentMonthlySch}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    paymentMonthlySch: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Payment Options"
              >
                {props.paymentOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                InputLabelProps={{ shrink: props.detail.garnishmentMethod ? true : false }}
                style={{ width: "100%" }}
                size="small"
                value={props.detail.garnishmentMethod}
                onChange={(e) => {
                  if (e.target.value) {
                    props.setDetail({
                      ...props.detail,
                      garnishmentMethod: e.target.value,
                      pctToDeduct: ''
                    });
                    let temp = props?.garnishmentMethods?.find(o => o.id === e.target.value);
                    if (temp?.name === 'Pct' || temp?.id === 1) setPctToDeductLabel('Percent To Deduct');
                    if (temp?.name === 'Fixed' || temp?.id === 2) setPctToDeductLabel('Amount To Deduct');
                  }

                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Method"
              >
                {props.garnishmentMethods.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name} - {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                    }}
                    label={pctToDeductLabel}
                    InputLabelProps={{ shrink: props.detail.pctToDeduct ? true : false }}
                    // value={(pctToDeductLabel === 'Percent To Deduct') ? `${props.detail.pctToDeduct}%` : `$${props.detail.pctToDeduct}`}
                    value={props.detail.pctToDeduct}
                    onChange={(e) => {
                      const regex = deductValueRegex;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        props.setDetail({ ...props.detail, pctToDeduct: e.target.value });
                      }
                    }}
                    name="pctToDeduct"
                />
            </Col>
            {/* <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                    }}
                    label={pctBasisLabel}
                    InputLabelProps={{ shrink: props.detail.pctBasis ? true : false }}
                    value={props.detail.pctBasis}
                    onChange={(e) => {
                      props.setDetail({ ...props.detail, pctBasis: e.target.value });
                    }}
                    name="pctBasis"
                />
            </Col> */}
            <Col md="4">
                <TextField
                  margin="dense"
                  className="flex w-full "
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      color: "black",
                      paddingTop: "12px",
                    },
                    maxLength: 10
                  }}
                  label='Apply To OT'
                  error={isOTError}
                  helperText={isOTError ? 'Valid percentage!' : ''}
                  InputLabelProps={{ shrink: props.detail.applyToOT ? true : false }}
                  value={props.detail.applyToOT}
                  onChange={(e) => {
                    let str = e.target.value;
                    // let str = (Math.round(e.target.value * 100) / 100).toFixed(2);
                    // if (str?.length <= 12 && (str?.includes('.') && str?.split('.')[1]?.length <= 2)) {
                    if (str?.length <= 12) {
                      props.setDetail({ ...props.detail, applyToOT: str });
                    }
                    if (!percentageRegex.test(str)) setIsOTError(true);
                    else setIsOTError(false);
                  }}
                  name="applyToOT"
                />
            </Col>
            <Col md="4">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                      maxLength: 12
                    }}
                    label="Total Amount Paid"
                    InputLabelProps={{ shrink: props.detail.totalOriginal ? true : false }}
                    value={props.detail.totalOriginal}
                    onChange={(e) => {
                      if ((e.target.value === "" || floatNumberRegex.test(e.target.value)) && e.target.value?.length <= 12) {
                        props.setDetail({ ...props.detail, totalOriginal: e.target.value });
                      }
                    }}
                    name="totalOriginal"
                />
            </Col>
            <Col md="4">
                <TextField
                  margin="dense"
                  className="flex w-full "
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="small"
                  // disabled
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      color: "black",
                      paddingTop: "12px",
                    },
                    maxLength: 12
                  }}
                  label="Total Amount Plan Due"
                  InputLabelProps={{ shrink: props.detail.balanceDue ? true : false }}
                  value={props.detail.balanceDue}
                  onChange={(e) => {
                      if ((e.target.value === "" || floatNumberRegex.test(e.target.value)) && e.target.value?.length <= 12) {
                        props.setDetail({ ...props.detail, balanceDue: e.target.value });
                      }
                  }}
                  name="balanceDue"
                />
            </Col>
            {/* <Col md="4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  label="Paid To Date"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  value={props.detail.paidToDate}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      paidToDate: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Col> */}
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full"
                variant="outlined"
                select
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: menuItemListHeight },
                  }
                }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{ shrink: props.detail?.deductionPlanStatus ? true : false }}
                value={props.detail.deductionPlanStatus}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    deductionPlanStatus: e.target.value,
                  });
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Deduction Plan Status"
              >
                {props.deductionPlanStatusList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.displayName}
                    </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col md="2" className="isActiveAlignCenter">
              <Label className="labelColor isActiveLabel">Taxable</Label>
              <Checkbox
                checked={props.detail.taxable}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    taxable: !props.detail.taxable,
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Col>
            <Col md="2" className="isActiveAlignCenter">
              <Label className="labelColor isActiveLabel">Is Active</Label>
              <Checkbox
                checked={props.detail.isActive}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    isActive: !props.detail.isActive,
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Col>
        </Row>
    );
}

export default GarnishmentManage;