import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Switch, Typography } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
  Table,
} from "reactstrap";
import { H5, P } from "../../../AbstractElements";
import moment from "moment";
import { Row } from "antd";
import { getAllEmployeeDeductionPlan, getAllDeductionMethodList, getAllPaymentType, getAllDeductionPlanStatusList, getAllDeductionPlanMaster } from "../../../app/utility/axiosService";
import MUIDataTable from "mui-datatables";
import { useAuth } from "oidc-react";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatMoney, getUserRole } from "../../../app/utility/helper";
import { useNavigate } from "react-router-dom";

function EmployeeDeductionsDetails(props) {
  const { userInfo, type } = props;
  const { userData } = useAuth();
  const history = useNavigate();
  const role = getUserRole(userData);
  const [empGarnishments, setEmpGarnishments] = useState([]);
  const [deductionMethods, setDeductionMethods] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [deductionPlanStatusList, setDeductionPlanStatusList] = useState([]);
  const [deductionPlans, setDeductionPlans] = useState([]);
  const [takeCount, setTakeCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchAllEmployeeDeductionRecords();
    fetchAllDeductionMethod();
    fetchAllPaymentOption();
    fetchAllDeductionPlanStatusList();
    fetchAllDeductionPlans();
  }, []);

  useEffect(() => {
    fetchAllEmployeeDeductionRecords();
  }, [takeCount, skipCount, JSON.stringify(userInfo), userInfo?.ID]);
  
  const arrangeList = useCallback((list) => {
    return list.reduce(
      (acc, element) =>
        element.status === "Pending" ? [element, ...acc] : [...acc, element],
      []
    );
  }, []);

  // const { requestDetail } = props;
  const [filterBy, setFilter] = useState({
    ID: "",
    EmployeeName: "",
    SSN: "",
    Department: "",
    Status: "",
  });

  const [state, setState] = useState({
    filteredList: empGarnishments ? arrangeList(empGarnishments) : undefined,
  });

  // const { filteredList } = state;
  // const filterByRef = useRef(filterBy);

  useEffect(() => {
    if (empGarnishments) {
      setState((prevState) => ({
        ...prevState,
        filteredList: arrangeList(empGarnishments),
      }));
    }
  }, [empGarnishments]);

  const options = {
    selectableRows: false,
    display: false,
    download: false,
    print: false,
    viewColumns: false,
    count: totalCount,
    onChangeRowsPerPage: (number) => {
      setTakeCount(number);
    },
    onChangePage: (page) => {
      setSkipCount(takeCount*(page));
    }
  };

  const employeeGarnishmentColumn = [
    {
      id: "deductionType",
      name: "deductionType",
      label: "Deduction Type",
      options : {
				customBodyRender : (value, tableMeta, updateValue) => {
					return (
						<Typography component={'span'} noWrap={true}>
							{value}
						</Typography>
					)
				},
        // setCellProps: () => ({ style: { minWidth: "350px", maxWidth: "350px" }}),
			}
    },
    {
      id: "deductionCode",
      name: "deductionCode",
      label: "Deduction Code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (deductionPlans?.length > 0) {
            temp = deductionPlans.find(o => o.deductionCode == value);
          }
          return (
						<Typography component={'span'} noWrap={true}>
              {temp ? temp?.deductionName : ''}
            </Typography>
          ) 
        },
      },
    },
    {
      id: "prioritySeq",
      name: "prioritySeq",
      label: "Priority Seq",
    },
    {
      id: "paymentMonthlySch",
      name: "paymentMonthlySch",
      label: "Payment Option",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (paymentOptions?.length > 0) {
            temp = paymentOptions.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "deductionMethod",
      name: "deductionMethod",
      label: "Deduction Method",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (deductionMethods?.length > 0) {
            temp = deductionMethods.find(o => o.id == value);
          }
          return temp ? temp?.name : '';
        },
      },
    },
    {
      id: "deductionAmount",
      name: "deductionAmount",
      label: "Deduction Value",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {formatMoney(Number(value) ? Number(value) : 0)}
            </Typography>
          )
        },
      },
    },
    {
      id: 'accountNoForVendor',
      name: 'accountNoForVendor',
      label: 'Account No. For Vendor'
    },
    {
      id: "applyToOT",
      name: "applyToOT",
      label: "Apply To OT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}%
            </Typography>
          )
        },
      },
    },
    {
      id: "startDate",
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value ? moment(value).format("MM-DD-YYYY") : ''}
						</Typography>
          )
        },
      },
    },
    {
        id: "endDate",
        name: "endDate",
        label: "End Date",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Typography component={'span'} noWrap={true}>
                {value ? moment(value).format("MM-DD-YYYY") : ''}
              </Typography>
            )
          },
        },
    },
    {
        id: "totalAmountPaid",
        name: "totalAmountPaid",
        label: "Total Amount Paid",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Typography component={'span'} noWrap={true}>
                {formatMoney(Number(value) ? Number(value) : 0)}
              </Typography>
            )
          },
        },
    },
    {
        id: "totalAmountPlanDue",
        name: "totalAmountPlanDue",
        label: "Total Amount Plan Due",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Typography component={'span'} noWrap={true}>
                {formatMoney(Number(value) ? Number(value) : 0)}
              </Typography>
            )
          },
        },
    },
    {
      id: "deductionPlanStatus",
      name: "deductionPlanStatus",
      label: "Deduction Plan Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let temp = null;
          if (deductionPlanStatusList?.length > 0) {
            temp = deductionPlanStatusList.find(o => o.id == value);
          }
          return temp ? temp?.displayName : '';
        },
      },
    },
    {
        id: "isTaxable",
        name: "isTaxable",
        label: "Is Taxable",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return <Switch
                checked={value ? true : false}
                disableRipple
                onChange={(event) => {
                  const value = event.target.checked;
                }}
                color="primary"
                className="align-top"
              />;
            },
        },
    },
    {
        id: "isActive",
        name: "isActive",
        label: "Is Active",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            return <Switch
                checked={value ? true : false}
                disableRipple
                onChange={(event) => {
                  const value = event.target.checked;
                }}
                color="primary"
                className="align-top"
              />;
            },
        },
    },
  ];

  const fetchAllEmployeeDeductionRecords = async () => {
    if (userInfo?.ID) {
      const res = await getAllEmployeeDeductionPlan(userInfo?.ID, type);
      if (res.status === 200) {
        setEmpGarnishments(res.data);
        setTotalCount(res.totalCount);
      }
    }
  };

  const fetchAllDeductionPlans = async () => {
    const res = await getAllDeductionPlanMaster();
    if (res?.status === 200) {
      setDeductionPlans(res?.data);
    }
  };

  const fetchAllDeductionMethod = async () => {
    const res = await getAllDeductionMethodList();
    if (res?.length > 0) setDeductionMethods(res);
  };

  const fetchAllDeductionPlanStatusList = async () => {
    const res = await getAllDeductionPlanStatusList();
    if (res?.length > 0) setDeductionPlanStatusList(res);
  };

  const fetchAllPaymentOption = async () => {
    const res = await getAllPaymentType();
    if (res?.status === 200) setPaymentOptions(res.data);
  };

  const handleAddEmployeeDeduction = () => {
      let getPath = type === "GARNISHMENT" ? "garnishment" :
      type === "BENEFITS" ? "benefit" :
      type === "MISCELLANEOUS" ? "miscellaneous" :
      type === "RETIREMENT-PLANS" ? "retirement-plan" : '';
      return history(`/master/${getPath}/update/${Number(userInfo?.ID)}`, { state: { employeeId: Number(userInfo?.ID), employeeName: userInfo?.EmployeeName } });
  };

  return (
    <>
      <Card>
        <CardHeader className="pb-0">
          <Row align='middle' justify='space-between'>
            <H5>{(type === 'GARNISHMENT') ? 'Garnishments' : (type === 'BENEFITS') ? 'Benefits' : (type === 'MISCELLANEOUS') ? 'Miscellaneous' : 'Deduction'}</H5>
            {role === 'admin' &&
              <Button
                  variant="contained"
                  className="m-10 mx-2 xs:mx-10 min-w-0"
                  style={{
                  color: "#234d10",
                  backgroundColor: "#ffffff",
                  }}
                  onClick={handleAddEmployeeDeduction}
              >
                  <FontAwesomeIcon
                  icon={faPlus}
                  style={{ color: "#24695c", marginRight: "10px" }}
                  />
                  <Typography className="text-12">
                  {" "}
                  {`Add ${(type === 'GARNISHMENT') ? 'Garnishments' : (type === 'BENEFITS') ? 'Benefits' : (type === 'MISCELLANEOUS') ? 'Miscellaneous' : 'Deduction'}`}{" "}
                  </Typography>
              </Button>
            }
          </Row>
        </CardHeader>

        <CardBody>
          <div style={{ width: "100%" }}>
            <MUIDataTable
              className='empCertificationDataTable'
              // title={<Typography variant="h6">Employee Certification Data</Typography>}
              data={empGarnishments}
              columns={employeeGarnishmentColumn}
              options={options}
              selectableRowsHideCheckboxes={true}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default EmployeeDeductionsDetails;