import React, { Fragment, useEffect, useState } from "react";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Autocomplete, TextField } from "@mui/material";
import { Box } from "react-feather";
import { Col, Row } from "reactstrap";
import "../../../styles/custom.css";

const useStyles = makeStyles((theme) => ({
  listHeight: {
    height: "calc(100vh - 100px - 317px)",
    maxHeight: "calc(100vh - 100px - 317px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 180px)",
      maxHeight: "calc(100vh - 100px - 180px)",
    },
  },
  listHeightError: {
    height: "calc(100vh - 100px - 256px)",
    maxHeight: "calc(100vh - 100px - 256px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 239px)",
      maxHeight: "calc(100vh - 100px - 239px)",
    },
  },
}));

const DepartmetChips = (props) => {
  const classes = useStyles();
  // const { values: detail, handleChange, touched, errors } = props;
  const [values, setValues] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [positionList, setPositionList] = useState([]);

  useEffect(() => {
    console.log(
      "Item 121212 selected ::: ",
      props.selectedDepts,
      props.DPDVList
    );
    let newArray = props.DPDVList.map((item) => {
      return {
        id: item.id,
        key: item.value,
        value: item.label,
        label: item.label,
      };
    });
    setPositionList(newArray);
    if (props.selectedDepts?.length) {
      let newArray1 = props.selectedDepts.map((item) => {
        return {
          id: item.id,
          key: item.value,
          value: item.label,
          label: item.label,
        };
      });
      setSelectedPositions(newArray1);
    }
  }, [props.DPDVList, props.selectedDepts]);

  const handleChange = (e) => {
    if (e.target.name === "positionList") {
      const arr = values.positionList || [];
      const index = arr.indexOf(e.target.value);
      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(e.target.value);
      }
      setValues({
        ...values,
        [e.target.name]: arr,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <div className="  w-full flex-col">
      <TextField
        margin="dense"
        className="flex w-full "
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        inputProps={{
          style: {
            fontSize: "14px",
            color: "black",
            paddingTop: "12px",
          },
        }}
        label="Find Departments"
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />

      <div className="d-flex align-content-start flex-wrap">
        {positionList
          .filter((item) => {
            return (
              selectedPositions.find((d) => d.value === item.value) != null
            );
          })
          .map((i, index) => {
            return (
              <div className="p-1">
                <input
                  type="checkbox"
                  class="btn-check font-primary"
                  value={i.value}
                  tabIndex={-1}
                  name="positionList"
                  id={i.value}
                  onClick={() => {
                    let selectedItems = [...selectedPositions];
                    if (
                      selectedItems.find((d) => d.value === i.value) != null
                    ) {
                      let itemToDelete = selectedItems.find(
                        (data) => data.value === i.value
                      );
                      console.log("Item 121212 000 ::: ", itemToDelete);
                      if (itemToDelete.id) {
                        props.handleRemove(itemToDelete.key, itemToDelete.id);
                      }
                      selectedItems = selectedItems.filter(
                        (d) => d.value !== i.value
                      );
                    } else {
                      selectedItems.push({
                        id: i.id,
                        key: i.key,
                        value: i.value,
                        label: i.label,
                      });
                    }
                    console.log("Item 121212 111::: ", i, selectedItems);
                    setSelectedPositions([...selectedItems]);
                    let newArray = selectedItems.map((item) => {
                      return {
                        id: item.id,
                        value: item.key,
                        label: item.value,
                      };
                    });
                    props.setSelectedDepts(newArray);
                    handleChange({
                      target: { value: i.value, name: "positionList" },
                    });
                  }}
                  checked={
                    selectedPositions.find((d) => d.value === i.value) != null
                  }
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary chipbtnchecked"
                  for={i.value}
                >
                  {i.value}
                </label>
              </div>
            );
          })}
        {positionList
          .filter((item) => {
            return (
              selectedPositions.find((d) => d.value === item.value) == null &&
              item.value.toLowerCase().indexOf(filterText.toLocaleLowerCase()) >
                -1
            );
          })
          .map((i, index) => {
            return (
              <div className="p-1">
                <input
                  type="checkbox"
                  class="btn-check font-primary"
                  value={i.value}
                  tabIndex={-1}
                  name="positionList"
                  id={i.value}
                  onClick={() => {
                    let selectedItems = [...selectedPositions];
                    if (selectedItems.find((d) => d.value === i.value) != null)
                      selectedItems = selectedItems.filter(
                        (d) => d !== i.value
                      );
                    else selectedItems.push(i);
                    setSelectedPositions([...selectedItems]);
                    console.log("Item 121212 222::: ", i);

                    let newArray = selectedItems.map((item) => {
                      return {
                        id: item.id,
                        value: item.key,
                        label: item.value,
                      };
                    });
                    props.setSelectedDepts(newArray);
                    handleChange({
                      target: { value: i.value, name: "positionList" },
                    });
                  }}
                  checked={
                    selectedPositions.find((d) => d.value === i.value) != null
                  }
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary chipbtn"
                  for={i.value}
                >
                  {i.value}
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DepartmetChips;