import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Media,
} from "reactstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function PayCodeManagement(props) {
  const [selectedDate, handleDateChange] = useState(new Date());
  const [topLevelDark, setTopLevelDark] = React.useState(false);

  console.log("top-------", props.detail);
  return (
    <div className="w-full h-full">
      <div className="flex flex-col sm:flex-row w-full py-4 mt-10 ">
        <Form>
          <Row className="g-3  ">
            <Col md="2">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Pay Code"
                value={props.detail.code}
                onChange={(e) => {
                  props.setDetail({ ...props.detail, code: e.target.value });
                }}
              />
              {/* <Label className="labelColor">Pay Code</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Employee Number"
                value={props.detail.code}
                label="Pay Code"
                onChange={(e) => {
                  props.setDetail({ ...props.detail, code: e.target.value });
                }}
              /> */}
            </Col>
            <Col md="2">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Abbreviation"
                value={props.detail.abbreviation}
                // defaultValue={""}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    abbreviation: e.target.value,
                  });
                }}
              />
              {/* <Label className="labelColor">Abbreviation</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Abbreviation"
                value={props.detail.abbreviation}
                // defaultValue={""}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    abbreviation: e.target.value,
                  });
                }}
              /> */}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "11px",
                  },
                }}
                label="Description"
                value={props.detail.description}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    description: e.target.value,
                  });
                }}
              />
              {/* <Label className="labelColor">Description</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Description"
                value={props.detail.description}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    description: e.target.value,
                  });
                }}
              /> */}
            </Col>
            <Col md="2">
              {console.log("value date api", props.detail.effectDate)}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  label="Effect Date"
                  value={props.detail.effectDate}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    console.log("value date", value);
                    props.setDetail({
                      ...props.detail,
                      effectDate: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="labelColor">Effect Date</Label>

              <input
                className="form-control"
                type="date"
                format="MM/dd/yyyy"
                value={props.detail.effectDate}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    effectDate: e.target.value,
                  });
                }}
                placeholder="Effect Date"
                name="date"
              /> */}
            </Col>
            <Col md="2">
              {console.log("props.detail.expireDate", props.detail.expireDate)}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  label="Expire Date"
                  value={props.detail.expireDate}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");
                    props.setDetail({
                      ...props.detail,
                      expireDate: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="labelColor">Expire Date</Label>
              <input
                className="form-control"
                type="date"
                data-date-formTarget="DD MMMM YYYY"
                format="MM/dd/yyyy"
                value={props.detail.expireDate}
                onChange={(e) => {
                  console.log("date", e.target.value);
                  props.setDetail({
                    ...props.detail,
                    expireDate: e.target.value,
                  });
                }}
                placeholder="Expire Date"
                name="date"
              /> */}
            </Col>
            <Col md="1">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Multiply"
                value={props.detail.multi}
                onChange={(e) => {
                  props.setDetail({ ...props.detail, multi: e.target.value });
                }}
              />
              {/* <Label className="labelColor">Multiply</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Multiply"
                value={props.detail.multi}
                onChange={(e) => {
                  props.setDetail({ ...props.detail, multi: e.target.value });
                }}
              /> */}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default PayCodeManagement;
