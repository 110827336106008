import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "OverTimeSlice";

export const GetEmployeeAuthorizedPayrollData = createAsyncThunk(
  `${sliceName}/GetEmployeeAuthorizedPayrollData`,
  async (payload) => {
    try {
      const { payPeriodId, employeeId } = payload;
      const data = await axios.post(
        `${process.env.REACT_APP_WEB_API_URL}Payroll/GetEmployeeAuthorizedPayrollData`,
        {
          ppe: payPeriodId,
          employeeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return data.data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const OverTimeSlice = createSlice({
  name: sliceName,
  initialState: {
    isVisibleOTTable: false,
    employeeAuthorizedPayTimeCodesList: []
  },
  reducers: {
    setIsVisibleOTTable(state, action) {
      return {
        ...state,
        isVisibleOTTable: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEmployeeAuthorizedPayrollData.pending, (state, action) => {
        state.employeeAuthorizedPayTimeCodesList = [
          {
              "value": "REG",
              "label": "REG",
              "title": "REGULAR                            ",
              "code": "1",
              "multi": 1,
              "EffectDate": "1992-02-12T18:30:00.000Z",
              "ExpireDate": "2099-12-29T18:30:00.000Z",
              "color": "#00FF00",
              "name": "jobtype",
              "OrderNo": "1"
          },
          {
              "value": "A/L",
              "label": "A/L",
              "title": "ANNUAL LEAVE                       ",
              "code": "2",
              "multi": 1,
              "EffectDate": "1990-01-01T00:00:00.000Z",
              "ExpireDate": "2099-12-31T00:00:00.000Z",
              "color": "#000",
              "name": "jobtype",
              "OrderNo": "1017"
          },
          {
              "value": "S/L",
              "label": "S/L",
              "title": "SICK LEAVE                         ",
              "code": "3",
              "multi": 1,
              "EffectDate": "1990-01-01T00:00:00.000Z",
              "ExpireDate": "2099-12-31T00:00:00.000Z",
              "color": "#000",
              "name": "jobtype",
              "OrderNo": "1016"
          },
          {
              "value": "O/T1.5",
              "label": "O/T1.5",
              "title": "OVERTIME 1.5                         ",
              "code": "6",
              "multi": 1.50,
              "EffectDate": "2021-08-11T00:00:00",
              "ExpireDate": "2026-08-11T00:00:00",
              "color": "#000",
              "name": "jobtype",
              "OrderNo": "1016"
          }
          
      ];
      })
      .addCase(GetEmployeeAuthorizedPayrollData.fulfilled, (state, action) => {
        return ({
          ...state,
          employeeAuthorizedPayTimeCodesList: action.payload?.employeeAuthorizedPayTimeCodesList
        })
      })
      .addCase(GetEmployeeAuthorizedPayrollData.rejected, (state, action) => {
        state.employeeAuthorizedPayTimeCodesList = [];
      })
  },
});

export const { setIsVisibleOTTable } = OverTimeSlice.actions;
export default OverTimeSlice.reducer;
