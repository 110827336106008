import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../_helper/api_helper";
import { toast } from "react-toastify";

const sliceName = "employeeDeductionPlan";

export const createOrUpdateEmployeeDeductionPlanMaster = createAsyncThunk(
    "createOrUpdateEmployeeDeductionPlanMaster",
    async (payload) => {
        try {
            let response = await post(`${process.env.REACT_APP_WEB_API_URL}EmployeeDeductionPlanMaster/CreateOrUpdate`, payload);
            toast.success(`${payload?.id ? 'Updated' : 'Created'} successfully`);
            return { createResult: response?.data };
        } catch (error) {
            toast.error(`Invalid data!`);
            return { error }
        }
    }
);

export const getEmployeeDeductionPlanById = createAsyncThunk(
    'getEmployeeDeductionPlanById',
    async (id) => {
        try {
            let response = await get(`${process.env.REACT_APP_WEB_API_URL}EmployeeDeductionPlanMaster/GetById?Id=${id}`);
            return { data: response.data };
        } catch (error) {
            return { error }
        }
    }
);

export const resetEmployeeDeductionPlan = createAsyncThunk('resetEmployeeDeductionPlan', async () => {
    return {}
});

const employeeDeductionsPlanlice = createSlice({
    name: sliceName,
    initialState: {
        createResult: {},
        isCreating: false,
        createError: {},
        isFetching: false,
        employeeDeductionPlan: {},
        error: {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrUpdateEmployeeDeductionPlanMaster.pending, (state) => { state.isCreating = true; })
            .addCase(createOrUpdateEmployeeDeductionPlanMaster.fulfilled, (state, action) => {
                state.createResult = action.payload?.createResult;
                state.isCreating = false;
            })
            .addCase(createOrUpdateEmployeeDeductionPlanMaster.rejected, (state, action) => {
                state.createResult = {};
                state.createError = action.payload?.error;
                state.isCreating = false;
            })
            .addCase(getEmployeeDeductionPlanById.pending, (state) => { state.isFetching = true; })
            .addCase(getEmployeeDeductionPlanById.fulfilled, (state, action) => {
                state.employeeDeductionPlan = action.payload?.data;
                state.isFetching = false;
            })
            .addCase(getEmployeeDeductionPlanById.rejected, (state, action) => {
                state.employeeDeductionPlan = {};
                state.error = action.payload?.error;
                state.isFetching = false;
            })
            .addCase(resetEmployeeDeductionPlan.fulfilled, (state, action) => {
                state.employeeDeductionPlan = {};
                state.isFetching = false;
            });
    },
});
export default employeeDeductionsPlanlice.reducer;
