import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

function FilterList(props) {
  const { changeHeaderFilter, filterBy } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Id"
          name="id"
          value={filterBy.id}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="name"
          placeholder="Name"
          value={filterBy.name}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell>

    </TableRow>
  );
}

export default FilterList;