import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

function FilterList(props) {
  const { changeHeaderFilter, filterBy } = props;

  return (
    <TableRow>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee Id"
          name="employeeId"
          value={filterBy.employeeId}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee Name"
          name="name"
          value={filterBy.name}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="totalAmount"
          placeholder="Total Amount"
          value={filterBy.totalAmount}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Amount"
          name="deductionAmount"
          value={filterBy.deductionAmount}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="withheldTax"
          placeholder="Withheld Tax"
          value={filterBy.withheldTax}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="netPay"
          placeholder="Net Pay"
          value={filterBy.netPay}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      {/* <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell> */}

    </TableRow>
  );
}

export default FilterList;