import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";
import { Label } from "reactstrap";
import { withStyles } from "@material-ui/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";

// const darkTheme = createTheme({
//   palette: {
//     type: "dark",
//   },
// });

// const lightTheme = createTheme({
//   palette: {
//     type: "light",
//   },
// });
// import MaskedInput from "react-text-mask";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(TextField);
const CssTextField1 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },

      "& .MuiInputBase-root": {
        height: (params) => (params.height ? params.height : "auto"),
        color: (params) => (params.color ? params.color : "inherit"),
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(KeyboardDatePicker);
function RequestFilter(props) {
  const { changeHeaderFilter, role, requestDetail, filterBy } = props;
  const [topLevelDark, setTopLevelDark] = React.useState(false);
  const theme = localStorage.getItem("mix_background_layout");
  useEffect(() => {
    if (theme === "dark-only") {
      setTopLevelDark(true);
    } else {
      setTopLevelDark(false);
    }
  }, [theme]);
  console.log("fiter", filterBy);
  return (
    // <MuiThemeProvider theme={topLevelDark ? lightTheme : darkTheme}>
    <TableRow>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Pay Step"
          name="payStep"
          value={filterBy && filterBy.payStep}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      {role !== "user" && (
        <TableCell size="small" className="py-6 px-8">
          <input
            className="form-control"
            type="text"
            placeholder="Pay Grade"
            name="payGrade"
            value={filterBy && filterBy.payGrade}
            onChange={(event) => changeHeaderFilter(event)}
          />
        </TableCell>
      )}
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Hourley Rate"
          name="hourleyRate"
          value={filterBy && filterBy.hourleyRate}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="Salary"
          name="salary"
          value={filterBy && filterBy.salary}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="text"
          placeholder="DESCR"
          name="DESCR"
          value={filterBy && filterBy.DESCR}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="date"
          format="MM/dd/yyyy"
          onChange={(event) => {
            let value = event.target.value;
            console.log("effect", value);
            changeHeaderFilter({ target: { name: "EffectDate", value } });
          }}
          value={filterBy && filterBy.EffectDate}
          placeholder="Effect Date"
          // name="date"
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8">
        <input
          className="form-control"
          type="date"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "ExpireDate", value } });
          }}
          value={filterBy && filterBy.ExpireDate}
          placeholder="Expire Date"
          name="date"
        />
      </TableCell>
    </TableRow>
    // </MuiThemeProvider>
  );
}

export default RequestFilter;
