import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@mui/material/TextField";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import React from "react";
import { Label } from "reactstrap";

function DepartMentFilterList(props) {
  const { changeHeaderFilter, role, requestDetail, filterBy } = props;

  return (
    <TableRow>
      <TableCell
        size="small"
        className="py-6 px-8 page-body-wrapper"
      ></TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Department"
          name="Department"
          value={filterBy.DEPT_NAME}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="EMP CNT"
          name="EMP_CNT"
          value={filterBy.EMP_CNT}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
    </TableRow>
  );
}

export default DepartMentFilterList;
