import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@mui/material/TextField";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import React from "react";
import { Label } from "reactstrap";

function EmployeeFilterList(props) {
  const { changeHeaderFilter1, role, requestDetail, filterBy } = props;

  return (
    <TableRow>
      <TableCell
        size="small"
        className="py-6 px-8 page-body-wrapper"
      ></TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee ID"
          name="EmployeeID"
          value={filterBy.EmployeeID}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="EmployeeName"
          placeholder="EmployeeName"
          value={filterBy.EmployeeName}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="DPDV_NAME"
          placeholder="DPDV NAME"
          value={filterBy.DPDV_NAME}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="PPE"
          placeholder="PPE"
          value={filterBy.PPE}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="WorkingHours"
          placeholder="Working Hours"
          value={filterBy.WorkingHours}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell>
      {/* <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="Emp_Status"
          placeholder="Emp Status"
          value={filterBy.Emp_Status}
          onChange={(event) => changeHeaderFilter1(event)}
        />
      </TableCell> */}
    </TableRow>
  );
}

export default EmployeeFilterList;
