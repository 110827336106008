import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { IconButton, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ShowError, ShowSucess } from "../../../app/utility/helper";
import { useAuth } from "oidc-react";
import { getUserId, getUserRole } from "../../../app/utility/helper";
import TimeSheetHeader from "../PayCodeTemplate/header";

const inputProps = {
  style: { fontSize: 14, step: 300 },
  step: 300,
};
function PayCodeTemplateDetailsUpdate() {
  const paramsID = useParams();

  console.log("paramsID", paramsID.id);
  const location = useLocation();
  const history = useNavigate();
  console.log("location.state", location.state);
  const { userData } = useAuth();
  const userId = getUserId(userData);
  const userRole = getUserRole(userData);

  const [payCodeList, setPayCodeList] = useState([]);
  console.log("payCodeList", payCodeList);
  const [paramsIds, setParamsIds] = useState(null);
  const [inputFields, setInputFields] = useState([
    {
      payCodeId: "",
      payCodeAbberv: "",
    },
  ]);

  const [templateTitle, setTemplateTitle] = useState();

  const getData = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}typecode/get/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode1", response.data);
        setTemplateTitle(location.state.TypeCodeName);

        // setTableList(response.data);
        let newArray = [];

        if (response.data.result.length) {
          response.data.result.map((i, index) => {
            newArray.push({
              value: index,
              payCodeId: i.Code,
              payCodeAbberv: i.Abberv,
            });
          });
        } else {
          newArray.push({
            value: 0,
            payCodeId: "",
            payCodeAbberv: "",
          });
        }

        console.log("newArray", newArray);
        setInputFields(newArray);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getDataOption = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Added successfully!",
        //     variant: "success",
        //   })
        // );
        console.log("Master timecode", response.data);
        // setTableList(response.data);
        let newArray =
          response.data.length &&
          response.data.map((item, index) => {
            return {
              value: index,
              payCodeId: item.Code,
              payCodeAbberv: item.Abberv,
            };
          });
        setPayCodeList(newArray);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getDataOption();
  }, []);
  useEffect(() => {
    if (paramsID.id) {
      getData(paramsID.id);
    }
  }, [paramsID.id]);

  useEffect(() => {
    if (paramsID.id) {
      setParamsIds(paramsID.id);
    }
  }, [paramsID.id]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      { value: "", payCodeId: "", payCodeAbberv: "" },
    ]);
  };

  useEffect(() => {
    console.log("data ::: ", location.state);
    if (!location.state) {
      return false;
    }
    // if (location.state) {
    //   let data = location.state;
    //   let newSheetData = data?.TimeSheetProfiles;
    //   let newArray = [];
    //   newSheetData.length > 0 &&
    //     newSheetData.map((obj) => {
    //       newArray.push({
    //         selectedDay: obj.Day,
    //         time_from: obj.From && moment(obj.From).utc().format("HH:mm"),
    //         time_to: obj.To && moment(obj.To).utc().format("HH:mm"),
    //       });
    //     });
    //   setTemplateTitle(data?.TimeSheetName);
    //   setInputFields(
    //     newArray.length > 0
    //       ? newArray
    //       : [{ selectedDay: "", time_from: "", time_to: "" }]
    //   );
    // }
  }, [location]);

  const removeInputFields = (data, index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, data, key) => {
    console.log("handlse change", index, data, key);

    let inputData = [...inputFields];
    inputData[index] = data;

    console.log("handlse change final", inputData);
    setInputFields(inputData);

    // setInputFields([{ ...inputFields, selectedDay: data }]);
  };

  const addTimeSheet = () => {
    if (!templateTitle || templateTitle === "") {
      ShowError("Please Enter Title Value!");
      return false;
    }

    let newData = [];
   

    inputFields.map((obj) => {
      newData.push({
        PayCode: obj.payCodeId.payCodeId,
      });
    });

    console.log("Data sheet add : ", newData, templateTitle);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}typecode/create`,
        {
          TypeCodeName: templateTitle,
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            selectedDay: "",
            time_from: "",
            time_to: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/paycode-template`);
        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const updateTimeSheet = () => {
    let newData = [];
    console.log("inputFields", inputFields);
    inputFields.map((obj) => {
      newData.push({
        PayCode: `${parseInt(obj.payCodeId)}`,
      });
    });

    console.log("inputFields final ", newData);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}typecode/update`,
        {
          TypeCodeName: templateTitle,
          TypeCodeID: parseInt(paramsID.id),
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        setInputFields([
          {
            payCodeId: "",
            payCodeAbberv: "",
          },
        ]);
        setTemplateTitle("");
        ShowSucess(response.data.message);
        history(`/master/paycode-template`);

        console.log("Master responsexxxx", response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  return (
    <div>
      <TimeSheetHeader screen={"details"} data={location.state} />
      <div style={{ padding: "16px 2px" }}>
        <Card style={{ gap: "10px" }} elevation={4} className=" p-10  ">
          {/* <h3>TimeSheet Template</h3> */}
          <TextField
            size={"small"}
            id="time"
            label="Template Title"
            // placeholder="Template Title"
            type="text"
            sx={{ width: "250px" }}
            inputProps={inputProps}
            value={templateTitle}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
              shrink: true,
            }}
            onChange={(e) => {
              // handleChange(index, e.target.value, "time_from");
              console.log("index eeee", e);
              setTemplateTitle(e.target.value);
            }}
            margin="dense"
            name="template_title"
            fullWidth={true}
            variant="outlined"
            hiddenLabel="true"
          />

          <table style={{ width: "fit-content" }}>
            <tr>
              <td>Pay Code</td>
              {/* <td>From Time</td>
              <td>To Time</td> */}
            </tr>
            {inputFields.map((data, index) => {
              const { fullName, emailAddress, salary } = data;
              return (
                <tr>
                  <td>
                    <Autocomplete
                      value={{
                        payCodeAbberv: inputFields[index].payCodeAbberv,
                      }}
                      // value={"REG"}

                      size="small"
                      onChange={(event, newValue) => {
                        console.log("newValue", newValue);
                        handleChange(index, newValue, "payCodeId");
                        // console.log("Clicked... ", event.target.value, newValue);
                        // setInputFields({ ...inputFields, selectedDay: newValue });
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      // id="free-solo-with-text-demo"
                      id="controllable-states-demo"
                      options={payCodeList}
                      getOptionLabel={(option) => {
                        console.log("12331233 ::: ", option);
                        return option.payCodeAbberv;
                      }}
                      // renderOption={(props, option) => (
                      //   console.log("props option", props, option),
                      //   (<li {...props}>{option}</li>)
                      // )}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose PayCode"
                          value={"1"}
                        />
                      )}
                    />
                  </td>
                  {/* <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      // error={ole1 ? true : false}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_from}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_from");
                        console.log("index eeee", index, e);
                      }}
                      margin="dense"
                      name="time_from"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td>
                  <td>
                    <TextField
                      size={"small"}
                      id="time"
                      // label="From"
                      type="time"
                      sx={{ width: "200px" }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                        },
                        shrink: true,
                      }}
                      value={inputFields[index].time_to}
                      onChange={(e) => {
                        handleChange(index, e.target.value, "time_to");
                      }}
                      margin="dense"
                      name="time_to"
                      fullWidth={true}
                      variant="outlined"
                      hiddenLabel="true"
                    />
                  </td> */}
                  <td>
                    {inputFields.length !== index + 1 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => removeInputFields(data, index)}
                      >
                        x
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-success"
                        onClick={addInputField}
                      >
                        +
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>

          <div className="row">
            <div className="col-sm-12">
              {userRole === "admin" ||
                (userRole === "supervisor" && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={updateTimeSheet}
                    style={{
                      textTransform: "none",
                      color: "white",
                    }}
                    className="bg-primary"
                  >
                    Update Template
                  </Button>
                ))}
              {/* </>
                )} */}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default PayCodeTemplateDetailsUpdate;
