import { TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import * as yup from "yup";
import "./formvalidation.css";

let schema = yup.object({
  code: yup.string().required(),
  description: yup.string().required(),
});

let userSchema = yup.object({
  name: yup.string().required(),
  surname: yup.string().required(),
});

// parse and assert validity

function AddForm(props) {
  const { handleChange, values, errors, touched } = props;
  console.log("props.details", props.detail);

  // const verifyCode = async () => {
  //   const user = await userSchema.validate({ name: "", surname: "" });
  //   console.log("user", user);
  //   console.log("Xip");
  //   try {
  //     schema
  //       .validate([
  //         { code: props.detail.code, description: props.detail.description },
  //       ])
  //       .then((valid) => {
  //         console.log(
  //           props.detail.code,
  //           "Yup",
  //           valid,
  //           "#",
  //           props.detail.description
  //         ); // true
  //       })
  //       .catch((e) => {
  //         console.log("e", e);
  //       });
  //   } catch (error) {
  //     console.log("e", error);
  //   }
  // };

  return (
    <form autoComplete="off" style={{ width: "100%" }}>
      <Row style={{ width: "100%" }} className="mt-2 g-3">
        <Col md={6}>
          <TextField
            margin="dense"
            // className="flex w-full "
            // className={
            //   errors.EMPL_TYPE && touched.EMPL_TYPE ? "input-error" : ""
            // }
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            label="Employee Type Code"
            // defaultValue={props.detail.EMPL_TYPE}
            onChange={handleChange}
            value={values?.EMPL_TYPE}
            // onChange={(e) => {
            //   props.setDetail({ ...props.detail, EMPL_TYPE: e.target.value });
            //   // verifyCode();
            // }}
            // onBlur={handleBlur}
            name="EMPL_TYPE"
            id="EMPL_TYPE"
            type="EMPL_TYPE"
          />

          {errors.EMPL_TYPE && touched.EMPL_TYPE && (
            <p className="error">{errors.EMPL_TYPE}</p>
          )}

          {/* <Label className="labelColor">Deduction Code</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Code"
          name="code"
          value={props.detail.code}
          onChange={(e) => {
            props.setDetail({ ...props.detail, code: e.target.value });
            // verifyCode();
          }}
        /> */}
        </Col>

        <Col md={6}>
          <TextField
            margin="dense"
            // className="flex w-full "
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: "14px",
                color: "black",
                paddingTop: "12px",
              },
            }}
            label="Description"
            // value={props.detail.DESCRIPTION}
            // onChange={(e) => {
            //   props.setDetail({
            //     ...props.detail,
            //     DESCRIPTION: e.target.value,
            //   });
            //   // verifyCode();
            // }}
            // name="DESCRIPTION"
            name="DESCRIPTION"
            value={values?.DESCRIPTION}
            onChange={handleChange}
            id="DESCRIPTION"
            type="DESCRIPTION"
            placeholder="Enter description"
            max="15"
            // onBlur={handleBlur}
            // className={
            //   errors.DESCRIPTION && touched.DESCRIPTION ? "input-error" : ""
            // }
          />
          {errors.DESCRIPTION && touched.DESCRIPTION && (
            <div className="error">{errors.DESCRIPTION}</div>
          )}

          {/* <Label className="labelColor">Description</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Description"
          name="description"
          value={props.detail.description}
          onChange={(e) => {
            props.setDetail({
              ...props.detail,
              description: e.target.value,
            });
            verifyCode();
          }}
        /> */}
        </Col>
      </Row>
    </form>
    // <div className="w-full h-full" style={{ width: "100%" }}>
    //   <div className="flex flex-col sm:flex-row w-full py-4 mt-10">
    //     <Grid container spacing={2}>
    //       <Grid item xs={12} sm={12} md={6} lg={6}>
    //         <TextField
    //           style={{ width: "100%" }}
    //           className="flex w-full mx-10"
    //           variant="outlined"
    //           margin="dense"
    //           size="small"
    //           value={props.detail.code}
    //           label="Deduction Code"
    //           onChange={(e) => {
    //             props.setDetail({ ...props.detail, code: e.target.value });
    //           }}
    //         ></TextField>
    //       </Grid>

    //       <Grid item xs={12} sm={12} lg={6}>
    //         <TextField
    //           style={{ width: "100%" }}
    //           className="flex w-full "
    //           margin="dense"
    //           variant="outlined"
    //           label="Description"
    //           size="small"
    //           value={props.detail.description}
    //           onChange={(e) => {
    //             props.setDetail({
    //               ...props.detail,
    //               description: e.target.value,
    //             });
    //           }}
    //         />
    //       </Grid>
    //     </Grid>
    //   </div>
    // </div>
  );
}

export default AddForm;
