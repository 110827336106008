import { makeStyles } from "@material-ui/core/styles";

import React, { useEffect, useRef, useState } from "react";

import Header from "./component/header";
// import EmployeeInfo from "./component/employeeInfo";
import { connect, useDispatch, useSelector } from "react-redux";

import DeductionSettings from "./component/deductionSettings";
import { useParams } from "react-router";
import EmployeeInfo from "./component/EmployeeInfo";
import EmployeeStatusInfo from "./component/EmployeeStatusInfo";
import DetailSettings from "./component/DetailSettings";
import MiscellaneousInfo from "./component/MiscellaneousInfo";
import { useAuth } from "oidc-react";
import { getEmployeeTypes } from "../../app/utility/axiosService";
import {
  fetchAdvanceSearchData,
  fetchLeaveHours,
} from "../../app/store/reducers/slices/configurationSlice";
import {
  GetLeaveAccureByExperiance,
  getUserId,
  getUserRole,
} from "../../app/utility/helper";
import SupervisorInfo from "./component/SuperVisorInfo";
import axios from "axios";

import moment from "moment";
import TemplateInfo from "./component/TemplateInfo";

import PayCodeManagment from "./component/PayCodeManagment";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import ViewAccess from "./component/ViewAccess";
import { useLocation } from "react-router-dom";
import EmployeeCertificationDetails from "./component/employeeCertification";
import EmployeeDeductionsDetails from "./component/employeeDeductions";
import EmployeeW4 from "./component/employeeW4";
import EmployeDeductionPlanMasterGird from "./component/EmployeeDeductionMasterGrid";
import { LoadingPanel } from "../../CommonComponents/CommonFunction";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
}));

function PayrollMasterDetails(props) {
  const history = useNavigate();
  const locationPath = useLocation();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  }
  const userDetails1 = {
    userDetails: {
      employeeNumber: "4641",
      employeeID: "123456789",
      employeeType: "P",
      firstName: "Robert",
      middleName: "D",
      lastName: "Duenas",
      gender: "M",
      martialStatus: "S",
      dob: "1968-07-02T00:00:00",
      citizenship: "United States",
      ethnicCode: "",
      departmentDivision: "9910",
      jobOrderNumber: "991019201",
      objectCode: "111",
      description: "No time records",

      partTimeEmployee: "N",
      employmentBeginDate: "1972-12-01T00:00:00",
      terminationDate: null,
      lengthOfService: "24 YEARS 6 MONTHS 23 DAYS",
      employeeStatus: "A",
      jobClassificationCode: "9522",
      jobClassificationTitle: "EQUIPMENT OPERATOR II",
      exemptPosition: "N",
      payGrade: "",
      payGradeStep: "G",
      hourlyRate: "16.32",
      annualSalary: "33948",

      actingDetailCode: "D",
      actingDetailJobClass: "770047",
      actingDetailHourlyRate: "17.18",
      actingDetailBeginDate: "2018-08-27T00:00:00",
      actingDetailEndCode: "2018-11-24T00:00:00",
      priorLengthOfService: "20 YEARS 1 MONTHS 11 DAYS",

      militaryReserve: "",
      reimbursablePerDiem: "",
      deceasedProcessing: "N",
      dateDeceased: null,
      processForSeparation: "N",
      separationRate: "",

      convertANLLVtoRtrmt: "N",
      supervisor: "N",
      mailFinalCheck: "N",
      deductMedicare: "Y",
      holdCheckforClearance: "N",
      suspended: "N",
      authorizedOverageAL: "N",
      eligibleForNightDiff: "Y",
      cTAAgreement: "N",
      cafeteriaPlan: "N",
      nurseCertificationDiff: "N",
      _457ACADeduction: "N",
      demandRunNetToMisc: "N",
      retirementPlanOption: "7",
      longTermDisabilityDC: "N",
      dCRetireeReEmployed: "N",
      deductRetirement: "N",
      taxExempt: "N",
      taxCode: "1",
      numberOfExemptions: "",
      additionalTax: "",
      retroPay: "",
    },
    employeeList: [
      {
        id: 1,
        employee_number: 14,
        employee_name: "Robert Duanes",
        rate: 12,
        department: "Outside Employment",
        division: "DOA",
        dob: formatDate(new Date("1985-05-23T00:00:00")),
      },
      {
        id: 2,
        employee_number: 14,
        employee_name: "Cecilly Hollak",
        rate: 12,
        department: "Insurance Department",
        division: "ID",
        dob: formatDate(new Date("1986-09-02T00:00:00")),
      },
      {
        id: 3,
        employee_number: 14,
        employee_name: "Lisha entini",
        rate: 13,
        department: "DOA - PERSONNEL MANAGEMENT",
        division: "DOA",
        dob: formatDate(new Date("1963-02-23T00:00:00")),
      },
      {
        id: 4,
        employee_number: 14,
        employee_name: "Emigh Stephen",
        rate: 12,
        department: "IT",
        division: "DOA",
        dob: formatDate(new Date("1984-05-23T00:00:00")),
      },
      {
        id: 5,
        employee_number: 14,
        employee_name: "Kallie Blackwood",
        rate: 12,
        department: "IT",
        division: "ID",
        dob: formatDate(new Date("1975-09-02T00:00:00")),
      },
      {
        id: 6,
        employee_number: 14,
        employee_name: "Elvers Benimadho",
        rate: 13,
        department: "DOA-Personnel Management",
        division: "DOA",
        dob: formatDate(new Date("1982-02-23T00:00:00")),
      },
      {
        id: 7,
        employee_number: 14,
        employee_name: "Micella Rhymes",
        rate: 12,
        department: "IT",
        division: "DOA",
        dob: formatDate(new Date("1986-05-23T00:00:00")),
      },
      {
        id: 8,
        employee_number: 14,
        employee_name: "Rozzela Ostrosky",
        rate: 12,
        department: "Insurance Department",
        division: "ID",
        dob: formatDate(new Date("2000-08-02T00:00:00")),
      },
      {
        id: 9,
        employee_number: 14,
        employee_name: "Ilene Eroman",
        rate: 13,
        department: "DOA-Personnel Management",
        division: "DOA",
        dob: formatDate(new Date("1996-02-02T00:00:00")),
      },
    ],
  };
  const dispatch = useDispatch();
  const [employeeID, setEmployeeID] = useState("");
  const params = useParams();

  console.log("params 124", params);
  // const { employee } = useSelector((state) => {
  //   return state.employeeReducer;
  // });

  const [expand, setExpand] = React.useState({
    employeInfo: true,
    employeStatus: true,
    detailSettings: true,
    miscInfo: true,
    deductionSettings: true,
  });

  const [userInfo, setUserInfo] = useState({});
  const [employee, setEmployee] = useState({});
  const [empTypes, setEmpTypes] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({});
  console.log("state", state);

  useEffect(() => {
    // setState({
    //   employeeNumber: "4641456",
    //   employeeID: employee && employee.ID ? employee.ID : null,
    //   employeeType: employee && employee.Empl_Type ? employee.Empl_Type : null,
    //   firstName: employee && employee.FirstName ? employee.FirstName : "",
    //   middleName: employee && employee.MiddleName ? employee.MiddleName : "",
    //   lastName: employee && employee.LastName ? employee.LastName : "",
    //   gender: employee && employee.Sex ? employee.Sex : "",
    //   martialStatus:
    //     employee && employee.Marital_Status
    //       ? employee.Marital_Status.trim()
    //       : "",
    //   dob: employee && employee.Ndate_Of_Birth ? employee.Ndate_Of_Birth : null,
    //   citizenship: "United States",
    //   ethnicCode: "100",
    //   departmentDivision: employee && employee.DPDV ? employee.DPDV : null,
    //   jobOrderNumber: "991022",
    //   objectCode: "111",
    //   description: " ",

    // });

    if (!!Object.keys(employee).length) {
      setState({
        employeeID: employee?.employeeID || null,
        employeeNumber: employee?.employeeID || null,
        employeeType: employee?.empl_Type || null,
        firstName: employee?.firstName || "",
        middleName: employee?.middleName || "",
        lastName: employee?.lastName || "",
        gender: employee.sex || "",
        martialStatus: (employee.marital_Status).trim() || "",
        dob: employee.ndate_Of_Birth || null,
        ethnicCode: employee.ethnic_Group || "",
        departmentDivision: employee.dept_No || null,
        lengthOfService: `${employee.ps_Yy || 0} YEARS ${employee?.ps_Mm || 0} MONTHS ${employee.ps_Mm || 0} DAYS`,
        priorLengthOfService: `${employee.sep_Yy || 0} YEARS ${employee?.sep_Mm || 0} MONTHS ${employee.sep_Mm || 0} DAYS`,
        employeeStatus: employee.emp_Status || "",
        jobClassificationCode: employee.nposition_No || "",
        payGrade: (employee.range_Pay).trim() || "",
        payGradeStep: employee.step_Actual || "",
        hourlyRate: (employee.rate)?.toFixed(2) || "",
        annualSalary: (employee.annual_Salary)?.toFixed(2) || "",
        jobOrderNumber: employee.job_Order_No || "",
        employmentBeginDate: employee.date_Employment || "",
        terminationDate: employee.date_Term || "",
        actingDetailEndCode: employee.dtl_Term_Date || "",
        military_Status: employee.military_Status || "",
        partTimeEmployee: (employee.work_Sch || "").trim() == "FULL-TIME" ? "N" : "Y",

        exemptPosition: "Y",
        citizenship: "us", //citizen
        objectCode: "111",
        description: " ",
      });
    } else {
      setState({});
    }
  }, [employee]);

  //   const [empTypes, setEmpTypes] = React.useState({});
  // const { changeDetails } = props;
  const changeDetails = () => { };
  // alert(userDetails)
  const userDetails = userDetails1.userDetails;

  const { userData } = useAuth();
  const userRole = getUserRole(userData);
  const optionsMaster = useSelector(
    (state) => state.configurationSlice.advanceSearchData
  );

  // const path = props.location.pathname.split("/");

  const getData = (userid) => {
    axios
      .get(`${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeePayrollMasterDetails?employeeId=${userid}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      })
      .then((response) => {
        console.log("das;dakd;laksd", response.data.data.employeePayrollMaster);
        setUserInfo(response.data ? response.data.data.employeePayrollMaster : []);
        setEmployee(response.data ? response.data.data.employeePayrollMaster : []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Cannot retrieve user data", error);
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({ ...state, [name]: value?.trim() });
  };
  // useEffect(() => {
  //   dispatch(getEmployeeTypes());
  // }, []);
  useEffect(() => {
    if (userData && userData.profile) {
      setEmployeeID(userData.profile.EmployeeID);
    }
  }, [userData]);

  useEffect(() => {
    if (employee) {
      setUserInfo(employee);
    }
  }, [employee]);

  let userId = params.id ? params.id : getUserId(userData);

  useEffect(() => {
    if (!!params.id) {
      userId = params.id ? params.id : getUserId(userData);
      getData(userId);
      (async () => {
        let eTypes = await getEmployeeTypes();
        setEmpTypes(eTypes);
        console.log("eTypes", eTypes);
      })();
    }
    // let userId = params.id ? params.id : employeeID;

    // if (userId !== "" && userId !== undefined) dispatch(getEmployee(userId));
  }, [params.id]);

  console.log("optionsMater", optionsMaster, userId, employeeID);

  useEffect(() => {
    dispatch(fetchAdvanceSearchData());
  }, []);

  const selectUsers = () => {
    // if (["admin", "supervisor"].includes(getUserRole(userData))) {
    //   return getUserId(userData);
    // } else {
    return params.id ? params.id : getUserId(userData);
    // }
  };

  if (isLoading)
    return <LoadingPanel />

  return (
    <>
      <Header
        {...props}
        ID={params.id ? params.id : employeeID}
        breadcrumbs={locationPath?.state}
      />

      <EmployeeInfo
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        empTypes={empTypes}
        userDetail={userDetails}
        changeDetails={changeDetails}
        optionsMaster={optionsMaster}
        state={state}
        handleChange={handleChange}
        setState={setState}
      />

      <EmployeeStatusInfo
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        userDetail={userDetails}
        changeDetails={changeDetails}
        optionsMaster={optionsMaster}
        state={state}
        handleChange={handleChange}
        setState={setState}
      />

      <DetailSettings userDetail={userDetails} changeDetails={changeDetails} state={state} setState={setState} handleChange={handleChange} />

      <MiscellaneousInfo
        userDetail={userDetails}
        changeDetails={changeDetails}
        userInfo={userInfo}
        optionsMaster={optionsMaster}
        state={state}
        setState={setState}
        handleChange={handleChange}
      />

      {userRole && userRole === "user" &&
        <EmployeeW4
          userDetail={userDetails}
          changeDetails={changeDetails}
          userInfo={userInfo}
          optionsMaster={optionsMaster}
        />
        // <Card>
        //   <FederalW4Info 
        //   />
        //   <StateTaxW4Info 
        //     userDetail={userDetails}
        //     changeDetails={changeDetails}
        //     userInfo={userInfo}
        //     optionsMaster={optionsMaster}
        //   />
        // </Card>
      }

      {/* <DeductionSettings
        userInfo={userInfo}
        userDetail={userDetails}
        changeDetails={changeDetails}
      /> */}

      <EmployeeCertificationDetails
        userDetails={userInfo}
        state={state}
      />


      {/* <EmployeeDeductionsDetails userInfo={userInfo} type='GARNISHMENT' />
      <EmployeeDeductionsDetails userInfo={userInfo} type='BENEFITS' />
      <EmployeeDeductionsDetails userInfo={userInfo} type='MISCELLANEOUS' /> */}
      <EmployeDeductionPlanMasterGird employeeID={params.id} />


      {userRole && ["supervisor", "timekeeper"].includes(userRole) && (
        <>
          <PayCodeManagment
            userInfo={userInfo}
            userDetail={userDetails}
            userId={selectUsers()}
          />
          <TemplateInfo empId={params.id ? params.id : getUserId(userData)} />
        </>
      )}

      {userRole && userRole == "supervisor" && (
        <ViewAccess empId={params.id ? params.id : getUserId(userData)} />
      )}

      {userRole &&
        ["admin", "supervisor"].includes(userRole) &&
        ["10003000", "10036942"].includes(userId) && (
          // <SupervisorInfo
          //   userDetail={userDetails}
          //   changeDetails={changeDetails}
          //   userInfo={userInfo}
          //   optionsMaster={optionsMaster}
          //   supEmpId={params.id}
          // />
          <Box>
            <PayCodeManagment
              userInfo={userInfo}
              userDetail={userDetails}
              userId={params.id ? params.id : getUserId(userData)}
            />
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                color: "white",
                width: "100%",
                marginBottom: "90px"
              }}
              className="bg-primary"
              onClick={() => {
                // assignDeptEmp();
                console.log("On Clicl Assignment");
                // props.history.push({
                //   pathname: "admin/employee/update/:id/assign-dept-emp",
                //   state: { supEmpId: params.id },
                // });
                history(`/admin/employee/update/${params.id}/assign-dept-emp`, {
                  state: { supEmpId: params.id },
                });
              }}
            >
              Assign Departments & Employees to Supervisor
            </Button>
          </Box>
        )}
    </>
  );
}

export default PayrollMasterDetails;
