import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";

import Header from "./component/header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import RequestTable from "./component/request-table";
import axios from "axios";
import Header1 from "./component/header1";
import AdvanceSearch from "./AdvanceSearch";
import { useSelector } from "react-redux";
import { getUserId } from "../../app/utility/helper";
import { useAuth } from "oidc-react";
import TelerikDataTable from "./component/telerikDataTable";
import LoadingPanel from "../Common/telerik/loadingPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    "& .MuiPaper-root": {
      background: "none",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

const SuperVisorDetails = (props) => {
  const classes = useStyles();

  const { userData } = useAuth();
  const ID = getUserId(userData);
  const employeeSearched = useSelector(
    ({ employeeSlice }) => employeeSlice.employeeSearched
  );

  const employeeColumns = useSelector(
    (state) => state.querySlice.colsPlaceholder
  );
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });

  const [sortedColumns, setSortedColumns] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getColumnFilterType = (type) => {
    if (["float", "bigint", "smallint", "int"].includes(type)) {
      return "numeric";
    } else if (["varchar", "char"].includes(type)) {
      return "text";
    } else if (["datetime"].includes(type)) {
      return "date";
    } else {
      return "text";
    }
  };

  useEffect(() => {
    if (employeeColumns && employeeColumns.length) {
      let colArray = [];
      employeeColumns.map((i) => {
        let iObj = {
          title: i.column_title,
          field: i.column_name,
          locked: false,
          show: false,
          filter: getColumnFilterType(i.data_type),
          width: 200,
        };
        colArray.push(iObj);
      });

      // Arrange column sequence

      const visibleColumnArray = [
        "SSN",
        "ID",
        "EmployeeName",
        "Department",
        "Nposition_No",
        "DPDV",
        "Military_Status",
        "Hm_Phone",
        "Ml_Wk_Phone",
        "Email",
        "Inactive_Status_Title",
        "Emp_Status_Title",
      ];
      let orderColumns = [];
      console.log("employeeColumns", colArray);
      visibleColumnArray.map((i) => {
        let columnIndex = colArray.findIndex((k) => {
          return k.field == i;
        });
        if (columnIndex !== -1) {
          orderColumns.push({ ...colArray[columnIndex], show: true });
          colArray.splice(columnIndex, 1);
        }
      });
      let updatedColumn = orderColumns.concat(colArray);
      setSortedColumns(updatedColumn);
    }
  }, [employeeColumns]);
  useEffect(() => {
    setTableList(employeeSearched);
    console.log("Prm", employeeSearched);
  }, [employeeSearched]);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  const getData = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search/employee`,
        {
          filters: [],
          queries: [],
          limit: 20,
          page: 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setTableList([]);
        console.warn("Cannot retrieve user data", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    // getData();
  }, []);

  return (
    <>
      <Header1 {...props} detail={detail} />

      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full`}>
          <AdvanceSearch userId={ID} setLoading={setLoading} />
        </div>
      </div>
      {/* <Header
        {...props}
        detail={detail}
        getData={getData}
        setDetail={setDetail}
      /> */}

      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <TelerikDataTable
          data={tableList}
          selectedColumns={sortedColumns}
          setSortedColumns={setSortedColumns}
          loading={loading}
        ></TelerikDataTable>
        <div className={`${classes.root} w-full`}>
          {/* <RequestTable
            {...props}
            role={"admin"}
            requestDetail={tableList}
            detail={detail}
            setDetail={setDetail}
            userDetail={null}
          /> */}
        </div>
      </div>
    </>
  );
};
export default SuperVisorDetails;
