import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faFileExport,
  faAddressCard,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import { connect } from "react-redux";
import { CardHeader } from "reactstrap";

function ApplyLeaveHeader(props) {
 
  return (
    <CardHeader>
      <div
        className={`flex flex-1 w-full bg-primary`}
        style={{
          display: "flex",
          flexDirection: "row",
          // backgroundColor: "#1975D2",
          height: "4.2rem",
          borderRadius: "5px",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
        <Typography className="fs-5">Apply Leave</Typography>
      </div>
    </CardHeader>
  );
}

export default ApplyLeaveHeader;
