import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { getAllFilingStatus } from "../../../app/utility/axiosService";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-duotone-svg-icons";

const FederalW4Info = (props) => {
  const { employeeDefaultW4Data, userInfo, setUserInfo, saveData, isUpdate, setIsUpdate } = props;
  const [filingStatusList, setFilingStatusList] = useState([]);
  const [isEarlierVersionW4, setIsEarlierVersionW4] = useState(false);
  const [isNonResidentAlien, setIsNonResidentAlien] = useState(false);
  const [isFITWithholding, setIsFITWithholding] = useState(false);

  useEffect(() => {
    fetchAllFilingStatus();
  }, []);

  useEffect(() => {
    if (employeeDefaultW4Data && !isUpdate) {
      if (employeeDefaultW4Data?.isW4Pre2019Format) setIsEarlierVersionW4(true);
      if (employeeDefaultW4Data?.nonResidentAlien) {
        setIsEarlierVersionW4(true);
        setIsFITWithholding(false);
        setUserInfo({ ...userInfo, filingStatus: 1, isExemptFromFederalITWithholding: false });
      }
      if (employeeDefaultW4Data?.isFederalIncomeTaxWithholding) setIsFITWithholding(true);
    }
  }, [JSON.stringify(employeeDefaultW4Data), isUpdate]);

  const fetchAllFilingStatus = async () => {
    const res = await getAllFilingStatus();
    if (res?.length > 0) setFilingStatusList(res);
  };

  return (
    <>
      <CardHeader className="pb-0">
        <Row className="w4HeaderRow">
          <H5>Federal Taxes</H5>
          <Button
            variant="contained"
            className="w-10 m-10 mx-2 xs:mx-10 min-w-0"
            style={{
                color: "#234d10",
                backgroundColor: "#ffffff",
            }}
            onClick={saveData}
        >
          <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
          />
          Save
        </Button>
        </Row>
      </CardHeader>
      <CardBody>
        {/* <Form> */}
          <Row className="g-3 mt-1">
          <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Use 2019 or earlier version of Form W-4?</Label>
              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, earlierVersionW4: JSON.parse(value) });
                  if (JSON.parse(value) === true) {
                    setIsEarlierVersionW4(true);
                    setUserInfo({ ...userInfo, earlierVersionW4: JSON.parse(value), spouseWorks: false, dependentAmount: '', otherIncomeAmount: '', deductionAmount: '',  });
                  }
                  else setIsEarlierVersionW4(false);
                }}
                // defaultValue={employeeDefaultW4Data?.earlierVersionW4 ? employeeDefaultW4Data.earlierVersionW4 : false}
                value={userInfo?.earlierVersionW4 ? userInfo?.earlierVersionW4 : false}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Are you a nonresident alien?</Label>
              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userInfo?.nonResidentAlien ? userInfo?.nonResidentAlien : false}
                onChange={(_, value) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, nonResidentAlien: JSON.parse(value) });
                  if (JSON.parse(value) === true) {
                    setIsNonResidentAlien(true);
                    setIsFITWithholding(false);
                    setUserInfo({ ...userInfo, nonResidentAlien: JSON.parse(value), filingStatus: 1, isExemptFromFederalITWithholding: false });
                  } else setIsNonResidentAlien(false);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Filing Status"
                disabled={isNonResidentAlien ? true : false}
                InputLabelProps={{ shrink: true }}
                // defaultValue={userInfo?.filingStatus ? userInfo.filingStatus : null}
                value={userInfo?.filingStatus ? userInfo.filingStatus : null}
                onChange={(e) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, filingStatus: e.target.value });
                }}
              >
                <option value={null} disabled>What's your filing status?</option>
                {filingStatusList?.length > 0 && filingStatusList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.displayName}
                    </option>
                ))}
              </TextField>
            </Col>
            {!isEarlierVersionW4 &&
              <>
                <Col md={4} xs={6} lg={4}>
                  <Label className="form-label">Work more than one job or your spouse works?</Label>
                  <RadioGroup
                    className="mx-2"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={userInfo?.spouseWorks ? userInfo.spouseWorks : false}
                    onChange={(_, value) => {
                      setIsUpdate(true);
                      setUserInfo({ ...userInfo, spouseWorks: JSON.parse(value) });
                    }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" disabled={isFITWithholding} />
                    <FormControlLabel value={false} control={<Radio />} label="No" disabled={isFITWithholding} />
                  </RadioGroup>
                </Col>
                <Col md="4">
                  <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    type="number"
                    name="dependentAmount"
                    disabled={isFITWithholding}
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                    }}
                    label="Dependent amount?"
                    value={userInfo?.dependentAmount}
                    onChange={(e) => {
                      setIsUpdate(true);
                      setUserInfo({ ...userInfo, dependentAmount: e.target.value });
                    }}
                  />
                </Col>
                <Col md="4">
                  <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    type="number"
                    disabled={isFITWithholding}
                    name="otherIncomeAmount"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                    }}
                    label="Other income amount?"
                    value={userInfo?.otherIncomeAmount}
                    onChange={(e) => {
                      setIsUpdate(true);
                      setUserInfo({ ...userInfo, otherIncomeAmount: e.target.value });
                    }}
                  />
                </Col>
                <Col md="4">
                  <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    type="number"
                    disabled={isFITWithholding}
                    name="deductionAmount"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        color: "black",
                        paddingTop: "12px",
                      },
                    }}
                    label="Deduction amount?"
                    value={userInfo?.deductionAmount}
                    onChange={(e) => {
                      setIsUpdate(true);
                      setUserInfo({ ...userInfo, deductionAmount: e.target.value });
                    }}
                  />
                </Col>
              </>
            }
            {isEarlierVersionW4 &&
              <Col md="4">
                <TextField
                  margin="dense"
                  className="flex w-full "
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="small"
                  type="number"
                  name="allowances"
                  disabled={isFITWithholding}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      color: "black",
                      paddingTop: "12px",
                    },
                  }}
                  label="How many allowances do you have?"
                  value={userInfo?.allowances}
                  onChange={(e) => {
                    setIsUpdate(true);
                    setUserInfo({ ...userInfo, allowances: e.target.value });
                  }}
                />
              </Col>
            }
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="additionalWithholdingAmount"
                type="number"
                disabled={isFITWithholding}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Additional withholding amount?"
                value={userInfo?.additionalWithholdingAmount}
                onChange={(e) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, additionalWithholdingAmount: e.target.value });
                }}
              />
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Are you exempt from Federal Income Tax Withholding?</Label>
              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // disabled={isNonResidentAlien ? true : false}
                // defaultValue={userInfo?.isExemptFromFederalITWithholding ? userInfo.isExemptFromFederalITWithholding : false}
                value={userInfo?.isExemptFromFederalITWithholding ? userInfo.isExemptFromFederalITWithholding : false}
                onChange={(_, value) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, isExemptFromFederalITWithholding: JSON.parse(value) });
                  if (JSON.parse(value) === true) {
                    setIsFITWithholding(true);
                    setUserInfo({ ...userInfo, isExemptFromFederalITWithholding: JSON.parse(value), spouseWorks: false, dependentAmount: '', otherIncomeAmount: '', deductionAmount: '', additionalWithholdingAmount: '' });
                  }
                  else setIsFITWithholding(false);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} disabled={isNonResidentAlien ? true : false} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} disabled={isNonResidentAlien ? true : false} label="No" />
              </RadioGroup>
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Are you exempt from Medicare Tax?</Label>
              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // defaultValue={userInfo?.isExemptFromMedicareTax ? userInfo.isExemptFromMedicareTax : false}
                value={userInfo?.isExemptFromMedicareTax ? userInfo.isExemptFromMedicareTax : false}
                onChange={(_, value) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, isExemptFromMedicareTax: JSON.parse(value) });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md={4} xs={6} lg={4}>
              <Label className="form-label">Are you exempt from Social Security Tax?</Label>
              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  setIsUpdate(true);
                  setUserInfo({ ...userInfo, isExemptFromSocialSecurityTax: JSON.parse(value) });
                }}
                value={userInfo?.isExemptFromSocialSecurityTax ? userInfo.isExemptFromSocialSecurityTax : false}
                // defaultValue={userInfo?.isExemptFromSocialSecurityTax ? userInfo.isExemptFromSocialSecurityTax : false}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
          </Row>
          <Row>
            <Label className="form-label">If your income will be $200,000 or less ($400,000 or less if married filing jointly) :</Label>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                type="number"
                name="numberOfQualifyingChildrenUnderAge17"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                  maxLength: 3,
                  minLength: 3
                }}
                label="Number of qualifying children under age 17?"
                value={userInfo?.numberOfQualifyingChildrenUnderAge17}
                onChange={(e) => setUserInfo({ ...userInfo, numberOfQualifyingChildrenUnderAge17: e.target.value })}
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                type="number"
                name="numberOfOtherDependents"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Number of other dependents?"
                value={userInfo?.numberOfOtherDependents ? userInfo?.numberOfOtherDependents : ''}
                onChange={(e) => setUserInfo({ ...userInfo, numberOfOtherDependents: e.target.value })}
              />
            </Col>
          </Row>
        {/* </Form> */}
      </CardBody>
    </>
  );
};

export default FederalW4Info;