import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMessageExclamation,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";

import { showMessage } from "../../../../app/store/actions";
import moment from "moment";
import { CardHeader } from "reactstrap";

function HomeHeader(props) {
  let dispatch = useDispatch();
  const checkvalid = () => {
    if (props.detail.code == "" || props.detail.description == "") {
      dispatch(
        showMessage({
          message: "Please validate values!",
          variant: "error",
        })
      );
      return false;
    } else {
      return true;
    }
  };
  const clearField = () => {
    props.setDetail({
      ethnicCode: "",
      ethnicTitle: "",
    });
  };
  const saveData = () => {
    console.log("props.detail xxxxx", JSON.stringify(checkvalid()));
    if (!checkvalid()) {
      console.log("props.deta xxxx1");
      return false;
    }

    let paypload = {
      ETHNIC_CODE: props.detail.ethnicCode,
      ETHNIC_TITLE: props.detail.ethnicTitle,
    };
    if (props.detail.oldId) {
      paypload = {
        ETHNIC_CODE: props.detail.ethnicCode,
        ETHNIC_TITLE: props.detail.ethnicTitle,
      };
      axios
        .put(
          `${process.env.REACT_APP_API_URL}ethicGroup/update/${props.detail.oldId}`,
          paypload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((response) => {
          dispatch(
            showMessage({
              message: "Updated successfully!",
              variant: "success",
            })
          );
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}ethicGroup/create`, paypload, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        })
        .then((response) => {
          dispatch(
            showMessage({
              message: "Added successfully!",
              variant: "success",
            })
          );
          clearField();
          props.getData();
          console.log("Master responsexxxx", response.data);
          props.getData();
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    }
  };

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">Ethnic Code</Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={saveData}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {props.detail.oldId ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {props.detail.oldId ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={clearField}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;
