const booleanOptions = [
  {
    value: "Y",
    label: "Yes",
  },
  {
    value: "N",
    label: "No",
  },
];
const citizenshipOptions = [
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "us",
    label: "United States",
  }
];

const ethnicCodeOptions = [
  {
    value: "WH",
    label: "CAUCASIAN -UPDATE"
  },
  {
    value: "VE",
    label: "VIETNAMESE"
  },
  {
    value: "OT",
    label: "OTHER"
  },
  {
    value: "NM",
    label: "NORTHERN MARIANA"
  },
  {
    value: "NI",
    label: "NOT IDENTIFIED"
  },
  {
    value: "MX",
    label: "MIXED"
  },
  {
    value: "MN",
    label: "MICRONESIA"
  },
  {
    value: "KN",
    label: "KOREAN"
  },
  {
    value: "JE",
    label: "JAPANESE"
  },
  {
    value: "HI",
    label: "HISPANIC"
  }
];

const departmentDivisionOptions = [
  {
    value: "9910",
    label: "9910",
  },
  {
    value: "1",
    label: "1",
  },
];

const jobOrderNumberOptions = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "991022",
    label: "991022",
  },
];

const objectCodeOptions = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "111",
    label: "111"
  }
];

const employmentTypeOptions = [
  {
    value: "P",
    label: "Permanent",
  },
  {
    value: "C",
    label: "Contractual",
  },
];

const employeeStatusOptions = [
  {
    value: "A",
    label: "Active",
  },
  {
    value: "R",
    label: "Retired",
  },
];

const jobClassificationCodeOptions = [
  {
    value: "9522",
    label: "9522",
  },
  {
    value: "9910",
    label: "9910",
  },
  {
    value: "8928",
    label: "8928",
  },
];

const jobClassificationTitleOptions = {
  "9522": [
    {
      value: "EQUIPMENT OPERATOR II",
      label: "EQUIPMENT OPERATOR II",
    },
    {
      value: "EQUIPMENT OPERATOR I",
      label: "EQUIPMENT OPERATOR I",
    },
  ],
  "8928": [
    {
      value: "EQUIPMENT OPERATOR III",
      label: "EQUIPMENT OPERATOR III",
    },
    {
      value: "EQUIPMENT OPERATOR IV",
      label: "EQUIPMENT OPERATOR IV",
    },
  ],
};

const payGradeStepOptions = {
  "9522": [
    {
      value: "G",
      label: "G",
    },
    {
      value: "E",
      label: "E",
    },
  ],
  "8928": [
    {
      value: "F",
      label: "F",
    },
    {
      value: "H",
      label: "H",
    },
  ],
};

export {
  booleanOptions,
  citizenshipOptions,
  ethnicCodeOptions,
  departmentDivisionOptions,
  jobOrderNumberOptions,
  objectCodeOptions,
  employmentTypeOptions,
  employeeStatusOptions,
  jobClassificationCodeOptions,
  jobClassificationTitleOptions,
  payGradeStepOptions,
};
