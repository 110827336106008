import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import clsx from "clsx";
import { Card, Col, Label, Row } from "reactstrap";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EarningHeader from "../Timekeeper/EarningsReports/EarningHeader";
import { fetchPayPeriodMaster } from "../../app/store/reducers/slices/masterSlice";
import axios from "axios";
import { GetAllEmployee } from "../Supervisor/CommonConfigrations/TableSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";
import RequestTable from "./component/request-table";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getAllEmployeePayrollList, getEmployeePayrollProcessRunRecord } from "../../app/utility/axiosService";
import LoadingPanel from "../Common/telerik/loadingPanel";
import AdvanceSearch from './component/AdvanceSearch';
import './style.css';
import { getUserId, getUserRole } from "../../app/utility/helper";
import { useAuth } from "oidc-react";

const PayRollRun = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { payperiods } = useSelector((state) => state.masterSlice);
  const { allEmployee, employeeTotalCount } = useSelector((state) => state.TableSlice);

  const configrationJSON = {
    headerTitle: "Payroll Run",
    detailTitle: "Employee Detail",
  };
  const { userData } = useAuth();
  const ID = getUserId(userData);
  const role = getUserRole(userData);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(16);
  const [pageSize, setPageSize] = useState(10);
  const [tableList, setTableList] = useState([]);
  const [takeCount, setTakeCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [paramsQueries, setParamsQueries] = useState([]);
  const [isPreviewOption, setIsPreviewOption] = useState(false);
  const [isGenerateOption, setIsGenerateOption] = useState(false);
  const [page, setPage] = useState(0);
  const [expand, setExpand] = useState({
    panel1: true,
    panel2: true,
  });

  const [selectedValue, setSelectedValue] = useState({
    payperiod: null,
    employee: "",
  });

  const loadMoreResults = () => {
    const nextPage = page + 1;
    if (allEmployee.length < employeeTotalCount) {
      setPage(nextPage);
      dispatch(GetAllEmployee(nextPage))
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };
  useEffect(() => {
    dispatch(fetchPayPeriodMaster());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetAllEmployee(0));
  }, []);

  useEffect(() => {
    handleSerachList(selectedValue?.payperiod);
  }, [paramsQueries]);

  const handleGenerateReport = async () => {
    if (!selectedValue?.payperiod) {
      toast.error("Please select pay period");
      return;
    }
    setLoading(true);

    let data = {
      ppe: selectedValue?.payperiod,
      employeeId: selectedValue?.employee ? selectedValue?.employee.toString() : null,
    };

    const res = await getEmployeePayrollProcessRunRecord(data);
    if (res?.status === 200) {
      if (res?.data?.length > 0) {
        setTableList(res?.data);
        toast.success("Payroll run successfully");
        setLoading(false);
        setSelectedValue({ ...selectedValue, payperiod: null });
        setIsGenerateOption(false);
      } else {
        toast.warn("No Record Found.")
        setLoading(false);
      };
    }

    // axios
    //   .post(`${process.env.REACT_APP_WEB_API_URL}Payroll/RunPayroll`, data, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
    //     },
    //   })
    //   .then((res) => {
    //     toast.success("Payroll run successfully");
    //   })
    //   .catch((err) => { });
  };

  const handleSerachList = async (id) => {
    if (!id) {
      return;
    }
    setLoading(true);
    let temp = [];
    if (paramsQueries && paramsQueries?.length > 0) {
      paramsQueries.forEach((o, i) => {
        temp.push({
            "columnName": o.columnName,
            "operator": o.operator,
            "paramValue": o.paramValue?.trim(),
            "paramType": "",
            "logicOperator": (i===0) ? "" : o.logicOperator
        })
      });
    }

    const payload = {
      ppeId: id,
      params: temp
    }

    const res = await getAllEmployeePayrollList(payload);
    if (res?.status === 200) {
      if (res?.data?.length > 0) {
        setTableList(res?.data);
        setIsGenerateOption(false);
        setIsPreviewOption(true);
      } else {
        setTableList([]);
        setIsPreviewOption(false);
        setIsGenerateOption(true);
      };
    }
    setLoading(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };
  
  return (
    <>
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <EarningHeader configrationJSON={configrationJSON} />
      </div>
      <div className="page-content">
      {isPreviewOption && <AdvanceSearch loading={loading} role={role} userId={ID} paramsQueries={paramsQueries} setParamsQueries={setParamsQueries} setLoading={setLoading} />}
      <div
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <div className={`${classes.root} w-full `}>
          <Card style={{ width: "100%", padding: "10px" }}>
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <div
                className={clsx("form-parent-div1")}
                style={{ justifyContent: "left" }}
              >
                <div className="grid mt-10 grid-cols-1">
                  <div
                    className="flex justify-start mt-2 mb-3 gap-12"
                    style={{ gap: "5px" }}
                  >
                      <Row style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div>
                            <FormControl
                              variant="outlined"
                              style={{
                                width: "100%",
                                minWidth: "250px",
                              }}
                              sx={{ m: 1, minWidth: 120 }} size="small"
                            >
                              <InputLabel>Select Pay Period</InputLabel>
                              <Select
                                label="Select Pay Period"
                                name="payperiod"
                                // size="small"
                                InputLabelProps={{ shrink: selectedValue?.payperiod ? true : false }}
                                value={selectedValue?.payperiod}
                                onChange={handleChange}
                              >
                                {payperiods?.map((item, index) => (
                                  <MenuItem
                                    value={item?.ID}
                                    onClick={() => {
                                      handleSerachList(item?.ID);
                                      setSelectedValue({
                                        ...selectedValue,
                                        payperiod: item?.ID,
                                      });
                                    }}
                                  >
                                    {`${item?.StartDate} TO ${item?.EndDate}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </Col>
                        {!isPreviewOption &&
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                                color: "white",
                              }}
                              disabled={!isGenerateOption}
                              className="bg-primary"
                              onClick={handleGenerateReport}
                            >
                              <FontAwesomeIcon
                                icon={faRectangleList}
                                style={{
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              />
                              Generate Report
                            </Button>
                          </Col>
                        }
                        {/* {!isGenerateOption && isPreviewOption &&
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                                color: "white",
                              }}
                              className="bg-primary"
                              onClick={handleSerachList}
                            >
                              <FontAwesomeIcon
                                icon={faRectangleList}
                                style={{
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              />
                              Search List
                            </Button>
                          </Col>
                        } */}
                        {/* <Col lg={6} md={6} sm={12} xs={12}>
                          <div>
                            <FormControl
                              variant="outlined"
                              style={{
                                width: "100%",
                                minWidth: "250px",
                              }}
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={allEmployee}
                                sx={{ width: 300 }}
                                onChange={(event, newValue) => {
                                  setSelectedValue({
                                    ...selectedValue,
                                    employee: newValue?.id,
                                  });
                                }}
                                ListboxProps={{
                                  onScroll: handleScroll
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Employee" />
                                )}
                              />
                              <Select
                                label="Select Employee"
                                name="employee"
                                onChange={handleChange}
                              >
                                {allEmployee?.map((item, index) => (
                                  <MenuItem
                                    value={item?.id}
                                    onClick={() =>
                                      setSelectedValue({
                                        ...selectedValue,
                                        employee: item?.id,
                                      })
                                    }
                                  >
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </Col> */}
                      </Row>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              color: "white",
                            }}
                            className="bg-primary"
                            onClick={handleGenerateReport}
                          >
                            <FontAwesomeIcon
                              icon={faRectangleList}
                              style={{
                                color: "white",
                                marginRight: "5px",
                              }}
                            />
                            Generate Report
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
          </Card>
        </div>
      </div>
      {/* {loading ?
        <div style={{ minHeight: "100px" }}>
          <LoadingPanel></LoadingPanel>
        </div> 
        : */}
        <Card className="mt-3 mb-3">
          <Accordion
              className={classes.root}
              expanded={expand.panel2}
              onChange={handleChange("panel2")}
          >
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className="bg-gray-200 sub-header"
              >
                  <FontAwesomeIcon
                      icon={faRectangleList}
                      className="sub-header"
                      style={{
                          marginRight: "10px",
                          marginTop: "3px",
                      }}
                  />
                  <Typography className={classes.heading}>
                      Payroll Run List
                  </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 page-body-wrapper">
              {loading ?
                <div style={{ minHeight: "100px" }}>
                  <LoadingPanel></LoadingPanel>
                </div> 
                :
                <RequestTable
                  role={"admin"}
                  // clearFields={clearFields}
                  requestDetail={tableList}
                  setSkipCount={setSkipCount}
                  isPreviewOption={isPreviewOption}
                  totalCount={totalCount}
                  // detail={detail}
                  // setDetail={setDetail}
                  // filterBy={detail}
                  // getData={getData}
                />
              }
              </AccordionDetails>
          </Accordion>
        </Card>
      {/* } */}
      </div>
      </Container>
    </>
  );
};

export default PayRollRun;
