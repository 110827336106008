 
import React, { Fragment, useEffect, useState } from "react";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Autocomplete, TextField } from "@mui/material";
import { Box } from "react-feather";
import { Col, Row } from "reactstrap";
import "../../../styles/custom.css"
import axios from "axios";
 

const useStyles = makeStyles((theme) => ({
    listHeight: {
      height: "calc(100vh - 100px - 317px)",
      maxHeight: "calc(100vh - 100px - 317px)",
      overflow: "auto",
      "@media screen and (max-width: 767px)": {
        height: "calc(100vh - 100px - 180px)",
        maxHeight: "calc(100vh - 100px - 180px)",
      },
    },
    listHeightError: {
      height: "calc(100vh - 100px - 256px)",
      maxHeight: "calc(100vh - 100px - 256px)",
      overflow: "auto",
      "@media screen and (max-width: 767px)": {
        height: "calc(100vh - 100px - 239px)",
        maxHeight: "calc(100vh - 100px - 239px)",
      },
    },
  }));
  
  // const positionList = [
  //   {
  //     key: "04.438",
  //     value: "convertANLLVtoRtrmt",
  //     value1: " Convert ANLLV to Rtrmt",
  //   },
  //   {
  //     key: "02.330",
  //     value: "supervisor",
  //     value1: " Supervisor",
  //   },
  //   {
  //     key: "02.331",
  //     value: "mailFinalCheck",
  //     value1: "Mail Final Check",
  //   },
  //   {
  //     key: "02.332",
  //     value: "deductMedicare",
  //     value1: "Deduct Medicare",
  //   },
  //   {
  //     key: "00.400",
  //     value: "holdCheckforClearance",
  //     value1: "Hold Check for Clearance",
  //   },
  //   {
  //     key: "02.320",
  //     value: "suspended",
  //     value1: "Suspended",
  //   },
  //   {
  //     key: "02.321",
  //     value: "authorizedOverageAL",
  //     value1: "Authorized Overage AL",
  //   },
  //   {
  //     key: "02.322",
  //     value: "eligiblefornightdiff",
  //     value1: "Eligible For Night Diff",
  //   },
  //   {
  //     key: "02.325",
  //     value: "ACCOUNTING TECHNICIAN SUPERVISOR",
  //     value1: "ACCTNG TECH SUPVR",
  //   },
  //   {
  //     key: "01.100",
  //     value: "cTAAgreement",
  //     value1: "CTA Agreement",
  //   },
  //   {
  //     key: "01.135",
  //     value: "cafeteriaPlan",
  //     value1: "Cafeteria Plan",
  //   },
  //   {
  //     key: "02.831",
  //     value: "nurseCertificationDiff",
  //     value1: "Nurse Certification Diff",
  //   },
  //   {
  //     key: "02.010",
  //     value: "ADMINISTRATIVE OFFICER",
  //     value1: "ADMINISTRATIVE OFFICER",
  //   },
  //   {
  //     key: "00.225",
  //     value: "457ACADeduction",
  //     value1: "457 ACA Deduction",
  //   },
  //   {
  //     key: "00.226",
  //     value: "demandRunNetToMisc",
  //     value1: "Demand Run Net To Misc",
  //   },
  //   {
  //     key: "02.505",
  //     value: "longtermdisabilitdc",
  //     value1: "Long Term Disability DC",
  //   },
  //   {
  //     key: "02.030",
  //     value: "dCRetireeReEmployed",
  //     value1: "DC Retiree Re-Employed",
  //   },
  //   {
  //     key: "01.163",
  //     value: "deductRetirement",
  //     value1: "Deduct Retirement",
  //   },
    
  // ];

const ChipsDeduction = (props) => {
  const  {userInfo} = props
    const classes = useStyles();
    // const { values: detail, handleChange, touched, errors } = props;
    const [values , setValues] = useState([])
    const [filterText, setFilterText] = useState("");
    const [selectedPositions, setSelectedPositions] = useState([]); 
    const [positionList, setPositionList] = useState([]);  
    const getPositionData = () => {
      axios
        .get(
          `${process.env.REACT_APP_WEB_API_URL}LkCertification`,
          
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":`Bearer ${localStorage.getItem("jwt_access_token")}`,
            },
          }
        )
        .then(async (response) => {
          console.log("getPositionData", response.data.data); 
          let data = response?.data?.data?.map((i) => {
            return {key : i.id, value:i.code , value1:i.certificateName}
          })
          setPositionList(data)
        })
        .catch((error) => {
        
          console.warn("Cannot retrieve user data", error);
        });
    };

    const getPositionDataByID = async (id) => {
     await axios
        .get(
          `${process.env.REACT_APP_WEB_API_URL}EmployeeCertification/GetAll?EmployeeId=${id}&MaxCount=1000&SkipCount=0`,
          
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":`Bearer ${localStorage.getItem("jwt_access_token")}`,
            },
          }
        )
        .then(async (response) => {
          let DemoArray =  response.data.data
        let dataselected = DemoArray.map((i) => {
          return i.certificationCode
        })
        setSelectedPositions(dataselected)
        })
        .catch((error) => {
          console.warn("Cannot retrieve user data", error);
        });
    };

    useEffect(() => {
      getPositionData()
    },[])

    useEffect(() => {
      if(userInfo?.ID)
      getPositionDataByID(userInfo?.ID)
    },[userInfo?.ID])


    const handleChange = (e) => { 
        if (e.target.name === "positionList") {
          const arr = values.positionList || [];
          const index = arr.indexOf(e.target.value);
          if (index > -1) {
            arr.splice(index, 1);
          } else {
            arr.push(e.target.value);
          }
          setValues({
            ...values,
            [e.target.name]: arr,
          });
        } else {
          setValues({
            ...values,
            [e.target.name]: e.target.value,
          });  
      }; 
    }
  return (
    <div className="  w-full flex-col">
    <Typography className="flex w-full justify-center py-8 bg-gray-200 text-gray-600 text-18">
      Positions in which you are interested
    </Typography>
    
        <TextField
              margin="dense"
              className="flex w-full "
              style={{ width: "100%" }}
              variant="outlined"
              size="small"
            
              inputProps={{
                style: {
                  fontSize: "14px",
                  color: "black",
                  paddingTop: "12px",
                },
              }}
              label="Find positions"
            
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
            />

    <div className="d-flex align-content-start flex-wrap">
      {positionList
        .filter((item) => {
          return selectedPositions.find((d) => d === item.value) != null;
        })
        .map((i, index) => {
          return (
            <div className="p-1">
              <input
                type="checkbox"
                class="btn-check font-primary"
                value={i.value}
                tabIndex={-1}
                name="positionList"
                id={i.value}
                onClick={() => {
                  let selectedItems = [...selectedPositions];
                  if (selectedItems.find((d) => d === i.value) != null)
                    selectedItems = selectedItems.filter(
                      (d) => d !== i.value
                    );
                  else selectedItems.push(i.value);
                  setSelectedPositions([...selectedItems]);

                  handleChange({
                    target: { value: i.value, name: "positionList" },
                  });
                }}
                checked={selectedPositions.find((d) => d === i.value) != null}
                autocomplete="off"
              />
              <label className="btn btn-outline-primary chipbtnchecked" for={i.value}>
                {i.value1}
              </label>
            </div>
          );
        })}
      {positionList
        .filter((item) => {
          return (
            selectedPositions.find((d) => d === item.value) == null &&
            item.value.toLowerCase().indexOf(filterText.toLocaleLowerCase()) >
              -1
          );
        })
        .map((i, index) => {
          return (
            <div className="p-1">
              <input
                type="checkbox"
                class="btn-check font-primary"
                value={i.value}
                tabIndex={-1}
                name="positionList"
                id={i.value}
                onClick={() => {
                  let selectedItems = [...selectedPositions];
                  if (selectedItems.find((d) => d === i.value) != null)
                    selectedItems = selectedItems.filter(
                      (d) => d !== i.value
                    );
                  else selectedItems.push(i.value);
                  setSelectedPositions([...selectedItems]);
                  handleChange({
                    target: { value: i.value, name: "positionList" },
                  });
                }}
                checked={selectedPositions.find((d) => d === i.value) != null}
                autocomplete="off"
              />
              <label className="btn btn-outline-primary chipbtn" for={i.value}>
                {i.value1}
              </label>
            </div>
          );
        })}
    </div>
  </div>
  )
}

export default ChipsDeduction