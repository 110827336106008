import { Typography } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessages,
  faFileSpreadsheet,
} from "@fortawesome/pro-duotone-svg-icons";

import { FormControl, TextField } from "@mui/material";
import { Row } from "react-bootstrap";
import { CardHeader } from "reactstrap";

const ReportHeader = (props) => {
  const role = localStorage.getItem("role");

  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#1975D2",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faFileSpreadsheet}
            className="mx-10  px-3 fs-4"
          />
          <Typography className="fs-5">{props.header}</Typography>
        </div>
      </div>
    </CardHeader>
  );
};

export default ReportHeader;
