import React from "react";
import { Hidden, Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faSave,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import { CardHeader } from "reactstrap";

const HomeHeader = (props) => {
  const { isUpdate, handleSubmit, clearField, configrationJSON } = props;
  return (
    <CardHeader>
      <div>
        <div
          className={`flex flex-1 w-full bg-primary`}
          style={{
            display: "flex",
            flexDirection: "row",
            height: "4.2rem",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
          <Typography className="fs-5">
            {" "}
            {configrationJSON.headerTitle}
          </Typography>

          <div style={{ display: "flex", flex: 1 }}></div>
          <Button
            variant="contained"
            className="m-10 mx-2 xs:mx-10 min-w-0"
            style={{
              color: "#234d10",
              backgroundColor: "#ffffff",
            }}
            onClick={handleSubmit}
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "#24695c", marginRight: "10px" }}
            />
            <Hidden smDown>
              <Typography className="text-12">
                {" "}
                {isUpdate ? "Update" : "Save"}{" "}
              </Typography>
            </Hidden>
          </Button>
          {isUpdate ? (
            <Button
              variant="contained"
              className="m-10 mx-2 xs:mx-10 min-w-0"
              style={{
                color: "#d0efe9",
                backgroundColor: "#61bdaa",
              }}
              onClick={clearField}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ color: "#24695c", marginRight: "10px" }}
              />
              <Hidden smDown>
                <Typography className="text-12"> Cancel </Typography>
              </Hidden>
            </Button>
          ) : null}
        </div>
      </div>
    </CardHeader>
  );
}

export default HomeHeader;
