import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { get } from "../../../../_helper/api_helper";
const sliceName = "employeeSlice";

export const fetchEmployeeSearch = createAsyncThunk(
  `${sliceName}/fetchEmployeeSearch`,
  async (payload) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        employeeSearched: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        employeeSearched: [],
      };
    }
  }
);

export const fetchEmployeeIssues = createAsyncThunk(
  `${sliceName}/fetchallIssues`,
  async (payload) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/fetchall-issues`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        fetchEmployeeIssues: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        fetchEmployeeIssues: [],
      };
    }
  }
);

export const fetchEmployeeDeductionPlan = createAsyncThunk(
  `${sliceName}/fetchEmployeeDeductionPlan`,
  async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}employeeDeduction`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        empDuductionPlans: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        empDuductionPlans: [],
      };
    }
  }
);

export const subscribeEmployeeDeductionPlan = createAsyncThunk(
  `${sliceName}/subscribeEmployeeDeductionPlan`,
  async (data) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}employeeDeduction/subscribe`,
        {
          DeductionCode: data,
          DeductionDate: "2022-01-05",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      // return {
      //   empDuductionPlans: data,
      // };
    } catch (e) {
      console.log("Master", e.message);
      return {
        error: e.message,
        empDuductionPlans: [],
      };
    }
  }
);

export const GetAllEmployees = createAsyncThunk(
  `${sliceName}/GetAllEmployee`,
  async (page) => {
    try {
      const data = await get(`${process.env.REACT_APP_WEB_API_URL}Employee/GetAllEmployee?MaxCount=500000&SkipCount=0`)
      return data;
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const chartSlice = createSlice({
  name: sliceName,
  initialState: {
    empLists: [],
    empDuductionPlans: [],
    employeeSearched: [],
    fetchEmployeeIssues: [],
    isEmpListLoading:false
  },
  extraReducers: {
    [fetchEmployeeDeductionPlan.fulfilled]: (state, action) => {
      state.empDuductionPlans = action.payload.empDuductionPlans;
    },
    [fetchEmployeeSearch.fulfilled]: (state, action) => {
      state.employeeSearched = action.payload.employeeSearched;
    },
    [fetchEmployeeIssues.fulfilled]: (state, action) => {
      state.fetchEmployeeIssues = action.payload.fetchEmployeeIssues;
    },
    [GetAllEmployees.pending]: (state, action) => {
      state.isEmpListLoading = true
    },
    [GetAllEmployees.fulfilled]: (state, action) => {
      state.empLists = action.payload.data;
      state.isEmpListLoading = false;
    },
    [GetAllEmployees.rejected]: (state, action) => {
      state.isEmpListLoading = false
    }
  },
});

export default chartSlice.reducer;
