import React, { useEffect, useRef, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded";
import PayrollHeader from "./component/payrollHeader";
import PayrollContent from "./component/payrollContent";
// import Accordion from "@material-ui/core/Accordion";
import { Accordion, Alert, Card } from "@mui/material";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionSummary } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";

// import Typography from "@material-ui/core/Typography";
import { Typography } from "@mui/material";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployeeDetail from "./component/employeeDetail";
import EmployeeAccountDetail from "./component/employeeAccountDetail";
import axios from "axios";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import { Button } from "@material-ui/core";

import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  faClock,
  faCircleNotch,
  faMoneyCheckDollar,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import { Row } from "react-bootstrap";
import { useAuth } from "oidc-react";
import {
  getUserId,
  getUserRole,
  KeyGen,
  payCodeFilter,
  ShowError,
} from "../../app/utility/helper";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Col } from "reactstrap";
import { GetEmployeeAuthorizedPayrollData } from "../OverTime/OverTimeSlice";

const filter = createFilterOptions();

let lastKnownScrollPosition = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiExpansionPanelSummary-content": {
      padding: 0,
    },

    "& .MuiPaper-root": {
      background: "none",
      display: "block",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "70%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
}));

const PayrollWorkTime = (props) => {
  const { employeeAuthorizedPayTimeCodesList } = useSelector(
    (state) => state.OverTimeSlice
  );
  const [selectedPayPeriod, setSelectedPayPeriod] = React.useState({});

  const sampleData = {
    jobtype: "REG",
    id: KeyGen(7),
    date:
      moment(new Date()).format("YYYY-MM-DD") >= selectedPayPeriod?.EndDate
        ? selectedPayPeriod?.EndDate
        : moment(new Date()).format("YYYY-MM-DD"),
    time_from: "09:00", //moment(new Date()),
    time_to: "", //moment(new Date()),
    time_from1: "", //moment(new Date()),
    time_to1: "", //moment(new Date()),
    hrs: "00:00",
    isNew: true,
    mn: 0,
    mn1: 0,
    code: null,
    jobOrder: null,
    hjobtype: "",
    rowstatus: "Confirm",
  };
  const sampleDatav1 = {
    jobtype: " ",
    id: KeyGen(7),
    date:
      moment(new Date()).format("YYYY-MM-DD") >= selectedPayPeriod?.EndDate
        ? selectedPayPeriod?.EndDate
        : moment(new Date()).format("YYYY-MM-DD"),
    time_from: "09:00", //moment(new Date()),
    time_to: "", //moment(new Date()),
    time_from1: "", //moment(new Date()),
    time_to1: "", //moment(new Date()),
    hrs: "00:00",
    isNew: true,
    mn: 0,
    mn1: 0,
    code: null,
    jobOrder: null,
    hjobtype: "",
    rowstatus: "Confirm",
    ApproveToTime: null,
    ApproveFromTime: null,
  };
  const classes = useStyles();
  const [price, setPrice] = useState(0);
  const [rowsData, setRowsData] = useState([sampleData]);
  const [rowsOTData, setRowsOTData] = useState([sampleData]);
  const [empValue, setEmpValue] = useState({
    EmployeeName: null,
    ID: null,
  });

  const { userData } = useAuth();
  const userRole = getUserRole(userData);

  let params = useParams();
  // let empId = params.id ? params.id : getUserId(userData);

  const [empId, setEmpId] = useState(
    params.id ? params.id : getUserId(userData)
  );
  useEffect(() => {
    setEmpId(params.id ? params.id : getUserId(userData));
  }, [userData, params]);
  const [EmployeeData, setEmloyeeDAta] = useState([]);
  const dispatch = useDispatch();

  // const [scrollTop, setScrollTop] = useState(false);

  let ppeId = params.ppe ? params.ppe : "";
  // console.log("Params... :: ", params);
  // const dispatch = useDispatch();
  const pageLayout = useRef(null);
  const [expanded, setExpanded] = React.useState({
    payroll: true,
    employee: false,
  });
  const payrollRef = useRef();
  const [weekData, setWeekData] = useState({
    normal_hour: 0,
    annual_leave_hour: 0,
    sick_leave_hour: 0,
    overtime_hour: 0,
    night_diff_hour: 0,
    hazardous_hour: 0,
  });
  const getEmpData = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        {
          tableName: "EmployeeMaster",
          params: [
            {
              value: 0,
              operator: ">=",
              column: "ID",
              dataType: "bigint",
              columnName: "ID",
            },
          ],
          columns: "ID,EmployeeName",
          page: 1,
          limit: 100000000,
          orderBy: "ID",
          sortBy: "DESC",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master timecode", response.data);
        setEmloyeeDAta(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getEmpValidation = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("E1111 code", response.data?.result);
        let rdata = response.data?.result;
        if (rdata.length) {
          rdata.map((i, index) => {
            return (i["Abberv"] = i.PayCode_PayTimeCode.Abberv);
          });
          console.log("E1111 code result", rdata);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getEmpData(); // This is require to show emp list in auto search dropdown
  }, []);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingSearchEmp, setIsloadingSearchEmp] = useState(false);

  const [hourData, setHourData] = useState({
    REG: 0,
    NDIF: 0,
    HAZARDOUS: 0,
  });
  const [chatOpen, setChatOpen] = React.useState(false);
  const [paytimeCode, setPaytimeCode] = React.useState([]);
  const [allPaytimeCode, setALLPaytimeCode] = React.useState([]);
  const [paytimeCodeByID, setPaytimeCodeByID] = React.useState([]);
  const [filterPaycodeByRole, setFilterPaycodeByRole] = React.useState([]);

  const [allPayPeriod, setAllPayPeriod] = React.useState({});

  const [employeeProcessPayTimes, setEmployeeProcessPayTimes] = useState();
  //EmployeeStatuses: userInfo.EmployeeStatuses
  const [employeeStatuses, setEmployeeStatuses] = useState();
  //EmployeeStatuses: userInfo.EmployeeStatuses
  const [userObject, setUserObject] = useState();

  const UserID = getUserId(userData);
  const role = getUserRole(userData);
  console.log("getUserId(userData)", userObject);
  const [isLoadTimesheetData, setIsLoadTimesheetData] = useState(false);

  useEffect(() => {
    const filterPaycodes = payCodeFilter(
      paytimeCode,
      paytimeCodeByID,
      role,
      null,
      { DPDV: userObject?.DPDV }
    );
    setFilterPaycodeByRole(filterPaycodes);
    console.log("filterPaycodes", paytimeCodeByID, filterPaycodes);
  }, [paytimeCode, paytimeCodeByID]);

  console.log("UserID", UserID);
  //EHR_ON_SET_USER

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const handleChatClose = () => {
    setChatOpen(!chatOpen);
  };

  useEffect(() => {
    if (selectedPayPeriod?.ID && userObject?.ID) {
      dispatch(
        GetEmployeeAuthorizedPayrollData({
          payPeriodId: selectedPayPeriod?.ID || "",
          employeeId: userObject?.ID || "",
        })
      );
    }
  }, [selectedPayPeriod, userObject]);
  const getPayPeriod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payperiod`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master response", response.data);

        // let createObj = [];
        if (response.data.length) {
          let resPaycode = response.data;
          let tody = moment().format("YYYY-MM-DD");
          let allpayperiod = resPaycode.filter((i) => {
            return i.StartDate <= tody;
          });
          setAllPayPeriod(allpayperiod);
          if (!params.ppe) {
            let currentPayPeriod = resPaycode.find((i) => {
              return i.StartDate <= tody && i.EndDate >= tody;
            });
            if (currentPayPeriod === undefined) {
              setSelectedPayPeriod(resPaycode[resPaycode.length - 1]);
            } else {
              setSelectedPayPeriod(currentPayPeriod);
            }
            console.log("currentPayPeriod", currentPayPeriod);
          } else {
            let currentPayPeriodByID = resPaycode.find((i) => {
              return i.ID == ppeId;
            });
            setSelectedPayPeriod(currentPayPeriodByID);
          }
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getMasterData = () => {
    console.log("get timecode");
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master response", response.data);

        let createObj = [];
        if (response.data.length) {
          response.data.map((item, index) => {
            createObj.push({
              value: item.Abberv,
              label: item.Abberv,
              title: item.Description,
              code: item.Code,
              multi: item.Multi,
              EffectDate: item.EffectDate,
              ExpireDate: item.ExpireDate,
              color: item.color,
              name: "jobtype",
              OrderNo: item.OrderNo,
            });
            return true;
          });
        }
        setPaytimeCode(createObj);
        // setALLPaytimeCode(createObj);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  // useEffect(() => {
  //   let filterPayCodeRole = payCodeFilter(allPaytimeCode, paytimeCodeByID, userRole);
  //   console.log(allPaytimeCode.length,'@#@', paytimeCodeByID.length,'Master111 Data',filterPayCodeRole);
  //   setPaytimeCode(filterPayCodeRole);
  // },[paytimeCodeByID,allPaytimeCode])

  const getPaycodeBYID = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        console.log("Master response", response.data);

        setPaytimeCodeByID(response.data.result);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getUserInfo = () => {
    //Employee Data
    if (!empId) {
      return false;
    }

    axios
      .get(
        `${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeePayrollMasterDetails?employeeId=${empId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      )
      .then((response) => {
        let userInfo = response.data.data.employeePayrollMaster;
        let employeeStatuses = response.data.data.employeeStatuses;
        let departmentDivisionMaster =
          response.data.data.departmentDivisionMaster;
        console.log("userinfo", userInfo);

        // toast.success('Record Found', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        let newObj = {
          DPDV: userInfo.dpdv,
          // "EmployeeAllowedLeaves":userInfo.EmployeeAllowedLeaves,
          EmployeeName: userInfo.employeeName,
          DPDV_DepartmentDivisionMaster: departmentDivisionMaster,
          // "EmployeeProcessPayTimes":userInfo.EmployeeProcessPayTimes,
          EmployeeStatuses: employeeStatuses,
          ID: userInfo.employeeID,
          Seq: userInfo.seq,
          Status: userInfo.status,
          Rate: userInfo.rate ? userInfo.rate : 16.32,
          EmployeementDate: userInfo?.date_Employment,
        };

        // dispatch({
        //   type: "EmployeeProcessPayTimes",
        //   payload: {
        //     EmployeeProcessPayTimes: userInfo.EmployeeProcessPayTimes,
        //   },
        // });
        setEmployeeProcessPayTimes(userInfo.EmployeeProcessPayTimes);

        // dispatch({
        //   type: "EmployeeStatuses",
        //   payload: { EmployeeStatuses: userInfo.EmployeeStatuses },
        // });
        setEmployeeStatuses(userInfo.EmployeeStatuses);
        // dispatch({ type: "EHR_ON_SET_USER", payload: newObj });
        setUserObject(newObj);
        setIsloadingSearchEmp(false);
      })
      .catch((error) => {
        toast.error("Employee Not Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsloadingSearchEmp(false);
      });
  };

  useEffect(() => {
    if (empId) {
      setIsloadingSearchEmp(true);
      getUserInfo();
      getMasterData();
      getPaycodeBYID(empId);
    }
    getPayPeriod();
    if (params.ppe) {
      setSelectedPayPeriod(params.ppe);
    }
  }, [empId]);
  const searchByEmpId = () => {
    if (!empId) {
      ShowError("Please Select Employee");
      return false;
    }
    setIsloadingSearchEmp(true);
    getUserInfo();
    getMasterData();
    getPayPeriod();
    //getEmpValidation(empId);
    if (params.ppe) {
      setSelectedPayPeriod(params.ppe);
    }
    setTimeout(() => {
      setIsloadingSearchEmp(false);
    }, 3000);
  };

  const handleClose = (e) => {
    console.log("handleCloseOption", e.target.value);
  };
  let ticking = false;

  // document.addEventListener("scroll", (event) => {
  //   lastKnownScrollPosition = window.scrollY;
  //   window.requestAnimationFrame(() => {
  //     let timer = null;
  //     if (window.scrollY >= 95) {
  //         timer = setTimeout(() => {
  //           setScrollTop(true);
  //         }, 100);
  //     }
  //   return () => {
  //       if (timer) {
  //         // setScrollTop(false);
  //       }
  //   };
  //     });
  // });
  return (
    <>
      {/* <div style={{ height:"100px",width:"83%", backgroundColor:"white", display:"flex", alignItems:"center", justifyContent:"center", position:"fixed", top:"82px", zIndex:10000}}> */}

      {/* </div> */}

      {/* { scrollTop ?
        <div style={ { position: "fixed",top:"80px",width:"-webkit-fill-available",marginRight:"15px"} }  >
          <PayrollHeader
            { ...props }
            
              handleChatClose={handleChatClose}
              chatOpen={chatOpen}
              payPeriod={selectedPayPeriod}
              allPayPeriod={ allPayPeriod }
              userId={empId}
              setPayPeriod={(info) => {
                setSelectedPayPeriod(info);
              }}
        />
        </div>
     :
     <PayrollHeader
              {...props}
              handleChatClose={handleChatClose}
              chatOpen={chatOpen}
              payPeriod={selectedPayPeriod}
              allPayPeriod={ allPayPeriod }
              userId={empId}
              setPayPeriod={(info) => {
                setSelectedPayPeriod(info);
              }}
        />
      } */}
      <Container
        fluid={true}
        className="dashboard-default-sec"
        style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
          <PayrollHeader
            {...props}
            handleChatClose={handleChatClose}
            chatOpen={chatOpen}
            payPeriod={selectedPayPeriod}
            allPayPeriod={allPayPeriod}
            userId={empId}
            setPayPeriod={(info) => {
              setSelectedPayPeriod(info);
            }}
            setIsLoadTimesheetData={setIsLoadTimesheetData}
            isLoadTimesheetData={isLoadTimesheetData}
          />
        </div>
        <div className="page-content">
          {console.log("saasfasfas ::: ", userObject?.EmployeeName)}

          {!empId && (
            <Alert severity="error" className="mt-2 mb-2">
              Employee is not selected. Please select first
            </Alert>
          )}

          {getUserRole(userData) == "admin" && userObject?.EmployeeName && (
            <div style={{ padding: "16px 2px" }}>
              <Card
                style={{ gap: "10px" }}
                elevation={4}
                className=" p-10 d-flex ">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={EmployeeData}
                  // getOptionLabel={(options) => {
                  //   return options.EmployeeName + "-" + options.ID;
                  // }}

                  size="small"
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option?.EmployeeName + "-" + option.ID;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option?.EmployeeName + "-" + option.ID;
                  }}
                  onChange={(e, newValue) => {
                    console.log("onChange", newValue);

                    // setEmpId(newValue?.ID);
                    // setEmpValue({
                    //   EmployeeName: newValue,
                    // });

                    if (typeof newValue === "object" && !newValue?.inputValue) {
                      setEmpId(newValue?.ID);
                      setEmpValue({
                        EmployeeName: newValue?.EmployeeName,
                        ID: newValue?.ID,
                      });

                      // if (e.code == "Enter") {
                      //   console.log("case 1", e.code, empValue);
                      //   searchByEmpId();
                      // }
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input

                      console.log("case 2");
                      setEmpId(newValue.inputValue);

                      setEmpValue({
                        EmployeeName: {
                          ID: newValue.inputValue,
                          EmployeeName: newValue.inputValue,
                        },
                      });
                    } else {
                      console.log("case 3");
                      setEmpValue({ ID: null, EmployeeName: null });
                    }
                  }}
                  defaultValue={{
                    ID: empId ? empId : "",
                    EmployeeName: userObject?.EmployeeName
                      ? userObject?.EmployeeName
                      : " ",
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.EmployeeName
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        EmployeeName: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Employee" />
                  )}
                />
                <Button
                  size="medium"
                  className="bg-primary"
                  style={{ color: "white" }}
                  onClick={(e) => {
                    e.preventDefault();
                    searchByEmpId();
                  }}
                  variant="contained">
                  Search
                </Button>
              </Card>
            </div>
          )}
          <div className="w-full">
            <EmployeeDetail
              data={weekData}
              price={price}
              setPrice={setPrice}
              hourData={hourData}
              rowsData={rowsData}
              paytimeCode={filterPaycodeByRole}
              userInfo={userObject}
              EmployeeStatuses={employeeStatuses}
              isloading={isloadingSearchEmp}
            />
            <div className={`${classes.root} w-100`}>
              <div
                classes={{
                  root: "w-full h-full",
                  header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                  contentWrapper: "p-0",
                  contentCard: "rounded-none",
                }}>
                <div className={`${classes.root} w-full`}>
                  <Card>
                    <Accordion
                      className={classes.root}
                      expanded={expanded.payroll}
                      onChange={handleChange("payroll")}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="bg-gray-200 sub-header w-100 p-1">
                        <FontAwesomeIcon
                          icon={faRectangleList}
                          className="sub-header"
                          style={{
                            color: "#1569C7",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        <Typography className={classes.heading}>
                          Work Time{" "}
                          <b
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}>
                            {"  "}{" "}
                            {` ${
                              userObject?.EmployeeName
                                ? userObject?.EmployeeName
                                : "Select  "
                            }`}{" "}
                            / {`${userObject?.ID ? userObject?.ID : ""}`}
                          </b>{" "}
                          {` (${
                            selectedPayPeriod?.StartDate
                              ? moment(selectedPayPeriod?.StartDate).format(
                                  "MM-DD-YYYY"
                                )
                              : ""
                          } To ${
                            selectedPayPeriod?.EndDate
                              ? moment(selectedPayPeriod?.EndDate).format(
                                  "MM-DD-YYYY"
                                )
                              : ""
                          }))`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="p-0 page-body-wrapper">
                        <PayrollContent
                          ref={payrollRef}
                          {...props}
                          userInfo={userObject}
                          rowsOTData={rowsOTData}
                          setRowsOTData={setRowsOTData}
                          rowsData={rowsData}
                          sampleData={sampleData}
                          setRowsData={setRowsData}
                          setWeekData={setWeekData}
                          setIsLoading={setIsLoading}
                          isloading={isloading}
                          price1={price}
                          paytimeCodeByID={paytimeCodeByID}
                          allPayPeriod={allPayPeriod}
                          weekData={weekData}
                          paytimeCode={paytimeCode}
                          setHourData={setHourData}
                          hourData={hourData}
                          payPeriod={selectedPayPeriod}
                          EmployeeProcessPayTimes={employeeProcessPayTimes}
                          userId={empId}
                          sampleDatav1={sampleDatav1}
                          isLoadTimesheetData={isLoadTimesheetData}
                          setIsLoadTimesheetData={setIsLoadTimesheetData}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                  <Card style={{ paddingBottom: "80px" }} className="mt-3 mb-3">
                    <Accordion
                      className={classes.root}
                      expanded={expanded.employee}
                      onChange={handleChange("employee")}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="bg-gray-200 sub-header w-100 p-1">
                        <FontAwesomeIcon
                          icon={faRectangleList}
                          className="sub-header"
                          style={{
                            color: "#1569C7",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        <Typography className={classes.heading}>
                          Employee Account Balance
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="page-body-wrapper">
                        <EmployeeAccountDetail
                          accountBalanceList={{
                            employeeAuthorizedPayTimeCodesList,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </div>
              </div>

              {/* ----------------------- */}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PayrollWorkTime;

// const PayrollDetailData = withFormik({
//   enableReinitialize: true,
//   mapPropsToValues: ({ payrollData }) => ({
//     payrollList: payrollData || [
//       {
//         paycode: "",
//         f: "",
//         job_order: "",
//         hrs: "",
//         time_from: moment(new Date()).format("HH:mm"),
//         time_to: moment(new Date()).format("HH:mm"),
//         typh: true,
//         normal_profile: false,
//         date: moment(new Date()).format("MM-DD-YYYY"),
//       },
//     ],
//   }),
//   validationSchema: Yup.object().shape({
//     payrollList: Yup.array().of(
//       Yup.object().shape({
//         paycode: Yup.string().required("First Name is required"),
//         f: Yup.string().required("Last Name is required"),
//         job_order: Yup.string().required("First Name is required"),
//         hrs: Yup.string().required("Last Name is required"),
//         time_from: Yup.string().required("First Name is required"),
//         time_to: Yup.string().required("Last Name is required"),
//         typh: Yup.string().required("First Name is required"),
//       })
//     ),
//   }),
//   async handleSubmit(values, props) {
//     props.props.updateHealthInsDetail({
//       payrollData: values.payrollList,
//     });
//     props.props.showMessage({
//       message: "Saved Successfully",
//       variant: "success",
//     });
//   },
// })(PayrollWorkTime);

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ updatePayrollDetail, showMessage }, dispatch);
// };

// const mapStateToProps = (state) => {
//   return {
//     payrollData: state.PayrollWorkData.PayrollWork.payrollDetail.payrollData,
//     employee: state.PayrollWorkData.PayrollWork.employee,
//   };
// };

// export default withReducer(
//   "PayrollWorkData",
//   reducer
// )(connect(mapStateToProps, mapDispatchToProps)(PayrollDetailData));
