import React, { useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

const CustomCheckBox = ({ name, className, errorMessage, onChange, label, labelPlacement, value, ...props }) => {
    return <FormControl
        size="small"
        margin="dense"
        error={!!errorMessage}
    >
        <FormControlLabel
            name={name}
            value={value}
            defaultChecked={value}
            defaultValue={value}
            control={
                <Checkbox
                    onChange={onChange}
                    defaultChecked={value}
                    value={value}
                    checked={value}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
            className="m-0"
        />
        <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
};

export default CustomCheckBox;