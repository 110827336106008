import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReportHeader from '../ReportHeader';
import { makeStyles } from "@material-ui/core/styles";
import CombinedDeductionReportLayout from './component/CombinedDeductionReportLayout';
import { COMBINED_DEDUCTION_DATA } from './request-table-data';
import RequestTable from './component/deduction-request-table';
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      ".&.MuiAccordionSummary-content": {
        padding: 0,
      },
      "& .MuiPaper-root": {
        background: "none",
      },
      margin: "0 !important",
      borderTopWidth: 1,
      borderTopColor: "#ccc",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      fontWeight: 500,
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
      // color: theme.palette.text.secondary,
    },
}));

const CombinedDeductionReport = (props) => {

  const classes = useStyles();
  const [tableList, setTableList] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });

  const pageLayout = useRef(null);

  useEffect(() => {
    setTableList(COMBINED_DEDUCTION_DATA);
  }, []);

    return (
      <Container fluid={true} style={{ overflow: "auto" }}>
            <div  style={{ position: "sticky", top: 0, zIndex: 1 }}
            ref={pageLayout}>
                <ReportHeader {...props} header="Combined Deduction Summary Reports" />
            </div>
            <div className='page-content' >

            <CombinedDeductionReportLayout
                {...props}
                role={"admin"}
            />

            <div
                classes={{
                root: "w-full h-full",
                header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                contentWrapper: "p-0",
                contentCard: "rounded-none",
                }}
            >
                <div className={`${classes.root} w-full `}>
                    <RequestTable
                        {...props}
                        role={"admin"}
                        requestDetail={tableList}
                        detail={detail}
                        // setHoursQuery={setHoursQuery}
                        setDetail={setDetail}
                        userDetail={null}
                        apiCall={apiCall}
                        setApiCall={setApiCall}
                    />
                </div>
            </div>
            </div>
            
        </Container>
    )
}

export default CombinedDeductionReport;