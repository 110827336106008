import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTimeSchedular from "./ReactTimeSchedular";
import { FormControl, TextField } from "@mui/material";
import moment from "moment";

const ReactTimeSheet = () => {
  const [paytimeCodeList, setPaytimecodeList] = useState([]);
  const [payPeriod, setPayPeriod] = useState([]);
  const [allPayPeriod, setAllPayPeriod] = useState([]);
  console.log("payPeriod", payPeriod);
  const getPayTimeCode = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Get api/paytimecode  response", response.data);
        const finalData = response?.data.map((i) => {
          return {
            label: i.Abberv,
            value: i.Abberv,
            code: i.Code,
            color: i.color,
          };
        });
        setPaytimecodeList(finalData);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPayPeriod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payperiod`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master response", response.data);

        // let createObj = [];
        if (response.data.length) {
          let resPaycode = response.data;
          let tody = moment().format("YYYY-MM-DD");
          let allpayperiod = resPaycode.filter((i) => {
            return i.StartDate <= tody;
          });
          setAllPayPeriod(allpayperiod);

          let currentPayPeriod = resPaycode.find((i) => {
            return i.StartDate <= tody && i.EndDate >= tody;
          });
          setPayPeriod(currentPayPeriod);
          console.log("currentPayPeriod", currentPayPeriod);
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getPayTimeCode();
    getPayPeriod();
  }, []);
  return (
    <div>
      <div style={{ display: "flex", flex: 1 }} className="mx-4">
        <FormControl className="payperiodRange">
          <TextField
            size="small"
            select
            name="payperiod_range"
            id="payperiod_range"
            className="min-w-128 text-16"
            style={{ color: "red", backgroundColor: "ActiveBorder" }}
            variant="outlined"
            value={payPeriod ? payPeriod["ID"] : 0}
            margin="dense"
            onChange={(event) => {
              console.log("setData", JSON.stringify(event.target.value));
              let info = allPayPeriod.find((i) => {
                return i.ID.toString() === event.target.value + "";
              });
              console.log("info", info);
              setPayPeriod(info);
            }}
            SelectProps={{
              native: true,
            }}
            inputProps={{
              style: { color: "white" },
            }}
          >
            {allPayPeriod.length &&
              allPayPeriod.map((option, index) => (
                <option
                  key={index}
                  value={option.ID}
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  {option.StartDate} {option.EndDate}
                </option>
              ))}
          </TextField>
        </FormControl>
      </div>
      <ReactTimeSchedular
        EmployeeID={10000000}
        paytimeCodeList={paytimeCodeList}
        filteredEvent={[]}
        payPeriod={payPeriod}
      />
    </div>
  );
};

export default ReactTimeSheet;
