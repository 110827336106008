import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { addOrUpdateEmployeeW4Record, getEmployeeW4Record } from "../../../app/utility/axiosService";
import StateTaxW4Info from "./stateTaxW4Info";
import FederalW4Info from "./federalW4Info";
import { toast } from "react-toastify";

const EmployeeW4 = (props) => {
  const [employeeDefaultW4Data, setEmployeeDefaultW4Data] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState({
    earlierVersionW4: false,
    nonResidentAlien: false,
    filingStatus: null,
    spouseWorks: false,
    dependentAmount: '',
    otherIncomeAmount: '',
    deductionAmount: '',
    allowances: '',
    additionalWithholdingAmount: '',
    isExemptFromFederalITWithholding: false,
    isExemptFromMedicareTax: false,
    isExemptFromSocialSecurityTax: false,
    numberOfQualifyingChildrenUnderAge17: '',
    numberOfOtherDependents: '',
    state: null,
    regAllowances: '',
    allowancesFromEstDeduction: '',
    additionalWithholding: '',
    isExemptFromStateWithholding: false,
    isExemptFromSDI: false,
    isWashingtonLIRate: false,
    washingtonLIRate: '',
    isSalaryOrhourly: true,
    isExemptFromFLI: false,
    isExemptFromMLI: false
  });

  useEffect(() => {
    if (!isUpdate) {
      setUserInfo({
        ...userInfo,
        earlierVersionW4: employeeDefaultW4Data?.isW4Pre2019Format,
        nonResidentAlien: employeeDefaultW4Data?.nonResidentalien,
        filingStatus: employeeDefaultW4Data?.filingStatus,
        spouseWorks: employeeDefaultW4Data?.isWorkMoreThanOneJobOrYourSpouseWorks,
        dependentAmount: employeeDefaultW4Data?.anyDependentAmount,
        otherIncomeAmount: employeeDefaultW4Data?.anyOtherIncomeAmount,
        deductionAmount: employeeDefaultW4Data?.anyDeductionAmount,
        allowances: employeeDefaultW4Data?.allowances,
        additionalWithholdingAmount: employeeDefaultW4Data?.additionalWithholding,
        isExemptFromFederalITWithholding: employeeDefaultW4Data?.isFederalIncomeTaxWithholding,
        isExemptFromMedicareTax: employeeDefaultW4Data?.isExemptFromMedicareTax,
        isExemptFromSocialSecurityTax: employeeDefaultW4Data?.isExemptFromSocialSecurityTax,
        numberOfQualifyingChildrenUnderAge17: employeeDefaultW4Data?.numberOfQualifyingChildrenUnderAge17,
        numberOfOtherDependents: employeeDefaultW4Data?.numberOfOtherDependents,
        state: employeeDefaultW4Data?.stateCode,
        regAllowances: employeeDefaultW4Data?.regularAllowances,
        allowancesFromEstDeduction: employeeDefaultW4Data?.allowancesFromEstimatedDeductions,
        additionalWithholding: employeeDefaultW4Data?.additionalWithholdingAmount,
        isExemptFromStateWithholding: employeeDefaultW4Data?.isStateIncomeTaxWithholding,
        isExemptFromSDI: employeeDefaultW4Data?.isStateDisabilityInsurance,
        isWashingtonLIRate: employeeDefaultW4Data?.isWashingtonLAndIRate,
        washingtonLIRate: employeeDefaultW4Data?.washingtonLAndIRate,
        isSalaryOrhourly: employeeDefaultW4Data?.isSalariedOrHourlyEmployee,
        isExemptFromFLI: employeeDefaultW4Data?.isFamilyLeaveInsurance,
        isExemptFromMLI: employeeDefaultW4Data?.isMedicalLeaveInsurance,
      });
    }
  }, [JSON.stringify(employeeDefaultW4Data), isUpdate]);

  useEffect(() => {
    fetchEmployeeW4Record();
  }, [props?.userInfo?.ID]);

  const fetchEmployeeW4Record = async () => {
    if (props?.userInfo?.ID) {
      const res = await getEmployeeW4Record(props?.userInfo?.ID);
      if (res?.status === 200) {
        setEmployeeDefaultW4Data(res?.data);
      }
    }
  };

  const checkValid = () => {
    let isFederal = false;
    let isState = false;
    const { earlierVersionW4, nonResidentAlien, filingStatus, spouseWorks, dependentAmount, otherIncomeAmount, deductionAmount, allowances,
      additionalWithholdingAmount, isExemptFromFederalITWithholding, isExemptFromMedicareTax, isExemptFromSocialSecurityTax,
      numberOfQualifyingChildrenUnderAge17, numberOfOtherDependents, state, regAllowances, allowancesFromEstDeduction, additionalWithholding,
      isExemptFromStateWithholding, isExemptFromSDI, isWashingtonLIRate, washingtonLIRate, isSalaryOrhourly, isExemptFromFLI, isExemptFromMLI } = userInfo;
    if (userInfo) {
      if (earlierVersionW4 && !nonResidentAlien && filingStatus && ((isExemptFromFederalITWithholding && !allowances && !additionalWithholdingAmount) || (!isExemptFromFederalITWithholding && allowances && additionalWithholdingAmount)) && 
      (isExemptFromMedicareTax === false || isExemptFromMedicareTax === true) && (isExemptFromSocialSecurityTax === false || isExemptFromSocialSecurityTax === true) && numberOfQualifyingChildrenUnderAge17 && numberOfOtherDependents) {
        isFederal = true;
      }
      if (earlierVersionW4 && nonResidentAlien && filingStatus == 1 && allowances && additionalWithholdingAmount && !isExemptFromFederalITWithholding && (isExemptFromMedicareTax === false || isExemptFromMedicareTax === true) && 
      (isExemptFromSocialSecurityTax === false || isExemptFromSocialSecurityTax === true) && numberOfQualifyingChildrenUnderAge17 && numberOfOtherDependents) {
        isFederal = true;
      }
      if (!earlierVersionW4 && nonResidentAlien && filingStatus == 1 && (spouseWorks === false || spouseWorks === true) && dependentAmount && otherIncomeAmount && deductionAmount && additionalWithholdingAmount && !isExemptFromFederalITWithholding &&
      (isExemptFromMedicareTax === false || isExemptFromMedicareTax === true) && (isExemptFromSocialSecurityTax === false || isExemptFromSocialSecurityTax === true) && numberOfQualifyingChildrenUnderAge17 && numberOfOtherDependents) {
        isFederal = true;
      }
      if (!earlierVersionW4 && !nonResidentAlien && filingStatus && ((isExemptFromFederalITWithholding && !spouseWorks && (isExemptFromMedicareTax === false || isExemptFromMedicareTax === true) && (isExemptFromSocialSecurityTax === false || isExemptFromSocialSecurityTax === true)) || 
      (!isExemptFromFederalITWithholding && (spouseWorks === false || spouseWorks === true) && dependentAmount && otherIncomeAmount && deductionAmount && additionalWithholdingAmount && (isExemptFromMedicareTax === false || isExemptFromMedicareTax === true) && 
      (isExemptFromSocialSecurityTax === false || isExemptFromSocialSecurityTax === true))) && numberOfQualifyingChildrenUnderAge17 && numberOfOtherDependents) {
        isFederal = true;
      }
      // State taxes validation
      if (state === 'CA' && regAllowances && allowancesFromEstDeduction && additionalWithholding &&
        (isExemptFromStateWithholding === false || isExemptFromStateWithholding === true) && (isExemptFromSDI === false || isExemptFromSDI === true)) {
          isState = true;
      }
      if (state === 'HI' && regAllowances && additionalWithholding && (isExemptFromSDI === false || isExemptFromSDI === true)) {
        isState = true;
      }
      if (state === 'WA' && ((isWashingtonLIRate && washingtonLIRate && (isSalaryOrhourly === false || isSalaryOrhourly === true) && (isExemptFromFLI === false || isExemptFromFLI === true) && (isExemptFromMLI === false || isExemptFromMLI === true)) || 
      (!isWashingtonLIRate && (isExemptFromFLI === false || isExemptFromFLI === true) && (isExemptFromMLI === false || isExemptFromMLI === true)))) {
        isState = true;
      }
    }
    return (isFederal && isState);
  };

  const saveData = async () => {
    const { earlierVersionW4, nonResidentAlien, filingStatus, spouseWorks, dependentAmount, otherIncomeAmount, deductionAmount, allowances,
      additionalWithholdingAmount, isExemptFromFederalITWithholding, isExemptFromMedicareTax, isExemptFromSocialSecurityTax,
      numberOfQualifyingChildrenUnderAge17, numberOfOtherDependents, state, regAllowances, allowancesFromEstDeduction, additionalWithholding,
      isExemptFromStateWithholding, isExemptFromSDI, isWashingtonLIRate, washingtonLIRate, isSalaryOrhourly, isExemptFromFLI, isExemptFromMLI } = userInfo;

    if (!checkValid) {
      toast.error('Please validate values!');
      return;
    }

    const payload = {
      "id": employeeDefaultW4Data ? employeeDefaultW4Data?.id : 0,
      "employeeID": Number(props?.userInfo?.ID),
      "isW4Pre2019Format": earlierVersionW4 ? earlierVersionW4 : null,
      "nonResidentalien": nonResidentAlien ? nonResidentAlien : null,
      "filingStatus": filingStatus ? filingStatus : null,
      "allowances": allowances ? Number(allowances) : null,
      "additionalWithholding": additionalWithholdingAmount ? Number(additionalWithholdingAmount) : null,
      "isFederalIncomeTaxWithholding": isExemptFromFederalITWithholding ? isExemptFromFederalITWithholding : null,
      "isExemptFromMedicareTax": isExemptFromMedicareTax ? isExemptFromMedicareTax : null,
      "isExemptFromSocialSecurityTax": isExemptFromSocialSecurityTax ? isExemptFromSocialSecurityTax : null,
      "isWorkMoreThanOneJobOrYourSpouseWorks": spouseWorks ? Number(spouseWorks) : null,
      "anyDependentAmount": dependentAmount ? Number(dependentAmount) : null,
      "anyOtherIncomeAmount": otherIncomeAmount ? Number(otherIncomeAmount) : null,
      "anyDeductionAmount": deductionAmount ? Number(deductionAmount) : null,
      "numberOfQualifyingChildrenUnderAge17": numberOfQualifyingChildrenUnderAge17 ? Number(numberOfQualifyingChildrenUnderAge17) : null,
      "numberOfOtherDependents": numberOfOtherDependents ? Number(numberOfOtherDependents) : null,
      "stateCode": state ? state : null,
      "regularAllowances": regAllowances ? Number(regAllowances) : null,
      "allowancesFromEstimatedDeductions": allowancesFromEstDeduction ? Number(allowancesFromEstDeduction) : null,
      "additionalWithholdingAmount": additionalWithholding ? Number(additionalWithholding) : null,
      "isStateIncomeTaxWithholding": isExemptFromStateWithholding ? isExemptFromStateWithholding : null,
      "isStateDisabilityInsurance": isExemptFromSDI ? isExemptFromSDI : null,
      "isWashingtonLAndIRate": isWashingtonLIRate ? isWashingtonLIRate : null,
      "washingtonLAndIRate": washingtonLIRate ? Number(washingtonLIRate) : null,
      "isSalariedOrHourlyEmployee": isSalaryOrhourly ? isSalaryOrhourly : null,
      "isFamilyLeaveInsurance": isExemptFromFLI ? isExemptFromFLI : null,
      "isMedicalLeaveInsurance": isExemptFromMLI ? isExemptFromMLI : null,
    }

    const res = await addOrUpdateEmployeeW4Record(payload);
    if (res?.status === 200) {
      toast.success('Data Updated');
    } else toast.error('Something went wrong');

  };

  return (
    <Card>
      <FederalW4Info
        {...props}
        employeeDefaultW4Data={employeeDefaultW4Data}
        userInfo={userInfo}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        setUserInfo={setUserInfo}
        saveData={saveData}
      />
      <StateTaxW4Info
        {...props}
        employeeDefaultW4Data={employeeDefaultW4Data}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
    </Card>
  );
};

export default EmployeeW4;