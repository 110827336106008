import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmployeeByVendor from "./EmployeeByVendor";
import Header1 from "../../HoursReport/component/header1";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";

const VendorDeductionEmployeeList = (props) => {
  const params = useParams();
  const vendorCode = params.vendorId;
  const vendorList = useSelector(({ vendorSlice }) => vendorSlice.vendorList);
  const [vendorInfo, setVenderInfo] = useState({
    Department: "",
    PPE: "",
    VendorName: "",
    VendorNo: "",
  });

  useEffect(() => {
    let info = vendorList.find((i) => {
      return i.VendorNo == vendorCode;
    });
    setVenderInfo(info);
  }, []);
  return (
    <div>
      <Card
        style={{
          marginBottom: "10px",
          overflow: "hidden",
          padding: 0,
          borderRadius: "10px",
        }}
        elevation={3}
      >
        <Header1
          {...props}
          title={`Employee Deduction for ${vendorInfo.VendorName} (PPE: ${vendorInfo.PPE})`}
        />
        <EmployeeByVendor {...props} vendorCode={vendorCode} />
      </Card>
    </div>
  );
};

 

export default VendorDeductionEmployeeList;
