import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox } from "@mui/material";
import React from "react";

function FilterList(props) {
  const { changeHeaderFilter, filterBy, filterListByChecked } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Id"
          name="id"
          value={filterBy.id}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee Id"
          name="employeeID"
          value={filterBy.employeeID}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Employee Seq"
          name="employeeSeq"
          value={filterBy.employeeSeq}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Type"
          name="deductionType"
          value={filterBy.deductionType}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Plan"
          name="deductionPlan"
          value={filterBy.deductionPlan}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="sequence"
          placeholder="Sequence"
          value={filterBy.sequence}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      {/* <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="garnishmentTypeId"
          placeholder="Garnishment Type"
          value={filterBy.garnishmentTypeId}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell> */}

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="paymentMonthlySch"
          placeholder="Payment Monthly Schedule"
          value={filterBy.paymentMonthlySch}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="garnishmentMethod"
          placeholder="Deduction Method"
          value={filterBy.garnishmentMethod}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          format="MM/dd/yyyy"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "startDate", value } });
          }}
          value={filterBy && filterBy.startDate}
          placeholder="Start Date"
          name="startDate"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "endDate", value } });
          }}
          value={filterBy && filterBy.endDate}
          placeholder="End Date"
          name="endDate"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="pctToDeduct"
          placeholder="Percent To Deduct"
          value={filterBy.pctToDeduct}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      {/* <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="pctBasis"
          placeholder="Percent Basis"
          value={filterBy.pctBasis}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell> */}
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="applyToOT"
          placeholder="Apply to OT"
          value={filterBy.applyToOT}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="totalOriginal"
          placeholder="Total Amount Paid"
          value={filterBy.totalOriginal}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="balanceDue"
          placeholder="Total Amount Plan Due"
          value={filterBy.balanceDue}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      {/* <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="paidToDate"
          placeholder="Paid To Date"
          value={filterBy.paidToDate}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell> */}

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="accountNoForVendor"
          placeholder="Vendor AccountNo"
          value={filterBy.accountNoForVendor}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="deductionPlanStatus"
          placeholder="Deduction Plan Status"
          value={filterBy.deductionPlanStatus}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.taxable}
          onClick={(event) => filterListByChecked(event, 'taxable')}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.isActive}
          onClick={(event) => filterListByChecked(event, 'isActive')}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>

    </TableRow>
  );
}

export default FilterList;