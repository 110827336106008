import React, { useState } from 'react'
import ApplyLeaveHeader from './header'
import {  Card, Col, Row } from 'reactstrap'
import { Button } from '@mui/material'

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { ShowSucess } from '../../app/utility/helper';
const inputProps = {
    style: { fontSize: 14, step: 300 },
    step: 300,
  };

 


const LeaveApply = () => {

    const [data , setData] = useState([{
        Date: null,
        STime: "",
        ETime:"",
        AllDay:false
      }])

      const leaveSubmitHandler = (data) => {
            console.log("data final", data);
      
           
        axios
        .post(
          `${process.env.REACT_APP_API_URL}leave/create`,
          { leaveList:data},
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        ).then(async (response) => {
        ShowSucess("Leave Create Successfully..")
        
      })
      .catch((error) => {
        // ShowError(error)
        console.warn("Cannot retrieve user data", error);
      });
      }


  const addTemplateRow = (index) => {
  
    return (

      <Row className="g-2"> 
        <Col md="2">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className=" payroll-content-date1"
                    style={{width:'165px'}}
                    // margin="dense"
                    size="small"
                    format="MM/dd/yyyy"
                    disableToolbar
                    inputVariant={"outlined"}
                    id="date-picker-inline"
                    // label="Date"
                    value={data[index]?.Date}
                    onChange={(_,value) => {
                        let copy = [ ...data ];
                        copy[index][ "Date" ] =   value;
                        setData(copy);
                      //   updateTime(index);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                     name="date"
                    // variant="outlined"
                    hiddenLabel="true"
                  />
              </MuiPickersUtilsProvider>  
        </Col>
        <Col md="2">
          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
          <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  disabled={data[ index ]?.AllDay}
                  style={{marginTop:"0px"}}
                  inputProps={inputProps}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop:'0px'
                    },
                    shrink: true,
                  }}
                  value={data[ index ]?.STime}
                  onChange={(e) => {
                      console.log('value',e);
                      let copy = [ ...data ];
                     
                      copy[index][ "STime" ] =  e.target.value;
                   
                      setData(copy);
                    //   updateTime(index);
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  hiddenLabel="true"
                //   error={timeData[ index ]?.checkValid} 
                />
            {/* <KeyboardDatePicker
              className="flex w-full"
              fullWidth
              size="small"
              format="MM/dd/yyyy"
              disableToolbar
              inputVariant={ "outlined" }
              id="effective-start-date"
              // value={timeData["time_from"]}
              KeyboardButtonProps={ {
                "aria-label": "change date",
              } }
              name="date"
              value={ timeData[ index ]?.time_from }
              onChange={ (_, value) => {
                let copy = [ ...timeData ];
                copy[ index ][ "time_from" ] = value;
                setTimeData(copy);
              } }
              hiddenLabel="true"
            /> */}
          </MuiPickersUtilsProvider>
        </Col>
        <Col md="2">
        <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  disabled={data[ index ]?.AllDay}

                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  } }
                  style={{marginTop:"0px"}}
                  value={data[ index ]?.ETime}
                  onChange={(e) => {
                      console.log('value',e);
                      let copy = [ ...data ];
                     
                      copy[index][ "ETime" ] =  e.target.value;
                   
                      setData(copy);
                    //   updateTime(index);
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  hiddenLabel="true"
                //   error={timeData[ index ]?.checkValid}
                />
          {/* <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <KeyboardDatePicker
              className="flex w-full"
              fullWidth
              size="small"
              format="MM/dd/yyyy"
              disableToolbar
              inputVariant={ "outlined" }
              id="effective-end-date"
              // value={timeData[index]["time_to"]}
              KeyboardButtonProps={ {
                "aria-label": "change date",
              } }
              value={ timeData[ index ]?.time_to }
              onChange={ (_, value) => {
                let copy = [ ...timeData ];
                copy[ index ][ "time_to" ] = value;
                setTimeData(copy);
              } }
              name="date1"
              hiddenLabel="true"
            />
          </MuiPickersUtilsProvider> */}
        </Col>
        <Col md="1">
          {/* <div
            style={ {
              display: "flex",
              width: "100%",
            //   justifyContent: "left",
            } }
          >
          </div> */}
            <Checkbox className='mb-2'  defaultChecked={data.AllDay} onChange={(e) => {
                let copy = [ ...data ];
                     
                copy[index][ "AllDay" ] =  e.target.checked;
             
                setData(copy);
            }}
            />

        </Col>
        <Col md="2"> 
        {index > 0 ?
          
          <button
          className="btn btn-outline-success paroll-contents-btn"
              onClick={ () => {
                let copy = [ ...data ];
               
                copy.splice(index, 1);
                setData(copy);
              } }
              style={ { marginBottom: 20 } }
            >
              -
            </button>
            :
            <button
            className="btn btn-outline-success paroll-contents-btn"
            onClick={ () => {
              let copy = {
                Date: null,
                STime: "",
                ETime:"",
                AllDay:false
              }  
              setData([...data ,copy]);
            } }
            style={ { marginBottom: 20 } }
          >
            +
          </button>}
        </Col>
        {/* <Col md="1">
          { index === timeData.length - 1 ? (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={ () => {
                let copy = [ ...timeData ];
                copy.push(sampleData);
                setTimeData(copy);
              } }
              style={ { marginBottom: 20 } }
            >
              +
            </button>
          ) : (
            <button
              className="btn btn-outline-success paroll-contents-btn"
              onClick={ () => {
                let copy = [ ...timeData ];
                if (timeData[ index ].id) {
                  let list = deletedList;
                  list.push(timeData[ index ].id);
                  setDeletedList(list);
                }
                copy.splice(index, 1);
                setTimeData(copy);
              } }
              style={ { marginBottom: 20 } }
            >
              -
            </button>
          ) }
        </Col> */}
      </Row>
    );
  };

  return (
    <div>
        <ApplyLeaveHeader />

        <Card className='mt-3 p-4' >
        <Row className="g-3 mt-2">
            <Col md="2">
              <Typography style={ { display: "flex", justifyContent: "left" } }>
                <h6>Leave Date</h6>
              </Typography>
            </Col>
            <Col md="2">
              <Typography style={ { display: "flex", justifyContent: "left" } }>
                <h6>Start Time</h6>
              </Typography>
            </Col>
            <Col md="2">
              <Typography style={ { display: "flex", justifyContent: "left" } }>
                <h6>End Time</h6>
              </Typography>
            </Col>
            <Col md="1">
              <Typography style={ { display: "flex", justifyContent: "left" } }>
                <h6>Full Day</h6>
              </Typography>
            </Col>
            <Col md="2"></Col>
          </Row>
            {data.map((row, index) => {
       return addTemplateRow(index)
    })}
        <Col md={2}>
        <Button className="bg-primary"  onClick={() => leaveSubmitHandler(data)}>Submit </Button>
        </Col>
        </Card>
    </div>

  )
}

export default LeaveApply