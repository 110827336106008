// import auth from "app/auth/store/reducers";
import { combineReducers } from "redux";
// import footer from "./footer";
import ehrpweb from "./ehrReducer/myehr.reducer";
// import fuse from "./fuse";
// import request from "app/main/dashboard/store/reducer";
// import home from "app/main/home/store";
import masterSlice from "./slices/masterSlice";
import chartSlice from "./slices/chartSlice";
import vendorSlice from "./slices/vendorSlice";
import empDashboardSlice from "./slices/empDashboardSlice";
import employeeSlice from "./slices/employeeSlice";
import querySlice from "./slices/querySlice";
import toolbarHeader from "./slices/toolbarHeaderSlice";
import configurationSlice from "./slices/configurationSlice";
import notificationSlice from "./slices/notificationSlice";
import TableSlice from "../../../Components/Supervisor/CommonConfigrations/TableSlice";
import OverTimeSlice from "../../../Components/OverTime/OverTimeSlice/index";
import advanceSearchSlice from "./slices/advanceSearchSlice";
import PRhistorySlice from "./slices/PRhistorySlice";
import CommonSlice from "./slices/CommonSlice";
import axioSlice from "../../utility/axiosService";
import vendor from "./vendor/slice";
import deductionPlan from "./DeductionPlan/slice";
import employeeDeductionPlan from "./EmployeeDeductionPlan/slice";

const createReducer = (asyncReducers) =>
  combineReducers({
    masterSlice,
    employeeSlice,
    advanceSearchSlice,
    PRhistorySlice,
    CommonSlice,
    configurationSlice,
    chartSlice,
    vendorSlice,
    empDashboardSlice,
    toolbarHeader,
    axioSlice,
    // auth,
    notificationSlice,
    querySlice,
    ehrpweb,
    TableSlice,
    OverTimeSlice,
    // fuse,
    // footer,
    // home,
    // request,
    vendor,
    deductionPlan,
    employeeDeductionPlan,
    ...asyncReducers,
  });

export default createReducer;
// import auth from 'app/auth/store/reducers';
// import { combineReducers } from 'redux';
// import footer from './footer';
// import ehrpweb from './ehrReducer/myehr.reducer';
// import fuse from './fuse';

// const createReducer = asyncReducers =>
// 	combineReducers({
// 		auth,
// 		ehrpweb,
// 		fuse,
// 		footer,
// 		...asyncReducers
// 	});

// export default createReducer;
