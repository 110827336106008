import React from "react";
import { Card, Grid, TextField } from "@mui/material";
import moment from "moment";
import { Form } from "reactstrap";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
function IRSQuarterlyReportLayout(props) {
  const { selectedYear, setSelectedYear } = props;

  return (
    <div className="w-full h-full reportTbl CardBack">
      <div className="mt-5  ">
        <div style={{ marginTop: "20px" }}>
          <Card
            style={{
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            elevation={3}
            className="body-Color2"
          >
            <Grid container spacing={2}>
              <Grid item xs={18} sm={8} lg={8} style={{ textAlign: "center" }}>
                <table className="padTbl">
                  <tbody>
                  <tr>
                  </tr>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          Select Year:
                        </b>
                      </td>
                      <div style={{ display: "flex", flex: 1 }} className="mx-4">
                        <Form>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              views={['year']}
                              label="Year"
                              value={dayjs(selectedYear)}
                              onChange={(e) => {
                                const formatDate = e.$d;
                                const value = moment(formatDate).format("YYYY-MM-DD");
                                setSelectedYear(value);
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                          </LocalizationProvider>
                        </Form>
                    </div>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default IRSQuarterlyReportLayout;
