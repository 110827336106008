import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReportHeader from '../ReportHeader';
import { makeStyles } from "@material-ui/core/styles";
import IRSQuarterlyReportLayout from './component/IRSQuarterlyReportLayout';
import RequestTable from "./component/request-table";
import { Container } from '@mui/material';
import { getAllIRSQuarterlyReportRecords } from '../../../app/utility/axiosService';
import { getUserId, getUserRole } from '../../../app/utility/helper';
import { useAuth } from 'oidc-react';
import AdvanceSearch from './component/AdvanceSearch';

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      ".&.MuiAccordionSummary-content": {
        padding: 0,
      },
      "& .MuiPaper-root": {
        background: "none",
      },
      margin: "0 !important",
      borderTopWidth: 1,
      borderTopColor: "#ccc",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      fontWeight: 500,
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
}));

const IRSQuarterlyReport = (props) => {

  const classes = useStyles();
  const { userData } = useAuth();
  const ID = getUserId(userData);
  const role = getUserRole(userData);
  const [tableList, setTableList] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [paramsQueries, setParamsQueries] = useState([]);
  const [detail, setDetail] = useState({
    code: "",
    description: "",
  });
  const [selectedYear, setSelectedYear] = useState(new Date());

  const pageLayout = useRef(null);

  useEffect(() => {
    fetchIRSQuarterlyReportRecords();
  }, []);

  useEffect(() => {
    fetchIRSQuarterlyReportRecords();
  }, [paramsQueries, selectedYear]);

  const fetchIRSQuarterlyReportRecords = async () => {
    if (selectedYear) {
      // setLoading(true);
      setDataLoading(true);
      let temp = [];
      if (paramsQueries && paramsQueries?.length > 0) {
        paramsQueries.forEach((o, i) => {
          temp.push({
              "columnName": o.columnName,
              "operator": o.operator,
              "paramValue": o.paramValue?.trim(),
              "paramType": "",
              "logicOperator": (i===0) ? "" : o.logicOperator
          })
        });
      }

      let payload = {
        year: new Date(selectedYear).getFullYear(),
        params: temp
      }
      const res = await getAllIRSQuarterlyReportRecords(payload);
      if (res?.status===200) {
        setTableList(res.data);
        // setTotalCount(res.totalCount);
      }
      setLoading(false);
      setDataLoading(false);
    }
  };

    return (
      <Container fluid={true} style={{ overflow: "auto" }}>
    
            <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
                <ReportHeader {...props} header="IRS Quarterly Summary Reports" />
            </div>
            <div className='page-content'>
            <AdvanceSearch loading={loading} role={role} userId={ID} paramsQueries={paramsQueries} setParamsQueries={setParamsQueries} setLoading={setLoading} />

            <IRSQuarterlyReportLayout
                {...props}
                role={"admin"}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
            />

            <div
                classes={{
                root: "w-full h-full",
                header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                contentWrapper: "p-0",
                contentCard: "rounded-none",
                }}
            >
                <div className={`${classes.root} w-full `}>
                    <RequestTable
                        {...props}
                        role={"admin"}
                        requestDetail={tableList}
                        detail={detail}
                        loading={dataLoading}
                        setDetail={setDetail}
                        userDetail={null}
                        apiCall={apiCall}
                        setApiCall={setApiCall}
                    />
                </div>
            </div>
            </div>
        </Container>
    )
}

export default IRSQuarterlyReport;