import React, { useEffect, useState } from "react";
import { ShimmerTable } from "react-shimmer-effects";
// import {
//   KeyboardDatePicker,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers";
import { Card, Grid } from "@mui/material";
import { Table } from "reactstrap";
// import InputMask from "react-input-mask";
// import DateInput from "app/main/payroll/component/DateInput";
// import { fontSize } from "@mui/system";
// import { size } from "lodash";
// import MaskedInput from "react-text-mask";

// let payAmount = [
//   {
//     title: "Regular",
//     amount: "80000",
//   },
//   {
//     title: "Overtime",
//     amount: "79999",
//   },
//   {
//     title: "Holiday Pay",
//     amount: "12000",
//   },
//   {
//     title: "Night Diffrential",
//     amount: "8998888",
//   },
//   {
//     title: "Emt Pay",
//     amount: "8000",
//   },
//   {
//     title: "Typhoon Pay",
//     amount: "129999",
//   },
//   {
//     title: "Nurse Pay",
//     amount: "988998",
//   },
//   {
//     title: "Hazard Pay",
//     amount: "200000",
//   },
//   {
//     title: "Other Pay",
//     amount: "3200000",
//   },
//   {
//     title: "Gross Pay",
//     amount: "1000000",
//   },
//   {
//     title: "Government Contribution",
//     amount: "1000000",
//   },
// ];

// let deductionArray = [
//   {
//     deductionPlan: "Life Insurance",
//     deduction: [
//       {
//         vendor: "SIC",
//         count: "3173",
//         amount: "31388.16",
//         govtShare: "22.306.19",
//       },
//       {
//         vendor: "Gove",
//         count: "3173",
//         amount: "31388.16",
//         govtShare: "22.306.19",
//       },
//     ],
//   },
//   {
//     deductionPlan: "Health Insurance",
//     deduction: [
//       {
//         vendor: "SC15c3",
//         count: "3173",
//         amount: "31388.16",
//         govtShare: "22.306.19",
//       },
//       {
//         vendor: "SC15c2",
//         count: "3173",
//         amount: "31388.16",
//         govtShare: "22.306.19",
//       },
//     ],
//   },
//   {
//     deductionPlan: "Dental Insurance",
//     deduction: [
//       {
//         vendor: "SCDEN1",
//         count: "3173",
//         amount: "31388.16",
//         govtShare: "22.306.19",
//       },
//     ],
//   },
// ];

function ReportLayout(props) {
  const { deduction, earning } = props;
  const [deductions, setDeductions] = useState(deduction);
  const [totalearning, setTotalEarning] = useState(0);
  const [totaldeduction, setTotalDeduction] = useState({
    empDeduction: 0,
    govtDeduction: 0,
  });
  console.log(totaldeduction);
  const [grandTotalDeduction] = useState({
    cnt: 0,
    empDeduction: 0,
    govtDeduction: 0,
  });

  function formatMoney(number) {
    number = parseFloat(number);
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  useEffect(() => {
    if (deduction) {
      let empsum = 0;
      let govtsum = 0;
      let cnt = 0;
      grandTotalDeduction.empDeduction = 0;
      grandTotalDeduction.govtDeduction = 0;

      deduction.map((i) => {
        i.deductionPlans.map((j) => {
          empsum = empsum + parseFloat(j.EmployeeShare ? j.EmployeeShare : 0);
          govtsum = govtsum + parseFloat(j.GovtShare ? j.GovtShare : 0);
          return (cnt = cnt + j.count);
        });
        grandTotalDeduction.cnt = cnt;
        grandTotalDeduction.empDeduction += empsum;
        grandTotalDeduction.govtDeduction += govtsum;
        return (i.total = { empDeduction: empsum, govtDeduction: govtsum });
      });
      setDeductions(deduction);
      setTotalDeduction({ empDeduction: empsum, govtDeduction: govtsum });
    }
    if (earning) {
      let sum = 0;
      earning.map((i) => {
        return (sum = sum + parseFloat(i.Earnings));
      });
      setTotalEarning(sum);
    }
  }, [props, deduction, earning, grandTotalDeduction]);

  const renderDeductionList = () => {
    return (
      <Grid item xs={12} sm={8} lg={8}>
        <Card
          style={{
            marginBottom: "10px",
            overflow: "hidden",
            padding: 0,
            borderRadius: "10px",
          }}
          elevation={3}
          className="body-Color2 reportTbl "
        >
          <Table>
            <table className="table100">
              <thead>
                <tr>
                  {/* <th colSpan={5} className="tc mainHead">
                  Deduction
                </th> */}
                  <th colSpan={5}>
                    <h5>
                      <span style={{}}> Deduction</span>
                    </h5>
                  </th>
                </tr>

                <tr className="tc mainHead1">
                  <th className="tl1">Vendor</th>
                  <th className="tr1" colSpan={4} style={{ padding: "0px" }}>
                    <table
                      style={{ width: "100%" }}
                      className="w25 tl  "
                      // className="w25 tl"
                    >
                      <thead>
                        <tr className="tc mainHead1">
                          <th className="tl1">Code1</th>
                          <th className="tl1">Count</th>
                          <th className="tl1">Amount</th>
                          <th className="tl1">Govt Share</th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>
              </thead>
              <tbody>
                {deductions.length ? (
                  deductions.map((i) => {
                    return (
                      <tr
                        style={{
                          borderBottomColor: "#EAEBE4",
                          borderBottomWidth: "1px",
                        }}
                      >
                        <td className="tl1 labelColor ">
                          <b>{i.key}</b>
                        </td>
                        <td colSpan={4}>
                          <Table>
                            <table style={{ width: "100%" }} className="w25">
                              <tbody>
                                {i.deductionPlans.length
                                  ? i.deductionPlans.map((info, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td className="labelColor">
                                              {info.Code}
                                            </td>
                                            <td className="labelColor">
                                              {info.count}
                                            </td>
                                            <td className="labelColor">
                                              {formatMoney(
                                                info.EmployeeShare
                                                  ? info.EmployeeShare
                                                  : 0
                                              )}
                                            </td>
                                            <td className="labelColor">
                                              {formatMoney(
                                                info.GovtShare
                                                  ? info.GovtShare
                                                  : 0
                                              )}
                                            </td>
                                          </tr>
                                          {index ===
                                          i.deductionPlans.length - 1 ? (
                                            <>
                                              <tr
                                                style={{
                                                  fontWeight: "bold",
                                                  paddingTop: "15px",
                                                  paddingBottom: "20px",
                                                  // backgroundColor: "#F5F5F5",

                                                  // marginTop:
                                                }}
                                              >
                                                <td colSpan={2}>
                                                  Total {i.key}
                                                </td>
                                                <td>
                                                  {formatMoney(
                                                    i.total.empDeduction
                                                  )}
                                                </td>
                                                <td>
                                                  {formatMoney(
                                                    i.total.govtDeduction
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })
                                  : null}
                              </tbody>
                            </table>
                          </Table>
                        </td>
                        {/* <td className="tr1">{i.amount}</td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {" "}
                    <td colSpan={3}>
                      {" "}
                      <ShimmerTable row={2} col={5} />
                    </td>
                  </tr>
                )}
                {/* Deduction Gross Total */}
                {/* <tr style={{ backgroundColor: "#1975D2" }}> */}
                <tr className="bg-primary">
                  <td
                    className="tl1"
                    style={{
                      padding: "8px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                    }}
                  >
                    Gross Deduction
                  </td>
                  <td colSpan={4}>
                    <table style={{ width: "100%" }} className="w25">
                      <tbody>
                        <td></td>
                        <td
                          style={{
                            // padding: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {grandTotalDeduction.cnt}
                        </td>
                        <td
                          // className="tl1"
                          style={{
                            // padding: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {formatMoney(grandTotalDeduction.empDeduction)}
                        </td>
                        <td
                          // className="tl1"
                          style={{
                            // padding: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {formatMoney(grandTotalDeduction.govtDeduction)}
                        </td>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {/* Deduction Gross Total Ends*/}
              </tbody>
            </table>
          </Table>
        </Card>
      </Grid>
    );
  };
  return (
    <div className="w-full h-full reportTbl CardBack">
      <div className="mt-5  ">
        <div style={{ marginTop: "20px" }}>
          {/* <div class="rounded overflow-hidden shadow-lg"> */}
          <Card
            style={{
              padding: "15px",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            elevation={3}
            className="body-Color2"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "right" }}>
                <table className="padTbl ">
                  <tbody className="leftHead">
                    {/* <tr>
                      <td className="cardSmallTitle labelColor">Run Date</td>
                      <td>:</td>
                      <td className="cardSmallValue labelColor">12/22/2022</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle labelColor">Program</td>
                      <td>:</td>
                      <td className="cardSmallValue labelColor">Paybills</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle labelColor" colSpan={3}>
                        DEPT/DIV SPECIFICATION
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ textAlign: "center" }}>
                <table className="table100 centerDiv padTbl">
                  <tbody>
                    <tr>
                      <td colSpan={2} className="t18 bold">
                        <b className="text-light">
                          Department of Administrative
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="t18">
                        <b className="text-light">
                          Payroll Summary of Earning and Deduction
                        </b>
                      </td>
                    </tr>
                    <tr style={{ marginTop: "20px" }}>
                      <td className="cardSmallTitle labelColor">
                        CHK: 6/24/2022
                      </td>
                      <td className="cardSmallTitle labelColor">
                        PPE: 6/18/2022
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>

              <Grid item xs={12} sm={3} lg={3} style={{ textAlign: "left" }}>
                <table className="rightHead fright  padTbl">
                  <tbody>
                    {/* <tr>
                      <td className="cardSmallTitle labelColor">Page</td>
                      <td>:</td>
                      <td className="cardSmallValue labelColor">1</td>
                    </tr>
                    <tr>
                      <td className="cardSmallTitle labelColor">Run Time</td>
                      <td>:</td>
                      <td className="cardSmallValue labelColor">Paybills</td>
                    </tr>

                    <tr>
                      <td className="cardSmallTitle labelColor">Run ID</td>
                      <td>:</td>
                      <td className="cardSmallValue labelColor">60</td>
                    </tr> */}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card>
          {/* </div> */}
        </div>
        <div style={{ marginBottom: "50px", marginTop: "20px" }}>
          <Grid container spacing={2}>
            {/* Earning Manage */}

            <Grid item xs={12} sm={4} lg={4}>
              <Card
                style={{
                  marginBottom: "10px",
                  overflow: "hidden",
                  padding: 0,
                  borderRadius: "10px",
                }}
                elevation={3}
                className="body-Color2"
              >
                <Table>
                  <table className="table100">
                    <thead>
                      <tr>
                        <th colSpan={2}>
                          <h5 className="">
                            <span className="">Earning</span>
                          </h5>
                        </th>
                      </tr>

                      <tr className="tc mainHead1">
                        <th className="tl1">Description</th>
                        <th className="tr1">Amounts</th>
                      </tr>
                    </thead>
                    <tbody>
                      {earning.length ? (
                        earning.map((i) => {
                          return (
                            <tr>
                              <td className="tl1 labelColor">
                                {i.Description}
                              </td>
                              <td className="tr1 labelColor">
                                {formatMoney(i.Earnings)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          {" "}
                          <td colSpan={2}>
                            {" "}
                            <ShimmerTable row={2} col={2} />
                          </td>
                        </tr>
                      )}
                      {/* <tr style={{ backgroundColor: "#1975D2" }}> */}
                      <tr className="bg-primary">
                        <td
                          className="tl1"
                          style={{
                            padding: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          Gross Payroll
                        </td>
                        <td
                          className="tr1"
                          style={{
                            padding: "8px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {formatMoney(totalearning)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Table>
              </Card>
            </Grid>
            {/* Deduction list */}

            {renderDeductionList()}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ReportLayout;
