import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { darken } from "@mui/material";
import ConfigDB from "../../../Config/ThemeConfig";
//  '#26695c'
const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;
//  '#c29873'
const secondary =
  localStorage.getItem("secondary_color") ||
  ConfigDB.data.color.secondary_color;

const EmpDashPieChart = () => {
  const deducation = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.deducation
  );
  var element, bgColor, brightness, r, g, b, hsp;
  const lightOrDark = (color) => {
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    } else {
      return "dark";
    }
  };

  var pieColors = (function () {
    var colors = [],
      base = primary,
      i;
    let j = 1;
    for (i = 1; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      console.log("Color chk", lightOrDark(base));
      if (lightOrDark(base) == "dark") {
        colors.push(
          Highcharts.color(base)
            .brighten((i - 3) / 7)
            .get()
        );
      } else {
        colors.push(
          // Highcharts.color(base)
          //   .brighten((i + 3) / 7)
          //   .get()
          darken(base, i * 0.1)
        );
      }
      // colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());

      // if (i ===1) {
      //   colors.push(darken("#4ebfa9", 0.1))
      // }else if (i === 2) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 3) {
      //   colors.push(darken("#4ebfa9", 0.3))
      // }
      // else if (i === 4) {
      //   colors.push(darken("#4ebfa9", 0.4))
      // }
      // colors.push(darken("#4ebfa9", (1 /i )/0))
      // j++;
    }

    return colors;
    // return ["#26695c","#3da18d","#59736e","#0c3a31","#4fcfb5"];
  })();
  let charData = [
    { name: "ABC11", amount: 125 },
    { name: "Xyz", amount: 152 },
    { name: "pqr", amount: 50 },
  ];
  let charData2 = [{ name: "ABC", amount: 25 }];
  const getChartDetails = (chart, type) => {
    if (type === "pie") {
      let series_name = "";
      let series_data = [];
      let empshare = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        chart_title = "Distribution amongst types of pay";
        series_data = charData2.map((i) => {
          return {
            name: i.name,
            y: i.amount,
          };
        });
      }

      if (chart == "deduction") {
        series_data = deducation.map((i) => {
          return {
            name: `${i.VendorName}`,
            y: i.GovermentDeduction + i.EmployeeDeduction,
          };
        });
        chart_title = "Deducation by Vendor";
        empshare = deducation.map((i) => {
          return {
            name: i.VendorName,
            y: i.EmployeeDeduction + i.GovermentDeduction,
          };
        });
      }

      let opPie = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: chart_title,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors: pieColors,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Deduction",
            colorByPoint: true,
            data: series_data,
          },
        ],
      };
      return opPie;
    }
    if (type == "column") {
      let series_name = "";
      let series_data = [];
      let series_category = [];
      let chart_title = "Column chart";
      if (chart == "paytype") {
        series_name = "Pay Types";
        series_data = charData2.map((i) => {
          return i.amount;
        });
        series_category = charData2.map((i) => {
          return i.name;
        });
        chart_title = "Distribution amongst types of pay";
      }
      if (chart == "deduction") {
        series_name = "Deduction Types";
        series_data = deducation.map((i) => {
          return i.GovermentDeduction + i.GovermentDeduction;
        });
        series_category = deducation.map((i) => {
          return i.VendorName;
        });
        chart_title = "Distribution amongst types of deduction";
      }
      let opCol = {
        chart: {
          type: "column",
        },
        title: {
          text: chart_title,
        },
        subtitle: {
          text: "Source:Payroll System",
        },
        xAxis: {
          categories: series_category,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Amount ($)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} $</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: series_name,
            data: series_data,
          },
        ],
      };

      return opCol;
    }
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getChartDetails("deduction", "pie")}
    />
  );
};

export default EmpDashPieChart;
