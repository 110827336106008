import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React from "react";

import Header from "./component/header";
import General from "./component/generalDetail";
import Professional from "./component/professionaleDetail";
import Other from "./component/otherDetail";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faUser, faUserTie } from "@fortawesome/pro-duotone-svg-icons";
import { Accordion, Card, CardBody, CardHeader, Col, Collapse } from "reactstrap";
import Btn from "../../CommonElements/Button";

const userDetail1 = {
  firstName: "Robert",
  middleName: "M",
  lastName: "Duenas",
  fullName: "Robert Duenas",
  email: "robert@gmail.com",
  avatar: "assets/images/avatars/Tillman.jpg",
  maritalStatus: "single",
  citizen: "United States",
  education: "BACCALAUREATE",
  gender: "Male",
  disability: "NO",
  DOB: "11/05/1993",
  veteran: "Y",
  i9: "Y",
  ssn: "333-10-1001",
  empTye: "Permanent",
  dept: "DOA-Personnel Management",
  payGrade: "Q",
  empNo: "1035",
  positionTitle: "EQUAL EMPLOYMENT OPPORTUNITY",
  employmentDate: "02/04/2018",
  reEmploymentDate: "02/04/2019",
  totalSvc: " ",
  currentSvc: " ",
  totalPriorSvc: "18Y 0M 11D",
  milPriorSvc: "18Y 0M 11D",
  grade: "Q",
  step: "9",
  rate: "38.69",
  salary: "8047",
  actual: "38.69",
  actual2: "80,476.00",
  home: "174N HENRY KAISER, DEDEDO, GU, 96921",
  mail: "POBOX 228444 GMF, BARRIGADA, GU, 96921",
  homePhone: "6124893753",
  workPhone: "6124893753",
  workSchedule: "FULL TIME",
  lastActionDate: "09/02/2020",
  policeClearanceDate: "09/02/2020",
  courtClearanceDate: "09/02/2020",
  empContractNo: " ",
  requestId: " ",
  physicalExam: "Y",
  drivingLicenseNo: " ",
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     ".&.MuiAccordionSummary-content": {
//       padding: 0,
//     },
//     margin: "0 !important",
//     borderTopWidth: 1,
//     borderTopColor: "#ccc",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "33.33%",
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "33.33%",
//     flexShrink: 0,
//     // color: theme.palette.text.secondary,
//   },
// }));

function MasterDetails(props) {

  const [expand, setExpand] = React.useState({
    panel1: 1,
    panel2: 1,
    panel3: 1,
  });
  console.log("check", expand.panel1);

  const handleChange = (name) => {
    setExpand({ ...expand, [name]: !expand[name] })
  };
  const userDetail = userDetail1;



  console.log("userDetail", userDetail);
  return (
    <><Header {...props} />

      <Col sm="12" xl="12" className="accordians mt-3">
        <Accordion defaultActiveKey="0">
          <Card>

            <CardBody style={{ padding: "0" }} >
              <div className="default-according" id="accordion1">
                <Card>
                  <CardHeader className="bg-primary" onClick={(e) => handleChange("panel1")} style={{ padding: "7px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h5 attrH5={{ className: 'mb-0' }} >
                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link txt-white', color: 'default', onClick: (e) => handleChange("panel1") }} >
                          {/* <i className="icofont icofont-briefcase-alt-2"></i> */}
                          <FontAwesomeIcon
                            icon={faUser}
                            className="icofont"
                            style={{ marginRight: "10px" }}
                          />
                          General Details
                        </Btn>
                      </h5>
                      <div className="mt-1">
                        {expand.panel1 ?
                          <ExpandLessIcon />
                          :
                          <ExpandMoreIcon />
                        }
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={expand.panel1 ? 1 : 0}>
                    <CardBody>
                      <General userDetail={userDetail} />
                    </CardBody>
                  </Collapse>
                </Card>

                <Card >
                  <CardHeader className="bg-primary" onClick={(e) => handleChange("panel2")} style={{ padding: "7px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h5 attrH5={{ className: 'mb-0' }} >
                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: (e) => handleChange("panel2") }} >
                          <FontAwesomeIcon
                            icon={faUserTie}
                            className="icofont"
                            style={{ marginRight: "10px" }}
                          />

                          Professional Details
                        </Btn>
                      </h5>
                      <div className="mt-1">
                        {expand.panel2 ?
                          <ExpandLessIcon />
                          :
                          <ExpandMoreIcon />
                        }
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={expand.panel2 ? 1 : 0}>
                    <CardBody>
                      <Professional userDetail={userDetail} />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card  >
                  <CardHeader className="bg-primary" onClick={(e) => handleChange("panel3")} style={{ padding: "7px" }}>
                    <div className="mt-1" style={{ display: "flex", justifyContent: "space-between" }}>
                      <h5 attrH5={{ className: 'mb-0' }} >
                        <Btn attrBtn={{ as: Card.Header, className: 'btn btn-link text-white', color: 'default', onClick: (e) => handleChange("panel3") }} >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="icofont"
                            style={{ marginRight: "10px" }}
                          />

                          Other Details
                        </Btn>
                      </h5>
                      <div className="mt-1">
                        {expand.panel3 ?
                          <ExpandLessIcon />
                          :
                          <ExpandMoreIcon />
                        }
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={expand.panel3 ? 1 : 0}>
                    <CardBody>
                      <Other userDetail={userDetail} />
                    </CardBody>
                  </Collapse>
                </Card>

              </div>
            </CardBody>
          </Card>
        </Accordion>
      </Col>

    </>
  );
}

export default MasterDetails;
