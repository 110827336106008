import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from 'dayjs';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import {
  employeeStatusOptions,
  jobClassificationCodeOptions,
  jobClassificationTitleOptions,
} from "./constants";

const EmployeeStatusInfo = (props) => {
  const [empStatus, setEmpStatus] = useState({});
  const {
    state,
    setState,
    handleChange,
    optionsMaster,
    userDetail,
    changeDetails,
    userInfo,
    setUserInfo,
  } = props;
  const [tempPayGrade, setTempPayGrade] = useState();
  const [tempPayGradeStep, setTempPayGradeStep] = useState();
  // alert(userDetail)
  return (
    <Card>
      <CardHeader className="pb-0">
        <H5>Employee Status </H5>
      </CardHeader>
      <CardBody>
        <Form>
          <Row className="g-3 mt-1 p-l-10">
            <Col md={3}>
              <Label className="form-label">Part Time Employee</Label>

              <RadioGroup
                row
                className="mx-2"
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  setState({ ...state, partTimeEmployee: value });
                }}
                value={
                  state && state?.partTimeEmployee
                    ? state?.partTimeEmployee
                    : ""
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Employeement Begin Date"
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(moment(state.employmentBeginDate).format("YYYY-MM-DD"))}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");

                    setState({ ...state, employmentBeginDate: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="form-label">Employeement Begin Date</Label>
              <input
                className="form-control"
                type="date"
                placeholder="Last Name"
                value={
                  userInfo &&
                  userInfo.EmployeeStatuses &&
                  userInfo.EmployeeStatuses.length
                    ? moment(
                        userInfo.EmployeeStatuses[0]["EmploymentBeginDate"]
                      ).format("yyyy-MM-DD")
                    : null
                }
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "employmentBeginDate", value: value },
                  })
                }
              /> */}
            </Col>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Termination Date"
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(moment(state.terminationDate).format("YYYY-MM-DD"))}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");

                    setState({ ...state, terminationDate: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              {/* <Label className="form-label">Termination Date</Label>
              <input
                className="form-control"
                type="date"
                placeholder="Last Name"
                value={
                  userInfo &&
                  userInfo.EmployeeStatuses &&
                  userInfo.EmployeeStatuses.length
                    ? moment(
                        userInfo.EmployeeStatuses[0]["TerminationDate"]
                      ).format("yyyy-MM-DD")
                    : null
                }
                onChange={(_, value) =>
                  changeDetails({
                    target: { name: "terminationDate", value: value },
                  })
                }
              /> */}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="lengthOfService"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Length of Service"
                value={state.lengthOfService}
                onChange={(e) => handleChange(e)}
              />
              {/* <Label className="form-label">Length of Service</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Length of Service"
                value={
                  userInfo &&
                  userInfo.EmployeeStatuses &&
                  userInfo.EmployeeStatuses.length
                    ? userInfo.EmployeeStatuses[0]["LengthOfService"]
                    : ""
                }
                onChange={(e) =>
                  changeDetails({
                    target: { name: "lengthOfService", value: e.target.value },
                  })
                }
              /> */}
            </Col>
          </Row>
          <Row className="g-3 mt-1">
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Employee Status"
                name="employeeStatus"
                value={state.employeeStatus}
                onChange={(e) => handleChange(e)}
              >
                {/* <option value={null}>Select Citizenship</option> */}
                {optionsMaster?.EmpStatus?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Position No"
                name="employeeStatus"
                value={state.jobClassificationCode}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>{state.jobClassificationCode}</option>
                {/* <option value={null}>Select Job Classification Code</option> */}
                {jobClassificationCodeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Position Title"
                name="jobClassificationTitle"
                value={state.jobClassificationCode}
                onChange={(e) => handleChange(e)}
              >
                {/* <option value={null}>{state.jobClassificationTitle}</option> */}
                <option value={null}>Select Job Classification Title</option>
                {optionsMaster?.Nposition_No?.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Col>
            {console.log("dasdada",optionsMaster)}
          </Row>
          <Row className="g-3 mt-2 p-l-10">
            <Col md={2} xs={4} lg={2}>
              <Label className="form-label ">Exempt Position </Label>

              <RadioGroup
                className="mx-2"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  setState({ ...state, exemptPosition: value });
                }}
                value={
                  state && state?.exemptPosition ? state?.exemptPosition : ""
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </Col>
            <Col md="2 ">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Pay Grade"
                name="payGrade"
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={(e) => handleChange(e)}

                defaultValue={state.payGrade}
                value={state.payGrade}
                onChange={(e) => {
                  setState({ ...state, payGrade: e.target.value });
                }}
              >
                <option value={null}>Select pay grade</option>
                {optionsMaster?.Pay_Grade?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Col>
            <Col md="2 ">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Pay Grade Step"
                name="payGradeStep"
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={(e) => handleChange(e)}
                defaultValue={state.payGradeStep}
                value={state.payGradeStep}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>{state.payGradeStep}</option>
                {/* <option value={null}>Select Pay Grade Step</option> */}
                {optionsMaster?.Pay_Step?.filter((i) => {
                  return i.payGrade?.trim() == state.payGrade?.trim();
                }).map((option) => {
                  return (
                    <option
                      style={{ display: "block", padding: "5px" }}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </TextField>
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="hourlyRate"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Hourly Rate($)"
                value={state.hourlyRate}
                onChange={(e) => handleChange(e)}
              />
              {/* <Label className="form-label">Hourly Rate($)</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Hourly Rate($)"
                value={
                  userInfo &&
                  userInfo.EmployeeStatuses &&
                  userInfo.EmployeeStatuses.length
                    ? userInfo.EmployeeStatuses[0]["HourlyRate"]
                    : ""
                }
                onChange={(e) =>
                  changeDetails({
                    target: { name: "hourlyRate", value: e.target.value },
                  })
                }
              /> */}
            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="annualSalary"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Annual Salary"
                value={state.annualSalary}
                onChange={(e) => handleChange(e)}
              />
              {/* <Label className="form-label">Annual Salary</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Annual Salary"
                value={userDetail.annualSalary}
                onChange={(e) =>
                  changeDetails({
                    target: { name: "annualSalary", value: e.target.value },
                  })
                }
              /> */}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EmployeeStatusInfo;
