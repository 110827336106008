import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles, MenuItem } from "@mui/material";
import { Select } from "@mui/material";
 import { Col } from "reactstrap";
import { Button } from "@mui/material";

import { Row } from "reactstrap";

const SaveNewQuery = (props) => {
  return (
    <Card>
      <CardContent>
        <Row>
          <Typography
            variant="h5"
            component="h5"
            style={{
              marginBottom: "16px",
              marginLeft: "2px",
              fontWeight: "bold",
              color: "#24695c",
            }}
          >
            Enter Query Details
          </Typography>
        </Row>
        <div className="mt-2 mb-5">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>

              <TextField 
                className="mb-3"
                fullWidth
                label= "Query Name"
                variant="outlined"
                value={props.queryName}
                onChange={props.onTextChange}
              />
               
            </Col>
          </Row>

          <>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                value={props.selected}
                size="small"
                 onChange={(e) => props.onSelectUserGroup(e.target.value)}
              >
                <MenuItem value="select-role">Select Role</MenuItem>
                {props?.userGroup?.map((i) => {
                  return (
                    <MenuItem value={i.user_group_name}>
                      {i.user_group_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "4rem",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Button
              variant="contained"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
          <div>
             <Button
              variant="contained"
              className="bg-primary"
              style={{ color: "white" }}
              onClick={() => {
                props.onSubmit(props.values);
              }}
            >
              Submit
            </Button>
          </div>
        </div>

        {/* )} */}
      </CardContent>
    </Card>
  );
};

export default SaveNewQuery;
