import { BagSvgIcon } from "../../Common/Data/SvgIcons";
import IncomeCard from "./IncomeCard";
import IncomeChartClass from "./IncomechartCard";
import GrowthOverview from "./GrownCard";
import LatestActivityClass from "./LatestActivityCard";
import RecentOrderClass from "./RecentOrderCard";
import UserCard from "./UserCard";
import TransactionCard from "./TransactionCard";
import { Container, Row, Col } from "reactstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchDeltaEmployeeEarning,
  fetchDeltaEmployeeEmpDeduction,

  // fetchDeltaEmployeeGovDeduction,
  // fetchDeltaEmployeePE,
  fetchDeducation,
  fetchDeductionToPlan,
  fetchAnnualLeaveEarning,
  filterChart,
  fetchSickLeaveEarning,
  fetchWorkingHoursByDate,
  fetchEarningByPayType,
  fetchEarningByPayPeriod,
  fetchDeductionByPayPeriod,
  fetchEmployeeBalance,
} from "../../../app/store/reducers/slices/empDashboardSlice";

import { formatMoney, getUserRole } from "../../../app/utility/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsd } from "@fortawesome/pro-duotone-svg-icons";
import DelteEmployeeCard from "./DelteEmployeeCard";
import SankeyChart from "../../Reports/Sankey/Sankey";
import { Card, CardBody, CardHeader } from "reactstrap";
import CardHeaderComponent from "../Common/CardHeader";
import EmpDashPieChart from "./PieChart";
import { useAuth } from "oidc-react";
import { getUserId } from "../../../app/utility/helper";
import EmpDashDeducationToPlanPieChart from "./DeducationToPlan";
import EmpDashEarningByPayTypePieChart from "./EarningByPayType";
import EarningDeductionChart from "./EarningDeductionChart";
import FilterSection from "./filterSection";
import SankeyEmployee from "./SankeyEmployee";

const Dashboard = () => {
  const { userData } = useAuth();
  // console.log("USERDATA ::: ", userData, getUserId(userData));
  const dispatch = useDispatch();
  const [empId, setEmpID] = useState(getUserId(userData));
  const filterinfo = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.chartFilter
  );
  const empBalance = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.empBalanceCount
  );
  const chart = useSelector(({ empDashboardSlice }) => empDashboardSlice);

  let role = getUserRole(userData);

  useEffect(() => {
     
    if(["admin", "supervisor", "timekeeper"].includes(role) ){
     

      setEmpID(0)
    }
  },[userData])
  useEffect(() => {
    // userData
    let userId = getUserId(userData);
    let ppeId = filterinfo.id;
    // if (ppeId && role == "user") {
    // alert("m" + empId + '--' + ppeId);
    if (ppeId) {
      dispatch(fetchDeducation({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchDeductionToPlan({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchEarningByPayType({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchDeltaEmployeeEarning({ ppe: ppeId, employeeID: empId }));
      dispatch(
        fetchDeltaEmployeeEmpDeduction({ ppe: ppeId, employeeID: empId })
      );
      // dispatch(fetchDeltaEmployeeGovDeduction({ ppe: ppeId, employeeID: empId }));
      // dispatch(fetchDeltaEmployeePE({ ppe: ppeId, employeeID: empId }));

      dispatch(fetchEmployeeBalance({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchAnnualLeaveEarning({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchSickLeaveEarning({ ppe: ppeId, employeeID: empId }));
      dispatch(fetchWorkingHoursByDate({ ppe: ppeId, employeeID: empId,StartDate:filterinfo.StartDate,EndDate:filterinfo.EndDate }));
      dispatch(
        fetchEarningByPayPeriod({ ppe: ppeId, employeeID: empId, max: 10 })
      );
      dispatch(
        fetchDeductionByPayPeriod({ ppe: ppeId, employeeID: empId, max: 10 })
      );
    }
  }, [filterinfo.EndDate]);

  const renderAdminBoard = () => {
    return (
      <Row>
        {/* <Col lg="12">
          <FilterSection></FilterSection>
        </Col> */}
        <Col xl="12">
          <IncomeChartClass />
        </Col>

        {/* Sankey Report */}

        {/* Sankey ends */}
        {/* <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Pie Chart"} />
            </CardHeader>
            <CardBody>
              <EmpDashPieChart />
            </CardBody>
          </Card>
        </Col> */}

        <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Sankey Report"} />
            </CardHeader>
            <CardBody>
              <SankeyChart />
            </CardBody>
          </Card>
        </Col>

        <Col xl="8" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="6" className="col-50 box-col-6 des-xl-50">
              <GrowthOverview />
            </Col>
            <Col xl="6" className="col-50 box-col-6 des-xl-50">
              <LatestActivityClass />
            </Col>
            {/* <Col xl="12" className="recent-order-sec">
              <RecentOrderClass />
            </Col> */}
          </Row>
        </Col>
        <Col xl="4" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" className="box-col-6 des-xl-50">
              <UserCard />
            </Col>
            <Col xl="12" className="box-col-6 des-xl-50">
              <TransactionCard />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderEmployeeBoard = () => {
    return (
      <Row>
        <Col lg="12">
          <FilterSection></FilterSection>
        </Col>
        <Col xl="12">
          <IncomeChartClass empId={empId} />
        </Col>

        <Col xl="12">
          {console.log("Emp Id11 ::: ", empId)}
          <EarningDeductionChart empId={empId} />
        </Col>

        {/* <Col xl="12" md="6" className="box-col-6 des-xl-50">
                        <ProfileGreeting />
                    </Col> */}
        <Col xl="3" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
          <IncomeCard
            iconClass={<FontAwesomeIcon icon={faUsd} />}
            amount={formatMoney(empBalance.totalEarning)}
            title="Total Earning"
            // percent="95.54%"
          />
        </Col>
        <Col xl="3" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
          <IncomeCard
            iconClass={<FontAwesomeIcon icon={faUsd} />}
            amount={formatMoney(empBalance.annualDeduction?empBalance.annualDeduction:0)}
            title="Annual Deduction"
            // percent="90.54%"
          />
        </Col>
        <Col xl="3" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
          <IncomeCard
            iconClass={<BagSvgIcon />}
            amount={empBalance.annualLeave}
            title="Total Earned A/L Leave"
            // percent="90.54%"
          />
        </Col>
        <Col xl="3" md="3" sm="6" className="box-col-3 des-xl-25 rate-sec">
          <IncomeCard
            iconClass={<BagSvgIcon />}
            amount={empBalance.sickLeave}
            title="Total Earned S/L Leave"
            // percent="90.54%"
          />
        </Col>

        {/* /* Delta values */}
        <Col
          xl="6"
          md="6"
          sm="6"
          xs="6"
          className="box-col-3 des-xl-25 rate-sec"
        >
          <DelteEmployeeCard
            value={
              chart && chart.deltaEmployeeEarning.data
                ? formatMoney(chart.deltaEmployeeEarning.data[0]["Earning"]?chart.deltaEmployeeEarning.data[0]["Earning"]:0)
                : 0
            }
            percentage={
              chart && chart.deltaEmployeeEarning.percentChange
                ? chart.deltaEmployeeEarning.percentChange
                : 0
            }
            difference={
              chart.deltaEmployeeEarning.data
                ? chart.deltaEmployeeEarning.data[0]["Earning"] -
                  chart.deltaEmployeeEarning.data[1]["Earning"]
                : 0
            }
            title="Earning Growth"
            subtext="Earning"
          />
        </Col>
        <Col
          xl="6"
          md="6"
          sm="6"
          xs="6"
          className="box-col-3 des-xl-25 rate-sec"
        >
          <DelteEmployeeCard
            value={
              chart && chart.deltaEmployeeEmpDeduction.data
                ? formatMoney(
                    chart.deltaEmployeeEmpDeduction.data[0]["empDeduction"]
                  )
                : 0
            }
            percentage={
              chart.deltaEmployeeEmpDeduction.percentChange
                ? chart.deltaEmployeeEmpDeduction.percentChange
                : 0
            }
            difference={
              chart.deltaEmployeeEmpDeduction.data
                ? chart.deltaEmployeeEmpDeduction.data[0]["empDeduction"] -
                  chart.deltaEmployeeEmpDeduction.data[1]["empDeduction"]
                : 0
            }
            title="Deduction Growth"
            subtext="Deduction"
          />
        </Col>
        <Col
          xl="6"
          md="6"
          sm="6"
          xs="6"
          className="box-col-3 des-xl-25 rate-sec"
        >
          <DelteEmployeeCard
            value={
              chart && chart.deltaEmployeeALEarning.data
                ? `${chart.deltaEmployeeALEarning.data[0]["LeaveHours"]} Hrs`
                : 0
            }
            percentage={
              chart && chart.deltaEmployeeALEarning.percentChange
                ? chart.deltaEmployeeALEarning.percentChange
                : 0
            }
            difference={
              chart && chart.deltaEmployeeALEarning.data
                ? chart.deltaEmployeeALEarning.data[0]["LeaveHours"] -
                  chart.deltaEmployeeALEarning.data[1]["LeaveHours"]
                : 0
            }
            title="A/L Earning Growth"
            subtext="A/L Earning"
          />
        </Col>
        <Col
          xl="6"
          md="6"
          sm="6"
          xs="6"
          className="box-col-3 des-xl-25 rate-sec"
        >
          <DelteEmployeeCard
            value={
              chart && chart.deltaEmployeeSLEarning.data
                ? `${chart.deltaEmployeeSLEarning.data[0]["LeaveHours"]} Hrs`
                : 0
            }
            percentage={
              chart && chart.deltaEmployeeSLEarning.percentChange
                ? chart.deltaEmployeeSLEarning.percentChange
                : 0
            }
            difference={
              chart && chart.deltaEmployeeSLEarning.data
                ? chart.deltaEmployeeSLEarning.data[0]["LeaveHours"] -
                  chart.deltaEmployeeSLEarning.data[1]["LeaveHours"]
                : 0
            }
            title="S/L Earning Growth"
            subtext="S/L Earning"
          />
        </Col>

        {/*  ends */}

        {/* Sankey Report */}
        <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Pie Chart"} />
            </CardHeader>
            <CardBody>
              <EmpDashPieChart />
            </CardBody>
          </Card>
          {/* <EmpDashPieChart /> */}
        </Col>
        <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Pie Chart"} />
            </CardHeader>
            <CardBody>
              <EmpDashDeducationToPlanPieChart />
            </CardBody>
          </Card>
        </Col>
        <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Pie Chart"} />
            </CardHeader>
            <CardBody>
              <EmpDashEarningByPayTypePieChart />
            </CardBody>
          </Card>
        </Col>
        <Col xl="6" className="box-col-12 des-xl-100">
          <Card>
            <CardHeader>
              <CardHeaderComponent title={"Sankey Report"} />
            </CardHeader>
            <CardBody>
              <SankeyEmployee />
            </CardBody>
          </Card>
        </Col>
        {/* Sankey ends */}

        <Col xl="8" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="6" className="col-50 box-col-6 des-xl-50">
              <GrowthOverview />
            </Col>
            <Col xl="6" className="col-50 box-col-6 des-xl-50">
              <LatestActivityClass />
            </Col>
            {/* <Col xl="12" className="recent-order-sec">
              <RecentOrderClass />
            </Col> */}
          </Row>
        </Col>
        <Col xl="4" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" className="box-col-6 des-xl-50">
              <UserCard />
            </Col>
            {/* <Col xl="12" className="box-col-6 des-xl-50">
              <TransactionCard />
            </Col> */}
          </Row>
        </Col>
      </Row>
    );
  };
  return (
    <Container fluid={true} className="dashboard-default-sec">
      {getUserRole(userData) === "admin"
        ? // renderAdminBoard()
          renderEmployeeBoard()
        : renderEmployeeBoard()}
    </Container>
  );
};

export default Dashboard;
