import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { TextField } from "@mui/material";

function StateManage(props) {
    const [codeMinLengthError, setCodeMinLengthError] = useState(false);
    return (
        <Row style={{ width: "100%" }} className="g-3">
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full "
                    style={{ width: "80%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                        minLength: 2,
                        maxLength: 4,
                    }}
                    error={codeMinLengthError}
                    helperText={codeMinLengthError ? "minimum length should be 2" : null}
                    label="State Code"
                    value={props.detail.code}
                    onChange={(e) => {
                        const regex = /^[A-Za-z]+$/;
                        if (e.target.value === "" || regex.test(e.target.value)) {
                            props.setDetail({ ...props.detail, code: e.target.value });
                            if (e.target.value?.length < 2) setCodeMinLengthError(true);
                            else setCodeMinLengthError (false);
                        }
                    }}
                    name="code"
                />
            </Col>
            <Col md="6">
                <TextField
                    margin="dense"
                    className="flex w-full"
                    style={{ width: "80%" }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            fontSize: "14px",
                            color: "black",
                            paddingTop: "12px",
                        },
                    }}
                    label="State Name"
                    value={props.detail.name}
                    onChange={(e) => {
                        props.setDetail({ ...props.detail, name: e.target.value });
                    }}
                    name="name"
                />
            </Col>
        </Row>
    );
}

export default StateManage;
