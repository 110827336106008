import * as React from "react";

import {
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Popover,
  Checkbox,
} from "@mui/material";

import { useState } from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-duotone-svg-icons";
const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

export default function ColumnFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlseSelect = (option) => {
    console.log("option 123", option);
  };

  const handleRemoveColumn = async (field, showField) => {
    // This function used  to ordering fields
    let copyArray = [...props.columns];

    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field == field;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["show"] = showField;
      copyArray[objectAtIndex] = copyObject;
      props.setSortedColumns(copyArray);
    }
  };
  const handleFilterChange = (event) => {
    setValue(event.target.value);
  };

  const filteredColumns = props.columns.filter((column) =>
    column.title.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <>
      <div>
        <Tooltip title="Filter Column" placement="bottom">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              icon={faBars}
              className="font-primary"
              rotation={90}
            />

            {/* <ViewColumnIcon /> */}
          </IconButton>
        </Tooltip>

        <Popover
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          style={{ zIndex: "10004" }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,

              // width: "200px",
            },
          }}
        >
          <div className="customCheck">
            <TextField
              label="Filter Columns"
              value={value}
              onChange={handleFilterChange}
              // fullWidth
              size="small"
              style={{ margin: 10 }}
            />
          </div>
          {/* props.columns */}
          {filteredColumns &&
            filteredColumns.length &&
            filteredColumns.map((option) => (
              <MenuItem
                style={{ backgroundColor: "white", width: "auto" }}
                key={option.field}
                // onClick={() => handlseSelect(option.title)}
                onClick={() => handleRemoveColumn(option.field, !option.show)}
              >
                <Checkbox
                  checked={option.show}
                  style={{ padding: "0", margin: "0" }}
                />
                {option.title}
              </MenuItem>
            ))}
        </Popover>
      </div>
      <div onClick={() => setAnchorEl(null)}></div>
    </>
  );
}
