import { H5 } from "../../../AbstractElements";
import React from "react";
import { Card, CardBody } from "reactstrap";
import { format2Decimal } from "../../../app/utility/helper";

const DelteEmployeeCard = ({
  value,
  title,
  percentage,
  difference,
  subtext,
}) => {
  return (
    <Card className="income-card">
      <CardBody className="text-center">
        {/* <div className="round-box">{iconClass}</div> */}
        {/* <P>{title}</P> */}
        <h4>{title}</h4>
        <H5>{value}</H5>
        <div>
          {percentage >= 0 ? (
            <span className="upclass">
              {" "}
              +{format2Decimal(difference)} ({format2Decimal(percentage)}%){" "}
              <i className=" upclass fa fa-arrow-up me-2"></i>
              {subtext}{" "}
            </span>
          ) : (
            <span className="downclass">
              {" "}
              {format2Decimal(difference)} ({format2Decimal(percentage)}%){" "}
              <i className=" downclass fa fa-arrow-down me-2"></i>
              {subtext}
            </span>
          )}
        </div>
        {/* <div className="parrten">{iconClass}</div> */}
      </CardBody>
    </Card>
  );
};

export default DelteEmployeeCard;
