import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/pro-duotone-svg-icons";
import { Card, CardBody } from "reactstrap";
import InputForm from "./InputForm";

const DetailForm = (props) => {
  const { detail, setDetail, classes, configrationJSON, expand, handleChange } =
    props;
  return (
    <>
      <Card className="mt-3 mb-3">
        <Accordion
          className={classes.root}
          expanded={expand.panel1}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="bg-gray-200 sub-header"
          >
            <FontAwesomeIcon
              icon={faRectangleList}
              className="sub-header"
              style={{
                marginRight: "10px",
                marginTop: "3px",
              }}
            />
            <Typography className={classes.heading}>
             {configrationJSON.detailTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pt-0 page-body-wrapper">
            <CardBody>
              <InputForm detail={detail} setDetail={setDetail} configrationJSON={configrationJSON}/>
            </CardBody>
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
};

export default DetailForm;
