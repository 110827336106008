import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import {
  faXmark,
  faCheck,
  faCircleArrowDown,
  faCircleArrowUp,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRowsPerPage, setPage } from "../TableSlice";

const TableComponent = (props) => {
  let dispatch = useDispatch();
  const { rowsPerPage, page,data } = useSelector((state) => state.TableSlice);

  const use = makeStyles({
    selectLabel: {
      padding: 8,
      fontSize: "15px",
    },
    toolbar: {
      "& > p": {
        fontSize: "15px",
      },
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const {
    filteredList,
    onRowClick,
    columns,
    tableClass,
    filterComponent,
  } = props;

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead
        className="w-full labelColor "
        style={{ width: "100%", position: "sticky", top: "0", zIndex: "5" }}
      >
        <TableRow className="labelColor">
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              className="py-10 bg-gray-300 page-body-wrapper labelColor"
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                minWidth: headCell.minWidth ? headCell.minWidth : "200px",
                fontWeight: "bold",
              }}
            >
              {headCell.name}
              {headCell.sort ? null : orderBy === headCell.id ? (
                <Box component="span">
                  {order === "desc" ? (
                    <FontAwesomeIcon
                      icon={faCircleArrowDown}
                      className="sub-header"
                      onClick={createSortHandler(headCell.id)}
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="sub-header"
                      icon={faCircleArrowUp}
                      onClick={createSortHandler(headCell.id)}
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Box>
              ) : (
                <>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircleArrowDown}
                    className="sub-header"
                    onClick={createSortHandler(headCell.id)}
                    style={{ color: "#1569C7", marginRight: "5px" }}
                  />
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    className="sub-header"
                    onClick={createSortHandler(headCell.id)}
                    style={{ color: "#1569C7", marginRight: "5px" }}
                  />
                </>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const renderRowData = (column, value, row, index) => {
    return column.type === "status" ? (
      <TableCell key={index} size="small" className="labelColor">
        {renderStatus(value)}
      </TableCell>
    ) : column.type === "date" ? (
      <TableCell key={column.id} align={column.align} className="labelColor">
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className="labelColor"
        >
          {moment(value).format(column?.dateFormat || 'MM/DD/YYYY')}
        </Typography>
      </TableCell>
    ) :
      (
        <TableCell key={column.id} align={column.align} className="labelColor">
          <Typography
            color="textSecondary"
            variant="subtitle2"
            className="labelColor"
          >
            {value}
          </Typography>
        </TableCell>
      );
  };

  const renderStatus = (status) => {
    const statusCls =
      status
        ? "text-green"
        : "text-red";
    return (
      <div
        className={`inline text-12 p-4 labelColor rounded truncate ${statusCls} font-semibold w-95 justify-left flex items-center`}
      >
        <span style={{ marginTop: "2px", marginRight: "5px" }}>
          {status ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faXmark} />
          )}
        </span>
        {/* {status ? "True" : "False"} */}
      </div>
    );
  };

  function descendingComparator(a, b, orderBy) {
    if (moment(a[orderBy], "MM/DD/YYYY", true).isValid()) {
      var date1, date2;
      if (typeof a[orderBy] == "string") {
        const parts1 = a[orderBy].split("/");
        const parts2 = b[orderBy].split("/");
        date1 = new Date(parts1[2], parts1[1] - 1, [parts1[0]]);
        date2 = new Date(parts2[2], parts2[1] - 1, [parts2[0]]);
      } else {
        date1 = new Date(
          a[orderBy].getFullYear(),
          a[orderBy].getMonth(),
          a[orderBy].getDate()
        );
        date2 = new Date(
          b[orderBy].getFullYear(),
          b[orderBy].getMonth(),
          b[orderBy].getDate()
        );
      }

      if (date2 < date1) {
        return -1;
      }
      if (date2 > date1) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const classes = use();
  return (
    <div className="mt-10" style={{ minWidth: "100%" }}>
      <TableContainer
        component={Paper}
        id="scrollableDiv"
        className={tableClass}
      >
        <Table stickyHeader aria-label="sticky table">
          {filteredList && (
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredList.length}
            />
          )}
          {filterComponent ? filterComponent : null}
          {filteredList && filteredList.length > 0 ? (
            <TableBody className="page-body-wrapper labelColor ">
              {stableSort(filteredList, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((obj, index) => (
                  <TableRow
                    key={index}
                    className="capitalize cursor-pointer labelColor"
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick !== null && onRowClick({ data: obj, index });
                      }
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        renderRowData(column, obj[column.id], obj, index)
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody className="page-body-wrapper">
              <TableRow>
                <TableCell
                  align="center"
                  className="labelColor"
                  colSpan={columns.length}
                >
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {filteredList && (
        <TablePagination
          sx={{ fontSize: "15px" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          className="labelColor"
          // count={filteredList.length}
          count={data?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ page }) => {
            return `Page: ${page + 1} of ${Math.ceil(
              // filteredList.length / rowsPerPage
              data?.totalCount / rowsPerPage

            )}`;
          }}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
          }}
        />
      )}
    </div>
  );
}

export default TableComponent;
