const HistoryColumns = [
  {
    title: "Query ID",
    field: "id",
    locked: false,
    filter: "numeric",
    width: "auto",
    show: true,
  },
  {
    title: "Query Name",
    field: "queryName",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Group Name",
    field: "permissionType",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Status",
    field: "status",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Created At",
    field: "createdOn",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy hh:mm A",
    editor: "date",
    width: "auto",
    show: true,
  },
];

export default HistoryColumns;
