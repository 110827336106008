import React, { useEffect, useRef, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded";
import PayrollHeader from "./component/payrollHeader";
import PayrollContentVerification from "./component/payrollVerification/payrollContentVerification";
// import Accordion from "@material-ui/core/Accordion";
import { Accordion, Alert, Card, Tooltip } from "@mui/material";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionSummary } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

// import Typography from "@material-ui/core/Typography";
import { Typography } from "@mui/material";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployeeDetail from "./component/employeeDetail";
import EmployeeAccountDetail from "./component/employeeAccountDetail";
import axios from "axios";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import { Button } from "@material-ui/core";

import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  faClock,
  faCircleNotch,
  faMoneyCheckDollar,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import { Row } from "react-bootstrap";
import { useAuth } from "oidc-react";
import {
  getUserId,
  getUserRole,
  KeyGen,
  payCodeFilter,
  ShowError,
} from "../../app/utility/helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import queryString from "query-string";
import { GetEmployeeAuthorizedPayrollData } from "../OverTime/OverTimeSlice";
import { Container } from "reactstrap";

import { GetEmpTagCode } from "../Supervisor/CommonConfigrations/TableSlice";
import LoadingPanel from "../Common/telerik/loadingPanel";
import { getAllEmployeeCertificatesRecordsByEmployeeId } from "../../app/utility/axiosService";
import SelectEmployee from "./selectEmployee";

const filter = createFilterOptions();

let lastKnownScrollPosition = 0;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiExpansionPanelSummary-content": {
      padding: 0,
    },

    "& .MuiPaper-root": {
      background: "none",
      display: "block",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "70%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
}));

const PayrollWorkTimeVerification = (props) => {
  const [selectedPayPeriod, setSelectedPayPeriod] = React.useState({});
  const dispatch = useDispatch();
  const localtion = useLocation();
  const navigate = useNavigate();
  const [OTMinutes, setOTMinutes] = useState(2400);
  const [OTHours, setOTHours] = useState(40);
  const [OTType, setOTType] = useState("PPEPW40"); //PPEPW40=40=2400 hr for week / PPEPW43 =43=2580 hr for week / PPE2W106=Full=106 PPE / PD= Per day
  const [empCertifications, setEmpCertifications] = useState([]);
  const { employeeAuthorizedPayTimeCodesList } = useSelector(
    (state) => state.OverTimeSlice
  );
  const queryPPE = queryString.parse(localtion.search);

  const { getEmpTag } = useSelector((state) => state.TableSlice);
  let params = useParams();
  const { userData } = useAuth();

  const [empId, setEmpId] = useState(
    params.id ? params.id : getUserId(userData)
  );

  const sampleData = {
    jobtype: "REG",
    id: KeyGen(7),
    date:
      moment(new Date()).format("YYYY-MM-DD") >= selectedPayPeriod?.EndDate
        ? selectedPayPeriod?.EndDate
        : moment(new Date()).format("YYYY-MM-DD"),
    time_from: "09:00", //moment(new Date()),
    time_to: "", //moment(new Date()),
    time_from1: "", //moment(new Date()),
    time_to1: "", //moment(new Date()),
    hrs: "00:00",
    isNew: true,
    mn: 0,
    mn1: 0,
    code: 1,
    jobOrder: null,
    hjobtype: "",
    rowstatus: "Confirm",
    TagCode: [],
    JObOrdersData: [{ value: null, label: null }],
    EmployeeID: empId,
  };
  const classes = useStyles();
  const [price, setPrice] = useState(0);
  const [rowsData, setRowsData] = useState([sampleData]);
  const [empValue, setEmpValue] = useState({
    EmployeeName: null,
    ID: null,
  });

  const userRole = getUserRole(userData);
  // let empId = params.id ? params.id : getUserId(userData);

  useEffect(() => {
    setEmpId(params.id ? params.id : getUserId(userData));
  }, [userData, params]);

  useEffect(() => {
    if (empId) {
      dispatch(GetEmpTagCode(empId));
    }
  }, [empId]);

  const [EmployeeData, setEmloyeeDAta] = useState([]);
  // const [scrollTop, setScrollTop] = useState(false);

  let ppeId = params.ppe ? params.ppe : "";
  // console.log("Params... :: ", params);
  // const dispatch = useDispatch();
  const pageLayout = useRef(null);
  const [expanded, setExpanded] = React.useState({
    payroll: true,
    employee: false,
    employeeList: false,
  });
  const payrollRef = useRef();
  const [weekData, setWeekData] = useState({
    normal_hour: 0,
    annual_leave_hour: 0,
    sick_leave_hour: 0,
    overtime_hour: 0,
    night_diff_hour: 0,
    hazardous_hour: 0,
  });
  const getEmpData = () => {
    if (getUserRole(userData) !== "admin") {
      return false;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/v2`,
        {
          tableName: "EmployeeMaster",
          params: [
            {
              value: 0,
              operator: ">=",
              column: "ID",
              dataType: "bigint",
              columnName: "ID",
            },
          ],
          columns: "ID,EmployeeName",
          page: 1,
          limit: 100000000,
          orderBy: "ID",
          sortBy: "DESC",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        setEmloyeeDAta(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getEmpValidation = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        let rdata = response.data?.result;
        if (rdata.length) {
          rdata.map((i, index) => {
            return (i["Abberv"] = i.PayCode_PayTimeCode.Abberv);
          });
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    getEmpData(); // This is require to show emp list in auto search dropdown
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [isloadingSearchEmp, setIsloadingSearchEmp] = useState(false);

  const [hourData, setHourData] = useState({
    REG: 0,
    NDIF: 0,
    HAZARDOUS: 0,
  });
  const [chatOpen, setChatOpen] = React.useState(false);
  const [paytimeCode, setPaytimeCode] = React.useState([]);
  const [allPaytimeCode, setALLPaytimeCode] = React.useState([]);
  const [paytimeCodeByID, setPaytimeCodeByID] = React.useState([]);
  const [filterPaycodeByRole, setFilterPaycodeByRole] = React.useState([]);
  const [paytimeCodeSuperVisor, setPaytimeCodeSuperVisor] = React.useState([]);
  const [assigneeEmployeeList, setAssigneeEmployeeList] = useState([]);
  const [employeeVerifyFlag, setEmployeeVerifyFlag] = useState([]);

  const [allPayPeriod, setAllPayPeriod] = React.useState({});

  const [employeeProcessPayTimes, setEmployeeProcessPayTimes] = useState();
  //EmployeeStatuses: userInfo.EmployeeStatuses
  const [employeeStatuses, setEmployeeStatuses] = useState();
  //EmployeeStatuses: userInfo.EmployeeStatuses
  const [userObject, setUserObject] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const UserID = getUserId(userData);
  const role = getUserRole(userData);

  useEffect(() => {
    if (queryPPE?.ppeID && allPayPeriod?.length) {
      const filterPPE = allPayPeriod?.find((i) => i.ID == queryPPE?.ppeID);
      setSelectedPayPeriod(filterPPE);
    }
  }, [queryPPE?.ppeID, allPayPeriod?.length]);

  useEffect(() => {
    if (selectedPayPeriod?.ID && userObject?.ID) {
      dispatch(
        GetEmployeeAuthorizedPayrollData({
          payPeriodId: selectedPayPeriod?.ID || "",
          employeeId: userObject?.ID || "",
        })
      );
    }
  }, [selectedPayPeriod, userObject]);

  useEffect(() => {
    const filterPaycodes = payCodeFilter(paytimeCode, paytimeCodeByID, role);

    setFilterPaycodeByRole(filterPaycodes);
  }, [paytimeCode, paytimeCodeByID]);

  //EHR_ON_SET_USER

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const handleChatClose = () => {
    setChatOpen(!chatOpen);
  };

  useEffect(() => {
    if (selectedPayPeriod?.ID && UserID) getAssigneeEmployee();
  }, [selectedPayPeriod, UserID]);
  const reAssignEmployee = (Emp_ID) => {
    let assigneeEmpData = [...assigneeEmployeeList] ;
    let index = assigneeEmpData.findIndex((i) => i.employeeID == Emp_ID);
    let findData = assigneeEmpData[ index ];
    findData[ 'flag' ] = assigneeEmpData[ 'flag' ] ? assigneeEmpData[ 'flag' ] : 1;
    assigneeEmpData = assigneeEmpData.sort(function(a, b) {
      return a.flag - b.flag;
    });
    setAssigneeEmployeeList(assigneeEmpData)
  }
  const getAssigneeEmployee = () => {
    axios
      // .get(
      //   `${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeeBySupervisionId?Id=${UserID}`
      // )
      .get(
        `${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeeBySupervision?ppe=${selectedPayPeriod?.ID}&SupervisorID=${UserID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      )
      .then((response) => {
        // remove dubplicate employeeId
        let unique = response.data.data.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.employeeID === item.employeeID)
        );

        setAssigneeEmployeeList(response.data.data);

        if (params.id === undefined) {
          navigate(`/payroll-work-verify/${response.data.data[0].employeeID}`);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPayPeriod = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payperiod`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // let createObj = [];
        if (response.data.length) {
          let resPaycode = response.data;
          let tody = moment().format("YYYY-MM-DD");
          let allpayperiod = resPaycode.filter((i) => {
            return i.StartDate <= tody;
          });
          setAllPayPeriod(allpayperiod);
          if (!params.ppe) {
            let currentPayPeriod = resPaycode.find((i) => {
              return i.StartDate <= tody && i.EndDate >= tody;
            });
            if (currentPayPeriod == undefined) {
              setSelectedPayPeriod(resPaycode[resPaycode.length - 1]);
            } else {
              setSelectedPayPeriod(currentPayPeriod);
            }
          } else {
            let currentPayPeriodByID = resPaycode.find((i) => {
              return i.ID == ppeId;
            });
            setSelectedPayPeriod(currentPayPeriodByID);
          }
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getMasterData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}paytimecode`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        let createObj = [];
        if (response.data.length) {
          response.data?.map((item, index) => {
            createObj.push({
              value: item.Abberv,
              label: item.Abberv,
              title: item.Description,
              code: item.Code,
              multi: item.Multi,
              EffectDate: item.EffectDate,
              ExpireDate: item.ExpireDate,
              color: item.color,
              name: "jobtype",
              OrderNo: item.OrderNo,
            });
            return true;
          });
        }
        setPaytimeCode(createObj);
        // setALLPaytimeCode(createObj);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  // useEffect(() => {
  //   let filterPayCodeRole = payCodeFilter(allPaytimeCode, paytimeCodeByID, userRole);
  //   console.log(allPaytimeCode.length,'@#@', paytimeCodeByID.length,'Master111 Data',filterPayCodeRole);
  //   setPaytimeCode(filterPayCodeRole);
  // },[paytimeCodeByID,allPaytimeCode])

  const getPaycodeBYID = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        setPaytimeCodeByID(response.data.result);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getPaycodeBYSuperVisor = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/paycode/get`,
        {
          EmployeeID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        setPaytimeCodeSuperVisor(response.data.result);
        // dispatch({ type: "EHR_ON_SET_PAYCODE", payload: createObj });
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const fetchAllEmployeeCertificationRecords = async (empId) => {
    if (empId) {
      const res = await getAllEmployeeCertificatesRecordsByEmployeeId(
        Number(empId),
        100000,
        0
      );
      if (res.status === 200) {
        let activeCertificates = res.data.filter((i) => i.isActive);
        setEmpCertifications(activeCertificates);
      }
    }
  };

  useEffect(() => {
    fetchAllEmployeeCertificationRecords(empId);
  }, []);

  const getUserInfo = () => {
    //Employee Data
    if (!empId) {
      return false;
    }

    axios
      .get(
        `${process.env.REACT_APP_WEB_API_URL}Employee/GetEmployeePayrollMasterDetails?employeeId=${empId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
          },
        }
      )
      .then((response) => {
        let userInfo = response.data.data.employeePayrollMaster;
        let employeeStatuses = response.data.data.employeeStatuses;
        let departmentDivisionMaster =
          response.data.data.departmentDivisionMaster;
        console.log("userinfo", userInfo);
        fetchAllEmployeeCertificationRecords(empId);
        // toast.success('Record Found', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        let newObj = {
          DPDV: userInfo.dpdv,
          // "EmployeeAllowedLeaves":userInfo.EmployeeAllowedLeaves,
          EmployeeName: userInfo.employeeName,
          DPDV_DepartmentDivisionMaster: departmentDivisionMaster,
          // "EmployeeProcessPayTimes":userInfo.EmployeeProcessPayTimes,
          EmployeeStatuses: employeeStatuses,
          ID: userInfo.employeeID,
          Seq: userInfo.seq,
          Status: userInfo.status,
          Rate: userInfo.rate ? userInfo.rate : 16.32,
          EmployeementDate: userInfo?.date_Employment,
        };

        // dispatch({
        //   type: "EmployeeProcessPayTimes",
        //   payload: {
        //     EmployeeProcessPayTimes: userInfo.EmployeeProcessPayTimes,
        //   },
        // });
        setEmployeeProcessPayTimes(userInfo.EmployeeProcessPayTimes);

        // dispatch({
        //   type: "EmployeeStatuses",
        //   payload: { EmployeeStatuses: userInfo.EmployeeStatuses },
        // });
        setEmployeeStatuses(userInfo.EmployeeStatuses);
        // dispatch({ type: "EHR_ON_SET_USER", payload: newObj });
        setUserObject(newObj);
      })
      .catch((error) => {
        toast.error("Employee Not Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if (empId) {
      getUserInfo();
      getMasterData();
      getPaycodeBYID(empId);
    }
    // getPayPeriod();

    if (params.ppe) {
      setSelectedPayPeriod(params.ppe);
    }
  }, [empId]);
  useEffect(() => {
    getPayPeriod();
  }, []);
  useEffect(() => {
    if (getUserId(userData) > 0) {
      getPaycodeBYSuperVisor(getUserId(userData));
    }
  }, [userData]);

  const searchByEmpId = () => {
    if (!empId) {
      ShowError("Please Select Employee");
      return false;
    }
    setIsloadingSearchEmp(true);
    getUserInfo();
    getMasterData();
    if (params.ppe) {
      setSelectedPayPeriod(params.ppe);
    }
    setTimeout(() => {
      setIsloadingSearchEmp(false);
    }, 3000);
  };

  const handleClose = (e) => {
    console.log("handleCloseOption", e.target.value);
  };
  let ticking = false;

  return (
    // <div style={{ overflowY: "scroll", height: "calc(100vh - 50px)" }}>
    <Container fluid={true} style={{ overflow: "auto" }}>
      {isloading && <LoadingPanel></LoadingPanel>}

      <div className="page-content">
        <>
          <div ref={pageLayout}>
            <Card className="mt-3 mb-3 p-10">
              <h5 className="font-bold my-3 ml-1"> Select Employee </h5>
              <div className="d-flex align-content-start flex-wrap">
                <SelectEmployee
                  dataList={assigneeEmployeeList}
                  currentEmployeeID={empId}
                  onClickHandle={(employeeID) => {
                    setEmpId(employeeID);
                    window.history.pushState(
                      null,
                      "",
                      `/payroll-work-verify/${employeeID}`
                    );
                    // setIsLoading(true);
                  }}
                />
              </div>
            </Card>
         
              <Card className="mt-3 mb-3 p-10">
              <h5 className="font-bold my-3 ml-1"> Tags </h5>
              {getEmpTag.length ? <div className="d-flex align-content-start flex-wrap">
                  {  getEmpTag?.map((i, index) => {
                    return (
                      <div className="p-1" key={`ex${index}`}>
                        <label
                          className={`btn btn-outline-primary selectedTags ${
                            empId === i.employeeID.toString()
                              ? "selectedUser"
                              : ""
                          }`}
                          // className={
                          //   empId === i.employeeID.toString() ? "selectedUser" : ""
                          // }
                        >
                          {i.tagName}
                        </label>
                      </div>
                    );
                  }) }
                </div>:<div className="d-flex align-content-start flex-wrap">
                    No Tag Assigned
                  </div>}
                
              </Card>
           
            {empCertifications.length ? (
              <Card className="mt-3 mb-3 p-10">
                <h5 className="font-bold my-3 ml-1"> Certificates </h5>
                <div className="d-flex align-content-start flex-wrap">
                  {empCertifications?.map((i, index) => {
                    return (
                      <div className="p-1" key={`ex${index}`}>
                        <label
                          className={`btn btn-outline-primary selectedTags ${
                            empId === i.employeeID.toString()
                              ? "selectedUser"
                              : ""
                          }`}
                          // className={
                          //   empId === i.employeeID.toString() ? "selectedUser" : ""
                          // }
                        >
                          {i.certificateName} 
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Card>
            ) : null}
            <PayrollHeader
              {...props}
              handleChatClose={handleChatClose}
              chatOpen={chatOpen}
              payPeriod={selectedPayPeriod}
              allPayPeriod={allPayPeriod}
              userId={empId}
              setPayPeriod={(info) => {
                setSelectedPayPeriod(info);
              }}
            />

            {!empId && (
              <Alert severity="error" className="mt-2 mb-2">
                Employee is not selected. Please select first
              </Alert>
            )}
            <div className="page-content">
              {getUserRole(userData) == "admin" && (
                <div style={{ padding: "16px 2px" }}>
                  <Card
                    style={{ gap: "10px" }}
                    elevation={4}
                    className=" p-10 d-flex ">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={EmployeeData}
                      // getOptionLabel={(options) => {
                      //   return options.EmployeeName + "-" + options.ID;
                      // }}

                      size="small"
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option?.EmployeeName + "-" + option.ID;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option?.EmployeeName + "-" + option.ID;
                      }}
                      onChange={(e, newValue) => {
                        console.log("onChange", newValue);

                        // setEmpId(newValue?.ID);
                        // setEmpValue({
                        //   EmployeeName: newValue,
                        // });

                        if (
                          typeof newValue === "object" &&
                          !newValue?.inputValue
                        ) {
                          setEmpId(newValue?.ID);
                          setEmpValue({
                            EmployeeName: newValue?.EmployeeName,
                            ID: newValue?.ID,
                          });

                          // if (e.code == "Enter") {
                          //   console.log("case 1", e.code, empValue);
                          //   searchByEmpId();
                          // }
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input

                          console.log("case 2");
                          setEmpId(newValue.inputValue);

                          setEmpValue({
                            EmployeeName: {
                              ID: newValue.inputValue,
                              EmployeeName: newValue.inputValue,
                            },
                          });
                        } else {
                          console.log("case 3");
                          setEmpValue({ ID: null, EmployeeName: null });
                        }
                      }}
                      defaultValue={null}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.EmployeeName
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            EmployeeName: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Employee"
                          // defaultValue={empValue}
                          // value={empValue}
                          // onChange={(e, newValue) => {
                          //   console.log("case onKeyDown", e.target.value);
                          //   e.preventDefault();
                          //   setEmpId(e.target.value);
                          //   setEmpValue({
                          //     ID: e.target.value,
                          //     EmployeeName: e.target.value,
                          //   });
                          //   if (e.code == "Enter") {
                          //     searchByEmpId();
                          //   }
                          // }}
                        />
                      )}
                    />
                    <Button
                      size="medium"
                      className="bg-primary"
                      style={{ color: "white" }}
                      onClick={(e) => {
                        e.preventDefault();
                        searchByEmpId();
                      }}
                      variant="contained">
                      Search
                    </Button>
                  </Card>
                </div>
              )}
              <div className="w-full">
                <EmployeeDetail
                  data={weekData}
                  price={price}
                  setPrice={setPrice}
                  hourData={hourData}
                  rowsData={rowsData}
                  paytimeCode={filterPaycodeByRole}
                  userInfo={userObject}
                  EmployeeStatuses={employeeStatuses}
                  isloading={isloadingSearchEmp}
                  OTMinutes={OTMinutes}
                  OTHours={OTHours}
                  OTType={OTType}
                />
                <div className={`${classes.root} w-100`}>
                  <div
                    classes={{
                      root: "w-full h-full",
                      header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
                      contentWrapper: "p-0",
                      contentCard: "rounded-none",
                    }}>
                    <div className={`${classes.root} w-full`}>
                      <Card>
                        <Accordion
                          className={classes.root}
                          expanded={expanded.payroll}
                          onChange={handleChange("payroll")}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            className="bg-gray-200 sub-header w-100 p-1">
                            <FontAwesomeIcon
                              icon={faRectangleList}
                              className="sub-header"
                              style={{
                                color: "#1569C7",
                                marginRight: "10px",
                                marginTop: "3px",
                              }}
                            />
                            <Typography className={classes.heading}>
                              Work Time
                              <b
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}>
                                {"  "}{" "}
                                {` ${
                                  userObject?.EmployeeName
                                    ? userObject?.EmployeeName
                                    : "Select Employee"
                                }`}{" "}
                                / {`${userObject?.ID ? userObject?.ID : ""}`}
                              </b>{" "}
                              {` (${
                                selectedPayPeriod?.StartDate
                                  ? moment(selectedPayPeriod?.StartDate).format(
                                      "MM-DD-YYYY"
                                    )
                                  : ""
                              } To ${
                                selectedPayPeriod?.EndDate
                                  ? moment(selectedPayPeriod?.EndDate).format(
                                      "MM-DD-YYYY"
                                    )
                                  : ""
                              }))`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 page-body-wrapper">
                            {OTType}
                            <PayrollContentVerification
                              ref={payrollRef}
                              { ...props }
                              reAssignEmployee={reAssignEmployee}
                              userInfo={ userObject }
                              setEmployeeVerifyFlag={setEmployeeVerifyFlag}
                              paytimeCodeSuperVisor={paytimeCodeSuperVisor}
                              rowsData={rowsData}
                              sampleData={sampleData}
                              setRowsData={setRowsData}
                              setWeekData={setWeekData}
                              setIsLoading={setIsLoading}
                              isloading={isloading}
                              price1={price}
                              paytimeCodeByID={paytimeCodeByID}
                              allPayPeriod={allPayPeriod}
                              weekData={weekData}
                              paytimeCode={paytimeCode}
                              setHourData={setHourData}
                              hourData={hourData}
                              payPeriod={selectedPayPeriod}
                              EmployeeProcessPayTimes={employeeProcessPayTimes}
                              employeeAuthorizedPayTimeCodesList1={
                                employeeAuthorizedPayTimeCodesList
                              }
                              userId={empId}
                              getEmpTag={getEmpTag}
                              OTMinutes={OTMinutes}
                              OTHours={OTHours}
                              setOTHours={setOTHours}
                              setOTMinutes={setOTMinutes}
                              OTType={OTType}
                              setOTType={setOTType}
                              assigneeEmployeeList={assigneeEmployeeList}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Card>
                      <Card
                        style={{ paddingBottom: "80px" }}
                        className="mt-3 mb-3">
                        <Accordion
                          className={classes.root}
                          expanded={expanded.employee}
                          onChange={handleChange("employee")}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="bg-gray-200 sub-header w-100 p-1">
                            <FontAwesomeIcon
                              icon={faRectangleList}
                              className="sub-header"
                              style={{
                                color: "#1569C7",
                                marginRight: "10px",
                                marginTop: "3px",
                              }}
                            />
                            <Typography className={classes.heading}>
                              Employee Account Balance
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="page-body-wrapper">
                            <EmployeeAccountDetail
                              accountBalanceList={{
                                employeeAuthorizedPayTimeCodesList,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Card>
                    </div>
                  </div>

                  {/* ----------------------- */}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Container>
  );
};

export default PayrollWorkTimeVerification;

// const PayrollDetailData = withFormik({
//   enableReinitialize: true,
//   mapPropsToValues: ({ payrollData }) => ({
//     payrollList: payrollData || [
//       {
//         paycode: "",
//         f: "",
//         job_order: "",
//         hrs: "",
//         time_from: moment(new Date()).format("HH:mm"),
//         time_to: moment(new Date()).format("HH:mm"),
//         typh: true,
//         normal_profile: false,
//         date: moment(new Date()).format("MM-DD-YYYY"),
//       },
//     ],
//   }),
//   validationSchema: Yup.object().shape({
//     payrollList: Yup.array().of(
//       Yup.object().shape({
//         paycode: Yup.string().required("First Name is required"),
//         f: Yup.string().required("Last Name is required"),
//         job_order: Yup.string().required("First Name is required"),
//         hrs: Yup.string().required("Last Name is required"),
//         time_from: Yup.string().required("First Name is required"),
//         time_to: Yup.string().required("Last Name is required"),
//         typh: Yup.string().required("First Name is required"),
//       })
//     ),
//   }),
//   async handleSubmit(values, props) {
//     props.props.updateHealthInsDetail({
//       payrollData: values.payrollList,
//     });
//     props.props.showMessage({
//       message: "Saved Successfully",
//       variant: "success",
//     });
//   },
// })(PayrollWorkTime);

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ updatePayrollDetail, showMessage }, dispatch);
// };

// const mapStateToProps = (state) => {
//   return {
//     payrollData: state.PayrollWorkData.PayrollWork.payrollDetail.payrollData,
//     employee: state.PayrollWorkData.PayrollWork.employee,
//   };
// };

// export default withReducer(
//   "PayrollWorkData",
//   reducer
// )(connect(mapStateToProps, mapDispatchToProps)(PayrollDetailData));
