import React, { Fragment } from 'react'
import { Container } from 'reactstrap';
import Header from './_Header';
import CreateUpdateForm from './CreateUpdateForm';
import ErrorBoundary from '../../../ErrorBoundary';

const CreateUpdateDPM = () => {
    return (
        <ErrorBoundary>
            <Fragment>
                <Container fluid={true}>
                    <Header title={`Deduction Plan`} url={'master/deduction-plan'} />
                    <CreateUpdateForm />
                </Container>
            </Fragment>
        </ErrorBoundary>
    )
}

export default CreateUpdateDPM;
