import { Currentlysale } from "../../Common/Data/ApexChart";
import CardHeaderComponent from "../Common/CardHeader";
import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

import ConfigDB from "../../../Config/ThemeConfig";
import moment from "moment/moment";
import { propsWithBsClassName } from "react-bootstrap-typeahead/types/utils";
//  '#26695c'
const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;
//  '#c29873'
const secondary =
  localStorage.getItem("secondary_color") ||
  ConfigDB.data.color.secondary_color;

const EarningDeductionChart = (props) => {
  let dataSeries = [{ data: [] }];
  const [chartEarningData, setChartEarningData] = useState([]);
  const [chartDeductionData, setChartDeductionData] = useState([]);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const earningByPayPeriod = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.deltaEarningByPayPeriod
  );
  const deductionByPayPeriod = useSelector(
    ({ empDashboardSlice }) => empDashboardSlice.deltaDeductionByPayPeriod
  );
  let CurrentlysaleNew = {
    series: [
      {
        data: [
          ["2022-08-14", 30.95],
          ["2022-08-13", 31.34],
          ["2022-08-12", 31.18],
          ["2022-08-11", 31.05],
          ["2022-08-10", 31.05],
          ["2022-08-09", 31.05],
          ["2022-08-08", 31.05],
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: "area",
        stacked: false,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: primary,
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: primary,
              },
            },
          },
        ],
        xaxis: [
          {
            x: new Date(endDate).getTime(),
            borderColor: primary,
            yAxisIndex: 0,
            label: {
              show: true,
              text: "$859.432",
              style: {
                color: "#fff",
                background: primary,
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: new Date(startDate).getTime(),
        tickAmount: 11,
      },
      colors: [primary],
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        autoSelected: "zoom",
      },
      fill: {
        colors: primary,
        borderColor: primary,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  };

  useEffect(() => {
    if (earningByPayPeriod && earningByPayPeriod.length) {
      let tArray = [];
      let data = earningByPayPeriod.map((i) => {
        tArray.push([i.EndDate, i.Amount]);
      });
      setstartDate(
        moment(earningByPayPeriod[0]["EndDate"]).format("DD MMM YYYY")
      );
      setendDate(
        moment(
          earningByPayPeriod[earningByPayPeriod.length - 1]["EndDate"]
        ).format("DD MMM YYYY")
      );

      setChartEarningData(tArray);
    }
  }, [earningByPayPeriod]);
  useEffect(() => {
    if (deductionByPayPeriod && deductionByPayPeriod.length) {
      let tArray = [];
      let data = deductionByPayPeriod.map((i) => {
        tArray.push([i.EndDate, i.Amount]);
      });
      setstartDate(
        moment(deductionByPayPeriod[0]["EndDate"]).format("DD MMM YYYY")
      );
      setendDate(
        // moment(deductionByPayPeriod[0]["EndDate"]).format("DD MMM YYYY")
        moment(
          deductionByPayPeriod[deductionByPayPeriod.length - 1]["EndDate"]
        ).format("DD MMM YYYY")
      );

      setChartDeductionData(tArray);
    }
  }, [deductionByPayPeriod]);
  return (
    <Fragment>
      <Col xl="12" className="box-col-12 des-xl-100 dashboard-sec">
        <Card className="income-card">
          <CardHeader>
            <CardHeaderComponent
              // title="Sales overview"
              // subtitle="86% More than last year"
              title="Earning/Deduction"
              subtitle="by PayPeriod"
              // settingIcon={true}
            />
          </CardHeader>
          <CardBody className="p-0">
            <div id="chart-timeline-dashbord">
              {props.empId !== null ? (
                <Chart
                  options={CurrentlysaleNew.options}
                  series={[
                    {
                      name: "Earning:",
                      data: chartEarningData,
                      color: primary,
                    },
                    {
                      name: "Deduction:",
                      data: chartDeductionData,
                      color: secondary,
                    },
                  ]}
                  height="395"
                  width="100%"
                  type="area"
                />
              ) : (
                <Chart
                  options={Currentlysale.options}
                  series={Currentlysale.series}
                  height="395"
                  width="100%"
                  type="area"
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default EarningDeductionChart;
