import React, { useEffect } from "react";
import CommonConfigrations from "../CommonConfigrations/CommonConfigrations";
import { useDispatch, useSelector } from "react-redux";
import { GetPayCode } from "../CommonConfigrations/TableSlice";

const LeaveConfigration = () => {
  const { payCodeList } = useSelector((state) => state.TableSlice);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(GetPayCode())
  }, []);

  const configrationJSON = {
    headerTitle: "Leave Configration",
    detailTitle: "Leave Configration Detail",
    detailTableTitle: "Leave Configration List",
    tableColumns: [
      {
        id: "payCode",
        name: "Pay Code",
        type: "select",
        label: "Pay Code",
        optionsList: payCodeList ?? [],
      },
      {
        id: "effStartDate",
        name: "Effective Start Date",
        type: "date",
        label: "Effective Start Date",
        dateFormat: "MM/DD/YYYY"
      },
      {
        id: "effEndDate",
        name: "Effective End Date",
        type: "date",
        label: "Effective End Date",
        dateFormat: "MM/DD/YYYY"
      },
      {
        id: "minExperianceYear",
        name: "Min Experiance Year",
        type: "number",
        label: "Min Experiance Year",
      },
      {
        id: "maxExperianceYear",
        name: "Max Experiance Year",
        type: "number",
        label: "Max Experiance Year",
      },
      {
        id: "hours",
        name: "Hours",
        type: "number",
        label: "Hours",
      },
      {
        id: "isActive",
        name: "Is Active",
        type: "status",
        label: "Is Active",
      }
    ],
    getAPIKey:"leaveConfigurations",
    API_ENDPOINT: {
      GET_DATA_API_END_POINT: "LeaveConfiguration/GetAll",
      CREATEORUPDATE_DATA_API_END_POINT: "LeaveConfiguration/CreateOrUpdate",
    },
    INPUT_FIELDS: {
      payCode: "",
      effEndDate: "",
      effStartDate: "",
      minExperianceYear: "",
      maxExperianceYear: "",
      hours: "",
      isActive: false,
    },
  };
  return (
    <>
      <CommonConfigrations configrationJSON={configrationJSON} />
    </>
  );
};

export default LeaveConfigration;
