import DateFnsUtils from "@date-io/date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";
import { Label } from "reactstrap";
import { Checkbox } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";

// const darkTheme = createTheme({
//   palette: {
//     type: "dark",
//   },
// });

// const lightTheme = createTheme({
//   palette: {
//     type: "light",
//   },
// });
// import MaskedInput from "react-text-mask";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(TextField);
const CssTextField1 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#234d10",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b5b5b5",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiFormLabel-root": {
      color: "#808080",
    },
    "& label.Mui-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d1d1d1",
      },

      "& .MuiInputBase-root": {
        height: (params) => (params.height ? params.height : "auto"),
        color: (params) => (params.color ? params.color : "inherit"),
      },
      "&:hover fieldset": {
        borderColor: "#b5b5b5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b5b5b5",
      },
    },
  },
})(KeyboardDatePicker);

function RequestFilter(props) {
  const { changeHeaderFilter, role, requestDetail, filterBy, filterListByChecked } = props;
  const [topLevelDark, setTopLevelDark] = React.useState(false);
  const theme = localStorage.getItem("mix_background_layout");

  useEffect(() => {
    if (theme === "dark-only") {
      setTopLevelDark(true);
    } else {
      setTopLevelDark(false);
    }
  }, [theme]);

  return (
    // <MuiThemeProvider theme={topLevelDark ? lightTheme : darkTheme}>
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Id"
          name="id"
          value={filterBy && filterBy.id}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Code"
          name="deductionCode"
          value={filterBy.deductionCode}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Name"
          name="deductionName"
          value={filterBy.deductionName}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Deduction Type"
          name="deductionType"
          value={filterBy.deductionType}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="PaymentMonthlySch"
          placeholder="Payment Monthly Schedule"
          value={filterBy.paymentMonthlySch}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="deductionMethod"
          placeholder="Deduction Method"
          value={filterBy.deductionMethod}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          format="MM/dd/yyyy"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "startDate", value } });
          }}
          value={filterBy && filterBy.startDate}
          placeholder="Start Date"
          name="startDate"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="date"
          onChange={(event) => {
            let value = event.target.value;
            changeHeaderFilter({ target: { name: "endDate", value } });
          }}
          value={filterBy && filterBy.endDate}
          placeholder="End Date"
          name="endDate"
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="deductionAmount"
          placeholder="Deduction Amount"
          value={filterBy.deductionAmount}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="applyToOT"
          placeholder="Apply to OT"
          value={filterBy.applyToOT}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="totalAmountPaid"
          placeholder="Total Amount Paid"
          value={filterBy.totalAmountPaid}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="totalAmountPlanDue"
          placeholder="Total Amount Plan Due"
          value={filterBy.totalAmountPlanDue}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="accountNoForVendor"
          placeholder="Vendor AccountNo"
          value={filterBy.accountNoForVendor}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="deductionPlanStatus"
          placeholder="Deduction Plan Status"
          value={filterBy.deductionPlanStatus}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.taxable}
          onClick={(event) => filterListByChecked(event, 'taxable')}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.isActive}
          onClick={(event) => filterListByChecked(event, 'isActive')}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>
    </TableRow>
    // </MuiThemeProvider>
  );
}

export default RequestFilter;
