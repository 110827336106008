import { Box, Checkbox, TextField, Tooltip } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import "../Modal.css";
import "./verification.css";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  WeekColor,
  checkIsHoliday,
  getUserRole,
  getValidPaycodeOfDay,
  getValidPaycodeOfDayv2,
  payCodeFilter,
  removeDuplicatesByKey,
} from "../../../../app/utility/helper";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "oidc-react";
import { ImportContacts } from "@material-ui/icons";
import { Bell } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFill,
  faMinusCircle,
  faPlusCircle,
  faStar,
  faStarCircle,
  faStarSharp,
} from "@fortawesome/pro-duotone-svg-icons";
import { GetTagCode } from "../../../Supervisor/CommonConfigrations/TableSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en";

const statusOptions = [
  {
    label: "Approve",
    value: "Approve",
  },
  { label: "Rejected", value: "Rejected" },
  {
    label: "Pending",
    value: "Pending",
  },
];
function TableRowsVerification({
  rowsData,
  deleteTableRows,
  confirmTableRows,
  handleChange,
  handleChangeHizard,
  handleDateFieldChange,
  paytimeCodeSuperVisor,
  setTimeTo,
  setValueChange,
  paytimeCode,
  paytimeCodeByID,
  getDateColor,
  setRowsData,
  payPeriod,
  handleVerifyRows,
  handleApproveStatus,
  addTableRowsIndex,
  index,
  allAccountNumber,
  getJobOrderNov2,
  jobTypeOptions,
  holidaysData,
  setIsDeleteRowModal,
  setDeleteRowIndex,
}) {
  const { tagCodeList } = useSelector((state) => state.TableSlice);
  const { employeeAuthorizedPayTimeCodesList } = useSelector(
    (state) => state.OverTimeSlice
  );
  const dispatch = useDispatch();
  // const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [assignJobOrders, setAssignJobOreders] = useState([]);
  const [hjobTypeOptions, setHJobTypeOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextType, setSearchTextType] = useState("");
  const [defaultType, setDefaultType] = useState({});
  const { userData } = useAuth();
  const userRole = getUserRole(userData);
  const [statusCheck, setStatusCheck] = useState({
    value: "Pending",
    label: "Pending",
  });

  //   <input
  //   className="form-control"
  //   type="text"
  //   placeholder="Employee Number"
  //   name="eName"
  // />
  const jobOrderOPtion = (data, type, code) => {
    let Array = [];

    Array = data?.map((i) => {
      return {
        label: i.jobOrder ? i.jobOrder : "",
        value: i.jobOrder ? i.jobOrder : "",
        id: i.id,
      };
    });

    Array = rowsData[index].JObOrdersData.concat(Array);

    if (type == "single") {
      const filterdata = data?.filter((i) => i.id == code);
      if (filterdata?.length) {
        const singleData = filterdata?.map((i) => {
          return {
            label: i.jobOrder ? i.jobOrder : "",
            value: i.jobOrder ? i.jobOrder : "",
            id: i.id,
          };
        });
        Array.concat(singleData);
      } else {
        Array = [{ label: "", value: "", id: null }];
      }
    }

    let finalArray = removeDuplicatesByKey(Array, "value");

    const rowsInput = [...rowsData];

    let newObj = rowsInput[index];
    newObj["JObOrdersData"] = finalArray;
    setRowsData(rowsInput);
    // setAssignJobOreders(finalArray)
  };

  // useEffect(() => {
  //   const filterPaycodeList = payCodeFilter(
  //     paytimeCode,
  //     paytimeCodeByID,
  //     userRole
  //   );
  //   setJobTypeOptions(filterPaycodeList);
  // }, [paytimeCode, paytimeCodeByID, paytimeCodeSuperVisor]);

  // useEffect(() => {
  //   let UpdatedCodes = paytimeCode.map((i) => {
  //     if (["8", "110", "111", "112", "113", "114", "115"].includes(i.code)) {
  //       i.disabled = true;
  //     } else {
  //       i.disabled = false;
  //     }
  //     return i;
  //   });

  //   // setJobTypeOptions(paytimeCode);

  //   setDefaultType(
  //     paytimeCode.find((i) => {
  //       return i.value + "" === "REG";
  //     })
  //   );

  //   let paytime = paytimeCode.filter((item) => {
  //     return ["10", "11", "110", "111", "112", "113", "114", "115"].includes(
  //       item.code
  //     );
  //   });

  //   const fiteterdArray = paytime.filter((i) => {
  //     if (jobTypeOptions.find((d) => d.code == i.code)) {
  //       return i;
  //     }
  //   });

  //   setHJobTypeOptions(fiteterdArray);
  // }, [paytimeCode, jobTypeOptions, paytimeCodeSuperVisor]);

  
  const handleErrorMessage = (
    ole1,
    ole2,
    dateExceed1,
    dateExceed2,
    leaveHours
  ) => {
    if (ole1 || ole2) {
      return "Check your time slot. It is overwrite with other row";
    } else if (dateExceed1 || dateExceed2) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (ole1 || (ole2 && (dateExceed1 || dateExceed2))) {
      return "This is PPE end date so enter 12AM time in next date";
    } else if (leaveHours) {
      return "Leave Hours Exceeded ";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (["timekeeper"].includes(getUserRole(userData))) {
      // dispatch(GetTagCode());
    }
  }, []);

  const getJobOrderNo = (paycode, date, TagCode) => {
    if (!allAccountNumber?.length) {
      return [{ label: "", value: "" }];
    }
    let allPayoode = getValidPaycodeOfDay(
      date,
      jobTypeOptions,
      paytimeCodeSuperVisor
    );
    let findPaycode = allPayoode?.find((i) => i.code == paycode);
    let accountNumber = [];

    let findAccountNo = allAccountNumber?.find(
      (i) => i.label == findPaycode?.AccountID
    );
    if (findAccountNo) {
      accountNumber.push(findAccountNo.value);
    }
    TagCode?.map((i) => {
      let final = tagCodeList?.find((item) => item.value == i.value)?.jobOrder;
      accountNumber.push(final);
    });

    let finalData = accountNumber?.map((i) => {
      return { label: i ? i : "", value: i ? i : "" };
    });
    const employeeAuthorized = employeeAuthorizedPayTimeCodesList?.filter(
      (item) => {
        let Effdate = item.effectDate
          ? moment(item.effectDate).format("YYYY-MM-DD")
          : null;
        let ExpireDate = item.expireDate
          ? moment(item.expireDate).format("YYYY-MM-DD")
          : null;
        if (item.code == paycode && date >= Effdate && date <= ExpireDate) {
          return item;
        }
      }
    );

    const newData = employeeAuthorized.map((item) => {
      return { label: item?.jobOrderNo || "", value: item?.jobOrderNo || "" };
    });
    return removeDuplicatesByKey([...finalData, ...newData], "label");
  };

  // const getJobOrderNov2 = (paycode, date, TagCode) => {
  //   if(!allAccountNumber?.length){
  //     return [{label : "", value :""}]
  //   }
  //   let allPayoode = getValidPaycodeOfDayv2(
  //     date,
  //     jobTypeOptions,
  //     paytimeCodeSuperVisor,
  //     paytimeCodeByID
  //   )
  //   let findPaycode = allPayoode?.find((i) => i.code == paycode)
  //   let accountNumber = []

  //   let findAccountNo = allAccountNumber?.find((i) => i.label == findPaycode?.AccountID)

  //   if (findAccountNo) {
  //     accountNumber.push(findAccountNo.value)

  //   }
  //   TagCode?.map((i) => {
  //     let final = tagCodeList?.find((item) => item.value == i.value)?.jobOrder
  //     accountNumber.push(final)
  //   })

  //   let finalData = accountNumber?.map((i) =>  {
  //     return { label: i ? i : "", value: i ? i :"" }
  //   })
  //   const employeeAuthorized = employeeAuthorizedPayTimeCodesList?.filter((item) => {
  //     let Effdate = item.effectDate
  //     ? moment(item.effectDate).format("YYYY-MM-DD")
  //     : null;
  //     let ExpireDate = item.expireDate
  //       ? moment(item.expireDate).format("YYYY-MM-DD")
  //       : null;
  //     if (item.code == paycode && (date >= Effdate && date <= ExpireDate)) {
  //       return item;
  //     }
  //   })
  //   console.log("allPayoode data 123",finalData);

  //   const newData= employeeAuthorized.map((item)=>{
  //     return { label: item?.jobOrderNo || "", value: item?.jobOrder || "" }
  //   })
  //   return removeDuplicatesByKey([...finalData,...newData],"label")
  // }

  return rowsData?.length 
    ? rowsData?.map((data, index) => {
        const {
          jobtype,
          date,
          time_from,
          time_to,
          time_from1,
          time_to1,
          hrs,
          jobOrder,
          parentIndex,
          hjobtype,
          ole1,
          ole2,
          rowstatus,
          dateExceed1,
          dateExceed2,
          authError2,
          authError1,
          leaveHours,
          approveTimeFrom,
          approveTimeTo,
          approveHrs,
          approvedStatus,
          verify,
          accountNo,
          TagCode,
          Status,
          JObOrdersData,
          code,
        } = data;
        // let parentIndex = data.parentIndex ? data.parentIndex : "";
        // let parentEnable = parentIdx!= "" ? true : false;
        // const inputProps = {
        //   step: 300,
        //   fontSize: 14,
        // };
        const inputProps = {
          style: { fontSize: 14, step: 300 },
          step: 300,
        };
        const isDisableRowFun = (role, Status) => {
          if (["timekeeper"].includes(role)) {
            return (
              Status === "supervisor" ||
              Status === "admin" ||
              approvedStatus === "Approve"
            );
          } else if (["supervisor"].includes(role)) {
            return Status === "admin";
          } else {
            return false;
          }
        };
        let parentEnable =
          parentIndex !== "0" && parentIndex != null ? true : false;
        const isDisableRow = (parentIndex, jobtype, resource, role, Status) => {
          try {
            if (isDisableRowFun(role, Status)) {
              return true;
            } else {
              if (jobtype === "NDIF") {
                return true;
              } else if (["H-5", "H-8", "H-10"].includes(jobtype)) {
                if (
                  ["time_from", "time_to", "time_from1", "time_to1"].includes(
                    resource
                  )
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          } catch (error) {
            console.log("error", error.message);
          }
        };
         
        // const jobType = jobTypeOptions.filter((val) => val.label === jobtype);
        const getSelectedType = (jobtype) => {
          const jobTypeOptions2 = getValidPaycodeOfDayv2(
            date,
            jobTypeOptions,
            paytimeCodeSuperVisor,
            paytimeCodeByID
          );
          let filterObj = jobTypeOptions2?.find((i) => {
            return i.value + "" === jobtype + "";
          });

          return filterObj !== undefined
            ? filterObj
            : {
                value: "REG",
                label: "REG",
                title: "REGULAR",
                code: "1",
                multi: 1,
                EffectDate: "1990-01-01T00:00:00.000Z",
                ExpireDate: "2099-12-31T00:00:00.000Z",
                name: "jobtype",
              };
        };
        const getSelectedHType = (jobtype) => {
          let filterObj = hjobTypeOptions.find((i) => {
            return i.value + "" === jobtype + "";
          });

          return filterObj !== undefined ? filterObj : null;
        };
        const getColor = (value) => {
          if (value === "Approve") {
            return "#4BB543";
          } else if (value === "Rejected") {
            return "red";
          } else {
            return "#ddb009";
          }
        };

        const weekColors = WeekColor(
          payPeriod?.StartDate,
          payPeriod?.EndDate,
          date,
          jobtype
        );
        
        let jobOrderValue = false;
        if (typeof jobOrder === "string") {
          jobOrderValue = jobOrder != "null";
        } else {
          jobOrderValue = jobOrder != null;
        }
        if (jobOrderValue) {
          const getJobOrder = getJobOrderNo(code, date, TagCode)?.map((i) => {
            return i.label;
          });
          const isJobOreder = getJobOrder.includes(jobOrder);
          if (!isJobOreder) {
            handleChange(index, {
              target: {
                value: getJobOrderNo(code, date, TagCode)[0]?.label,
                name: "jobOrder",
              },
            });
          }
        }
        const disableRowTable = isDisableRowFun(userRole, Status);

        const checkHolidays = checkIsHoliday(holidaysData, date);
        return (
          <React.Fragment key={`x${index}`}>
            <tr
              key={index}
              bordercolor="red"
              className={index % 2 === 0 ? "trRow1" : "trRow2"}
              style={{
                marginBottom: 0,
                marginTop: 0,
                backgroundColor: getDateColor(date, rowsData),
                // alignItems: "center",
                // display: "flex",
              }}
              // style={{ backgroundColor: (ole1 || ole2 ? "#e7e7e7" : null),borderRadius:'20px' }}
            >
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                {/* <Tooltip title={
                      jobtype
                        ? getSelectedType(jobtype)?.label
                      :null
                    } placement="bottom"> */}
                  <Autocomplete
                  disablePortal
                    size={"small"}
                    id="combo-box-demo"
                    name="combo-box-demo"
                    // disabled={ parentEnable }
                    disabled={disableRowTable}
                    autoHighlight={true}
                    autoSelect={false}
                    className="flex w-full mx-10 paroll-autocomplete "
                    onChange={(e, value) => {
                      console.log("saadsad", value);
                      handleChange(index, {
                        target: {
                          value: value ? value.value : "",
                          code: value ? value.code : " ",
                          name: "jobtype",
                        },
                      });
                      handleChange(index, {
                        target: {
                          value: getJobOrderNov2(value?.code, date, TagCode)[0]
                            ?.label,
                          name: "jobOrder",
                        },
                      });

                      let obj = [
                        {
                          value: value?.value,
                          label: value?.value,
                          jobOrder: value?.AccountID,
                          id: value?.code,
                        },
                      ];
                    }}
                    value={
                      jobtype
                        ? getSelectedType(jobtype)
                        : { label: "Select", value: " " }
                    }
                    filterOptions={(item) => {
                      return item.filter((i) =>
                        i.code
                          ? i.code
                              .replace(/-/g, "")
                              .toLowerCase()
                              .match(
                                `${searchTextType
                                  .replace(/-/g, "")
                                  .toLowerCase()}`
                              )
                          : ""
                      );
                    }}
                    // getOptionDisabled={(option) => !!timeSlotsArr.find(element => element === option)}

                    // options={jobTypeOptions.filter((val) => !["NDIF","H-5",'H-8','H-10'].includes(val.value))}

                    options={getValidPaycodeOfDayv2(
                      date,
                      jobTypeOptions,
                      paytimeCodeSuperVisor,
                      paytimeCodeByID
                    )}
                    getOptionDisabled={(jobTypeOptions) =>
                      !!jobTypeOptions.disabled
                    }
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        
                        {option.name == "jobtype" ? (
                          <FontAwesomeIcon
                            icon={faStar}
                            // style={{ color: "#24695c", marginRight: "10px" }}
                            style={{
                              marginRight: "10px",
                              color: "rgb(221, 176, 9)",
                              height: "15px",
                              width: "15px",
                              border: "1px solid rgb(221, 176, 9)",
                              borderRadius: "20px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faStar}
                            // style={{ color: "#24695c", marginRight: "10px" }}
                            style={{ marginRight: "10px" }}
                          />
                        )}
                        {option.label + " - " + option.title}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="MuiAutocomplete-input"
                        size="small"
                        // label="Pay Type"
                        variant="outlined"
                        hiddenLabel="true"
                        input
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: 14 },
                        }}
                        onChange={(e) => {
                          setSearchTextType(e.target.value);
                        }}
                      />
                    )}
                  />
                {/* </Tooltip> */}
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="w-100 payroll-content-date1"
                  // margin="dense"
                  size="small"
                  format="MM/dd/yyyy"
                  disableToolbar
                  inputVariant={"outlined"}
                  id="date-picker-inline"
                  // label="Date"
                  value={moment(date).format("MM/DD/YYYY")}
                  minDate={payPeriod.StartDate ? moment(payPeriod.StartDate).format("MM/DD/YYYY") : null}
                  maxDate={payPeriod.EndDate ? moment(payPeriod.EndDate).format("MM/DD/YYYY") : null}
                  onChange={ (_, value) => {
                    let timer = null;
                    timer = setTimeout(() => {
                      console.log('MAx',value);
                      if (moment(value, "MM/DD/YYYY", true).isValid()) {
                        console.log('MAx isvalid', value);
                        handleDateFieldChange("date", value, index);
                      } else {
                        console.log('MAx isvalid',value);
                      }
                      
                  
                    }, 1000);
                    return () => {
                      if (timer) {
                          clearTimeout(timer);
                      }
                  };
      
                     
                    
                  } }
                  mask="__/__/____"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isDisableRow(parentIndex, jobtype, "date",userRole, Status)}
                  name="date"
                  // variant="outlined"
                  hiddenLabel="true"
                />
              </MuiPickersUtilsProvider> */}
                {/* {JSON.stringify(checkIsHoliday(holidaysData, date))} */}
                <div style={{ display: "flex" }}>
                  {checkHolidays?.status ? (
                    <span>
                      <Tooltip title={checkHolidays?.name} placement="bottom">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            marginRight: "3px",
                            color: "red",
                            height: "15px",
                            width: "15px",
                            border: "1px solid red",
                            borderRadius: "20px",
                            marginTop: "15px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  ) : (
                    <span style={{ marginRight: "18px" }}></span>
                  )}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      format="dd MM/DD/YYYY"
                      label=""
                      value={date ? dayjs(date) : null}
                      onChange={(e) => {
                        handleDateFieldChange("date", e?.$d, index);
                      }}
                      disabled={isDisableRow(
                        parentIndex,
                        jobtype,
                        "date",
                        userRole,
                        Status
                      )}
                      name="date"
                      hiddenLabel={true}
                      minDate={
                        payPeriod.StartDate ? dayjs(payPeriod.StartDate) : null
                      }
                      maxDate={
                        payPeriod.EndDate ? dayjs(payPeriod.EndDate) : null
                      }
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "38px",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "14px",
                          width: "90px",
                        },
                      }}
                      renderInput={(props) => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                </div>
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="From"
                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 || dateExceed1 || authError1 || leaveHours
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_from ? time_from : ""}
                  onChange={(e) => {
                    handleChange(index, e);
                    if (e.target.value) {
                      setTimeTo(e.target.value);
                    }
                  }}
                  margin="dense"
                  name="time_from"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={ parentEnable }
                  disabled={isDisableRow(
                    parentIndex,
                    jobtype,
                    "time_from",
                    userRole,
                    Status
                  )}
                  // hiddenLabel="true"
                />
              </td>
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  id="time"
                  // label="To"

                  type="time"
                  inputProps={inputProps}
                  error={
                    ole1 || dateExceed1 || authError1 || leaveHours
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                    },
                    shrink: true,
                  }}
                  value={time_to ? time_to : ""}
                  onChange={(e) => {
                  
                    if (e.target.value) {
                      console.log('Mrbes');
                      handleChange(index, e);
                      setTimeTo(e.target.value);
                      
                      console.log('Mrbes3');
                         
                        // setValueChange({
                        //   index:index,
                        //   target: {
                        //     value: e.target.value?e.target.value : "",
                        //     name: "time_to",
                        //   }
                        // });
                        
                      
                    }
                  }}
                  margin="dense"
                  name="time_to"
                  fullWidth={true}
                  variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  disabled={isDisableRow(
                    parentIndex,
                    jobtype,
                    "time_to",
                    userRole,
                    Status
                  )}
                />
              </td>

              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <TextField
                  size={"small"}
                  margin="dense"
                  // label="Hrs"
                  name="hours"
                  inputProps={{
                    readOnly: true,
                    fontSize: 12,
                    style: { fontSize: 14 },
                  }}
                  fullWidth={true}
                  variant="outlined"
                  value={hrs}
                  onChange={(e) => handleChange(index, e)}
                  sx={{ fontSize: 14 }}
                  disabled={isDisableRow(
                    parentIndex,
                    jobtype,
                    "hrs",
                    userRole,
                    Status
                  )}
                />
              </td>
              {["supervisor", "timekeeper"].includes(getUserRole(userData)) && (
                <>
                  <td
                    className="paroll-contents-td tcolor"
                    style={{ backgroundColor: `${weekColors}` }}
                  >
                    <Checkbox
                      // disabled={parentEnable}
                      // onClick={ () => confirmTableRows(index) }
                      // style={{ width: "70px", background: "none" }}
                      name="rowstatus"
                      // disabled={verify}
                      disabled={disableRowTable}
                      checked={verify}
                      onChange={(event, value) => {
                        handleVerifyRows(index, event);
                      }}
                    >
                      {verify ? "Verified" : "Verify"}
                    </Checkbox>
                  </td>

                  <td
                    className="paroll-contents-td"
                    style={{ backgroundColor: `${weekColors}` }}
                  >
                    <Autocomplete
                      size={"small"}
                      autoHighlight={true}
                      autoSelect={true}
                      className="flex w-full mx-10 paroll-autocomplete "
                      disabled={disableRowTable}
                      onChange={(e, value) => {
                        console.log("sdsdsdsdsdsdsdsdsdsd", value);
                        handleChange(index, {
                          target: {
                            value: value?.value ?? "",
                            name: "jobOrder",
                          },
                        });
                      }}
                      value={
                        jobOrderValue
                          ? jobOrder
                          : getJobOrderNov2(code, date, TagCode).length > 0
                          ? getJobOrderNov2(code, date, TagCode)[0]
                          : { label: "", value: "" }
                      }
                    isOptionEqualToValue={(option, value) => option.id === value.id}

                      options={getJobOrderNov2(code, date, TagCode)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="MuiAutocomplete-input"
                          size="small"
                          variant="outlined"
                          hiddenLabel="true"
                          label="Select Account No"
                          input
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </>
              )}
              {["supervisor"].includes(getUserRole(userData)) && (
                <>
                  {/* <td className="paroll-contents-td" style={{backgroundColor:`${weekColors}`}}>
                  <TextField
                    size={"small"}
                    id="time"
                    // label="From"
                    type="time"
                    inputProps={inputProps}
                    //error={ole1 || dateExceed1 || authError1 ||leaveHours  ? true : false}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      },
                      shrink: true,
                    }}
                    value={approveTimeFrom ? approveTimeFrom : ""}
                    onChange={(e) => {
                      handleChange(index, e);
                      // if (e.target.value) {
                      //   setTimeTo(e.target.value);
                      // }
                    }}
                    // onKeyUp={(e) => {
                    //   if (e.target.value) {
                    //     setTimeTo(e.target.value);
                    //   }
                    // }}
                    // onBlur={(e) => {
                    //   if (e.target.value) {
                    //     setTimeTo(e.target.value);
                    //   }
                    // } }

                    margin="dense"
                    name="approveTimeFrom"
                    fullWidth={true}
                    variant="outlined"
                    // defaultValue={moment(new Date()).format("HH:mm")}
                    // disabled={ parentEnable }
                    //disabled={isDisableRow(parentIndex, jobtype, "time_from")}
                    hiddenLabel="true"
                  />
                </td> */}
                  {/* <td className="paroll-contents-td" style={{backgroundColor:`${weekColors}`}}>
                  <TextField
                    size={"small"}
                    id="time"
                    // label="To"

                    type="time"
                    inputProps={inputProps}
                    //error={ole1 || dateExceed1 || authError1 ||leaveHours ? true : false}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                      },
                      shrink: true,
                    }}
                    value={approveTimeTo ? approveTimeTo : ""}
                    onChange={(e) => {
                      handleChange(index, e);
                      // if (e.target.value) {
                      //   setTimeTo(e.target.value);
                      // }
                    }}
                    // onKeyUp={(e) => {
                    //   if (e.target.value) {
                    //     setTimeTo(e.target.value);
                    //   }
                    // }}
                    // onBlur={(e) => {
                    //   if (e.target.value) {
                    //     setTimeTo(e.target.value);
                    //   }
                    // }}
                    margin="dense"
                    name="approveTimeTo"
                    fullWidth={true}
                    variant="outlined"
                  // defaultValue={moment(new Date()).format("HH:mm")}
                  // disabled={isDisableRow(parentIndex, jobtype, "time_to")}
                  />
                </td> */}
                  {/* <td className="paroll-contents-td" style={{backgroundColor:`${weekColors}`}}>
                  <TextField
                    size={"small"}
                    margin="dense"
                    // label="Hrs"
                    name="hours"
                    inputProps={{
                      readOnly: true,
                      fontSize: 12,
                      style: { fontSize: 14 },
                    }}
                    fullWidth={true}
                    variant="outlined"
                    value={approveHrs ? approveHrs : "00:00"}
                    // onChange={(e) => handleChange(index, e)}
                    sx={{ fontSize: 14 }}
                  // disabled={isDisableRow(parentIndex, jobtype, "hrs")}
                  />
                </td> */}
                  {/* <td className="paroll-contents-td tcolor">
                <button
                  className={ `
                  ${parentEnable ? "" : ""}
                   btn btn-outline-primary bg-primary paroll-contents-calcle `}
                  // disabled={parentEnable}
                  // onClick={ () => confirmTableRows(index) }
                  name="rowstatus"
                  onClick={(e, value) => {
                    confirmTableRows(index, {
                      target: {
                        value: 'Confirmed',
                        name: "rowstatus",
                      },
                    });
                  }}
                >
                  Approve
                </button>
              </td> */}

                  <td
                    className="paroll-contents-td"
                    style={{ backgroundColor: `${weekColors}` }}
                  >
                    {/* <Autocomplete
                      size={"small"}
                      disableClearable
                      style={{
                        width: "100%",
                        backgroundColor: getColor(
                          approvedStatus ? approvedStatus : "Pending"
                        ),
                        borderRadius: "10px",
                        overflow: "hidden",
                        border: "none",
                      }}
                      disabled={disableRowTable}
                      autoHighlight={true}
                      autoSelect={true}
                      autoFocus={false}
                      className="flex w-full mx-10 statusBtn"
                      onChange={(e, value) => {
                        handleApproveStatus(index, value?.value);
                      }}
                      value={{
                        value: approvedStatus ? approvedStatus : "Pending",
                        label: approvedStatus ? approvedStatus : "Pending",
                      }}
                      filterOptions={(item) => {
                        return item.filter((i) =>
                          i.value
                            ? i.value
                                .replace(/-/g, "")
                                .toLowerCase()
                                .match(
                                  `${searchTextType
                                    .replace(/-/g, "")
                                    .toLowerCase()}`
                                )
                            : ""
                        );
                      }}
                      options={statusOptions}
                      getOptionDisabled={(jobTypeOptions) =>
                        !!jobTypeOptions.disabled
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          hiddenLabel="true"
                          input
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: 14,
                              color: "white",
                              border: "none",
                            },
                          }}
                        />
                      )}
                    /> */}
                  </td>
                </>
              )}

              {/* </td> } */}
              {/* {["timekeeper"].includes(
              getUserRole(userData)
            ) && <>
                <td className="paroll-contents-td">
                  <Autocomplete
                    disablePortal
                    margin="dense"
                    className="flex w-full mx-10 paroll-autocomplete "
                    variant="outlined"
                    size="small"
                    multiple
                    options={tagCodeList || []}

                    value={data?.TagCode || []}
                    onChange={(e, newValue) => {
                      const rowsInput = [...rowsData];
                      let newObj = rowsInput[index];
                      newObj["TagCode"] = newValue
                      setRowsData(rowsInput);

                    }}
                    renderInput={(params) => (
                      <TextField {...params}
                        hiddenLabel="true"
                        input
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: 14,
                            color: "white",
                            border: "none",
                          },
                        }}
                      />
                    )}
                  />
                </td>

              </>
            } */}
              <td
                className="paroll-contents-td"
                style={{ backgroundColor: `${weekColors}` }}
              >
                <div className="flex justify-content-center">
                  <FontAwesomeIcon
                    onClick={() => {
                      !disableRowTable && addTableRowsIndex(index);
                    }}
                    icon={faPlusCircle}
                    // style={{ color: "#24695c", marginRight: "10px" }}
                    style={{
                      marginRight: "10px",
                      color: disableRowTable ? "#6abd6a" : "green",
                      height: "20px",
                      width: "20px",
                      border: `1px solid ${
                        disableRowTable ? "#6abd6a" : "green"
                      }`,
                      borderRadius: "20px",
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      !disableRowTable && setDeleteRowIndex(index);
                      setIsDeleteRowModal(true);
                    }}
                    icon={faMinusCircle}
                    // style={{ color: "#24695c", marginRight: "10px" }}
                    style={{
                      marginRight: "10px",
                      color: disableRowTable ? "#b34a4a" : "red",
                      height: "20px",
                      width: "20px",
                      border: `1px solid ${
                        disableRowTable ? "#b34a4a" : "red"
                      }`,
                      borderRadius: "20px",
                    }}
                  />
                </div>
              </td>
            </tr>
            {/*<tr
          
        >
          <td
            colspan="10"
          >My Work</td>
          </tr> */}

            <tr
              style={{
                display:
                  ole1 || ole2 || dateExceed1 || dateExceed2 || leaveHours
                    ? ""
                    : "none",
              }}
            >
              <td
                colSpan="10"
                style={{
                  justifyContent: "center",
                  // display: "flex",
                  alignItems: "center",
                  transition: "ease-in",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {/* {ole1   || ole2 
                    ? "Check your time slot. It is overwrite with other row"
                    : ""} */}
                  {handleErrorMessage(
                    ole1,
                    ole2,
                    dateExceed1,
                    dateExceed2,
                    leaveHours
                  )}
                </p>
              </td>
            </tr>
          </React.Fragment>
        );
      })
    : null;
}

export default TableRowsVerification;
