import {
  Database,
  Headphones,
  Home,
  FileText,
  Watch,
  Table,
} from "react-feather";
// import { useAuth } from "oidc-react";

// const { userData } = useAuth();

// userData && userData.profile && userData.profile.role === "admin"
//   ? MENU_1
//   : MENU_2;

const dashboard = {
  title: "Dashboard",
  icon: Home,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}dashboard/default`,
  // children: [
  //   {
  //     path: `${process.env.REACT_APP_PREFIX_URL}dashboard/default`,
  //     title: 'Default',
  //     type: 'link',
  //   },
  //   // {
  //   //   path: `${process.env.REACT_APP_PREFIX_URL}dashboard/ecommerce`,
  //   //   title: 'ecommerce',
  //   //   type: 'link',
  //   // },
  // ],
};
const configurationMaster = {
  title: "Configuration Master",
  icon: FileText,
  type: "sub",
  role: "admin",
  active: false,
  children: [
    {
      title: "PayCode Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/paytimecode`,
    },
    {
      title: "Deduction Plan Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/deduction-plan`,
    },
    {
      title: "Vendor Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/vendors`,
    },
    {
      title: "Deduction Type Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/deduction-type`,
    },
    {
      title: "Department Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/department`,
    },
    {
      title: "Ethnic Code",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/ethnic-code`,
    },
    {
      title: "Employee Certification Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/employee-certification`,
    },
    {
      title: "Federal Tax Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/federal-tax`,
    },
    {
      title: "Tax Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/state-tax`,
    },
    {
      title: "State Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/state`,
    },
    {
      title: "Garnishment Type Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/garnishment-type`,
    },
    {
      title: "Garnishment Deduction Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/garnishment`,
    },
    {
      title: "Benefit Deduction Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/benefit`,
    },
    {
      title: "Miscellaneous Deduction Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/miscellaneous`,
    },
    {
      title: "Retirement Deduction Master",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/retirement-plan`,
    },
    // {
    //   title: "Payment Option Master",
    //   type: "link",
    //   path: `${process.env.REACT_APP_PREFIX_URL}master/payment-option-type`,
    // },
    {
      title: "Inactive Status",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/inactive-status`,
    },
    {
      title: "Pay Step",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/pay-step`,
    },
    {
      title: "NPosition",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/nposition`,
    },
    {
      title: "Military Status",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/military-status`,
    },
    {
      title: "TimeSheet Template",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet-template`,
    },
    {
      title: "PayCode Template",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/paycode-template`,
    },
    // {
    //   title: "View TimeSheet Templates",
    //   type: "link",
    //   path: `${process.env.REACT_APP_PREFIX_URL}master/view-timesheet-templates`,
    // },
    // {
    //   title: "TimeSheet",
    //   type: "link",
    //   path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet`,
    // },
    {
      title: "Act Type",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/act-type`,
    },
    {
      title: "Education",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/education`,
    },
    {
      title: "Employee Type",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/employee-type`,
    },
    {
      title: "Pay Grade",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/paygrade`,
    },
  ],
};

const configurationMasterSuperVisor = {
  title: "Configuration Master",
  icon: FileText,
  type: "sub",
  role: "supervisor",
  active: false,
  children: [
    {
      title: "Leave Configration",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}master/leave-configration`,
    },
  ],
};

const masterDetailsEmployee = {
  title: "Master Details",
  icon: FileText,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}master-detail`,
};
const employeeManagement = {
  title: "Employee Management",
  icon: Database,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}admin/employee`,
};
const employeeAssignment = {
  title: "Employee Tag Management",
  icon: Database,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}admin/employee-tag-management`,
};
const SupervisorDetails = {
  title: "Supervisor",
  icon: Database,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}admin/supervisor`,
};
const scanQrCode = {
  title: "Scan Qr Code",
  icon: Database,
  type: "link",
  role: "admin",
  path: `${process.env.REACT_APP_PREFIX_URL}admin/scanqrCode`,
};
const payrollTime = {
  title: "Payroll Time",
  icon: Watch,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}payroll-work`,
};
const earningsReports = {
  title: "Earnings Reports",
  icon: Watch,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}earnings-reports`,
};
const timeSheetVerify = {
  title: "TimeSheet Verify",
  icon: Watch,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}payroll-work-verify`,
};
const reports = {
  title: "Reports",
  icon: FileText,
  type: "sub",
  active: false,
  children: [
    {
      title: "Earning Report",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/earning`,
    },
    {
      title: "W2 Report",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/w2`,
    },
    {
      title: "Combined Deduction Report",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/combined-deduction`,
    },
    {
      title: "IRS Quarterly Report",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/irs-quarterly`,
    },
    {
      title: "Deduction Summary",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/deduction`,
    },
    {
      title: "Payroll Processing Exception Reports",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}admin/working-hrs`,
    },
    {
      title: "Deduction By Vendor",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/deductionbyvendor`,
    },
    {
      title: "Labor Cost Contribution",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/laborcost`,
    },
    {
      title: "Department Summary",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/department`,
    },
    {
      title: "Sankey Chart",
      type: "link",
      path: `${process.env.REACT_APP_PREFIX_URL}report/sankey`,
    },
  ],
};

const payrollMasterEmployee = {
  title: "Master Details",
  icon: Table,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}admin/employee/update/`,
};

const ApplyLeave = {
  title: "Leave Apply",
  icon: Table,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}master/leave-apply`,
};

const TimeSheetTemplateEmployee = {
  title: "TimeSheet Template",
  icon: Table,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}master/timesheet-template`,
};

const PayCodeTemplateEmployee = {
  title: "PayCode Template",
  icon: Table,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}master/paycode-template`,
};

const PayRollRun = {
  title: "PayRoll Run",
  icon: Table,
  type: "link",
  path: `${process.env.REACT_APP_PREFIX_URL}payroll-run`,
};

const LegacyPayroll = {
  title: "Lagecy Payroll",
  icon: Headphones,
  type: "link",
  active: false,
  path: `legacy-payroll`,
};

const MENU_ADMIN = [
  {
    menutitle: "General [ ADMIN ]",
    menucontent: "Dashboards,Widgets",
    Items: [
      dashboard,
      configurationMaster,
      employeeManagement,
      employeeAssignment,
      SupervisorDetails,
      reports,
      payrollTime,
      scanQrCode,
      PayRollRun,
      LegacyPayroll,
    ],
  },
];

const MENU_SUPERVISOR = [
  {
    menutitle: "General [ ADMIN ]",
    menucontent: "Dashboards,Widgets",

    Items: [
      dashboard,
      employeeManagement,
      configurationMasterSuperVisor,
      payrollTime,
      TimeSheetTemplateEmployee,
      PayCodeTemplateEmployee,
      timeSheetVerify
    ],
  },
];
const MENU_TIMEKEEPER = [
  {
    menutitle: "General [ ADMIN ]",
    menucontent: "Dashboards,Widgets",

    Items: [
      dashboard,
      employeeManagement,
      payrollTime,
      TimeSheetTemplateEmployee,
      PayCodeTemplateEmployee,
      earningsReports,
      timeSheetVerify,
    ],
  },
];

const MENU_USER = [
  {
    menutitle: "General [ Employee ]",
    menucontent: "Dashboards,Widgets",
    Items: [
      dashboard,
      // masterDetailsEmployee,
      payrollMasterEmployee,
      payrollTime,
    ],
  },
];

const MENU_DEPARTMENT = [
  {
    menutitle: "General [ Department ]",
    menucontent: "Dashboards,Widgets",
    Items: [dashboard, employeeManagement, reports, SupervisorDetails],
  },
];

export const MENUITEMS = (role) => {
  if (role === "admin") {
    return MENU_ADMIN;
  } else if (role === "user") {
    return MENU_USER;
  } else if (role === "DepartmentAdmin") {
    return MENU_DEPARTMENT;
  } else if (role === "supervisor") {
    return MENU_SUPERVISOR;
  } else if (role === "timekeeper") {
    return MENU_TIMEKEEPER;
  }

  return MENU_USER;
};
