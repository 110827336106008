import Typography from "@material-ui/core/Typography";
import React from "react";
import Icon from "@material-ui/core/Icon";
import { Button } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Hidden from "@material-ui/core/Hidden";
import { CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMessageExclamation,
  faAdd,
  faClose,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
import { Row } from "react-bootstrap";
import moment from "moment";

function TimeSheetHeader(props) {
  return (
    <>
      <CardHeader>
        <div>
          <div
            className={`flex flex-1 w-full bg-primary`}
            style={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "#1975D2",
              height: "4.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faAddressCard}
              className="mx-10  px-3 fs-4"
            />

            {props.screen === "details" ? (
              <Typography className="fs-5">
                PayCode Template {props.data?.TypeCodeName}
              </Typography>
            ) : (
              <Typography className="fs-5">PayCode Template</Typography>
            )}

            <div style={{ display: "flex", flex: 1 }}></div>
            {!props.screen && (
              <Button
                variant="contained"
                className="m-10 mx-2 xs:mx-10 min-w-0"
                style={{
                  color: "#234d10",
                  backgroundColor: "#ffffff",
                }}
                onClick={() => {
                  props.createTimeSheet();
                }}
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  style={{ color: "#24695c", marginRight: "10px" }}
                />
                <Hidden smDown>
                  <Typography className="text-12">
                    {" "}
                    {/* {props.detail.id ? "Update" : "Save"}{" "} */}
                    Add
                  </Typography>
                </Hidden>
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
    </>
  );
}

export default TimeSheetHeader;
