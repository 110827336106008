// import React from "react";
// import { Route, Routes } from "react-router-dom";
// import { routes } from "./Routes";
// import AppLayout from "../Layout/Layout";
// import { useAuth } from "oidc-react";

// const LayoutRoutes = () => {
//   const { userData } = useAuth();
//   return (
//     <>
//       <Routes>
//         {routes.map(({ path, Component, auth }, i) =>
//           auth.includes(
//             userData && userData.profile && userData.profile.role
//               ? userData.profile.role
//               : ""
//           ) ? (
//             <Route element={<AppLayout />} key={i}>
//               <Route path={path} element={Component} />
//             </Route>
//           ) : null
//         )}
//       </Routes>
//     </>
//   );
// };

// export default LayoutRoutes;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import AppLayout from "../Layout/Layout";
import { useAuth } from "oidc-react";

const LayoutRoutes = () => {
  const { userData } = useAuth();
  return (
    <>
      <Routes>
      {routes.filter(d=> {
			return (d.roles && userData?.profile?.role && d.roles.indexOf(userData?.profile?.role) > -1) || d.roles === undefined
		}).map(({ path,layout, Component }, i) => {
			
			if(layout === 'none') {
				return <Route key={i}>
				<Route path={path}  element={Component}  />
         	</Route>
			} else {
           return <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
      }
    })}
      </Routes>
    </>
  );
};

export default LayoutRoutes;

