import React, { Fragment, useEffect, useRef, useState } from "react";
import { AccordionDetails, AccordionSummary, Container, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";
// import {
//   getDeductionType,
//   getOverAllDeduction,
//   getOverAllEarning,
//   getVendor,
// } from "app/utility/axiosService";
import ReportLayoutByVendor from "./component/ReportLayoutByVendor";
import ReportHeader from "../ReportHeader";
// import { fetchDeductionTypeMaster } from "app/store/reducers/slices/masterSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "16px",
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  // secondaryHeading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   flexBasis: "33.33%",
  //   flexShrink: 0,
  //   // color: theme.palette.text.secondary,
  // },
}));

function DeductionByVendorReport(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({
    Code: "",
    VendorCode: "",
    Description: "",
    EmployeerFee: "",
    EmployeeFee: "",
    IsPercent: "",
    StartDate: null,
    EndDate: null,
    DeductionType: [],
    SelectedEmployee: [],
    DepartmentDivision: [],
    PayTimeCode: [],
  });

  const [vendors, setVendors] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [value, setValue] = useState(0);

  const deductionByVendor = useSelector(
    ({ chartSlice }) => chartSlice.queryDeductionByVendor
  );

  console.log("deductionByVendor", deductionByVendor);

  useEffect(() => {
    setTableList(deductionByVendor);
  }, [deductionByVendor]);

  const pageLayout = useRef(null);

  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}deductionPlan`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        console.log("Master timecode", response.data);
        setTableList(response.data);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  // useEffect(() => {
  //   if (detail && detail.SelectedEmployee) {
  //     alert("selected emp " + detail.SelectedEmployee.EmployeeName);
  //   }
  // }, [detail, detail.SelectedEmployee]);

  // useEffect(() => {
  //   if (detail && detail.PayTimeCode && detail.PayTimeCode.length > 0) {
  //     alert(
  //       "selected Paytime " +
  //         detail.PayTimeCode[detail.PayTimeCode.length - 1].Description
  //     );
  //   }
  // }, [detail, detail.PayTimeCode]);

  // useEffect(() => {
  //   getData();
  //   (async () => {
  //     setDeductionTypes(await getDeductionType());
  //     setVendors(await getVendor());
  //     setDeduction(await getOverAllDeduction());
  //     setEarning(await getOverAllEarning());
  //   })();
  //   dispatch(fetchDeductionTypeMaster());
  // }, []);

  return (
    <Container fluid={true} style={{ overflow: "auto" }}>
        
      <div style={{ position: "sticky", top: 0, zIndex: 1 }} ref={pageLayout}>
        <ReportHeader {...props} header="Vendor's Deduction Summary report" />
      </div>
      <div className="page-content">
      <div className="w-100 h-full mt-3  " ref={pageLayout} innerScroll>
        <div className={`${classes.root} w-100`}>
          <ReportLayoutByVendor
            {...props}
            role={"admin"}
            deduction={deduction}
            earning={earning}
            requestDetails={tableList}
          />
        </div>
      </div>
      </div>
    </Container>
  );
}

export default DeductionByVendorReport;
