import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";
import {
  citizenshipOptions,
  ethnicCodeOptions,
  departmentDivisionOptions,
  jobOrderNumberOptions,
  objectCodeOptions,
  employmentTypeOptions,
} from "./constants";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { FormLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAdvanceSearchData } from "../../AdvanceSearch/slices/configurationSlice";
import QRCodeModal from "../../employee/component/QRCodeModal";
import { faCalendar, faQrcode } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EmployeeInfo = (props) => {
  const {
    optionsMaster,
    userDetail,
    changeDetails,
    userInfo,
    setUserInfo,
    empTypes,
    state,
    handleChange,
    setState,
  } = props;
  console.log("userInfo", userInfo);
  console.log("state========", state);
  const history = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState({
    ID: null,
    NAME: "",
  });

  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };

  return (
    <Card className="mt-4">
      {/* <CardHeader
        className="pb-0"
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        <H5 style={{ margin: 0 }}>Employee Information</H5>
        <FontAwesomeIcon
          onClick={() =>
            handleModal(
              userInfo && userInfo.ID ? userInfo.ID : "1",
              userInfo && userInfo.EmployeeName ? userInfo.EmployeeName : "Test"
            )
          }
          className="sub-header"
          icon={faQrcode}
          style={{
            cursor: "pointer",
            color: "#1569C7",
            // marginRight: "10px",
            marginRight: "0"
          }}
          
          
        />
        <FontAwesomeIcon 
        onClick={() => {
          return history(`/payroll-work/${userInfo.ID}`);
        }}
        className="sub-header"
          icon={faCalendar}
          style={{
            cursor: "pointer",
            color: "#1569C7",
            // marginRight: "10px",
            marginLeft: "0",
          }}
        
        />
      </CardHeader> */}
      <CardHeader
        className="pb-0"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <H5 style={{ margin: 0 }}>Employee Information</H5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto", // This pushes the icons to the right
          }}
        >
          <FontAwesomeIcon
            onClick={() =>
              handleModal(
                userInfo && userInfo.ID ? userInfo.ID : "1",
                userInfo && userInfo.EmployeeName ? userInfo.EmployeeName : "Test"
              )
            }
            className="sub-header"
            icon={faQrcode}
            style={{
              cursor: "pointer",
              color: "#1569C7",
              marginRight: "10px",
            }}
          />
          <FontAwesomeIcon
            onClick={() => {
              return history(`/payroll-work/${userInfo.ID}`);
            }}
            className="sub-header"
            icon={faCalendar}
            style={{
              cursor: "pointer",
              color: "#1569C7",
              margin: 0, // No margin for the second icon
            }}
          />
        </div>
      </CardHeader>

      <CardBody>
        <Form>
          <Row className="g-3  ">
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                style={{ width: "100%" }}
                value={state.employeeNumber}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    width: "100%",
                  },
                }}
                onChange={(e) => handleChange(e)}
                label="Employee Number"
                name="employeeNumber"
                disabled
              />
              {/* <Label className="form-label">Employee Number</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Employee Number"
                name="eName"
              /> */}
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="Eid"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Employee ID"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                value={state.employeeID}
              />
              {/* <Label className="form-label">Employee Id</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Employee Id"
                name="Eid"
                value={userInfo && userInfo.ID ? userInfo.ID : ""}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, ID: e.target.value });
                }}
              /> */}
            </Col>

            <Col md="4">
              {console.log("valuename 123", state.employeeType)}

              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "12px",
                    color: "black",
                  },
                }}
                label="Employee Type"

                value={state.employeeType}
                onChange={(e) => handleChange(e)}
                name="employeeType"
              >
                <option value={null}>Select Employee Type</option>
                {optionsMaster?.EmpType &&
                  optionsMaster?.EmpType?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </TextField>

            </Col>
          </Row>
          <Row className="g-3 mt-2">
            <Col md="4">
              <TextField
                margin="dense"
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="firstName"
                placeholder="First Name"
                inputProps={{
                  value: state.firstName,
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="First Name"
                // value={state.employeeNumber}
                // defaultValue={state.firstName}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col md="4">
              {/* <Label className="form-label">Middle Name</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Middle Name"
                value={
                  userInfo && userInfo.MiddleName ? userInfo.MiddleName : ""
                }
                onChange={(e) => {
                  setUserInfo({ ...userInfo, MiddleName: e.target.value });
                }}
              /> */}

              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                placeholder="Middle Name"
                size="small"
                name="middleName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Middle Name"
                value={state.middleName}
                onChange={(e) => handleChange(e)}
              />
            </Col>
            <Col md="4">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="lastName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Last Name"
                value={state.lastName}
                onChange={(e) => handleChange(e)}
              />
              {/* <Label className="form-label">Last Name</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Last Name"
                value={userInfo && userInfo.LastName ? userInfo.LastName : ""}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, LastName: e.target.value });
                }}
              /> */}
            </Col>
          </Row>
          <Row className="g-2 mt-2">


            <Col md={3}>
              <Label className="form-label">Gender</Label>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  console.log("valuename gender", value);
                  setState({ ...state, gender: value });
                }}
                value={state?.gender || ""}
              >
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel value="M" control={<Radio />} label="Male" />
                <FormControlLabel value="O" control={<Radio />} label="Other" />
              </RadioGroup>
            </Col>

            <Col md={3}>
              <Label className="form-label">Marital Status</Label>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(_, value) => {
                  setState({ ...state, martialStatus: value });
                }}
                value={state?.martialStatus || ""}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio />}
                  label="Single"
                />
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Married"
                />
              </RadioGroup>
            </Col>
            <Col md="3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB"
                  inputFormat="MM/DD/YYYY"
                  value={state?.dob ? dayjs(moment(state?.dob).format("YYYY-MM-DD")) : null}
                  // value={state?.dob ||null}
                  onChange={(e) => {
                    const formatDate = e.$d;
                    const value = moment(formatDate).format("YYYY-MM-DD");

                    setState({ ...state, dob: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full  mt-2"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Citizenship"
                name="citizenship"
                value={state.citizenship}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>Select Citizenship</option>
                {citizenshipOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

            </Col>
          </Row>
          <Row className="g-3 mt-1">
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                name="ethnicCode"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Ethic Code"
                value={state.ethnicCode}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>Select Ethnic Code</option>
                {ethnicCodeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

            </Col>

            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                name="departmentDivision"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Department Divison"
                value={state.departmentDivision}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>Select Department</option>
                {optionsMaster?.DPDV?.map((option) => (
                  <option key={option.DEPT_KEY} value={option.NAME}>
                    {option.label}
                  </option>
                ))}
              </TextField>

            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                name="jobOrderNumber"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Job Order Number"
                value={state.jobOrderNumber}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>Job Order Number</option>
                {jobOrderNumberOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

            </Col>
            <Col md="3">
              <TextField
                margin="dense"
                className="flex w-full mx-10"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={{ width: "100%" }}
                size="small"
                name="objectCode"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Object Code"
                value={state.objectCode}
                onChange={(e) => handleChange(e)}
              >
                <option value={null}>Select Object Code</option>
                {objectCodeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              {/* <Label className="col-form-label">Object Code</Label>
              <Input
                className="form-control form-control-primary btn-square"
                name="select"
                type="select"
                // value={userDetail.objectCode}

                onChange={(e) =>
                  changeDetails({
                    target: { name: "objectCode", value: e.target.value },
                  })
                }
              >
                {objectCodeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input> */}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <TextField
                rows={3}
                multiline
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                name="description"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                  },
                }}
                placeholder="Notes"
                label="Description"
                value={state.description}
                onChange={(e) => handleChange(e)}
              />
              {/* <Label className="form-label">Notes</Label> */}


            </Col>
          </Row>
        </Form>
        {openModal && (
          <div>
            <Modal
              open={openModal}
              onClose={handleModal}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0",
                  }}
                >
                  <h6 className="mt-2">
                    {employeeInfo?.NAME
                      ? employeeInfo.NAME + " 'S QR Code"
                      : ""}
                  </h6>
                  <div>
                    <IconButton onClick={handleModal}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <QRCodeModal employeeInfo={employeeInfo} />
              </Box>
            </Modal>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default EmployeeInfo;
