import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { firebase_app } from "../../../Config/Config";
import { LogOut } from "react-feather";
import { Link } from "react-router-dom";
// import {  useNavigate } from "react-router-dom";

import { useAuth } from "oidc-react";

const LogoutClass = () => {
  // const history = useNavigate();
  const auth = useAuth();
  const {  signOutRedirect } = useAuth();

  const Logout = () => {

    if(localStorage.getItem("timeClockStartHours")){
      alert("Timer is running. Please stop it")
      return false
   
  
  }else{  localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    firebase_app.auth().signOut();

    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.setItem("authenticated", false);
    // history(`${process.env.REACT_APP_PREFIX_URL}login`);

    // auth.signOut();
    signOutRedirect();
    window.location = `${process.env.REACT_APP_PREFIX_URL}logout`
  }
  };

  return (
    <Fragment>
      <LI attrLI={{
        className: "onhover-dropdown p-0",

        onClick: Logout
      }}>
        <Btn
          attrBtn={{
            as: Card.Header,
            className: "btn btn-primary-light",
            color: "default",
          }}
        >
          <Link to="#">
            <LogOut />
            Log out
          </Link>
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
