import React, { useContext, useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Card, Col, Row } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import CustomizerContext from "../../../_helper/Customizer";
import { Label } from "reactstrap";





function GeneralDetail(props) {
  const { mix_background_layout } = useContext(CustomizerContext);

  const [detail, setDetail] = useState({
    firstName: props.userDetail.firstName,
    middleName: props.userDetail.middleName,
    lastName: props.userDetail.lastName,
    fullName: props.userDetail.fullName,
    email: props.userDetail.email,
    maritalStatus: props.userDetail.maritalStatus,
    citizen: props.userDetail.citizen,
    education: props.userDetail.education,
    gender: props.userDetail.gender,
    disability: props.userDetail.disability,
    DOB: props.userDetail.DOB,
    veteran: props.userDetail.veteran,
    i9: props.userDetail.i9,
  });
  return (
    <>

      <Row style={{ width: "100%" }} className="g-3">
        <Col md={4}>
          <Label className="form-label">First Name</Label>
          <input
            className="form-control"
            type="text"
            placeholder="First Name"
            name="eName"
            value={detail.firstName}
          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Last Name</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Last Name"
            name="eName"
            value={detail.lastName}
          />

        </Col>
        <Col md={4}>
          <Label className="form-label">MI</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Middle Name"
            name="eName"
            value={detail.middleName}
          />
        </Col>
        <Col md={4}>
          <Label className="form-label">Email</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Email"
            name="eName"
            value={detail.email}
          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Citizen</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Citizen"
            name="eName"
            value={detail.citizen}
          />

        </Col>
        <Col md={4}>
          <Label className="form-label">Education</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Education"
            name="eName"
            value={detail.education}
          />

        </Col>
        <Col md={2}>
          <Label className="form-label">Gender</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Gender"
            name="eName"
            value={detail.gender}
          />


        </Col>
        <Col md={2}>
          <Label className="form-label">Marital Status</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Marital Status"
            name="eName"
            value={detail.maritalStatus}

          />


        </Col>
        <Col md={2}>
          <Label className="form-label">Disability</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Disability"
            name="eName"
            value={detail.disability}

          />

        </Col>
        <Col md={2}>
          <Label className="form-label">DOB</Label>
          <input
            className="form-control"
            type="text"
            placeholder="DOB"
            name="eName"
            value={detail.DOB}

          />

        </Col>
        <Col md={2}>
          <Label className="form-label">Veteran</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Veteran"
            name="eName"
            value={detail.veteran}

          />

        </Col>
        <Col md={2}>
          <Label className="form-label">I9</Label>
          <input
            className="form-control"
            type="text"
            placeholder="I9"
            name="eName"
            value={detail.i9}

          />

        </Col>
      </Row>

    </>
  );
}

export default GeneralDetail;
