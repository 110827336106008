import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import useKeyboardShortcut from "use-keyboard-shortcut";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import TableRows from "../payrollTableRow";
// import { Grid, Typography } from "@material-ui/core";
// import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";

// import "../../../../styles/muiTables.css";
import {
  Button,
  Hidden,
  Switch,
  Typography,
  Grid,
  FormControl,
  TextField,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";

import { Card, CardBody, CardHeader } from "reactstrap";

// import Button from "@material-ui/core/Button";
// import Hidden from "@material-ui/core/Hidden";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faFileExport,
  faTable,
  faCalendar,
  faCalendarCheck,
  faList,
  faEye,
  faCircleInfo,
  faClock,
  faQuestionCircle,
  faMinus,
  faPlus,
  faMinusCircle,
  faPlusCircle,
  faFileDownload,
  faUpload,
  faDownload,
  faInfo,
} from "@fortawesome/pro-duotone-svg-icons";
import axios from "axios";

import {
  addPad,
  adjustRoundedTime,
  checkAddedTimeErrorTimekeeper,
  checkEmpDepartment,
  checkEmpTag,
  d2Time,
  getHMFromTime,
  GetLeaveAccureByExperiance,
  getUserRole,
  getValidPaycodeOfDay,
  getValidPaycodeOfDayv2,
  HourMinFormat,
  HourMinLabelFormat,
  KeyGen,
  removeDuplicatesByKey,
  RemoveSpaceKeyGen,
  ShowError,
  ShowSucess,
  uniqueValue,
} from "../../../../app/utility/helper";

import { floor, includes } from "lodash";
import { Row, Col } from "react-bootstrap";
import LoadingPanel from "../../../Common/telerik/loadingPanel";
import ConfigDB from "../../../../Config/ThemeConfig";

import ReactTimeSchedular, {
  diifrenceInMinutes,
} from "../../../ReactTimeSheet/ReactTimeSchedular";
import ViewTimeSheetTemplates from "../../../ConfigurationMaster/TimeSheetTemplate/ViewTimeSheetTemplates";
import { useAuth } from "oidc-react";
import PayrollSimpleView from "../payrollSimpleView";
import PayrollSimpleViewV2 from "../payrollSimpleViewV2";
import { TableView } from "@mui/icons-material";
import TimeSheetModal from "../TimeSheetModal";

import { payCodeFilter } from "../../../../app/utility/helper";
import PayrollAuthModalData from "../PayrollAuthModalData";
import {
  fetchLeaveHours,
  HolidaysData,
} from "../../../../app/store/reducers/slices/configurationSlice";
import { useDispatch, useSelector } from "react-redux";
import TimeClock from "../TimeClock";
import TableRowsVerification from "./payrollTableRowVerification";
import TableRowsAllVerifications from "./TableRowsAllVerifications";
import { GetEmployeeAuthorizedPayrollData } from "../../../OverTime/OverTimeSlice";
import ConfirmModal from "../../../../app/utility/ConfirmModal";
import { getMulti } from "../../../../_helper/helper";
import { toast } from "react-toastify";
import TableRowsVerificationUpload from "./payrollTableRowVerificationUpload";
import CopyTemplateModal from "./CopyTemplateModal";

const getStatusColorCode = (ConfirmStatus) => {
  if (ConfirmStatus === undefined) {
    return "#000";
  }
  ConfirmStatus = ConfirmStatus.toLowerCase();
  if (ConfirmStatus == "confirm") {
    return "#4A8738";
  } else if (ConfirmStatus == "auth") {
    return "#32CD32";
  } else if (ConfirmStatus == "pending") {
    return "#ED2938";
  } else {
    return "#000";
  }
};
let templateOption = [
  {
    label: "Use Previous Pay Period",
    value: "PREVPPE",
  },
  {
    label: "Use Previous Pay Week",
    value: "PREVWEAKPPE",
  },
  {
    label: "Use Template Profile",
    value: "PROFILETEMPLATE",
  },
  {
    label: "Custom Template for User",
    value: "CUSTOMTEMPLATE",
  },
];

let templateOptionData1 = [
  {
    label: "Use Previous Pay Period",
    value: "PREVPPE",
  },
  // {
  //   label: "Use Previous Pay Week",
  //   value: "PREVWEAKPPE",
  // },

  // {
  //   label: "Custom Template for User",
  //   value: "CUSTOMTEMPLATE",
  // },
];

const amountArray = [
  {
    startDate: "2020-06-01",
    endDate: "2020-06-05",
    rate: 25.7,
  },
  {
    startDate: "2020-06-06",
    endDate: "2020-06-08",
    rate: 35,
  },
  {
    startDate: "2020-06-10",
    endDate: "2020-06-15",
    rate: 50,
  },
];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const selectedCss = {
  padding: "5px",
  border: "1px solid grey",
  borderRadius: "6px",
  marginRight: "10px",
};
const notSelectedCss = { marginRight: "10px" };

const PayrollContentVerification = ({
  setWeekData,
  weekData,
  setHourData,
  hourData,
  paytimeCode,
  payPeriod,
  setIsLoading,
  isloading,
  paytimeCodeSuperVisor,
  EmployeeProcessPayTimes,
  userId,
  allPayPeriod,
  paytimeCodeByID,
  userInfo,
  price1,
  rowsData,
  setRowsData,
  sampleData,
  setEmployeeVerifyFlag,
  employeeAuthorizedPayTimeCodesList1,
  getEmpTag,
  setOTHours,
  setOTMinutes,
  OTHours,
  OTMinutes,
  OTType,
  setOTType,
  assigneeEmployeeList,
  reAssignEmployee,
  
}) => {
  const childRef = useRef();
  const handleClick = () => {};
  const { state } = useLocation();
  const [price, setPrice] = useState(0);

  const [helprCode, setHelprCode] = useState(null);
  const [rulesApplied, setRulesApplied] = useState(false);
  const [rowsDataUpload, setRowsDataUpload] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [
    employeeAuthorizedPayTimeCodesList,
    setEmployeeAuthorizedPayTimeCodesList,
  ] = useState([]);

  // useEffect(() => {
  //   if (getEmpTag.length) {
  //     let checkTag = checkEmpTag(getEmpTag, "POLICEOFFICER");

  //     if (checkTag?.status) {
  //       setOTHours(43);
  //       setOTMinutes(2580);
  //       loadPreData();
  //     }
  //   }
  // }, [getEmpTag, userId]);

  useEffect(() => {
    if (employeeAuthorizedPayTimeCodesList1.length) {
      setEmployeeAuthorizedPayTimeCodesList(
        employeeAuthorizedPayTimeCodesList1
      );
    }
  }, [employeeAuthorizedPayTimeCodesList1.length]);

  useEffect(() => {
    if (price1 > 0) {
      setPrice(price1);
    }
  }, [price1 > 0]);
  const startDate = payPeriod?.StartDate
    ? moment(payPeriod?.StartDate).format("MM-DD-YYYY")
    : "";
  const endDate = payPeriod?.EndDate
    ? moment(payPeriod?.EndDate).format("MM-DD-YYYY")
    : "";
  const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      dates.push(moment(new Date(date)).format("YYYY-MM-DD"));
      date.setDate(date.getDate() + 1);
    }
    const lastWeek = dates.slice(7);
    const firstWeek = dates.slice(0, 7);
    return { firstWeek, lastWeek };
  };
  const getPPEDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      dates.push({
        date: moment(new Date(date)).format("YYYY-MM-DD"),
        dayMin: 0,
        hasOT: false,
      });
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  const weekobj = getDatesInRange(d1, d2);
  const ppeDateobj = getPPEDatesInRange(d1, d2);

  const getRateForDate = (amountArray, date, price) => {
    const formattedDate = new Date(date);

    for (let i = 0; i < amountArray.length; i++) {
      const startDate = new Date(amountArray[i].startDate);
      const endDate = new Date(amountArray[i].endDate);

      if (formattedDate >= startDate && formattedDate <= endDate) {
        return amountArray[i].rate;
      }
    }
    return price;
  };

  const selectedCss = {
    padding: "7px 6px 10px",
    border: "1px solid grey",
    borderRadius: "6px",
    marginRight: "10px",
  };
  const notSelectedCss = { marginRight: "10px" };

  const getPPEColorCode = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    if (acode.toLowerCase() == "auth") {
      return "#e07c40";
    }
    let getcode = paytimeCode.find((i) => {
      return i.label == acode;
    });

    return getcode && getcode.color ? getcode.color : "#000";
  };

  const [originalAuthData, setOriginalAuthData] = useState([]); // set as rowdata values for authdata
  const [rowAuthData, setRowAuthData] = useState([]); // set as rowdata values for authdata
  const [templateData, setTemplateData] = useState([]); // over all show time by dayname
  const [authModal, setAuthModal] = useState(false);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const { tagCodeList } = useSelector((state) => state.TableSlice);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  // const [rowsData, setRowsData] = useState([sampleData]);
  const [saveData, setSaveData] = useState([]);
  const [templateGenData, setTemplateGenData] = useState([]);

  const [originalData, setOriginalData] = useState([]);
  // const [preDayHours, setPreDayHours] = useState([]);

  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const [timeTo, setTimeTo] = useState();
  const [timeToUpload, setTimeToUpload] = useState();
  const [valueChange, setValueChange] = useState({});
  const [index, setIndex] = useState(0);
  const [indexUpload, setIndexUpload] = useState(0);
  const [check, setChecked] = useState(false);
  const [pay, setPay] = useState(rowsData);
  const [deleted, setDeleted] = useState(false);
  const [prevPPE, setPrevPPE] = useState([]);
  const [profileType, setProfileType] = useState({ label: "", value: "" });
  const [adminTemplate, setAdminTemplate] = useState("");
  const [userTemplate, setUserTemplate] = useState("");
  const [templateID, setTemplateID] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateTitleCurrent, setTemplateTitleCurrent] = useState("");
  const [deletedItemIds, setDeletedItemIds] = useState([]);
  const [deletedItemIdsUpload, setDeletedItemIdsUpload] = useState([]);
  const [allAccountNumber, setAllAccontNumber] = useState([]);
  const [unqiueDate, setUnqiueDate] = useState([]);
  const [updateCorrespoding, setUpdateCorrespoding] = useState(true);
  const [templateOption1, setTemplateOption1] = useState(templateOptionData1);

  const [weekOT, setWeekOT] = useState({
    week1: 0,
    week2: 0,
  });
  const [assigneeAccountNumber, setAssigneeAccountNumber] = useState([]);

  const { userData } = useAuth();

  const [isTableView, setIsTableView] = useState("upload"); //table , simplev1
  const [view, setView] = useState([]);
  const dispatch = useDispatch();
  const leaveHours = useSelector(
    (state) => state.configurationSlice.leaveHours
  );
  const holidaysData = useSelector(
    (state) => state.configurationSlice.holidayData
  );

  const [isAllVerifyModal, setIsAllVerifyModal] = useState(false);
  const [isDeleteRowModal, setIsDeleteRowModal] = useState(false);
  const [isDeleteRowModalUpload, setIsDeleteRowModalUpload] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(0);
  const [deleteRowIndexUpload, setDeleteRowIndexUpload] = useState(0);

  const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    GetAllAcountNumber(userId);
  }, [userId]);

  useEffect(() => {
    if (payPeriod?.StartDate) {
      dispatch(HolidaysData(moment(payPeriod?.StartDate).format("YYYY")));
    }
  }, [payPeriod?.StartDate]);

  const GetAllAcountNumber = (data) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}apporpriationAccounts?AccountID=${data}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((res) => {
        let data = res?.data?.map((item) => {
          return {
            label: item.ID,
            value: item.JobOrderNo,
          };
        });
        setAllAccontNumber(data);
      })
      .catch((err) => {
        console.log("account number", err);
      });
  };

  const handleDateColor = (data) => {
    const uniqueArr = Array.from(new Set(data.map((obj) => obj.date))).map(
      (id) => {
        return data.find((obj) => obj.date === id);
      }
    );

    let uniqueDates = uniqueArr.map((k, ind) => {
      if (ind % 2 == 0) {
        return { ...k, bgcolor: "#7173a429" };
      } else {
        return { ...k, bgcolor: "#e3e3e36b" };
      }
    });

    setUnqiueDate(uniqueDates);
  };

  console.log("CAScsacdsccscdscdswcc", rowsDataUpload);

  const getView = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}timesheet/accessType/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        const result = response.data.result;
        let resultArray = [];
        let order = ["table", "sheet", "simplev1", "simplev2", "timeClock"];
        // result.map((i,) => {
        //   return resultArray.push(i.code);
        // });

        order.map((s) => {
          if (result.find((i) => i.code == s))
            resultArray.push(result.find((i) => i.code == s).code);
        });

        //  const finalSortArray =  resultArray.sort((a,b) => order.indexOf(a) < order.indexOf(b))

        setView(resultArray);
        if (resultArray.length) {
          // setIsTableView(resultArray[0]);
          setIsTableView('upload');
        }
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };

  const getDateColor = (date, rowsData) => {
    return unqiueDate.find((i) => {
      return i.date == date;
    })?.bgcolor;
  };

  const handleApproveStatus = (index, value) => {
    const rowsInput = [...rowsData];
    let newObj = rowsInput[index];

    newObj["approvedStatus"] = value;
    setRowsData(rowsInput);
  };

  const handleApproveStatusUpload = (index, value) => {
    const rowsInput = [...rowsDataUpload];
    let newObj = rowsInput[index];

    newObj["approvedStatus"] = value;
    setRowsDataUpload(rowsInput);
  };
  const getTimeSheetTemplate = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}advancesearch/gettimesheetForEmployee`,
        {
          EmployeeID: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        if (response.data.result.length) {
          let templateData = response.data.result;
          let adminTemplateRes = templateData.filter((i) => {
            return i.EmployeeID == null;
          });

          setAdminTemplate(adminTemplateRes);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };
  const filterCustomTemplate = (userTemps, payPeriod) => {
    console.log("asdfasdadasdad", userTemps, payPeriod);

    let filteredArray = [];
    filteredArray = userTemps.filter((i) => {
      return (
        (!i.EffectiveStartDate && !i.EffectiveEndDate) ||
        (i.EffectiveStartDate >= payPeriod.StartDate &&
          i.EffectiveStartDate <= payPeriod.EndDate) ||
        (i.EffectiveEndDate >= payPeriod.StartDate &&
          i.EffectiveEndDate <= payPeriod.EndDate)
      );
    });

    setUserTemplate(filteredArray);

    let finalDataOption = filteredArray.map((i) => {
      return {
        label:
          i.Auth == true
            ? `${i.EmployeeTemplateName} (Authorized) `
            : i.EmployeeTemplateName,
        value: i.TemplateID,
        isTemplate: true,
      };
    });

    console.log("dasdasdsadad  1", templateOption1);

    const temp = templateOption1?.filter((i) => !i.isTemplate);
    console.log("dasdasdsadad  2", temp);
    finalDataOption = temp.concat(finalDataOption);
    setTemplateOption1(finalDataOption);
  };
  const getTimeSheetTemplateByID = (userId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employeeAssign/worktemplate/get`,
        {
          EmployeeID: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        if (response.data.result.length) {
          let templateData = response.data.result;

          let userTemplateRes = templateData.filter((i) => {
            return i.IsActive === true && i.EmployeeID == userId;
          });
          if (payPeriod?.StartDate) {
            console.log("asdadjadakdaj", userTemplateRes);
            setTemplateOption1(templateOptionData1);
            filterCustomTemplate(userTemplateRes, payPeriod);
          }
          // setUserTemplate(userTemplateRes);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };

  const statusSubmit = (id, status) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/approve/${id}`,
        {
          Approve: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        ShowSucess("Status Change Successfully");
      })
      .catch((error) => {
        ShowError(error);
        console.warn("Cannot retrieve user data", error);
      });
  };

  useEffect(() => {
    if (userId) {
      getTimeSheetTemplate();
      getView(userId);
      dispatch(fetchLeaveHours());
    }
  }, [userId]);

  useEffect(() => {
    if (userId && payPeriod?.StartDate) {
      getTimeSheetTemplateByID(userId);
    }
  }, [userId, payPeriod?.StartDate]);
  useEffect(() => {
    // if (payPeriod) {
    //   setTimeout(() => {
    //   getTimeSheetTemplateByID();
    //   // if (templateID) {
    //   //   // renderProfileData(); // update for change te
    //   //   // handlePrefillTimesSlot(templateData);
    //   // }
    //   if (templateID && templateTitle) {
    //     renderProfileData();
    //   }
    // }, 1000);
    // }
    loadPreData();
  }, [payPeriod]);

  const getDataforUserTemplate = async (templateId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}timesheet/get/${
            templateId ? templateId : 0
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => reject([]));
    });
  };
  const renderProfileData = async () => {
    console.log("profileType 111 :->", profileType);

    setIsLoading(true);
    if (["PROFILETEMPLATE", "CUSTOMTEMPLATE"].includes(profileType?.value)) {
      if (!profileType?.value) {
        ShowError("Select Profile Type");
        return false;
      }
      if (!templateID) {
        ShowError("Select Template Type");
        return false;
      }

      let TimeSlot = {};
      if (profileType?.value == "CUSTOMTEMPLATE") {
        await getDataforUserTemplate(templateID)
          .then((res) => {
            console.log("return inf res", res[0]);
            TimeSlot = res[0];
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("return inf err", err);
            setIsLoading(false);
          });
      } else {
        TimeSlot = adminTemplate.find((k) => {
          return k.ID == templateID;
        });
      }
      console.log("return inf err", TimeSlot);

      if (Object.keys(TimeSlot).length > 0) {
        console.log("Innn Timeslot return inf err", TimeSlot);
        handlePrefillTimesSlot(TimeSlot.TimeSheetProfiles);

        setTemplateData(TimeSlot.TimeSheetProfiles); //days data save in state
        setTemplateTitleCurrent(templateTitle); // template title for display only
      }
    } else if (profileType?.value == "asdsa") {
      // let TimeSlot = userTemplate.find((k) => {
      //   return k.ID == templateID;
      // });
      // if (TimeSlot) {
      //   handlePrefillTimesSlot(TimeSlot.TimeSheetProfiles);
      // }
      setIsLoading(false);
    } else if (profileType?.value == "PREVPPE") {
      handlePreviousPPE();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  let mintByweek = { firstWeek: 0, lastWeek: 0 };
  let mintOfDay = 0;
  let isFlageByWeek = { firstWeek: true, lastWeek: true };
  let spliteRows = [];

  const splitDayBYRowsDataOT = (WeekDateArray, row, otPaycode, weekType) => {
    const { employeeAuthorizedPayTypeID, abberv, code } = otPaycode;
    console.log("ffasasfasfafaf", otPaycode);
    WeekDateArray.map((item, indexZ) => {
      if (item.date === row?.date) {
        WeekDateArray[indexZ][weekType] =
          WeekDateArray[indexZ][weekType] + row?.mn;
        const weekHoure = WeekDateArray[indexZ][weekType] / 60;
        if (weekHoure > OTHours) {
          console.log("OTT DATA DATE", item.date, "--", weekHoure);

          let otRowMn = WeekDateArray[indexZ][weekType] - OTMinutes;
          let regRowMn = row.mn - otRowMn;

          if (
            !WeekDateArray[indexZ]["hasOT"] &&
            regRowMn &&
            row.jobtype !== abberv
          ) {
            console.log(
              "OTT DATA indexZ",
              indexZ,
              WeekDateArray,
              "--",
              otRowMn,
              "=",
              regRowMn
            );
            let regRow = JSON.parse(JSON.stringify(row));
            const regRowHoure = moment(regRow.hrs, "HH:mm")
              .subtract(otRowMn, "minutes")
              .format("HH:mm");
            const regRowTime_to = moment(regRow.time_to, "HH:mm A")
              .subtract(otRowMn, "minutes")
              .format("HH:mm");
            if (regRowMn) {
              let payInfo = getCalculatedAmtMin(regRowMn, "REG", row?.Rate);
              regRow.mn = regRowMn;
              regRow.hrs = regRowHoure;
              regRow.jobtype = "REG";
              regRow.code = "1";
              regRow.time_to = regRowTime_to;
              regRow.GrossAmount = payInfo;

              spliteRows.push(regRow);
            }
            let otRow = row;
            const otRowHoure = moment(otRow.hrs, "HH:mm")
              .subtract(regRowMn, "minutes")
              .format("HH:mm");
            const otRowTime_to = moment(regRowTime_to, "HH:mm A")
              .add(otRowMn, "minutes")
              .format("HH:mm");
            let payInfo = getCalculatedAmtMin(otRowMn, abberv, row?.Rate);
            otRow.jobtype = abberv;
            otRow.mn = otRowMn;
            otRow.GrossAmount = payInfo;
            otRow.hrs = otRowHoure;
            otRow.ID = 0;
            otRow.EmployeeAuthorizedPayTypeId = employeeAuthorizedPayTypeID;
            otRow.time_from = regRowTime_to;
            otRow.time_to = otRowTime_to;
            otRow.code = code;
            spliteRows.push(otRow);
            console.log("OT DATA 1", otRow);
            WeekDateArray[indexZ]["hasOT"] = true;
            // isFlageByWeek['firstWeek'] = false;
          } else {
            row.jobtype = abberv;
            row.code = code;
            row.EmployeeAuthorizedPayTypeId = employeeAuthorizedPayTypeID;
            console.log("OT DATA 2", row);

            spliteRows.push(row);
          }
        } else {
          row.jobtype = "REG";
          row.code = "1";
          console.log("OT DATA 3", row);

          spliteRows.push(row);
        }
      }
    });
  };
  const splitWeekBYRowsData = (WeekDateArray, row, otPaycode, weekType) => {
    const { employeeAuthorizedPayTypeID, abberv, code } = otPaycode;
    console.log("ffasasfasfafaf", otPaycode, "##", WeekDateArray);
    WeekDateArray.map((item) => {
      if (item === row?.date) {
        mintByweek[weekType] += row?.mn;
        const weekHoure = mintByweek[weekType] / 60;
        if (weekHoure > OTHours) {
          let otRowMn = mintByweek[weekType] - OTMinutes;
          let regRowMn = row.mn - otRowMn;
          if (isFlageByWeek[weekType] && regRowMn && row.jobtype !== abberv) {
            let regRow = JSON.parse(JSON.stringify(row));
            const regRowHoure = moment(regRow.hrs, "HH:mm")
              .subtract(otRowMn, "minutes")
              .format("HH:mm");
            const regRowTime_to = moment(regRow.time_to, "HH:mm A")
              .subtract(otRowMn, "minutes")
              .format("HH:mm");
            if (regRowMn) {
              let payInfo = getCalculatedAmtMin(regRowMn, "REG", row?.Rate);
              regRow.mn = regRowMn;
              regRow.hrs = regRowHoure;
              regRow.jobtype = "REG";
              regRow.code = "1";
              regRow.time_to = regRowTime_to;
              regRow.GrossAmount = payInfo;

              spliteRows.push(regRow);
            }
            let otRow = row;
            const otRowHoure = moment(otRow.hrs, "HH:mm")
              .subtract(regRowMn, "minutes")
              .format("HH:mm");
            const otRowTime_to = moment(regRowTime_to, "HH:mm A")
              .add(otRowMn, "minutes")
              .format("HH:mm");
            let payInfo = getCalculatedAmtMin(otRowMn, abberv, row?.Rate);
            otRow.jobtype = abberv;
            otRow.mn = otRowMn;
            otRow.GrossAmount = payInfo;
            otRow.hrs = otRowHoure;
            otRow.ID = 0;
            otRow.EmployeeAuthorizedPayTypeId = employeeAuthorizedPayTypeID;
            otRow.time_from = regRowTime_to;
            otRow.time_to = otRowTime_to;
            otRow.code = code;
            spliteRows.push(otRow);
            isFlageByWeek[weekType] = false;
          } else {
            row.jobtype = abberv;
            row.code = code;
            row.EmployeeAuthorizedPayTypeId = employeeAuthorizedPayTypeID;
            spliteRows.push(row);
          }
        } else {
          row.jobtype = "REG";
          row.code = "1";
          spliteRows.push(row);
        }
      }
    });
  };

  const splitOTWeek = (weekData) => {
    mintByweek = { firstWeek: 0, lastWeek: 0 };
    isFlageByWeek = { firstWeek: true, lastWeek: true };
    spliteRows = [];
    let otPaycode = employeeAuthorizedPayTimeCodesList?.find((item) =>
      item?.abberv?.includes("O/T")
    );
    if (!otPaycode) {
      otPaycode = {
        employeeAuthorizedPayTypeID: null,
        abberv: null,
        code: null,
      };
    } else {
      otPaycode = employeeAuthorizedPayTimeCodesList?.find((item) =>
        item?.abberv?.includes("O/T")
      );
    }

    if (weekData?.week1 || weekData?.week2) {
    }

    // mergedArray.map((i) => {
    //   if (i?.jobtype?.trim() === "REG" || i?.jobtype === "") {
    //     splitWeekBYRowsData(weekobj?.firstWeek, i, otPaycode, "firstWeek");
    //     splitWeekBYRowsData(weekobj?.lastWeek, i, otPaycode, "lastWeek");
    //   } else {
    //     spliteRows.push(i);
    //   }
    // });
    // spliteRows.sort(function (a, b) {
    //   return b.date - a.date;
    // });
    // return spliteRows;
  };
  const splitOt = (mergedArray) => {
    mintByweek = { firstWeek: 0, lastWeek: 0 };
    isFlageByWeek = { firstWeek: true, lastWeek: true };
    spliteRows = [];
    let tempConcat = [{
            "id": 0,
            "employeeID": 10002808,
            "code": "6",
            "description": "OVERTIME 1.5                       ",
            "abberv": "O/T1.5",
            "effectDate": "2021-08-11T00:00:00",
            "expireDate": "2026-08-11T00:00:00",
            "multi": 1.50,
            "payTimeCodeID": 4,
            "employeeAuthorizedPayTypeID": "189",
            "accountID": 3,
            "jobOrderNo": "132222112",
            "totalAuthorizedHours": 0.00,
            "totalReservedHours": 5.00,
            "totalUsedHours": null,
            "category": "OVERTIME"
        }]
    let otPaycode = tempConcat.concat(employeeAuthorizedPayTimeCodesList)?.find((item) =>
      item?.abberv?.includes("O/T")
    );
    if (!otPaycode) {
      otPaycode = {
        employeeAuthorizedPayTypeID: null,
        abberv: null,
        code: null,
      };
    } else {
      otPaycode = tempConcat.concat(employeeAuthorizedPayTimeCodesList)?.find((item) =>
        item?.abberv?.includes("O/T")
      );
    }
    console.log("adsjasklsas 1111", mergedArray, employeeAuthorizedPayTimeCodesList);
    mergedArray.map((i) => {
      if (i?.jobtype?.trim() === "REG" || i?.jobtype === "") {
        console.log("safasfafasf 1", OTType);
        if (OTType == "PPEPW43" || OTType == "PPEPW40") {
          console.log("safasfafasf 2", OTType,otPaycode,'MRBeast',employeeAuthorizedPayTimeCodesList);

          splitWeekBYRowsData(weekobj?.firstWeek, i, otPaycode, "firstWeek");
          splitWeekBYRowsData(weekobj?.lastWeek, i, otPaycode, "lastWeek");
        } else if (OTType == "PPE2W106") {
          splitWeekBYRowsData(
            (weekobj?.firstWeek).concat(weekobj?.lastWeek),
            i,
            otPaycode,
            "firstWeek"
          );
        } else if (OTType == "PD") {
          splitDayBYRowsDataOT(ppeDateobj, i, otPaycode, "dayMin");
        }
      } else {
        spliteRows.push(i);
      }
    });
    spliteRows.sort(function (a, b) {
      return b.date - a.date;
    });
    return spliteRows;
  };
  useKeyboardShortcut(
    ["Shift", "A"],
    (shortcutKeys) => {
      addTableRows();
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );
  useKeyboardShortcut(
    ["Shift", "S"],
    (shortcutKeys) => {
      handleSaveRows();
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  const getCalculatedAmtMin = (totalMins, type, price) => {
    let rate = price;
    // ----- for amount calculation
    if (totalMins > 4800) {
      totalMins = 4800;
    }
    //-----
    let codeItem =
      paytimeCode &&
      paytimeCode.find((item) => {
        return item.value === type;
      });

    let multi = codeItem ? (codeItem.multi ? codeItem.multi : 1) : 1;

    let hr = `${Math.floor(totalMins / 60)}`;
    let min = `${totalMins % 60}`;

    let hRate = (rate * hr * multi).toFixed(2);
    let mRate = (rate * (min / 60) * multi).toFixed(2);

    return (parseFloat(hRate) + parseFloat(mRate)).toFixed(2);
  };

  const addTableRows = () => {
    let contactArray = [{ ...sampleData }].concat([...rowsData]);

    setRowsData(contactArray);

    // setRowsData([...rowsData],{...sampleData});
  };

  const addTableRowsUpload = () => {
    let contactArray = [{ ...sampleData }].concat([...rowsDataUpload]);

    setRowsDataUpload(contactArray);

    // setRowsData([...rowsData],{...sampleData});
  };

  useEffect(() => {
    if (rowsData.length && payPeriod.StartDate && userId) {
      console.log("askaldksdadkas", userId);
      let weekOverTime = OverTimeCalculation(
        payPeriod.StartDate,
        payPeriod.EndDate,
        rowsData
      );
      setWeekOT(weekOverTime);
      splitOTWeek(weekOverTime);
    } else {
      setWeekOT([]);
      splitOTWeek([]);
    }
  }, [rowsData, payPeriod, userId]);

  const OverTimeCalculation = (startDate, EndDate, data) => {
    let result = {};
    const week1StartDate = startDate;
    const week1EndDate = moment(startDate, "YYYY-MM-DD")
      .add(6, "d")
      .format("YYYY-MM-DD");
    const week2StartDate = moment(startDate, "YYYY-MM-DD")
      .add(7, "d")
      .format("YYYY-MM-DD");
    const week2EndDate = EndDate;

    if (data.length) {
      let totalMinWeek1 = data
        .filter((i) => {
          return (
            i.date >= week1StartDate &&
            i.date <= week1EndDate &&
            ["REG", "O/T1.5"].includes(i.jobtype)
          );
        })
        .reduce((accumulator, object) => {
          return accumulator + (object["mn"] + object["mn1"]);
        }, 0);

      let totalMinWeek2 = data
        .filter((i) => {
          return (
            i.date >= week2StartDate &&
            i.date <= week2EndDate &&
            ["REG", "O/T1.5"].includes(i.jobtype)
          );
        })
        .reduce((accumulator, object) => {
          return accumulator + (object["mn"] + object["mn1"]);
        }, 0);

      let OT1 = 0;
      let OT2 = 0;

      if (OTType == "PPEPW40" || OTType == "PPEPW43") {
        if (totalMinWeek1 > OTMinutes) {
          OT1 = totalMinWeek1 - OTMinutes;
        }
        if (totalMinWeek2 > OTMinutes) {
          OT2 = totalMinWeek2 - OTMinutes;
        }
      } else {
      }

      result = {
        week1: OT1 ? HourMinLabelFormat(OT1, true) : null,
        week2: OT2 ? HourMinLabelFormat(OT2, true) : null,
      };
    }
    return result;
  };
  const addTableRowsIndex = (index) => {
    let contactArray = [...rowsData];
    let newData = { ...sampleData };
    newData.date = moment(new Date(contactArray[index].date)); //.add(1, "day");
    contactArray.splice(index + 1, 0, newData);
    setRowsData(contactArray);
  };

  const addTableRowsIndexUpload = (index) => {
    let contactArray = [...rowsDataUpload];
    let newData = { ...sampleData };
    newData.date = moment(new Date(contactArray[index].date)); //.add(1, "day");
    contactArray.splice(index + 1, 0, newData);
    setRowsDataUpload(contactArray);
  };

  const addTableRowForNextDay = (index) => {
    let dataRow = rowsData[index];
    let newData = { ...dataRow };
    newData.date = moment(newData.date).add(1, "day");
    newData.time_from = "00:00";

    let contactArray = [...rowsData].concat([{ ...newData }]);
    setRowsData(contactArray);
  };

  const deleteTableRows = (index) => {
    setDeleteRowIndex(0);
    setIsDeleteRowModal(false);
    const rows = [...rowsData];

    let rowId = rows[index]["id"];
    let rowIds = rows[index]["ID"];
    let rowIds2 = rows[index]["ID2"];

    // return;
    let matchingRecord = rows.filter((row) => {
      return row.parentIndex === rowId;
    });
    let list = [...deletedItemIds];
    matchingRecord.map((i) => {
      if (i.ID) {
        list.push(parseInt(i.ID));
      }
      if (i.ID2) {
        list.push(parseInt(i.ID2));
      }
      let childIndex = rows.findIndex((row) => row.parentIndex === rowId);
      rows.splice(childIndex, 1);
    });

    rows.splice(index, 1);
    setRowsData(rows);
    // list.push(141002);
    list.push(parseInt(rowIds));
    list.push(parseInt(rowIds2));
    setDeletedItemIds(list);
  };

  const deleteTableRowsUpload = (index) => {
    setDeleteRowIndexUpload(0);
    setIsDeleteRowModalUpload(false);
    const rows = [...rowsDataUpload];

    let rowId = rows[index]["id"];
    let rowIds = rows[index]["ID"];
    let rowIds2 = rows[index]["ID2"];

    // return;
    let matchingRecord = rows.filter((row) => {
      return row.parentIndex === rowId;
    });
    let list = [...deletedItemIdsUpload];
    matchingRecord.map((i) => {
      if (i.ID) {
        list.push(parseInt(i.ID));
      }
      if (i.ID2) {
        list.push(parseInt(i.ID2));
      }
      let childIndex = rows.findIndex((row) => row.parentIndex === rowId);
      rows.splice(childIndex, 1);
    });

    rows.splice(index, 1);
    setRowsDataUpload(rows);
    // list.push(141002);
    list.push(parseInt(rowIds));
    list.push(parseInt(rowIds2));
    setDeletedItemIdsUpload(list);
  };

  const confirmTableRows = (index, evnt) => {
    let code = "";
    let rowsInput = [...rowsData];
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      rowsInput[index][name] = value;
      rowsInput[index]["rowstatus"] = value;

      setRowsData(rowsInput);
    }
  };
  const confirmTableRowsUpload = (index, evnt) => {
    let code = "";
    let rowsInput = [...rowsDataUpload];
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      rowsInput[index][name] = value;
      rowsInput[index]["rowstatus"] = value;

      setRowsDataUpload(rowsInput);
    }
  };
  const updateHzRowOLD = (rowsInput, prevData) => {
    console.log(index, "qwe", prevData, "$$$$", rowsInput);
    if (prevData === undefined) {
      return rowsInput;
    }

    //update hazard row value
    if (
      rowsInput[index].hjobtype !== undefined &&
      rowsInput[index].hjobtype !== ""
    ) {
      let rowId = rowsInput[index]["id"] ? rowsInput[index]["id"] : "0";
      if (rowId == "0") {
        return false;
      }
      // let rowsInput = [ ...rowsInput ];

      let paytime = paytimeCode.filter((item) => {
        return item.code >= 10 && item.code <= 12;
      });
      let arrayHtype = paytime.map((i) => {
        return i.value;
      });
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      let OtherforMasterIndex = rowsInput.filter((i) => {
        return i.parentIndex == rowId;
      });
      OtherforMasterIndex =
        parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
      if (existAtIndex !== -1) {
        //old index find
        console.log("#qwe LK UPDATE");

        // console.log('#LK UPDATE prevData', prevData);
        let newData = { ...rowsInput[index] };
        let newobj = { ...rowsInput[existAtIndex] };

        if (
          prevData["date"] == newobj["date"] &&
          prevData["time_from"] == newobj["time_from"] &&
          prevData["time_to"] == newobj["time_to"]
        ) {
          newobj = newData;
          newobj["id"] = newobj["id"] ? newobj["id"] : KeyGen(7);
          if (!newData["hjobtype"]) {
            newobj["code"] = getpayCode(newobj["jobtype"]);
            newobj["jobtype"] = newobj["jobtype"];
          } else {
            newobj["code"] = getpayCode(newobj["hjobtype"]);
            newobj["jobtype"] = newobj["hjobtype"];
          }
          newobj["time_from"] = newobj["time_from"];
          newobj["time_to"] = newobj["time_to"];
          newobj["ole1"] = false;
          newobj["ole2"] = false;
          newobj["hjobtype"] = "";
          newobj["parentIndex"] = rowId;

          console.log("#qwe LK UPDATE newobj", newobj);
          console.log(existAtIndex, "#LK newobj", newobj);
          rowsInput[existAtIndex] = newobj;

          console.log("#LK newobj newval", rowsInput);
          // return rowsInput;
          setRowsData([...rowsInput], rowsInput);
        }
      }
    }
    return rowsInput;
    //end hazard
  };

  const updateHzRow = (rowsInput, prevData, newData) => {
    console.log(index, "qwe", prevData, "$$$$", rowsInput);
    if (prevData === undefined) {
      return rowsInput;
    }

    //update hazard row value
    if (
      rowsInput[index]?.hjobtype !== undefined &&
      rowsInput[index].hjobtype !== ""
    ) {
      let rowId = rowsInput[index]["id"] ? rowsInput[index]["id"] : "0";
      if (rowId == "0") {
        return rowsInput;
      }
      // let rowsInput = [ ...rowsInput ];

      console.log("#qwe rowsid", rowId);

      let paytime = paytimeCode.filter((item) => {
        return item.code >= 10 && item.code <= 12;
      });
      let arrayHtype = paytime.map((i) => {
        return i.value;
      });
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      let OtherforMasterIndex = rowsInput.filter((i) => {
        return i.parentIndex == rowId;
      });
      OtherforMasterIndex =
        parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
      if (existAtIndex !== -1) {
        //old index find
        console.log("#qwe LK UPDATE");

        // let newData = { ...rowsInput[index] };
        let newobj = { ...rowsInput[existAtIndex] };

        console.log("#LK UPDATE prevData", prevData);
        if (
          prevData["date"] == newobj["date"] &&
          prevData["time_from"] == newobj["time_from"] &&
          prevData["time_to"] == newobj["time_to"]
        ) {
          newobj["time_from"] = newData["time_from"];
          newobj["time_to"] = newData["time_to"];

          let t1 = hoursDiffrence(newData["time_from"], newData["time_to"]);
          let t2 = hoursDiffrence(newData["time_from1"], newData["time_to1"]);

          let hrCalc = HourMinFormat(
            `${Math.floor((t1.totalMin + t2.totalMin) / 60)}:${
              (t1.totalMin + t2.totalMin) % 60
            }`
          );
          console.log("asfaf", price);
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = newobj.mn + newobj.mn1;
          let payInfo = getCalculatedAmtMin(totalmins, "H-5", rate);

          console.log("HTYPE", newobj, payInfo, rate);
          newobj["time_from1"] = newData["time_from1"];
          newobj["time_to1"] = newData["time_to1"];
          newobj["hrs"] = hrCalc;
          newobj["ole1"] = false;
          newobj["ole2"] = false;
          newobj["hjobtype"] = "";
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;

          console.log("#qwe LK UPDATE newobj", newobj);
          console.log(existAtIndex, "#LK newobj", newobj);
          rowsInput[existAtIndex] = newobj;

          console.log("#LK newobj newval", rowsInput);
          return rowsInput;
          // setRowsData([...rowsInput], rowsInput);
        }
      }
    }
    return rowsInput;
    //end hazard
  };
  const getTimeOfDays = (dayName, preDayHours) => {
    let data = preDayHours.filter((i) => {
      return i.dayName == dayName;
    });
    console.log("xxipen", data);

    if (data) {
      console.log("xxipenin", data);

      // let myresult = data.map((j) => { return { startTime: moment(j.startTime).format('HH:mm:ss'), endTime: moment( j.endTime).format('HH:mm:ss') } });
      let myresult = data.map((j) => {
        return {
          startTime: moment(j.startTime).utc().format("HH:mm:ss"),
          endTime: moment(j.endTime).utc().format("HH:mm:ss"),
          orgstartTime: j.startTime,
          orgendTime: j.endTime,
        };
      });
      console.log("Pixel", data, "###", myresult);

      return myresult;
    } else {
      return [{ startTime: null, endTime: null }];
    }
  };

  const replacePPEDate = (dateList, date) => {
    console.log(date, "dateList", dateList);
    let replaceby = dateList.find((i) => {
      return i.StartDate == date;
    });
    return replaceby ? replaceby.replacedate : date;
    // dateList.find(());
  };
  const handlePreviousPPE = async () => {
    let csdt = payPeriod && payPeriod.StartDate;
    let cedt = payPeriod && payPeriod.EndDate;
    
    let currentSelectedPPEIndex = allPayPeriod.findIndex((i) => {
      return i.StartDate <= csdt && i.EndDate >= cedt;
    });
    console.log(csdt,'csdt--',cedt,currentSelectedPPEIndex);

    if (currentSelectedPPEIndex===-1) {
      ShowError("Previous PPE record not found");
      return false;
    }

    let preVal = allPayPeriod[currentSelectedPPEIndex + 1];

    let sdt = preVal && preVal.StartDate;
    let edt = preVal && preVal.EndDate;
    if (sdt === undefined || edt === undefined) {
      return false;
    }

    // payPeriod.map((i) => {
    //   if(moment(i.startTime).format('dddd')===moment(preVal[i]['startTime']).format('dddd')) {
    //     preVal[ i ][ 'currentSDate' ] = i.startTime;
    //   }
    // });

    let a = moment(sdt);
    let b = moment(edt);
    let csm = moment(csdt);
    let cem = moment(cedt);

    let preFilledArray = [];
    let preFillDatesArray = [];
    let tempindex = 0;
    let c = moment(csm);
    for (let m = moment(a); m.isBefore(b); m.add(1, "days")) {
      let cdateName = c.format("YYYY-MM-DD");
      let cdayName = c.format("dddd");

      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");

      console.log(
        "preVal",
        cdayName,
        "#---",
        dayName,
        "@@",
        cdateName,
        "##",
        dateName
      );

      if (dayName == cdayName) {
        let oneObj = {};
        oneObj["replacedate"] = cdateName;
        oneObj["replaceday"] = cdayName;
        oneObj["StartDate"] = dateName;
        oneObj["EndDate"] = dateName;
        preFillDatesArray.push(oneObj);
      } else {
      }

      c.add(1, "days");
    }

    setPrevPPE(preFillDatesArray);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee/timelog/${userId}/from1/${sdt}/to/${edt}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        let createObj = [];
        console.log('response.data',response.data);
        
        if (response.data.length) {
          let prevdata = response.data;
          setOriginalData(prevdata);
          setEmployeeVerifyFlag(prevdata);

          // dispatch({
          //   type: "EmployeeProcessPayTimes",
          //   payload: { EmployeeProcessPayTimes: prevdata },
          // });

          let EmployeeProcessPayTimes = prevdata;
          console.log('Max',prevdata);
          
          if (EmployeeProcessPayTimes.length) {
            let newArray = [];

            let skip = false;
            EmployeeProcessPayTimes.map(async (i, index) => {
              const rate = getRateForDate(
                amountArray,
                replacePPEDate(
                  preFillDatesArray,
                  moment(i.WorkDate).utc().format("YYYY-MM-DD")
                ),
                price
              );
              let totalMins = i.TotalMinutes;
              let orgObj = i;

              let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
              // newobj[ 'time_from1' ]  = info.start;
              // newobj[ 'time_to1' ]  = info.end;
              // newobj[ 'hrs' ] = HourMinFormat(hrCalc);

              let lableid = `${moment(i.WorkDate).format("YYYY-MM-DD")}${
                i.PayCode
              }${d2Time(i.WorkFrom)}`;
              lableid = RemoveSpaceKeyGen(lableid);
              orgObj["WorkDate"] = replacePPEDate(
                preFillDatesArray,
                moment(i.WorkDate).utc().format("YYYY-MM-DD")
              );

              let payInfo = getCalculatedAmtMin(
                i.TotalMinutes,
                i.PayCode_PayTimeCode ? i.PayCode_PayTimeCode.Abberv : "",
                rate
              );

              let assign = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : "",
                ID: 0,
                id: lableid,
                date: replacePPEDate(
                  preFillDatesArray,
                  moment(i.WorkDate).utc().format("YYYY-MM-DD")
                ), // moment(i.WorkDate).format("YYYY-MM-DD"),
                dateName: moment(i.WorkDate).utc().format("dddd"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: payInfo,
                isNew: true,
                mn: i.TotalMinutes,

                mn1: 0,
                hrs: HourMinFormat(hrCalc),
                jobOrder: parseInt(i.JobOrder),
                code: parseInt(i.PayCode),
                verify: false,
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                JObOrdersData: [],
                Status: i.Status,
              };

              if (
                index + 1 < EmployeeProcessPayTimes.length &&
                i.PayCode == EmployeeProcessPayTimes[index + 1]["PayCode"] &&
                moment(i.WorkDate).utc().format("YYYY-MM-DD") ==
                  moment(EmployeeProcessPayTimes[index + 1]["WorkDate"])
                    .utc()
                    .format("YYYY-MM-DD") &&
                moment(i.CreatedDate).utc().format("YYYY-MM-DD HH:mm:ss") ==
                  moment(EmployeeProcessPayTimes[index + 1]["CreatedDate"])
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss") &&
                i.code == EmployeeProcessPayTimes[index + 1]["code"]
              ) {
                skip = true;

                // assign.ID = EmployeeProcessPayTimes[index + 1]["ID"];
                assign.ID2 = EmployeeProcessPayTimes[index + 1]["ID"];
                assign.time_from1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkFrom"]
                );
                assign.time_to1 = d2Time(
                  EmployeeProcessPayTimes[index + 1]["WorkTo"]
                );
                assign.isNew = false;
                assign.verify = false;
                assign.mn1 = parseInt(
                  EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                );
                assign.time_to_set = true;
                assign.parentIndex =
                  EmployeeProcessPayTimes[index + 1]["RecordNo"];
                assign.hrs = HourMinFormat(
                  `${Math.floor(
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) /
                      60
                  )}:${
                    (i.TotalMinutes +
                      parseInt(
                        EmployeeProcessPayTimes[index + 1]["TotalMinutes"]
                      )) %
                    60
                  }`
                );
                assign.rowstatus = i.ConfirmStatus
                  ? i.ConfirmStatus
                  : "Confirm";
                assign.verify = false;
                assign.Status = i.Status;

                // assign.hrs = HourFormat(parseInt(i.TotalHours)+parseInt(EmployeeProcessPayTimes[index+1]['TotalHours']));
                newArray.push(assign);
              } else {
                if (!skip) {
                  newArray.push(assign);
                }
                skip = false;
              }
            });
            setIsLoading(false);
            let refindArray = await handleSetHzToHtype(newArray);
            console.log("taget newArrayMB prevdate 11111", refindArray);

            setSaveData(refindArray);
            // setRowsData(refindArray);
            const mergedArray = [...refindArray, ...rowAuthData];
            console.log("fdfdsdsddsfdsfdfdfdfdsf", mergedArray);
            const isVerifyData = mergedArray.some((item) => item?.verify);

            console.log(
              "meta rowAuthData 121212 ddddd",
              rowAuthData,
              mergedArray
            );
            // if (isVerifyData) {
            if (mergedArray.length) {
              reAssignEmployee(userId)
            }
            setRowsData(mergedArray);
            // setWeekOT([]);
            // splitOTWeek([]);
            // } else {
            console.log("fdfdfdfdff dfdfdf");
            // const splitRowsData = splitOt(mergedArray);
            // setRowsData(splitRowsData);
            // }
          } else {
            setIsLoading(false);
            setRowsData([]);
          }
        } else {
          setIsLoading(false);
          setRowsData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };
  const handlePrefill = async (evnt, preHrsArray) => {
    console.log("Load prefill", rowsData, preHrsArray);

    // setChecked(evnt);
    // if (evnt === true && !rowsData.length) {
    let sdt = payPeriod && payPeriod.StartDate;
    let edt = payPeriod && payPeriod.EndDate;

    var a = moment(sdt);
    var b = moment(edt);

    let preFilledArray = [];
    let preOriginal = [];
    let tempindex = 0;
    for (var m = moment(a); m.isSameOrBefore(b); m.add(1, "days")) {
      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");
      let timePre = getTimeOfDays(dayName, preHrsArray);
      console.log("111222 @@@@@::: ", timePre);
      let createObject = {
        jobtype: "REG",
        id: KeyGen(7),
        date: dateName,
        time_from: timePre[0]?.["startTime"],
        time_to: timePre[0]?.["endTime"],
        time_from1: timePre[1]?.["startTime"],
        time_to1: timePre[1]?.["endTime"],
        hrs: "00:00",
        // hrs: HourMinFormat(hrCalc),
        mn: 0,
        mn1: 0,
        code: 1,
        jobOrder: "991022201",
        hjobtype: "",
        rowstatus: "Confirm",
        time_to_set: true,
        ole1: false,
        ole2: false,
        verify: false,
        isTemplate: true,
      };
      //create logic
      if (timePre[0]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[0]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: "1",
          FMLA: "",
          WorkFrom: timePre[0]?.["orgstartTime"],
          WorkTo: timePre[0]?.["orgendTime"],

          // "WorkFrom": moment(` ${timePre[0]?.["orgstartTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),
          // "WorkTo":moment(` ${timePre[0]?.["orgendTime"]}`).utc().format('YYYY-MM-DDTHH:mm:ss'),// timePre[0]?.["endTime"],
          // "WorkFrom": moment(`${timePre[0]?.["startTime"]}`,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.000Z'),
          // "WorkTo":mome,
          Rate: 26,
          JobOrder: "991022201",
          TotalHours: 420,
          GrossAmount: 10920,
          Typh: "REG",
          prefillData: true,
          PPE: 19,
          RecordNo: "0",
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          Auth: "Auth",
          isTemplate: true,
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }
      if (timePre[1]?.["startTime"]) {
        preOriginal.push({
          ID: KeyGen(7),
          EmployeeID: userId,
          WorkDate: moment(
            `${dateName} ${timePre[1]?.["startTime"]}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DDT00:00:00.000Z"),
          PayCode: "1",
          FMLA: "",
          WorkFrom: timePre[1]?.["orgstartTime"],
          WorkTo: timePre[1]?.["orgendTime"],
          Rate: 26,
          JobOrder: "991022201",
          TotalHours: 420,
          GrossAmount: 10920,
          prefillData: true,
          Typh: "REG",
          PPE: 19,
          RecordNo: "0",
          Auth: "Auth",
          isTemplate: true,
          CreatedDate: "2023-03-01T06:47:12.367Z",
          ConfirmStatus: "Confirm",
          PayCode_PayTimeCode: {
            ID: 1,
            Abberv: "REG",
          },
        });
      }

      /**
       * 
}
       */
      if (timePre[0]?.["startTime"]) {
        preFilledArray.push(createObject);
        console.log("111 @@@@@ :: ", createObject, preFilledArray);
      }
    } //end of loop

    let merged = [...saveData, ...preFilledArray];

    setRowsData(merged);
    console.log("--preOriginal merged 22", merged);
    console.log(
      merged,
      "--preOriginal",
      saveData,
      "PPE",
      preFilledArray,
      "@@@@@",
      preOriginal,
      "#####",
      originalData
    );
    let mixData = originalData.concat(preOriginal);
    const combinedArray = [originalData, preOriginal];
    setOriginalAuthData(preOriginal);
    setRowAuthData(preFilledArray); // not in use
    handleDateColor(preFilledArray);
    await Promise.all(
      preFilledArray?.map(async (i, index) => {
        // setIndex(index);
        // await handleTimeDiffCalculation();
        // handleTimeDiffCalculationPrefill(index, merged);
        handleTimeDiffCalculationPrefill(index, preFilledArray);
      })
    );

    setIsLoading(false);
    // }
  };
  const handleChange = (index, evnt, val = "") => {
    // console.log(index, "#@", evnt, "@#@", val);
    console.log("Handle change");

    setIndex(index);
    let code = "";
    let rowsInput = [...rowsData];

    let prev = { ...rowsInput[index] };
    // let prev = { ...rowsInput[index] };

    let attempt = false;
    // debugger;
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      const jobtypeCode = evnt.target.code ? evnt.target.code : null;

      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        attempt = true;
      }

      rowsInput[index][name] = value;
      if (name === "time_from") {
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_from"] = info;
        if (!rowsInput[index]["time_to_set"]) {
          let newTime = moment(info, "HH:mm").add(1, "hours");
          rowsInput[index]["time_to"] = newTime.format("HH:mm");
        }
      }

      if (name === "approveTimeFrom" || name === "approveTimeTo") {
        rowsInput[index][name] = value;
        rowsInput[index]["approveHrs"] = CalculateWorkingHR(
          rowsInput[index]["approveTimeFrom"],
          rowsInput[index]["approveTimeTo"],
          rowsInput[index]["date"],
          rowsInput[index]["mn"]
        );
      }
      //JD EXTRA CODE END
      if (name === "time_to") {
        // let info = adjustRoundedTime(value);
        // console.log('value---',value,'@',info);

        rowsInput[index]["time_to"] = value;
        rowsInput[index]["time_to_set"] = value ? true : false;
      }

      if (
        rowsInput[index].jobtype === "REG" &&
        rowsInput[index].hjobtype !== ""
      ) {
        // rowsInput = updateHzRow(rowsInput, prev, rowsInput[index]);
      }

      //JD EXTRA CODE
      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        if (attempt) {
          // rowsInput.splice(index + 1, 1);
        }
      }

      if (name && name === "jobtype") {
        // const paycodeId = paytimeCodeByID?.find((item) => (item.PayCode_PayTimeCode?.Abberv === evnt?.target?.value))?.ID;
        const paycodeId = employeeAuthorizedPayTimeCodesList?.find(
          (item) => item?.abberv === evnt?.target?.value
        )?.employeeAuthorizedPayTypeID;

        rowsInput[index]["EmployeeAuthorizedPayTypeId"] = paycodeId;
        if (value === "NDIF") {
          rowsInput[index]["code"] = jobtypeCode;
        } else {
          rowsInput[index]["code"] = jobtypeCode;
        }
        setRowsData(rowsInput);
      } else {
        // This function check time overlapping
        checkAddedTimeErrorTimekeeper(
          rowsInput,
          rowsInput[index],
          (isError) => {
            setHasError(isError);
          }
        );
        // let updateRowsInput = updateHzRow(rowsInput,prev);
        setRowsData(rowsInput);
      }
    } else {
      if (val !== null) {
        console.log("XXASDDE UPDATE AAA 2");

        rowsInput[index][val?.name] = val?.value;
        rowsInput[index]["code"] = val.code;
        setRowsData(rowsInput);
      } else {
        rowsInput[index]["jobtype"] = null;
        rowsInput[index]["code"] = null;
        setRowsData(rowsInput);
      }
    }

    // setIndex(index);
  };
  const handleChangeUpload = (index, evnt, val = "") => {
    // console.log(index, "#@", evnt, "@#@", val);
    console.log("Handle change");

    setIndexUpload(index);
    let code = "";
    let rowsInput = [...rowsDataUpload];

    let prev = { ...rowsInput[index] };
    // let prev = { ...rowsInput[index] };

    let attempt = false;
    // debugger;
    if (evnt.target.name !== undefined) {
      const { name, value } = evnt.target;
      const jobtypeCode = evnt.target.code ? evnt.target.code : null;

      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        attempt = true;
      }

      rowsInput[index][name] = value;
      if (name === "time_from") {
        let info = adjustRoundedTime(value);
        rowsInput[index]["time_from"] = info;
        if (!rowsInput[index]["time_to_set"]) {
          let newTime = moment(info, "HH:mm").add(1, "hours");
          rowsInput[index]["time_to"] = newTime.format("HH:mm");
        }
      }

      if (name === "approveTimeFrom" || name === "approveTimeTo") {
        rowsInput[index][name] = value;
        rowsInput[index]["approveHrs"] = CalculateWorkingHR(
          rowsInput[index]["approveTimeFrom"],
          rowsInput[index]["approveTimeTo"],
          rowsInput[index]["date"],
          rowsInput[index]["mn"]
        );
      }
      //JD EXTRA CODE END
      if (name === "time_to") {
        // let info = adjustRoundedTime(value);
        // console.log('value---',value,'@',info);

        rowsInput[index]["time_to"] = value;
        rowsInput[index]["time_to_set"] = value ? true : false;
      }

      if (
        rowsInput[index].jobtype === "REG" &&
        rowsInput[index].hjobtype !== ""
      ) {
        // rowsInput = updateHzRow(rowsInput, prev, rowsInput[index]);
      }

      //JD EXTRA CODE
      if (
        rowsInput[index]["time_from"] !== "" &&
        rowsInput[index]["time_to"] !== "" &&
        rowsInput[index]["time_from1"] !== "" &&
        rowsInput[index]["time_to1"] !== ""
      ) {
        if (attempt) {
          // rowsInput.splice(index + 1, 1);
        }
      }

      if (name && name === "jobtype") {
        // const paycodeId = paytimeCodeByID?.find((item) => (item.PayCode_PayTimeCode?.Abberv === evnt?.target?.value))?.ID;
        const paycodeId = employeeAuthorizedPayTimeCodesList?.find(
          (item) => item?.abberv === evnt?.target?.value
        )?.employeeAuthorizedPayTypeID;

        rowsInput[index]["EmployeeAuthorizedPayTypeId"] = paycodeId;
        if (value === "NDIF") {
          rowsInput[index]["code"] = jobtypeCode;
        } else {
          rowsInput[index]["code"] = jobtypeCode;
        }
        setRowsData(rowsInput);
      } else {
        // This function check time overlapping
        checkAddedTimeErrorTimekeeper(
          rowsInput,
          rowsInput[index],
          (isError) => {
            setHasError(isError);
          }
        );
        // let updateRowsInput = updateHzRow(rowsInput,prev);
        setRowsData(rowsInput);
      }
    } else {
      if (val !== null) {
        console.log("XXASDDE UPDATE AAA 2");

        rowsInput[index][val?.name] = val?.value;
        rowsInput[index]["code"] = val.code;
        setRowsDataUpload(rowsInput);
      } else {
        rowsInput[index]["jobtype"] = null;
        rowsInput[index]["code"] = null;
        setRowsDataUpload(rowsInput);
      }
    }

    // setIndex(index);
  };
  const addAfter = (array, index, newItem) => {
    return [...array.slice(0, index), newItem, ...array.slice(index)];
  };
  const handleChangeHizard = async (index, val = "") => {
    return false;
    console.log("#LK", index, "#", val);

    let paytime = paytimeCode.filter((item) => {
      return item.code >= 10 && item.code <= 12;
    });
    let arrayHtype = paytime.map((i) => {
      return i.value;
    });

    if (!val) {
      const rowsInput = rowsData;
      let rowId = rowsInput[index]["id"];
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
      });
      if (existAtIndex !== -1) {
        console.log("Next clear", existAtIndex);
        let newobj = { ...rowsInput[existAtIndex] };
        rowsInput.splice(existAtIndex, 1);
        console.log("next row check", newobj);

        setRowsData([...rowsInput], rowsInput);
        return false;
      }
    }

    const rowsInput = [...rowsData];
    let rowId = rowsInput[index]["id"];
    console.log("#mj rowsid", rowId);

    let existAtIndex = rowsData.findIndex((i) => {
      return i.parentIndex == rowId && arrayHtype.includes(i.jobtype);
    });
    let OtherforMasterIndex = rowsInput.filter((i) => {
      return i.parentIndex == rowId;
    });

    OtherforMasterIndex =
      parseInt(OtherforMasterIndex.length) + parseInt(index) + 1;
    if (existAtIndex !== -1) {
      //old index find
      let prevData = { ...rowsInput[index] };
      // let newobj = { ...rowsInput[ existAtIndex ] };
      // newobj = prevData;
      let newobj = prevData;

      const rate = getRateForDate(amountArray, newobj.date, price);
      let totalmins = newobj.totalminutes + newobj.totalminutes;
      let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

      newobj["jobtype"] = val.value;
      newobj["code"] = val.code;
      newobj["hjobtype"] = "";
      newobj["parentIndex"] = rowId;
      newobj["rowstatus"] = "Confirm";
      newobj["ole2"] = false;
      newobj["ole1"] = false;
      newobj["id"] = KeyGen(7);
      newobj["Rate"] = rate;
      newobj["payAmount"] = payInfo;

      console.log(existAtIndex, "#LK newobj", newobj);
      rowsInput[existAtIndex] = newobj;

      // setRowsData([...rowsInput], rowsInput);
    } else {
      let newobj = { ...rowsInput[index] };
      let insertAt = OtherforMasterIndex;
      const rate = getRateForDate(amountArray, newobj.date, price);
      let totalmins = newobj.mn + newobj.mn1;
      let payInfo = getCalculatedAmtMin(totalmins, val.value, rate);
      console.log("dsfhjs", newobj, rate, payInfo);
      // OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log(
        "#LK ADD",
        "OtherforMasterIndex",
        OtherforMasterIndex,
        "#",
        insertAt,
        "!#!",
        index
      );
      newobj["jobtype"] = val.value;
      newobj["code"] = val.code;
      newobj["parentIndex"] = rowId;
      newobj["rowstatus"] = "Confirm";
      newobj["ole2"] = false;
      newobj["ole1"] = false;
      newobj["id"] = KeyGen(7);
      newobj["Rate"] = rate;
      newobj["payAmount"] = payInfo;
      let newobjSame = { ...rowsInput[index] };
      newobjSame["hjobtype"] = val.value;
      rowsInput[index] = newobjSame;
      let ne = addAfter(rowsInput, insertAt, newobj);
      setRowsData(ne);
    }

    return false;
  };
  const setHoursWorked = async () => {
    await setHourData({
      ...hourData,
      REG: 0,
      "S/L": 0,
      NDIF: 0,
      HAZARDOUS: 0,
    });
    let myCnt = {
      "S/L": 0,
      REG: 0,
      NDIF: 0,
      HAZARDOUS: 0,
    };

    let rowsData1 = rowsData.filter((i) => {
      return !i.isTemplate;
    });

    if (rowsData1.length) {
      rowsData1.map(async (item, index) => {
        let selectedHours = parseInt(item.mn) + parseInt(item.mn1);
        if (item.jobtype === "REG") {
          // myCnt.REG = myCnt.REG + parseInt(selectedHours);
          myCnt.REG = myCnt.REG + parseInt(selectedHours);
        } else if (item.jobtype === "NDIF") {
          myCnt.NDIF = myCnt.NDIF + parseInt(selectedHours);
        } else if (item.jobtype === "HAZARDOUS") {
          myCnt.HAZARDOUS = myCnt.HAZARDOUS + parseInt(selectedHours);
        } else {
          myCnt[item.jobtype] = myCnt[item.jobtype]
            ? myCnt[item.jobtype] + parseInt(selectedHours)
            : 0 + parseInt(selectedHours);
        }
      });

      setHourData(myCnt);
    }
  };

  const setHoursWorkedOld = async () => {
    await setHourData({
      ...hourData,
      REG: 0,
      "S/L": 0,
      NDIF: 0,
      HAZARDOUS: 0,
    });
    let myCnt = {
      "S/L": 0,
      REG: 0,
      NDIF: 0,
      HAZARDOUS: 0,
    };
    if (rowsData.length) {
      rowsData.map(async (item, index) => {
        let selectedHours = item.hrs
          ? item.hrs.includes(":")
            ? item.hrs.split(":")[0]
            : item.hrs
          : 0;

        console.log("##### Hours ::: ", selectedHours, item.hrs);
        if (item.jobtype === "REG") {
          myCnt.REG = myCnt.REG + parseInt(selectedHours);
          // setHourData({
          //   ...hourData,
          //   REG: parseInt(selectedHours),
          // });
        } else if (item.jobtype === "NDIF") {
          // setHourData({
          //   ...hourData,
          //   NDIF: parseInt(selectedHours),
          // });
          myCnt.NDIF = myCnt.NDIF + parseInt(selectedHours);
        } else if (item.jobtype === "HAZARDOUS") {
          // setHourData({
          //   ...hourData,
          //   HAZARDOUS: parseInt(selectedHours),
          // });
          myCnt.HAZARDOUS = myCnt.HAZARDOUS + parseInt(selectedHours);
        } else {
          myCnt[item.jobtype] = myCnt[item.jobtype]
            ? myCnt[item.jobtype] + parseInt(selectedHours)
            : 0 + parseInt(selectedHours);
        }
      });

      setHourData(myCnt);
    }
  };
  const handleDateFieldChange = (name, value, index) => {
    let rowsInput = [...rowsData];

    console.log("#LL 012331", "rowsInput", rowsInput[index]["id"]);
    let rowId = rowsInput[index]["id"];

    let filZ = rowsData.filter((i) => {
      return i.parentIndex === rowId;
    });

    filZ.map((j) => {
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.id === j.id;
      });
      if (existAtIndex !== -1) {
        rowsInput[existAtIndex]["date"] = value;
      }
    });
    const date = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
    const rate = getRateForDate(
      amountArray,
      moment(value, "MM/DD/YYYY").format("YYYY-MM-DD"),
      price
    );
    rowsInput[index][name] = date;
    rowsInput[index]["Rate"] = rate;
    console.log("dsds", rowsInput[index]);
    let totalmins = rowsInput[index].mn + rowsInput[index].mn1;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index].jobtype,
      rate
    );

    console.log("csdkvsd", payInfo);

    rowsInput[index].payAmount = payInfo;
    checkAddedTimeErrorTimekeeper(rowsInput, rowsInput[index], (isError) => {
      setHasError(isError);
    });
    console.log("ASASdasdjnasdklhddjkl");
    // const sorted = [...rowsInput].sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    let rowDate = rowsInput[index]["date"];
    console.log("rowDate aaaa", rowDate);
    if (!checkPaycodeAccess("NDIF", rowDate)) {
      console.log("check ndiffffff", rowsInput[index]);
      let id4Match = rowsInput[index]["id"];
      let jobtype4Match = rowsInput[index]["jobtype"];
      // parentIndex
      let findDataIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == id4Match && i.jobtype == "NDIF";
      });
      if (findDataIndex !== -1) {
        let items = [...rowsInput];
        let remainAray = [
          ...items.slice(0, findDataIndex),
          ...items.slice(findDataIndex + 1),
        ];
        console.log(rowsInput, "Lsit Deleeted", deletedItemIds);
        if (rowsInput[findDataIndex].ID) {
          let list = deletedItemIds;
          list.push(rowsInput[findDataIndex].ID);
          console.log("Lsit Deleeted aaa", list);
          setDeletedItemIds(list);
        }
        rowsInput = remainAray;
      }
      //   remainAray.filter(
      //   (i) => i.parentIndex !== rowsInput[index].id
      // );

      // rowsInput = [...rowsInput];
      // rowsInput = [
      //   ...rowsInput.slice(0, findDataIndex),
      //   ...rowsInput.slice(findDataIndex + 1),
      // ];

      console.log("Bricks", findDataIndex);
    }
    setRowsData(rowsInput);

    handleDateColor(rowsInput);
  };
  const handleDateFieldChangeUpload = (name, value, index) => {
    let rowsInput = [...rowsDataUpload];

    console.log("#LL 012331", "rowsInput", rowsInput[index]["id"]);
    let rowId = rowsInput[index]["id"];

    let filZ = rowsDataUpload.filter((i) => {
      return i.parentIndex === rowId;
    });

    filZ.map((j) => {
      let existAtIndex = rowsInput.findIndex((i) => {
        return i.id === j.id;
      });
      if (existAtIndex !== -1) {
        rowsInput[existAtIndex]["date"] = value;
      }
    });
    const date = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
    const rate = getRateForDate(
      amountArray,
      moment(value, "MM/DD/YYYY").format("YYYY-MM-DD"),
      price
    );
    rowsInput[index][name] = date;
    rowsInput[index]["Rate"] = rate;
    console.log("dsds", rowsInput[index]);
    let totalmins = rowsInput[index].mn + rowsInput[index].mn1;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index].jobtype,
      rate
    );

    console.log("csdkvsd", payInfo);

    rowsInput[index].payAmount = payInfo;
    checkAddedTimeErrorTimekeeper(rowsInput, rowsInput[index], (isError) => {
      setHasError(isError);
    });
    console.log("ASASdasdjnasdklhddjkl");
    // const sorted = [...rowsInput].sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    let rowDate = rowsInput[index]["date"];
    console.log("rowDate aaaa", rowDate);
    if (!checkPaycodeAccess("NDIF", rowDate)) {
      console.log("check ndiffffff", rowsInput[index]);
      let id4Match = rowsInput[index]["id"];
      let jobtype4Match = rowsInput[index]["jobtype"];
      // parentIndex
      let findDataIndex = rowsInput.findIndex((i) => {
        return i.parentIndex == id4Match && i.jobtype == "NDIF";
      });
      if (findDataIndex !== -1) {
        let items = [...rowsInput];
        let remainAray = [
          ...items.slice(0, findDataIndex),
          ...items.slice(findDataIndex + 1),
        ];
        console.log(rowsInput, "Lsit Deleeted", deletedItemIds);
        if (rowsInput[findDataIndex].ID) {
          let list = deletedItemIds;
          list.push(rowsInput[findDataIndex].ID);
          console.log("Lsit Deleeted aaa", list);
          setDeletedItemIds(list);
        }
        rowsInput = remainAray;
      }
      //   remainAray.filter(
      //   (i) => i.parentIndex !== rowsInput[index].id
      // );

      // rowsInput = [...rowsInput];
      // rowsInput = [
      //   ...rowsInput.slice(0, findDataIndex),
      //   ...rowsInput.slice(findDataIndex + 1),
      // ];

      console.log("Bricks", findDataIndex);
    }
    setRowsDataUpload(rowsInput);

    handleDateColor(rowsInput);
  };

  const createRecord = (recno) => {
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }
    let recordsCopy = [...rowsData];

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });

    if (findID.length) {
      let lableCreate = `${findID[0]?.date}${findID[0]?.code}${findID[0]?.time_from}`;
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };

  const createRecord2 = (recno) => {
    if (recno == "" || recno == "0" || recno == null) {
      return "0";
    }
    let recordsCopy = [...rowsData];

    let findID = recordsCopy.filter((i) => {
      return i.id == recno;
    });

    if (findID.length) {
      let lableCreate = `${findID[0]?.date}${findID[0]?.code}${findID[0]?.time_from1}`;
      return RemoveSpaceKeyGen(lableCreate);
    } else {
      return RemoveSpaceKeyGen(recno);
    }
  };

  //Manoj
  const handleSaveRows = () => {
    console.log("SAve", rowsData);
    let hasError = rowsData.filter((i) => {
      return i.ole1 || i.ole2;
    });

    if (hasError.length) {
      ShowError(
        "Please Verify Timesheet. It's has issue with overlapping time"
      );
      return false;
    }
    let rowdataNew = rowsData.filter((i) => !i.isTemplate);
    let myArray = [];

    rowdataNew.map((item, index) => {
      // let recno = item.id ? item.id : "0";
      let recno = item.parentIndex ? item.parentIndex : "0";
      recno = createRecord(recno);

      let info = {
        WorkDate: item.date,
        PayCode: parseInt(item.code),
        FMLA: "",
        WorkFrom: item.time_from,
        WorkTo: item.time_to,
        JobOrder: `${item.jobOrder}`,
        Typh: item.jobtype,
        RecordNo: recno ? recno : "0",
        Status: item.rowstatus,
      };
      if (item?.CreatedDate) {
        info = { ...info, CreatedDate: item["CreatedDate"] };
      }
      if (item.ID && item.ID !== undefined) {
        info["ID"] = item.ID;
        // info["RecordNo"] = item.parentIndex;
      } else {
      }

      if (
        (item.jobtype && item.time_from && item.time_to) ||
        (item.time_from1 && item.time_to1)
      ) {
        if (!item.time_from && !item.time_to && item.ID) {
          console.log("del id", item.ID);
        }
        if (!item.time_from1 && !item.time_to1 && item.ID2) {
          console.log("del id 2", item.ID2);
        }
        if (item.time_from && item.time_to) {
          myArray.push(info);
        }

        if (item.time_from1 && item.time_to1) {
          if (
            typeof item.time_from1 == "string" &&
            item.time_from1.includes(":")
          ) {
            if (!item.ID2) {
              // let recno2 = item.parentIndex ? item.parentIndex : "0";
              // recno = createRecord(recno2); //implement as createRecord2
            }
            if (!item.ID2 && item.ID) {
              let recno2 = item.parentIndex ? item.parentIndex : "0";
              recno = createRecord2(recno2); //implement as createRecord2
            }
            if (!item.ID && item.ID2) {
              let recno2 = item.parentIndex ? item.parentIndex : "0";
              recno = createRecord(recno2); //implement as createRecord2
            }

            console.log(
              item.ID2,
              "#RNO",
              recno,
              "push3 Index",
              index,
              "@@@",
              item.parentIndex2
            );
            let info2 = {
              WorkDate: item.date,
              ID: item.ID2,
              PayCode: item.code,
              FMLA: "",
              WorkFrom: item.time_from1,
              WorkTo: item.time_to1,
              JobOrder: item.jobOrder,
              Typh: item.jobtype,
              RecordNo: recno ? recno : "0",
              Status: item.rowstatus,
            };
            if (item?.CreatedDate) {
              info2 = { ...info2, CreatedDate: item["CreatedDate"] };
            }
            if (!item.isNew && item.ID && item.ID !== undefined) {
              info["ID"] = item.ID;
            } else {
            }
            if (!item.isNew && item.ID2 && item.ID2 !== undefined) {
              info2["ID"] = item.ID2;
              // info2["RecordNo"] = item.parentIndex2;
            }
            myArray.push(info2);
            console.log("LogA", info2);
          } else {
            console.log("LogB");
          }
        }
      }
    });

    console.log(deletedItemIds, "13D 13D del", myArray);

    if (!window.confirm("Are you sure to submit timesheet?")) {
      return false;
    }
    // return false;
    //  return
    //working
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feed/${userId}`,
        { workingHours: myArray, deletedList: deletedItemIds },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        loadPreData();
        // setRowsData([sampleData]);
        // alert("Timesheet added successfully")

        ShowSucess("Time Added Successfully");
        // dispatch(
        //   showMessage({
        //     message: "Timesheet added successfully",
        //     variant: "success",
        //   })
        // );
        console.log("Master responsexxxx", response.data);
        setDeletedItemIds([]);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
        setDeletedItemIds([]);
      });
    console.log("myArray", myArray);
  };

  const hoursDiffrence = (time_from, time_to) => {
    const startTime = moment(time_from, "HH:mm");
    const endTime = moment(time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;
    return {
      hr: hours,
      minutes: minutes,
      totalMin: totalMin,
    };
  };

  const handleTimeDiffCalculationPrefillRemove = async (
    indexID,
    rowsDataArray
  ) => {
    let indexNo = indexID ? indexID : index;
    let rows_Data = rowsDataArray ? rowsDataArray : rowsData;
    console.log("indexCall night diff calc", index, "#", rows_Data[indexNo]);

    const startTime = moment(rows_Data[indexNo]?.time_from, "HH:mm");
    const endTime = moment(rows_Data[indexNo]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      console.log("Duration lessthen");
      const endTime2 = moment(rows_Data[indexNo]?.time_to, "HH:mm").add(
        1,
        "day"
      );
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    console.log(hours, "##", minutes);

    const startTime1 = moment(rows_Data[indexNo]?.time_from1, "HH:mm");
    const endTime1 = moment(rows_Data[indexNo]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rows_Data[indexNo]?.time_to1, "HH:mm").add(
        1,
        "day"
      );
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

    let hrCalc = await HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );

    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rows_Data[indexNo] };
    const rowsInput = [...rows_Data];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    console.log(rowsInput[index], "#33123# ", hrCalc, "=", hours1, totalMin1);
    rowsInput[index]["hrs"] = hrCalc; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;
    rowsInput[index]["mn"] = totalMin;
    rowsInput[index]["mn1"] = totalMin1;
    await setRowsData(rowsInput);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rows_Data[indexNo]?.time_from === "string" &&
      typeof rows_Data[indexNo]?.time_to === "string" &&
      rows_Data[indexNo]?.jobtype === "REG"
    )
      if (
        typeof rows_Data[indexNo]?.time_to === "string" &&
        rows_Data[indexNo]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", ninfo);

        if (rows_Data[indexNo]?.hjobtype !== "") {
          console.log("#LK in info", rows_Data[indexNo]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rows_Data[indexNo]?.time_from === "string" &&
      typeof rows_Data[indexNo]?.time_to === "string" &&
      typeof rows_Data[indexNo]?.time_from1 === "string" &&
      rows_Data[indexNo]?.jobtype === "REG"
    )
      if (
        typeof rows_Data[indexNo]?.time_to1 === "string" &&
        rows_Data[indexNo]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        console.log("#LK ninfo 2", ninfo);
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    if (rows_Data[indexNo]?.jobtype == "REG") {
      console.log("ntntntntntnt", rows_Data[indexNo]);
      await appendNDIFRow(nightDiffObj, rows_Data[indexNo]?.date);
    }
  };
  const handleTimeDiffCalculationPrefill = async (index, rowsData) => {
    console.log("indexCall night diff calc", index, "#", rowsData[index]);
    const startTime = moment(rowsData[index]?.time_from, "HH:mm");
    const endTime = moment(rowsData[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    console.log("Duration", duration);
    if (duration.asHours() < 0) {
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    const startTime1 = moment(rowsData[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsData[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;
    let hrCalc = HourMinFormat(
      `${Math.floor((totalMin + totalMin1) / 60)}:${
        (totalMin + totalMin1) % 60
      }`
    );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;
    let prev = { ...rowsData[index] };
    const rowsInput = [...rowsData];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }
    rowsInput[index]["hrs"] = hrCalc; //`${sumHours}:${("0" + sumMinutes).slice(-2)}`;
    rowsInput[index]["mn"] = totalMin;
    rowsInput[index]["mn1"] = totalMin1;

    setRowsData([...rowsInput, ...saveData]);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to === "string" &&
        rowsData[index]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", rowsData[index]);

        if (rowsData[index]?.hjobtype !== "") {
          console.log("#LK in info", rowsData[index]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      typeof rowsData[index]?.time_from1 === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to1 === "string" &&
        rowsData[index]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    if (rowsData[index]?.jobtype == "REG") {
      console.log("ntntntntntnt", rowsData[index]);
      await appendNDIFRow(nightDiffObj, rowsData[index]?.date);
    }
  };

  const checkAuth = (sd, ed, cDate) => {
    console.log("ascfsaf", sd, ed);
    let isauth = "pending";
    // let dy = moment(sd, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
    let shr = sd;
    let ehr = ed;

    let tData = rowAuthData.filter((i) => {
      return i.date == cDate;
    });
    console.log("Dasflkdjdmds", tData);

    let isPartial = false;
    let info = [];
    let result = [];
    tData.map((i, index) => {
      let fromTime = moment(i.time_from, "HH:mm:ss").format("HH:mm");
      let toTime = moment(i.time_to, "HH:mm:ss").format("HH:mm");
      if (shr >= fromTime && ehr <= toTime) {
        isauth = "confirm";
      }
      if (
        shr < toTime &&
        ehr > fromTime &&
        !(shr >= fromTime && ehr <= toTime)
      ) {
        isPartial = true;
        info.push({ shr: shr, ehr: ehr, fromTime: fromTime, toTime: toTime });

        if (shr < fromTime) {
          result.push({ start: shr, end: fromTime });
        } else {
          result.push({ start: shr, end: fromTime });
        }
        if (ehr < toTime) {
          result.push({ start: fromTime, end: ehr });
        } else {
        }
      }
    });

    return { status: isauth, isPartial: isPartial, info: info, result: result };
  };
  const CalculateWorkingHR = (st, et, date, mn) => {
    const startTime = moment(`${date} ${st}`, "YYYY-MM-DD HH:mm");
    const endTime = moment(`${date} ${et}`, "YYYY-MM-DD HH:mm");
    let duration = moment.duration(endTime.diff(startTime));

    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;
    let hrCalc = "00:00";
    if (mn) {
      hrCalc = HourMinFormat(`${Math.floor(mn / 60)}:${mn % 60}`);
    } else {
      hrCalc = HourMinFormat(`${Math.floor(totalMin / 60)}:${totalMin % 60}`);
    }

    return hrCalc;
  };
  const splitTimeRangeSlots = (
    startTime,
    endTime,
    existingTime,
    allData,
    index
  ) => {
    const splitSlots = [{ start: startTime, end: endTime }];

    for (let i = 0; i < existingTime.length; i++) {
      const currentSlot = existingTime[i];
      const updatedSlots = [];

      for (let j = 0; j < splitSlots.length; j++) {
        const slot = splitSlots[j];

        if (
          currentSlot.fromTime >= slot.start &&
          currentSlot.toTime <= slot.end
        ) {
          updatedSlots.push({
            ...allData,
            time_from: slot.start,
            time_to: currentSlot.fromTime,
            jobtype: "N/D",
            code: 5,
            ID: null,
            hrs: getHMFromTime(slot.start, currentSlot.fromTime)?.hhmm,
          });
          updatedSlots.push({
            ...allData,
            time_from: currentSlot.fromTime,
            time_to: currentSlot.toTime,
            jobtype: "N/D",
            code: 5,
            ID: null,
            hrs: getHMFromTime(currentSlot.fromTime, currentSlot.toTime)?.hhmm,
          });
          updatedSlots.push({
            ...allData,
            time_from: currentSlot.toTime,
            time_to: slot.end,
            jobtype: "N/D",
            code: 5,
            ID: null,
            hrs: getHMFromTime(currentSlot.toTime, slot.end)?.hhmm,
          });
        } else if (
          currentSlot.fromTime < slot.end &&
          currentSlot.toTime > slot.start
        ) {
          if (slot.start < currentSlot.fromTime) {
            updatedSlots.push({
              ...allData,
              time_from: slot.start,
              time_to: currentSlot.fromTime,
              jobtype: "N/D",
              code: 5,
              ID: null,

              hrs: getHMFromTime(slot.start, currentSlot.fromTime)?.hhmm,
            });
          }
          if (slot.end > currentSlot.toTime) {
            updatedSlots.push({
              ...allData,
              time_from: currentSlot.toTime,
              time_to: slot.end,
              jobtype: "N/D",
              code: 5,
              ID: null,
              hrs: getHMFromTime(currentSlot.toTime, slot.end)?.hhmm,
            });
          }
        } else {
          updatedSlots.push(slot);
        }
      }
      splitSlots.splice(0, splitSlots.length, ...updatedSlots);
    }
    return { splitSlots, index };
  };

  const splitTimeRange = (startTime, endTime, date) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    const newDate = moment(date, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD");

    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);
    const timeRanges = [];
    if (endDate < startDate) {
      const midnight = new Date(startDate);
      midnight.setHours(24, 0);

      // timeRanges.push({
      //   date:newDate,
      //   startTime: startTime,
      //   endTime: midnight.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      // });

      timeRanges.push({
        startTime: "00:00",
        endTime: endTime,
        date: newDate,
      });
    }
    // else {
    //   timeRanges.push({
    //     // date: date,
    //     startTime: startTime,
    //     endTime: endTime
    //   });
    // }

    return timeRanges;
  };

  const handleVerifyRows = (index, event) => {
    const rowsInput = [...rowsData];
    let newObj = rowsInput[index];
    let totalMins = hoursDiffrence(newObj.time_from, newObj?.time_to);
    newObj["approveTimeFrom"] = newObj.time_from;
    newObj["approveTimeTo"] = newObj?.time_to;
    newObj["verify"] = event.target.checked;
    newObj["mn"] = totalMins?.totalMin;

    newObj["approveHrs"] = CalculateWorkingHR(
      newObj.time_from,
      newObj.time_to,
      newObj?.date,
      totalMins?.totalMin
    );
    setRowsData(rowsInput);
  };

  const handleVerifyRowsUpload = (index, event) => {
    const rowsInput = [...rowsDataUpload];
    let newObj = rowsInput[index];
    let totalMins = hoursDiffrence(newObj.time_from, newObj?.time_to);
    newObj["approveTimeFrom"] = newObj.time_from;
    newObj["approveTimeTo"] = newObj?.time_to;
    newObj["verify"] = event.target.checked;
    newObj["mn"] = totalMins?.totalMin;

    newObj["approveHrs"] = CalculateWorkingHR(
      newObj.time_from,
      newObj.time_to,
      newObj?.date,
      totalMins?.totalMin
    );
    setRowsDataUpload(rowsInput);
  };

  const handleTimeDiffCalculation = async () => {
    const startTime = moment(rowsData[index]?.time_from, "HH:mm");
    const endTime = moment(rowsData[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    if (duration.asHours() < 0) {
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    const startTime1 = moment(rowsData[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsData[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

    // let hrCalc = HourMinFormat(
    //   `${Math.floor((totalMin + totalMin1) / 60)}:${
    //     (totalMin + totalMin1) % 60
    //   }`
    // );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;

    const rowsInput = [...rowsData];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    if (rowsInput[index]) {
      rowsInput[index]["mn"] = totalMin;
      rowsInput[index]["mn1"] = totalMin1;
    }

    //Check date exceed
    if (payPeriod?.EndDate == rowsInput[index]?.date) {
      if (
        (rowsInput[index]["time_to"] || rowsInput[index]["time_from"]) &&
        rowsInput[index]["time_to"] <= rowsInput[index]["time_from"]
      ) {
        rowsInput[index]["dateExceed1"] = true;
      } else {
        rowsInput[index]["dateExceed1"] = false;
      }
      if (
        (rowsInput[index]["time_to1"] || rowsInput[index]["time_from1"]) &&
        rowsInput[index]["time_to1"] <= rowsInput[index]["time_from1"]
      ) {
        rowsInput[index]["dateExceed2"] = true;
      } else {
        rowsInput[index]["dateExceed2"] = false;
      }
    } else {
      rowsInput[index]["dateExceed1"] = false;
      rowsInput[index]["dateExceed2"] = false;
      let rowId = rowsInput[index]["id"];
      // rowsInput.filter((i) => { i.parentIndex == rowsInput[ index ][ "Id" ] });
      let matchingRecord = rowsInput.filter((row) => {
        return row.parentIndex === rowId;
      });
      let list = [...deletedItemIds];
      matchingRecord.map((i) => {
        i.time_from = rowsInput[index].time_from;
        if (i.ID) {
          // list.push(parseInt(i.ID));
        }

        let childIndex = rowsInput.findIndex(
          (row) => row.parentIndex === rowId
        );
        // rowsInput.splice(childIndex, 1);
      });
    }

    const rate = getRateForDate(amountArray, rowsInput[index]?.date, price);

    rowsInput[index].Rate = rate;
    let totalmins = rowsInput[index]?.mn + rowsInput[index]?.mn1;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount = payInfo;
    if (
      rowsInput[index].jobtype == "S/L" ||
      rowsInput[index].jobtype == "A/L"
    ) {
      const diffInYears = GetLeaveAccureByExperiance(
        userInfo?.EmployeementDate,
        leaveHours
      );
      const TotalMinSick = diffInYears.sick * 60;
      const TotalMinAnnual = diffInYears.annual * 60;

      if (
        rowsInput[index].jobtype == "S/L" &&
        rowsInput[index].mn > TotalMinSick
      ) {
        rowsInput[index]["leaveHours"] = true;
      } else if (
        rowsInput[index].jobtype == "A/L" &&
        rowsInput[index].mn > TotalMinAnnual
      ) {
        rowsInput[index]["leaveHours"] = true;
      } else {
        rowsInput[index]["leaveHours"] = false;
      }
    } else {
      rowsInput[index]["leaveHours"] = false;
    }
    // end of check date exceed

    if (rowAuthData.length && rowsInput[index].jobtype == "REG") {
      const checkData1 = checkAuth(
        rowsInput[index].time_from,
        rowsInput[index].time_to,
        rowsInput[index].date
      );
      const checkData2 = checkAuth(
        rowsInput[index].time_from1,
        rowsInput[index].time_to1,
        rowsInput[index].date
      );

      if (checkData1 && rowsInput[index].time_to) {
        if (checkData1.status === "confirm") {
          rowsInput[index]["authError1"] = false;
        } else {
          rowsInput[index]["authError1"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
      if (checkData2 && rowsInput[index].time_to1) {
        if (checkData2.status === "confirm") {
          rowsInput[index]["authError2"] = false;
        } else {
          rowsInput[index]["authError2"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
    }

    const spiltAfter12Range1 = splitTimeRange(
      rowsInput[index]?.time_from,
      rowsInput[index]?.time_to,
      rowsInput[index]?.date
    );

    if (spiltAfter12Range1.length) {
      rowsInput[index]["time_to"] = "00:00";

      if (
        rowsInput.filter((item) => item.parentID === rowsInput[index].id).length
      ) {
        setRowsData(
          rowsInput.map((item) => {
            if (item.parentID === rowsInput[index].id) {
              return {
                ...item,
                date: spiltAfter12Range1[0].date,
                time_from: spiltAfter12Range1[0].startTime,
                time_to: spiltAfter12Range1[0].endTime,
              };
            } else {
              return item;
            }
          })
        );
        // rowsInput[index].time_to = "00:00"
      } else {
        spiltAfter12Range1.map((i) => {
          if (i.date && rowsInput[index].id) {
            let createObject = sampleData;
            let totalMins = hoursDiffrence(i.startTime, i.endTime);
            createObject["date"] = i.date;
            createObject["parentID"] = rowsInput[index].id;
            createObject["time_from"] = i.startTime;
            createObject["time_to"] = i.endTime;
            createObject["mn"] = totalMins.totalMin;

            createObject["hrs"] = CalculateWorkingHR(
              i.startTime,
              i.endTime,
              i.date
            );
            rowsInput.push(createObject);
          }
        });
      }
    } else {
      rowsInput.filter((item) => item.parentID !== rowsInput[index].id);
    }

    let totalMins = hoursDiffrence(
      rowsInput[index].time_from,
      rowsInput[index].time_to
    );
    rowsInput[index]["hrs"] = CalculateWorkingHR(
      rowsInput[index].time_from,
      rowsInput[index].time_to,
      rowsInput[index].date,
      totalMins.totalMin
    );
    rowsInput[index]["mn"] = totalMins.totalMin;
    setRowsData(rowsInput);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to === "string" &&
        rowsData[index]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", rowsData[index]);

        if (rowsData[index]?.hjobtype !== "") {
          console.log("#LK in info", rowsData[index]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rowsData[index]?.time_from === "string" &&
      typeof rowsData[index]?.time_to === "string" &&
      typeof rowsData[index]?.time_from1 === "string" &&
      rowsData[index]?.jobtype === "REG"
    )
      if (
        typeof rowsData[index]?.time_to1 === "string" &&
        rowsData[index]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    // if (rowsData[index]?.jobtype == "REG") {
    //   console.log("ntntntntntnt 22222", rowsData[index]);
    //   await appendNDIFRow(nightDiffObj, rowsData[index]?.date);
    // }
    // add To Next Payperiod dipenBhai

    // if (rowsData[index].time_from && rowsData[index].time_to) {
    //   let fr = rowsData[index].time_from.split(":")[0];
    //   let to = rowsData[index].time_to.split(":")[0];
    //   let fr1 = rowsData[index].time_from1.split(":")[0];
    //   let to1 = rowsData[index].time_to1.split(":")[0];
    //   console.log(
    //     "121212 before condition ::: ",
    //     payPeriod,
    //     rowsData[index].date,
    //     to
    //   );
    //   if (rowsData[index].date === payPeriod.EndDate) {
    //     if (
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from) ||
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from)
    //     ) {
    //       console.log(
    //         "121212 in condition  ::: ",
    //         rowsData[index].time_from,
    //         rowsData[index].time_to
    //       );
    //       addTableRowForNextDay(index);
    //     }
    //   }
    // }
    // const splitDatas = splitOt(rowsInput)
    // setRowsData(splitDatas)
  };

  const handleTimeDiffCalculationUpload = async () => {
    const startTime = moment(rowsDataUpload[index]?.time_from, "HH:mm");
    const endTime = moment(rowsDataUpload[index]?.time_to, "HH:mm");
    let duration = moment.duration(endTime.diff(startTime));
    if (duration.asHours() < 0) {
      const endTime2 = moment(rowsData[index]?.time_to, "HH:mm").add(1, "day");
      duration = moment.duration(endTime2.diff(startTime));
    }
    const hours = duration._isValid ? parseInt(duration.asHours()) : 0;
    const minutes = duration._isValid ? parseInt(duration.asMinutes()) % 60 : 0;
    const totalMin = duration._isValid ? parseInt(duration.asMinutes()) : 0;

    const startTime1 = moment(rowsDataUpload[index]?.time_from1, "HH:mm");
    const endTime1 = moment(rowsDataUpload[index]?.time_to1, "HH:mm");
    let duration1 = moment.duration(endTime1.diff(startTime1));
    if (duration1.asHours() < 0) {
      const endTime3 = moment(rowsDataUpload[index]?.time_to1, "HH:mm").add(
        1,
        "day"
      );
      duration1 = moment.duration(endTime3.diff(startTime1));
    }

    const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
    const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

    // let hrCalc = HourMinFormat(
    //   `${Math.floor((totalMin + totalMin1) / 60)}:${
    //     (totalMin + totalMin1) % 60
    //   }`
    // );
    const minutes1 = duration1._isValid
      ? parseInt(duration1.asMinutes()) % 60
      : 0;

    const rowsInput = [...rowsDataUpload];

    let sumHours = hours + hours1;
    let sumMinutes = minutes + minutes1;

    if (minutes + minutes1 > 60) {
      sumHours++;
      sumMinutes = sumMinutes - 60;
    }

    if (rowsInput[index]) {
      rowsInput[index]["mn"] = totalMin;
      rowsInput[index]["mn1"] = totalMin1;
    }

    //Check date exceed
    if (payPeriod?.EndDate == rowsInput[index]?.date) {
      if (
        (rowsInput[index]["time_to"] || rowsInput[index]["time_from"]) &&
        rowsInput[index]["time_to"] <= rowsInput[index]["time_from"]
      ) {
        rowsInput[index]["dateExceed1"] = true;
      } else {
        rowsInput[index]["dateExceed1"] = false;
      }
      if (
        (rowsInput[index]["time_to1"] || rowsInput[index]["time_from1"]) &&
        rowsInput[index]["time_to1"] <= rowsInput[index]["time_from1"]
      ) {
        rowsInput[index]["dateExceed2"] = true;
      } else {
        rowsInput[index]["dateExceed2"] = false;
      }
    } else {
      rowsInput[index]["dateExceed1"] = false;
      rowsInput[index]["dateExceed2"] = false;
      let rowId = rowsInput[index]["id"];
      // rowsInput.filter((i) => { i.parentIndex == rowsInput[ index ][ "Id" ] });
      let matchingRecord = rowsInput.filter((row) => {
        return row.parentIndex === rowId;
      });
      let list = [...deletedItemIds];
      matchingRecord.map((i) => {
        i.time_from = rowsInput[index].time_from;
        if (i.ID) {
          // list.push(parseInt(i.ID));
        }

        let childIndex = rowsInput.findIndex(
          (row) => row.parentIndex === rowId
        );
        // rowsInput.splice(childIndex, 1);
      });
    }

    const rate = getRateForDate(amountArray, rowsInput[index]?.date, price);

    rowsInput[index].Rate = rate;
    let totalmins = rowsInput[index]?.mn + rowsInput[index]?.mn1;
    let payInfo = getCalculatedAmtMin(
      totalmins,
      rowsInput[index]?.jobtype,
      rate
    );
    rowsInput[index].payAmount = payInfo;
    if (
      rowsInput[index].jobtype == "S/L" ||
      rowsInput[index].jobtype == "A/L"
    ) {
      const diffInYears = GetLeaveAccureByExperiance(
        userInfo?.EmployeementDate,
        leaveHours
      );
      const TotalMinSick = diffInYears.sick * 60;
      const TotalMinAnnual = diffInYears.annual * 60;

      if (
        rowsInput[index].jobtype == "S/L" &&
        rowsInput[index].mn > TotalMinSick
      ) {
        rowsInput[index]["leaveHours"] = true;
      } else if (
        rowsInput[index].jobtype == "A/L" &&
        rowsInput[index].mn > TotalMinAnnual
      ) {
        rowsInput[index]["leaveHours"] = true;
      } else {
        rowsInput[index]["leaveHours"] = false;
      }
    } else {
      rowsInput[index]["leaveHours"] = false;
    }
    // end of check date exceed

    if (rowAuthData.length && rowsInput[index].jobtype == "REG") {
      const checkData1 = checkAuth(
        rowsInput[index].time_from,
        rowsInput[index].time_to,
        rowsInput[index].date
      );
      const checkData2 = checkAuth(
        rowsInput[index].time_from1,
        rowsInput[index].time_to1,
        rowsInput[index].date
      );

      if (checkData1 && rowsInput[index].time_to) {
        if (checkData1.status === "confirm") {
          rowsInput[index]["authError1"] = false;
        } else {
          rowsInput[index]["authError1"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
      if (checkData2 && rowsInput[index].time_to1) {
        if (checkData2.status === "confirm") {
          rowsInput[index]["authError2"] = false;
        } else {
          rowsInput[index]["authError2"] = true;
          ShowError("Unauthorized Time added!");
        }
      }
    }

    const spiltAfter12Range1 = splitTimeRange(
      rowsInput[index]?.time_from,
      rowsInput[index]?.time_to,
      rowsInput[index]?.date
    );

    if (spiltAfter12Range1.length) {
      rowsInput[index]["time_to"] = "00:00";

      if (
        rowsInput.filter((item) => item.parentID === rowsInput[index].id).length
      ) {
        setRowsDataUpload(
          rowsInput.map((item) => {
            if (item.parentID === rowsInput[index].id) {
              return {
                ...item,
                date: spiltAfter12Range1[0].date,
                time_from: spiltAfter12Range1[0].startTime,
                time_to: spiltAfter12Range1[0].endTime,
              };
            } else {
              return item;
            }
          })
        );
        // rowsInput[index].time_to = "00:00"
      } else {
        spiltAfter12Range1.map((i) => {
          if (i.date && rowsInput[index].id) {
            let createObject = sampleData;
            let totalMins = hoursDiffrence(i.startTime, i.endTime);
            createObject["date"] = i.date;
            createObject["parentID"] = rowsInput[index].id;
            createObject["time_from"] = i.startTime;
            createObject["time_to"] = i.endTime;
            createObject["mn"] = totalMins.totalMin;

            createObject["hrs"] = CalculateWorkingHR(
              i.startTime,
              i.endTime,
              i.date
            );
            rowsInput.push(createObject);
          }
        });
      }
    } else {
      rowsInput.filter((item) => item.parentID !== rowsInput[index].id);
    }

    let totalMins = hoursDiffrence(
      rowsInput[index].time_from,
      rowsInput[index].time_to
    );
    rowsInput[index]["hrs"] = CalculateWorkingHR(
      rowsInput[index].time_from,
      rowsInput[index].time_to,
      rowsInput[index].date,
      totalMins.totalMin
    );
    rowsInput[index]["mn"] = totalMins.totalMin;
    setRowsDataUpload(rowsInput);
    let nightDiffObj = {
      firstShift: false,
      firstInfo: {},
      secondShift: false,
      secondInfo: {},
    };

    if (
      typeof rowsDataUpload[index]?.time_from === "string" &&
      typeof rowsDataUpload[index]?.time_to === "string" &&
      rowsDataUpload[index]?.jobtype === "REG"
    )
      if (
        typeof rowsDataUpload[index]?.time_to === "string" &&
        rowsDataUpload[index]?.time_to != ""
      ) {
        let ninfo = nightShiftTimeCheck("first");
        console.log("#LK ninfo 1", rowsData[index]);

        if (rowsDataUpload[index]?.hjobtype !== "") {
          console.log("#LK in info", rowsDataUpload[index]?.hjobtype);
          // let prev = { ...rowsInput[index] };
          // handleChangeHizard(index, { value: rowsData[ index ]?.hjobtype });

          // updateHzRow(rowsInput, prev);
        }
        console.log("#LK next", ninfo);

        if (ninfo.status) {
          // appendNDIFRow(ninfo.shift,ninfo.info,'first');
          nightDiffObj["firstShift"] = true;
          nightDiffObj["firstInfo"] = ninfo.info;
        }
      }

    if (
      typeof rowsDataUpload[index]?.time_from === "string" &&
      typeof rowsDataUpload[index]?.time_to === "string" &&
      typeof rowsDataUpload[index]?.time_from1 === "string" &&
      rowsDataUpload[index]?.jobtype === "REG"
    )
      if (
        typeof rowsDataUpload[index]?.time_to1 === "string" &&
        rowsDataUpload[index]?.time_to1 != ""
      ) {
        // nightShiftCheck();
        let ninfo = nightShiftTimeCheck("second");
        if (ninfo.status) {
          nightDiffObj["secondShift"] = true;
          nightDiffObj["secondInfo"] = ninfo.info;
        }
      }

    // if (rowsData[index]?.jobtype == "REG") {
    //   console.log("ntntntntntnt 22222", rowsData[index]);
    //   await appendNDIFRow(nightDiffObj, rowsData[index]?.date);
    // }
    // add To Next Payperiod dipenBhai

    // if (rowsData[index].time_from && rowsData[index].time_to) {
    //   let fr = rowsData[index].time_from.split(":")[0];
    //   let to = rowsData[index].time_to.split(":")[0];
    //   let fr1 = rowsData[index].time_from1.split(":")[0];
    //   let to1 = rowsData[index].time_to1.split(":")[0];
    //   console.log(
    //     "121212 before condition ::: ",
    //     payPeriod,
    //     rowsData[index].date,
    //     to
    //   );
    //   if (rowsData[index].date === payPeriod.EndDate) {
    //     if (
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from) ||
    //       (rowsData[index].time_to > "00:00" &&
    //         rowsData[index].time_to < rowsData[index].time_from)
    //     ) {
    //       console.log(
    //         "121212 in condition  ::: ",
    //         rowsData[index].time_from,
    //         rowsData[index].time_to
    //       );
    //       addTableRowForNextDay(index);
    //     }
    //   }
    // }
    // const splitDatas = splitOt(rowsInput)
    // setRowsData(splitDatas)
  };
  const getpayCode = (acode) => {
    if (acode === undefined) {
      return acode;
    }
    let getcode = paytimeCode.find((i) => {
      return i.label == acode;
    });

    return getcode.code ? parseInt(getcode.code) : "";
  };

  const checkPaycodeAccess = (type, date) => {
    let paycodeList = payCodeFilter(
      paytimeCode,
      paytimeCodeByID,
      getUserRole(userData)
    );

    let validateNDIF = getValidPaycodeOfDay(date, paycodeList);
    console.log("paycodeList 123", paycodeList, type);
    if (type == "NDIF") {
      let filter = validateNDIF?.filter((i) => i.label === type);
      console.log("filter paylist", filter);
      return filter.length ? true : false;
    } else {
      return false;
    }
  };
  const appendNDIFRow2 = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }
          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }
          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (!rowsInput[existAtIndex]?.ID && !rowsInput[existAtIndex]?.ID2) {
            console.log("#NDIF 4 111 delete", rowsInput);

            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          // JSON.stringify(nightObj.firstShift),
          "#",
          // JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          console.log("#NDIF BOTHEXIST ", infos);
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes + info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);
          let info = nightObj.firstInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }

          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("#NDIF Second ", newobj);
          console.log("#NDIF OLD", oldDataCopy);
          console.log("#NDIF Second info", info);
          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }

          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);

        setRowsData([...myNew]);

        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes + info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (nightObj.firstShift) {
          let info = nightObj.firstInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("#mj log B ff");

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: 991022201,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  const appendNDIFRow = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }
          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }
          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (
            !rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.jobtype == "NDIF"
          ) {
            console.log("#NDIF 4 111 delete", rowsInput);

            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          // JSON.stringify(nightObj.firstShift),
          "#",
          // JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          console.log("#NDIF BOTHEXIST ", infos);

          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes + info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }
          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          let info = nightObj.firstInfo;

          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          console.log("#NDIF Second ", newobj);
          console.log("#NDIF OLD", oldDataCopy);
          console.log("#NDIF Second info", info);
          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);

          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }
          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);

        setRowsData([...myNew]);

        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let info = nightObj.secondInfo;
          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;

          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes + info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (nightObj.firstShift) {
          console.log("#mj log B ff");
          let info = nightObj.firstInfo;

          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: 991022201,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  const appendNDIFRow3 = async (nightObj, date) => {
    try {
      if (!checkPaycodeAccess("NDIF", date)) {
        return false;
      }
      console.log("#NDIF 1--", date, nightObj);

      const rowsInput = [...rowsData];
      let rowId = rowsInput[index]["id"];

      // let rowsInput = [ ...rowsData ];
      // let rowId = rowsInput[index]['id'];
      if (!nightObj.firstShift && !nightObj.secondShift) {
        let existAtIndex = rowsData.findIndex((i) => {
          return i.parentIndex == rowId && i.jobtype == "NDIF";
        });

        let oldDataCopyNew = rowsData[existAtIndex];
        console.log("#NDIF 2 existAtIndex", existAtIndex);

        if (existAtIndex !== -1) {
          console.log(
            "#NDIF 3 splicess",
            existAtIndex,
            "##",
            rowsInput[existAtIndex]
          );

          let newobj = { ...rowsInput[existAtIndex] };
          if (
            rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.time_from &&
            !rowsInput[existAtIndex]?.time_to
          ) {
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            setDeletedItemIds(list);
            console.log("#NDIF 111 rowsInput", rowsInput);
          }

          if (
            rowsInput[existAtIndex]?.ID2 &&
            !rowsInput[existAtIndex]?.time_from1 &&
            !rowsInput[existAtIndex]?.time_to1
          ) {
            console.log("#NDIF 1114 rowsInput", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
          }

          console.log("#NDIF 4 111 rowsInput", rowsInput[existAtIndex]);
          // check tomorrow
          if (
            !rowsInput[existAtIndex]?.ID &&
            !rowsInput[existAtIndex]?.ID2 &&
            rowsInput[existAtIndex]?.jobtype === "NDIF"
          ) {
            console.log("#NDIF 4 111 delete 123", rowsInput);
            rowsInput.splice(existAtIndex, 1);
          }
          if (!nightObj.firstShift && !nightObj.secondShift) {
            console.log("#NDIF 4 111 delete", rowsInput);
            let list = deletedItemIds;
            list.push(rowsInput[existAtIndex]?.ID);
            list.push(rowsInput[existAtIndex]?.ID2);
            setDeletedItemIds(list);
            rowsInput.splice(existAtIndex, 1);
          }
          // rowsInput.splice(existAtIndex, 1); //removed by dipen request

          console.log("#NDIF 4 rowsInput", rowsInput);

          setRowsData([...rowsInput], rowsInput);
        }
        return false;
      }

      if (nightObj.firstShift || nightObj.secondShift) {
      } else {
        return false;
      }

      let existAtIndex = rowsData.findIndex((i) => {
        return i.parentIndex == rowId && i.jobtype == "NDIF";
      });

      //remove if more then 1 ndif exist KEEP IT

      // let lengthexist = rowsData.filter((i) => {
      //   return i.parentIndex == rowId && i.jobtype == "NDIF";
      // });

      // console.log("lentth up",lengthexist );
      // if(lengthexist.length>1){
      //   console.log("lentth nd");
      //   let findOtherIndex = rowsData.findIndex((i) => {
      //     return (i.ID == lengthexist[1]['ID'] ) && i.jobtype == "NDIF";
      //   });
      //   if(findOtherIndex){
      //     rowsData.splice( findOtherIndex,1);
      //     let list = deletedItemIds;
      //     list.push(lengthexist[1]['ID']);
      //     setDeletedItemIds(list);
      //     console.log("delete id lentth",lengthexist[1]['ID']);
      //   }

      //   console.log("lentth inde",findOtherIndex,"###",lengthexist[1]['ID']);
      // }

      //end of remove

      let oldDataCopy = rowsData[existAtIndex];
      console.log("OLD COPY index", existAtIndex, "****", oldDataCopy);
      let OtherforMasterIndex = rowsData.filter((i) => {
        return i.parentIndex == rowId;
      });

      console.log(rowsInput, "!#!", index, "#NDIF existAtIndex", existAtIndex);

      if (parseInt(existAtIndex) !== -1) {
        console.log("#NDIF Find index", nightObj);

        let myNew = [...rowsData];
        let newobj = { ...myNew[existAtIndex] };
        console.log(
          "#NDIF CHECK ",
          JSON.stringify(nightObj.firstShift),
          "#",
          JSON.stringify(nightObj.secondShift),
          "DD",
          nightObj.firstShift
        );

        if (nightObj.firstShift && nightObj.secondShift) {
          let infos = nightObj.firstInfo;
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = infos.totalminutes + info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 0", rate, payInfo, newobj);

          console.log("#NDIF BOTHEXIST ", infos);
          let totalMins = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;

          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.secondShift && nightObj.firstShift) {
          console.log("#NDIF First ", newobj);

          // if (newobj?.ID && !info.start && !info.end) {
          //   let list = deletedItemIds;
          //   list.push(newobj.ID);
          //   setDeletedItemIds(list);
          // }
          // if (newobj?.ID2 && !info.start && !info.end) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(newobj.ID2);
          //   console.log("Lsit Deleeted aaaa", list);
          //   setDeletedItemIds(list);
          // }
          console.log("Lsit Deleeted aaaa Olde", oldDataCopy);
          if (oldDataCopy && oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa 1", list);
            setDeletedItemIds(list);
          }

          let info = nightObj.firstInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 1", rate, payInfo, newobj);
          console.log("#info first", info);
          console.log("#info first old data copy", oldDataCopy);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";

          // newobj["ID"] = oldDataCopy && oldDataCopy.ID  ? oldDataCopy.ID : oldDataCopy.ID2;
          newobj["ID"] = oldDataCopy && oldDataCopy.ID ? oldDataCopy.ID : null;
          newobj["ID2"] = null;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["parentIndex"] = info.parentIndex ? info.parentIndex : rowId;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          let info = nightObj.secondInfo;
          console.log("#NDIF Second ", newobj);
          console.log("#NDIF OLD", oldDataCopy);
          console.log("#NDIF Second info", info);
          console.log("#NDIF Second info", newobj?.ID, oldDataCopy?.ID);
          // if (oldDataCopy && oldDataCopy?.ID && !nightObj.firstShift) {
          //   // not tested
          //   let list = deletedItemIds;
          //   list.push(oldDataCopy.ID);
          //   console.log("Lsit Deleeted aaaa 2", list);
          //   setDeletedItemIds(list);
          // }
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 2", rate, payInfo, newobj);

          if (oldDataCopy?.ID2 && !nightObj.secondShift) {
            // not tested
            let list = deletedItemIds;
            list.push(oldDataCopy.ID2);
            console.log("Lsit Deleeted aaaa", list);
            setDeletedItemIds(list);
          }
          newobj["ID2"] =
            oldDataCopy.ID2 && oldDataCopy.time_from1 && oldDataCopy.time_to1
              ? oldDataCopy.ID2
              : oldDataCopy.ID; // I have to in case of new row
          newobj["time_from"] = "";
          newobj["time_to"] = "";
          newobj["ID"] = null;
          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          // newobj[ "parentIndex" ] = rowId;
          newobj["parentIndex2"] = info.parentIndex2
            ? info.parentIndex2
            : rowId;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
          console.log("#NDIF M");
          if (newobj[existAtIndex].ID) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID);
            console.log("Lsit Deleeted aaaa 3", list);
            setDeletedItemIds(list);
          }
          if (newobj[existAtIndex].ID2) {
            let list = deletedItemIds;
            list.push(newobj[existAtIndex].ID2);
            console.log("Lsit Deleeted aaaa 4", list);
            setDeletedItemIds(list);
          }
        }

        myNew[existAtIndex] = newobj;
        console.log("#NDIF NEWOBJ ", newobj);
        setRowsData([...myNew]);
        console.log("#mj newobj newval", myNew);
        // setRowsData([...myNew]);
      } else {
        let newobj = { ...rowsInput[index] };
        let insertAt =
          OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
        console.log("#mj ADD", insertAt);
        newobj["jobtype"] = "NDIF";
        newobj["ID"] = null;
        newobj["ID2"] = null;
        newobj["code"] = getpayCode("NDIF");

        if (nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ffss");
          let infos = nightObj.firstInfo;
          newobj["time_from"] = infos.start;
          newobj["time_to"] = infos.end;

          let info = nightObj.secondInfo;
          let totalMin = infos.totalminutes + info.totalminutes;
          let hrCalc = `${Math.floor(totalMin / 60)}:${totalMin % 60}`;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = newobj.mn + newobj.mn1;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 3", rate, payInfo, newobj);

          newobj["time_from1"] = info.start;
          newobj["time_to1"] = info.end;
          newobj["hrs"] = HourMinFormat(hrCalc);
          newobj["mn"] = infos.totalminutes;
          newobj["mn1"] = info.totalminutes;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (nightObj.firstShift) {
          console.log("#mj log B ff");
          let info = nightObj.firstInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 4", rate, payInfo, newobj);

          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else if (!nightObj.firstShift && nightObj.secondShift) {
          console.log("#mj log B ss");
          let info = nightObj.secondInfo;
          const rate = getRateForDate(amountArray, newobj.date, price);
          let totalmins = info.totalminutes;
          let payInfo = getCalculatedAmtMin(totalmins, newobj.jobtype, rate);
          console.log("adsfjadksf 5", rate, payInfo, newobj);

          newobj["time_from1"] = "";
          newobj["time_to1"] = "";
          newobj["time_from"] = info.start;
          newobj["time_to"] = info.end;
          newobj["hrs"] = HourMinFormat(`${info.hours}:${info.minutes}`);
          newobj["mn"] = info.totalminutes;
          newobj["mn1"] = 0;
          newobj["ole2"] = false;
          newobj["ole1"] = false;
          newobj["Rate"] = rate;
          newobj["payAmount"] = payInfo;
        } else {
        }

        newobj["parentIndex"] = rowId;
        newobj["id"] = KeyGen(7);

        if (
          rowsInput[existAtIndex]?.ID &&
          !rowsInput[existAtIndex]?.time_from &&
          !rowsInput[existAtIndex]?.time_to
        ) {
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID);
          setDeletedItemIds(list);
          console.log("#NDIF 111 rowsInput", rowsInput);
        }
        if (
          rowsInput[existAtIndex]?.ID2 &&
          !rowsInput[existAtIndex]?.time_from1 &&
          !rowsInput[existAtIndex]?.time_to1
        ) {
          console.log("#NDIF 1114 rowsInput", rowsInput);
          let list = deletedItemIds;
          list.push(rowsInput[existAtIndex]?.ID2);
          setDeletedItemIds(list);
        }
        // rowsInput.push(newobj);
        rowsInput.splice(insertAt, 0, newobj);
        setRowsData([...rowsInput], rowsInput);
        // setTimeout(() => {
        //   console.log("#mj timeout", rowsData);
        // }, 3000);
        // let ne = addAfter(rowsInput, insertAt,newobj)
        // setRowsData();
      }
    } catch (error) {
      console.log("#NDIF ERR", error.message);
    }
    /*
    
    if (type == 'clear_first') {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    if (type == 'clear_second' && !localtion) {
      let existAtIndex = rowsData.findIndex((i) => { return (i.parentIndex == index && i.jobtype == 'NDIF') });
      if (existAtIndex != -1) {
        console.log('#mj next row check remove');
        let newobj = { ...rowsInput[ existAtIndex ] };
        rowsInput.splice( existAtIndex,1);
     
        setRowsData([...rowsInput],rowsInput);
      }
      
      return false;
    }
    

  console.log('#mj info',info);
  
    let existAtIndex = rowsData.findIndex((i) => {  return (i.parentIndex == index && i.jobtype=='NDIF') });
    console.log('#mj checkNFDIndex',existAtIndex);
    
    let OtherforMasterIndex = rowsInput.filter((i) => {  return i.parentIndex === index });
     

        
    if (existAtIndex !== -1) { //old index find
      console.log('UPDATE');
      
      let newobj = { ...rowsInput[existAtIndex] };
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
      
      console.log('newobj',newobj);
      rowsInput[ existAtIndex ] = newobj;
      
      console.log('newobj newval',rowsInput);

       setRowsData([...rowsInput],rowsInput);
    } else {
     
      let newobj = { ...rowsInput[ index ] };
      let insertAt = OtherforMasterIndex > -1 ? OtherforMasterIndex + 1 : index + 1;
      console.log('ADD',insertAt);
      newobj[ 'jobtype' ] = 'NDIF';
      newobj[ 'code' ] = getpayCode("NDIF");
      if (type == 'first') {
        newobj[ 'time_from' ] = info.start;
        newobj[ 'time_to' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      } else {
        newobj[ 'time_from1' ] = info.start;
        newobj[ 'time_to1' ] = info.end;
        newobj[ 'hrs' ] = `${info.hours}:${info.minutes}`;
      }
       
      newobj[ 'parentIndex' ] = index;
      let ne = addAfter(rowsInput, insertAt,newobj)
      setRowsData(ne);
    }
   */

    // const rowsInput = {
    //   jobtype: "NDIF",
    //   date: rowsData[index]?.date,
    //   // time_from: rowsData[index]?.time_from,
    //   time_from:
    //     rowsData[index]?.time_from.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from
    //       : "18:00",
    //   time_to: rowsData[index]?.time_to,
    //   time_from1: rowsData[index]?.time_from1
    //     ? rowsData[index]?.time_from1.slice(0, 2) >= 18
    //       ? rowsData[index]?.time_from1
    //       : "18:00"
    //     : rowsData[index]?.time_from1,
    //   time_to1: rowsData[index]?.time_to1,
    //   // time_from1: moment(new Date()),
    //   // time_to1: moment(new Date()),
    //   hrs: `${sumHours}:${("0" + sumMinutes).slice(-2)}`,
    //   jobOrder: 991022201,
    //   code: getpayCode("NDIF"),
    //   parentIndex: index
    // };
    // let isRowExist = rowsData.find((i) => {
    //   return i.parentIndex == index;
    // });

    // console.log(checkNFDIndex,'checkNFDIndex',isRowExist,'#',index);

    // if (isRowExist) {
    //   const groupedINDX = rowsData.findIndex((i) => {
    //     return i.parentIndex == index;
    //   });
    //   let newRowsData = [...rowsData];
    //   newRowsData[groupedINDX] = rowsInput;
    //   setRowsData(newRowsData);
    // } else {
    //   setRowsData([...rowsData, rowsInput]);
    // }
  };

  console.log("Dasdadadaa", deletedItemIds);

  const applyTKPaycode = (paycode, code, multi) => {
    let inputData = rowsData;
    let orgData = [...rowsData];
    let alreayExist = inputData.filter((i) => [paycode].includes(i.jobtype));

    if (alreayExist.length) {
      orgData = inputData.filter((i) => ![paycode].includes(i.jobtype));
      setRowsData(orgData);

      let DeletedID = inputData
        .filter((i) => [paycode].includes(i.jobtype))
        .map((i) => i.ID);
      if (DeletedID) {
        DeletedID = DeletedID.concat(deletedItemIds);
        setDeletedItemIds(DeletedID);
      }
      setIsLoading(false);
      return false;
    }
    let data = inputData.filter((i) => ["REG", "O/T1.5"].includes(i.jobtype));

    data.map((i, index) => {
      let newOBJ = {
        ...i,
        jobtype: paycode,
        code: code,
        ID: null,
        parentIndex: i.id,
        payAmount: i.payAmount * parseFloat(multi),
        id: KeyGen(6),
      };
      let findDataIndex = orgData?.findIndex(
        (item) => item.ID == i.ID && item.id == i.id
      );
      orgData.splice(findDataIndex + 1, 0, newOBJ);
    });
    console.log(orgData, "adskksjklsklmacss");
    setTimeout(() => {
      console.log(orgData, "adskksjklsklmacss1231312");
      setRowsData(orgData);
      setIsLoading(false);
    }, 1000);
  };

  const UpdateTimesheetRulesAfterLoad = () => {
    // return true;
    let inputData = rowsData;
    let orgData = inputData;
    if (!userInfo) {
      return;
    }
    console.log("ALL RULE", userInfo);
    console.log("ALL RULE getEmpTag", getEmpTag);

    // Tag = Public Health Social Worker
    // Dept = DPHSS
    if (
      checkEmpDepartment(userInfo, "1725") &&
      checkEmpTag(getEmpTag, "DPHSSPHSOCWORKER")?.status
    ) {
      // Dept : GBHWC / Tag : "GBWHC Technician"
      let alreayExist = inputData.filter((i) => ["PHSW25"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG"].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "PHSW25",
          code: "128",
          ID: null,
          payAmount: i.payAmount * getMulti(paytimeCode, 128),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ);
      });
      setRowsData(orgData);
    }

    // Tag = Public Health Nurse
    // Dept = DPHSS
    else if (
      checkEmpDepartment(userInfo, "1725") &&
      checkEmpTag(getEmpTag, "DPHSSPHNURSE")?.status
    ) {
      // Dept : GBHWC / Tag : "GBWHC NURSING SERVICES"
      let alreayExist = inputData.filter((i) => ["DPH15"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG", "O/T1.5"].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "DPH15",
          code: "125",
          ID: null,
          parentIndex: i.id,
          id: KeyGen(6),
          payAmount: i.payAmount * getMulti(paytimeCode, 125),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ);
      });
      setRowsData(orgData);
    }

    // Tag = GBWHC Technician
    else if (
      checkEmpDepartment(userInfo, "2330A") &&
      checkEmpTag(getEmpTag, "GBHWTECHNICIAN")?.status
    ) {
      // Dept : GBHWC / Tag : "GBWHC Technician"
      let alreayExist = inputData.filter((i) => ["H-8"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG"].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "H-8",
          code: "11",
          ID: null,
          payAmount: i.payAmount * getMulti(paytimeCode, 11),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ);
      });
      setRowsData(orgData);
      console.log("findDatdad iDnex", inputData, orgData);
    }

    // Dept : GBHWC
    // Tag : "GBWHC Nurse"
    else if (
      checkEmpDepartment(userInfo, "2330A") &&
      checkEmpTag(getEmpTag, "GBHWCNURSE")?.status
    ) {
      console.log(
        "ALL RULE Inside",
        checkEmpTag(getEmpTag, "GBHWCNURSE")?.status,
        getEmpTag
      );

      setOTHours(8);
      setOTMinutes(480);
      setOTType("PD");

      let alreayExist = inputData.filter((i) => ["GB25"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG"].includes(i.jobtype));
      data?.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "GB25",
          code: "126",
          ID: null,
          payAmount: i.payAmount * getMulti(paytimeCode, 126),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ);
      });

      let weekendWork = inputData.filter((i) => {
        return (
          i.jobtype == "REG" &&
          ["Saturday", "Sunday"].includes(moment(i.date).format("dddd"))
        );
      });
      if (weekendWork.length) {
        weekendWork?.map((i, index) => {
          let newOBJ = {
            ...i,
            jobtype: "NRSSUN",
            code: "36",
            ID: null,
            payAmount: i.payAmount * getMulti(paytimeCode, 36),
          };
          let findDataIndex = inputData?.findIndex(
            (item) => item.ID == i.ID && item.id == i.id
          );
          orgData.splice(findDataIndex + 1, 0, newOBJ);
        });
      }
      // Tag : "Nurse Certified"

      if (checkEmpTag(getEmpTag, "GBHWCNURCERTIFIED")?.status) {
        let data = inputData.filter((i) => ["REG"].includes(i.jobtype));
        data?.map((i, index) => {
          let newOBJ = {
            ...i,
            jobtype: "NRSCDP",
            code: "49",
            ID: null,
            payAmount: i.payAmount * getMulti(paytimeCode, 49),
          };
          let findDataIndex = inputData?.findIndex(
            (item) => item.ID == i.ID && item.id == i.id
          );
          orgData.splice(findDataIndex + 1, 0, newOBJ);
        });
      }
      setRowsData(orgData);

      console.log("findDatdad iDnex", inputData, orgData);
    } else if (
      checkEmpDepartment(userInfo, "1201") &&
      checkEmpTag(getEmpTag, "LE43WWEEK")?.status
    ) {
      // Dept : GPD / Tag : "Low Enforcement"


      setOTHours(43);
      setOTMinutes(2580);
      
      // setOTHours(8);
      // setOTMinutes(480);
      setOTType("PPEPW43");
      let alreayExist = inputData.filter((i) => ["H-10"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG",''].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "H-10",
          code: "12",
          ID: null,
          parentIndex: i.id,
          id: KeyGen(6),
          payAmount: i.payAmount * getMulti(paytimeCode, 12),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ); // add record
      });
      setRowsData(orgData);
      setRowsDataUpload(orgData);
      console.log("findDatdad iDnex", inputData, orgData);
    } else if (
      checkEmpDepartment(userInfo, "9910") &&
      checkEmpTag(getEmpTag, "GSWAEXSANREEN")?.status
    ) {
      // Dept : GSWA / Tag : "GSWAEXSANREEN" = Exposed to Haz Sanitation Related Env
      let alreayExist = inputData.filter((i) => ["H-8"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG"].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "H-8",
          code: "11",
          ID: null,
          parentIndex: i.id,
          id: KeyGen(6),
          payAmount: i.payAmount * getMulti(paytimeCode, 11),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ); // add record
      });
      setRowsData(orgData);
      console.log("findDatdad iDnex", inputData, orgData);
    } else if (
      checkEmpDepartment(userInfo, "3201") &&
      checkEmpTag(getEmpTag, "LE43WWEEK")?.status
    ) {
      // Dept : C & Q - DIRECTOR'S OFFICE / Tag : "Low Enforcement" = Exposed to Haz Sanitation Related Env
      let alreayExist = inputData.filter((i) => ["H-10"].includes(i.jobtype));
      if (alreayExist.length) {
        return false;
      }
      let data = inputData.filter((i) => ["REG"].includes(i.jobtype));

      data.map((i, index) => {
        let newOBJ = {
          ...i,
          jobtype: "H-10",
          code: "12",
          ID: null,
          parentIndex: i.id,
          id: KeyGen(6),
          payAmount: i.payAmount * getMulti(paytimeCode, 12),
        };
        let findDataIndex = inputData?.findIndex(
          (item) => item.ID == i.ID && item.id == i.id
        );
        orgData.splice(findDataIndex + 1, 0, newOBJ); // add record
      });
      setRowsData(orgData);
      console.log("findDatdad iDnex", inputData, orgData);
    } else if (
      checkEmpDepartment(userInfo, "9910") &&
      checkEmpTag(getEmpTag, "GSWACUSSERV")?.status
    ) {
      // Dept : GSWA / Tag : "GSWACUSSERV" = GSWA and Customer Service Rep
      let alreayExist = inputData.filter((i) => ["H-8"].includes(i.jobtype));
      if (alreayExist.length) {
        //return false;
      }

      setHelprCode("9910GSWACUSSERV");
    } else if (
      checkEmpDepartment(userInfo, "20", true) &&
      checkEmpTag(getEmpTag, "LE43WWEEK")?.status
    ) {
      // Dept : DYA / Tag : "LE43WWEEK" = Law Enforcement 43 Work Week
      setOTHours(43);
      setOTMinutes(2580);
      setOTType("PPEPW43");
      setHelprCode("20LE43WWEEK");
    } else if (
      checkEmpDepartment(userInfo, "20", true) &&
      checkEmpTag(getEmpTag, "DYASWORKER")?.status
    ) {
      // Dept : DYA / Tag : "DYASWORKER" =DYA Social Worker
      setOTHours(40);
      setOTMinutes(2400);
      setOTType("PPEPW40");
      setHelprCode("20DYASWORKER");
    } else if (
      checkEmpDepartment(userInfo, "1309", true) &&
      checkEmpTag(getEmpTag, "LE43WWEEK")?.status
    ) {
      // Dept : DOC / Tag : "LE43WWEEK" = Law Enforcement 43 Work Week
      setOTHours(43);
      setOTMinutes(2580);
      setOTType("PPEPW43");
      setHelprCode("1309LE43WWEEK");
    } else if (
      checkEmpDepartment(userInfo, "4298", true) &&
      checkEmpTag(getEmpTag, "GFD106WP")?.status
    ) {
      // Dept :  GFD - GUAM FIRE DEPARTMENT / Tag : "GFD106WP" = GFD 106 Work Period
      setOTHours(106);
      setOTMinutes(6360);
      setOTType("PPE2W106");
      setHelprCode("4298GFD106WP");
    }

    setRulesApplied(true);
    // setHelprCode("4298GFD106WP");
  };

  const getHelprCode = (helprCode) => {
    let htmlCode = { title: null, description: null };
    let userinfo = {
      name: userInfo?.EmployeeName,
      ID: userInfo?.ID,
    };

    console.log("ssjkshjksjkhds", helprCode);
    if (helprCode == "9910GSWACUSSERV") {
      let isExist = rowsData.find((i) => i.code == 11);
      htmlCode = {
        title: <span>Tag = GSWA and Customer Service Rep</span>,
        description: (
          <span>
            This Employee #{userinfo?.ID}
            <b> {userinfo?.name} </b> you can assign <b>H-8</b> mannually
            <br />{" "}
            <Button
              onClick={() => {
                setIsLoading(true);
                applyTKPaycode("H-8", "11", 0.08);
              }}
              variant="contained"
              // disabled={check || hasError}
              className="mt-1  "
              size="small"
              style={{
                color: "#d0efe9",
              }}
            >
              {isExist ? (
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  style={{ marginRight: "10px", fontSize: "16px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ marginRight: "10px", fontSize: "16px" }}
                />
              )}
              <Hidden smDown>
                <Typography style={{ fontSize: "14px" }}>Apply H-8</Typography>
              </Hidden>
            </Button>
          </span>
        ),
      };
    }

    if (helprCode == "20LE43WWEEK") {
      let isExist = rowsData.find((i) => i.code == 12);
      let isExist1 = rowsData.find((i) => i.code == 68);

      htmlCode = {
        title: <span>Tag = Law Enforcement 43 Work Week</span>,
        description: (
          <span>
            This For Employee #{userinfo?.ID} {userinfo?.name}
            <div>
              If When Employee Not On Compound use H-10 <br />
              If When Employee On Compound DIFF25
              <br />
              <br />{" "}
              <Button
                onClick={() => {
                  setIsLoading(true);
                  applyTKPaycode("H-10", "12", 0.1);
                }}
                disabled={isExist1 ? true : false}
                variant="contained"
                // disabled={check || hasError}
                className="mt-1"
                size="small"
                style={{
                  color: "#d0efe9",
                }}
              >
                {isExist ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                <Hidden smDown>
                  <Typography style={{ fontSize: "14px" }}>
                    Apply H-10
                  </Typography>
                </Hidden>
              </Button>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  applyTKPaycode("DIFF25", "68", 0.35);
                }}
                disabled={isExist ? true : false}
                variant="contained"
                // disabled={check || hasError}
                className="mt-1"
                size="small"
                style={{
                  color: "#d0efe9",
                  marginLeft: "20px",
                }}
              >
                {isExist1 ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                <Hidden smDown>
                  <Typography dis style={{ fontSize: "14px" }}>
                    Apply DIFF25
                  </Typography>
                </Hidden>
              </Button>
            </div>
          </span>
        ),
      };
    }

    if (helprCode == "4298GFD106WP") {
      let isExist = rowsData.find((i) => i.code == 51);
      htmlCode = {
        title: <span>Tag = GFD 106 Work Period </span>,
        description: (
          <span>
            This For Employee #{userinfo?.ID} {userinfo?.name}
            <div>
              When Employee on Ambulance Only 51 - EMT-15%
              <br />
              EMT - Cert must be Active
              <br />{" "}
              <Button
                onClick={() => {
                  setIsLoading(true);
                  applyTKPaycode("EMT", "51", 0.15);
                }}
                variant="contained"
                // disabled={check || hasError}
                className="mt-1 "
                size="small"
                style={{
                  color: "#d0efe9",
                }}
              >
                {isExist ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                <Hidden smDown>
                  <Typography style={{ fontSize: "14px" }}>
                    Apply EMT-10%
                  </Typography>
                </Hidden>
              </Button>
            </div>
          </span>
        ),
      };
    }

    if (helprCode == "20DYASWORKER") {
      let isExist = rowsData.find((i) => i.code == 12);
      htmlCode = {
        title: <span>Tag = DYA Social Worker </span>,
        description: (
          <span>
            This For Employee #{userinfo?.ID} {userinfo?.name}
            <div>
              If When Employee Not On Compound use H-10 <br />
              <br />{" "}
              <Button
                onClick={() => {
                  setIsLoading(true);
                  applyTKPaycode("H-10", "12", 0.1);
                }}
                variant="contained"
                // disabled={check || hasError}
                className="mt-1 "
                size="small"
                style={{
                  color: "#d0efe9",
                }}
              >
                {isExist ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                <Hidden smDown>
                  <Typography style={{ fontSize: "14px" }}>
                    Apply H-10
                  </Typography>
                </Hidden>
              </Button>
            </div>
          </span>
        ),
      };
    }

    if (helprCode == "1309LE43WWEEK") {
      let isExist = rowsData.find((i) => i.code == 12);
      htmlCode = {
        title: <span>Tag = Law Enforcement 43 Work Week </span>,
        description: (
          <span>
            This For Employee #{userinfo?.ID} {userinfo?.name}
            <div>
              If When Employee Not On Compound use H-10 <br />
              <br />{" "}
              <Button
                onClick={() => {
                  setIsLoading(true);
                  applyTKPaycode("H-10", "12", 0.1);
                }}
                variant="contained"
                // disabled={check || hasError}
                className="mt-1 "
                size="small"
                style={{
                  color: "#d0efe9",
                }}
              >
                {isExist ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                <Hidden smDown>
                  <Typography style={{ fontSize: "14px" }}>
                    Apply H-10
                  </Typography>
                </Hidden>
              </Button>
            </div>
          </span>
        ),
      };
    }
    return htmlCode;
  };

  useEffect(() => {
    if (isTableView !== "upload") {
      if (rowsData.length && userId && getEmpTag.length) {
        UpdateTimesheetRulesAfterLoad(rowsData);
      }
    }
  }, [rowsData, userId, getEmpTag, isTableView]);

  useEffect(() => {
    if (employeeAuthorizedPayTimeCodesList.length && OTType && rulesApplied) {
      // window.alert(rowsData.length+"CALL"+OTType)
      // splitOt(rowsData);
    }
  }, [employeeAuthorizedPayTimeCodesList, OTType, rulesApplied]);
  const nightShiftTimeCheck = (shift) => {
    let startTime = moment(rowsData[index]?.time_from, "HH:mm");
    let endTime = moment(rowsData[index]?.time_to, "HH:mm");
    if (shift !== "first") {
      startTime = moment(rowsData[index]?.time_from1, "HH:mm");
      endTime = moment(rowsData[index]?.time_to1, "HH:mm");
    }

    let nightStart = moment("18:00", "HH:mm");
    let nightEnd = moment("06:00", "HH:mm");
    let isNight = true;
    let totalminutes = 0;
    let totalHr = 0;
    let nightTime = {};
    if (
      (moment(startTime, "HH:mm") > nightStart &&
        moment(startTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(startTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(startTime, "HH:mm") < moment("06:00", "HH:mm")) ||
      (moment(endTime, "HH:mm") > moment("18:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("23:59", "HH:mm")) ||
      (moment(endTime, "HH:mm") >= moment("00:00", "HH:mm") &&
        moment(endTime, "HH:mm") <= moment("06:00", "HH:mm")) ||
      (moment(startTime, "HH:mm") > moment(endTime, "HH:mm") &&
        moment(endTime, "HH:mm") >= moment("06:00", "HH:mm"))
    ) {
      if (startTime >= endTime) {
        if (startTime >= endTime && startTime <= nightEnd) {
          let durationBefore = moment.duration(nightEnd.diff(startTime));
          console.log("#mj HHBefore", durationBefore.asHours());
        }
        console.log("#mj Contains ");
        nightStart = nightStart >= startTime ? nightStart : startTime;
        let duration = moment.duration(
          moment("24:00", "HH:mm").diff(nightStart)
        );
        totalminutes = totalminutes + parseInt(duration.asMinutes());
        totalHr = totalHr + parseInt(duration.asHours());

        if (endTime >= nightEnd) {
          endTime = nightEnd;
        }
        let durationAfter = moment.duration(
          endTime.diff(moment("00:00", "HH:mm"))
        );
        totalminutes = totalminutes + parseInt(durationAfter.asMinutes());
        totalHr = totalHr + parseInt(durationAfter.asHours());

        console.log("#mj totalminutes", totalminutes);
        nightTime["start"] = nightStart;
        nightTime["end"] = endTime;
      } else {
        if (startTime <= endTime) {
          //  let sectionA = endTime >= nightStart ? endTime: nightStart ;
          // console.log(endTime,sectionA,'#mj sAAA',startTime);
          if (startTime <= nightEnd && endTime <= nightEnd) {
            let duration = moment.duration(endTime.diff(startTime));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

            console.log("#mj HHAA#", duration.asHours());
            nightTime["start"] = startTime;
            nightTime["end"] = endTime;
          }
          if (startTime <= nightEnd && endTime > nightEnd) {
            let duration = moment.duration(nightEnd.diff(startTime));
            console.log("#mj HHAA", duration.asHours());
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());
            nightTime["start"] = startTime;
            nightTime["end"] = nightEnd;
          }

          if (startTime <= nightEnd && endTime > nightStart) {
            /// equal removed
            let duration = moment.duration(endTime.diff(nightStart));
            totalminutes = totalminutes + parseInt(duration.asMinutes());
            totalHr = totalHr + parseInt(duration.asHours());

            console.log("#mj HHAA11", duration.asHours());
            nightTime["start"] = nightStart;
            nightTime["end"] = endTime;
          } else {
            if (endTime > nightStart && startTime < endTime) {
              if (startTime >= nightStart) {
                nightStart = startTime;
              }
              let duration = moment.duration(endTime.diff(nightStart));

              totalminutes = totalminutes + parseInt(duration.asMinutes());
              totalHr = totalHr + parseInt(duration.asHours());
              console.log(
                endTime,
                "@@",
                startTime,
                "#mj Plain",
                duration.asHours()
              );
              nightTime["start"] = nightStart;
              nightTime["end"] = endTime;
            }
          }
        } else {
          nightStart = nightStart >= startTime ? nightStart : startTime;
          let duration = moment.duration(endTime.diff(nightStart));
          console.log("#mj HHBB", duration.asHours());
          totalminutes = totalminutes + parseInt(duration.asMinutes());
          totalHr = totalHr + parseInt(duration.asHours());
        }

        /*
        if (startTime <= endTime) {
          nightStart = startTime;
        } else {
          nightStart = nightStart >= startTime?nightStart:startTime;
        }

        endTime = endTime >= nightEnd ? nightEnd:endTime;
       
        let duration = moment.duration(endTime.diff(nightStart));
        if (endTime >= nightStart) {
          let durationNext = moment.duration(endTime.diff(nightStart));
          console.log('#mj HH$',durationNext.asHours());
        } */
      }

      // if (duration.asHours() < 0) {
      //   const endTime3 = moment(rowsData[index]?.time_to1, "HH:mm").add(1, "day");
      //   duration1 = moment.duration(endTime3.diff(startTime1));
      // }
      console.log(
        "#mj night diff",
        moment(nightTime.start).format("HH:mm"),
        ":",
        moment(nightTime.end).format("HH:mm")
      );
      console.log("#mj totalminutes", totalminutes);
      console.log("#mj totalhours", floor(totalminutes / 60));
      console.log("#mj totalhours mm", totalminutes % 60);
      let info = {
        start: moment(nightTime.start).format("HH:mm"),
        end: moment(nightTime.end).format("HH:mm"),
        hours: floor(totalminutes / 60),
        minutes: totalminutes % 60,
        totalminutes: totalminutes,
      };
      // appendNDIFRow(shift,info)
      return { status: true, shift: shift, info: info };
    } else {
      // appendNDIFRow(`clear_${shift}`,{})
      isNight = false;
      return { status: false, shift: shift, info: {} };
    }
  };

  const addNDIF = () => {
    const NDIFData = rowsData.filter((i) => i.jobtype == "N/D");

    if (NDIFData?.length) {
      const removeNDIF = rowsData.filter((i) => i.jobtype !== "N/D");
      setRowsData(removeNDIF);

      let DeletedID = rowsData
        .filter((i) => ["N/D"].includes(i.jobtype))
        .map((i) => i.ID);
      if (DeletedID) {
        DeletedID = DeletedID.concat(deletedItemIds);
        setDeletedItemIds(DeletedID);
      }
      return false;
    }
    let orgData = [...rowsData];

    if (rowsData.length) {
      rowsData.map((i, index) => {
        if (i.jobtype == "REG") {
          const time_from = i.time_from;
          const time_to = i.time_to;

          const exipsting = [
            {
              shr: time_from,
              ehr: time_to,
              fromTime: "06:00",
              toTime: "18:00",
            },
          ];

          const result = splitTimeRangeSlots(
            i.time_from,
            i.time_to,
            exipsting,
            i,
            index
          );
          if (result?.splitSlots.length) {
            result?.splitSlots.map((i, index) => {
              let newOBJ = {
                ...i,
                parentIndex: i.id,
              };

              const findINdex = orgData.findIndex((item) => item.id == i.id);

              orgData.splice(findINdex + 1, 0, newOBJ);
              setRowsData(orgData);
            });
          }
        }
      });
    }
  };
  const handleSetHzToHtype = async (arrayInput) => {
    // console.log('taget arrayInput',arrayInput);
    let paytime = paytimeCode.filter((item) => {
      return item.code >= 10 && item.code <= 12;
    });
    let arrayHtype = paytime.map((i) => {
      return i.value;
    });
    let targetItem = arrayInput.filter((i) => {
      return arrayHtype.includes(i.jobtype);
    });

    // if (targetItem.length) {
    //   targetItem.map((j) => {
    //     let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
    //     console.log('taget findAtIndex',index);
    //     arrayInput[ index ][ 'hjobtype' ] = j.jobtype;
    //   })
    // }
    if (targetItem.length) {
      targetItem.map((j) => {
        // let index = arrayInput.findIndex((row) => row.id == j.parentIndex);
        let index = arrayInput.findIndex((row) => row.id == j.id);
        console.log("taget findAtIndex", index);
        // arrayInput[index]["hjobtype"] = j.jobtype;
        arrayInput[index]["jobtype"] = j.jobtype;
      });
    }
    return arrayInput;
  };

  //Dipen
  const loadPreData = async (type) => {
    console.log("Log loadpredata");

    let sdt = payPeriod && payPeriod?.StartDate;
    let edt = payPeriod && payPeriod?.EndDate;
    setIsLoading(true);

    if (sdt === undefined || edt === undefined) {
      ///|| check === false uncommented
      return false;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee/timelog/${userId}/from/${sdt}/to/${edt}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        let createObj = [];
        if (response.data.length) {
          let prevdata = response.data;
          // setEmployeeVerifyFlag(prevdata);

          const sorted = prevdata.sort(
            (a, b) =>
              Date.parse(a.WorkDate) - Date.parse(b.WorkDate) ||
              Date.parse(a.WorkFrom) - Date.parse(b.WorkFrom)
          );
          setOriginalData(sorted);

          // console.log("prevdata--", prevdata);

          // dispatch({
          //   type: "EmployeeProcessPayTimes",
          //   payload: { EmployeeProcessPayTimes: prevdata },
          // });

          let EmployeeProcessPayTimes = sorted;

          if (EmployeeProcessPayTimes.length) {
            let newArray = [];
            let skip = false;

            EmployeeProcessPayTimes.map(async (i, index) => {
              const rate = getRateForDate(
                amountArray,
                moment(i.WorkDate).format("YYYY-MM-DD"),
                price
              );
              let totalMins = i.TotalMinutes;

              let hrCalc = `${Math.floor(totalMins / 60)}:${totalMins % 60}`;
              // newobj[ 'time_from1' ]  = info.start;
              // newobj[ 'time_to1' ]  = info.end;
              // newobj[ 'hrs' ] = HourMinFormat(hrCalc);

              let lableid = `${moment(i.WorkDate).format("YYYY-MM-DD")}${
                i.PayCode
              }${d2Time(i.WorkFrom)}`;
              lableid = RemoveSpaceKeyGen(lableid);

              let payInfo = getCalculatedAmtMin(
                i.TotalMinutes,
                i.PayCode_PayTimeCode ? i.PayCode_PayTimeCode.Abberv : "",
                rate
              );

              const tagCode =
                i?.TagCode?.split(",").length === 1 &&
                !i?.TagCode?.split(",")[0]
                  ? []
                  : i?.TagCode?.split(",").map((item) => {
                      return {
                        value: item,
                        label: item,
                        jobOrder: i.JobOrder,
                      };
                    });
              let assign = {
                // jobtype: i.Typh,
                jobtype: i.PayCode_PayTimeCode
                  ? i.PayCode_PayTimeCode.Abberv
                  : "REG",
                ID: i.ID,
                EmployeeID: i.EmployeeID,
                TagCode: tagCode,
                id: lableid,
                date: moment(i.WorkDate).utc().format("YYYY-MM-DD"),
                time_from: d2Time(i.WorkFrom),
                time_to: d2Time(i.WorkTo),
                time_from1: null,
                time_to1: null,
                Rate: rate,
                payAmount: payInfo,
                isNew: true,
                mn: i.TotalMinutes,
                mn1: 0,
                JObOrdersData: [],
                jobOrder: i.JobOrder,
                code: i.PayCode ? i.PayCode : "1",
                parentIndex: i.RecordNo,
                ignoreadd: true,
                time_to_set: true,
                verify: i.verify ? i.verify : false,
                approvedStatus: i.ApproveStatus,
                approveTimeFrom: i.ApproveFromTime
                  ? moment(i.ApproveFromTime).utc().format("HH:mm")
                  : null,
                approveTimeTo: i.ApproveToTime
                  ? moment(i.ApproveToTime).utc().format("HH:mm")
                  : null,
                approveHrs: CalculateWorkingHR(
                  moment(i.ApproveFromTime).utc().format("HH:mm"),
                  moment(i.ApproveToTime).utc().format("HH:mm"),
                  moment(i.WorkDate).format("YYYY-MM-DD"),
                  i.ApproveMinutes
                ),
                hrs: CalculateWorkingHR(
                  moment(i.WorkFrom).utc().format("HH:mm"),
                  moment(i.WorkTo).utc().format("HH:mm"),
                  moment(i.WorkDate).utc().format("YYYY-MM-DD"),
                  i.TotalMinutes
                ),

                rowstatus: i.ConfirmStatus ? i.ConfirmStatus : "Confirm",
                CreatedDate: i.CreatedDate,
                Status: i.Status,
              };

              // if (
              //   index + 1 < EmployeeProcessPayTimes.length &&
              //   i.PayCode == EmployeeProcessPayTimes[index + 1]["PayCode"] &&
              //   moment(i.WorkDate).format("YYYY-MM-DD") ==
              //     moment(EmployeeProcessPayTimes[index + 1]["WorkDate"]).format(
              //       "YYYY-MM-DD"
              //     ) &&
              //   moment(i.CreatedDate).format("YYYY-MM-DD HH:mm:ss") ==
              //     moment(
              //       EmployeeProcessPayTimes[index + 1]["CreatedDate"]
              //     ).format("YYYY-MM-DD HH:mm:ss") &&
              //   i.code == EmployeeProcessPayTimes[index + 1]["code"]
              // )
              {
                skip = false;

                // assign.ID2 = EmployeeProcessPayTimes[index + 1]["ID"];

                // assign.time_from1 = d2Time(
                //   EmployeeProcessPayTimes[index + 1]["WorkFrom"]
                // );
                // assign.time_to1 = d2Time(
                //   EmployeeProcessPayTimes[index + 1]["WorkTo"]
                // );
                // assign.isNew = false;

                // assign.mn1 = parseInt(
                //   EmployeeProcessPayTimes[index + 1]["TotalHours"]
                // );
                assign.time_to_set = true;
                // assign.parentIndex2 =
                //   EmployeeProcessPayTimes[index + 1]["RecordNo"];
                // assign.hrs = HourMinFormat(
                //   `${Math.floor(
                //     (i.TotalHours +
                //       parseInt(
                //         EmployeeProcessPayTimes[index + 1]["TotalHours"]
                //       )) /
                //       60
                //   )}:${
                //     (i.TotalHours +
                //       parseInt(
                //         EmployeeProcessPayTimes[index + 1]["TotalHours"]
                //       )) %
                //     60
                //   }`
                // );
                // assign.rowstatus = i.ConfirmStatus
                //   ? i.ConfirmStatus
                //   : "Confirm";
                // // assign.hrs = HourFormat(parseInt(i.TotalHours)+parseInt(EmployeeProcessPayTimes[index+1]['TotalHours']));
                newArray.push(assign);
              }
              if (!skip) {
                // newArray.push(assign);
              }
              // else {
              //   if (!skip) {
              //     newArray.push(assign);
              //   }
              //   skip = false;
              // }
            });
            setIsLoading(false);

            let refindArray = await handleSetHzToHtype(newArray);
            const sorted = [...refindArray].sort(
              (a, b) => Date.parse(a.date) - Date.parse(b.date)
            );
            handleDateColor(sorted);
            setSaveData(sorted);
            const mergedArray = [...sorted, ...rowAuthData];
            const isVerifyData = mergedArray.some((item) => item?.verify);

            // if (isVerifyData) {
            setRowsData(mergedArray);
            setRowsDataUpload(mergedArray);
            // setWeekOT([]);
            // splitOTWeek([]);
            // } else {

            const splitRowsData = splitOt(mergedArray);
            // console.log("splitRowsDatasplitRowsData", splitRowsData);
            if (splitRowsData.length) {
              reAssignEmployee(userId)
            }
            
            setRowsData(splitRowsData);
            setRowsDataUpload(splitRowsData);

            // }
          } else {
            setIsLoading(false);
            setRowsData([]);
            setRowsDataUpload([]);
          }
        } else {
          setIsLoading(false);
          setRowsData([]);
          setRowsDataUpload([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.warn("Cannot retrieve user data", error);
      });
  };

  console.log("cjlkscjdscklds", rowsDataUpload);

  // useEffect(() => {
  //   console.log("EmployeeProcessPayTimes", EmployeeProcessPayTimes);
  //   // loadPreData()
  // }, [EmployeeProcessPayTimes]);
  /** 
  useEffect(() => {
    // if (!isloading) {
    //   return false
    // }
     

    console.log('PreviTimesheet ppe',prevPPE);
    

      let sdt = prevPPE && prevPPE.StartDate;
      let edt = prevPPE && prevPPE.EndDate;

      let a = moment(sdt);
      let b = moment(edt);

      let preFilledArray = [];
      let tempindex = 0;
    for (let m = moment(a); m.isBefore(b); m.add(1, "days")) {
      let dateName = m.format("YYYY-MM-DD");
      let dayName = m.format("dddd");
       
      console.log("PreviTimesheet", dateName,dayName);

      let createObject = {
        jobtype: "REG",
        id: KeyGen(7),
        date: dateName,
        // time_from: timePre[ 0 ]?.[ "startTime" ],
        // time_to: timePre[ 0 ]?.[ "endTime" ],
        // time_from1: timePre[ 1 ]?.[ "startTime" ],
        // time_to1: timePre[ 1 ]?.[ "endTime" ],
        hrs: "00:00",
        mn: 0,
        mn1: 0,
        code: 1,
        jobOrder: "991022201",
        hjobtype: "",
        rowstatus: "Confirm",
        time_to_set: true,
        ole1: false,
        ole2: false,
      };
     
    
      } //end of loop
      console.log("Instaz", preFilledArray);


  },[isloading]);
*/
  useEffect(() => {
    // if (templateID && templateTitle) {
    //   renderProfileData();
    // }
    // if (payPeriod && userId && price > 0) {
    //   setRowsData([]);
    //   setTimeout(() => {
    //     loadPreData("AAA");
    //   }, 3000);
    // }
    // getTimeSheetTemplateByID();
    // if (templateID) {
    //   // renderProfileData(); // update for change te
    //   // handlePrefillTimesSlot(templateData);
    // }
    // if (check) {
    // }
  }, [payPeriod, userId, price > 0]);
  useEffect(() => {
    if (
      payPeriod &&
      userId &&
      price > 0 &&
      employeeAuthorizedPayTimeCodesList.length &&
      OTType
    ) {
      setHelprCode(null);
      // setEmployeeAuthorizedPayTimeCodesList([]);
      
      console.log(
        "csjksjksjkcdsjk111222",
        payPeriod,
        userId,
        price,
        employeeAuthorizedPayTimeCodesList,
        OTType
      );
      setRowsData([]);
      setTimeout(() => {
        loadPreData("AAA");
      }, 100);
    }
    // getTimeSheetTemplateByID();
    // if (templateID) {
    //   // renderProfileData(); // update for change te
    //   // handlePrefillTimesSlot(templateData);
    // }
    // if (check) {

    // }
  }, [
    payPeriod,
    userId,
    price > 0,
    employeeAuthorizedPayTimeCodesList,
    OTType,
  ]);

  const getPrefillTimesSlot = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}advancesearch/prefillhours`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      })
      .then((response) => {
        // setPreDayHours(response.data.result);
        console.log("Master responsexxxx", response.data.result);
      })
      .catch((error) => {
        console.warn("Cannot retrieve user data", error);
      });
  };
  // useEffect(() => {
  //    setDeletedItemIds([]);
  // }, []);
  const handlePrefillTimesSlot = (arrSlot) => {
    let tempArray = [];
    arrSlot.map((i) => {
      tempArray.push({
        id: i.ID,
        dayName: i.Day,
        startTime: i.From,
        endTime: i.To,
      });
    });
    console.log("tempArray", tempArray);

    // setPreDayHours(tempArray);
    setRowsData([]);
    setTimeout(() => {
      handlePrefill(true, tempArray);
    }, 1000);
  };
  useEffect(() => {
    //not in use
    console.log("Mrbes41", valueChange);

    let timer = null;
    if (valueChange && valueChange.target?.name) {
      timer = setTimeout(() => {
        console.log("Mrbes41  111", valueChange);
        handleChange(valueChange.index, {
          target: {
            value: valueChange?.target?.value,
            name: valueChange?.target?.name,
          },
        });
        // handleTimeDiffCalculation();
      }, 500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // handleTimeDiffCalculation();
  }, [valueChange]);
  useEffect(() => {
    console.log("Go 4 Update timeTo Mrbes4");
    // handleTimeDiffAll();
    // handleTimeDiff();

    let timer = null;
    if (timeTo) {
      timer = setTimeout(() => {
        console.log("Go 4 Update timeTo Mrbes4  111", timeTo);
        handleTimeDiffCalculation();
        // UpdateTimesheetRulesAfterLoad(rowsData);
      }, 300);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // let contactArray = rowsData;
    // let contactArray = [{ ...sampleData }].concat([...rowsData]);

    // console.log("contactArray 123",contactArray);

    // const data = contactArray?.map((i) => {
    //   let wd = moment(i.date).format("YYYY-MM-DD");
    //   let stdate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_from,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';
    //   let eddate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_to,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';

    //   console.log("stdate eddate",stdate,eddate );

    //   return {
    //     ID: i.id,
    //     start:  stdate,   //moment(i.WorkFrom).format("YYYY-MM-DDTHH:mm:ss"),
    //     end:  eddate,  // moment(i.WorkTo).format("YYYY-MM-DDTHH:mm:ss"),
    //     text: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //     paycode: i.code,

    //     paytype:  i.jobtype,

    //     indexNo:   parseFloat(moment().format("YYMMDDHHmmss")),
    //     barColor: getStatusColorCode(i.rowstatus),
    //     backColor:  getPPEColorCode(i.jobtype),

    //     // JobOrder: i.JobOrder,
    //     authStatus:"Confirm",
    //     bubbleHtml: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //       TotalHours:diifrenceInMinutes(stdate, eddate),
    //       WorkDate:i.date,
    //       WorkFrom:  stdate ,
    //       WorkTo:   eddate,
    //       PayCode_PayTimeCode:{
    //         ID:i.code,
    //         Abberv:i.jobtype
    //       }

    //   };

    // });
    // console.log("data fia 123456", data);

    // setOriginalData(data)
  }, [timeTo]);

  useEffect(() => {
    console.log("Go 4 Update timeTo Mrbes4");
    // handleTimeDiffAll();
    // handleTimeDiff();

    let timer = null;
    if (timeToUpload) {
      timer = setTimeout(() => {
        handleTimeDiffCalculationUpload();
        // UpdateTimesheetRulesAfterLoad(rowsData);
      }, 300);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // let contactArray = rowsData;
    // let contactArray = [{ ...sampleData }].concat([...rowsData]);

    // console.log("contactArray 123",contactArray);

    // const data = contactArray?.map((i) => {
    //   let wd = moment(i.date).format("YYYY-MM-DD");
    //   let stdate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_from,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';
    //   let eddate = moment(
    //     `${moment(i.date).format("YYYY-MM-DD")} ${moment(i.time_to,"HH:mm").format(
    //       "HH:mm:ss"
    //     )}`,
    //     "YYYY-MM-DD HH:mm:ss"
    //   ).format("YYYY-MM-DDTHH:mm:ss")+'.000Z';

    //   console.log("stdate eddate",stdate,eddate );

    //   return {
    //     ID: i.id,
    //     start:  stdate,   //moment(i.WorkFrom).format("YYYY-MM-DDTHH:mm:ss"),
    //     end:  eddate,  // moment(i.WorkTo).format("YYYY-MM-DDTHH:mm:ss"),
    //     text: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //     paycode: i.code,

    //     paytype:  i.jobtype,

    //     indexNo:   parseFloat(moment().format("YYMMDDHHmmss")),
    //     barColor: getStatusColorCode(i.rowstatus),
    //     backColor:  getPPEColorCode(i.jobtype),

    //     // JobOrder: i.JobOrder,
    //     authStatus:"Confirm",
    //     bubbleHtml: `${i.jobtype}-${moment(stdate)
    //       .utc()
    //       .format(" hh:mm A")}:${moment(eddate).utc().format("hh:mm A")}`,
    //       TotalHours:diifrenceInMinutes(stdate, eddate),
    //       WorkDate:i.date,
    //       WorkFrom:  stdate ,
    //       WorkTo:   eddate,
    //       PayCode_PayTimeCode:{
    //         ID:i.code,
    //         Abberv:i.jobtype
    //       }

    //   };

    // });
    // console.log("data fia 123456", data);

    // setOriginalData(data)
  }, [timeToUpload]);

  useEffect(() => {
    setHoursWorked();
  }, [rowsData]);

  const verifyAll = () => {
    let verify = [...rowsData];
    verify = verify.map((i) => {
      return {
        ...i,
        approveTimeFrom: i.time_from,
        approveTimeTo: i.time_to,
        approveHrs: CalculateWorkingHR(i.time_from, i.time_to, i.date, i.mn),
        verifyAllDisable: true,
        verify: true,
      };
    });
    setRowsData(verify);
  };

  const handleApproveAllStatus = (value) => {
    let allStatusApproved = [...rowsData];
    allStatusApproved = allStatusApproved.map((i) => {
      return { ...i, approvedStatus: value };
    });
    setRowsData(allStatusApproved);
  };

  const handleDisabelVerify = () => {
    // for (let i = 0; i < rowsData.length; i++) {
    //   if (rowsData[i].verify === true) {
    //     return true;
    //   }
    // }
    let ind = rowsData.findIndex((i) => {
      return i.verify == false;
    });

    if (ind !== -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleVerifyAllAndSubmit = (data) => {
    console.log("data====>>", data);
  };
  const getJobOrderNov2 = (paycode, date, TagCode) => {
    if (!allAccountNumber?.length) {
      return [{ label: "", value: "" }];
    }
    let allPayoode = getValidPaycodeOfDayv2(
      date,
      jobTypeOptions,
      paytimeCodeSuperVisor,
      paytimeCodeByID
    );

    let findPaycode = allPayoode?.find((i) => i.code == paycode);
    let accountNumber = [];

    let findAccountNo = allAccountNumber?.find(
      (i) => i.label == findPaycode?.AccountID
    );

    if (findAccountNo) {
      accountNumber.push(findAccountNo.value);
    }
    TagCode?.map((i) => {
      let final = tagCodeList?.find((item) => item.value == i.value)?.jobOrder;
      accountNumber.push(final);
    });

    let finalData = accountNumber?.map((i) => {
      return { label: i ? i : "", value: i ? i : "" };
    });
    const employeeAuthorized = employeeAuthorizedPayTimeCodesList?.filter(
      (item) => {
        let Effdate = item.effectDate
          ? moment(item.effectDate).format("YYYY-MM-DD")
          : null;
        let ExpireDate = item.expireDate
          ? moment(item.expireDate).format("YYYY-MM-DD")
          : null;
        if (item.code == paycode && date >= Effdate && date <= ExpireDate) {
          return item;
        }
      }
    );

    const newData = employeeAuthorized.map((item) => {
      return { label: item?.jobOrderNo || "", value: item?.jobOrderNo || "" };
    });
    return removeDuplicatesByKey([...finalData, ...newData], "label");
  };
  useEffect(() => {
    const filterPaycodeList = payCodeFilter(
      paytimeCode,
      paytimeCodeByID,
      getUserRole(userData)
    );
    setJobTypeOptions(filterPaycodeList);
  }, [paytimeCode, paytimeCodeByID, paytimeCodeSuperVisor]);

  const handleSaveVarificationData = (data) => {
    let finalData = rowsData;
    let hasError = rowsData.filter((i) => {
      return i.ole1 || i.ole2;
    });

    if (hasError.length) {
      ShowError(
        "Please Verify Timesheet. It's has issue with overlapping time"
      );
      return false;
    }
    // .filter((i) => i.verify === true);
    console.log("data====>>", data);

    // if (data == "allVerify") {
    //   console.log("allVerify=======");
    //   // finalData?.map((i) => {
    //   //   return {
    //   //     ...i,
    //   //     verify: true,
    //   //   };
    //   // });
    // }

    const tagValue = getEmpTag?.map((i) => i.tagCode)?.join(",");

    finalData = finalData.map((i) => {
      let totalMins = hoursDiffrence(i.approveTimeFrom, i.approveTimeTo);

      const jobOrderData = getJobOrderNov2(i.code, i.date, i?.TagCode)[0]
        ?.value;
      const approvedStatusData = ["supervisor"].includes(getUserRole(userData))
        ? "Approve"
        : i.approvedStatus === null
        ? "pending"
        : i.approvedStatus;
      return {
        ID: i.ID,
        ApproveStatus: approvedStatusData,
        ApproveFromTime: i.approveTimeFrom,
        ApproveToTime: i.approveTimeTo,
        ApproveMinutes: totalMins?.totalMin,
        // verify: i.verify == true ? "1" : "0",
        verify: data == "allVerify" ? "1" : i.verify == true ? "1" : "0",
        EmployeeID: userId,
        WorkDate: i.date,
        PayCode: i.code,
        FMLA: "",
        WorkFrom: i.time_from,
        WorkTo: i.time_to,
        TotalMinutes: i.mn,
        GrossAmount: i.payAmount,
        Rate: i.Rate,
        JobOrder: i.jobOrder == null ? jobOrderData : i.jobOrder,
        Typh: i.jobtype,
        RecordNo: "0",
        Status: i.rowstatus,
        TagCode: tagValue,
        EmployeeAuthorizedPayTypeId: i.EmployeeAuthorizedPayTypeId,
      };
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/approve`,

        {
          isVerifyTimeSheet: data == "allVerify" ? true : false,
          timeSheetData: finalData,
          empId: userId,
          deletedList: deletedItemIds,
          ppe: payPeriod?.ID || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        ShowSucess("Data Saved Successfully");
        dispatch(
          GetEmployeeAuthorizedPayrollData({
            payPeriodId: payPeriod?.ID || "",
            employeeId: userId || "",
          })
        );
        setIsAllVerifyModal(false);
        loadPreData();
        setDeletedItemIds([]);
        setSaveButtonStatus(false);
      })
      .catch((error) => {
        ShowError(error);
        setIsAllVerifyModal(false);
        setDeletedItemIds([]);
        console.warn("Cannot retrieve user data", error);
      });
  };

  const handleSaveVarificationDataUpload = (data) => {
    let finalData = rowsDataUpload;
    let hasError = rowsDataUpload.filter((i) => {
      return i.ole1 || i.ole2;
    });

    if (hasError.length) {
      ShowError(
        "Please Verify Timesheet. It's has issue with overlapping time"
      );
      return false;
    }
    // .filter((i) => i.verify === true);
    console.log("data====>>", data);

    // if (data == "allVerify") {
    //   console.log("allVerify=======");
    //   // finalData?.map((i) => {
    //   //   return {
    //   //     ...i,
    //   //     verify: true,
    //   //   };
    //   // });
    // }

    const tagValue = getEmpTag?.map((i) => i.tagCode)?.join(",");
    finalData = finalData.map((i) => {
      let totalMins = hoursDiffrence(i.approveTimeFrom, i.approveTimeTo);

      const jobOrderData = getJobOrderNov2(i.code, i.date, i?.TagCode)[0]
        ?.value;
      const approvedStatusData = ["supervisor"].includes(getUserRole(userData))
        ? "Approve"
        : i.approvedStatus === null
        ? "pending"
        : i.approvedStatus;
      console.log("sddssfdsfsf", i);
      return {
        ID: i.ID,
        ApproveStatus: approvedStatusData,
        ApproveFromTime: i.approveTimeFrom,
        ApproveToTime: i.approveTimeTo,
        ApproveMinutes: totalMins?.totalMin,
        // verify: i.verify == true ? "1" : "0",
        verify: i.verify == true ? true : false,
        EmployeeID: i.EmployeeID,
        WorkDate: moment(i.date).format("YYYY-MM-DD"),
        PayCode: i.code,
        FMLA: "",
        WorkFrom: i.time_from,
        WorkTo: i.time_to,
        TotalMinutes: i.mn,
        GrossAmount: i.payAmount,
        Rate: i.Rate,
        JobOrder: i.jobOrder == null ? jobOrderData : i.jobOrder,
        Typh: i.jobtype,
        RecordNo: "0",
        Status: i.rowstatus,
        TagCode: tagValue,
        EmployeeAuthorizedPayTypeId: i.EmployeeAuthorizedPayTypeId,
      };
    });
     
    let rowsIDs = rowsData.map((i) => i.ID);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}timesheet/feedMultiple`,

        {
          isVerifyTimeSheet: data == "allVerify" ? true : false,
          workingHours: finalData,
          empId: userId,
          deletedList: deletedItemIdsUpload.length?deletedItemIdsUpload:rowsIDs,
          ppe: payPeriod?.ID || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then(async (response) => {
        ShowSucess("Data Saved Successfully");
        dispatch(
          GetEmployeeAuthorizedPayrollData({
            payPeriodId: payPeriod?.ID || "",
            employeeId: userId || "",
          })
        );
        setIsAllVerifyModal(false);
        loadPreData();
        setDeletedItemIdsUpload([]);
        setSaveButtonStatus(false);
      })
      .catch((error) => {
        ShowError(error);
        setIsAllVerifyModal(false);
        setDeletedItemIdsUpload([]);
        console.warn("Cannot retrieve user data", error);
      });
  };

  const [viewModal, setViewModal] = useState(false);

  // const isAllRowVerify = () => {
  //   let isShowButton = false;
  //   for (let i = 0; i < rowsData.length; i++) {
  //     if (rowsData[i].verify === false) {
  //       isShowButton = true;
  //     }
  //   }
  //   return isShowButton;
  // };
  const handleDownloadExcel = (rowData) => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = rowData.map((i) => ({
      EmployeeID: userId,
      jobtype: i.jobtype,
      date: i.date,
      from: i.time_from,
      to: i.time_to,
      // hours: i.hrs,
      // verify: i.verify,
      // jobOrder: i.jobOrder,
    }));

    // create workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(rows);
    // worksheet["C1"].t = "d";
    // worksheet["C1"].v = "Date";

    console.log("DSFdsfsdsfsf", worksheet);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "EmployeeId",
        "Pay Type",
        "Date",
        "From",
        "To",
        // "hours",
        // "Verify",
        // "jobOrder",
      ],
    ]);

    XLSX.writeFile(
      workbook,
      `TimeSheet-TK-${payPeriod.StartDate}-${moment().format(
        "DDMMYYYHHMMSS"
      )}.xlsx`,
      { compression: true }
    );
  };

  const keyData = [
    { labal: "EmployeeId", type: "EmployeeID" },
    { labal: "Pay Type", type: "jobtype" },
    { labal: "Date", type: "date" },
    { labal: "From", type: "time_from" },
    { labal: "To", type: "time_to" },
    { labal: "hours", type: "hrs" },
    // { labal: "Verify", type: "verify" },
    // { labal: "Job Order", type: "jobOrder" },
    // { labal: "From_1", type: "time_from1" },
    // { labal: "To_1", type: "time_to1" },
    // { labal: "H Pay Type", type: "hjobtype" },
  ];

  console.log("dasdfjkfhsdkls", rowsDataUpload);
  const checkUniqueArray = (uniqueEmp, uniqueDataID) => {
    const mainArraySet = new Set(uniqueEmp);

    // Iterate through secondArray
    for (const value of uniqueDataID) {
      // Check if the value exists in mainArraySet
      if (!mainArraySet.has(value)) {
        return true; // If a value doesn't match, return true
      }
    }

    // If all values match, return false
    return false;
  };

  function getMatch(a, b, err) {
    var matches = [];
    console.log("Dsaddadadadas", a, b);
    for (var i = 0; i < a.length; i++) {
      if (!b.includes(parseInt(a[i]))) {
        matches.push(a[i]);
      }
    }
    return { matches, err };
  }

  function getMatchPaycode(a, b, err) {
    var matches = [];
    console.log("Dsaddadadadas", a, b);
    for (var i = 0; i < a.length; i++) {
      if (!b.includes(a[i])) {
        matches.push(a[i]);
      }
    }
    return { matches, err };
  }

  function getMatchDate(a, b, err, fileData) {
    var matches = [];
    for (var i = 0; i < a.length; i++) {
      if (!(a[i] >= b?.StartDate && a[i] <= b?.EndDate)) {
        // Datas[indexData]["dateError"] = true;

        matches.push(a[i]);
      }
    }
    return { matches, err };
  }
  const checkIsValidFile = (fileData, authPaycode) => {
    console.log("fileData", fileData);
    console.log("fileData assigneeEmployeeList", assigneeEmployeeList);
    const uniqueEmp = uniqueValue(assigneeEmployeeList, "employeeID");
    const uniqueDataID = uniqueValue(fileData, "EmployeeID");
    const uniqueDataDate = uniqueValue(fileData, "date");

    const filterPaycode = paytimeCodeByID.map((i) => {
      return { payCode: i.PayCode_PayTimeCode?.Abberv, code: i.PayCode };
    });

    const uniquePaycodeExcel = uniqueValue(fileData, "jobtype");
    const uniquePaycodeAssign = uniqueValue(filterPaycode, "payCode");
    console.log("fileData uniq", uniquePaycodeExcel, uniquePaycodeAssign);

    const getErrorDate = getMatchDate(
      uniqueDataDate,
      payPeriod,
      "DATEERROR",
      fileData
    );
    const getErrorID = getMatch(uniqueDataID, uniqueEmp, "IDERROR");
    const getErrorPaycode = getMatchPaycode(
      uniquePaycodeExcel,
      uniquePaycodeAssign,
      "CODEERR"
    );

    console.log(uniqueDataDate,payPeriod,"fileData Complete", getErrorDate);

    let errors = [];

    if (getErrorDate?.matches.length) {
      console.log("dscdfsffas 2");

      let errorMEss = {
        message: `Date ${getErrorDate?.matches
          ?.map((i) => i)
          ?.join(",")} is not in PPE`,
      };
      // errors.push(errorMEss); //date hide for now

    }

    if (getErrorID?.matches.length) {
      console.log("dscdfsffas 1", getErrorID?.matches.length);
      if (getErrorID?.matches
        ?.map((i) => i)
        ?.join(",")) {
        let errorMEss = {
          message: `Employee #${getErrorID?.matches
            ?.map((i) => i)
            ?.join(",")} is not assign to you`,
        };
        errors.push(errorMEss);
      }

      
    }

    if (getErrorPaycode?.matches.length) {
      console.log("dscdfsffas 2");

      let errorMEss = {
        message: `PayCode ${getErrorPaycode?.matches
          ?.map((i) => i)
          ?.join(",")} is not assign to you`,
      };
      errors.push(errorMEss);
    }

    setFileErrors(errors);
    return errors;
    // fileErrors = []
    // fileError.push("10000 is not assign to you");
    // fileError.push("Paycode is not assign to you");
    // fileError.push("Date is not in PPE");
  };

  console.log("casdsdssasf", fileErrors);
  const handleGetFileData = (e) => {
    // setFileErrors([]);

    const fileType = e.target.files[0].type;
    if (
      fileType !== "application/vnd.ms-excel" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload valid file");
      return false;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      console.log("csksdfks", workbook.SheetNames[0]);
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        // header: 1,
        raw: false,
        cellDates: true,
        dateNF: "yyyy-mm-dd",
        defval: "",
      });
      console.log("askdjlsjas", jsonData, data);

      // const modifiedData = jsonData.map((item, i) => {
      const modifiedData = jsonData.map((item, i) => {
        console.log("dxasdadasdadasd", item, paytimeCode, paytimeCodeByID);
        keyData.map((k) => {
          if (k.type == "time_from") {
            item[k.type] = addPad(item[k.labal]);
            delete item[k.labal];
          } else if (k.type == "From") {
            item['time_from'] = addPad(item[k.labal]);
            delete item[k.labal];
          } else if (k.type == "To") {
            item['time_to'] = addPad(item[k.labal]);
            delete item[k.labal];
          } else if (k.type == "Date") {
            item['Date'] = moment(item[k.labal]).format("YYYY-MM-DD");
            delete item[k.labal];
          } else if (k.type == "time_to") {
            item[k.type] = addPad(item[k.labal]);
            delete item[k.labal];
          } else if (k.type == "verify") {
            item[k.type] =
              item[k.labal]?.toLowerCase() == "true" ? true : false;
            delete item[k.labal];
          } else {
            item[k.type] = item[k.labal];
            delete item[k.labal];
          }

          if (k.type == "EmployeeId") {
            item[k.type] = item[k.labal];
            delete item[k.labal];
          } else {
            item["EmployeeID"] = userId;
          }
        });
        console.log('baby ==>',item);
        

        item["id"] = KeyGen(7);
        item["code"] = paytimeCode?.find((i) => i.value == item.jobtype)?.code;

        item["rowstatus"] = "Confirm";
        item["EmployeeID"] =  item["EmployeeID"]?item["EmployeeID"]:userId;
        item["ole2"] = false;
        item["ole1"] = false;
        item["isNew"] = true;
        const rate = getRateForDate(amountArray, item["date"], price);
        item["Rate"] = rate;
        let t1 = hoursDiffrence(item["time_from"], item["time_to"]);
        let t2 = hoursDiffrence(item["time_from1"], item["time_to1"]);
        let hrCalc = HourMinFormat(
          `${Math.floor((t1.totalMin + t2.totalMin) / 60)}:${
            (t1.totalMin + t2.totalMin) % 60
          }`
        );
        // item["hrs"] = hrCalc;
        item["mn"] = t1.totalMin;
        item["mn1"] = t2.totalMin;
        const payInfo = getCalculatedAmtMin(
          t1.totalMin + t2.totalMin,
          item["jobtype"],
          rate
        );
        item["payAmount"] = payInfo;
        return item;
      });
      console.log(
        "dasdjkadaklsa",
        modifiedData,
        employeeAuthorizedPayTimeCodesList
      );
      checkIsValidFile(modifiedData);

      setRowsDataUpload(modifiedData);
    };

    reader.readAsArrayBuffer(file);
  };

  function areObjectsEqual(obj1, obj2) {
    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }
  const copyTemplate = () => {
    let copy = [];

    if (isTableView == "table") {
      copy = [...rowsData];
    } else if (isTableView == "upload") {
      copy = [...rowsDataUpload];
    } else {
      copy = [...rowsData];
    }
    let filteredData1 = copy.filter((p) => p.jobtype == "REG");
    let array = [];
    filteredData1.map((i) => {
      array.push({
        selectedDay: moment(i.date).format("dddd"),
        DayNo: moment(i.date).format("dddd"),
        time_from: moment(i.time_from, "HH:mm").format("HH:mm:ss"),
        time_to: moment(i.time_to, "HH:mm").format("HH:mm:ss"),
        SnNo: 1,
      });
    });
    const dayOrder = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    console.log("asdadadadadada", array);
    const filteredData = array.filter((item, index, self) => {
      // Check if there are any previous items with the same values for all keys
      return self.findIndex((el) => areObjectsEqual(el, item)) === index;
    });

    return filteredData.sort(function (a, b) {
      const dayA = dayOrder[a.selectedDay];
      const dayB = dayOrder[b.selectedDay];

      var aLow = a.time_from;
      var bLow = b.time_to;
      console.log(aLow + " | " + bLow);

      if (dayA == dayB) {
        return aLow < bLow ? -1 : aLow > bLow ? 1 : 0;
      } else {
        return dayA < dayB ? -1 : 1;
      }
    });
  };

  console.log("asdsadadasdadd", copyTemplate());
  const disableTable =
    rowsData.length > 0
      ? rowsData?.every((item) => {
          let isdisable = false;
          if (["timekeeper"].includes(getUserRole(userData))) {
            isdisable = item.Status === "supervisor" || item.Status === "admin";
          } else if (["supervisor"].includes(getUserRole(userData))) {
            isdisable = item.Status === "admin";
          } else {
            isdisable = false;
          }
          return isdisable;
        })
      : false;

  return (
    <div className="container w-full ">
      {/* {isloading && <LoadingPanel></LoadingPanel>} */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="row mt-3 mb-3" card>
          <div className="col-md-3">
            <FormControl fullWidth>
              <InputLabel id="demo-data-select-label">
                Select Profile Type
              </InputLabel>

              <Select
                labelId="demo-data-select-label"
                label="Select Profile Type"
                id="demo-data-select"
                value={profileType.label}
                name={profileType.value}
                size={"medium"}
                onChange={(e) => {
                  console.log("podsfdsfsfs", e.target);
                  setTemplateID(e.target.value);
                  setProfileType({
                    label: e.target.value,
                    value: ["PREVPPE", "PREVWEAKPPE"].includes(e.target.value)
                      ? e.target.value
                      : "CUSTOMTEMPLATE",
                  });
                  if (
                    userTemplate.length > 0 &&
                    !["PREVPPE", "PREVWEAKPPE"].includes(e.target.value)
                  ) {
                    let optionData = userTemplate.find(
                      (option) => option.TemplateID == e.target.value
                    );
                    if (optionData?.EmployeeTemplateName) {
                      setTemplateTitle(optionData?.EmployeeTemplateName);
                    }
                  }
                }}
              >
                {["admin", "supervisor"].includes(getUserRole(userData))
                  ? templateOption.length &&
                    templateOption.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : templateOption1.length &&
                    templateOption1.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>
          {profileType?.value == "PROFILETEMPLATE" && (
            <div className="col-md-3">
              <FormControl fullWidth>
                <InputLabel id="demo-data2-select-label">
                  Select Profile Template
                </InputLabel>

                <Select
                  labelId="demo-data2-select-label"
                  label="Select Profile Template"
                  id="demo-data2-select"
                  value={templateID ? templateID : ""}
                  size={"medium"}
                  onChange={(e) => {
                    console.log("Max 1", e.target.value, e.target);
                    setTemplateID(e.target.value);
                    if (adminTemplate.length > 0) {
                      let optionData = adminTemplate.find(
                        (option) => option.ID === e.target.value
                      );
                      if (optionData.TimeSheetName) {
                        setTemplateTitle(optionData.TimeSheetName);
                      }
                    }
                  }}
                >
                  {adminTemplate.length &&
                    adminTemplate.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.ID}
                        name={option.TimeSheetName}
                      >
                        {option.TimeSheetName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* {profileType?.value == "CUSTOMTEMPLATE" && (
            <div className="col-md-3">
              <FormControl fullWidth>
                <InputLabel id="demo-data3-select-label">
                  Select Custom Template
                </InputLabel>

                <Select
                  labelId="demo-data3-select-label"
                  label="Select Custom Template"
                  id="demo-data3-select"
                  value={templateID ? templateID : ""}
                  size={"medium"}
                  onChange={(e) => {
                    setTemplateID(e.target?.value);
                    if (userTemplate.length > 0) {
                      let optionData = userTemplate.find(
                        (option) => option.TemplateID === e.target.value
                      );
                      if (optionData.EmployeeTemplateName) {
                        setTemplateTitle(optionData.EmployeeTemplateName);
                      }
                    }
                  }}
                >
                  {userTemplate.length ? (
                    userTemplate.map((option, index) => (
                      <MenuItem key={index} value={option.TemplateID}>
                        {console.log("121212 :::: ", option)}
                        {option.EmployeeTemplateName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}>
                      No Template Found
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          )} */}
          {["PROFILETEMPLATE", "CUSTOMTEMPLATE"].includes(
            profileType?.value
          ) ? (
            <div className="col-md-3 flex">
              <Button
                // onClick={renderProfileData}
                onClick={() => {
                  console.log("TEST 123123 ::: ", templateID);
                  if (templateID) {
                    setViewModal(true);
                  } else {
                    ShowError("Please select Template");
                  }
                }}
                variant="contained"
                // disabled={check || hasError}
                className="m-10 bg-primary"
                size="medium"
                style={{
                  color: "#d0efe9",
                  // backgroundColor: "#61bdaa",
                }}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  // style={{ color: "#24695c", marginRight: "10px" }}
                  style={{ marginRight: "10px" }}
                  className="bg-primary"
                />
                <Hidden smDown>
                  <Typography className="text-12">Preview</Typography>
                </Hidden>
              </Button>
              {templateData.length ? (
                <Button
                  onClick={() => {
                    setAuthModal(true);
                  }}
                  variant="contained"
                  className="m-10 bg-primary"
                  size="medium"
                  style={{
                    color: "#d0efe9",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    style={{ marginRight: "10px" }}
                    className="bg-primary"
                  />
                  <Hidden smDown>
                    <Typography className="text-12">Auth</Typography>
                  </Hidden>
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="col-md-3">
              <Button
                onClick={renderProfileData}
                variant="contained"
                // disabled={check || hasError}
                className="m-10 bg-primary"
                size="medium"
                style={{
                  color: "#d0efe9",
                  // backgroundColor: "#61bdaa",
                }}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  // style={{ color: "#24695c", marginRight: "10px" }}
                  style={{ marginRight: "10px" }}
                  className="bg-primary"
                />
                <Hidden smDown>
                  <Typography className="text-12">Submit Now</Typography>
                </Hidden>
              </Button>
            </div>
          )}

          {viewModal && (
            <Dialog
              open={viewModal}
              fullWidth
              maxWidth="lg"
              onClose={() => {
                setViewModal(false);
              }}
            >
              <DialogTitle>
                {/* <Box display="flex" justifyContent="space-between"> */}
                <Typography>
                  <h3>{templateTitle}</h3>
                </Typography>

                {/* <IconButton
          onClick={() => {
            setViewModal(false);
          }}
        >
          <Close />
        </IconButton> */}
                {/* </Box> */}
              </DialogTitle>
              <DialogContent>
                <ViewTimeSheetTemplates
                  templateId={templateID}
                  setTemplateID={setTemplateID}
                  templateList={
                    profileType?.value == "CUSTOMTEMPLATE"
                      ? userTemplate
                      : adminTemplate
                  }
                  sideBarFrom="Payroll"
                  setTemplateTitle={setTemplateTitle}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    setViewModal(false);
                    if (templateID && templateTitle) {
                      renderProfileData();
                    }
                  }}
                  className="bg-primary"
                  style={{ color: "white" }}
                >
                  Apply
                </Button>
                <Button
                  autoFocus
                  onClick={() => {
                    setViewModal(false);
                  }}
                  className="bg-primary"
                  style={{ color: "white" }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>

        <div className="mt-3" style={ { display: "flex" } }>
        <div>
            <span
              style={isTableView == "upload" ? selectedCss : notSelectedCss}
            >
              <Button
                variant="contained"
                className="bg-primary"
                onClick={() => {
                  setIsTableView("upload");
                  setRowsDataUpload([]);
                }}
                style={{
                  width: "75px",
                  minWidth: 0,
                  padding: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{
                    marginRight: "3px",
                  }}
                />{" "}
               Step 1 {/*  V2 */}
              </Button>
            </span>
          </div>
          <div>
            <span style={isTableView == "table" ? selectedCss : notSelectedCss}>
              <Button
                variant="contained"
                className="bg-primary"
                onClick={() => {
                  setIsTableView("table");
                }}
                style={{
                  width: "75px",
                  minWidth: 0,

                  padding: "2px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{
                    marginRight: "3px",
                  }}
                />{" "}
                Step 2 {/* V1 */}
              </Button>
            </span>
          </div>
         
          {rowsDataUpload?.length ? (
            <div>
              <Button
                variant="contained"
                className="bg-primary"
                onClick={() => handleDownloadExcel(rowsDataUpload)}
                style={{
                  padding: 2,
                }}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    marginRight: "3px",
                  }}
                />{" "}
                DownLoad
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      {isTableView == "table" && !rowsData.length ? null : isTableView ==
          "upload" && !rowsDataUpload.length ? null : (
        <div
          style={{ display: "flex", justifyContent: "end" }}
          className="mb-2"
        >
          <Button
            variant="contained"
            className="bg-primary"
            onClick={() => setModalCopy(true)}
            style={{
              padding: 2,
            }}
          >
            <FontAwesomeIcon
              icon={faDownload}
              style={{
                marginRight: "3px",
              }}
            />{" "}
            Save Template
          </Button>
        </div>
      )}

      <div className="row">
        <div className="col-md-12" style={{ padding: "0" }}>
          {/* <pre> */}
          {/* {JSON.stringify(originalData)} */}
          {/* {JSON.stringify(rowsData)} */}

          {/* {JSON.stringify(hourData)} */}
          {/* </pre> */}
          {isTableView == "table" ? (
            <Card style={{ width: "100%" }}>
              {/* {JSON.stringify(rowsData)} */}
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Standard Sheet</h5>
                  </div>
                  <div>
                    {weekOT?.week1 || weekOT?.week2 ? (
                      <Alert
                        icon={false}
                        severity="success"
                        className="mt-2 mb-2"
                        style={{ backgroundColor: "#388e3c", color: "#fff" }}
                      >
                        {weekOT.week1 ? (
                          <>
                            <FontAwesomeIcon icon={faClock} /> Week 1 OT :{" "}
                            {weekOT?.week1}
                          </>
                        ) : null}
                        {weekOT.week2 ? (
                          <>
                            <FontAwesomeIcon icon={faClock} /> Week 2 OT :{" "}
                            {weekOT?.week2}
                          </>
                        ) : null}
                      </Alert>
                    ) : null}
                  </div>
                  <div>
                    {disableTable === false && (
                      <button
                        className="btn btn-outline-success  "
                        style={{ marginBottom: 20 }}
                        onClick={addTableRows}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                {/* {JSON.stringify(rowsData)} */}
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Type</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Date</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>

                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>

                      <th
                        className="paroll-contents-th"
                        style={{ width: "80px" }}
                      >
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      {["supervisor", "timekeeper"].includes(
                        getUserRole(userData)
                      ) && (
                        <>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Verify</p>
                          </th>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Account No</p>
                          </th>
                        </>
                      )}
                      {["supervisor"].includes(getUserRole(userData)) && (
                        <>
                          {/* <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Approve From</p>
                          </th>

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Approve To</p>
                          </th>

                          <th
                            className="paroll-contents-th"
                            style={{ width: "80px" }}
                          >
                            <p className="tabletitle labelColor">Approved Hours</p>
                          </th> */}

                          <th
                            className="paroll-contents-th"
                            style={{ width: "125px" }}
                          >
                            <p className="tabletitle labelColor">Status</p>
                          </th>
                        </>
                      )}
                      {["timekeeper", "supervisor"].includes(
                        getUserRole(userData)
                      ) && (
                        <>
                          {/* <th
                              className="paroll-contents-th"
                              style={{ width: "290px" }}
                            >
                              <p className="tabletitle labelColor">TagCode</p>
                            </th> */}

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Add Row</p>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody
                    className="bg-white  paroll-contents-tbody"
                    style={{ width: "100%" }}
                  >
                    <TableRowsVerification
                      index={index}
                      allAccountNumber={allAccountNumber}
                      setTimeTo={setTimeTo}
                      setValueChange={setValueChange}
                      paytimeCodeSuperVisor={paytimeCodeSuperVisor}
                      rowsData={rowsData?.filter((i) => {
                        return !i.isTemplate;
                      })}
                      setRowsData={setRowsData}
                      employeeAuthorizedPayTimeCodesList={
                        employeeAuthorizedPayTimeCodesList
                      }
                      paytimeCode={paytimeCode}
                      holidaysData={holidaysData}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRows}
                      confirmTableRows={confirmTableRows}
                      handleVerifyRows={handleVerifyRows}
                      handleChange={handleChange}
                      handleChangeHizard={handleChangeHizard}
                      handleApproveStatus={handleApproveStatus}
                      handleDateFieldChange={handleDateFieldChange}
                      addTableRowsIndex={addTableRowsIndex}
                      deleteRowIndex={deleteRowIndex}
                      setDeleteRowIndex={setDeleteRowIndex}
                      setIsDeleteRowModal={setIsDeleteRowModal}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                      getJobOrderNov2={getJobOrderNov2}
                      jobTypeOptions={jobTypeOptions}
                    />

                    {/* {["supervisor"].includes(
                      getUserRole(userData)
                    ) && rowsData.filter((i) => {
                      return !i.isTemplate;
                    })?.length >= 2 ? (
                      <TableRowsAllVerifications
                        verifyAll={verifyAll}
                        handleApproveAllStatus={handleApproveAllStatus}
                        handleDisabelVerify={handleDisabelVerify}
                      />
                    ) : (
                      ""
                    )} */}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null}
          {isTableView == "upload" ? (
            <Card style={{ width: "100%" }}>
              {/* {JSON.stringify(rowsData)} */}
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Upload Sheet</h5>
                  </div>
                  <div>
                    {/* {weekOT?.week1 || weekOT?.week2 ? (
                      <Alert
                        icon={false}
                        severity="success"
                        className="mt-2 mb-2"
                        style={{ backgroundColor: "#388e3c", color: "#fff" }}
                      >
                        {weekOT.week1 ? (
                          <>
                            <FontAwesomeIcon icon={faClock} /> Week 1 OT :{" "}
                            {weekOT?.week1}
                          </>
                        ) : null}
                        {weekOT.week2 ? (
                          <>
                            <FontAwesomeIcon icon={faClock} /> Week 2 OT :{" "}
                            {weekOT?.week2}
                          </>
                        ) : null}
                      </Alert>
                    ) : null} */}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label htmlFor="upload-csv">
                        <input
                          style={{ display: "none" }}
                          id="upload-csv"
                          name="upload-csv"
                          type="file"
                          accept=".xlsx,.xls"
                          onChange={(e) => {
                            setFileErrors([]);

                            handleGetFileData(e);
                          }}
                          onClick={(e) => {
                            e.currentTarget.value = "";
                          }}
                        />
                        <Button
                          variant="contained"
                          className="m-10 bg-primary"
                          size="medium"
                          style={{
                            color: "#d0efe9",
                          }}
                          component="span"
                        >
                          <FontAwesomeIcon
                            icon={faFileExport}
                            style={{ marginRight: "10px" }}
                            className="bg-primary"
                          />
                          <Hidden smDown>
                            <Typography className="text-12">
                              Upload Timesheet
                            </Typography>
                          </Hidden>
                        </Button>
                      </label>
                    </div>

                    <div className="mt-2">
                      {disableTable === false && (
                        <button
                          className="btn btn-outline-success  "
                          onClick={addTableRowsUpload}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {fileErrors?.length ? (
                  <Alert
                    icon={false}
                    severity="success"
                    className="mt-2"
                    style={{ backgroundColor: "#FDEDED", color: "#5F2120" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          size="40"
                          style={{
                            marginRight: "10px",
                            color: "#5F2120",
                            backgroundColor: "#FDEDED",
                            fontSize: "14px",
                          }}
                          // className="bg-primary"
                        />
                      </div>
                      <div>
                        {fileErrors
                          ? fileErrors?.map((i, index) => {
                              return (
                                <div>
                                  <b>{`(${index + 1})`}</b> {`${i.message}`}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </Alert>
                ) : null}
              </CardHeader>
              <CardBody
                style={{ width: "100%", padding: "10px", overflowX: "auto" }}
              >
                <table
                  className="table paroll-contents-table  "
                  style={{ width: "100%" }}
                >
                  <thead className="paroll-contents-thead mb-3">
                    <tr>
                      {/* <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Employee Name</p>
                      </th> */}
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Pay Type</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">Date</p>
                      </th>
                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">From</p>
                      </th>

                      <th className="paroll-contents-th">
                        <p className="tabletitle labelColor">To</p>
                      </th>

                      <th
                        className="paroll-contents-th"
                        style={{ width: "80px" }}
                      >
                        <p className="tabletitle labelColor">Hours</p>
                      </th>
                      {["supervisor", "timekeeper"].includes(
                        getUserRole(userData)
                      ) && (
                        <>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Verify</p>
                          </th>
                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Account No</p>
                          </th>
                        </>
                      )}
                      {["supervisor"].includes(getUserRole(userData)) && (
                        <>
                          {/* <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Approve From</p>
                          </th>

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Approve To</p>
                          </th>

                          <th
                            className="paroll-contents-th"
                            style={{ width: "80px" }}
                          >
                            <p className="tabletitle labelColor">Approved Hours</p>
                          </th> */}

                          <th
                            className="paroll-contents-th"
                            style={{ width: "125px" }}
                          >
                            <p className="tabletitle labelColor">Status</p>
                          </th>
                        </>
                      )}
                      {["timekeeper", "supervisor"].includes(
                        getUserRole(userData)
                      ) && (
                        <>
                          {/* <th
                              className="paroll-contents-th"
                              style={{ width: "290px" }}
                            >
                              <p className="tabletitle labelColor">TagCode</p>
                            </th> */}

                          <th className="paroll-contents-th">
                            <p className="tabletitle labelColor">Add Row</p>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody
                    className="bg-white  paroll-contents-tbody"
                    style={{ width: "100%" }}
                  >
                  
                    <TableRowsVerificationUpload
                      index={indexUpload}
                      assigneeEmployeeList={assigneeEmployeeList}
                      allAccountNumber={allAccountNumber}
                      setTimeTo={setTimeToUpload}
                      paytimeCodeSuperVisor={paytimeCodeSuperVisor}
                      rowsData={rowsDataUpload?.filter((i) => {
                        return !i.isTemplate;
                      })}
                      setRowsData={setRowsDataUpload}
                      employeeAuthorizedPayTimeCodesList={
                        employeeAuthorizedPayTimeCodesList
                      }
                      paytimeCode={paytimeCode}
                      holidaysData={holidaysData}
                      paytimeCodeByID={paytimeCodeByID}
                      getDateColor={getDateColor}
                      deleteTableRows={deleteTableRowsUpload}
                      confirmTableRows={confirmTableRowsUpload}
                      handleVerifyRows={handleVerifyRowsUpload}
                      handleChange={handleChangeUpload}
                      handleChangeHizard={handleChangeHizard}
                      handleApproveStatus={handleApproveStatusUpload}
                      handleDateFieldChange={handleDateFieldChangeUpload}
                      addTableRowsIndex={addTableRowsIndexUpload}
                      deleteRowIndex={deleteRowIndexUpload}
                      setDeleteRowIndex={setDeleteRowIndexUpload}
                      setIsDeleteRowModal={setIsDeleteRowModalUpload}
                      payPeriod={{
                        StartDate:
                          payPeriod && payPeriod.StartDate
                            ? payPeriod.StartDate
                            : null,
                        EndDate:
                          payPeriod && payPeriod.EndDate
                            ? payPeriod.EndDate
                            : null,
                      }}
                      getJobOrderNov2={getJobOrderNov2}
                      jobTypeOptions={jobTypeOptions}
                      EmployeeID={userId}
                    />

                    {/* {["supervisor"].includes(
                      getUserRole(userData)
                    ) && rowsData.filter((i) => {
                      return !i.isTemplate;
                    })?.length >= 2 ? (
                      <TableRowsAllVerifications
                        verifyAll={verifyAll}
                        handleApproveAllStatus={handleApproveAllStatus}
                        handleDisabelVerify={handleDisabelVerify}
                      />
                    ) : (
                      ""
                    )} */}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ) : null}
          {isTableView == "sheet" ? (
            <Card className="p-3">
              <CardHeader>
                <h5>Schedular Sheet</h5>
              </CardHeader>
              <ReactTimeSchedular
                EmployeeID={userId}
                paytimeCodeList={paytimeCode}
                filteredEvent={originalData.concat(originalAuthData)}
                templateID={templateID}
                payPeriod={payPeriod}
                ref={childRef}
                templateData={templateData}
                paytimeCodeByID={paytimeCodeByID}
                setRowsData={setRowsData}
                statusSubmit={statusSubmit}
                loadPreData={loadPreData}
              />
            </Card>
          ) : null}
          {isTableView == "simplev1" ? (
            // <Card className="p-3">
            <PayrollSimpleView
              EmployeeID={userId}
              paytimeCodeList={paytimeCode}
              filteredEvent={originalData.filter((i) => i.Auth !== "Auth")}
              templateID={templateID}
              payPeriod={payPeriod}
              ref={childRef}
              templateData={templateData} // check for vaild auth hours
              paytimeCodeByID={paytimeCodeByID}
              userId={userId}
              setRowsData={setRowsData}
              statusSubmit={statusSubmit}
              loadPreData={loadPreData}
            />
          ) : // </Card>
          null}
          {isTableView == "simplev2" ? (
            // <Card className="p-3">
            <PayrollSimpleViewV2
              EmployeeID={userId}
              paytimeCodeList={paytimeCode}
              filteredEvent={originalData.filter((i) => i.Auth !== "Auth")}
              templateID={templateID}
              payPeriod={payPeriod}
              ref={childRef}
              templateData={templateData}
              paytimeCodeByID={paytimeCodeByID}
              userId={userId}
              setRowsData={setRowsData}
              statusSubmit={statusSubmit}
              loadPreData={loadPreData}
            />
          ) : // </Card>
          null}
          {isTableView == "timeClock" ? (
            <TimeClock
              userId={userId}
              ref={childRef}
              paytimeCodeByID={paytimeCodeByID}
              filteredEvent={originalData}
              paytimeCode={paytimeCode}
              payPeriod={payPeriod}
              loadPreData={loadPreData}
              rowAuthData={rowAuthData}
            />
          ) : null}
          {/* <Row style={{ marginTop: 30 }}>
            <Col
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
              >
              <b className="labelColor">Load Normal Profile :</b>

              <div style={{ flex: 1, display: "flex", marginLeft: 20 }}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item component="label">
                    No
                    <AntSwitch
                      checked={check}
                      onChange={(e) => {
                        handlePrefill(e.target.checked);
                      }}
                      name="normal_profile"
                    />
                    Yes
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col xs="12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {disableTable === false && (
                  <>
                    <Button
                      // onClick={() => handleSaveRows()}
                      onClick={() => {
                        setSaveButtonStatus(true);
                        if (isTableView == "table") {
                          handleSaveVarificationData();
                        } else if (isTableView == "upload") {
                          handleSaveVarificationDataUpload();
                        } else {
                          return;
                        }
                      }}
                      disabled={saveButtonStatus}
                      // onClick={ () => { childRef.current.childFunction() }}
                      variant="contained"
                      // disabled={check || hasError}
                      className="m-10 bg-primary"
                      style={{
                        color: "#d0efe9",
                        // backgroundColor: "#61bdaa",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{
                          // color: "#24695c",
                          marginRight: "10px",
                        }}
                        className="bg-primary"
                      />
                      <Hidden smDown>
                        <Typography className="text-12">
                          Save Time Sheet
                        </Typography>
                      </Hidden>
                    </Button>
                    <Button
                      // onClick={() => handleSaveVarificationData()}
                      onClick={() => setIsAllVerifyModal(true)}
                      variant="contained"
                      className="m-10 bg-primary"
                      style={{
                        color: "#d0efe9",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{
                          marginRight: "10px",
                        }}
                        className="bg-primary"
                      />
                      <Hidden smDown>
                        <Typography className="text-12">
                          Verify and submit
                        </Typography>
                      </Hidden>
                    </Button>
                  </>
                )}
                <div style={{ display: "flex", padding: "20px" }}>
                  <span style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "#f6e5e5 ",
                      }}
                    ></div>{" "}
                    <div style={{ marginLeft: "5px" }}>Week 1 Records</div>
                  </span>
                  <span style={{ display: "flex", marginLeft: "10px" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        background: "#d9ecd9",
                      }}
                    ></div>{" "}
                    <div style={{ marginLeft: "5px" }}>Week 2 Records</div>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {isAllVerifyModal && (
        <ConfirmModal
          open={isAllVerifyModal}
          setOpen={setIsAllVerifyModal}
          setOpenRemoveAlert={setIsAllVerifyModal}
          confimClick={() => handleSaveVarificationData("allVerify")}
          message={"Are you sure you want to verify all the records?"}
          modalHeader="Alert Message"
        />
      )}

      <TimeSheetModal
        open={modalOpen}
        setOpen={setModalOpen}
        title={isTableView}
        saveButton={true}
      />

      <CopyTemplateModal
        open={modalCopy}
        setOpen={setModalCopy}
        saveButton={true}
        copyTemplate={copyTemplate}
        EmpID={userId}
      />
      {rowAuthData.length && authModal ? (
        <PayrollAuthModalData
          authModal={true}
          setAuthModal={setAuthModal}
          rowDisable={true}
          setTimeTo={setTimeTo}
          rowsData={rowAuthData}
          setRowsData={setRowsData}
          paytimeCode={paytimeCode}
          templateTitleCurrent={templateTitleCurrent}
          paytimeCodeByID={paytimeCodeByID}
          getDateColor={getDateColor}
          deleteTableRows={deleteTableRows}
          confirmTableRows={confirmTableRows}
          handleChange={handleChange}
          handleChangeHizard={handleChangeHizard}
          handleDateFieldChange={handleDateFieldChange}
          payPeriod={{
            StartDate:
              payPeriod && payPeriod.StartDate ? payPeriod.StartDate : null,
            EndDate: payPeriod && payPeriod.EndDate ? payPeriod.EndDate : null,
          }}
        />
      ) : null}
      {isDeleteRowModal ? (
        <ConfirmModal
          open={isDeleteRowModal}
          setOpen={setIsDeleteRowModal}
          confimClick={() => deleteTableRows(deleteRowIndex)}
          message={"Are you sure you want to remove this record?"}
          modalHeader="Alert Message"
        />
      ) : (
        ""
      )}

      {isDeleteRowModalUpload ? (
        <ConfirmModal
          open={isDeleteRowModalUpload}
          setOpen={setIsDeleteRowModalUpload}
          confimClick={() => deleteTableRowsUpload(deleteRowIndexUpload)}
          message={"Are you sure you want to remove this record?"}
          modalHeader="Alert Message"
        />
      ) : (
        ""
      )}

      {getHelprCode(helprCode)?.description ? (
        <div>
          <Alert
            icon={false}
            severity="success"
            className="mt-2 mb-2"
            style={{ backgroundColor: "#e3effb", color: "#5287bd" }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <FontAwesomeIcon icon={faQuestionCircle} />
              <AlertTitle>{getHelprCode(helprCode)?.title} </AlertTitle>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <b>Hint : </b>
              </div>
              {getHelprCode(helprCode)?.description}
            </div>
          </Alert>
        </div>
      ) : null}
      {rowsData?.filter((i) => i.jobtype == "N/D")?.length ? (
        <Button
          variant="contained"
          onClick={addNDIF}
          // disabled={check || hasError}
          className="mt-1 mb-2  "
          size="small"
          style={{
            color: "#d0efe9",
          }}
        >
          <FontAwesomeIcon
            icon={faMinusCircle}
            style={{ marginRight: "10px", fontSize: "16px" }}
          />

          <Hidden smDown>
            <Typography style={{ fontSize: "14px" }}>Apply N/D</Typography>
          </Hidden>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={addNDIF}
          // disabled={check || hasError}
          className="mt-1 mb-2  "
          size="small"
          style={{
            color: "#d0efe9",
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ marginRight: "10px", fontSize: "16px" }}
          />

          <Hidden smDown>
            <Typography style={{ fontSize: "14px" }}>Apply N/D</Typography>
          </Hidden>
        </Button>
      )}
    </div>
  );
};
// export default PayrollContent;

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ showMessage }, dispatch);
// };

// export default connect(null, mapDispatchToProps)(PayrollContent);
export default PayrollContentVerification;
