import { TextField } from "@mui/material";
import React from "react";

import { Col, Form, Label, Row } from "reactstrap";

// import MaskedInput from "react-text-mask";
function AddForm(props) {
  return (
    <div className="w-100 h-full">
      <div className="flex flex-col sm:flex-row w-100 py-4 mt-10">
        {/* {JSON.stringify(props.detail)} */}
        <Form>
          <Row className="g-3  ">
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Vendor No"
                value={props.detail.VendorNo}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorNo: e.target.value,
                  });
                }}
                name="eName"
              />
              {/* <Label className="labelColor">Vendor No</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Vendor No"
                value={props.detail.VendorNo}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorNo: e.target.value,
                  });
                }}
              /> */}
            </Col>{" "}
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Vendor Name"
                value={props.detail.VendorName}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorName: e.target.value,
                  });
                }}
                name="eName"
              />
              {/* <Label className="labelColor">Vendor Name</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Vendor Name"
                value={props.detail.VendorName}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorName: e.target.value,
                  });
                }}
              /> */}
            </Col>{" "}
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="Vendor EIN"
                value={props.detail.VendorEIN}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorEIN: e.target.value,
                  });
                }}
                name="eName"
              />
              {/* <Label className="labelColor">Vendor EIN</Label>
              <input
                className="form-control"
                type="text"
                placeholder="Vendor EIN"
                value={props.detail.VendorEIN}
                onChange={(e) => {
                  props.setDetail({
                    ...props.detail,
                    VendorEIN: e.target.value,
                  });
                }}
              /> */}
            </Col>{" "}
            <Col md="6">
              <TextField
                margin="dense"
                className="flex w-full "
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    color: "black",
                    paddingTop: "12px",
                  },
                }}
                label="State"
                value={props.detail.State}
                onChange={(e) => {
                  props.setDetail({ ...props.detail, State: e.target.value });
                }}
                name="eName"
              />
              {/* <Label className="labelColor">State</Label>
              <input
                className="form-control"
                type="text"
                placeholder="State"
                value={props.detail.State}
                onChange={(e) => {
                  props.setDetail({ ...props.detail, State: e.target.value });
                }}
              /> */}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddForm;
