import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { Label } from "reactstrap";
import { Checkbox } from "@mui/material";

function FilterList(props) {
  const { changeHeaderFilter, filterBy, filterListByIsSingle } = props;

  return (
    <TableRow>
      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          placeholder="Min Range"
          name="minRange"
          value={filterBy.minRange}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="maxRange"
          placeholder="Max Range"
          value={filterBy.maxRange}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <input
          className="form-control"
          type="text"
          name="rate"
          placeholder="Rate"
          value={filterBy && filterBy.rate}
          onChange={(event) => changeHeaderFilter(event)}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper">
        <Checkbox
          checked={filterBy && filterBy.isSingle}
          onClick={(event) => filterListByIsSingle(event)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>

      <TableCell size="small" className="py-6 px-8 page-body-wrapper"></TableCell>

    </TableRow>
  );
}

export default FilterList;