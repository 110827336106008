// Mock Data
export const COMBINED_DEDUCTION_DATA = [
    {
        Emp_name: 'MICHAEL DIAZ',
        Emp_id: '10040529',
        Deduction_name: '',
        Deduction_type: '',
        Deduction_amount: '',
        Gross_pay: '',
        Federal_tax: '',
        State_tax: '',
        Insurance: '',
        Other_Deduction: '',
        Net_pay: ''
    },
    {
        Emp_name: 'ATALIG REYNITA',
        Emp_id: '10040532',
        Deduction_name: '',
        Deduction_type: '',
        Deduction_amount: '',
        Gross_pay: '',
        Federal_tax: '',
        State_tax: '',
        Insurance: '',
        Other_Deduction: '',
        Net_pay: ''
    },
    {
        Emp_name: 'RIVERA MARCIA',
        Emp_id: '10040533',
        Deduction_name: '',
        Deduction_type: '',
        Deduction_amount: '',
        Gross_pay: '',
        Federal_tax: '',
        State_tax: '',
        Insurance: '',
        Other_Deduction: '',
        Net_pay: ''
    },
    {
        Emp_name: 'ISMAEL DAVID',
        Emp_id: '10040531',
        Deduction_name: '',
        Deduction_type: '',
        Deduction_amount: '',
        Gross_pay: '',
        Federal_tax: '',
        State_tax: '',
        Insurance: '',
        Other_Deduction: '',
        Net_pay: ''
    },
    {
        Emp_name: 'SANTOS JOHN',
        Emp_id: '10040505',
        Deduction_name: '',
        Deduction_type: '',
        Deduction_amount: '',
        Gross_pay: '',
        Federal_tax: '',
        State_tax: '',
        Insurance: '',
        Other_Deduction: '',
        Net_pay: ''
    }
];