import { makeStyles } from "@material-ui/core/styles";

// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded";
// import Header from "./component/header";
import { Accordion, AccordionSummary, AccordionDetails, Container } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faUser,
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
// import axios from "axios";
// import {
//   getDeductionType,
//   getOverAllDeduction,
//   getOverAllEarning,
//   getVendor,
// } from "app/utility/axiosService";

import { faChartBar, faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import SankeyChart from "./Sankey";
import { Card, CardBody } from "reactstrap";
import ReportHeader from "../ReportHeader";
import AutocompleteAll from "../../../CommonElements/CustomComponent/AutoComplete";
import SankeyDetails from "./SankeyDetails";
import axios from "axios";
import { toast } from "react-toastify";
import { ShowError } from "../../../app/utility/helper";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiAccordionSummary-content": {
      padding: 0,
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: theme.palette.text.secondary,
  },
}));

function SankeyReport(props) {
  const classes = useStyles();

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const [chartData, setChartData] = useState([]);
  const [detail, setDetail] = useState({
    Code: "",
    VendorCode: "",
    Description: "",
    EmployeerFee: "",
    EmployeeFee: "",
    IsPercent: "",
    StartDate: null,
    EndDate: null,
    DeductionType: "",
  });
  const [expand, setExpand] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const sankeyChartRender = (payload) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/advancesearch/getcount`,
        {
          array: payload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      )
      .then((response) => {
        const data = response?.data?.result?.map((i) => {
          return [i.name, i.value, i.count];
        });

        setChartData(data);

        if (!data.length) {
          ShowError("No Record Found")
        }
      })
      .catch((error) => {
        ShowError("No Record Found")
      });
  };

  const { userDetail } = props;
  return (
    
    <Container fluid={true} style={{ overflow: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <ReportHeader {...props} header="Sankey Chart" />
      </div>
      <div className="page-content">
      <Card
        classes={{
          root: "w-full h-full",
          header: "min-h-40 h-0 sm:h-0 sm:min-h-68",
          contentWrapper: "p-0",
          contentCard: "rounded-none",
        }}
      >
        <CardBody>
          <div className={`${classes.root} w-full `}>
            <SankeyDetails sankeyChartRender={sankeyChartRender} />
          </div>
        </CardBody>
      </Card>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <SankeyChart chartData={chartData} />
      </div>
      </div>
      </Container>
    
  );
}
export default SankeyReport;
