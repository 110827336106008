const visileColumns = [
  {
    title: "DEPT_KEY",
    field: "DEPT_KEY",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "DP2",
    field: "DP2",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "DP3",
    field: "DP3",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "JOB_ORDER",
    field: "JOB_ORDER",
    locked: false,
    filter: "Numeric",
    width: "auto",
    show: true,
  },
  {
    title: "NAME",
    field: "NAME",
    locked: false,
    filter: "auto",
    width: 180,
    show: true,
  },
  {
    title: "REMARKS",
    field: "REMARKS",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "USER_DATE",
    field: "USER_DATE",
    locked: false,
    filter: "date",
    format: "DD/MM/yyyy",
    editor: "date",
    width: "auto",
    show: true,
  },
];

export default visileColumns;
